import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { IncludesLocationPathName, contructValidationErrorMessage, isBranchManager } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { history } from "../..";
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";

const ChangePasscode = () => {
	//Use Paramsf
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { profileStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { changePasscode } = profileStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "Passcode" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "ChangePasscode" }), urlPath: RoutesList.changePasscode }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			currentPasscode: "",
			newPasscode: "",
			confirmPasscode: "",
		},
		validationSchema: Yup.object({
			currentPasscode: Yup.string()
				.required(intl.formatMessage({ id: "CurrentPasscodeIsRequired" }))
				.min(4, intl.formatMessage({ id: "CurrentPasscodeMustLonger" })),
			newPasscode: Yup.string()
				.required(intl.formatMessage({ id: "NewPasscodeIsRequired" }))
				.min(4, intl.formatMessage({ id: "NewPasscodeMustLonger" })),
			confirmPasscode: Yup.string()
				.oneOf([Yup.ref('newPasscode'), null], intl.formatMessage({ id: "ConfirmPasscodeMustMatchNewPasscode" }))
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			try {
				let resultChangePasscodeAPI = await changePasscode(valuesTemp);
				if (resultChangePasscodeAPI.status === Constants.success) {
					setTimeout(()=> {
						history.push(`/${RoutesList.dashboard}`)
					}, Constants.timeoutNavigation)
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(()=> {
		if (!isBranchManager()) {
			history.push(`/${RoutesList.notFound}`)
		}
	}, [])
	
	useEffect(() => {
		if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl={12}>
								<LineBreakWithTittle
									paddingBottom="0px"
									title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ChangePasscode" }) })}
									h4Title />
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "CurrentPasscode" })}
										name="currentPasscode"
										className="mb-3 mt-4"
										type="password"
										maxLength={4}
										blnNumberOnly={true}
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "NewPasscode" })}
										name="newPasscode"
										type="password"
										maxLength={4}
										blnNumberOnly={true}
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "ConfirmPasscode" })}
										name="confirmPasscode"
										type="password"
										maxLength={4}
										blnNumberOnly={true}
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
							</Col>
						</Row>
						<GeneralSubmitAndCancelBtn
							successMessage={successMessage}
							viewAction={viewAction}
							validation={validation}
							cancelButtonFunction={() => {
								history.push(`/${RoutesList.dashboard}`)
							}}
						/>
					</Form>
				)}
			</DetailViewLayout>
		</div>
	);
};

export default observer(ChangePasscode);
