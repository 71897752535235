import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, returnPriceWithCurrency } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import MyButton from "../../../app/components/form/MyButton";
import GeneralInput from "../../../app/components/form/GeneralInput";
import ReportExpandRow from "../subView/ReportExpandRow";

const ProductMasterData = () => {
  const intl = useIntl();
  //Use Store
  const { reportMasterDataStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, windowSize  } = commonStore;
  const { productMasterDataList, getProductMasterDataList, setProductMasterDataList, exportProductMasterData } = reportMasterDataStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMasterDataReport = checkPermission([PermissionConstants.ExportMasterDataReport]);

  const aryProductsDetails : ObjectForReportRow[] = [
    {label: "Branch", value: "branchName", type: "string"}, 
    {label: "SellingPrice", value: "sellingPrice", type: "currency"}, 
    {label: "Quantity", value: "quantity", type: "string"}, 
    {label: "ReservedQuantity", value: "reservedQuantity", type: "string"}, 
    {label: "PhysicalQuantity", value: "actualQuantity", type: "string"}, 
    {label: "DefectQuantity", value: "defectQuantity", type: "string"}, 
    {label: "MinimumThreshold", value: "minimumThreshold", type: "string"}, 
    {label: "MaxReorderQuantity", value: "maxReorderQuantity", type: "string"}, 
  ];
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageMasterDataReport], true)) {
      return;
    }

    return (() => {
      setProductMasterDataList([]);
    })
  }, []);


  const fetchProductMasterDataListWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportProductMasterData({ pageNumber: 1, pageSize: pageSize, name: validation.values.name, sKU: validation.values.sKU });
    }
    else{
      setCurrentPage(1);
      await getProductMasterDataList({ pageNumber: 1, pageSize: pageSize, productName: encodeURIComponent(validation.values.name), sKU: encodeURIComponent(validation.values.sKU) });
    }
    setLocalLoading(false);
  };


  //Table Content UI
  const ProductMasterDataColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.name || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.sku || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "productTypes",
      text: intl.formatMessage({ id: "ProductTypes" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${(row.productTypes)}`}</div>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.sellingPrice)}
        </div>
      ),
    },
    {
      dataField: "floorPrice",
      text: intl.formatMessage({ id: "FloorPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.floorPrice)}
        </div>
      ),
    },
    {
      dataField: "costPrice",
      text: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.costPrice)}
        </div>
      ),
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.categoryName}`}</div>
      ),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.salesCategoryName}`}</div>
      ),
    },
    {
      dataField: "taxName",
      text: intl.formatMessage({ id: "Tax" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.taxName}`}</div>
      ),
    },
    {
      dataField: "measurementName",
      text: intl.formatMessage({ id: "BaseUnitofMeasure" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {`${row.measurementQuantity} ${row.measurementName}`}
        </div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "description",
      text: intl.formatMessage({ id: "Description" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.description || Constants.emptyData}</div>
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ProductDetails" })}
        rowFieldName={"productDetails"}
        rowColumns={aryProductsDetails}
        keyFieldName={"randomId"}
        actionTitle={intl.formatMessage({ id: "ViewProductDetails" })}
        actionPathName={`/${RoutesList.report}/${RoutesList.leadsDetail}`}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ProductMasterData" }) })}
        title={intl.formatMessage({ id: "ProductMasterData" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMasterDataReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchProductMasterDataListWithLoading(true)
            }}
          />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterProductName" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "SKU" })}
              name="sKU"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSKU" })}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchProductMasterDataListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ProductMasterDataColumns}
              keyField={"randomId"}
              data={productMasterDataList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductMasterData" }) })}
              objSorted={defaultSorted}
              requestAPI={getProductMasterDataList}
              productName={encodeURIComponent(validation.values.name)}
              sKU={encodeURIComponent(validation.values.sKU)}
              expandRow={expandRow}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ProductMasterData);
