import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { BatchJobListObject } from '../../app/models/batchJob';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { batchJobStatusColor, checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import moment from "moment";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const BatchJob = () => {
  const intl = useIntl();
  //Use Store
  const { batchJobStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt } = commonStore;
  const { batchJobList, batchJobPaginationParams, getBatchJob, deleteBatchJob, setBatchJobPaginationParams } = batchJobStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<BatchJobListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionUpdateBatchJob = checkPermission([PermissionConstants.UpdateBatchJob]);
  const blnPermissionDeleteBatchJob = checkPermission([PermissionConstants.DeleteBatchJob]);
  const blnPermissionCreateBatchJob = checkPermission([PermissionConstants.CreateBatchJob]);

  let cateogryRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize 
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchBatchJobAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.batchJob)
      if (blnBackNavigation) {
        if (batchJobPaginationParams) {
          setCurrentPage(batchJobPaginationParams.pageNumber)
          cateogryRequestBody = batchJobPaginationParams;
        }
      }
      else {
        setBatchJobPaginationParams(undefined);
      }
      await getBatchJob(cateogryRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageBatchJob], true)) {
      return;
    }

    fetchBatchJobAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.batchJob)
        if (!blnSetParams) {
          setBatchJobPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: BatchJobListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const BatchJobColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "className",
      text: intl.formatMessage({ id: "ClassName" }).toUpperCase(),
      style: {
        width: '15%'
      },
    },
    {
      dataField: "batchJobRunningType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.batchJobRunningType) || Constants.emptyData}
        </>
      )
    },
    {
      dataField: "description",
      text: intl.formatMessage({ id: "Description" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.description || Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "startRunningDateTime",
      text: intl.formatMessage({ id: "StartRunningDateTime" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.startRunningDateTime !== Constants.invalidDateTime ? `${moment(row.startRunningDateTime).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.startRunningDateTime !== Constants.invalidDateTime ? moment(row.startRunningDateTime).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "lastRunningDateTime",
      text: intl.formatMessage({ id: "LastRunningDateTime" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.lastRunningDateTime !== Constants.infiniteDateTimeWithValue ? `${moment(row.lastRunningDateTime).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.lastRunningDateTime !== Constants.infiniteDateTimeWithValue ? moment(row.lastRunningDateTime).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "nextRunningDateTime",
      text: intl.formatMessage({ id: "NextRunningDateTime" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.nextRunningDateTime !== Constants.invalidDateTime ? `${moment(row.nextRunningDateTime).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.nextRunningDateTime !== Constants.invalidDateTime ? moment(row.nextRunningDateTime).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "lastExceptionMessage",
      text: intl.formatMessage({ id: "LastExceptionMessage" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.lastExceptionMessage || Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "isActive",
      text:  intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle"/> : <div className="rounded-circle red-circle"/>}
        </div>
      ),
    },
    {
      dataField: "batchJobStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.batchJobStatus}
          statusColorFunction={batchJobStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.batchJob}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateBatchJob
              &&
              <TableActionButton 
                to={`/${RoutesList.batchJob}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteBatchJob
              &&
              <TableActionButton 
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "BatchJob" }) })}
        title={intl.formatMessage({ id: "BatchJob" })}
        addButton={
          blnPermissionCreateBatchJob
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "BatchJob" }) })}
            onClick={() => { history.push(`/${RoutesList.batchJob}/add`) }}
            linkTo={`/${RoutesList.batchJob}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={BatchJobColumns}
          data={batchJobList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "BatchJob" }) })}
          objSorted={defaultSorted}
          requestAPI={getBatchJob} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteBatchJob(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (batchJobList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getBatchJob({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(BatchJob);
