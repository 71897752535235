import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { StockReceiveListObject } from "../../app/models/stockReceive";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { stockReceiveViewAvailable, stockReceiveStatusColor, stockReceiveDeleteAvailable, newSpaceBeforeCapitalLetter, checkPermission, getBranchId, getBranchUser, getBranchName, arraySpliceInAllValue, stockReceiveEditAvailable, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { ProductListObject } from "../../app/models/product";

const StockReceive = () => {
  const intl = useIntl();

  //Use Store
  const { stockReceiveStore, staticReferenceStore, branchStore, productStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { stockReceiveList, stockReceivePaginationParams, getStockReceive, deleteStockReceive, setStockReceivePaginationParams } = stockReceiveStore;
  const { stockReceiveStatus, stockRequestPurchaserType, getStaticReferenceWithType } = staticReferenceStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { getProductDropdown } = productStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<StockReceiveListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedStockReceiveStatus, setSelectedStockReceiveStatus] = useState<string>(AllStatus);
  const [selectedStockReceiveStatusId, setSelectedStockReceiveStatusId] = useState<number | string | undefined>(" ");
  const [stockReceiveStatusList, setStockReceiveStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedPurchaserType, setSelectedPurchaserType] = useState<string>(AllType);
  const [selectedPurchaserTypeId, setSelectedPurchaserTypeId] = useState<number | string | undefined>(" ")
  const [stockPurchaserTypeList, setStockPurchaserTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllProducts = `${intl.formatMessage({ id: "AllProducts" })}`;
  const [productList, setProductList] = useState<ProductListObject[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>(AllProducts);
  const [selectedProductId, setSelectedProductId] = useState< string>(" ");
  const blnPermissionCreateStockReceive = checkPermission([PermissionConstants.CreateStockReceive])
  const blnPermissionUpdateStockReceive = checkPermission([PermissionConstants.UpdateStockReceive]);
  const blnPermissionDeleteStockReceive = checkPermission([PermissionConstants.DeleteStockReceive]);
  const [triggerSearch, setTriggerSearch] = useState(0);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      stockReceiveNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let stockReceiveRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    requestBranchId: selectedBranchId,
    branchName: selectedBranchName,
    stockReceiveStatus: selectedStockReceiveStatusId,
    stockReceiveStatusName: selectedStockReceiveStatus,
    stockRequestPurchaserType: selectedPurchaserTypeId,
    stockRequestPurchaserTypeName: selectedPurchaserType,
    stockReceiveNo: validation.values.stockReceiveNo,
    productId: selectedProductId,
    productName: selectedProduct
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchStockReceiveListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.stockReceive)
      if (blnBackNavigation) {
        if (stockReceivePaginationParams) {
          setCurrentPage(stockReceivePaginationParams.pageNumber)
          setSelectedBranchId(stockReceivePaginationParams.requestBranchId || "")
          setSelectedBranchName(stockReceivePaginationParams.branchName || "")
          setSelectedStockReceiveStatus(stockReceivePaginationParams.stockReceiveStatusName || AllStatus);
          setSelectedStockReceiveStatusId(stockReceivePaginationParams.stockReceiveStatus)
          setSelectedPurchaserType(stockReceivePaginationParams.stockRequestPurchaserTypeName || "");
          setSelectedPurchaserTypeId(stockReceivePaginationParams.stockRequestPurchaserType);
          setSelectedProductId(stockReceivePaginationParams.productId || "");
          setSelectedProduct(stockReceivePaginationParams.productName || AllProducts);
          validation.setValues((values)=> ({
            ...values,
            stockReceiveNo: stockReceivePaginationParams.stockReceiveNo || "",
          }))
          stockReceiveRequestBody = stockReceivePaginationParams;
        }
      }
      else {
        setStockReceivePaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchStockReceiveAPI(false),
        getStaticReferenceWithType(Constants.stockReceiveStatus, AllStatus),
        getStaticReferenceWithType(Constants.stockRequestPurchaserType, AllType),
        getBranchDropDown(),
        getProductDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setStockReceiveStatusList(resultAPI[1]);
      setStockPurchaserTypeList(resultAPI[2]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[3], isEligibleAllBranch ? AllBranch : "", true));
      setProductList(arraySpliceInAllValue(resultAPI[4], AllProducts, false, "name", "id"));
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockReceive], true)) {
      return;
    }

    fetchStockReceiveListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.stockReceive)
        if (!blnSetParams) {
          setStockReceivePaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedStockReceiveStatusId || selectedStockReceiveStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchStockReceiveAPI(true);
    }
  }, [selectedStockReceiveStatusId, selectedPurchaserTypeId, selectedBranchId, selectedProductId, triggerSearch]);

  async function fetchStockReceiveAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockReceive(stockReceiveRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: StockReceiveListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const StockReceiveColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockReceiveNo",
      text: `${intl.formatMessage({ id: "StockReceiveNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "receivedDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {row.receivedDate !== Constants.invalidDateTime ? `${moment(row.receivedDate).format(Constants.displayDateFormat)}` : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.receivedDate !== Constants.invalidDateTime ? moment(row.receivedDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "requestBranchName",
      text: intl.formatMessage({ id: "RequestedBranch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.requestBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "fromSupplierName",
      text: intl.formatMessage({ id: "Supplier" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.fromSupplierName || Constants.emptyData}</div>,
    },
    {
      dataField: "fromBranchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.fromBranchName || Constants.emptyData}</div>,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockReceiveStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.stockReceive}/view/${row.id}`}
              type={`view`}/>
            {
              blnPermissionUpdateStockReceive
              &&
              stockReceiveEditAvailable(row.status)
              &&
              <TableActionButton 
                to={`/${RoutesList.stockReceive}/edit/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeleteStockReceive
              &&
              !stockReceiveViewAvailable(row.status)
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}
        title={intl.formatMessage({ id: "StockReceive" })}
        addButton={
          blnPermissionCreateStockReceive
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}
            onClick={() => { history.push(`/${RoutesList.stockReceive}/add`) }}
            linkTo={`/${RoutesList.stockReceive}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              {
                !getBranchUser()
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchName"
                    options={branchListFinal}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    setFieldLabel={setSelectedBranchName}
                    setFieldValue={setSelectedBranchId}
                    disabled={localLoading || loading}
                    validationRequired={true}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockReceive" }) })}
                  name="stockReceiveStatus"
                  options={stockReceiveStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedStockReceiveStatus}
                  initialValue={selectedStockReceiveStatusId}
                  setFieldLabel={setSelectedStockReceiveStatus}
                  setFieldValue={setSelectedStockReceiveStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PurchaserType" })}
                  name="stockPurchaserType"
                  options={stockPurchaserTypeList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedPurchaserType}
                  initialValue={selectedPurchaserTypeId}
                  setFieldLabel={setSelectedPurchaserType}
                  setFieldValue={setSelectedPurchaserTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "StockReceiveNo" })}
                    name="stockReceiveNo"
                    type="text"
                    disabled={localLoading || loading}
                    validation={validation}
                    placeholder={intl.formatMessage({ id: "EnterStockReceiveNo" })}
                    onBlurFunction={()=> {
                      onClickSearch()
                    }}
                  />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Product" })}
                  name="product"
                  options={productList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedProduct}
                  initialValue={selectedProductId}
                  setFieldLabel={setSelectedProduct}
                  setFieldValue={setSelectedProductId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={StockReceiveColumns}
              data={stockReceiveList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockReceive" }) })}
              objSorted={defaultSorted}
              stockReceiveStatus={selectedStockReceiveStatusId}
              stockReceiveStatusName={selectedStockReceiveStatus}
              stockRequestPurchaserType={selectedPurchaserTypeId}
              stockRequestPurchaserTypeName={selectedPurchaserType}
              requestBranchId={selectedBranchId}
              branchName={selectedBranchName}
              stockReceiveNo={validation.values.stockReceiveNo}
              productId={selectedProductId}
              productName={selectedProduct}
              requestAPI={getStockReceive}
            />
            <DeleteModal
              displayName={selectedRow?.stockReceiveNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deleteStockReceive(
                  selectedRow?.id || "",
                  selectedRow?.stockReceiveNo || ""
                );
                let currentPageTemp = currentPage;
                if (stockReceiveList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getStockReceive({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(StockReceive);
