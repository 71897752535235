import { AppointmentSettings, AppointmentSubmitObject, GeneralSubmitObject, GeneralValueObject, SalesSettings, SalesSubmitObject } from "../models/setting";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class SettingStore {
  appointmentSettings : AppointmentSettings | undefined = undefined;
  generalSettings : GeneralValueObject | undefined = undefined;
  salesSettings : SalesSettings | any | undefined = undefined;
  
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appointmentSettings = undefined;
    this.generalSettings = undefined;
    this.salesSettings = undefined;
  }

  setAppointmentSettingsDetail = (appointmentSettings: AppointmentSettings | undefined) => {
    this.appointmentSettings = appointmentSettings;
  }

  setGeneralSettingsDetail = (generalSettings: GeneralValueObject | undefined) => {
    this.generalSettings = generalSettings;
  }

  setSalesSettingsDetail = (salesSettings: SalesSettings | undefined) => {
    this.salesSettings = salesSettings;
  }
  
  getAppointmentSettings = async () => {
    try{
      const resultAppointmentSettingsDetail = await agent.Settings.appointmentSettings();
      runInAction(() => {
        let objTemp : any = { id: resultAppointmentSettingsDetail.id, ...resultAppointmentSettingsDetail.values }
        this.appointmentSettings = objTemp;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentSettings = undefined;
    }
  }

  updateAppointmentSettings = async (appointmentSettingsRequestBody: AppointmentSubmitObject) => {
    try{
      await agent.Settings.updateAppointmentSettings(appointmentSettingsRequestBody);
      runInAction(() => {
        let objTemp : any = { id: appointmentSettingsRequestBody.id, ...appointmentSettingsRequestBody.values }
        this.appointmentSettings = objTemp;
      });
      store.commonStore.setSuccessMessage(`AppointmentSettingsUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getGeneralSettings = async () => {
    try{
      const resultGeneralSettingsDetail = await agent.Settings.generalSettings();
      runInAction(() => {
        let objTemp : any = { id: resultGeneralSettingsDetail.id, ...resultGeneralSettingsDetail.values }
        this.generalSettings = objTemp;
      });
      return Promise.resolve(resultGeneralSettingsDetail.values)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.generalSettings = undefined;
    }
  }

  updateGeneralSettings = async (generalSettingsRequestBody: GeneralSubmitObject) => {
    try{
      await agent.Settings.updateGeneralSettings(generalSettingsRequestBody);
      runInAction(() => {
        let objTemp : any = { id: generalSettingsRequestBody.id, ...generalSettingsRequestBody.values }
        this.generalSettings = objTemp;
      });
      store.commonStore.setSuccessMessage(`GeneralSettingsUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getSalesSettings = async () => {
    try{
      const resultSalesSettingsDetail = await agent.Settings.salesSettings();
      runInAction(() => {
        let objTemp : any = { id: resultSalesSettingsDetail.id, ...resultSalesSettingsDetail.values }
        this.salesSettings = objTemp;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesSettings = undefined;
    }
  }

  updateSalesSettings = async (salesSettingsRequestBody: SalesSubmitObject | any) => {
    try{
      await agent.Settings.updateSalesSettings(salesSettingsRequestBody);
      store.commonStore.setSuccessMessage(`SalesSettingsUpdateSuccess`);
      runInAction(() => {
        let objTemp : any = { id: salesSettingsRequestBody.id, ...salesSettingsRequestBody.values }
        this.salesSettings = objTemp;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  clearCache = async () => {
    try {
      await agent.Settings.clearCache();
      store.commonStore.setSuccessMessage(`CacheClearSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}