import React, { useEffect, useState } from 'react'
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/components/form/TextInput';
import MyButton from '../../app/components/form/MyButton';
import { history } from '../..';
import { useLocation } from "react-router-dom";
import { Constants } from '../../app/constants/Constants';
import { RoutesList } from "../../app/constants/RoutesList";
import AlertMessage from '../../app/components/alert/AlertMessage';
import { useIntl } from "react-intl";
import _ from 'lodash';

const ForgetPassword = () => {
  const intl = useIntl();
  const { userStore, commonStore } = useStore();
  const { forgetPassword } = userStore;
  const { errorMessage, setErrorMessage, successMessage } = commonStore;
  const [showReset, setShowReset] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let blnParamsFound = false
    if (location.state) {
      if (location.state.detail) {
        blnParamsFound = true;
        setShowReset(true)
      }
    }

    if (!blnParamsFound) {
      history.push(RoutesList.serverError)
    }
  }, [])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        history.push(RoutesList.login)
      }, Constants.timeoutNavigation)
    }
  }, [successMessage])

  return (
    <div>
      {
        showReset
        &&
        <>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {(errorMessage) && (
                    <AlertMessage
                      errorMessage={errorMessage}
                    />
                  )}
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs={7}>
                          <div className="text-primary p-4">
                            <h5 className="text-primary">{intl.formatMessage({ id: "ResetPassword" })}</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <br />
                      <div className="p-2">
                        <Formik
                          initialValues={{ userId: location.state.detail.userId, code: location.state.detail.code, newPassword: '', confirmPassword: '', error: null }}
                          onSubmit={(values, { setErrors, setSubmitting }) => {
                            setErrorMessage("");
                            forgetPassword(values)
                              .catch(error => {
                                setErrors({ error: `${intl.formatMessage({ id: "InvalidUsernameOrPasssord" })}` });
                              })
                              .finally(() => {
                                setSubmitting(false);
                              })
                          }}
                          validationSchema={Yup.object({
                            newPassword: Yup.string()
                              .required(intl.formatMessage({ id: "NewPasswordIsRequired" }))
                              .min(6, intl.formatMessage({ id: "NewPasswordMustLonger" }))
                              .matches(/[a-z]/, intl.formatMessage({ id: "NewPasswordMustContainLowercaseCharacters" }))
                              .matches(/[A-Z]/, intl.formatMessage({ id: "NewPasswordMustContainUppercaseCharacters" }))
                              .matches(/^(?=.{6,20}$)\D*\d/, intl.formatMessage({ id: "NewPasswordMustContainNumber" })),
                            confirmPassword: Yup.string()
                              .oneOf([Yup.ref('newPassword'), null], intl.formatMessage({ id: "ConfirmPasswordMustMatchNewPassword" }))
                          })}
                        >
                          {({ handleSubmit, isSubmitting, errors, dirty, isValid }) => (

                            <Form
                              className="form-horizontal"
                              onSubmit={handleSubmit}
                              onBlur={() => { setErrorMessage("") }}
                              autoComplete='off'
                            >
                              <ErrorMessage
                                name='error'
                                render={() => <Alert color="danger">{errors.error}</Alert>}
                              />

                              {/* <span className="mb-3">{isSubmitting ? "error" : null}</span> */}

                              <div className="mb-3">
                                <TextInput name='newPassword' type='password' placeholder={intl.formatMessage({ id: "LoginInputPlaceHolderEnter" }, { field: intl.formatMessage({ id: "NewPassword" }) })} label={intl.formatMessage({ id: "NewPassword" })} />
                              </div>

                              <div className="mb-3">
                                <TextInput name='confirmPassword' type='password' placeholder={intl.formatMessage({ id: "LoginInputPlaceHolderEnter" }, { field: intl.formatMessage({ id: "ConfirmPassword" }) })} label={intl.formatMessage({ id: "ConfirmPassword" })} />
                              </div>

                              <div className="flex-direction-row">
                                <MyButton content='Reset'
                                  class='btn btn-primary btn-block margin-right-8'
                                  type='submit'
                                  disable={(dirty && !isValid) || isSubmitting || Boolean(successMessage)}
                                  loading={isSubmitting || Boolean(successMessage)} />
                                <MyButton
                                  type="submit"
                                  class={`btn btn-back margin-right-8`}
                                  content={intl.formatMessage({ id: "Back" })}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/${RoutesList.login}`
                                    });
                                  }}
                                />
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      }
    </div>
  )
}

export default observer(ForgetPassword);
