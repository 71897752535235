import { PaginationRequestBody } from "../models/pagination";
import { LeaveEntitlementListObject, LeaveEntitlementAddObject, LeaveEntitlementUpdateObject, LeaveEntitlementDetailObject } from "../models/leaveEntitlement";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class LeaveEntitlementStore {
  leaveEntitlementList: LeaveEntitlementListObject[] = [];
  leaveEntitlementDropdownList: LeaveEntitlementListObject[] = [];
  leaveEntitlementDetail: LeaveEntitlementDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.leaveEntitlementList = [];
    this.leaveEntitlementDropdownList = [];
    this.leaveEntitlementDetail = undefined;
  }

  setLeaveEntitlementDetail = (leaveEntitlementDetail: LeaveEntitlementDetailObject | undefined) => {
    this.leaveEntitlementDetail = leaveEntitlementDetail;
  }
  
  getLeaveEntitlement = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultLeaveEntitlement = await agent.LeaveEntitlement.leaveEntitlementList(PaginationRequestBody);
      runInAction(() => {
        this.leaveEntitlementList = resultLeaveEntitlement.data;
        store.commonStore.setTotalItem(resultLeaveEntitlement.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leaveEntitlementList = [];
    }
  }

  getLeaveEntitlementDropdown = async () => {
    try{
      const resultLeaveEntitlementDropdown = await agent.LeaveEntitlement.leaveEntitlementDropdownList();
      runInAction(() => {
        this.leaveEntitlementDropdownList = resultLeaveEntitlementDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leaveEntitlementDropdownList = [];
    }
  }

  getLeaveEntitlementWithId = async (id: string) => {
    try{
      const resultLeaveEntitlementDetail = await agent.LeaveEntitlement.leaveEntitlementDetail(id);
      runInAction(() => {
        this.leaveEntitlementDetail = resultLeaveEntitlementDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leaveEntitlementDetail = undefined;
    }
  }

  addLeaveEntitlement = async (leaveEntitlementRequestBody: LeaveEntitlementAddObject) => {
    try{
      await agent.LeaveEntitlement.addLeaveEntitlement(leaveEntitlementRequestBody);
      store.commonStore.setSuccessMessage(`LeaveEntitlementAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateLeaveEntitlement = async (leaveEntitlementRequestBody: LeaveEntitlementUpdateObject) => {
    try{
      await agent.LeaveEntitlement.updateLeaveEntitlement(leaveEntitlementRequestBody);
      store.commonStore.setSuccessMessage(`LeaveEntitlementUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteLeaveEntitlement  = async (id: string, name: string) => {
    try {
      await agent.LeaveEntitlement.deleteLeaveEntitlement(id);
      store.commonStore.setSuccessMessage(`LeaveEntitlementDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
