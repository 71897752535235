import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, checkPermission, returnTableCellUI, getMonths, getBranchUser, arraySpliceInAllValue, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import { Link } from "react-router-dom"

const MonthEndStock = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, productStore, commonStore } = useStore();
  const { loading, setLoading, windowSize } = commonStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { monthEndStockList, getMonthEndStockList, exportMonthEndStock, setMonthEndStockList } = reportAccountStore; 
  const { getProductDropdown } = productStore;
  
  //Data List
  const monthEndStockColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      style: { alignContent: 'baseline' }
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
				row.details.map((valueDetailTemp, indexDetailTemp) => {
					aryViews.push(
						returnTableCellUI(`product_${indexDetailTemp}`, indexDetailTemp, row.details.length, valueDetailTemp.productName)
					)
				})
				return aryViews;
      },
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
				row.details.map((valueDetailTemp, indexDetailTemp) => {
					aryViews.push(
						returnTableCellUI(`sku_${indexDetailTemp}`, indexDetailTemp, row.details.length, valueDetailTemp.sku)
					)
				})
				return aryViews;
      },
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
				row.details.map((valueDetailTemp, indexDetailTemp) => {
					aryViews.push(
            <Link
              key={`quantity_${indexDetailTemp}_link`}
              to={`/${RoutesList.stockMovement}${valueDetailTemp.routeUrl}`}
              className="text-primary">
              {returnTableCellUI(`quantity_${indexDetailTemp}`, indexDetailTemp, row.details.length, valueDetailTemp.quantity)}
            </Link>    
					)
				})
				return aryViews;
      },
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
				row.details.map((valueDetailTemp, indexDetailTemp) => {
					aryViews.push(
						returnTableCellUI(`isActive_${indexDetailTemp}`, indexDetailTemp, row.details.length, valueDetailTemp.isActive ? intl.formatMessage({ id: "Yes" }).toUpperCase() : intl.formatMessage({ id: "No" }).toUpperCase())
					)
				})
				return aryViews;
      },
    }
  ];

  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(" ");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [productListFinal, setProductListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedProductName, setSelectedProductName] = useState(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState(" ");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const tabButtonWidth = windowSize.innerWidth >= Constants.innerWidthCompare ? `${windowSize.innerWidth/15}px` : "";
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnProductSelected = selectedProductId && selectedProductId !== " ";

  //Default Sorting Type
  const defaultSorted = [];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths());
    
    async function fetchMonthEndStock() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown()
      ];      
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setProductListFinal(arraySpliceInAllValue(resultAPI[1], AllProduct, false, "name", "id"));
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }
    
    fetchMonthEndStock();

    return (()=> {
      setMonthEndStockList([]);
    })
  }, [])

  const fetchMonthEndStockWithLoading = async (blnExport: boolean) => {
    setCurrentPage(1);
    setLocalLoading(true);
    if (blnExport) {
      await exportMonthEndStock({ branchId: selectedBranchId, productId: selectedProductId, year: selectedYear, month: selectedMonthValue, ...!blnAllActive && { isActiveProduct: blnIsActive } })
    } 
    else {
      await getMonthEndStockList({ branchId: selectedBranchId, productId: selectedProductId, year: selectedYear, month: selectedMonthValue, ...!blnAllActive && { isActiveProduct: blnIsActive } });    
    }
    setLocalLoading(false);
	};

  const blnViewAllFunction = () => {
    setBlnAllActive(true);
  }

  const blnActiveOnlyFunction = () => {
    setBlnIsActive(true);
    setBlnAllActive(false);
  }

  const blnInactiveOnlyFunction = () => {
    setBlnIsActive(false);
    setBlnAllActive(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "MonthEndStock"})})}
        title={intl.formatMessage({ id: "MonthEndStock" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchMonthEndStockWithLoading(true)
            }}
          />
        }
        >
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Product" })}
              name="productName"
              labelField={"name"}
              valueField={"id"}
              options={productListFinal}
              initialLabel={selectedProductName}
              initialValue={selectedProductId}
              setFieldLabel={setSelectedProductName}
              setFieldValue={setSelectedProductId}
              onChange={(value)=> {
                blnViewAllFunction();
              }}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col>
            <div className={`btn-group ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : ""}`} role="group">
              {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading || blnProductSelected, intl.formatMessage({ id: "All" }), blnViewAllFunction, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading || blnProductSelected, intl.formatMessage({ id: "Active" }), blnActiveOnlyFunction, tabButtonWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading || blnProductSelected, intl.formatMessage({ id: "Inactive" }), blnInactiveOnlyFunction, tabButtonWidth)}
            </div>
          </Col>
          <Col>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : `${Constants.innerWidthCompare472 > windowSize.innerWidth ? "mt-2" : ""} margin-bottom-14`}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading }
              disable={localLoading || loading }
              onClick={() => {
                fetchMonthEndStockWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <div>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              bordered={true}
              pageSize={pageSize}
              totalItem={monthEndStockList.length}
              tableColumns={monthEndStockColumns}
              data={monthEndStockList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "MonthEndStock"})})}
              objSorted={defaultSorted}
              keyField={"branchName"}
              requestAPI={getMonthEndStockList}
              hideSearchBar={true}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(MonthEndStock);
