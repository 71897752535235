import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, returnAdvancedSearchIcon, returnCurrency, returnPriceWithCurrency, returnSubBrandList, validRefundNoteStatusForEdit } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import MyButton from "../../app/components/form/MyButton";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import moment from "moment";
import CreditNotesModal from "./Modal/CreditNotesModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInputForModal from "../../app/components/form/GeneralInputForModal";
import { CustomerListObject } from "../../app/models/customer";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";

const RefundNoteDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { refundNoteStore, branchStore, settingsStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addRefundNote, updateRefundNote, updateRefundNoteAccountRevised, refundNoteDetail, setRefundNoteDetail, getRefundNoteWithId } = refundNoteStore;
  const { customerDropdownList, setCustomerDropdownList, getCustomerDropdown } = customerStore;
  const { customerRelationshipType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "RefundNote" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "RefundNote" }), urlPath: RoutesList.refundNote }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionManageCreditNote = checkPermission([PermissionConstants.ManageCreditNote]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [creditNotesModal, setCreditNotesModal] = useState(false);
  const [selectedCreditNotesIndex, setSelectedCreditNotesIndex] = useState<number | undefined>(undefined);
  const [triggerDeleteCreditNote, setTriggerDeleteCreditNote] = useState<number>(-1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);

  const CreditNotesColumns : any = [
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <>
          {
            blnPermissionManageCreditNote
            ?
            <Link to={`/${RoutesList.creditNote}/view/${row.id}`} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
            </Link>
            :
            <Label className="margin-bottom-0 margin-left-4">{row.documentNo}</Label>
          }
        </>
    },
    {
      dataField: "subTotalCreditNoteAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.subTotalCreditNoteAmount)}
        </div>
      )
    }
  ];

  const CreditNotesWithActionColumns = [
    ...CreditNotesColumns,
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: {textAlign: "center"},
      formatter: (cellContent, row, index) => (
        <div className="d-flex gap-3 flex-justfity-content-center">
          <TableActionButton
            type={'delete'}
            onClick={()=> { 
              setSelectedCreditNotesIndex(index)
              setTriggerDeleteCreditNote((triggerDeleteCreditNote)=> (triggerDeleteCreditNote+1))
            }} />
        </div>
      ),
    }
  ]

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: refundNoteDetail || {
      documentNo: "",
      requestBranchId: getBranchUser() ? getBranchId() : "",
      requestBranchName: getBranchUser() ? getBranchName() : "",
      customerId: "",
      customerName: "",
      customerIdentityNo: "",
      customerMobileNo: "",
      customerPrefillIdentityNo: "",
      customerPrefillMobileNo: "",
      status: "",
      preparedBy: "",
      preparedOn: "",
      reviewedBy: "",
      reviewedOn: "",
      actionedBy: "",
      actionedOn: "",
      creditRedemptionNoteRequestId: "",
      creditRedemptionNoteRequestNo: "",
      subBrandId: "",
      subBrandName: "",
      accountHolderName: "",
      bankAccountType: "",
      bankAccountNo: "",
      reason: "",
      creditNoteDetails: [],
      creditNoteIds: [],
      subTotalRefundAmount: 0,
      refundToCustomer: 0,
      processingFee: 0,
      refundSales: 0,
      customerEntitledAmount: 0,
      customerBranchId: "",
      customerBranchName: "",
      customerSubBranchId: "",
      customerSubBranchName: "",
      remark: "",
      reviewRemark: "",
      isRefundToOtherParty: false,
      recipientName: "",
      recipientRelationship: ""
    },
    validationSchema: Yup.object({
      requestBranchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
      customerId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })),  
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.creditNoteIds = valuesTemp.creditNoteDetails.map((valueCreditNote)=> valueCreditNote.id);
      if (valuesTemp.workflowAction === Constants.submit) {
        let blnErrorFound = false;
        if (!valuesTemp.subBrandId && returnSubBrandList().length > 0) {
          validation.setFieldError("subBrandId", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.accountHolderName) {
          validation.setFieldError("accountHolderName", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AccountHolderName" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.bankAccountType) {
          validation.setFieldError("bankAccountType", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountType" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.bankAccountNo) {
          validation.setFieldError("bankAccountNo", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountNo" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.reason) {
          validation.setFieldError("reason", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) }))
          blnErrorFound = true;
        }
        if (valuesTemp.creditNoteDetails.length === 0) {
          validation.setFieldError("creditNoteDetails", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CreditNotes" }) }))
          blnErrorFound = true;
        }
        if (valuesTemp.isRefundToOtherParty && !valuesTemp.recipientName) {
          validation.setFieldError("recipientName", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RecipientName" }) }))
          blnErrorFound = true;
        }
        if (valuesTemp.isRefundToOtherParty && !valuesTemp.recipientRelationship) {
          validation.setFieldError("recipientRelationship", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Relationship" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.customerIdentityNo) {
          validation.setFieldError("customerIdentityNo", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) }))
          blnErrorFound = true;
        }
        if (!valuesTemp.customerMobileNo) {
          validation.setFieldError("customerMobileNo", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MobileNo" }) }))
          blnErrorFound = true;
        }
        if (blnErrorFound) {
          return;
        }
      }

      try {
        if (blnAccountReviseRequiredStatus) {
          await updateRefundNoteAccountRevised({ 
            id: valuesTemp.id,
            accountHolderName: valuesTemp.accountHolderName,
            bankAccountType: valuesTemp.bankAccountType,
            bankAccountNo: valuesTemp.bankAccountNo,
            isRefundToOtherParty: valuesTemp.isRefundToOtherParty,
            recipientName: valuesTemp.recipientName,
            recipientRelationship: valuesTemp.recipientRelationship,
            customerIdentityNo: valuesTemp.customerIdentityNo,
            customerMobileNo: valuesTemp.customerMobileNo
          });
        }
        else if (addAction) {
          await addRefundNote(valuesTemp);
        } 
        else {
          await updateRefundNote(valuesTemp);
        }
      } 
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const [processingDetails, setProcessingDetails] = useState({
    refundToCustomer: 0,
    processingFee: 0
  })

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnAccountReviseRequiredStatus = validation.values.status === Constants.accountReviseRequired;

  useEffect(() => {
    async function fetchRefundNoteDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getGeneralSettings(),
        getStaticReferenceWithType(Constants.customerRelationshipType)
      ]
      if (id && !addAction) {
        aryAPI.push(getRefundNoteWithId(id));
      }
      
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 3) {
        if (resultAPI[3]) {
          if (!validRefundNoteStatusForEdit(resultAPI[3].status)) {
            history.push(`/${RoutesList.notFound}`)
            setLoading(false);
            return;
          }

          setProcessingDetails({
            refundToCustomer: _.cloneDeep(resultAPI[3].refundToCustomer),
            processingFee: _.cloneDeep(resultAPI[3].processingFee),
          })

          if (resultAPI[3].isRefundToOtherParty) {
            let selectedCustomerDropdownListTemp = await getCustomerDropdown(resultAPI[3].requestBranchId, {customerId: resultAPI[3].customerId});
            let indexCustomer = _.findIndex(selectedCustomerDropdownListTemp, { id: resultAPI[3].customerId });
            if (indexCustomer > -1 && selectedCustomerDropdownListTemp) {
              validation.setFieldValue("customerPrefillIdentityNo", selectedCustomerDropdownListTemp[indexCustomer].identityNo);
              validation.setFieldValue("customerPrefillMobileNo", selectedCustomerDropdownListTemp[indexCustomer].mobileNo);
            }
          }
          else {
            validation.setFieldValue("customerPrefillIdentityNo", resultAPI[3].customerIdentityNo);
            validation.setFieldValue("customerPrefillMobileNo", resultAPI[3].customerMobileNo);
          }

          if (resultAPI[3].status === Constants.accountReviseRequired) {
            validation.setFieldValue("aryFinanceTransactionAttachments", []);
          }
        }
      }
  
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (addAction) {
      setRefundNoteDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateRefundNote], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateRefundNote], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageRefundNote], true)) {
        return;
      }
    }

    fetchRefundNoteDetailAPI();

    return (() => {
      if (id && !addAction) {
        setRefundNoteDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(()=> {
    if (selectedCreditNotesIndex !== undefined) {
      if (triggerDeleteCreditNote > -1 && selectedCreditNotesIndex > -1) {
        let oldPaymentsTemp = _.cloneDeep(validation.values.creditNoteDetails);
        oldPaymentsTemp.splice(selectedCreditNotesIndex, 1);
        validation.setFieldValue("creditNoteDetails", oldPaymentsTemp);
      }
    }
  }, [triggerDeleteCreditNote])

  useEffect(()=> {
    if (!initialCallAPI) {
      let subTotalRefundAmountTemp = 0;
      validation.values.creditNoteDetails.forEach((valueCreditNote)=> {
        subTotalRefundAmountTemp+=valueCreditNote.subTotalCreditNoteAmount;
      })
      validation.setFieldValue("subTotalRefundAmount", subTotalRefundAmountTemp);
      onChangeRefundToCustomer(subTotalRefundAmountTemp, 0)
    }
  }, [validation.values.creditNoteDetails])

  const onChangeRefundToCustomer = (subTotalRefundAmountTemp, refundToCustomerTemp) => {
    let processingFeesTemp : any = ((refundToCustomerTemp * (generalSettings?.refundProcessingFeeRate || 0))/100).toFixed(2);
    validation.setFieldValue("refundToCustomer", refundToCustomerTemp);
    validation.setFieldValue("processingFee", processingFeesTemp);
    validation.setFieldValue("customerEntitledAmount", (refundToCustomerTemp-processingFeesTemp).toFixed(2));
    validation.setFieldValue("refundSales", (subTotalRefundAmountTemp-refundToCustomerTemp).toFixed(2));
    setProcessingDetails({refundToCustomer: refundToCustomerTemp, processingFee: processingFeesTemp});
  }

  const onChangeProcessingFees = (processingFeesTemp) => {
    validation.setFieldValue("processingFee", processingFeesTemp);
    validation.setFieldValue("customerEntitledAmount", (processingDetails.refundToCustomer-processingFeesTemp).toFixed(2));
    setProcessingDetails((processingDetailTemp)=> ({...processingDetailTemp, processingFee: processingFeesTemp}));
  }

  const prefillCustomerInfo = async (id: string, customerDropdownListTemp: CustomerListObject[] | any = []) => {
    setDisplayCustomerDropdown(false);
    if (customerDropdownListTemp.length === 0) {
      if (customerDropdownList.length > 0) {
        customerDropdownListTemp = customerDropdownList;
      }
      else {
        customerDropdownListTemp = await getCustomerDropdown(validation.values.requestBranchId, {customerId: id});
      }
    }

    //Prefill Customer Info
    let indexCustomer = _.findIndex(customerDropdownListTemp, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownListTemp[indexCustomer].id,
        customerName: customerDropdownListTemp[indexCustomer].preferredName,
        customerIdentityNo: customerDropdownListTemp[indexCustomer].identityNo,
        customerMobileNo: customerDropdownListTemp[indexCustomer].mobileNo,
        customerBranchId: values.requestBranchId,
        customerBranchName: customerDropdownListTemp[indexCustomer].branchName,
        customerSubBranchId: customerDropdownListTemp[indexCustomer].subBranchId,
        customerSubBranchName: customerDropdownListTemp[indexCustomer].subBranchName,
        customerPrefillIdentityNo: customerDropdownListTemp[indexCustomer].identityNo,
        customerPrefillMobileNo: customerDropdownListTemp[indexCustomer].mobileNo,
        isRefundToOtherParty: false,
        recipientName: "",
        recipientRelationship: ""
      }))
    }

    setTimeout(() => {
      clearCustomerDropdownList();
      setDisplayCustomerDropdown(true);
    }, 100)
  }

  const onChangeBranch = async () => {
    validation.setFieldValue("customerId", "");
    validation.setFieldValue("customerName", "");
    validation.setFieldValue("customerIdentityNo", "");
    validation.setFieldValue("customerMobileNo", "");
    validation.setFieldValue("customerPrefillIdentityNo", "");
    validation.setFieldValue("customerPrefillMobileNo", "");
    validation.setFieldValue("customerBranchId", "");
    validation.setFieldValue("customerBranchName", "");
    validation.setFieldValue("customerSubBranchId", "");
    validation.setFieldValue("customerSubBranchName", "");
    validation.setFieldValue("creditNoteDetails", []);
    validation.setFieldValue("isRefundToOtherParty", false);
    validation.setFieldValue("recipientName", "");
    validation.setFieldValue("recipientRelationship", "");
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(validation.values.requestBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "RefundNote" }) })}
                  h4Title />
                <Row>
                  <Col xl={6}>
                    <SingleColumnRowParent blnDoubleTab>
                      <DropDownWithTitle
                        name={"branchId"}
                        className="mb-3 mt-4"
                        title={intl.formatMessage({ id: "Branch"})}
                        specifyReturnFieldName={[{"field": "requestBranchName", "value": "name"},{"field": "requestBranchId", "value": "id"}]}
                        labelField={"name"}
                        valueField={"id"}
                        options={branchDropDownList}
                        disabled={disabledFieldInput || viewAction || getBranchUser() || blnAccountReviseRequiredStatus}
                        initialLabel={validation.values.requestBranchName}
                        initialValue={validation.values.requestBranchId}
                        onChangeFunction={onChangeBranch}
                        validation={validation}
                        validationRequired={true}/>
                    </SingleColumnRowParent>
                    {
                      displayCustomerDropdown
                      &&
                      <>
                        <SingleColumnRowParent blnDoubleTab>
                          <Row>
                          <DropDownWithTitle
                              name={"customerId"}
                              title={intl.formatMessage({ id: "Customer" })}
                              placeholder={intl.formatMessage({ id: "CustomerPrefillMessage" })}
                              specifyReturnFieldName={[
                                {
                                  field: "customerId",
                                  value: "id",
                                }
                              ]}
                              labelField={"preferredName"}
                              valueField={"id"}
                              options={customerDropdownList}
                              disabled={disabledFieldInput || !validation.values.requestBranchId || blnAccountReviseRequiredStatus}
                              initialLabel={validation.values.customerName}
                              initialValue={validation.values.customerId}
                              validationRequired={true}
                              validation={validation}
                              onChangeField={"id"}
                              onChangeFunction={prefillCustomerInfo}
                              blnSupportCustomSearch={true}
                              onChangeCustomSearch={(value) => {
                                fetchCustomerDropdown(value);
                              }}
                              onMenuOpenFunction={() => {
                                clearCustomerDropdownList();
                                setLoadingCustomerDropdown(false);
                              }}
                              loadingCustomSearch={loadingCustomerDropdown}
                              setLoadingCustomSearch={setLoadingCustomerDropdown}
                            />
                          </Row>
                        </SingleColumnRowParent>
                        <SingleColumnRowParent blnDoubleTab>
                          <GeneralInput
                            title={intl.formatMessage({ id: "RefundToOtherParty" })}
                            name="isRefundToOtherParty"
                            type="checkbox"
                            disabled={disabledFieldInput || viewAction || !validation.values.customerId}
                            validation={validation}
                            childrenUI={<Label className="margin-bottom-18 margin-left-4">Yes</Label>}
                            onChangeFunctionWithSetValidationField={(valueIsRefundToOtherParty)=> {
                              validation.setFieldValue("customerIdentityNo", !valueIsRefundToOtherParty ? validation.values.customerPrefillIdentityNo : "")
                              validation.setFieldValue("customerMobileNo", !valueIsRefundToOtherParty ? validation.values.customerPrefillMobileNo : "")
                              validation.setFieldValue("recipientName", "")
                              validation.setFieldValue("recipientRelationship", "")
                            }}
                          />
                        </SingleColumnRowParent>
                        {
                          validation.values.isRefundToOtherParty
                          &&
                          <>
                            <SingleColumnRowParent blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "RecipientName" })}
                                name="recipientName"
                                type="text"
                                disabled={disabledFieldInput || viewAction}
                                validation={validation}
                                validationRequired={true}
                              />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab={true}>
                              <DropDownWithTitle
                                name={"recipientRelationship"}
                                title={intl.formatMessage({ id: "Relationship" })}
                                specifyReturnFieldName={[{"field": "recipientRelationship", "value": "displayValue"}]}
                                labelField={"displayValue"}
                                valueField={"displayValue"}
                                options={customerRelationshipType}
                                disabled={disabledFieldInput || viewAction}
                                validationRequired={true}
                                initialLabel={validation.values.recipientRelationship}
                                initialValue={validation.values.recipientRelationship}
                                validation={validation}/>
                            </SingleColumnRowParent>
                          </>
                        }
                        <SingleColumnRowParent blnDoubleTab>
                          <GeneralInput
                            title={intl.formatMessage({ id: "IdentityNo" })}
                            name="customerIdentityNo"
                            type="text"
                            disabled={disabledFieldInput || viewAction || !validation.values.isRefundToOtherParty}
                            validation={validation}
                            validationRequired={true}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent blnDoubleTab>
                          <GeneralInput
                            title={intl.formatMessage({ id: "MobileNo" })}
                            name="customerMobileNo"
                            type="text"
                            disabled={disabledFieldInput || viewAction || !validation.values.isRefundToOtherParty}
                            validation={validation}
                            validationRequired={true}
                          />
                        </SingleColumnRowParent>
                      </>
                    }
                  </Col>
                  <Col xl={6} className="mb-3 mt-4">
                    {
                      returnSubBrandList().length > 0
                      &&
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <DropDownWithTitle
                          name={"subBrandId"}
                          title={intl.formatMessage({ id: "SubBrand"})}
                          specifyReturnFieldName={[{"field": "subBrandName", "value": "name"},{"field": "subBrandId", "value": "id"}]}
                          labelField={"name"}
                          valueField={"id"}
                          options={returnSubBrandList()}
                          disabled={disabledFieldInput || viewAction || blnAccountReviseRequiredStatus}
                          validationRequired={true}
                          initialLabel={validation.values.subBrandName}
                          initialValue={validation.values.subBrandId}
                          validation={validation}/>
                      </SingleColumnRowParent>
                    }
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "AccountHolderName" })}
                        name="accountHolderName"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                        validationRequired={true}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "BankAccountType" })}
                        name="bankAccountType"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                        validationRequired={true}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent blnDoubleTab>
                      <GeneralInput
                        title={intl.formatMessage({ id: "BankAccountNo" })}
                        name="bankAccountNo"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                        validationRequired={true}
                      />
                    </SingleColumnRowParent>
                  </Col>
                </Row>
                <GeneralTextArea
                  title={intl.formatMessage({ id: "Reason" })}
                  name="reason"
                  row={5}
                  disabled={disabledFieldInput || blnAccountReviseRequiredStatus}
                  validationRequired={true}
                  validation={validation}/>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "CreditNotes" })}
                  className="fw-bold"
                  titleSize="16px"
                  paddingBottom="12px"
                />
                {
                  !blnAccountReviseRequiredStatus
                  &&
                  <MyButton
                    type="button"
                    class={`btn btn-success`}
                    style={{ padding: "8px", paddingBottom: "4px", paddingTop: "4px"}}
                    content={intl.formatMessage({ id: "AddCreditNote" })}
                    disable={disabledFieldInput || !validation.values.customerId}
                    onClick={()=> {
                      setCreditNotesModal(true);
                    }}
                  />
                }
                <div className="margin-top-16">
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "CreditNotes" })}
                    headerClassName={"table-light"}
                    options={validation.values.creditNoteDetails}
                    columns={!blnAccountReviseRequiredStatus ? CreditNotesWithActionColumns : CreditNotesColumns}
                    pageSize={Constants.defaultPageSize}
                    blnHideTotal={true}
                    keyField={"id"}/>
                </div>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Refund" })}
                  className={`fw-bold margin-top-36`}
                  blnSkipMarginTop={true}
                  titleSize="16px"
                  paddingBottom="12px"
                />
                <Row>
                  <Col>
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "TotalCNAmount" })}
                        name="subTotalRefundAmount"
                        type="number"
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        disabled={true}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <GeneralInputForModal
                        title={intl.formatMessage({ id: "RefundToCustomer" })}
                        name="refundToCustomer"
                        type="number"
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        numberMaxValue={validation.values.subTotalRefundAmount}
                        field={processingDetails}
                        setFieldValue={setProcessingDetails}
                        onChangeFunctionWithValueReturn={(refundToCustomerTemp)=> {
                          onChangeRefundToCustomer(validation.values.subTotalRefundAmount, refundToCustomerTemp);
                        }}
                        blnDisableMin={true}
                        blnNumberOnlyWithDecimal={true}
                        disabled={disabledFieldInput || blnAccountReviseRequiredStatus}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <GeneralInputForModal
                        title={intl.formatMessage({ id: "ProcessingFees" })}
                        name="processingFee"
                        type="number"
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        numberMaxValue={processingDetails.refundToCustomer}
                        field={processingDetails}
                        setFieldValue={setProcessingDetails}
                        onChangeFunctionWithValueReturn={(processingFeeTemp)=> {
                          onChangeProcessingFees(processingFeeTemp);
                        }}
                        blnDisableMin={true}
                        blnNumberOnlyWithDecimal={true}
                        disabled={disabledFieldInput || blnAccountReviseRequiredStatus}
                      />
                    </SingleColumnRowParent>
                    <Row>
                      <DoubleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "CustomerEntitle" })}
                          name="customerEntitledAmount"
                          type="number"
                          inputGroup={true}
                          inputGroupText={returnCurrency()}
                          disabled={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "RefundSales" })}
                          name="refundSales"
                          type="number"
                          inputGroup={true}
                          inputGroupText={returnCurrency()}
                          disabled={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                    </Row>
                  </Col>
                  <Col xl={1}/>
                  {
                    validation.values.customerBranchName
                    &&
                    <Col>
                      <Label className="margin-bottom-0">{intl.formatMessage({ id: "SalesToDeduct" })}</Label>
                      <p className="mb-0">
                        <span>
                          {validation.values.customerBranchName}
                        </span>
                        <span className="margin-left-32">
                          {returnPriceWithCurrency(validation.values.customerEntitledAmount / (validation.values.customerSubBranchName ? 2 : 1))}
                        </span>
                      </p>
                      {
                        validation.values.customerSubBranchName
                        &&
                        <p>
                          <span>
                            {validation.values.customerSubBranchName}
                          </span>
                          <span className="margin-left-32">
                            {returnPriceWithCurrency(validation.values.customerEntitledAmount/2)}
                          </span>
                        </p>
                      }
                    </Col>
                  }
                </Row>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "AdditionalInformation" })}
                  className={`fw-bold margin-top-36`}
                  blnSkipMarginTop={true}
                  titleSize="16px"
                  paddingBottom="12px"
                />
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Remark" })}
                    name="remark"
                    row={5}
                    disabled={disabledFieldInput || blnAccountReviseRequiredStatus}
                    validation={validation}/>
                </SingleColumnRowParent>
                {
                  validation.values.reviewRemark
                  &&
                  <SingleColumnRowParent>
                    <GeneralTextArea
                      title={intl.formatMessage({ id: "Review" })}
                      name="reviewRemark"
                      row={5}
                      disabled={true}
                      validation={validation}/>
                  </SingleColumnRowParent>
                }
                {
                  blnAccountReviseRequiredStatus
                  &&
                  <>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "FinanceUsedOnly" })}
                      className={`fw-bold margin-top-36`}
                      blnSkipMarginTop={true}
                      titleSize="16px"
                      paddingBottom="12px"
                    />
                    <SingleColumnRowParent>
                      <GeneralTextArea
                        title={intl.formatMessage({ id: "Remark" })}
                        name="financeRemark"
                        row={5}
                        disabled={true}
                        validation={validation}/>
                    </SingleColumnRowParent>
                    <SingleColumnRowParent>
                      <GeneralAttachment
                        title={intl.formatMessage({ id: "TransactionSlips" })}
                        name="aryFinanceTransactionAttachments"
                        className=""
                        validation={validation}
                        blnMultipleFile={true}
                        disabled={true}/>
                    </SingleColumnRowParent>
                  </>
                }
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              className="mt-2"
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={!validation.values.status || validation.values.status === Constants.draft}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", Constants.save)
              }}
              hidePrimaryButton={false}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", Constants.submit)
              }}
              extraLeftDirtyDisabled={true}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        creditNotesModal
        &&
        <CreditNotesModal
          blnShow={creditNotesModal}
          setModal={setCreditNotesModal}
          customerId={validation.values.customerId}
          selectedCreditNoteDetails={validation.values.creditNoteDetails || []}
          validation={validation}
          blnPermissionManageCreditNote={blnPermissionManageCreditNote}/>
      }
    </div>
  );
};

export default observer(RefundNoteDetail);
