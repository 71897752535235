import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName, newSpaceBeforeCapitalLetter, purchaseDeliverOrderStatusColor, purchaseOrderStatusColor
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import moment from "moment";
import GeneralInput from "../../app/components/form/GeneralInput";
import { PurchaseDeliverOrderDetailObject, PurchaseDeliverOrderObject } from "../../app/models/purchaseDeliverOrder";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { BatchDetailObject } from "../../app/models/purchaseOrder";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const PurchaseDeliverOrderDetail = () => {
  //Use ParamsProfile
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { purchaseDeliverOrderStore, purchaseOrderStore, warehouseStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addPurchaseDeliverOrder, updatePurchaseDeliverOrder, purchaseDeliverOrderDetail, setPurchaseDeliverOrderDetail, getPurchaseDeliverOrderWithId } = purchaseDeliverOrderStore;
  const { purchaseOrderDropDownList, getPurchaseOrderDropdown, getPurchaseOrderWithId, getPurchaseOrderWarehouseList } = purchaseOrderStore;
  const { warehouseDropdownList, getWarehouseDropdown } = warehouseStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })} ${intl.formatMessage({ id: "PurchaseDeliverOrder" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PurchaseDeliverOrder" }), urlPath: RoutesList.purchaseDeliverOrder }];
  const [activeTab, setActiveTab] = useState(1);
  const [purchaseOrderModal, setPurchaseOrderModal] = useState(false);
  const [loadingPurchaseOrder, setLoadingPurchaseOrder] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: purchaseDeliverOrderDetail || {
      purchaseOrderId: "",
      purchaseOrderNo: "",
      warehouseId: "",
      warehouseName: "",
      purchaseDeliverOrderDetails: [],
      status: "",
      workflowAction: "",
      supplierName: "",
      supplierContactNo: "",
      receiverName: "",
      receiverContactNo: "",
      supplierType: addAction ? "Warehouse" : ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (purchaseDeliverOrderDetailsValidation()) {
        return;
      }

      let purchaseDeliverOrderDetailsTemp : any = [];
      valuesTemp.purchaseDeliverOrderDetails.map((valuePurchaseDeliverOrderDetailsTemp)=> {
        valuePurchaseDeliverOrderDetailsTemp.batchDetails.map((valueBatchDetailsTemp)=> {
          purchaseDeliverOrderDetailsTemp.push({
            productId: valuePurchaseDeliverOrderDetailsTemp.productId,
            quantity: valueBatchDetailsTemp.quantity,
            receivedQuantity: blnAcknowledgedStatus ? valueBatchDetailsTemp.receivedQuantity : 0,
            remark: valueBatchDetailsTemp.remark || "",
            warehouseProductPricingBatchId: valueBatchDetailsTemp.warehouseProductPricingBatchId
          })
        })      
      })

      valuesTemp.purchaseDeliverOrderDetails = purchaseDeliverOrderDetailsTemp;
      try {
        if (addAction) {
          await addPurchaseDeliverOrder(valuesTemp);
        } else {
          await updatePurchaseDeliverOrder(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || (validation.values.status !== Constants.pending && validation.values.status !== Constants.acknowledged && validation.values.status !== Constants.pendingAllocation && !addAction);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnProcessingStatus = validation.values.status == Constants.processing;
  const blnAcknowledgedStatus = validation.values.status == Constants.acknowledged;
  const blnPartialReceivedStatus = validation.values.status == Constants.partialReceived;
  const blnReceivedStatus = validation.values.status == Constants.received;
  const blnCancelledStatus = validation.values.status == Constants.cancelled;
  const blnPendingAllocationStatus = validation.values.status == Constants.pendingAllocation;
  const blnOutlet = validation.values.supplierType === Constants.outlet;
  const blnPermissionUpdatePurchaseDeliverOrder = checkPermission([PermissionConstants.UpdatePurchaseDeliverOrder]);
  const blnPermissionReviewPurchaseDeliverOrder = checkPermission([PermissionConstants.ReviewPurchaseDeliverOrder]);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "productName",
      inputType: "text",
    },
    {
      label:  blnOutlet ? intl.formatMessage({ id: "RequestQuantity" }).toUpperCase() : intl.formatMessage({ id: "PurchaseOrderRemainingQuantity" }).toUpperCase() ,
      type: "input",
      xl: "3",
      inputName: "remainingQuantity",
      inputType: "number",
      disabled: true,
    },
    {
      label: `${intl.formatMessage({ id: "BatchDetails" }).toUpperCase()}`,
      type: "dynamicRowContent",
      xl: "12",
      contentXL: "12",
      inputName: "batchDetails",
      inputType: "row",
      rowValue: [
        {
          label: intl.formatMessage({ id: "WarehouseReferenceNo" }).toUpperCase(),
          type: "label",
          xl: "3",
          inputName: "warehouseReferenceNo",
          inputType: "text",
          disabled: true,
        },
        {
          label: intl.formatMessage({ id: "ExpiryDate" }).toUpperCase(),
          type: "label",
          xl: "2.5",
          inputName: "expiryDate",
          inputType: "text",
        },
        {
          label: intl.formatMessage({ id: "WarehouseCurrentQuantity" }).toUpperCase(),
          type: "input",
          xl: "2",
          inputName: "warehouseQuantity",
          inputType: "text",
          disabled: true,
        },
        {
          label: `${intl.formatMessage({ id: "Quantity" }).toUpperCase()}`,
          type: "input",
          xl: "2",
          inputName: "quantity",
          inputType: "number",
          validationRequired: true,
          numberMaxFieldName: "warehouseQuantity"
        },
        {
          label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
          type: "input",
          xl: "4",
          inputName: "remark",
          inputType: "text",
        }
      ],
      rowButtonTitle: "",
      disabled: true
    },
  ];

  const aryDynamicInputAndCheckBoxAcknowledgementTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: `${intl.formatMessage({ id: "BatchDetails" }).toUpperCase()}`,
      type: "dynamicRowContent",
      xl: "12",
      contentXL: "12",
      inputName: "batchDetails",
      inputType: "row",
      rowValue: [
        {
          label: intl.formatMessage({ id: "WarehouseReferenceNo" }).toUpperCase(),
          type: "label",
          xl: "3",
          inputName: "warehouseReferenceNo",
          inputType: "text",
          disabled: true,
        },
        {
          label: intl.formatMessage({ id: "ExpiryDate" }).toUpperCase(),
          type: "label",
          xl: "2.5",
          inputName: "expiryDate",
          inputType: "text",
        },
        {
          label: `${intl.formatMessage({ id: "Quantity" }).toUpperCase()}`,
          type: "input",
          xl: "2",
          inputName: "quantity",
          inputType: "number",
          numberMaxFieldName: "warehouseQuantity",
          disabled: true
        },
        {
          label: `${intl.formatMessage({ id: "ReceivedQuantity" }).toUpperCase()}`,
          type: "input",
          xl: "2",
          inputName: "receivedQuantity",
          inputType: "number",
          validationRequired: true,
          numberMaxFieldName: "quantity"
        },
        {
          label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
          type: "input",
          xl: "4",
          inputName: "remark",
          inputType: "text",
        }
      ],
      rowButtonTitle: "",
      disabled: true
    },
  ];

  //Table Content UI
  const PurchaseOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "purchaseOrderNo",
      text: `${intl.formatMessage({ id: "PurchaseOrderNo" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "RequestedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.requestedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "purchaseFromSupplierName",
      text: intl.formatMessage({ id: "PurchaseFrom" }).toUpperCase(),
    },
    {
      dataField: "receiverName",
      text: intl.formatMessage({ id: "Receiver" }).toUpperCase(),
    },
    {
      dataField: "purchasePaymentMethod",
      text: intl.formatMessage({ id: "PurchasePaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.purchasePaymentMethod)}</div>,
    },
    {
      dataField: "purchaseOrderStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.purchaseOrderStatus}
          statusColorFunction={purchaseOrderStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({id: "Select"})}/>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    async function fetchPurchaseDeliverOrderDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getPurchaseOrderDropdown({ orderTypes: "0, 1, 2", dropDownModule: "1", statuses: "1,3" }),
        getWarehouseDropdown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getPurchaseDeliverOrderWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPurchaseDeliverOrderDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePurchaseDeliverOrder, PermissionConstants.ReviewPurchaseDeliverOrder], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePurchaseDeliverOrder], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePurchaseDeliverOrder], true)) {
        return;
      }
    }

    if(viewAction && (blnPermissionUpdatePurchaseDeliverOrder || blnPermissionReviewPurchaseDeliverOrder)){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.purchaseDeliverOrder}/edit/${id}`)} })
    }

    fetchPurchaseDeliverOrderDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPurchaseDeliverOrderDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (purchaseDeliverOrderDetail) {
      if (purchaseDeliverOrderDetail.purchaseDeliverOrderDetails) {
        let purchaseDeliverOrderDetailsTemp = purchaseDeliverOrderDetail.purchaseDeliverOrderDetails.map((value)=> {
          let batchDetailsTemp = value.purchaseDeliverOrderBatches.map((valueBatches)=> ({
            ...valueBatches,
            warehouseQuantity: valueBatches.allowedQuantity,
            expiryDate : moment(valueBatches.expiryDate).format(Constants.displayDateFormat),
            receivedDate : moment(valueBatches.receivedDate).format(Constants.displayDateFormat),
          }))

          return ({
            ...value,
            batchDetails: batchDetailsTemp
          })
        })
        validation.setFieldValue("purchaseDeliverOrderDetails", purchaseDeliverOrderDetailsTemp);
      }
    }
  }, [purchaseDeliverOrderDetail]);

  const purchaseDeliverOrderDetailsValidation = () => {
    let blnEmptyAdditionalQuantity = false;
    let blnMismatchRowNumber = false;
    let blnMismatchRowAcknowledgementNumber = false;

    validation.values.purchaseDeliverOrderDetails.map((purchaseDeliverOrderTemp) => {
      let totalAdditionalQuantity = 0;
      let purchaseOrderQuantity = purchaseDeliverOrderTemp.remainingQuantity;

      if (purchaseDeliverOrderTemp.batchDetails) {
        purchaseDeliverOrderTemp.batchDetails.map((batchDetailsTemp) => {
          if (!blnAcknowledgedStatus) {
            totalAdditionalQuantity += batchDetailsTemp.quantity;
            if (!batchDetailsTemp.quantity && batchDetailsTemp.quantity !== 0) {
              blnEmptyAdditionalQuantity = true;
            }
          }
          else {
            if (!batchDetailsTemp.receivedQuantity && batchDetailsTemp.receivedQuantity !== 0) {
              blnEmptyAdditionalQuantity = true;
            }
          }
        })
      } 

      if (!blnAcknowledgedStatus) {
        if (purchaseOrderQuantity < totalAdditionalQuantity && purchaseDeliverOrderTemp.batchDetails.length > 0) {
          blnMismatchRowNumber = true;
        }
      }
    })

    if (blnEmptyAdditionalQuantity) {
      setErrorMessage("Amount of batch details quantity could not be empty")
    }
    else if (blnMismatchRowNumber) {
      setErrorMessage(`Quantity could not exceed request quantity`)
    }
    else if (blnMismatchRowAcknowledgementNumber) {
      setErrorMessage(`Received Quantity could not exceed request quantity`)
    }

    return  blnEmptyAdditionalQuantity || blnMismatchRowNumber;
  }

  const onChangePurchaseOrderModal = (row) => {
    validation.setFieldValue("purchaseOrderId", row.id);
    validation.setFieldValue("purchaseOrderNo", row.purchaseOrderNo);
    setPurchaseOrderModal(false);
    onChangePurchaseOrder(row.id);
  }

  const onChangePurchaseOrder = (purchaseOrderId) => {
    try {
      if (purchaseOrderId && validation.values.warehouseId) {
        fetchPurchaseDeliverOrderWarehouseAPI(purchaseOrderId, validation.values.warehouseId)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }

  const onChangeWarehouse = (warehouseId) => {
    try {
      if (validation.values.purchaseOrderId && warehouseId) {
        fetchPurchaseDeliverOrderWarehouseAPI(validation.values.purchaseOrderId, warehouseId)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }

  const fetchPurchaseDeliverOrderWarehouseAPI = async (purchaseOrderId: string, warehouseId: string, purchaseDeliverOrderDetailsTemp?: PurchaseDeliverOrderObject[]) => {
    setLoadingPurchaseOrder(true);
    try {
      let purchaseOrderDetailObject = await getPurchaseOrderWarehouseList({ id: purchaseOrderId, warehouseId: warehouseId });
      if (purchaseOrderDetailObject) {
        let purchaseDeliverOrderDetailsNew = purchaseOrderDetailObject.purchaseOrderDetails.map((value) => {
          // if (purchaseDeliverOrderDetailsTemp) {
          //   purchaseDeliverOrderDetailsTemp = purchaseDeliverOrderDetailsTemp.map((valuePurchaseOrderDetailTemp) => {
          //     if (valuePurchaseOrderDetailTemp.warehouseProductPricingBatchId === value.warehouseProductPricingBatchId) {
          //       return ({ ...valuePurchaseOrderDetailTemp, productName: value.productName!.concat(`(${value.warehouseSupplierReferenceNo})`) })
          //     }
          //     return valuePurchaseOrderDetailTemp;
          //   })
          // }

          let batchDetailsTemp : BatchDetailObject[] = [];
          
          if (value.batchDetails) {
            batchDetailsTemp = value.batchDetails.map((valueBatchDetailsTemp) => {
              return (
                {
                  ...valueBatchDetailsTemp,
                  expiryDate : moment(valueBatchDetailsTemp.expiryDate).format(Constants.displayDateFormat),
                  receivedDate : moment(valueBatchDetailsTemp.receivedDate).format(Constants.displayDateFormat),
                  warehouseQuantity: valueBatchDetailsTemp.quantity,
                  quantity: 0
                }
              )
            })
          }

          let purchaseOrderDetailContent: any = {
            productId: value.productId,
            productName: value.productName,
            remainingQuantity: value.remainingQuantity,
            remark: value.remark,
            warehouseProductPricingBatchId: value.warehouseProductPricingBatchId,
            batchDetails: batchDetailsTemp
          }
          if (!addAction) {
            purchaseOrderDetailContent = {
              ...purchaseOrderDetailContent,
              id: value.id
            }
          }
          return purchaseOrderDetailContent;
        })

        if (!purchaseDeliverOrderDetailsTemp) {
          validation.setFieldValue("purchaseDeliverOrderDetails", purchaseDeliverOrderDetailsNew)
        } else {
          validation.setFieldValue("purchaseDeliverOrderDetails", purchaseDeliverOrderDetailsTemp)
        }
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
    setLoadingPurchaseOrder(false);
  }

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisableTab = () => {
    if (blnTab1) {
      if ((addAction && !validation.values.purchaseOrderId) || (!blnOutlet && !validation.values.warehouseId)) {
        return true;
      }
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}`}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {`${intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}`}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={addAction ? 12 : 6}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent 
                          blnDoubleTab={!addAction}>
                          <GeneralInput
                            name={"purchaseOrderNo"}
                            title={intl.formatMessage({ id: "PurchaseOrder" })}
                            className="mb-3 mt-4"
                            classNameInput="width-100-percentage"
                            type="text"
                            disabled={true}
                            validation={validation}
                            validationRequired={true}
                            childrenUI={
                              addAction
                              &&
                              <MyButton
                                type="button"
                                content={"Select"}
                                class="btn btn-primary margin-left-8"
                                onClick={() => { setPurchaseOrderModal(true) }}
                                loading={loadingPurchaseOrder}
                                disable={disabledFieldInput || viewAction || loadingPurchaseOrder || !addAction}
                              />
                            }/>
                        </SingleColumnRowParent>
                        {
                          !blnPendingAllocationStatus &&
                          <SingleColumnRowParent blnDoubleTab={!addAction}>
                            <DropDownWithTitle
                              name={"warehouseId"}
                              title={intl.formatMessage({ id: "Warehouse" })}
                              specifyReturnFieldName={[
                                {
                                  field: "warehouseId",
                                  value: "id",
                                },
                                {
                                  field: "warehouseName",
                                  value: "name",
                                }
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={warehouseDropdownList}
                              disabled={disabledFieldInput || viewAction || blnAcknowledgedStatus || !addAction}
                              onChangeFunction={onChangeWarehouse}
                              initialLabel={!addAction ? validation.values.warehouseName : undefined}
                              initialValue={!addAction ? validation.values.warehouseId : undefined}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        }
                        <SingleColumnRowParent blnDoubleTab={!addAction}>
                          <GeneralTextArea
                            title="Note"
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>

                      </Col>
                      {
                        !addAction &&
                        <Col xl={6}>
                          <LineBreakWithTittle
                            paddingBottom="0px"
                            title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Courier" }) })}
                            h4Title
                          />
                          <SingleColumnRowParent blnDoubleTab={!addAction}>
                            <GeneralInput
                              title={intl.formatMessage({ id: "Supplier" })}
                              name="supplierName"
                              className="mb-3 mt-4"
                              type="text"
                              disabled={disabledFieldInput || viewAction || !addAction}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                          <SingleColumnRowParent blnDoubleTab={!addAction}>
                            <GeneralInput
                              title={intl.formatMessage({ id: "SupplierContactNo" })}
                              name="supplierContactNo"
                              type="number"
                              blnNumberOnly={true}
                              disabled={disabledFieldInput || viewAction || !addAction}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                          <SingleColumnRowParent blnDoubleTab={!addAction}>
                            <GeneralInput
                              title={intl.formatMessage({ id: "Receiver" })}
                              name="receiverName"
                              type="text"
                              disabled={disabledFieldInput || viewAction || !addAction}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                          <SingleColumnRowParent blnDoubleTab={!addAction}>
                            <GeneralInput
                              title={intl.formatMessage({ id: "ReceiverContactNo" })}
                              name="receiverContactNo"
                              type="number"
                              blnNumberOnly={true}
                              disabled={disabledFieldInput || viewAction || !addAction}
                              validationRequired={true}
                              validation={validation}
                            />
                          </SingleColumnRowParent>
                        </Col>
                      }
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="purchaseDeliverOrderDetails"
                        title=""
                        buttonTitle={""}
                        blnEditable={!viewAction}
                        hideAddButton={true}
                        hideDeleteButton={true}
                        loading={loadingPurchaseOrder}
                        aryDynamicInputAndCheckBoxTab={blnAcknowledgedStatus ? aryDynamicInputAndCheckBoxAcknowledgementTab : aryDynamicInputAndCheckBoxTab}
                        data={validation.values.purchaseDeliverOrderDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {
                      blnTab2 && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {
                      blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisableTab()
                        }
                        loading={
                          validation.isSubmitting || loadingPurchaseOrder
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-3" />
            <div className="flex-direction-row">
              {!addAction && !Boolean(successMessage) && blnTab2 && !blnReceivedStatus && !blnPartialReceivedStatus && !blnCancelledStatus && !blnPendingAllocationStatus && !viewAction && blnPermissionUpdatePurchaseDeliverOrder &&
                <MyButton
                  type="submit"
                  class={`btn btn-warning margin-right-8`}
                  content={Constants.cancel}
                  disable={
                    validation.isSubmitting
                  }
                  loading={
                    validation.isSubmitting
                  }
                  onClick={() => {
                    validation.setFieldValue("workflowAction", Constants.cancel)
                  }}
                />
              }
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={(blnProcessingStatus || blnAcknowledgedStatus) ? false : blnPendingAllocationStatus && blnPermissionReviewPurchaseDeliverOrder ? true : blnPermissionUpdatePurchaseDeliverOrder}
                extraLeftButtonTitle={blnPendingAllocationStatus ? intl.formatMessage({ id: "Reject" }) : intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor={blnPendingAllocationStatus ? "btn-danger" : "btn-primary"}
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", blnPendingAllocationStatus ? Constants.reject : Constants.save)
                }}
                extraLeftDirtyDisabled={true}
                hidePrimaryButton={blnTab1 ? true : blnPendingAllocationStatus && blnPermissionReviewPurchaseDeliverOrder ? false : !blnPendingAllocationStatus && blnPermissionUpdatePurchaseDeliverOrder ? false : true}
                submitButtonTitle={blnProcessingStatus ? Constants.acknowledge : blnAcknowledgedStatus ? Constants.receive : blnPendingAllocationStatus ? Constants.approve : Constants.submit}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", blnProcessingStatus ? Constants.acknowledge : blnAcknowledgedStatus ? Constants.receive : blnPendingAllocationStatus ? Constants.approve : Constants.submit)
                }}
              />
            </div>
          </Form>
        )}
        <SelectWithTableListModal
          blnShow={purchaseOrderModal}
          setModal={setPurchaseOrderModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "PurchaseOrder" }) })}
          options={purchaseOrderDropDownList}
          keyField={"id"}
          fieldName={"purchaseOrderId"}
          onSelectFunction={onChangePurchaseOrderModal}
          columns={PurchaseOrderColumns}
          validation={validation}/>
      </DetailViewLayout>
    </div >
  );
};

export default observer(PurchaseDeliverOrderDetail);
