import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, paymentStatusColor, returnPriceWithCurrency, purchasedItemStatusColor, salesOrderStatusColor, displayPromotionCode, displayWatermark, escapedNewLineToLineBreakTag, deliveryOrderStatusColor, removeAllSpace, getBranchName, appolousSalesOrderStatusColor } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../../app/components/layout/DetailViewLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { history } from "../../..";
import TableActionButton from "../../../app/components/table/TableActionButton";
import Borderline from "../../../app/components/form/BorderLine";
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { burgerMenu } from "../../../app/models/common";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import AppolousCustomerSalesPaymentModal from "./Modal/AppolousCustomerSalesPaymentModal";
import { AppolousPaymentObject, AppolousSalesPaymentDetailObject, AppolousSalesUpdatePaymentObject } from "../../../app/models/appolousCustomerSales";
import PencilBoxOutlineIcon from 'mdi-react/PencilBoxOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';

const AppolousCustomerSalesDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { branchStore, staticReferenceStore, paymentMethodOptionStore, settingsStore, appolousCustomerSalesStore, employeeStore, commonStore } = useStore();
  const { windowSize, successMessage, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { appolousCustomerSalesDetail, setAppolousCustomerSalesDetail, getAppolousCustomerSalesDetail, appolousSubmitAdditionalPayment, appolousUpdateAdditionalPayment, voidAppolousCustomerSalesPayment, voidAppolousCustomerSales } = appolousCustomerSalesStore;
  const { branchPaymentMethod, getBranchPaymentMethod, setBranchPaymentMethod } = branchStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const { salesSettings, getSalesSettings } = settingsStore;
  const { getEmployeeDropdown } = employeeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "AppolousCustomerSales" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: appolousCustomerSalesDetail?.customerName || "", urlPath: `${RoutesList.customer}/view/${appolousCustomerSalesDetail?.customerId}`}];

  const appolousCustomerSalesFullDetail = appolousCustomerSalesDetail || {
    appolousSaleId: "",
    performaInvoiceNo: "",
    salesCategoryName: "",
    subTotal: 0,
    total: 0,
    balance: 0,
    note: "",
    status: "",
    branchId: "",
    branchName: "",
    customerId: "",
    customerName: "",
    createdAt: "",
    appolousId: 0,
    advisableProductCommission: 0,
    isVoidAllowed: false,
    consultants: [{
      consultantId: "",
      consultantName: "",
      appolousSalesId: 0,
      appolousConsultantId: 0,
    }],
    appolousSaleDetails: [{
      appolousSaleDetailId: "",
      itemType: "",
      itemId: "",
      itemName: "",
      description: "",
      quantity: 0,
      price: 0,
      discount: 0,
      appolousId: 0,
      appolousItemId: 0,
      total: 0,
    }],
    appolousPayments: [{
      appolousPaymentId: "",
      paidAmount: 0,
      last4CardDigit: 0,
      last4CardDigitNo: "",
      cardType: "",
      transactionNo: "",
      optionType: "",
      paymentMethodId: "",
      paymentMethodName: "",
      branchId: "",
      branchName: "",
      createdAt: "",
      appolousId: "",
      isVoidAllowed: false,
      isEditAllowed: false,
      packageSaleAmount: 0,
      productSaleAmount: 0,
      productCommission: 0
    }],
    productRedemptions: [{
      appolousCustomerProductRedemptionId: "",
      branchName: "",
      itemName: "",
      quantity: 0,
      remark: "",
      consultantName: "",
      createdAt: "",
    }],
    branch: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryName: "",
      stateName: "",
    },
    customer: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryName: "",
      stateName: "",
      mobileNo: "",
    },
  }
  const [selectedInvoicePaymentIndex, setSelectedInvoicePaymentIndex] = useState<number | undefined>(undefined);
  const [appolousCustomerSalesPaymentModal, setAppolousCustomerSalesPaymentModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [additionalPayment, setAdditionalPayment] = useState<AppolousSalesPaymentDetailObject[]>([])
  const [totalDraftPayment, setTotalDraftPayment] = useState(0);
  const blnHQStaff = !getBranchUser();
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionCreateAppolousSales = checkPermission([PermissionConstants.CreateAppolousSales]);
  const blnPermissionUpdateAppolousSales = checkPermission([PermissionConstants.UpdateAppolousSales]);
  const blnPermissionDeleteAppolousSales = checkPermission([PermissionConstants.DeleteAppolousSales]);
  const blnPermissionDeleteAppolousPayment = checkPermission([PermissionConstants.DeleteAppolousPayment]);
  const [voidAppolousSalesPaymentModal, setVoidAppolousSalesPaymentModal] = useState(false);
  const [selectedAppolousPaymentId, setSelectedAppolousPaymentId] = useState<any>(undefined);
  const [selectedAppolousPayment, setSelectedAppolousPayment] = useState<any>(undefined);
  const [viewType, setViewType] = useState(blnHQStaff ? Constants.fullVersion : Constants.customerVersion);
  const [burgerMenuListContent, setBurgerMenuListContent] = useState<burgerMenu[]>([]);
  const [isLastestPayment, setIsLastestPayment] = useState(false);
  const [voidAppolousSalesModal, setVoidAppolousSalesModal] = useState(false);
  const blnValidBranch = appolousCustomerSalesFullDetail.branchId === getBranchId();

  const ProductRedemptionColumns = [
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.createdAt).format(Constants.displayDateAndTimeFormat)}`}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "itemName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "RedeemedQty" }).toUpperCase(),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.remark || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "PerformBy" }).toUpperCase(),
    },
  ];

  const validationForAddPayment = useFormik({
    enableReinitialize: true,
    initialValues: {
      branchName: getBranchName(),
      packageSaleAmount: 0,
      productSaleAmount: 0,
      productCommission: 0,
      subBrandId: "",
      subBrandName: "",
      submissionDate: "",
      isBackdate: false,
      consultantIds: []
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchAppolousCustomerSalesDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getAppolousCustomerSalesDetail(id),
        getSalesSettings(),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageAppolousSales], true)) {
      return;
    }
    fetchAppolousCustomerSalesDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setAppolousCustomerSalesDetail(undefined)
        setBranchPaymentMethod([]);
      }
    })
  }, []);

  useEffect(() => {
    if(appolousCustomerSalesDetail){
      let indexViewAs = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewAs" })}`})
      if (indexViewAs < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewAs" })}`, 
          onFunction: () => {},
          blnSubTitle: true
        })
      }
      
      let indexViewFullVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewFullVersion" })}`})
      if (indexViewFullVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewFullVersion" })}`, 
          onFunction: () => {
            setViewType(Constants.fullVersion);
          },
          blnSubView: true
        })
      }

      let indexViewCustomerVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`})
      if (indexViewCustomerVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerVersion)
          },
          blnSubView: true
        })
      }
      
      let indexViewCustomerWithoutQuantityVersion = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`})
      if (indexViewCustomerWithoutQuantityVersion < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ViewCustomerWithoutQuantityVersion" })}`, 
          onFunction: () => { 
            setViewType(Constants.customerWithoutQuantityVersion)
          },
          blnSubView: true
        })
      }
    }
  }, [appolousCustomerSalesDetail])

  useEffect(() => {
    let totalDraftPaymentTemp = 0;
    additionalPayment.map((value) => {
      totalDraftPaymentTemp += Number(value.paidAmount)
    })
    setTotalDraftPayment(totalDraftPaymentTemp);
  }, [additionalPayment])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        if(appolousCustomerSalesPaymentModal){
          setAppolousCustomerSalesPaymentModal(false);
        }
        if (voidAppolousSalesModal) {
          setVoidAppolousSalesModal(false);
        }
        history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${id}`);
        fetchAppolousCustomerSalesDetailAPI();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const paidStatus = appolousCustomerSalesFullDetail.status === Constants.paid;
  const voidedStatus = appolousCustomerSalesFullDetail.status === Constants.voided;
  const blnAllowAddPayment = blnPermissionCreateAppolousSales && !paidStatus && !voidedStatus;
  const blnDraftPaymentExist = additionalPayment.length > 0;

  const fetchPaymentMethod = async () => {
    setLocalLoading(true);
    let aryAPI: any = [
      getBranchPaymentMethod(getBranchId(), true),
      getStaticReferenceWithType(Constants.cardType)
    ];
    await Promise.all(aryAPI);
    setAppolousCustomerSalesPaymentModal(true);
    setLocalLoading(false);
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <Col className="m-0 text-muted">
          {label}
        </Col>
        <Col className="text-muted">
          {value}
        </Col>
      </Row>
    )
  }

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr>
        {colSpan !== 0 ? <td colSpan={colSpan} /> : undefined}
        <td className="text-end" style={{ fontWeight: 'bold', width: colSpan === 0 ? '70%' : undefined }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  const onClickUpdateAppolousSalesPayment = async (updateAppolousSalesPaymentRequestBody: AppolousSalesUpdatePaymentObject) => {
		setLocalLoading(true);
		await appolousUpdateAdditionalPayment(updateAppolousSalesPaymentRequestBody);
		setLocalLoading(false);
	}

  const onClickVoidAppolousSalesPayment = async () => {
    setLocalLoading(true);
    let resultAPI = await voidAppolousCustomerSalesPayment(selectedAppolousPaymentId);
    if (resultAPI.status === Constants.success) {
      setVoidAppolousSalesPaymentModal(false);
    }
    setLocalLoading(false);
  }

  const returnEditAppolousPaymentButton = (appolousPaymentDetail: AppolousPaymentObject, key: string | number) => {
    let blnDisplayEditButton = !blnDraftPaymentExist && blnPermissionUpdateAppolousSales && appolousPaymentDetail.isEditAllowed && !Boolean(successMessage) && !localLoading && blnValidBranch;
    
    return(
      blnDisplayEditButton
      &&
      <div key={`edit-payment-${key}`}>
        &nbsp;&nbsp;
        <PencilBoxOutlineIcon  className="text-info" size={20} id={`editAppolousSalesPayment-${key}`}
          onClick={() => {
            validationForAddPayment.setValues((values) => ({
              ...values,
              branchName: appolousPaymentDetail.branchName,
              packageSaleAmount: appolousPaymentDetail.packageSaleAmount,
              productSaleAmount: appolousPaymentDetail.productSaleAmount,
              productCommission: appolousPaymentDetail.productCommission
            }))
            if(appolousCustomerSalesFullDetail.appolousPayments.length - 1 === key){
              setIsLastestPayment(true);
            }
            setSelectedAppolousPayment(appolousPaymentDetail);
            setAppolousCustomerSalesPaymentModal(true);                                       
          }}
        />
        <UncontrolledTooltip placement="top" target={`editAppolousSalesPayment-${key}`}>
          {intl.formatMessage({ id: "Edit" })}
        </UncontrolledTooltip>
      </div>
    )
  }

  const returnDeleteAppolousPaymentButton = (appolousPaymentDetail: AppolousPaymentObject, key: string | number) => {
    // let blnDisplayDeleteButton = !blnDraftPaymentExist && blnPermissionDeleteAppolousPayment && appolousPaymentDetail.isVoidAllowed && !appolousPaymentDetail.appolousId &&!Boolean(successMessage) && !localLoading;
    let blnDisplayDeleteButton = !blnDraftPaymentExist && blnPermissionDeleteAppolousPayment && appolousPaymentDetail.isVoidAllowed &&!Boolean(successMessage) && !localLoading && blnValidBranch;

    return(
      blnDisplayDeleteButton
      &&
      <div key={`delete-payment-${key}`}>
        &nbsp;
        <DeleteIcon className={"text-danger"} size={20} id={`voidAppolousSalesPayment-${key}`}
          onClick={() => {
            setSelectedAppolousPaymentId(appolousPaymentDetail.appolousPaymentId);
            setVoidAppolousSalesPaymentModal(true);
          }}
        />
        <UncontrolledTooltip placement="top" target={`voidAppolousSalesPayment-${key}`}>
          {intl.formatMessage({ id: "Delete" })}
        </UncontrolledTooltip>
      </div>
    )
  }

  const onClickMakePayment = async (additionalPaymentParams: AppolousSalesPaymentDetailObject[], blnIsAllowProductCommission) => {
    let additionalPaymentFinal = additionalPaymentParams || additionalPayment;
    // if (blnDraftPaymentExist) {
      let totalPayment = 0;
      let additionalPaymentTemp: any = [];

      additionalPaymentTemp = additionalPaymentFinal.map((valueAdditionalPaymentTemp) => {
        totalPayment += Number(valueAdditionalPaymentTemp.paidAmount)
        return ({
          ...valueAdditionalPaymentTemp,
          transactionNo: valueAdditionalPaymentTemp.transactionNo ? String(valueAdditionalPaymentTemp.transactionNo) : "",
        })
      })
      
      if (totalPayment > appolousCustomerSalesFullDetail.balance) {
        setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalBalance" }))
        return;
      }

      let consultantIdsTemp = validationForAddPayment.values.consultantIds.map((valueConsultantIdsTemp: any)=> valueConsultantIdsTemp.value);

      let objTemp = {
        appolousSaleId: appolousCustomerSalesFullDetail.appolousSaleId,
        branchId: getBranchId(),
        packageSaleAmount: validationForAddPayment.values.packageSaleAmount,
        productSaleAmount: validationForAddPayment.values.productSaleAmount,
        productCommission: blnIsAllowProductCommission ? validationForAddPayment.values.productCommission : 0,
        subBrandId: validationForAddPayment.values.subBrandId,
        payments: additionalPaymentTemp,
        isBackdate: validationForAddPayment.values.isBackdate,
        submissionDate: validationForAddPayment.values.submissionDate,
        consultantIds: consultantIdsTemp
      }

      setLocalLoading(true);
      let resultPaymentSuccess = await appolousSubmitAdditionalPayment(objTemp);
      setLocalLoading(false);
      if (resultPaymentSuccess && resultPaymentSuccess.status == Constants.success) {
        setAdditionalPayment([]);
      }
    // }
  }

  const onClickVoidAppolousSalesOrder = async () => {
    setLocalLoading(true);
    await voidAppolousCustomerSales(id)
    setLocalLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title margin-bottom-16 ">
                      <Row style={{ alignItems: 'center' }}>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "ProformaInvoice" })}
                            </span>
                            <br />
                            <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                              {appolousCustomerSalesFullDetail.branchName}
                            </h3>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {`${appolousCustomerSalesFullDetail.branch.addressLine1} ${appolousCustomerSalesFullDetail.branch.addressLine2 || ""} ${appolousCustomerSalesFullDetail.branch.city || ""} ${appolousCustomerSalesFullDetail.branch.postcode || ""}`}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {`${appolousCustomerSalesFullDetail.branch.stateName}`}
                            </h4>
                          </div>
                        </Col>
                        <Col xs="6" className="text-end text-muted">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '20px' , color: appolousSalesOrderStatusColor(removeAllSpace(appolousCustomerSalesFullDetail.status))}}>
                              {appolousCustomerSalesFullDetail.status === Constants.partial ? newSpaceBeforeCapitalLetter(Constants.partialPayment) : appolousCustomerSalesFullDetail.status}
                            </span>
                            <br />
                            <h3 className="margin-top-12 margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "InvoiceNo" })}. (${intl.formatMessage({ id: "Appolous" })}):`, 
                              appolousCustomerSalesFullDetail.appolousId
                              ?
                                <div className="text-primary margin-bottom-0 pointer-clickable" onClick={async ()=> {window.open(`https://app.appolous.com/sales/${appolousCustomerSalesFullDetail.appolousId}`)}}>{appolousCustomerSalesFullDetail.performaInvoiceNo}</div>
                              :
                                appolousCustomerSalesFullDetail.performaInvoiceNo
                              )}
                              </h3>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(appolousCustomerSalesFullDetail.createdAt).format(Constants.displayDateFormat))}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "Branch" })}:`, appolousCustomerSalesFullDetail.branchName)}
                            </h4>
                            <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                              {displayHeaderRowData(`${intl.formatMessage({ id: "Category" })}:`, appolousCustomerSalesFullDetail.salesCategoryName || Constants.emptyData)}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                      <Borderline/>
                      <Row>
                        <Col>
                          <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Client" })}:</strong>
                          <h3 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                           {
                            blnPermissionViewCustomer ?
                              <Link to={appolousCustomerSalesFullDetail.customerId ? `/${RoutesList.customer}/view/${appolousCustomerSalesFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{appolousCustomerSalesFullDetail.customerName || Constants.emptyData}</Label>
                              </Link>
                            :
                              `${appolousCustomerSalesFullDetail.customerName}`
                            }
                          </h3>
                          <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                            {`${appolousCustomerSalesFullDetail.customer.addressLine1} ${appolousCustomerSalesFullDetail.customer.addressLine2 ?? ""}`}
                          </h4>
                          <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                            {`${appolousCustomerSalesFullDetail.customer.city ? `${appolousCustomerSalesFullDetail.customer.city},`: ""} ${appolousCustomerSalesFullDetail.customer.postcode ? `${appolousCustomerSalesFullDetail.customer.postcode}`: ""}`}
                          </h4>
                          <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                            {`${appolousCustomerSalesFullDetail.customer.stateName}, ${appolousCustomerSalesFullDetail.customer.countryName}`}
                          </h4>
                          <h4 className="margin-bottom-0 font-weight-bold text-muted" style={{ fontSize: '14px', marginTop: '5px' }}>
                            {appolousCustomerSalesFullDetail.customer.mobileNo}
                          </h4>
                        </Col>
                        <Col>
                          <address>
                            {
                              appolousCustomerSalesFullDetail.consultants &&
                              appolousCustomerSalesFullDetail.consultants.length > 0 &&
                              <>
                                <strong className="margin-top-16 margin-bottom-2 font-size-16 ">{intl.formatMessage({ id: "Consultant" })}:</strong>
                                {
                                  appolousCustomerSalesFullDetail.consultants.map((value, index) => (
                                    <div key={`${value.consultantId}_${index}`}>
                                      <span className="margin-bottom-0 font-weight-bold" style={{ fontSize: '15px', marginTop: '5px' }}>
                                        <>{index + 1}. {value.consultantName}</>
                                      </span>
                                      {index !== appolousCustomerSalesFullDetail.consultants.length - 1 && <br />}
                                    </div>
                                  ))
                                }
                              </>
                            }
                          </address>
                        </Col>
                      </Row>
                    </div>
                    {/* watermark */}
                    {displayWatermark()}
                    <div className="table-responsive margin-top-36">
                      <Table className="table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "Name" })}</th>
                            <th className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{intl.formatMessage({ id: "Type" })}</th>
                            {<th className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? intl.formatMessage({ id: "Quantity" }) : ""}</th>}
                            {
                              viewType === Constants.fullVersion &&
                              <>
                                <th className="text-center">{intl.formatMessage({ id: "Price" })}</th>
                                <th className="text-center">{intl.formatMessage({ id: "Discount" })}</th>
                                <th className="text-end">{intl.formatMessage({ id: "Total" })}</th>
                              </>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            appolousCustomerSalesFullDetail.appolousSaleDetails.map((item, key) => (
                              <tr key={key}>
                                <td>{getPurchaseTypeIcon(item.itemType)}{item.itemName} </td>
                                <td className={viewType !== Constants.customerWithoutQuantityVersion ? "text-center" : ""}>{item.itemType}</td>
                                {<td className={viewType === Constants.fullVersion ? "text-center" : "text-end"}>{viewType !== Constants.customerWithoutQuantityVersion ? item.quantity : ""}</td>}
                                {
                                  viewType === Constants.fullVersion &&
                                  <>
                                    <td className="text-center">{returnPriceWithCurrency(item.price)}</td>
                                    <td className="text-center">{item.discountType === Constants.percentage ? `${item.discount}%` : item.discountType === Constants.amount ? returnPriceWithCurrency(item.discount) : '-'}</td>
                                    <td className="text-end">{returnPriceWithCurrency(item.total)}</td>
                                  </>
                                }
                              </tr>
                            ))
                          }
                          {
                            viewType === Constants.fullVersion
                            ?
                            <>
                              <tr>
                                <td colSpan={4} />
                                <td className="text-end" style={{ fontWeight: 'bold' }}>
                                  <div>
                                    {`${intl.formatMessage({ id: "SubTotal" })}`}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>
                                    {`${returnPriceWithCurrency(appolousCustomerSalesFullDetail.subTotal)}`}
                                  </div>
                                </td>
                              </tr>
                              {displayCustomRowData(4, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(appolousCustomerSalesFullDetail.total))}
                              {appolousCustomerSalesFullDetail.appolousPayments && appolousCustomerSalesFullDetail.appolousPayments.length > 0 &&
                                appolousCustomerSalesFullDetail.appolousPayments.map((item, key) => (                        
                                  <tr key={key} style={{borderTopStyle: key > 0 ? 'hidden' : 'none'}}>
                                    <td className="text-end" colSpan={5}>{intl.formatMessage({ id: "PaymentAtBranchOnDateWithPaymentMethod" }, {branchName: item.branchName, paymentDate: moment(item.createdAt).format(Constants.displayDateFormat), paymentMethodName: `${item.paymentMethodName} ${item.last4CardDigitNo !== Constants.emptyLast4CardDigitNo ? `(${item.last4CardDigitNo})` : item.transactionNo ? `(${item.transactionNo})` : item.paymentMethodOptionName ? `(${item.paymentMethodOptionName})` : ""}`})}</td>
                                    <td className="d-flex flex-right" >
                                      {returnPriceWithCurrency(item.paidAmount)}
                                      {returnEditAppolousPaymentButton(item, key)}
                                      {returnDeleteAppolousPaymentButton(item, key)}
                                    </td>
                                  </tr>
                                ))
                              }
                            </>
                            :
                            <>
                              {displayCustomRowData(1, `${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(appolousCustomerSalesFullDetail.total))}
                              {appolousCustomerSalesFullDetail.appolousPayments && appolousCustomerSalesFullDetail.appolousPayments.length > 0 &&
                                appolousCustomerSalesFullDetail.appolousPayments.map((item, key) => (                        
                                  <tr key={key} style={{borderTopStyle: key > 0 ? 'hidden' : 'none'}}>
                                    <td className="text-end" colSpan={2}>{intl.formatMessage({ id: "PaymentAtBranchOnDateWithPaymentMethod" }, {branchName: item.branchName, paymentDate: moment(item.createdAt).format(Constants.displayDateFormat), paymentMethodName: `${item.paymentMethodName} ${item.last4CardDigitNo !== Constants.emptyLast4CardDigitNo ? `(${item.last4CardDigitNo})` : item.transactionNo ? `(${item.transactionNo})` : item.paymentMethodOptionName ? `(${item.paymentMethodOptionName})` : ""}`})}</td>
                                    <td className="d-flex flex-right">
                                      {returnPriceWithCurrency(item.paidAmount)}
                                      {returnEditAppolousPaymentButton(item, key)}
                                      {returnDeleteAppolousPaymentButton(item, key)}
                                    </td>
                                  </tr>
                                ))
                              }
                              {
                                appolousCustomerSalesFullDetail.balance > 0
                                &&
                                displayCustomRowData(1, `${intl.formatMessage({ id: "Balance" }).toUpperCase()}`, returnPriceWithCurrency(appolousCustomerSalesFullDetail.balance))
                              }
                            </>
                          }
                        </tbody>
                      </Table>
                    </div>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Note" })} :
                      </h3>
                    </div>
                    <GeneralTextAreaForModal
                      title=""
                      name="note"
                      className="margin-top-0"
                      row={5}
                      disabled={true}
                      field={appolousCustomerSalesFullDetail}
                      setFieldValue={() => { }} />
                    <p className="text-center margin-top-16" style={{ fontSize: '14px', pageBreakInside: "avoid"}}>{salesSettings?.invoiceFooterNote ? escapedNewLineToLineBreakTag(salesSettings.invoiceFooterNote) : ""}</p>
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                    {
                        appolousCustomerSalesFullDetail.isVoidAllowed
                        &&
                        blnValidBranch
                        &&
                        blnPermissionDeleteAppolousSales
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-danger margin-left-8"
                          content={intl.formatMessage({ id: "VoidInvoice" })}
                          style={{ width: '150px' }}
                          loading={localLoading}
                          onClick={() => {
                            setVoidAppolousSalesModal(true);
                          }}
                          disable={Boolean(successMessage)}
                        />
                      }
                      {
                        //temporary allow for 1 payment only, due to commission calculation
                        blnAllowAddPayment
                        &&
                        !(blnDraftPaymentExist)
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-info margin-left-8"
                          content={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Payment" }) })}
                          style={{ width: '150px' }}
                          loading={localLoading}
                          onClick={() => {
                            if (branchPaymentMethod.length > 0 && cardType.length > 0) {
                              setAppolousCustomerSalesPaymentModal(true);
                            }
                            else {
                              fetchPaymentMethod();
                            }
                          }}
                          disable={Boolean(successMessage)}
                        />
                      }
                      {
                        !successMessage 
                        &&
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={true}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
              appolousCustomerSalesFullDetail.productRedemptions.length > 0 &&
              <Row className="d-print-none">
                <div>
                  <Col className="standard-layout">
                    <h5 className="mb-4">{intl.formatMessage({ id: "ProductRedemptions" })}</h5>
                    <PaginationTableWithoutApi 
                      title={intl.formatMessage({ id: "ProductRedemptions" })}
                      options={appolousCustomerSalesFullDetail.productRedemptions}
                      columns={ProductRedemptionColumns}
                      pageSize={5}
                      keyField={"appolousCustomerProductRedemptionId"}/>
                  </Col>
                </div>
              </Row>
            }
          </div>
        )}
        {
          appolousCustomerSalesPaymentModal &&
          <AppolousCustomerSalesPaymentModal
            blnShow={appolousCustomerSalesPaymentModal}
            setModal={setAppolousCustomerSalesPaymentModal}
            cardType={cardType}
            branchPaymentMethod={branchPaymentMethod}
            additionalPayment={additionalPayment}
            setAdditionalPayment={setAdditionalPayment}
            selectedInvoicePaymentIndex={selectedInvoicePaymentIndex}
            setSelectedInvoicePaymentIndex={setSelectedInvoicePaymentIndex}
            invoiceTotal={appolousCustomerSalesFullDetail.total}
            invoiceBalance={appolousCustomerSalesFullDetail.balance}
            advisableProductCommission={appolousCustomerSalesFullDetail.advisableProductCommission}
            validationForAddPayment={validationForAddPayment}
            getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}
            onClickUpdateAppolousSalesPayment={onClickUpdateAppolousSalesPayment}
            onClickMakePayment={onClickMakePayment}
            selectedAppolousPayment={selectedAppolousPayment}
            setSelectedAppolousPayment={setSelectedAppolousPayment}
            blnDisabledEditPaymentField={localLoading || Boolean(successMessage)}
            windowSize={windowSize}
            isLastestPayment={isLastestPayment}
            setIsLastestPayment={setIsLastestPayment}
            getEmployeeDropdown={getEmployeeDropdown}
          />
        }
        {
          voidAppolousSalesPaymentModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "VoidAppolousSalePaymentAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={voidAppolousSalesPaymentModal}
            setShowPrompt={setVoidAppolousSalesPaymentModal}
            onPrimaryClick={onClickVoidAppolousSalesPayment}
          />
        }
        {
          voidAppolousSalesModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "VoidAppolousSaleAlert"})}`}
            primaryActionTitle={`Yes`}
            showPrompt={voidAppolousSalesModal}
            setShowPrompt={setVoidAppolousSalesModal}
            onPrimaryClick={onClickVoidAppolousSalesOrder}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(AppolousCustomerSalesDetail);
