import React, { useState, useEffect } from "react";
import { Col, Form, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox, WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import { returnCurrency } from "../../../app/common/function/function";
import { CustomerImportFailedDetailObject } from "../../../app/models/customer";

interface Props {
  blnShow: boolean;
  setModal: Function;
  importedCustomerDetails: CustomerImportFailedDetailObject[];
  setImportedCustomerDetails: Function;
}

export default observer(function ImportCustomerModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore, customerStore } = useStore();
  const {
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;

  const hideModal = () => {
    props.setModal(false);
    props.setImportedCustomerDetails([]);
  }


  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "RowNumber" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "rowNumber",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "CustomerName" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "customerName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "ErrorMessage" }).toUpperCase(),
      type: "label",
      xl: "7",
      inputName: "errorCode",
      inputType: "text",
    }
  ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerDetails: props.importedCustomerDetails || []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      try {

      } finally {
        validationLocal.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = Boolean(successMessage) || validationLocal.isSubmitting;

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        style={{ width: "90%" }}
        isOpen={props.blnShow}
        toggle={() => {
          if (!validationLocal.isSubmitting && !Boolean(successMessage)) {
            hideModal();
          }
        }}
        size="xl"
        centered
      >
        {
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0 text-danger">{intl.formatMessage({ id: "ImportCustomerFailedWithError" })}</h5>
              </div>
              {
                !validationLocal.isSubmitting 
                && 
                !Boolean(successMessage)
                &&
                <button
                  type="button"
                  onClick={() => {
                    if (!disabledFieldInput) {
                      hideModal();
                    }
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
            <div className="modal-body">
                <LineBreakWithTittle
                  blnSkipMarginTop={true}
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Customers" }) })}
                />
                <TableWithEditableInputFields
                  name="customerDetails"
                  className="mb-3"
                  title=""
                  buttonTitle={""}
                  blnEditable={false}
                  aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                  data={validationLocal.values.customerDetails}
                  validation={validationLocal}
                  disabled={disabledFieldInput}
                  supportBackgroundColor={true}
                  hideAddButton={true}
                  hideDeleteButton={true} />
                <p className="text-danger">{intl.formatMessage({ id: "ReImportCustomerExcel" })}</p>
            </div>
          </div>
        }
      </Modal>
  )
})