import { useEffect, useState } from "react";
import { Col, Input, Label, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import GeneralInput from "../../../app/components/form/GeneralInput";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { arraySpliceInAllValue, checkPermission, firstDayOfCurrentMonthToLastDayOfCurrentMonth, firstDayOfFiveYearAgoToLastDayOfCurrentYear, returnSubBrandList } from "../../../app/common/function/function";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { observer } from 'mobx-react-lite';
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import { PaginationRequestBody } from "../../../app/models/pagination";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  options: any; //Dropdown data to display
  columns: any;
  keyField: string;
  fieldName: string;
  onSelectFunction?: Function;
  validation: any;
  totalItem?: number;
  requestAPI?: Function;
  paginationSubmitField?: any;
  branchId: string;
  appointmentDate: string;
}

export default observer(function SelectCustomerSalesOrderListModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  // const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface | undefined>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const [selectedSalesOrderStatus, setSelectedSalesOrderStatus] = useState<string>(AllStatus);
  const [selectedSalesOrderStatusId, setSelectedSalesOrderStatusId] = useState<number | string | undefined>(" ");
  const [salesOrderStatusList, setSalesOrderStatusList] = useState<StaticReferenceDataObject[]>([]);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState<string>(AllStatus);
  const [selectedPaymentStatusId, setSelectedPaymentStatusId] = useState<number | string | undefined>(" ");
  const [paymentStatusList, setPaymentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: props.appointmentDate, toDate: props.appointmentDate});
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>("");

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerSalesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerSalesOrderRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    // salesOrderStatus: selectedSalesOrderStatusId,
    branchId: props.branchId,
    // customerId: selectedCustomerId,
    // customerName: selectedCustomer,
    // salesOrderStatusName: selectedSalesOrderStatus,
    // customerSalesOrderNo: validationLocal.values.customerSalesOrderNo,
    // paymentStatus: selectedPaymentStatusId,
    // paymentStatusName: selectedPaymentStatus,
    // subBrandName: selectedSubBrandName,
    // subBrandId: selectedSubBrandId,
    ...selectedDateRange && {dateRange: selectedDateRange}
  }

  const [customerSalesOrderRequestBodyAfterApply, setCustomerSalesOrderRequestBodyAfterApply] = useState<PaginationRequestBody | any>(customerSalesOrderRequestBody);

  useEffect(()=> {
    async function fetchCustomerSalesOrderListAPI (){
      setLoading(true);
      setInitialLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.salesOrderStatus, AllStatus),
        getStaticReferenceWithType(Constants.paymentStatus, AllStatus)
      ];
 
      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setSalesOrderStatusList(resultAPI[0]);
      setPaymentStatusList(resultAPI[1]);
      setInitialLoading(false);
      setLoading(false);
    }

    fetchCustomerSalesOrderListAPI();
  },[])

  useEffect(()=> {
    let indexTemp = _.findIndex(props.options, {[props.keyField]: props.validation.values[props.fieldName]});
    if (indexTemp > -1) {
      setSelectedRow(props.options[indexTemp])
    }
  }, [props.validation.values[props.fieldName]])

  const clearData = () => {
    setSelectedRow("");
    setCurrentPage(1);
  }

  const hideModal = () => {
    props.setModal(false);
  }

  const fetchCustomerSalesOrderListWithLoading = async () => {
    setLocalLoading(true);
    setCustomerSalesOrderRequestBodyAfterApply({...customerSalesOrderRequestBody, pageNumber: 1})
    await props.requestAPI!({...customerSalesOrderRequestBody, pageNumber: 1});
    setLocalLoading(false);
  };

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(props.branchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (String(e.target).includes(Constants.htmlButton)){
        if(props.onSelectFunction){
          props.onSelectFunction(row);
          clearData();
        }
      }
    }
  };

  const selectRowFunction = (row, isSelect, rowIndex) => {
    setSelectedRow(row);
  }; 

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      size='xl'
      fullscreen={true}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="standard-layout">
        {
          !initialLoading
          &&
          <>
            {/* <Row className="align-items-md-center margin-top--8">
              {
                subBrandListFinal.length > 0
                &&
                <Col xl="3">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerSalesOrder" }) })}
                  name="status"
                  options={salesOrderStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedSalesOrderStatus}
                  initialValue={selectedSalesOrderStatusId}
                  setFieldLabel={setSelectedSalesOrderStatus}
                  setFieldValue={setSelectedSalesOrderStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Payment" }) })}
                  name="paymentStatus"
                  options={paymentStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedPaymentStatus}
                  initialValue={selectedPaymentStatusId}
                  setFieldLabel={setSelectedPaymentStatus}
                  setFieldValue={setSelectedPaymentStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl="3">
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="customerSalesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validationLocal}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </Col>
            </Row> */}
            {/* <Row className="align-items-md-center margin-top--8 margin-bottom--8">
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  validationRequired={false}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange} />
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class="btn btn-primary margin-top-12"
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    setCurrentPage(1);
                    fetchCustomerSalesOrderListWithLoading()
                  }}
                />
              </Col>
            </Row> */}
          </>
        }
        {
          localLoading 
            ?
            <Loading/>
            :
            <>
              <PaginationTable
                bordered={true}
                keyField={props.keyField}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={props.totalItem || 0}
                tableColumns={props.columns}
                data={props.options}
                rowEvents={ rowEvents }
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: props.title})}
                requestAPI={props.requestAPI!}
                selectRow={{
                  mode: "radio",
                  hideSelectColumn: true,
                  clickToSelect: true,
                  bgColor: Constants.placeholderColor,
                  selected: !!selectedRow && [selectedRow[props.keyField]],
                  onSelect: selectRowFunction
                }} 
                {...props.paginationSubmitField ? props.paginationSubmitField : customerSalesOrderRequestBodyAfterApply}
                blnRemoveBackground={true}
                />
            </>
        }
        </div>
      </div>
    </Modal>
  )
});