import React, { Fragment, useEffect, useState } from 'react'
import {
  Input,
  Label
} from "reactstrap";
import _ from 'lodash';
import { AddOptionsValueField } from '../../common/function/function';

interface Props {
  name: string;
  className?: any;
  title?: string;
  options: any;
  valueField: string,
  labelField: string,
  sectionHeaderField?: string;
  placeholder?: string;
  disabled: boolean;
  initialValue: string | number | string[] | any;
  field: any;
  setFieldValue: any;
  blnInputUI?: boolean;
  inputField?: string;
  inputType?: 'text' | 'number'
  blnNumberOnly?: boolean;
}

export default function GeneralCheckBoxWithDropdownFormat(props: Props) {  
  const [optionsTemp, setOptionsTemp] = useState(AddOptionsValueField(props.options, props.labelField, props.valueField));

  useEffect(()=> {
    if (props.initialValue) {
      let optionsTempClone = _.cloneDeep(optionsTemp);
      for (var a=0; a<props.initialValue.length; a++) {
        let indexValueFound = _.findIndex(props.options, {[props.valueField]: props.initialValue[a][props.valueField]});
        if (indexValueFound > -1) {
          optionsTempClone[indexValueFound].checked = true;

          if (props.inputField) {
            optionsTempClone[indexValueFound][props.inputField] = props.initialValue[a][props.inputField];
          }
        }
      }
      setOptionsTemp(optionsTempClone);
    }
  }, [])

  const displayListWithCheckBox = () => {
    let sectionHeaderName = "";
    return (
      optionsTemp.map((valueOptionsTemp, indexOptionsTemp)=> {
        let blnDisplaySectionHeader = false;
        if (props.sectionHeaderField && (sectionHeaderName !== valueOptionsTemp[props.sectionHeaderField])) {
          blnDisplaySectionHeader = true;
          sectionHeaderName = valueOptionsTemp[props.sectionHeaderField];
        }
        return (
          <div key={`checkbox_input_${valueOptionsTemp.label}_parents`}>
            {
              blnDisplaySectionHeader && props.sectionHeaderField
              ?
              <div className={`${indexOptionsTemp !== 0 ? "mt-2" : ""}`}>
                <span>{`- ${valueOptionsTemp[props.sectionHeaderField]} -`}</span>
              </div>
              :
              <div/>
            }
            <div style={{ display: "flex", ...props.blnInputUI && { alignItems: 'center', marginBottom: '16px' }}} key={`checkbox_input_${valueOptionsTemp.label}`}>
              <Input
                checked={valueOptionsTemp?.checked}
                className={`${props.blnInputUI ? "mt-0" : ""}`}
                type="checkbox"
                disabled={props.disabled}
                onChange={event => {
                  let optionsTempCloneOnChange = _.cloneDeep(optionsTemp);
                  optionsTempCloneOnChange[indexOptionsTemp].checked = event.target.checked;
                  setOptionsTemp(optionsTempCloneOnChange);
                  let optionsTempCloneOnChangeFilter = _.filter(optionsTempCloneOnChange, {checked: true});
                  let fieldTemp = _.cloneDeep(props.field);
                  fieldTemp[props.name] = optionsTempCloneOnChangeFilter;
                  props.setFieldValue(fieldTemp);
                }}
              />
              <div style={{ marginLeft: "10px", color: 'black' }}>
                <span>{valueOptionsTemp.label}</span>
              </div>
             {
              props.blnInputUI
              &&
              <Input 
                style={{
                  width: '20%', 
                  maxWidth: '55px', 
                  maxHeight: '20px', 
                  marginLeft: '16px',
                  padding: "0px",
                  textAlign: 'center'
                }}
                type={props.inputType || 'text'}
                value={props.inputField ? valueOptionsTemp[props.inputField] : ""}
                onChange={(event)=> {
                  if (props.inputField) {
                    let optionsTempCloneOnChange = _.cloneDeep(optionsTemp);
                    optionsTempCloneOnChange[indexOptionsTemp][props.inputField] = event.target.value;
                    setOptionsTemp(optionsTempCloneOnChange);
                    let optionsTempCloneOnChangeFilter = _.filter(optionsTempCloneOnChange, {checked: true});
                    let fieldTemp = _.cloneDeep(props.field);
                    fieldTemp[props.name] = optionsTempCloneOnChangeFilter;
                    props.setFieldValue(fieldTemp);
                  }
                }}
                onKeyPress={(event) => {
                  if (props.blnNumberOnly) {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }
                }}
                onWheel={(event) => { event.currentTarget.blur(); }}
                disabled={!valueOptionsTemp.checked}/>
              }
            </div>
          </div>
        )
      })
    )
  }
  return (
    <div className={`${props.className || "" } mb-3`}>
      {
        props.title && 
        <Label>
          <Label className="margin-bottom-0 margin-top-0">{props.title}</Label>
        </Label>
      }
      {
        displayListWithCheckBox()
      }
    </div>
  )
}