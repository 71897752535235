import { PaginationRequestBody } from "../models/pagination";
import { BranchCommissionListObject, BranchCommissionAddObject, BranchCommissionUpdateObject, BranchCommissionDetailObject } from "../models/branchCommission";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class BranchCommissionStore {
  branchCommissionList: BranchCommissionListObject[] = [];
  branchCommissionDetail: BranchCommissionDetailObject | undefined = undefined;
  branchCommissionPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.branchCommissionList = [];
    this.branchCommissionDetail = undefined;
    this.branchCommissionPaginationParams= undefined;
  }

  setBranchCommissionDetail = (branchCommissionDetail: BranchCommissionDetailObject | undefined) => {
    this.branchCommissionDetail = branchCommissionDetail;
  }

  setBranchCommissionPaginationParams = (branchCommissionPaginationParams: PaginationRequestBody | undefined) => {
    this.branchCommissionPaginationParams = branchCommissionPaginationParams;
  }

  getBranchCommission = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setBranchCommissionPaginationParams(PaginationRequestBody);
    try{
      const resultBranchCommission = await agent.BranchCommission.branchCommissionList(PaginationRequestBody);
      runInAction(() => {
        this.branchCommissionList = resultBranchCommission.data;
        store.commonStore.setTotalItem(resultBranchCommission.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchCommissionList = [];
    }
  }

  getBranchCommissionWithId = async (id: string, passcode: number | string) => {
    try{
      let resultBranchCommissionDetail = await agent.BranchCommission.branchCommissionDetail(id, passcode);
      if (resultBranchCommissionDetail) {
        if (resultBranchCommissionDetail.commissionDetails) {
          resultBranchCommissionDetail.commissionDetails = resultBranchCommissionDetail.commissionDetails.map((valueCommissionDetailsTemp)=> ({
            ...valueCommissionDetailsTemp,
            positionName: !valueCommissionDetailsTemp.id ? "Total Commission" : valueCommissionDetailsTemp.positionName,
            ...!valueCommissionDetailsTemp.id && valueCommissionDetailsTemp.totalPackageCommission !== valueCommissionDetailsTemp.finalPackageCommission ? { packageStyle : { color : Constants.red, fontWeight: Constants.boldFontWeight } } : undefined,
            ...!valueCommissionDetailsTemp.id && valueCommissionDetailsTemp.totalProductCommission !== valueCommissionDetailsTemp.finalProductCommission ? { productStyle : { color : Constants.red, fontWeight: Constants.boldFontWeight } } : undefined,
            ...!valueCommissionDetailsTemp.id && valueCommissionDetailsTemp.totalAngPaoBonus !== valueCommissionDetailsTemp.finalAngPaoBonus ? { angPaoStyle : { color : Constants.red, fontWeight: Constants.boldFontWeight } } : undefined,
            ...!valueCommissionDetailsTemp.id && { boldLabel : Constants.boldFontWeight }
          }))
        }
      }
      runInAction(() => {
        this.branchCommissionDetail = resultBranchCommissionDetail;
      });
      return Promise.resolve(resultBranchCommissionDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.branchCommissionDetail = undefined;
    }
  }

  addBranchCommission = async (branchCommissionRequestBody: BranchCommissionAddObject) => {
    try{
      await agent.BranchCommission.addBranchCommission(branchCommissionRequestBody);
      store.commonStore.setSuccessMessage(`BranchCommissionAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateBranchCommission = async (branchCommissionRequestBody: BranchCommissionUpdateObject) => {
    try{
      await agent.BranchCommission.updateBranchCommission(branchCommissionRequestBody);
      store.commonStore.setSuccessMessage(`BranchCommissionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteBranchCommission  = async (id: string, name: string) => {
    try {
      await agent.BranchCommission.deleteBranchCommission(id);
      store.commonStore.setSuccessMessage(`BranchCommissionDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  branchCommissionReviewFirstApproval = async (branchCommissionRequestBody: BranchCommissionUpdateObject) => {
    try{
      await agent.BranchCommission.branchCommissionReviewFirstApproval(branchCommissionRequestBody);
      store.commonStore.setSuccessMessage(`BranchCommissionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  branchCommissionReviewSecondApproval = async (branchCommissionRequestBody: BranchCommissionUpdateObject) => {
    try{
      await agent.BranchCommission.branchCommissionReviewSecondApproval(branchCommissionRequestBody);
      store.commonStore.setSuccessMessage(`BranchCommissionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  branchCommissionReviewFinalApproval = async (branchCommissionRequestBody: BranchCommissionUpdateObject) => {
    try{
      await agent.BranchCommission.branchCommissionReviewFinalApproval(branchCommissionRequestBody);
      store.commonStore.setSuccessMessage(`BranchCommissionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}