import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const AppointmentSummaryBySource = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppointmentStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { appointmentSummaryListBySource, getAppointmentSummaryBySource, setAppointmentSummaryListBySource, exportAppointmentSummaryBySource } = reportAppointmentStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { appointmentSourceType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() :  AllBranch);
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportAppointmentReport = checkPermission([PermissionConstants.ExportAppointmentReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      sourceTypes: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchAppointmentSummaryBySource() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.appointmentSourceType),
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointmentReport], true)) {
      return;
    }

    fetchAppointmentSummaryBySource();

    return (() => {
      setAppointmentSummaryListBySource([]);
    })
  }, [])

  const fetchAppointmentSummaryBySourceWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportAppointmentSummaryBySource({ branchId: selectedBranchId, dateRange: selectedDateRange, sourceTypes: validation.values.sourceTypes })
    }
    else { 
      await getAppointmentSummaryBySource({ branchId: selectedBranchId, dateRange: selectedDateRange, sourceTypes: validation.values.sourceTypes });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setErrorMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const AppointmentSummaryColumns = [
    {
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "mediaSourceName",
      text: intl.formatMessage({ id: "MediaSourceName" }).toUpperCase(),
    },
    {
      dataField: "total",
      text: intl.formatMessage({ id: "TotalSources" }).toUpperCase(),
    },
    {
      dataField: "totalConfirmed",
      text: intl.formatMessage({ id: "TotalConfirmed" }).toUpperCase(),
    },
    {
      dataField: "totalNotShow",
      text: intl.formatMessage({ id: "TotalNotShow" }).toUpperCase(),
    },
    {
      dataField: "totalTurnUp",
      text: intl.formatMessage({ id: "TotalTurnUp" }).toUpperCase(),
    },
    {
      dataField: "turnUpPercentage",
      text: intl.formatMessage({ id: "TurnUpPercentage" }).toUpperCase(),
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.appointmentDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppointmentSummaryBySource" }) })}
        title={intl.formatMessage({ id: "AppointmentSummaryBySource" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppointmentReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchAppointmentSummaryBySourceWithLoading(true)
            }}
          />
        }>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3" >
                <DropDownWithTitleMultiSelect
                  name="appointmentSourceType"
                  title={intl.formatMessage({ id: "AppointmentSourceType" })}
                  specifyReturnFieldName={[{ "field": "sourceTypes", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={appointmentSourceType}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  validationRequired={false}
                  validation={validation}
                />
              </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "AppointmentDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchAppointmentSummaryBySourceWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentSummaryColumns}
              data={appointmentSummaryListBySource}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppointmentSummary" }) })}
              objSorted={defaultSorted}
              keyField={"mediaSourceName"}
              requestAPI={getAppointmentSummaryBySource}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              sourceTypes={validation.values.sourceTypes}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppointmentSummaryBySource);
