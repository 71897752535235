import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CreditHistoryObject, CustomerBirthdayObject, UpsalesSummaryObject, ExpiringPackageObject, PackageUnutilizedBalanceListObject, ProductUnutilizedBalanceListObject, AverageSpendingSummaryGetObject, AverageSpendingSummaryObject, WithoutAppointmentObject, UpsalesSummaryGetObject, AverageSpendingSummaryExportObject, UpsalesSummaryExportObject, WithoutPackageListObject, TopSpenderListObject, CustomerDetailsGetAndExportObject, CustomerDetailsListObject } from "../models/reportCustomer";
import { downloadExcel } from "../common/function/function";

export default class ReportCustomerStore {
  packageUnutilizedBalanceList: PackageUnutilizedBalanceListObject[] = [];
  productUnutilizedBalanceList: ProductUnutilizedBalanceListObject[] = [];
  averageSpendingSummary: AverageSpendingSummaryObject | undefined = undefined
  creditHistoryList : CreditHistoryObject[] = [];
  transferCreditHistoryList : CreditHistoryObject[] = [];
  expiringPackageList : ExpiringPackageObject[] = [];
  withoutAppointmentList : WithoutAppointmentObject[] = [];
  customerBirthdayList : CustomerBirthdayObject[] = [];
  upsalesSummary: UpsalesSummaryObject | undefined = undefined;
  withoutPackageList: WithoutPackageListObject[] = [];
  topSpenderList: TopSpenderListObject[] = [];
  customerDetailsList: CustomerDetailsListObject[] = [];
  customerDetailsListController: any = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.packageUnutilizedBalanceList = []
    this.productUnutilizedBalanceList = [];
    this.averageSpendingSummary = undefined;
    this.creditHistoryList = [];
    this.transferCreditHistoryList = [];
    this.expiringPackageList = [];
    this.withoutAppointmentList = [];
    this.customerBirthdayList = [];
    this.upsalesSummary = undefined;
    this.withoutPackageList = [];
    this.topSpenderList = [];
    this.customerDetailsList = [];
  }

  setPackageUnutilizedBalanceList = (packageUnutilizedBalance: PackageUnutilizedBalanceListObject[]) => {
    this.packageUnutilizedBalanceList = packageUnutilizedBalance;
  }

  setProductUnutilizedBalanceList = (productUnutilizedBalance: ProductUnutilizedBalanceListObject[]) => {
    this.productUnutilizedBalanceList = productUnutilizedBalance;
  }

  setAverageSpendingSummary = (averageSpendingSummary: AverageSpendingSummaryObject | undefined) => {
    this.averageSpendingSummary = averageSpendingSummary
  }

  setCreditHistoryList = (creditHistoryList: CreditHistoryObject[]) => {
    this.creditHistoryList = creditHistoryList;
  }

  setTransferCreditHistoryList = (transferCreditHistoryList: CreditHistoryObject[]) => {
    this.transferCreditHistoryList = transferCreditHistoryList;
  }

  setExpiringPackageList = (expiringPackageList: ExpiringPackageObject[]) => {
    this.expiringPackageList = expiringPackageList;
  }

  setWithoutAppointmentList = (withoutAppointmentList: WithoutAppointmentObject[]) => {
    this.withoutAppointmentList = withoutAppointmentList;
  }

  setCustomerBirthdayList = (customerBirthdayList: CustomerBirthdayObject[]) => {
    this.customerBirthdayList = customerBirthdayList;
  }

  setUpsalesSummary = (upsalesSummary: UpsalesSummaryObject | undefined) => {
    this.upsalesSummary = upsalesSummary
  }

  setWithoutPackageList = (withoutPackageList: WithoutPackageListObject[]) => {
    this.withoutPackageList = withoutPackageList;
  }

  setTopSpenderList = (topSpenderList: TopSpenderListObject[]) => {
    this.topSpenderList = topSpenderList;
  }

  setCustomerDetailsList = (customerDetailsList: CustomerDetailsListObject[]) => {
    this.customerDetailsList = customerDetailsList;
  }

  setCustomerDetailsListController = (customerDetailsListController: any) => {
    this.customerDetailsListController = customerDetailsListController;
  }

  getCustomerDetailsReportList = async (PaginationRequestBody: PaginationRequestBody | any) => {
    if(this.customerDetailsListController) {
      this.customerDetailsListController.abort()
      this.setCustomerDetailsListController(undefined);
    }
    
    try {
      const customerDetailsListControllerTemp = new AbortController();
      this.setCustomerDetailsListController(customerDetailsListControllerTemp);
      let resultCustomerDetails = await agent.ReportCustomer.customerDetailReportList(PaginationRequestBody, this.customerDetailsListController.signal);
      runInAction(() => {
        this.customerDetailsList = resultCustomerDetails.data
        store.commonStore.setTotalItem(resultCustomerDetails.pagination.totalItems)
      });
    } catch (error: any) {
      console.log(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerDetailsList = [];
    }
  }

  getPackageUnutilizedBalanceList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultPackageUnutilizedBalance = await agent.ReportCustomer.packageUnutilizedBalanceList(PaginationRequestBody);
      resultPackageUnutilizedBalance.data = resultPackageUnutilizedBalance.data.map((valueResultPackageUnutilizedBalanceTemp, index)=> ({
        ...valueResultPackageUnutilizedBalanceTemp,
        randomId: `${valueResultPackageUnutilizedBalanceTemp.customerName}_${index}`
      }))
      runInAction(() => {
        this.packageUnutilizedBalanceList = resultPackageUnutilizedBalance.data;
        store.commonStore.setTotalItem(resultPackageUnutilizedBalance.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.packageUnutilizedBalanceList = [];
    }
  }

  getProductUnutilizedBalanceList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultPackageUnutilizedBalance  = await agent.ReportCustomer.productUnutilizedBalanceList(PaginationRequestBody);
      resultPackageUnutilizedBalance.data = resultPackageUnutilizedBalance.data.map((valueResultPackageUnutilizedBalanceTemp, index)=> ({
        ...valueResultPackageUnutilizedBalanceTemp,
        randomId: `${valueResultPackageUnutilizedBalanceTemp.customerName}_${index}`
      }))
      runInAction(() => {
        this.productUnutilizedBalanceList = resultPackageUnutilizedBalance.data;
        store.commonStore.setTotalItem(resultPackageUnutilizedBalance.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productUnutilizedBalanceList = [];
    }
  }

  getAverageSpendingSummary = async (averageSpendingSummaryGetObject: AverageSpendingSummaryGetObject) => {
    try{
      let resultAverageSpendingSummary  = await agent.ReportCustomer.averageSpendingSummary(averageSpendingSummaryGetObject);
      this.averageSpendingSummary = resultAverageSpendingSummary;
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.averageSpendingSummary = undefined;
    }
  }

  getCreditHistoryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultCreditHistory = await agent.ReportCustomer.creditHistoryList(PaginationRequestBody);

      runInAction(() => {
        this.creditHistoryList = resultCreditHistory.data;
        store.commonStore.setTotalItem(resultCreditHistory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.creditHistoryList = [];
    }
  }

  getTransferCreditHistoryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultCreditHistory = await agent.ReportCustomer.transferCreditHistoryList(PaginationRequestBody);

      runInAction(() => {
        this.transferCreditHistoryList = resultCreditHistory.data;
        store.commonStore.setTotalItem(resultCreditHistory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.transferCreditHistoryList = [];
    }
  }

  getExpiringPackageList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultExpiringPackage = await agent.ReportCustomer.expiringPackageList(PaginationRequestBody);

      runInAction(() => {
        this.expiringPackageList = resultExpiringPackage.data;
        store.commonStore.setTotalItem(resultExpiringPackage.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.expiringPackageList = [];
    }
  }

  getWithoutAppointmentList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultWithoutAppointment = await agent.ReportCustomer.withoutAppointmentList(PaginationRequestBody);
      runInAction(() => {
        resultWithoutAppointment.data = resultWithoutAppointment.data.map((valueResultWithoutAppointmentTemp, index)=> ({
          ...valueResultWithoutAppointmentTemp,
          randomId: `${valueResultWithoutAppointmentTemp.customerName}_${index}`
        }))

        this.withoutAppointmentList = resultWithoutAppointment.data;
        store.commonStore.setTotalItem(resultWithoutAppointment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.withoutAppointmentList = [];
    }
  }

  getCustomerBirthdayList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      let resultCustomerBirthday = await agent.ReportCustomer.customerBirthdayList(PaginationRequestBody);

      runInAction(() => {
        this.customerBirthdayList = resultCustomerBirthday.data;
        store.commonStore.setTotalItem(resultCustomerBirthday.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerBirthdayList = [];
    }
  }

  getUpsalesSummary = async (upsalesSummaryGetObject: UpsalesSummaryGetObject) => {
    try{
      let resultUpsalesSummary  = await agent.ReportCustomer.upsalesSummary(upsalesSummaryGetObject);
      this.upsalesSummary = resultUpsalesSummary;
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.upsalesSummary = undefined;
    }
  }

  getWithoutPackageList = async (withoutPackageGetObject: PaginationRequestBody) => {
    try{
      let resultWithoutPackageList  = await agent.ReportCustomer.withoutPackageList(withoutPackageGetObject);
      
      runInAction(() => {
        this.withoutPackageList = resultWithoutPackageList.data;
        store.commonStore.setTotalItem(resultWithoutPackageList.pagination.totalItems);
      })
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.withoutPackageList = [];
    }
  }

  getTopSpenderList = async (topSpenderGetObject: PaginationRequestBody) => {
    try{
      let resultTopSpenderList  = await agent.ReportCustomer.topSpenderList(topSpenderGetObject);
      
      runInAction(() => {
        this.topSpenderList = resultTopSpenderList.data;
        store.commonStore.setTotalItem(resultTopSpenderList.pagination.totalItems);
      })
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.topSpenderList = [];
    }
  }

  exportCustomerDetailsReportList = async (customerDetailsListExportObject: CustomerDetailsGetAndExportObject | any ) => {
    try {
        await downloadExcel(`/report/customer/customerdetail/export`, customerDetailsListExportObject);
    } catch (error: any) {
        console.log(error)
        store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAverageSpendingSummary = async (averageSpendingSummaryExportObject: AverageSpendingSummaryExportObject) => {
    try{
      await downloadExcel(`/report/customer/spendingsummary/export`, averageSpendingSummaryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportPackageUnutilizedBalance = async (packageUnutilizedBalanceExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/packageUnutilizedBalance/export`, packageUnutilizedBalanceExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportProductUnutilizedBalance = async (productUnutilizedBalanceExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/productUnutilizedBalance/export`, productUnutilizedBalanceExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCreditHistory = async (creditHistoryExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/creditHistory/export`, creditHistoryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportTransferCreditHistory = async (transferCreditHistoryExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/transferCreditHistory/export`, transferCreditHistoryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCustomerWithoutAppointment = async (customerWithoutAppointmentExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/withoutAppointment/export`, customerWithoutAppointmentExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCustomerBirthday = async (customerBirthdayExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/birthday/export`, customerBirthdayExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportUpSalesSummary = async (upSalesSummaryExportObject: UpsalesSummaryExportObject) => {
    try{
      await downloadExcel(`/report/customer/upSalesSummary/export`, upSalesSummaryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportExpiringPackage = async (expiringPackageExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/expiringpackage/export`, expiringPackageExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportWithoutPackage = async (withoutPackageExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/withoutpackage/export`, withoutPackageExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportTopSpender = async (topSpenderExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/customer/topSpender/export`, topSpenderExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}