import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import CardInfo from "../../../app/components/form/CardInfo";
import CashCheckIcon from "mdi-react/CashCheckIcon";
import { Link } from "react-router-dom";

const UpsalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { upsalesSummary, getUpsalesSummary, setUpsalesSummary, exportUpSalesSummary } = reportCustomerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchUpsalesSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchUpsalesSummary();

    return (() => {
      setUpsalesSummary(undefined);
    })
  }, [])

  const fetchUpsalesSummaryWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportUpSalesSummary({ branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    else {
      await getUpsalesSummary({ branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const UpsalesSummaryDetailsColumns = [
    {
      dataField: "customerId", //field name from array to display
      text: "customerId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "totalPackageNewSales",
      text: intl.formatMessage({ id: "PackageNewSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPackageNewSales)}</div>
      ),
    },
    {
      dataField: "totalProductNewSales",
      text: intl.formatMessage({ id: "ProductNewSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalProductNewSales)}</div>
      ),
    },
    {
      dataField: "totalServiceNewSales",
      text: intl.formatMessage({ id: "ServiceNewSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalServiceNewSales)}</div>
      ),
    },
    {
      dataField: "totalNewPayment",
      text: intl.formatMessage({ id: "TotalNewPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalNewPayment)}</div>
      ),
    },
    {
      dataField: "totalPackageUpSales",
      text: intl.formatMessage({ id: "PackageUpsales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPackageUpSales)}</div>
      ),
    },
    {
      dataField: "totalProductUpSales",
      text: intl.formatMessage({ id: "ProductUpsales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalProductUpSales)}</div>
      ),
    },
    {
      dataField: "totalServiceUpSales",
      text: intl.formatMessage({ id: "ServiceUpsales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalServiceUpSales)}</div>
      ),
    },
    {
      dataField: "totalUpSalesPayment",
      text: intl.formatMessage({ id: "TotalUpsalesPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalUpSalesPayment)}</div>
      ),
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: '250px' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              content={intl.formatMessage({ id: "ViewDynamicSalesDetails" })}
              class={"btn btn-primary"}
              onClick={() => {
                window.open(`/${RoutesList.report}/${RoutesList.dynamicSalesDetails}${row.routeUrl}`)
              }}
              disable={false}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerUpsalesSummary" }) })}
        title={intl.formatMessage({ id: "CustomerUpsalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchUpsalesSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Customer-Upsales-Summary.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser() }
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PaymentDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchUpsalesSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              {
                upsalesSummary
                &&
                <Row className="padding-top-10">
                  <Col xl="3" lg="6" sm="12" xs="12">
                    <CardInfo
                      icon={() => (<CashCheckIcon className="h2 text-success mb-0" />)}
                      title={intl.formatMessage({ id: "TotalPaymentsReceived" }).toUpperCase()}
                      value={returnPriceWithCurrency(upsalesSummary.totalPayment)}
                      valueColor={"text-green-color"} />
                  </Col>
                </Row>
              }
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={UpsalesSummaryDetailsColumns}
                data={upsalesSummary ? upsalesSummary.customerUpSalesSummaryDetails : []}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerUpsalesSummary" }) })}
                objSorted={defaultSorted}
                keyField={"customerId"}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                requestAPI={getUpsalesSummary}
                hideSearchBar={true} />
            </>

        }
      </ListViewLayout>
    </div>
  );
}

export default observer(UpsalesSummary);
