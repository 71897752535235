import { Fragment, useEffect, useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import Loading from "../../../app/components/loading/Loading";
import { newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  blnAction: any;
  setModal: Function;
  leadId?: any;
  leadName?: string;
  addFollowUp?: Function;
  updateFollowUp?: Function;
  selectedFollowUpDetail: any;
  setFollowUpDetail: Function;
  setErrorMessage?: Function;
  onSuccessFunction?: Function;
}

export default function FollowUpHistoryModal(props: Props) {
  const intl = useIntl();
  const { commonStore, staticReferenceStore } = useStore();
  const { successMessage } = commonStore;
  const { defaultFollowUpRemark, getStaticReferenceWithType } =staticReferenceStore;
  const [loadingFollowUp, setLoadingFollowUp] = useState(false);
  const [loadingDefaultFollowUpRemark, setLoadingDefaultFollowUpRemark] = useState(false);

  const defaultFollowUpHistoryDetails = {
    id: "",
    leadId: props.leadId,
    remark: ""
  }

  const [followUpHistoryDetails, setFollowUpHistoryDetails] = useState(defaultFollowUpHistoryDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
  })

  useEffect(()=> {
    async function fetchDefaultFollowUpRemark() {
      setLoadingDefaultFollowUpRemark(true);
      await getStaticReferenceWithType(Constants.defaultFollowUpRemark);
      setLoadingDefaultFollowUpRemark(false);
    }

    fetchDefaultFollowUpRemark();
  }, [])

  useEffect(() => {
    if (props.selectedFollowUpDetail) {
      setFollowUpHistoryDetails(props.selectedFollowUpDetail);
    }
  }, [props.selectedFollowUpDetail])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        hideModal();
        if (props.onSuccessFunction) {
          props.onSuccessFunction();
        }
        else {
          history.push(`/${RoutesList.lead}/view/${props.leadId}`);
        }
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const viewRemarkAction = props.blnAction === Constants.view || Boolean(successMessage);

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      remark: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setFollowUpHistoryDetails(defaultFollowUpHistoryDetails);
    props.setFollowUpDetail(undefined);
    props.setModal(false);
  }

  const onClickFollowUp = async (followUpDetailTemp: any) => {
    setLoadingFollowUp(true);
    if (props.blnAction === Constants.add) {
      if(props.addFollowUp){
        await props.addFollowUp(followUpDetailTemp);
      }
    }
    else {
      if(props.updateFollowUp){
        await props.updateFollowUp(followUpDetailTemp);
      }
    }
    setLoadingFollowUp(false);
  }

  const displayDefaultFollowUpRemark = () => {
    let aryViews : any = [];
    defaultFollowUpRemark.map((valueDefaultFollowUpRemark, indexDefaultFollowUpRemark)=> {
      let displayContent = newSpaceBeforeCapitalLetter(valueDefaultFollowUpRemark.displayValue || "");
      aryViews.push(
        <Fragment key={`follow_up_key_${indexDefaultFollowUpRemark}`}>
          <MyButton
            type="button"
            class="btn btn-info button-padding-overwrite margin-left-8 margin-top-4"
            content={displayContent}
            disable={loadingFollowUp}
            onClick={()=> {
              setFollowUpHistoryDetails((valueFollowUpHistory)=> ({
                ...valueFollowUpHistory,
                remark: valueFollowUpHistory.remark ? valueFollowUpHistory.remark + ` ${displayContent}` : `${displayContent}`
              }))
            }}
          />
        </Fragment>
      )
    })
    return <div className="margin-top--8 margin-left--8px">{aryViews}</div>
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingFollowUp && !successMessage) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: `${props.blnAction}` })} {intl.formatMessage({ id: "FollowUp" })} {`${props.leadName ? `(${props.leadName})` : ""}`}</h5>
          {!loadingFollowUp && !successMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }

        </div>
        <div className="modal-body">
          <GeneralTextAreaForModal
            title={intl.formatMessage({ id: "Remark" })}
            name="remark"
            className={errorState.remark ? "mb-0" : "mb-3"}
            row={4}
            validationRequired={true}
            field={followUpHistoryDetails}
            setFieldValue={setFollowUpHistoryDetails}
            onChangeFunction={() => { clearErrorState("remark") }}
            disabled={viewRemarkAction || loadingFollowUp} />
          {errorState.remark ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Note" }) })}</FormFeedback>
          ) : null}
          {
            !viewRemarkAction
            ?
            loadingDefaultFollowUpRemark
            ?
            <Loading/>
            :
            displayDefaultFollowUpRemark()
            :
            <div/>
          }
          <div className="margin-top-14 ">
            {
              !viewRemarkAction 
              &&
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: `${props.blnAction}` })}
                onClick={() => {
                  let errorStateTemp = {
                    remark: false,
                  };
                  let blnErrorFound = false;
                  if (!followUpHistoryDetails.remark) {
                    errorStateTemp.remark = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickFollowUp(followUpHistoryDetails)
                  }
                }}
                loading={loadingFollowUp}
                disable={loadingFollowUp}
              />
            }
          </div>
        </div>
      </Modal>
  )
}