import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import ReportListingWithClick from "./subView/ReportListingWithClick";
import { Col, Row } from "reactstrap";
import { arraySpliceInAllValue, checkPermission, getBrandId } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import { useStore } from "../../app/stores/store";
import { Constants } from "../../app/constants/Constants";
import { EmployeeListObject } from "../../app/models/employee";
import SundayMarcomReportModal from "./Modal/SundayMarcomReportModal";
import TodayAppointmentMadeSummaryModal from "./Modal/TodayAppointmentMadeSummaryModal";
import IndividualDailyCSAppointmentModal from "./Modal/IndividualCSDailyAppointmentModal";
import CSCommissionReportModal from "./Modal/CSCommissionReportModal";

const MarcomReport = () => {
  const intl = useIntl();

  //Use Store
  const { commonStore, employeeStore, serviceTreatmentStore } = useStore();
  const { loading, setLoading } = commonStore;
  const { getEmployeeDropdown } = employeeStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;

  //Data List
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const [csCommissionReportModal, setCSCommissionReportModal] = useState(false);
  const [sundayMarcomReportModal, setSundayMarcomReportModal] = useState(false);
  const [todayAppointmentMadeSummaryModal, setTodayAppointmentMadeSummaryModal] = useState(false);
  const [individualCSDailyAppointmentModal, setIndividualCSDailyAppointmentModal] = useState(false);
  const All = `${intl.formatMessage({ id: "All" })}`;

  useEffect(() => {
    async function fetchMarcomReport() {
      setLoading(true);
      let aryAPI: any = [
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.marComCustomerServiceId}`], isActive: true}, true),
        getServiceTreatmentDropdown({branchId: "" })
      ];

      let resultAPI = await Promise.all(aryAPI);

      setMarcomConsultantList(arraySpliceInAllValue(resultAPI[0], All, false, "preferredName", "id"));
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ExportMarcomReport], true)) {
      return;
    }

    fetchMarcomReport();
  }, [])

  const onClickCSCommissionReport = () => {
    setCSCommissionReportModal(true);
  }

  const onClickSundayMarcomReport = () => {
    setSundayMarcomReportModal(true);
  }

  const onClickTodayAppointmentMadeSummary = () => {
    setTodayAppointmentMadeSummaryModal(true);
  }

  const onClickIndividualDailyCSAppointment = () => {
    setIndividualCSDailyAppointmentModal(true);
  }

  const marcomReportList = [
    {
      label: intl.formatMessage({ id: "CSCommissionReport" }),
      value: onClickCSCommissionReport
    },
    {
      label: intl.formatMessage({ id: "SundayMarcomReport" }),
      value: onClickSundayMarcomReport
    },
    {
      label: intl.formatMessage({ id: "TodayAppointmentMadeSummary" }),
      value: onClickTodayAppointmentMadeSummary
    },
    {
      label: intl.formatMessage({ id: "IndividualCSDailyAppointment" }),
      value: onClickIndividualDailyCSAppointment
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "MarcomReport" }) })}
        title={intl.formatMessage({ id: "MarcomReport" })}>
        {
          loading
            ?
            <Loading />
            :
            <Row form={+true}>
              <Col xl={6}>
                {
                  marcomReportList.length > 0
                  &&
                  <ReportListingWithClick
                    title={intl.formatMessage({ id: "Marcom" })}
                    subTitle={intl.formatMessage({ id: "MarcomReportDetail" })}
                    aryReport={marcomReportList} />
                }
              </Col>
            </Row>
        }
      </ListViewLayout>
      {
        sundayMarcomReportModal
        &&
        <SundayMarcomReportModal 
          blnShow={sundayMarcomReportModal}
          setModal={setSundayMarcomReportModal}/>
	    }
      {
        todayAppointmentMadeSummaryModal
        &&
        <TodayAppointmentMadeSummaryModal 
          blnShow={todayAppointmentMadeSummaryModal}
          setModal={setTodayAppointmentMadeSummaryModal}
          serviceTreatmentDropdownList={serviceTreatmentDropdownList}/>
	    }
      {
        individualCSDailyAppointmentModal
        &&
        <IndividualDailyCSAppointmentModal
          blnShow={individualCSDailyAppointmentModal}
          setModal={setIndividualCSDailyAppointmentModal}
          marcomConsultantList={marcomConsultantList}/>
      }
      {
        csCommissionReportModal
        &&
        <CSCommissionReportModal 
          blnShow={csCommissionReportModal}
          setModal={setCSCommissionReportModal}
          marcomConsultantList={marcomConsultantList}/>
      }
    </div>
  );
}

export default observer(MarcomReport);