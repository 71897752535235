
import React from 'react'
import { GeneralSnipButtonObject } from '../../models/common';
import Loading from '../loading/Loading';

interface Props {
  name: string;
  className?: string;
  aryButton: GeneralSnipButtonObject[];
  selectedIndex: number;
  setSelectedIndex: Function;
  loading?: boolean;
  disabled?: boolean;
}

export default function GeneralSnipButton(props: Props) {
  return (
    <div
      className={`btn-group btn-group-example ${props.className}`}
      role="group">
      {
        props.loading
        ?
        <Loading/>
        :
        props.aryButton.map((valueButton, index)=> (
          <button
            key={`snip_button_key_${index}_${props.name}`}
            type="button"
            className={`btn w-sm ${props.selectedIndex === index ? valueButton.color || "btn-primary" : valueButton.outlineColor || "btn-outline-primary"}`}
            onClick={()=> {
              valueButton.onClick();
            }}
            disabled={props.disabled}>
            {valueButton.buttonName}
          </button>
        ))
      }
    </div>
  )
}