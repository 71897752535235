import React, { useState } from "react";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../models/common";
import { Label, Input, UncontrolledTooltip, InputGroup } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import LineBreakWithTittle from "../form/LineBreakWithTittle";
import { AddOptionsValueField, calculateTotalByArray, handleCustomizeTableScrolling, paginationButtonTabDisplay, returnMobileOrTable, returnWidthPercentage } from '../../common/function/function';
import Select from 'react-select';
import MyButton from "../form/MyButton";
import { useIntl } from "react-intl";
import { Constants } from "../../constants/Constants";
import PlusIcon from 'mdi-react/PlusIcon';
import DeleteIcon from "mdi-react/DeleteIcon";
import GeneralImageWithBase64 from "../form/GeneralImageWithBase64";
import InformationIcon from "mdi-react/InformationIcon";

interface Props {
  name: string;
  title: string;
  buttonTitle: string;
  headerColor?: string;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  className?: string;
  classNameContent?: string;
  blnValidation?: boolean;
  blnEditable: boolean;
  aryDynamicInputAndCheckBoxTab?: ObjectForTableWithDynamicInputAndCheckBox[];
  data: any
  inputField?: any;
  validation: any;
  blnSupportWithModal?: boolean;
  blnBtnModalBottom?: boolean;
  options?: any;
  optionsId?: string;
  setOptionsFunction?: any;
  blnCheckBox?: boolean;
  blnCheckBoxSingleSelect?: boolean;
  blnDynamicRowContent?: boolean;
  blnHideBorder?: boolean;
  customCheckedField?: string;
  addButtonFunction?: () => void;
  deleteButtonFunction?: () => void;
  disabled: boolean;
  loading?: boolean;
  supportBackgroundColor?: boolean;
  alignItemsBasedLineOnRow?: boolean;
  fixedHeader?: boolean;
  windowSizeHeight?: number;
  minDateValue?: string | any;
  minCurrentDate?: boolean; 
  maxDateValue?: string;
  pageSize?: number;
  currentPage?: number;
  setCurrentPage?: Function;
  blnSupportPagination?: boolean;
  blnDisabledCustomizedTableScrolling?: boolean;
}

export default function TableWithEditableInputFields(props: Props) {
  let aryData = _.cloneDeep(props.data || [])
  const checkedField = props.customCheckedField || "isActive";
  const [randomNumber, setRandomNumber] = useState(1000);
  const intl = useIntl();
  const blnScreenSupportScroll = !props.blnDisabledCustomizedTableScrolling && handleCustomizeTableScrolling();

  const onClickDelete = (index: number, indexSubTab: number = -1, inputHeadFieldName: string = "", onChangeDeleteFunction?: Function) => {
    setRandomNumber((prev)=> prev+=8888);
    if (props.blnSupportWithModal && props.options && props.setOptionsFunction && props.optionsId && indexSubTab < 0 && inputHeadFieldName === "") {
      const aryOptionsTemp = _.cloneDeep(props.options);
      const indexSelectedValue = _.findIndex(props.options, { [props.optionsId]: aryData[index][props.optionsId] });
      if (indexSelectedValue > -1) {
        aryOptionsTemp[indexSelectedValue] = { ...aryOptionsTemp[indexSelectedValue], disabled: false }
        props.setOptionsFunction(aryOptionsTemp);
      }
    }

    if (indexSubTab > -1 && inputHeadFieldName !== "") {
      aryData[index][inputHeadFieldName].splice(indexSubTab, 1);
    }
    else {
      aryData.splice(index, 1);
    }
    props.validation.setFieldValue(props.name, aryData)

    if (props.deleteButtonFunction) {
      props.deleteButtonFunction()
    }

    setTimeout(()=> {
      if (onChangeDeleteFunction) {
        onChangeDeleteFunction(aryData);
      }
    }, 10)
  }

  const onChangeTextInput = (value: any, rowIndex: number, textInputFieldName: string, textInputType: string, rowIndexSubTab: number, textInputHeadFieldName: string, onChangeFunction?: Function, maxNumber?: number, blnNumberOnlyWithDecimal?: boolean) => {
    let valueForNumber : number | undefined | string = undefined;
    if (maxNumber || maxNumber === 0) {
      valueForNumber = value === "" ? "" : Math.max(0, Math.min(maxNumber, Number(value)));
    }
    
    if (blnNumberOnlyWithDecimal) {
      const numberOnlyWithTwoDecimalsRegex = Constants.numberOnlyWithTwoDecimalPlacesRegex;
      if (!numberOnlyWithTwoDecimalsRegex.test(value)) {
        return false;
      }
    }
    
    if (rowIndexSubTab > -1 && textInputHeadFieldName !== "") {
      aryData[rowIndex][textInputHeadFieldName || ""][rowIndexSubTab][textInputFieldName] = textInputType == "number" ? (valueForNumber === "" ? "" : valueForNumber === 0 ? 0 : valueForNumber || Number(value)) : value
    }
    else {
      aryData[rowIndex][textInputFieldName || ""] = textInputType == "number" ? (valueForNumber === "" ? "" : valueForNumber === 0 ? 0 : valueForNumber || Number(value)) : value
    }

    if (onChangeFunction) {
      props.validation.values[props.name] = aryData;
      onChangeFunction();
    }
    else {
      props.validation.setFieldValue(props.name, aryData);
    }
  }

  const onChangeChecked = (e: React.ChangeEvent<any>, rowIndex: number) => {
    if (props.blnCheckBoxSingleSelect) {
      aryData = aryData.map((valueDataTemp)=> ({
        ...valueDataTemp,
        [checkedField]: false
      }))
    }
    aryData[rowIndex][checkedField] = e.target.checked;
    props.validation.setFieldValue(props.name, aryData);
  };

  const onChangeSelect = (value: any, rowIndex: number, textInputFieldName: string) => {
    aryData[rowIndex][textInputFieldName || ""] = value;
    props.validation.setFieldValue(props.name, aryData);
  }

  const displayEmptyRow = () => {
    return (
      // <Label>There's no content available</Label>
      <LineBreakWithTittle title={`There's no content added`} color='grey' />
    )
  }

  const displayAddModalButton = () => {
    return (
      <div className="text-sm-end mb-3">
        <MyButton
          type="button"
          content={props.buttonTitle}
          class="mt-2 btn btn-success"
          onClick={() => { props.addButtonFunction && props.addButtonFunction() }}
          disable={props.disabled}
          onBlur={props.validation.handleBlur}
          subContent={<PlusIcon className="me-1" size={Constants.menuIconSize}/>}
        />
      </div>
    )
  }

  const displayDropdown = (value: any, valueTab: ObjectForTableWithDynamicInputAndCheckBox, index: number, indexValueTab: number = -1) => {
    let blnMultiSelect = valueTab.type === 'multiSelect'
    let optionTemp = valueTab.options ? valueTab.options : valueTab.optionsLocalField ? AddOptionsValueField(value[valueTab.optionsLocalField.fieldName], valueTab.optionsLocalField.label, valueTab.optionsLocalField.value) : [];
    let keyDropdown = `tab_second_row_select_${index}_${randomNumber}`;
    return (
      <div className="flex-align-items-center">
        <Select
          key={keyDropdown}
          className="full-width"
          classNamePrefix={keyDropdown}
          options={optionTemp}
          menuPosition="fixed"
          onChange={(selectedValue: any)=> {
            if (valueTab.specifyReturnFieldName) {
              valueTab.specifyReturnFieldName.map((valueTemp)=> {
                onChangeSelect(selectedValue ? blnMultiSelect ? selectedValue.map((value)=> value[valueTemp.value]) : selectedValue[valueTemp.value] : undefined, index, valueTemp.field);
              })
            }
            if (valueTab.onChangeFunction) {
              valueTab.onChangeFunction({...selectedValue, dropdownIndex: index})
            }
          }}
          placeholder={'- Please Select -'}
          defaultValue={valueTab.displayLabel && valueTab.displayValue ? value[valueTab.displayLabel] && value[valueTab.displayValue] ? blnMultiSelect ? value[valueTab.displayLabel] : {label: value[valueTab.displayLabel], value: value[valueTab.displayValue]} : null : null}
          isClearable={!valueTab.validationRequired}
          isMulti={blnMultiSelect}
          isOptionDisabled={(option: any) => option?.disabled}
          isDisabled={props.disabled || !props.blnEditable || (props.blnCheckBox && !value[checkedField]) || valueTab.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false)}
          onMenuOpen={()=> {
            setTimeout(()=>{
              const selectedEl = document.getElementsByClassName(`${keyDropdown}__option--is-selected`)[0];
              if(selectedEl){
                selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
              }
            },15)
          }}
        />
      </div>
    )
  }

  const displayInputGroup = (value: any, valueTab: ObjectForTableWithDynamicInputAndCheckBox, index: number, indexSubTab: number = -1, valueTabHeadField: string = "") => {
    return (
      <InputGroup>
        <span className="input-group-text">
          {valueTab.inputGroupText}
        </span>
        <Input
          key={`tab_second_row_input_${index}_${indexSubTab}_${randomNumber}`}
          name={valueTab.inputName}
          type={valueTab.inputType}
          {...valueTab.customInputStyle ? value[valueTab.customInputStyle] ? { style: value[valueTab.customInputStyle] } : undefined : undefined}
          {...valueTab.numberMaxFieldName || valueTab.blnNumberOnlyWithDecimal ? {"value": value[valueTab.inputName]} : undefined}
          {...!valueTab.numberMaxFieldName && !valueTab.blnNumberOnlyWithDecimal ? {"defaultValue": value[valueTab.inputName]} : undefined}
          defaultChecked={value[valueTab.inputName]}
          onChange={(e) => {
            onChangeTextInput(valueTab.inputType == 'checkbox' ? e?.target?.checked : e?.target?.value, index, valueTab.inputName, valueTab.inputType, indexSubTab, valueTabHeadField, valueTab.onChangeFunction, valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : undefined, valueTab.blnNumberOnlyWithDecimal)
          }}
          disabled={props.disabled || !props.blnEditable || (props.blnCheckBox && !value[checkedField]) || valueTab.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false)}
          onBlur={props.validation.handleBlur}
          onWheel={(event) => { event.currentTarget.blur(); }}
          min={valueTab.numberMaxFieldName ? 0 : undefined}
          // min={valueTab.numberMin ? valueTab.numberMin : 0}
          max={valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : undefined}
          // onKeyPress={valueTab.inputType === 'number' ? preventMinus : undefined}
        />
      </InputGroup>
    )
  }

  const displayInput = (value: any, valueTab: ObjectForTableWithDynamicInputAndCheckBox, index: number, indexSubTab: number = -1, valueTabHeadField: string = "") => {
    let numberMaxConditionFieldName = "";
    if (valueTab.numberMaxCondition) {
      if (value[valueTab.numberMaxCondition.compareFieldName] !== ""  && value[valueTab.numberMaxCondition.compareFieldName] === valueTab.numberMaxCondition.compareValue) {
        numberMaxConditionFieldName = valueTab.numberMaxCondition.trueConditionFieldName;
      }
      else{
        numberMaxConditionFieldName = valueTab.numberMaxCondition.falseConditionFieldName;
      }
    }

    return (
      <div 
        key={`tab_second_row_input_${index}_${indexSubTab}_${randomNumber}`} 
        {...valueTab.inputType !== Constants.checkbox && {style: { width: "100%" }}}>
        <Input
          key={`tab_second_row_input_${index}_${indexSubTab}_${randomNumber}`}
          name={valueTab.inputName}
          type={valueTab.inputType}
          style={{backgroundColor: valueTab.supportInputBackgroundColor ? value.backgroundColor : ""}}
          {...valueTab.inputValueForSuccessColor && value[valueTab.inputName] === valueTab.inputValueForSuccessColor ? {className: "inputWithSuccessColor"} : undefined}
          {...valueTab.numberMaxFieldName || valueTab.valueOnTime || numberMaxConditionFieldName || valueTab.blnNumberOnlyWithDecimal ? {"value": value[valueTab.inputName]} : undefined}
          {...!valueTab.numberMaxFieldName && !valueTab.valueOnTime && !numberMaxConditionFieldName && !valueTab.blnNumberOnlyWithDecimal ? {"defaultValue": value[valueTab.inputName]} : undefined}
          defaultChecked={value[valueTab.inputName]}
          onChange={(e) => {
            onChangeTextInput(valueTab.inputType == 'checkbox' ? e?.target?.checked : e?.target?.value, index, valueTab.inputName, valueTab.inputType, indexSubTab, valueTabHeadField, valueTab.onChangeFunction, valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : numberMaxConditionFieldName ? value[numberMaxConditionFieldName] : undefined, valueTab.blnNumberOnlyWithDecimal)
          }}
          disabled={props.disabled || !props.blnEditable || (props.blnCheckBox && !value[checkedField]) || valueTab.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false) || (value.disabledDisplayPurpose ? value.disabledDisplayPurpose : false)}
          onBlur={props.validation.handleBlur}
          onWheel={(event) => { event.currentTarget.blur(); }}
          {...valueTab.inputType === 'date' && {min: props.minCurrentDate ? props.minDateValue ? new Date(props.minDateValue).toISOString().split("T")[0] : new Date().toISOString().split("T")[0] : undefined}}
          {...(valueTab.numberMaxFieldName || numberMaxConditionFieldName) && { min: (valueTab.numberMaxFieldName || numberMaxConditionFieldName) ? 0 : undefined}}
          // min={valueTab.numberMin ? valueTab.numberMin : 0}
          max={valueTab.inputType === 'date' && props.maxDateValue ? new Date(props.maxDateValue).toISOString().split("T")[0] : valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : numberMaxConditionFieldName ? value[numberMaxConditionFieldName] : undefined}
          // onKeyPress={valueTab.inputType === 'number' ? preventMinus : undefined}
        />
        {
          valueTab.bottomTextDisplay || valueTab.bottomTextFieldDisplay
          ?
          <p className="mt-1 mb-0">
            {valueTab.bottomTextDisplay || ""} {valueTab.bottomTextFieldDisplay ? value[valueTab.bottomTextFieldDisplay] ? value[valueTab.bottomTextFieldDisplay] : "" : ""}
          </p>
          :
          null
        }
      </div>
    )
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
  };

  const displayCheckboxText = (valueTab: ObjectForTableWithDynamicInputAndCheckBox) => {
    return (
      <Label className="margin-bottom-0 margin-left-8" style={{marginTop: "0.25em"}}>{valueTab.checkBoxText}</Label>
    )
  }

  const displayLabel = (value: any, valueTab: ObjectForTableWithDynamicInputAndCheckBox, index) => {
    return (
      <div className="flex-align-items-center">
        <Label className="margin-bottom-0 label-truncate" style={{ color: valueTab.labelColor ? valueTab.labelColor : valueTab.labelColorField ? value[valueTab.labelColorField] : "", fontWeight: valueTab.labelBoldField ? value[valueTab.labelBoldField] : ""}}>
          {
            valueTab.displayIndexNumber && (!valueTab.hideIndexNumberFieldName ? true : value[valueTab.hideIndexNumberFieldName])
            ? 
            `${index+1}. `
            :
            <>
              {valueTab?.inputLabelTextDisplay} 
              {
                valueTab.totalDisplayFormula
                ?
                calculateTotalByArray(valueTab.totalDisplayFormula, value)
                :
                value[valueTab.inputName]
              }
            </>
          }
        </Label>
        {
          valueTab?.toolTipObject
          &&
          <div style={{marginLeft: '12px'}}>
            <InformationIcon color='#556ee6' size={18} id={`${valueTab.toolTipObject.inputName}_${index}`}/> 
            <UncontrolledTooltip placement="top" target={`${valueTab.toolTipObject.inputName}_${index}`}>
              {`${intl.formatMessage({ id: "AdviceOrderQty" })}: ${value[valueTab.toolTipObject.inputName]}`}
            </UncontrolledTooltip>
          </div>
        }
      </div>
    )
  }

  return (
    <div className={`${props.className} ${blnScreenSupportScroll ? "table-select-row-support-scroll-without-flex" : ""}`}>
      <div className={`${props.className} ${props.classNameContent} ${props.blnHideBorder ? "" : `tab_bar_border`}`} style={{...blnScreenSupportScroll && {minWidth: Constants.innerWidthCompare}}}>
        {
          props.title &&
          <Label>
            {props.blnValidation && <Label style={{ color: "red" }}>{`*`}</Label>}
            <Label className="margin-bottom-0">
              {props.title}
            </Label>
          </Label>
        }
        {
          props.blnEditable && props.blnSupportWithModal && !props.blnBtnModalBottom
          &&
          displayAddModalButton()
        }
        {
          (props.data || []).length > 0 && !props.loading
            ?
            <>
              {
                props.aryDynamicInputAndCheckBoxTab &&
                <div className="padding-0-75rem flex-direction-row" style={{backgroundColor: props.headerColor || ""}}>
                  <div style={{width: returnWidthPercentage(Number(props.blnCheckBox ? 1 : 0)), paddingTop: props.headerColor || props.title ? "6px" : undefined}}/> {/*If checkbox exist, add extra space for checkbox*/}
                  <div style={{width: props.blnCheckBox ? returnWidthPercentage(10) : !props.hideDeleteButton ? returnWidthPercentage(11) : "100%", paddingTop: props.headerColor || props.title ? "6px" : undefined}}>
                    <div className="flex-direction-row">
                      {
                        props.aryDynamicInputAndCheckBoxTab.map((valueTemp, index) => {
                          if (valueTemp.type !== "dynamicRowContent") {
                            if (valueTemp.xl == "0") {
                              return null;
                            }
                            return (
                              <div
                                key={`tab_index ${index}`}
                                style={{width: returnWidthPercentage(Number(valueTemp.xl)), paddingRight: "20px"}}>
                                <div className="flex-direction-row">
                                  {valueTemp.validationRequired && <h6 style={{color: 'red'}} className="margin-bottom-0">{`*`}</h6>}
                                  <h6 className="label-truncate" style={valueTemp?.textAlign ? {textAlign: 'center', width: '100%'} : undefined}>{valueTemp.label}</h6>
                                  {
                                    valueTemp?.toolTipTitle
                                    &&
                                    <div style={{marginLeft: '12px'}}>
                                      <InformationIcon color='#556ee6' size={18} id={`tooltip_title_${index}`}/> 
                                      <UncontrolledTooltip placement="top" target={`tooltip_title_${index}`}>
                                        {`${valueTemp.toolTipTitle}`}
                                      </UncontrolledTooltip>
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                  {
                    !props.disabled && props.blnEditable && !props.hideDeleteButton
                    &&
                    <div style={{width: returnWidthPercentage(1), paddingTop: props.headerColor || props.title ? "6px" : undefined}}>
                      <h6 style={{textAlign: 'center'}}>{'ACTION'}</h6>
                    </div>
                  }
                </div>
              }
              <div className="borderLine margin-bottom-2" />
              <div {...props.fixedHeader && !returnMobileOrTable() && {style: {overflowY: 'scroll', maxHeight: props.windowSizeHeight || 300}}}>
                {
                aryData.map((value: any, index: number) => (
                  !props.blnSupportPagination || (props.blnSupportPagination && (index >= ((props.pageSize! * props.currentPage!) - props.pageSize!)) && (index < (props.pageSize! * props.currentPage!)))
                  ?
                  <div
                    className={`padding-0-75rem ${!props.blnDynamicRowContent ? "flex-align-items-center" : ""} flex-direction-row`}
                    key={`tab_first_row_${index}`}
                    style={{backgroundColor: props.supportBackgroundColor ? value.backgroundColor : ""}}>
                    {
                      props.blnCheckBox &&
                      <div 
                        style={{width: returnWidthPercentage(Number(props.blnCheckBox ? 1 : 0))}}>
                        <div 
                          className="flex-align-items-center" 
                          style={{width: '20px'}}>
                          <Input
                            type="checkbox"
                            onChange={(e) => onChangeChecked(e, index)}
                            checked={value[checkedField]}
                            disabled={props.disabled || !props.blnEditable}
                            onBlur={props.validation.handleBlur}
                            className={"margin-top-0"}
                          />
                        </div>
                      </div>
                    }
                    <div style={{width: props.blnCheckBox ? returnWidthPercentage(10) : !props.hideDeleteButton ? returnWidthPercentage(11) : "100%"}}>  {/*If checkbox exist, add extra space for checkbox*/}
                      <div className="flex-direction-row flex-wrap" style={{alignItems: props.alignItemsBasedLineOnRow ? 'baseline' : ""}}>
                        {
                          props.aryDynamicInputAndCheckBoxTab &&
                          props.aryDynamicInputAndCheckBoxTab.map((valueTab, indexValueTab)=> {
                            if (valueTab.xl == "0") {
                              return null;
                            }

                            return (
                              <div 
                                style={{width: returnWidthPercentage(Number(valueTab.xl)), paddingRight: '20px'}} 
                                key={`tab_second_row_${index}_${indexValueTab}`} className={`${valueTab.textValueAlign ? "flex-justfity-content-center" : ""}`}
                                {...valueTab.onClickForIndex && { onClick: ()=> {valueTab.onClickForIndex && valueTab.onClickForIndex(index)}}}>
                                {
                                  valueTab.type === 'select' || valueTab.type === 'multiSelect'
                                  ?
                                  displayDropdown(value, valueTab, index)
                                  :
                                  valueTab.type == 'input'
                                  ?
                                  <div className="flex-align-items-center">
                                    {
                                      valueTab.inputGroup
                                      ?
                                      displayInputGroup(value, valueTab, index)
                                      :
                                      displayInput(value, valueTab, index)
                                    }
                                    {
                                      valueTab.checkBoxText
                                      &&
                                      displayCheckboxText(valueTab)
                                    }
                                  </div>
                                  :
                                  valueTab.type == 'image'
                                  ?
                                  <div>
                                    {
                                      value[valueTab.inputName] 
                                      ?
                                      <div style={{display: "flex"}}>
                                        <GeneralImageWithBase64
                                          index={index}
                                          imageWidth={valueTab.imageWidth}
                                          base64Image={value[valueTab.inputName]}
                                          />
                                        <MyButton
                                          class={`btn btn-danger margin-left-16`}
                                          content={"Remove"}
                                          disable={props.disabled || !props.blnEditable || (props.blnCheckBox && !value[checkedField]) || valueTab.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false)}
                                          onClick={()=> {
                                            onChangeTextInput("", index, valueTab.inputName, valueTab.inputType, -1, "", valueTab.onChangeFunction, undefined)
                                          }}
                                        />
                                      </div>
                                      :
                                      <input
                                        type="file"
                                        key={`tab_file_input_${index}`}
                                        name={valueTab.inputName}
                                        disabled={props.disabled || !props.blnEditable || (props.blnCheckBox && !value[checkedField]) || valueTab.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false)}
                                        onChange={async (e) => {
                                          if (e.target.files && e.target.files[0]) {
                                            let imgTemp = e.target.files[0];
                                            const reader = new FileReader()
                                            reader.readAsDataURL(imgTemp)
                                            let base64Final : any = await new Promise(resolve => {
                                              reader.onloadend = () => {
                                                resolve(reader.result);
                                              };
                                            });
                                            base64Final = base64Final.replace("data:image/png;base64,", "");
                                            onChangeTextInput(base64Final, index, valueTab.inputName, valueTab.inputType, -1, "", valueTab.onChangeFunction, undefined)
                                          }
                                        }}
                                      />
                                    }
                                  </div>
                                  :
                                  valueTab.type == 'dynamicButton'
                                  ?
                                  <div className={`${valueTab.textValueAlign && "flex-justfity-content-center margin-left-20"}`}>
                                    <MyButton
                                      type="button"
                                      content={valueTab.rowButtonTitle || "Add"}
                                      class={`btn ${valueTab.dynamicButtonClassName ? valueTab.dynamicButtonClassName : "btn-success"}`}
                                      onClick={() => {
                                        if (valueTab.dynamicButtonFunction) {
                                          valueTab.dynamicButtonFunction(index)
                                        }
                                        else {
                                          let aryRowTemp = _.cloneDeep(value[valueTab.inputName]) || [];
                                          aryRowTemp.push(valueTab.rowInputField);
                                          aryData[index][valueTab.inputName] = aryRowTemp;
                                          props.validation.setFieldValue(props.name, aryData);
                                        }
                                      }}
                                      disable={props.disabled || (valueTab.reverseDisabledField ? !value[valueTab.reverseDisabledField]: false) || (valueTab.disabledField ? value[valueTab.disabledField]: false)}
                                      onBlur={props.validation.handleBlur}
                                    />
                                  </div>
                                  :
                                  valueTab.type == 'dynamicRowContent' && (value[valueTab.inputName] && value[valueTab.inputName].length > 0)
                                  ?
                                  <div 
                                    className="flex-direction-row margin-top-16 flex-align-items-center-without-height" style={{flex: 1}}>
                                    {/* <br/> */}
                                    {/* <i className="fa fa-caret-right margin-right-8"/> */}
                                    <div style={{alignItems: 'center', width: returnWidthPercentage(Number(valueTab.contentXL))}} key={`row_indexValueTab${value.id}`}>
                                      <div style={{border: "solid", borderWidth: "0.1px", padding: "16px", borderColor: "#ebe8e8", borderRadius: "8px"}}>
                                        {/* <div
                                          key={`tab_index ${index}`}
                                          style={{paddingRight: "20px"}}>
                                          <div className="flex-direction-row">
                                            {valueTab.validationRequired && <h6 style={{color: 'red'}} className="margin-bottom-0">{`*`}</h6>}
                                            <h6 style={{...valueTab?.textAlign ? {textAlign: 'center'} : undefined, fontWeight: 'bold'}}>{valueTab.label}</h6>
                                          </div>
                                        </div> */}
                                        <div className="flex-direction-row">
                                          {
                                            valueTab.rowValue
                                            &&
                                            valueTab.rowValue.map((valueTemp, index) => {
                                              return (
                                                <div
                                                  key={`tab_index ${index}`}
                                                  style={{width: returnWidthPercentage(Number(valueTemp.xl)), paddingRight: "20px"}}>
                                                  <div className="flex-direction-row">
                                                    {valueTemp.validationRequired && <h6 style={{color: 'red'}} className="margin-bottom-0">{`*`}</h6>}
                                                    <h6 style={valueTemp?.textAlign ? {textAlign: 'center'} : undefined}>{valueTemp.label}</h6>
                                                  </div>
                                                </div>
                                              )
                                            })
                                          }
                                          {
                                            valueTab.rowValue && valueTab.rowValue.length > 0 && !props.disabled  && !valueTab.disabled
                                            && props.blnEditable &&
                                            <div
                                              key={`tab_index ${index}`}
                                              style={{width: returnWidthPercentage(Number(1))}}>
                                              <h6 style={{textAlign: 'center'}}>{"Action"}</h6>
                                            </div>
                                          }
                                        </div>
                                        {
                                          value[valueTab.inputName] &&
                                          value[valueTab.inputName].map((rowValue: any, rowIndex: number) => (
                                            <div className="flex-direction-row" key={`tab_dynamic_row_second_row_${rowIndex}_row`}>
                                              {
                                                valueTab.rowValue &&
                                                valueTab.rowValue.map((rowValueTab, indexRowValueTab)=> (
                                                  <div
                                                    style={{width: returnWidthPercentage(Number(rowValueTab.xl))}}
                                                    key={`tab_dynamic_row_second_row_${rowIndex}_${indexRowValueTab}_1`} className={`${rowValueTab.textValueAlign ? "flex-justfity-content-center" : ""}`}>
                                                    <div className={`flex-direction-row flex-align-items-center ${rowIndex > 0 ? 'margin-top-8' : 'margin-top-10'}`} style={{paddingRight: '20px'}}>
                                                      {indexRowValueTab === 0 && <div style={{marginRight: '8px'}}><i>{`${rowIndex+1}.`}</i></div>}
                                                      {
                                                        // rowValueTab.type == 'select'
                                                        // ?
                                                        // displayDropdown(rowValue, rowValueTab, index, rowIndex)
                                                        // :
                                                        rowValueTab.type == 'input'
                                                        ?
                                                        <div className="flex-align-items-center" style={{width: '100%'}}>
                                                          {
                                                            rowValueTab.inputGroup
                                                            ?
                                                            displayInputGroup(rowValue, rowValueTab, index, rowIndex, valueTab.inputName)
                                                            :
                                                            displayInput(rowValue, rowValueTab, index, rowIndex, valueTab.inputName)
                                                          }
                                                          {
                                                            rowValueTab.checkBoxText
                                                            &&
                                                            displayCheckboxText(rowValueTab)
                                                          }
                                                        </div>
                                                        :
                                                        displayLabel(rowValue, rowValueTab, 0)
                                                      }
                                                    </div>
                                                  </div>
                                                ))
                                              }
                                              {
                                                !props.disabled && props.blnEditable && !valueTab.disabled
                                                &&
                                                <div style={{width: returnWidthPercentage(Number(1)), marginTop: '4px'}} className="flex-justfity-content-center">
                                                  <div className={`flex-direction-row flex-align-items-center ${rowIndex > 0 ? 'margin-top-8' : 'margin-top-10'}`}>
                                                    <Link
                                                      to="#"
                                                      className="text-danger"
                                                      onClick={() => onClickDelete(index, rowIndex, valueTab.inputName, valueTab.onChangeDeleteFunction)}
                                                      onBlur={props.validation.handleBlur}
                                                    >
                                                      <DeleteIcon size={18} id={"deletetooltip"}/>
                                                      <UncontrolledTooltip
                                                        placement="top"
                                                        target="deletetooltip"
                                                      >
                                                        Delete
                                                      </UncontrolledTooltip>
                                                    </Link>
                                                  </div>
                                                </div>
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  valueTab.type == 'dynamicRowContent' && (value[valueTab.inputName] && value[valueTab.inputName].length === 0)
                                  ?
                                  <div 
                                    className="flex-direction-row margin-top-16 flex-align-items-center-without-height" style={{flex: 1}}>
                                    <div style={{alignItems: 'center', width: returnWidthPercentage(Number(valueTab.contentXL))}} key={`row_indexValueTab${value.id}`}>
                                      <div style={{border: "solid", borderWidth: "0.1px", padding: "16px", borderColor: "#ebe8e8", borderRadius: "8px"}}>
                                        <span style={{color: 'black'}}>
                                          {intl.formatMessage({ id: "NoItemToSelect" })}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  valueTab.linkPath && valueTab.linkPathFieldName
                                  ?
                                  <div className={`flex-align-items-center ${valueTab.flexRight ? "flex-right" : ""}`}>
                                    <Link to={`/${valueTab.linkPath}${value[valueTab.linkPathFieldName]}`} target={Constants.supportBlank} rel="noopener noreferrer">
                                      <Label className="margin-bottom-4 pointer-clickable">
                                        {valueTab?.inputLabelTextDisplay} 
                                        {
                                          value[valueTab.inputName]
                                        }
                                        </Label>
                                    </Link>
                                  </div>
                                  :
                                  displayLabel(value, valueTab, index)
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    {
                      !props.disabled && props.blnEditable && !props.hideDeleteButton
                      &&
                      <div style={{width: returnWidthPercentage(1)}}>
                        <div className={`d-flex gap-3 flex-justfity-content-center ${props.blnDynamicRowContent ? "margin-top-8" : ""}`}>
                          <Link
                            to="#"
                            className="text-danger"
                            onClick={() => onClickDelete(index)}
                            onBlur={props.validation.handleBlur}
                          >
                            <DeleteIcon size={18} id={"deletetooltip"}/>
                            <UncontrolledTooltip
                              placement="top"
                              target="deletetooltip"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </Link>
                        </div>
                      </div>
                    }
                    </div>
                  :
                  null
                  ))
                }
              </div>
            </>
          :
          displayEmptyRow()
        }
        {
          props.blnSupportPagination
          &&
          <div className="margin-top-28">
            {paginationButtonTabDisplay(props.data.length, props.pageSize!, props.currentPage!, props.setCurrentPage!, intl)}
          </div>
        }
        {
          props.blnEditable && !props.blnSupportWithModal && !props.hideAddButton
          &&
          <div className="text-sm-end margin-bottom-4">
            <MyButton
              type="button"
              content={props.buttonTitle}
              class="mt-2 btn btn-success"
              onClick={() => {
                aryData.push(props.inputField)
                props.validation.setFieldValue(props.name, aryData);
              }}
              disable={props.disabled}
              onBlur={props.validation.handleBlur}
              subContent={<PlusIcon className="me-1" size={Constants.menuIconSize}/>}
            />
          </div>
        }
        {
          props.blnEditable && props.blnSupportWithModal && props.blnBtnModalBottom && !props.hideAddButton
          &&
          displayAddModalButton()
        }
      </div>
    </div>
  );
}
