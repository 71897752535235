import { PaginationRequestBody } from "../models/pagination";
import { ContractLiabilityCommissionCostListObject, ContractLiabilityDetailObject, ContractLiabilityListObject, ContractLiabilitySiteMapObject, ContractLiabilityTimelineObject, ContractLiabilityTransactionListObject } from "../models/contractLiability";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class contractLiabilityStore {
  contractLiabilitySiteMap: ContractLiabilitySiteMapObject | undefined = undefined;
  contractLiabilityDetail: ContractLiabilityDetailObject | undefined = undefined;
  contractLiabilityList: ContractLiabilityListObject[] = [];
  contractLiabilityTransactionList: ContractLiabilityTransactionListObject[] = [];
  contractLiabilityCommissionCostList: ContractLiabilityCommissionCostListObject[] = [];
  contractLiabilityTransactionTotalItem: number = 0;
  contractLiabilityCommissionCostTotalItem: number = 0;
  
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.contractLiabilitySiteMap = undefined;
    this.contractLiabilityDetail = undefined;
    this.contractLiabilityList = [];
    this.contractLiabilityTransactionList = [];
    this.contractLiabilityCommissionCostList = [];
    this.contractLiabilityTransactionTotalItem = 0;
    this.contractLiabilityCommissionCostTotalItem = 0;
  }

  setContractLiabilitySiteMap = (contractLiabilitysSiteMap: ContractLiabilitySiteMapObject | undefined) => {
    this.contractLiabilitySiteMap = contractLiabilitysSiteMap;
  }

  setContractLiabilityDetail = (contractLiabilityDetail: ContractLiabilityDetailObject | undefined) => {
    this.contractLiabilityDetail = contractLiabilityDetail;
  }
  
  setContractLiabilityList = (contractLiabilityList: ContractLiabilityListObject[]) => {
    this.contractLiabilityList = contractLiabilityList;
  }

  setContractLiabilityTransactionList = (contractLiabilityTransactionList: ContractLiabilityTransactionListObject[]) => {
    this.contractLiabilityTransactionList = contractLiabilityTransactionList;
  }

  setContractLiabilityCommissionCostList = (contractLiabilityCommissionCostList: ContractLiabilityCommissionCostListObject[]) => {
    this.contractLiabilityCommissionCostList = contractLiabilityCommissionCostList;
  }

  getContractLiabilitySiteMap = async (id: string) => {
    try{
      const resultContractLiabilitySiteMap = await agent.ContractLiability.contractLiabilitySiteMap(id);
      runInAction(() => {
        this.contractLiabilitySiteMap = resultContractLiabilitySiteMap;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilitySiteMap = undefined;
    }
  }

  getContractLiabilityDetail = async (id: string) => {
    try{
      const resultContractLiabilityDetail = await agent.ContractLiability.contractLiabilityDetail(id);
      runInAction(() => {
        this.contractLiabilityDetail = resultContractLiabilityDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityDetail = undefined;
    }
  }

  getContractLiabilityTimelineDetail = async (id: string) => {
    try{
      const resultContractLiabilityTimelineDetail = await agent.ContractLiability.contractLiabilityTimelineDetail(id);
      return Promise.resolve(resultContractLiabilityTimelineDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getContractLiability = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultContractLiabilityList = await agent.ContractLiability.contractLiability(PaginationRequestBody);
      runInAction(() => {
        this.contractLiabilityList = resultContractLiabilityList.data;
        store.commonStore.setTotalItem(resultContractLiabilityList.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityList = [];
    }
  };

  getContractLiabilityTransaction = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultContractLiabilityTransactionList = await agent.ContractLiability.contractLiabilityTransaction(PaginationRequestBody);
      runInAction(() => {
        this.contractLiabilityTransactionList = resultContractLiabilityTransactionList.data;
        this.contractLiabilityTransactionTotalItem = resultContractLiabilityTransactionList.pagination.totalItems;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityTransactionList = [];
      this.contractLiabilityTransactionTotalItem = 0;
    }
  };

  getContractLiabilityCommissionCost = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultContractLiabilityCommissionCostList = await agent.ContractLiability.contractLiabilityCommissionCost(PaginationRequestBody);
      runInAction(() => {
        this.contractLiabilityCommissionCostList = resultContractLiabilityCommissionCostList.data;
        this.contractLiabilityCommissionCostTotalItem = resultContractLiabilityCommissionCostList.pagination.totalItems;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityCommissionCostList = [];
      this.contractLiabilityCommissionCostTotalItem = 0;
    }
  };
}