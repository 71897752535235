import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, returnBlobImage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import DropZoneUploadWidget from "../../app/components/form/DropZoneUploadWidget";

const SalesSettingsDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { settingsStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { salesSettings, getSalesSettings, updateSalesSettings, setSalesSettingsDetail } = settingsStore;
  const displayTitle = `${intl.formatMessage({ id: "SalesSettings" })}`;
  const [newImageUrl, setNewImageUrl] = useState<string>("");
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: salesSettings || {
      id: "",
      photoId: "",
      photoUrl: "",
      displayLogo: false,
      allowNegativeStock: false,
      allowRedeemNegativeStock: false,
      strictRedemption: false,
      allowWholeBillDiscount: false,
      allowItemDiscount: false,
      allowPackageDiscount: false,
      discountRequireManagerApproval: false,
      isESignRequired: false,
      isStockAdjustmentRequireApproval: false,
      receiptExtraInfo: "",
      invoiceFooterNote: "",
      logoPhoto: undefined,
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let finalImageUrl = "";
      if (newImageUrl) {
        finalImageUrl = newImageUrl;
      }
      else if (valuesTemp.photoUrl) {
        finalImageUrl = valuesTemp.photoUrl;
      }

      if (finalImageUrl) {
        let logoTemp = await returnBlobImage(finalImageUrl);
        valuesTemp.logoPhoto = logoTemp;
      }

      if (!blnEditAction) {
        try {
          let valuesFinal: any = {
            id: valuesTemp.id,
            logoPhoto: valuesTemp.logoPhoto,
            values: {...valuesTemp, logoPhoto: ""}
          }
          let resultSettings = await updateSalesSettings(valuesFinal);
          if (resultSettings?.status === Constants.success) {
            fetchSalesSettingsDetailAPI();
          }
        }
        finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnEditAction = !checkPermission([PermissionConstants.UpdateSalesSetting])

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageSalesSetting], true)) {
      return;
    }

    fetchSalesSettingsDetailAPI();

    return (() => {
      if (id) {
        setSalesSettingsDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  async function fetchSalesSettingsDetailAPI() {
    setLoading(true);
    let aryAPI: any = [
      getSalesSettings(),
    ];

    await Promise.all(aryAPI);
    setLoading(false);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={[]}
        auditTrailId={undefined}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "SalesSettings" }) })}
                  h4Title />
                <Row className="mt-3">
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "NegativeStock" })}
                      name="allowNegativeStock"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "RedeemNegativeStock" })}
                      name="allowRedeemNegativeStock"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "StrictRedemption" })}
                      name="strictRedemption"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "WholeBillDiscount" })}
                      name="allowWholeBillDiscount"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ItemDiscount" })}
                      name="allowItemDiscount"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "PackageDiscount" })}
                      name="allowPackageDiscount"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DiscountRequireManagerApproval" })}
                      name="discountRequireManagerApproval"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ESignRequired" })}
                      name="isESignRequired"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={4} md={4} xl={4}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "StockAdjustmentRequireApproval" })}
                      name="isStockAdjustmentRequireApproval"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                </Row>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayLogo" })}
                    name="displayLogo"
                    type="checkbox"
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        Yes
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "LabelWithExtraInfo" }, { field: intl.formatMessage({ id: "Receipt" }) })}
                    name="receiptExtraInfo"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "InvoiceFooterNote" })}
                    name="invoiceFooterNote"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
              </Col>
              <Col xl={6} className={`${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-8" : ""}`}>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "LogoPhoto" })}
                  className=""
                  h4Title
                />
                <Col >
                  <SingleColumnRowParent
                    blnDoubleTab={true}>
                    <DropZoneUploadWidget
                      name="logoPhoto"
                      validation={validation}
                      setNewImageUrl={setNewImageUrl}
                      disabled={disabledFieldInput}
                      imageUrl={validation.values.photoUrl}
                    />
                  </SingleColumnRowParent>
                </Col>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              className="mt-4"
              successMessage={successMessage}
              viewAction={blnEditAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(SalesSettingsDetail);
