import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from "../../..";
import { checkPermission, onClickWhatsappWithDelay } from "../../../app/common/function/function";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { TaxEntityDropdownListObject } from "../../../app/models/taxEntity";
import { useEffect, useState } from "react";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: string;
  customerMobileNo: string;
  tinId: string;
  localLoading?: boolean;
  onResubmitEInvoiceRequest: Function;
}

export default observer(function ConsolidatedEInvoiceResubmitModal(props: Props) {
  //Use Store
  const { commonStore, taxEntityStore } = useStore();
  const { successMessage } = commonStore;
  const { getCustomerTaxEntityDropdown, addTaxEntityFormHistory } = taxEntityStore;
  const intl = useIntl();
  const blnPermissionUpdateTaxEntity = checkPermission([PermissionConstants.UpdateTaxEntity]);
  const blnPermissionCreateTaxEntity = checkPermission([PermissionConstants.CreateTaxEntity]);
  const [tinDropdownList, setTinDropdownList] = useState<TaxEntityDropdownListObject[]>([]);
  const [loadingTinDropdown, setLoadingTinDropdown] = useState(false);
  const [loadingCustomerTaxEntity, setLoadingCustomerTaxEntity] = useState(false);
  const [selectedTinDisplayName, setSelectedTinDisplayName] = useState("");
  const [selectedTinId, setSelectedTinId] = useState("");
  const disabledFieldInput = loadingTinDropdown || props.localLoading || loadingCustomerTaxEntity || Boolean(successMessage);

  const hideModal = () => {
    if (!props.localLoading && !Boolean(successMessage)) {
      props.setModal(false);
    }
  }

  useEffect(() => {
    async function fetchTinDropdown() {
      setLoadingTinDropdown(true);
      const taxEntityList = await getCustomerTaxEntityDropdown(props.customerId)
      taxEntityList.shift();
      setTinDropdownList(taxEntityList);
      setLoadingTinDropdown(false);
    }

    if (props.blnShow && !props.tinId) {
      fetchTinDropdown();
    }
  }, [])

  async function addCustomerTaxEntityWebFormHistory() {
    const result = await addTaxEntityFormHistory({ customerId: props.customerId })
    if (result?.data.webFormHistoryId) {
      return result?.data.webFormHistoryId;
    }
  }

  return (
    <div>
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered={true}>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => { hideModal() }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
        <ModalBody className="py-3 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                {<AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"} />}
                <h4 style={{ whiteSpace: "pre-wrap" }}>
                  {intl.formatMessage({ id: "ResubmitConsolidatedEInvoiceMessage" })}
                </h4>
                {/* <div className="mt-2" style={{fontSize: "15px"}}>
                  {intl.formatMessage({ id: "EditTinInfoMessage" })}
                  <Link 
                    to={``} 
                    target={Constants.supportBlank} 
                    rel="noopener noreferrer">
                    <span className="margin-bottom-0 pointer-clickable text-truncate fw-bold">{intl.formatMessage({ id: "here" })}.</span>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row className={`flex-1`}>
            <Col>
              <div className={`text-center mt-3`}>
                {
                  (
                    blnPermissionUpdateTaxEntity && props.tinId
                    ?
                    <MyButton
                      type="button"
                      content={intl.formatMessage({ id: "EditTinInfo" })}
                      class="btn btn-primary btn-lg me-2"
                      style={{ minWidth: "60px" }}
                      onClick={() => { history.push(`/${RoutesList.taxEntity}/edit/${props.tinId}`) }}
                      loading={props.localLoading}
                      disable={disabledFieldInput} />
                    :
                    !props.tinId
                    ?
                    <>
                      {
                        loadingTinDropdown
                        ?
                        <div className="flex-justfity-content-center">
                          <Loading 
                            className="mb-4"/>
                        </div>
                        :
                        <GeneralSelectDropdown
                          name={"tinDisplayName"}
                          title={""}
                          labelField={"tinDisplayName"}
                          valueField={"tinId"}
                          options={tinDropdownList}
                          initialLabel={selectedTinDisplayName}
                          initialValue={selectedTinId}
                          setFieldLabel={setSelectedTinDisplayName}
                          setFieldValue={setSelectedTinId}
                          disabled={disabledFieldInput}
                          validationRequired={true}
                        />
                      }
                      {
                        blnPermissionCreateTaxEntity
                        &&
                        <MyButton
                          content={intl.formatMessage({ id: "ReqTIN" })}
                          type="button"
                          onClick={async () => {
                            setLoadingCustomerTaxEntity(true);
                            let resultId = await addCustomerTaxEntityWebFormHistory()
                            if (resultId) {
                              const message = `${window.location.origin}/${RoutesList.taxEntityWeb}/add/${resultId}`;
                              const encodedId = encodeURIComponent(message);
                              onClickWhatsappWithDelay(`https://web.whatsapp.com/send?phone=${props.customerMobileNo}&text=${encodedId}`)
                            }
                            setLoadingCustomerTaxEntity(false);
                          }}
                          class={'btn btn-primary btn-lg me-2'}
                          style={{ minWidth: "60px" }}
                          loading={loadingCustomerTaxEntity}
                          disable={disabledFieldInput} />
                      }
                    </>
                    :
                    <div/>
                  )
                }
                <MyButton
                  type="button"
                  content={intl.formatMessage({ id: "Yes" })}
                  class="btn btn-success btn-lg me-2"
                  style={{ minWidth: "60px" }}
                  onClick={() => {
                    props.onResubmitEInvoiceRequest();
                  }}
                  loading={props.localLoading}
                  disable={disabledFieldInput || (selectedTinId || props.tinId ? false : true)} />
                <MyButton
                  type="button"
                  content={intl.formatMessage({ id: "No" })}
                  class={`btn btn-danger btn-lg me-2 ${props.localLoading ? "mt-2" : ""}`}
                  style={{ minWidth: "60px" }}
                  onClick={() => {
                    hideModal();
                  }}
                  loading={props.localLoading}
                  disable={disabledFieldInput} />
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
})
