import React from 'react'
import { Constants } from '../../constants/Constants';
interface Props {
    id?: string;
    content: string;
    name?: string;
    class?: string;
    style?: any;
    type?: 'submit' | 'reset' | 'button' | undefined;
    loading? : boolean;
    disable?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement> | undefined) => void;
    subContent?: any;
    onBlur?: any;
    form?: string;
}


export default function MyButton(props: Props) {
  return (
    <button id={props.id} className={props.class} style={{...props.style, ...props.loading && {minWidth: '120px'}, ...props.disable && !props.loading && {borderColor: Constants.grey, backgroundColor : Constants.grey}}} type={props.type} disabled={props.disable || props.loading} onClick={props.onClick} onBlur={props.onBlur} form={props.form}>
      {props.subContent ? props.subContent : undefined}{props.loading ? (<> <i className='bx bx-loader bx-spin font-size-16 align-middle me-2'></i>{" "}Loading</>) : `${props.subContent ? " " : ""}${props.content}` } 
    </button>
  )
}
