import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { SalesTargetListObject } from '../../app/models/salesTarget';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getMonths, getYears, newSpaceBeforeCapitalLetter, returnThousandSeperator } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import MyButton from "../../app/components/form/MyButton";
import SalesTargetDuplicateModal from "./Modal/SalesTargetDuplicateModal";
import { PaginationRequestBody } from "../../app/models/pagination";

const SalesTarget = () => {
  const intl = useIntl();
  //Use Store
  const { salesTargetStore, positionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { salesTargetList, salesTargetPaginationParams, getSalesTarget, deleteSalesTarget, setSalesTargetPaginationParams } = salesTargetStore;
  const { positionDropdownList, getPositionDropdown} = positionStore;
  //Data List
  const [selectedRow, setSelectedRow] = useState<SalesTargetListObject | undefined>(undefined);
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>();
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [positionListFinal, setPositionListFinal] = useState<ObjectForDropdown[]>([]);
  const AllPosition = `${intl.formatMessage({ id: "AllPosition" })}`;
  const [selectedPositionName, setSelectedPositionName] = useState(AllPosition);
  const [selectedPositionId, setSelectedPositionId] = useState(" ");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [salesTargetDuplicateModal, setSalesTargetDuplicateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: "" }];
  const blnPermissionManageSalesTarget = checkPermission([PermissionConstants.ManageSalesTarget]);
  const blnPermissionCreateSalesTarget = checkPermission([PermissionConstants.CreateSalesTarget]);
  const blnPermissionUpdateSalesTarget = checkPermission([PermissionConstants.UpdateSalesTarget]);
  const blnPermissionDeleteSalesTarget = checkPermission([PermissionConstants.DeleteSalesTarget]);
  const [showDropdown, setShowDropdown] = useState(false);

  let salesTargetRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize, 
    year: selectedYear,
    selectedMonthDisplay: selectedMonth, 
    month: selectedMonthValue,
    positionId: selectedPositionId,
    positionName: selectedPositionName
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesTargetListAPI() {
      setLoading(true)

      let blnSetDate = false;
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.salesTarget)
      if (blnBackNavigation) {
        if (salesTargetPaginationParams) {
          setCurrentPage(salesTargetPaginationParams.pageNumber )
          setSelectedMonth(salesTargetPaginationParams.selectedMonthDisplay || moment().format("MMM"))
          setSelectedMonthValue(salesTargetPaginationParams.month || Number(moment().format("M")))
          setSelectedYear(salesTargetPaginationParams.year || Number(moment().format('yyyy')))
          setSelectedPositionId(salesTargetPaginationParams.positionId || " ")
          setSelectedPositionName(salesTargetPaginationParams.positionName || AllPosition)
          salesTargetRequestBody = salesTargetPaginationParams;
          blnSetDate = true;
        }
      }
      else {
        setSalesTargetPaginationParams(undefined);
      }
      
      if (!blnSetDate) {
        setSelectedMonth(moment().format("MMM"))
        setSelectedMonthValue(Number(moment().format("M")))
        setSelectedYear(Number(moment().format('yyyy')))
      }
  
      setShowDropdown(true);

      let aryAPI: any = [
        getPositionDropdown("1")
      ];
      let resultAPI = await Promise.all(aryAPI);
      setPositionListFinal(arraySpliceInAllValue(resultAPI[0], AllPosition, false, "name", "id"))
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesTarget], true)) {
      return;
    }

    fetchSalesTargetListAPI();
    setMonthList(getMonths());
    setYearList(getYears());

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.salesTarget)
        if (!blnSetParams) {
          setSalesTargetPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedYear && selectedMonthValue) {
      fetchSalesTargetAPI(true);
    }
  }, [selectedYear, selectedMonthValue])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchSalesTargetAPI(true);
    }
  }, [selectedPositionId])

  async function fetchSalesTargetAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getSalesTarget(salesTargetRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: SalesTargetListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const SalesTargetColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <div>
          {returnThousandSeperator(row.from)}
        </div>,
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <div>
          {returnThousandSeperator(row.to)}
        </div>,
    },
    {
      dataField: "commissionType",
      text: intl.formatMessage({ id: "CommissionType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.commissionType)}</div>, //Custom UI to display
    },
    {
      dataField: "commissionValue",
      text: intl.formatMessage({ id: "CommissionValue" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <div>
        {row.commissionType === Constants.byAmount ? returnThousandSeperator(row.commissionValue) :  row.commissionValue}
      </div>,
    },
    {
      dataField: "positionName",
      text: intl.formatMessage({ id: "PositionName" }).toUpperCase(),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.salesTarget}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateSalesTarget
              &&
              <TableActionButton 
                to={`/${RoutesList.salesTarget}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteSalesTarget
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
        title={intl.formatMessage({ id: "SalesTarget" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateSalesTarget
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
              onClick={() => { history.push(`/${RoutesList.salesTarget}/add`) }}
              linkTo={`/${RoutesList.salesTarget}/add`}
              disable={localLoading || loading} />
            <MyButton
              content={intl.formatMessage({ id: "DuplicateWithModuleName" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
              onClick={() => { setSalesTargetDuplicateModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={loading} />
          </>
        }
        addButtonXL={"4"}>
        {
          showDropdown
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Position" })}
                name="positionName"
                options={positionListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedPositionName}
                initialValue={selectedPositionId}
                setFieldLabel={setSelectedPositionName}
                setFieldValue={setSelectedPositionId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={(value) => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                  // getCustomerWithLoading(value);
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={SalesTargetColumns}
              data={salesTargetList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
              objSorted={defaultSorted}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              selectedMonthDisplay={selectedMonth}
              positionId={selectedPositionId}
              positionName={selectedPositionName}
              requestAPI={getSalesTarget} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteSalesTarget(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (salesTargetList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getSalesTarget({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear, month: selectedMonthValue })
            setLoading(false);
          }} />
        {
          salesTargetDuplicateModal
          &&
          <SalesTargetDuplicateModal 
            blnShow={salesTargetDuplicateModal}
            setModal={setSalesTargetDuplicateModal}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesTarget);
