import { PaginationRequestBody } from "../models/pagination";
import { LeadListObject, LeadAddObject, LeadUpdateObject, LeadDetailObject, FollowUpAddObject, FollowUpUpdateObject, FollowUpListObject, FollowUpDetailObject, ChangeLeadStatusRequestBody, LeadExportObject, LeadDropdownObject, LeadImportObject, LeadImportSuccessObject, LeadDuplicateListObject, LeadDuplicateDetailObject, ReassignLeadObject } from "../models/lead";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { downloadExcel } from "../common/function/function";

export default class leadStore {
  leadList: LeadListObject[] = [];
  leadDropdownList: LeadListObject[] = [];
  leadDetail: LeadDetailObject | undefined = undefined;
  followUpList: FollowUpListObject[] = [];
  followUpDetail: FollowUpDetailObject | undefined = undefined;
  leadDuplicateList: LeadDuplicateListObject[] = [];
  leadDuplicateDetail: LeadDuplicateDetailObject | undefined = undefined;
  leadPaginationParams: PaginationRequestBody | undefined = undefined;
  leadDuplicatePaginationParams: PaginationRequestBody | undefined = undefined;
  followUpTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.leadList = [];
    this.leadDropdownList = [];
    this.leadDetail = undefined;
    this.followUpList = [];
    this.followUpDetail = undefined;
    this.leadDuplicateList = [];
    this.leadDuplicateDetail = undefined;
    this.leadPaginationParams= undefined;
    this.followUpTotalItems = 0;
  }

  setLeadDetail = (leadDetail: LeadDetailObject | undefined) => {
    this.leadDetail = leadDetail;
  }

  setFollowUpDetail = (followUpDetail: FollowUpDetailObject | undefined) => {
    this.followUpDetail = followUpDetail;
  }

  setLeadDuplicateDetail = (leadDuplicateDetail: LeadDuplicateDetailObject | undefined) => {
    this.leadDuplicateDetail = leadDuplicateDetail;
  }

  setLeadPaginationParams = (leadPaginationParams: PaginationRequestBody | undefined) => {
    this.leadPaginationParams = leadPaginationParams;
  }

  setLeadDuplicatePaginationParams = (leadDuplicatePaginationParams: PaginationRequestBody | undefined) => {
    this.leadDuplicatePaginationParams = leadDuplicatePaginationParams;
  }

  getLead = async (PaginationRequestBody: PaginationRequestBody, blnReturnResultOnly : boolean = false) => {
    this.setLeadPaginationParams(PaginationRequestBody);
    try{
      const resultLead = await agent.Lead.leadList(PaginationRequestBody);
      if (!blnReturnResultOnly) {
        runInAction(() => {
          this.leadList = resultLead.data;
          store.commonStore.setTotalItem(resultLead.pagination.totalItems);
        });
      }
      return Promise.resolve(resultLead.data);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      if (!blnReturnResultOnly) {
        this.leadList = [];
      }
    }
  }

  getLeadDropdown = async (leadDropDownRequestBody: LeadDropdownObject) => {
    try{
      const resultLeadDropdownList = await agent.Lead.leadDropdown(leadDropDownRequestBody);
      runInAction(() => {
        this.leadDropdownList = resultLeadDropdownList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadDropdownList = [];
    }
  }

  getLeadWithId = async (id: string) => {
    try{
      const resultLeadDetail = await agent.Lead.leadDetail(id);
      runInAction(() => {
        this.leadDetail = resultLeadDetail;
      });
      return Promise.resolve(resultLeadDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadDetail = undefined;
    }
  }

  addLead = async (leadRequestBody: LeadAddObject) => {
    try{
      const resultLead = await agent.Lead.addLead(leadRequestBody);
      if (!resultLead.isRejoin && !resultLead.isCrossBrand) {
        store.commonStore.setSuccessMessage(`LeadAddSuccess`)
      }
      else {
        return Promise.resolve(resultLead);
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateLead = async (leadRequestBody: LeadUpdateObject) => {
    try{
      const resultUpdateLead =  await agent.Lead.updateLead(leadRequestBody);
      if (!resultUpdateLead.isRejoin && !resultUpdateLead.isCrossBrand) {
        store.commonStore.setSuccessMessage(`LeadUpdateSuccess`)
      }
      else {
        return Promise.resolve(resultUpdateLead);
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteLead  = async (id: string, name: string) => {
    try {
      await agent.Lead.deleteLead(id);
      store.commonStore.setSuccessMessage(`LeadDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  changeLeadStatus = async (changeLeadStatusRequestBody: ChangeLeadStatusRequestBody) => {
    try{
      if (changeLeadStatusRequestBody.type === Constants.lead) {
        await agent.Lead.changeLeadStatus(changeLeadStatusRequestBody);
        // 0 = Open
        // 4 = Closed
        // 5 = Others
      } else if (changeLeadStatusRequestBody.type === Constants.walkIn) {
        await agent.Lead.changeWalkInLeadStatus(changeLeadStatusRequestBody);
      }
      store.commonStore.setSuccessMessage(changeLeadStatusRequestBody.status === 0 ? `LeadRecordReopenSuccess`: changeLeadStatusRequestBody.status === 5 ? `LeadRecordNotInterestedSuccess` : `LeadRecordCloseSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportLead = async (LeadExportRequestBody: LeadExportObject) => {
    try{
      await downloadExcel(`/lead/export`, LeadExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importLead = async (platformType: string | number, leadImportRequestBody: LeadImportObject) => {
    try{
      const resutlImportLead = await agent.Lead.importLead(platformType, leadImportRequestBody);
      return Promise.resolve(resutlImportLead);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importLeadConfirmation = async (leadImportRequestBody: LeadImportSuccessObject) => {
    try{
      await agent.Lead.importLeadConfirmation(leadImportRequestBody);
      store.commonStore.setSuccessMessage(`LeadImportSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getFollowUp = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultFollowUp = await agent.Lead.followUpList(PaginationRequestBody);
      runInAction(() => {
        this.followUpList = resultFollowUp.data;
        this.followUpTotalItems = resultFollowUp.pagination.totalItems
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.followUpList = [];
      this.followUpTotalItems = 0;
    }
  }

  getFollowUpWithId = async (id: string) => {
    try{
      const resultFollowUpDetail = await agent.Lead.followUpDetail(id);
      runInAction(() => {
        this.followUpDetail = resultFollowUpDetail;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.followUpDetail = undefined;
      return Promise.resolve({status: Constants.failed});
    }
  }

  addFollowUp = async (followUpRequestBody: FollowUpAddObject) => {
    try{
      await agent.Lead.addFollowUp(followUpRequestBody);
      store.commonStore.setSuccessMessage("Lead Follow Up has added successfully.")
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateFollowUp = async (followUpRequestBody: FollowUpUpdateObject) => {
    try{
      await agent.Lead.updateFollowUp(followUpRequestBody);
      store.commonStore.setSuccessMessage(`LeadFollowUpUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteFollowUp  = async (id: string) => {
    try {
      await agent.Lead.deleteFollowUp(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`LeadFollowUpDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  } 

  getLeadDuplicate = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setLeadDuplicatePaginationParams(PaginationRequestBody);
    try{
      const resultLeadDuplicate = await agent.Lead.leadDuplicateList(PaginationRequestBody);
      runInAction(() => {
        this.leadDuplicateList = resultLeadDuplicate.data;
        store.commonStore.setTotalItem(resultLeadDuplicate.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadDuplicateList = [];
    }
  }

  getLeadDuplicateWithId = async (id: string) => {
    try{
      const resultLeadDuplicateDetail = await agent.Lead.leadDuplicateDetail(id);
      runInAction(() => {
        this.leadDuplicateDetail = resultLeadDuplicateDetail;
      });
      return Promise.resolve(resultLeadDuplicateDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadDuplicateDetail = undefined;
    }
  }

  deleteLeadDuplicate  = async (id: string, name: string) => {
    try {
      await agent.Lead.deleteLeadDuplicate(id);
      store.commonStore.setSuccessMessage(`LeadDuplicateDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reassignLead = async (reassignLeadRequestBody: ReassignLeadObject) => {
    try{
      await agent.Lead.reassignLead(reassignLeadRequestBody);
      store.commonStore.setSuccessMessage(`LeadReassignSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}