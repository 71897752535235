import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";

const PositionDetail = () => {
	//Use Params
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { positionStore, staticReferenceStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addPosition, updatePosition, positionDetail, setPositionDetail, getPositionWithId } = positionStore;
	const {employeeType, commissionEarnType, getStaticReferenceWithType} = staticReferenceStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "Position" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "Position" }), urlPath: RoutesList.position }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdatePosition = checkPermission([PermissionConstants.UpdatePosition]);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: positionDetail || {
			name: "",
			employeeType: "",
			commissionEarnType: "",
			description: "",
			displayOrder: 1,
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PositionName" }) })),
			employeeType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EmployeeType" }) })),
			commissionEarnType: Yup.string().test(
        "commissionEarnType",
        intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionEarnType" }) }),
        (value: any, context) => {
          if (!blnHqStaffType) {
            return value;
          }
          return true;
        }
      ).nullable(),
			displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);

			if(blnHqStaffType){
				delete valuesTemp.commissionEarnType
			}

			try {
				if (addAction) {
					await addPosition(valuesTemp);
				} else {
					await updatePosition(valuesTemp);
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		async function fetchPositionDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
				getStaticReferenceWithType(Constants.employeeType),
				getStaticReferenceWithType(Constants.commissionEarnType),
			];
			if (id && !addAction) {
				aryAPI.push(getPositionWithId(id));
			}
			await Promise.all(aryAPI);
			setLoading(false);
		}

		if (addAction) {
			setPositionDetail(undefined);
		}

		if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePosition], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePosition], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePosition], true)) {
        return;
      }
    }

		if(viewAction && blnPermissionUpdatePosition){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.position}/edit/${id}`)} })
    }

		fetchPositionDetailAPI();

		return (() => {
			if (id && !addAction) {
				setPositionDetail(undefined);
			}
		})
	}, []);

	useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	const blnHqStaffType = removeAllSpace(validation.values.employeeType) === Constants.hqStaff;

	const onChangeEmployeeType = () => {
		if(blnHqStaffType){
			validation.setFieldValue("commissionEarnType", "")
		}
  }

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}
				burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl={12}>
								<LineBreakWithTittle
									paddingBottom="0px"
									title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Position" }) })}
									h4Title />
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "PositionName" })}
										name="name"
										className="mb-3 mt-4"
										type="text"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<DropDownWithTitle
										name={"employeeType"}
										title={intl.formatMessage({ id: "EmployeeType" })}
										specifyReturnFieldName={[
											{
												field: "employeeType",
												value: "displayValue",
											},
										]}
										labelField={"displayValue"}
										valueField={"displayValue"}
										blnValueWithNewSpace={true}
										options={employeeType}
										disabled={disabledFieldInput ||viewAction}
										initialValue={!addAction? validation.values.employeeType: undefined}
										validationRequired={true}
										validation={validation}
										onChangeFunction={onChangeEmployeeType}
									/>
								</SingleColumnRowParent>
								{
									validation.values.employeeType && 
									!blnHqStaffType &&
									<SingleColumnRowParent>
                   <DropDownWithTitle
                      name={"commissionEarnType"}
                      title={intl.formatMessage({ id: "CommissionEarnType" })}
                      specifyReturnFieldName={[{ "field": "commissionEarnType", "value": "displayValue" }]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={commissionEarnType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.commissionEarnType : undefined}
                      validationRequired={blnHqStaffType ? false : true}
                      validation={validation} />
									</SingleColumnRowParent>
								}
								<SingleColumnRowParent>
									<GeneralTextArea
										title={intl.formatMessage({ id: "Description" })}
										name="description"
										row={5}
										disabled={disabledFieldInput || viewAction}
										validation={validation} />
								</SingleColumnRowParent>
								<SingleColumnRowParent>
									<GeneralInput
										title={intl.formatMessage({ id: "DisplayOrder" })}
										name="displayOrder"
										type="number"
										disabled={disabledFieldInput || viewAction}
										validationRequired={true}
										validation={validation}
									/>
								</SingleColumnRowParent>
							</Col>
						</Row>
						<GeneralSubmitAndCancelBtn
							successMessage={successMessage}
							viewAction={viewAction}
							validation={validation}
						/>
					</Form>
				)}
			</DetailViewLayout>
		</div>
	);
};

export default observer(PositionDetail);
