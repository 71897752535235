import { PaginationRequestBody } from "../models/pagination";
import { EISListObject, EISAddObject, EISUpdateObject, EISDetailObject } from "../models/eis";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class EISStore {
  eisList: EISListObject[] = [];
  eisDetail: EISDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.eisList = [];
    this.eisDetail = undefined;
  }

  setEISDetail = (eisDetail: EISDetailObject | undefined) => {
    this.eisDetail = eisDetail;
  }
  
  getEIS = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultEIS = await agent.EIS.eisList(PaginationRequestBody);
      runInAction(() => {
        this.eisList = resultEIS.data;
        store.commonStore.setTotalItem(resultEIS.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.eisList =  [];
    }
  }

  getEISWithId = async (id: string) => {
    try{
      const resultEISDetail = await agent.EIS.eisDetail(id);
      runInAction(() => {
        this.eisDetail = resultEISDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.eisDetail = undefined;
    }
  }

  addEIS = async (eisRequestBody: EISAddObject) => {
    try{
      await agent.EIS.addEIS(eisRequestBody);
      store.commonStore.setSuccessMessage(`EISAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateEIS = async (eisRequestBody: EISUpdateObject) => {
    try{
      await agent.EIS.updateEIS(eisRequestBody);
      store.commonStore.setSuccessMessage(`EISUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteEIS  = async (id: string, name: string) => {
    try {
      await agent.EIS.deleteEIS(id);
      store.commonStore.setSuccessMessage(`EISDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}