import { PaginationRequestBody } from "../models/pagination";
import { StockMovementAddObject, StockMovementDetailObject, StockMovementListObject, StockMovementUpdateObject } from "../models/stockMovement";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class StockMovementStore {
  stockMovementList: StockMovementListObject[] = [];
  stockMovementDetail: StockMovementDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.stockMovementList = [];
    this.stockMovementDetail = undefined;
  }

  setStockMovementList = (stockMovementList: StockMovementListObject[]) => {
    this.stockMovementList = stockMovementList;
  }
  
  setStockMovementDetail = (stockMovementDetail: StockMovementDetailObject | undefined) => {
    this.stockMovementDetail = stockMovementDetail;
  }
  
  getStockMovement = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultStockMovement = await agent.StockMovement.stockMovementList(PaginationRequestBody);
      runInAction(() => {
        this.stockMovementList = resultStockMovement.data;
        store.commonStore.setTotalItem(resultStockMovement.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockMovementList = [];
    }
  }

  getStockMovementWithId = async (id: string) => {
    try{
      const resultStockMovementDetail = await agent.StockMovement.stockMovementDetail(id);
      runInAction(() => {
        this.stockMovementDetail = resultStockMovementDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockMovementDetail = undefined;
    }
  }

  addStockMovement = async (stockMovementRequestBody: StockMovementAddObject) => {
    try{
      await agent.StockMovement.addStockMovement(stockMovementRequestBody);
      store.commonStore.setSuccessMessage(`StockMovementAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockMovement = async (stockMovementRequestBody: StockMovementUpdateObject) => {
    try{
      await agent.StockMovement.updateStockMovement(stockMovementRequestBody);
      store.commonStore.setSuccessMessage(`StockMovementUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockMovement  = async (id: string, name: string) => {
    try {
      await agent.StockMovement.deleteStockMovement(id);
      store.commonStore.setSuccessMessage(`StockMovementDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}