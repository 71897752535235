import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { PurchaseDeliverOrderListObject } from "../../app/models/purchaseDeliverOrder";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { purchaseDeliverOrderViewAvailable, purchaseDeliverOrderStatusColor, purchaseDeliverOrderDeleteAvailable, newSpaceBeforeCapitalLetter, purchaseDeliverOrderEditViewAvailable, checkPermission, purchaseDeliverOrderEditAvailable } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const PurchaseDeliverOrder = () => {
  const intl = useIntl();

  //Use Store
  const { purchaseDeliverOrderStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { purchaseDeliverOrderList, getPurchaseDeliverOrder, deletePurchaseDeliverOrder } =
    purchaseDeliverOrderStore;
  const { purchaseDeliverOrderStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PurchaseDeliverOrderListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedPurchaseDeliverOrderStatus, setSelectedPurchaseDeliverOrderStatus] = useState<number | string>(AllStatus);
  const [selectedPurchaseDeliverOrderStatusId, setSelectedPurchaseDeliverOrderStatusId] = useState<number | string | undefined>(" ");
  const [purchaseDeliverOrderStatusList, setPurchaseDeliverOrderStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionUpdatePurchaseDeliverOrder = checkPermission([PermissionConstants.UpdatePurchaseDeliverOrder]);
  const blnPermissionReviewPurchaseDeliverOrder = checkPermission([PermissionConstants.ReviewPurchaseDeliverOrder]);
  const blnPermissionDeletePurchaseDeliverOrder = checkPermission([PermissionConstants.DeletePurchaseDeliverOrder]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchPurchaseDeliverOrderListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchPurchaseDeliverOrderAPI(false),
        getStaticReferenceWithType(Constants.purchaseDeliverOrderStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setPurchaseDeliverOrderStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePurchaseDeliverOrder], true)) {
      return;
    }

    fetchPurchaseDeliverOrderListAPI();
  }, []);

  useEffect(() => {
    if (
      (selectedPurchaseDeliverOrderStatusId || selectedPurchaseDeliverOrderStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchPurchaseDeliverOrderAPI(true);
    }
  }, [selectedPurchaseDeliverOrderStatusId]);

  async function fetchPurchaseDeliverOrderAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPurchaseDeliverOrder({
      pageNumber: currentPage,
      pageSize: pageSize,
      purchaseDeliverOrderStatus: selectedPurchaseDeliverOrderStatusId,
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: PurchaseDeliverOrderListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  };

  //Table Content UI
  const PurchaseDeliverOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "purchaseDeliverOrderNo",
      text: intl.formatMessage({ id: "PurchaseDeliverOrderNo" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "supplierName",
      text: intl.formatMessage({ id: "Supplier" }).toUpperCase(),
    },
    {
      dataField: "receiverName",
      text: intl.formatMessage({ id: "Receiver" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={purchaseDeliverOrderStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.purchaseDeliverOrder}/view/${row.id}`}
              type={'view'}/>
            {
              (blnPermissionUpdatePurchaseDeliverOrder || blnPermissionReviewPurchaseDeliverOrder)
              &&
              purchaseDeliverOrderEditAvailable(row.status)
              &&
              <TableActionButton 
                to={`/${RoutesList.purchaseDeliverOrder}/${purchaseDeliverOrderViewAvailable(row.status) ? 'view' : 'edit'}/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeletePurchaseDeliverOrder
              &&
              !purchaseDeliverOrderViewAvailable(row.status) && row.status !== Constants.acknowledged
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}
        title={intl.formatMessage({ id: "PurchaseDeliverOrder" })}
        addButton={
          checkPermission([PermissionConstants.CreatePurchaseDeliverOrder])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}
            onClick={() => { history.push(`/${RoutesList.purchaseDeliverOrder}/add`) }}
            linkTo={`/${RoutesList.purchaseDeliverOrder}/add`}
            disable={localLoading || loading} />
        }>
        <SingleColumnRowParent blnDropDown={true}>
          <GeneralSelectDropdown
            title={intl.formatMessage({ id: "PurchaseDeliverOrderStatus" })}
            name="purchaseDeliverOrderStatus"
            options={purchaseDeliverOrderStatusList}
            blnValueWithNewSpace={true}
            labelField="displayValue"
            valueField="key"
            initialLabel={selectedPurchaseDeliverOrderStatus}
            initialValue={selectedPurchaseDeliverOrderStatusId}
            setFieldLabel={setSelectedPurchaseDeliverOrderStatus}
            setFieldValue={setSelectedPurchaseDeliverOrderStatusId}
            disabled={localLoading || loading}
            validationRequired={true}
            onChange={() => {
              setCurrentPage(1);
              setErrorMessage("");
              setSuccessMessage("");
            }}
          />
        </SingleColumnRowParent>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={PurchaseDeliverOrderColumns}
              data={purchaseDeliverOrderList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PurchaseDeliverOrder" }) })}
              objSorted={defaultSorted}
              purchaseDeliverOrderStatus={selectedPurchaseDeliverOrderStatusId}
              requestAPI={getPurchaseDeliverOrder}
            />
            <DeleteModal
              displayName={selectedRow?.purchaseDeliverOrderNo}
              onDeleteClick={async () => {
                setLoading(true);
                await deletePurchaseDeliverOrder(
                  selectedRow?.id || "",
                  selectedRow?.purchaseDeliverOrderNo || ""
                );
                let currentPageTemp = currentPage;
                if (purchaseDeliverOrderList.length === 1 && currentPage !== 1) {
                  setCurrentPage((value) => value - 1);
                  currentPageTemp = currentPageTemp - 1;
                }
                await getPurchaseDeliverOrder({
                  pageNumber: currentPageTemp,
                  pageSize: pageSize,
                });
                setLoading(false);
              }}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(PurchaseDeliverOrder);
