import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import * as Yup from "yup";
import { useFormik } from "formik";
import _ from 'lodash';
import {
  Row,
  Col,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label
} from "reactstrap";
import GeneralInput from '../../app/components/form/GeneralInput';
import GeneralTextArea from '../../app/components/form/GeneralTextArea';
import Loading from "../../app/components/loading/Loading";
import { AddOptionsValueField, arraySpliceInAllValue, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, removeAllSpace, returnBlobImage, returnCurrency, returnRouteFromModule } from "../../app/common/function/function";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForDropdown, ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import TableWithSelectAllCheckBoxAndInput from "../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import DropZoneUploadWidget from "../../app/components/form/DropZoneUploadWidget";
import classnames from "classnames"
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { BranchForProductObject } from "../../app/models/product";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import ProductBatchesDetailModal from "./Modal/ProductBatchesDetailModal";
import ProductStockOutModal from "./Modal/ProductStockOutModal";
import PaginationTable from "../../app/components/table/PaginationTable";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import moment from "moment";
import { Link } from "react-router-dom"
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";

const ProductDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { productStore, commonStore, staticReferenceStore, categoryStore, salesCategoryStore, taxStore, measurementStore, branchStore, settingsStore, stockMovementStore } = useStore();
  const {
    windowSize,
    errorMessage,
    successMessage,
    loading,
    totalItem,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addProduct, updateProduct, productDetail, productBranchAccess, getProductWithId, getProductBranchAccess, setProductDetail, stockOutProduct } = productStore;
  const { productType, stockOutProductReasonType, getStaticReferenceWithType } = staticReferenceStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;
  const { salesCategoryDropdownList, getSalesCategoryDropdown } = salesCategoryStore;
  const { taxDropdownList, getTaxDropdown } = taxStore;
  const { measurementDropdownList, getMeasurementDropdown } = measurementStore;
  const { isEligibleAllBranch, getBranchDropDown, getBranchProductBatch } = branchStore;
  const { stockMovementList, getStockMovement } = stockMovementStore;

  const { generalSettings, getGeneralSettings } = settingsStore;

  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${productDetail?.name ? ` - ${productDetail?.name}` : intl.formatMessage({ id: "Product" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Products" }), urlPath: RoutesList.product }];
  const [activeTab, setActiveTab] = useState("1");
  const tabBar = addAction ? [intl.formatMessage({ id: "Basic" }), intl.formatMessage({ id: "Branches" })] : [intl.formatMessage({ id: "Basic" }), intl.formatMessage({ id: "Branches" }), intl.formatMessage({ id: "StockMovements" })];
  const [selectedProductType, setSelectedProductType] = useState<ObjectForDropdown[]>([]);
  const [newImageUrl, setNewImageUrl] = useState<string>("");
  const [productInitialMaxReorderQuantity, setProductInitialMaxReorderQuantity] = useState(0);
  const [reRenderBranches, setRenderBranches] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  const [productModal, setProductModal] = useState(false);
  const [stockOutProductModal, setStockOutProductModal] = useState(false);
  const [selectedProductDetails, setSelectedProductDetails] = useState<BranchForProductObject | undefined>(undefined);
  const hideCostPrice = generalSettings?.isInventoryModuleOn || getBranchUser();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnHQStaff = !getBranchUser();
  const blnShowAppolous = generalSettings?.isShowAppolous;

  //Default Sorting Type
  const defaultSorted = [];

  const viewProductBatchDetails = (index) => {
    if (validation.values.branchProducts.length > index) {
      setSelectedProductDetails(validation.values.branchProducts[index])
      setProductModal(true);
    }
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "SellingPrice" }),
      type: "input",
      xl: "3",
      inputName: "sellingPrice",
      inputType: "number",
      inputGroup: true,
      inputGroupText: returnCurrency(),
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "Qty" }),
      type: "label",
      xl: "1.5",
      inputName: "quantity",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "DefectQty" }),
      type: "label",
      xl: "1.5",
      inputName: "defectQuantity",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "MinimumThreshold" }),
      type: "input",
      xl: "2",
      inputName: "minimumThreshold",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "MaxReorderQty" }),
      type: "input",
      xl: "2",
      inputName: "maxReorderQuantity",
      inputType: "number",
    },
    {
      label: `${intl.formatMessage({ id: "MoreInfo" })}`,
      type: "dynamicButton",
      xl: "2",
      textAlign: true,
      textValueAlign: true,
      inputName: "",
      inputType: "row",
      rowButtonTitle: "View",
      disabled: addAction,
      dynamicButtonFunction: viewProductBatchDetails
    },
  ];
  
  //Table Content UI
  const StockMovementColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "affectedEntityName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
          <div className="">
            {row.affectedEntityName || Constants.emptyData}
          </div>
      ),
    },
    {
      dataField: "referenceEntityName",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      formatter: (cellContent, row) => {
        if (row.referenceEntity === Constants.manualStockOut || row.referenceEntity === Constants.customerTransfer) {
          return (
            <div>
              {newSpaceBeforeCapitalLetter(row.referenceEntity) || Constants.emptyData}
            </div>
          )
        }
        else {
          if (row.referenceEntityId === Constants.emptyId){
            return (
              <div>
                {newSpaceBeforeCapitalLetter(row.referenceEntityName) || Constants.emptyData}
              </div>
            )
          }
          else {
            if (row.referenceEntity){
              let routePath = returnRouteFromModule(row.referenceEntity);
              if (routePath !== undefined){
                let finalRoutePath = `/${routePath}/view/${row.referenceEntityId}`;
                return (
                  <Link className="d-block text-primary mb-2 h6"
                    to={finalRoutePath}
                    target={Constants.blank}
                    rel="noopener noreferrer">
                    {row.referenceEntityName}
                  </Link>
                )
              }
              else {
                return (
                  <div>
                    {newSpaceBeforeCapitalLetter(row.referenceEntityName) || Constants.emptyData}
                  </div>
                )
              }
            }
          }
        }
      },
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "BalanceQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.balanceQuantity)}
            statusBackgroundColor={Math.sign(row.balanceQuantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "reservedQuantity",
      text: intl.formatMessage({ id: "ReservedQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.reservedQuantity)}
            statusBackgroundColor={Math.sign(row.reservedQuantity) == -1 ? Constants.peach : Constants.tabBlue } />
        </>
      ),
    },
    {
      dataField: "balanceReservedQuantity",
      text: intl.formatMessage({ id: "BalanceReservedQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
              status={(row.balanceReservedQuantity)}
              statusBackgroundColor={Math.sign(row.balanceReservedQuantity) == -1 ? Constants.peach : Constants.tabBlue} />          
        </>
      ),
    },
    {
      dataField: "actualQuantity",
      text: intl.formatMessage({ id: "PhysicalQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.actualQuantity)}
            statusBackgroundColor={Math.sign(row.actualQuantity) == -1 ? Constants.palepink : Constants.darkgreen} />
        </>
      ),
    }
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: productDetail || {
      name: "",
      description: "",
      sku: "",
      costPrice: 0,
      sellingPrice: undefined,
      floorPrice: undefined,
      initialMinimumThreshold: 0,
      initialMaxReorderQuantity: 0,
      displayOrder: 1,
      productTypes: [],
      categoryId: "",
      categoryName: "",
      salesCategoryId: "",
      salesCategoryName: "",
      taxId: "",
      taxName: "",
      measurementId: "",
      measurementName: "",
      orderMeasurementId: "",
      orderMeasurementName: "",
      unitConvertionRate: 0,
      measurementQuantity: undefined,
      branchProducts: productBranchAccess,
      file: undefined,
      photoUrl: "",
      appolousId: null,
      isSuspended: false,
      isActive: true
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ProductName" }) })),
      sku: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SKU" }) })),
      taxId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Tax" }) })),
      costPrice: Yup.string().test(
        "costPrice",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CostPrice" }) }),
        (value: any, context) => {
          if (!hideCostPrice) {
            return value;
          }
          return true;
        }
      ).nullable(),
      initialMinimumThreshold: Yup.number()
        .test("initialMaxReorderQuantity", intl.formatMessage({ id: "MoreThanInitialMaxReorderQuantity" }), (value: any, context) => {
          if (value > productInitialMaxReorderQuantity) {
            return false;
          }
          return true;
        }),
      productTypes: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "ProductType" }) })),
      categoryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Category" }) })),
      measurementId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Measurement" }) })),
      orderMeasurementId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "OrderMeasurement" }) })),
      unitConvertionRate: Yup.number().min(1).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "UnitConvertionRate" }) })),
      salesCategoryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesCategory" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);

      if (blnHideSellingAndProductType) {
        valuesTemp.sellingPrice = 1;
        valuesTemp.floorPrice = 1;
      }

      if (blnShowAppolous) {
        if(valuesTemp.appolousId !== null && valuesTemp.appolousId === 0){
          setErrorMessage(`${intl.formatMessage({ id: "ZeroAppolousId" })}`) 
          return;
        }
      }

      try {
        if (newImageUrl) {
          let fileTemp = await returnBlobImage(newImageUrl);
          valuesTemp.file = fileTemp;
        }

        valuesTemp.productTypes = valuesTemp.productTypes.map((value) => (removeAllSpace(value)));

        if (addAction) {
          await addProduct(valuesTemp);
        } else {
          await updateProduct(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const productTypesTemp = validation.values.productTypes || [];
  const blnPurchasableProduct = productTypesTemp.indexOf(Constants.purchasable) > -1;
  const blnCompanyAssetsProduct = productTypesTemp.indexOf(Constants.companyAssest) > -1;
  const blnMachineryProduct = productTypesTemp.indexOf(Constants.machinery) > -1;
  const blnHideSellingAndProductType = !blnPurchasableProduct && !blnCompanyAssetsProduct && !blnMachineryProduct && productTypesTemp.length > 0;
  const blnShowAppolousViewId = viewAction && validation.values.appolousId !== null;

  const blnPermissionUpdateProduct = checkPermission([PermissionConstants.UpdateProduct]);
  const blnPermissionCreateStockOutProduct = checkPermission([PermissionConstants.CreateStockOutProduct]);

  useEffect(() => {
    async function fetchCountryDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getProductBranchAccess(),
        getStaticReferenceWithType(Constants.productType),
        getCategoryDropdown(),
        getSalesCategoryDropdown(),
        getTaxDropdown(),
        getMeasurementDropdown(),
        getGeneralSettings(),
        getStaticReferenceWithType(Constants.stockOutProductReasonType),
      ];
      if (id && !addAction) {
        aryAPI.push(getProductWithId(id), getBranchDropDown())
        fetchStockMovementAPI()
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[9]) {
        setBranchListFinal(arraySpliceInAllValue(resultAPI[9], isEligibleAllBranch ? AllBranch : "", true))
      }

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (addAction) {
      setProductDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateProduct], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateProduct], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageProduct], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateProduct){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.product}/edit/${id}`)} })
    }
    if(viewAction && blnPermissionCreateStockOutProduct){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "StockOut"})}`, onFunction: () => { setStockOutProductModal(true)} })
    }

    fetchCountryDetailAPI();

    return (() => {
      if (id && !addAction) {
        setProductDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (id && !addAction) {
      if (productDetail) {
        setLoading(true);
        let selectedProductTypeTemp: ObjectForDropdown[] = productDetail.productTypes.map((valueProductTypes: string) => ({
          label: newSpaceBeforeCapitalLetter(valueProductTypes),
          value: newSpaceBeforeCapitalLetter(valueProductTypes)
        }))
        setSelectedProductType(selectedProductTypeTemp)

        setTimeout(()=> {
          setLoading(false);
        }, 100)
      }
    }
  }, [productDetail])

  useEffect(() => {
    if (validation.values.initialMaxReorderQuantity > 0) {
      setProductInitialMaxReorderQuantity(validation.values.initialMaxReorderQuantity)
    }
  }, [validation.values.initialMaxReorderQuantity])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchStockMovementAPI(true);
    }
  }, [selectedBranchId])

  async function fetchStockMovementAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockMovement({
      pageNumber: currentPage,
      pageSize: pageSize,
      branchId: selectedBranchId == AllBranch ? "" : selectedBranchId,
      productId: id
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const displayFooterButtons = () => {
    return (
      <GeneralSubmitAndCancelBtn
        successMessage={successMessage}
        viewAction={viewAction}
        validation={validation}
      />
    )
  }

  const handleMeasurementUnit = (measurementType: string = "") => {
    if (measurementType.toUpperCase() === 'UNIT') {
      validation.setFieldValue("measurementQuantity", 1)
    }
  }

  const handleOrderMeasurementUnit = (measurementType: string = "") => {
    if (measurementType.toUpperCase() === 'UNIT') {
      validation.setFieldValue("unitConvertionRate", 1)
    }
  }

  const updateSellingPrice = (value) => {
    updateBranchField(value, "sellingPrice")
  }

  const updateMinimumThreshold = (value) => {
    updateBranchField(value, "minimumThreshold")
  }

  const updateMaxReorderQuantity = (value) => {
    updateBranchField(value, "maxReorderQuantity")
  }

  const updateBranchField = (value, field) => {
    setRenderBranches(true);
    let branchProductsTemp = _.cloneDeep(validation.values.branchProducts);
    branchProductsTemp = branchProductsTemp.map((valueBranchProduct) => ({
      ...valueBranchProduct,
      [field]: value,
    }))
    validation.setFieldValue("branchProducts", branchProductsTemp)
    setTimeout(() => {
      setRenderBranches(false);
    }, 100)
  }
  
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {
          loading
            ?
            <Loading />
            :
            <Form
              className="checkout-tabs"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="2" sm="12">
                  {/* <Nav className="flex-column vertical-icon"> */}
                  {/* 1200 - 400 */}
                  {
                    windowSize.innerWidth < 1200 && windowSize.innerWidth > 400
                      ?
                      <Row>
                        {
                          tabBar.map((value, indexTab) => (
                            <Col xs={"4"} md={"4"} lg={"4"} xl={"4"}>
                              <Nav className="flex-column" pills>
                                <NavItem
                                  key={`tabkey_${indexTab}`}>
                                  <NavLink
                                    className={classnames({ active: activeTab === String(indexTab + 1) })}
                                    onClick={() => {
                                      toggle(String(indexTab + 1));
                                    }}
                                  >
                                    <i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bxl-dropbox"} check-nav-icon mt-4 mb-2`} />
                                    <p className="font-weight-bold mb-4">{value}</p>
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </Col>
                          ))
                        }
                      </Row>
                      :
                      <Nav className="flex-column" pills>
                        {
                          tabBar.map((value, indexTab) => (
                            <NavItem
                              key={`tabkey_${indexTab}`}>
                              <NavLink
                                className={classnames({ active: activeTab === String(indexTab + 1) })}
                                onClick={() => {
                                  toggle(String(indexTab + 1));
                                }}
                              >
                                <i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bxl-dropbox"} check-nav-icon mt-4 mb-2`} />
                                <p className="font-weight-bold mb-4">{value}</p>
                              </NavLink>
                            </NavItem>
                          ))
                        }
                      </Nav>
                  }
                </Col>
                <Col xl="10">
                  <TabContent
                    activeTab={activeTab}
                    className="mt-4 mt-md-0 content-layout-white"
                  >
                    <TabPane tabId="1">
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
                          className={"mb-4"}
                          h4Title />
                        <Row>
                          <Col xl={6}>
                            {
                              blnShowAppolous
                              &&
                              <SingleColumnRowParent blnDoubleTab={true}>
                                {
                                  blnShowAppolousViewId
                                  &&
                                  <GeneralInputWithClickableUI
                                    title={intl.formatMessage({ id: "ViewAppolous" })}
                                    name="appolousId"
                                    type="number"
                                    disabled={true}
                                    validation={validation}
                                    onClickFunction={()=> {
                                      window.open(`https://app.appolous.com/products/${validation.values.appolousId}/edit`)
                                    }}/>
                                }
                                {
                                  !viewAction
                                  &&
                                  <GeneralInput
                                    title={intl.formatMessage({ id: "AppolousId" })}
                                    name="appolousId"
                                    type="number"
                                    blnNumberOnly={true}
                                    disabled={disabledFieldInput || viewAction}
                                    validationRequired={false}
                                    validation={validation} />
                                }
                              </SingleColumnRowParent>
                            }
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "ProductName" })}
                                name="name"
                                className={blnShowAppolousViewId ? "mt-3 mb-3" : ""}
                                type="text"
                                disabled={disabledFieldInput || viewAction}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitleMultiSelect
                                name={"productTypes"}
                                title={intl.formatMessage({ id: "ProductType" })}
                                specifyReturnFieldName={[{ "field": "productTypes", "value": "value" }]}
                                returnFieldWithLabel={false}
                                labelField={"displayValue"}
                                valueField={"displayValue"}
                                options={productType}
                                disabled={disabledFieldInput || viewAction}
                                blnValueWithNewSpace={true}
                                initialValue={!addAction ? selectedProductType : undefined}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "SKU" })}
                                name="sku"
                                type="text"
                                disabled={disabledFieldInput || viewAction}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            {
                              !blnHideSellingAndProductType
                              &&
                              <SingleColumnRowParent
                                blnDoubleTab>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "SellingPrice" })}
                                  name="sellingPrice"
                                  type="number"
                                  inputGroup={true}
                                  inputGroupText={returnCurrency()}
                                  onChangeFunction={updateSellingPrice}
                                  validationRequired={true}
                                  disabled={disabledFieldInput || viewAction}
                                  validation={validation} />
                              </SingleColumnRowParent>
                            }
                            {
                              !blnHideSellingAndProductType
                              &&
                              <SingleColumnRowParent
                                blnDoubleTab>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "FloorPrice" })}
                                  name="floorPrice"
                                  type="number"
                                  inputGroup={true}
                                  inputGroupText={returnCurrency()}
                                  validationRequired={true}
                                  disabled={disabledFieldInput || viewAction}
                                  validation={validation} />
                              </SingleColumnRowParent>
                            }
                            {
                              !hideCostPrice
                              &&
                              <SingleColumnRowParent
                                blnDoubleTab>
                                <GeneralInput
                                  title="Cost Price"
                                  name="costPrice"
                                  type="number"
                                  inputGroup={true}
                                  inputGroupText="RM"
                                  validationRequired={true}
                                  disabled={disabledFieldInput || viewAction}
                                  validation={validation}/>
                              </SingleColumnRowParent>
                            }
                            {addAction &&
                              <>
                              <SingleColumnRowParent
                                blnDoubleTab>
                                  <GeneralInput
                                    title={intl.formatMessage({ id: "InitialMaxReorderQuantity" })}
                                    name="initialMaxReorderQuantity"
                                    type="number"
                                    onChangeFunction={updateMaxReorderQuantity}
                                    validationRequired={true}
                                    disabled={disabledFieldInput || viewAction}
                                    validation={validation} />
                                </SingleColumnRowParent>
                                <SingleColumnRowParent
                                  blnDoubleTab>
                                  <GeneralInput
                                    title={intl.formatMessage({ id: "InitialMinimumThreshold" })}
                                    name="initialMinimumThreshold"
                                    type="number"
                                    onChangeFunction={updateMinimumThreshold}
                                    validationRequired={true}
                                    disabled={disabledFieldInput || viewAction}
                                    validation={validation} />
                                </SingleColumnRowParent>
                              </>
                            }
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitle
                                name={"categoryId"}
                                title={intl.formatMessage({ id: "Category" })}
                                specifyReturnFieldName={[{ "field": "categoryId", "value": "id" }]}
                                labelField={"name"}
                                valueField={"id"}
                                options={categoryDropdownList}
                                disabled={disabledFieldInput || viewAction}
                                initialLabel={!addAction ? validation.values.categoryName : undefined}
                                initialValue={!addAction ? validation.values.categoryId : undefined}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitle
                                name={"salesCategoryId"}
                                title={intl.formatMessage({ id: "SalesCategory" })}
                                specifyReturnFieldName={[{ "field": "salesCategoryId", "value": "id" }]}
                                labelField={"name"}
                                valueField={"id"}
                                options={salesCategoryDropdownList}
                                disabled={disabledFieldInput || viewAction}
                                initialLabel={!addAction ? validation.values.salesCategoryName : undefined}
                                initialValue={!addAction ? validation.values.salesCategoryId : undefined}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitle
                                name={"taxId"}
                                title={intl.formatMessage({ id: "Tax" })}
                                specifyReturnFieldName={[{ "field": "taxId", "value": "id" }]}
                                labelField={"name"}
                                valueField={"id"}
                                options={taxDropdownList}
                                disabled={disabledFieldInput || viewAction}
                                initialValue={!addAction ? validation.values.taxId : undefined}
                                initialLabel={!addAction ? validation.values.taxName : undefined}
                                validationRequired={true}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent>
                              <GeneralInput
                                title={intl.formatMessage({ id: "Suspended" })}
                                name="isSuspended"
                                type="checkbox"
                                disabled={disabledFieldInput || viewAction}
                                validation={validation}
                                childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent>
                              <GeneralInput
                                title={intl.formatMessage({ id: "Active" })}
                                name="isActive"
                                type="checkbox"
                                disabled={disabledFieldInput || viewAction}
                                validation={validation}
                                childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                            </SingleColumnRowParent>
                          </Col>
                          <Col xl={6}>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitle
                                name={"measurementId"}
                                title={intl.formatMessage({ id: "BaseUnitofMeasure" })}
                                specifyReturnFieldName={[{ "field": "measurementId", "value": "id" }, { "field": "measurementName", "value": "name" }]}
                                labelField={"name"}
                                valueField={"id"}
                                options={measurementDropdownList}
                                disabled={disabledFieldInput || viewAction}
                                initialLabel={!addAction ? validation.values.measurementName : undefined}
                                initialValue={!addAction ? validation.values.measurementId : undefined}
                                validationRequired={true}
                                validation={validation}
                                onChangeField={"label"}
                                onChangeFunction={handleMeasurementUnit} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "MeasurementQuantity" })}
                                name="measurementQuantity"
                                type="number"
                                disabled={disabledFieldInput || viewAction || validation.values.measurementName == 'Unit'}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropDownWithTitle
                                name={"orderMeasurementId"}
                                title={intl.formatMessage({ id: "OrderMeasurement" })}
                                specifyReturnFieldName={[{ "field": "orderMeasurementId", "value": "id" }, { "field": "orderMeasurementName", "value": "name" }]}
                                labelField={"name"}
                                valueField={"id"}
                                options={measurementDropdownList}
                                disabled={disabledFieldInput || viewAction}
                                initialLabel={!addAction ? validation.values.orderMeasurementName : undefined}
                                initialValue={!addAction ? validation.values.orderMeasurementId : undefined}
                                validationRequired={true}
                                validation={validation}
                                onChangeField={"label"}
                                onChangeFunction={handleOrderMeasurementUnit} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "UnitConvertionRate" })}
                                name="unitConvertionRate"
                                type="number"
                                disabled={disabledFieldInput || viewAction || validation.values.orderMeasurementName == 'Unit'}
                                validation={validation}
                                validationRequired={true} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralInput
                                title={intl.formatMessage({ id: "DisplayOrder" })}
                                name="displayOrder"
                                type="number"
                                disabled={disabledFieldInput || viewAction}
                                validationRequired={true}
                                validation={validation}
                              />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <GeneralTextArea
                                title={intl.formatMessage({ id: "Description" })}
                                name="description"
                                row={5}
                                disabled={disabledFieldInput || viewAction}
                                validation={validation} />
                            </SingleColumnRowParent>
                            <SingleColumnRowParent
                              blnDoubleTab>
                              <DropZoneUploadWidget
                                name="file"
                                validation={validation}
                                setNewImageUrl={setNewImageUrl}
                                disabled={disabledFieldInput || viewAction}
                                imageUrl={validation.values.photoUrl}
                              />
                            </SingleColumnRowParent>
                          </Col>
                        </Row>                        
                      </Col>
                      {displayFooterButtons()}
                    </TabPane>
                    <TabPane tabId="2">
                      {
                        !reRenderBranches
                        &&
                        <>
                          <LineBreakWithTittle
                            paddingBottom="0px"
                            title={intl.formatMessage({ id: "Branches" })}
                            h4Title />
                          <TableWithSelectAllCheckBoxAndInput
                            name="branchProducts"
                            title=""
                            blnEditable={!viewAction}
                            aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                            data={validation.values.branchProducts}
                            validation={validation}
                            selectAllTextFieldDisplay={"branchName"}
                            selectAllTextFieldDisplayXLSize={4}
                            fixedHeader={true}
                            windowSizeHeight={windowSize.innerHeight/1.6}
                            disabled={disabledFieldInput} />
                          <div className="mt-3" />
                          {displayFooterButtons()}
                        </>
                      }
                    </TabPane>
                    <TabPane tabId="3">
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "StockMovements" })}
                        h4Title />
                      <Row>
                        <DoubleColumnRowParent>
                          <GeneralSelectDropdown
                            title={intl.formatMessage({ id: "Branch" })}
                            name="branchName"
                            className="mt-3 mb-2"
                            options={branchListFinal}
                            initialLabel={selectedBranchName}
                            initialValue={selectedBranchId}
                            setFieldLabel={setSelectedBranchName}
                            setFieldValue={setSelectedBranchId}
                            disabled={localLoading || loading || !blnHQStaff}
                            validationRequired={true}
                            onChange={() => {
                              setCurrentPage(1);
                              setErrorMessage("");
                              setSuccessMessage("");
                            }}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                      {
                        localLoading
                          ?
                          <Loading />
                          :
                          <PaginationTable
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            totalItem={totalItem}
                            tableColumns={StockMovementColumns}
                            data={stockMovementList}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockMovement" }) })}
                            objSorted={defaultSorted}
                            branchId={selectedBranchId == AllBranch ? "" : selectedBranchId}
                            productId={id}
                            requestAPI={getStockMovement}
                            blnRemoveBackground={true} />
                      }
                      <div className="mt-3" />
                      {displayFooterButtons()}
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Form>
        }
      </DetailViewLayout>
      {
        productModal &&
        <ProductBatchesDetailModal 
        blnShow={productModal}
        setModal={setProductModal}
        selectedProductDetails={selectedProductDetails}
        setSelectedProductDetails={setSelectedProductDetails}
        getBranchProductBatch={getBranchProductBatch}
        getProductName={productDetail!.name}
        getProductId={id}
        />
      }
      {
        stockOutProductModal &&
        <ProductStockOutModal
          blnShow={stockOutProductModal}
          setModal={setStockOutProductModal}
          selectedProductId={id}
          selectedProductName={productDetail?.name || ""}
          optionsStockOutReason={AddOptionsValueField(stockOutProductReasonType, "displayValue", "displayValue")}
          stockOutProduct={stockOutProduct}
          setErrorMessage={setErrorMessage} />
      }
    </div>
  );
}

export default observer(ProductDetail);
