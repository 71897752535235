import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { CustomerSalesOrderDetailObject, CustomerSalesOrderListObject, CustomerSalesOrderObject, CustomerSalesOrderUpgradeSubmitObject, CustomerSalesOrderPaymentObject, CustomerSalesOrderPaymentSuccess, CustomerSalesOrderRedeemListObject, CustomerSalesOrderRedeemSubmitObject, CustomerSalesOrderUpdateObject, CustomerSalesOrderUpdateConsultantObject, CustomerSalesOrderReceiptObject, CustomerSalesOrderSignObject, CustomerSalesOrderVoidObject, CustomerSalesOrderSiteMapObject, CustomerSalesOrderCommissionObject, PackageUpgradeObject, CustomerSalesOrderUpdateSubBrandObject, CustomerSalesOrderProductRedemptionListObject } from "../models/customerSalesOrder";

export default class customerOrderStore {
  customerSalesOrderList: CustomerSalesOrderListObject[] = [];
  customerSalesOrderDetail: CustomerSalesOrderDetailObject | undefined = undefined;
  customerSalesOrderRedeemList: CustomerSalesOrderRedeemListObject[] = [];
  customerSalesOrderReceiptDetail: CustomerSalesOrderReceiptObject | undefined = undefined;
  customerSalesOrderPaginationParams: PaginationRequestBody | undefined = undefined;
  customerSalesOrderSiteMapDetailFromStore: CustomerSalesOrderSiteMapObject | undefined = undefined;
  customerSalesOrderCommissionDetail: CustomerSalesOrderCommissionObject | undefined = undefined;
  customerSalesOrderProductRedemptionList: CustomerSalesOrderProductRedemptionListObject[] = [];
  packageUpgradeDetails: PackageUpgradeObject | undefined = undefined;
  customerSalesOrderTotalItems = 0;
  customerSalesOrderProductRedemptionTotalItems = 0;
  
  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerSalesOrderList = [];
    this.customerSalesOrderDetail = undefined;
    this.customerSalesOrderRedeemList = [];
    this.customerSalesOrderReceiptDetail = undefined;
    this.customerSalesOrderPaginationParams= undefined;
    this.customerSalesOrderSiteMapDetailFromStore = undefined;
    this.customerSalesOrderCommissionDetail = undefined;
    this.customerSalesOrderProductRedemptionList = [];
    this.packageUpgradeDetails = undefined;
    this.customerSalesOrderTotalItems = 0;
    this.customerSalesOrderProductRedemptionTotalItems = 0;
  }

  setCustomerSalesOrderList = (customerSalesOrderList: CustomerSalesOrderListObject[]) => {
    this.customerSalesOrderList = customerSalesOrderList;
  }

  setCustomerSalesOrderDetail = (customerSalesOrderDetail: CustomerSalesOrderDetailObject | undefined) => {
    this.customerSalesOrderDetail = customerSalesOrderDetail;
  }
  
  setCustomerSalesOrderRedeemList = (customerSalesOrderRedeemList: CustomerSalesOrderRedeemListObject[]) => {
    this.customerSalesOrderRedeemList = customerSalesOrderRedeemList;
  }

  setCustomerSalesOrderReceiptDetail = (customerSalesOrderReceiptDetail: CustomerSalesOrderReceiptObject | undefined) => {
    this.customerSalesOrderReceiptDetail = customerSalesOrderReceiptDetail;
  }

  setCustomerSalesOrderPaginationParams = (customerSalesOrderPaginationParams: PaginationRequestBody | undefined) => {
    this.customerSalesOrderPaginationParams = customerSalesOrderPaginationParams;
  }

  setCustomerSalesOrderSiteMapDetailFromStore = (customerSalesOrderSiteMapDetailFromStore: CustomerSalesOrderSiteMapObject | undefined) => {
    this.customerSalesOrderSiteMapDetailFromStore = customerSalesOrderSiteMapDetailFromStore;
  }

  setCustomerSalesOrderCommissionDetail = (customerSalesOrderCommissionDetail: CustomerSalesOrderCommissionObject | undefined) => {
    this.customerSalesOrderCommissionDetail = customerSalesOrderCommissionDetail;
  }

  setPackageUpgradeDetails = (packageUpgradeDetails: PackageUpgradeObject | undefined) => {
    this.packageUpgradeDetails = packageUpgradeDetails;
  } 

  setCustomerSalesOrderProductRedemptionList = (customerSalesOrderProductRedemptionList: CustomerSalesOrderProductRedemptionListObject[]) => {
    this.customerSalesOrderProductRedemptionList = customerSalesOrderProductRedemptionList;
  }
  
  submitCustomerSalesOrder = async (customerSalesOrderRequestBody: CustomerSalesOrderObject) => {
    try{
      let result : CustomerSalesOrderPaymentSuccess = await agent.CustomerSalesOrder.submitCustomerSalesOrder(customerSalesOrderRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`CustomerSalesOrderCreateSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: result});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: { invoiceId: "", salesOrderId:"", customerDeliveryOrderId: "" }});
    }
  }

  updateCustomerSalesOrder = async (customerSalesOrderRequestBody: CustomerSalesOrderUpdateObject) => {
    try{
      let resultAPI = await agent.CustomerSalesOrder.updateCustomerSalesOrder(customerSalesOrderRequestBody);
      store.commonStore.setSuccessMessage(`CustomerSalesOrderUpdateSuccess`)
      return Promise.resolve(resultAPI);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  submitAdditionalPayment = async (customerSalesOrderRequestBody: CustomerSalesOrderPaymentObject) => {
    try{
      let resultAdditionalPayment = await agent.CustomerSalesOrder.submitAdditionalPayment(customerSalesOrderRequestBody);
      store.commonStore.setSuccessMessage(`PaymentCreateSuccess`)
      
      if(resultAdditionalPayment){
        return Promise.resolve({status: Constants.success, data: resultAdditionalPayment})
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
  
  getCustomerSalesOrder = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCustomerSalesOrderPaginationParams(PaginationRequestBody);
    try{
      const resultCustomerSalesOrder = await agent.CustomerSalesOrder.customerSalesOrderList(PaginationRequestBody);
      runInAction(() => {
        this.customerSalesOrderList = resultCustomerSalesOrder.data;
        store.commonStore.setTotalItem(resultCustomerSalesOrder.pagination.totalItems);
        this.customerSalesOrderTotalItems = resultCustomerSalesOrder.pagination.totalItems;
      });
      return Promise.resolve(resultCustomerSalesOrder.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderList = [];
      this.customerSalesOrderTotalItems = 0;
    }
  }

  getCustomerSalesOrderWithId = async (id: string) => {
    try{
      const resultCustomerSalesOrderDetail = await agent.CustomerSalesOrder.customerSalesOrderDetail(id);
      runInAction(() => {
        if (resultCustomerSalesOrderDetail.salesOrderDetails) {
          resultCustomerSalesOrderDetail.salesOrderDetails = resultCustomerSalesOrderDetail.salesOrderDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
          resultCustomerSalesOrderDetail.paymentHistories = resultCustomerSalesOrderDetail.paymentHistories.sort((a, b) => (a.paymentDate > b.paymentDate) ? -1 : 1)
          resultCustomerSalesOrderDetail.customerInvoices = resultCustomerSalesOrderDetail.customerInvoices.sort((a, b) => (a.issueDate > b.issueDate) ? -1 : 1)
        }
        this.customerSalesOrderDetail = resultCustomerSalesOrderDetail;
      });
      return Promise.resolve(resultCustomerSalesOrderDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderDetail = undefined;
    }
  }

  getCustomerSalesOrderSiteMapWithId = async (id: string) => {
    try{
      const resultCustomerSalesOrderSiteMapDetail = await agent.CustomerSalesOrder.customerSalesOrderSiteMapDetail(id);
      this.customerSalesOrderSiteMapDetailFromStore = resultCustomerSalesOrderSiteMapDetail;
      return Promise.resolve(resultCustomerSalesOrderSiteMapDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderSiteMapDetailFromStore = undefined;
    }
  }

  getCustomerSalesOrderRedeem = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerSalesOrderRedeem = await agent.CustomerSalesOrder.customerSalesOrderRedeem(PaginationRequestBody);
      runInAction(() => {
        this.customerSalesOrderRedeemList = resultCustomerSalesOrderRedeem;
      });
      return Promise.resolve(resultCustomerSalesOrderRedeem)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderRedeemList = [];
    }
  }

  submitCustomerSalesOrderRedeem = async (customerSalesOrderReedemRequestBody: CustomerSalesOrderRedeemSubmitObject) => {
    try{
      const resultCustomerSalesOrderRedeem = await agent.CustomerSalesOrder.addCustomerSalesOrderRedeem(customerSalesOrderReedemRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`ItemRedeemSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: resultCustomerSalesOrderRedeem});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: { redeemedDeliverOrders: []}});
    }
  }

  getCustomerSalesOrderUpgradeList = async (id: string) => {
    try{
      const resultCustomerSalesOrderUpgradeList = await agent.CustomerSalesOrder.customerSalesOrderUpgradeList(id);
      return Promise.resolve(resultCustomerSalesOrderUpgradeList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  submitCustomerSalesOrderUpgrade = async (customerSalesOrderUpgradeRequestbody: CustomerSalesOrderUpgradeSubmitObject) => {
    try{
      const resultCustomerSalesOrderUpgrade = await agent.CustomerSalesOrder.submitCustomerSalesOrderUpgrade(customerSalesOrderUpgradeRequestbody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`PackageUpgradeSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: resultCustomerSalesOrderUpgrade});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: { invoiceId: "", salesOrderId: "" }});
    }
  }

  updateCustomerSalesOrderConsultant = async (editConsultantRequestBody: CustomerSalesOrderUpdateConsultantObject) => {
    try{
      await agent.CustomerSalesOrder.updateCustomerSalesOrderConsultant(editConsultantRequestBody);
      store.commonStore.setSuccessMessage(`ChangeConsultantSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getCustomerSalesOrderReceipt = async (id: string) => {
    try{
      const resultCustomerSalesOrderReceipt = await agent.CustomerSalesOrder.customerSalesOrderReceiptDetail(id);
      runInAction(() => {
        this.customerSalesOrderReceiptDetail = resultCustomerSalesOrderReceipt;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderReceiptDetail = undefined;
    }
  }

  getCustomerSalesOrderPaymentHistory = async (id: string) => {
    try{
      const resultCustomerSalesOrderPaymentHistory = await agent.CustomerSalesOrder.customerSalesOrderPaymentHistoryDetail(id);
      runInAction(() => {
        this.customerSalesOrderReceiptDetail = resultCustomerSalesOrderPaymentHistory;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderReceiptDetail = undefined;
    }
  }

  signCustomerSalesOrder = async (signCustomerSalesOrderRequestBody: CustomerSalesOrderSignObject) => {
    try{
      let resultSignCustomerSalesOrder = await agent.CustomerSalesOrder.signCustomerSalesOrder(signCustomerSalesOrderRequestBody);
      store.commonStore.setSuccessMessage(`CustomerSalesOrderSignSuccess`)
      return Promise.resolve({status: Constants.success, data: resultSignCustomerSalesOrder});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  requestVoidCustomerSalesOrder = async (requestVoidCustomerSalesOrderRequestBody: CustomerSalesOrderVoidObject) => {
    try{
      await agent.CustomerSalesOrder.requestVoidCustomerSalesOrder(requestVoidCustomerSalesOrderRequestBody);
      store.commonStore.setSuccessMessage(`VoidCustomerSalesOrderRequestSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  reviewVoidCustomerSalesOrder = async (reviewVoidCustomerSalesOrderRequestBody: CustomerSalesOrderVoidObject) => {
    try{
      await agent.CustomerSalesOrder.reviewVoidCustomerSalesOrder(reviewVoidCustomerSalesOrderRequestBody);
      store.commonStore.setSuccessMessage(`VoidCustomerSalesOrderReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCustomerSalesOrderCommissionWithId = async (id: string) => {
    try{
      const resultCustomerSalesOrderCommission = await agent.CustomerSalesOrder.customerSalesOrderCommissionDetail(id);
      runInAction(() => {
        if (resultCustomerSalesOrderCommission.commissionSummaryDetails) {
          resultCustomerSalesOrderCommission.commissionSummaryDetails = resultCustomerSalesOrderCommission.commissionSummaryDetails.map((valueCommissionSummaryDetails, index)=> {
            return({
              ...valueCommissionSummaryDetails,
              randomId: `${valueCommissionSummaryDetails.date}_${index}`, 
            })
          })
        }
        if (resultCustomerSalesOrderCommission.salesAdjustments) {
          resultCustomerSalesOrderCommission.salesAdjustments = resultCustomerSalesOrderCommission.salesAdjustments.map((valueSalesAdjustments, index)=> {
            return({
              ...valueSalesAdjustments,
              randomId: `${valueSalesAdjustments.performedDateTime}_${index}`, 
            })
          })
        }
        this.customerSalesOrderCommissionDetail = resultCustomerSalesOrderCommission;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderCommissionDetail = undefined;
    }
  }

  updateCustomerSalesOrderStatus = async (id: string) => {
    try{
      await agent.CustomerSalesOrder.updateCustomerSalesOrderStatus(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`CustomerSalesOrderStatusUpdateSuccessfully`);
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  updateCustomerSalesOrderSubBrand = async (updateCustomerSalesOrderSubBrandRequestBody: CustomerSalesOrderUpdateSubBrandObject) => {
    try{
      await agent.CustomerSalesOrder.updateCustomerSalesOrderSubBrand(updateCustomerSalesOrderSubBrandRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CustomerSalesOrderSubBrandUpdateSuccessfully`);
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  getCustomerSalesOrderProductRedemptionList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerSalesOrderProductRedemptionList = await agent.CustomerSalesOrder.customerSalesOrderProductRedemptionList(PaginationRequestBody);
      if (resultCustomerSalesOrderProductRedemptionList) {
        if (resultCustomerSalesOrderProductRedemptionList.data) {
          resultCustomerSalesOrderProductRedemptionList.data = resultCustomerSalesOrderProductRedemptionList.data.map((valueCustomerSalesOrderProductRedemptionTemp, indexCustomerSalesOrderProductRedemptiontTemp)=> ({
            ...valueCustomerSalesOrderProductRedemptionTemp,
            randomId: `${valueCustomerSalesOrderProductRedemptionTemp.documentNo}_${indexCustomerSalesOrderProductRedemptiontTemp}`
          }))
        }
      }
      runInAction(() => {
        this.customerSalesOrderProductRedemptionList = resultCustomerSalesOrderProductRedemptionList.data;
        this.customerSalesOrderProductRedemptionTotalItems = resultCustomerSalesOrderProductRedemptionList.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderProductRedemptionList = [];
      this.customerSalesOrderProductRedemptionTotalItems = 0;
    }
  }
}