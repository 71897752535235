import { Fragment, useEffect, useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { isTablet } from "react-device-detect";
import { Constants } from "../../../app/constants/Constants";
import { newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";

interface Props {
    blnShow: boolean;
    setModal: Function;
    onPrimaryClick: Function;
    localLoading: boolean;
    blnAddCreditNoteSuccess: boolean;
}

export default function CustomerCreditRefundModal(props: Props) {
    const intl = useIntl();
    const { staticReferenceStore, commonStore } = useStore();
    const { successMessage, setErrorMessage } = commonStore;
    const { defaultRefundReason, getStaticReferenceWithType } = staticReferenceStore;
    const [customerRefundReason, setCustomerRefundReason] = useState({ reason: "" })
    const [errorState, setErrorState] = useState({ reason: false })
    const disabledFieldInput = props.localLoading || Boolean(successMessage) || props.blnAddCreditNoteSuccess;
    const [loadingRefundReason, setLoadingRefundReason] = useState(false);

    useEffect(()=> {
        async function fetchDefaultRefundReason() {
          setLoadingRefundReason(true);
          await getStaticReferenceWithType(Constants.defaultRefundReason);
          setLoadingRefundReason(false);
        }
    
        fetchDefaultRefundReason();
    }, [])
      
    const hideModal = () => {
        props.setModal(false);
    }

    const displayDefaultRefundReason = () => {
        let aryViews : any = [];
        defaultRefundReason.map((valueDefaultRefundReason, indexDefaultRefundReason) => {
          let displayContent = newSpaceBeforeCapitalLetter(valueDefaultRefundReason.displayValue || "");
          aryViews.push(
            <Fragment key={`refund_reason_key_${indexDefaultRefundReason}`}>
              <MyButton
                type="button"
                class="btn btn-info button-padding-overwrite margin-left-8 margin-top-4"
                content={displayContent}
                disable={loadingRefundReason}
                onClick={()=> {
                    setCustomerRefundReason((valueRefundReason)=> ({
                        ...valueRefundReason,
                        reason: valueRefundReason.reason ? valueRefundReason.reason + ` ${displayContent}` : `${displayContent}`
                    }))
                }}
              />
            </Fragment>
          )
        })
        return <div className="margin-top--8 margin-left--8px">{aryViews}</div>
    }
      
    return (
        !props.blnShow
            ?
            <div />
            :
            <Modal
                isOpen={props.blnShow}
                toggle={() => {
                    if (!disabledFieldInput) {
                        hideModal();
                    }
                }}
                size={"xl"}
                style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
                centered>
                <div className="modal-header">
                    <h5 className="modal-title margin-top-0"> {intl.formatMessage({ id: "ReasonForCreateCustomerCreditRefund" })}</h5>
                    {
                        !disabledFieldInput
                        &&
                        <button
                            type="button"
                            onClick={() => {
                                hideModal();
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    }
                </div>
                <div className="modal-body">
                    <GeneralTextAreaForModal
                        title={intl.formatMessage({ id: "Reason" })}
                        name="reason"
                        className={errorState.reason ? "mb-0" : "mb-3"}
                        row={4}
                        validationRequired={true}
                        field={customerRefundReason}
                        setFieldValue={setCustomerRefundReason}
                        disabled={disabledFieldInput}
                        onChangeFunction={() => {
                            if (errorState.reason) {
                                setErrorState((value) => ({ ...value, reason: false }))
                            }
                        }} />
                    {errorState.reason ? (
                        <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`}</FormFeedback>
                    ) : null}
                    {
                        loadingRefundReason
                        ?
                        <Loading/>
                        :
                        displayDefaultRefundReason()
                    }
                    {
                        !Boolean(successMessage)
                        &&
                        <div className={`float-end d-print-none mt-2`}>
                            <MyButton
                                type="button"
                                class="btn btn-danger margin-left-8"
                                content={intl.formatMessage({ id: "Cancel" })}
                                onClick={() => {
                                    props.setModal(false)
                                }}
                                disable={disabledFieldInput}
                                loading={props.localLoading}
                            />
                            <MyButton
                                type="button"
                                class="btn btn-primary margin-left-8"
                                content={intl.formatMessage({ id: "Submit" })}
                                onClick={() => {
                                    if (!customerRefundReason.reason) {
                                        setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}`)
                                        setErrorState({ reason: true })
                                    }
                                    else {
                                        props.onPrimaryClick(customerRefundReason.reason);
                                    }
                                }}
                                disable={disabledFieldInput}
                                loading={props.localLoading}
                            />
                        </div>
                    }
                </div>
            </Modal>
    )
}