import { makeAutoObservable, runInAction } from "mobx";
import { PaginationRequestBody } from "../models/pagination";
import { RefundNoteWebFormTncObject, TncAddObject, TncCategoryObject, TncDetailObject, TncListObject, TncUpdateObject } from "../models/tnc";
import agent from "../api/agent";
import { store } from "./store";

export default class TncStore {
    tncList: TncListObject[] = [];
    tncDetail: TncDetailObject | undefined = undefined;
    tncPaginationParams: PaginationRequestBody | undefined = undefined;
    tncText: TncCategoryObject | undefined = undefined;

    constructor() {
        makeAutoObservable(this)
    }

    reset = () => {
        this.tncList = [];
        this.tncDetail = undefined;
        this.tncPaginationParams = undefined;
        this.tncText = undefined;
    }

    setTncDetail = (tncDetail: TncDetailObject | undefined) => {
        this.tncDetail = tncDetail;
    }

    setTncPaginationParams = (tncPaginationParams: PaginationRequestBody | undefined) => {
        this.tncPaginationParams = tncPaginationParams;
    }

    getTnc = async (PaginationRequestBody: PaginationRequestBody) => {
        this.setTncPaginationParams(PaginationRequestBody);
        try {
            const resultTnc = await agent.Tnc.tncList(PaginationRequestBody);
            runInAction(() => {
                this.tncList = resultTnc.data;
                store.commonStore.setTotalItem(resultTnc.pagination.totalItems);
            });
        } catch (error: any) {
            console.log(error)
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
            this.tncList = [];
        }
    }

    getTncWithId = async (id: string) => {
        try {
            const resultTncDetail = await agent.Tnc.tncDetail(id);
            runInAction(() => {
                this.tncDetail = resultTncDetail;
            });
            return Promise.resolve(resultTncDetail)
        } catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
            this.tncDetail = undefined;
        }
    }

    addTnc = async (tncRequestBody: TncAddObject) => {
        try {
            await agent.Tnc.addTnc(tncRequestBody);
            store.commonStore.setSuccessMessage(`TncAddSuccess`)
        }
        catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        }
    }

    updateTnc = async (tncRequestBody: TncUpdateObject) => {
        try {
            await agent.Tnc.updateTnc(tncRequestBody);
            store.commonStore.setSuccessMessage(`TncUpdateSuccess`)
        } catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        }
    }

    deleteTnc = async (id: string, name: string) => {
        try {
            await agent.Tnc.deleteTnc(id);
            store.commonStore.setSuccessMessage(`TncDeleteSuccess`)
        } catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        }
    }

    getTncWithCategory = async (category: string, date: string) => {
        try {
            const resultTncByCategory = await agent.Tnc.getTncWithCategory(category, date);
            runInAction(() => {
                this.tncText = resultTncByCategory;
            });
            return Promise.resolve(resultTncByCategory)
        } catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        }
    }

    getRefundNoteWebFormTnc = async (tncRequestBody: RefundNoteWebFormTncObject) => {
        try {
            const resultTnc = await agent.Tnc.getRefundNoteWebFormTnc(tncRequestBody);
            runInAction(() => {
                this.tncText = resultTnc;
            });
            return Promise.resolve(resultTnc)
        } catch (error: any) {
            console.log(error);
            store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
        }
    }
}