import React, { useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import { Tooltip } from "bootstrap";
import { history } from "../..";
import { useLocation } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
//css
import "@fullcalendar/bootstrap/main.css";
import moment from 'moment-timezone';

import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { BranchListObject } from "../../app/models/branch";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import AppointmentDetailModal from "./Modal/AppointmentDetailModal";
import Loading from "../../app/components/loading/Loading";
import GeneralMultiSelectDropdownWithCheckBox from "../../app/components/dropdown/GeneralMultiSelectDropdownWithCheckBox";
import { ObjectForDropdown } from "../../app/models/common";
import { checkPermission, comparePathForPagination, comparePathForSetParams, filterDropDownValue, getBranchId, getMonths, getYears, newSpaceBeforeCapitalLetter, returnSuccessMarkedIcon, returnMobileOrTable } from "../../app/common/function/function";
import LoadingModal from "../../app/components/modal/LoadingModal";
import MyAddButton from "../../app/components/form/MyAddButton";
import _ from "lodash";
import { useIntl } from "react-intl";
import { AppointmentRescheduleObject } from "../../app/models/appointment";
import MyButton from "../../app/components/form/MyButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "mdi-react/CalendarIcon";
import NoteIcon from "mdi-react/NoteIcon";
import AccountCheckIcon from "mdi-react/AccountCheckOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AccountReactivateIcon from "mdi-react/AccountReactivateIcon";
import AccountMultiplePlusIcon from "mdi-react/AccountMultiplePlusIcon";
import CrownIcon from "mdi-react/CrownIcon";
import Select from 'react-select';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const Appointment = () => {
  //Use Store
  const { appointmentStore, branchStore, staticReferenceStore, serviceTreatmentStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, windowSize, prevPath } = commonStore;
  const { appointmentCalendar, appointmentDetail, roomList, objAppointment, appointmentObjAfterSuccess, addAppointmentForCalendar, updateAppointment, appointmentErrorPrompt, setObjAppointment, setAppointmentDetail, setAppointmentObjAfterSuccess, setAppointmentErrorPrompt, getAppointment, getRoomWithBranchId, getAppointmentWithId } = appointmentStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { appointmentStatusType, getStaticReferenceWithType } = staticReferenceStore;
  const { getServiceTreatmentDropdown } = serviceTreatmentStore;
  const location = useLocation();
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [selectedAryRoomsId, setSelectedAryRoomsId] = useState<any>([]);
  const [selectedAryPrevRoomsId, setSelectedAryPrevRoomsId] = useState<any>([]);
  const [openRoomDropdown, setOpenRoomDropdown] = useState(false);
  const [startDate, setStartDate] = useState(moment().set({hour:0,minute:0,second:0,millisecond:0}).format(Constants.defaultDateFormat).toString());
  const startDateWithDateFormat = moment(startDate).format(Constants.displayDateFormat);
  const [endDate, setEndDate] = useState(moment().set({hour:0,minute:0,second:0,millisecond:0}).add(1, 'days').format(Constants.defaultDateFormat).toString());
  const [calendarViewType, setCalendarViewType] = useState('Day');
  const [initialData, setInitialData] = useState(true);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [loadingDragAppointment, setLoadingDragAppointment] = useState(false);
  const [blnDatePickerOnClick, setBlnDatePickerOnClick] = useState(false);
  const [appointmentDetailModal, setAppointmentDetailModal] = useState(false);
  const [dragAppointmentModal, setDragAppointmentModal] = useState(false);
  const [dragAppointmentDetail, setDragAppointmentDetail] = useState<any>(undefined);
  const blnPermissionCreateAppointment = checkPermission([PermissionConstants.CreateAppointment]);
  const blnPermissionUpdateAppointment = checkPermission([PermissionConstants.UpdateAppointment]);
  const [objReschedule, setObjReschedule] = useState<AppointmentRescheduleObject | undefined>(undefined);
  const [blnDisplayDatePicker, setBlnDisplayDatePicker] = useState(false);
  const calendarIcon : any = <CalendarIcon fontSize={10}/>
  const localYears = getYears();
  const localMonths = getMonths();
  const revertDropEvent = useRef<() => void>();

  const paramsSearch = useLocation().search;

  const intl = useIntl();
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchAppointmentAPI() {
      setLoading(true);
      let aryBranchTemp : BranchListObject[] = await getBranchDropDown() || [];
      let branchIdTemp = getBranchId();
      let aryRoomIdsTemp : string[] = [];
      let blnRefreshAppointmentData = false;

      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.appointment)
      if (blnBackNavigation) {
        if (objAppointment && !appointmentObjAfterSuccess) {
          blnRefreshAppointmentData = true;
          branchIdTemp = objAppointment?.branchId;
          setSelectedBranchId(objAppointment?.branchId);
          setSelectedBranchName(objAppointment?.branchName || "")
          setSelectedAryRoomsId(objAppointment?.roomIds)
          setStartDate(objAppointment?.startDate)
          setEndDate(objAppointment?.endDate)
          setCalendarViewType(objAppointment?.calendarViewType)
        }
      }
      else {
        setObjAppointment(undefined);
      }

      const appointmentIdParams = new URLSearchParams(paramsSearch).get('appointmentId');
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const branchNameParams = new URLSearchParams(paramsSearch).get('branchName');
      const startDateParams = new URLSearchParams(paramsSearch).get('startDate');
      const endDateParams = new URLSearchParams(paramsSearch).get('endDate');
    
      let blnRefreshAppointmentDataFromParams = false;
      if (appointmentIdParams && branchIdParams && branchNameParams && startDateParams && endDateParams) {
        blnRefreshAppointmentDataFromParams=true;
        branchIdTemp = branchIdParams;
        setSelectedBranchId(branchIdParams);
        setSelectedBranchName(branchNameParams)
        setStartDate(moment(startDateParams).set({hour:0,minute:0,second:0,millisecond:0}).format(Constants.defaultDateFormat).toString())
        setEndDate(moment(endDateParams).set({hour:0,minute:0,second:0,millisecond:0}).format(Constants.defaultDateFormat).toString())
      }

      let blnObjAfterSuccess = false;
      let startDateAfterSuccess = "";
      let endDateAfterSuccess = "";
      if (appointmentObjAfterSuccess) {
        blnObjAfterSuccess = true;
        branchIdTemp = appointmentObjAfterSuccess.branchId;
        setSelectedBranchId(appointmentObjAfterSuccess.branchId);
        setSelectedBranchName(appointmentObjAfterSuccess.branchName)
        startDateAfterSuccess = moment(appointmentObjAfterSuccess.startDate).set({hour:0,minute:0,second:0,millisecond:0}).format(Constants.defaultDateFormat).toString();
        setStartDate(moment(appointmentObjAfterSuccess.startDate).set({hour:0,minute:0,second:0,millisecond:0}).format(Constants.defaultDateFormat).toString())
        endDateAfterSuccess = moment(appointmentObjAfterSuccess.endDate).set({hour:0,minute:0,second:0,millisecond:0}).add(1, 'days').format(Constants.defaultDateFormat).toString();
        setEndDate(moment(appointmentObjAfterSuccess.endDate).set({hour:0,minute:0,second:0,millisecond:0}).add(1, 'days').format(Constants.defaultDateFormat).toString())
        setAppointmentObjAfterSuccess(undefined);
      }

      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.addAppointmentAction) {
            if (location.state.detail.branchId) {
              branchIdTemp = location.state.detail.branchId;
              setSelectedBranchId(location.state.detail.branchId);
              setSelectedBranchName(location.state.detail.branchName);
            }
            
            setObjReschedule({
              appointmentId: "",
              customerName: location.state.detail.leadName || location.state.detail.customerName,
              fromDateAndTime: "",
              newDate: "",
              newTime: "",
              actionType: Constants.add
            })
          }
        }
      }
      if (aryBranchTemp.length > 0) {
        let indexCurrentBranch = _.findIndex(aryBranchTemp, {id: branchIdTemp});
        indexCurrentBranch = indexCurrentBranch>-1 ? indexCurrentBranch : 0;
        branchIdTemp = aryBranchTemp[indexCurrentBranch].id;
        setSelectedBranchId(aryBranchTemp[indexCurrentBranch].id)
        setSelectedBranchName(aryBranchTemp[indexCurrentBranch].name)
        aryRoomIdsTemp = await fetchRoomWithBranchId(aryBranchTemp[indexCurrentBranch].id, blnRefreshAppointmentData) || [];
      }

      await getAppointmentWithType(
        blnRefreshAppointmentDataFromParams ? branchIdParams || "" : blnRefreshAppointmentData ? objAppointment?.branchId || "" : branchIdTemp,
        blnRefreshAppointmentData ? returnRoomTemp(objAppointment?.roomIds as any|| []) : aryRoomIdsTemp,
        blnRefreshAppointmentData ? objAppointment?.calendarViewType || "" : calendarViewType, 
        blnObjAfterSuccess ? startDateAfterSuccess : blnRefreshAppointmentDataFromParams ? startDateParams || "" : blnRefreshAppointmentData ? objAppointment?.startDate || "" : startDate,
        blnObjAfterSuccess ? endDateAfterSuccess : blnRefreshAppointmentDataFromParams ? endDateParams || "" : blnRefreshAppointmentData ? objAppointment?.endDate || "" : endDate
      )

      if (blnRefreshAppointmentDataFromParams) {
        history.replace({ pathname: `/${RoutesList.appointment}` });
        promptAppointmentDetailModal(appointmentIdParams || "");
      }

      setLoading(false);
      setInitialData(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointment], true)) {
      return;
    }

    fetchAppointmentAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.appointment)
        if (!blnSetParams) {
          setObjAppointment(undefined);
        }
      }
      setAppointmentObjAfterSuccess(undefined);
      setAppointmentErrorPrompt("");
    })
  }, [])

  useEffect(()=> {
    if (selectedBranchId || selectedAryRoomsId || calendarViewType || startDate || endDate) {
      setObjAppointment({
        branchId: selectedBranchId,
        branchName: selectedBranchName,
        roomIds: selectedAryRoomsId,
        calendarViewType, 
        startDate,
        endDate
      })
    }
  }, [selectedBranchId, selectedAryRoomsId, calendarViewType, startDate, endDate])

  const fetchRoomWithBranchId = async (branchId: string, blnSkipSetData: boolean = false) => {
    let roomResultTemp = await getRoomWithBranchId(branchId) || []
    let aryRoomIdsTemp : string[] = [];
    let aryRoomOptionsTemp : ObjectForDropdown[] = [];
    aryRoomIdsTemp = roomResultTemp.map((value)=> value.id)
    aryRoomOptionsTemp = roomResultTemp.map((value)=> ({label: value.name, value: value.id}))
    if (aryRoomOptionsTemp.length > 0) { 
      aryRoomOptionsTemp.splice(0,0, {label: "Select All", value: 'all'});
    }
    if (!blnSkipSetData) {
      setSelectedAryRoomsId([...aryRoomOptionsTemp]);
      setSelectedAryPrevRoomsId([...aryRoomOptionsTemp]);
    }
    return aryRoomIdsTemp;
  }
  
  const fetchAppointmentForBranch = async (branchId: string) => {
    setInitialData(true);
    setLoadingAppointment(true);
    let aryRoomIdsTemp : string[] = [];
    aryRoomIdsTemp = await fetchRoomWithBranchId(branchId) || [];

    await getAppointmentWithType(
      branchId,
      aryRoomIdsTemp,
      calendarViewType, 
      startDate,
      endDate
    )
    setLoadingAppointment(false);
    setInitialData(false);
  }

  const fetchAppointmentForRooms = async (rooms: string[]) => {
    setInitialData(true);
    setLoadingAppointment(true);
    await getAppointmentWithType(
      selectedBranchId,
      rooms,
      calendarViewType, 
      startDate,
      endDate
    )
    setLoadingAppointment(false);
    setInitialData(false);
  } 

  const refreshAppointmentData = async () => {
    setLoadingAppointment(true);
    await getAppointmentWithType(
      selectedBranchId,
      returnRoomTemp(),
      calendarViewType, 
      startDate,
      endDate
    )
    setLoadingAppointment(false);
  }

  const returnRoomTemp = (selectedAryRoomsIdFromFunction?: ObjectForDropdown[]) => {
    let aryRoomTemp = filterDropDownValue(selectedAryRoomsIdFromFunction || selectedAryRoomsId, 'all');
    return aryRoomTemp;
  }

  const getAppointmentWithType = async (branchId: string, roomIds: string[], calendarViewType: string, startDate: string, endDate: string) => {
    await getAppointment({
      branchId: branchId,
      roomIds: roomIds, 
      calendarViewType: calendarViewType,
      startDate: startDate,
      endDate: endDate
    })
  }

  const returnTooltipHtmlContent = (displayName: string, displayField: string) => {
    return (
      `<div class="row">
        <div class="flex-align-items-center-without-height col-xl-6">
          <p class="mb-0 appointment-font-weight-800">
            ${displayName}
          </p>
        </div>
        <div class="flex-align-items-center-without-height col-xl-6">
          <p class="mb-0 appointment-font-weight-400">
            ${displayField}
          </p>
        </div>
      </div>`
    )
  }

  const returnTooltipHTML = (additionalInfo: any, isBlocker: Boolean, title: string) => {
    if (isBlocker) {
      return (
        `<div class="appointment-tool-tip-background">
          <h4 class="mb-4 appointment-tool-tip-header-background">
            Blocker
          </h4>
          <div class="appointment-tool-top-content-background">
            ${returnTooltipHtmlContent("Title: ", title )}
            ${additionalInfo.remark ? `<hr /> ${returnTooltipHtmlContent("Remark ", additionalInfo.remark)}` : ""}
          </div>
        </div>`
      )
    }
    else {
      return (
        `<div class="appointment-tool-tip-background">
          <h4 class="mb-4 ${additionalInfo.appointmentSourceType === Constants.existing ? `appointment-tool-tip-header-background-${Constants.existing}` : `appointment-tool-tip-header-background`}">
            Summary ${!(additionalInfo.services.length > 0) ? `<i class="font-size-16">(${intl.formatMessage({id: "FollowUpOnly"})})</i>`: ""}
          </h4>
          <div class="appointment-tool-top-content-background">
            ${returnTooltipHtmlContent(`${intl.formatMessage({id: "FullName"})}: `, `${additionalInfo.customerName} ${additionalInfo.isRejoin ? `(${intl.formatMessage({id: "Rejoin"})})` : additionalInfo.isCrossBrand ? `(${intl.formatMessage({id: "CrossBrand"})})` : ""}`)}            
            <hr />
            ${
              additionalInfo.appointmentSourceType === Constants.existing && additionalInfo.customerNo 
              ?
              `
                ${returnTooltipHtmlContent(`${intl.formatMessage({id: "CustomerNo"})}: `, additionalInfo.customerNo)}
                <hr />`
              : 
              ""
            }
            ${
              additionalInfo.customerContactNo
              ?
              `
                ${returnTooltipHtmlContent(`${intl.formatMessage({id: "Mobile"})}: `, additionalInfo.customerContactNo)}
                <hr />`
              : 
              ""
            }
            ${ 
              additionalInfo.appointmentSourceType !== Constants.existing 
              ? 
              `${returnTooltipHtmlContent(`${intl.formatMessage({id: "MediaSource"})}: `, additionalInfo.mediaSource)}
              <hr />`
              :
              ""
            }
            ${
              additionalInfo.services.length > 0
              ?
              `${returnTooltipHtmlContent(`${intl.formatMessage({id: "Services"})}: `, additionalInfo.services)}
              <hr />`
              :
              ""
            }
            ${returnTooltipHtmlContent(`${intl.formatMessage({id: "ConsultantName"})}: `, additionalInfo.consultantName || Constants.emptyData)}
            ${
              additionalInfo.subBrandName
              ?
              `
                <hr /> 
                ${returnTooltipHtmlContent(`${intl.formatMessage({id: "SubBrand"})}: `, additionalInfo.subBrandName)}
              `
              : 
              ""
            }
            ${
              additionalInfo.remark
              ?
              `
                <hr /> 
                ${returnTooltipHtmlContent(`${intl.formatMessage({id: "Remark"})}: `, additionalInfo.remark)}
              `
              : 
              ""
            }
          </div>
        </div>`
      )
    }
  } 

  // actions
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps.additionalInfo && !returnMobileOrTable()) {
      let tooltipInstanceTemp = new Tooltip(info.el, {
        title: returnTooltipHTML({...info.event.extendedProps.additionalInfo, remark: info.event.extendedProps?.remark || ""}, info.event.extendedProps.isBlocker, info.event.title),
        html: true,
      });

      tooltipInstanceTemp.show();
    }
  };

  const handleMouseLeave = (info) => {
    removeAllTooTips();
  };

  const removeAllTooTips = () => {
    Array.from(document.getElementsByClassName("tooltip bs-tooltip-auto fade show")).forEach(
      function(element: any, index, array) {
        document.body.removeChild(element);
      }
    );
  }

  const displayService = (eventContentTemp) => {
    let specialStatus = eventContentTemp.event.extendedProps?.additionalInfo?.isPin;
    let textColor = Constants.white;
    if (eventContentTemp) {
      if (eventContentTemp.event?.extendedProps?.blnAllDay) {
        return (
          <div>
            <span className="font-size-13">{eventContentTemp.event.extendedProps?.leaveAdditionalInfo?.employeeName}</span>
            <br />
            <span className="font-size-12" style={{fontStyle: "italic"}}>- {`${newSpaceBeforeCapitalLetter(eventContentTemp.event.extendedProps?.leaveAdditionalInfo?.leaveType)}`}</span>
            <br />
            <span>{`(${newSpaceBeforeCapitalLetter(eventContentTemp.event.extendedProps?.leaveAdditionalInfo?.isHalfDay ? eventContentTemp.event.extendedProps?.leaveAdditionalInfo?.leavePeriod : intl.formatMessage({ id: "FullDay" }))})`}</span>
          </div>
        )
      }
      return (
        <div style={{overflow: 'hidden', maxHeight: '100%', height: '-webkit-fill-available', ...specialStatus && { boxShadow: "0 0px 8px 8px #ffdb19"}}} >
          {
            specialStatus
            &&
            <span className="font-size-10">
              {
                <CrownIcon size={18} color="#FFD700"/>
              }
            </span>
          }
          <div className="font-size-11" style={{color: textColor, whiteSpace: 'nowrap'}}>
            {moment(eventContentTemp.event.start).format(Constants.displayTimeFormat)} - {eventContentTemp.event.end ? moment(eventContentTemp.event.end).format(Constants.displayTimeFormat) : ""}
          </div>
          {
            eventContentTemp.event.extendedProps?.additionalInfo?.appointmentSourceType === Constants.existing
            ?
            <span className="font-size-10" style={{color: textColor}}>
              {
                <AccountCheckIcon size={24} color={textColor} className="align-middle me-2"/>
              }
            </span>
            :
            eventContentTemp.event.extendedProps?.additionalInfo?.isRejoin
            ?
            <span className="font-size-10" style={{color: textColor}}>
              {
                <AccountReactivateIcon size={24} color={textColor} className="align-middle me-2"/>
              }
            </span>
            :
            eventContentTemp.event.extendedProps?.additionalInfo?.isCrossBrand
            ?
            <span className="font-size-10" style={{color: textColor}}>
              {
                <AccountMultiplePlusIcon size={24} color={textColor} className="align-middle me-2"/>
              }
            </span>
            :
            eventContentTemp.event.extendedProps?.additionalInfo?.customerNo
            ?
            <span className="font-size-10" style={{color: textColor}}>
              {
                <AccountOutlineIcon size={24} color={textColor} className="align-middle me-2"/>
              }
            </span>
            :
            null
          }
          <span className="font-size-12" style={{color: textColor, fontWeight: 'bold'}}>{eventContentTemp.event.extendedProps?.additionalInfo?.customerName}</span>
          <i className="font-size-10" style={{color: textColor}}>
            {
              eventContentTemp.event.extendedProps?.isBlocker
              ?
              <>
                <span className="font-size-12">
                  {eventContentTemp.event?.title || Constants.emptyData}
                </span>
                <span className="font-size-10">
                  <br/>
                  {eventContentTemp.event.extendedProps?.remark}
                </span>
              </>
              :
              <ul className="margin-left--12px mt-2" style={{lineHeight:'1.6'}}>
                {
                  eventContentTemp.event.extendedProps.additionalInfo?.services
                  ?
                  eventContentTemp.event.extendedProps.additionalInfo.services.map((value,index) =>
                    <li key={index}>
                      {value}
                    </li>
                  )
                  :
                  <div/>
                }
                {intl.formatMessage({ id: "By" })} {eventContentTemp.event.extendedProps.additionalInfo?.consultantName || `${intl.formatMessage({ id: "NoPreference" })}`}
              </ul>
            }
          </i>
          {
            !eventContentTemp.event.extendedProps?.isBlocker
            &&
            eventContentTemp.event.extendedProps?.remark
            &&
            <span className="font-size-10" style={{color: textColor}}><br/>
              {
                <NoteIcon size={18} className="text-warning align-middle me-2"/>
              }
            </span>
          }
        </div>
      );
    }
  };

  const promptAppointmentDetailModal = (appointmentIdTemp: string) => {
    setAppointmentDetailModal(true);
    getAppointmentWithId(appointmentIdTemp);
    if (appointmentStatusType.length < 1) {
      getStaticReferenceWithType("AppointmentStatusType");
    }
  }

  return ( 
    <div>
      <div
        className="page-content-sticky-header">
        <MetaTags>
        <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "Appointment"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="10">
            <div className="h4">{`${intl.formatMessage({id: "Appointment"})}`}</div>
            </Col>
          </Row>
          <BreadCrumb activeBreadCrumbTitle={intl.formatMessage({id: "Appointment"})}/>
          {/* {
            ((errorMessage || successMessage) && !loading && !appointmentDetailModal)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {
            loading && !appointmentDetailModal 
            ?
            <Loading />
            :
            <div style={{padding: '20px'}}>
              <Row className="mb-3">
                <Col xs={"6"} md={"6"} xl={"3"} style={{zIndex: 888}}>
                  <GeneralSelectDropdown
                    title=""
                    name="branchName"
                    className="mb-0"
                    options={branchDropDownList}
                    labelField={"name"}
                    valueField={"id"}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    disabled={loading}
                    setFieldLabel={setSelectedBranchName}
                    setFieldValue={setSelectedBranchId}
                    onChange={fetchAppointmentForBranch}
                    validationRequired={true}
                  />
                </Col>
                <Col xs={"6"} md={"6"} xl={"3"} style={{zIndex: 888}}>
                  <GeneralMultiSelectDropdownWithCheckBox
                    title=""
                    className="mb-0"
                    name="branchName"
                    options={roomList}
                    labelField={"name"}
                    valueField={"id"}
                    menuIsOpen={openRoomDropdown}
                    setMenuIsOpen={setOpenRoomDropdown}
                    disabled={loading}
                    initialValue={selectedAryRoomsId}
                    setFieldFunction={setSelectedAryRoomsId}
                    prevoiousValue={selectedAryPrevRoomsId}
                    setPreviousFieldFunction={setSelectedAryPrevRoomsId}
                    onChange={fetchAppointmentForRooms}
                  />
                </Col>
                {
                  blnPermissionCreateAppointment
                  &&
                  <Col xs={"12"} md={"12"} xl={"6"} className={`flex-right ${Constants.innerWidthCompare>windowSize.innerWidth ? "margin-top-8" : ""}`}>
                    <MyAddButton
                      content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({id: "Appointment"})})}
                      onClick={()=> {
                        history.push({
                          pathname: `/${RoutesList.appointment}/add`,
                          state: { 
                            detail: {
                              branchId: selectedBranchId,
                              branchName: selectedBranchName
                            }
                          }
                        });
                      }}
                      linkTo={{
                        pathname: `/${RoutesList.appointment}/add`,
                        state: { 
                          detail: {
                            branchId: selectedBranchId,
                            branchName: selectedBranchName
                          }
                        }
                      }}
                      disable={loading || objReschedule ? true : false}
                    />
                    <MyAddButton
                      class="btn margin-left-8"
                      btnColor="btn-primary"
                      content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({id: "Blocker"})})}
                      onClick={()=> {history.push(`/${RoutesList.appointment}/blocker/add`)}}
                      linkTo={{
                        pathname: `/${RoutesList.appointment}/blocker/add`,
                        state: { 
                          detail: {
                            branchId: selectedBranchId,
                            branchName: selectedBranchName
                          }
                        }
                      }}
                      disable={loading || objReschedule ? true : false}
                    />
                  </Col>
                }
              </Row>
              {
                objReschedule
                &&
                <div className="flex-direction-row" style={{marginBottom: '20px'}}>
                  <div className="alert alert-warning alert-dismissible fade show" style={{flex:1, marginRight: '20px', marginBottom: '0px'}}>
                    {intl.formatMessage({id: objReschedule.actionType === Constants.update ? "RescheduleWithNameAndTime" : objReschedule.actionType === Constants.add ? "AddAppointmentWithName" : "CopyAppointmentWithNameAndTime"}, { name: objReschedule.customerName, date : objReschedule.fromDateAndTime })}
                  </div>
                  <MyButton
                    type="button"
                    class={`btn btn-danger`}
                    content={intl.formatMessage({ id: "Cancel"})}
                    style={{ width: '150px'}}
                    disable={
                      Boolean(successMessage) || loading
                    }
                    loading={
                      loading
                    }
                    onClick={()=> {
                      setObjReschedule(undefined);
                    }}
                  />
                </div>
              }
              {
                blnDisplayDatePicker
                &&
                <div 
                  style={{
                    position: 'absolute', 
                    zIndex: 10, 
                    right: windowSize.innerWidth > 575 ? '40px' : "10px", 
                    marginTop: windowSize.innerWidth > 575 ? "35px" : "95px"
                  }}>
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div>
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}>
                          <MyButton 
                            content={"<"}
                            class="btn btn-primary"
                            onClick={decreaseMonth} 
                            disable={prevMonthButtonDisabled}/>
                          <Select 
                            className="margin-left-8"
                            classNamePrefix={"localYears"}
                            options={localYears}
                            value={{label: Number(moment(date).format("YYYY")), value: Number(moment(date).format("YYYY"))}}
                            onChange={(selectedValue)=> {
                              changeYear(selectedValue?.value)
                            }}
                            onMenuOpen={()=> {
                              setTimeout(()=>{
                                const selectedEl = document.getElementsByClassName(`localYears__option--is-selected`)[0];
                                if(selectedEl){
                                  selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
                                }
                              },15)
                            }}/>
                          <Select 
                            className="margin-left-8 margin-right-8"
                            options={localMonths}
                            classNamePrefix={"localMonths"}
                            value={{label: moment(date).format("MMM"), value: Number(moment(date).format("M"))}}
                            onChange={(selectedValue)=> {
                              if (selectedValue) {
                                if (selectedValue.value) {
                                  changeMonth(selectedValue.value-1)
                                }
                              }
                            }}
                            onMenuOpen={()=> {
                              setTimeout(()=>{
                                const selectedEl = document.getElementsByClassName(`localMonths__option--is-selected`)[0];
                                if(selectedEl){
                                  selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
                                }
                              },15)
                            }}/>
                          <MyButton 
                            content={">"}
                            class="btn btn-primary"
                            onClick={increaseMonth} 
                            disable={nextMonthButtonDisabled}/>
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}>
                          <Label style={{fontSize: '.944rem'}} className="mb-0">{moment(date).format("MMM")}</Label>
                          <Label style={{fontSize: '.944rem'}} className="mb-0 margin-left-4">{moment(date).format("YYYY")}</Label>
                        </div>
                      </div>
                    )}
                    selected={new Date(startDate)}
                    onChange={async (date) => {
                      if(!initialData) {
                        setStartDate(moment(date).format(Constants.defaultDateFormat).toString());
                        setBlnDisplayDatePicker(false);
                        setBlnDatePickerOnClick(true);
                        setTimeout(() => {
                          setBlnDatePickerOnClick(false)
                        }, 100);
                      }
                    }}
                    inline
                  />
                </div>
              }
              {
                !blnDatePickerOnClick
                &&
                <FullCalendar
                  timeZone="Asia/Kuala_Lumpur"
                  schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                  allDaySlot={true}
                  events={appointmentCalendar?.events || []}
                  eventContent={(eventContent)=> {
                    return(displayService(eventContent))
                  }}
                  height={'1340px'}
                  {...600 > windowSize.innerWidth && {dayMinWidth: 120}}
                  stickyHeaderDates={true}
                  datesSet={async (arg) => {
                    setBlnDisplayDatePicker(false);
                    let startDateTemp = moment(arg.start).format(Constants.defaultDateFormat).toString();
                    let endDateTemp = moment(arg.end).format(Constants.defaultDateFormat).toString();
                    let calendarViewTypeTemp = arg.view.type == "resourceTimeGridDay" ? 'Day' : 'Week'
                    if(!initialData) {
                      setLoadingAppointment(true);
                      await getAppointmentWithType(
                        selectedBranchId,
                        returnRoomTemp(),
                        calendarViewTypeTemp, 
                        startDateTemp,
                        endDateTemp
                      )
                      setLoadingAppointment(false);
                    }
                    setStartDate(startDateTemp)
                    setEndDate(endDateTemp)
                    setCalendarViewType(calendarViewTypeTemp)
                  }}
                  expandRows={true}
                  initialView={calendarViewType == 'Day' ? "resourceTimeGridDay" : "timeGridWeek"}
                  initialDate={startDate}
                  nowIndicator={true}
                  plugins={[
                    interactionPlugin,
                    momentTimezonePlugin,
                    scrollGridPlugin,
                    resourceTimeGridPlugin,
                  ]}
                  resources={appointmentCalendar?.rooms || []}
                  resourceLabelClassNames={"label-truncate"}
                  resourceOrder={[""]}
                  scrollTime={moment().subtract('70', 'minutes').format('HH:mm:ss')}
                  selectable={false}
                  editable={calendarViewType == 'Day' ? moment(startDateWithDateFormat).isSameOrAfter(moment().format(Constants.displayDateFormat)) ? true : false : false}
                  eventDurationEditable={false}
                  eventDrop={({event, revert, newResource})=> {
                    revertDropEvent.current = revert;
                    if (event._def.extendedProps.additionalInfo && !event._def.extendedProps.isBlocker) {
                      if (event._def.extendedProps.additionalInfo.isAbleRescheduleOrDelete) { 
                        let roomId = newResource?._resource.id || event._def.extendedProps.additionalInfo.roomId || Constants.emptyId;
                        let roomName = newResource?._resource.title || event._def.extendedProps.additionalInfo.roomName;
                        let startDate = moment(event.startStr).format(Constants.displayDateFormat);
                        let startDateAndTime = event.startStr;
                        let endDateAndTime = event.endStr;
                        let appointmentId = event._def.publicId;
                        let customerDisplayName = `${event._def.extendedProps.additionalInfo.customerName}`;
                        if (moment(startDate).isSameOrAfter(moment().format(Constants.displayDateFormat))) {
                          setDragAppointmentDetail({
                            roomId: roomId,
                            roomName: roomName,
                            startDateAndTime: startDateAndTime,
                            endDateAndTime: endDateAndTime,
                            appointmentId: appointmentId,
                            customerDisplayName: customerDisplayName
                          })
                          setDragAppointmentModal(true);
                        }
                      }
                      else {
                        revert();
                      }
                    }
                    else {
                      revert();
                    }
                    removeAllTooTips();
                  }}
                  selectConstraint="businessHours"
                  slotDuration={{
                    minute: 30,
                  }}
                  slotEventOverlap={true}
                  slotLabelInterval={{
                    hour: 1,
                  }}
                  slotMinTime={"09:00:00"}
                  slotMaxTime={"23:30:00"}
                  {...calendarViewType == 'Day' && {titleFormat :{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}}
                  headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right:  'today,timeGridWeek,resourceTimeGridDay,myCustomButton'
                  }}
                  customButtons={{
                    myCustomButton: {
                      text: calendarIcon,
                      click: function() {
                        setBlnDisplayDatePicker((valueBlnDisplayDatePicker)=> !valueBlnDisplayDatePicker);
                      }
                    }
                  }}
                  dateClick={async (date) => {
                    setBlnDisplayDatePicker(false);
                    if (blnPermissionCreateAppointment) {
                      if (appointmentCalendar?.rooms) {
                        let blnFound = false;
                        for (let indexRoom=0; indexRoom < appointmentCalendar.rooms.length; indexRoom++) {
                          let valueRoomTemp = _.cloneDeep(appointmentCalendar.rooms[indexRoom]);
                          if(valueRoomTemp.title === date.resource?.title || calendarViewType !== 'Day') {
                            if (valueRoomTemp.businessHours) {
                              // let selectedDay = moment(date.dateStr).day() === 0 ? 7 : moment(date.dateStr).day();
                              let selectedDay = moment(date.dateStr).day();
                              for (let indexBusinessHour=0; indexBusinessHour < valueRoomTemp.businessHours.length; indexBusinessHour++) {
                                let valueBusinessHours = _.cloneDeep(valueRoomTemp.businessHours[indexBusinessHour])
                                let indexDay = valueBusinessHours.daysOfWeek.indexOf(selectedDay);
                                if (indexDay > -1) {
                                  let selectedOperatingTime = moment(date.dateStr).format(Constants.displayCompareTimeFormat)
                                  if (moment(selectedOperatingTime, Constants.displayCompareTimeFormat) >= moment(valueBusinessHours.startTime, Constants.displayCompareTimeFormat) && moment(selectedOperatingTime, Constants.displayCompareTimeFormat) < moment(valueBusinessHours.endTime, Constants.displayCompareTimeFormat)) {
                                    if (objReschedule) {
                                      blnFound=true;
                                      if (objReschedule.actionType === Constants.add) {
                                        history.push({
                                          pathname: `/${RoutesList.appointment}/add`,
                                          state: { 
                                            detail: {
                                              ...location.state.detail,
                                              date: date.dateStr,
                                              roomName: date.resource?.title,
                                              roomId: date.resource?.id,
                                              branchId: selectedBranchId,
                                              branchName: selectedBranchName
                                            }
                                          }
                                        });
                                        return;
                                      }
                                      setLoadingAppointment(true);
                                      let aryAPI : any = [
                                        getAppointmentWithId(objReschedule.appointmentId),
                                        // getServiceTreatmentDropdown()
                                      ]
                                      let resultAPI = await Promise.all(aryAPI);
                                      if (resultAPI[0]) {
                                        let resultAppointmentWithId = _.cloneDeep(resultAPI[0]);
                                        // let serviceTreatmentDropdownList = _.cloneDeep(resultAPI[1]);
                                        if (resultAppointmentWithId) {
                                          resultAppointmentWithId.appointStartDateTime = date.dateStr;
                                          resultAppointmentWithId.appointEndDateTime = date.dateStr;
                                          resultAppointmentWithId.roomName = date.resource?.title || resultAppointmentWithId.roomName;
                                          resultAppointmentWithId.roomId = date.resource?.id || resultAppointmentWithId.roomId;
                                          resultAppointmentWithId.branchId = selectedBranchId;
                                          resultAppointmentWithId.branchName = selectedBranchName;
                                          resultAppointmentWithId.appointEndDateTime = moment(resultAppointmentWithId.appointEndDateTime).add(resultAppointmentWithId.duration, 'minutes').format(Constants.defaultDateFormat);
                                          // resultAppointmentWithId.serviceTreatmentIds.map((selectedValue)=> {
                                          //   serviceTreatmentDropdownList.map((valueServiceTreament)=> {
                                          //     if (selectedValue == valueServiceTreament.id && resultAppointmentWithId) {
                                          //       resultAppointmentWithId.appointEndDateTime = moment(resultAppointmentWithId.appointEndDateTime).add(valueServiceTreament.minutes, 'minutes').add(valueServiceTreament.hours, 'hours').format(Constants.defaultDateFormat)
                                          //     }
                                          //   })
                                          // })
                                          if (resultAppointmentWithId.subBrandId === Constants.emptyId) {
                                            resultAppointmentWithId.subBrandId = "";
                                          }
                                          let resultUpdateAppointment : any = undefined; 
                                          if (objReschedule.actionType === Constants.copy) {
                                            delete resultAppointmentWithId["id"];
                                            delete resultAppointmentWithId["appointmentStatus"];
                                            resultAppointmentWithId.isCopyAppointment = true;
                                            resultUpdateAppointment = await addAppointmentForCalendar(resultAppointmentWithId);
                                          }
                                          else {
                                            resultUpdateAppointment = await updateAppointment(resultAppointmentWithId);
                                          } 
                                          if (resultUpdateAppointment) {
                                            if (resultUpdateAppointment.data) {
                                              if (resultUpdateAppointment.data.isCrossBrand || resultUpdateAppointment.data.isRejoin) {
                                                setAppointmentErrorPrompt(objReschedule.actionType === Constants.copy ? intl.formatMessage({id: "CreateAppointmentWithRejoinOrCrossBrand"}) : intl.formatMessage({id: "UpdateAppointmentWithRejoinOrCrossBrand"}));
                                              }
                                            }

                                            if (resultUpdateAppointment.status === Constants.success) {
                                              setObjReschedule(undefined);
                                              await refreshAppointmentData();
                                            }
                                            else if (resultUpdateAppointment.status === Constants.skip) {
                                              setObjReschedule(undefined);
                                            }                                            
                                          }
                                        }
                                      }
                                      setLoadingAppointment(false);
                                    }
                                    else {
                                      history.push({
                                        pathname: `/${RoutesList.appointment}/add`,
                                        state: { 
                                          detail: {
                                            date: date.dateStr,
                                            roomName: date.resource?.title,
                                            roomId: date.resource?.id,
                                            branchId: selectedBranchId,
                                            branchName: selectedBranchName
                                          }
                                        }
                                      });
                                    }
                                    return;
                                  }
                                }
                              }
                            }
                          }
                          if (blnFound) {
                            return;
                          }
                        }
                      }
                    }
                    removeAllTooTips();
                  }}
                  eventClick={async (info) => {
                    setBlnDisplayDatePicker(false);
                    
                    if (objReschedule) {
                      return;
                    }

                    removeAllTooTips();
                    let indexAppointmentTemp = _.findIndex(appointmentCalendar?.events, {id: info.event._def.publicId})
                    if (indexAppointmentTemp > -1) {
                      if (appointmentCalendar?.events[indexAppointmentTemp].blnAllDay) {
                        return;
                      }

                      if (appointmentCalendar?.events[indexAppointmentTemp].isBlocker) {
                        if (blnPermissionUpdateAppointment) {
                          history.push(`/${RoutesList.appointment}/blocker/edit/${info.event._def.publicId}`)
                        }
                        else {
                          history.push(`/${RoutesList.appointment}/blocker/view/${info.event._def.publicId}`)
                        }
                      }
                      else {
                        promptAppointmentDetailModal(info.event._def.publicId);
                      }
                    }
                  }}
                  {...!returnMobileOrTable() && {eventMouseEnter : handleMouseEnter}}
                  {...!returnMobileOrTable() && {eventMouseLeave : handleMouseLeave}}
                  slotLaneContent={()=> {
                    const displaySlotHover = (aryViews, widthPercentage, index?: number) => {
                      for(let a=0; a<(calendarViewType === 'Week' ? 7 : 1); a++) {
                        aryViews.push(
                          <div
                            key={`appointment_room_${!index ? "week" : index}_${a}`}
                            style={{
                              width: `${widthPercentage}%`, 
                              height: '40px'
                            }}
                            className="appointmentSlotHover">
                          </div>
                        )
                      }
                    }
                    if (blnPermissionCreateAppointment) {
                      let aryViews : any = [];
                      if (appointmentCalendar?.rooms) {
                        let widthPercentage = 100/(calendarViewType === 'Week' ? 7 : appointmentCalendar.rooms.length);
                        if (calendarViewType === 'Week') {
                          displaySlotHover(aryViews, widthPercentage);
                        }
                        else {
                          appointmentCalendar.rooms.map((valueRoomsTemp, index)=> {
                            displaySlotHover(aryViews, widthPercentage, index);
                          })
                        }
                      }
                      return (<div className="flex-direction-row">{aryViews}</div>);
                    }
                  }}
                />
              }
              {
                loadingAppointment && !appointmentDetailModal
                &&
                <LoadingModal/>
              }
              <div style={{display: 'flex'}} className="margin-top-24">
                <div 
                  className={`flex-align-items-center`} 
                  style={{marginRight: '16px'}}>
                  <span>
                    <AccountOutlineIcon size={32} color={Constants.darkorange} className="align-middle me-2"/>
                    {intl.formatMessage({id: "TurnedUp"})}
                  </span>
                </div>
                <div 
                  className={`flex-align-items-center`} 
                  style={{marginRight: '16px'}}>
                  <span>
                    <AccountCheckIcon size={32} color={Constants.darkorange} className="align-middle me-2"/>
                    {intl.formatMessage({id: "Existing"})}
                  </span>
                </div>
                <div 
                  className={`flex-align-items-center`} 
                  style={{marginRight: '16px'}}>
                  <span>
                    <AccountReactivateIcon size={32} color={Constants.darkorange} className="align-middle me-2"/>
                    {intl.formatMessage({id: "Rejoin"})}
                  </span>
                </div>
                <div 
                  className={`flex-align-items-center`} 
                  style={{marginRight: '16px'}}>
                  <span>
                    <AccountMultiplePlusIcon size={32} color={Constants.darkorange} className="align-middle me-2"/>
                    {intl.formatMessage({id: "CrossBrand"})}
                  </span>
                </div>
              </div>
            </div>
          }
          {
            appointmentDetailModal
            &&
            <AppointmentDetailModal
              blnShow={appointmentDetailModal}
              setModal={setAppointmentDetailModal}
              selectedAppointmentDetail={appointmentDetail}
              setSelectedAppointmentDetail={setAppointmentDetail}
              refreshAppointmentData={refreshAppointmentData}
              windowSize={windowSize}
              selectedBranchId={selectedBranchId}
              setObjReschedule={setObjReschedule}/>
          } 
          {
            dragAppointmentModal
            &&
            <ActionPromptModal
              title={`${intl.formatMessage({ id: "DragAppointmentConfirmationMessage" }, {date: moment(dragAppointmentDetail?.startDateAndTime).format(Constants.displayDateAndTimeFormat) || "", room: dragAppointmentDetail?.roomName || "", customer: dragAppointmentDetail?.customerDisplayName || ""})}`}
              primaryActionTitle={`Confirm`}
              showPrompt={dragAppointmentModal}
              setShowPrompt={setDragAppointmentModal}
              onPrimaryClick={async () => {
                setLoadingDragAppointment(true);
                let resultAppointmentWithId : any = await getAppointmentWithId(dragAppointmentDetail.appointmentId);
                if (resultAppointmentWithId) {
                  resultAppointmentWithId.appointStartDateTime = dragAppointmentDetail.startDateAndTime;
                  resultAppointmentWithId.appointEndDateTime = dragAppointmentDetail.endDateAndTime || dragAppointmentDetail.startDateAndTime;
                  resultAppointmentWithId.roomName = dragAppointmentDetail.roomName;
                  resultAppointmentWithId.roomId = dragAppointmentDetail.roomId;
                  resultAppointmentWithId.branchId = selectedBranchId;
                  resultAppointmentWithId.branchName = selectedBranchName;
                  if (resultAppointmentWithId.subBrandId === Constants.emptyId) {
                    resultAppointmentWithId.subBrandId = "";
                  }
                  let resultUpdateAppointment : any = undefined; 
                  resultUpdateAppointment = await updateAppointment(resultAppointmentWithId);
                  if (resultUpdateAppointment) {
                    if (resultUpdateAppointment.status === Constants.success) {
                      setDragAppointmentModal(false);
                      await refreshAppointmentData();
                    }

                    if (resultUpdateAppointment.data) {
                      if (resultUpdateAppointment.data.isCrossBrand || resultUpdateAppointment.data.isRejoin) {
                        setAppointmentErrorPrompt(intl.formatMessage({id: "UpdateAppointmentWithRejoinOrCrossBrand"}));
                      }
                    }
                  }
                }
                setLoadingDragAppointment(false);
              }}
              onCancelClick={()=> {
                setDragAppointmentDetail(undefined);
                if (revertDropEvent.current) {
                  revertDropEvent.current();
                }
              }}
              localLoading={loadingDragAppointment}
            />
          }
          {
            Boolean(appointmentErrorPrompt)
            &&
            <ActionPromptModal 
              title={`${appointmentErrorPrompt}`}
              primaryActionTitle={appointmentErrorPrompt === intl.formatMessage({ id: "CreateAppointmentWithRejoinOrCrossBrand" }) || appointmentErrorPrompt === intl.formatMessage({ id: "UpdateAppointmentWithRejoinOrCrossBrand" }) ? intl.formatMessage({ id: "Ok" }) : intl.formatMessage({ id: "Close" })}
              showPrompt={Boolean(appointmentErrorPrompt)}
              setShowPrompt={()=> {
                setAppointmentErrorPrompt("");
              }}
              onPrimaryClick={()=> {}}
              blnHideCancelButton={true}
              {...(appointmentErrorPrompt === intl.formatMessage({ id: "CreateAppointmentWithRejoinOrCrossBrand" }) || appointmentErrorPrompt === intl.formatMessage({ id: "UpdateAppointmentWithRejoinOrCrossBrand" })) && { replaceIcon: returnSuccessMarkedIcon }}
            />
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(Appointment);
