import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { IncludesLocationPathName, contructValidationErrorMessage, returnOperatingHours } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import moment from "moment";

const PayrollItemDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { testTimeZoneStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const {
    addTime
  } = testTimeZoneStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } Test`;
  const breadCrumbList = [{ title: "Test", urlPath: RoutesList.payrollItem }];
  const timeList = returnOperatingHours(true);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      testDateTimeOffset1: "",
      testDateTimeOffset2: "",
      testDateTime1: "",
      testDateTime2: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.testDateTimeOffset1 = moment(valuesTemp.testDateTimeOffset1).format("YYYY-MM-DDThh:mm:ss.sss[Z]")
      valuesTemp.testDateTimeOffset2 = moment(valuesTemp.testDateTimeOffset2).format("YYYY-MM-DDThh:mm:ssZ")
      valuesTemp.testDateTime1 = moment(valuesTemp.testDateTime1).format("YYYY-MM-DDThh:mm:ss.sss[Z]")
      valuesTemp.testDateTime2 = moment(valuesTemp.testDateTime2).format("YYYY-MM-DDThh:mm:ssZ")
      try {
        await addTime(valuesTemp);
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput =
    validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    if (validation.isSubmitting) {
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])


  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={4}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={"Test"}
                  h4Title
                />
                <SingleColumnRowParent>
                  <GeneralInput
                    title="Test Date Time Offset 1"
                    name="testDateTimeOffset1"
                    className="mb-3 mt-4"
                    type="date"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title="Test Date Time Offset 2"
                    name="testDateTimeOffset2"
                    type="date"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title="Test Date Time 1"
                    name="testDateTime1"
                    type="date"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title="Test Date Time 2"
                    name="testDateTime2"
                    type="date"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PayrollItemDetail);
