

import { useEffect, useState } from "react";
import _ from 'lodash';
import { ObjectForReportRow } from "../../../app/models/common";
import { Link } from "react-router-dom"
import { useIntl } from "react-intl";
import { Label } from "reactstrap";
import { displaySummaryDetailsTableCellUI, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnRouteFromModule } from "../../../app/common/function/function";
import MyButton from "../../../app/components/form/MyButton";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import GeneralStatus from "../../../app/components/form/GeneralStatus";

interface Props {
  row: any;
  rowTitle: string;
  rowFieldName: string;
  rowColumns: ObjectForReportRow[];
  keyFieldName: string;
  actionTitle?: string;
  actionPathName?: string;
  linkPathName?: string;
  linkFieldName?: string;
  statusColorFunction?: Function;
  childRow?: any;
  blnExcelUIDesign?: boolean;
  tableFixedWidth?: number;
  blnFixedTableWidthByTableFixedWidth?: boolean;
  tableHeaderBackgroundColor?: string;
  tableRowBackgroundColor?: string;
  pageSize?: number;
}

export default function ReportExpandRow(props: Props) {
  const intl = useIntl();
  const [localColumns, setLocalColumns] = useState<any[]>([]);

  useEffect(()=> {
    let localColumnsTemp : any = [];
    localColumnsTemp.push({
      dataField: props.keyFieldName, //field name from array to display
      text: props.keyFieldName, //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row[props.keyFieldName]}</>, //Custom UI to display
    })

    props.rowColumns.map((keyValueRowColumnTemp, index) => {
      if (keyValueRowColumnTemp.displayFieldCondition && keyValueRowColumnTemp.displayFieldValueCompare) {
        if (props.row[keyValueRowColumnTemp.displayFieldCondition] !== keyValueRowColumnTemp.displayFieldValueCompare) {
          return;
        }
      }

      if (keyValueRowColumnTemp.hideFieldCondition && keyValueRowColumnTemp.hideFieldValueCompare) {
        if (props.row[keyValueRowColumnTemp.hideFieldCondition] === keyValueRowColumnTemp.hideFieldValueCompare) {
          return;
        }
      }
      
      localColumnsTemp.push({
        dataField: keyValueRowColumnTemp.value,
        text: intl.formatMessage({ id: keyValueRowColumnTemp.label}).toUpperCase(),
        headerStyle: { 
          textAlign: (keyValueRowColumnTemp.type === "status" || keyValueRowColumnTemp.type === "action") ? "center" : "", 
          ...props.tableFixedWidth ? {width: `${props.tableFixedWidth}px`, minWidth: `${props.tableFixedWidth}px`, maxWidth: `${props.tableFixedWidth}px`} : undefined, 
          ...props.tableHeaderBackgroundColor ? { backgroundColor: props.tableHeaderBackgroundColor } : undefined
        },
        ...props.tableRowBackgroundColor ? { style: { backgroundColor : props.tableRowBackgroundColor }} : undefined,
        formatter: (cellContent, row) => (
          keyValueRowColumnTemp.label === "Action"
          ?
          <span className="flex-justfity-content-center">
            <MyButton
              type="button"
              content={props.actionTitle || intl.formatMessage({ id: "View" })}
              class={"btn btn-primary flex-justfity-content-center"}
              onClick={()=> {
                window.open(`${props.actionPathName}${row[keyValueRowColumnTemp.value]}`)
              }}
              disable={false}
            />
          </span>
          :
          (row[keyValueRowColumnTemp.value] || row[keyValueRowColumnTemp.value] === 0) 
          && 
          keyValueRowColumnTemp.type === 'link'
          &&
          ((keyValueRowColumnTemp.routeObject && row[keyValueRowColumnTemp.routeObject.routePathId] !== Constants.emptyId) || !keyValueRowColumnTemp.routeObject)
          ?
          <Link 
            to={
              keyValueRowColumnTemp.routeObject 
              ? 
              `/${returnRouteFromModule(row[keyValueRowColumnTemp.routeObject.routePathField])}/${keyValueRowColumnTemp.routeObject.routePath}/${row[keyValueRowColumnTemp.routeObject.routePathId]}`
              : 
              `${props.linkPathName}${row[props.linkFieldName || ""]}`
            } 
            target={Constants.supportBlank} 
            rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row[keyValueRowColumnTemp.value]}</Label>
          </Link>
          :
          row[keyValueRowColumnTemp.value] || row[keyValueRowColumnTemp.value] === 0 || keyValueRowColumnTemp.type === 'currency' 
          ? 
          keyValueRowColumnTemp.type === 'date' 
          ?
          moment(row[keyValueRowColumnTemp.value]).format(Constants.displayDateAndTimeFormat) 
          :
          keyValueRowColumnTemp.type === 'dateWithoutTime' 
          ? 
          moment(row[keyValueRowColumnTemp.value]).format(Constants.displayDateFormat) 
          :
          keyValueRowColumnTemp.type === 'date-time-range'
          ?
          <span>
            <div className="">
              {`${moment(row[keyValueRowColumnTemp.value]).format(Constants.displayDateFormat)}`}
            </div>
            <div className="small-text">
              {moment(row[keyValueRowColumnTemp.value]).format(Constants.displayTimeFormat)} - {moment(row[keyValueRowColumnTemp.secondValue]).format(Constants.displayTimeFormat)}
            </div>
          </span>
          : 
          keyValueRowColumnTemp.type === 'currency' 
          ? 
          newSpaceBeforeCapitalLetter(returnPriceWithCurrency(row[keyValueRowColumnTemp.value])) 
          : 
          keyValueRowColumnTemp.type === 'status' 
          ? 
          <GeneralStatus
            status={row[keyValueRowColumnTemp.value]}
            statusColorFunction={props.statusColorFunction ? props.statusColorFunction : ()=> {}}/>
          :
          keyValueRowColumnTemp.type === 'dynamic-table-cell-ui' && keyValueRowColumnTemp.tableCellObject
          ?
          displaySummaryDetailsTableCellUI(row[keyValueRowColumnTemp.tableCellObject.rowFieldName], keyValueRowColumnTemp.value, keyValueRowColumnTemp.tableCellObject.blnPercentage, keyValueRowColumnTemp.tableCellObject.blnCurrency, "", "", keyValueRowColumnTemp.tableCellObject.hyperLinkPathFieldName)
          :
          keyValueRowColumnTemp.type === 'string-without-space' 
          ? 
          row[keyValueRowColumnTemp.value]
          :
          newSpaceBeforeCapitalLetter(row[keyValueRowColumnTemp.value]) 
          : 
          Constants.emptyData
        ),
      })
    })
    setLocalColumns(localColumnsTemp)
  }, [])
  
  if (localColumns.length > 0) {
    if (props.row[props.rowFieldName]) {
      if (props.row[props.rowFieldName].length > 0) {
        let aryRowTemp = props.row[props.rowFieldName];
        return (
          props.blnExcelUIDesign
          ?
          <div 
            style={{
              padding: "-0.25px", 
              marginLeft: props.childRow ? "-1px" : "42px", 
              width: !props.blnFixedTableWidthByTableFixedWidth || !props.tableFixedWidth ? undefined : `${(props.tableFixedWidth-20)*localColumns.length}px`
            }}>
            {
              <style type="text/css">
                {`
                  .padding-2-5px {
                    padding: 0px !important;
                  }
                  .table {
                    margin-bottom: 0px !important;
                  }
                `}
              </style>
            }
            <PaginationTableWithoutApi
              title={props.rowTitle}
              options={aryRowTemp} //Dropdown data to display
              columns={localColumns}
              pageSize={Constants.maxPageSize}
              blnHideTotal={true}
              keyField={props.keyFieldName}
              headerClasses={"table-light"}
              blnDisplayForMainListing={true}
              {...props.childRow && {expandRow: props.childRow}}/>
          </div>
          :
          <div key={`expandrow::${aryRowTemp[0][props.keyFieldName]}`} style={{marginTop: "8px", marginBottom: "8px", marginLeft: "42px", padding: "18px", border: "solid", borderWidth: "0.2px", borderColor: "lightblue", borderRadius: "8px"}}>
            <Label>{props.rowTitle}</Label>
            <div className="" style={{alignItems: 'center', marginTop: "6px"}}>
              <PaginationTableWithoutApi
                title={props.rowTitle}
                options={aryRowTemp} //Dropdown data to display
                columns={localColumns}
                keyField={props.keyFieldName}
                {...props.pageSize && { pageSize: props.pageSize }}/>
            </div>
          </div>
        )
      }
    }
  }
  
  return (
    props.blnExcelUIDesign
    ?
    <div key={`expandrow::`} style={{marginLeft: "42px", padding: "10px", paddingTop: "18px", paddingBottom: "18px", backgroundColor: props.tableRowBackgroundColor}}>
      - {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "Item"})})} ({props.rowTitle})
    </div>
    :
    <div key={`expandrow::`} style={{marginTop: "8px", marginBottom: "8px", marginLeft: "42px", padding: "18px", border: "solid", borderWidth: "0.2px", borderColor: "lightblue", borderRadius: "8px"}}>
      <Label>{props.rowTitle}</Label>
      <div>- {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "Item"})})}</div>
    </div>
  )
}