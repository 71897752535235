import { PaginationRequestBody } from "../models/pagination";
import { CustomerPaymentAdjustmentListObject, CustomerPaymentAdjustmentRequestObject, CustomerPaymentAdjustmentReviewObject, CustomerPaymentAdjustmentDetailObject, CustomerPaymentAdjustmentWithSalesOrderDetailObject, CustomerPaymentAdjustmentWithSalesOrderObject } from "../models/customerPaymentAdjustment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class customerPaymentAdjustmentStore {
  customerPaymentAdjustmentList: CustomerPaymentAdjustmentListObject[] = [];
  customerPaymentAdjustmentDetail: CustomerPaymentAdjustmentDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerPaymentAdjustmentList = [];
    this.customerPaymentAdjustmentDetail = undefined;
  }

  setCustomerPaymentAdjustmentDetail = (customerPaymentAdjustmentDetail: CustomerPaymentAdjustmentDetailObject | undefined) => {
    this.customerPaymentAdjustmentDetail = customerPaymentAdjustmentDetail;
  }
  
  getCustomerPaymentAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerPaymentAdjustment = await agent.CustomerPaymentAdjustment.customerPaymentAdjustmentList(PaginationRequestBody);
      runInAction(() => {
        this.customerPaymentAdjustmentList = resultCustomerPaymentAdjustment.data;
        store.commonStore.setTotalItem(resultCustomerPaymentAdjustment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPaymentAdjustmentList = [];
    }
  }

  getCustomerPaymentAdjustmentWithId = async (id: string) => {
    try{
      const resultCustomerPaymentAdjustmentDetail = await agent.CustomerPaymentAdjustment.customerPaymentAdjustmentDetail(id);
      runInAction(() => {
        this.customerPaymentAdjustmentDetail = resultCustomerPaymentAdjustmentDetail;
      });
      return Promise.resolve(resultCustomerPaymentAdjustmentDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPaymentAdjustmentDetail = undefined;
    }
  }

  getCustomerPaymentAdjustmentWithSalesOrder = async (CustomerPaymentAdjustmentWithSalesOrderRequestBody: CustomerPaymentAdjustmentWithSalesOrderObject) => {
    try{
      const resultCustomerPaymentAdjustmentWithSalesOrder = await agent.CustomerPaymentAdjustment.customerPaymentAdjustmentWithSalesOrder(CustomerPaymentAdjustmentWithSalesOrderRequestBody);
      return Promise.resolve(resultCustomerPaymentAdjustmentWithSalesOrder);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  requestCustomerPaymentAdjustment = async (customerPaymentAdjustmentRequestBody: CustomerPaymentAdjustmentRequestObject) => {
    try{
      let resultRequestCustomerPaymentAdjustment = await agent.CustomerPaymentAdjustment.requestCustomerPaymentAdjustment(customerPaymentAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`PaymentAdjustmentRequestSuccess`)
      return Promise.resolve(resultRequestCustomerPaymentAdjustment);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewCustomerPaymentAdjustment = async (customerPaymentAdjustmentRequestBody: CustomerPaymentAdjustmentReviewObject) => {
    try{
      let resultReviewPaymentAdjustment = await agent.CustomerPaymentAdjustment.customerPaymentAdjustmentReview(customerPaymentAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`PaymentAdjustmentReviewSuccess`)
      
      if(resultReviewPaymentAdjustment){
        return Promise.resolve({status: Constants.success, data: resultReviewPaymentAdjustment});
      }
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomerPaymentAdjustment  = async (id: string, name: string) => {
    try {
      await agent.CustomerPaymentAdjustment.deleteCustomerPaymentAdjustment(id);
      store.commonStore.setSuccessMessage(`PaymentAdjustmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}