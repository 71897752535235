import React from "react";
import { Col, Row } from "reactstrap";

interface Props {
	children: any;
  blnDoubleTab?: boolean;
  blnDropDown?: boolean;
}

//这个是当 children ui 的同一排是只有单独一个的时候
export default function SingleColumnRowParent(props: Props) {
	return (
		<Row>
      {
        props.blnDropDown
        ?
        <Col xs={"12"} md={"12"} lg={"6"} xl={"5"}>
          {props.children}
        </Col>
        :
        <Col xs={"12"} md={"12"} xl={props.blnDoubleTab ? "12" : "6"}>
          {props.children}
        </Col>
      }
    </Row>
	);
}
