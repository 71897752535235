import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import MyButton from "../../app/components/form/MyButton";
import CustomerPurchasePackageDetail from "./Modal/CustomerPurchasePackageDetail";
import { AvailableProductPurchaseObject, CustomerPurchasePackage, CustomerPurchaseProduct, MandatoryProductsObject, RedeemPurchaseDetailObject } from "../../app/models/customerOrder";
import { checkPermission, contructValidationErrorMessage, displayPromotionCode, getBranchId, getBranchName, getPurchaseTypeIcon, newSpaceBeforeCapitalLetter, paymentStatusColor, redemptionCalculation, returnAdvancedSearchIcon, returnAdvancedSearchText, returnPriceWithCurrency, returnSplitBackgroundRow } from "../../app/common/function/function";
import CustomerPurchaseCart from "./subView/CustomerPurchaseCart";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomerPurchaseDetailModal from "./Modal/CustomerPurchaseDetailModal";
import { history } from "../..";
import { useIntl } from "react-intl";
import { OptionalProductObject } from "../../app/models/customerSalesOrder";
import CustomerSearchModal from "./Modal/CustomerSearchModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import CustomerPurchaseConfimationModal from "./Modal/CustomerPurchaseConfimationModal";
import moment from "moment";
import { CustomerListObject } from "../../app/models/customer";
import { useLocation } from "react-router-dom";

const CustomerPurchase = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { customerStore, employeeStore, customerSalesOrderStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerSalesOrderRedeemList, setCustomerSalesOrderRedeemList, getCustomerSalesOrderRedeem, submitCustomerSalesOrderRedeem } = customerSalesOrderStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [customerPurchasePackageDetailModal, setCustomerPurchasePackageDetailModal] = useState(false);
  const [selectedPackageDetail, setSelectedPackageDetail] = useState<CustomerPurchasePackage | undefined>(undefined);
  const [selectedRedeemPurchaseDetails, setSelectedRedeemPurchaseDetails] = useState<RedeemPurchaseDetailObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerPurchaseList, setCustomerPurchaseList] = useState<any>([])
  const [customerPurchaseDetailModal, setCustomerPurchaseDetailModal] = useState(false);
  const [selectedCustomerPurchaseDetail, setSelectedCustomerPurchaseDetail] = useState<RedeemPurchaseDetailObject | undefined>(undefined);
  const [tableExpandRowKeyList, setTableExpandRowKeyList] = useState<string[]>([]);
  const pageSize = Constants.maxPageSize;
  const spanFontSize = "14px";
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [customerPurchaseConfirmationModal, setCustomerPurchaseConfirmationModal] = useState(false);
  const [blnBlockRedemptionSubmit, setBlnBlockRedemptionSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionCreateCustomerDeliverOrder = checkPermission([PermissionConstants.CreateCustomerDeliverOrder]);
  const [localCustomerDropdownList, setLocalCustomerDropdownList] = useState<CustomerListObject[]>([]);
  const blnPermissionCreateCustomerOrder = checkPermission([PermissionConstants.CreateCustomerOrder]);
  const blnPermissionBackdateCustomerDeliverOrder= checkPermission([PermissionConstants.BackdateCustomerDeliverOrder]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const location = useLocation();

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      customerName: selectedCustomer,
      customerId: selectedCustomerId,
      customerNo:"",
      customerMobileNo: "",
      branchId: getBranchId(),
      branchName: getBranchName(),
      isBackdate: false,
      performedDateTime: "",
      note: "",
      redeemPurchaseDetails: []
    },
    validationSchema: Yup.object({
      redeemPurchaseDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Item" }).toLowerCase() })),
    }),
    onSubmit: async (values) => {
      try {
        const valuesTemp: any = _.cloneDeep(values);
        let blnValidationRequired = false;
        let redeemPurchaseDetailsTemp = valuesTemp.redeemPurchaseDetails.map((value)=> {
          if (value.availableOptionalProducts) {
            delete value["availableOptionalProducts"];
          }
          if (value.availableQuantity) {
            delete value["availableQuantity"];
          }
          if (value.availableSessions) {
            delete value["availableSessions"];
          }
          if (value.selectedId) {
            delete value["selectedId"];
          }
          if (value.serviceProductUsed) {
            value.selectedProductIds = value.serviceProductUsed.map((valueProductUsed)=> valueProductUsed.value)
            delete value["serviceProductUsed"];
          }
          if (value.mandatoryProductsUsed) {
            delete value["mandatoryProductsUsed"];
          }
          if (value.mandatoryProducts) {
            delete value["mandatoryProducts"];
          }
          if (value.customerInvoiceId) {
            value.salesOrderId = value.customerInvoiceId;
            delete value["customerInvoiceId"];
          }
          if (value.customerPurchaseId) {
            value.salesOrderDetailId = value.customerPurchaseId;
            delete value["customerPurchaseId"];
          }
          if (value.customerPurchaseDetailId) {
            value.salesOrderDetailRedeemId = value.customerPurchaseDetailId;
            delete value["customerPurchaseDetailId"];
          }
          if (value.availableOptionalBranchMachines) {
            delete value["availableOptionalBranchMachines"];
          }
          if (value.optionalBranchMachinesUsed) {
            value.selectedMachines = value.optionalBranchMachinesUsed.map((valueOptionalBranchMachinesUsed)=> ({branchMachineId: valueOptionalBranchMachinesUsed.value, consumeQuantity: Number(valueOptionalBranchMachinesUsed.consumeQuantity)}));
            delete value["optionalBranchMachinesUsed"];
          }

          if (value.validationRequired) {
            blnValidationRequired = true;
          }
          return value;
        })

        if (blnValidationRequired) {
          if (Constants.validationError) {
            console.log (`Validation Errors :: Line 118`)
          }
          setErrorMessage(intl.formatMessage({ id: "ValidationError"}))
          return;
        }

        valuesTemp.redeemCustomerSalesOrderRequestDetail = redeemPurchaseDetailsTemp;
        delete valuesTemp["redeemPurchaseDetails"];

        if (valuesTemp.performedDateTime) {
          valuesTemp.performedDateTime = moment(valuesTemp.performedDateTime).format(Constants.defaultDateFormat)
        }
        
        if (!customerPurchaseConfirmationModal){
          prefillCustomerInfo(selectedCustomerId);
          setCustomerPurchaseConfirmationModal(true);
        }
        else {
          setBlnBlockRedemptionSubmit(true);
          let result = await submitCustomerSalesOrderRedeem(valuesTemp);
          if (result?.status == Constants.success) {
            let valuesFinal : any = _.cloneDeep(values);
            if (result?.data) {
              valuesFinal = {
                ...values,
                customerDeliverOrders: result?.data?.redeemedDeliverOrders
              }
              customerDropdownList.map((value) => {
                if(selectedCustomerId == value.id){
                  valuesFinal = {
                    ...valuesFinal,
                    customerNo: value.customerNo,
                    customerMobileNo : value.mobileNo,
                  }
                }
              })
            } 
            setTimeout(() => {
              setSuccessMessage("")
              history.push({
                pathname: `/${RoutesList.customerPurchaseAcknowledgement}`,
                state: { detail: valuesFinal }
              });
            }, Constants.dismissSuccessMessage)
          }
        }
      }
      finally {
        setTimeout(()=> {
          setBlnBlockRedemptionSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  });

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCustomerPurchaseListAPI() {
      setLocalLoading(true)
      setCustomerSalesOrderRedeemList([]);
      let aryAPI: any = [
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true })
      ];
      await Promise.all(aryAPI);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerOrder], true)) {
      return;
    }

    fetchCustomerPurchaseListAPI();

    return (()=> {
      setCustomerSalesOrderRedeemList([]);
    })
  }, [])

  useEffect(()=> {
    if (!initialCallAPI) {
      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.customerId && location.state.detail.customerName) {
            setDisplayCustomerDropdown(false);
            setSelectedCustomerId(location.state.detail.customerId);
            setSelectedCustomer(location.state.detail.customerName);
            setTableExpandRowKeyList([location.state.detail.customerSalesOrderNo]);
            let localCustomerDropdownTemp : any = [{
              id: location.state.detail.customerId,
              preferredName: location.state.detail.customerName,
              customerNo: location.state.detail.customerNo,
              mobileNo: location.state.detail.customerMobileNo,
            }];
            setLocalCustomerDropdownList(localCustomerDropdownTemp);
            setTimeout(()=> {
              setDisplayCustomerDropdown(true);
            }, 100)
          }
        }
      }
    }
  }, [initialCallAPI])

  useEffect(()=> {
    if (selectedCustomerId && !initialCallAPI) {
      fetchCustomerPurchaseAPI(true);
    }
  }, [selectedCustomerId])

  useEffect(()=> {
    if (customerSalesOrderRedeemList) {
      let customerPurchaseListTemp: any = [];
      customerSalesOrderRedeemList.map((valueCustomerSalesOrderRedeemListTemp)=> {
        let aryDetailTemp : any = [];
        valueCustomerSalesOrderRedeemListTemp.redeemCustomerSalesOrderDetail.map((valueRedeemCustomerSalesOrderDetailTemp)=> {
          //This index is to find items from same package.
          let indexDetail = _.findIndex(aryDetailTemp, {id: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId})
          if (indexDetail > -1) {
            let detailTemp : any = {
              ...aryDetailTemp[indexDetail],
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && { availableSessions : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct && { maxRedeemProductQuantity : valueRedeemCustomerSalesOrderDetailTemp.packageTotalRedeemCount, redeemedProductQuantity: valueRedeemCustomerSalesOrderDetailTemp.packageRedeemedCount, availableProductsRedeem : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && { optionalProductCost : valueRedeemCustomerSalesOrderDetailTemp.totalPrice, redeemedProductCost: valueRedeemCustomerSalesOrderDetailTemp.redeemedPrice, optionalAvailableProductCost: valueRedeemCustomerSalesOrderDetailTemp.availablePrice},
              availableProducts: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageProduct, aryDetailTemp[indexDetail].availableProducts) : aryDetailTemp[indexDetail].availableProducts,
              availableServices: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageService, aryDetailTemp[indexDetail].availableServices) : aryDetailTemp[indexDetail].availableServices,
              status: valueRedeemCustomerSalesOrderDetailTemp.status,
              paymentStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus,
              promotionCode: valueRedeemCustomerSalesOrderDetailTemp.promotionCode || ""
            }

            aryDetailTemp[indexDetail] = detailTemp;
          }
          else {
            let detailTemp : any = {
              id: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId,
              customerInvoiceNo: valueCustomerSalesOrderRedeemListTemp.salesOrderNo,
              customerInvoiceId: valueCustomerSalesOrderRedeemListTemp.salesOrderId,
              salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
              type: valueRedeemCustomerSalesOrderDetailTemp.purchaseType,
              packageId:  valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailId,
              packageName: valueRedeemCustomerSalesOrderDetailTemp.packageName,
              productName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
              serviceName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
              quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
              redeemdedQuantity: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
              availableProducts: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageProduct, []) : [],
              availableServices: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService ? convertPackageServiceOrProduct(valueRedeemCustomerSalesOrderDetailTemp, Constants.packageService, []) : [],
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && { availableSessions : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct && { maxRedeemProductQuantity : valueRedeemCustomerSalesOrderDetailTemp.packageTotalRedeemCount, redeemedProductQuantity: valueRedeemCustomerSalesOrderDetailTemp.packageRedeemedCount, availableProductsRedeem : valueRedeemCustomerSalesOrderDetailTemp.packageTotalAvailableRedeemCount},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && { optionalProductCost : valueRedeemCustomerSalesOrderDetailTemp.totalPrice, redeemedProductCost: valueRedeemCustomerSalesOrderDetailTemp.redeemedPrice, optionalAvailableProductCost: valueRedeemCustomerSalesOrderDetailTemp.availablePrice},
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.productOnly && { productId : valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId, availableQuantity: valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount },
              ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.serviceTreatmentOnly && { serviceTreatmentId : valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId, availableSessions : valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount, optionalProducts: valueRedeemCustomerSalesOrderDetailTemp.optionalProducts, mandatoryProducts: valueRedeemCustomerSalesOrderDetailTemp?.mandatoryProducts || [], optionalBranchMachines: valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines || []},
              status: valueRedeemCustomerSalesOrderDetailTemp.status,
              paymentStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus,
              promotionCode: valueRedeemCustomerSalesOrderDetailTemp.promotionCode || ""
            }
            aryDetailTemp.push(detailTemp)
          }
        })
        customerPurchaseListTemp.push({
          id: valueCustomerSalesOrderRedeemListTemp.salesOrderNo,
          customerSalesOrderId: valueCustomerSalesOrderRedeemListTemp.salesOrderId,
          customerInvoiceBalance: valueCustomerSalesOrderRedeemListTemp.balance,
          customerInvoiceStatus: valueCustomerSalesOrderRedeemListTemp.paymentStatus,
          details: aryDetailTemp
        })
      })
      
      setCustomerPurchaseList(customerPurchaseListTemp);
    }
  }, [customerSalesOrderRedeemList])

  useEffect(()=> {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log (`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  async function fetchCustomerPurchaseAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerSalesOrderRedeem({pageNumber: currentPage, pageSize: pageSize, customerId: selectedCustomerId, branchId: getBranchId(), })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CustomerPurchaseColumns = [
    {
      dataField: "id", //field name from array to display
      text: intl.formatMessage({id: "CustomerSalesOrderNo"}).toUpperCase(), //display name
      headerStyle: {width: '60%'}, 
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerInvoiceBalance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.customerInvoiceBalance)}</>,
    },
    {
      dataField: "customerInvoiceStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus 
          status={row.customerInvoiceStatus}
          statusColorFunction={paymentStatusColor}/>
      )
    },
    {
      dataField: "customerSalesOrderId",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <MyButton
          type="button"
          class="btn btn-primary"
          content={intl.formatMessage({ id: "View" })}
          disable={!blnPermissionManageCustomerSalesOrder}
          onClick={(event: any)=> {
            window.open(`/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}`)
            event.stopPropagation() //This is to stop trigger other button action -> e.g expand row
          }}
        />
      )
    },
  ];

  const displayServicesAndProductRow = (valueRow: CustomerPurchasePackage | CustomerPurchaseProduct | any) => {
    let value = _.cloneDeep(valueRow);
    let availableProductsTemp : AvailableProductPurchaseObject[] = [];
    let availableOptionalProductsTemp: AvailableProductPurchaseObject[] = [];
    if (value.availableProducts) {
      value.availableProducts.map((valueProductTemp)=> {
        if (valueProductTemp.isOptional) {
          availableOptionalProductsTemp.push(valueProductTemp)
        }
        else {
          availableProductsTemp.push(valueProductTemp)
        }
      })
    }
    value.availableProducts = availableProductsTemp;
    value.availableOptionalProducts = availableOptionalProductsTemp;
    let aryView : any = []
    if (value.availableServices) {
      if (value.availableServices.length > 0) {
        aryView.push(
          <div key={`div_service`}>
            <span style={{fontWeight: 'bold', fontSize: spanFontSize}}>{intl.formatMessage({id: "ServicesTreatment"})}: </span>
            <span style={{fontWeight: 400, marginLeft: '8px', color: 'grey', fontSize: spanFontSize}}>
              {`${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableSessions" })} : ${value.availableSessions}`}
            </span>
            <ul className="verti-timeline list-unstyled">
              {
                value.availableServices.map((valueService, index)=> {
                  return (
                    <li className="event-list" style={{paddingTop: "12px", paddingBottom: "12px", fontSize: spanFontSize}} key={`service_list_${index}`}>
                      <div className="event-timeline-dot" style={{paddingTop: "12px", paddingBottom: "12px", fontSize: spanFontSize}}>
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <span className="">{getPurchaseTypeIcon(Constants.service)}</span>
                            <span className="margin-left-8" style={{fontSize: spanFontSize}}>{`${valueService.serviceTreatmentName}`}</span>
                            <span className="margin-left-8" style={{fontSize: spanFontSize}}>{`(${valueService.redeemedSessions}/${valueService.quantity})`}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        )
      }
    }
    if (value.availableProducts) {
      if (value.availableProducts.length > 0) {
        aryView.push(
          <div className="margin-top-8" key={`div_available_product`}>
            <span style={{fontWeight: 'bold', fontSize: spanFontSize}}>{intl.formatMessage({id: "Products"})} : </span>
            <span style={{fontWeight: 400, marginLeft: '8px', color: 'grey', fontSize: spanFontSize}}>
              {`${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "AvailableQuantity" })} : ${value.availableProductsRedeem}`}
            </span>
            <ul className="verti-timeline list-unstyled">
              {
                value.availableProducts.map((valueProduct, index)=> {
                  return (
                    <li className="event-list" style={{paddingTop: "12px", paddingBottom: "12px"}} key={`products_list_${index}`}>
                      <div className="event-timeline-dot" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <span className="">{getPurchaseTypeIcon(Constants.product)}</span>
                            <span className="margin-left-8" style={{fontSize: spanFontSize}}>{`${valueProduct.productName}`}</span>
                            <span className="margin-left-8" style={{fontSize: spanFontSize}}>{`(${valueProduct.redeemedQuantity}/${valueProduct.quantity})`}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        )
      }
    }
    if (value.availableOptionalProducts) {
      if (value.availableOptionalProducts.length > 0) {
        aryView.push(
          <div className="margin-top-8" key={`div_available_optional`}>
            <span style={{fontWeight: 'bold', fontSize: spanFontSize}}>{intl.formatMessage({id: "OptionalProducts"})} : </span>
            <span style={{fontWeight: 400, marginLeft: '8px', color: 'grey', fontSize: spanFontSize}}>
              {`(${intl.formatMessage({ id: "Total" })} ${intl.formatMessage({ id: "LeftOver" })} ${intl.formatMessage({ id: "OptionalProductCost" })} : ${value.optionalAvailableProductCost})`}
            </span>
            <ul className="verti-timeline list-unstyled">
              {
                value.availableOptionalProducts.map((valueOptionalProduct, index)=> {
                  return (
                    <li className="event-list" style={{paddingTop: "12px", paddingBottom: "12px"}} key={`optional_products_list_${index}`}>
                      <div className="event-timeline-dot" style={{paddingTop: "12px", paddingBottom: "12px"}}>
                        <i className="bx bx-right-arrow-circle"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <span className="">{getPurchaseTypeIcon(newSpaceBeforeCapitalLetter(Constants.optionalProducts))}</span>
                            <span className="margin-left-8" style={{fontSize: spanFontSize}}>{`${valueOptionalProduct.productName}`}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        )
      }
    }

    if (aryView.length > 0) {
      return (
        <div style={{paddingRight: "1.2rem", paddingLeft: "3rem", paddingBottom: "1rem", alignItems: 'center'}} key={`index_${value.id}`}>
          <div style={{border: "solid", borderWidth: "0.5px", padding: "16px", borderColor: "grey", borderRadius: "8px"}}>
            {aryView}
          </div>
        </div>
      )
    }
  }

  const checkRedemptionExceed = (valueRow: CustomerPurchasePackage | CustomerPurchaseProduct | any) =>{
    let value = _.cloneDeep(valueRow);
    if(value.type == Constants.product){
      if(value.redeemdedQuantity !== value.quantity){
        return false;
      }
    }
    if(value.type == Constants.package){
      if(value.availableSessions > 0 || (value.paymentStatus !== Constants.partial && value.availableProductsRedeem > 0) || (value.paymentStatus !== Constants.partial && value.optionalAvailableProductCost > 0)){
        return false;
      }
    }
    if (value.type === Constants.service && value.salesOrderDetailRedeemType === Constants.serviceTreatmentOnly) {
      if (value.availableSessions > 0) {
        return false;
      }
    } 
    return true;
  }

  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => {
      return (
        row.details.map((value, index) => {
          let buttonContent = intl.formatMessage({id: value.type == Constants.package ? "OutTreatment" : "Redeem"});
          if (value.status !== Constants.active) {
            if (value.status === Constants.creditExchanged) {
              buttonContent = intl.formatMessage({id: "CreditExchanged"})
            }
            else {
              buttonContent = newSpaceBeforeCapitalLetter(value.status);
            }
          }

          if (value.paymentStatus === Constants.partial && value.type === Constants.product && value.availableQuantity === 0) {
            buttonContent = intl.formatMessage({id: "NotEligible"})
          } 

          if (checkRedemptionExceed(value)) {
            if (value.paymentStatus === Constants.partial && value.salesOrderDetailRedeemType !== Constants.serviceTreatmentOnly && (value.redeemdedQuantity !== value.quantity)) {
              buttonContent = intl.formatMessage({id: "NotEligible"})
            }
            else {
              buttonContent = intl.formatMessage({id: "FullyRedeemed"})
            }
          }

          return (
            <div key={`expandrow::${value.customerInvoiceId}_${index}`}>
            	<div className="flex-direction-row" style={{padding: '0.75rem 3rem 0.75rem 3rem', alignItems: 'center'}}>
              	<div style={{fontWeight: 600}}>
                  <span>{getPurchaseTypeIcon(value.type)}</span>
                  {value.type == Constants.package ? `${value.packageName}` : `${value.productName}`} {displayPromotionCode(value.promotionCode)}
                </div>
                {
                  (value.type == Constants.product || value.type == Constants.service)
                  &&
                  <div style={{fontWeight: 400, marginLeft: '8px'}}>
                    {`(${value.redeemdedQuantity}/${value.quantity})`}
                  </div>
                }
                {
                  (value.type == Constants.product || value.type == Constants.service)
                  &&
                  <div style={{fontWeight: 400, marginLeft: '4px', color: 'grey'}}>
                    {value.type == Constants.product ? `(${intl.formatMessage({id: "Product"})})` : `(${intl.formatMessage({id: "ServiceTreament"})})`}
                  </div>
                }
              </div>
              {
                displayServicesAndProductRow(value)
              }
              {
                blnPermissionCreateCustomerDeliverOrder
                &&
                <div style={{width: '100%', marginLeft: "3rem", marginBottom: "16px"}} key={`submit_button_${index}`}>
                  <MyButton
                    type="button"
                    class="btn btn-primary"
                    content={buttonContent}
                    loading={false}
                      onClick={()=> {
                      if (value.type == Constants.package) {
                        let valueTemp = _.cloneDeep(value);
                          let availableProducts : any = [];
                          let availableOptionalProducts : any = [];
                          valueTemp.availableProducts.map((valueProducts)=> {
                          if (valueProducts.isOptional) {
                            availableOptionalProducts.push(valueProducts)
                          }
                          else {
                            availableProducts.push(valueProducts);
                          }
                        })
                        valueTemp.availableProducts = availableProducts;
                        valueTemp.availableOptionalProducts = availableOptionalProducts;
                        setSelectedPackageDetail(valueTemp);
                          let aryRedeemPurchaseDetailsTemp : RedeemPurchaseDetailObject[] = [];
                          let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
                          for (var a=0; a<redeemPurchaseDetailsTemp.length; a++) {
                            let redeemPurchaseDetailsObject : RedeemPurchaseDetailObject = redeemPurchaseDetailsTemp[a];
                            value.availableServices.map((valueServices)=> {
                            if (valueServices.serviceTreatmentId == redeemPurchaseDetailsObject.customerPurchaseDetailId && value.packageId == redeemPurchaseDetailsObject.packageId && value.customerInvoiceId == redeemPurchaseDetailsObject.customerInvoiceId) {
                              aryRedeemPurchaseDetailsTemp.push(redeemPurchaseDetailsObject);
                            }
                          })
                          value.availableProducts.map((valueProducts)=> {
                            if (valueProducts.productId == redeemPurchaseDetailsObject.customerPurchaseDetailId && value.packageId == redeemPurchaseDetailsObject.packageId && value.customerInvoiceId == redeemPurchaseDetailsObject.customerInvoiceId) {
                              aryRedeemPurchaseDetailsTemp.push(redeemPurchaseDetailsObject);
                            }
                          })
                        }
                        setSelectedRedeemPurchaseDetails(aryRedeemPurchaseDetailsTemp);
                        setCustomerPurchasePackageDetailModal(true);
                      }
                      else if (value.type === Constants.service) {
                        let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
                        let indexCustomerOrderDetail = _.findIndex(redeemPurchaseDetailsTemp, {customerInvoiceId: value.customerInvoiceId, customerPurchaseDetailId: value.serviceTreatmentId, purchaseRedeemType: 2})
                        if (indexCustomerOrderDetail > -1) {
                          let  blnProceed = redemptionCalculation(1, redeemPurchaseDetailsTemp[indexCustomerOrderDetail].availableSessions, redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count+1);
                          if (!blnProceed) {
                            return;
                          }
                          setSelectedCustomerPurchaseDetail(_.cloneDeep(redeemPurchaseDetailsTemp[indexCustomerOrderDetail]))
                          setCustomerPurchaseDetailModal(true);
                          // redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count += 1;
                        }
                        else {
                          let  blnProceed = redemptionCalculation(1, value.availableSessions, 1);
                          if (!blnProceed) {
                            return;
                          }
						  value.optionalProducts = _.sortBy(value.optionalProducts, ['categoryName', 'productName']);
                          let serviceProductUsedTemp = value.optionalProducts.map((valueTemp)=> ({label: valueTemp.productName, value: valueTemp.productId, categoryName: valueTemp.categoryName}))
                          let serviceMandatoryProductUsedTemp = value.mandatoryProducts.map((valueTemp)=> ({label: `${valueTemp.productName}`, value: `${valueTemp.productName}`}));
                          let optionalBranchMachinesUsedTemp = value.optionalBranchMachines.map((valueTemp)=> ({label: `${valueTemp.branchMachineName} ${valueTemp.serialNumber ? `(${valueTemp.serialNumber})` : ""}`, value: valueTemp.branchMachineId, consumeQuantity: valueTemp.advisableConsumeQuantity || 0}));
                          let objOrderDetails : RedeemPurchaseDetailObject = {
                            name: value.serviceName,
                            customerInvoiceId: value.customerInvoiceId,
                            customerInvoiceNo: value.customerInvoiceNo || "",
                            purchaseRedeemType: 2, // Service 
                            customerPurchaseId: value.id || "",
                            purchaseDetailRedeemType: 3, // 0 = Package Service, 1 = Package Product, 2 = Product Only, 3 = Service Only, 4 = Package Optional Product
                            packageId: value.packageId,
                            packageName: value.packageName,
                            customerPurchaseDetailId: value.serviceTreatmentId,
                            salesOrderDetailRedeemType: value.salesOrderDetailRedeemType,
                            count: 1,
                            serviceById: null,
                            serviceByName: null,
                            availableOptionalProducts: serviceProductUsedTemp,
                            mandatoryProducts: serviceMandatoryProductUsedTemp,
                            mandatoryProductsUsed: serviceMandatoryProductUsedTemp,
                            serviceProductUsed: [],
                            availableOptionalBranchMachines: optionalBranchMachinesUsedTemp,
                            optionalBranchMachinesUsed: [],
                            availableSessions: value.availableSessions,
                            redeemedSessions: value.redeemdedQuantity,
                            quantity: value.quantity
                          }
                          setSelectedCustomerPurchaseDetail(_.cloneDeep(objOrderDetails))
                          setCustomerPurchaseDetailModal(true);
                          return;
                        }
                      }
                      else {
                        let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
                        if (value.paymentStatus === Constants.partial && value.availableQuantity === 0) {
                          setErrorMessage(`${intl.formatMessage({id: "RequiredFullRedemption"})}`)
                          return;
                        }
                        let indexCustomerOrderDetail = _.findIndex(redeemPurchaseDetailsTemp, {customerInvoiceId: value.customerInvoiceId, customerPurchaseDetailId: value.productId, purchaseRedeemType: 1})
                        if (indexCustomerOrderDetail > -1) {
                          let  blnProceed = redemptionCalculation(2, redeemPurchaseDetailsTemp[indexCustomerOrderDetail].quantity-redeemPurchaseDetailsTemp[indexCustomerOrderDetail].redeemdedQuantity, redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count+1);
                          if (!blnProceed) {
                            return;
                          }
                          setSelectedCustomerPurchaseDetail(_.cloneDeep(redeemPurchaseDetailsTemp[indexCustomerOrderDetail]))
                          setCustomerPurchaseDetailModal(true);
                          // redeemPurchaseDetailsTemp[indexCustomerOrderDetail].count += 1;
                        }
                        else {
                          let  blnProceed = redemptionCalculation(2, value.quantity-value.redeemdedQuantity, 1);
                          if (!blnProceed) {
                            return;
                          }
                          let objOrderDetails : RedeemPurchaseDetailObject = {
                            name: value.productName,
                            customerInvoiceId: value.customerInvoiceId,
                            customerInvoiceNo: value.customerInvoiceNo,
                            salesOrderDetailRedeemType: value.salesOrderDetailRedeemType,
                            purchaseRedeemType: 1, //Product
                            customerPurchaseId: value.id || "",
                            purchaseDetailRedeemType: 2, //Product Only
                            customerPurchaseDetailId: value.productId,
                            redeemdedQuantity: value.redeemdedQuantity,
                            quantity: value.quantity,
                            count: 1
                          }
                          setSelectedCustomerPurchaseDetail(_.cloneDeep(objOrderDetails))
                          setCustomerPurchaseDetailModal(true);
                          return;
                          // redeemPurchaseDetailsTemp.push(objOrderDetails);
                        }
                        // validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp)
                      }
                    }}
                    disable={checkRedemptionExceed(value) || value.status !== Constants.active || value.paymentStatus === Constants.unpaid || (value.paymentStatus === Constants.partial && value.type === Constants.product && value.availableQuantity === 0)}
                  />
                </div>
              }
            	<div className={row.details.length == index+1 ? "" : "table_border_line"}/>
            </div>
          )
        })
    )},
    showExpandColumn: true,
    // onlyOneExpanding: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expanded: tableExpandRowKeyList,
    onExpand: (row, isExpand, rowIndex, e) => {
      setTableExpandRowKeyList(isExpand ? [row.id] : []);
    }
  };

  const dismissMessage = () => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
      setSuccessMessage("");
    }
  }

  const selectCustomerPurchase = (index) => {
    dismissMessage();
    setSelectedCustomerPurchaseDetail(_.cloneDeep(validation.values.redeemPurchaseDetails[index]))
    setCustomerPurchaseDetailModal(true);
  }

  const deleteCustomerPurchase = (index) => {
    let redeemPurchaseDetailsTemp : any = _.cloneDeep(validation.values.redeemPurchaseDetails);
    redeemPurchaseDetailsTemp.splice(index, 1);
    validation.setFieldValue("redeemPurchaseDetails", redeemPurchaseDetailsTemp);
  }

  const productRedemptionEntitlement = (redeemPurchaseDetails: RedeemPurchaseDetailObject[], selectedPackageDetail: CustomerPurchasePackage) => {
    // let serviceRedemption = 0;
    // redeemPurchaseDetails.map((value: RedeemPurchaseDetailObject ) => {
    //   if (value.purchaseRedeemType === 0 && value.purchaseDetailRedeemType === 0) {
    //     serviceRedemption += value.count;
    //   }
    // })
    // if (((selectedPackageDetail.packageSessions || 0)-(selectedPackageDetail.redeemedSessions || 0)) > serviceRedemption) {
    //   setErrorMessage(`${intl.formatMessage({id: "RequiredFullRedemption"})}`)
    //   return false;
    // }

    // if (selectedPackageDetail.customerInvoiceBalance > 0) {
    if (selectedPackageDetail.paymentStatus === Constants.partial) {
      setErrorMessage(`${intl.formatMessage({id: "RequiredFullRedemption"})}`)
      return false;
    }

    return true;
  }

  const convertPackageServiceOrProduct = (valueRedeemCustomerSalesOrderDetailTemp, typeOfPackage, aryExisting) => {
    let aryTemp : any = _.cloneDeep(aryExisting) || [];

    if ((valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct || valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct) && typeOfPackage === Constants.packageProduct) {
      aryTemp.push({
        productId: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId,
        productName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
        isOptional: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageProduct ? false : true,
        quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
        availableQuantity: valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount,
        redeemedQuantity: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
        salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
        ...valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageOptionalProduct && {unitPrice: valueRedeemCustomerSalesOrderDetailTemp.unitPrice}
      })
    }
    else if (valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType === Constants.packageService && typeOfPackage === Constants.packageService) {
      let availableProductsTemp : OptionalProductObject[] = [];
      valueRedeemCustomerSalesOrderDetailTemp.optionalProducts.map((valueOptionalProductsTemp)=> {
        availableProductsTemp.push({
          ...valueOptionalProductsTemp,
        })
      })

      let mandatoryProductsTemp = [];
      if (valueRedeemCustomerSalesOrderDetailTemp.mandatoryProducts) {
        mandatoryProductsTemp = valueRedeemCustomerSalesOrderDetailTemp.mandatoryProducts.map((valueMandatoryProducts: MandatoryProductsObject)=> {
          return ({
            // label:`${valueMandatoryProducts. productName} x ${valueMandatoryProducts.consumeQuantity} ${valueMandatoryProducts.measurementName}`,
            // value:`${valueMandatoryProducts. productName} x ${valueMandatoryProducts.consumeQuantity} ${valueMandatoryProducts.measurementName}`,
            label:`${valueMandatoryProducts. productName}`,
            value:`${valueMandatoryProducts. productName}`,
          })
        })
      }
      
      aryTemp.push({
        serviceTreatmentId: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemId,
        serviceTreatmentName: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemName,
        quantity: valueRedeemCustomerSalesOrderDetailTemp.totalRedeemCount,
        availableSessions: valueRedeemCustomerSalesOrderDetailTemp.availableRedeemCount,
        redeemedSessions: valueRedeemCustomerSalesOrderDetailTemp.redeemedCount,
        salesOrderDetailRedeemType: valueRedeemCustomerSalesOrderDetailTemp.salesOrderDetailRedeemType,
        availableProducts: availableProductsTemp,
        mandatoryProducts: mandatoryProductsTemp,
        optionalBranchMachines: valueRedeemCustomerSalesOrderDetailTemp.optionalBranchMachines || [],
      })
    }
    return aryTemp;
  }

  const onClickSelect =  (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    let localCustomerDropdownTemp : any = [{
      id: row.id,
      preferredName: row.preferredName,
      customerNo: row.customerNo,
      mobileNo: row.mobileNo,
    }];
    setLocalCustomerDropdownList(localCustomerDropdownTemp);
    setDisplayCustomerDropdown(false);
    
    setTimeout(()=> {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }
  
  const prefillCustomerInfo = (id: string) => {
    let indexCustomer = _.findIndex(localCustomerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: localCustomerDropdownList[indexCustomer].id,
        customerName: localCustomerDropdownList[indexCustomer].preferredName,
        customerNo: localCustomerDropdownList[indexCustomer].customerNo,
        customerMobileNo: localCustomerDropdownList[indexCustomer].mobileNo,
      }))
    }
  }
  
  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "CustomerPurchase"})})}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
            <div className="h4">{intl.formatMessage({id: "CustomerPurchase"})}</div>
            </Col>
          </Row>
          <BreadCrumb activeBreadCrumbTitle={intl.formatMessage({id: "CustomerPurchase"})}/>
          {/* {
            ((errorMessage || successMessage) && !loading && !customerPurchasePackageDetailModal && !customerPurchaseDetailModal)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          <Row>
            <Col xl={'4'} md={'7'} xs={'7'}>
              {
                !displayCustomerDropdown 
                ?
                <Loading/>
                :
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setErrorMessage("");
                    setSuccessMessage("");
                    setLocalCustomerDropdownList(customerDropdownList);
                    clearCustomerDropdownList();
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomerDropdown(false);
                  }}
                  loadingCustomSearch={loadingCustomerDropdown}
                  setLoadingCustomSearch={setLoadingCustomerDropdown}
                />
              }
            </Col>
            <Col xl={'4'} md={'5'} xs={'5'}>
              <MyButton
                type="button"
                class={`btn btn-primary margin-top-28`}
                content={""}
                subContent={returnAdvancedSearchIcon()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => { setCustomerSearchModal(true) }}
              />
            </Col>
          </Row>
          {
            localLoading && !customerPurchasePackageDetailModal
              ?
              <Loading />
              :
              <Form
                id={Constants.redemptionOrderForm}
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                onBlur={() => {
                  dismissMessage();
                }}>
                <Row>
                  <Col xl={selectedCustomerId ? "7" : "12"}>
                    <PaginationTable
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      totalItem={totalItem}
                      tableColumns={CustomerPurchaseColumns}
                      data={customerPurchaseList}
                      emptyTextDisplay={selectedCustomerId ? `${intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerPurchase" }) })}` : `${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })}`}
                      objSorted={defaultSorted}
                      isModal={customerPurchasePackageDetailModal}
                      customerId={selectedCustomerId}
                      requestAPI={getCustomerSalesOrderRedeem}
                      expandRow={expandRow} />
                  </Col>
                  {
                    selectedCustomerId
                    &&
                    blnPermissionCreateCustomerDeliverOrder
                    &&
                    <Col xl="5">
                      {
                        Constants.innerWidthCompare > windowSize.innerWidth
                        ?
                        returnSplitBackgroundRow()
                        :
                        null
                      }
                      <div className="standard-layout">
                        <CustomerPurchaseCart
                          validation={validation}
                          disabledCustomerOrder={disabledFieldInput}
                          selectCustomerPurchase={selectCustomerPurchase}
                          deleteCustomerPurchase={deleteCustomerPurchase}
                          blnDisplayNote={true}
                        />
                        <Col>
                          {!Boolean(successMessage) &&
                            (
                              <MyButton
                                type="submit"
                                class="btn btn-success"
                                style={{width: '100%'}}
                                content={intl.formatMessage({id: "Redeem"})}
                                disable={
                                  validation.isSubmitting
                                }
                                loading={
                                  validation.isSubmitting
                                }
                              />
                            )}
                        </Col>
                      </div>
                    </Col>
                  }
                </Row>
              </Form>
          }
          {
            customerSearchModal
            &&
            <CustomerSearchModal
              blnShow={customerSearchModal}
              setModal={setCustomerSearchModal}
              title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
              onSelectFunction={onClickSelect} />
          }
          {
            customerPurchasePackageDetailModal &&
            <CustomerPurchasePackageDetail
              blnShow={customerPurchasePackageDetailModal}
              setModal={setCustomerPurchasePackageDetailModal}
              reloadData={fetchCustomerPurchaseAPI}
              selectedCustomer={selectedCustomer}
              selectedCustomerId={selectedCustomerId}
              selectedRedeemPurchaseDetails={selectedRedeemPurchaseDetails}
              selectedPackageDetail={selectedPackageDetail}
              setSelectedPurchaseDetail={setSelectedPackageDetail}
              productRedemptionEntitlement={productRedemptionEntitlement}
              employeeList={employeeDropdownList}
              customerPurchaseList={customerPurchaseList}
              validation={validation}
            />
          }
          {
            customerPurchaseDetailModal &&
            <CustomerPurchaseDetailModal
              blnShow={customerPurchaseDetailModal}
              setModal={setCustomerPurchaseDetailModal}
              selectedCustomerPurchaseDetail={selectedCustomerPurchaseDetail}
              setSelectedCustomerPurchaseDetail={setSelectedCustomerPurchaseDetail}
              productRedemptionEntitlement={productRedemptionEntitlement}
              employeeList={employeeDropdownList}
              customerPurchaseList={customerPurchaseList}
              blnPromptInfoMessage={true}
              validation={validation}/>
          }
          {
            customerPurchaseConfirmationModal &&
            <CustomerPurchaseConfimationModal
              blnShow={customerPurchaseConfirmationModal}
              setModal={setCustomerPurchaseConfirmationModal}
              validation={validation}
              blnBlockRedemptionSubmit={blnBlockRedemptionSubmit}
              blnPermissionBackdateCustomerDeliverOrder={blnPermissionBackdateCustomerDeliverOrder} />
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(CustomerPurchase);
