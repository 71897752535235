import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, IncludesLocationPathName } from "../../../app/common/function/function";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody } from "reactstrap";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../../app/components/layout/DetailViewLayout";
import TableActionButton from "../../../app/components/table/TableActionButton";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import BookCancelOutlineIcon from 'mdi-react/BookCancelOutlineIcon';
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import { RedemptionCustomerProduct } from "../../../app/models/appolousCustomerProduct";
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";
import { useLocation } from "react-router-dom";

const AppolousCustomerProductDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { appolousCustomerProductStore, commonStore } = useStore();
  const { loading, setLoading } = commonStore;
  const { appolousCustomerProductDetail, getAppolousCustomerProductDetail, setAppolousCustomerProductDetail, voidAppolousCustomerProductRedemption } = appolousCustomerProductStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "AppolousCustomerProduct" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: appolousCustomerProductDetail?.customerName || "", urlPath: `${RoutesList.customer}/view/${appolousCustomerProductDetail?.customerId}`}];
  const [loadingVoidAppolousCustomerProduct, setLoadingVoidAppolousCustomerProduct] = useState(false);
  const [voidAppolousCustomerProductModal, setVoidAppolousCustomerProductModal] = useState(false);
  const [selectedVoidAppolousCustomerProduct, setSelectedVoidAppolousCustomerProduct] = useState<RedemptionCustomerProduct | undefined>(undefined);
  const location = useLocation();

  const appolousCustomerProductFullDetail = appolousCustomerProductDetail || {
    id: "",
    productName: "",
    quantity: 0,
    redeemedQuantity: 0,
    balanceQuantity: 0,
    price: 0,
    redemptions: [
      {
        appolousCustomerProductRedemptionId: "",
        quantity: 0,
        remark: "",
        consultantName: "",
        createdAt: "",
        signatureImage: "",
        isVoidAllowed: false,
      }
    ]
  }

  const AppolousCustomerProductColumns = [
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>  
      <>
        <div className="">
          {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.createdAt).format(Constants.displayTimeFormat)}
        </div>
      </>
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase()
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "PerformBy" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.consultantName || "-"}</div>,
    },
    {
      dataField: "alternativeProductName",
      text: intl.formatMessage({ id: "AlternativeProductName" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.alternativeProductName || "-"}</div>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.isVoidAllowed
              ?
              <TableActionButton 
                type="cancel"
                onClick={()=> {
                  setSelectedVoidAppolousCustomerProduct(row);
                  setVoidAppolousCustomerProductModal(true);
                }}
                iconReplaceUI={<BookCancelOutlineIcon size={18} id="voidAppolousCustomerProductTooltip" />}
                iconId="voidAppolousCustomerProductTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "Void" })}`}
                target={Constants.blank}/>
              :
              "-"
            }
          </div>
        </>
      ),
    }
  ];

  async function fetchAppolousCustomerProductDetailAPI() {
    setLoading(true);
    let isManualTemp = false;
    if (id && !addAction) {
      let aryAPI: any = [
        getAppolousCustomerProductDetail(id),
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  async function onVoidAppolousCustomerProductAPI() {
    setLoadingVoidAppolousCustomerProduct(true);
    let resultVoidAppolousCustomerProduct = await voidAppolousCustomerProductRedemption(selectedVoidAppolousCustomerProduct?.appolousCustomerProductRedemptionId || "");
    if (resultVoidAppolousCustomerProduct.status === Constants.success) {
      setLoadingVoidAppolousCustomerProduct(false);
      setVoidAppolousCustomerProductModal(false);
      fetchAppolousCustomerProductDetailAPI();
      return;
    }
    
    setLoadingVoidAppolousCustomerProduct(false);
    setVoidAppolousCustomerProductModal(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageAppolousRedemption], true)) {
      return;
    }

    fetchAppolousCustomerProductDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setAppolousCustomerProductDetail(undefined);
      }
    })
  }, []);

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "AppolousCustomerProduct" })}
                      h4Title />   
                    <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold text-muted">
                      {`${appolousCustomerProductFullDetail.productName}`}
                      <span style={{fontWeight: 'normal'}}>
                        {` (${appolousCustomerProductFullDetail.redeemedQuantity}/${appolousCustomerProductFullDetail.quantity})`}
                      </span>
                    </h3>
                    {/* <Borderline/> */}
                    <div className="margin-top-32">
                      <PaginationTableWithoutApi 
                        title={intl.formatMessage({ id: "Redemption" })}
                        headerClassName={"table-light"}
                        options={appolousCustomerProductFullDetail.redemptions}
                        columns={AppolousCustomerProductColumns}
                        pageSize={Constants.maxPageSize}
                        keyField={"appolousCustomerProductRedemptionId"}/>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
      {
        voidAppolousCustomerProductModal
        &&
        <ActionPromptModal 
          title={`${intl.formatMessage({ id: "VoidAppolousCustomerProductAlert" })}`}
          subTitle={`${moment(selectedVoidAppolousCustomerProduct?.createdAt).format(Constants.displayDateAndTimeFormat)} ?`}
          primaryActionTitle={`Yes`}
          showPrompt={voidAppolousCustomerProductModal}
          setShowPrompt={setVoidAppolousCustomerProductModal}
          onPrimaryClick={()=> {
            onVoidAppolousCustomerProductAPI()
          }}
          onCancelClick={()=> {
            setSelectedVoidAppolousCustomerProduct(undefined);
          }}
          localLoading={loadingVoidAppolousCustomerProduct}
        />
      }
    </div>
  );
};

export default observer(AppolousCustomerProductDetail);
