import { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { contructValidationErrorMessage, getBrandId, getBrandName, IncludesLocationPathName, returnPriceWithCurrency, returnSuccessMarkedIcon } from "../../app/common/function/function";
import { Col, Form, Row, Table } from 'reactstrap';
import _ from "lodash";
import { useStore } from '../../app/stores/store';
import { Constants } from '../../app/constants/Constants';
import Loading from '../../app/components/loading/Loading';
import LoadingModal from '../../app/components/modal/LoadingModal';
import MyButton from "../../app/components/form/MyButton";
import ActionPromptModal from '../../app/components/modal/ActionPromptModal';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import { RefundNoteWebFormDetailObject } from '../../app/models/refundNote';
import MetaTags from "react-meta-tags";
import SignaturePad from '../../app/components/form/SignaturePad';
import ActionPromptModalWithRemarkModal from '../../app/components/modal/ActionPromptModalWithRemarkModal';
import GeneralTextArea from '../../app/components/form/GeneralTextArea';
import { RoutesList } from '../../app/constants/RoutesList';
import { history } from '../../'
import moment from 'moment';
import parse from 'html-react-parser';

const RefundNoteWeb = () => {
  //Use Paramsf
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  const { commonStore, refundNoteStore, tncStore } = useStore();
  const { errorMessage, successMessageWithoutNavigation, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { getRefundNoteWebFormDetail, getRefundNoteWithId, signRefundNoteWebForm, cancelRefundNoteWebForm } = refundNoteStore;
  const { tncText, getRefundNoteWebFormTnc } = tncStore;
  const viewAction = IncludesLocationPathName("view");
  const [localRefundNoteWebFormDetail, setLocalRefundNoteWebFormDetail] = useState<{status: string, data: RefundNoteWebFormDetailObject | undefined, message: string}>({status: "", data: undefined, message: ""});
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [viewModeAfterSubmit, setViewModeAfterSubmit] = useState(false);
  const [prompSuccessModal, setPrompSuccessModal] = useState(false);
  const [showCancelRefundNotePrompt, setShowCancelRefundNotePrompt] = useState(false);
  const [refundNoteWorkflowAction, setRefundNoteWorkflowAction] = useState('');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      webFormHistoryId: id,
      workflowAction: "",
      signatureImage: "",
      remark: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      try {
        let valuesTemp = _.cloneDeep(values);
        const resultUpdateRefundNoteWebForm = valuesTemp.workflowAction === Constants.sign ? await signRefundNoteWebForm(valuesTemp) : await cancelRefundNoteWebForm(valuesTemp);
        if (resultUpdateRefundNoteWebForm?.status === Constants.success) {
          setViewModeAfterSubmit(true);
          setRefundNoteWorkflowAction(valuesTemp.workflowAction);
          setPrompSuccessModal(true);
          setShowCancelRefundNotePrompt(false);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessageWithoutNavigation) || viewModeAfterSubmit;
  const blnDisplayRemark = viewModeAfterSubmit && validation.values.remark;
  const labelStyle = {fontSize: "14px"}
  const borderStyle = { border: "1px solid #bfc1c5" }

  useEffect(() => {
    async function fetchRefundNoteDetailAPI() {
      setLoading(true);
      if (!viewAction) {
        let resultRefundNoteWebFormDetail = await getRefundNoteWebFormDetail(id);
        if (resultRefundNoteWebFormDetail) {
          setLocalRefundNoteWebFormDetail(resultRefundNoteWebFormDetail);
          await getRefundNoteWebFormTnc({brandId: resultRefundNoteWebFormDetail.data?.brandId! , date: moment().format(Constants.displayDateFormat)})
        }
      }
      else {
        let resultRefundNoteDetail = await getRefundNoteWithId(id);
        if (resultRefundNoteDetail) {
          await getRefundNoteWebFormTnc({brandId: getBrandId(), date: moment(resultRefundNoteDetail.preparedOn).format(Constants.displayDateFormat)});
          if (resultRefundNoteDetail.signatureImage) {
            setViewModeAfterSubmit(true);
            setLocalRefundNoteWebFormDetail({
              status: Constants.success, 
              data: {
                brandId: getBrandId(),
                brandName: getBrandName(),
                customerName: resultRefundNoteDetail.customerName,
                customerIdentityNo: resultRefundNoteDetail.customerIdentityNo,
                customerMobileNo: resultRefundNoteDetail.customerMobileNo,
                refundToCustomerAmount: resultRefundNoteDetail.refundToCustomer,
                processingFee: resultRefundNoteDetail.processingFee,
                customerEntitledAmount: resultRefundNoteDetail.customerEntitledAmount,
                reason: resultRefundNoteDetail.reason,
                accountHolderName: resultRefundNoteDetail.accountHolderName,
                bankAccountType: resultRefundNoteDetail.bankAccountType,
                bankAccountNo: resultRefundNoteDetail.bankAccountNo,
                isRefundToOtherParty: resultRefundNoteDetail.isRefundToOtherParty,
                recipientName: resultRefundNoteDetail.recipientName,
                recipientRelationship: resultRefundNoteDetail.recipientRelationship
              }, 
              message: ""
            });
            validation.setFieldValue("signatureImage",resultRefundNoteDetail.signatureImage)
          }
          else {
            history.push(`/${RoutesList.notFound}`);
          }
        }
        else {
          history.push(`/${RoutesList.notFound}`);
        }
      }
      setLoading(false);
    }

    fetchRefundNoteDetailAPI();
  }, [])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
        setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const returnContentLabel = (value, blnCurrency) => {
    return <span style={{fontWeight: 600, marginLeft: "8px", fontSize: "14px"}}>{blnCurrency ? returnPriceWithCurrency(value) : value}</span>
  }

  const displayTableRowContent = (field, value) => {
    return (
      <tr>
        <td style={{...borderStyle, width: "50%", padding: '8px'}}>
          {field}
        </td>
        <td style={{...borderStyle, width: "50%", padding: '8px'}}>
          {value}
        </td>
      </tr>
    )
  }

  return (
    <div className={"padding-1rem"}>
      <MetaTags>
        <title>{intl.formatMessage({id: "RefundConfirmation"})}</title>
      </MetaTags>
      {
        loading 
        ? 
        viewAction
        ?       
        <div style={{marginTop: "68px"}}>  
          <Loading/>
        </div>
        :
        <div/>
        : 
        (
          <>
            {
              localRefundNoteWebFormDetail.status === Constants.failed
                ?
                <div className="my-5 pt-5">
                  <Row>
                    <Col lg="12">
                      <div className="text-center mb-5">
                        <AlertCircleOutlineIcon size={"8em"} color={"orange"} className={"margin-bottom-8"} />
                        <h4 className="text-uppercase">{intl.formatMessage({id: localRefundNoteWebFormDetail.message || ""})}</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                :
                <Form
                  className=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  onBlur={() => {
                    if (errorMessage || successMessageWithoutNavigation) {
                      setErrorMessage("");
                      setSuccessMessage("");
                    }
                  }}
                >
                  <Row form={+true} >
                    {" "}
                    {/* +true = Convert boolean to numbers*/}
                    <Col xl={12}>
                      <div className="flex-justfity-content-center" style={{marginTop: !viewAction ? "62px" : "134px"}}>
                        <div style={{backgroundColor: Constants.white, borderRadius: "8px"}}>
                          <div className="text-center" style={{backgroundColor: Constants.tabBlue, padding: "20px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}>
                            <h4 style={{color: Constants.white}} className='mb-0'>{intl.formatMessage({id: "RefundConfirmation"})}</h4>
                          </div>
                          <div style={{padding: "20px"}}>
                            <h5>{`${intl.formatMessage({id: "Dear"})} ${localRefundNoteWebFormDetail.data?.customerName},`}</h5>
                            <p className="mt-4 mb-0" style={labelStyle}>{`${intl.formatMessage({id: "RefundNoteWebMessage"})}`}</p>
                            <p className="mt-2 mb-0" style={labelStyle}><span className="margin-right-32">●</span> {`${intl.formatMessage({id: "FinalAmount"})}:`} {returnContentLabel(localRefundNoteWebFormDetail.data?.customerEntitledAmount, true)}</p>
                            <p className="mb-0" style={labelStyle}><span className="margin-right-32">●</span> {`${intl.formatMessage({id: "Reason"})}:`} {returnContentLabel(localRefundNoteWebFormDetail.data?.reason, false)}</p>
                            <p className="mb-0" style={labelStyle}><span className="margin-right-32">●</span> {`${intl.formatMessage({id: "RefundDetails"})}:`}</p>
                            <Table className="table margin-left-40 mt-2" style={{width: "80%", ...borderStyle, borderRadius: "12px"}}>
                              <tbody>
                                {displayTableRowContent(intl.formatMessage({ id: "RefundToOtherParty" }), returnContentLabel(localRefundNoteWebFormDetail.data?.isRefundToOtherParty ? Constants.nikeSymbol : Constants.xSymbol, false))}
                                {displayTableRowContent(intl.formatMessage({ id: "Name" }), returnContentLabel(localRefundNoteWebFormDetail.data?.isRefundToOtherParty ? localRefundNoteWebFormDetail.data?.recipientName : localRefundNoteWebFormDetail.data?.customerName, false))}
                                {displayTableRowContent(intl.formatMessage({ id: "IdentityNo" }), returnContentLabel(localRefundNoteWebFormDetail.data?.customerIdentityNo, false))}
                                {displayTableRowContent(intl.formatMessage({ id: "MobileNo" }), returnContentLabel(localRefundNoteWebFormDetail.data?.customerMobileNo, false))}
                                {localRefundNoteWebFormDetail.data?.isRefundToOtherParty && displayTableRowContent(intl.formatMessage({ id: "RecipientRelationship" }), returnContentLabel(localRefundNoteWebFormDetail.data?.recipientRelationship, false))}
                              </tbody>
                            </Table>
                            <p className="mb-0" style={labelStyle}><span className="margin-right-32">●</span> {`${intl.formatMessage({id: "AccountDetails"})}:`}</p>
                            <Table className="table table-bordered margin-left-40 mt-2" style={{width: "80%"}}>
                              <tbody>
                                {displayTableRowContent(intl.formatMessage({ id: "AccountHolderName" }), returnContentLabel(localRefundNoteWebFormDetail.data?.accountHolderName, false))}
                                {displayTableRowContent(intl.formatMessage({ id: "BankAccountType" }), returnContentLabel(localRefundNoteWebFormDetail.data?.bankAccountType, false))}
                                {displayTableRowContent(intl.formatMessage({ id: "BankAccountNo" }), returnContentLabel(localRefundNoteWebFormDetail.data?.bankAccountNo, false))}
                              </tbody>
                            </Table>
                            <h5 className="mt-4">{tncText?.description ? intl.formatMessage({id: "Declaration"}).toUpperCase() : ""}</h5>
                            <div className="margin-top-16" style={{ fontSize: '12px', maxWidth: '800px'}}>{tncText?.description ? (parse(tncText.description)) : ""}</div>
                            {
                              !blnDisplayRemark
                              ?
                              <>
                                <p className="mt-4" style={labelStyle}><span style={{color: 'red'}} className="margin-bottom-0">{`*`}</span>{`${intl.formatMessage({id: "CustomerSignature"})}`}</p>
                                <SignaturePad
                                  title={""}
                                  blnHideTitle={true}
                                  width={220}
                                  height={100}
                                  successMessage={successMessageWithoutNavigation}
                                  validation={validation}
                                  disabled={disabledFieldInput}
                                  isSubmitting={disabledFieldInput} />
                              </>
                              :
                              <GeneralTextArea
                                title={`${intl.formatMessage({id: "Remark"})}`}
                                name="remark"
                                className="mt-4"
                                row={3}
                                disabled={disabledFieldInput}
                                validation={validation}/>
                            }
                            <h5 className="mb-1 margin-top-32" style={{fontSize: "14px"}}>{`${intl.formatMessage({id: "BestRegards"})}`}</h5>
                            <h5>{localRefundNoteWebFormDetail.data?.brandName}</h5>
                            {
                              !viewModeAfterSubmit 
                              &&
                              <div className="mt-4 mb-2">
                                <MyButton
                                  type="button"
                                  class="btn btn-success"
                                  style={{width: "120px"}}
                                  disable={!validation.values.signatureImage || disabledFieldInput}
                                  loading={validation.isSubmitting}
                                  content={intl.formatMessage({ id: "Sign" }).toUpperCase()}
                                  onClick={async (remark) => {
                                    validation.setFieldValue("workflowAction", Constants.sign);
                                    validation.setFieldValue("remark", "");
                                    validation.submitForm();
                                  }} />
                                <MyButton
                                  type="button"
                                  class="btn btn-warning margin-left-8"
                                  style={{width: "120px"}}
                                  disable={disabledFieldInput}
                                  loading={validation.isSubmitting}
                                  content={intl.formatMessage({ id: "Cancel" }).toUpperCase()}
                                  onClick={()=> {
                                    setShowCancelRefundNotePrompt(true);
                                  }} />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
            }
          </>
        )
      }
      {
        prompSuccessModal
        &&
        <ActionPromptModal
          title={ refundNoteWorkflowAction === Constants.sign ? `${intl.formatMessage({ id: "RefundNoteSubmittedSuccess" })}` : `${intl.formatMessage({ id: "RefundNoteCancelledSuccess" })}`}
          replaceIcon={returnSuccessMarkedIcon}
          showPrompt={prompSuccessModal}
          setShowPrompt={setPrompSuccessModal}
          blnHideCancelButton={true}
          onPrimaryClick={() => { }}
        />
      }
      {
        showCancelRefundNotePrompt
        &&
        <ActionPromptModalWithRemarkModal 
          title={`${intl.formatMessage({ id: "CancelRefundNote" })}`}
          showPrompt={showCancelRefundNotePrompt}
          setShowPrompt={setShowCancelRefundNotePrompt}
          localLoading={validation.isSubmitting}
          remarkRequired={true}
          onActionClick={async (remark) => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.setFieldValue("remark", remark);
            validation.submitForm();
          }}/>
      }
      {
        loading
        &&
        !viewAction
        &&
        <LoadingModal/>
      }
    </div >
  )
}

export default observer(RefundNoteWeb);