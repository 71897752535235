import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, salesOrderStatusColor, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, getBranchId, firstDayOfMonthToCurrentDay, returnSubBrandList, purchasedItemStatusColor, getBranchName, arraySpliceInAllValue } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from 'react-router-dom';
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useLocation } from 'react-router-dom';

const UnearnedSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setErrorMessage } = commonStore;
  const { unearnedSalesDetailsList, getUnearnedSalesDetailsList, setUnearnedSalesDetailsList, exportUnearnedSalesDetails } = reportAccountStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { clType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [selectedCLType, setSelectedCLType] = useState<number | string>("All");
  const [selectedCLTypeId, setSelectedCLTypeId] = useState<any>(0);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);
  const paramsSearch = useLocation().search;

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCreditRedemptionNote = checkPermission([PermissionConstants.ManageCreditRedemptionNote]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  useEffect(() => {
    async function fetchDynamicSalesListAPI() {     
      setLoading(true); 
      setLoadingDropdownRefresh(true);
      
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.clType)
      ];
      let resultAPI = await Promise.all(aryAPI);  
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      const purchaseBranchIdParams = new URLSearchParams(paramsSearch).get('purchaseBranchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const clTypeParams = new URLSearchParams(paramsSearch).get('clType');
     
      if (purchaseBranchIdParams && fromDateParams && toDateParams && clTypeParams) {
        let indexBranch = _.findIndex(resultAPI[0], { id: purchaseBranchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchName(resultAPI[0][indexBranch].name);
          setSelectedBranchId(resultAPI[0][indexBranch].id);
        }

        let indexCLType = _.findIndex(resultAPI[1], { key: Number(clTypeParams) });
        if (indexCLType > -1) {
          setSelectedCLType(resultAPI[1][indexCLType].displayValue);
          setSelectedCLTypeId(resultAPI[1][indexCLType].key);
        }

        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        // history.replace({ pathname: `/${RoutesList.report}/${RoutesList.unearnedSalesDetails}` });
        await getUnearnedSalesDetailsList({
          pageNumber: 1,
          pageSize: pageSize,
          dateRange: { fromDate: fromDateTemp, toDate: toDateTemp },
          purchaseBranchId: purchaseBranchIdParams,
          cLType: clTypeParams
        });
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setUnearnedSalesDetailsList([]);
    })
  }, []);

  async function fetchUnearnedSalesDetailsListApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getUnearnedSalesDetailsList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      purchaseBranchId: selectedBranchId,
      cLType: selectedCLTypeId
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportUnearnedSalesDetailsApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    setLocalLoading(true);
    await exportUnearnedSalesDetails({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      purchaseBranchId: selectedBranchId,
      cLType: selectedCLTypeId
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const tableFixedWidth = 150;
  const returnHeaderStyle = { width: `${tableFixedWidth}px`, minWidth: `${tableFixedWidth}px`, maxWidth: `${tableFixedWidth}px` };

  //Table Content UI
  const UnearnedSalesDetailsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
            ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div className="margin-bottom-0">{row.customerSalesOrderNo || Constants.emptyData}</div>
          }
        </>
      )
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.submissionDate)
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor} />
      ),
    },
    {
      dataField: "signDate",
      text: intl.formatMessage({ id: "SignDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.signDate)
      ),
    },
    {
      dataField: "businessEntityName",
      text: intl.formatMessage({ id: "CompanyName" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "purchaseBranchName",
      text: intl.formatMessage({ id: "PurchaseBranch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "invoiceAmount",
      text: intl.formatMessage({ id: "InvoiceAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.invoiceAmount)}</div>
      ),
    },
    {
      dataField: "totalNonCreditPayment",
      text: intl.formatMessage({ id: "NonCreditPayment" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalNonCreditPayment)}</div>
      ),
    },
    {
      dataField: "totalCreditPayment",
      text: intl.formatMessage({ id: "CreditPayment" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalCreditPayment)}</div>
      ),
    },
    {
      dataField: "totalRefundAmount",
      text: intl.formatMessage({ id: "RefundToCN" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalRefundAmount)}</div>
      ),
    },
    {
      dataField: "purchaseType",
      text: intl.formatMessage({ id: "PurchaseType" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.purchaseType)}
        </>
      ),
    },
    {
      dataField: "itemName",
      text: intl.formatMessage({ id: "ItemName" }).toUpperCase(),
      headerStyle: { width: "250px", minWidth: "250px", maxWidth: "250px" },
    },
    {
      dataField: "detailStatus",
      text: intl.formatMessage({ id: "ItemStatus" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.detailStatus}
          statusColorFunction={purchasedItemStatusColor} />
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },    
      formatter: (cellContent, row) => (
        <div style={{ textAlign: "center" }}>
          {row.quantity}
        </div>
      ),
    },
    {
      dataField: "originalPrice",
      text: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.originalPrice)}</div>
      ),
    },
    {
      dataField: "unitPrice",
      text: intl.formatMessage({ id: "UnitPrice" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.unitPrice)}</div>
      ),
    },
    {
      dataField: "discountAmount",
      text: intl.formatMessage({ id: "DiscountAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.discountAmount)}</div>
      ),
    },
    {
      dataField: "totalPrice",
      text: intl.formatMessage({ id: "TotalPrice" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPrice)}</div>
      ),
    },
    {
      dataField: "allocatedAmount",
      text: intl.formatMessage({ id: "AllocatedAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.allocatedAmount)}</div>
      ),
    },
    {
      dataField: "unearnedRevenue",
      text: intl.formatMessage({ id: "UnearnedRevenue" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.unearnedRevenue)}</div>
      ),
    },    
    {
      dataField: "expiredDate",
      text: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.expiredDate)
      ),
    }
  ];

  const returnDisplayDate = (valueDate) => {
    return (
      valueDate === Constants.invalidDate
      ?
      Constants.emptyDataV2
      :
      <>
        <div className="">
          {`${moment(valueDate).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(valueDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "UnearnedSalesDetails" }) })}
        title={intl.formatMessage({ id: "UnearnedSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportUnearnedSalesDetailsApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Unearned-Sales-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  className={""}
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Type" })}
                  name="clType"
                  options={clType}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedCLType}
                  initialValue={selectedCLTypeId}
                  setFieldLabel={setSelectedCLType}
                  setFieldValue={setSelectedCLTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            </Row>
            <DoubleColumnRowParent>
              <MyButton
                type="button"
                class={`btn btn-primary margin-bottom-18`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchUnearnedSalesDetailsListApi(true)
                }}
              />
            </DoubleColumnRowParent>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={UnearnedSalesDetailsColumns}
              keyField={"randomId"}
              data={unearnedSalesDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "UnearnedSalesDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getUnearnedSalesDetailsList}
              bordered={true}
              purchaseBranchId={selectedBranchId}
              dateRange={selectedDateRange}
              cLType={selectedCLTypeId} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(UnearnedSalesDetails);
