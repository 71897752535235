import { PaginationRequestBody } from "../models/pagination";
import { StockReceiveAddObject, StockReceiveCompleteObject, StockReceiveDetailObject, StockReceiveListObject, StockReceiveUpdateObject } from "../models/stockReceive";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
export default class StockReceiveStore {
  stockReceiveList: StockReceiveListObject[] = [];
  stockReceiveDetail: StockReceiveDetailObject | undefined = undefined;
  stockReceivePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.stockReceiveList = [];
    this.stockReceiveDetail = undefined;
    this.stockReceivePaginationParams = undefined;
  }

  setStockReceiveDetail = (stockReceiveDetail: StockReceiveDetailObject | undefined) => {
    this.stockReceiveDetail = stockReceiveDetail;
  }

  setStockReceivePaginationParams = (stockReceivePaginationParams: PaginationRequestBody | undefined) => {
    this.stockReceivePaginationParams = stockReceivePaginationParams;
  }

  getStockReceive = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setStockReceivePaginationParams(PaginationRequestBody);
    try {
      const resultStockReceives = await agent.StockReceive.stockReceiveList(PaginationRequestBody);
      runInAction(() => {
        this.stockReceiveList = resultStockReceives.data;
        store.commonStore.setTotalItem(resultStockReceives.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockReceiveList = [];
    }
  };

  getStockReceiveWithId = async (id: string) => {
    try{
      const resultStockReceiveDetail = await agent.StockReceive.stockReceiveDetail(id);
      if (resultStockReceiveDetail.stockReceiveDetails) {
        resultStockReceiveDetail.stockReceiveDetails = resultStockReceiveDetail.stockReceiveDetails.map((valueStockReceiveDetail) => ({
          ...valueStockReceiveDetail,
          measurementName: `${valueStockReceiveDetail.measurementName}${valueStockReceiveDetail.measurementName !== Constants.unit ? ` (${valueStockReceiveDetail.productConvertionRate} Unit)` : ""}`,
        }))
      }
      runInAction(() => {
        this.stockReceiveDetail = resultStockReceiveDetail;
      });
      return Promise.resolve(resultStockReceiveDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stockReceiveDetail = undefined;
    }
  }

  addStockReceive = async (stockReceiveRequestBody: StockReceiveAddObject) => {
    try{
      await agent.StockReceive.addStockReceive(stockReceiveRequestBody);
      store.commonStore.setSuccessMessage(`StockReceiveAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockReceive = async (stockReceiveRequestBody: StockReceiveUpdateObject) => {
    try{
      await agent.StockReceive.updateStockReceive(stockReceiveRequestBody);
      store.commonStore.setSuccessMessage(`StockReceiveUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  completeStockReceive = async (stockReceiveCompleteRequestBody: StockReceiveCompleteObject) => {
    try{
      await agent.StockReceive.completeStockReceive(stockReceiveCompleteRequestBody);
      store.commonStore.setSuccessMessage(`StockReceiveCompleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteStockReceive  = async (id: string, name: string) => {
    try {
      await agent.StockReceive.deleteStockReceive(id);
      store.commonStore.setSuccessMessage(`StockReceiveDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateStockReceiveStatus = async (id: string) => {
    try{
      await agent.StockReceive.updateStockReceiveStatus(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`StockReceiveUpdateSuccess`);
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
}
