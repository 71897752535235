import { PaginationRequestBody } from "../models/pagination";
import { CustomerSubBranchRequestListObject, CustomerSubBranchRequestAddObject, CustomerSubBranchRequestUpdateObject, CustomerSubBranchRequestDetailObject, CustomerSubBranchRequestReviewObject } from "../models/customerSubBranchRequest";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class customerSubBranchRequestStore {
  customerSubBranchRequestList: CustomerSubBranchRequestListObject[] = [];
  customerSubBranchRequestDetail: CustomerSubBranchRequestDetailObject | undefined = undefined;
  customerSubBranchRequestPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerSubBranchRequestList = [];
    this.customerSubBranchRequestDetail = undefined;
  }

  setCustomerSubBranchRequestDetail = (customerSubBranchRequestDetail: CustomerSubBranchRequestDetailObject | undefined) => {
    this.customerSubBranchRequestDetail = customerSubBranchRequestDetail;
  }

  setCustomerSubBranchRequestPaginationParams = (customerSubBranchRequestPaginationParams: PaginationRequestBody | undefined) => {
    this.customerSubBranchRequestPaginationParams = customerSubBranchRequestPaginationParams;
  }
  
  getCustomerSubBranchRequest = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setCustomerSubBranchRequestPaginationParams(PaginationRequestBody);
      const resultCustomerSubBranchRequest = await agent.CustomerSubBranchRequest.customerSubBranchRequestList(PaginationRequestBody);
      runInAction(() => {
        this.customerSubBranchRequestList = resultCustomerSubBranchRequest.data;
        store.commonStore.setTotalItem(resultCustomerSubBranchRequest.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSubBranchRequestList = [];
    }
  }

  getCustomerSubBranchRequestWithId = async (id: string) => {
    try{
      const resultCustomerSubBranchRequestDetail = await agent.CustomerSubBranchRequest.customerSubBranchRequestDetail(id);
      runInAction(() => {
        this.customerSubBranchRequestDetail = resultCustomerSubBranchRequestDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSubBranchRequestDetail = undefined;
    }
  }

  addCustomerSubBranchRequest = async (customerSubBranchRequestRequestBody: CustomerSubBranchRequestAddObject) => {
    try{
      let resultAPI = await agent.CustomerSubBranchRequest.addCustomerSubBranchRequest(customerSubBranchRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerSubBranchRequestAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAPI})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {id: ""}})
    }
  }

  updateCustomerSubBranchRequest = async (customerSubBranchRequestRequestBody: CustomerSubBranchRequestUpdateObject) => {
    try{
      let resultAPI = await agent.CustomerSubBranchRequest.updateCustomerSubBranchRequest(customerSubBranchRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerSubBranchRequestUpdateSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAPI})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {id: ""}})
    }
  }

  reviewCustomerSubBranchRequest = async (customerSubBranchRequestRequestBody: CustomerSubBranchRequestReviewObject) => {
    try{
      let resultAPI = await agent.CustomerSubBranchRequest.reviewCustomerSubBranchRequest(customerSubBranchRequestRequestBody);
      store.commonStore.setSuccessMessage(`CustomerSubBranchRequestReviewSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAPI})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {id: ""}})
    }
  }

  deleteCustomerSubBranchRequest  = async (id: string, name: string) => {
    try {
      let resultAPI = await agent.CustomerSubBranchRequest.deleteCustomerSubBranchRequest(id);
      store.commonStore.setSuccessMessage(`CustomerSubBranchRequestDeleteSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAPI})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {id: ""}})
    }
  }
}