import { RoutesList } from "./RoutesList";
import { PermissionConstants } from "./PermissionConstants";

export const MenuConstants = {
  Dashboard: "Dashboard",
  Appointment: "Appointment",
  Calendar: "Calendar",
  ListView: "List View",
  Sales: "Sales",
  POS: "P.O.S",
  Invoice: "Invoice",
  SalesTarget: "Sales Target",
  Customer: "Customer",
  CustomerPurchase: "Purchased Items",
  CustomerRedemption: "Customer Redemption",
  Birthday: "Birthday",
  RequestTransfer: "Request Transfer",
  Inventory: "Inventory",
  Product: "Products",
  Category: "Category",
  SalesCategory: "Sales Category",
  Warehouse: "Warehouse",
  UnitOfMeasure: "Unit of Measure",
  StockMovements: "Stock Movements",
  StockRequest: "Stock Request",
  StockReceive: "Stock Receive",
  PurchaseOrder: "Purchase Order",
  InboundNotice: "Inbound Notice",
  GoodsReceiveNote: "Goods Receive Note",
  PurchaseDeliverOrder: "Purchase Deliver Order",
  Purchase: "Purchase",
  HumanResource: "Human Resource",
  Employees: "Employees",
  Permissions: "Permissions",
  Position: "Position",
  LeaveEntitlement: "Leave Entitlement",
  EPFContribution: "EPF Contribution",
  EIS: "EIS",
  PCB: "PCB",
  SOCSO: "SOCSO",
  PayrollItem: "Payroll Item",
  Business: "Business",
  Brand: "Brand",
  BusinessEntity: "Business Entity",
  Branch: "Branch",
  Country: "Country",
  Supplier: "Supplier",
  ServiceTreatments: "Service Treatments",
  Packages: "Packages",
  Tax: "Tax",
  Currency: "Currency",
  Race: "Race",
  MediaSource: "Media Source",
  UserRole: "User Role",
  PackageUpgrade: "Package Upgrade",
  NotificationTemplate: "Notification Template",
  StockAdjustment: "Stock Adjustment",
  StockIn: "Stock In",
  StockOut: "Stock Out",
  PaymentMethod: "Payment Method",
  Report: "Report",
  Settings: 'Settings',
  Payslip: "Payslip",
  AdditionalFormField: "Additional Form Field",
  Notification: "Notification",
  StockTake: "Stock Take",
  AuditLog: "Audit Log",
  Marcom: "Marcom",
  Lead: "Lead",
  CustomerSalesOrder: "Sales Order",
  AppointmentSettings: "Appointment Settings",
  GeneralSettings: "General Settings",
  SalesSettings: "Sales Settings",
  Promotion: "Promotion",
  ExchangeToCredit: "Exchange to Credit",
  CreditAdjustment: "Credit Adjustment",
  LeaveApplication: "Leave Application",
  ProductReturn: "Product Return",
  PaymentAdjustment: "Payment Adjustment",
  StockReturnToHQ: "Stock Return To HQ",
  ExtendPackage: "Extend Package",
  LeadDuplicate: "Duplicate Lead",
  PaymentMethodOption: "Payment Method Option",
  SalesAdjustment: "Sales Adjustment",
  ProductCommissionSettings: "Product Commission Settings",
  CustomerAccessRequest: "Access Request",
  CacheSettings: "Cache Settings",
  BundledProductCommissionSettings: "Bundled Product Commission Settings",
  CustomerTransferRequest: "Transfer Request",
  AdditionBonusSalesTarget: "Addition Bonus Sales Target",
  BranchCommission: "Branch Commission",
  VirtualWarehouse: "Virtual Warehouse",
  CustomerVirtualWarehouse: "Reserved Products",
  CustomerVirtualWarehouseCreditExchange: "Credit Exchange",
  ProductRedemptionNote: "Redemption Notes",
  ProductRedemptionNoteReturn: "Return Requests",
  Adjustment: 'Adjustment',
  CommissionSettings: "Commission Settings",
  Requests: "Requests",
  DSRAdjustment: "DSR Adjustment",
  BatchJob: "Batch Job",
  CustomerSubBranchRequest: "Sub Branch Request",
  ReservedProductMovements: "Reserved Product Movements",
  AppointmentTarget: "Appointment Target",
  MarcomDailyRecordHistory: "Marcom Daily Record History",
  Department: "Department",
  ChangePaymentMethod: "Change Payment Detail",
  FollowUp: "Follow Up",
  Machine: "Machine",
  MachineUsage: "Machine Usage",
  ContractLiabilityBenchmark: "Contract Liability Benchmark",
  GroupCode: "Group Code",
  PaymentControl: "Payment Control",
  UnknownPayment: "Unknown Payment",
  Payment: "Payment",
  Closing: "Closing",
  CashOut: "Cash Out",
  PaymentTerminal: "Payment Terminal",
  ChangePaymentMode: "Change Payment Mode",
  CloseCounter: "Close Counter",
  Test: "Test",
  Tnc: "T&C",
  MarcomReport: "Marcom Report",
  ProductCreditCommissionSettings: "Product Credit Commission Settings",
  CreditRedemption: "Credit Redemption",
  CreditRedemptionNoteRequest: "Credit Redemption Note Request",
  ReturnRedemption: "Return Redemption",
  CreditRedemptionNote: "Credit Redemption Note",
  CreditNote: "Credit Note",
  RefundNote: "Refund Note"
}

export const MenuList = [
  {
		menuName: MenuConstants.Dashboard,
    route: `/${RoutesList.dashboard}`,
    subContent: [],
    isActive: true
	},
  {
		menuName: MenuConstants.Appointment,
		subContent: [
      { 
        name: MenuConstants.Calendar, 
        route: `/${RoutesList.appointment}`,
        isActive: false,
        systemName: PermissionConstants.ManageAppointment
      },
      { 
        name: MenuConstants.ListView, 
        route: `/${RoutesList.appointmentList}`,
        isActive: false,
        systemName: PermissionConstants.ManageAppointment
      },
      { 
        name: MenuConstants.FollowUp, 
        route: `/${RoutesList.appointmentLead}`,
        isActive: false,
        systemName: PermissionConstants.ManageAppointmentLead
      }
    ],
    isActive: false
	},
  { 
    menuName: MenuConstants.Closing, 
    subContent: [
      { 
        name: MenuConstants.CashOut, 
        route: `/${RoutesList.cashOut}`,
        isActive: false,
        systemName: PermissionConstants.ManageCashOut
      },
      { 
        name: MenuConstants.CloseCounter, 
        route: `/${RoutesList.closeCounter}`,
        isActive: false,
        systemName: PermissionConstants.ManageCloseCounter
      },
    ],
    isActive: false
  },
  {
		menuName: MenuConstants.Sales,
		subContent: [
      { 
        name: MenuConstants.POS, 
        route: `/${RoutesList.pos}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerSalesOrder
      },
      { 
        name: MenuConstants.CustomerSalesOrder, 
        route: `/${RoutesList.customerSalesOrder}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerSalesOrder
      },
      { 
        name: MenuConstants.Invoice, 
        route: `/${RoutesList.invoice}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerInvoice
      },
      { 
        name: MenuConstants.CreditRedemption, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.CreditRedemption, 
            route: `/${RoutesList.creditRedemption}`,
            isActive: false,
            systemName: PermissionConstants.CreateCreditRedemptionNoteRequest
          },
          { 
            name: MenuConstants.CreditRedemptionNoteRequest, 
            route: `/${RoutesList.creditRedemptionNoteRequest}`,
            isActive: false,
            systemName: PermissionConstants.ManageCreditRedemptionNoteRequest
          },
          { 
            name: MenuConstants.CreditRedemptionNote, 
            route: `/${RoutesList.creditRedemptionNote}`,
            isActive: false,
            systemName: PermissionConstants.ManageCreditRedemptionNote
          }
        ]
      },
      { 
        name: MenuConstants.Adjustment, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.PaymentAdjustment, 
            route: `/${RoutesList.paymentAdjustment}`,
            isActive: false,
            systemName: PermissionConstants.ManagePaymentAdjustment
          },
          { 
            name: MenuConstants.ChangePaymentMethod, 
            route: `/${RoutesList.changePaymentMethod}`,
            isActive: false,
            systemName: PermissionConstants.ManageChangePaymentMethod
          },
          { 
            name: MenuConstants.ExchangeToCredit, 
            route: `/${RoutesList.exchangeToCredit}`,
            isActive: false,
            systemName: PermissionConstants.ManageExchangeToCredit
          },
          { 
            name: MenuConstants.SalesAdjustment, 
            route: `/${RoutesList.salesAdjustment}`,
            isActive: false,
            systemName: PermissionConstants.ManageSalesAdjustment
          },
          { 
            name: MenuConstants.CreditAdjustment, 
            route: `/${RoutesList.creditAdjustment}`,
            isActive: false,
            systemName: PermissionConstants.ManageCreditAdjustment
          },
          { 
            name: MenuConstants.DSRAdjustment, 
            route: `/${RoutesList.dsrAdjusment}`,
            isActive: false,
            systemName: PermissionConstants.ManageDSR
          },
          { 
            name: MenuConstants.ProductReturn, 
            route: `/${RoutesList.customerProductReturn}`,
            isActive: false,
            systemName: PermissionConstants.ManageCustomerProductReturn
          },
          { 
            name: MenuConstants.PackageUpgrade, 
            route: `/${RoutesList.packageUpgrade}`,
            isActive: false,
            systemName: PermissionConstants.UpdateCustomerSalesOrder
          },
          { 
            name: MenuConstants.ExtendPackage, 
            route: `/${RoutesList.extendPackage}`,
            isActive: false,
            systemName: PermissionConstants.ManageExtendPackage
          },
          { 
            name: MenuConstants.ReturnRedemption, 
            route: `/${RoutesList.returnRedemption}`,
            isActive: false,
            systemName: PermissionConstants.ManageExchangeToCredit
          },
          { 
            name: MenuConstants.CreditNote, 
            route: `/${RoutesList.creditNote}`,
            isActive: false,
            systemName: PermissionConstants.ManageCreditNote
          },
          { 
            name: MenuConstants.RefundNote, 
            route: `/${RoutesList.refundNote}`,
            isActive: false,
            systemName: PermissionConstants.ManageRefundNote
          }
        ]
      },
      { 
        name: MenuConstants.CommissionSettings, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.SalesTarget, 
            route: `/${RoutesList.salesTarget}`,
            isActive: false,
            systemName: PermissionConstants.ManageSalesTarget
          },
          { 
            name: MenuConstants.AdditionBonusSalesTarget, 
            route: `/${RoutesList.additionBonusSalesTarget}`,
            isActive: false,
            systemName: PermissionConstants.ManageAdditionBonusSalesTarget
          },
          { 
            name: MenuConstants.ProductCommissionSettings, 
            route: `/${RoutesList.productCommissionScheme}`,
            isActive: false,
            systemName: PermissionConstants.ManageCommissionScheme
          },
          { 
            name: MenuConstants.BundledProductCommissionSettings, 
            route: `/${RoutesList.bundledProductCommissionScheme}`,
            isActive: false,
            systemName: PermissionConstants.ManageCommissionScheme
          },
          { 
            name: MenuConstants.ProductCreditCommissionSettings, 
            route: `/${RoutesList.productCreditCommissionSettings}`,
            isActive: false,
            systemName: PermissionConstants.ManageProductCreditCommissionScheme
          }
        ]
      },
      { 
        name: MenuConstants.PaymentControl, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.UnknownPayment, 
            route: `/${RoutesList.unknownPayment}`,
            isActive: false,
            systemName: PermissionConstants.ManageUnknownCustomerPayment
          },
          { 
            name: MenuConstants.ChangePaymentMode, 
            route: `/${RoutesList.changePaymentMode}`,
            isActive: false,
            systemName: PermissionConstants.ManageChangePaymentMode
          },
        ]
      }
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Customer,
		subContent: [
      { 
        name: MenuConstants.Customer, 
        route: `/${RoutesList.customer}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomer
      },
      { 
        name: MenuConstants.CustomerPurchase, 
        route: `/${RoutesList.customerPurchase}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerSalesOrder
      },
      { 
        name: MenuConstants.CustomerRedemption, 
        route: `/${RoutesList.customerRedemption}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerDeliverOrder
      },
      { 
        name: MenuConstants.VirtualWarehouse, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.CustomerVirtualWarehouse, 
            route: `/${RoutesList.customerVirtualWarehouse}`,
            isActive: false,
            systemName: PermissionConstants.ManageCustomerVirtualWarehouse
          },
          { 
            name: MenuConstants.ProductRedemptionNote, 
            route: `/${RoutesList.productRedemptionNote}`,
            isActive: false,
            systemName: PermissionConstants.ManageProductRedemptionNote
          },
          { 
            name: MenuConstants.ProductRedemptionNoteReturn, 
            route: `/${RoutesList.productRedemptionNoteReturn}`,
            isActive: false,
            systemName: PermissionConstants.ManageProductRedemptionNoteReturn
          },
          { 
            name: MenuConstants.CustomerVirtualWarehouseCreditExchange, 
            route: `/${RoutesList.virtualWarehouseCreditExchange}`,
            isActive: false,
            systemName: PermissionConstants.ManageVirtualWarehouseCreditExchange
          }
        ]
      },
      { 
        name: MenuConstants.Requests, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.CustomerAccessRequest, 
            route: `/${RoutesList.customerAccessRequest}`,
            isActive: false,
            systemName: PermissionConstants.ManageCustomerAccessRequest
          },
          { 
            name: MenuConstants.CustomerTransferRequest, 
            route: `/${RoutesList.customerTransferRequest}`,
            isActive: false,
            systemName: PermissionConstants.ManageCustomerTransferRequest
          },
          { 
            name: MenuConstants.CustomerSubBranchRequest, 
            route: `/${RoutesList.customerSubBranchRequest}`,
            isActive: false,
            systemName: PermissionConstants.ManageCustomerSubBranchRequest
          }
        ]
      },
      { 
        name: MenuConstants.Birthday, 
        route: `/${RoutesList.dashboard}`,
        isActive: false,
        systemName: ""
      },
      { 
        name: MenuConstants.RequestTransfer, 
        route: `/${RoutesList.dashboard}`,
        isActive: false,
        systemName: ""
      }
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Inventory,
		subContent: [
      { 
        name: MenuConstants.Product, 
        route: `/${RoutesList.product}`,
        isActive: false,
        systemName: PermissionConstants.ManageProduct
      },
      { 
        name: MenuConstants.Category, 
        route: `/${RoutesList.category}`,
        isActive: false,
        systemName: PermissionConstants.ManageCategory
      },
      { 
        name: MenuConstants.StockMovements, 
        route: `/${RoutesList.stockMovement}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockMovement
      },
      { 
        name: MenuConstants.ReservedProductMovements, 
        route: `/${RoutesList.reservedProductMovements}`,
        isActive: false,
        systemName: PermissionConstants.ManageCustomerVirtualWarehouse
      },
      { 
        name: MenuConstants.StockTake, 
        route: `/${RoutesList.stockTake}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockTake
      },
      { 
        name: MenuConstants.Machine, 
        route: `/${RoutesList.machine}`,
        isActive: false,
        systemName: PermissionConstants.ManageMachine
      },{ 
        name: MenuConstants.MachineUsage, 
        route: `/${RoutesList.machineUsage}`,
        isActive: false,
        systemName: PermissionConstants.ManageMachineUsage
      }

    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Purchase,
		subContent: [
      { 
        name: MenuConstants.PurchaseOrder, 
        route: `/${RoutesList.purchaseOrder}`,
        isActive: false,
        systemName: PermissionConstants.ManagePurchaseOrder
      },
      { 
        name: MenuConstants.InboundNotice, 
        route: `/${RoutesList.inboundNotice}`,
        isActive: false,
        systemName: PermissionConstants.ManageInboundNotice
      },
      { 
        name: MenuConstants.GoodsReceiveNote, 
        route: `/${RoutesList.goodsReceiveNote}`,
        isActive: false,
        systemName: PermissionConstants.ManageGRN
      },
      { 
        name: MenuConstants.PurchaseDeliverOrder, 
        route: `/${RoutesList.purchaseDeliverOrder}`,
        isActive: false,
        systemName: PermissionConstants.ManagePurchaseDeliverOrder
      },
      { 
        name: MenuConstants.StockRequest, 
        route: `/${RoutesList.stockRequest}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockRequest
      },
      { 
        name: MenuConstants.StockReceive, 
        route: `/${RoutesList.stockReceive}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockReceive
      },
      {
        name: MenuConstants.StockIn, 
        route: `/${RoutesList.stockIn}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockIn
      },
      {
        name: MenuConstants.StockOut, 
        route: `/${RoutesList.stockOut}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockOut
      },
      { 
        name: MenuConstants.StockReturnToHQ, 
        route: `/${RoutesList.stockReturnToHQ}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockReturnToHQ
      },
      { 
        name: MenuConstants.StockAdjustment, 
        route: `/${RoutesList.stockAdjustment}`,
        isActive: false,
        systemName: PermissionConstants.ManageStockAdjustment
      }
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.HumanResource,
		subContent: [
      { 
        name: MenuConstants.Employees, 
        route: `/${RoutesList.employee}`,
        isActive: false,
        systemName: PermissionConstants.ManageEmployee
      },
      { 
        name: MenuConstants.Permissions, 
        route: `/${RoutesList.permission}`,
        isActive: false,
        systemName: PermissionConstants.ManagePermission
      },
      { 
        name: MenuConstants.Department, 
        route: `/${RoutesList.department}`,
        isActive: false,
        systemName: PermissionConstants.ManageDepartment
      },
      { 
        name: MenuConstants.Position, 
        route: `/${RoutesList.position}`,
        isActive: false,
        systemName: PermissionConstants.ManagePosition
      },
      { 
        name: MenuConstants.LeaveApplication, 
        route: `/${RoutesList.employeeLeave}`,
        isActive: false,
        systemName: PermissionConstants.ManageEmployeeLeave
      },
      // { 
      //   name: MenuConstants.LeaveEntitlement, 
      //   route: `/${RoutesList.leaveEntitlement}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManageLeaveEntitlement
      // },
      // { 
      //   name: MenuConstants.EPFContribution, 
      //   route: `/${RoutesList.epfcontribution}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManageEpfContribution
      // },
      // { 
      //   name: MenuConstants.EIS, 
      //   route: `/${RoutesList.eis}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManageEis
      // },
      // { 
      //   name: MenuConstants.PCB, 
      //   route: `/${RoutesList.pcb}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManagePcb
      // },
      // { 
      //   name: MenuConstants.SOCSO, 
      //   route: `/${RoutesList.socso}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManageSocso
      // },
      // { 
      //   name: MenuConstants.PayrollItem, 
      //   route: `/${RoutesList.payrollItem}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManagePayrollItem
      // },
      { 
        name: MenuConstants.UserRole, 
        route: `/${RoutesList.userRole}`,
        isActive: false,
        systemName: PermissionConstants.ManageUserRole
      },
      // { 
      //   name: MenuConstants.Payslip, 
      //   route: `/${RoutesList.payslip}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManagePayslip
      // }
      { 
        name: MenuConstants.BranchCommission, 
        route: `/${RoutesList.branchCommission}`,
        isActive: false,
        systemName: PermissionConstants.ManageBranchCommission
      },
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Business,
		subContent: [
      { 
        name: MenuConstants.Brand, 
        route: `/${RoutesList.brand}`,
        isActive: false,
        systemName: PermissionConstants.ManageBrand
      },
      { 
        name: MenuConstants.BusinessEntity, 
        route: `/${RoutesList.businessEntity}`,
        isActive: false,
        systemName: PermissionConstants.ManageBusinessEntity
      },
      { 
        name: MenuConstants.Branch, 
        route: `/${RoutesList.branch}`,
        isActive: false,
        systemName: PermissionConstants.ManageBranch
      },
      { 
        name: MenuConstants.Supplier, 
        route: `/${RoutesList.supplier}`,
        isActive: false,
        systemName: PermissionConstants.ManageSupplier
      },
      { 
        name: MenuConstants.Warehouse, 
        route: `/${RoutesList.warehouse}`,
        isActive: false,
        systemName: PermissionConstants.ManageWarehouse
      },
      { 
        name: MenuConstants.ServiceTreatments, 
        route: `/${RoutesList.serviceTreatment}`,
        isActive: false,
        systemName: PermissionConstants.ManageServiceTreatment
      },
      { 
        name: MenuConstants.Packages, 
        route: `/${RoutesList.package}`,
        isActive: false,
        systemName: PermissionConstants.ManagePackage
      },
      { 
        name: MenuConstants.Tax, 
        route: `/${RoutesList.tax}`,
        isActive: false,
        systemName: PermissionConstants.ManageTax
      },
      { 
        name: MenuConstants.Currency, 
        route: `/${RoutesList.currency}`,
        isActive: false,
        systemName: PermissionConstants.ManageCurrency
      },

      { 
        name: MenuConstants.Payment, 
        route: "",
        isActive: false,
        isSubMenu: true,
        systemName: "",
        subContent: [
          { 
            name: MenuConstants.PaymentMethod, 
            route: `/${RoutesList.paymentMethod}`,
            isActive: false,
            systemName: PermissionConstants.ManagePaymentMethod
          },
          { 
            name: MenuConstants.PaymentMethodOption, 
            route: `/${RoutesList.paymentMethodOption}`,
            isActive: false,
            systemName: PermissionConstants.ManagePaymentMethodOption
          },
          { 
            name: MenuConstants.PaymentTerminal, 
            route: `/${RoutesList.paymentTerminal}`,
            isActive: false,
            systemName: PermissionConstants.ManagePaymentTerminal
          },
        ]
      },
      { 
        name: MenuConstants.SalesCategory, 
        route: `/${RoutesList.salesCategory}`,
        isActive: false,
        systemName: PermissionConstants.ManageSalesCategory
      },
      { 
        name: MenuConstants.GroupCode, 
        route: `/${RoutesList.groupCode}`,
        isActive: false,
        systemName: PermissionConstants.ManageGroupCode
      },
      { 
        name: MenuConstants.Country, 
        route: `/${RoutesList.country}`,
        isActive: false,
        systemName: PermissionConstants.ManageCountry
      },
      { 
        name: MenuConstants.Race, 
        route: `/${RoutesList.race}`,
        isActive: false,
        systemName: PermissionConstants.ManageRace
      },
      { 
        name: MenuConstants.MediaSource, 
        route: `/${RoutesList.mediaSource}`,
        isActive: false,
        systemName: PermissionConstants.ManageMediaSource
      },
      { 
        name: MenuConstants.ContractLiabilityBenchmark, 
        route: `/${RoutesList.contractLiabilityBenchmark}`,
        isActive: false,
        systemName: PermissionConstants.ManageContractLiabilityBenchmark
      },
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Marcom,
		subContent: [
      { 
        name: MenuConstants.Lead, 
        route: `/${RoutesList.lead}`,
        isActive: false,
        systemName: PermissionConstants.ManageLead
      },
      { 
        name: MenuConstants.LeadDuplicate, 
        route: `/${RoutesList.leadDuplicate}`,
        isActive: false,
        systemName: PermissionConstants.ManageLeadDuplicate
      },
      { 
        name: MenuConstants.Promotion, 
        route: `/${RoutesList.promotion}`,
        isActive: false,
        systemName: PermissionConstants.ManagePromotion
      },
      { 
        name: MenuConstants.AppointmentTarget, 
        route: `/${RoutesList.appointmentTarget}`,
        isActive: false,
        systemName: PermissionConstants.ManageAppointmentTarget
      },
      { 
        name: MenuConstants.MarcomDailyRecordHistory, 
        route: `/${RoutesList.marcomDailyRecord}`,
        isActive: false,
        systemName: PermissionConstants.ManageMarcomDailyRecords
      },
      { 
        name: MenuConstants.MarcomReport, 
        route: `/${RoutesList.marcomReport}`,
        isActive: false,
        systemName: PermissionConstants.ExportMarcomReport
      },
    ],
    isActive: false
	},
  {
		menuName: MenuConstants.Report,
    route: `/${RoutesList.report}`,
    subContent: [],
    isActive: false,
    systemName: PermissionConstants.ManageReport
	},
  {
		menuName: MenuConstants.Settings,
		subContent: [
      { 
        name: MenuConstants.UnitOfMeasure, 
        route: `/${RoutesList.measurement}`,
        isActive: false,
        systemName: PermissionConstants.ManageMeasurement
      },
      {
        name: MenuConstants.Notification, 
        route: `/${RoutesList.notification}`,
        isActive: false,
        systemName: PermissionConstants.ManageNotification
      },
      { 
        name: MenuConstants.NotificationTemplate, 
        route: `/${RoutesList.notificationTemplate}`,
        isActive: false,
        systemName: PermissionConstants.ManageNotificationTemplate
      },
      { 
        name: MenuConstants.AdditionalFormField, 
        route: `/${RoutesList.additionalFormField}`,
        isActive: false,
        systemName: PermissionConstants.ManageAdditionalFormField
      },
      { 
        name: MenuConstants.AuditLog, 
        route: `/${RoutesList.auditLog}`,
        isActive: false,
        systemName: PermissionConstants.ManageAuditLog
      },
      { 
        name: MenuConstants.AppointmentSettings, 
        route: `/${RoutesList.appointmentSettings}`,
        isActive: false,
        systemName: PermissionConstants.ManageAppointmentSetting
      },
      { 
        name: MenuConstants.GeneralSettings, 
        route: `/${RoutesList.generalSettings}`,
        isActive: false,
        systemName: PermissionConstants.ManageGeneralSetting
      },
      { 
        name: MenuConstants.SalesSettings, 
        route: `/${RoutesList.salesSettings}`,
        isActive: false,
        systemName: PermissionConstants.ManageSalesSetting
      },
      { 
        name: MenuConstants.BatchJob, 
        route: `/${RoutesList.batchJob}`,
        isActive: false,
        systemName: PermissionConstants.ManageBatchJob
      },
      { 
        name: MenuConstants.CacheSettings, 
        route: `/${RoutesList.cacheList}`,
        isActive: false,
        systemName: PermissionConstants.ManageCache
      },
      {
        name: MenuConstants.Tnc, 
        route: `/${RoutesList.tnc}`,
        isActive: false,
        systemName: PermissionConstants.ManageTnc
      },
      // { 
      //   name: MenuConstants.Test, 
      //   route: `/${RoutesList.testTimeZone}`,
      //   isActive: false,
      //   systemName: PermissionConstants.ManageAuditLog
      // }
    ],
    isActive: false
	},
]