import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { CurrencyListObject } from '../../app/models/currency';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const Currency = () => {
  const intl = useIntl();
  //Use Store
  const { currencyStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { currencyList, currencyPaginationParams, getCurrency, deleteCurrency, setCurrencyPaginationParams } = currencyStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CurrencyListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateCurrency =  checkPermission([PermissionConstants.CreateCurrency]);
  const blnPermissionUpdateCurrency =  checkPermission([PermissionConstants.UpdateCurrency]);
  const blnPermissionDeleteCurrency =  checkPermission([PermissionConstants.DeleteCurrency]);

  let currencyRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCurrencyAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.currency)
      if (blnBackNavigation) {
        if (currencyPaginationParams) {
          setCurrentPage(currencyPaginationParams.pageNumber)
          currencyRequestBody = currencyPaginationParams;
        }
      }
      else {
        setCurrencyPaginationParams(undefined);
      }
      await getCurrency(currencyRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCurrency], true)) {
      return;
    }

    fetchCurrencyAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.currency)
        if (!blnSetParams) {
          setCurrencyPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: CurrencyListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const CurrencyColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      // style: {
      //   width: '60%'
      // },
    },
    {
      dataField: "currencyCode",
      text: intl.formatMessage({ id: "CurrencyCode" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.currencyCode}</>, //Custom UI to display
    },
    {
      dataField: "customFormatting",
      text: intl.formatMessage({ id: "CustomFormatting" }).toUpperCase(),
      formatter: (cellContent, row) => <>{row.customFormatting}</>, //Custom UI to display
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageCurrency, PermissionConstants.UpdateCurrency, PermissionConstants.DeleteCurrency]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.currency}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateCurrency
              &&
              <TableActionButton 
                to={`/${RoutesList.currency}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteCurrency
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Currency" }) })}
        title={intl.formatMessage({ id: "Currency" })}
        addButton={
          blnPermissionCreateCurrency
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Currency" }) })}
            onClick={() => { history.push(`/${RoutesList.currency}/add`) }}
            linkTo={`/${RoutesList.currency}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CurrencyColumns}
          data={currencyList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Currency" }) })}
          objSorted={defaultSorted}
          requestAPI={getCurrency} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCurrency(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (currencyList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCurrency({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Currency);
