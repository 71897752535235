import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { CategoryListObject } from "../../../app/models/category";
import { SalesCategoryListObject } from "../../../app/models/salesCategory";
import moment from "moment";

const StockLevelHistory = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, staticReferenceStore, categoryStore, salesCategoryStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { getCategoryDropdown } = categoryStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;
  const { stockLevelHistoryList, getStockLevelHistoryList, setStockLevelHistoryList, exportStockLevelHistory } = reportStockStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { productType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const AllCategory = `${intl.formatMessage({ id: "AllCategory" })}`;
  const [selectedCategory, setSelectedCategory] = useState<string>(AllCategory);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(" ");
  const [categoryList, setCategoryList] = useState<CategoryListObject[]>([])
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [selectedSalesCategory, setSelectedSalesCategory] = useState<string>(AllSalesCategory);
  const [selectedSalesCategoryId, setSelectedSalesCategoryId] = useState< string>(" ");
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);

  //Default Sorting Type
  const defaultSorted = [
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      productName: "",
      sKU: "",
      productTypes: [],
      closingDate: moment().format(Constants.textInputDateFormat)
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchStockLevelHistory() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCategoryDropdown(),
        getSalesCategoryDropdown(),
        getStaticReferenceWithType(Constants.productType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setCategoryList(arraySpliceInAllValue(resultAPI[1], AllCategory, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[2], AllSalesCategory, false, "name", "id"))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockLevelHistory();

    return (() => {
      setStockLevelHistoryList([]);
    })
  }, [])

  const fetchStockLevelHistoryWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }
    if (!validation.values.closingDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ClosingDate" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportStockLevelHistory({ 
        branchId:  selectedBranchId,
        productName: validation.values.productName,
        sKU: validation.values.sKU,
        productTypes: validation.values.productTypes,
        categoryId: selectedCategoryId,
        salesCategoryId: selectedSalesCategoryId,
        closingDate: validation.values.closingDate
      });
    }
    else {
      setCurrentPage(1);
      await getStockLevelHistoryList({ 
        pageNumber: 1, 
        pageSize: pageSize, 
        branchId: selectedBranchId,
        productName: encodeURIComponent(validation.values.productName),
        sKU: encodeURIComponent(validation.values.sKU),
        productTypes: validation.values.productTypes,
        categoryId: selectedCategoryId,
        categoryName: selectedCategory,
        salesCategoryId: selectedSalesCategoryId,
        salesCategoryName: selectedSalesCategory,
        closingDate: validation.values.closingDate
      })
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const StockLevelHistoryListColumns = [
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "LiveQuantity" }).toUpperCase(),
    },
    {
      dataField: "reservedQuantity",
      text: intl.formatMessage({ id: "VirtualWarehouseBalance" }).toUpperCase(),
    },
    {
      dataField: "physicalQuantity",
      text: intl.formatMessage({ id: "OnHandQuantity" }).toUpperCase(),
    },
    {
      dataField: "defectQuantity",
      text: intl.formatMessage({ id: "DefectQuantity" }).toUpperCase(),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockLevelHistory" }) })}
        title={intl.formatMessage({ id: "StockLevelHistory" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchStockLevelHistoryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Stock-Level-History.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchId"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Category" })}
              name="categoryId"
              options={categoryList}
              labelField="name"
              valueField="id"
              initialLabel={selectedCategory}
              initialValue={selectedCategoryId}
              setFieldLabel={setSelectedCategory}
              setFieldValue={setSelectedCategoryId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "SalesCategory" })}
              name="salesCategory"
              options={salesCategoryList}
              labelField="name"
              valueField="id"
              initialLabel={selectedSalesCategory}
              initialValue={selectedSalesCategoryId}
              setFieldLabel={setSelectedSalesCategory}
              setFieldValue={setSelectedSalesCategoryId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="productTypes"
              title={intl.formatMessage({ id: "ProductType" })}
              specifyReturnFieldName={[{ "field": "productTypes", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"displayValue"}
              blnValueWithNewSpace={true}
              options={productType}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="3">
            <GeneralInput
              title={intl.formatMessage({ id: "ClosingDate"})}
              name="closingDate"
              type="date"
              disabled={localLoading || loading}
              validationRequired={true}
              validation={validation}
            />
          </Col>
          <Col xl="3">
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="productName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterProductName" })}
            />
          </Col>
          <Col xl="3">
            <GeneralInput
              title={intl.formatMessage({ id: "SKU" })}
              name="sKU"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSKU" })}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchStockLevelHistoryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={StockLevelHistoryListColumns}
                data={stockLevelHistoryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockLevelHistory" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                productName={encodeURIComponent(validation.values.productName)}
                sKU={encodeURIComponent(validation.values.sKU)}
                categoryId={selectedCategoryId}
                categoryName={selectedCategory}
                salesCategoryId={selectedSalesCategoryId}
                salesCategoryName={selectedSalesCategory}
                productTypes={validation.values.productTypes}
                closingDate={validation.values.closingDate}
                keyField={"productName"}
                requestAPI={getStockLevelHistoryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockLevelHistory);
