import { PaginationRequestBody } from "../models/pagination";
import { SupplierListObject, SupplierAddObject, SupplierUpdateObject, SupplierDetailObject } from "../models/supplier";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class supplierStore {
  supplierList: SupplierListObject[] = [];
  supplierDropdownList: SupplierListObject[] = [];
  supplierDetail: SupplierDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.supplierList = [];
    this.supplierDropdownList = [];
    this.supplierDetail = undefined;
  }

  setSupplierDetail = (supplierDetail: SupplierDetailObject | undefined) => {
    this.supplierDetail = supplierDetail;
  }
  
  getSupplier = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSupplier = await agent.Supplier.supplierList(PaginationRequestBody);
      runInAction(() => {
        this.supplierList = resultSupplier.data;
        store.commonStore.setTotalItem(resultSupplier.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.supplierList = [];
    }
  }

  getSupplierDropdown = async () => {
    try{
      const resultSupplierDropdown = await agent.Supplier.supplierDropdownList();
      runInAction(() => {
        this.supplierDropdownList = resultSupplierDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.supplierList = [];
    }
  }

  getSupplierWithId = async (id: string) => {
    try{
      const resultSupplierDetail = await agent.Supplier.supplierDetail(id);
      runInAction(() => {
        this.supplierDetail = resultSupplierDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.supplierDetail = undefined;
    }
  }

  addSupplier = async (supplierRequestBody: SupplierAddObject) => {
    try{
      await agent.Supplier.addSupplier(supplierRequestBody);
      store.commonStore.setSuccessMessage(`SupplierAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateSupplier = async (supplierRequestBody: SupplierUpdateObject) => {
    try{
      await agent.Supplier.updateSupplier(supplierRequestBody);
      store.commonStore.setSuccessMessage(`SupplierUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteSupplier  = async (id: string, name: string) => {
    try {
      await agent.Supplier.deleteSupplier(id);
      store.commonStore.setSuccessMessage(`SupplierDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}