import React from "react";
import { Col, Row } from "reactstrap";

interface Props {
	children: any;
  blnDoubleTab?: boolean;
}

//这个是当 children ui 的同一排是有超过一个children ui 的时候
export default function DoubleColumnRowParent(props: Props) {
	return (
    <Col xs={"12"} md={"6"} xl={props.blnDoubleTab ? "6" : "3"}>
      {props.children}
    </Col>
	);
}
