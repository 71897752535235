import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import MyButton from "../../../app/components/form/MyButton";
import { checkPermission, compareArrayWithField, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnBrandCommissionType, returnCurrency } from "../../../app/common/function/function";
import AlertMessage from "../../../app/components/alert/AlertMessage";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, Nav, NavItem, NavLink } from "reactstrap";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../../app/components/layout/DetailViewLayout";
import { burgerMenu, ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { history } from "../../..";
import classNames from "classnames";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { CommissionSchemeThresholdObject } from "../../../app/models/commissionScheme";
import SelectDropDownModal from "../../../app/components/modal/SelectDropDownModal";

const BundledProductCommissionSchemeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { commissionSchemeStore, positionStore, packageStore, staticReferenceStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { addCommissionScheme, updateCommissionScheme, commissionSchemeDetail, setCommissionSchemeDetail, getCommissionSchemeWithId, commissionSchemePackagesList, getCommissionSchemePackages } = commissionSchemeStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const { commissionSchemeType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "BundledProductCommissionSettings" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: RoutesList.bundledProductCommissionScheme }, { title: intl.formatMessage({ id: "BundledProductCommissionSettings" }), urlPath: RoutesList.bundledProductCommissionScheme }];
  const pageSize = Constants.maxPageSize;
  const [selectedPositionIds, setSelectedPositionIds] = useState<any>([]);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [activeTab, setActiveTab] = useState("1");
  const tabBar = [intl.formatMessage({ id: "Package" }).toUpperCase(), intl.formatMessage({ id: "Commission" }).toUpperCase()];
  const [packageListForCommission, setPackageListForCommission] = useState<any[]>([]);
  const [packageModal, setPackageModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnBrandCommissionIndividual = returnBrandCommissionType() === Constants.individual;
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: commissionSchemeDetail || {
      name: "",
      commissionSchemeType: "",
      start: "",
      end: "",
      isActive: false,
      packageIds: [],
      packages: [],
      positionIds: [],
      commissionSchemeThresholds: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CommissionSchemeName" }) })),
      commissionSchemeType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionSchemeType" }) })),
      // start: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "StartDate" }) })),
      // end: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EndDate" }) })),
      //positionIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Position" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let blnBlockCommission = false;
      let blnBlockCommissionNegativeValue = false;
      let blnBlockPackage = false;
      let blnBlockCommissionFromLargerThanTo = false;
      let blnBlockPosition = false;
      let aryPackageTemp: any = [];

      if (valuesTemp.packages.length == 0) {
        blnBlockPackage = true;
      }
      else {
        valuesTemp.packages.map((valuePackagesTemp) => {
          aryPackageTemp.push(valuePackagesTemp.packageId)
        })
        valuesTemp.packageIds = aryPackageTemp;
      }
      if (valuesTemp.commissionSchemeThresholds.length == 0) {
        blnBlockCommission = true;
      }

      if (blnBrandCommissionIndividual && valuesTemp.positionIds.length == 0){
        blnBlockPosition = true;
      }

      valuesTemp.commissionSchemeThresholds = valuesTemp.commissionSchemeThresholds.map((valueCommissionSchemeThresholdsTemp)=> {
        if (valueCommissionSchemeThresholdsTemp.from > valueCommissionSchemeThresholdsTemp.to) {
          blnBlockCommissionFromLargerThanTo = true;
        }
        
        if (valueCommissionSchemeThresholdsTemp.from < 0 || valueCommissionSchemeThresholdsTemp.to < 0 || valueCommissionSchemeThresholdsTemp.percentage < 0) {
          blnBlockCommissionNegativeValue = true;
        }
        return ({
          ...valueCommissionSchemeThresholdsTemp,
          backgroundColor: ((valueCommissionSchemeThresholdsTemp.from > valueCommissionSchemeThresholdsTemp.to) || valueCommissionSchemeThresholdsTemp.from < 0 || valueCommissionSchemeThresholdsTemp.to < 0 || valueCommissionSchemeThresholdsTemp.percentage < 0) ? Constants.lightorange : ""
        })
      })
      validation.setFieldValue("commissionSchemeThresholds", valuesTemp.commissionSchemeThresholds)

      if (blnBlockCommission) {
        setErrorMessage(intl.formatMessage({ id: "EmptyRecordInCommisionTab" }));
        setActiveTab("2");
      }
      if (blnBlockCommissionNegativeValue) {
        setErrorMessage(intl.formatMessage({ id: "NegativeNumberIsNotAllow" }));
        setActiveTab("2");
      }
      if (blnBlockCommissionFromLargerThanTo) {
        setErrorMessage(intl.formatMessage({ id: "FromIsGreaterThanTo" }));
        setActiveTab("2");
      }
      if (blnBlockPackage) {
        setErrorMessage("Please select at least one Package");
        setActiveTab("1");
      }
      if (blnBlockPosition){
        setErrorMessage(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Position" }) }));
      }

      if (!blnBlockPackage && !blnBlockCommission && !blnBlockCommissionFromLargerThanTo && !blnBlockCommissionNegativeValue && !blnBlockPosition) {
        try {
          if (addAction) {
            await addCommissionScheme(valuesTemp);
          } else {
            await updateCommissionScheme(valuesTemp);
          }
        } finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  const aryDynamicInputAndCheckBoxTabCommissions: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "From" }),
      type: "input",
      xl: "4",
      inputName: "from",
      inputType: "number",
    },
    {
      label: intl.formatMessage({ id: "To" }),
      type: "input",
      xl: "4",
      inputName: "to",
      inputType: "number",
    },
    {
      label: `${intl.formatMessage({ id: "Percentage" })} (%)`,
      type: "input",
      xl: "4",
      inputName: "percentage",
      inputType: "number",
    },
  ];

  const aryDynamicInputAndCheckBoxTabPackage: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "6",
      inputName: "packageName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      type: "label",
      xl: "6",
      inputName: "sellingPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    }
  ];

  const fieldsOfCommisions: CommissionSchemeThresholdObject = { from: 0, to: 0, percentage: 0 };
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCommissionSchemeDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.commissionSchemeType),
        getPositionDropdown(1),
        getCommissionSchemePackages(),
      ];
      if (id && !addAction) {
        aryAPI.push(getCommissionSchemeWithId(id));
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0].length > 0) {
        let indexPackageType = _.findIndex(resultAPI[0], { displayValue: Constants.package });
        if (indexPackageType > -1) {
          validation.setValues((values) => ({
            ...values,
            commissionSchemeType: resultAPI[0][indexPackageType].displayValue,
          }))
        }
      }
      setLoading(false);
    }

    if (addAction) {
      setCommissionSchemeDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCommissionScheme], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCommissionScheme], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCommissionScheme], true)) {
        return;
      }
    }

    if (viewAction && checkPermission([PermissionConstants.UpdateCommissionScheme])) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.bundledProductCommissionScheme}/edit/${id}`) } })
    }

    fetchCommissionSchemeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCommissionSchemeDetail(undefined);
      }
    })
  }, []);


  useEffect(() => {
    let arySelectedPackagesTemp: any = [];
    if (id && !addAction) {
      if (commissionSchemeDetail) {
        if (commissionSchemeDetail.packageIds) {
          commissionSchemeDetail.packageIds.map((value) => {
            for (var a = 0; a < commissionSchemePackagesList.length; a++) {
              if (value === commissionSchemePackagesList[a].id && (commissionSchemeDetail.id === commissionSchemePackagesList[a].selectedByCommissionSchemeId || commissionSchemePackagesList[a].selectedByCommissionSchemeId === Constants.emptyId)) {
                arySelectedPackagesTemp.push({
                  packageId: commissionSchemePackagesList[a].id,
                  packageName: commissionSchemePackagesList[a].name,
                  sellingPrice: commissionSchemePackagesList[a].sellingPrice,
                  selectedByCommissionSchemeId: commissionSchemePackagesList[a].selectedByCommissionSchemeId,
                  isDisabled: commissionSchemePackagesList[a].isDisabled
                })
              }
            }
          })
          validation.setFieldValue('packages', arySelectedPackagesTemp)
        }
        if (commissionSchemeDetail.positionIds) {
          let arySelectedPositionIdTemp: any = [];
          commissionSchemeDetail.positionIds.map((value) => {
            for (var a = 0; a < positionDropdownList.length; a++) {
              if (value === positionDropdownList[a].id) {
                arySelectedPositionIdTemp.push({
                  ...positionDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(positionDropdownList[a].name),
                  value: positionDropdownList[a].id
                })
              }
            }
          })
          setSelectedPositionIds(arySelectedPositionIdTemp);
        }
      }
    }

    let aryPackageTemp: any = [];
    commissionSchemePackagesList.map((valuePackageList) => {
      if (valuePackageList.selectedByCommissionSchemeId === Constants.emptyId && valuePackageList.isDisabled == false) {
        let packageIdFound = false;
        if (arySelectedPackagesTemp.length > 0) {
          arySelectedPackagesTemp.map((value) => {
            if (value.packageId === valuePackageList.id) {
              packageIdFound = true;
            }
          })
        }
        aryPackageTemp.push({
          packageId: valuePackageList.id,
          packageName: valuePackageList.name,
          sellingPrice: valuePackageList.sellingPrice,
          disabled: packageIdFound || valuePackageList.isDisabled
        })
      }
      else {
        aryPackageTemp.push({
          packageId: valuePackageList.id,
          packageName: valuePackageList.name,
          sellingPrice: valuePackageList.sellingPrice,
          disabled: valuePackageList.isDisabled
        })
      }
    })
    setPackageListForCommission(aryPackageTemp);
  }, [commissionSchemePackagesList, commissionSchemeDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "BundledProductCommissionSettings" }) })}
                  h4Title />
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "BundledProductCommissionSchemeName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                {/* <SingleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"commissionSchemeType"}
                    title={intl.formatMessage({ id: "CommissionSchemeType" })}
                    specifyReturnFieldName={[
                      {
                        field: "commissionSchemeType",
                        value: "displayValue",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    blnValueWithNewSpace={true}
                    options={commissionSchemeType}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={!addAction ? validation.values.commissionSchemeType : undefined}
                    // onChangeFunction={getEmployeeType}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent> */}
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "CommissionSchemeType" })}
                    name="commissionSchemeType"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                {/* <Row>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "StartDate" })}
                      name="start"
                      type="date"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "EndDate" })}
                      name="end"
                      type="date"
                      disabled={disabledFieldInput || viewAction}
                      minCurrentDate={true}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row> */}
                {
                  blnBrandCommissionIndividual
                  &&
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitleMultiSelect
                      name={"positionIds"}
                      title={intl.formatMessage({ id: "Position" })}
                      specifyReturnFieldName={[{ "field": "positionIds", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={positionDropdownList}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={selectedPositionIds}
                      validationRequired={true}
                      validation={validation} />
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                </SingleColumnRowParent>
              </Col>
              <Col xl={6}>
                <Nav tabs className="">
                  {
                    tabBar.map((value, indexTab) => (
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active: activeTab === String(indexTab + 1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab + 1));
                          }}
                        >
                          {value}
                        </NavLink>
                      </NavItem>
                    ))
                  }
                </Nav>
                {activeTab === "1" && (
                  <TableWithEditableInputFields
                    name="packages"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
                    blnEditable={!viewAction}
                    blnSupportWithModal={true}
                    blnBtnModalBottom={true}
                    options={packageListForCommission}
                    optionsId={"packageId"}
                    setOptionsFunction={setPackageListForCommission}
                    addButtonFunction={() => setPackageModal(true)}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabPackage}
                    data={validation.values.packages}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
                {activeTab === "2" && (
                  <TableWithEditableInputFields
                    name="commissionSchemeThresholds"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Commissions" }) })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabCommissions}
                    data={validation.values.commissionSchemeThresholds}
                    inputField={fieldsOfCommisions}
                    supportBackgroundColor={true}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
        {
          packageModal &&
          <SelectDropDownModal
            blnShow={packageModal}
            setModal={setPackageModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Package" }) })}
            buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
            placeholder={"- Please Select -"}
            labelField={"packageName"}
            valueField={"packageId"}
            options={packageListForCommission}
            setOptionsFunction={setPackageListForCommission}
            fieldInput={validation.values.packages}
            fieldName={"packages"}
            validation={validation} />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(BundledProductCommissionSchemeDetail);
