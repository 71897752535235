import { PaginationRequestBody } from "../models/pagination";
import { ProductRedemptionEligibleListObject, ProductRedemptionNoteAddObject, ProductRedemptionNoteDetailObject, ProductRedemptionNoteListObject, ProductRedemptionNoteSignObject, ProductRedemptionNoteVoidObject } from "../models/productRedemptionNote";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class productRedemptionNoteStore {
  productRedemptionNoteList: ProductRedemptionNoteListObject[] = [];
  productRedemptionNoteDetail: ProductRedemptionNoteDetailObject | undefined = undefined;
  productRedemptionNoteMovementsTotalItem: number = 0;
  productRedemptionNotePaginationParams: PaginationRequestBody | undefined = undefined;
  productRedemptionEligibleList: ProductRedemptionEligibleListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.productRedemptionNoteList = [];
    this.productRedemptionNoteDetail = undefined;
    this.productRedemptionNoteMovementsTotalItem = 0;
    this.productRedemptionNotePaginationParams = undefined;
    this.productRedemptionEligibleList = [];
  }

  setProductRedemptionNoteDetail = (productRedemptionNoteDetail: ProductRedemptionNoteDetailObject | undefined) => {
    this.productRedemptionNoteDetail = productRedemptionNoteDetail;
  }

  setProductRedemptionNotePaginationParams = (productRedemptionNotePaginationParams: PaginationRequestBody | undefined) => {
    this.productRedemptionNotePaginationParams = productRedemptionNotePaginationParams;
  }
  
  setProductRedemptionEligibleList = (productRedemptionEligibleList: ProductRedemptionEligibleListObject[]) => {
    this.productRedemptionEligibleList = productRedemptionEligibleList;
  }
  
  getProductRedemptionNote = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setProductRedemptionNotePaginationParams(PaginationRequestBody);
    try{
      const resultProductRedemptionNote = await agent.ProductRedemptionNote.productRedemptionNoteList(PaginationRequestBody);
      runInAction(() => {
        this.productRedemptionNoteList = resultProductRedemptionNote.data;
        store.commonStore.setTotalItem(resultProductRedemptionNote.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionNoteList = [];
    }
  }
  
  getProductRedemptionNoteWithId = async (id: string) => {
    try{
      const resultProductRedemptionNote = await agent.ProductRedemptionNote.productRedemptionNoteDetail(id);
      runInAction(() => {
        this.productRedemptionNoteDetail = resultProductRedemptionNote;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionNoteDetail = undefined;
    }
  }

  getProductRedemptionEligibleWithId = async (customerId: string, deliverOrderId?: string) => {
    try{
      let resultProductRedemptionEligible = await agent.ProductRedemptionNote.productRedemptionEligibleList(customerId, deliverOrderId);
      if (resultProductRedemptionEligible) {
        resultProductRedemptionEligible = resultProductRedemptionEligible.map((valueProductRedemptionEligible) => ({
          ...valueProductRedemptionEligible,
          quantity: 0,
          isActive: false
        }))
      }
      runInAction(() => {
        this.productRedemptionEligibleList = resultProductRedemptionEligible;
      });
      return Promise.resolve(resultProductRedemptionEligible);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionEligibleList = [];
    }
  }

  addProductRedemptionNote = async (productRedemptionNoteRequestBody: ProductRedemptionNoteAddObject) => {
    try{
      const resultProductRedemptionNote=  await agent.ProductRedemptionNote.addProductRedemptionNote(productRedemptionNoteRequestBody);
      store.commonStore.setSuccessMessage(`ProductRedemptionAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultProductRedemptionNote});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {productRedemptionNoteId: "", productRedemptionNoteNo: ""}});
    }
  }

  signProductRedemptionNote = async (signProductRedemptionRequestBody: ProductRedemptionNoteSignObject) => {
    try{
      let result = await agent.ProductRedemptionNote.signProductRedemptionNote(signProductRedemptionRequestBody);
      if (result.isRedeemOnBehalf) {
        store.commonStore.setSuccessMessageWithoutNavigation(`ProductRedemptionNoteSignSuccess`)
      }
      else {
        store.commonStore.setSuccessMessage(`ProductRedemptionNoteSignSuccess`)
      }
      return Promise.resolve({status: Constants.success, data: result});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: undefined});
    }
  }
  
  requestVoidProductRedemptionNote = async (requestVoidProductRedemptionNoteRequestBody: ProductRedemptionNoteVoidObject) => {
    try{
      await agent.ProductRedemptionNote.requestVoidProductRedemptionNote(requestVoidProductRedemptionNoteRequestBody);
      store.commonStore.setSuccessMessage(`VoidProductRedemptionNoteRequestSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewVoidProductRedemptionNote = async (reviewVoidProductRedemptionNoteRequestBody: ProductRedemptionNoteVoidObject) => {
    try{
      await agent.ProductRedemptionNote.reviewVoidProductRedemptionNote(reviewVoidProductRedemptionNoteRequestBody);
      store.commonStore.setSuccessMessage(`VoidProductRedemptionNoteReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  cancelProductRedemptionNote = async (id: string) => {
    try{
      await agent.ProductRedemptionNote.cancelProductRedemptionNote(id);
      store.commonStore.setSuccessMessage(`CancelProductRedemptionNoteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}