import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, returnPriceWithCurrency, newSpaceBeforeCapitalLetter, cashOutStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralAttachment from "../../app/components/form/GeneralAttachment";
import { GeneralAttachmentListInterface } from "../../app/models/common";
import { history } from "../..";
import MiniCard from "../../app/components/form/MiniCard";

const CashOutDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();

  //Use Store
  const { cashOutStore, commonStore } = useStore();
  const { successMessage, loading, setLoading } = commonStore;
  const { cashOutDetail, setCashOutDetail, getCashOutWithId } = cashOutStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CashOut" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CashOut" }), urlPath: RoutesList.cashOut }];

  const cashOutFullDetail = cashOutDetail || {
    id: "",
    branchId: "",
    branchName: "",
    submissionDate: "",
    status: "",
    totalAmount: 0,
    note: "",
    attachmentFileHistoryIds: [],
    attachmentFiles: [],
    cashOutDetails: [],
    amountSummaries: [],
    aryAttachments: [],
    workflowAction: ""
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      aryAttachments: []
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
    },
  });

  async function fetchCashOutDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCashOutWithId(id),
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[0]) {
        if (resultAPI[0].status === Constants.draft) {
          history.push(`/${RoutesList.notFound}`)
          setLoading(false);
          return;
        }
      }

      let aryAttachmentsTemp : GeneralAttachmentListInterface[]  = [];
      if (resultAPI[0].attachmentFiles) {
        resultAPI[0].attachmentFiles.map((valueAttachmentFile)=> {
          aryAttachmentsTemp.push({
            id: valueAttachmentFile.attachmentFileId,
            preview: "",
            path: valueAttachmentFile.attachmentFileName,
            webUrl: valueAttachmentFile.attachmentWebUrl,
          })
        })
        validation.setFieldValue("aryAttachments", aryAttachmentsTemp);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCashOut], true)) {
      return;
    }

    fetchCashOutDetailAPI();
    
    return (() => {
      if (id && !addAction) {
        setCashOutDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <div>
          <h6 className="m-0 text-muted">
            {label}
          </h6>
        </div>
        <div>
          <h6 className="text-muted" style={{whiteSpace: "pre-wrap"}}>
            {value}
          </h6>
        </div>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="cashOut-title margin-bottom-16 ">
                      <Row>
                        <Col xs="6">
                          <div className="logo-lg" style={{ lineHeight: '20px' }}>
                            <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CashOut" })}
                            </span>
                            <br />
                            <h3 className="font-weight-bold text-muted margin-top-16" style={{ fontSize: '14px' }}>
                              {cashOutFullDetail.branchName}
                            </h3>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Date" })}:`, `${moment(cashOutFullDetail.submissionDate).format(Constants.displayDateFormat)}`)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr/>
                    <Col xs="6">
                      <address>
                        <div className="margin-top-8">
                          <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                          <br />
                          <div style={{ marginTop: '8px' }}>
                            <GeneralStatus 
                              status={cashOutFullDetail.status}
                              statusColorFunction={cashOutStatusColor}
                              blnDisableCenter/>
                          </div>
                        </div>
                      </address>
                    </Col>
                    <div className="py-2 mt-4">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Summaries" })}
                      </h3>
                    </div>
                    <Row>
                      {
                        cashOutFullDetail.amountSummaries.map((valueAmountSummary, indexAmountSummary)=> (
                          <Col xl="2" lg="5" md="6" sm="6" xs="6" key={`amount_summary_${indexAmountSummary}`}>
                            <MiniCard
                              title={newSpaceBeforeCapitalLetter(valueAmountSummary.type)}
                              value={returnPriceWithCurrency(valueAmountSummary.amount)}
                              borderColor={Constants.blue}
                              size={12} />
                          </Col>
                        ))
                      }
                    </Row>
                    <div className="py-2 mt-2">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "Details" })}
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-fixed">
                        <thead className="table-light">
                          <tr>
                            <th>{intl.formatMessage({ id: "TransactionDate" })}</th>
                            <th>{intl.formatMessage({ id: "TransactionType" })}</th>
                            <th>{intl.formatMessage({ id: "Amount" })}</th>
                            <th>{intl.formatMessage({ id: "TransactionNo" })}</th>
                            <th>{intl.formatMessage({ id: "Remark" })}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            cashOutFullDetail.cashOutDetails.map((valueCashOutDetails, indexCashOutDetails)=> (
                              <tr key={`cashout_details_${indexCashOutDetails}`}>
                                <td>{`${moment(valueCashOutDetails.transactionDate).format(Constants.displayDateAndTimeFormat)}`}</td>
                                <td>{newSpaceBeforeCapitalLetter(valueCashOutDetails.transactionType)}</td>
                                <td>{returnPriceWithCurrency(valueCashOutDetails.amount)}</td>
                                <td>{valueCashOutDetails.transactionNo || Constants.emptyData}</td>
                                <td>{valueCashOutDetails.remark || Constants.emptyData}</td>
                              </tr>
                            ))
                        }
                        </tbody>
                      </Table>
                    </div>
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Note" })}
                      name="note"
                      className={`mt-4`}
                      row={4}
                      field={cashOutFullDetail}
                      setFieldValue={()=> {}}
                      disabled={true} />
                    <GeneralAttachment
                      title={intl.formatMessage({ id: "Attachments" })}
                      name="aryAttachments"
                      className="mt-4"
                      validation={validation}
                      disabled={true}/>
                    <div className="d-print-none flex-direction-row mb-2" style={{ marginTop: "3rem" }}>
                      <GeneralSubmitAndCancelBtn
                        className="width-100-percentage"
                        successMessage={successMessage}
                        viewAction={false}
                        hidePrimaryButton={true}
                        showPrint={true}
                        cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CashOutDetail);
