import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import { useLocation, Link } from "react-router-dom";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { calculateBalanceAmount, checkPermission, contructValidationErrorMessage, getBranchId, getBranchName } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import CustomerOrderCart from "../CustomerOrder/subView/CustomerOrderCart";
import { useIntl } from "react-intl";
import PaymentSubview from "../CustomerOrder/subView/PaymentSubview";
import CustomerOrderConfimSalesModal from "../CustomerOrder/Modal/CustomerOrderConfimSalesModal";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { EmployeeListObject } from "../../app/models/employee";
import { AvailableUnknownPaymentListObject } from "../../app/models/customer";

const TopUpCreditPayment = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { branchStore, customerStore, employeeStore, settingsStore, staticReferenceStore, crnRequestStore, paymentMethodOptionStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, setErrorMessage, setSuccessMessage, windowSize } = commonStore;
  const { getCustomerAvailableUnknownPayment } = customerStore;
  const { employeeDropdownList } = employeeStore;
  const { branchPaymentMethod, getBranchPaymentMethod } = branchStore;
  const { cardType, getStaticReferenceWithType } = staticReferenceStore;
  const { topUpCredit } = crnRequestStore;
  const { generalSettings, getGeneralSettings} = settingsStore;
  const { getPaymentMethodOptionDropdown } = paymentMethodOptionStore;
  const location = useLocation();
  const [customerConfirmSalesModal, setCustomerConfirmSalesModal] = useState(false);
  const [blnBlockPaymentSubmit, setBlnBlockPaymentSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const [availableUnknownPaymentList, setAvailableUnknownPaymentList] = useState<AvailableUnknownPaymentListObject[]>([]);
  const blnPermissionBackdateProductRedemptionNote= checkPermission([PermissionConstants.BackdateProductRedemptionNote]);
  const blnPermissionUseUnknownCustomerPayment = checkPermission([PermissionConstants.UseUnknownCustomerPayment]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      paymentMethod: "",
      branchId: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      consultantIds: [],
      note: "",
      totalCount: 0,
      totalPrice: 0,
      totalSellingPrice: 0,
      totalTax: 0,
      paidNumber: 0,
      salesOrderDetails: [],
      paymentDetails: [],
      availableCredits: 0,
      availableProductCredits: 0,
      blnDifferentBranch: false,
      originalBranchName: "",
      tinDisplayId: "",
      tinDisplayName: ""
    },
    validationSchema: Yup.object({
      paymentDetails: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "PaymentMethod" }).toLowerCase() })),
      consultantIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() })),
    }),
    onSubmit: async (values: any) => {
      try {
        let valuesTemp = _.cloneDeep(values);
        let totalPaid : any = 0;
        valuesTemp.paymentDetails = valuesTemp.paymentDetails.map((valueCustomerPaymentDetailsTemp) => {
          totalPaid += Number(valueCustomerPaymentDetailsTemp.paidAmount)
          return ({
            ...valueCustomerPaymentDetailsTemp,
            transactionNo: valueCustomerPaymentDetailsTemp.transactionNo ? String(valueCustomerPaymentDetailsTemp.transactionNo): "",
          })
        })
        
        totalPaid = totalPaid.toFixed(2);
        if (totalPaid > valuesTemp.totalPrice) {
          setErrorMessage(intl.formatMessage({ id: "TotalPaidAmountCouldNotMoreThanTotalPrice" }))
          return;
        }
        else if (!customerConfirmSalesModal){
          setCustomerConfirmSalesModal(true);
        }
        else {
          //Consultants
          valuesTemp.consultantIds = valuesTemp.consultantIds.map((valueConsultantTemp) => (valueConsultantTemp.value));

          //Top Up Credit Details
          valuesTemp.topUpCreditDetails = valuesTemp.salesOrderDetails.map((valueSalesOrderTemp)=> ({
            count: valueSalesOrderTemp.count,
            creditPackageId: valueSalesOrderTemp.itemId
          }));
          delete valuesTemp["salesOrderDetails"];

          //Set Branch Id
          valuesTemp.branchId = getBranchId();

          setBlnBlockPaymentSubmit(true);
          await fetchSubmitTopUpCredit(valuesTemp);
        }
      }
      catch (e) {
      }
      finally {
        setTimeout(()=> {
          setBlnBlockPaymentSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  }); 

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPackageAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchPaymentMethod(getBranchId()),
        getStaticReferenceWithType(Constants.cardType),
        getGeneralSettings()
      ];
      if (location.state) {
        aryAPI.push(getCustomerAvailableUnknownPayment(location.state.detail.customerId))
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 3) {
        setAvailableUnknownPaymentList(resultAPI[3]);
      }  
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.CreateCreditRedemptionNoteRequest], true)) {
      return;
    }
    
    fetchPackageAPI();
  }, [])

  useEffect(() => {
    if (location.state) {
      validation.setValues({
        ...location.state.detail,
        paidNumber: location.state.detail.totalPrice
      })
      setLocalEmployeeDropdownList(employeeDropdownList)
    }
    else {
      history.push(`/${RoutesList.notFound}`)
    }
  }, [location]);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const deletePaymentOrder = (index) => {
    let paymentDetailsTemp: any = _.cloneDeep(validation.values.paymentDetails);
    if (paymentDetailsTemp[index].unknownPaymentId) {
      let availableUnknownPaymentListTemp = _.cloneDeep(availableUnknownPaymentList);
      let indexAvailableUnknownPaymentTemp = _.findIndex(availableUnknownPaymentListTemp, {unknownPaymentId : paymentDetailsTemp[index].unknownPaymentId})
      if (indexAvailableUnknownPaymentTemp > -1) {
        availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount = Number(availableUnknownPaymentListTemp[indexAvailableUnknownPaymentTemp].availableAmount) + Number(paymentDetailsTemp[index].paidAmount)
        setAvailableUnknownPaymentList(availableUnknownPaymentListTemp);
      }
    }
    paymentDetailsTemp.splice(index, 1);
    validation.setFieldValue("paymentDetails", paymentDetailsTemp);
    let prefillPaidNumber : any = calculateBalanceAmount(validation, "paymentDetails", "totalPrice", paymentDetailsTemp);
    validation.setFieldValue("paidNumber", prefillPaidNumber);
  }

  const fetchSubmitTopUpCredit = async (valuesTemp) => {
    let result = await topUpCredit(valuesTemp);
    if (result.status === Constants.success) {
      setTimeout(() => {
        setSuccessMessage("")
        history.push({ pathname: `/${RoutesList.customerSalesOrder}/view/${result.data.salesOrderId}`});
      }, Constants.dismissSuccessMessage)
    }
  }

  const displayLocalBreadCrumb = () => {
    return (
      <div className={`page-title-box d-block align-items-center justify-content-between`}>
        <div className="page-title-right">
            <nav className="">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={`/${RoutesList.dashboard}`}>{intl.formatMessage({ id: "Dashboard" })}</Link></li>
                    <li key={`breadCrumb_go_back`} className="breadcrumb-item"><div className="pointer-clickable" style={{display: "contents"}} onClick={()=> {history.goBack()}}>{intl.formatMessage({ id: "TopUpCredit" })}</div></li>
                    <li className="active breadcrumb-item" aria-current="page">{intl.formatMessage({ id: "SalesSummary" })}</li>
                </ol>
            </nav>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "TopUpCredit" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "SalesSummary" }).toUpperCase()}</div>
            </Col>
          </Row>
          {displayLocalBreadCrumb()}
          {loading && !successMessage ? (
            <Loading />
          ) : (
            <Form
              id={Constants.paymentOrderForm}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="5" md="5" sm="12" xs="12">
                  <div className="standard-layout">
                    <CustomerOrderCart
                      validation={validation}
                      disabledCustomerOrder={true}
                      disabledNote={false}
                      disabledVoidedSalesOrderNo={true}
                      disabledCustomerPayment={disabledFieldInput}
                      deletePaymentOrder={deletePaymentOrder}
                      blnShowMinimumPaymentRequirement={true}
                      blnDisplayRounding={false} />
                  </div>
                </Col>
                <Col xl="7" md="7" sm="12" xs="12" className={`${Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : ""}`}>
                  <div className={`standard-layout ${Constants.innerWidthCompare770 > windowSize.innerWidth ? "padding-top-0 margin-top--40" : ""}`}>
                    <PaymentSubview
                      validation={validation}
                      disabledFieldInput={disabledFieldInput}
                      branchPaymentMethod={branchPaymentMethod}
                      availableUnknownPaymentList={blnPermissionUseUnknownCustomerPayment ? availableUnknownPaymentList : []}
                      setAvailableUnknownPaymentList={setAvailableUnknownPaymentList}
                      cardType={cardType}
                      isTransferCreditEnabled={generalSettings?.isTransferCreditEnabled || false}
                      setErrorMessage={setErrorMessage}
                      getPaymentMethodOptionDropdown={getPaymentMethodOptionDropdown}
                    />
                    <Borderline />
                    <Row>
                      <Col xl='6' md='6' xs='6'>
                        <DropDownWithTitle
                          name={"customerId"}
                          title={intl.formatMessage({ id: "Customer" })}
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          specifyReturnFieldName={[
                            {
                              field: "customerId",
                              value: "id",
                            }
                          ]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={[]}
                          disabled={disabledFieldInput || true}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          validation={validation}
                          validationRequired={true}
                        />
                        {
                          validation.values.originalBranchName !== "" && validation.values.originalBranchName !== getBranchName()
                          &&
                          <p className="text-danger margin-top--12">*{intl.formatMessage({ id: "OriginalBranch" })}: {validation.values.originalBranchName}</p>
                        }
                      </Col>
                      <Col xl='6'>
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          title={"Consultants"}
                          specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                          returnFieldWithLabel={true}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={localEmployeeDropdownList}
                          disabled={disabledFieldInput || validation.values.blnDifferentBranch}
                          initialValue={validation.values.consultantIds}
                          validationRequired={true}
                          validation={validation} />
                      </Col>
                    </Row>
                    <p className="margin-left-8 mb-0" style={{ color: 'grey' }}>
                      {validation.values.customerNo} | {validation.values.customerMobileNo} {`${validation.values.tinDisplayName ? ` | ${validation.values.tinDisplayName}` : ""}`}
                    </p>
                    <Borderline />
                    <Row>
                      <Col xl={4}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="button"
                              class="btn btn-back"
                              style={{ width: '100%' }}
                              content={intl.formatMessage({ id: "Back" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                              onClick={() => {
                                history.goBack();
                              }}
                            />
                          )}
                      </Col>
                      <Col xl={8}>
                        {!Boolean(successMessage) &&
                          (
                            <MyButton
                              type="submit"
                              class="btn btn-primary"
                              style={{ width: '100%', marginTop: Constants.innerWidthCompare>windowSize.innerWidth ? "8px" : "0px" }}
                              content={intl.formatMessage({ id: "ConfirmSales" }).toUpperCase()}
                              disable={
                                validation.isSubmitting
                              }
                              loading={
                                validation.isSubmitting
                              }
                            />
                          )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            customerConfirmSalesModal
            &&
            <CustomerOrderConfimSalesModal
              blnShow={customerConfirmSalesModal}
              setModal={setCustomerConfirmSalesModal}
              validation={validation}
              employeeDropdownList={localEmployeeDropdownList}
              blnBlockPaymentSubmit={blnBlockPaymentSubmit}
              blnPermissionBackdateProductRedemptionNote={blnPermissionBackdateProductRedemptionNote}
              blnHideRounding={true} />
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(TopUpCreditPayment);
