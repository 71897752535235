import _ from "lodash";
import React, { Fragment } from "react";
import { FormFeedback, Label } from "reactstrap";

interface Props {
	title: string;
	name: string;
	className?: string;
	placeholder?: string;
	row: number;
	disabled: boolean;
	validationRequired?: boolean;
	validation?: any;
  blnModal?: boolean;
  field?: any;
  setFieldValue?: Function;
	onChangeFunction?: Function;
}

export default function GeneralTextArea(props: Props) {
	return (
		<div>
			<div className={props.className || "mb-3"}>
				{props.title && (
					<Label>
						{props.validationRequired && (
							<Label style={{ color: "red" }}>{`*`}</Label>
						)}
						<Label className="margin-bottom-0">{props.title}</Label>
					</Label>
				)}
				{props.title && <br />}
				<textarea
					className="form-control mb-3"
					id={props.name}
					placeholder={props.placeholder}
					rows={props.row}
					onChange={!props.blnModal ? props.validation.handleChange : (event)=> {
            if (props.setFieldValue) {
              let fieldTemp = _.cloneDeep(props.field);
              fieldTemp[props.name] = event.target.value
              props.setFieldValue(fieldTemp);

							if (props.onChangeFunction) {
								props.onChangeFunction();
							}
            }
          }}
					disabled={props.disabled}
					value={props.blnModal ? props.field[props.name] || "" : props.validation.values[props.name] || ""}
				/>
				{!props.blnModal &&
        props.validation.touched[props.name] &&
				props.validation.errors[props.name] ? (
					<FormFeedback type="invalid">
						{props.validation.errors[props.name]}
					</FormFeedback>
				) : null}
			</div>
		</div>
	);
}
