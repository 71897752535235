import React, { useState, useEffect } from "react";
import { Col, Form, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox, WindowObject } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import { EmployeeLeaveConfirmationDetailObject } from "../../../app/models/employeeLeave";
import { displayTypeWithColor } from "../../../app/common/function/function";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  blnShow: boolean;
  setModal: Function;
  importedEmployeeLeaveDetails: EmployeeLeaveConfirmationDetailObject[];
  setImportedEmployeeLeaveDetails: Function;
  refreshData: Function;
  importedEmployeeLeaveDeletedLeaveIds: string[];
}

export default observer(function ImportEmployeeLeaveModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore, employeeLeaveStore } = useStore();
  const { importEmployeeLeaveConfirmation } = employeeLeaveStore;
  const {
    windowSize,
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;
  const [blnHideConfirm, setBlnHideConfirm] = useState(false);
  
  const hideModal = () => {
    props.setModal(false);
    props.setImportedEmployeeLeaveDetails([]);
  }

  const [loadingProduct, setLoadingProduct] = useState(false);
  const [pageSize, setPageSize] = useState(Constants.defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const viewEmployeeLeaveDetails = (index) => {
    if (props.importedEmployeeLeaveDetails.length > index) {
      if (props.importedEmployeeLeaveDetails[index].duplicatedEmployeeLeaveId) {
        window.open(`/${RoutesList.employeeLeave}/view/${props.importedEmployeeLeaveDetails[index].duplicatedEmployeeLeaveId}`)
      }
    }
  }

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: "",
        type: "input",
        xl: "0.5",
        inputName: "isRequiredToAddEmployeeLeave",
        inputType: "checkbox",
        checkBoxText: "",
        disabledField: "checkFieldDisabled"
      },
      {
        label: intl.formatMessage({ id: "No." }).toUpperCase(),
        type: "label",
        xl: "0.6",
        inputName: "",
        inputType: "text",
        displayIndexNumber: true
      },
      {
        label: intl.formatMessage({ id: "Name" }).toUpperCase(),
        type: "label",
        xl: "1.5",
        inputName: "employeeNameDisplay",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "LeaveType" }).toUpperCase(),
        type: "label",
        xl: "1.32",
        inputName: "leaveTypeDisplay",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
        type: "label",
        xl: "1.32",
        inputName: "startDateDisplay",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "EndDate" }).toUpperCase(),
        type: "label",
        xl: "1.32",
        inputName: "endDateDisplay",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "TotalDays" }).toUpperCase(),
        type: "label",
        xl: "1.22",
        inputName: "totalLeaveDaysDisplay",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Status" }).toUpperCase(),
        type: "label",
        xl: "1.32",
        inputName: "status",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Reason" }).toUpperCase(),
        type: "label",
        xl: "1.42",
        inputName: "reason",
        inputType: "text",
      },
      {
        label: `${intl.formatMessage({ id: "MoreInfo" })}`,
        type: "dynamicButton",
        xl: "1.42",
        textAlign: true,
        textValueAlign: true,
        inputName: "",
        inputType: "row",
        rowButtonTitle: "View",
        reverseDisabledField: "duplicatedEmployeeLeaveId",
        dynamicButtonFunction: viewEmployeeLeaveDetails
      },
    ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employeeLeaveDetails: props.importedEmployeeLeaveDetails || [],
      deletedLeaveIds: props.importedEmployeeLeaveDeletedLeaveIds || []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      try {
        const valuesTemp = _.cloneDeep(values);
        let resultImportEmployeeLeaveConfirmation = await importEmployeeLeaveConfirmation(valuesTemp);
        if (resultImportEmployeeLeaveConfirmation?.status) {
          if (resultImportEmployeeLeaveConfirmation.status === Constants.success) {
            if (props.refreshData) {
              props.refreshData(false);
            }
          }
        }
      } finally {
        validationLocal.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = Boolean(successMessage) || validationLocal.isSubmitting;

  useEffect(()=> {
    if (props.importedEmployeeLeaveDetails) {
      for (let a=0; a<props.importedEmployeeLeaveDetails.length; a++) {
        let valueImportedEmployeeLeaveDetails = props.importedEmployeeLeaveDetails[a];
        if (valueImportedEmployeeLeaveDetails.isDuplicatedEmployeeLeave || valueImportedEmployeeLeaveDetails.isInvalidEmployeeNo) {
          setBlnHideConfirm(true);
          break;
        }
      }
    }
  }, [])

  return (
    !props.blnShow
    ?
    <div />
    :
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size="xl"
      centered>
      {
        loadingProduct
          ?
          <div style={{ padding: "20px" }}>
            <Loading />
          </div>
          :
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ImportEmployeeLeaveConfirmation" })}</h5>
              </div>
              <button
                type="button"
                onClick={() => { 
                  if (!disabledFieldInput) {
                    hideModal(); 
                  }
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                // className="standard-layout"
                onSubmit={(e) => {
                  e.preventDefault();
                  validationLocal.handleSubmit();
                  return false;
                }}
                onBlur={() => {
                  if (errorMessage || successMessage) {
                    setErrorMessage("");
                    setSuccessMessage("");
                  }
                }}>
                <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginRight: "42px", marginBottom: '8px'}}>
                  {displayTypeWithColor(Constants.leadPink, intl.formatMessage({id: "InvalidEmployeeNo"}))}
                  {displayTypeWithColor(Constants.leadYellow, intl.formatMessage({id: "OverlappedEmployeeLeave"}))}
                </div>
                <LineBreakWithTittle
                  blnSkipMarginTop={true}
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "EmployeeLeave" }) })}
                />
                <TableWithEditableInputFields
                  name="employeeLeaveDetails"
                  title=""
                  buttonTitle={intl.formatMessage({ id: "Confirm" })}
                  blnEditable={true}
                  aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                  data={validationLocal.values.employeeLeaveDetails}
                  validation={validationLocal}
                  disabled={disabledFieldInput}
                  supportBackgroundColor={true}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  blnSupportPagination={true}
                  hideAddButton={true}
                  hideDeleteButton={true} />
                {
                  !blnHideConfirm
                  &&
                  <div className="mb-3 mt-4">
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Confirm" })}
                      loading={validationLocal.isSubmitting}
                      disable={disabledFieldInput}
                    />
                  </div>
                }
              </Form>
            </div>
          </div>
      }
    </Modal>
  )
})