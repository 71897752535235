import React from "react";
import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { history } from '../../..';
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  leadDetail: any;
  changeLeadStatus: Function;
  leadId: any;
}

export default observer(function ReopenConfirmationModal(props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingFollowUp, setLoadingFollowUp] = useState(false);
  const reopenRequestBody = {
    type: props.leadDetail.type,
    leadId: props.leadId,
    status: 0 // 0 = Open
  }

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        props.setModal(false);
        history.push(`/${RoutesList.lead}/view/${props.leadId}`);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const onClickFollowUp = async (id: any, status: any) => {
    setLoadingFollowUp(true);
    await props.changeLeadStatus(reopenRequestBody);
    setLoadingFollowUp(false);
  }

  return (
    <ActionPromptModal 
      title={`Are you sure want to reopen this record ?`}
      primaryActionTitle={`Yes, open it!`}
      showPrompt={props.blnShow}
      setShowPrompt={props.setModal}
      onPrimaryClick={()=> {
        onClickFollowUp(props.leadId, props.leadDetail.status)
      }}
      onCancelClick={()=> {
        props.setModal(false)
      }}
      localLoading={loadingFollowUp}/>
  )
})
