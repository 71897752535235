import { PaginationRequestBody } from "../models/pagination";
import { GroupCodeListObject, GroupCodeAddObject, GroupCodeUpdateObject, GroupCodeDetailObject, GroupCodeGetObject } from "../models/groupCode";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class GroupCodeStore {
  groupCodeList: GroupCodeListObject[] = [];
  groupCodeDropdownList: GroupCodeListObject[] = [];
  groupCodeDetail: GroupCodeDetailObject | undefined = undefined;
  groupCodePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.groupCodeList = [];
    this.groupCodeDropdownList = [];
    this.groupCodeDetail = undefined;
    this.groupCodePaginationParams= undefined;
  }

  setGroupCodeDetail = (groupCodeDetail: GroupCodeDetailObject | undefined) => {
    this.groupCodeDetail = groupCodeDetail;
  }

  setGroupCodePaginationParams = (groupCodePaginationParams: PaginationRequestBody | undefined) => {
    this.groupCodePaginationParams = groupCodePaginationParams;
  }

  getGroupCode = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setGroupCodePaginationParams(PaginationRequestBody);
    try{
      const resultGroupCode = await agent.GroupCode.groupCodeList(PaginationRequestBody);
      runInAction(() => {
        this.groupCodeList = resultGroupCode.data;
        store.commonStore.setTotalItem(resultGroupCode.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.groupCodeList = [];
    }
  }

  getGroupCodeDropDown = async (groupCodeRequestBody: GroupCodeGetObject) => {
    try{
      const resultGroupCodeDropdown = await agent.GroupCode.groupCodeDropdown(groupCodeRequestBody);
      runInAction(() => {
        this.groupCodeDropdownList = resultGroupCodeDropdown;
      });
      return Promise.resolve(resultGroupCodeDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.groupCodeDropdownList = [];
    }
  }

  getGroupCodeWithId = async (id: string) => {
    try{
      const resultGroupCodesDetail = await agent.GroupCode.groupCodeDetail(id);
      runInAction(() => {
        this.groupCodeDetail = resultGroupCodesDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.groupCodeDetail = undefined;
    }
  }

  addGroupCode = async (groupCodeRequestBody: GroupCodeAddObject) => {
    try{
      await agent.GroupCode.addGroupCode(groupCodeRequestBody);
      store.commonStore.setSuccessMessage(`GroupCodeAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateGroupCode = async (groupCodeRequestBody: GroupCodeUpdateObject) => {
    try{
      await agent.GroupCode.updateGroupCode(groupCodeRequestBody);
      store.commonStore.setSuccessMessage(`GroupCodeUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteGroupCode  = async (id: string, name: string) => {
    try {
      await agent.GroupCode.deleteGroupCode(id);
      store.commonStore.setSuccessMessage(`GroupCodeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}