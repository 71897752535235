import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import moment from "moment";
import { checkPermission, returnPriceWithCurrency, getBranchId, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, returnAdvancedSearchText, returnSubBrandList, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import MyButton from "../../app/components/form/MyButton";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { SubBrandObject } from "../../app/models/brand";

const InvoiceFromSalesOrder = () => {
  const intl = useIntl();
  //Use Store
  const { invoiceStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { invoiceFromSalesOrderList, invoiceFromSalesOrderPaginationParams, getInvoiceFromCustomerSalesOrder, setInvoiceFromSalesOrderPaginationParams } = invoiceStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;

  //Data List
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerSalesOrderNo: "",
      customerInvoiceNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let invoiceRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: blnAdvancedSearch ? "" : getBranchId(),
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    customerInvoiceNo: validation.values.customerInvoiceNo,
    customerSalesOrderNo: validation.values.customerSalesOrderNo,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId
  }

  useEffect(() => {
    async function fetchInvoiceListAPI() {
      setLocalLoading(true)
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.invoice)
      if (blnBackNavigation) {
        if (invoiceFromSalesOrderPaginationParams) {
          setCurrentPage(invoiceFromSalesOrderPaginationParams.pageNumber);
          setSelectedCustomer(invoiceFromSalesOrderPaginationParams.customerName || "");
          setSelectedCustomerId(invoiceFromSalesOrderPaginationParams.customerId || "");
          setSelectedSubBrandName(invoiceFromSalesOrderPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(invoiceFromSalesOrderPaginationParams.subBrandId || " ");
          validation.setValues((values) => ({
            ...values,
            customerSalesOrderNo: invoiceFromSalesOrderPaginationParams.customerSalesOrderNo || "",
            customerInvoiceNo: invoiceFromSalesOrderPaginationParams.customerInvoiceNo || "",
          }))
          invoiceRequestBody = invoiceFromSalesOrderPaginationParams;
        }
      }
      else {
        setInvoiceFromSalesOrderPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchInvoiceAPI(false)
      ];
      await Promise.all(aryAPI);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerInvoice], true)) {
      return;
    }

    fetchInvoiceListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.invoice)
        if (!blnSetParams) {
          setInvoiceFromSalesOrderPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchInvoiceAPI(true);
    }
  }, [triggerSearch, selectedSubBrandId])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchInvoiceAPI(true);
    }
  }, [selectedCustomerId])

  async function fetchInvoiceAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getInvoiceFromCustomerSalesOrder(invoiceRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const InvoiceColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerInvoiceNo",
      text: intl.formatMessage({ id: "InvoiceNo" }).toUpperCase(),
      style: {
        width: "15%"
      }
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomerSalesOrder
        ?
        <div className="">
          <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="">{row.customerSalesOrderNo}</div>
      ),
    },
    {
      dataField: "orderDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.orderDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.orderDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div className="text-center">
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="text-center">{row.customerName}</div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      hidden: returnSubBrandList().length === 0,
      formatter: (cellContent, row) => (
        <div className="text-center">
          {newSpaceBeforeCapitalLetter(row.subBrandName)}
        </div>
      )
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{row.salesOrderStatus === Constants.void ? Constants.emptyData : returnPriceWithCurrency(row.totalAmount)}</div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.invoice}/view/${row.id}`}
              type="view"/>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Invoice" }) })}
        title={intl.formatMessage({ id: "Invoice" })}>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <Col xl={'4'} md={'6'} xs={'12'}>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    labelField="name"
                    valueField="id"
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    onChange={(value) => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "InvoiceNo" })}
                  name="customerInvoiceNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterInvoiceNo" })}
                  onBlurFunction={() => {
                    onClickSearch()
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="customerSalesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                  onBlurFunction={() => {
                    onClickSearch()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                      setBlnAdvancedSearch(false);
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
              <Col xl={'4'} md={'12'} xs={'12'}>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-12"}`}
                  content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={InvoiceColumns}
              data={invoiceFromSalesOrderList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Invoice" }) })}
              objSorted={defaultSorted}
              branchId={blnAdvancedSearch ? "" : getBranchId()}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              customerSalesOrderNo={validation.values.customerSalesOrderNo}
              customerInvoiceNo={validation.values.customerInvoiceNo}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName}
              requestAPI={getInvoiceFromCustomerSalesOrder} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          blnHideAccessGranted={true}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
}

export default observer(InvoiceFromSalesOrder);
