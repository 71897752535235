import { PaginationRequestBody } from "../models/pagination";
import { PaymentMethodOptionListObject, PaymentMethodOptionAddObject, PaymentMethodOptionUpdateObject, PaymentMethodOptionDetailObject } from "../models/paymentMethodOption";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class paymentMethodOptionStore {
  paymentMethodOptionList: PaymentMethodOptionListObject[] = [];
  paymentMethodOptionDropdownList: PaymentMethodOptionListObject[] = [];
  paymentMethodOptionDetail: PaymentMethodOptionDetailObject | undefined = undefined;
  paymentMethodOptionPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.paymentMethodOptionList = [];
    this.paymentMethodOptionDropdownList = [];
    this.paymentMethodOptionDetail = undefined;
    this.paymentMethodOptionPaginationParams = undefined;
  }

  setPaymentMethodOptionDetail = (paymentMethodOptionDetail: PaymentMethodOptionDetailObject | undefined) => {
    this.paymentMethodOptionDetail = paymentMethodOptionDetail;
  }

  setPaymentMethodOptionPaginationParams = (paymentMethodOptionPaginationParams: PaginationRequestBody | undefined) => {
    this.paymentMethodOptionPaginationParams = paymentMethodOptionPaginationParams;
  }
  
  getPaymentMethodOption = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setPaymentMethodOptionPaginationParams(PaginationRequestBody);
    try{
      const resultPaymentMethodOption = await agent.PaymentMethodOption.paymentMethodOptionList(PaginationRequestBody);
      runInAction(() => {
        this.paymentMethodOptionList = resultPaymentMethodOption.data;
        store.commonStore.setTotalItem(resultPaymentMethodOption.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodOptionList = [];
    }
  }

  getPaymentMethodOptionDropdown = async (paymentMethodId: string) => {
    try{
      const resultPaymentMethodOptionDropdown = await agent.PaymentMethodOption.paymentMethodOptionDropdownList(paymentMethodId);
      runInAction(() => {
        this.paymentMethodOptionDropdownList = resultPaymentMethodOptionDropdown;
      });
      return Promise.resolve(resultPaymentMethodOptionDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodOptionDropdownList = [];
    }
  }

  getPaymentMethodOptionWithId = async (id: string) => {
    try{
      const resultPaymentMethodOptionDetail = await agent.PaymentMethodOption.paymentMethodOptionDetail(id);
      runInAction(() => {
        this.paymentMethodOptionDetail = resultPaymentMethodOptionDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentMethodOptionDetail = undefined;
    }
  }

  addPaymentMethodOption = async (paymentMethodOptionRequestBody: PaymentMethodOptionAddObject) => {
    try{
      await agent.PaymentMethodOption.addPaymentMethodOption(paymentMethodOptionRequestBody);
      store.commonStore.setSuccessMessage(`PaymentMethodOptionAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePaymentMethodOption = async (paymentMethodOptionRequestBody: PaymentMethodOptionUpdateObject) => {
    try{
      await agent.PaymentMethodOption.updatePaymentMethodOption(paymentMethodOptionRequestBody);
      store.commonStore.setSuccessMessage(`PaymentMethodOptionUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePaymentMethodOption  = async (id: string, name: string) => {
    try {
      await agent.PaymentMethodOption.deletePaymentMethodOption(id);
      store.commonStore.setSuccessMessage(`PaymentMethodOptionDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}