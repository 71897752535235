import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { history } from "../../..";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  blnShow: boolean;
  setModal: Function;
	disabledCloseFunction: boolean;
}

export default observer(function PendingExchangeToCreditModal(props: Props) {
  const intl = useIntl();

	return (
		<div>
			<Modal 
        isOpen={props.blnShow} 
        toggle={() => {
          if (!props.disabledCloseFunction) {
            props.setModal(false);
          }
        }}
        centered={true}>
        {
          !props.disabledCloseFunction
          &&
          <button
            type="button"
            onClick={() => { props.setModal(false)}}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>
								<h4 style={{whiteSpace:"pre-wrap"}}>
									{intl.formatMessage({ id: "PendingExchangeToCreditAlert" })}
								</h4>
							</div>
						</Col>
					</Row>
          <div className={`text-center flex-wrap mt-4 mb-2`}>
						<MyButton
							type="button"
							content={intl.formatMessage({ id: "CreditRedemption" })}
							class="btn btn-primary btn-lg me-2"
							onClick={() => {
								history.push(`/${RoutesList.creditRedemption}`);
							}}/>
          </div>
				</ModalBody>
			</Modal>
		</div>
	);
})
