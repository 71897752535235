import { useEffect, useState } from "react";
import {Modal} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import { AppolousCustomerPackageStatusUpdateRequestBody } from "../../../../app/models/appolousCustomerPackage";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId?: string;
  currentPage?: number;
  setCurrentPage?: Function;
  statusUpdateFunction: Function;
  getAppolousCustomerPackages?: Function;
  selectedAppolousCustomerPackageDetail: any;
  setSelectedAppolousCustomerPackageDetail: Function;
  setErrorMessage?: Function;
  setLoadingAppolousCustomerPackage?: Function;
}

export default function AppolousCustomerPackageStatusUpdateModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [localLoadingAppolousCustomerPackage, setLocalLoadingAppolousCustomerPackage] = useState(false);
  const disabledFieldInput = localLoadingAppolousCustomerPackage || Boolean(successMessage); 

  const defaultAppolousCustomerPackageDetail = {
    appolousCustomerPackageId: "",
    appolousCustomerPackageName: "",
    action: "Close"
  }

  const [appolousCustomerPackageDetail, setAppolousCustomerPackageDetail] = useState(defaultAppolousCustomerPackageDetail)
  const blnCloseAction = appolousCustomerPackageDetail.action === Constants.close;
  
  useEffect(() => {
    if (props.selectedAppolousCustomerPackageDetail) {
      setAppolousCustomerPackageDetail(props.selectedAppolousCustomerPackageDetail);
    }
  }, [props.selectedAppolousCustomerPackageDetail])

  useEffect(() => {
    async function getAppolousCustomerPackageList() {
      if(props.setLoadingAppolousCustomerPackage){
        props.setLoadingAppolousCustomerPackage(true);
        if(props.getAppolousCustomerPackages){
          await props.getAppolousCustomerPackages({ pageNumber: props.currentPage, pageSize: Constants.maxPageSize, customerId: props.customerId });
        }
        props.setLoadingAppolousCustomerPackage(false);
      }
    }

    if (successMessage) {
      hideModal();
      getAppolousCustomerPackageList();
    }
  }, [successMessage])

  const hideModal = () => {
    setAppolousCustomerPackageDetail(defaultAppolousCustomerPackageDetail);
    props.setSelectedAppolousCustomerPackageDetail(undefined);
    props.setModal(false);
  }

  const onClickStatusUpdateAppolousCustomerPackage = async (appolousCustomerPackageDetailTemp: AppolousCustomerPackageStatusUpdateRequestBody) => {
    setLocalLoadingAppolousCustomerPackage(true);
    await props.statusUpdateFunction(appolousCustomerPackageDetailTemp);
    setLocalLoadingAppolousCustomerPackage(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!localLoadingAppolousCustomerPackage && !successMessage) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0" style={{paddingRight: '18px'}}>{intl.formatMessage({ id: "StatusUpdateAppolousCustomerPackageAlertTitle" }, {action: appolousCustomerPackageDetail.action})}</h5>
          {
            !localLoadingAppolousCustomerPackage && !successMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <h3 className="font-size-15 font-weight-bold">
            {intl.formatMessage({ id: "StatusUpdateAppolousCustomerPackageAlertMessage" }, {action: appolousCustomerPackageDetail.action.toLowerCase()})}
            {`(${appolousCustomerPackageDetail.appolousCustomerPackageName}) ?`}
          </h3>
          <div className="mt-4 float-end">
            {
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class={`btn btn-success margin-right-8`}
                style={{width: "80px"}}
                content={intl.formatMessage({ id: "Yes" })}
                onClick={() => {
                  onClickStatusUpdateAppolousCustomerPackage(appolousCustomerPackageDetail)
                }}
                loading={localLoadingAppolousCustomerPackage}
                disable={disabledFieldInput}
              />
            }
            {
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class={`btn btn-danger`}
                style={{width: "80px"}}
                content={intl.formatMessage({ id: "No" })}
                onClick={() => {
                  hideModal();
                }}
                loading={localLoadingAppolousCustomerPackage}
                disable={disabledFieldInput}
              />
            }
          </div>
        </div>
      </Modal>
  )
}