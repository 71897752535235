import { PaginationRequestBody } from "../models/pagination";
import { DepartmentListObject, DepartmentAddObject, DepartmentUpdateObject, DepartmentDetailObject, DepartmentDropdownListObject } from "../models/department";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class departmentStore {
  departmentList: DepartmentListObject[] = [];
  departmentDropdownList: DepartmentDropdownListObject[] = [];
  departmentDetail: DepartmentDetailObject | undefined = undefined;
  departmentPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.departmentList = [];
    this.departmentDropdownList = [];
    this.departmentDetail = undefined;
    this.departmentPaginationParams= undefined;
  }

  setDepartmentDetail = (departmentDetail: DepartmentDetailObject | undefined) => {
    this.departmentDetail = departmentDetail;
  }

  setDepartmentDropdown = (departmentDropdownList: DepartmentDropdownListObject[]) => {
    this.departmentDropdownList = departmentDropdownList;
  }

  setDepartmentPaginationParams = (departmentPaginationParams: PaginationRequestBody | undefined) => {
    this.departmentPaginationParams = departmentPaginationParams;
  }

  getDepartment = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setDepartmentPaginationParams(PaginationRequestBody);
    try {
      const resultDepartment = await agent.Department.departmentList(PaginationRequestBody);
      runInAction(() => {
        this.departmentList = resultDepartment.data;
        store.commonStore.setTotalItem(resultDepartment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.departmentList = [];
    }
  }

  getDepartmentDropdown = async () => {
    try{
      const resultDepartmentDropdown = await agent.Department.departmentDropdownList();
      runInAction(() => {
        this.departmentDropdownList = resultDepartmentDropdown;
      });
      return Promise.resolve(resultDepartmentDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.departmentDropdownList = [];
      return Promise.resolve([]);
    }
  }

  getDepartmentWithId = async (id: string) => {
    try {
      const resultDepartmentDetail = await agent.Department.departmentDetail(id);
      runInAction(() => {
        this.departmentDetail = resultDepartmentDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.departmentDetail = undefined;
    }
  }

  addDepartment = async (departmentRequestBody: DepartmentAddObject) => {
    try {
      await agent.Department.addDepartment(departmentRequestBody);
      store.commonStore.setSuccessMessage(`DepartmentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateDepartment = async (departmentRequestBody: DepartmentUpdateObject) => {
    try {
      await agent.Department.updateDepartment(departmentRequestBody);
      store.commonStore.setSuccessMessage(`DepartmentUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteDepartment = async (id: string, name: string) => {
    try {
      await agent.Department.deleteDepartment(id);
      store.commonStore.setSuccessMessage(`DepartmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}