import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getBrandId, returnPriceWithCurrency, totalCountStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import moment from "moment";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { Link } from "react-router-dom";

const WithoutAppointment = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { withoutAppointmentList, getWithoutAppointmentList, setWithoutAppointmentList, exportCustomerWithoutAppointment } = reportCustomerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedConsultantName, setSelectedConsultantName] = useState("");
  const [selectedConsultantId, setSelectedConsultantId] = useState("");
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [loadingBranch, setLoadingBranch] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCollectionsSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: selectedBranchId, isBranchStaff: true }),
      ];

      await Promise.all(aryAPI);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setWithoutAppointmentList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerAndConsultanttDropdownFunction() {
      setLoadingBranch(true);
      setSelectedConsultantId("");
      setSelectedConsultantName("");
      let aryAPI = [
        getEmployeeDropdown({ brandId: getBrandId(), branchId: selectedBranchId, isBranchStaff: true }),
      ]
      await Promise.all(aryAPI);
      setLoadingBranch(false);
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerAndConsultanttDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchCustomerWithoutAppointmentWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportCustomerWithoutAppointment({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, consultantId: selectedConsultantId })
    }
    else { 
      setCurrentPage(1);
      await getWithoutAppointmentList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, consultantId: selectedConsultantId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const CollectionsSummaryDetailsColumns = [
    {
      dataField: "customerId", //field name from array to display
      text: "customerId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.consultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "totalAppointments",
      text: intl.formatMessage({ id: "TotalAppointments" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.totalAppointments}
          statusColorFunction={totalCountStatusColor}/>
      ),
    },
    {
      dataField: "totalPackages",
      text: intl.formatMessage({ id: "TotalPackages" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.totalPackages}
          statusColorFunction={totalCountStatusColor}/>
      ),
    },
    {
      dataField: "lastVisitDate",
      text: intl.formatMessage({ id: "LastVisitDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastVisitDate !== Constants.invalidDateTime ? moment(row.lastVisitDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastVisitDate !== Constants.invalidDateTime ? moment(row.lastVisitDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerWithoutNextAppointment" }) })}
        title={intl.formatMessage({ id: "CustomerWithoutNextAppointment" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCustomerWithoutAppointmentWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Customer-Without-Next-Appointment.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownList}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            {
              loadingBranch
                ?
                <div />
                :
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Consultant" })}
                  name={"consultantId"}
                  options={employeeDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedConsultantName}
                  initialValue={selectedConsultantId}
                  setFieldLabel={setSelectedConsultantName}
                  setFieldValue={setSelectedConsultantId}
                  disabled={localLoading || loading}/>
            }
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading || loadingBranch}
              disable={localLoading || loading || loadingBranch}
              onClick={() => {
                fetchCustomerWithoutAppointmentWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={CollectionsSummaryDetailsColumns}
                data={withoutAppointmentList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerWithoutNextAppointment" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                consultantId={selectedConsultantId}
                keyField={"randomId"}
                requestAPI={getWithoutAppointmentList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(WithoutAppointment);
