import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Nav, NavItem, NavLink } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { burgerMenu, ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import { StateObject } from "../../app/models/country";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";

const CountryDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { countryStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addCountry, updateCountry, countryDetail, setCountryDetail, getCountryWithId } = countryStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Country" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Country" }), urlPath: RoutesList.country }];
  const [activeTab, setActiveTab] = useState("1");
  const tabBar = [intl.formatMessage({ id: "States" }).toUpperCase()];
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [{ label: intl.formatMessage({ id: "Name" }).toUpperCase(), type: "input", xl: "4", inputName: "name", inputType: "text" }, { label: intl.formatMessage({ id: "Abbreviation" }).toUpperCase(), type: "input", xl: "4", inputName: "abbreviation", inputType: "text" }, { label: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(), type: "input", xl: "4", textAlign: true, inputName: "displayOrder", inputType: "number" }];
  const fieldsOfState: StateObject = { name: "", abbreviation: "", displayOrder: 1 };
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateCountry = checkPermission([PermissionConstants.UpdateCountry]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: countryDetail || {
      name: "",
      twoLetterIsoCode: "",
      threeLetterIsoCode: "",
      numericIsoCode: undefined,
      callingCode: "",
      states: [],
      displayOrder: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CountryName" }) })),
      twoLetterIsoCode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TwoLetterIsoCode" }) })),
      threeLetterIsoCode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ThreeLetterIsoCode" }) })),
      numericIsoCode: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "NumbericIsoCode" }) })),
      callingCode: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CallingCode" }) })),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (addAction) {
          await addCountry(valuesTemp);
        } else {
          await updateCountry(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchCountryDetailAPI() {
      setLoading(true);
      if (id && !addAction) {
        await getCountryWithId(id);
      }
      setLoading(false);
    }

    if (addAction) {
      setCountryDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCountry], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCountry], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCountry], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateCountry){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.country}/edit/${id}`)} })
    }

    fetchCountryDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCountryDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Country" }) })}
                  h4Title />
                <GeneralInput
                  title={intl.formatMessage({ id: "CountryName" })}
                  name="name"
                  className="mb-3 mt-4"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "TwoLetterIsoCode" })}
                  name="twoLetterIsoCode"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "ThreeLetterIsoCode" })}
                  name="threeLetterIsoCode"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "NumbericIsoCode" })}
                  name="numericIsoCode"
                  type="number"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "CallingCode" })}
                  name="callingCode"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <GeneralInput
                  title={intl.formatMessage({ id: "DisplayOrder" })}
                  name="displayOrder"
                  type="number"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
              </Col>
              <Col xl={7}>
                <Nav tabs className="">
                  {tabBar.map((value, indexTab) => (
                    <NavItem
                      key={`tabkey_${indexTab}`}
                    >
                      <NavLink
                        style={{
                          cursor: "pointer",
                        }}
                        className={classNames({
                          active:
                            activeTab ===
                            String(
                              indexTab + 1
                            ),
                        })}
                        onClick={() => {
                          toggle(
                            String(
                              indexTab + 1
                            )
                          );
                        }}
                      >
                        {value}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                {activeTab === "1" && (
                  <TableWithEditableInputFields
                    name="states"
                    title="States includes in this country"
                    buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "State" }) })}
                    blnEditable={!viewAction}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validation.values.states}
                    inputField={fieldsOfState}
                    validation={validation}
                    disabled={disabledFieldInput} />
                )}
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(CountryDetail);
