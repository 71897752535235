import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, replaceAllStringWithArray, returnThousandSeperatorInString } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const ProductCreditCommissionSettingsDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { commissionSchemeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { commissionSchemeProductCreditDetail, getCommisionSchemeProductCreditWithId, addCommissionSchemeProductCredit, updateCommissionSchemeProductCredit, setCommissionSchemeProductCreditDetail } = commissionSchemeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "ProductCreditCommissionSettings" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: RoutesList.productCreditCommissionSettings }, { title: intl.formatMessage({ id: "ProductCreditCommissionSettings" }), urlPath: RoutesList.productCreditCommissionSettings }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateProductCreditCommissionSettings = checkPermission([PermissionConstants.UpdateProductCreditCommissionScheme]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: commissionSchemeProductCreditDetail || {
      name: "",
      creditAmountFrom: "",
      creditAmountTo: "",
      percentage: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Name" }) })),
      creditAmountFrom: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CreditAmountFrom" }) })),
      creditAmountTo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CreditAmountTo" }) })),
      percentage: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Percentage" }) }))
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      validateNumberFieldWithSeparator(valuesTemp);

      try {
        if (addAction) {
          await addCommissionSchemeProductCredit(valuesTemp);
        } 
        else {
          await updateCommissionSchemeProductCredit(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchProductCreditCommissionSettingsDetailAPI() {
      setLoading(true);
      let aryAPI: any = [];
      if (id && !addAction) {
        aryAPI.push(getCommisionSchemeProductCreditWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setCommissionSchemeProductCreditDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateProductCreditCommissionScheme], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateProductCreditCommissionScheme], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageProductCreditCommissionScheme], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateProductCreditCommissionSettings) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.productCreditCommissionSettings}/edit/${id}`) } })
    }

    fetchProductCreditCommissionSettingsDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCommissionSchemeProductCreditDetail(undefined);
      }
    })
  }, []);
  
  useEffect(() => {
    if (commissionSchemeProductCreditDetail) {
      validation.setFieldValue("creditAmountFrom", returnThousandSeperatorInString(commissionSchemeProductCreditDetail.creditAmountFrom.toString()));
      validation.setFieldValue("creditAmountTo", returnThousandSeperatorInString(commissionSchemeProductCreditDetail.creditAmountTo.toString()));
    }
  }, [commissionSchemeProductCreditDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const validateNumberFieldWithSeparator = (productCreditCommissionSettingsDetailTemp: any) => {
    if(productCreditCommissionSettingsDetailTemp.creditAmountFrom.includes(",")){
      let creditAmountFromTemp = removeThousandSeperator(productCreditCommissionSettingsDetailTemp.creditAmountFrom);
      productCreditCommissionSettingsDetailTemp.creditAmountFrom = creditAmountFromTemp;
    }
    if(productCreditCommissionSettingsDetailTemp.creditAmountTo.includes(",")){
      let creditAmountToTemp = removeThousandSeperator(productCreditCommissionSettingsDetailTemp.creditAmountTo);
      productCreditCommissionSettingsDetailTemp.creditAmountTo = creditAmountToTemp;
    }
    return productCreditCommissionSettingsDetailTemp;
  }

  const removeThousandSeperator = (value) =>{
    return Number(replaceAllStringWithArray(value, [','], ""));
  }
  
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ProductCreditCommissionSettings" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Name" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "CreditAmountFrom" })}
                      name="creditAmountFrom"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.creditAmountFrom){
                          let creditAmountFromTemp: any = replaceAllStringWithArray(validation.values.creditAmountFrom, [','], "");
                          const formattedValue = returnThousandSeperatorInString(creditAmountFromTemp);
                          validation.setFieldValue("creditAmountFrom", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "CreditAmountTo" })}
                      name="creditAmountTo"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.creditAmountTo){
                          let creditAmountToTemp: any = replaceAllStringWithArray(validation.values.creditAmountTo, [','], "");
                          const formattedValue = returnThousandSeperatorInString(creditAmountToTemp);
                          validation.setFieldValue("creditAmountTo", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={`${intl.formatMessage({ id: "Percentage" })} (%)`}
                    name="percentage"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ProductCreditCommissionSettingsDetail);
