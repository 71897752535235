import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import _ from 'lodash';
import { history } from "../..";
import * as Yup from "yup";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { PromotionListObject } from "../../app/models/promotion";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter, returnSwitchTabButtonUI } from "../../app/common/function/function";
import moment from "moment";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";

const Promotion = () => {
  const intl = useIntl();
  //Use Store
  const { promotionStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setSuccessMessage, setErrorMessage, windowSize } = commonStore;
  const { promotionList, promotionPaginationParams, getPromotion, deletePromotion, setPromotionPaginationParams } = promotionStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<PromotionListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreatePromotion = checkPermission([PermissionConstants.CreatePromotion]);
  const blnPermissionUpdatePromotion = checkPermission([PermissionConstants.UpdatePromotion]);
  const blnPermissionDeletePromotion = checkPermission([PermissionConstants.DeletePromotion]);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      promotionCode: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let promotionRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    promotionCode: validation.values.promotionCode,
    ...!blnAllActive && {isActive: blnIsActive}
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchPromotionAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.promotion)
      if (blnBackNavigation) {
        if (promotionPaginationParams) {
          setCurrentPage(promotionPaginationParams.pageNumber)
          validation.setValues((values)=> ({
            ...values,
            promotionCode: promotionPaginationParams.promotionCode || ""
          }))
          if(promotionPaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(promotionPaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          promotionRequestBody = promotionPaginationParams;
        }
      }
      else {
        setPromotionPaginationParams(undefined);
      }

      await getPromotion(promotionRequestBody)
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManagePromotion], true)) {
      return;
    }

    fetchPromotionAPI();
    
    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.promotion)
        if (!blnSetParams) {
          setPromotionPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchPromotionAPI(true);
    }
  }, [triggerSearch, blnAllActive, blnIsActive])

  async function fetchPromotionAPI(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getPromotion(promotionRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: PromotionListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const PromotionColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "code",
      text: intl.formatMessage({ id: "PromotionCode" }).toUpperCase(),
      style: {
        width: '15%'
      },
    },
    {
      dataField: "desc",
      text: intl.formatMessage({ id: "Description" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.desc ? row.desc : Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "StartBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.startDate !== Constants.invalidDateTime ? moment(row.startDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
        </>
      ),
    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "EndBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.endDate !== Constants.invalidDateTime ? moment(row.endDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
        </>
      ),
    },
    {
      dataField: "promotionType",
      text: intl.formatMessage({ id: "PromotionType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {newSpaceBeforeCapitalLetter(row.promotionType)}
        </div>
      ),
    },
    {
      dataField: "isForStaffOnly",
      text: intl.formatMessage({ id: "StaffOnly" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isForStaffOnly ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isForSponsorProfileOnly",
      text: intl.formatMessage({ id: "SponsoredProfile" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isForSponsorProfileOnly ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManagePromotion, PermissionConstants.UpdatePromotion, PermissionConstants.DeletePromotion]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.promotion}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdatePromotion
              &&
              <TableActionButton 
                to={`/${RoutesList.promotion}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeletePromotion
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }
  
  const blnViewAllFunction = () => {
    setCurrentPage(1);
    setBlnAllActive(true);
  }

  const blnActiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(true);
    setBlnAllActive(false);
  }

  const blnInactiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(false);
    setBlnAllActive(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Promotion" }) })}
        title={intl.formatMessage({ id: "Promotion" })}
        addButton={
          blnPermissionCreatePromotion
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Promotion" }) })}
            onClick={() => { history.push(`/${RoutesList.promotion}/add`) }}
            linkTo={`/${RoutesList.promotion}/add`}
            disable={loading} />
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "PromotionCode" })}
              name="promotionCode"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterPromotionCode" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28": ""} ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "" : "margin-bottom-4"}`}>           
            <div className="btn-group" role="group">
              {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), blnActiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "70px" : "")}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), blnInactiveOnlyFunction, 1790 > windowSize.innerWidth && windowSize.innerWidth > 1200 ? "90px" : "")}
            </div>
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading />
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={PromotionColumns}
            data={promotionList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Promotion" }) })}
            objSorted={defaultSorted}
            promotionCode={validation.values.promotionCode}
            isActive={!blnAllActive ? blnIsActive : undefined}
            requestAPI={getPromotion} />
        }
        <DeleteModal
          displayName={selectedRow?.code}
          onDeleteClick={async () => {
            setLoading(true);
            await deletePromotion(selectedRow?.id || "", selectedRow?.code || "");
            let currentPageTemp = currentPage;
            if (promotionList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getPromotion({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Promotion);
