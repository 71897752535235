import React, { useState, useEffect } from "react";
import { Col, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { batchJobHistoryStatusColor, escapedNewLineToLineBreakTag } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import { BatchJobHistoryDetailObject } from "../../../app/models/batchJob";
import GeneralInput from "../../../app/components/form/GeneralInput";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralTextArea from "../../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedBatchJobHistoryDetail: BatchJobHistoryDetailObject | undefined;
  setSelectedBatchJobHistoryDetail: Function;
}

export default observer(function BatchJobHistoryDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedBatchJobHistoryDetail(undefined);
  }

  useEffect(() => {
    if (props.selectedBatchJobHistoryDetail) {
      validationLocal.setFieldValue("batchJobName", props.selectedBatchJobHistoryDetail.batchJobName || Constants.emptyData)
      validationLocal.setFieldValue("executeDateTime", moment(props.selectedBatchJobHistoryDetail.executeDateTime).format(Constants.displayDateAndTimeFormat))
      validationLocal.setFieldValue("exceptionMessage", props.selectedBatchJobHistoryDetail.exceptionMessage || Constants.emptyData)
      validationLocal.setFieldValue("logMessage", escapedNewLineToLineBreakTag(props.selectedBatchJobHistoryDetail.logMessage) || Constants.emptyData)
    }
  }, [props.selectedBatchJobHistoryDetail])

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
			id: "",
			batchJobId: "",
			batchJobName: "",
			executeDateTime: "",
			jobStatus: "",
			exceptionMessage: "",
      logMessage: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="lg"
        centered>
        {
          !props.selectedBatchJobHistoryDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-direction-row" style={{flex:1 }}>
                  <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "BatchJobHistoryDetail" })}</h5>
                  <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedBatchJobHistoryDetail.jobStatus}
                    statusColorFunction={batchJobHistoryStatusColor}/>
                </div>
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Name" })}
                      name="batchJobName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab>
                    <GeneralInput
                      name="executeDateTime"
                      title={intl.formatMessage({ id: "Date" })}
                      type="text"
                      disabled={true}
                      validationRequired={false}
                      validation={validationLocal}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent blnDoubleTab>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "ExceptionMessage" })}
                    name="exceptionMessage"
                    row={5}
                    disabled={true}
                    validation={validationLocal} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab>
                  <Label>{intl.formatMessage({ id: "LogMessage" })}</Label>
                  <div className="log-message-view">
                    <span>{validationLocal.values.logMessage}</span>
                  </div>
                </SingleColumnRowParent>
              </div>
            </div>
        }
      </Modal>
  )
})