import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, purchasedItemStatusColor, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import ReportExpandRow from "../subView/ReportExpandRow";
import { Link } from "react-router-dom";
import moment from "moment";

const AppolousProductCommissionVariant = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppolousStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { appolousProductCommissionVariantList, getAppolousProductCommissionVariantList, setAppolousProductCommissionVariantList, exportAppolousProductCommissionVariant } = reportAppolousStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportAppolousReport = checkPermission([PermissionConstants.ExportAppolousReport]);

  const aryAppolousProductCommissionVariantSummaryDetail: ObjectForReportRow[] = [
    { label: "Product", value: "productName", type: "string" },
    { label: "Quantity", value: "quantity", type: "string" },
    { label: "UnitPrice", value: "price", type: "currency" },
    { label: "Discount", value: "discount", type: "currency" },
    { label: "TotalPrice", value: "total", type: "currency" },
  ];


  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchAppolousProductCommissionVariant() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppolousReport], true)) {
      return;
    }

    fetchAppolousProductCommissionVariant();

    return (() => {
      setAppolousProductCommissionVariantList([]);
    })
  }, [])

  const fetchAppolousProductCommissionVariantWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportAppolousProductCommissionVariant({       
        pageNumber: 1,
        pageSize: pageSize,
        branchId: selectedBranchId,
        dateRange: selectedDateRange
      });
    }
    else{
      await getAppolousProductCommissionVariantList({ 
        pageNumber: 1,
        pageSize: pageSize,
        branchId: selectedBranchId,
        dateRange: selectedDateRange
      });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const AppolousProductCommissionVariantDetailsColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "performaInvoiceNo",
      text: `${intl.formatMessage({ id: "InvoiceNo" }).toUpperCase()}.`,
      formatter: (cellContent, row) => <>
        <Link to={row.appolousSaleId ? `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.appolousSaleId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.performaInvoiceNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "totalProductAmount",
      text: intl.formatMessage({ id: "TotalProductAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalProductAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "advisableProductCommissionAmount",
      text: intl.formatMessage({ id: "AdvisableProductCommAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.advisableProductCommissionAmount) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "productCommissionAmount",
      text: intl.formatMessage({ id: "ProductCommAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.productCommissionAmount) || Constants.emptyData}
        </div>
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "AppolousProductCommissionVariantSummaryDetails" })}
        rowFieldName={"details"}
        rowColumns={aryAppolousProductCommissionVariantSummaryDetail}
        keyFieldName={"randomId"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppolousProductCommissionVariant" }) })}
        title={intl.formatMessage({ id: "AppolousProductCommissionVariant" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppolousReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchAppolousProductCommissionVariantWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Appolous-Product-Commission-Variant.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchAppolousProductCommissionVariantWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={AppolousProductCommissionVariantDetailsColumns}
                data={appolousProductCommissionVariantList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousProductCommissionVariant" }) })}
                objSorted={defaultSorted}
                expandRow={expandRow}
                keyField={"randomId"}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                requestAPI={getAppolousProductCommissionVariantList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppolousProductCommissionVariant);
