import { PaginationRequestBody } from "../models/pagination";
import { CategoryListObject, CategoryAddObject, CategoryUpdateObject, CategoryDetailObject } from "../models/category";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class categoryStore {
  categoryList: CategoryListObject[] = [];
  categoryDropdownList: CategoryListObject[] = [];
  categoryDetail: CategoryDetailObject | undefined = undefined;
  categoryPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.categoryList = [];
    this.categoryDropdownList = [];
    this.categoryDetail = undefined;
    this.categoryPaginationParams= undefined;
  }

  setCategoryDetail = (categoryDetail: CategoryDetailObject | undefined) => {
    this.categoryDetail = categoryDetail;
  }
  
  setCategoryPaginationParams = (categoryPaginationParams: PaginationRequestBody | undefined) => {
    this.categoryPaginationParams = categoryPaginationParams;
  }

  getCategory = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCategoryPaginationParams(PaginationRequestBody);
    try{
      const resultCategory = await agent.Category.categoryList(PaginationRequestBody);
      runInAction(() => {
        this.categoryList = resultCategory.data;
        store.commonStore.setTotalItem(resultCategory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.categoryList = [];
    }
  }

  getCategoryDropdown = async () => {
    try{
      const resultCategoryDropdown = await agent.Category.categoryDropdownList();
      runInAction(() => {
        this.categoryDropdownList = resultCategoryDropdown;
      });
      return Promise.resolve(resultCategoryDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.categoryDropdownList = [];
    }
  }

  getCategoryWithId = async (id: string) => {
    try{
      const resultCategoryDetail = await agent.Category.categoryDetail(id);
      runInAction(() => {
        this.categoryDetail = resultCategoryDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.categoryDetail = undefined;
    }
  }

  addCategory = async (categoryRequestBody: CategoryAddObject) => {
    try{
      await agent.Category.addCategory(categoryRequestBody);
      store.commonStore.setSuccessMessage(`CategoryAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCategory = async (categoryRequestBody: CategoryUpdateObject) => {
    try{
      await agent.Category.updateCategory(categoryRequestBody);
      store.commonStore.setSuccessMessage(`CategoryUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCategory  = async (id: string, name: string) => {
    try {
      await agent.Category.deleteCategory(id);
      store.commonStore.setSuccessMessage(`CategoryDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}