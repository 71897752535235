import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import SortCaret from '../../app/components/table/SortCaret';
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { BranchListObject } from "../../app/models/branch";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";

const Branch = () => {
  //Use Store
  const { branchStore, commonStore } = useStore();
  const { loading, setLoading, successMessage, errorMessage, totalItem, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { branchList, branchPaginationParams, getBranch, deleteBranch, setBranchPaginationParams } = branchStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<BranchListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const intl = useIntl();
  const blnPermissionCreateBranch = checkPermission([PermissionConstants.CreateBranch]);
  const blnPermissionUpdateBranch = checkPermission([PermissionConstants.UpdateBranch]);
  const blnPermissionDeleteBranch = checkPermission([PermissionConstants.DeleteBranch]);
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let branchRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchName: validation.values.name
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchBranchAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.branch)
      if (blnBackNavigation) {
        if (branchPaginationParams) {
          setCurrentPage(branchPaginationParams.pageNumber)
          validation.setValues((values)=> ({
            ...values,
            name: branchPaginationParams.branchName || "",
          }))
          branchRequestBody = branchPaginationParams;
        }
      }
      else {
        setBranchPaginationParams(undefined);
      }

      await getBranch(branchRequestBody)
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageBranch], true)) {
      return;
    }

    fetchBranchAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.branch)
        if (!blnSetParams) {
          setBranchPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchBranchApi(true);
    }
  }, [triggerSearch])

  const onClickDelete = async (row : BranchListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchBranchApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getBranch(branchRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const BranchesColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({id: "Name"}).toUpperCase(),
    },
    {
      dataField: "businessEntityName",
      text: intl.formatMessage({id: "CompanyName"}).toUpperCase(),
    },
    {
      dataField: "prefix",
      text: "PREFIX",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order}/>,
    },
    {
      dataField: "branchCode",
      text: intl.formatMessage({id: "BranchCode"}).toUpperCase(),
    },
    {
      dataField: "colorCode",
      text: intl.formatMessage({id: "ColorCode"}).toUpperCase(),
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          <div style={{padding: '5px', borderRadius: "1px", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px", width: "36px", height: "14px", backgroundColor: row.colorCode}}/>
        </div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerStyle: { textAlign: 'center' },   
      hidden: !checkPermission([PermissionConstants.ManageBranch, PermissionConstants.UpdateBranch, PermissionConstants.DeleteBranch]), 
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.branch}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateBranch
              &&
              <TableActionButton 
                to={`/${RoutesList.branch}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteBranch
              &&
              <TableActionButton 
                to="#"
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "Branch"})})}
        title={intl.formatMessage({ id: "Branch" })}
        addButton={
          blnPermissionCreateBranch
          &&
          <MyAddButton
          content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({id: "Branch"})})}
          onClick={()=> {history.push(`/${RoutesList.branch}/add`)}}
          linkTo={`/${RoutesList.branch}/add`}
          disable={loading}/>
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterBranchName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
          ?
            <Loading/>
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={BranchesColumns}
              data={branchList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "Branch"})})}
              objSorted={defaultSorted}
              branchName={validation.values.name}
              requestAPI={getBranch}/>
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async ()=> {
            setLoading(true);
            await deleteBranch(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (branchList.length === 1 && currentPage !== 1) {
              setCurrentPage((value)=> value-1)
              currentPageTemp = currentPageTemp-1;
            }
            await getBranch({pageNumber: currentPageTemp, pageSize: pageSize})
            setLoading(false);
          }}/>
      </ListViewLayout>
    </div>
  );
}

export default observer(Branch);
