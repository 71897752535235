import { PaginationRequestBody } from "../models/pagination";
import { MeasurementListObject, MeasurementAddObject, MeasurementUpdateObject, MeasurementDetailObject } from "../models/measurement";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class MeasurementStore {
  measurementList: MeasurementListObject[] = [];
  measurementDropdownList: MeasurementListObject[] = [];
  measurementDetail: MeasurementDetailObject | undefined = undefined;
  measurementPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.measurementList = [];
    this.measurementDropdownList = [];
    this.measurementDetail = undefined;
    this.measurementPaginationParams= undefined;
  }

  setMeasurementDetail = (measurementDetail: MeasurementDetailObject | undefined) => {
    this.measurementDetail = measurementDetail;
  }
  
  setMeasurementPaginationParams = (measurementPaginationParams: PaginationRequestBody | undefined) => {
    this.measurementPaginationParams = measurementPaginationParams;
  }

  getMeasurement = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setMeasurementPaginationParams(PaginationRequestBody);
    try{
      const resultMeasurement = await agent.Measurement.measurementList(PaginationRequestBody);
      runInAction(() => {
        this.measurementList = resultMeasurement.data;
        store.commonStore.setTotalItem(resultMeasurement.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.measurementList = [];
    }
  }

  getMeasurementDropdown = async () => {
    try{
      const resultMeasurementDropdown = await agent.Measurement.measurementDropdownList();
      runInAction(() => {
        this.measurementDropdownList = resultMeasurementDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.measurementDropdownList = [];
    }
  }

  getMeasurementWithId = async (id: string) => {
    try{
      const resultMeasurementDetail = await agent.Measurement.measurementDetail(id);
      runInAction(() => {
        this.measurementDetail = resultMeasurementDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.measurementDetail = undefined;
    }
  }

  addMeasurement = async (measurementRequestBody: MeasurementAddObject) => {
    try{
      await agent.Measurement.addMeasurement(measurementRequestBody);
      store.commonStore.setSuccessMessage(`MeasurementAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateMeasurement = async (measurementRequestBody: MeasurementUpdateObject) => {
    try{
      await agent.Measurement.updateMeasurement(measurementRequestBody);
      store.commonStore.setSuccessMessage(`MeasurementUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteMeasurement  = async (id: string, name: string) => {
    try {
      await agent.Measurement.deleteMeasurement(id);
      store.commonStore.setSuccessMessage(`MeasurementDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}