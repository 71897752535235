import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  AddOptionsValueField,
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName,
  returnCurrency,
  returnExpiredDate,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { StockInForProductDetailsObject } from "../../app/models/stockIn";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import moment from "moment";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const StockInDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockInStore, productStore, warehouseStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addStockIn, updateStockIn, stockInDetail, setStockInDetail, getStockInWithId } = stockInStore;
  const { warehouseDropdownList, getWarehouseDropdown } = warehouseStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockIn" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockIn" }), urlPath: RoutesList.stockIn }];
  const [activeTab, setActiveTab] = useState(1);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  const [productStockInlist, setProductStockInlist] = useState<StockInForProductDetailsObject[]>([]);
  const [productModal, setProductModal] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "productName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "CostPrice" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "costPrice",
        inputType: "number",
        inputGroup: true,
        inputGroupText: `${returnCurrency()} `
      },
      {
        label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "quantity",
        inputType: "number"
      },
      {
        label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "supplierReferenceNo",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "expiryDate",
        inputType: "date",
        // validationRequired: true,
      },
      {
        label: intl.formatMessage({ id: "Remarks" }).toUpperCase(),
        type: "input",
        xl: "2",
        inputName: "remark",
        inputType: "text",
      },
    ];
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockInDetail || {
      warehouseId: "",
      warehouseName: "",
      note: "",
      stockInDetails: [],
      workflowAction: "",
      status: ""
    },
    validationSchema: Yup.object({
      warehouseId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Warehouse" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      let stockInDetailsTemp = _.cloneDeep(valuesTemp.stockInDetails);
      let blnEmptyCostPriceBlock = false;
      let blnEmptyQuantityBlock = false;
      stockInDetailsTemp = stockInDetailsTemp.map((value, index) => {
        if (value.quantity === 0) {
          blnEmptyQuantityBlock = true
        }
        if (value.costPrice === 0) {
          blnEmptyCostPriceBlock = true
        }
      })
      if (blnEmptyQuantityBlock) {
        setErrorMessage("Please input Quantity")
        return;
      }
      if (blnEmptyCostPriceBlock) {
        setErrorMessage("Please input Cost Price")
        return;
      }
      try {
        if (addAction) {
          await addStockIn(valuesTemp);
        } else {
          await updateStockIn(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnStatusProcessing = (validation.values.status === Constants.processing);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnPermissionUpdateStockIn = checkPermission([PermissionConstants.UpdateStockIn]);

  useEffect(() => {
    async function fetchStockInDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getWarehouseDropdown(),
        getProductDropdown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getStockInWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStockInDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockIn], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockIn], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockIn], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateStockIn){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockIn}/edit/${id}`)} })
    }

    fetchStockInDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockInDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    try {
      if (stockInDetail) {
        fetchProductListDowndown(stockInDetail.stockInDetails)
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
  }, [stockInDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onChangeWarehouse = () => {
    fetchProductListDowndown()
  }

  const fetchProductListDowndown = (stockInDetailsTemp?: StockInForProductDetailsObject[]) => {
    let productListTemp: StockInForProductDetailsObject[] = productDropdownList.map((value) => {
      let productIdFound = false;

      if (stockInDetailsTemp) {
        stockInDetailsTemp = stockInDetailsTemp.map((valueStockInDetail) => {
          if (valueStockInDetail.productId === value.id) {
            productIdFound = true;
            return ({ ...valueStockInDetail, expiryDate: moment(valueStockInDetail.expiryDate).format(Constants.displayDateFormat) })
          }
          return valueStockInDetail;
        })
      }

      return ({
        productId: value.id,
        productName: value.name,
        quantity: 0,
        costPrice: 0,
        supplierReferenceNo: "",
        remark: "",
        expiryDate: returnExpiredDate(Constants.textInputDateFormat),
        disabled: productIdFound
      })
    });
    if (!stockInDetailsTemp) {
      validation.setFieldValue("stockInDetails", [])
    } else {
      validation.setFieldValue("stockInDetails", stockInDetailsTemp)
    }
    setProductStockInlist(productListTemp);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisable = () => {
    if (!validation.values.warehouseId) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockIn" }) })}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "StockIn" }) })}
                          h4Title
                        />
                        <SingleColumnRowParent>
                          <DropDownWithTitle
                            name={"warehouseId"}
                            title={intl.formatMessage({ id: "Warehouse" })}
                            className="mb-3 mt-4"
                            specifyReturnFieldName={[
                              {
                                field: "warehouseId",
                                value: "id",
                              }
                            ]}
                            labelField={"name"}
                            valueField={"id"}
                            options={warehouseDropdownList}
                            blnValueWithNewSpace={true}
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={onChangeWarehouse}
                            initialLabel={!addAction ? validation.values.warehouseName : undefined}
                            initialValue={!addAction ? validation.values.warehouseId : undefined}
                            validationRequired={true}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title={intl.formatMessage({ id: "Note" })}
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Products" }) })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="stockInDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={true}
                        blnBtnModalBottom={true}
                        options={productStockInlist}
                        optionsId={"productId"}
                        setOptionsFunction={setProductStockInlist}
                        addButtonFunction={() => setProductModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.stockInDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {blnTab2 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {blnTab1 &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisable()
                        }
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              dirtyDisabled={true}
              showExtraLeftButton={true}
              extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
              extraLeftButtonColor="btn-primary"
              extraLeftButtonFunction={() => {
                validation.setFieldValue("workflowAction", 'Save')
              }}
              hidePrimaryButton={blnStatusProcessing || blnTab1}
              submitButtonTitle={intl.formatMessage({ id: "Submit" })}
              onClickFunction={() => {
                validation.setFieldValue("workflowAction", 'Submit')
              }}
            />
          </Form>
        )}
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={AddOptionsValueField(productStockInlist, "name", "id")}
          setOptionsFunction={setProductStockInlist}
          fieldInput={validation.values.stockInDetails}
          fieldName={"stockInDetails"}
          validation={validation} />
      </DetailViewLayout>
    </div >
  );
};

export default observer(StockInDetail);
