import { PaginationRequestBody } from "../models/pagination";
import { AdditionalFormFieldListObject, AdditionalFormFieldAddObject, AdditionalFormFieldUpdateObject, AdditionalFormFieldDetailObject, AdditionalFormFieldWithFormType } from "../models/additionalFormField";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class additionalFormFieldStore {
  additionalFormFieldList: AdditionalFormFieldListObject[] = [];
  additionalFormFieldFormTypeList: AdditionalFormFieldWithFormType[] = [];
  additionalFormFieldDetail: AdditionalFormFieldDetailObject | undefined = undefined;
  additionalFormFieldPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.additionalFormFieldList = [];
    this.additionalFormFieldFormTypeList = [];
    this.additionalFormFieldDetail = undefined;
    this.additionalFormFieldPaginationParams= undefined;
  }

  setAdditionalFormFieldDetail = (additionalFormFieldDetail: AdditionalFormFieldDetailObject | undefined) => {
    this.additionalFormFieldDetail = additionalFormFieldDetail;
  }

  setAdditionalFormFieldFormTypeList = (additionalFormFieldFormTypeList: AdditionalFormFieldWithFormType[] | []) => {
    this.additionalFormFieldFormTypeList = additionalFormFieldFormTypeList;
  }

  setAdditionalFormFieldPaginationParams = (additionalFormFieldPaginationParams: PaginationRequestBody | undefined) => {
    this.additionalFormFieldPaginationParams = additionalFormFieldPaginationParams;
  }
  
  getAdditionalFormField = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setAdditionalFormFieldPaginationParams(PaginationRequestBody);
    try{
      const resultAdditionalFormField = await agent.AdditionalFormField.additionalFormFieldList(PaginationRequestBody);
      runInAction(() => {
        this.additionalFormFieldList = resultAdditionalFormField.data;
        store.commonStore.setTotalItem(resultAdditionalFormField.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.additionalFormFieldList = [];
    }
  }

  getAdditionalFormFieldDropdown = async (formType: string) => {
    try{
      const resultAdditionalFormFieldDropdown = await agent.AdditionalFormField.additionalFormFieldFormTypeList(formType);
      runInAction(() => {
        this.additionalFormFieldFormTypeList = resultAdditionalFormFieldDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.additionalFormFieldFormTypeList = [];
    }
  }

  getAdditionalFormFieldWithId = async (id: string) => {
    try{
      const resultAdditionalFormFieldDetail = await agent.AdditionalFormField.additionalFormFieldDetail(id);
      runInAction(() => {
        this.additionalFormFieldDetail = resultAdditionalFormFieldDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.additionalFormFieldDetail = undefined;
    }
  }

  addAdditionalFormField = async (additionalFormFieldRequestBody: AdditionalFormFieldAddObject) => {
    try{
      await agent.AdditionalFormField.addAdditionalFormField(additionalFormFieldRequestBody);
      store.commonStore.setSuccessMessage(`AdditionalFormFieldAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateAdditionalFormField = async (additionalFormFieldRequestBody: AdditionalFormFieldUpdateObject) => {
    try{
      await agent.AdditionalFormField.updateAdditionalFormField(additionalFormFieldRequestBody);
      store.commonStore.setSuccessMessage(`AdditionalFormFieldUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteAdditionalFormField  = async (id: string, name: string) => {
    try {
      await agent.AdditionalFormField.deleteAdditionalFormField(id);
      store.commonStore.setSuccessMessage(`AdditionalFormFieldDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}