import { useState } from "react";
import { Modal, FormFeedback } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { isTablet } from "react-device-detect";
import { observer } from "mobx-react-lite";

interface Props {
  displayName?: string;
	onDeleteClick: Function;
}

export default observer(function DeleteModalWithReviewModal(props: Props) {
  const { commonStore } = useStore();
  const { showDeletePrompt , setShowDeletePrompt, setErrorMessage } = commonStore;
  const intl = useIntl();
  
  const [errorState, setErrorState] = useState({remark: false})
  const [remarkDetail, setRemarkDetail] = useState({remark: ""})

  const hideModal = () => {
    setShowDeletePrompt(false);
  }

  return (
    !showDeletePrompt
    ?
    <div />
    :
    <Modal
      isOpen={showDeletePrompt}
      toggle={() => {
        hideModal();
      }}
      size={"lg"}
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0" style={{whiteSpace:"pre-wrap"}}>{intl.formatMessage({ id: "RemarkForDeleteAlert"}, { displayName: props.displayName })}</h5>
        {
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        {
          <>
            <GeneralTextAreaForModal
              title={intl.formatMessage({ id: "Remark" })}
              name="remark"
              className={errorState.remark ? "mb-0" : "mb-3"}
              row={4}
              validationRequired={true}
              field={remarkDetail}
              setFieldValue={setRemarkDetail}
              onChangeFunction={()=> {
                if (errorState.remark) {
                  setErrorState((value) => ({...value, remark: false}))
                }
              }} />
            {errorState.remark ? (
              <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Remark"})})}`}</FormFeedback>
            ) : null}
            <div className={`float-end d-print-none`}>
              <MyButton
                type="button"
                class="btn btn-danger margin-left-8"
                content={intl.formatMessage({ id: "Cancel" })}
                onClick={() => {
                  setShowDeletePrompt(false)
                }}
              />
              <MyButton
                type="button"
                class="btn btn-success margin-left-8"
                content={`Yes, delete it!`}
                onClick={() => {
                  if (!remarkDetail.remark) {
                    setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Remark" }) })}`)
                    setErrorState({remark: true})
                  }
                  else {
                    props.onDeleteClick(remarkDetail.remark);
                    setShowDeletePrompt(false);
                  }
                }}
              />
            </div>
          </>
        }
      </div>
    </Modal>
  )
})