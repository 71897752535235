import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import ActionPromptModal from "./ActionPromptModal";
interface Props {
	title?: string;
  displayName?: string;
	onDeleteClick: () => void;
}

export default observer(function DeleteModal(props: Props) {
  //Use Store
  const { commonStore } = useStore();
  const { showDeletePrompt , setShowDeletePrompt, loading } = commonStore;

	return (
    <ActionPromptModal 
      title={props.title || `Are you sure want to delete this item \n ${props.displayName && '(' + props.displayName + ')'} ?`}
      primaryActionTitle={`Yes, delete it!`}
      showPrompt={showDeletePrompt}
      setShowPrompt={setShowDeletePrompt}
      onPrimaryClick={()=> {props.onDeleteClick()}}
      />
	);
})
