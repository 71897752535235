import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import SortCaret from '../../app/components/table/SortCaret';
import { history } from "../..";
import { ProductImportObject, ProductListObject } from "../../app/models/product";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { AddOptionsValueField, arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, convertArrayToStringDisplay, getBranchId, getBranchUser, returnBlobImage, returnSwitchTabButtonUI } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import MyButton from "../../app/components/form/MyButton";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import Loading from "../../app/components/loading/Loading";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";
import RenameBoxIcon from "mdi-react/RenameBoxIcon";
import AnimationIcon from "mdi-react/AnimationIcon";
import ProductStockOutModal from "./Modal/ProductStockOutModal";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import TagMultipleOutlineIcon from 'mdi-react/TagMultipleOutlineIcon';
import { CategoryListObject } from "../../app/models/category";
import { SalesCategoryListObject } from "../../app/models/salesCategory";
import UpdateServiceConsumeQuantityModal from "./Modal/UpdateServiceConsumeQuantityModal";

const Product = () => {
  const intl = useIntl();
  //Use Store
  const { productStore, staticReferenceStore, categoryStore, salesCategoryStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, totalItem, prevPath, windowSize, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { productList, getProduct, productPaginationParams, deleteProduct, exportProduct, importProduct, setProductPaginationParams, stockOutProduct, updateServiceConsumeQuantity, productTableColumnFromStore, setProductTableColumnFromStore } = productStore;
  const { stockOutProductReasonType, getStaticReferenceWithType } = staticReferenceStore;
  const { getCategoryDropdown } = categoryStore;
  const { getSalesCategoryDropdown } = salesCategoryStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<ProductListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateProduct = checkPermission([PermissionConstants.CreateProduct]);
  const blnPermissionUpdateProduct = checkPermission([PermissionConstants.UpdateProduct]);
  const blnPermissionDeleteProduct = checkPermission([PermissionConstants.DeleteProduct]);
  const blnPermissionUpdateServiceTreatment = checkPermission([PermissionConstants.UpdateServiceTreatment]);
  const blnPermissionCreateStockOutProduct = checkPermission([PermissionConstants.CreateStockOutProduct]);
  const [importFileModal, setImportFileModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [stockOutProductModal, setStockOutProductModal] = useState(false);
  const [updateServiceConsumeQuantityModal, setUpdateServiceConsumeQuantityModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [productTypeList, setProductTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedProductType, setSelectedProductType] = useState<string>(AllType);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<number | string | undefined>(" ");
  const AllCategory = `${intl.formatMessage({ id: "AllCategory" })}`;
  const [categoryList, setCategoryList] = useState<CategoryListObject[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(AllCategory);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string >(" ");
  const AllSalesCategory = `${intl.formatMessage({ id: "AllSalesCategory" })}`;
  const [salesCategoryList, setSalesCategoryList] = useState<SalesCategoryListObject[]>([]);
  const [selectedSalesCategory, setSelectedSalesCategory] = useState<string>(AllSalesCategory);
  const [selectedSalesCategoryId, setSelectedSalesCategoryId] = useState< string>(" ");
  const [blnIsActive, setBlnIsActive] = useState(false);
  const [blnAllActive, setBlnAllActive] = useState(true);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let productRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    productName: validation.values.name,
    sKU: validation.values.sKU,
    productType: selectedProductTypeId,
    productTypeName: selectedProductType,
    categoryId: selectedCategoryId,
    categoryName: selectedCategory,
    salesCategoryId: selectedSalesCategoryId,
    salesCategoryName: selectedSalesCategory,
    branchId: getBranchUser() ? getBranchId() : "",
    ...!blnAllActive && {isActive: blnIsActive}
  }

  useEffect(() => {
    async function fetchProductListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.product)
      if (blnBackNavigation) {
        if (productPaginationParams) {
          setCurrentPage(productPaginationParams.pageNumber);
          setSelectedProductTypeId(productPaginationParams.productType);
          setSelectedProductType(productPaginationParams.productTypeName || AllType);
          setSelectedCategoryId(productPaginationParams.categoryId || "");
          setSelectedCategory(productPaginationParams.categoryName || AllCategory);
          setSelectedSalesCategoryId(productPaginationParams.salesCategoryId || "");
          setSelectedSalesCategory(productPaginationParams.salesCategoryName || AllSalesCategory);
          validation.setValues((values)=> ({
            ...values,
            name: productPaginationParams.productName || "",
            sKU: productPaginationParams.sKU || ""
          }))
          if(productPaginationParams.hasOwnProperty('isActive')){
            setBlnIsActive(productPaginationParams.isActive || false);
            setBlnAllActive(false);
          }
          productRequestBody = productPaginationParams;
        }
      }
      else {
        setProductPaginationParams(undefined);
      }
      let aryAPI: any = [
        getProduct(productRequestBody),
        getStaticReferenceWithType(Constants.stockOutProductReasonType),
        getStaticReferenceWithType(Constants.productType, AllType),
        getCategoryDropdown(),
        getSalesCategoryDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setProductTypeList(resultAPI[2]);
      setCategoryList(arraySpliceInAllValue(resultAPI[3], AllCategory, false, "name", "id"))
      setSalesCategoryList(arraySpliceInAllValue(resultAPI[4], AllSalesCategory, false, "name", "id"))
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageProduct], true)) {
      return;
    }

    fetchProductListAPI()

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.product)
        if (!blnSetParams) {
          setProductPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if ((selectedProductTypeId || selectedProductTypeId === 0 ) && !initialCallAPI) {
      fetchProductApi(true);
    }
  }, [selectedProductTypeId, selectedCategoryId, selectedSalesCategoryId, triggerSearch, blnAllActive, blnIsActive])

  async function fetchProductApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getProduct(productRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: ProductListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  function renderBranchQuantities(aryBranchQuantitiesTemp) {
    return (
      <>
        <div className="branch-quantities-tool-tip-background">
          <h4 className="mb-2 branch-quantities-tool-tip-header-background padding-0-75rem">
            {intl.formatMessage({ id: "Branches" })}
          </h4>
          <div className="branch-quantities-tool-top-content-background font-size-14">
              <ul>
                {aryBranchQuantitiesTemp.map(values => (
                  <li key={values} style={{ textAlign: 'left' }}>{values}</li>
                ))}
              </ul>
          </div>
        </div>
      </>
    )
  }

  //Table Content UI
  const ProductColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    // {
    //   dataField: "photo",
    //   text: "PHOTO",
    //   formatter: (cellContent, row) => <img style={{width: "20px", height: "20px"}} src={row.photoUrl}/>
    // },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "categoryName",
      text: intl.formatMessage({ id: "Category" }).toUpperCase(),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
    },
    {
      dataField: "productTypes",
      text: intl.formatMessage({ id: "ProductTypes" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${convertArrayToStringDisplay(row.productTypes)}`}</div>
      ),
    },
    {
      dataField: "sellingPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`RM ${row.sellingPrice}`}</div>
      ),
    },
    {
      dataField: "taxRate",
      text: intl.formatMessage({ id: "TaxPrice" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.taxRate}`}</div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <div className="d-flex gap-1 flex-justfity-content-center">
          {`${row.quantity}`}
          <TagMultipleOutlineIcon
            id={`customerLockedCreditBottom_${row.id}`}
            className="pointer-help"
            color='#556ee6'
            style={{ paddingBottom: "4px" }}
          />
          <UncontrolledTooltip placement="right" target={`customerLockedCreditBottom_${row.id}`}>
            {renderBranchQuantities(row.branchQuantities)}
          </UncontrolledTooltip>
        </div>
      ),
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.product}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionCreateStockOutProduct
              &&
              <TableActionButton
                type="edit"
                onClick={() => {
                  setSelectedProductName(row.name)
                  setSelectedProductId(row.id)
                  setStockOutProductModal(true)}}
                iconReplaceUI={<RenameBoxIcon size={20} className={"text-warning"} id="stockOutTooltip" />}
                iconId="stockOutTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "StockOut" })}`} />
            }
            {
              blnPermissionUpdateServiceTreatment
              &&
              row.productTypes.indexOf(Constants.consumable) > -1
              &&
              <TableActionButton
                type="edit"
                onClick={() => {
                  setSelectedProductName(row.name)
                  setSelectedProductId(row.id)
                  setUpdateServiceConsumeQuantityModal(true)}}
                iconReplaceUI={<AnimationIcon size={20} className={"text-info"} id="updateServiceConsumeQuantityTooltip" />}
                iconId="updateServiceConsumeQuantityTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "UpdateServiceConsumeQuantity" })}`} />
            }
            {
              blnPermissionUpdateProduct
              &&
              <TableActionButton
                to={`/${RoutesList.product}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteProduct
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const exportProductReport = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await (exportProduct({ exportType: 2 }))
    }
    setLocalLoading(false);
  }

  const importProductFunction = async (excelFile) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: ProductImportObject = { file: fileTemp };
        let resultImportProduct = await importProduct(valuesTemp);
        if (resultImportProduct?.status) {
          if (resultImportProduct.status === Constants.success) {
            getProduct(productRequestBody);
            setTimeout(() => {
              setImportFileModal(false);
            }, 2000)
          }
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const blnViewAllFunction = () => {
    setCurrentPage(1);
    setBlnAllActive(true);
  }

  const blnActiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(true);
    setBlnAllActive(false);
  }

  const blnInactiveOnlyFunction = () => {
    setCurrentPage(1);
    setBlnIsActive(false);
    setBlnAllActive(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
        title={intl.formatMessage({ id: "Product" })}
        addButton={
          blnPermissionCreateProduct
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
              onClick={() => { history.push(`/${RoutesList.product}/add`) }}
              linkTo={`/${RoutesList.product}/add`}
              disable={loading} />
            <MyButton
              content={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
              onClick={() => { setImportFileModal(true) }}
              class={'btn btn-primary margin-left-8'}
              disable={loading} />
            <MyButton
              content={intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Template" }) })}
              onClick={() => { exportProductReport(true) }}
              class={'btn btn-info margin-left-8'}
              disable={loading} />
          </>
        }
        addButtonXL={"4"}>
        <Row>
          {
            !initialCallAPI
            &&
            <>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "ProductType" })}
                  name="productType"
                  options={productTypeList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedProductType}
                  initialValue={selectedProductTypeId}
                  setFieldLabel={setSelectedProductType}
                  setFieldValue={setSelectedProductTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Category" })}
                  name="category"
                  options={categoryList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedCategory}
                  initialValue={selectedCategoryId}
                  setFieldLabel={setSelectedCategory}
                  setFieldValue={setSelectedCategoryId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SalesCategory" })}
                  name="salesCategory"
                  options={salesCategoryList}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSalesCategory}
                  initialValue={selectedSalesCategoryId}
                  setFieldLabel={setSelectedSalesCategory}
                  setFieldValue={setSelectedSalesCategoryId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
            </>
          }
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterProductName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "SKU" })}
              name="sKU"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterSKU" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28": ""} ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "" : "margin-bottom-4"}`}>
            <div className="btn-group d-flex mb-3" role="group">
              {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAllActive, loading || localLoading, intl.formatMessage({ id: "All" }), ()=> {blnViewAllFunction()}, Constants.activeTabWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveTrue", blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Active" }), ()=> {blnActiveOnlyFunction()}, Constants.activeTabWidth)}
              {returnSwitchTabButtonUI("blnIsActive", "blnIsActiveFalse", !blnIsActive && !blnAllActive, loading || localLoading, intl.formatMessage({ id: "Inactive" }), ()=> {blnInactiveOnlyFunction()}, Constants.activeTabWidth)}
            </div>
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ProductColumns}
              data={productList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
              objSorted={defaultSorted}
              blnSupportColumnFilter={true}
              tableColumnsFromStore={productTableColumnFromStore}
              setTableColumnsFromStore={setProductTableColumnFromStore}
              productName={validation.values.name}
              sKU={validation.values.sKU}
              productType={selectedProductTypeId}
              productTypeName={selectedProductType}
              categoryId={selectedCategoryId}
              categoryName={selectedCategory}
              salesCategoryId={selectedSalesCategoryId}
              salesCategoryName={selectedSalesCategory}
              branchId={getBranchUser() ? getBranchId() : ""}
              isActive={!blnAllActive ? blnIsActive : undefined}
              requestAPI={getProduct} />
        }

        <ImportFileModal
          blnShow={importFileModal}
          setModal={setImportFileModal}
          title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Product" }) })}
          btnFunction={importProductFunction}
        />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteProduct(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (productList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getProduct({ ...productRequestBody, pageNumber: currentPageTemp })
            setLoading(false);
          }} />
      </ListViewLayout>
      {
        stockOutProductModal &&
        <ProductStockOutModal
          blnShow={stockOutProductModal}
          setModal={setStockOutProductModal}
          selectedProductId={selectedProductId}
          selectedProductName={selectedProductName}
          optionsStockOutReason={AddOptionsValueField(stockOutProductReasonType, "displayValue", "displayValue")}
          stockOutProduct={stockOutProduct}
          fetchProductApi={fetchProductApi}
          setErrorMessage={setErrorMessage} />
      }
      {
        updateServiceConsumeQuantityModal &&
        <UpdateServiceConsumeQuantityModal
          blnShow={updateServiceConsumeQuantityModal}
          setModal={setUpdateServiceConsumeQuantityModal}
          selectedProductId={selectedProductId}
          selectedProductName={selectedProductName}
          fetchUpdateServiceConsumeQuantity={updateServiceConsumeQuantity} />
      }
    </div>
  );
}

export default observer(Product);
