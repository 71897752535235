import { useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import moment from "moment";
import { EmployeeBranchTransferHistory } from "../../../app/models/employee";
import { BranchListObject } from "../../../app/models/branch";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";

interface Props {
  blnShow: boolean;
  setModal: Function;
  addEmployeeBranchTransferHistory: Function;
  validation: any;
  setRefreshBranchTransferHistoryData: Function;
  branchDropdownList: BranchListObject[];
  blnPermissionReassignEmployeeCustomer: boolean;
}

export default observer(function AddBranchTransferHistoryModal(props: Props) {
  const intl = useIntl();
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false);
  const [loadingAddBranchTransfer, setLoadingAddBranchTransfer] = useState(false);
  const disabledFieldInput = loadingAddBranchTransfer || localSuccessMessage;

  const [errorState, setErrorState] = useState({
    branch: false,
    startDate: false
  })

  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employeeId: props.validation.values.id,
      employeeName: props.validation.values.preferredName,
      branchId: "",
      startDate: "",
      isBypassReassignCustomerValidation: !props.blnPermissionReassignEmployeeCustomer
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branch: false,
      startDate: false
    });
  }

  const hideModal = () => {
    props.setModal(false);
  }

  const fetchAddEmployeeBranchTransferHistoryAPI = async () => {
    setLoadingAddBranchTransfer(true);
    let resultAPI = await props.addEmployeeBranchTransferHistory(validationLocal.values);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
        props.setRefreshBranchTransferHistoryData(true);
      }, Constants.timeoutNavigation)
    }
    setLoadingAddBranchTransfer(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!loadingAddBranchTransfer && !localSuccessMessage) {
          hideModal();
        }
      }}
      size='md'
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "AddBranchTransferHistory" })}</h5>
        {
          !loadingAddBranchTransfer && !localSuccessMessage
          &&
          <button
            type="button"
            onClick={() => { hideModal(); }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <GeneralInput
          title={`${intl.formatMessage({ id: "Employee" })}`}
          name="employeeName"
          type="text"
          disabled={true}
          validation={validationLocal}
        />
        <DropDownWithTitle
          name={"positionId"}
          className={errorState.branch ? "mb-0" : "mb-3"}
          title={intl.formatMessage({ id: "Branch" })}
          specifyReturnFieldName={[
            {
              field: "branchId",
              value: "id",
            },
          ]}
          labelField={"name"}
          valueField={"id"}
          options={props.branchDropdownList}
          disabled={disabledFieldInput}
          disableMenuPortalTarget={true}
          validationRequired={true}
          validation={validationLocal}
        />
        {errorState.branch ? (
          <FormFeedback className="margin-top--12 mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" })})}`}</FormFeedback>
        ) : null}
        <GeneralInput
          title={`${intl.formatMessage({ id: "StartDate" })}`}
          name="startDate"
          className={errorState.startDate ? "mb-0" : "mb-3"}
          type="date"
          disabled={disabledFieldInput}
          validation={validationLocal}
        />
        {errorState.startDate ? (
          <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "StartDate" })})}`}</FormFeedback>
        ) : null}
        <div className="flex-right">
          {
            !Boolean(localSuccessMessage)
            &&
            <MyButton
              type="button"
              class="btn btn-success"
              style={{ width: '20%' }}
              content={intl.formatMessage({ id: "Confirm" })}
              onClick={() => {
                let errorStateTemp = {
                  branch: false,
                  startDate: false
                };
                let blnErrorFound = false;
                if (!validationLocal.values.branchId) {
                  errorStateTemp.branch = true;
                  blnErrorFound = true;
                }
                if (!validationLocal.values.startDate) {
                  errorStateTemp.startDate = true;
                  blnErrorFound = true;
                }
                
                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  clearErrorState();
                  fetchAddEmployeeBranchTransferHistoryAPI()
                }
              }}
              loading={loadingAddBranchTransfer}
              disable={loadingAddBranchTransfer || Boolean(localSuccessMessage)}
            />
          }
        </div>
      </div>
    </Modal>
  )
});