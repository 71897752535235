import { PaginationRequestBody } from "../models/pagination";
import { EmployeeLeaveListObject, EmployeeLeaveAddObject, EmployeeLeaveUpdateObject, EmployeeLeaveDetailObject, EmployeeLeaveExportObject, EmployeeLeaveImportObject, ImportEmployeeLeaveConfirmationObject, ImportDayOffLeaveConfirmationObject, EmployeeLeaveDeleteWithDateRangeObject } from "../models/employeeLeave";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class EmployeeLeaveStore {
  employeeLeaveList: EmployeeLeaveListObject[] = [];
  employeeLeaveDetail: EmployeeLeaveDetailObject | undefined = undefined;
  employeeLeavePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.employeeLeaveList = [];
    this.employeeLeaveDetail = undefined;
    this.employeeLeavePaginationParams = undefined;
  }

  setEmployeeLeaveDetail = (employeeLeaveDetail: EmployeeLeaveDetailObject | undefined) => {
    this.employeeLeaveDetail = employeeLeaveDetail;
  }
  
  setEmployeeLeavePaginationParams = (employeeLeavePaginationParams: PaginationRequestBody | undefined) => {
    this.employeeLeavePaginationParams = employeeLeavePaginationParams;
  }

  getEmployeeLeave = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setEmployeeLeavePaginationParams(PaginationRequestBody);
    try{
      const resultEmployeeLeave = await agent.EmployeeLeave.employeeLeaveList(PaginationRequestBody);
      runInAction(() => {
        this.employeeLeaveList = resultEmployeeLeave.data;
        store.commonStore.setTotalItem(resultEmployeeLeave.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.employeeLeaveList = [];
    }
  }

  getEmployeeLeaveWithId = async (id: string) => {
    try{
      const resultEmployeeLeaveDetail = await agent.EmployeeLeave.employeeLeaveDetail(id);
      runInAction(() => {
        this.employeeLeaveDetail = resultEmployeeLeaveDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.employeeLeaveDetail = undefined;
    }
  }

  addEmployeeLeave = async (employeeLeaveRequestBody: EmployeeLeaveAddObject) => {
    try{
      await agent.EmployeeLeave.addEmployeeLeave(employeeLeaveRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeLeaveAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateEmployeeLeave = async (employeeLeaveRequestBody: EmployeeLeaveUpdateObject) => {
    try{
      await agent.EmployeeLeave.updateEmployeeLeave(employeeLeaveRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeLeaveUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteEmployeeLeave  = async (id: string) => {
    try {
      await agent.EmployeeLeave.deleteEmployeeLeave(id);
      store.commonStore.setSuccessMessage(`EmployeeLeaveDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportEmployeeLeave = async (employeeLeaveExportRequestBody: EmployeeLeaveExportObject) => {
    try{
      await downloadExcel(`/employeeLeave/export/hr2000`, employeeLeaveExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importEmployeeLeave = async (employeeLeaveImportRequestBody: EmployeeLeaveImportObject) => {
    try{
      const resultImportEmployeeLeave = await agent.EmployeeLeave.importEmployeeLeave(employeeLeaveImportRequestBody);
      // store.commonStore.setSuccessMessage(`EmployeeLeaveImportSuccess`)
      return Promise.resolve(resultImportEmployeeLeave)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importEmployeeLeaveConfirmation = async (employeeLeaveImportConfirmationRequestBody: ImportEmployeeLeaveConfirmationObject) => {
    try{
      await agent.EmployeeLeave.importEmployeeLeaveConfirmation(employeeLeaveImportConfirmationRequestBody);
      store.commonStore.setSuccessMessage(`EmployeeLeaveImportSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  exportDayOffLeave = async (exportDayOffRequestBody: EmployeeLeaveExportObject) => {
    try{
      await downloadExcel(`/employeeLeave/export/hr2000/dayoff`, exportDayOffRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importDayOffLeave = async (dayOffImportRequestBody: EmployeeLeaveImportObject) => {
    try{
      const resultImportDayOffLeave = await agent.EmployeeLeave.importDayOffLeave(dayOffImportRequestBody);
      // store.commonStore.setSuccessMessage(`EmployeeLeaveImportSuccess`)
      return Promise.resolve(resultImportDayOffLeave)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importDayOffLeaveConfirmation = async (dayOffLeaveImportConfirmationRequestBody: ImportDayOffLeaveConfirmationObject) => {
    try{
      await agent.EmployeeLeave.importDayOffLeaveConfirmation(dayOffLeaveImportConfirmationRequestBody);
      store.commonStore.setSuccessMessage(`DayOffLeaveImportSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  deleteEmployeeLeaveWithDateRange  = async (employeeLeaveDeleteWithDateRangeObject: EmployeeLeaveDeleteWithDateRangeObject) => {
    try {
      await agent.EmployeeLeave.deleteEmployeeLeaveWithDateRange(employeeLeaveDeleteWithDateRangeObject);
      store.commonStore.setSuccessMessage(`EmployeeLeaveDeleteSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}
