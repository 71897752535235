import { PaginationRequestBody } from "../models/pagination";
import { InvoiceListObject, InvoiceDetailObject, InvoiceSignObject } from "../models/invoice";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CustomerSalesOrderDetailObject, CustomerSalesOrderListObject } from "../models/customerSalesOrder";

export default class InvoiceStore {
  invoiceList: InvoiceListObject[] = [];
  invoiceFromSalesOrderList: CustomerSalesOrderListObject[] = [];
  invoiceDetail: InvoiceDetailObject | undefined = undefined;
  invoiceDetailFromSalesOrder: CustomerSalesOrderDetailObject | undefined = undefined;
  invoiceFromSalesOrderPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.invoiceList = [];
    this.invoiceFromSalesOrderList = [];
    this.invoiceDetail = undefined;
    this.invoiceDetailFromSalesOrder = undefined;
    this.invoiceFromSalesOrderPaginationParams = undefined;
  }

  setInvoiceDetail = (invoiceDetail: InvoiceDetailObject | undefined) => {
    this.invoiceDetail = invoiceDetail;
  }
  
  setInvoiceFromSalesOrderPaginationParams = (invoiceFromSalesOrderPaginationParams: PaginationRequestBody | undefined) => {
    this.invoiceFromSalesOrderPaginationParams = invoiceFromSalesOrderPaginationParams;
  }

  setInvoiceDetailFromSalesOrder = (invoiceDetailFromSalesOrder: CustomerSalesOrderDetailObject | undefined) => {
    this.invoiceDetailFromSalesOrder = invoiceDetailFromSalesOrder;
  }

  getInvoice = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultInvoice = await agent.Invoice.invoiceList(PaginationRequestBody);
      runInAction(() => {
        this.invoiceList = resultInvoice.data;
        store.commonStore.setTotalItem(resultInvoice.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.invoiceList = [];
    }
  }

  getInvoiceWithId = async (id: string) => {
    try{
      const resultInvoiceDetail = await agent.Invoice.invoiceDetail(id);
      runInAction(() => {
        if (resultInvoiceDetail.invoiceDetails) {
          resultInvoiceDetail.invoiceDetails = resultInvoiceDetail.invoiceDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
        }
        this.invoiceDetail = resultInvoiceDetail;
      });
      return Promise.resolve(resultInvoiceDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.invoiceDetail = undefined;
    }
  }

  signInvoice = async (signInvoiceRequestBody: InvoiceSignObject) => {
    try{
      await agent.Invoice.signInvoice(signInvoiceRequestBody);
      store.commonStore.setSuccessMessage(`InvoiceSignSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getInvoiceFromCustomerSalesOrder = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setInvoiceFromSalesOrderPaginationParams(PaginationRequestBody);
    try{
      const resultInvoiceFromCustomerSalesOrder = await agent.Invoice.invoiceFromSalesOrderList(PaginationRequestBody);
      runInAction(() => {
        this.invoiceFromSalesOrderList = resultInvoiceFromCustomerSalesOrder.data;
        store.commonStore.setTotalItem(resultInvoiceFromCustomerSalesOrder.pagination.totalItems);
      });
      return Promise.resolve(resultInvoiceFromCustomerSalesOrder.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.invoiceFromSalesOrderList = [];
    }
  }

  getInvoiceFromCustomerSalesOrderWithId = async (id: string) => {
    try{
      const resultInvoiceFromCustomerSalesOrderDetail = await agent.Invoice.invoiceFromSalesOrderDetail(id);
      runInAction(() => {
        if (resultInvoiceFromCustomerSalesOrderDetail.salesOrderDetails) {
          resultInvoiceFromCustomerSalesOrderDetail.salesOrderDetails = resultInvoiceFromCustomerSalesOrderDetail.salesOrderDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
          resultInvoiceFromCustomerSalesOrderDetail.paymentHistories = resultInvoiceFromCustomerSalesOrderDetail.paymentHistories.sort((a, b) => (a.paymentDate > b.paymentDate) ? -1 : 1)
        }
        this.invoiceDetailFromSalesOrder = resultInvoiceFromCustomerSalesOrderDetail;
      });
      return Promise.resolve(resultInvoiceFromCustomerSalesOrderDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.invoiceDetailFromSalesOrder = undefined;
    }
  }
}