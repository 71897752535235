import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, deliveryOrderStatusColor, getBranchId, getBranchName, getBranchUser, returnAdvancedSearchText } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import { Col, Label, Row } from "reactstrap";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { PaginationRequestBody } from "../../app/models/pagination";
import { history } from "../..";
import { Link, useLocation } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import CloseThickIcon from "mdi-react/CloseThickIcon";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";

const DeliveryOrder = () => {
  const intl = useIntl();
  //Use Store
  const { deliveryOrderStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { deliveryOrderList, deliveryOrderPaginationParams, getDeliveryOrder, setDeliveryOrderPaginationParams } = deliveryOrderStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCustomerDeliverOrderStatus, setSelectedCustomerDeliverOrderStatus] = useState<string>(AllStatus);
  const [selectedCustomerDeliverOrderStatusId, setSelectedCustomerDeliverOrderStatusId] = useState<number | string | undefined>(" ");
  const [customerDeliverOrderStatusList, setCustomerDeliverOrderStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
  const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const blnHQStaff = !getBranchUser();
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionUpdateCustomerDeliverOrder = checkPermission([PermissionConstants.UpdateCustomerDeliverOrder]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCreditRedemptionNote = checkPermission([PermissionConstants.ManageCreditRedemptionNote]);
  const location = useLocation();

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
      salesOrderId: "",
      customerDeliverOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let deliveryOrderRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    deliverOrderStatusName: selectedCustomerDeliverOrderStatus,
    deliverOrderStatus: selectedCustomerDeliverOrderStatusId,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    salesOrderId: validation.values.salesOrderId,
    salesOrderNo: validation.values.salesOrderNo,
    customerDeliverOrderNo: validation.values.customerDeliverOrderNo,
    dateRange: selectedDateRange
  }

  useEffect(() => {
    async function fetchDeliveryOrderListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerRedemption)
      let selectedBranchTemp: string = selectedBranchId;

      if (blnBackNavigation) {
        if (deliveryOrderPaginationParams) {
          setCurrentPage(deliveryOrderPaginationParams.pageNumber)
          deliveryOrderRequestBody = deliveryOrderPaginationParams;
          setSelectedCustomerDeliverOrderStatus(deliveryOrderPaginationParams.deliverOrderStatusName || "")
          setSelectedCustomerDeliverOrderStatusId(deliveryOrderPaginationParams.deliverOrderStatus)
          setSelectedBranchId(deliveryOrderPaginationParams.branchId || "")
          selectedBranchTemp = deliveryOrderPaginationParams.branchId || "";
          setSelectedBranchName(deliveryOrderPaginationParams.branchName || "")
          setSelectedCustomerId(deliveryOrderPaginationParams.customerId || "")
          setSelectedCustomer(deliveryOrderPaginationParams.customerName || "")
          validation.setFieldValue("salesOrderId", deliveryOrderPaginationParams.salesOrderId)
          validation.setFieldValue("salesOrderNo", deliveryOrderPaginationParams.salesOrderNo)
          validation.setFieldValue("customerDeliverOrderNo", deliveryOrderPaginationParams.customerDeliverOrderNo)
          if (deliveryOrderPaginationParams.dateRange) {
            setSelectedDateRange(deliveryOrderPaginationParams.dateRange)
          }
        }
      }
      else {
        setDeliveryOrderPaginationParams(undefined);
      }

      let blnParamsFound = false;
      let salesOrderIdTemp: string = "";
      let salesOrderNoTemp: string = "";

      if (location.state) {
        if (location.state.detail) {
          if (location.state.detail.salesOrderId && location.state.detail.salesOrderNo) {
            blnParamsFound = true;
            validation.setFieldValue("salesOrderId", location.state.detail.salesOrderId);
            validation.setFieldValue("salesOrderNo", location.state.detail.salesOrderNo);
            salesOrderIdTemp = location.state.detail.salesOrderId;
            salesOrderNoTemp = location.state.detail.salesOrderNo;
            window.history.replaceState({}, "");
          }
        }
      }

      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.customerDeliverOrderStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);

      if (!blnParamsFound) {
        await fetchDeliveryOrderAPI(false);
        setInitialCallAPI(false);
      }
      else {
        await fetchDeliveryOrderAPI(false, 1, salesOrderIdTemp, salesOrderNoTemp);
        setInitialCallAPI(false);
      }

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? allBranch : "", true))
      setCustomerDeliverOrderStatusList(resultAPI[1]);

      setLocalLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerDeliverOrder], true)) {
      return;
    }

    fetchDeliveryOrderListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerRedemption)
        if (!blnSetParams) {
          setDeliveryOrderPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(() => {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if ((selectedBranchId) && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchDeliveryOrderAPI(true);
    }
  }, [selectedBranchId]);

  useEffect(() => {
    if ((selectedCustomerDeliverOrderStatusId || selectedCustomerDeliverOrderStatusId === 0) && !initialCallAPI) {
      fetchDeliveryOrderAPI(true);
    }
  }, [selectedCustomerId, selectedCustomerDeliverOrderStatusId, validation.values.salesOrderId, triggerSearch, selectedDateRange])

  async function fetchDeliveryOrderAPI(blnDropDown?: boolean, pageSizeInsert?: number, salesOrderIdTemp?: string, salesOrderNoTemp?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getDeliveryOrder({
      ...deliveryOrderRequestBody,
      ...pageSizeInsert && { pageNumber: pageSizeInsert },
      ...salesOrderIdTemp && { salesOrderId: salesOrderIdTemp, salesOrderNo: salesOrderNoTemp }
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown(value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, { customerNoOrName: value });
    if (resultCustomerAPI) {
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const DeliveryOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerDeliverOrderNo",
      text: intl.formatMessage({ id: "RedemptionNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
      <>
        {row.customerDeliverOrderNo} {row.isSystemAutoGenerated && <i className="text-muted">{`(${intl.formatMessage({ id: "Auto" }).toUpperCase()})`}</i>}
      </>
    },
    {
      dataField: "performDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerPreferredName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerPreferredName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerPreferredName}</div>
          }
        </>
      )
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "ReferenceDocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
          ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo}</Label>
            </Link>
          :
            row.customerSalesOrderNo
        }
      </>
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={deliveryOrderStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.customerRedemption}/view/${row.id}`}
              type="view" />
            {
              row.status == Constants.pending &&
              blnPermissionUpdateCustomerDeliverOrder
              &&
              <TableActionButton
                to={`/${RoutesList.customerRedemption}/edit/${row.id}`}
                type="edit" />
            }
          </div>
        </>
      ),
    },
  ];

  const onClickSelect = (row) => {
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
}

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerRedemption" }) })}
        title={intl.formatMessage({ id: "CustomerRedemption" })}
      >
        {
          !initialCallAPI
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Order" }) })}
                  name="customerDeliverOrderStatus"
                  options={customerDeliverOrderStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedCustomerDeliverOrderStatus}
                  initialValue={selectedCustomerDeliverOrderStatusId}
                  setFieldLabel={setSelectedCustomerDeliverOrderStatus}
                  setFieldValue={setSelectedCustomerDeliverOrderStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || !blnHQStaff}
                  validationRequired={true}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                    // getCustomerWithLoading(value);
                  }}
                />
              </DoubleColumnRowParent>
              {
                localLoading
                ?
                <div />
                :
                <>
                  <DoubleColumnRowParent>
                    {
                      displayCustomerDropdown &&
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "Customer" })}
                        name="customer"
                        placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                        options={customerDropdownList}
                        labelField="preferredName"
                        valueField="id"
                        initialLabel={selectedCustomer}
                        initialValue={selectedCustomerId}
                        setFieldLabel={setSelectedCustomer}
                        setFieldValue={setSelectedCustomerId}
                        disabled={localLoading || loading}
                        onChange={() => {
                          clearCustomerDropdownList();
                          setCurrentPage(1);
                          setErrorMessage("");
                          setSuccessMessage("");
                        }}
                        blnSupportCustomSearch={true}
                        onChangeCustomSearch={(value)=> {
                          fetchCustomerDropdown(value);
                        }}
                        onMenuOpenFunction={()=> {
                          clearCustomerDropdownList();
                          setLoadingCustomer(false);
                        }}
                        loadingCustomSearch={loadingCustomer}
                        setLoadingCustomSearch={setLoadingCustomer}
                      />
                    }
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <MyButton
                      type="button"
                      class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-12"}`}
                      content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                      loading={localLoading || loading}
                      disable={localLoading || loading}
                      onClick={() => { setCustomerSearchModal(true) }}
                    />
                  </DoubleColumnRowParent>
                </>
              }
            </Row>
            <Row>
              {
                validation.values.salesOrderId
                &&
                <DoubleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SalesOrderNo" })}
                    name="salesOrderNo"
                    type="text"
                    disabled={true}
                    validation={validation}
                    placeholder={intl.formatMessage({ id: "EnterSalesOrderNo" })}
                    childrenUI={
                      <CloseThickIcon
                        className="mt-2 margin-left-8 text-muted"
                        onClick={() => {
                          validation.setFieldValue("salesOrderNo", "");
                          validation.setFieldValue("salesOrderId", "");
                        }} />
                    }
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "RedemptionNo" })}
                  name="customerDeliverOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterRedemptionNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "RedemptionDate" }) })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  initialValue={selectedDateRange}
                  onChangeFunction={onSelectDateRange}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DeliveryOrderColumns}
              data={deliveryOrderList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "DeliveryOrder" }) })}
              objSorted={defaultSorted}
              deliverOrderStatus={selectedCustomerDeliverOrderStatusId}
              deliverOrderStatusName={selectedCustomerDeliverOrderStatus}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              salesOrderId={validation.values.salesOrderId}
              customerDeliverOrderNo={validation.values.customerDeliverOrderNo}
              dateRange={selectedDateRange}
              requestAPI={getDeliveryOrder} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect}
          branchName={selectedBranchName === allBranch ? getBranchName() : selectedBranchName}
          branchId={selectedBranchId === " " ? getBranchId() : selectedBranchId}
          blnHideOtherBranch={!blnHQStaff} />
      }
    </div>
  );
}

export default observer(DeliveryOrder);
