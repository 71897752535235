import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, salesOrderStatusColor, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, purchasedItemStatusColor, getBranchId, firstDayOfMonthToCurrentDay, returnSubBrandList, displayTypeWithColor } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from 'react-router-dom';
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import ReportExpandRow from "../subView/ReportExpandRow";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const ContractLiabilityReport = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setErrorMessage, windowSize } = commonStore;
  const { contractLiabilityReportList, getContractLiabilityReportList, setContractLiabilityReportList, exportContractLiabilityReport } = reportAccountStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { salesOrderStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);
  const [refreshCustomerDropdown, setRefreshCustomerDropdown] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [tableExpandRowSalesOrderKeyList, setTableExpandRowSalesOrderKeyList] = useState<string[]>([]);
  const [tableExpandRowPackageKeyList, setTableExpandRowPackageKeyList] = useState<string[]>([]);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const arySalesOrderDetails: ObjectForReportRow[] = [
    { label: "PurchaseType", value: "purchaseType", type: "string" },
    { label: "ItemName", value: "itemName", type: "string" },
    { label: "Status", value: "status", type: "status" },
    { label: "Quantity", value: "count", type: "string" },
    { label: "SellingPrice", value: "originalPrice", type: "currency" },
    { label: "UnitPrice", value: "unitPrice", type: "currency" },
    { label: "DiscountedAmount", value: "discountAmount", type: "currency" },
    { label: "TotalPrice", value: "totalPrice", type: "currency" },
    { label: "ExpiredDate", value: "expiredDate", type: "date" },
    { label: "AvailableSession", value: "packageAvailableSession", type: "string" },
    { label: "RedeemedSession", value: "packageRedeemedSession", type: "string" },
    //Available product quantity is missing
    { label: "RedeemedProductQuantity", value: "packageRedeemedProductQuantity", type: "string" },
    { label: "OptionalProductCost", value: "packageOptionalProductCost", type: "currency" },
    { label: "RedeemedProductCost", value: "packageRedeemedProductCost", type: "currency" },
    { label: "RedeemedCredit", value: "packageRedeemedCredit", type: "string" },
    { label: "SessionBalance", value: "packageSessionBalance", type: "string" },
    { label: "ProductBalance", value: "packageProductBalance", type: "string" },
  ]

  const aryPackageDetails: ObjectForReportRow[] = [
    //item type name is missing
    { label: "ItemName", value: "itemName", type: "string" },
    { label: "BreakdownAmount", value: "breakdownAmount", type: "currency" },
    { label: "EntitleSession", value: "availableSession", type: "string" },
    { label: "RedeemedSession", value: "redeemedSession", type: "string" },
    { label: "AvailableQuantity", value: "availableQuantity", type: "string" },
    { label: "RedeemedQuantity", value: "redeemedQuantity", type: "string" },
    { label: "ItemCostPrice", value: "costPrice", type: "currency" },
    { label: "ItemUnitPrice", value: "unitPrice", type: "currency" },
    { label: "ItemTotalPrice", value: "total", type: "currency" },
  ]

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerIds: [],
      statuses: [],
      isExcludeProduct: false,
      isIncludeEarnSales: false,
      salesOrderNos: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  useEffect(() => {
    async function fetchDynamicSalesListAPI() {     
      setLoading(true); 
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.salesOrderStatus)
      ];
      await Promise.all(aryAPI);  
    
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setContractLiabilityReportList([]);
    })
  }, []);

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  async function fetchContractLiabilityReportListApi(blnDropDown: boolean = false) {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getContractLiabilityReportList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      purchaseBranchId: selectedBranchId,
      customerIds: validation.values.customerIds,
      statuses: validation.values.statuses,
      isExcludeProduct: validation.values.isExcludeProduct,
      isIncludeEarnSales: validation.values.isIncludeEarnSales,
      salesOrderNos: validation.values.salesOrderNos,
    });
    setTableExpandRowPackageKeyList([]);
    setTableExpandRowSalesOrderKeyList([]);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportContractLiabilityReportApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    setLocalLoading(true);
    await exportContractLiabilityReport({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      purchaseBranchId: selectedBranchId,
      customerIds: validation.values.customerIds,
      statuses: validation.values.statuses,
      isExcludeProduct: validation.values.isExcludeProduct,
      isIncludeEarnSales: validation.values.isIncludeEarnSales,
      salesOrderNos: validation.values.salesOrderNos,
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const tableFixedWidth = 200;
  const chevronDownIconSize = 18;
  const returnHeaderStyle = { width: `${tableFixedWidth}px`, minWidth: `${tableFixedWidth}px`, maxWidth: `${tableFixedWidth}px` };

  //Table Content UI
  const DynamicSalesColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "customerCurrentBranchName",
      text: intl.formatMessage({ id: "CustomerCurrentBranch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerIC",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerSalesOrder
            ?
            <Link to={row.id ? `/${RoutesList.customerSalesOrder}/view/${row.id}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <Label className="margin-bottom-0">{row.customerSalesOrderNo || Constants.emptyData}</Label>
          }
        </>
      )
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      hidden: returnSubBrandList().length < 1,
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.submissionDate)
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center", ...returnHeaderStyle },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={salesOrderStatusColor} />
      ),
    },
    {
      dataField: "signDate",
      text: intl.formatMessage({ id: "SignDate" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        returnDisplayDate(row.signDate)
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "CustomerBranch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "purchaseBranchName",
      text: intl.formatMessage({ id: "PurchaseBranch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "totalInvoiceAmount",
      text: intl.formatMessage({ id: "InvoiceAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalInvoiceAmount)}</div>
      ),
    },
    {
      dataField: "discountAmount",
      text: intl.formatMessage({ id: "DiscountAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.discountAmount)}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "PaidAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "totalCreditPayment",
      text: intl.formatMessage({ id: "CreditAmount" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalCreditPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.balance)}</div>
      ),
    },
    {
      dataField: "totalRefundAmount",
      text: intl.formatMessage({ id: "RefundToCn" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalRefundAmount)}</div>
      ),
    }
  ];

  const returnDisplayDate = (valueDate) => {
    return (
      valueDate === Constants.invalidDate
      ?
      Constants.emptyDataV2
      :
      <>
        <div className="">
          {`${moment(valueDate).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(valueDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    )
  }

  const expandRowSalesOrderDetails = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "SalesOrderDetails" })}
        rowFieldName={"salesOrderDetails"}
        rowColumns={arySalesOrderDetails}
        keyFieldName={"randomId"}
        statusColorFunction={purchasedItemStatusColor}
        // childRow={expandRowPackageDetails}
        blnExcelUIDesign={true}
        tableFixedWidth={tableFixedWidth}
        tableHeaderBackgroundColor={Constants.lightbluev2}
        tableRowBackgroundColor={Constants.lightbluev3}
      />
    ),
    showExpandColumn: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <ChevronUpIcon size={chevronDownIconSize} className="text-secondary"/>
            :
            <ChevronDownIcon size={chevronDownIconSize} className="text-secondary"/>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
            ?
            <ChevronUpIcon size={chevronDownIconSize} className="text-secondary"/>
            :
            <ChevronDownIcon size={chevronDownIconSize} className="text-secondary"/>
        }
      </div>
    ),
    expanded: tableExpandRowSalesOrderKeyList,
    onExpandAll: (isExpandAll, results, e) => {
      if (isExpandAll) {
        let tableExpandRowSalesOrderKeyListTemp : string[] = [];
        let tableExpandRowPackageKeyListTemp : string[] = [];
        contractLiabilityReportList.map((valueContractLiabilityReportTemp)=> {
          tableExpandRowSalesOrderKeyListTemp.push(valueContractLiabilityReportTemp.id);
          valueContractLiabilityReportTemp.salesOrderDetails.map((salesOrderDetailTemp)=> {
            tableExpandRowPackageKeyListTemp.push(salesOrderDetailTemp.id)
          })
        })

        setTableExpandRowSalesOrderKeyList(tableExpandRowSalesOrderKeyListTemp);
        setTableExpandRowPackageKeyList(tableExpandRowPackageKeyListTemp);
      }
      else {
        setTableExpandRowPackageKeyList([]);
        setTableExpandRowSalesOrderKeyList([]);
      }
    }
  };

  const expandRowPackageDetails = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "PurchaseDetails" })}
        rowFieldName={"packageDetails"}
        rowColumns={aryPackageDetails}
        keyFieldName={"itemId"}
        blnExcelUIDesign={true}
        tableFixedWidth={tableFixedWidth}
        tableHeaderBackgroundColor={Constants.lightgreenv2}
        tableRowBackgroundColor={Constants.lightgreenv3}
      />
    ),
    showExpandColumn: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <ChevronUpIcon size={chevronDownIconSize} className="text-secondary"/>
            :
            <ChevronDownIcon size={chevronDownIconSize} className="text-secondary"/>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <div>
        {
          !expanded
            ?
            <ChevronUpIcon size={chevronDownIconSize} className="text-secondary"/>
            :
            <ChevronDownIcon size={chevronDownIconSize} className="text-secondary"/>
        }
      </div>
    ),
    expanded: tableExpandRowPackageKeyList.length > 0 ? tableExpandRowPackageKeyList : undefined,
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ContractLiability" }) })}
        title={intl.formatMessage({ id: "ContractLiability" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportContractLiabilityReportApi()
            }}
          />
        }>
        {
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  className={""}
                  options={branchDropDownList}
                  labelField={"name"}
                  valueField={"id"}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldValue={setSelectedBranchId}
                  onChange={()=> {
                    setRefreshCustomerDropdown(true);
                    validation.setFieldValue("customerIds", []);
                    setTimeout(() => {
                      setRefreshCustomerDropdown(false);
                    }, 100);
                  }}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  refreshCustomerDropdown
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"customerIds"}
                    title={intl.formatMessage({ id: "Customer" })}
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    specifyReturnFieldName={[{ "field": "customerIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={customerDropdownList}
                    disabled={localLoading || loading || !selectedBranchId}
                    blnSupportCustomSearch={true}
                    onChangeFunction={()=> {
                      clearCustomerDropdownList();
                    }}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomerDropdown(false);
                    }}
                    loadingCustomSearch={loadingCustomerDropdown}
                    setLoadingCustomSearch={setLoadingCustomerDropdown}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <DropDownWithTitleMultiSelect
                  name={"statuses"}
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "SalesOrder" }) })}
                  specifyReturnFieldName={[{ "field": "statuses", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={salesOrderStatus}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  validationRequired={false}
                  validation={validation} />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent
                blnDoubleTab={true}>
                <GeneralInput
                  title={intl.formatMessage({ id: "SalesOrderNos" })}
                  name="salesOrderNos"
                  type="input"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterSalesOrderNos" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "ExcludeProduct" })}
                  name="isExcludeProduct"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IncludeEarnSales" })}
                  name="isIncludeEarnSales"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
            </Row>
            <DoubleColumnRowParent>
              <MyButton
                type="button"
                class={`btn btn-primary margin-bottom-18`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchContractLiabilityReportListApi(true)
                }}
              />
            </DoubleColumnRowParent>
          </>
        }
        {/* <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginBottom: "16px", marginRight: "42px",flexWrap: 'wrap'}}>
          {displayTypeWithColor(Constants.lightbluev2, intl.formatMessage({id: "PurchaseDetails"}))}
          {displayTypeWithColor(Constants.lightgreenv2, intl.formatMessage({id: "ProductDetails"}))}
        </div> */}
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DynamicSalesColumns}
              keyField={"id"}
              data={contractLiabilityReportList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ContractLiability" }) })}
              objSorted={defaultSorted}
              requestAPI={getContractLiabilityReportList}
              expandRow={expandRowSalesOrderDetails}
              purchaseBranchId={selectedBranchId}
              customerIds={validation.values.customerIds}
              statuses={validation.values.statuses}
              salesOrderNos={validation.values.salesOrderNos}
              isExcludeProduct={validation.values.isExcludeProduct}
              isIncludeEarnSales={validation.values.isIncludeEarnSales}
              bordered={true}
              dateRange={selectedDateRange} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ContractLiabilityReport);
