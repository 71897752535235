import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import moment from "moment";
import { checkPermission, paymentAdjustmentStatusColor, getBranchUser, getBranchName, getBranchId, arraySpliceInAllValue } from "../../app/common/function/function";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { ObjectForDropdown } from "../../app/models/common";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import PaymentAdjustmentDetailModal from "./Modal/PaymentAdjustmentDetailModal";
import { CustomerPaymentAdjustmentListObject } from "../../app/models/customerPaymentAdjustment";
import DeleteModal from "../../app/components/modal/DeleteModal";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import AutoGeneratedCustomerRedemptionModal from "../ProductRedemptionNote/Modal/AutoGeneratedCustomerRedemptionModal";
import { AutoRedemptionGeneratedObject } from "../../app/models/deliveryOrder";

const CustomerPaymentAdjustment = () => {
  const intl = useIntl();

  //Use Params
  const { id } = useParams();

  //Use Store
  const { customerPaymentAdjustmentStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { customerPaymentAdjustmentList, getCustomerPaymentAdjustment, getCustomerPaymentAdjustmentWithId, reviewCustomerPaymentAdjustment, deleteCustomerPaymentAdjustment } = customerPaymentAdjustmentStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedPaymentAdjustmentStatus, setSelectedPaymentAdjustmentStatus] = useState<number | string>(AllStatus);
  const [selectedPaymentAdjustmentStatusId, setSelectedPaymentAdjustmentStatusId] = useState<number | string | undefined>(" ");
  const [paymentAdjustmentStatusList, setPaymentAdjustmentStatusList] = useState<StaticReferenceDataObject[]>([])
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [selectedRow, setSelectedRow] = useState<CustomerPaymentAdjustmentListObject | undefined>(undefined);
  const [paymentAdjustmentDetailModal, setPaymentAdjustmentDetailModal] = useState(false);
  const [selectedCustomerPaymentAdjustmentDetail, setSelectedCustomerPaymentAdjustmentDetail] = useState<any>([]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionDeletePaymentAdjustment = checkPermission([PermissionConstants.DeletePaymentAdjustment]);
  const [autoRegenerateRedemptionNoticeModal, setAutoRegenerateRedemptionNoticeModal] = useState(false);
  const [autoRegenerateRedemptionResponse, setAutoRegenerateRedemptionResponse] = useState<AutoRedemptionGeneratedObject | undefined>(undefined);
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerPaymentAdjustmentNo: "",
      customerSalesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchCustomerPaymentAdjustmentListAPI() {
      setLocalLoading(true)
      let aryAPI: any = [
        fetchCustomerPaymentAdjustmentAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.paymentAdjustmentStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))
      setPaymentAdjustmentStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id) {
        await onClickViewDetail(id);
        window.history.replaceState(null, "", `/${RoutesList.paymentAdjustment}`)
      }
    }

    if (!checkPermission([PermissionConstants.ManagePaymentAdjustment], true)) {
      return;
    }

    fetchCustomerPaymentAdjustmentListAPI();
  }, [])

  useEffect(() => {
    if ((selectedPaymentAdjustmentStatusId || selectedPaymentAdjustmentStatusId === 0) && !initialCallAPI) {
      fetchCustomerPaymentAdjustmentAPI(true);
    }
  }, [selectedPaymentAdjustmentStatusId, selectedBranchId, triggerSearch])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setPaymentAdjustmentDetailModal(false);
        fetchCustomerPaymentAdjustmentAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    if (autoRegenerateRedemptionResponse) {
      setTimeout(() => {
        if(autoRegenerateRedemptionResponse && autoRegenerateRedemptionResponse.isProductDOAutoGenerated){
          setAutoRegenerateRedemptionNoticeModal(true);
        }
      }, Constants.dismissSuccessMessage)
    }
  }, [autoRegenerateRedemptionResponse])


  async function fetchCustomerPaymentAdjustmentAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerPaymentAdjustment({
      pageNumber: currentPage,
      pageSize: pageSize,
      paymentAdjustmentStatus: selectedPaymentAdjustmentStatusId,
      branchId: selectedBranchId,
      customerPaymentAdjustmentNo: validation.values.customerPaymentAdjustmentNo,
      customerSalesOrderNo: validation.values.customerSalesOrderNo,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: CustomerPaymentAdjustmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickViewDetail = async (paymentAdjustmentId: any) => {
    setLoading(true);
    let resultPreviewRefundCredit = await getCustomerPaymentAdjustmentWithId(paymentAdjustmentId);
    setSelectedCustomerPaymentAdjustmentDetail(resultPreviewRefundCredit)
    setPaymentAdjustmentDetailModal(true);
    setLoading(false);
  }

  //Table Content UI
  const CustomerPaymentAdjustmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerPaymentAdjustmentNo",
      text: intl.formatMessage({ id: "PaymentAdjustmentNo" }).toUpperCase(),
      style: {
        width: "15%"
      },
    },
    {
      dataField: "adjustmentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.adjustmentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.adjustmentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4">{row.customerSalesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "actionUserName",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.actionUserName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "ActionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.actionDate !== Constants.invalidDateTime ? moment(row.actionDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={paymentAdjustmentStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to="#"
              onClick={() => onClickViewDetail(row.id)}
              type="viewModal" />
            {
              blnPermissionDeletePaymentAdjustment
              &&
              row.status === Constants.processing
              &&
              <TableActionButton
                onClick={() => onClickDelete(row)} 
                type={'delete'}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PaymentAdjustment" }) })}
        title={intl.formatMessage({ id: "PaymentAdjustment" })}
        breadCrumbList={breadCrumbList}>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "PaymentAdjustment" }) })}
              name="status"
              options={paymentAdjustmentStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedPaymentAdjustmentStatus}
              initialValue={selectedPaymentAdjustmentStatusId}
              setFieldLabel={setSelectedPaymentAdjustmentStatus}
              setFieldValue={setSelectedPaymentAdjustmentStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              onChange={(value) => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
                // getCustomerWithLoading(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "PaymentAdjustmentNo" })}
              name="customerPaymentAdjustmentNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterPaymentAdjustmentNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
          <Col xl={'4'} md={'6'} xs={'12'}>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
              name="customerSalesOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerPaymentAdjustmentColumns}
              data={customerPaymentAdjustmentList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PaymentAdjustment" }) })}
              objSorted={defaultSorted}
              paymentAdjustmentStatus={selectedPaymentAdjustmentStatusId}
              branchId={selectedBranchId}
              customerPaymentAdjustmentNo={validation.values.customerPaymentAdjustmentNo}
              customerSalesOrderNo={validation.values.customerSalesOrderNo}
              requestAPI={getCustomerPaymentAdjustment} />
        }
      </ListViewLayout>
      <DeleteModal
        displayName={selectedRow?.customerPaymentAdjustmentNo}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteCustomerPaymentAdjustment(selectedRow?.id || "", selectedRow?.customerPaymentAdjustmentNo || "");
          let currentPageTemp = currentPage;
          if (customerPaymentAdjustmentList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getCustomerPaymentAdjustment({ pageNumber: currentPageTemp, pageSize: pageSize })
          setLoading(false);
        }} />
      {
        paymentAdjustmentDetailModal &&
        <PaymentAdjustmentDetailModal
          blnShow={paymentAdjustmentDetailModal}
          setModal={setPaymentAdjustmentDetailModal}
          selectedCustomerPaymentAdjustmentDetail={selectedCustomerPaymentAdjustmentDetail}
          setSelectedCustomerPaymentAdjustmentDetail={setSelectedCustomerPaymentAdjustmentDetail}
          reviewCustomerPaymentAdjustment={reviewCustomerPaymentAdjustment}
          setAutoRegenerateRedemptionResponse={setAutoRegenerateRedemptionResponse}
        />
      }
      {
        autoRegenerateRedemptionNoticeModal
        &&
        <AutoGeneratedCustomerRedemptionModal
          blnShow={autoRegenerateRedemptionNoticeModal}
          setModal={setAutoRegenerateRedemptionNoticeModal}
          deliverOrderNo={autoRegenerateRedemptionResponse?.deliverOrderNo}
          onRedeemClick={() => { 
            history.push({
              pathname: `/${RoutesList.productRedemptionNote}/add`,
              state: { 
                detail: { 
                  customerId: autoRegenerateRedemptionResponse?.customerId,
                  deliverOrderId: autoRegenerateRedemptionResponse?.deliverOrderId
                }}
            }); 
          }}
          onCancelClick={() => {
            setAutoRegenerateRedemptionNoticeModal(false);
          }}/>
        }
    </div>
  );
}

export default observer(CustomerPaymentAdjustment);
