import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithSelectAllCheckBoxAndInput from "../../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import Borderline from "../../../app/components/form/BorderLine";
import { LeadListObject } from "../../../app/models/lead";
import { EmployeeListObject } from "../../../app/models/employee";
import { leadStatusColor, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  getLead: Function;
  marcomConsultantList: EmployeeListObject[];
  marcomConsultantWithoutFilterList: EmployeeListObject[];
  reassignLead: Function;
}

export default observer(function ReassignLeadModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage, setErrorMessage } = commonStore;
  const [loadingReassign, setLoadingReassign] = useState(false);
  const [loadingLead, setLoadingLead] = useState(false);
  const pageSize = Constants.maxPageSize;
  const [reassignLeadList, setReassignLeadList] = useState<LeadListObject[]>([]);
  const [toMarcomCSList, setToMarcomCSList] = useState<EmployeeListObject[]>(props.marcomConsultantList);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "Name" }).toUpperCase(),
        type: "label",
        xl: "1.65",
        inputName: "name",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
        type: "label",
        xl: "1.65",
        inputName: "mobileNo",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Race" }).toUpperCase(),
        type: "label",
        xl: "1.65",
        inputName: "raceName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
        type: "label",
        xl: "1.65",
        inputName: "mediaSource",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
        type: "label",
        xl: "2.4",
        inputName: "marcomConsultantName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Status" }).toUpperCase(),
        type: "status",
        xl: "3",
        inputName: "status",
        inputType: "text",
        statusColorFunction: leadStatusColor
      },
    ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      leadListDetails: [],
      fromMarcomConsultantId: "",
      fromMarcomConsultantName: "",
      toMarcomConsultantId: "",
      toMarcomConsultantName: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const hideModal = () => {
    validation.setFieldValue("leadListDetails", []);
    props.setModal(false);
  }

  const initLeadList = (leadListTemp?: any) => {
    let valueTemp: any = leadListTemp;
    let leadListDetailsTemp: any = [];
    if (valueTemp.length > 0) {
      leadListDetailsTemp = valueTemp.map((value) => {
        return {
          ...value,
          raceName: value.raceName || Constants.emptyData,
          status: newSpaceBeforeCapitalLetter(value.status),
          lastFollowUpDate: value.lastFollowUpDate !== Constants.invalidDate ? moment(value.lastFollowUpDate).format(Constants.displayDateFormat) : Constants.emptyData,
          isActive: true,
        }
      })
    }
    return leadListDetailsTemp;
  }

  const fetchReassignLeadAPI = async (selectedLeadIdsTemp) => {
    setLoadingReassign(true);
    await props.reassignLead({ marcomConsultantId: validation.values.toMarcomConsultantId, leadIds: selectedLeadIdsTemp });
    setLoadingReassign(false);
  }

  const onChangeConsultant = async (id: string, blnCallAPI: boolean) => {
    if (blnCallAPI) {
      setLoadingLead(true);
      let resultLead = await props.getLead({
        pageNumber: 1,
        pageSize: pageSize,
        branchId: "",
        subBrandId: "",
        sourceId: "",
        marcomConsultantId: id,
        isFilterExistingCustomer: true,
        isFilterClosedLeads: true
      }, true);
      validation.setFieldValue("fromMarcomConsultantId", id)
      validation.setFieldValue("leadListDetails", initLeadList(resultLead));
      validation.setFieldValue("toMarcomConsultantId", "")
      validation.setFieldValue("toMarcomConsultantName", "");

      let toMarcomCSListTemp = _.cloneDeep(props.marcomConsultantList)
       _.remove(toMarcomCSListTemp, {
          id: id
      });
      setToMarcomCSList(toMarcomCSListTemp);
      setReassignLeadList(resultLead);
      setLoadingLead(false);
    }
    else {
      let indexMarcomConsultant = _.findIndex(props.marcomConsultantList, { id: id });
      if (indexMarcomConsultant > -1) {
        validation.setValues((values) => ({
          ...values,
          toMarcomConsultantId: props.marcomConsultantList[indexMarcomConsultant].id,
          toMarcomConsultantName: props.marcomConsultantList[indexMarcomConsultant].preferredName
        }))
      }
    }
  };

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReassign && !successMessage) {
            hideModal();
          }
        }}
        size={"xl"}
        centered>
        {
          !reassignLeadList
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ReassignLead" })}</h5>
                </div>
                {
                  !loadingReassign && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => { hideModal(); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <div className="">
                  <Row>
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "FromMarcomCS" })}
                        name={""}
                        options={props.marcomConsultantWithoutFilterList}
                        labelField={"preferredName"}
                        valueField={"id"}
                        initialLabel={validation.values.fromMarcomConsultantId}
                        initialValue={validation.values.fromMarcomConsultantName}
                        disabled={loadingLead || loadingReassign || Boolean(successMessage)}
                        validationRequired={true}
                        onChange={(idTemp) => { onChangeConsultant(idTemp, true) }} />
                    </SingleColumnRowParent>
                  </Row>
                  <Row>
                    {
                      !loadingLead
                      &&
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralSelectDropdown
                          title={intl.formatMessage({ id: "ToMarcomCS" })}
                          name="marcomConsultantId"
                          className={"mb-3"}
                          options={toMarcomCSList}
                          labelField={"preferredName"}
                          valueField={"id"}
                          initialLabel={validation.values.toMarcomConsultantName}
                          initialValue={validation.values.toMarcomConsultantId}
                          disabled={ !(validation.values.leadListDetails.length>0) || !validation.values.fromMarcomConsultantId || loadingLead || loadingReassign || Boolean(successMessage)}
                          onChange={(idTemp) => { onChangeConsultant(idTemp, false) }}
                          validationRequired={true}
                        />
                      </SingleColumnRowParent>
                    }
                  </Row>
                </div>
                {
                  loadingLead 
                  ?
                  <Loading/>
                  :
                  validation.values.fromMarcomConsultantId !== "" &&
                  <>
                    <Borderline />
                    <div className="py-2 margin-bottom--24">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "LeadLists" })}
                      </h3>
                    </div>
                    <TableWithSelectAllCheckBoxAndInput
                      name="leadListDetails"
                      title=""
                      className="mb-3"
                      blnEditable={true}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.leadListDetails}
                      validation={validation}
                      selectAllTextFieldDisplay={"leadNo"}
                      selectAllTextFieldDisplayXLSize={2}
                      blnTableViewCss={true}
                      disabled={loadingReassign || Boolean(successMessage)} />
                    <div className="mb-3 mt-4 flex-right">
                      {
                        !Boolean(successMessage)
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-primary"
                          style={{ width: '20%' }}
                          content={intl.formatMessage({ id: "Confirm" })}
                          onClick={() => {
                            let blnErrorFound = false;
                            if (validation.values.toMarcomConsultantId === "") {
                              blnErrorFound = true;
                            }
                            if (!blnErrorFound) {
                              let valueTemp: any = validation.values.leadListDetails;
                              let selectedLeadIds: any = [];
                              for (var a = 0; a < valueTemp.length; a++) {
                                if (valueTemp[a].isActive && valueTemp[a].isActive == true) {
                                  selectedLeadIds.push(valueTemp[a].id)
                                }
                              }
                              if (selectedLeadIds && selectedLeadIds.length > 0) {
                                fetchReassignLeadAPI(selectedLeadIds)
                              } else {
                                setErrorMessage(`${intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}`)
                                return;
                              }
                            }
                            else {
                              setErrorMessage(`${(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ToMarcomCS" }) }))}`)
                              return;
                            }
                          }}
                          loading={loadingReassign}
                          disable={!(validation.values.leadListDetails.length>0) ||loadingLead || loadingReassign || Boolean(successMessage)}
                        />
                      }
                    </div>
                  </>
                }

              </div>
            </div>
        }
      </Modal>
  )
})