import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, returnPriceWithCurrency, firstDayOfMonthToCurrentDay, returnSubBrandList, displayPromotionCode } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from 'react-router-dom';
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const DiscountSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { discountSalesDetailsList, getDiscountSalesDetailsList, setDiscountSalesDetailsList, exportDiscountSalesDetails } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { purchaseType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingBranchDropdown, setLoadingBranchDropdown] = useState(false);
  const [loadingPurchaseTypeDropdown, setLoadingPurchaseTypeDropdown] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchIds: [],
      customerIds: [],
      subBrandIds: [],
      types: [],
      salesOrderNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  async function fetchBranchDropdown () {
    setLoadingBranchDropdown(true);
    let resultAPI = await getBranchDropDown();
    setLoadingBranchDropdown(false);
    return resultAPI;
  }

  async function fetchPurchaseTypeDropdown () {
    setLoadingPurchaseTypeDropdown(true);
    let resultAPI = await getStaticReferenceWithType(Constants.purchaseType);
    setLoadingPurchaseTypeDropdown(false);
    return resultAPI;
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown("", {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  useEffect(() => {
    async function fetchDiscountSalesDetailsListAPI() {            
      let aryAPI: any = [
        fetchBranchDropdown(),
        fetchPurchaseTypeDropdown()
      ];
      
      await Promise.all(aryAPI);  
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchDiscountSalesDetailsListAPI();

    return (() => {
      setDiscountSalesDetailsList([]);
    })
  }, []);

  async function fetchDiscountSalesDetailApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getDiscountSalesDetailsList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      branchIds: validation.values.branchIds,
      customerIds: validation.values.customerIds,
      types: validation.values.types,
      subBrandIds: validation.values.subBrandIds,
      salesOrderNo: validation.values.salesOrderNo
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportDiscountSalesDetailsApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    await exportDiscountSalesDetails({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      branchIds: validation.values.branchIds,
      customerIds: validation.values.customerIds,
      types: validation.values.types,
      subBrandIds: validation.values.subBrandIds,
      salesOrderNo: validation.values.salesOrderNo
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const DiscountSalesColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "salesOrderDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.salesOrderDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.salesOrderDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "itemName",
      text: intl.formatMessage({ id: "Item" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            <div>{row.itemName || Constants.emptyData} {displayPromotionCode(row.promotionCode)}</div> 
          }          
        </>
      ),
    },
    {
      dataField: "purchaseType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Count" }).toUpperCase(),
    },
    {
      dataField: "unitPrice",
      text: intl.formatMessage({ id: "UnitPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.unitPrice)}</div>
      ),
    },
    {
      dataField: "totalBeforeDiscount",
      text: intl.formatMessage({ id: "TotalBeforeDiscount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalBeforeDiscount)}</div>
      ),
    },
    {
      dataField: "discount",
      text: intl.formatMessage({ id: "Discount" }).toUpperCase()
    },
    {
      dataField: "totalAfterDiscount",
      text: intl.formatMessage({ id: "TotalAfterDiscount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAfterDiscount)}</div>
      ),
    }
  ];


  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "DiscountSalesDetails" }) })}
        title={intl.formatMessage({ id: "DiscountSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportDiscountSalesDetailsApi()
            }}
          />
        }>
        {
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              {
                returnSubBrandList().length > 0
                &&
                <DoubleColumnRowParent>
                  <DropDownWithTitleMultiSelect
                    name={"subBrandIds"}
                    title={intl.formatMessage({ id: "SubBrand" })}
                    specifyReturnFieldName={[{ "field": "subBrandIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"name"}
                    valueField={"id"}
                    options={returnSubBrandList()}
                    disabled={localLoading || loading}
                    validationRequired={false}
                    validation={validation} />
                </DoubleColumnRowParent>
              }
              {
                !getBranchUser() &&
                <DoubleColumnRowParent>
                  {
                    loadingBranchDropdown
                    ?
                    <Loading/>
                    :
                    <DropDownWithTitleMultiSelect
                      name={"branchIds"}
                      title={intl.formatMessage({ id: "Branch" })}
                      specifyReturnFieldName={[{ "field": "branchIds", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={branchDropDownList}
                      disabled={localLoading || loading || getBranchUser()}
                      validationRequired={false}
                      validation={validation} />
                  }
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                {
                  loadingPurchaseTypeDropdown
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"types"}
                    title={intl.formatMessage({ id: "Type" })}
                    specifyReturnFieldName={[{ "field": "types", "value": "key" }]}
                    returnFieldWithLabel={false}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={purchaseType}
                    disabled={localLoading || loading}
                    blnValueWithNewSpace={true}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                {
                  <DropDownWithTitleMultiSelect
                    name={"customerIds"}
                    title={intl.formatMessage({ id: "Customer" })}
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    specifyReturnFieldName={[{ "field": "customerIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={customerDropdownList}
                    disabled={localLoading || loading}
                    blnSupportCustomSearch={true}
                    onChangeFunction={()=> {
                      clearCustomerDropdownList();
                    }}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomerDropdown(false);
                    }}
                    loadingCustomSearch={loadingCustomerDropdown}
                    setLoadingCustomSearch={setLoadingCustomerDropdown}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="salesOrderNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchDiscountSalesDetailApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>            
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DiscountSalesColumns}
              keyField={"randomId"}
              data={discountSalesDetailsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "DiscountSalesDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getDiscountSalesDetailsList}
              branchIds={validation.values.branchIds}
              customerIds={validation.values.customerIds}
              types={validation.values.types}
              subBrandIds={validation.values.subBrandIds}
              salesOrderNo={validation.values.salesOrderNo}
              dateRange={selectedDateRange} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(DiscountSalesDetails);
