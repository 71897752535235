import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Modal } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import ModalViewLayout from "../../app/components/layout/ModalViewLayout";
import { burgerMenu } from "../../app/models/common";

interface Props {
	id: string;
	blnShow: boolean;
	setModal: Function;
	reloadData: Function;
  viewEPFContribution: boolean;
}

const EPFContributionDetail = (props: Props) => {
	//Use INTL
	const intl = useIntl();

	//Use Store
	const { epfContributionStore, commonStore } = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addEPFContribution, updateEPFContribution, epfContributionDetail, setEPFContributionDetail, getEPFContribution, getEPFContributionWithId } = epfContributionStore;
	const [viewModalAction , setViewModalAction] = useState(props.viewEPFContribution);
	const displayTitle = `${!props.id ? intl.formatMessage({ id: "Add" }) : viewModalAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })} ${intl.formatMessage({ id: "EPFContribution" })}`;
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		initialValues: epfContributionDetail || {
			name: "",
			rate: undefined,
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "EPFContributionName" }) })),
			rate: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Rate" }) })),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			try {
				if (!props.id) {
					await addEPFContribution(valuesTemp);
				} else {
					await updateEPFContribution(valuesTemp);
				}
				props.reloadData();
			}
			finally {
				validation.setSubmitting(false);
			}
		},
	});

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || viewModalAction;

	useEffect(() => {
		async function fetchEPFContributionDetailAPI() {
			setLoading(true);
			if (props.id) {
				await getEPFContributionWithId(props.id);
			}
			setLoading(false);
		}

		if (!props.id) {
			setEPFContributionDetail(undefined);
		}

		if(props.viewEPFContribution && checkPermission([PermissionConstants.UpdateEpfContribution])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { setViewModalAction(false);} })
    }

		fetchEPFContributionDetailAPI();

		return (() => {
			if (props.id) {
				setEPFContributionDetail(undefined);
			}
		})
	}, []);

	useEffect(() => {
		if (successMessage) {
			setTimeout(() => {
				dismissModal()
			}, Constants.timeoutNavigation)
		}
	}, [successMessage])

	useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	const dismissModal = () => {
		props.setModal(false);
		setErrorMessage("");
		setSuccessMessage("");
	}

	return (
		<ModalViewLayout 
      title={displayTitle}
      blnShow={props.blnShow}
      auditTrailId={viewModalAction ? props.id : ""}
			burgerMenuList={viewModalAction ? burgerMenuListContent: []}>
      {loading && !successMessage ? (
        <div style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Loading />
        </div>
      ) : (
        <Form
          className="standard-layout"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          onBlur={() => {
            if (errorMessage || successMessage) {
              setErrorMessage("");
              setSuccessMessage("");
            }
          }}
        >
          <Row form={+true}>
            {" "}
            {/* +true = Convert boolean to numbers*/}
            <Col xl={12}>
              <GeneralInput
                title={intl.formatMessage({ id: "Name" })}
                name="name"
                className="mb-3"
                type="text"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "Rate" })}
                name="rate"
                type="number"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
            </Col>
          </Row>
          <GeneralSubmitAndCancelBtn
            className="flex-right"
            successMessage={successMessage}
            hidePrimaryButton={viewModalAction}
            viewAction={false}
            validation={validation}
            cancelButtonFunction={() => dismissModal()}
          />
        </Form>
      )}
		</ModalViewLayout>
	);
};

export default observer(EPFContributionDetail);
