import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Row, UncontrolledTooltip } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import { StockReturnToHQListObject } from "../../app/models/stockReturnToHQ";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { stockReturnToHQViewAvailable, stockReturnToHQStatusColor, checkPermission, newSpaceBeforeCapitalLetter, arraySpliceInAllValue, stockReturnToHQEditAvailable, getBranchUser, getBranchId, getBranchName, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { BranchListObject } from "../../app/models/branch";
import { PaginationRequestBody } from "../../app/models/pagination";

const StockReturnToHQ = () => {
  const intl = useIntl();
  //Use Store
  const { stockReturnToHQStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt, windowSize} = commonStore;
  const { stockReturnToHQList, stockReturnToHQPaginationParams, getStockReturnToHQ, deleteStockReturnToHQ, setStockReturnToHQPaginationParams } = stockReturnToHQStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { stockInOutStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<StockReturnToHQListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedStockReturnToHQStatus, setSelectedStockReturnToHQStatus] = useState<string>(AllStatus);
  const [selectedStockReturnToHQStatusId, setSelectedStockReturnToHQStatusId] = useState<number | string | undefined>(" ");
  const [stockReturnToHQStatusList, setStockReturnToHQStatusList] = useState<StaticReferenceDataObject[]>([]);
  const blnPermissionCreateStockReturnToHQ = checkPermission([PermissionConstants.CreateStockReturnToHQ]);
  const blnPermissionUpdateStockReturnToHQ = checkPermission([PermissionConstants.UpdateStockReturnToHQ]);
  const blnPermissionReviewStockReturnToHQ = checkPermission([PermissionConstants.ReviewStockReturnToHQ]);

  let stockReturnToHQRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    stockReturnToHQStatus: selectedStockReturnToHQStatusId,
    stockReturnToHQStatusName: selectedStockReturnToHQStatus
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchStockReturnToHQListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.stockReturnToHQ)
      if (blnBackNavigation) {
        if (stockReturnToHQPaginationParams) {
          setCurrentPage(stockReturnToHQPaginationParams.pageNumber)
          setSelectedBranchId(stockReturnToHQPaginationParams.branchId || "")
          setSelectedBranchName(stockReturnToHQPaginationParams.branchName || "")
          setSelectedStockReturnToHQStatus(stockReturnToHQPaginationParams.stockReturnToHQStatusName || "");
          setSelectedStockReturnToHQStatusId(stockReturnToHQPaginationParams.stockReturnToHQStatus)
          stockReturnToHQRequestBody = stockReturnToHQPaginationParams;
        }
      }
      else {
        setStockReturnToHQPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchStockReturnToHQAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.stockReturnToHQStatus, AllStatus),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setStockReturnToHQStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageStockReturnToHQ], true)) {
      return;
    }

    fetchStockReturnToHQListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.stockReturnToHQ)
        if (!blnSetParams) {
          setStockReturnToHQPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedStockReturnToHQStatusId || selectedStockReturnToHQStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchStockReturnToHQAPI(true);
    }
  }, [selectedStockReturnToHQStatusId, selectedBranchId]);

  async function fetchStockReturnToHQAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getStockReturnToHQ(stockReturnToHQRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const StockReturnToHQColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "stockReturnNo",
      text: `${intl.formatMessage({ id: "StockReturnToHQNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.requestedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "approverName",
      text: intl.formatMessage({ id: "Approver" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.approverName || Constants.emptyData
      ),
    },
    {
      dataField: "approvedDate",
      text: intl.formatMessage({ id: "ApprovedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.approvedDate === Constants.invalidDate ? Constants.emptyData : moment(row.approvedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={stockReturnToHQStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.stockReturnToHQ}/view/${row.id}`}
              type={`view`}/>
            {
              (blnPermissionUpdateStockReturnToHQ || blnPermissionReviewStockReturnToHQ)
              &&
              stockReturnToHQEditAvailable(row.status)
              &&
              <TableActionButton 
                to={`/${RoutesList.stockReturnToHQ}/view/${row.id}`}
                type={`edit`}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockReturnToHQ" }) })}
        title={intl.formatMessage({ id: "StockReturnToHQ" })}
        addButton={
          blnPermissionCreateStockReturnToHQ
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "StockReturnToHQ" }) })}
            onClick={() => { history.push(`/${RoutesList.stockReturnToHQ}/add`) }}
            linkTo={`/${RoutesList.stockReturnToHQ}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchDropDownListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                validationRequired={true}
                onChange={(value) => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "StockReturnToHQ" }) })}
                name="stockReturnToHQOutStatus"
                options={stockReturnToHQStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedStockReturnToHQStatus}
                initialValue={selectedStockReturnToHQStatusId}
                setFieldLabel={setSelectedStockReturnToHQStatus}
                setFieldValue={setSelectedStockReturnToHQStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={StockReturnToHQColumns}
              data={stockReturnToHQList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockReturnToHQ" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              stockReturnToHQStatus={selectedStockReturnToHQStatusId}
              stockReturnToHQStatusName={selectedStockReturnToHQStatus}
              requestAPI={getStockReturnToHQ}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(StockReturnToHQ);
