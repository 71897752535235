import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Modal
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import { checkPermission, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import AuditLogDetail from "./AuditLogDetail";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import TableActionButton from "../../app/components/table/TableActionButton";
import { isTablet } from "react-device-detect";

interface Props {
  blnModal?: boolean;
  setModal?: Function;
  auditLogId?: string;
  skipPermisssion?: boolean;
}

const AuditLog = (props: Props) => {
  const intl = useIntl();
  //Use Store
  const { auditLogStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, setErrorMessage, setSuccessMessage } = commonStore;
  const { auditLogList, getAuditLog } = auditLogStore;
  const { getStaticReferenceWithType, getStaticReferenceAuditModules } = staticReferenceStore;

  //Data List
  const [auditLogDetailModal, setAuditLogDetailModal] = useState(false);
  const [selectedAuditLogDetailID, setSelectedAuditLogDetailID] = useState("");
  const allDisplay = `${intl.formatMessage({ id: "AllType" })}`;
  const [auditLogEntityTypeFinal, setAuditLogEntityTypeFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedAuditLogEntityType, setSelectedAuditLogEntityType] = useState(allDisplay);
  const [selectedAuditLogEntityTypeName, setSelectedAuditLogEntityTypeName] = useState(" ");
  const [auditTypeFinal, setAuditTypeFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedAuditType, setSelectedAuditType] = useState(allDisplay);
  const [selectedAuditTypeId, setSelectedAuditTypeId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: "", toDate: ""});
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "AuditLog" }) }`;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchAuditLogAPI() {
      setLoading(true);
      let aryAPI: any = [
        getAuditLog({ pageNumber: currentPage, pageSize: pageSize, entityName: selectedAuditTypeId, entityId: props.auditLogId || " ", auditType: selectedAuditTypeId }),
        !props.auditLogId && getStaticReferenceAuditModules(),
        !props.auditLogId && getStaticReferenceWithType(Constants.auditType, allDisplay)
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI.length > 2) {
        setAuditLogEntityTypeFinal(resultAPI[1]);
        setAuditTypeFinal(resultAPI[2]);
      }
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!props.skipPermisssion) {
      if (!checkPermission([PermissionConstants.ManageAuditLog], true)) {
        return;
      }
    }

    fetchAuditLogAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchAuditLogAPI(true);
    }
  }, [selectedAuditLogEntityTypeName, selectedAuditTypeId, selectedDateRange])

  async function fetchAuditLogAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getAuditLog({
      pageNumber: currentPage,
      pageSize: pageSize,
      entityName: selectedAuditLogEntityTypeName !== allDisplay ? removeAllSpace(selectedAuditLogEntityTypeName) : "",
      auditType: selectedAuditTypeId,
      dateRange: selectedDateRange
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const AuditLogColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
        <div className="">
          {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.createdAt).format(Constants.displayTimeFormat)}
        </div>
      </>
      ),
    },
    {
      dataField: "entityChanged",
      text: intl.formatMessage({ id: "Module" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.entityChanged)}
        </div>
      ),
    },
    {
      dataField: "auditType",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.auditType)}
        </div>
      ),
    },
    {
      dataField: "createdBy",
      text: intl.formatMessage({ id: "CreatedBy" }).toUpperCase(),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
    },
    {
      dataField: "actionButton",
      isDummyField: true,
      text: "Action",
      headerStyle: { textAlign: 'center' },   
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to="#"
              onClick={() => {
                setSelectedAuditLogDetailID(row.id)
                setAuditLogDetailModal(true);
              }}
              type="viewModal"/>
          </div>
        </>
      ),
    },
  ];

  const displayContent = () => {
    return (
      localLoading
      ?
      <Loading />
      :
      <>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={AuditLogColumns}
          data={auditLogList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AuditLog" }) })}
          objSorted={defaultSorted}
          bordered={props.auditLogId ? true : false}
          entityName={selectedAuditLogEntityTypeName == allDisplay ? " " : selectedAuditLogEntityTypeName !== " " ? removeAllSpace(selectedAuditLogEntityTypeName) : selectedAuditLogEntityTypeName}
          entityId={props.auditLogId || " "}
          auditType={selectedAuditTypeId}
          requestAPI={getAuditLog}
          dateRange={selectedDateRange} />
        {
          auditLogDetailModal &&
          <AuditLogDetail
            id={selectedAuditLogDetailID}
            blnShow={auditLogDetailModal}
            setModal={setAuditLogDetailModal}
          />
        }
      </>
    )
  }

  const dropDownStyles = {
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? Constants.white : data.isLegacy ? Constants.lightgrey : Constants.black
      };
    },
  };

  return (
    <div>
      {
        !props.blnModal
        ?
        <ListViewLayout
          metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AuditLog" }) })}
          title={intl.formatMessage({ id: "AuditLog" })}>
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "AuditLogEntityType" })}
                name="auditLogEntityType"
                options={auditLogEntityTypeFinal}
                initialLabel={selectedAuditLogEntityType}
                initialValue={selectedAuditLogEntityTypeName}
                setFieldLabel={setSelectedAuditLogEntityType}
                setFieldValue={setSelectedAuditLogEntityTypeName}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                dropDownStyles={dropDownStyles}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "AuditType" })}
                name="auditType"
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                options={auditTypeFinal}
                initialLabel={selectedAuditType}
                initialValue={selectedAuditTypeId}
                setFieldLabel={setSelectedAuditType}
                setFieldValue={setSelectedAuditTypeId}
                disabled={localLoading || loading}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralDateRange 
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange}/>
            </DoubleColumnRowParent>
          </Row>
          {displayContent()}
        </ListViewLayout>
        :
        <Modal
          isOpen={true}
          size="xl"
          style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
          centered
          toggle={()=> {
            if (props.setModal) {
              props.setModal(false)
            }
          }}>
          <div className="standard-layout">
            <LineBreakWithTittle
              paddingBottom="0px"
              title={displayTitle}
              h4Title />
            <div className={loading || localLoading ? "margin-top-16" : ""}>
              {displayContent()}
            </div>
            <GeneralSubmitAndCancelBtn
              className="flex-right"
              successMessage={successMessage}
              hidePrimaryButton={true}
              viewAction={false}
              cancelButtonFunction={() => {  props.setModal && props.setModal(false)}}
            />
          </div>
        </Modal>
      }
    </div>
  );
}

export default observer(AuditLog);
