import { PaginationRequestBody } from "../models/pagination";
import { WarehouseListObject, WarehouseAddObject, WarehouseUpdateObject, WarehouseDetailObject, WarehousePurchaseOrderDropdownRequestBody, WarehousePurchaseOrderForProductObject, WarehouseProductObject, WarehouseProductsList } from "../models/warehouse";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class warehouseStore {
  warehouseList: WarehouseListObject[] = [];
  warehouseDropdownList: WarehouseListObject[] = [];
  warehousePurchaseOrderDropdownList: WarehousePurchaseOrderForProductObject[] = [];
  warehouseDetail: WarehouseDetailObject | undefined = undefined;
  warehouseProducts: WarehouseProductsList[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.warehouseList = [];
    this.warehouseDropdownList = [];
    this.warehouseProducts = [];
    this.warehousePurchaseOrderDropdownList = [];
    this.warehouseDetail = undefined;
  }

  setWarehouseDetail = (warehouseDetail: WarehouseDetailObject | undefined) => {
    this.warehouseDetail = warehouseDetail;
  }
  
  getWarehouse = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultWarehouse = await agent.Warehouse.warehouseList(PaginationRequestBody);
      runInAction(() => {
        this.warehouseList = resultWarehouse.data;
        store.commonStore.setTotalItem(resultWarehouse.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.warehouseList = [];
    }
  }

  getWarehouseDropdown = async () => {
    try{
      const resultWarehouseDropdown = await agent.Warehouse.warehouseDropdownList();
      runInAction(() => {
        this.warehouseDropdownList = resultWarehouseDropdown;
      });
      return Promise.resolve(resultWarehouseDropdown)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.warehouseDropdownList = [];
    }
  }

  getWarehouseProducts = async (warehouseId: string) => {
    try{
      const resultWarehouseProducts = await agent.Warehouse.warehouseProducts(warehouseId);
      runInAction(() => {
        this.warehouseProducts = resultWarehouseProducts;
      });
      
      return Promise.resolve(resultWarehouseProducts);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.warehouseProducts = [];
    }
  }

  getWarehouseProductDetails = async (warehouseId: string, productId: string) => {
    try{
      const resultWarehouseProductDetails = await agent.Warehouse.warehouseProductDetails(warehouseId, productId);
      return Promise.resolve(resultWarehouseProductDetails);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getWarehousePurchaseOrderDropdown = async (warehousePurchaseOrderDropdownRequestBody: WarehousePurchaseOrderDropdownRequestBody) => {
    try{
      const resultWarehousePurchaseOrderProductList  = await agent.Warehouse.warehousePurchaseOrderDropdownList(warehousePurchaseOrderDropdownRequestBody);

      return Promise.resolve(resultWarehousePurchaseOrderProductList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.warehousePurchaseOrderDropdownList = [];
    }
  };

  getWarehouseWithId = async (id: string) => {
    try{
      const resultWarehouseDetail = await agent.Warehouse.warehouseDetail(id);
      runInAction(() => {
        this.warehouseDetail = resultWarehouseDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.warehouseDetail = undefined;
    }
  }

  addWarehouse = async (warehouseRequestBody: WarehouseAddObject) => {
    try{
      await agent.Warehouse.addWarehouse(warehouseRequestBody);
      store.commonStore.setSuccessMessage(`WarehouseAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateWarehouse = async (warehouseRequestBody: WarehouseUpdateObject) => {
    try{
      await agent.Warehouse.updateWarehouse(warehouseRequestBody);
      store.commonStore.setSuccessMessage(`WarehouseUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteWarehouse  = async (id: string, name: string) => {
    try {
      await agent.Warehouse.deleteWarehouse(id);
      store.commonStore.setSuccessMessage(`WarehouseDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}