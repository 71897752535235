import { useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { DateRangeInterface } from "../../../app/models/common";
import moment from "moment";
import _ from "lodash";
import { ServiceTreatmentListObject } from "../../../app/models/serviceTreatment";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { Constants } from "../../../app/constants/Constants";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  serviceTreatmentDropdownList: ServiceTreatmentListObject[];
}

export default observer(function TodayAppointmentMadeSummaryModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, commonStore } = useStore();
  const { exportTodayAppointmentMadeSummary } = reportMarcomStore;
  const { windowSize } = commonStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({fromDate: moment().format(Constants.displayDateFormat), toDate: moment().format(Constants.displayDateFormat)});

  const disabledFieldInput = localLoading;

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      serviceTreatmentIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  const hideModal = () => {
    props.setModal(false);
  }

  const exportTodayAppointmentMadeSummaryReport = async () => {
    setLocalLoading(true);
    await exportTodayAppointmentMadeSummary({
      fromDate: selectedDateRange.fromDate,
      toDate: selectedDateRange.toDate,
      serviceTreatmentIds: validation.values.serviceTreatmentIds
    });
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      size='lg'
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      toggle={() => {
        if(!localLoading) {
          hideModal();
        }
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "TodayAppointmentMadeSummary"})}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "flex-align-items-center-without-height" : ""}`}>
          <Row className={`flex-1`}>
            <Col xl="6">
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "AppointmentDate"})})}
                name={"DateRange"}
                disabled={localLoading}
                onChangeFunction={onSelectDateRange}
                initialValue={selectedDateRange}
                validationRequired={true} />
            </Col>
            <Col xl="6">
              <DropDownWithTitleMultiSelect
                name={"serviceTreatmentIds"}
                title={intl.formatMessage({ id: "ServiceTreatment" })}
                specifyReturnFieldName={[{ "field": "serviceTreatmentIds", "value": "id" }]}
                returnFieldWithLabel={false}
                labelField={"name"}
                valueField={"id"}
                options={props.serviceTreatmentDropdownList}
                disabled={localLoading}
                validation={validation} />
            </Col>
          </Row>
          <MyButton
                type="button"
                class={`btn btn-success ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-12 margin-left-16" : ""}`}
                content={intl.formatMessage({ id: "Export" }).toUpperCase()}
                loading={disabledFieldInput}
                disable={disabledFieldInput}
                onClick={() => {
                  exportTodayAppointmentMadeSummaryReport()
                }}
              />
        </div>
      </div>
    </Modal>
  )
});