import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { DatumObject, RowDataObject } from "../../../models/dashboard";
import { WindowObject } from "../../../models/common";
import { Constants } from "../../../constants/Constants";

interface Props {
	data?: DatumObject[];
  rowData?: RowDataObject;
  windowSize: WindowObject;
}

interface PieObject {
  name: string;
  value: number;
}

const PieChartFromEChart = (props: Props) => {
  const [aryFinalData, setAryFinalData] = useState<PieObject[]>([]);

  useEffect(()=> {
    let aryDataTemp : any = [];
    let aryCategoryTemp : any = [];
    let aryFinalTemp: PieObject[] = [];
    if (props.data) {
      if (props.data.length > 0) {
        aryDataTemp = props.data[0].data;
      }
    }
    if (props.rowData) {
      if (props.rowData.categories) {
        aryCategoryTemp = props.rowData.categories; 
      }
    }

    aryDataTemp.map((valueAryDataTemp, indexAryDataTemp)=> {
      aryFinalTemp.push({
        name: aryCategoryTemp.length > indexAryDataTemp ? aryCategoryTemp[indexAryDataTemp] : "",
        value: valueAryDataTemp
      })
    })
    setAryFinalData(aryFinalTemp);
  }, [props.data, props.rowData])

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} {b} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: props.rowData?.categories || [],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
    series: [
      {
        name: "",
        type: "pie",
        radius: "55%",
        top: props.windowSize.innerWidth < Constants.innerWidthCompare770 ? '30%' : '0%',
        center: ["50%", "60%"],
        data: aryFinalData,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactEcharts style={{ height: props.windowSize.innerWidth < Constants.innerWidthCompare770 ? "420px" : "235px" }} option={options} />
    </div>
  );
};

export default PieChartFromEChart;
