import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { Constants } from "../../constants/Constants";
import { useStore } from "../../stores/store";
import Loading from "../loading/Loading";
type Props = {
  column_xl: string;
  column_sm: string;
  heading_txt: string;
  headerStyle?: object;
  body_txt: string;
  headerPositionTop: boolean;
  content: any;
  contentStyle?: object; 
  contentTopOfScope?: any;
  contentBottomOfScope?: any;
  blnOffMarginTop?: boolean;
  loading: boolean;
}

const ChartWithTitle = (props: Props) => {
  const  { column_xl, column_sm, heading_txt, headerStyle, body_txt, headerPositionTop, content, contentStyle, contentTopOfScope, contentBottomOfScope } = props;
  //Use Store
  const { commonStore } = useStore();
  const { windowSize } = commonStore;
  
  return (
    <Col xl={column_xl} className={Constants.innerWidthCompare>windowSize.innerWidth ? !props.blnOffMarginTop ? "margin-top-48" : "margin-top-32" : ""} style={{...headerStyle}}>
      {contentTopOfScope}
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', ...contentStyle }}>
        <Row className="justify-content-center">
          <Col sm={column_sm}>
            <div className="text">
              {
                headerPositionTop
                ?
                <>
                  {heading_txt ? <h5 className="margin-bottom-0 font-size-24">{heading_txt}</h5> : <div/>}
                  {heading_txt && body_txt ? <p className="text-muted">{body_txt}</p> : <div/>}
                </>
                :
                <>
                  {heading_txt ? <p className="margin-bottom-0 text-muted">{heading_txt}</p> : <div/>}
                  {heading_txt && body_txt ? <h5 className="font-size-24">{body_txt}</h5> : <div/>}
                </>
              }
            </div>
          </Col>
        </Row>
        {
          props.loading
          ?
          <Loading/>
          :
          content
        }
      </div>
      {
        !props.loading
        &&
        contentBottomOfScope
      }
    </Col>
  );
}

export default observer(ChartWithTitle);