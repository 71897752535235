import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, newSpaceBeforeCapitalLetter, getBranchId, getBranchName, firstDayOfMonthToCurrentDay, getPurchaseTypeIcon, arraySpliceInAllValue } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from "react-router-dom"
import { BranchListObject } from "../../../app/models/branch";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const AppolousFreePackageAndProduct = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppolousStore, branchStore, customerStore, productStore, packageStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, windowSize, setErrorMessage } = commonStore;
  const { appolousFreePackageAndProductList, getAppolousFreePackageAndProductList, setAppolousFreePackageAndProductList, exportAppolousFreePackageAndProduct } = reportAppolousStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getProductDropdown } = productStore;
  const { getPackageDropdown } = packageStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [selectedProductName, setSelectedProductName] = useState(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState(" ");
  const [productListFinal, setProductListFinal] = useState<ObjectForDropdown[]>([]);
  const AllPackage = `${intl.formatMessage({ id: "AllPackage" })}`;
  const [selectedPackageName, setSelectedPackageName] = useState(AllPackage);
  const [selectedPackageId, setSelectedPackageId] = useState(" ");
  const [packageListFinal, setPackageListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAppolousReport = checkPermission([PermissionConstants.ExportAppolousReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvanceFilter, setBlnAdvanceFilter] = useState(false);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];


  useEffect(() => {
    async function fetchAppolousFreePackageAndProductListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown(),
        getPackageDropdown({ branchId: "" })
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setProductListFinal(arraySpliceInAllValue(resultAPI[1], AllProduct, false, "name", "id"));
      setPackageListFinal(arraySpliceInAllValue(resultAPI[2], AllPackage, false, "name", "id"));
      
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppolousReport], true)) {
      return;
    }

    fetchAppolousFreePackageAndProductListAPI();

    return (() => {
      setAppolousFreePackageAndProductList([]);
    })
  }, []);

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
    }
  }, [selectedBranchId])

  const fetchAppolousFreePackageAndProductListWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportAppolousFreePackageAndProduct({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, customerId: selectedCustomerId, packageId: selectedPackageId, productId: selectedProductId });
    }
    else {
      setCurrentPage(1);
      await getAppolousFreePackageAndProductList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange, customerId: selectedCustomerId, packageId: selectedPackageId, productId: selectedProductId });
    }
    setLocalLoading(false);
  };
  

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const displayOptionalRow = (mainComponent: any) => {
    return (
      <div className="flex-align-items-center">
        {mainComponent()}
        <div style={{marginTop: '10px', backgroundColor: 'lightgrey', padding: '4px', borderRadius: '20px', marginLeft: "18px"}}>
          <p className="text-muted margin-top-0 margin-bottom-0">OR</p>
        </div>
      </div>
    )
  }

  //Table Content UI
  const AppolousFreePackageAndProductColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "actionOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.actionOn !== Constants.invalidDateTime ? moment(row.actionOn).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.actionOn !== Constants.invalidDateTime ? moment(row.actionOn).format(Constants.displayTimeFormat) : ""}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerNo",
      text: intl.formatMessage({ id: "CustomerNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerNo || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName || Constants.emptyData}</div>
          }          
        </>
      ),
    },
    {
      dataField: "itemName",
      text: intl.formatMessage({ id: "PurchasedItem" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          <span>{getPurchaseTypeIcon(row.itemType)}{row.itemName}</span>
        </div>
      ),
    },
    {
      dataField: "itemType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.itemType || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "itemQuantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.itemQuantity)}
            statusBackgroundColor={Math.sign(row.itemQuantity) == -1 ? Constants.peach : Constants.tabBlue} />
        </>
      ),
    },
    {
      dataField: "actionBy",
      text: intl.formatMessage({ id: "actionBy" }).toUpperCase(),
      headerStyle: { width: '100px' },
      formatter: (cellContent, row) => (
        <div>
          {row.actionBy || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      headerStyle: { width: '600px' },
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppolousFreePackageAndProduct" }) })}
        title={intl.formatMessage({ id: "AppolousFreePackageAndProduct" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppolousReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchAppolousFreePackageAndProductListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Appolous-Free-Package-And-Product.aspx`}>
        <>
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchId"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
                />
            </DoubleColumnRowParent>
            {
              displayCustomerDropdown &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              </DoubleColumnRowParent>
            }
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "CreatedAt"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange}
                initialValue={selectedDateRange}
                validationRequired={true} />
            </DoubleColumnRowParent>
          </Row>
          <hr/>
          <div className={`flex-align-items-center margin-bottom-16`}>
            <div style={{backgroundColor: Constants.lightgreyfaded, padding: '4px', paddingLeft: '16px', paddingRight: '16px', borderRadius: '8px'}} 
              onClick={()=> {
                setBlnAdvanceFilter((valueAdvanceFilter)=> !valueAdvanceFilter);
              }}>
              <Label className="margin-bottom-0">{intl.formatMessage({ id: "AdvanceFilter" })}</Label>
              {blnAdvanceFilter ? <ChevronUpIcon/> : <ChevronDownIcon/>}
            </div>
          </div>
          {
            <Row style={{display: !blnAdvanceFilter ? "none": ""}}>
              <DoubleColumnRowParent>
              {
                displayOptionalRow(()=> (
                  
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Package" })}
                    name="packageId"
                    className={"flex-1 mb-3"}
                    labelField={"name"}
                    valueField={"id"}
                    options={packageListFinal}
                    initialLabel={selectedPackageName}
                    initialValue={selectedPackageId}
                    setFieldLabel={setSelectedPackageName}
                    setFieldValue={setSelectedPackageId}
                    disabled={localLoading || loading}
                    validationRequired={true}
                  />
                ))
              }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Product" })}
                  name="productId"
                  className={"flex-1 mb-3"}
                  labelField={"name"}
                  valueField={"id"}
                  options={productListFinal}
                  initialLabel={selectedProductName}
                  initialValue={selectedProductId}
                  setFieldLabel={setSelectedProductName}
                  setFieldValue={setSelectedProductId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            </Row>         
          }
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary margin-bottom-18 `}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchAppolousFreePackageAndProductListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppolousFreePackageAndProductColumns}
              keyField={"randomId"}
              data={appolousFreePackageAndProductList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousFreePackageAndProduct" }) })}
              objSorted={defaultSorted}
              requestAPI={getAppolousFreePackageAndProductList}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              customerId={selectedCustomerId}
              productId={selectedProductId}
              packageId={selectedPackageId}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppolousFreePackageAndProduct);
