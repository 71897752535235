import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, deliveryOrderStatusColor, displayTypeWithColor, getPurchaseTypeIcon, newSpaceBeforeCapitalLetter, paymentAdjustmentStatusColor, productRedemptionStatusColor, returnPaymentIcon, returnPriceWithCurrency, exchangeToCreditStatusColor, salesOrderStatusColor, virtualWarehouseStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import { Constants } from "../../app/constants/Constants";
import moment from "moment";
import { Card, CardBody, Row, UncontrolledTooltip } from "reactstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CustomerSalesOrderSiteMapObject } from "../../app/models/customerSalesOrder";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import CardGiftcardIcon from 'mdi-react/CardGiftcardIcon';
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import HomeVariantIcon from "mdi-react/HomeVariantIcon"
import AccountIcon from "mdi-react/AccountIcon";
import CashSyncIcon from "mdi-react/CashSyncIcon";
import CreditCardSyncIcon from "mdi-react/CreditCardSyncIcon";
import PencilOffOutlineIcon from "mdi-react/TextBoxRemoveOutlineIcon";
import GiftOpenIcon from "mdi-react/GiftOpenIcon";
import GiftIcon from "mdi-react/GiftIcon";
import BriefcaseArrowLeftRightOutlineIcon from "mdi-react/BriefcaseArrowLeftRightOutlineIcon";

const CustomerSalesOrderSiteMap = () => {
	//Use Params
	const { id } = useParams();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const { customerSalesOrderStore, commonStore } = useStore();
	const {
		loading,
		setLoading,
    windowSize
	} = commonStore;
	const { customerSalesOrderSiteMapDetailFromStore, setCustomerSalesOrderSiteMapDetailFromStore, getCustomerSalesOrderSiteMapWithId } = customerSalesOrderStore;
	const displayTitle = `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "CustomerSalesOrder"})} ${intl.formatMessage({id: "SiteMap"})}`;
	const breadCrumbList = [{ title: intl.formatMessage({id: "CustomerSalesOrder"}), urlPath: RoutesList.customerSalesOrder }, { title:   `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "CustomerSalesOrder"})}`, urlPath: `${RoutesList.customerSalesOrder}/view/${id}` }];
  const [customerSalesOrderSiteMapDetail, setCustomerSalesOrderSiteMapDetail] = useState<any>(undefined);
  const [displayPaymentHistory, setDisplayPaymentHistory] = useState(true);
  const [displayDeliverOrder, setDisplayDeliverOrder] = useState(true);
  const [displayDeliverOrderSystemAutoGenerated, setDisplayDeliverOrderSystemAutoGenerated] = useState(true);
  const [displayPaymentAdjustment, setDisplayPaymentAdjustment] = useState(true);
  const [displayExchangeToCredit, setDisplayExchangeToCredit] = useState(true);
  const [displayProductRedemption, setDisplayProductRedemption] = useState(true);
  const [displayChangePaymentMethod, setDisplayChangePaymentMethod] = useState(true);
  const [displayReversedVirtualWarehouse, setDisplayReversedVirtualWarehouse] = useState(true);
  const [siteMapTypeList, setSiteMapTypeList] = useState<string[]>([]);

  const paymentColor = Constants.lightseagreen;
  const deliverOrderColor = Constants.lightseablue;
  const deliverOrderSystemAutoGeneratedColor = Constants.savoryblue;
  const paymentAdjustmentColor = Constants.candypink;
  const creditExchangeColor = Constants.lightorange;
  const productRedemptionColor = Constants.lavender;
  const changePaymentMethodColor = Constants.lightbluev2;
  const reversedVirtualWarehouseColor = Constants.chocalate;

  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionManagePaymentAdjustment = checkPermission([PermissionConstants.ManagePaymentAdjustment]);
  const blnPermissionManageExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const blnPermissionManageChangePaymentMethod = checkPermission([PermissionConstants.ManageChangePaymentMethod]);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);

	useEffect(() => {
		async function fetchCustomerSalesOrderSiteMapAPI() {
			setLoading(true);
      if (id) {
			  let resultAPI : CustomerSalesOrderSiteMapObject | undefined = await getCustomerSalesOrderSiteMapWithId(id);
        if (resultAPI) {
          let resultAPITemp : any = _.cloneDeep(resultAPI);
          if (resultAPITemp.paymentHistories && resultAPITemp.customerDeliverOrders) {
            let siteMapTypeListTemp : string[] = [];

            if (resultAPITemp.paymentHistories.length > 0) {
              siteMapTypeListTemp.push(Constants.payment);
            }
            resultAPITemp.timeLineDetails = resultAPITemp.paymentHistories.map((valuePaymentHistories)=> ({
              ...valuePaymentHistories,
              type: Constants.payment,
              date: valuePaymentHistories.paymentDate
            }))

            resultAPITemp.customerDeliverOrders.map((valueCustomerDeliverOrders)=> {
              if (resultAPITemp) {
                siteMapTypeListTemp.push(!valueCustomerDeliverOrders.isSystemAutoGenerated ? Constants.deliverOrder : Constants.autoDeliverOrder);
                resultAPITemp.timeLineDetails.push({
                  ...valueCustomerDeliverOrders,
                  type: Constants.deliverOrder,
                  date: valueCustomerDeliverOrders.performDateTime
                })
              }
            })

            resultAPITemp.paymentAdjustments.map((valuePaymentAdjustment)=> {
              if (resultAPITemp) {
                siteMapTypeListTemp.push(valuePaymentAdjustment.type === Constants.changePaymentMethod ? Constants.changePaymentMethod : Constants.paymentAdjustment);
                resultAPITemp.timeLineDetails.push({
                  ...valuePaymentAdjustment,
                  type: valuePaymentAdjustment.type === Constants.changePaymentMethod ? Constants.changePaymentMethod : Constants.paymentAdjustment,
                  date: valuePaymentAdjustment.status === Constants.processing ? valuePaymentAdjustment.adjustmentDate : valuePaymentAdjustment.actionDate
                })
              }
            })

            resultAPITemp.creditExchangeRequests.map((valueCreditExchangeRequest)=> {
              if (resultAPITemp) {
                siteMapTypeListTemp.push(Constants.creditExchange);
                resultAPITemp.timeLineDetails.push({
                  ...valueCreditExchangeRequest,
                  type: Constants.creditExchange,
                  date: valueCreditExchangeRequest.issueDate
                })
              }
            })

            resultAPITemp.productRedemptions.map((valueProductRedemptions)=> {
              if (resultAPITemp) {
                siteMapTypeListTemp.push(Constants.productRedemptionNote);
                resultAPITemp.timeLineDetails.push({
                  ...valueProductRedemptions,
                  type: Constants.productRedemptionNote,
                  date: valueProductRedemptions.performedDateTime
                })
              }
            })

            resultAPITemp.virtualWarehouseReversalTransactions.map((valueVirtualWarehouseReversalTransaction)=> {
              if (resultAPITemp) {
                siteMapTypeListTemp.push(Constants.reversedVirtualWarehouse);
                resultAPITemp.timeLineDetails.push({
                  ...valueVirtualWarehouseReversalTransaction,
                  type: Constants.reversedVirtualWarehouse,
                  date: valueVirtualWarehouseReversalTransaction.performDateTime
                })
              }
            })

            delete resultAPITemp["paymentHistories"];
            delete resultAPITemp["customerDeliverOrders"];
            delete resultAPITemp["paymentAdjustments"];
            delete resultAPITemp["creditExchangeRequests"];
            delete resultAPITemp["productRedemptions"];
            delete resultAPITemp["virtualWarehouseReversalTransactions"]
            resultAPITemp.timeLineDetails = resultAPITemp.timeLineDetails.sort((a ) => (a.Type === Constants.payment) ? 1 : -1).sort((a, b) => (a.date > b.date) ? 1 : -1)
            setCustomerSalesOrderSiteMapDetail(resultAPITemp);
            siteMapTypeListTemp = _.uniq(siteMapTypeListTemp);
            setSiteMapTypeList(siteMapTypeListTemp);
          }
        }
      }
      else {
        history.push(`/${RoutesList.notFound}`)
        return;
      }
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }

    fetchCustomerSalesOrderSiteMapAPI();

    return (()=> {
      setCustomerSalesOrderSiteMapDetailFromStore(undefined);
    })
	}, []);

  const returnTimelineIcon = (valueTimeLineTemp) => {
    let iconTemp : any = "";
    if (valueTimeLineTemp.type === Constants.payment) {
      iconTemp = <CashCheckIcon size={24} className='align-middle me-2'/>;
    }
    else if (valueTimeLineTemp.type === Constants.deliverOrder && !valueTimeLineTemp.isSystemAutoGenerated) {
      iconTemp = <CardGiftcardIcon size={24} className='align-middle me-2'/>;
    }
    else if (valueTimeLineTemp.type === Constants.deliverOrder && valueTimeLineTemp.isSystemAutoGenerated) {
      iconTemp = <GiftIcon size={24} className='align-middle me-2'/>;
    }
    else if (valueTimeLineTemp.type === Constants.paymentAdjustment || valueTimeLineTemp.type === Constants.changePaymentMethod) {
      iconTemp = <CashSyncIcon size={24} className='align-middle me-2'/>;
    }
    else if (valueTimeLineTemp.type === Constants.creditExchange) {
      iconTemp = <CreditCardSyncIcon size={24} className='align-middle me-2'/>;
    }
    else if (valueTimeLineTemp.type === Constants.productRedemptionNote) {
      iconTemp = <GiftOpenIcon size={24} className='align-middle me-2'/>
    }
    else if (valueTimeLineTemp.type === Constants.reversedVirtualWarehouse) {
      iconTemp = <BriefcaseArrowLeftRightOutlineIcon size={24} className='align-middle me-2'/>
    }
    return iconTemp;
  }

  const returnTimelineIconColor = (valueTimeLineTemp) => {    
    let iconColorTemp : any = "";
    if (valueTimeLineTemp.type === Constants.payment) {
      iconColorTemp = paymentColor;
    }
    else if (valueTimeLineTemp.type === Constants.deliverOrder && !valueTimeLineTemp.isSystemAutoGenerated) {
      iconColorTemp = deliverOrderColor;
    }
    else if (valueTimeLineTemp.type === Constants.deliverOrder && valueTimeLineTemp.isSystemAutoGenerated) {
      iconColorTemp = deliverOrderSystemAutoGeneratedColor;
    }
    else if (valueTimeLineTemp.type === Constants.paymentAdjustment) {
      iconColorTemp = paymentAdjustmentColor;
    }
    else if (valueTimeLineTemp.type === Constants.creditExchange) {
      iconColorTemp = creditExchangeColor;
    }
    else if (valueTimeLineTemp.type === Constants.productRedemptionNote) {
      iconColorTemp = productRedemptionColor
    }
    else if (valueTimeLineTemp.type === Constants.changePaymentMethod) {
      iconColorTemp = changePaymentMethodColor;
    }
    else if (valueTimeLineTemp.type === Constants.reversedVirtualWarehouse) {
      iconColorTemp = reversedVirtualWarehouseColor;
    }
    return iconColorTemp;
  }

  const returnLocalStatus = (status, statusFunction) => {
    return (
      <div style={{marginTop: '4px'}}>
        <span
          style={{
            color: 'white', 
            backgroundColor: statusFunction(status),
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '4px'
          }}>
          {newSpaceBeforeCapitalLetter(status)}
        </span>
      </div>    
    )
  }

  const voidNotEditableIcon = (id: string) => {
    return (
      <>
        <PencilOffOutlineIcon 
          id={id}
          size={18} 
          className='align-middle margin-left-4 text-danger'/>
        <UncontrolledTooltip
          placement="top"
          target={id}
        >
          {intl.formatMessage({ id: "VoidActionIsAllow" })}
        </UncontrolledTooltip>
      </>
    )
  }

  const displayPaymentRowData = (valueTimeLineTemp) => { 
    return ( 
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="flex-align-items-center-without-height">
            <div className="text-lg-center"> 
              <div className="avatar-sm me-3 mx-lg-auto float-start float-lg-none rounded-circle"> 
                <span className={"avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"} > 
                  {returnPaymentIcon(valueTimeLineTemp.paymentMethodName)}
                </span>
              </div> 
            </div>  
            <div style={{marginLeft: '16px'}}> 
              <h5 className="text-truncate font-size-15" style={{marginBottom: '4px'}}>
                <Link
                  to={blnPermissionManageCustomerSalesOrder ? `/${RoutesList.receipt}/view/${valueTimeLineTemp.id}` : "#"} 
                  target={Constants.blank} 
                  rel="noopener noreferrer" 
                  className="text-primary pointer-clickable"
                >
                  {valueTimeLineTemp.paymentReceiptNo}
                </Link>
              </h5>
              <p style={{marginTop: '0px', marginBottom: '4px', padding:'0px', fontWeight: 500, fontSize: '14px'}}>
                {`${valueTimeLineTemp.paymentMethodName} `} {valueTimeLineTemp.cardType ? `(${valueTimeLineTemp.cardType} | ${valueTimeLineTemp.optionName} | ${valueTimeLineTemp.last4CardDigitNo})` : valueTimeLineTemp.optionName ? `(${valueTimeLineTemp.optionName})` : ""}
              </p>
              <ul className="list-inline" style={{marginBottom: "3px"}}> 
                <li className="list-inline-item me-3"> 
                  <h5 className="font-size-14" id="paymentBranchTooltip"> 
                    <HomeVariantIcon className="mb-0 text-muted" size={14} />
                    {" "} {`${valueTimeLineTemp.branchName}`}
                    <UncontrolledTooltip placement="top" target="paymentBranchTooltip">
                        {intl.formatMessage({ id: "PaymentBranch" })}
                    </UncontrolledTooltip> 
                  </h5> 
                </li>&nbsp; 
              </ul> 
              <ul className="list-inline" style={{marginBottom: "-8px"}}> 
                <li className="list-inline-item me-3"> 
                <h5 className="font-size-14" id="amountTooltip">
                  <i className="bx bx-money me-1 text-muted"/> {returnPriceWithCurrency(valueTimeLineTemp.paidAmount)} 
                  <UncontrolledTooltip placement="top" target="amountTooltip">
                      {intl.formatMessage({ id: "Amount" })}
                  </UncontrolledTooltip> 
                </h5> </li>&nbsp; 
                <li className="list-inline-item me-3"> 
                  <h5 className="font-size-14" id="duedateTooltip"> 
                    <i className="bx bx-calendar me-1 text-muted" />
                    {" "} {moment(valueTimeLineTemp.paymentDate).format(Constants.displayDateAndTimeFormat) } 
                    <UncontrolledTooltip placement="top" target="duedateTooltip">
                        {intl.formatMessage({ id: "Date" })}
                    </UncontrolledTooltip> 
                  </h5> 
                </li> 
              </ul> 
            </div> 
          </div> 
        </CardBody>
      </Card>
    ) 
  }

  const displayDeliverOrderData = (valueTimeLine) => {
    return (
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15" style={{marginBottom: '0px'}}>
                <Link
                  to={blnPermissionManageCustomerDeliverOrder ? `/${RoutesList.customerRedemption}/view/${valueTimeLine.id}` : "#"} 
                  target={Constants.blank} 
                  rel="noopener noreferrer" 
                  className="text-primary pointer-clickable"
                >
                  {valueTimeLine.customerDeliverOrderNo} {valueTimeLine.isSystemAutoGenerated && <i className="font-size-12 text-muted">{`(${intl.formatMessage({ id: "Auto" }).toUpperCase()})`}</i>}
                </Link>
                {
                  valueTimeLine.isVoidAllowed
                  &&
                  voidNotEditableIcon("deliverOrderVoid")
                }
              </h5>
              <p className="text-muted" style={{fontSize: '12px', margin: '0px'}}>
                <i className="bx bx-calendar me-1 text-muted" />
                {moment(valueTimeLine.performDateTime).format(Constants.displayDateAndTimeFormat)}
              </p>               
              {returnLocalStatus(valueTimeLine.status, deliveryOrderStatusColor)}                  
            </div>
          </div>
        </CardBody>
        <div className="px-4 py-3 border-top">
        <div >
          <ul className="verti-timeline list-unstyled">
            {valueTimeLine.deliverOrderDetails.map((valueDeliverOrderDetails, index)=> (
              <li
                className={"event-list active"}
                key={"deliver_order_detail" + index}
                style={{marginTop: index === 0 ? '0px' : '16px'}}
              >
                <div className="event-timeline-dot">
                  <i className={"bx bx-right-arrow-circle"}/>
                </div>
                <div className="d-flex">
                  <div className="">
                    {getPurchaseTypeIcon(valueDeliverOrderDetails.redeemType)}
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-15" style={{marginBottom: '0px'}}>
                        <div className="text-dark">
                          {valueDeliverOrderDetails.redeemDetailName} {valueDeliverOrderDetails.packageName ? `(${valueDeliverOrderDetails.packageName})` : ""}
                        </div>
                      </h5>
                      <span className="text-muted">
                        {newSpaceBeforeCapitalLetter(valueDeliverOrderDetails.redeemType)}
                      </span>
                    </div>
                    <span >
                      <span className="text-muted">
                        {`Count: : `}
                      </span>
                      <span style={{color: Constants.pink}}>
                        {valueDeliverOrderDetails.count}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </div>
      </Card>
    )
  }

  const displayPaymentAdjustmentData = (valueTimeLine) => { 
    return ( 
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15" style={{marginBottom: '0px'}}>
                {
                  (valueTimeLine.type === Constants.changePaymentMethod && blnPermissionManageChangePaymentMethod) || (valueTimeLine.type !== Constants.changePaymentMethod && blnPermissionManagePaymentAdjustment)
                  ?
                  <Link
                    to={valueTimeLine.type === Constants.changePaymentMethod ? blnPermissionManageChangePaymentMethod ? `/${RoutesList.changePaymentMethod}/${valueTimeLine.id}` : "#" : blnPermissionManagePaymentAdjustment ? `/${RoutesList.paymentAdjustment}/${valueTimeLine.id}` : "#"} 
                    target={Constants.blank} 
                    rel="noopener noreferrer" 
                    className="text-primary"
                  >
                    {valueTimeLine.customerPaymentAdjustmentNo}
                  </Link>
                  :
                  valueTimeLine.customerPaymentAdjustmentNo
                }
              </h5>
              <p className="text-muted" style={{fontSize: '12px', margin: '0px'}}>
                <i className="bx bx-calendar me-1 text-muted" />
                {moment(valueTimeLine.status === Constants.processing ? valueTimeLine.adjustmentDate : valueTimeLine.actionDate).format(Constants.displayDateAndTimeFormat)}
              </p>               
              {returnLocalStatus(valueTimeLine.status, paymentAdjustmentStatusColor)}                  
            </div>
          </div>
        </CardBody>
        <div className="px-4 border-top" style={{paddingTop: '18px', paddingBottom: '12px'}}>
          <li className="list-inline-item me-3"> 
            <h5 className="font-size-14 text-muted">
              {intl.formatMessage({ id: "PreviousPayments" })}: <span className="text-danger">{returnPriceWithCurrency(valueTimeLine.oldPaymentAmount)}</span> ({valueTimeLine.oldPaymentMethods})
            </h5> 
          </li>
          <li className="list-inline-item me-3"> 
            <h5 className="font-size-14"> 
            {intl.formatMessage({ id: "NewPayments" })}: <span className="text-success">{returnPriceWithCurrency(valueTimeLine.newPaymentAmount)}</span>  ({valueTimeLine.newPaymentMethods})
            </h5> 
          </li> 
        </div>
      </Card>
    ) 
  }

  const displayCreditExchangeData = (valueTimeLine) => {
    return (
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15" style={{marginBottom: '0px'}}>
                <Link
                  to={blnPermissionManageExchangeToCredit ? `/${RoutesList.exchangeToCredit}/${valueTimeLine.id}` : "#"} 
                  target={Constants.blank} 
                  rel="noopener noreferrer" 
                  className="text-primary"
                >
                  {valueTimeLine.documentNo}
                </Link>
              </h5>
              <ul className="list-inline mt-1" style={{marginBottom: "-8px"}}> 
                <li className="list-inline-item me-3"> 
                  <h5 className="font-size-14" id="duedateTooltip"> 
                    <i className="bx bx-calendar me-1 text-muted" />
                    {" "} {moment(valueTimeLine.issueDate).format(Constants.displayDateAndTimeFormat) } 
                    <UncontrolledTooltip placement="top" target="duedateTooltip">
                        {intl.formatMessage({ id: "Date" })}
                    </UncontrolledTooltip> 
                  </h5> 
                </li> 
                <li className="list-inline-item me-3"> 
                <h5 className="font-size-14" id="amountTooltip">
                  <i className="bx bx-credit-card-alt me-1 text-muted"/> {valueTimeLine.totalExchangeAmount} 
                  <UncontrolledTooltip placement="top" target="amountTooltip">
                      {intl.formatMessage({ id: "Amount" })}
                  </UncontrolledTooltip> 
                </h5> </li>&nbsp; 
              </ul>              
              {returnLocalStatus(valueTimeLine.status, exchangeToCreditStatusColor)}                  
            </div>
          </div>
        </CardBody>
        <div className="px-4 py-3 border-top">
        <div >
          <ul className="verti-timeline list-unstyled">
            {valueTimeLine.details.map((valueCreditExchangeDetail, index)=> (
              <li
                className={"event-list active"}
                key={"deliver_order_detail" + index}
                style={{marginTop: index === 0 ? '0px' : '16px'}}
              >
                <div className="event-timeline-dot">
                  <i className={"bx bx-right-arrow-circle"}/>
                </div>
                <div className="d-flex">
                  <div className="">
                    {getPurchaseTypeIcon(valueCreditExchangeDetail.purchaseType)}
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-15" style={{marginBottom: '0px'}}>
                        <div className="text-dark">
                          {valueCreditExchangeDetail.purchaseName}
                        </div>
                      </h5>
                      <span className="text-muted">
                        {newSpaceBeforeCapitalLetter(valueCreditExchangeDetail.purchaseType)}
                      </span>
                    </div>
                    <span>
                      <span className="text-muted">
                        {`Exchange Amount: : `}
                      </span>
                      <span style={{color: Constants.pink}}>
                        {valueCreditExchangeDetail.exchangeAmount}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </div>
      </Card>
    )
  }

  const displayProductRedemptionData = (valueTimeLine) => {
    return (
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15" style={{marginBottom: '0px'}}>
                <Link
                  to={blnPermissionManageProductRedemptionNote ? `/${RoutesList.productRedemptionNote}/view/${valueTimeLine.id}` : "#"} 
                  target={Constants.blank} 
                  rel="noopener noreferrer" 
                  className="text-primary pointer-clickable"
                >
                  {valueTimeLine.productRedemptionNo}
                </Link>
                {
                  valueTimeLine.isVoidAllowed
                  &&
                  voidNotEditableIcon("productRedemptionVoid")
                }
              </h5>
              <p className="text-muted" style={{fontSize: '12px', margin: '0px'}}>
                <i className="bx bx-calendar me-1 text-muted" />
                {moment(valueTimeLine.performedDateTime).format(Constants.displayDateAndTimeFormat)}
              </p>               
              {returnLocalStatus(valueTimeLine.status, productRedemptionStatusColor)}                  
            </div>
          </div>
        </CardBody>
        <div className="px-4 py-3 border-top">
        <div >
          <ul className="verti-timeline list-unstyled">
            {valueTimeLine.productRedemptionNoteDetails.map((valueProductRedemptionNoteDetails, index)=> (
              <li
                className={"event-list active"}
                key={"deliver_order_detail" + index}
                style={{marginTop: index === 0 ? '0px' : '16px'}}
              >
                <div className="event-timeline-dot">
                  <i className={"bx bx-right-arrow-circle"}/>
                </div>
                <div className="d-flex">
                  <div className="">
                    {getPurchaseTypeIcon(Constants.product)}
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-15" style={{marginBottom: '0px'}}>
                        <div className="text-dark">
                          {valueProductRedemptionNoteDetails.productName}
                        </div>
                      </h5>
                      <span className="text-muted">
                        {newSpaceBeforeCapitalLetter(valueProductRedemptionNoteDetails.virtualWarehouseNo)}
                      </span>
                    </div>
                    <span >
                      <span className="text-muted">
                        {`Count: : `}
                      </span>
                      <span style={{color: Constants.pink}}>
                        {valueProductRedemptionNoteDetails.quantity}
                      </span>
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </div>
      </Card>
    )
  }

  const displayReversedVirtualWarehouseData = (valueTimeLine) => {
    return (
      <Card style={{margin: '0px'}}>
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15" style={{marginBottom: '0px'}}>
                <Link
                  to={blnPermissionManageCustomerVirtualWarehouse ? `/${RoutesList.customerVirtualWarehouse}/view/${valueTimeLine.virtualWarehouseId}` : "#"} 
                  target={Constants.blank} 
                  rel="noopener noreferrer" 
                  className="text-primary pointer-clickable"
                >
                  {valueTimeLine.virtualWarehouseNo}
                </Link>
              </h5>
              <p className="text-muted" style={{fontSize: '12px', margin: '0px'}}>
                <i className="bx bx-calendar me-1 text-muted" />
                {moment(valueTimeLine.performDateTime).format(Constants.displayDateAndTimeFormat)}
              </p>               
              {returnLocalStatus(valueTimeLine.status, virtualWarehouseStatusColor)}                  
            </div>
          </div>
        </CardBody>
        <div className="px-4 py-3 border-top">
        <div >
          <ul className="verti-timeline list-unstyled">
            <li className={"event-list active"}>
              <div className="event-timeline-dot">
                <i className={"bx bx-right-arrow-circle"}/>
              </div>
              <div className="d-flex">
                <div className="">
                  {getPurchaseTypeIcon(Constants.product)}
                </div>
                <div className="flex-grow-1">
                  <div>
                    <h5 className="font-size-15" style={{marginBottom: '0px'}}>
                      <div className="text-dark">
                        {valueTimeLine.productName}
                      </div>
                    </h5>
                    <span className="text-muted">
                      {valueTimeLine.branchName}
                    </span>
                  </div>
                  <span >
                    <span className="text-muted">
                      {`Count: : `}
                    </span>
                    <span style={{color: Constants.pink}}>
                      {valueTimeLine.quantity}
                    </span>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        </div>
      </Card>
    )
  }

  const displayFullTimeLine = (mode?: number) => {
    return (
      <VerticalTimeline lineColor={'white'} animate={false}>
        {
          customerSalesOrderSiteMapDetail.timeLineDetails.map((valueTimeLine, key) => {
            if ((valueTimeLine.type === Constants.payment && !displayPaymentHistory) || (valueTimeLine.type === Constants.deliverOrder && !valueTimeLine.isSystemAutoGenerated && !displayDeliverOrder) || (valueTimeLine.type === Constants.deliverOrder && valueTimeLine.isSystemAutoGenerated && !displayDeliverOrderSystemAutoGenerated) || (valueTimeLine.type === Constants.paymentAdjustment && !displayPaymentAdjustment) || (valueTimeLine.type === Constants.creditExchange && !displayExchangeToCredit) || (valueTimeLine.type === Constants.changePaymentMethod && !displayChangePaymentMethod) || (valueTimeLine.type === Constants.productRedemptionNote && !displayProductRedemption) || (valueTimeLine.type === Constants.reversedVirtualWarehouse && !displayReversedVirtualWarehouse)) {
              return <div key={`time-line-${key}`}/>
            }

            return (
              <VerticalTimelineElement
                key={`time-line-${key}`}
                className="vertical-timeline-element--work"
                style={{padding: '0px'}}
                contentStyle={{ color: 'black', border: "solid", borderWidth: '2px', borderRadius: "4px", borderColor: "lightgrey", padding: '0px', }}
                contentArrowStyle={{ borderRight: '7px solid lightgrey' }}
                iconStyle={{ backgroundColor: returnTimelineIconColor(valueTimeLine), color: '#fff' }}
                position={valueTimeLine.type === Constants.payment || valueTimeLine.type === Constants.paymentAdjustment || valueTimeLine.type === Constants.changePaymentMethod || valueTimeLine.type === Constants.creditExchange ? 'left' : 'right'}
                icon={returnTimelineIcon(valueTimeLine)}
              >
                <Row >
                  {
                    valueTimeLine.type === Constants.payment
                    ?
                    <div>
                      {displayPaymentRowData(valueTimeLine)}
                    </div>
                    :
                    valueTimeLine.type === Constants.deliverOrder
                    ?
                    <div>
                      {displayDeliverOrderData(valueTimeLine)}                              
                    </div>
                    :
                    valueTimeLine.type === Constants.paymentAdjustment || valueTimeLine.type === Constants.changePaymentMethod
                    ?
                    <div>
                      {displayPaymentAdjustmentData(valueTimeLine)}                              
                    </div>
                    :
                    valueTimeLine.type === Constants.creditExchange
                    ?
                    <div>
                      {displayCreditExchangeData(valueTimeLine)}                              
                    </div>
                    :
                    valueTimeLine.type === Constants.productRedemptionNote
                    ?
                    <div>
                      {displayProductRedemptionData(valueTimeLine)}                              
                    </div>
                    :
                    valueTimeLine.type === Constants.reversedVirtualWarehouse
                    ?
                    <div>
                      {displayReversedVirtualWarehouseData(valueTimeLine)}                              
                    </div>
                    :
                    <div/>
                  }
                </Row>
              </VerticalTimelineElement>
            )
          })
        }
      </VerticalTimeline>
    )
  }

  const onClickType = (type: string, isSystemAutoGenerated: boolean = false) => {
    if (type === Constants.payment) {
      setDisplayPaymentHistory((prev)=> !prev);
    }
    //for auto DO
    else if (type === Constants.deliverOrder && isSystemAutoGenerated) {
      setDisplayDeliverOrderSystemAutoGenerated((prev)=> !prev);
    }
    else if (type === Constants.deliverOrder) {
      setDisplayDeliverOrder((prev)=> !prev);
    }
    else if (type === Constants.paymentAdjustment) {
      setDisplayPaymentAdjustment((prev)=> !prev);
    }
    else if (type === Constants.creditExchange) {
      setDisplayExchangeToCredit((prev)=> !prev);
    }
    else if (type === Constants.productRedemptionNote) {
      setDisplayProductRedemption((prev)=> !prev);
    }
    else if (type === Constants.changePaymentMethod) {
      setDisplayChangePaymentMethod((prev)=> !prev);
    }
    else if (type === Constants.reversedVirtualWarehouse) {
      setDisplayReversedVirtualWarehouse((prev)=> !prev);
    }
  }

	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        // burgerMenuList={[]}
        auditTrailId={""}>
        {loading || !customerSalesOrderSiteMapDetail ? (
          <Loading />
        ) : (
          <div>
            <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right align-items-flex-end" : ""}>
              <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{maxWidth: "1250px", marginRight: "42px",flexWrap: 'wrap'}}>
                {
                  siteMapTypeList.map((valueType)=> (
                    <div key={`color_${valueType}`}>
                      {
                        valueType === Constants.payment
                        ?
                        displayTypeWithColor(displayPaymentHistory ? paymentColor : Constants.lightgrey, intl.formatMessage({id: "PaymentHistory"}), ()=> {onClickType(Constants.payment)})
                        :
                        valueType === Constants.deliverOrder
                        ?
                        displayTypeWithColor(displayDeliverOrder ? deliverOrderColor : Constants.lightgrey, intl.formatMessage({id: "CustomerRedemption"}), ()=> {onClickType(Constants.deliverOrder)})
                        :
                        valueType === Constants.paymentAdjustment
                        ?
                        displayTypeWithColor(displayPaymentAdjustment ? paymentAdjustmentColor : Constants.lightgrey, intl.formatMessage({id: "PaymentAdjustment"}), ()=> {onClickType(Constants.paymentAdjustment)})
                        :
                        valueType === Constants.changePaymentMethod
                        ?
                        displayTypeWithColor(displayChangePaymentMethod ? changePaymentMethodColor : Constants.lightgrey, intl.formatMessage({id: "ChangePaymentMethod"}), ()=> {onClickType(Constants.changePaymentMethod)})
                        :
                        valueType === Constants.creditExchange
                        ?
                        displayTypeWithColor(displayExchangeToCredit ? creditExchangeColor : Constants.lightgrey, intl.formatMessage({id: "ExchangeToCredit"}), ()=> {onClickType(Constants.creditExchange)})
                        :
                        valueType === Constants.autoDeliverOrder
                        ?
                        displayTypeWithColor(displayDeliverOrderSystemAutoGenerated ? deliverOrderSystemAutoGeneratedColor : Constants.lightgrey, `${intl.formatMessage({id: "CustomerRedemption"})} (${intl.formatMessage({ id: "Auto" }).toUpperCase()})`, ()=> {onClickType(Constants.deliverOrder, true)})
                        :
                        valueType === Constants.productRedemptionNote
                        ?
                        displayTypeWithColor(displayProductRedemption ? productRedemptionColor : Constants.lightgrey, intl.formatMessage({id: "ReservedProductRedemption"}), ()=> {onClickType(Constants.productRedemptionNote)})
                        :
                        displayTypeWithColor(displayReversedVirtualWarehouse ? reversedVirtualWarehouseColor : Constants.lightgrey, intl.formatMessage({id: "ReversedVirtualWarehouse"}), ()=> {onClickType(Constants.reversedVirtualWarehouse)})
                      }
                    </div>
                  ))
                }
              </div>
            </div>
            <Card style={{background: 'none'}}>
              <CardBody style={{justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: windowSize.innerWidth > 1169 ? 'center': undefined}}>
                  <div className="text-center" style={{backgroundColor: 'white', padding: '48px', paddingTop: '16px', paddingBottom: '16px', borderRadius: '4px', border: 'solid', borderColor: 'lightgrey', borderWidth: '2px'}}>
                    <h5 className="text-truncate font-size-15" style={{marginBottom: '4px'}}>
                      <Link
                        to={blnPermissionManageCustomerSalesOrder ? `/${RoutesList.customerSalesOrder}/view/${id}` : "#"} 
                        target={Constants.blank} 
                        rel="noopener noreferrer" 
                        className="text-primary pointer-clickable"
                      >
                        {customerSalesOrderSiteMapDetail.customerSalesOrderNo}
                      </Link>
                      {
                        customerSalesOrderSiteMapDetailFromStore
                        ?
                        customerSalesOrderSiteMapDetailFromStore.isVoidAllowed
                        ?
                        voidNotEditableIcon("salesOrderVoid")
                        :
                        <div/>
                        :
                        <div/>
                      }
                    </h5>
                    <p className="text-muted" style={{fontSize: '12px', margin: '0px'}}>
                      <i className="bx bx-calendar me-1 text-muted" />
                      {moment(customerSalesOrderSiteMapDetail.submissionDate).format(Constants.displayDateAndTimeFormat)}
                    </p>  
                    <p style={{fontWeight: "600", marginBottom: "4px", fontSize: '15px'}}><AccountIcon className="mb-0" size={15} /> {`${customerSalesOrderSiteMapDetail.customerName}`}</p>
                    <p className="text-muted" style={{marginBottom: "0px"}}><HomeVariantIcon className="mb-0" size={15} /> {`${customerSalesOrderSiteMapDetail.branchName}`}</p>
                    {returnLocalStatus(customerSalesOrderSiteMapDetail.status, salesOrderStatusColor)}                  
                  </div>
                </div>
                {displayFullTimeLine()}
                </CardBody>
              </Card>
              <GeneralSubmitAndCancelBtn
                className="width-100-percentage"
                successMessage={""}
                viewAction={false}
                hidePrimaryButton={true}
                cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                cancelButtonFunction={()=> {
                  history.goBack();
                }}
              />
          </div>
        )}
			</DetailViewLayout>
		</div>
	);
};

export default observer(CustomerSalesOrderSiteMap);
