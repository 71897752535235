import { useState } from "react";
import {
  FormFeedback,
  Modal,
  Row
} from "reactstrap";
import _ from 'lodash';
import { returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from '../../../app/components/form/GeneralInputForModal';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";

interface Props {
  blnShow: boolean;
  setModal: Function;
  creditNoteAmount: Number;
  refundProcessingFeeRate: Number;
  setCreditRedemptionConfirmationModal: Function;
  validation: any;
}

export default function CreditRedemptionRefundModal(props: Props) {
  const intl = useIntl();
  const defaultRefundDetails = {
    accountHolderName: "",
    bankAccountType: "",
    bankAccountNo: "",
    reason: "",
    creditNoteAmount: Number(props.creditNoteAmount),
    processingFees: (Number(props.creditNoteAmount) * Number(props.refundProcessingFeeRate) / 100).toFixed(2),
    customerEntitledAmount: (Number(props.creditNoteAmount) * (100-Number(props.refundProcessingFeeRate)) / 100).toFixed(2)
  }
  const [refundDetails, setRefundDetails] = useState(defaultRefundDetails)
  const [errorState, setErrorState] = useState({
    accountHolderName: false,
    bankAccountType: false,
    bankAccountNo: false,
    processingFees: false
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value)=> ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      accountHolderName: false,
      bankAccountType: false,
      bankAccountNo: false,
      processingFees: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setRefundDetails(defaultRefundDetails);
    props.setModal(false);
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
      size={"lg"}
    >
      <div className="modal-header">
        <div>
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Refund" })}</h5>
        </div>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <>
          <GeneralInputForModal
            title={intl.formatMessage({ id: "AccountHolderName" })}
            name="accountHolderName"
            className={errorState.accountHolderName ? "mb-0" : "mb-3"}
            type="text"
            validationRequired={true}
            field={refundDetails}
            setFieldValue={setRefundDetails}
            onChangeFunction={() => { clearErrorState("accountHolderName") }}
          />
          {errorState.accountHolderName ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "AccountHolderName" }) })}</FormFeedback>
          ) : null}
          <GeneralInputForModal
            title={intl.formatMessage({ id: "BankAccountType" })}
            name="bankAccountType"
            className={errorState.bankAccountType ? "mb-0" : "mb-3"}
            type="text"
            validationRequired={true}
            field={refundDetails}
            setFieldValue={setRefundDetails}
            onChangeFunction={() => { clearErrorState("bankAccountType") }}
          />
          {errorState.bankAccountType ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountType" }) })}</FormFeedback>
          ) : null}
          <GeneralInputForModal
            title={intl.formatMessage({ id: "BankAccountNo" })}
            name="bankAccountNo"
            className={errorState.bankAccountNo ? "mb-0" : "mb-3"}
            type="text"
            validationRequired={true}
            field={refundDetails}
            setFieldValue={setRefundDetails}
            onChangeFunction={() => { clearErrorState("bankAccountNo") }}
          />
          {errorState.bankAccountNo ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BankAccountNo" }) })}</FormFeedback>
          ) : null}
          <Row>
            <DoubleColumnRowParent
              blnDoubleTab={true}>
              <div>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "RefundToCustomer"})}
                  name="creditNoteAmount"
                  type="number"
                  inputGroup={true}
                  inputGroupText={returnCurrency()}
                  validationRequired={true}
                  disabled={true}
                  field={refundDetails}
                  setFieldValue={setRefundDetails}
                />
              </div>
            </DoubleColumnRowParent>
            <DoubleColumnRowParent
              blnDoubleTab={true}>
              <div>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "ProcessingFees"})}
                  name="processingFees"
                  className={errorState.processingFees ? "mb-0" : "mb-3"}
                  type="number"
                  inputGroup={true}
                  inputGroupText={returnCurrency()}
                  validationRequired={true}
                  field={refundDetails}
                  setFieldValue={setRefundDetails}
                  blnNumberOnlyWithDecimal={true}
                  numberMaxValue={refundDetails.creditNoteAmount}
                  onChangeFunctionWithValueReturn={(valueProcessingFees)=> {
                    clearErrorState("processingFees")
                    setRefundDetails((valueRefundDetails)=> ({
                      ...valueRefundDetails,
                      customerEntitledAmount: (valueRefundDetails.creditNoteAmount - valueProcessingFees).toFixed(2)
                    }))
                  }}
                />
              </div>
              {errorState.processingFees ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ProcessingFees" }) })}</FormFeedback>
              ) : null}
            </DoubleColumnRowParent>
          </Row>
          <GeneralInputForModal
            title={intl.formatMessage({ id: "CustomerEntitle"})}
            name="customerEntitledAmount"
            type="number"
            inputGroup={true}
            inputGroupText={returnCurrency()}
            validationRequired={true}
            disabled={true}
            field={refundDetails}
            setFieldValue={setRefundDetails}
          />
        </>
        <div className="mt-4 mb-2 flex-right flex-direction-row">
          <MyButton
            type="button"
            class="btn btn-back margin-right-8"
            style={{ width: '20%' }}
            content={intl.formatMessage({ id: "Back" })}
            onClick={() => props.setModal(false)}
          />
          <MyButton
            type="button"
            class="btn btn-primary"
            style={{ width: '20%' }}
            content={intl.formatMessage({ id: "Next" })}
            onClick={()=> {
              let errorStateTemp = {
                accountHolderName: false,
                bankAccountType: false,
                bankAccountNo: false,
                processingFees: false
              };
              let blnErrorFound = false;
              if (refundDetails.accountHolderName === "") {
                errorStateTemp.accountHolderName = true;
                blnErrorFound = true;
              }
              if (refundDetails.bankAccountType === "") {
                errorStateTemp.bankAccountType = true;
                blnErrorFound = true;
              }
              if (refundDetails.bankAccountNo === "") {
                errorStateTemp.bankAccountNo = true;
                blnErrorFound = true;
              }
              if (refundDetails.processingFees === "") {
                errorStateTemp.processingFees = true;
                blnErrorFound = true;
              }
              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                props.validation.setFieldValue("refundDetail", refundDetails);
                props.setCreditRedemptionConfirmationModal(true);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  )
}