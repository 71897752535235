import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnTableCellUI } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import moment from "moment";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import CashIcon from "mdi-react/CashIcon";
import CardInfo from "../../app/components/form/CardInfo";
import CashCheckIcon from "mdi-react/CashCheckIcon";
import CashRemoveIcon from "mdi-react/CashRemoveIcon";
import CashPlusIcon from "mdi-react/CashPlusIcon";
import PackageVariantClosedIcon from "mdi-react/PackageVariantClosedIcon";
import ArrangeBringForwardIcon from "mdi-react/ArrangeBringForwardIcon";
import CashLockIcon from "mdi-react/CashLockIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import CashSyncIcon from "mdi-react/CashSyncIcon";
import MyButton from "../../app/components/form/MyButton";
import { history } from "../..";

const CustomerSalesOrderCommissionDetail = () => {
	//Use Paramsf
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { customerSalesOrderStore, commonStore } = useStore();
	const {
		windowSize,
		loading,
		setLoading,
	} = commonStore;
	const { customerSalesOrderCommissionDetail, setCustomerSalesOrderCommissionDetail, getCustomerSalesOrderCommissionWithId } = customerSalesOrderStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "CommissionSummary" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "CustomerSalesOrder" }), urlPath: RoutesList.customerSalesOrder }, { title: intl.formatMessage({ id: "ViewCustomerSalesOrder" }), urlPath: `${RoutesList.customerSalesOrder}/view/${id}` }];

	const CommissionSummaryColumns = [
    {
      dataField: "date",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>  
			<div>
				{`${moment(row.date, "DD-MM-YYYY").format(Constants.displayDateFormat)}`}
			</div>
    },
    {
      dataField: "paymentInfo",
      text: intl.formatMessage({ id: "PaymentInfo" }).toUpperCase()
    },
    {
      dataField: "consultantNames",
      text: intl.formatMessage({ id: "Consultants" }).toUpperCase(),
			formatter: (cellContent, row) => {
				let aryViews : any = [];
				row.consultantNames.map((value, indexConsultantNames) => {
					aryViews.push(
						returnTableCellUI(`consultant_${indexConsultantNames}`, indexConsultantNames, row.consultantNames.length, value)
					)
				})
				return aryViews;
			},
    },
		{
      dataField: "receivedAmounts",
      text: intl.formatMessage({ id: "ReceivedAmounts" }).toUpperCase(),
      formatter: (cellContent, row) => {
				let aryViews : any = [];
				row.receivedAmounts.map((value, indexReceivedAmounts) => {
					aryViews.push(
						returnTableCellUI(`receivedAmounts_${indexReceivedAmounts}`, indexReceivedAmounts, row.receivedAmounts.length, returnPriceWithCurrency(value))
					)
				})
				return aryViews;
			},
    },
		{
      dataField: "packageSalesAmounts",
      text: intl.formatMessage({ id: "PackageSalesAmounts" }).toUpperCase(),
      formatter: (cellContent, row) => {
				let aryViews : any = [];
				row.packageSalesAmounts.map((value, indexPackageSalesAmounts) => {
					aryViews.push(
						returnTableCellUI(`packageSalesAmounts_${indexPackageSalesAmounts}`, indexPackageSalesAmounts, row.packageSalesAmounts.length, returnPriceWithCurrency(value))
					)
				})
				return aryViews;
			},
    },
		{
      dataField: "productSalesAmounts",
      text: intl.formatMessage({ id: "ProductSalesAmounts" }).toUpperCase(),
      formatter: (cellContent, row) => {
				let aryViews : any = [];
				row.productSalesAmounts.map((value, indexProductSalesAmounts) => {
					aryViews.push(
						returnTableCellUI(`productSalesAmounts_${indexProductSalesAmounts}`, indexProductSalesAmounts, row.productSalesAmounts.length, returnPriceWithCurrency(value))
					)
				})
				return aryViews;
			},
    },
		{
      dataField: "productCommissionAmounts",
      text: intl.formatMessage({ id: "ProductCommissionAmounts" }).toUpperCase(),
      formatter: (cellContent, row) => {
				let aryViews : any = [];
				row.productCommissionAmounts.map((value, indexProductCommissionAmounts) => {
					aryViews.push(
						returnTableCellUI(`productCommissionAmounts${indexProductCommissionAmounts}`, indexProductCommissionAmounts, row.productCommissionAmounts.length, returnPriceWithCurrency(value))
					)
				})
				return aryViews;
			},
    }
  ];

	useEffect(() => {
		async function fetchCustomerSalesOrderCommissionDetailAPI() {
			setLoading(true);
			if (id && !addAction) {
				await getCustomerSalesOrderCommissionWithId(id);
			}
			setLoading(false);
		}

		if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
        return;
      }
    }

		fetchCustomerSalesOrderCommissionDetailAPI();

		return (()=> {
			setCustomerSalesOrderCommissionDetail(undefined);
		})
	}, []);

	const displayRowData = (label, value, iconName?:any) => {
    return (
      <Col sm="6" xs="6">
        <div className="mt-2 font-size-14">
          <h5 style={{fontSize: '12px'}}>
            {iconName && <i className={`${iconName} me-1 text-primary`}/>} {label}
          </h5>
          <p className="mt-0 mb-0" style={{fontSize: '13px', fontWeight: '500'}}>{value}</p>
        </div>
      </Col>
    )
  }

	const displayPaymentAmountData = (fullPaymentTitle: string, valueFullPaymentAmount: number, partialPaymentTitle: string, valuePartialPaymentAmount: number ) => {
    return (
      <>
        <Row className="list-inline" style={{marginTop:"10px",marginBottom: "-10px"}}> 
          <Col className="list-inline-item me-3"> 
          <h6 className="font-size-13 text-muted">
            {fullPaymentTitle}: <br/>
            {returnPriceWithCurrency(valueFullPaymentAmount)} 
          </h6> </Col>&nbsp; 
          <Col className="list-inline-item me-3"> 
            <h6 className="font-size-13 text-muted"> 
              {partialPaymentTitle}: <br/>
              {" "} {returnPriceWithCurrency(valuePartialPaymentAmount)} 
            </h6> 
          </Col>
        </Row> 
      </>
    )
  }

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}>
				{loading || !customerSalesOrderCommissionDetail ? (
					<Loading />
				) : (
					customerSalesOrderCommissionDetail.commissionSummaryDetails.length < 1 && customerSalesOrderCommissionDetail.salesAdjustments.length < 1
					?
					<div>
						<h4 className="mt-5 text-center text-uppercase">{intl.formatMessage({id: "NoCommissionEntitled"})}</h4>
						<div className="text-center">
							<MyButton
								type="button"
								class="btn btn-primary mt-4"
								content={intl.formatMessage({id: "BackToPage"})}
								onClick={()=> {
									history.goBack();
								}}
							/>
						</div>
					</div>
					:
					<div>
						<Label className={"mt-2 mb-3"} style={{fontSize: '18px', fontWeight: '600px'}}><strong>{intl.formatMessage({ id: "CommissionSummary" })} {`(${customerSalesOrderCommissionDetail.salesOrderNo})`}</strong></Label>
						<Row>
							<Col xl="4" lg="4" sm="12" xs="12">
								<CardInfo
									title={intl.formatMessage({ id: "TotalPackageSales" }).toUpperCase()}
									value={returnPriceWithCurrency(customerSalesOrderCommissionDetail.totalPackageSales)}
									displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "EarnedAmt" }), customerSalesOrderCommissionDetail.totalActualPackageSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), customerSalesOrderCommissionDetail.totalSalesAdjustmentAmount )}  
									isEqualHeightRequired={true}/>
							</Col>
							<Col xl="4" lg="4" sm="12" xs="12">
								<CardInfo
									title={intl.formatMessage({ id: "TotalProductSales" }).toUpperCase()}
									value={returnPriceWithCurrency(customerSalesOrderCommissionDetail.totalProductSales)}
									cardColor={"card-yellow"}
									displayCardSubContent={displayPaymentAmountData(intl.formatMessage({ id: "EarnedAmt" }), customerSalesOrderCommissionDetail.totalActualProductSales, intl.formatMessage({ id: "AfterAdjustmentAmount" }), customerSalesOrderCommissionDetail.totalSalesAdjustmentProductAmount )}  
									isEqualHeightRequired={true}/>
							</Col>
							<Col xl="4" lg="4" sm="12" xs="12">
								<CardInfo
									icon={() => (<CashSyncIcon className="h2 text-info mb-0" />)}
									title={intl.formatMessage({ id: "TotalProductCommission" }).toUpperCase()}
									value={returnPriceWithCurrency(customerSalesOrderCommissionDetail.totalProductCommission)} 
									cardColor={"card-blue"} 
									isEqualHeightRequired={true}/>
							</Col>
						</Row>
						<Row className="margin-left-0 margin-right-0">
							<Col xl="8" lg="12" className="standard-layout">
								<Label className={"mb-2"} style={{fontSize: '15px', fontWeight: '600px'}}><strong>{intl.formatMessage({ id: "CommissionSummaryDetails" })}</strong></Label>
								<PaginationTableWithoutApi 
									title={intl.formatMessage({ id: "CommissionSummaryDetails" })}
									headerClassName={"table-light"}
									options={customerSalesOrderCommissionDetail.commissionSummaryDetails}
									columns={CommissionSummaryColumns}
									pageSize={10}
									keyField={"randomId"}/>
							</Col>
							<Col xl="4" lg="12" style={{paddingLeft: '8px'}} className={`${Constants.innerWidthCompare>windowSize.innerWidth ? "margin-top-16" : ""}`}>
								<div className="standard-layout">
									<Label className={"mb-2"} style={{fontSize: '15px', fontWeight: '600px'}}><strong>{intl.formatMessage({ id: "SalesAdjustmentDetail" })}</strong></Label>
										{
											customerSalesOrderCommissionDetail.salesAdjustments.length > 0
											?
											<VerticalTimeline layout="1-column-left" lineColor={'#e8e8ed80'} animate={false}>
												{
													customerSalesOrderCommissionDetail.salesAdjustments.map((valueSalesAdjustments, key) => (
														<VerticalTimelineElement
															key={`${valueSalesAdjustments.randomId}`}
															className="vertical-timeline-element--work"
															dateClassName="font-size-13"
															contentStyle={{ background: '#e8e8ed80', color: 'black' }}
															contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
															date={moment(valueSalesAdjustments.performedDateTime).format(Constants.displayDateAndTimeFormat)}
															iconStyle={{ backgroundColor: 'lightgrey', color: '#fff' }}
															icon={<CashIcon size={18} className='text-success align-middle me-2'/>}
														>
															<h5 className="font-size-13">
																<span className="font-size-13">{`${newSpaceBeforeCapitalLetter(valueSalesAdjustments.salesAdjustmentType)} `}</span>
															</h5>
															<Row className="mb-1">
																{displayRowData(intl.formatMessage({ id: "PackageSales" }), returnPriceWithCurrency(valueSalesAdjustments.amount), "bx bx-dollar")}
																{displayRowData(intl.formatMessage({ id: "Branch" }), valueSalesAdjustments.branchName, "bx bxs-purchase-tag-alt")}
															</Row>
															{
																<Row className="mb-1">
																	{displayRowData(intl.formatMessage({ id: "ProductSales" }), returnPriceWithCurrency(valueSalesAdjustments.productAmount), "bx bx-dollar")}
																	{displayRowData(intl.formatMessage({ id: "ProductCommission" }), returnPriceWithCurrency(valueSalesAdjustments.productCommission), "bx bx-dollar")}														
																</Row>
															}
														</VerticalTimelineElement>
													))
												}
											</VerticalTimeline>
											:
											<div> 
												{intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PostedTransaction" })})}
											</div>
										}
									</div>
							</Col>
						</Row>
					</div>
				)}
			</DetailViewLayout>
		</div>
	);
};

export default observer(CustomerSalesOrderCommissionDetail);
