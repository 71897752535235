import { useEffect, useState } from "react";
import { FormFeedback, Modal } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { getBranchId } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import { CustomerValidationResponseObject } from "../../../app/models/customer";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { BranchListObject } from "../../../app/models/branch";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerForAccess: CustomerValidationResponseObject | undefined;
  setSelectedCustomerForAccess?: Function;
  addCustomerAccessRequest?: Function;
  customerBranchId?: string;
  customerSubBranchId?: string;
  fetchCustomerAccessRequestList?: Function;
}

export default observer(function CustomerAccessRequestModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore, branchStore } = useStore();
  const { successMessage } = commonStore;
  const { getBranchDropDownWithoutValidation } = branchStore;
  const [localBranchDropdown, setLocalBranchDropdown] = useState<BranchListObject[]>([]);
  const [loadingBranch, setLoadingBranch] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);

  const defaultCreateCustomerAccessRequestDetails = {
    requestBranchId: props.customerBranchId ? "" : getBranchId(), 
    customerId: props.selectedCustomerForAccess?.customerId || "",  
    customerName: props.selectedCustomerForAccess?.customerName || "",
    from: moment().format(Constants.defaultDateFormat),
    to: moment().add(3, 'days').format(Constants.defaultDateFormat),
    isDirectAccessGranted: props.customerBranchId ? true : false
  }
  const [createCustomerAccessRequestDetails, setCreateCustomerAccessRequestDetails] = useState(defaultCreateCustomerAccessRequestDetails)
  const [errorState, setErrorState] = useState({
    branchId: false,
    to: false,
  })

  useEffect(()=> {
    async function fetchBranchDropdown() {
      setLoadingBranch(true);
      let resultBranchAPI = await getBranchDropDownWithoutValidation();
      if (resultBranchAPI) {
        let indexCurrentBranch = _.findIndex(resultBranchAPI, {id: props.customerBranchId} )
        if (indexCurrentBranch > -1) {
          resultBranchAPI.splice(indexCurrentBranch, 1);
        }

        if (props.customerSubBranchId) {
          let indexCurrentSubBranch = _.findIndex(resultBranchAPI, {id: props.customerSubBranchId} )
          if (indexCurrentSubBranch > -1) {
            resultBranchAPI.splice(indexCurrentSubBranch, 1);
          }
        }
        setLocalBranchDropdown(resultBranchAPI);
      }
      setTimeout(() => {
        setLoadingBranch(false);
      }, 2000);
    }
    
    if (props.customerBranchId) {
      fetchBranchDropdown();
    }
  }, [])

  const hideModal = () => {
    if (props.setSelectedCustomerForAccess) {
      props.setSelectedCustomerForAccess(undefined);
    }
    setCreateCustomerAccessRequestDetails(defaultCreateCustomerAccessRequestDetails);
    props.setModal(false);
  }

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branchId: false,
      to: false
    });
  }

  const onClickCreateCustomerAccessRequest = async () => {
    if (props.addCustomerAccessRequest) {
      let createCustomerAccessRequestDetailsTemp = _.cloneDeep(createCustomerAccessRequestDetails);
      createCustomerAccessRequestDetailsTemp.to = moment(createCustomerAccessRequestDetailsTemp.to, Constants.textInputDateFormat).format(Constants.defaultDateFormat)
      setLoadingCreate(true);
      let resultAPI = await props.addCustomerAccessRequest(createCustomerAccessRequestDetailsTemp)
      if(resultAPI.status === Constants.success){
        if (props.fetchCustomerAccessRequestList) {
          props.fetchCustomerAccessRequestList();
        }
        setTimeout(()=> {
          hideModal();
        }, Constants.dismissSuccessMessage)
      }
      setLoadingCreate(false);
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingCreate && !successMessage) {
            hideModal();
          }
        }}
        size="lg"
        centered>
        {
          !props.selectedCustomerForAccess
          ?
          <div style={{ padding: "20px" }}>
            <Loading />
          </div>
          :
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: props.customerBranchId ? "GrantCustomerAccess" : "CustomerAccessRequestDay" })}</h5>
              </div>
              {
                !loadingCreate && !successMessage
                &&
                <button
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
            <div className="modal-body">
              {
                props.customerBranchId
                &&
                <SingleColumnRowParent blnDoubleTab>
                  {
                    loadingBranch
                    ?
                    <Loading className="mb-2"/>
                    :
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "AccessBranch" })}
                      name="branchName"
                      className={errorState.branchId ? "mb-0" : "mb-3"}
                      options={localBranchDropdown}
                      initialLabel={""}
                      initialValue={""}
                      labelField="name"
                      valueField="id"
                      disabled={loadingCreate || Boolean(successMessage)}
                      validationRequired={true}
                      onChange={(valueBranchId) => {
                        setCreateCustomerAccessRequestDetails((valueCreateCustomerAccessRequestDetails)=> ({
                          ...valueCreateCustomerAccessRequestDetails,
                          requestBranchId: valueBranchId
                        }))
                      }}
                    />
                  }
                  {errorState.branchId ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                  ) : null}
                </SingleColumnRowParent>
              }
              <SingleColumnRowParent blnDoubleTab>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customerName"
                  type="text"
                  disabled={true}
                  field={createCustomerAccessRequestDetails}
                  setFieldValue={setCreateCustomerAccessRequestDetails}
                />
              </SingleColumnRowParent>
              <SingleColumnRowParent blnDoubleTab>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "ValidTill" })}
                  name="to"
                  className={errorState.to ? "mb-0" : "mb-3"}
                  type="date"
                  disabled={loadingCreate || Boolean(successMessage)}
                  field={createCustomerAccessRequestDetails}
                  setFieldValue={setCreateCustomerAccessRequestDetails}
                  minCurrentDate={true}
                  minDateValue={moment().add(1, 'days').format(Constants.displayDateFormat)}
                />
              </SingleColumnRowParent>
              {errorState.to ? (
                <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "AtLeastOneDayAfter" }) })}</FormFeedback>
                ) : null}
              <div className="mb-2 mt-4 flex-right">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-primary margin-right-8"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        let errorStateTemp = {
                          branchId: false,
                          to: false,
                        };
                        let blnErrorFound = false;
                        if (!createCustomerAccessRequestDetails.requestBranchId) {
                          errorStateTemp.branchId = true;
                          blnErrorFound = true;
                        }
                        if (moment().isAfter(moment(createCustomerAccessRequestDetails.to))) {
                          errorStateTemp.to = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          setErrorState(errorStateTemp);
                          onClickCreateCustomerAccessRequest();
                        }
                      }}
                      disable={loadingCreate || loadingBranch || Boolean(successMessage)}
                      loading={loadingCreate}
                    />
                  </>
                }
              </div>
            </div>
          </div>
        }
      </Modal>
  )
})