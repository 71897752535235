import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const StockForecastSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, categoryStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { stockForecastSummaryList, getStockForecastSummaryList, setStockForecastSummaryList, exportStockForecastSummary } = reportStockStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: "",
      categoryIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchStockForecastSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCategoryDropdown()
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchStockForecastSummary();

    return (() => {
      setStockForecastSummaryList(undefined);
    })
  }, [])

  const fetchStockForecastSummaryWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportStockForecastSummary({ branchId:  selectedBranchId === " " ? undefined : selectedBranchId, name: validation.values.name, sKU: validation.values.sKU, categoryIds: validation.values.categoryIds})
    }
    else {
      await getStockForecastSummaryList({ branchId: selectedBranchId, name: encodeURIComponent(validation.values.name), sKU: encodeURIComponent(validation.values.sKU), categoryIds: validation.values.categoryIds });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const StockForecastSummaryListColumns = [
    {
      dataField: "productId", //field name from array to display
      text: "productId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.sku || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "totalUnredeemQuantity",
      text: intl.formatMessage({ id: "UnredeemQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.totalUnredeemQuantity}
        </div>
      ),
    },
    {
      dataField: "totalStockQuantity",
      text: intl.formatMessage({ id: "StockQuantity" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.totalStockQuantity}
        </div>
      ),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "StockForecastSummary" }) })}
        title={intl.formatMessage({ id: "StockForecastSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchStockForecastSummaryWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Stock-Forecast-Summary.aspx`}>
        <Row>
          {/* {
            !getBranchUser()
            && */}
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setErrorMessage("");
                }}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "Name" })}
                name="name"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterProductName" })}
              />
            </Col>
            <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "SKU" })}
                name="sKU"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSKU" })}
              />
            </Col>
          {/* } */}
        </Row>
        <Row>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="categoryIds"
              title={intl.formatMessage({ id: "Category" })}
              specifyReturnFieldName={[{ "field": "categoryIds", "value": "id" }]}
              returnFieldWithLabel={false}
              labelField={"name"}
              valueField={"id"}
              options={categoryDropdownList}
              disabled={localLoading || loading}
              validationRequired={false}
              validation={validation}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchStockForecastSummaryWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={StockForecastSummaryListColumns}
                data={stockForecastSummaryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "StockForecastSummary" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                name={encodeURIComponent(validation.values.name)}
                sKU={encodeURIComponent(validation.values.sKU)}
                categoryIds={validation.values.categoryIds}
                keyField={"productId"}
                requestAPI={getStockForecastSummaryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(StockForecastSummary);
