
import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import { CommissionSchemeListObject } from "../../../app/models/commissionScheme";
import DeleteModal from "../../../app/components/modal/DeleteModal";
import { Constants } from '../../../app/constants/Constants';
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import MyAddButton from "../../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import TableActionButton from "../../../app/components/table/TableActionButton";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { PaginationRequestBody } from "../../../app/models/pagination";

const BundledProductCommissionScheme = () => {
  const intl = useIntl();
  //Use Store
  const { commissionSchemeStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { commissionSchemeList, commissionSchemePaginationParams, getCommissionScheme, deleteCommissionScheme, setCommissionSchemePaginationParams } = commissionSchemeStore;
  const { commissionSchemeType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: "" }];
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [selectedRow, setSelectedRow] = useState<CommissionSchemeListObject | undefined>(undefined);
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;

  let bundledProductCommissionSchemeRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    commissionSchemeType: 0, // 0 = package, 1= product
  }
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCommissionSchemeListAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.bundledProductCommissionScheme)
      if (blnBackNavigation) {
        if (commissionSchemePaginationParams) {
          setCurrentPage(commissionSchemePaginationParams.pageNumber)
          bundledProductCommissionSchemeRequestBody = commissionSchemePaginationParams;
        }
      }
      else {
        setCommissionSchemePaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchCommissionSchemeAPI(true),
        getStaticReferenceWithType(Constants.commissionSchemeType, AllType)
      ];
      await Promise.all(aryAPI);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCommissionScheme], true)) {
      return;
    }

    fetchCommissionSchemeListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.bundledProductCommissionScheme)
        if (!blnSetParams) {
          setCommissionSchemePaginationParams(undefined);
        }
      }
    })
  }, [])

  // useEffect(() => {
  //   if ((selectedCommissionSchemeTypeId || selectedCommissionSchemeTypeId === 0) && !initialCallAPI) {
  //     fetchCommissionSchemeAPI(true);
  //   }
  // }, [selectedCommissionSchemeTypeId])

  async function fetchCommissionSchemeAPI(blnDropDown?: boolean) {
    // blnDropDown ? setLocalLoading(true) : setLoading(true);
    setLoading(true);
    await getCommissionScheme(bundledProductCommissionSchemeRequestBody)
    setLoading(false);
    // blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: CommissionSchemeListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const CommissionSchemeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        width: '35%'
      },
    },
    {
      dataField: "commissionSchemeType",
      text: intl.formatMessage({ id: "CommissionSchemeType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.commissionSchemeType)}</div>
      ),
    },
    {
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
              <TableActionButton
                to={`/${RoutesList.bundledProductCommissionScheme}/view/${row.id}`}
                type={'view'} />
            {
              checkPermission([PermissionConstants.UpdateCommissionScheme])
              &&
              <TableActionButton
                to={`/${RoutesList.bundledProductCommissionScheme}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              checkPermission([PermissionConstants.DeleteCommissionScheme])
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "BundledProductCommissionSettings" }) })}
        title={intl.formatMessage({ id: "BundledProductCommissionSettings" })}
        breadCrumbList={breadCrumbList}
        addButton={
          checkPermission([PermissionConstants.CreateCommissionScheme])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "BundledProductCommissionSettings" }) })}
            onClick={() => { history.push(`/${RoutesList.bundledProductCommissionScheme}/add`) }}
            linkTo={`/${RoutesList.bundledProductCommissionScheme}/add`}
            disable={loading} />
        }>
        {/* <Row>
          <Col xl={'4'} md={'6'} xs={'6'}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "CommissionSchemeType" })}
              name="commissionSchemeType"
              options={commissionSchemeTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedCommissionSchemeType}
              initialValue={selectedCommissionSchemeTypeId}
              setFieldLabel={setSelectedCommissionSchemeType}
              setFieldValue={setSelectedCommissionSchemeTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </Col>
        </Row> */}
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CommissionSchemeColumns}
          data={commissionSchemeList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "BundledProductCommissionSettings" }) })}
          objSorted={defaultSorted}
          commissionSchemeType={0}
          requestAPI={getCommissionScheme} />

        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCommissionScheme(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (commissionSchemeList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCommissionScheme({ pageNumber: currentPageTemp, pageSize: pageSize, commissionSchemeType: 0 })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(BundledProductCommissionScheme);
