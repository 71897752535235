import { PaginationRequestBody } from "../models/pagination";
import { CurrencyListObject, CurrencyAddObject, CurrencyUpdateObject, CurrencyDetailObject } from "../models/currency";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class currencyStore {
  currencyList: CurrencyListObject[] = [];
  currencyDropdownList: CurrencyListObject[] = [];
  currencyDetail: CurrencyDetailObject | undefined = undefined;
  currencyPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.currencyList = [];
    this.currencyDropdownList = [];
    this.currencyDetail = undefined;
    this.currencyPaginationParams= undefined;
  }

  setCurrencyDetail = (currencyDetail: CurrencyDetailObject | undefined) => {
    this.currencyDetail = currencyDetail;
  }

  setCurrencyPaginationParams = (currencyPaginationParams: PaginationRequestBody | undefined) => {
    this.currencyPaginationParams = currencyPaginationParams;
  }
  
  getCurrency = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCurrencyPaginationParams(PaginationRequestBody);
    try{
      const resultCurrency = await agent.Currency.currencyList(PaginationRequestBody);
      runInAction(() => {
        this.currencyList = resultCurrency.data;
        store.commonStore.setTotalItem(resultCurrency.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.currencyList = [];
    }
  }

  getCurrencyDropdown = async () => {
    try{
      const resultCurrencyDropdown = await agent.Currency.currencyDropdownList();
      runInAction(() => {
        this.currencyDropdownList = resultCurrencyDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.currencyDropdownList = [];
    }
  }

  getCurrencyWithId = async (id: string) => {
    try{
      const resultCurrencyDetail = await agent.Currency.currencyDetail(id);
      runInAction(() => {
        this.currencyDetail = resultCurrencyDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.currencyDetail = undefined;
    }
  }

  addCurrency = async (currencyRequestBody: CurrencyAddObject) => {
    try{
      await agent.Currency.addCurrency(currencyRequestBody);
      store.commonStore.setSuccessMessage(`CurrencyAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCurrency = async (currencyRequestBody: CurrencyUpdateObject) => {
    try{
      await agent.Currency.updateCurrency(currencyRequestBody);
      store.commonStore.setSuccessMessage(`CurrencyUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCurrency  = async (id: string, name: string) => {
    try {
      await agent.Currency.deleteCurrency(id);
      store.commonStore.setSuccessMessage(`CurrencyDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}