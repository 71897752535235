import { useEffect, useState } from "react";
import {FormFeedback, Label, Modal} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../../app/stores/store";
import { Constants } from "../../../../app/constants/Constants";
import { AppolousCustomerPackageEditRequestBody } from "../../../../app/models/appolousCustomerPackage";
import GeneralInputForModal from "../../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId?: string;
  currentPage?: number;
  setCurrentPage?: Function;
  editFunction: Function;
  getAppolousCustomerPackages?: Function;
  selectedAppolousCustomerPackageDetail: any;
  setSelectedAppolousCustomerPackageDetail: Function;
  setErrorMessage?: Function;
  setLoadingAppolousCustomerPackage?: Function;
}

export default function AppolousCustomerPackageEditModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [localLoadingAppolousCustomerPackage, setLocalLoadingAppolousCustomerPackage] = useState(false);

  const defaultAppolousCustomerPackageDetail = {
    appolousCustomerPackageId: "",
    packageName: "",
    totalSessions: 0,
    redeemedSessions: 0
  }

  const [errorState, setErrorState] = useState({
    totalSessions: false,
  })

  const [appolousCustomerPackageDetail, setAppolousCustomerPackageDetail] = useState(defaultAppolousCustomerPackageDetail)
  const disabledFieldInput = localLoadingAppolousCustomerPackage || Boolean(successMessage);

  useEffect(() => {
    if (props.selectedAppolousCustomerPackageDetail) {
      setAppolousCustomerPackageDetail(props.selectedAppolousCustomerPackageDetail);
    }
  }, [props.selectedAppolousCustomerPackageDetail])

  useEffect(() => {
    async function getAppolousCustomerPackageList() {
      if(props.setLoadingAppolousCustomerPackage){
        props.setLoadingAppolousCustomerPackage(true);
        if(props.getAppolousCustomerPackages){
          await props.getAppolousCustomerPackages({ pageNumber: props.currentPage, pageSize: Constants.maxPageSize, customerId: props.customerId });
        }
        props.setLoadingAppolousCustomerPackage(false);
      }
    }

    if (successMessage) {
      setTimeout(() => {
        hideModal();
        getAppolousCustomerPackageList();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      totalSessions: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    setAppolousCustomerPackageDetail(defaultAppolousCustomerPackageDetail);
    props.setSelectedAppolousCustomerPackageDetail(undefined);
    props.setModal(false);
  }

  const onClickEditAppolousCustomerPackage = async (appolousCustomerPackageDetailTemp: AppolousCustomerPackageEditRequestBody) => {
    setLocalLoadingAppolousCustomerPackage(true);
    await props.editFunction(appolousCustomerPackageDetailTemp);
    setLocalLoadingAppolousCustomerPackage(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          // tog_center();
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Edit" })} {intl.formatMessage({ id: "AppolousCustomerPackage" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <GeneralInputForModal
            title={intl.formatMessage({ id: "PackageName" })}
            name="packageName"
            className={"mb-3"}
            type="text"
            validationRequired={true}
            field={appolousCustomerPackageDetail}
            setFieldValue={setAppolousCustomerPackageDetail}
            disabled={disabledFieldInput}
          />
          <GeneralInputForModal
            title={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "TotalSessions" })})}
            name="totalSessions"
            className={errorState.totalSessions ? "mb-0" : "mb-3"}
            type="number"
            validationRequired={true}
            field={appolousCustomerPackageDetail}
            setFieldValue={setAppolousCustomerPackageDetail}
            onChangeFunction={()=> {clearErrorState("totalSessions")}}
            disabled={disabledFieldInput}
            childrenUI={<p className="text-muted margin-bottom-0 mt-1">{`${intl.formatMessage({ id: "TotalRedeemedSessions" })}: ${appolousCustomerPackageDetail.redeemedSessions}`}</p>}/>
          {errorState.totalSessions ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "Appolous-008" })}</FormFeedback>
          ) : null}
          <div className="float-end">
            {
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: "Submit" })}
                onClick={() => {
                  let errorStateTemp = {
                    totalSessions: false,
                  };
                  let blnErrorFound = false;
                  if (appolousCustomerPackageDetail.totalSessions < appolousCustomerPackageDetail.redeemedSessions) {
                    errorStateTemp.totalSessions = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickEditAppolousCustomerPackage(appolousCustomerPackageDetail)
                  }
                }}
                loading={localLoadingAppolousCustomerPackage}
                disable={disabledFieldInput}
              />
            }
          </div>
        </div>
      </Modal>
  )
}