import { AppointmentCalendarObject, AppointmentAddObject, AppointmentUpdateObject, AppointmentDetailObject, RoomListObject, AppointmentGetListObject, AppointmentUpdateStatusObject, AppointmentBlockerDetailObject, AppointmentBlockerAddObject, AppointmentBlockerUpdateObject, AppointmentListObject, AppointmentGetListBranchObject, AppointmentObjectAfterSuccess, AppointmentHistoryListObject, AppointmentPinRequestObject } from "../models/appointment";
import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";
import moment from "moment";
import { newSpaceBeforeCapitalLetter } from "../common/function/function";

export default class appointmentStore {
  appointmentList: AppointmentListObject[] = [];
  appointmentCalendar: AppointmentCalendarObject | undefined = undefined;
  appointmentDetail: AppointmentDetailObject | undefined = undefined;
  appointmentBlockerDetail: AppointmentBlockerDetailObject | undefined = undefined;
  updatedAppointmentStatus: string | undefined;
  roomList: RoomListObject[] = [];
  objAppointment: AppointmentGetListObject | undefined = undefined;
  appointmentListPaginationParams: PaginationRequestBody | undefined = undefined;
  appointmentTotalItems: number = 0;
  appointmentObjAfterSuccess : AppointmentObjectAfterSuccess | undefined = undefined;
  appointmentHistory: AppointmentHistoryListObject[] = [];
  appointmentErrorPrompt: string = "";

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appointmentList = [];
    this.appointmentCalendar = undefined;
    this.appointmentDetail = undefined;
    this.appointmentBlockerDetail = undefined;
    this.updatedAppointmentStatus = undefined;
    this.roomList = [];
    this.objAppointment = undefined;
    this.appointmentListPaginationParams = undefined;
    this.appointmentObjAfterSuccess = undefined;
    this.appointmentHistory = [];
    this.appointmentErrorPrompt = "";
  }

  setAppointmentList = (appointmentList: AppointmentListObject[]) => {
    this.appointmentList = appointmentList;
  }

  setAppointmentDetail = (appointmentDetail: AppointmentDetailObject | undefined) => {
    this.appointmentDetail = appointmentDetail;
  }

  setAppointmentBlockerDetail = (appointmentBlockerDetail: AppointmentBlockerDetailObject | undefined) => {
    this.appointmentBlockerDetail = appointmentBlockerDetail;
  }

  setUpdatedAppointmentStatus = (status: string | undefined) => {
    this.updatedAppointmentStatus = status;
  }

  setRoomList = (roomListTemp : RoomListObject[]) => {
    this.roomList =roomListTemp;
  }

  setObjAppointment = (objAppointmentTemp: AppointmentGetListObject | undefined) => {
    this.objAppointment = objAppointmentTemp;
  }

  setAppointmentListPaginationParams = (appointmentListPaginationParams: PaginationRequestBody | undefined) => {
    this.appointmentListPaginationParams = appointmentListPaginationParams;
  }

  setAppointmentObjAfterSuccess = (appointmentObjAfterSuccess: AppointmentObjectAfterSuccess | undefined) => {
    this.appointmentObjAfterSuccess = appointmentObjAfterSuccess;
  }

  setAppointmentHistory = (appointmentHistory: AppointmentHistoryListObject[]) => {
    this.appointmentHistory = appointmentHistory;
  }

  setAppointmentErrorPrompt = (appointmentErrorPrompt: string) => {
    this.appointmentErrorPrompt = appointmentErrorPrompt;
  }

  getAppointment = async (appointmentRequestBody: AppointmentGetListObject) => {
    try{
      const resultAppointment = await agent.Appointment.appointmentCalendar(appointmentRequestBody);
      runInAction(() => {
        if (resultAppointment.events) {
          resultAppointment.events = resultAppointment.events.map((valueEvent)=> {
            let valueEventTemp =  _.cloneDeep(valueEvent);
            if (valueEventTemp.end === null) {
              delete valueEventTemp["end"];
            }
            
            valueEventTemp = ({
              ...valueEventTemp,
              backgroundColor: valueEventTemp.isBlocker ? "grey" : valueEventTemp.backgroundColor,
              borderColor: valueEventTemp.isBlocker ? "grey" : valueEventTemp.backgroundColor,
              start: !valueEventTemp.end ? moment(valueEventTemp.start).format(Constants.displayDateFormat) : valueEventTemp.start,
              blnAllDay: !valueEventTemp.end
            })
            return valueEventTemp;
          })
        }
        this.appointmentCalendar = resultAppointment;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentCalendar = undefined;
    }
  }

  getAppointmentList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setAppointmentListPaginationParams(PaginationRequestBody);
      const resultAppointmentList = await agent.Appointment.appointmentList(PaginationRequestBody);
      resultAppointmentList.data = resultAppointmentList.data.map((valueResultAppointmentList)=> {
        return({
          ...valueResultAppointmentList,
          serviceTreatmentNames: valueResultAppointmentList.serviceTreatmentNames.toString()
        })
      })
      runInAction(() => {
        this.appointmentList = resultAppointmentList.data;
        this.appointmentTotalItems = resultAppointmentList.pagination.totalItems;
      });

      return Promise.resolve(resultAppointmentList.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentList = [];
      this.appointmentTotalItems = 0;
    }
  }

  getAppointmentWithId = async (id: string) => {
    try{
      const resultAppointmentDetail = await agent.Appointment.appointmentDetail(id);
      runInAction(() => {
        this.appointmentDetail = resultAppointmentDetail;
      });
      return Promise.resolve(resultAppointmentDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentDetail = undefined;
    }
  }

  getRoomWithBranchId = async (id: string) => {
    try{
      const resultRoom = await agent.Appointment.roomList(id);
      runInAction(() => {
        resultRoom.map((value) => {
          value.displayOrder = value.displayOrder + 1
        })
        resultRoom.splice(0,0,{
          displayOrder: 1,
          id: Constants.emptyId,
          isActive: true,
          name: "Not Available"
        })
        this.roomList = resultRoom;
      });

      return Promise.resolve(resultRoom)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.roomList = [];
    }
  }

  addAppointment = async (appointmentRequestBody: AppointmentAddObject) => {
    try{
      const resultAppointment = await agent.Appointment.addAppointment(appointmentRequestBody);
      if (!resultAppointment.isRejoin && !resultAppointment.isCrossBrand && !resultAppointment.hasFutureValidAppointment) {
        store.commonStore.setSuccessMessage(`AppointmentAddSuccess`)
        this.setAppointmentObjAfterSuccess({
          branchId: appointmentRequestBody.branchId,
          branchName: appointmentRequestBody.branchName,
          startDate: appointmentRequestBody.appointStartDateTime,
          endDate: appointmentRequestBody.appointStartDateTime
        })
      }
      else if (resultAppointment.hasFutureValidAppointment) {
        this.setAppointmentErrorPrompt(`There's an appointment scheduled with current contact no at ${moment(resultAppointment.futureValidAppointment.appointmentStartDateTime).format(Constants.displayDateAndTimeFormat)} - ${moment(resultAppointment.futureValidAppointment.appointmentEndDateTime).format(Constants.displayTimeFormat) } in ${resultAppointment.futureValidAppointment.branchName}.`)
        return Promise.resolve({status: Constants.success, data: resultAppointment});
      }
      else {
        return Promise.resolve({status: Constants.success, data: resultAppointment});
      }
    }
    catch (error: any) {
      console.error(error)
      if (error.errorCode) {
        this.setAppointmentErrorPrompt(error.errorMessage)
      }
      else {
        store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      }
    }
  }

  addAppointmentForCalendar = async (appointmentRequestBody: AppointmentAddObject) => {
    try{
      let resultAddAppointment = await agent.Appointment.addAppointment(appointmentRequestBody);
      if (resultAddAppointment.hasFutureValidAppointment) {
        this.setAppointmentErrorPrompt(`There's an appointment scheduled with current contact no at ${moment(resultAddAppointment.futureValidAppointment.appointmentStartDateTime).format(Constants.displayDateAndTimeFormat)} - ${moment(resultAddAppointment.futureValidAppointment.appointmentEndDateTime).format(Constants.displayTimeFormat) } in ${resultAddAppointment.futureValidAppointment.branchName}.`)
        return Promise.resolve({status: Constants.skip, data: resultAddAppointment});
      }
      else {
        store.commonStore.setSuccessMessage(`AppointmentAddSuccess`)
        return Promise.resolve({status: Constants.success, data: resultAddAppointment});
      }
    }
    catch (error: any) {
      console.error(error)
      if (error.errorCode) {
        store.commonStore.setErrorMessage(error.errorMessage)
      }
      else {
        store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      }
    }
  }

  updateAppointment = async (appointmentRequestBody: AppointmentUpdateObject) => {
    try{
      let resultUpdateAppointment = await agent.Appointment.updateAppointment(appointmentRequestBody);
      if (!resultUpdateAppointment.isRejoin && !resultUpdateAppointment.isCrossBrand && !resultUpdateAppointment.hasFutureValidAppointment) {
        store.commonStore.setSuccessMessage(`AppointmentUpdateSuccess`)
        this.setAppointmentObjAfterSuccess({
          branchId: appointmentRequestBody.branchId,
          branchName: appointmentRequestBody.branchName,
          startDate: appointmentRequestBody.appointStartDateTime,
          endDate: appointmentRequestBody.appointStartDateTime
        })
        return Promise.resolve({status: Constants.success, data: resultUpdateAppointment});
      }
      else if (resultUpdateAppointment.hasFutureValidAppointment) {
        this.setAppointmentErrorPrompt(`There's an appointment scheduled with current contact no at ${moment(resultUpdateAppointment.futureValidAppointment.appointmentStartDateTime).format(Constants.displayDateAndTimeFormat)} - ${moment(resultUpdateAppointment.futureValidAppointment.appointmentEndDateTime).format(Constants.displayTimeFormat) } in ${resultUpdateAppointment.futureValidAppointment.branchName}.`)
        return Promise.resolve({status: Constants.success, data: resultUpdateAppointment});
      }
      else {
        return Promise.resolve({status: Constants.success, data: resultUpdateAppointment});
      }
    }
    catch (error: any) {
      console.error(error)
      if (error.errorCode) {
        this.setAppointmentErrorPrompt(error.errorMessage)
      }
      else {
        store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      }
    }
  }

  updateAppointmentStatus = async (appointmentRequestBody: AppointmentUpdateStatusObject) => {
    try{
      await agent.Appointment.updateAppointmentStatus(appointmentRequestBody);
      this.setUpdatedAppointmentStatus(appointmentRequestBody.appointmentStatus);
      store.commonStore.setSuccessMessage(`AppointmentStatusUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  deleteAppointment = async (id: string, name: string) => {
    try {
      await agent.Appointment.deleteAppointment(id);
      store.commonStore.setSuccessMessage(`AppointmentDeleteSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getAppointmentBlockerWithId = async (id: string) => {
    try{
      const resultAppointmentBlockerDetail = await agent.Appointment.appointmentBlockerDetail(id);
      runInAction(() => {
        this.appointmentBlockerDetail = resultAppointmentBlockerDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addAppointmentBlocker = async (appointmentBlockerRequestBody: AppointmentBlockerAddObject) => {
    try{
      let resultAppointmentBlocker = await agent.Appointment.addAppointmentBlocker(appointmentBlockerRequestBody);
      store.commonStore.setSuccessMessage(`AppointmentBlockerAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAppointmentBlocker});
    }
    catch (error: any) {
      console.error(error)
      if (error.message) {
        if (error.message === 'Appointment-004') {
          return Promise.resolve({status: Constants.failed, data: error});
        }
      }
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateAppointmentBlocker = async (appointmentBlockerRequestBody: AppointmentBlockerUpdateObject) => {
    try{
      let resultAppointmentBlocker = await agent.Appointment.updateAppointmentBlocker(appointmentBlockerRequestBody);
      store.commonStore.setSuccessMessage(`AppointmentBlockerUpdateSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAppointmentBlocker});
    }
    catch (error: any) {
      console.error(error)
      if (error.message) {
        if (error.message === 'Appointment-004') {
          return Promise.resolve({status: Constants.failed, data: error});
        }
      }
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  linkAppointmentCustomer = async (id: string, customerId: string) => {
    try{
      await agent.Appointment.linkAppointmentCustomer({id: id, customerId: customerId});
      store.commonStore.setSuccessMessage(`AppointmentCustomerLinkSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  unlinkAppointmentCustomer = async (id: string) => {
    try{
      await agent.Appointment.unlinkAppointmentCustomer(id);
      store.commonStore.setSuccessMessage(`AppointmentCustomerUnlinkSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getAppointmentCustomerTreatmentList = async (customerId: string) => {
    try{
      const resultAppointmentCustomerTreatmentList = await agent.Appointment.appointmentCustomerTreatmentList(customerId);
      return Promise.resolve({status: Constants.success, data: resultAppointmentCustomerTreatmentList})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  getAppointmentCustomerPurchasedPackageList = async (customerId: string) => {
    try{
      const resultAppointmentCustomerPurchasedPackageList = await agent.Appointment.appointmentCustomerPurchasedPackageList(customerId);
      let finalGroupData : any = [];
      resultAppointmentCustomerPurchasedPackageList.map((valueResultAppointmentCustomerPurchasedPackage)=> {
        let documentNoDisplay = `${valueResultAppointmentCustomerPurchasedPackage.documentNo} (${valueResultAppointmentCustomerPurchasedPackage.isAppolousPackage ? "Appolous" : "ERP"})`;
        let indexDocumentNo = _.findIndex(finalGroupData, { label: documentNoDisplay })
        let optionServices: any = [];

        valueResultAppointmentCustomerPurchasedPackage.services.map((valueServices)=> {
          optionServices.push({
            ...valueServices,
            name: `${valueServices.serviceTreatmentName}`,
            id: valueServices.serviceTreatmentId
          })
        })
        
        let packageNameDisplay = `${valueResultAppointmentCustomerPurchasedPackage.packageName} (${valueResultAppointmentCustomerPurchasedPackage.redeemedSession}/${valueResultAppointmentCustomerPurchasedPackage.totalSession})`
        let optionPackage = {
          label: packageNameDisplay,
          packageName: packageNameDisplay,
          value: valueResultAppointmentCustomerPurchasedPackage.purchasedPackageId,
          purchasedPackageId: valueResultAppointmentCustomerPurchasedPackage.purchasedPackageId,
          services: optionServices,
          isAppolousPackage: valueResultAppointmentCustomerPurchasedPackage.isAppolousPackage,
        }

        if (indexDocumentNo > -1) {
          finalGroupData[indexDocumentNo].options.push(optionPackage)
        }
        else {
          finalGroupData.push({
            label: documentNoDisplay,
            value: valueResultAppointmentCustomerPurchasedPackage.purchasedPackageId,
            options: [optionPackage],
            isAppolousPackage: valueResultAppointmentCustomerPurchasedPackage.isAppolousPackage
          })
        }
      })
      return Promise.resolve({status: Constants.success, data: finalGroupData})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  getAppointmentHistoryList = async (appointmentId: string) => {
    try{
      let resutlAppointmentHistory = await agent.Appointment.appointmentHistory(appointmentId);
      resutlAppointmentHistory = resutlAppointmentHistory.map((valueResultAppointmentHistory) => {
        if (valueResultAppointmentHistory.oldValue && valueResultAppointmentHistory.newValue) {
          if (valueResultAppointmentHistory.oldValue?.appointStartDateTime) {
            valueResultAppointmentHistory.oldValue = {
              ...valueResultAppointmentHistory.oldValue,
              appointStartDate: moment(valueResultAppointmentHistory.oldValue.appointStartDateTime).format(Constants.displayDateFormat),
              appointStartTime: moment(valueResultAppointmentHistory.oldValue.appointStartDateTime).format(Constants.displayTimeFormat),
              appointStartDateTime: ""
            }
          }

          if (valueResultAppointmentHistory.newValue?.appointStartDateTime) {
            valueResultAppointmentHistory.newValue = {
              ...valueResultAppointmentHistory.newValue,
              appointStartDate: moment(valueResultAppointmentHistory.newValue.appointStartDateTime).format(Constants.displayDateFormat),
              appointStartTime: moment(valueResultAppointmentHistory.newValue.appointStartDateTime).format(Constants.displayTimeFormat),
              appointStartDateTime: ""
            }
          }


          if (valueResultAppointmentHistory.oldValue?.appointEndDateTime) {
            valueResultAppointmentHistory.oldValue = {
              ...valueResultAppointmentHistory.oldValue,
              appointEndDate: moment(valueResultAppointmentHistory.oldValue.appointEndDateTime).format(Constants.displayDateFormat),
              appointEndTime: moment(valueResultAppointmentHistory.oldValue.appointEndDateTime).format(Constants.displayTimeFormat),
              appointEndDateTime: ""
            }
          }

          if (valueResultAppointmentHistory.newValue?.appointEndDateTime) {
            valueResultAppointmentHistory.newValue = {
              ...valueResultAppointmentHistory.newValue,
              appointEndDate: moment(valueResultAppointmentHistory.newValue.appointEndDateTime).format(Constants.displayDateFormat),
              appointEndTime: moment(valueResultAppointmentHistory.newValue.appointEndDateTime).format(Constants.displayTimeFormat),
              appointEndDateTime: ""
            }
          }

          valueResultAppointmentHistory.oldValue.serviceTreatmentNames = "";
          valueResultAppointmentHistory.newValue.serviceTreatmentNames = "";

          let aryOldValueTemp : any = [];
          let aryNewValueTemp: any = [];
          let aryAppointmentKeyTemp = Object.keys(valueResultAppointmentHistory.oldValue);
          aryAppointmentKeyTemp.map((valueAppointmentKeyTemp)=> {
            if ((valueResultAppointmentHistory.oldValue[valueAppointmentKeyTemp] || valueResultAppointmentHistory.newValue[valueAppointmentKeyTemp]) && valueResultAppointmentHistory.oldValue[valueAppointmentKeyTemp] !== valueResultAppointmentHistory.newValue[valueAppointmentKeyTemp]) {
              aryOldValueTemp.push(this.returnAppointmentKey(valueAppointmentKeyTemp, valueResultAppointmentHistory.oldValue));
              aryNewValueTemp.push(this.returnAppointmentKey(valueAppointmentKeyTemp, valueResultAppointmentHistory.newValue));
            }
          })
          valueResultAppointmentHistory.finalOldValue = aryOldValueTemp;
          valueResultAppointmentHistory.finalNewValue = aryNewValueTemp;
        }
        return valueResultAppointmentHistory;
      })
      runInAction(() => {
        this.appointmentHistory = resutlAppointmentHistory;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentHistory = [];
    }
  }

  returnAppointmentKey = (keyField: string, historyValue) => {
    switch(keyField) {
      case "branchName":
        return `Branch: ${historyValue[keyField] || Constants.emptyDataV2 } `;
      case "roomName":
        return  `Room: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "consultantName":
        return  `Consultant: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "appointStartDate":
        return  `Date: ${historyValue[keyField] || Constants.emptyDataV2}  `;
      case "appointStartTime":
        return  `Start Time: ${historyValue[keyField] || Constants.emptyDataV2}  `;
      case "appointEndDate":
        return  `End Date: ${historyValue[keyField] || Constants.emptyDataV2}  `;
      case "appointEndTime":
        return  `End Time: ${historyValue[keyField] || Constants.emptyDataV2}  `;
      case "serviceTreatments":
        return  `Services: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "remark":
        return  `Remark: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "appointmentStatus":
        return  `Status: ${historyValue[keyField] ? newSpaceBeforeCapitalLetter(historyValue[keyField]) : Constants.emptyDataV2} `;
      case "isNotifyCustomer":
        return  `Notify Customer: ${historyValue[keyField] ? Constants.nikeSymbol : Constants.xSymbol} `;
      case "isFollowUpOnly":
        return  `Follow Up Only: ${historyValue[keyField] ? Constants.nikeSymbol : Constants.xSymbol} `;
      case "customerName":
        return  `Name: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "mediaSourceName":
        return  `Media Source: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "referredByName":
        return  `Referred By: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "contactNo":
        return  `Contact No: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "email":
        return  `Email: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "subBrandName":
        return  `Sub Brand: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "title":
        return  `Title: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "isAllDay":
        return  `All Day: ${historyValue[keyField] ? Constants.nikeSymbol : Constants.xSymbol} `;
      case "isPin": 
        return  `Pin: ${historyValue[keyField] ? Constants.nikeSymbol : Constants.xSymbol} `;
      case "isPurchasedPackageSelected": 
        return  `Purchased Package Selected: ${historyValue[keyField] ? Constants.nikeSymbol : Constants.xSymbol} `;
      case "purchasedPackageName": 
        return  `Purchased Package: ${historyValue[keyField] || Constants.emptyDataV2} `;
      case "gender": 
        return  `Gender: ${historyValue[keyField] || Constants.emptyDataV2} `;
        
      default:
        return undefined;
    }
  }

  appointmentPin = async (appointmentPinRequestBody: AppointmentPinRequestObject) => {
    try{
      await agent.Appointment.appointmentPin(appointmentPinRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(appointmentPinRequestBody.isPin ? `AppointmentCustomerPinSuccess` : `AppointmentCustomerUnpinSuccess`)
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }
}