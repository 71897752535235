import { PermissionListObject, PermissionUserListObject, PermissionUpdateObject, MenuListObject } from "../models/permission";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import _ from 'lodash';
import { MenuConstants, MenuList } from "../constants/MenuConstants";
import { PermissionConstants } from "../constants/PermissionConstants";
import { checkPermission } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class permissionStore {
  permissionList: PermissionListObject[] = [];
  permissionUserList: PermissionUserListObject[] = [];
  menuList: MenuListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.permissionList = [];
    this.permissionUserList = [];
    this.menuList = [];
  }
  
  getPermission = async () => {
    try{
      const resultPermission = await agent.Permission.permissionList();
      runInAction(() => {
        this.permissionList = resultPermission;
      });

      return Promise.resolve(resultPermission)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.permissionList = [];
    }
  }

  getPermissionUser = async () => {
    try{
      const resultPermissionUser = await agent.Permission.permissionUserList();
      let aryMenuTemp : MenuListObject[] = _.cloneDeep(MenuList);      
      aryMenuTemp.map((valueMenuTemp)=> {
        valueMenuTemp.subContent.map((valueSubContent)=> {
          if (!valueSubContent.isSubMenu) {
            let indexSystemName = _.findIndex(resultPermissionUser, {systemName: valueSubContent.systemName })
            if (indexSystemName > -1) {
              valueSubContent.isActive = true;
              valueMenuTemp.isActive = true;
            }     
          }
          else if (valueSubContent.subContent) {
            valueSubContent.subContent.map((valueSubContentLevel2)=> {
              let indexSystemName = _.findIndex(resultPermissionUser, {systemName: valueSubContentLevel2.systemName })
              if (indexSystemName > -1) {
                valueSubContent.isActive = true;
                valueMenuTemp.isActive = true;
                valueSubContentLevel2.isActive = true;
              }   
            })
          }
        })
        if (valueMenuTemp.systemName) {
          if (valueMenuTemp.menuName === MenuConstants.Report) {
            let blnPermissionReport = checkPermission(Constants.ReportPermissionList, false , resultPermissionUser);
            valueMenuTemp.isActive = blnPermissionReport;          
          }
          else {
            for (let a=0; a<resultPermissionUser.length; a++) {
              let valuePermissionUser = resultPermissionUser[a];
              if (valuePermissionUser.systemName === valueMenuTemp.systemName) {
                valueMenuTemp.isActive = true;
                break;
              }
            }
          }
        }
      })

      runInAction(() => {
        this.permissionUserList = resultPermissionUser;
        this.menuList = aryMenuTemp;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.permissionUserList = [];
    }
  }

  get returnPermissionUserList () {
    return this.permissionUserList;
  }

  updatePermission = async (permissionRequestBody: PermissionListObject[]) => {
    try{
      // let permissionRequestBodyTemp : PermissionUpdateObject[] = _.differenceWith(permissionRequestBody, this.permissionList, _.isEqual)
      let permissionRequestBodyTemp : PermissionUpdateObject[] = [];
      permissionRequestBody.map((valuePermissionRequestBodyTemp)=> {
        for (var a=0; a<this.permissionList.length; a++) {
          let valuePermissionListTemp = this.permissionList[a];
          if (valuePermissionRequestBodyTemp.name === valuePermissionListTemp.name && valuePermissionRequestBodyTemp.systemName === valuePermissionListTemp.systemName) {
            if (JSON.stringify(valuePermissionRequestBodyTemp) !== JSON.stringify(valuePermissionListTemp)) {
              permissionRequestBodyTemp.push(valuePermissionRequestBodyTemp);
            }
            break;
          }
        }
      })
      await agent.Permission.updatePermission(permissionRequestBodyTemp);
      store.commonStore.setSuccessMessage(`PermissionUpdateSuccess`)
      this.permissionList = permissionRequestBody;
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}