import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { LeadDuplicateListObject } from '../../app/models/lead';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBrandId, newSpaceBeforeCapitalLetter, returnBlobImage } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import MyButton from "../../app/components/form/MyButton";
import { EmployeeListObject } from "../../app/models/employee";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { useLocation } from "react-router-dom";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { PaginationRequestBody } from "../../app/models/pagination";

const LeadDuplicate = () => {
  const intl = useIntl();
  //Use Store
  const { leadStore, mediaSourceStore, branchStore, staticReferenceStore, employeeStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setSuccessMessage, setErrorMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { leadDuplicateList, leadDuplicatePaginationParams, getLeadDuplicate, deleteLeadDuplicate, setLeadDuplicatePaginationParams } = leadStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<LeadDuplicateListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState(All);
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const blnPermissionDeleteLeadDuplicate = checkPermission([PermissionConstants.DeleteLeadDuplicate]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let leadDuplicateRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    sourceId: selectedMediaSourceId,
    sourceName: selectedMediaSourceName,
    dateRange: selectedDateRange,
    marcomConsultantId: selectedMarcomConsultantId,
    marcomConsultantName: selectedMarcomConsultantName,

  }

  useEffect(() => {
    async function fetchLeadDuplicateListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.leadDuplicate)
      if (blnBackNavigation) {
        if (leadDuplicatePaginationParams) {
          setCurrentPage(leadDuplicatePaginationParams.pageNumber)
          setSelectedMediaSourceId(leadDuplicatePaginationParams.sourceId || "");
          setSelectedMediaSourceName(leadDuplicatePaginationParams.sourceName || AllSource);
          setSelectedDateRange(leadDuplicatePaginationParams.dateRange);
          setSelectedMarcomConsultantId(leadDuplicatePaginationParams.marcomConsultantId || "");
          setSelectedMarcomConsultantName(leadDuplicatePaginationParams.marcomConsultantName || All);
          leadDuplicateRequestBody = leadDuplicatePaginationParams;
        }
      }
      else {
        setLeadDuplicatePaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchLeadDuplicateApi(false),
        getMediaSourceDropdown(),
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "", userRoleIds: [`${Constants.marComUserRoleId},${Constants.customerServiceId},${Constants.marComCustomerServiceId}`] })
      ];
      let resultAPI = await Promise.all(aryAPI);
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"));

      if (resultAPI[2] && resultAPI[2].length > 0) {
        let marcomConsultantListTemp: any = _.cloneDeep(resultAPI[2]);
        marcomConsultantListTemp = marcomConsultantListTemp.map((value) => ({ label: value.preferredName, value: value.id }))
        marcomConsultantListTemp.splice(0, 0, { label: All, value: " " });
        setMarcomConsultantList(marcomConsultantListTemp)
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageLeadDuplicate], true)) {
      return;
    }

    fetchLeadDuplicateListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.leadDuplicate)
        if (!blnSetParams) {
          setLeadDuplicatePaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (!initialCallAPI) {
      fetchLeadDuplicateApi(true);
    }
  }, [selectedMarcomConsultantId, selectedMediaSourceId, selectedDateRange])

  async function fetchLeadDuplicateApi(blnDropDown: boolean = false, pageSizeInsert?: number, leadDuplicateStatusTemp?: number | string, marcomConsultantId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getLeadDuplicate(leadDuplicateRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: LeadDuplicateListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const LeadDuplicateColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "mobileNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "raceName",
      text: intl.formatMessage({ id: "Race" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.raceName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "marcomConsultantName",
      text: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.marcomConsultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSource",
      text: intl.formatMessage({ id: "Source" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "PreferredBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Count" }).toUpperCase(),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
        <div className="">
          {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
        </div>
        <div className="small-text">
          {moment(row.createdAt).format(Constants.displayTimeFormat)}
        </div>
      </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.leadDuplicate}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionDeleteLeadDuplicate
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeadDuplicate" }) })}
        title={intl.formatMessage({ id: "LeadDuplicate" })}
        addButton={<div />}>
        {
          !initialCallAPI &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "MediaSource" })}
                name="mediaSource"
                styles={{}}
                options={mediaSourceListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedMediaSourceName}
                initialValue={selectedMediaSourceId}
                setFieldLabel={setSelectedMediaSourceName}
                setFieldValue={setSelectedMediaSourceId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                onChangeFunction={onSelectDateRange} 
                initialValue={selectedDateRange}/>
            </DoubleColumnRowParent>
            {
              !loadingDropdownRefresh
              &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "AppointedBy" })}
                  name={"marcomConsultantId"}
                  options={marcomConsultantList}
                  initialLabel={selectedMarcomConsultantName}
                  initialValue={selectedMarcomConsultantId}
                  setFieldLabel={setSelectedMarcomConsultantName}
                  setFieldValue={setSelectedMarcomConsultantId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }} />
              </DoubleColumnRowParent>
            }
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              key={"id"}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={LeadDuplicateColumns}
              data={leadDuplicateList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "LeadDuplicate" }) })}
              objSorted={defaultSorted}
              requestAPI={getLeadDuplicate}
              sourceId={selectedMediaSourceId}
              sourceName={selectedMediaSourceName}
              marcomConsultantId={selectedMarcomConsultantId}
              marcomConsultantName={selectedMarcomConsultantName}
              dateRange={selectedDateRange} />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteLeadDuplicate(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (leadDuplicateList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getLeadDuplicate(leadDuplicateRequestBody)
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadDuplicate);
