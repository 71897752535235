import { PaginationRequestBody } from "../models/pagination";
import { AppolousCustomerProductAddRequestBody, AppolousCustomerProductDetailObject, AppolousCustomerProductListObject, AppolousCustomerProductRedeemRequestBody, AppolousCustomerProductStatusUpdateRequestBody } from "../models/appolousCustomerProduct";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class AppolousStore {
  appolousCustomerProductList: AppolousCustomerProductListObject[] = [];
  appolousCustomerProductDetail: AppolousCustomerProductDetailObject | undefined = undefined;
  appolousCustomerProductListTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.appolousCustomerProductList = [];
    this.appolousCustomerProductDetail = undefined;
    this.appolousCustomerProductListTotalItems = 0;
  }

  setAppolousCustomerProductList = (appolousCustomerProductList: AppolousCustomerProductListObject[]) => {
    this.appolousCustomerProductList = appolousCustomerProductList;
  }

  setAppolousCustomerProductDetail = (appolousCustomerProductDetail: AppolousCustomerProductDetailObject | undefined) => {
    this.appolousCustomerProductDetail = appolousCustomerProductDetail;
  }

  getAppolousCustomerProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultAppolousCustomerProductList = await agent.AppolousCustomerProduct.appolousCustomerProduct(PaginationRequestBody);
      runInAction(() => {
        this.appolousCustomerProductList = resultAppolousCustomerProductList.data;
        this.appolousCustomerProductListTotalItems = resultAppolousCustomerProductList.pagination.totalItems;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerProductList = [];
      this.appolousCustomerProductListTotalItems = 0;
    }
  };

  getAppolousCustomerProductDetail = async (id: string) => {
    try {
      const resultAppolousCustomerProductDetail = await agent.AppolousCustomerProduct.appolousCustomerProductDetail(id);

      runInAction(() => {
        this.appolousCustomerProductDetail = resultAppolousCustomerProductDetail;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appolousCustomerProductDetail = undefined;
    }
  };

  redeemAppolousCustomerProduct = async (appolousCustomerProductRedeemRequestBody: AppolousCustomerProductRedeemRequestBody) => {
    try {
      await agent.AppolousCustomerProduct.redeemAppolousCustomerProduct(appolousCustomerProductRedeemRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerProductRedeemSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, message: Array.isArray(error) ? error[0] : error.message});
    }
  }
  
  voidAppolousCustomerProductRedemption = async (appolousCustomerProductRedemptionId: string) => {
    try {
      await agent.AppolousCustomerProduct.voidAppolousCustomerProductRedemption(appolousCustomerProductRedemptionId);
      store.commonStore.setSuccessMessageWithoutNavigation(`AppolousCustomerProductRedemptionVoidSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  statusUpdateAppolousCustomerProduct = async (appolousCustomerProductStatusUpdateRequestBody: AppolousCustomerProductStatusUpdateRequestBody) => {
    try {
      await agent.AppolousCustomerProduct.statusUpdateAppolousCustomerProduct(appolousCustomerProductStatusUpdateRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerProductStatusUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  };

  addAppolousCustomerProduct = async (appolousCustomerProductAddRequestBody: AppolousCustomerProductAddRequestBody) => {
    try{
      await agent.AppolousCustomerProduct.addAppolousCustomerProduct(appolousCustomerProductAddRequestBody);
      store.commonStore.setSuccessMessage(`AppolousCustomerProductAddSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  };
}
