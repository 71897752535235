import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../../../app/components/form/MyButton";
import { CloseCounterPendingObject } from "../../../app/models/closeCounter";
import { useIntl } from "react-intl";
import { history } from "../../..";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  blnShow: boolean;
  setModal: Function;
  pendingCloseCounterDetail: CloseCounterPendingObject;
	disabledCloseFunction: boolean;
}

export default observer(function PendingCloseCounterModal(props: Props) {
  const intl = useIntl();

	return (
		<div>
			<Modal 
        isOpen={props.blnShow} 
        toggle={() => {
          if (!props.disabledCloseFunction) {
            props.setModal(false);
          }
        }} 
        // size="lg"
        // style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered={true}>
        {
          !props.disabledCloseFunction
          &&
          <button
            type="button"
            onClick={() => { props.setModal(false)}}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>
								<h4 style={{whiteSpace:"pre-wrap"}}>
									{intl.formatMessage({ id: "PendingCloseCounterAlert" })}
								</h4>
							</div>
						</Col>
					</Row>
          <div className={`text-center flex-wrap mt-4 mb-2`}>
            {
              props.pendingCloseCounterDetail.isInvalidCloseCounter
              &&
              <MyButton
                type="button"
                content={intl.formatMessage({ id: "CloseCounter" })}
                class="btn btn-primary btn-lg me-2"
                onClick={() => {
                  if (props.pendingCloseCounterDetail.closeCounterId) {
                    history.push(`/${RoutesList.closeCounter}/edit/${props.pendingCloseCounterDetail.closeCounterId}`);
                  }
                  else {
                    history.push(`/${RoutesList.closeCounter}`);
                  }
                }}/>
            }
            {
              props.pendingCloseCounterDetail.isInvalidCashOut
              &&
              <MyButton
                type="button"
                content={intl.formatMessage({ id: "CashOut" })}
                class={`btn btn-primary btn-lg me-2`}
                onClick={() => {
                  history.push(`/${RoutesList.cashOut}`)
                }}/>
            }
          </div>
				</ModalBody>
			</Modal>
		</div>
	);
})
