import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PaymentCollectionDetailsListObject, PaymentCollectionSummaryListObject, PaymentCollectionSummaryListGetObject, PaymentCollectOnBehalfListGetObject, PaymentCollectOnBehalfListObject, PaymentCollectionSummaryExportObject, PaymentCollectOnBehalfExportObject, PaymentCollectOnBehalfDetailsListObject, PaymentCollectOnBehalfExportDetailsObject, CombinedCollectionSummaryObject, ExportCombinedPaymentCollectionExportObject, CombinedPaymentCollectionDetailListObject } from "../models/reportPayment";
import { downloadExcel } from "../common/function/function";
import { Constants } from "../constants/Constants";

export default class ReportSalesStore {
  collectionSummaryList: PaymentCollectionSummaryListObject | undefined = undefined;
  collectionDetailsList: PaymentCollectionDetailsListObject[] = [];
  collectOnBehalfList: PaymentCollectOnBehalfListObject | undefined = undefined;
  collectOnBehalfDetailsList: PaymentCollectOnBehalfDetailsListObject[] = [];
  combinedCollectionSummary: CombinedCollectionSummaryObject | undefined = undefined;
  combinedCollectionSummaryDetailList: CombinedPaymentCollectionDetailListObject[] = [];
  combinedCollectionSummaryDetailListController: any = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.collectionSummaryList = undefined;
    this.collectionDetailsList = [];
    this.collectOnBehalfList = undefined;
    this.collectOnBehalfDetailsList = [];
    this.combinedCollectionSummary = undefined;
    this.combinedCollectionSummaryDetailList = [];
  }

  setCollectionSummaryList = (collectionSummaryListObject: PaymentCollectionSummaryListObject | undefined) => {
    this.collectionSummaryList = collectionSummaryListObject;
  }

  setCollectionDetailsList = (collectionDetailsListObject: PaymentCollectionDetailsListObject[]) => {
    this.collectionDetailsList = collectionDetailsListObject;
  }

  setCollectOnBehalfList = (collectOnBehalfList: PaymentCollectOnBehalfListObject | undefined) => {
    this.collectOnBehalfList = collectOnBehalfList;
  }

  setCollectOnBehalfDetailsList = (collectOnBehalfDetailsList: PaymentCollectOnBehalfDetailsListObject[]) => {
    this.collectOnBehalfDetailsList = collectOnBehalfDetailsList;
  }

  setCombinedCollectionSummary = (combinedCollectionSummary: CombinedCollectionSummaryObject | undefined) => {
    this.combinedCollectionSummary = combinedCollectionSummary;
  }

  setCombinedCollectionSummaryDetailList = (combinedCollectionSummaryDetailList: CombinedPaymentCollectionDetailListObject[]) => {
    this.combinedCollectionSummaryDetailList = combinedCollectionSummaryDetailList;
  }

  setCombinedCollectionSummaryDetailListController = (combinedCollectionSummaryDetailListController: any) => {
    this.combinedCollectionSummaryDetailListController = combinedCollectionSummaryDetailListController;
  }

  getCollectionSummaryList = async (collectionSummaryListGetObject: PaymentCollectionSummaryListGetObject) => {
    try{
      let resultCollectionSummary = await agent.ReportPayment.collectionSummaryList(collectionSummaryListGetObject);
      
      resultCollectionSummary.paymentCollectionDetails = resultCollectionSummary.paymentCollectionDetails.map((valuePaymentCollectionDetailsTemp, index)=> ({
        ...valuePaymentCollectionDetailsTemp,
        randomId: `${valuePaymentCollectionDetailsTemp.customerSalesOrderId}_${index}`
      }))
      
      runInAction(() => {
        this.collectionSummaryList = resultCollectionSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.collectionSummaryList = undefined;
    }
  }

  getCollectionDetailList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCollectionDetails = await agent.ReportPayment.collectionDetailList(PaginationRequestBody);
      runInAction(() => {
        this.collectionDetailsList = resultCollectionDetails.data;
        store.commonStore.setTotalItem(resultCollectionDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.collectionDetailsList = [];
    }
  }

  getCollectOnBehalfList = async (paymentCollectOnBehalfGetObject: PaymentCollectOnBehalfListGetObject) => {
    try{
    let resultCollectOnBehalf = await agent.ReportPayment.collectOnBehalfList(paymentCollectOnBehalfGetObject);
    
      runInAction(() => {
        this.collectOnBehalfList = resultCollectOnBehalf;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.collectOnBehalfList = undefined;
    }
  }

  getCollectOnBehalfDetailsList = async (paymentCollectOnBehalfGetObject: PaginationRequestBody) => {
    try{
      let resultCollectOnBehalfDetailsList = await agent.ReportPayment.collectOnBehalfDetailsList(paymentCollectOnBehalfGetObject);
      
      resultCollectOnBehalfDetailsList.data = resultCollectOnBehalfDetailsList.data.map((valueCollectOnBehalfDetailTemp, index)=> ({
        ...valueCollectOnBehalfDetailTemp,
        randomId: `${valueCollectOnBehalfDetailTemp.customerSalesOrderId}_${index}`
      }))

      runInAction(() => {
        this.collectOnBehalfDetailsList = resultCollectOnBehalfDetailsList.data;
        store.commonStore.setTotalItem(resultCollectOnBehalfDetailsList.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.collectOnBehalfDetailsList = [];
    }
  }

  getCombinedCollectionSummary = async (combinedCollectionSummaryGetObject: PaginationRequestBody | any) => {
    if (combinedCollectionSummaryGetObject.dateRange) {
      combinedCollectionSummaryGetObject.fromDate = combinedCollectionSummaryGetObject.dateRange.fromDate;
      combinedCollectionSummaryGetObject.toDate = combinedCollectionSummaryGetObject.dateRange.toDate;
    }
    
    try{
      let resultCombinedCollectionSummary = await agent.ReportPayment.combinedCollectionSummary(combinedCollectionSummaryGetObject);
      runInAction(() => {
        this.combinedCollectionSummary = resultCombinedCollectionSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.combinedCollectionSummary = undefined;
    }
  }

  getCombinedCollectionSummaryDetailList = async (combinedCollectionSummaryGetObject: PaginationRequestBody | any) => {
    if (combinedCollectionSummaryGetObject.dateRange) {
      combinedCollectionSummaryGetObject.fromDate = combinedCollectionSummaryGetObject.dateRange.fromDate;
      combinedCollectionSummaryGetObject.toDate = combinedCollectionSummaryGetObject.dateRange.toDate;
    }

    if (this.combinedCollectionSummaryDetailListController) {
      this.combinedCollectionSummaryDetailListController.abort()
      this.setCombinedCollectionSummaryDetailListController(undefined);
    }

    try{
      const combinedCollectionSummaryDetailListControllerTemp = new AbortController();
      this.setCombinedCollectionSummaryDetailListController(combinedCollectionSummaryDetailListControllerTemp);
      let resultCombinedCollectionSummaryDetailList = await agent.ReportPayment.combinedCollectionSummaryDetailList(combinedCollectionSummaryGetObject,  this.combinedCollectionSummaryDetailListController.signal);
      resultCombinedCollectionSummaryDetailList.data = resultCombinedCollectionSummaryDetailList.data.map((valueCombinedPaymentCollectionDetails, index)=> ({
        ...valueCombinedPaymentCollectionDetails,
        randomId: `${valueCombinedPaymentCollectionDetails.documentNo}_${index}`
      }))
      runInAction(() => {
        this.combinedCollectionSummaryDetailList = resultCombinedCollectionSummaryDetailList.data;
        store.commonStore.setTotalItem(resultCombinedCollectionSummaryDetailList.pagination.totalItems);
        this.setCombinedCollectionSummaryDetailListController(undefined);
      });
      return Promise.resolve({status: Constants.success})
    }
    catch (error: any) {
      console.error(error)
      if (Array.isArray(error) || error.message) {
        return Promise.resolve({status: Constants.failed})
      }
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.combinedCollectionSummaryDetailList = [];
    }
  }

  exportCollectionSummary = async (paymentCollectionSummaryExportRequestBody: PaymentCollectionSummaryExportObject) => {
    try{
      await downloadExcel(`/report/payment/collectionsummary/export`, paymentCollectionSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCollectionDetails = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/payment/collectiondetails/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCollectOnBehalf = async (paymentCollectOnBehalfExportRequestBody: PaymentCollectOnBehalfExportObject) => {
    try{
      await downloadExcel(`/report/payment/collectonbehalf/export`, paymentCollectOnBehalfExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCollectOnBehalfDetails = async (paymentCollectOnBehalDetailsfExportRequestBody: PaymentCollectOnBehalfExportDetailsObject) => {
    try{
      await downloadExcel(`/report/payment/collectonbehalf/details/export`, paymentCollectOnBehalDetailsfExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCombinedCollectionSummary = async (combinedCollectionSummaryExportRequestBody: ExportCombinedPaymentCollectionExportObject) => {
    try{
      await downloadExcel(`/report/payment/combinedcollectionsummary/export`, combinedCollectionSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}