import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../../../app/components/form/GeneralInput";
import Loading from "../../../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form } from "reactstrap";
import { Constants } from '../../../../app/constants/Constants';
import { ObjectForDropdown } from "../../../../app/models/common";
import DropDownWithTitle from "../../../../app/components/dropdown/DropDownWithTitle";
import GeneralSubmitAndCancelBtn from "../../../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import ModalViewLayout from "../../../../app/components/layout/ModalViewLayout";
import { BranchListObject } from "../../../../app/models/branch";
import GeneralTextArea from "../../../../app/components/form/GeneralTextArea";
import { contructValidationErrorMessage } from "../../../../app/common/function/function";

interface Props {
  blnShow: boolean;
  setModal: Function;
  yearList: ObjectForDropdown[];
  monthList: ObjectForDropdown[];
  branchDropdownList: BranchListObject[];
}

const ConsultantSalesDetail = (props: Props) => {
  //Use Store
  const { reportSalesStore, commonStore } = useStore();

  //Use INTL
  const intl = useIntl();

  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addConsultantSales } = reportSalesStore;
  const displayTitle = `${intl.formatMessage({ id: "Add" })} ${intl.formatMessage({ id: "ConsultantSales" })}`;
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      year: "",
      month: "",
      branchId: "",
      amount: 0,
      remark: ""
    },
    validationSchema: Yup.object({
      year: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })),
      month: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })),
      branchId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })),
      amount: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Amount" }) })),
      remark: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Remark" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        await addConsultantSales(valuesTemp);
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        dismissModal()
      }, Constants.timeoutNavigation)
    }
  }, [successMessage])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const dismissModal = () => {
    props.setModal(false);
    setErrorMessage("");
    setSuccessMessage("");
  }

  const onChangeBranch = (branchId) => {
    validation.setFieldValue("branchId", branchId);
  }

  const onChangeYear = (year) => {
    validation.setFieldValue("year", year);
  }

  const onChangeMonth = (month) => {
    validation.setFieldValue("month", month);
  }
  
  return (
    <ModalViewLayout 
      title={displayTitle}
      blnShow={props.blnShow}
      auditTrailId={""}
      burgerMenuList={[]}>
      {loading && !successMessage ? (
        <div style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Loading />
        </div>
      ) : (
        <Form
          className="standard-layout"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          onBlur={() => {
            if (errorMessage || successMessage) {
              setErrorMessage("");
              setSuccessMessage("");
            }
          }}
        >
          <Row form={+true}>
            {" "}
            {/* +true = Convert boolean to numbers*/}
            <Col xl={12}>
              <DropDownWithTitle
                name={"branchId"}
                title={intl.formatMessage({ id: "Branch" })}
                specifyReturnFieldName={[]}
                labelField={"name"}
                valueField={"id"}
                options={props.branchDropdownList}
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
                onChangeFunction={onChangeBranch}
                disableMenuPortalTarget={true} />
              <Row>
                <Col>
                  <DropDownWithTitle
                    name={"year"}
                    title={intl.formatMessage({ id: "Year" })}
                    specifyReturnFieldName={[]}
                    labelField={"label"}
                    valueField={"value"}
                    options={props.yearList}
                    disabled={disabledFieldInput}
                    validationRequired={true}
                    validation={validation}
                    onChangeFunction={onChangeYear}
                    disableMenuPortalTarget={true} />
                </Col>
                <Col>
                  <DropDownWithTitle
                    name={"month"}
                    title={intl.formatMessage({ id: "Month" })}
                    specifyReturnFieldName={[]}
                    labelField={"label"}
                    valueField={"value"}
                    options={props.monthList}
                    disabled={disabledFieldInput}
                    validationRequired={true}
                    validation={validation}
                    onChangeFunction={onChangeMonth}
                    disableMenuPortalTarget={true} />
                </Col>
              </Row>
              <GeneralInput
                title={intl.formatMessage({ id: "Amount" })}
                name="amount"
                type="number"
                disabled={disabledFieldInput}
                toolTipMessage={intl.formatMessage({ id: "ConsultantSalesAmountTooltip" })}
                validationRequired={true}
                validation={validation}
              />
              <GeneralTextArea
                title={intl.formatMessage({id: "Remark"})}
                name="remark"
                row={5}
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}/>
            </Col>
          </Row>
          <GeneralSubmitAndCancelBtn
            className="flex-right"
            successMessage={successMessage}
            hidePrimaryButton={false}
            viewAction={false}
            validation={validation}
            cancelButtonFunction={() => dismissModal()}
          />
        </Form>
      )}
    </ModalViewLayout>
  );
};

export default observer(ConsultantSalesDetail);
