import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import classNames from "classnames";
import { IncludesLocationPathName, compareArrayWithField, checkPermission, returnPriceWithCurrency, returnCurrency, getBranchUser, contructValidationErrorMessage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Nav, NavItem, NavLink, Label } from "reactstrap";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import TableWithCheckBox from "../../app/components/table/TableWithCheckBox";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { ProductForPackageObject, ServiceForPackageObject } from "../../app/models/package";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";

const PackageDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { packageStore, serviceTreatmentStore, categoryStore, salesCategoryStore, taxStore, productStore, groupCodeStore, settingsStore, staticReferenceStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addPackage, updatePackage, packageDetail, setPackageDetail, getPackageWithId, packageBranchAccess, getPackageBranchAccess } = packageStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;
  const { salesCategoryDropdownList, getSalesCategoryDropdown } = salesCategoryStore;
  const { taxDropdownList, getTaxDropdown } = taxStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { groupCodeDropdownList, getGroupCodeDropDown } = groupCodeStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { creditType, getStaticReferenceWithType } = staticReferenceStore;
  const [productListForPackage, setProductListForPackage] = useState<ProductForPackageObject[]>([]);
  const [serviceTreatmentListForPackage, setServiceTreatmentListForPackage] = useState<ServiceForPackageObject[]>([]);
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Package" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Package" }), urlPath: RoutesList.package }];
  const [activeTab, setActiveTab] = useState("1");
  const [tabBar, setTabBar] = useState<any[]>([intl.formatMessage({ id: "ServiceTreatment" }).toUpperCase(), intl.formatMessage({ id: "Product" }).toUpperCase()]);
  // const [tabBar, setTabBar] = useState<any[]>([intl.formatMessage({ id: "ServiceTreatment" }).toUpperCase(), intl.formatMessage({ id: "Product" }).toUpperCase(), intl.formatMessage({ id: "Product(Optional)" }).toUpperCase()]);
  const [serviceTreatmentModal, setServiceTreatmentModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [optionalProductModal, setOptionalProductModal] = useState(false);
  const [totalSellingPrice, setTotalSellingPrice] = useState(0);
  const [packageSellingPrice, setPackageSellingPrice] = useState(0);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [initialData, setInitialData] = useState(true);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdatePackage = checkPermission([PermissionConstants.UpdatePackage]);
  const blnShowAppolous = generalSettings?.isShowAppolous;
  
  const packagePriceCalculationTemp = () => {
    packagePriceCalculation()
  }

  const aryDynamicInputAndCheckBoxTabProduct: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "3.5",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "sellingPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "FinalPrice" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "discountPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "Qty" }).toUpperCase(),
      type: "input",
      xl: "2.5",
      inputName: "quantity",
      inputType: "number",
      onChangeFunction: packagePriceCalculationTemp,
    },
    {
      label: intl.formatMessage({ id: "Total" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "total",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `,
      totalDisplayFormula: [
        { fieldName: "discountPrice", type: "field" },
        { fieldName: "*", type: "method" },
        { fieldName: "quantity", type: "field" },
      ],
    },
  ];

  const aryDynamicInputAndCheckBoxTabOptionalProduct : ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "sellingPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "DiscountAmount" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "discountPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `,
      labelColorField: "labelColor"
    },
    {
      label: intl.formatMessage({ id: "Total" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "total",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    }
  ];

  const aryDynamicInputAndCheckBoxTabServiceTreatment: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "3.5",
      inputName: "serviceTreatmentName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "SellingPrice" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "sellingPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "DiscountPrice" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "discountPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "Qty" }).toUpperCase(),
      type: "input",
      xl: "2.5",
      inputName: "quantity",
      inputType: "number",
      onChangeFunction: packagePriceCalculationTemp,
    },
    {
      label: intl.formatMessage({ id: "Total" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "total",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `,
      totalDisplayFormula: [
        { fieldName: "discountPrice", type: "field" },
        { fieldName: "*", type: "method" },
        { fieldName: "quantity", type: "field" },
      ],
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: packageDetail || {
      name: "",
      originalPrice: undefined,
      sellingPrice: undefined,
      floorPrice: undefined,
      optionalProductCost: undefined,
      optionalProductQuantity: undefined,
      sessions: undefined,
      maxRedeemProductQuantity: undefined,
      isActive: true,
      salesCategoryId: "",
      salesCategoryName: "",
      taxId: "",
      taxName: "",
      displayOrder: 1,
      isUpgradeable: true,
      isPrepaidPackage: false,
      isCreditPackage: false,
      isProductBundledPackage: false,
      services: [],
      products: [],
      optionalProducts: [],
      branches: packageBranchAccess,
      groupCodeId: "",
      groupCodeName: "",
      creditPackageType: "",
      appolousId: null
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PackageName" }) })),
      salesCategoryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesCategory" }) })),
      taxId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Tax" }) })),
      sessions: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Sessions" }) })),
      maxRedeemProductQuantity: Yup.number()
        .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MaxRedeemProductQuantity" }) }), (value: any, context) => {
          if (!isCreditPackage && isMandatoryProduct && !value) {
            return false;
          }
          return true;
        }),
      sellingPrice: Yup.number()
        .required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SellingPrice" }) }))
        .lessThan(totalSellingPrice + 0.01, intl.formatMessage({ id: "LessThanTotalSellingValue" }, { field: totalSellingPrice }).toUpperCase()),
      floorPrice: Yup.number()
        .required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FloorPrice" }) }))
        .lessThan(packageSellingPrice + 0.01, intl.formatMessage({ id: "LessThanPackageSellingPrice" }, { field: packageSellingPrice }).toUpperCase()),
      optionalProductCost: Yup.number()
        .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "OptionalProductCost" }) }), (value: any, context) => {
          if (!isCreditPackage && isOptionalProduct && !value) {
            return false;
          }
          return true;
        }),
      optionalProductQuantity: Yup.number()
        .test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "OptionalProductQuantity" }) }), (value: any, context) => {
          if (!isCreditPackage && isOptionalProduct && !value) {
            return false;
          }
          return true;
        }),
        creditPackageType: Yup.string().test(
          "creditPackageType",
          intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CreditPackageType" }) }),
          (value: any, context) => {
            if (isCreditPackage) {
              return value;
            }
            return true;
          }
        ).nullable()
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.originalPrice = totalSellingPrice;
      valuesTemp.products = valuesTemp.products.map((productTemp) => ({
        ...productTemp,
        isOptional: false
      }))
      valuesTemp.optionalProducts = valuesTemp.optionalProducts.map((productTemp) => ({
        ...productTemp,
        isOptional: true
      }))
      valuesTemp.products = _.concat(valuesTemp.products, valuesTemp.optionalProducts);

      if (blnShowAppolous) {
        if(valuesTemp.appolousId !== null && valuesTemp.appolousId === 0){
          setErrorMessage(`${intl.formatMessage({ id: "ZeroAppolousId" })}`) 
          return;
        }
      }

      let indexOptionProductCostError = _.findIndex(valuesTemp.optionalProducts, {backgroundColor : Constants.lightorange})
      if (indexOptionProductCostError > -1) {
        setErrorMessage(`${intl.formatMessage({ id: "Package-026" })}`)
        return;
      }

      delete valuesTemp.optionalProducts;

      if (!valuesTemp.isCreditPackage) {
        valuesTemp.creditPackageType = "";
      }

      try {
        if (addAction) {
          await addPackage(valuesTemp);
        } else {
          await updatePackage(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const isMandatoryProduct = validation.values.products ? validation.values.products.length > 0 : false;
  const isOptionalProduct = validation.values.optionalProducts ? validation.values.optionalProducts.length > 0 : false;
  const isUpgradeablePacakge = validation.values.isUpgradeable;
  const isPrepaidPackage = validation.values.isPrepaidPackage;
  const isCreditPackage = validation.values.isCreditPackage;
  const isProductBundledPackage = validation.values.isProductBundledPackage;
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnShowAppolousViewId = viewAction && validation.values.appolousId !== null;

  useEffect(() => {
    async function fetchPackageDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getPackageBranchAccess(),
        getProductDropdown({ productType: 1 }),
        getServiceTreatmentDropdown({branchId: "" }),
        getCategoryDropdown(),
        getSalesCategoryDropdown(),
        getTaxDropdown(),
        getGeneralSettings(),
        getGroupCodeDropDown({isActive: true}),
        getStaticReferenceWithType(Constants.creditType)
      ];
      if (id && !addAction) {
        aryAPI.push(getPackageWithId(id))
      }
      await Promise.all(aryAPI);
      setInitialData(false);
      setLoading(false);
    }

    if (addAction) {
      setPackageDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePackage], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePackage], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePackage], true)) {
        return;
      }
    }

    if(!getBranchUser()){
      tabBar.push(intl.formatMessage({ id: "Branch" }).toUpperCase())
    }
    
    if(viewAction && blnPermissionUpdatePackage){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.package}/edit/${id}`)} })
    }

    fetchPackageDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPackageDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    let aryProductTemp: ProductForPackageObject[] = [];
    let arySelectedProduct: any = []
    if (id && !addAction) {
      arySelectedProduct = compareArrayWithField(packageDetail?.products || [], productDropdownList, "productId", "id")
    }
    productDropdownList.map((value) => {
      let indexSelectedProductTemp = _.findIndex(arySelectedProduct, { productId: value.id })
      aryProductTemp.push({
        productId: value.id,
        productName: value.name,
        quantity: 1,
        sellingPrice: value.sellingPrice,
        discountPrice: value.sellingPrice,
        total: value.sellingPrice * 1,
        disabled: indexSelectedProductTemp >= 0 ? true : false,
        isActive: value.isActive
      })
    })

    setProductListForPackage(aryProductTemp);
  }, [productDropdownList, packageDetail])

  useEffect(() => {
    let aryServiceTreatmentTemp: ServiceForPackageObject[] = [];
    let arySelectedService: any = []
    if (id && !addAction) {
      arySelectedService = compareArrayWithField(packageDetail?.services || [], serviceTreatmentDropdownList, "serviceTreatmentId", "id")
    }

    serviceTreatmentDropdownList.map((value) => {
      let indexSelectedServiceTemp = _.findIndex(arySelectedService, { serviceTreatmentId: value.id })
      aryServiceTreatmentTemp.push({
        serviceTreatmentId: value.id,
        serviceTreatmentName: value.name,
        quantity: 1,
        sellingPrice: value.sellingPrice,
        discountPrice: value.sellingPrice,
        total: value.sellingPrice * 1,
        disabled: indexSelectedServiceTemp >= 0 ? true : false,
        isActive: value.isActive
      })
    })

    setServiceTreatmentListForPackage(aryServiceTreatmentTemp);
  }, [serviceTreatmentDropdownList, packageDetail])

  useEffect(() => {
    if (packageDetail?.sellingPrice) {
      setTotalSellingPrice(packageDetail?.sellingPrice)
    }

    if (packageDetail?.isProductBundledPackage) {
      setActiveTab("2")
    }

    let productsTemp = _.filter(packageDetail?.products, { isOptional: false });
    let optionalProductstemp = _.filter(packageDetail?.products, { isOptional: true });
    validation.setFieldValue("products", productsTemp)
    validation.setFieldValue("optionalProducts", optionalProductstemp)
  }, [packageDetail])

  useEffect(() => {
    if (validation.values.sellingPrice !== undefined ) {
      setPackageSellingPrice(validation.values.sellingPrice);
    }
  }, [validation.values.sellingPrice])

  useEffect(() => {
    calcaluteTotalCost(validation.values.services, validation.values.products, validation.values.optionalProductCost)

    //sessions
    let totalSessionCount = 0;
    let totalRedeemProductQuantityCount = 0;

    validation.values.services.map((valueServicesTemp) => {
      totalSessionCount += valueServicesTemp.quantity
    })
    validation.values.products.map((valueServicesTemp) => {
      totalRedeemProductQuantityCount += valueServicesTemp.quantity
    })
    
    validation.setFieldValue("sessions", totalSessionCount);
    validation.setFieldValue("maxRedeemProductQuantity", totalRedeemProductQuantityCount);
  }, [validation.values.services, validation.values.products, validation.values.optionalProductCost])

  useEffect(()=> {
    packagePriceCalculation();
  }, [validation.values.optionalProductCost])

  useEffect(()=> {
    if (!initialData) {
      packagePriceCalculation();
    }
  }, [serviceTreatmentListForPackage, productListForPackage])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const packagePriceCalculation = () => {
    let aryServicesTemp = _.cloneDeep(validation.values.services);
    let aryProductsTemp = _.cloneDeep(validation.values.products);
    let aryOptionalProductsTemp = _.cloneDeep(validation.values.optionalProducts);
    let optionalProductCostTemp = _.cloneDeep(validation.values.optionalProductCost);
    let optionalProductQuantityTemp = _.cloneDeep(validation.values.optionalProductQuantity) || 0;
    let totalSellingPriceTemp = calcaluteTotalCost(aryServicesTemp, aryProductsTemp, 0);
    let currentSellingPriceTemp = Number(validation.values.sellingPrice) - (Number(validation.values.optionalProductCost) || 0);
    let devideValue = (currentSellingPriceTemp && currentSellingPriceTemp <= totalSellingPriceTemp) ? totalSellingPriceTemp / currentSellingPriceTemp : currentSellingPriceTemp === 0 ? 0 : 1;
    
    if (validation.values.services.length > 0) {
      aryServicesTemp = aryServicesTemp.map((servicesValueTemp: ServiceForPackageObject) => ({
        ...servicesValueTemp,
        discountPrice: totalSellingPriceTemp && devideValue !== 0 ? Number((servicesValueTemp.sellingPrice / devideValue).toFixed(2)) : 0,
        total: (totalSellingPriceTemp && devideValue !== 0 ? Number((servicesValueTemp.sellingPrice / devideValue).toFixed(2)) : 0) * (servicesValueTemp.quantity || 0)
      }))
    }

    if (validation.values.products.length > 0) {
      aryProductsTemp = aryProductsTemp.map((productsValueTemp: ProductForPackageObject) => ({
        ...productsValueTemp,
        discountPrice: totalSellingPriceTemp && devideValue !== 0 ? Number((productsValueTemp.sellingPrice / devideValue).toFixed(2)) : 0,
        total: (totalSellingPriceTemp && devideValue !== 0 ? Number((productsValueTemp.sellingPrice / devideValue).toFixed(2)) : 0) * (productsValueTemp.quantity || 0)
      }))
    }

    if (validation.values.optionalProducts) {
      if (validation.values.optionalProducts.length > 0) {
        let pricePerProduct = optionalProductQuantityTemp > 0 ? Number(optionalProductCostTemp)/Number(optionalProductQuantityTemp) : 0;
        aryOptionalProductsTemp = aryOptionalProductsTemp.map ((optionalProductsValueTemp: ProductForPackageObject) => ({
          ...optionalProductsValueTemp,
          discountPrice: Number((Number(optionalProductsValueTemp.sellingPrice) - pricePerProduct).toFixed(2)),
          backgroundColor: !optionalProductQuantityTemp || (0 > Number((Number(optionalProductsValueTemp.sellingPrice) - pricePerProduct).toFixed(2))) ? Constants.lightorange : Constants.white,
          labelColor: !optionalProductQuantityTemp || (0 > Number((Number(optionalProductsValueTemp.sellingPrice) - pricePerProduct).toFixed(2))) ? Constants.red : Constants.black,
          total: Number(pricePerProduct.toFixed(2))
        }))
      }
    }

    validation.setFieldValue("services", aryServicesTemp)
    validation.setFieldValue("products", aryProductsTemp)
    validation.setFieldValue("optionalProducts", aryOptionalProductsTemp)
  }
  
  const calcaluteTotalCost = (servicesTemp, productsTemp, totalOptionalProductCost) => {
    let totalSellingPriceTemp = 0;

    if (servicesTemp) {
      servicesTemp.map((servicesValueTemp: ServiceForPackageObject) => {
        totalSellingPriceTemp += servicesValueTemp.sellingPrice * (servicesValueTemp.quantity || 0);
      })
    }

    if (productsTemp) {
      productsTemp.map((productsValueTemp: ProductForPackageObject) => {
        totalSellingPriceTemp += productsValueTemp.sellingPrice * (productsValueTemp.quantity || 0);
      })
    }

    totalSellingPriceTemp += totalOptionalProductCost || 0;

    if (!isCreditPackage) {
      setTotalSellingPrice(totalSellingPriceTemp);
    }
    return totalSellingPriceTemp;
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const displayIsActiveCheckbox = () => {
    return (
      <Col xl="4"
        blnDoubleTab={true}>
        <GeneralInput
          title={intl.formatMessage({ id: "Active" })}
          name="isActive"
          type="checkbox"
          disabled={disabledFieldInput || viewAction}
          validation={validation}
          childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
      </Col>
    )
  }
  
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Package" }) })}
                  h4Title />
                {
                  blnShowAppolous
                  &&
                  <SingleColumnRowParent blnDoubleTab={true}>
                    {
                      blnShowAppolousViewId
                      &&
                      <GeneralInputWithClickableUI
                      title={intl.formatMessage({ id: "ViewAppolous" })}
                      name="appolousId"
                      className="mt-4"
                      type="number"
                      disabled={true}
                      validation={validation}
                      onClickFunction={()=> {
                        window.open(`https://app.appolous.com/packages/${validation.values.appolousId}/edit`)
                      }}/>
                    }
                    {
                      !viewAction
                      &&
                      <GeneralInput
                        title={intl.formatMessage({ id: "AppolousId" })}
                        name="appolousId"
                        type="number"
                        className={`mt-4`}
                        blnNumberOnly={true}
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={false}
                        validation={validation} />
                    }
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PackageName" })}
                    name="name"
                    className={`mb-3 ${viewAction && validation.values.appolousId === null ? "mt-4" : "mt-3"}`}
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <Col xl="3">
                    <GeneralInput
                      title={intl.formatMessage({ id: "Upgradeable" })}
                      name="isUpgradeable"
                      type="checkbox"
                      disabled={
                        disabledFieldInput || viewAction || isPrepaidPackage || isCreditPackage || isProductBundledPackage
                      }
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                      onChangeFunctionWithSetValidationField={()=> {
                        toggle("1");
                      }}
                    />
                  </Col>
                  <Col xl="3">
                    <GeneralInput
                      title={intl.formatMessage({ id: "PrepaidPackage" })}
                      name="isPrepaidPackage"
                      type="checkbox"
                      disabled={
                        disabledFieldInput || viewAction || isUpgradeablePacakge || isCreditPackage || isProductBundledPackage || validation.values.services.length > 1 || validation.values.products.length > 0 || validation.values.optionalProducts.length > 0
                      }
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                      onChangeFunction={()=> {
                        toggle("1");
                        validation.setFieldValue("optionalProductCost", 0);
                        validation.setFieldValue("maxRedeemProductQuantity", 0);
                      }}
                    />
                  </Col>
                  <Col xl="3">
                    <GeneralInput
                      title={intl.formatMessage({ id: "CreditPackage" })}
                      name="isCreditPackage"
                      type="checkbox"
                      disabled={
                        disabledFieldInput || viewAction || validation.values.isPrepaidPackage || validation.values.services.length > 0 || validation.values.products.length > 0 || validation.values.optionalProducts.length > 0 || isUpgradeablePacakge || isPrepaidPackage || isProductBundledPackage
                      }
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                      onChangeFunction={(valueCreditPackage)=> {
                        if (valueCreditPackage) {
                          setTotalSellingPrice(validation.values.sellingPrice || 1000000);
                          validation.setFieldValue("floorPrice", 1);
                        }
                        else {
                          validation.setFieldValue("floorPrice", validation.values.sellingPrice);
                          packagePriceCalculation();
                        }
                      }}
                    />
                  </Col>
                  {
                    <Col xl="3">
                      <GeneralInput
                        title={intl.formatMessage({ id: "ProductBundledPackage" })}
                        name="isProductBundledPackage"
                        type="checkbox"
                        disabled={
                          disabledFieldInput || viewAction || validation.values.services.length > 0 || isUpgradeablePacakge || isCreditPackage || isPrepaidPackage
                        }
                        validation={validation}
                        childrenUI={
                          <Label className="margin-bottom-0 margin-left-4">
                            {intl.formatMessage({ id: "Yes" })}
                          </Label>
                        }
                        onChangeFunctionWithSetValidationField={(valueIsProductBundledPackage)=> {
                          if (valueIsProductBundledPackage) {
                            toggle("2")
                          }
                          else {
                            toggle("1")
                          }
                        }}
                      />
                    </Col>
                  }
                </Row>
                {
                  isCreditPackage 
                  &&
                  <SingleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"creditPackageType"}
                      title={intl.formatMessage({ id: "CreditPackageType" })}
                      specifyReturnFieldName={[
                        {
                          field: "creditPackageType",
                          value: "displayValue",
                        },
                      ]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={creditType}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.creditPackageType : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                }
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SellingPrice" })}
                    name="sellingPrice"
                    className={!isCreditPackage ? "margin-bottom-4" : "mb-3"}
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    onChangeFunction={(valueSellingPrice) => {
                      validation.setFieldValue("floorPrice", valueSellingPrice)
                      if (isCreditPackage) {
                        setTotalSellingPrice(valueSellingPrice);
                      }
                      else {
                        packagePriceCalculation();
                      }
                    }} />
                  {!isCreditPackage && <p className="total_label">{` ${intl.formatMessage({ id: "TotalSellingValue" })}: ${returnPriceWithCurrency(totalSellingPrice)} `}</p>}
                </SingleColumnRowParent>
                {
                  !isCreditPackage
                  &&
                  <>
                    {/* {
                      !isPrepaidPackage
                      &&
                      <Row>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "OptionalProductCost" })}
                            name="optionalProductCost"
                            type="number"
                            inputGroup={true}
                            inputGroupText={returnCurrency()}
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={() => {
                              packagePriceCalculation();
                            }}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent
                          blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "OptionalProductQuantity" })}
                            name="optionalProductQuantity"
                            type="number"
                            disabled={disabledFieldInput || viewAction}
                            onChangeFunction={() => {
                              packagePriceCalculation();
                            }}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                    } */}
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "FloorPrice" })}
                        name="floorPrice"
                        type="number"
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        validationRequired={true}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                    <SingleColumnRowParent
                      blnDoubleTab={true}>
                      <DropDownWithTitle
                        name={"groupCodeId"}
                        title={intl.formatMessage({ id: "GroupCode" })}
                        specifyReturnFieldName={[{ "field": "groupCodeId", "value": "id" }]}
                        labelField={"name"}
                        valueField={"id"}
                        options={groupCodeDropdownList}
                        disabled={disabledFieldInput || viewAction}
                        initialLabel={!addAction ? validation.values.groupCodeName : undefined}
                        initialValue={!addAction ? validation.values.groupCodeId : undefined}
                        validation={validation} />
                    </SingleColumnRowParent>
                    {
                      !isPrepaidPackage
                      &&
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "MaxRedeemProductQuantity" })}
                          name="maxRedeemProductQuantity"
                          type="number"
                          validationRequired={true}
                          disabled={true}
                          validation={validation} />
                      </SingleColumnRowParent>
                    }
                    {
                      !isProductBundledPackage
                      &&
                      <SingleColumnRowParent
                        blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "Sessions" })}
                          name="sessions"
                          type="number"
                          validationRequired={true}
                          disabled={true}
                          validation={validation} />
                      </SingleColumnRowParent>
                    }
                  </>
                }
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"salesCategoryId"}
                    title={intl.formatMessage({ id: "SalesCategory" })}
                    specifyReturnFieldName={[{ "field": "salesCategoryId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={salesCategoryDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.salesCategoryName : undefined}
                    initialValue={!addAction ? validation.values.salesCategoryId : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <DropDownWithTitle
                    name={"taxId"}
                    title={intl.formatMessage({ id: "Tax" })}
                    specifyReturnFieldName={[{ "field": "taxId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={taxDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.taxName : undefined}
                    initialValue={!addAction ? validation.values.taxId : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  {displayIsActiveCheckbox()}
                </SingleColumnRowParent>
                <SingleColumnRowParent
                  blnDoubleTab={true}>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Description" })}
                    name="description"
                    row={5}
                    disabled={disabledFieldInput || viewAction}
                    validation={validation} />
                </SingleColumnRowParent>
              </Col>
              {
                !isCreditPackage
                &&
                <Col xl={7}>
                  <Nav tabs className="">
                    {tabBar.map((value, indexTab) => {
                      if ((isProductBundledPackage && indexTab === 0) || (!isProductBundledPackage && indexTab === 1)){
                        return <div key={`empty_div_${indexTab}`}/>
                      }
                      return(
                        <NavItem
                          key={`tabkey_${indexTab}`}
                        >
                          <NavLink
                            style={{
                              cursor: "pointer",
                            }}
                            className={classNames({
                              active:
                                activeTab ===
                                String(
                                  indexTab + 1
                                ),
                            })}
                            onClick={() => {
                              toggle(
                                String(
                                  indexTab + 1
                                )
                              );
                            }}
                            // disabled={(isProductBundledPackage && (indexTab === 0) || (isPrepaidPackage && (indexTab === 1 || indexTab === 2))) ? true : false}
                          >
                            {value}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                  {activeTab === "1" && (
                    <TableWithEditableInputFields
                      name="services"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      hideAddButton={isPrepaidPackage && validation.values.services.length > 0 ? true : false}
                      options={serviceTreatmentListForPackage}
                      optionsId={"serviceTreatmentId"}
                      setOptionsFunction={setServiceTreatmentListForPackage}
                      addButtonFunction={() => setServiceTreatmentModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabServiceTreatment}
                      data={validation.values.services}
                      validation={validation}
                      disabled={disabledFieldInput || isProductBundledPackage} />
                  )}
                  {activeTab === "2" && (
                    <TableWithEditableInputFields
                      name="products"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      options={productListForPackage}
                      optionsId={"productId"}
                      setOptionsFunction={setProductListForPackage}
                      addButtonFunction={() => setProductModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabProduct}
                      data={validation.values.products}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )}
                  {/* {activeTab === "3" && (
                    <TableWithEditableInputFields
                      name="optionalProducts"
                      title=""
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                      blnEditable={!viewAction}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      options={productListForPackage}
                      optionsId={"productId"}
                      setOptionsFunction={setProductListForPackage}
                      addButtonFunction={() => setOptionalProductModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabOptionalProduct}
                      supportBackgroundColor={true}
                      data={validation.values.optionalProducts}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )} */}
                  {activeTab === "3" && (
                    <TableWithCheckBox
                      name="branches"
                      title={`${intl.formatMessage({ id: "BranchAvailableInServicePackage" })}: `}
                      customDisplayField={"branchName"}
                      blnEditable={!viewAction}
                      blnSelectAll={true}
                      data={validation.values.branches}
                      blnSingleSelect={false}
                      validation={validation}
                      disabled={disabledFieldInput} />
                  )}
                </Col>
              }
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
        <SelectDropDownModal
          blnShow={serviceTreatmentModal}
          setModal={setServiceTreatmentModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
          placeholder={"- Please Select -"}
          labelField={"serviceTreatmentName"}
          valueField={"serviceTreatmentId"}
          options={serviceTreatmentListForPackage}
          setOptionsFunction={setServiceTreatmentListForPackage}
          fieldInput={validation.values.services}
          fieldName={"services"}
          includeValueField={"isActive"}
          validation={validation} />
        <SelectDropDownModal
          blnShow={productModal}
          setModal={setProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={productListForPackage}
          setOptionsFunction={setProductListForPackage}
          fieldInput={validation.values.products}
          fieldName={"products"}
          includeValueField={"isActive"}
          validation={validation} />
        <SelectDropDownModal
          blnShow={optionalProductModal}
          setModal={setOptionalProductModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "OptionalProduct" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={productListForPackage}
          setOptionsFunction={setProductListForPackage}
          fieldInput={validation.values.optionalProducts}
          fieldName={"optionalProducts"}
          includeValueField={"isActive"}
          validation={validation} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(PackageDetail);
