import { useState, useEffect } from "react";
import { Form, Modal } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { Constants } from "../../../app/constants/Constants";
import { EmployeeImportSuccessDetailObject } from "../../../app/models/employee";

interface Props {
  blnShow: boolean;
  setModal: Function;
  importedEmployeeDetails: EmployeeImportSuccessDetailObject[];
  setImportedEmployeeDetails: Function;
  refreshData: Function;
}

export default observer(function ImportEmployeeModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore, employeeStore } = useStore();
  const { importEmployeeConfirmation } = employeeStore;
  const {
    errorMessage,
    successMessage,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;

  const hideModal = () => {
    props.setModal(false);
    props.setImportedEmployeeDetails([]);
  }

  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [blnValidRecord, setBlnValidRecord] = useState(false);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "preferredName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "identityNo",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Email" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "email",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Role" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "roleName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "BusinessEntity" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "businessEntityName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Department" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "departmentName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "EmploymentStartDate" }).toUpperCase(),
      type: "label",
      xl: "1.28",
      inputName: "employmentStartDate",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Errors" }).toUpperCase(),
      type: "label",
      xl: "1.75",
      inputName: "errorsDiplay",
      inputType: "text",
      labelColor: Constants.red
    },
  ];

  // validation
  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      employeeDetails: props.importedEmployeeDetails || []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      try {
        const valuesTemp = _.cloneDeep(values);
        let submitValuesTemp: EmployeeImportSuccessDetailObject[] = [];

        for (var a = 0; a < valuesTemp.employeeDetails.length; a++) {
          if (valuesTemp.employeeDetails[a].errors.length === 0) {
            submitValuesTemp.push(valuesTemp.employeeDetails[a])
          }
        }

        if (submitValuesTemp.length > 0) {
          let resultImportEmployeeConfirmation = await importEmployeeConfirmation({ employeeDetails: submitValuesTemp });
          if (resultImportEmployeeConfirmation?.status) {
            if (resultImportEmployeeConfirmation.status === Constants.success) {
              if (props.refreshData) {
                props.refreshData();
              }
            }
          }
        }
        else {
          setErrorMessage(intl.formatMessage({ id: "NoValidRecord" }))
        }

      } finally {
        validationLocal.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = Boolean(successMessage) || validationLocal.isSubmitting;

  useEffect(()=> {
    for (var a = 0; a < props.importedEmployeeDetails.length; a++) {
      if (props.importedEmployeeDetails[a].errors.length === 0) {
        setBlnValidRecord(true);
      }
    }
  }, [])

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!validationLocal.isSubmitting && !successMessage && !disabledFieldInput) {
            hideModal();
          }
        }}
        size="xl"
        style={{width: '100%', maxWidth: '80%'}}
        centered
      >
        {
          loadingEmployee
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ImportEmployeeConfirmation" })}</h5>
                </div>
                {
                  (!validationLocal.isSubmitting && !successMessage) &&
                  <button
                    type="button"
                    onClick={() => {
                      if (!disabledFieldInput) {
                        hideModal();
                      }
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <Form
                  // className="standard-layout"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validationLocal.handleSubmit();
                    return false;
                  }}
                  onBlur={() => {
                    if (errorMessage || successMessage) {
                      setErrorMessage("");
                      setSuccessMessage("");
                    }
                  }}>
                  <p className="font-size-14 error_require_text mt-2">
                    {intl.formatMessage({ id: "Employee_ImportNote" })}
                  </p>
                  <LineBreakWithTittle
                    blnSkipMarginTop={true}
                    paddingBottom="0px"
                    title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Employees" }) })}
                  />
                  <TableWithEditableInputFields
                    name="employeeDetails"
                    title=""
                    buttonTitle={intl.formatMessage({ id: "Confirm" })}
                    blnEditable={true}
                    aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                    data={validationLocal.values.employeeDetails}
                    validation={validationLocal}
                    disabled={disabledFieldInput}
                    supportBackgroundColor={true}
                    hideAddButton={true}
                    hideDeleteButton={true} />
                  <div className="mb-3 mt-4">
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '20%' }}
                      content={intl.formatMessage({ id: blnValidRecord ? "Confirm" : "NoValidRecordButton" })}
                      loading={validationLocal.isSubmitting}
                      disable={disabledFieldInput || !blnValidRecord}
                    />
                  </div>
                </Form>
              </div>
            </div>
        }
      </Modal>
  )
})