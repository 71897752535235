import { PaginationRequestBody } from "../models/pagination";
import { PurchasedCreditHistoryListObject, PurchasedCreditListObject } from "../models/purchasedCredit";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class purchasedCreditStore {
  purchasedCreditList: PurchasedCreditListObject[] = [];
  purchasedCreditHistoryList: PurchasedCreditHistoryListObject[] = [];
  purchasedCreditTotalItems: number = 0;
  purchasedCreditHistoryTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.purchasedCreditList = [];
    this.purchasedCreditHistoryList = [];
    this.purchasedCreditTotalItems = 0;
    this.purchasedCreditHistoryTotalItems = 0;
  }

  setPurchasedCreditList = (purchasedCreditList: PurchasedCreditListObject[]) => {
    this.purchasedCreditList = purchasedCreditList;
  }

  setPurchasedCreditHistoryList = (purchasedCreditHistoryList: PurchasedCreditHistoryListObject[]) => {
    this.purchasedCreditHistoryList = purchasedCreditHistoryList;
  }

  setPurchasedCreditTotalItems = (purchasedCreditTotalItems: number) => {
    this.purchasedCreditTotalItems = purchasedCreditTotalItems;
  }
  
  setPurchasedCreditHistoryTotalItems = (purchasedCreditHistoryTotalItems: number) => {
    this.purchasedCreditHistoryTotalItems = purchasedCreditHistoryTotalItems;
  }
  
  getPurchasedCredit = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPurchasedCredit = await agent.PurchasedCredit.purchasedCreditList(PaginationRequestBody);
      runInAction(() => {
        this.purchasedCreditList = resultPurchasedCredit.data;
        this.purchasedCreditTotalItems = resultPurchasedCredit.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchasedCreditList = [];
      this.purchasedCreditTotalItems = 0;
    }
  }

  getPurchasedCreditHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPurchasedCreditHistory = await agent.PurchasedCredit.purchasedCreditHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.purchasedCreditHistoryList = resultPurchasedCreditHistory.data;
        this.purchasedCreditHistoryTotalItems = resultPurchasedCreditHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchasedCreditHistoryList = [];
      this.purchasedCreditHistoryTotalItems = 0;
    }
  }
}