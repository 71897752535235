import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import MyButton from "../form/MyButton";
import GeneralInput from "../form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

interface Props {
  intl: any;
  actionTitle: string;
  showPrompt: boolean;
  setShowPrompt: Function;
  onPrimaryClick: Function;
  onCancelClick?: Function;
}

export default observer(function EnterPasscodeModal(props: Props) {
  // validation
	const validationLocal = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			passcode: ""
		},
		validationSchema: Yup.object({
			passcode: Yup.string()
				.required(props.intl.formatMessage({ id: "PasscodeIsRequired" }))
				.min(4, props.intl.formatMessage({ id: "PasscodeMustLonger" }))
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			try {
        props.onPrimaryClick(valuesTemp.passcode);
        props.setShowPrompt(false)
			} finally {
				validationLocal.setSubmitting(false);
			}
		},
	});
  
  const hideModal = () => {
    props.setShowPrompt(false);
    if (props.onCancelClick) {
      props.onCancelClick();
    }
  }

	return (
		<div>
			<Modal 
        isOpen={props.showPrompt} 
        toggle={() => {
          hideModal();
        }} 
        centered={true}>
        {
          <button
            type="button"
            onClick={() => { hideModal() }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
				<ModalBody className="py-3 px-5">
					<Row>
						<Col lg={12}>
							<div className="text-center">
                <AlertCircleOutlineIcon size={"6em"} color={"orange"} className={"margin-bottom-8"}/>
							</div>
						</Col>
					</Row>
          <GeneralInput
            title={props.intl.formatMessage({ id: "PasscodeBeforeAction" }, { action: props.actionTitle })}
            name="passcode"
            type="password"
            maxLength={4}
            blnNumberOnly={true}
            validationRequired={true}
            validation={validationLocal}
          />
          <Row>
            <Col>
              <div className={`text-center`}>
                <MyButton
                  type="button"
                  content={"Proceed"}
                  class="btn btn-success btn-lg me-2"
                  onClick={() => {
                    validationLocal.submitForm();
                  }}/>
                <MyButton
                  type="button"
                  content={"Cancel"}
                  class="btn btn-danger btn-lg me-2"
                  onClick={() => {
                    hideModal();
                  }}/>
              </div>
            </Col>
          </Row>
				</ModalBody>
			</Modal>
		</div>
	);
})
