import { PaginationRequestBody } from "../models/pagination";
import { CreditNoteListObject, CreditNoteAddObject, CreditNoteDetailObject, CreditNoteCreditRefundObject, CreditNoteReviewRequestBody } from "../models/creditNote";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class creditNoteStore {
  creditNoteList: CreditNoteListObject[] = [];
  creditNoteDetail: CreditNoteDetailObject | undefined = undefined;
  creditNotePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.creditNoteList = [];
    this.creditNoteDetail = undefined;
    this.creditNotePaginationParams= undefined;
  }

  setCreditNoteList = (creditNoteList: CreditNoteListObject[]) => {
    this.creditNoteList = creditNoteList;
  }
  
  setCreditNoteDetail = (creditNoteDetail: CreditNoteDetailObject | undefined) => {
    this.creditNoteDetail = creditNoteDetail;
  }

  setCreditNotePaginationParams = (creditNotePaginationParams: PaginationRequestBody | undefined) => {
    this.creditNotePaginationParams = creditNotePaginationParams;
  }
  
  getCreditNote = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCreditNotePaginationParams(PaginationRequestBody);
    try{
      const resultCreditNote = await agent.CreditNote.creditNoteList(PaginationRequestBody);
      runInAction(() => {
        this.creditNoteList = resultCreditNote.data;
        store.commonStore.setTotalItem(resultCreditNote.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.creditNoteList = [];
    }
  }

  getCreditNoteWithId = async (id: string) => {
    try{
      const resultCreditNoteDetail = await agent.CreditNote.creditNoteDetail(id);
      runInAction(() => {
        this.creditNoteDetail = resultCreditNoteDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.creditNoteDetail = undefined;
    }
  }

  addCreditNote = async (creditNoteRequestBody: CreditNoteAddObject) => {
    try{
      await agent.CreditNote.addCreditNote(creditNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CreditNoteAddSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }

  reviewCreditNote = async (reviewCreditNoteRequestBody: CreditNoteReviewRequestBody) => {
    try{
      await agent.CreditNote.reviewCreditNote(reviewCreditNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CreditNoteReviewSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.success});
    }
  }

  addCreditRefund = async (creditNoteRequestBody: CreditNoteCreditRefundObject) => {
    try{
      await agent.CreditNote.addCreditRefund(creditNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CreditRefundSubmitSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  }
}