
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { LeaveEntitlementListObject } from "../../app/models/leaveEntitlement";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";

const LeaveEntitlement = () => {
  const intl = useIntl();
  //Use Store
  const { leaveEntitlementStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt } = commonStore;
  const { leaveEntitlementList, getLeaveEntitlement, deleteLeaveEntitlement } = leaveEntitlementStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<LeaveEntitlementListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchLeaveEntitlementAPI() {
      setLoading(true);
      await getLeaveEntitlement({ pageNumber: currentPage, pageSize: pageSize })
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageLeaveEntitlement], true)) {
      return;
    }

    fetchLeaveEntitlementAPI();
  }, [])

  const onClickDelete = async (row: LeaveEntitlementListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const LeaveEntitlementColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      }
    },
    {
      dataField: "defaultQuantity",
      text: intl.formatMessage({ id: "DefaultQuantity" }).toUpperCase(),
      style: {
        flex: 1,
      }
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageLeaveEntitlement, PermissionConstants.UpdateLeaveEntitlement, PermissionConstants.DeleteLeaveEntitlement]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManageLeaveEntitlement])
              &&
              <TableActionButton 
                to={`/${RoutesList.leaveEntitlement}/view/${row.id}`}
                type={'view'}/>
            }
            {
              checkPermission([PermissionConstants.UpdateLeaveEntitlement])
              &&
              <TableActionButton 
                to={`/${RoutesList.leaveEntitlement}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              checkPermission([PermissionConstants.DeleteLeaveEntitlement])
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeaveEntitlement" }) })}
        title={intl.formatMessage({ id: "LeaveEntitlement" })}
        addButton={
          checkPermission([PermissionConstants.CreateLeaveEntitlement])
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "LeaveEntitlement" }) })}
            onClick={() => { history.push(`/${RoutesList.leaveEntitlement}/add`) }}
            linkTo={`/${RoutesList.leaveEntitlement}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={LeaveEntitlementColumns}
          data={leaveEntitlementList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "LeaveEntitlement" }) })}
          objSorted={defaultSorted}
          requestAPI={getLeaveEntitlement} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteLeaveEntitlement(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (leaveEntitlementList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getLeaveEntitlement({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(LeaveEntitlement);
