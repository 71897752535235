import { useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, displayTypeWithColor, newSpaceBeforeCapitalLetter, returnDocumentNoHyperLink, returnReferenceDocumentTypeColor, returnSwitchTabButtonUI } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import { Constants } from "../../app/constants/Constants";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { breadCrumbObject } from "../../app/models/common";
import { MarkerArrow, Canvas, Edge, Node } from "reaflow";
import MyButton from "../../app/components/form/MyButton";
import TargetIcon from 'mdi-react/TargetIcon';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import ReferenceDocumentTransactionModal from "./Modal/ReferenceDocumentTransactionModal";
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';
import { isTablet } from "react-device-detect";

const ReferenceDocumentFlow = () => {
	//Use Params
	const { id } = useParams();
  const location = useLocation();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const { referenceDocumentStore, commonStore } = useStore();
	const {
		loading,
		setLoading,
    windowSize
	} = commonStore;
	const { getReferenceDocumentMap } = referenceDocumentStore;
	const displayTitle = `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "ReferenceDocuments"})}`;
	const [breadCrumbList, setBreadCrumbList] = useState<breadCrumbObject[]>([]);
  const [localNodes, setLocalNodes] = useState<any>([]);
  const [localEdges, setLocalEdges] = useState<any>([]);
  const [direction, setDirection] = useState<any>(Constants.right);
  const refCanvas = useRef<any>(null);
  const [referenceDocumentTypeList, setReferenceDocumentTypeList] = useState<string[]>([]);
  const [blnReferenceDocumentTransactionModal, setBlnReferenceDocumentTransactionModal] = useState(false);
  const [selectedReferenceDocument, setSelectedReferenceDocument] = useState<{documentId: string, documentType: string} | undefined>(undefined);

	useEffect(() => {
		async function fetchReferenceDocumentMapAPI() {
			setLoading(true);
      if (id) {
			  let resultReferenceDocumentMap = await getReferenceDocumentMap(id);
        let nodesTemp : any = [];
        let edgesTemp : any = [];
        let referenceDocumentTypeListTemp : string[] = [];
        if (resultReferenceDocumentMap.status === Constants.success) {
          resultReferenceDocumentMap.data.forEach(valueObjTemp => {
            let indexTempType = _.indexOf(referenceDocumentTypeListTemp, valueObjTemp.documentType);
            if (indexTempType === -1) {
              referenceDocumentTypeListTemp.push(valueObjTemp.documentType);
            }
            nodesTemp.push({
              id: valueObjTemp.documentId,
              data: {
                display: `${valueObjTemp.documentNo}`,
                value: `${valueObjTemp.documentId}`,
                type: valueObjTemp.documentType
              },
              width: Constants.flowCardWidth,
              height: Constants.flowCardHeight,
            })
      
            valueObjTemp.linkedDocumentIds.forEach(valueLinkedDocumentId => {
              let indexFromIdTemp = _.findIndex(resultReferenceDocumentMap.data, {documentId: valueLinkedDocumentId})
              edgesTemp.push({
                id: `${valueObjTemp.documentId}-${valueLinkedDocumentId}`,
                from: valueLinkedDocumentId, 
                to: valueObjTemp.documentId,
                color: returnReferenceDocumentTypeColor(resultReferenceDocumentMap.data[indexFromIdTemp].documentType)
              })
            })
          });
        }
        setLocalNodes(nodesTemp);
        setLocalEdges(edgesTemp);
        setReferenceDocumentTypeList(referenceDocumentTypeListTemp);
      }
      else {
        history.push(`/${RoutesList.notFound}`)
        return;
      }

      if (location) {
        if (location.pathname) {
          if (location.pathname.includes(RoutesList.customerSalesOrder)) {
            setBreadCrumbList([
              { title: intl.formatMessage({id: "CustomerSalesOrder"}), urlPath: RoutesList.customerSalesOrder }, 
              { title: `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "CustomerSalesOrder"})}`, urlPath: `${RoutesList.customerSalesOrder}/view/${id}` }
            ])
          }
        }
      }
			setLoading(false);
		}

    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }
    
    fetchReferenceDocumentMapAPI();
	}, []);

  const displayNodeDetail = (display, value, type, pathTemp) => {
    return (
      <div 
        className={`flex-align-items-center-without-height flex-justfity-content-center`}
        style={{
          height: "100%", 
          border: '5px outset', 
          borderColor: returnReferenceDocumentTypeColor(type),
          backgroundColor: returnReferenceDocumentTypeColor(type), 
          borderRadius: '10px',
        }}>
        <h5 
          className={!isTablet ? "pointer-clickable" : ""}
          style={{marginBottom: '0px', color: 'white', height: "100%", display: "flex", alignItems: "center"}}
          onClick={()=> {
            setSelectedReferenceDocument({documentId: value, documentType: type});
            setBlnReferenceDocumentTransactionModal(true);
          }}>
          {display}
        </h5>
        {
          pathTemp
          &&
          <span style={{borderLeft: "1px solid white", height: "60%", marginLeft: "8px", marginRight: "4px"}}/>
        }
        {
          pathTemp
          &&
          <Link to={pathTemp} target={Constants.blank} rel="noopener noreferrer" style={{height: "100%", display: "flex", alignItems: "center"}}>
            <ArrowTopRightBoldBoxOutlineIcon className="margin-left-4" size={24} color={Constants.white}/>
          </Link>
        }
      </div>
    )
  }

  const displayNode = (display, value, type) => {
    let pathTemp = returnDocumentNoHyperLink({ documentNo: display, documentId: value, documentType: type }, true)
    return (displayNodeDetail(display, value, type, pathTemp))
  }

  const onChangeDirection = (directionTemp) => {
    setLoading(true);
    setDirection(directionTemp)
    setTimeout(()=> {
      setLoading(false);
    }, 100)
  }

	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        auditTrailId={""}>
        {loading || localNodes.length === 0 ? (
          <Loading />
        ) : (
          <div>
            <style>
              {`
                .edge-css {
                  stroke-dasharray: 5;
                }
                .marker-arrow-css {
                  fill: #778899;
                }
              `}
            </style>
            <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right align-items-flex-end" : ""}>
              <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{maxWidth: "1250px", marginRight: "42px",flexWrap: 'wrap'}}>
                {
                  referenceDocumentTypeList.map((valueType)=> (
                    <div className="mb-2" key={`${valueType}_1`}>
                      {displayTypeWithColor(returnReferenceDocumentTypeColor(valueType), newSpaceBeforeCapitalLetter(valueType))}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="flex-align-items-center-without-height">
              <div className="btn-group flex-wrap" role="group">
                {returnSwitchTabButtonUI("blnDirectionRight", "blnDirectionRightTrue", direction === Constants.right, loading, intl.formatMessage({ id: "DirectionRight" }), () => {onChangeDirection(Constants.right)}, "150px")}
                {returnSwitchTabButtonUI("blnDirectionRight", "blnDirectionRightFalse", direction !== Constants.right, loading, intl.formatMessage({ id: "DirectionDown" }), () => {onChangeDirection(Constants.down)}, "150px")}
              </div>
              <MyButton
                type="button"
                class="btn btn-info mb-0 margin-left-8"
                style={{width: "50px"}}
                content=""
                subContent={
                  <TargetIcon 
                    size={20}/>
                }
                onClick={()=> {
                  refCanvas.current.fitCanvas(true)
                }}/>
            </div>
            <div 
              style={{
                marginTop: "16px",
                marginBottom: "32px",
                border: '1px dashed',
                borderRadius: '20px',
                height: windowSize.innerHeight-350
              }}>
              <Canvas
                ref={refCanvas}
                arrow={<MarkerArrow className="marker-arrow-css" size={6} />}
                nodes={localNodes} 
                edges={localEdges} 
                node={
                  <Node style={{width: "0px"}}>
                    {event => 
                      <foreignObject height={event.height} width={event.width}>
                        {displayNode(event.node.data.display, event.node.data.value, event.node.data.type)}
                      </foreignObject>}
                  </Node>
                }
                edge={(edge: any) => (
                  <Edge
                    {...edge}
                    className="edge-css"
                    style={{ 
                      stroke: edge.properties.color,
                      strokeWidth: "2px",
                    }}>
                  </Edge>
                )}
                direction={direction}
                fit={true}
                center={true}
                disabled={true}
              />
            </div>
            <GeneralSubmitAndCancelBtn
              className="width-100-percentage"
              successMessage={""}
              viewAction={false}
              hidePrimaryButton={true}
              cancelButtonTitle={intl.formatMessage({ id: "Back" })}
              cancelButtonFunction={()=> {
                history.goBack();
              }}
            />
          </div>
        )}
			</DetailViewLayout>
      {
        blnReferenceDocumentTransactionModal
        &&
        <ReferenceDocumentTransactionModal
          blnShow={blnReferenceDocumentTransactionModal}
          setModal={setBlnReferenceDocumentTransactionModal}
          documentId={selectedReferenceDocument?.documentId || ""}
          documentType={selectedReferenceDocument?.documentType || ""}/>
      }
		</div>
	);
};

export default observer(ReferenceDocumentFlow);
