import { useState, useEffect } from "react";
import { Col, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { displayOptionalProducts, getPurchaseTypeIcon, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import { Constants } from "../../../app/constants/Constants";
import Borderline from "../../../app/components/form/BorderLine";
import { RedeemPurchaseDetailObject } from "../../../app/models/customerOrder";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  validation: any;
  blnBlockRedemptionSubmit: boolean;
  blnPermissionBackdateCustomerDeliverOrder: boolean;
}

export default observer(function CustomerPurchaseConfimationModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;

  const hideModal = () => {
    if (!props.validation.isSubmitting) {
      props.setModal(false);
    }
  }

  useEffect(() => {
    if (props.validation.values.redeemPurchaseDetails && props.validation.values.redeemPurchaseDetails.length > 0) {
      props.validation.values.redeemPurchaseDetails = props.validation.values.redeemPurchaseDetails.sort((a, b) => (a.salesOrderDetailRedeemType > b.salesOrderDetailRedeemType) ? 1 : -1)
    }
  }, [props.validation.values.redeemPurchaseDetails])

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!props.validation.isSubmitting && !Boolean(successMessage)) {
            hideModal();
          }
        }}
        size="xl"
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered>
        {
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "RedemptionSummaryConfirmation" })}</h5>
              </div>
              {
                !props.validation.isSubmitting && !Boolean(successMessage) &&
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
            <div className="modal-body">
              <Row style={{ fontSize: '15px' }}>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customerName"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={props.validation}
                  />
                </DoubleColumnRowParent>
                <DoubleColumnRowParent blnDoubleTab={true}>
                <GeneralInput
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchName"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={props.validation}
                  />
                </DoubleColumnRowParent>
              </Row>
              <p className="margin-left-8 mb-0" style={{ color: 'grey' }}>
                {props.validation.values.customerNo} | {props.validation.values.customerMobileNo}
              </p>
              <Borderline />
              {
                props.blnPermissionBackdateCustomerDeliverOrder
                &&
                props.validation.values.isBackdate
                &&
                <>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PerformedDateTime" })}
                    name="performedDateTime"
                    type="datetime-local"
                    disabled={true}
                    validation={props.validation}
                  />
                  <Borderline />
                </>
              }
              {
                props.validation.values.redeemPurchaseDetails && props.validation.values.redeemPurchaseDetails.length > 0 &&
                <>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({ id: "RedemptionItemList" })}
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-fixed">
                      <thead className="table-light">
                        <tr>
                          <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                          <th className="">{intl.formatMessage({ id: "Type" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "From" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "ServiceBy" })}</th>
                          <th className="text-end">{intl.formatMessage({ id: "Remark" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props.validation.values.redeemPurchaseDetails.map((value: RedeemPurchaseDetailObject, index) => (
                            <tr key={index}>
                              <td className="">
                                {getPurchaseTypeIcon(value.salesOrderDetailRedeemType)} {value.name} {`(${value.customerInvoiceNo})`}
                                {value.mandatoryProductsUsed ? value.mandatoryProductsUsed.length > 0 ? displayOptionalProducts(value.mandatoryProductsUsed, intl) : <div/> : <div/>}
                                {value.serviceProductUsed ? value.serviceProductUsed.length > 0 ? displayOptionalProducts(value.serviceProductUsed, intl) : <div/> : <div/>}
                                {(value.purchaseDetailRedeemType === 0 || value.purchaseDetailRedeemType === 3) && !(value.mandatoryProductsUsed ? value.mandatoryProductsUsed.length > 0 ? true : false : false) && !(value.serviceProductUsed ? value.serviceProductUsed.length > 0 ? true : false : false) && displayOptionalProducts([], intl)}
                              </td>
                              <td className="">{newSpaceBeforeCapitalLetter(value.salesOrderDetailRedeemType)}</td>
                              <td className="text-center">{value.count}</td>
                              <td className="text-center">{newSpaceBeforeCapitalLetter(value.packageName) || Constants.emptyData}</td>
                              <td className="text-center">{value.serviceByName || Constants.emptyData}</td>
                              <td className="text-end">{value.remark || Constants.emptyData}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                </>
              }
              <Borderline />
              <p className="font-size-14 error_require_text mt-3">
                {intl.formatMessage({ id: "RedemptionConfirmationMade" })}
              </p>
            </div>
            {
              !Boolean(successMessage)
              &&
              <div className="mb-3 flex-right flex-direction-row">
                <MyButton
                  type="button"
                  class="btn btn-back margin-right-8"
                  style={{ width: '20%' }}
                  content={intl.formatMessage({ id: "Back" })}
                  onClick={() => props.setModal(false)}
                  disable={props.validation.isSubmitting || props.blnBlockRedemptionSubmit}
                  loading={props.validation.isSubmitting}
                />
                <MyButton
                  type="submit"
                  class="btn btn-success"
                  style={{ width: '20%' }}
                  content={intl.formatMessage({ id: "Submit" })}
                  form={Constants.redemptionOrderForm}
                  disable={props.validation.isSubmitting || props.blnBlockRedemptionSubmit}
                  loading={props.validation.isSubmitting}
                />
              </div>
            }
          </div>
        }
      </Modal>
  )
})