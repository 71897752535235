import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, getMonths, getYears, IncludesLocationPathName } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const StockMovementDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { stockMovementStore, staticReferenceStore, positionStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addStockMovement, updateStockMovement, stockMovementDetail, setStockMovementDetail, getStockMovementWithId } = stockMovementStore;
  const { commissionTypeList, getStaticReferenceWithType } = staticReferenceStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "SalesTarget" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "SalesTarget" }), urlPath: RoutesList.stockMovement }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateStockMovement = checkPermission([PermissionConstants.UpdateStockMovement]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockMovementDetail || {
      name: "",
      year: undefined,
      month: undefined,
      from: undefined,
      to: undefined,
      commissionType: "",
      commissionValue: undefined,
      isRequiredSplit: true,
      splitPortion: undefined,
      positionName: "",
      positionId: "",
      isActive: true
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SalesTargetName" }) })),
      positionId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Position" }) })),
      from: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "From" }) })),
      to: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "To" }) })),
      year: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })),
      month: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })),
      commissionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionType" }) })),
      commissionValue: Yup.number().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionType" }) })),
      splitPortion: Yup.string()
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (valuesTemp.commissionType && valuesTemp.commissionValue === 0) {
        valuesTemp.commissionValue = 0;
      }
      try {
        if (addAction) {
          await addStockMovement(valuesTemp);
        } else {
          await updateStockMovement(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchStockMovementDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.commissionType),
        getPositionDropdown("")
      ];
      if (id && !addAction) {
        aryAPI.push(getStockMovementWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStockMovementDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockMovement], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockMovement], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockMovement], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateStockMovement){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.stockMovement}/edit/${id}`)} })
    }

    setMonthList(getMonths());
    setYearList(getYears());
    fetchStockMovementDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockMovementDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={5}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
                  h4Title />
                <GeneralInput
                  title={intl.formatMessage({ id: "StockMovementName" })}
                  name="name"
                  className="mb-3 mt-4"
                  type="text"
                  disabled={disabledFieldInput || viewAction}
                  validationRequired={true}
                  validation={validation}
                />
                <Row>
                  {/* <Col xl="6">
                      <DropDownWithTitle
                        name={"year"}
                        title={"Year"}
                        specifyReturnFieldName={[{"field": "year", "value": "value"}]}
                        labelField={"label"}
                        valueField={"value"}
                        options={yearList}
                        disabled={disabledFieldInput || viewAction}
                        initialLabel={!addAction ? `${validation.values.year}` : undefined}
                        initialValue={!addAction ? validation.values.year : undefined}
                        validationRequired={true}
                        validation={validation}/>
                    </Col>
                    <Col xl="6">
                      <DropDownWithTitle
                        name={"month"}
                        title={"Month"}
                        specifyReturnFieldName={[{"field": "month", "value": "value"}]}
                        labelField={"label"}
                        valueField={"value"}
                        placeholder={""}
                        options={monthList}
                        disabled={disabledFieldInput || viewAction}
                        initialLabel={!addAction ? `${moment(validation.values.month).format("MMM")}` : undefined}
                        initialValue={!addAction ? validation.values.month : undefined}
                        validationRequired={true}
                        validation={validation}/>
                    </Col> */}
                </Row>
                <Row>
                  <Col>
                    <GeneralInput
                      title={intl.formatMessage({ id: "From" })}
                      name="from"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col>
                    <GeneralInput
                      title={intl.formatMessage({ id: "To" })}
                      name="to"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* <DropDownWithTitle
                      name={"commissionType"}
                      title={"Commission Type"}
                      specifyReturnFieldName={[{"field": "commissionType", "value": "displayValue"}]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={commissionTypeList}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.commissionType : undefined}
                      validationRequired={true}
                      validation={validation}/> */}
                  </Col>
                  <Col>
                    <GeneralInput
                      title={intl.formatMessage({ id: "CommissionValue" })}
                      name="commissionValue"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                </Row>
                {/* <DropDownWithTitle
                    name={"positionId"}
                    title={"Position"}
                    specifyReturnFieldName={[{"field": "positionName", "value": "name"}, {"field": "positionId", "value": "id"}]}
                    labelField={"name"}
                    valueField={"id"}
                    options={positionList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.positionName : undefined}
                    initialValue={!addAction ? validation.values.positionId : undefined}
                    validationRequired={true}
                    validation={validation}/> */}
                <Row>
                  <Col>
                    <GeneralInput
                      title={intl.formatMessage({ id: "RequiredSplit" })}
                      name="isRequiredSplit"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">Yes</Label>} />
                  </Col>
                  {/* {
                      validation.values.isRequiredSplit
                      &&
                      <Col>
                        <GeneralInput
                          title="Split Portion"
                          name="splitPortion"
                          type="number"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                    } */}
                </Row>
                <GeneralInput
                  title={intl.formatMessage({ id: "Active" })}
                  name="isActive"
                  type="checkbox"
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}
                  childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockMovementDetail);
