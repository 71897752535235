import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, stockOutStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Input, Form } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import MyButton from "../../app/components/form/MyButton";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const StockOutInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockOutStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { updateStockOut, stockOutDetail, setStockOutDetail, getStockOutWithId } = stockOutStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockOut" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockOut" }), urlPath: RoutesList.stockOut }];
  const invoiceFullDetail = stockOutDetail || {
    isDelete: true,
    stockOutNo: "",
    stockOutDate: "",
    warehouseId: "",
    warehouseName: "",
    note: "",
    status: "",
    approverId: "",
    approverName: "",
    approvedDate: "",
    stockOutDetails: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockOutDetail || {
      isDelete: true,
      stockOutNo: "",
      stockOutDate: "",
      warehouseId: "",
      warehouseName: "",
      note: "",
      status: "",
      approverId: "",
      approverName: "",
      approvedDate: "",
      stockOutDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (invoiceFullDetail.status == Constants.processing || invoiceFullDetail.status == Constants.partialReceived) {
          await updateStockOut(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  async function fetchStockOutDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getStockOutWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockIn], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockIn], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockIn], true)) {
        return;
      }
    }
    
    fetchStockOutDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockOutDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 ">
          {label}
        </h6>
        <h6 className="">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  const blnProcessingStatus = invoiceFullDetail.status == Constants.processing;
  const blnApprovedOrCompletedStatus = invoiceFullDetail.status == Constants.approved || invoiceFullDetail.status == Constants.completed;
  const blnRejectedStatus = invoiceFullDetail.status == Constants.rejected;
  const blnPendingStatus = invoiceFullDetail.status == Constants.pending;
  const blnPermissionUpdateStockOut = checkPermission([PermissionConstants.UpdateStockOut]);
  const blnPermissionReviewStockOut = checkPermission([PermissionConstants.ReviewStockOut]);

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "StockOut" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({ id: "StockOutNo" })}:`, invoiceFullDetail.stockOutNo)}
                          {displayHeaderRowData("Date:", moment(invoiceFullDetail.stockOutDate).format(Constants.displayDateFormat))}
                          {displayHeaderRowData("Time:", moment(invoiceFullDetail.stockOutDate).format(Constants.displayTimeFormat))}
                        </Col>
                        <hr />
                        {
                          !blnProcessingStatus &&
                          <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({ id: "ApprovedBy" })}:`, invoiceFullDetail.approverName)}
                              {blnApprovedOrCompletedStatus &&
                                <>
                                  {displayBillingShippingRowData(`${intl.formatMessage({ id: "Date" })}:`, moment(invoiceFullDetail.approvedDate).format(Constants.displayDateFormat))}
                                  {displayBillingShippingRowData(`${intl.formatMessage({ id: "Time" })}:`, moment(invoiceFullDetail.approvedDate).format(Constants.displayTimeFormat))}
                                  <br/>
                                  {displayBillingShippingRowData(`${intl.formatMessage({ id: "Receiver" })}:`, invoiceFullDetail.warehouseName)}
                                </>
                              }
                            </div>
                          </Col>
                        </Row>
                        }

                      </div>
                      {!blnProcessingStatus && <><br /><br /></>}

                      <address style={{ marginTop: blnProcessingStatus ? '20px' : ''}}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.status}
                            statusColorFunction={stockOutStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({ id: "Name" })}</th>
                              <th>{intl.formatMessage({ id: "TotalQuantity" })}</th>
                              <th>{intl.formatMessage({ id: "StockOutReason" })}</th>
                              <th>{intl.formatMessage({ id: "Remark" })}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.stockOutDetails.map((item, key) => (
                                <Fragment key={`header_${key}`}>
                                  <tr key={`body_${key}`}>
                                    <td>{item.productName}</td>
                                    <td>{item.totalQuantity}</td>
                                    <td>{newSpaceBeforeCapitalLetter(item.stockOutReasonDisplay)}</td>
                                    <td>{item.remark}</td>
                                    <td style={{ width: "40px" }}><Input type='checkbox' className="margin-bottom-8 margin-top-0" style={{ width: "18px", height: "18px" }} disabled={true}></Input></td>
                                  </tr>
                                  {
                                    item.pricingBatchDetails
                                    ?
                                    item.pricingBatchDetails.map((pricingBatchDetailsTemp) => {
                                      return (
                                        pricingBatchDetailsTemp.isActive
                                        ?
                                        <tr key={`${pricingBatchDetailsTemp.warehouseReferenceNo}_${key}`}>
                                          <td style={{borderRightWidth: '1px'}}>
                                            <i className="bx bx-subdirectory-right"></i> {`${intl.formatMessage({id: "WarehouseReferenceNo"})} : ${pricingBatchDetailsTemp.warehouseReferenceNo}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            <i className="bx bx-subdirectory-right"></i> {`${intl.formatMessage({id: "SupplierReferenceNo"})} : ${pricingBatchDetailsTemp.supplierReferenceNo}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            {`${intl.formatMessage({id: "CostPrice"})} : ${returnPriceWithCurrency(pricingBatchDetailsTemp.costPrice)}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            {`${intl.formatMessage({id: "AdjustQuantity"})} : ${pricingBatchDetailsTemp.adjustQuantity}`}
                                          </td>
                                          <td style={{width: "40px"}}/>
                                        </tr>
                                        :
                                        null
                                      )
                                    })
                                    :
                                    null
                                  }
                                </Fragment>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className="mt-3" />
                      <div className="flex-direction-row d-print-none">
                        {!Boolean(successMessage) && !blnApprovedOrCompletedStatus && !blnRejectedStatus && !blnPendingStatus && blnPermissionReviewStockOut && 
                          <MyButton
                            type="submit"
                            class={`btn btn-danger margin-right-8`}
                            content={Constants.reject}
                            disable={
                              validation.isSubmitting
                            }
                            loading={
                              validation.isSubmitting
                            }
                            onClick={() => {
                              validation.setFieldValue("workflowAction", Constants.reject)
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={blnApprovedOrCompletedStatus || blnRejectedStatus || blnPendingStatus || !blnPermissionReviewStockOut}
                          submitButtonTitle={blnProcessingStatus ? Constants.approve : ""}
                          showPrint={true}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", blnProcessingStatus ? Constants.approve : "")
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockOutInvoiceDetail);
