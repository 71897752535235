import { PaginationRequestBody } from "../models/pagination";
import { RaceListObject, RaceAddObject, RaceUpdateObject, RaceDetailObject } from "../models/race";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class RaceStore {
  raceList: RaceListObject[] = [];
  raceDropdownList: RaceListObject[] = [];
  raceDetail: RaceDetailObject | undefined = undefined;
  racePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.raceList = [];
    this.raceDropdownList = [];
    this.raceDetail = undefined;
    this.racePaginationParams= undefined;
  }

  setRaceDetail = (raceDetail: RaceDetailObject | undefined) => {
    this.raceDetail = raceDetail;
  }

  setRacePaginationParams = (racePaginationParams: PaginationRequestBody | undefined) => {
    this.racePaginationParams = racePaginationParams;
  }
  
  getRace = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setRacePaginationParams(PaginationRequestBody);
    try{
      const resultRace = await agent.Race.raceList(PaginationRequestBody);
      runInAction(() => {
        this.raceList = resultRace.data;
        store.commonStore.setTotalItem(resultRace.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.raceList = [];
    }
  }

  getRaceDropdown = async () => {
    try{
      const resultRaceDropdown = await agent.Race.raceDropdownList();
      runInAction(() => {
        this.raceDropdownList = resultRaceDropdown;
      });
      return Promise.resolve(resultRaceDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.raceDropdownList = [];
    }
  }

  getRaceWithId = async (id: string) => {
    try{
      const resultRaceDetail = await agent.Race.raceDetail(id);
      runInAction(() => {
        this.raceDetail = resultRaceDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.raceDetail = undefined;
    }
  }

  addRace = async (raceRequestBody: RaceAddObject) => {
    try{
      await agent.Race.addRace(raceRequestBody);
      store.commonStore.setSuccessMessage(`RaceAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateRace = async (raceRequestBody: RaceUpdateObject) => {
    try{
      await agent.Race.updateRace(raceRequestBody);
      store.commonStore.setSuccessMessage(`RaceUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteRace  = async (id: string, name: string) => {
    try {
      await agent.Race.deleteRace(id);
      store.commonStore.setSuccessMessage(`RaceDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}