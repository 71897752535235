import { PaginationRequestBody } from "../models/pagination";
import { UnknownPaymentListObject, UnknownPaymentAddObject, UnknownPaymentUpdateObject, UnknownPaymentDetailObject } from "../models/unknownPayment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class unknownPaymentStore {
  unknownPaymentList: UnknownPaymentListObject[] = [];
  unknownPaymentDropdownList: UnknownPaymentListObject[] = [];
  unknownPaymentDetail: UnknownPaymentDetailObject | undefined = undefined;
  unknownPaymentPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.unknownPaymentList = [];
    this.unknownPaymentDropdownList = [];
    this.unknownPaymentDetail = undefined;
    this.unknownPaymentPaginationParams= undefined;
  }

  setUnknownPaymentDetail = (unknownPaymentDetail: UnknownPaymentDetailObject | undefined) => {
    this.unknownPaymentDetail = unknownPaymentDetail;
  }

  setUnknownPaymentPaginationParams = (unknownPaymentPaginationParams: PaginationRequestBody | undefined) => {
    this.unknownPaymentPaginationParams = unknownPaymentPaginationParams;
  }
  
  getUnknownPayment = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setUnknownPaymentPaginationParams(PaginationRequestBody);
    try{
      const resultUnknownPayment = await agent.UnknownPayment.unknownPaymentList(PaginationRequestBody);
      runInAction(() => {
        this.unknownPaymentList = resultUnknownPayment.data;
        store.commonStore.setTotalItem(resultUnknownPayment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.unknownPaymentList = [];
    }
  }

  getUnknownPaymentWithId = async (id: string) => {
    try{
      const resultUnknownPaymentDetail = await agent.UnknownPayment.unknownPaymentDetail(id);
      runInAction(() => {
        this.unknownPaymentDetail = resultUnknownPaymentDetail;
      });
      return Promise.resolve(resultUnknownPaymentDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.unknownPaymentDetail = undefined;
    }
  }

  addUnknownPayment = async (unknownPaymentRequestBody: UnknownPaymentAddObject) => {
    try{
      await agent.UnknownPayment.addUnknownPayment(unknownPaymentRequestBody);
      store.commonStore.setSuccessMessage(`UnknownPaymentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateUnknownPayment = async (unknownPaymentRequestBody: UnknownPaymentUpdateObject) => {
    try{
      await agent.UnknownPayment.updateUnknownPayment(unknownPaymentRequestBody);
      store.commonStore.setSuccessMessage(`UnknownPaymentUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteUnknownPayment  = async (id: string, name: string) => {
    try {
      await agent.UnknownPayment.deleteUnknownPayment(id);
      store.commonStore.setSuccessMessage(`UnknownPaymentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}