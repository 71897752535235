import { PaginationRequestBody } from "../models/pagination";
import { AuditLogDetailObject, AuditLogListObject } from "../models/auditLog";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class AuditLogStore {
  auditLogList: AuditLogListObject[] = [];
  auditLogDetail: AuditLogDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.auditLogList = [];
    this.auditLogDetail = undefined;
  }

  setAuditLogDetail = (auditLogDetail: AuditLogDetailObject | undefined) => {
    this.auditLogDetail = auditLogDetail;
  }

  getAuditLog = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultAuditLogs = await agent.AuditLog.auditLogList(PaginationRequestBody);
      runInAction(() => {
        this.auditLogList = resultAuditLogs.data;
        store.commonStore.setTotalItem(resultAuditLogs.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.auditLogList = [];
    }
  };

  getAuditLogWithId = async (id: string) => {
    try{
      const resultAuditLogDetail = await agent.AuditLog.auditLogDetail(id);
      runInAction(() => {
        this.auditLogDetail = resultAuditLogDetail;
      });

      return Promise.resolve(resultAuditLogDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.auditLogDetail = undefined;
    }
  }
}
