import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

const PayrollItemDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { staticReferenceStore, payrollItemStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const {
    addPayrollItem,
    updatePayrollItem,
    payrollItemDetail,
    setPayrollItemDetail,
    getPayrollItemWithId,
  } = payrollItemStore;
  const { payrollCategoryType, getStaticReferenceWithType } =
    staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PayrollItem" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PayrollItem" }), urlPath: RoutesList.payrollItem }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: payrollItemDetail || {
      payrollCategoryType: "",
      name: "",
      description: "",
      isPCB: true,
      isEPF: true,
      isEIS: true,
      isSOCSO: true,
      isActive: false,
      displayOrder: 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PayrollItemName" }) })),
      payrollCategoryType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "PayrollCategoryType" }) })),
      displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (addAction) {
          await addPayrollItem(valuesTemp);
        } else {
          await updatePayrollItem(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput =
    validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPayrollItemDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.payrollCategoryType),
      ];
      if (id && !addAction) {
        aryAPI.push(getPayrollItemWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setPayrollItemDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePayrollItem], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePayrollItem], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePayrollItem], true)) {
        return;
      }
    }

    if(viewAction && checkPermission([PermissionConstants.UpdatePayrollItem])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.payrollItem}/edit/${id}`)} })
    }

    fetchPayrollItemDetailAPI();

    return (() => {
      if (id && !addAction) {
        setPayrollItemDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PayrollItem" }) })}
                  h4Title
                />
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "PayrollItemName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"payrollCategoryType"}
                    title={intl.formatMessage({ id: "PayrollCategoryType" })}
                    specifyReturnFieldName={[
                      {
                        field: "payrollCategoryType",
                        value: "displayValue",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    options={payrollCategoryType}
                    blnValueWithNewSpace={true}
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    initialValue={
                      !addAction
                        ? validation.values
                          .payrollCategoryType
                        : undefined
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Description" })}
                    name="description"
                    row={5}
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <Col sm="6" md="6" xl="6">
                    <Row>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "PCB" })}
                          name="isPCB"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "EPF" })}
                          name="isEPF"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "EIS" })}
                          name="isEIS"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInput
                          title={intl.formatMessage({ id: "SOCSO" })}
                          name="isSOCSO"
                          type="checkbox"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              {intl.formatMessage({ id: "Yes" })}
                            </Label>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validation={validation}
                    childrenUI={
                      <Label className="margin-bottom-0 margin-left-4">
                        {intl.formatMessage({ id: "Yes" })}
                      </Label>
                    }
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DisplayOrder" })}
                    name="displayOrder"
                    type="number"
                    disabled={
                      disabledFieldInput || viewAction
                    }
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PayrollItemDetail);
