import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { CustomerProductReturnListObject } from "../../app/models/customerProductReturn";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { customerProductReturnViewAvailable, customerProductReturnStatusColor, checkPermission, newSpaceBeforeCapitalLetter, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, returnPriceWithCurrency, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";

const CustomerProductReturn = () => {
  const intl = useIntl();
  //Use Store
  const { customerProductReturnStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt, } = commonStore;
  const { customerProductReturnList, customerProductReturnPaginationParams, getCustomerProductReturn, setCustomerProductReturnPaginationParams } = customerProductReturnStore;
  const { branchDropDownList, isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { customerProductReturnStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CustomerProductReturnListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCustomerProductReturnStatus, setSelectedCustomerProductReturnStatus] = useState< string>(AllStatus);
  const [selectedCustomerProductReturnStatusId, setSelectedCustomerProductReturnStatusId] = useState<number | string | undefined>(" ");
  const [customerProductReturnStatusList, setCustomerProductReturnStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const blnPermissionCreateCustomerProductReturn = checkPermission([PermissionConstants.CreateCustomerProductReturn]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnHQStaff = !getBranchUser();

  let customerProductReturnRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerProductReturnStatus: selectedCustomerProductReturnStatusId,
    customerProductReturnStatusName: selectedCustomerProductReturnStatus
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCustomerProductReturnListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerProductReturn)
      if (blnBackNavigation) {
        if (customerProductReturnPaginationParams) {
          setCurrentPage(customerProductReturnPaginationParams.pageNumber)
          setSelectedBranchId(customerProductReturnPaginationParams.branchId || "")
          setSelectedBranchName(customerProductReturnPaginationParams.branchName || "")
          setSelectedCustomerProductReturnStatus(customerProductReturnPaginationParams.customerProductReturnStatusName || "");
          setSelectedCustomerProductReturnStatusId(customerProductReturnPaginationParams.customerProductReturnStatus)
          customerProductReturnRequestBody = customerProductReturnPaginationParams;
        }
      }
      else {
        setCustomerProductReturnPaginationParams(undefined);
      }

      let aryAPI: any = [
        fetchCustomerProductReturnAPI(false),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.customerProductReturnStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setCustomerProductReturnStatusList(resultAPI[2]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerProductReturn], true)) {
      return;
    }

    fetchCustomerProductReturnListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerProductReturn)
        if (!blnSetParams) {
          setCustomerProductReturnPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedCustomerProductReturnStatusId || selectedCustomerProductReturnStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchCustomerProductReturnAPI(true);
    }
  }, [selectedCustomerProductReturnStatusId, selectedBranchId]);

  async function fetchCustomerProductReturnAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerProductReturn(customerProductReturnRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }
  
  const CustomerProductReturnColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productReturnNo",
      text: `${intl.formatMessage({ id: "ProductReturnNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerName}</div>
          }
        </>
      )
    },
    {
      dataField: "returnBranchName",
      text: `${intl.formatMessage({ id: "ReturnBranch" }).toUpperCase()}`,
    },
    {
      dataField: "advisedCreditAmount",
      text: intl.formatMessage({ id: "TotalExchangeAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.advisedCreditAmount)}
        </>
      ),
    },
    {
      dataField: "customerDeliverOrderNo",
      text: intl.formatMessage({ id: "CustomerDeliverOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageDeliverOrder
              ?
              <Link to={row.customerDeliverOrderId ? `/${RoutesList.customerRedemption}/view/${row.customerDeliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerDeliverOrderNo}</Label>
              </Link>
              :
              <div>{row.customerDeliverOrderNo}</div>
          }
        </>
      )
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "RequestedOn" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.requestedDate
        ?
        <>
          <div className="">
            {`${moment(row.requestedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyData
      )
    },
    {
      dataField: "approvedDate",
      text: intl.formatMessage({ id: "ApprovedOn" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.approvedDate !== Constants.invalidDate
        ?
        <>
          <div className="">
            {`${moment(row.approvedDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.approvedDate).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyData
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={customerProductReturnStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.customerProductReturn}/view/${row.id}`}
              type="view" />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerProductReturn" }) })}
        title={intl.formatMessage({ id: "CustomerProductReturn" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateCustomerProductReturn
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "CustomerProductReturn" }) })}
            onClick={() => { history.push(`/${RoutesList.customerProductReturn}/add`) }}
            linkTo={`/${RoutesList.customerProductReturn}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || !blnHQStaff}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CustomerProductReturn" }) })}
                name="customerProductReturnStatus"
                options={customerProductReturnStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedCustomerProductReturnStatus}
                initialValue={selectedCustomerProductReturnStatusId}
                setFieldLabel={setSelectedCustomerProductReturnStatus}
                setFieldValue={setSelectedCustomerProductReturnStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerProductReturnColumns}
              data={customerProductReturnList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerProductReturn" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              customerProductReturnStatus={selectedCustomerProductReturnStatusId}
              customerProductReturnStatusName={selectedCustomerProductReturnStatus}
              requestAPI={getCustomerProductReturn}
            />
          </>
        )}
      </ListViewLayout>
    </div>
  );
};

export default observer(CustomerProductReturn);
