import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Modal, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { burgerMenu, ObjectForDropdown } from "../../app/models/common";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import moment from "moment";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import ModalViewLayout from "../../app/components/layout/ModalViewLayout";
interface Props {
  id: string;
  blnShow: boolean;
  setModal: Function;
  reloadData: Function;
  yearList: ObjectForDropdown[];
  selectedYear: number | undefined;
  viewEIS: boolean;
}

const EISDetail = (props: Props) => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { eisStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addEIS, updateEIS, eisDetail, setEISDetail, getEIS, getEISWithId } = eisStore;
  const [viewModalAction , setViewModalAction] = useState(props.viewEIS);
  const displayTitle = `${!props.id ? intl.formatMessage({ id: "Add" }) : viewModalAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })}  ${intl.formatMessage({ id: "EIS" })}`;
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: eisDetail || {
      year: props.selectedYear || Number(moment().format('yyyy')),
      from: undefined,
      to: undefined,
      employer: undefined,
      employee: undefined,
      isActive: true
    },
    validationSchema: Yup.object({
      year: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Year" }) })),
      from: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "From" }) })),
      to: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "To" }) })),
      employer: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Employer" }) })),
      employee: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Employee" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (!props.id) {
          await addEIS(valuesTemp);
        } else {
          await updateEIS(valuesTemp);
        }
        props.reloadData();
      }
      finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || viewModalAction;

  useEffect(() => {
    async function fetchEISDetailAPI() {
      setLoading(true);
      if (props.id) {
        await getEISWithId(props.id);
      }
      setLoading(false);
    }

    if (!props.id) {
      setEISDetail(undefined);
    }

    if(props.viewEIS && checkPermission([PermissionConstants.UpdateEis])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { setViewModalAction(false);} })
    }

    fetchEISDetailAPI();

    return (() => {
      if (props.id) {
        setEISDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        dismissModal()
      }, Constants.timeoutNavigation)
    }
  }, [successMessage])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const dismissModal = () => {
    props.setModal(false);
    setErrorMessage("");
    setSuccessMessage("");
  }

  return (
    <ModalViewLayout 
      title={displayTitle}
      blnShow={props.blnShow}
      auditTrailId={viewModalAction ? props.id : ""}
      burgerMenuList={viewModalAction ? burgerMenuListContent: []}>
      {loading && !successMessage ? (
        <div style={{ marginTop: '24px', marginBottom: '24px' }}>
          <Loading />
        </div>
      ) : (
        <Form
          className="standard-layout"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          onBlur={() => {
            if (errorMessage || successMessage) {
              setErrorMessage("");
              setSuccessMessage("");
            }
          }}
        >
          <Row form={+true}>
            {" "}
            {/* +true = Convert boolean to numbers*/}
            <Col xl={12}>
              <DropDownWithTitle
                name={"year"}
                title={intl.formatMessage({ id: "Year" })}
                specifyReturnFieldName={[{ "field": "year", "value": "value" }]}
                labelField={"label"}
                valueField={"label"}
                options={props.yearList}
                disabled={true}
                validationRequired={true}
                initialValue={validation.values.year}
                validation={validation} />
              <GeneralInput
                title={intl.formatMessage({ id: "From" })}
                name="from"
                type="number"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "To" })}
                name="to"
                type="number"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
              <GeneralInput
                title={`${intl.formatMessage({ id: "Employer" })} ${intl.formatMessage({ id: "Sens" }).toLowerCase()}`}
                name="employer"
                type="number"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
              <GeneralInput
                title={`${intl.formatMessage({ id: "Employee" })} ${intl.formatMessage({ id: "Sens" }).toLowerCase()}`}
                name="employee"
                type="number"
                disabled={disabledFieldInput}
                validationRequired={true}
                validation={validation}
              />
              <GeneralInput
                title={intl.formatMessage({ id: "Active" })}
                name="isActive"
                type="checkbox"
                disabled={disabledFieldInput}
                validation={validation}
                childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
            </Col>
          </Row>
          <GeneralSubmitAndCancelBtn
            className="flex-right"
            successMessage={successMessage}
            hidePrimaryButton={viewModalAction}
            viewAction={false}
            validation={validation}
            cancelButtonFunction={() => dismissModal()}
          />
        </Form>
      )}
    </ModalViewLayout>
  );
};

export default observer(EISDetail);
