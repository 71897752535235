import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Form,
  Label,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { history } from '../../';
import { useFormik } from "formik";
import Loading from "../../app/components/loading/Loading";
import MyButton from "../../app/components/form/MyButton";
import { CustomerPackageUpgradeServiceDetailObject } from "../../app/models/customerOrder";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import Borderline from "../../app/components/form/BorderLine";
import { AddOptionsValueField, checkPermission, comparePathForPagination, comparePathForSetParams, contructValidationErrorMessage, getBranchId, returnAdvancedSearchIcon, returnCurrency } from "../../app/common/function/function";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { useIntl } from "react-intl";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import { CustomerSalesOrderUpgradeList } from "../../app/models/customerSalesOrder";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { PackageWithGroupCodeListObject } from "../../app/models/package";
import GeneralInput from "../../app/components/form/GeneralInput";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { CloseCounterPendingObject } from "../../app/models/closeCounter";
import PendingCloseCounterModal from "../CustomerOrder/Modal/PendingCloseCounterModal";
import TodayCloseCounterSubmittedModal from "../CustomerOrder/Modal/TodayCloseCounterSubmittedModal";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import CustomerOrderPendingSignModal from "../CustomerOrder/Modal/CustomerOrderPendingSignModal";
import PendingExchangeToCreditModal from "../CustomerOrder/Modal/PendingExchangeToCreditModal";

const PackageUpgrade = () => {
  //Use INTL
  const intl = useIntl();

  //Use Store
  const { employeeStore, customerStore, customerSalesOrderStore, settingsStore, packageStore, staticReferenceStore, closeCounterStore, dashboardStore, exchangeToCreditStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, successMessage, windowSize, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { employeeDropdownList, getEmployeeDropdown } = employeeStore;
  const { customerDropdownList, getCustomerDropdown, getCustomerWithId, setCustomerDropdownList } = customerStore;
  const { packageUpgradeDetails, getCustomerSalesOrderUpgradeList, setPackageUpgradeDetails } = customerSalesOrderStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { getPackageWithGroupCode } = packageStore;
  const { getPendingCloseCounter } = closeCounterStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { weeklyPendingOrdersChart, getWeeklyPendingOrdersChart, setWeeklyPendingOrders } = dashboardStore;
  const { getPendingExchangeToCredit } = exchangeToCreditStore;
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingPackageWithGroupCode, setLoadingPackageWithGroupCode] = useState(false);
  const [packageCustomerOrderDropdownList, setPackageCustomerOrderDropdownList] = useState<CustomerSalesOrderUpgradeList[]>([]);
  const [serviceTreatmentList, setServiceTreatmentList] = useState<CustomerPackageUpgradeServiceDetailObject[]>([]);
  const [selectedPackageGroupCodeId, setSelectedPackageGroupCodeId] = useState("");
  const [serviceTreatmentModal, setServiceTreatmentModal] = useState(false);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const blnEnablePackageUpgradeDiscountLimit = generalSettings?.isEnablePackageUpgradeDiscountLimit;
  const [blnShowPendingCloseCounterModal, setBlnShowPendingCloseCounterModal] = useState(false);
  const [blnShowTodayCloseCounterSubmittedModal, setBlnShowTodayCloseCounterSubmittedModal] = useState(false);
  const [blnShowPendingExchangeToCreditModal, setBlnShowPendingExchangeToCreditModal] = useState(false);
  const [pendingCloseCounterDetail, setPendingCloseCounterDetail] = useState<CloseCounterPendingObject | undefined>(undefined);
  const [localDiscountTypeList, setLocalDiscountTypeList] = useState<StaticReferenceDataObject[]>([]);
  const location = useLocation();

  const updateMaxDiscountValue = (discountTypeDropdownValue?: any) => {
    let customerPackageDetailsTemp : any = _.cloneDeep(validation.values.customerPackageDetails)
    customerPackageDetailsTemp = customerPackageDetailsTemp.map((valueCustomerPackageDetailTemp: any, indexCustomerPackageDetailsTemp) => {
      let discountAmountTemp : any = 0;
      let discountTypeTemp = valueCustomerPackageDetailTemp.discountType;
      if (discountTypeDropdownValue) {
        if (discountTypeDropdownValue.dropdownIndex === indexCustomerPackageDetailsTemp) {
          discountTypeTemp = discountTypeDropdownValue.displayValue;
        }
      }
      
      let blnDiscountTypePercentage = false;
      if (discountTypeTemp === Constants.amount) {
        discountAmountTemp = Number(valueCustomerPackageDetailTemp.discountValue)
      }
      else if (discountTypeTemp === Constants.percentage) {
        blnDiscountTypePercentage = true;
        discountAmountTemp = ((valueCustomerPackageDetailTemp.count * valueCustomerPackageDetailTemp.unitPrice) * valueCustomerPackageDetailTemp.discountValue / 100).toFixed(2);
      }

      let finalAdvisableDiscountAmountTemp = (valueCustomerPackageDetailTemp.advisableDiscountAmount == "0.00" ? 0 : valueCustomerPackageDetailTemp.advisableDiscountAmount * valueCustomerPackageDetailTemp.count).toFixed(2);
      let finalAdvisableDiscountAmountDisplayTemp : any = `${returnCurrency()} 0.00`;
      if (blnDiscountTypePercentage) {
        finalAdvisableDiscountAmountDisplayTemp = `${Number(Number(finalAdvisableDiscountAmountTemp)/(valueCustomerPackageDetailTemp.count * valueCustomerPackageDetailTemp.unitPrice) * 100).toFixed(2)}%`
      }
      else {
        finalAdvisableDiscountAmountDisplayTemp = `${returnCurrency()} ${finalAdvisableDiscountAmountTemp}`;
      }

      return({
        ...valueCustomerPackageDetailTemp,
        finalAdvisableDiscountAmount: finalAdvisableDiscountAmountTemp,
        finalAdvisableDiscountAmountDisplay: finalAdvisableDiscountAmountDisplayTemp,
        totalPrice: ((valueCustomerPackageDetailTemp.count * valueCustomerPackageDetailTemp.unitPrice) - discountAmountTemp).toFixed(2),
        discountType: discountTypeTemp,
        discountAmount: discountAmountTemp
      })
    })
    validation.setFieldValue("customerPackageDetails", customerPackageDetailsTemp);
  }

  const aryDynamicInputAndCheckBoxTabServiceTreatment: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "serviceTreatmentName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "count",
      inputType: "number",
      onChangeFunction: updateMaxDiscountValue,
      validationRequired: true
    },
    {
      label: intl.formatMessage({ id: "UnitPrice" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "unitPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `,
      textAlign: true,
      textValueAlign: true,
    },
    {
      label: intl.formatMessage({ id: "DiscountType" }).toUpperCase(),
      type: "select",
      xl: "2",
      inputName: "discountType",
      inputType: "select",
      options: AddOptionsValueField(localDiscountTypeList, "displayValue", "displayValue"),
      displayLabel: "discountType",
      displayValue: "discountType",
      specifyReturnFieldName: [{ field: "discountType", value: "displayValue"}],
      onChangeFunction: updateMaxDiscountValue
    },
    {
      label: intl.formatMessage({ id: "DiscountValue" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "discountValue",
      inputType: "number",
      onChangeFunction: updateMaxDiscountValue,
      bottomTextDisplay: blnEnablePackageUpgradeDiscountLimit ? `${intl.formatMessage({ id: "MaxAdviseDiscount" })}: ` : "",
      bottomTextFieldDisplay: blnEnablePackageUpgradeDiscountLimit ? "finalAdvisableDiscountAmountDisplay" : "",
      blnNumberOnlyWithDecimal: true
    },
    {
      label: intl.formatMessage({ id: "Total" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "totalPrice",
      inputType: "text",
      inputLabelTextDisplay: `${returnCurrency()} `,
      textAlign: true,
      textValueAlign: true,
    }
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerId: "",
      customerName: "",
      customerNo: "",
      customerMobileNo: "",
      customerBranchId: "",
      customerSalesOrderId: "",
      salesOrderDetailId: "",
      customerPackageName: "",
      branchId: "",
      consultantIds: [],
      expiredDate: "",
      note: "",
      customerPackageDetails: [],
      customerPaymentDetails: [],
      isBackdate: false,
      submissionDate: "",
      hasRounding: false,
      totalRoundingPrice: 0,
      totalPriceBeforeRounding: 0
    },
    validationSchema: Yup.object({
      customerId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })),
      salesOrderDetailId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Package" }) })),
      consultantIds: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "Consultant" }).toLowerCase() }))
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      const valuesTempToBeSaved: any = _.cloneDeep(values);
      
      if (valuesTemp.customerPackageDetails) {
        if (valuesTemp.customerPackageDetails.length < 1) {
          setErrorMessage("Please select at least one Service Treatment to upgrade")
          return;
        }

        let blnErrorFound = false;
        let totalPackagePrice = 0.00;
        valuesTemp.customerPackageDetails = valuesTemp.customerPackageDetails.map((valueCustomerPackageDetailsTemp) => {
          if (valueCustomerPackageDetailsTemp.count < 1) {
            setErrorMessage(`Quantity of ${valueCustomerPackageDetailsTemp.serviceTreatmentName} must more than 0`)
            blnErrorFound = true;
            return ({...valueCustomerPackageDetailsTemp, backgroundColor: Constants.lightorange});
          }
          
          if (Number(valueCustomerPackageDetailsTemp.discountAmount) > Number(valueCustomerPackageDetailsTemp.finalAdvisableDiscountAmount) && blnEnablePackageUpgradeDiscountLimit) {
            setErrorMessage(`Discount of ${valueCustomerPackageDetailsTemp.serviceTreatmentName} could not more than ${valueCustomerPackageDetailsTemp.finalAdvisableDiscountAmountDisplay}`)
            blnErrorFound = true;
            return ({...valueCustomerPackageDetailsTemp, backgroundColor: Constants.lightorange});
          }

          let totalPriceTemp : any = (valueCustomerPackageDetailsTemp.count * valueCustomerPackageDetailsTemp.unitPrice) - valueCustomerPackageDetailsTemp.discountAmount
          if (totalPriceTemp < 0) {
            setErrorMessage(`Total price of ${valueCustomerPackageDetailsTemp.serviceTreatmentName} could not be negative`)
            blnErrorFound = true;
            return ({...valueCustomerPackageDetailsTemp, backgroundColor: Constants.lightorange});
          }

          totalPackagePrice += Number(totalPriceTemp);
          return ({
            ...valueCustomerPackageDetailsTemp,
            backgroundColor: "",
            totalPrice: totalPriceTemp.toFixed(2)
          })
        })

        if (blnErrorFound) {
          validation.setFieldValue("customerPackageDetails", valuesTemp.customerPackageDetails)
          return;
        }

        // let finalPriceAfterRounding = Constants.supportMalaysiaRounding ? returnMalaysiaCurrencyRounding(totalPackagePrice) : totalPackagePrice;
        // let totalRoundingPrice = Constants.supportMalaysiaRounding ? Math.round((finalPriceAfterRounding - totalPackagePrice) * 100) / 100 : undefined;

        let finalPriceAfterRounding = totalPackagePrice.toFixed(2);
        let totalRoundingPrice = 0;

        setPackageUpgradeDetails({...valuesTempToBeSaved, packageCustomerOrderDropdownList});
        history.push({
          pathname: `/${RoutesList.packageUpgradePayment}`,
          state: { detail: { ...valuesTemp, totalPackagePrice: finalPriceAfterRounding, totalRoundingPrice: totalRoundingPrice } }
        });
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPackageUpgradeAPI() {
      setLoading(true);      

      let aryAPI: any = [
        getEmployeeDropdown({ branchId: getBranchId(), isBranchStaff: true, isActive: true }),
        getGeneralSettings(),
        getStaticReferenceWithType(Constants.discountType)
      ];
      let resultAPI = await Promise.all(aryAPI);

      let aryGeneralSettingAPI : any = [
        !resultAPI[1]?.isAllowCreateSalesOrderWithPendingSign ? getWeeklyPendingOrdersChart({ branchId: getBranchId(), isFilterOutTodayOrder: true }) : null,
        resultAPI[1]?.isEnableCloseCounter ? getPendingCloseCounter(getBranchId()) : null,
        resultAPI[1]?.isEnforceUtilizeExchangedCredit ? getPendingExchangeToCredit(getBranchId()) : null
      ];
      let resultGeneralSettingAPI = await Promise.all(aryGeneralSettingAPI);
      if (resultGeneralSettingAPI[1]) {
        if (resultGeneralSettingAPI[1].isInvalidCashOut || resultGeneralSettingAPI[1].isInvalidCloseCounter) {
          setPendingCloseCounterDetail(resultGeneralSettingAPI[1]);
          setBlnShowPendingCloseCounterModal(true);
        }
        else if (resultGeneralSettingAPI[1].isTodayClosingSubmitted) {
          setBlnShowTodayCloseCounterSubmittedModal(true);
        }
      }
      if (resultGeneralSettingAPI[2]) {
        if (resultGeneralSettingAPI[2].hasPendingExchangeToCreditExist) {
          setBlnShowPendingExchangeToCreditModal(true);
        }
      }

      if (resultAPI[2]) {
        let indexPercentageTemp = _.findIndex(resultAPI[2], { displayValue: Constants.percentage})
        if (indexPercentageTemp > -1) {
          setLocalDiscountTypeList([resultAPI[2][indexPercentageTemp]]);
        }
      }

      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.packageUpgradePayment, true)
      if (blnBackNavigation) {
        if (packageUpgradeDetails) {
          let consultantIdsTemp: any = [];
          let employeeDrodpownTemp = _.cloneDeep(resultAPI[0]);
          packageUpgradeDetails.consultantIds.map((valueEmployee)=> {
            let indexEmployeeId = _.findIndex(employeeDrodpownTemp, {id: valueEmployee.value});
            if (indexEmployeeId > -1) {
              consultantIdsTemp.push({
                ...employeeDrodpownTemp[indexEmployeeId],
                label: employeeDrodpownTemp[indexEmployeeId].preferredName,
                value: employeeDrodpownTemp[indexEmployeeId].id,
              })
            }
          })            
          validation.setValues({...packageUpgradeDetails, consultantIds: consultantIdsTemp});
          if (packageUpgradeDetails.packageCustomerOrderDropdownList) {
            setPackageCustomerOrderDropdownList(packageUpgradeDetails.packageCustomerOrderDropdownList);
          }
        }
      }
      else {
        setPackageUpgradeDetails(undefined);
      }

      setLoading(false);

      if (location.state) {
        if (location.state.detail) {
          onClickSelect({id: location.state.detail.customerId})
        }
      }
    }

    if (!checkPermission([PermissionConstants.UpdateCustomerSalesOrder], true)) {
      return;
    }

    fetchPackageUpgradeAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.packageUpgradePayment, true)
        if (!blnSetParams) {
          setPackageUpgradeDetails(undefined);
        }
      }

      setWeeklyPendingOrders([]);
    })
  }, [])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const fetchPackageWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingPackage(true);
    let resultCustomerDetail = await getCustomerWithId(id);
    if (resultCustomerDetail) {
      validation.setValues((values) => ({
        ...values,
        customerId: id,
        customerName: `${resultCustomerDetail?.preferredName} (${resultCustomerDetail?.customerNo})` || "",
        customerNo: resultCustomerDetail?.customerNo || "",
        customerBranchId: resultCustomerDetail?.branchId || "",
        customerMobileNo: resultCustomerDetail?.mobileNo || "",
      }))
    }
    let resultCustomerSalesOrderUpgradeList = await getCustomerSalesOrderUpgradeList(id);
    let aryPackageTemp: CustomerSalesOrderUpgradeList[] = [];

    if (resultCustomerSalesOrderUpgradeList) {
      resultCustomerSalesOrderUpgradeList.map((valueCustomerSalesOrderUpgradeListTemp) => {
        if (!valueCustomerSalesOrderUpgradeListTemp.isUpgradePackage) {
          aryPackageTemp.push({ ...valueCustomerSalesOrderUpgradeListTemp, packageName: `${valueCustomerSalesOrderUpgradeListTemp.packageName} (${valueCustomerSalesOrderUpgradeListTemp.salesOrderNo})` })
        }
      })
    }
    setPackageCustomerOrderDropdownList(aryPackageTemp);

    if (removeData) {
      validation.setFieldValue("customerPackageName", "");
      validation.setFieldValue("salesOrderDetailId", "");
      validation.setFieldValue("customerSalesOrderId", "");
      validation.setFieldValue("customerPackageDetails", []);
      setServiceTreatmentList([]);
    }
    setDisplayCustomerDropdown(true);
    setLoadingPackage(false);
    clearCustomerDropdownList();
  };

  const getServiceTreatmentList = async (valueCustomerSalesOrderUpgradeTemp: CustomerSalesOrderUpgradeList) => {    
    let serviceTreatmentListTemp: CustomerPackageUpgradeServiceDetailObject[] = [];
    let valueServiceTreatmentList = valueCustomerSalesOrderUpgradeTemp.availableServices;
    setSelectedPackageGroupCodeId(valueCustomerSalesOrderUpgradeTemp.packageGroupCodeId || "");
    valueServiceTreatmentList.map((valueServiceTreatmentTemp) => {
      let objServiceTreatmentTemp = {
        serviceTreatmentId: valueServiceTreatmentTemp.serviceTreatmentId,
        serviceTreatmentName: valueServiceTreatmentTemp.serviceTreatmentName,
        unitPrice: valueServiceTreatmentTemp.unitPrice
      }
      serviceTreatmentListTemp.push(objServiceTreatmentTemp)
    })
    validation.setFieldValue("customerPackageDetails", []);
    setServiceTreatmentList(serviceTreatmentListTemp);
  }

  const onClickServiceTreatment= async (serviceTreatmentIdTemp: string) => {
    let aryPackageWithGroupCode : PackageWithGroupCodeListObject[] = [];
    if (blnEnablePackageUpgradeDiscountLimit) {
      setLoadingPackageWithGroupCode(true);
      aryPackageWithGroupCode = await getPackageWithGroupCode({branchId: validation.values.customerBranchId, groupCodeId: selectedPackageGroupCodeId, serviceTreatmentId: serviceTreatmentIdTemp}) || [];
    }

    let maxSessionObject: any = null;
    if (aryPackageWithGroupCode) {
      if (aryPackageWithGroupCode.length > 0) {
        maxSessionObject = aryPackageWithGroupCode[aryPackageWithGroupCode.length-1];
      }
    }
    
    let indexServiceTreatmentFound = _.findIndex(serviceTreatmentList, { serviceTreatmentId: serviceTreatmentIdTemp });
    if (indexServiceTreatmentFound > -1) {
      let valueServiceTreatmentTemp = serviceTreatmentList[indexServiceTreatmentFound];
      let advisableDiscountAmountTemp : any = Number(0).toFixed(2);
      if (maxSessionObject) {
        if (maxSessionObject.services) {
          let maxSessionServiceTemp = maxSessionObject.services;
          let indexTemp = _.findIndex(maxSessionServiceTemp, { serviceTreatmentId: valueServiceTreatmentTemp.serviceTreatmentId })
          if (indexTemp > -1) {
            let discountValueTemp = valueServiceTreatmentTemp.unitPrice - maxSessionServiceTemp[indexTemp].discountPrice;
            discountValueTemp = 0 > discountValueTemp ? 0 : discountValueTemp;
            advisableDiscountAmountTemp = discountValueTemp.toFixed(2);
          }
        }
      }

      let objServiceTreatmentTemp = {
        serviceTreatmentId: valueServiceTreatmentTemp.serviceTreatmentId,
        serviceTreatmentName: valueServiceTreatmentTemp.serviceTreatmentName,
        count: 1,
        unitPrice: valueServiceTreatmentTemp.unitPrice,
        discountAmount: 0,
        discountValue: 0,
        advisableDiscountAmount: advisableDiscountAmountTemp,
        finalAdvisableDiscountAmount: advisableDiscountAmountTemp,
        finalAdvisableDiscountAmountDisplay: `${returnCurrency()} ${advisableDiscountAmountTemp}`,
        totalPrice: valueServiceTreatmentTemp.unitPrice
      }
      
      let customerPackageDetailsTemp : any = _.cloneDeep(validation.values.customerPackageDetails);
      customerPackageDetailsTemp.push(objServiceTreatmentTemp);
      validation.setFieldValue("customerPackageDetails", customerPackageDetailsTemp);
    }
    setLoadingPackageWithGroupCode(false);
  }

  const onClickSelect = (row) => {
    fetchPackageWithLoading(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setCustomerSearchModal(false);
    }, 100)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <div
        className="page-content">
        <MetaTags>
          <title>{intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "PackageUpgrade" }) })}</title>
        </MetaTags>
        <Container fluid>
          <Row className="margin-bottom-4">
            <Col xl="9">
              <div className="h4">{intl.formatMessage({ id: "PackageUpgrade" })}</div>
            </Col>
          </Row>
          <BreadCrumb activeBreadCrumbTitle={intl.formatMessage({ id: "PackageUpgrade" })} breadCrumbList={breadCrumbList}/>
          {loading && !successMessage ? (
            <Loading />
          ) : (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col xl="12">
                  <div
                    className="standard-layout">
                    <Row>
                      <Col xl={'4'} md={'6'} xs={'7'}>
                        {
                          !displayCustomerDropdown 
                          ?
                          <Loading/>
                          :
                          <DropDownWithTitle
                            name={"customerId"}
                            title={intl.formatMessage({ id: "Customer" })}
                            placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                            specifyReturnFieldName={[
                              {
                                field: "customerId",
                                value: "id",
                              }
                            ]}
                            labelField={"preferredName"}
                            valueField={"id"}
                            options={customerDropdownList}
                            disabled={disabledFieldInput || loadingPackage || loadingPackageWithGroupCode}
                            initialLabel={validation.values.customerName}
                            initialValue={validation.values.customerId}
                            validationRequired={true}
                            validation={validation}
                            onChangeFunction={fetchPackageWithLoading}
                            blnSupportCustomSearch={true}
                            onChangeCustomSearch={(value)=> {
                              fetchCustomerDropdown(value);
                            }}
                            onMenuOpenFunction={()=> {
                              clearCustomerDropdownList();
                              setLoadingCustomer(false);
                            }}
                            loadingCustomSearch={loadingCustomer}
                            setLoadingCustomSearch={setLoadingCustomer}
                          />
                        }
                      </Col>
                      <Col xl={'4'} md={'6'} xs={'5'}>
                        <MyButton
                          type="button"
                          class={`btn btn-primary margin-top-28`}
                          content={""}
                          subContent={returnAdvancedSearchIcon()}
                          loading={loading}
                          disable={loading || !displayCustomerDropdown}
                          onClick={() => { setCustomerSearchModal(true) }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="4">
                        {
                          loadingPackage
                          ?
                          <Loading />
                          :
                          <div>
                            <DropDownWithTitle
                              name={"salesOrderDetailId"}
                              title={intl.formatMessage({ id: "Package" })}
                              specifyReturnFieldName={[
                                {
                                  field: "customerPackageName",
                                  value: "packageName",
                                },
                                {
                                  field: "salesOrderDetailId",
                                  value: "salesOrderDetailId",
                                },
                                {
                                  field: "customerSalesOrderId",
                                  value: "salesOrderId"
                                }
                              ]}
                              labelField={"packageName"}
                              valueField={"packageName"}
                              options={packageCustomerOrderDropdownList}
                              disabled={disabledFieldInput || loadingPackageWithGroupCode}
                              initialLabel={validation.values.customerPackageName}
                              initialValue={validation.values.salesOrderDetailId}
                              validationRequired={true}
                              validation={validation}
                              // onChangeField={"availableServices"}
                              onChangeAllField={true}
                              onChangeFunction={getServiceTreatmentList}
                            />
                            {
                              blnPermissionManageCustomerSalesOrder
                              &&
                              <MyButton
                                type="button"
                                class={`btn btn-primary ${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-bottom-8" : ""}`}
                                content={intl.formatMessage({ id: "ViewCustomerSalesOrder" }).toUpperCase()}
                                loading={loading}
                                disable={loading || !validation.values.customerSalesOrderId}
                                onClick={() => {window.open(`/${RoutesList.customerSalesOrder}/view/${validation.values.customerSalesOrderId}`)}}
                              />
                            }
                          </div>
                        }
                      </Col>
                      <Col xl="4">
                        <DropDownWithTitleMultiSelect
                          name={"consultantIds"}
                          className={!displayCustomerDropdown && loadingPackage ? "mt-4" : ""}
                          title={intl.formatMessage({ id: "Consultants" })}
                          specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                          returnFieldWithLabel={true}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={employeeDropdownList}
                          disabled={disabledFieldInput}
                          initialValue={validation.values.consultantIds}
                          validationRequired={true}
                          validation={validation} />
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xl="4">
                        <GeneralInput
                          title={""}
                          name="isBackdate"
                          className={`mt-3`}
                          type="checkbox"
                          disabled={disabledFieldInput}
                          validationRequired={false}
                          validation={validation}
                          onChangeFunctionWithSetValidationField={(valueCheckBox)=> {
                            validation.setFieldValue("submissionDate", valueCheckBox ? moment().format(Constants.textInputDateAndTimeFormat) : "");
                          }}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              {intl.formatMessage({ id: "IsApplyPackageUpgradeSubmissionDate" })}
                            </Label>
                          }
                        />
                        {
                          validation.values.isBackdate
                          &&
                          <GeneralInput
                            title={""}
                            name="submissionDate"
                            className="mt-2"
                            placeholder={intl.formatMessage({ id: "PackageUpgradeSubmissionDate" })}
                            type="datetime-local"
                            disabled={disabledFieldInput}
                            validationRequired={true}
                            validation={validation}
                            maxCurrentDate={true}
                          />
                        }
                      </Col>
                    </Row> */}
                    <Borderline />
                    <TableWithEditableInputFields
                      name="customerPackageDetails"
                      className={`tab_bar_border_top_width width-100-percentage}`}
                      title="Service Treatments"
                      buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
                      blnEditable={true}
                      blnSupportWithModal={true}
                      blnBtnModalBottom={true}
                      supportBackgroundColor={true}
                      options={serviceTreatmentList}
                      optionsId={"serviceTreatmentId"}
                      setOptionsFunction={setServiceTreatmentList}
                      addButtonFunction={() => setServiceTreatmentModal(true)}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabServiceTreatment}
                      alignItemsBasedLineOnRow={true}
                      data={validation.values.customerPackageDetails}
                      validation={validation}
                      disabled={disabledFieldInput || loadingPackageWithGroupCode} />
                    <div className="mt-3" />
                    <MyButton
                      type="submit"
                      class="btn btn-primary"
                      content={intl.formatMessage({ id: "Next" }).toUpperCase()}
                      disable={
                        validation.isSubmitting || loadingPackageWithGroupCode
                      }
                      loading={
                        validation.isSubmitting || loadingPackageWithGroupCode
                      }
                    />
                    <SelectDropDownModal
                      blnShow={serviceTreatmentModal}
                      setModal={setServiceTreatmentModal}
                      title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
                      buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "ServiceTreatment" }) })}
                      placeholder={"- Please Select -"}
                      labelField={"serviceTreatmentName"}
                      valueField={"serviceTreatmentId"}
                      options={serviceTreatmentList}
                      setOptionsFunction={setServiceTreatmentList}
                      onClickWithSingleValueReturn={onClickServiceTreatment}
                      // fieldInput={validation.values.customerPackageDetails}
                      // fieldName={"customerPackageDetails"}
                      // validation={validation} 
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          {
            customerSearchModal
            &&
            <CustomerSearchModal
              blnShow={customerSearchModal}
              blnHideOtherBranch={false}
              setModal={setCustomerSearchModal}
              title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
              onSelectFunction={onClickSelect} />
          }
          {
            blnShowPendingExchangeToCreditModal
            &&
            <PendingExchangeToCreditModal
              blnShow={blnShowPendingExchangeToCreditModal}
              setModal={setBlnShowPendingExchangeToCreditModal}
              disabledCloseFunction={true}/>
          }
          {
            blnShowPendingCloseCounterModal
            &&
            <PendingCloseCounterModal 
              blnShow={blnShowPendingCloseCounterModal}
              setModal={setBlnShowPendingCloseCounterModal}
              pendingCloseCounterDetail={pendingCloseCounterDetail!}
              disabledCloseFunction={true}/>
          }
          {
            blnShowTodayCloseCounterSubmittedModal
            &&
            <TodayCloseCounterSubmittedModal 
              blnShow={blnShowTodayCloseCounterSubmittedModal}/>
          }
          {
            weeklyPendingOrdersChart.length > 0
            &&
            <CustomerOrderPendingSignModal
              blnShow={true}
              weeklyPendingOrdersChart={weeklyPendingOrdersChart}/>
          }
        </Container>
      </div>
    </div>
  );
}

export default observer(PackageUpgrade);
