import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";
import _ from "lodash";
import agent from "../api/agent";
import { ReferenceDocumentListObject } from "../models/referenceDocument";
import { PaginationRequestBody } from "../models/pagination";
import { Constants } from "../constants/Constants";

export default class ReferenceDocumentStore {
  referenceDocumentList: ReferenceDocumentListObject[] = [];

  constructor() {
    makeAutoObservable(this);
  }
  
  reset = () => {
    this.referenceDocumentList = [];
  };

  setReferenceDocumentList = (referenceDocumentList: ReferenceDocumentListObject[]) => {
    this.referenceDocumentList = referenceDocumentList;
  }; 
  
  getReferenceDocument = async (id: string) => {
    try {
      let resultReferenceDocument = await agent.ReferenceDocument.referenceDocumentList(id);
      runInAction(() => {
        resultReferenceDocument = resultReferenceDocument.map((valueReferenceDocumentTemp, index) =>({
          ...valueReferenceDocumentTemp,
          randomId: `${valueReferenceDocumentTemp.documentId}_${index}`,
        }))
        this.referenceDocumentList = resultReferenceDocument;
      });
    } 
    catch (error: any) {
      console.error(error);
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message);
      this.referenceDocumentList = [];
    }
  };

  getReferenceDocumentMap = async (id: string) => {
    try {
      const resultReferenceDocumentMap = await agent.ReferenceDocument.referenceDocumentMapList(id);
      return Promise.resolve({status: Constants.success, data: resultReferenceDocumentMap});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []});
    }
  };

  getReferenceDocumentTransaction = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultReferenceDocumentTransaction = await agent.ReferenceDocument.referenceDocumentTransactionList(PaginationRequestBody);
      return Promise.resolve({data: resultReferenceDocumentTransaction.data, totalItems: resultReferenceDocumentTransaction.pagination.totalItems});
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({data: [], totalItems: 0});
    }
  };  
}
