import React, { useEffect, useState } from "react";
import { Label, Input, Row, Col } from "reactstrap";
import LineBreakWithTittle from "../form/LineBreakWithTittle";
import _ from 'lodash';
import SubdirectoryArrowRightIcon from 'mdi-react/SubdirectoryArrowRightIcon';

interface Props {
  name: string;
  className?: string;
  title: string;
  blnEditable:boolean
  blnValidation?:boolean;
  data: any;
  validation: any;
  disabled: boolean;
  customCheckedField?: string;
  aryField: string[];
}

export default function TreeView(props: Props) {
	const [aryData, setData] = useState([]);
	const checkedField = props.customCheckedField || "isActive";
  let aryFinalView : any = [];

  useEffect(()=> {
    let dataTemp = _.cloneDeep(props.data);
    if (props.aryField.length > 0 && dataTemp) {
      if (dataTemp[props.aryField[0]]) {
        setData(dataTemp[props.aryField[0]])
      }
    }
  },[])

 	const onChangeChecked = (e: React.ChangeEvent<any>, rowValue: any, headRowValue, indexHeadRow:number, fieldToPick) => {
    rowValue[checkedField] = e.target.checked;
    if (headRowValue && indexHeadRow !== undefined && indexHeadRow !== null && fieldToPick) {
      let blnChecked = false;
      headRowValue[indexHeadRow][fieldToPick].map((value)=> {
        if (value[checkedField]) {
          blnChecked = true;
        }
      })
      headRowValue[indexHeadRow][checkedField] = blnChecked;
      if (fieldToPick[0] == 'branchAccess') {  
        aryData.map((valueBrand: any)=> {
          let blnBrandChecked = false;
          if(valueBrand["businessEntities"]) {
            if (valueBrand["businessEntities"].length > 0) {
              valueBrand["businessEntities"].map((valueBusinessEntities)=> {
                if (valueBusinessEntities[checkedField]) {
                  blnBrandChecked = true;
                }
              })
            }
            else {
              blnBrandChecked = false;
            }
          }
          else {
            blnBrandChecked = false;
          }
          valueBrand[checkedField] = blnBrandChecked;
        })
      }
      else if (fieldToPick[0] == "businessEntities") {
        rowValue["branchAccess"].map((value)=> {
          value[checkedField] = e.target.checked;
        })
      }
    }
    else if (rowValue) {
      if (rowValue["businessEntities"]) {
        rowValue["businessEntities"].map((valueBusinessEntities)=> {
          valueBusinessEntities[checkedField] = e.target.checked;
          if (valueBusinessEntities["branchAccess"]) {
            valueBusinessEntities["branchAccess"].map((valueBranch)=> {
              valueBranch[checkedField] = e.target.checked;
            })
          }
        })
      }
    }

    if (props.aryField.length > 0) {
      props.validation.setFieldValue(props.name, {[props.aryField[0]] : aryData});
    }
	};

	const displayCheckBox = (rowValue, indexTemp, headRowValue, indexHeadRow, fieldToPick) => {
		return (
			<Col xl={"12"}>
        <div className="flex-direction-row" style={{alignItems: 'center', marginTop: '8px'}}>
          {indexTemp > 0 && <SubdirectoryArrowRightIcon className="mb-1" size={16}/>}
          <Input
            name={rowValue.name}
            type="checkbox"
            style={{marginTop: "0px", marginLeft:"4px"}}
            onChange={(e) => onChangeChecked(e, rowValue, headRowValue, indexHeadRow, fieldToPick)}
            checked={rowValue[checkedField]}
            disabled={props.disabled || !props.blnEditable}
            onBlur={props.validation.handleBlur}
          />
          <Label style={{fontSize: '14px'}} className="margin-left-4 mb-0">{rowValue.name}</Label>
        </div>
			</Col>
		);
	};

	const displayEmptyRow = () => {
		return (
			<LineBreakWithTittle
				title={`There's no content available`}
				color="grey"
			/>
		);
	};

  const displayFinalContent = (aryDataTemp: any, marginLeftValueTemp?: number, lastestIndexTemp?: number, prevArray?: any, prevIndex?: number, fieldToPick?: any) => {
    let marginLeftValue = marginLeftValueTemp ? marginLeftValueTemp + 25 : 1;
    let lastestIndex = lastestIndexTemp ? lastestIndexTemp+1 : 1;
    aryDataTemp.map((valueTemp, index)=> {
      aryFinalView.push(
        <Col key={`${valueTemp.id} ${index}`} xl={"12"} style={{marginTop: index >0 ? "8px" : "0px", marginLeft: marginLeftValue}}>
          <Row
            className={`flex-align-items-center margin-bottom-4'}`}
            key={`${valueTemp.id} ${index}`}
          >
            {displayCheckBox(valueTemp, lastestIndex-1, prevArray, prevIndex, fieldToPick)}
          </Row>
        </Col>
      )
      if (valueTemp[props.aryField[lastestIndex]]) {
        displayFinalContent(valueTemp[props.aryField[lastestIndex]], marginLeftValue, lastestIndex, aryDataTemp, index, [props.aryField[lastestIndex]])
      }
    })
    return aryFinalView;
  }

	return (
		<div className={`${props.className}`}>
      {/* <div className="mb-3" style={{fontSize: '14px'}}>{props.title}</div> */}
      <LineBreakWithTittle
        title={props.title}
        className=""
        h4Title
      />
			{
				<div className="tree_view_border">
					{ 
            aryData.length > 0
						? displayFinalContent(aryData)
            : displayEmptyRow()
          }
				</div>
			}
		</div>
	);
}
