import { useEffect, useState } from "react";
import { Col, Input, Label, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  onSelectFunction: Function;
  localLoading: boolean;
  setLocalLoading: Function;
}

interface SearchValueObject {
  searchValue: string;
}

export default observer(function EmployeeAdvanceSearch(props: Props) {
  const intl = useIntl();
  //Use Store
  const { commonStore } = useStore();
  const { loading } = commonStore;
  const [checked, setCheckBoxChecked] = useState<any>(Constants.identityNo);
  const [searchValueObj, setSearchValueObj] = useState<SearchValueObject>({searchValue: ""})
  const options = [
    { label: intl.formatMessage({ id: "IdentityNo" }), value: Constants.identityNo },
    { label: intl.formatMessage({ id: "EmployeeNo" }), value: Constants.employeeNo },
  ];

  const clearSearchValueObj = () => {
    setSearchValueObj({searchValue: ""})
  }

  const hideModal = () => {
    if (loading || props.localLoading) {
      return;
    }

    clearSearchValueObj();
    props.setModal(false);
  }

  function handleChangeRadioBox(value) {
    setCheckBoxChecked(value);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      size='lg'
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="standard-layout">
          <Row className="align-items-md-center margin-top--8">
            <Col xl="6">
              <Row className="mb-4">
                <Label>{intl.formatMessage({ id: "SearchType" })}</Label>
                <div className="flex-direction-row">
                  {options.map((option, index) => (
                    <div key={`radio_${index}`}>
                      <Input
                        type="radio"
                        name="branch"
                        checked={option.value === checked}
                        className={`${index > 0 && "margin-left-16"}`}
                        onChange={() => handleChangeRadioBox(option.value)}
                        disabled={props.localLoading || loading}
                      />
                      &nbsp;
                      <label>{option.label}</label>
                    </div>
                  ))}
                </div>
              </Row>
            </Col>
            <Col xl="6">
              <Row className="align-items-md-center">
                <Col xl="8">
                  <GeneralInput
                    title={intl.formatMessage({ id: "SearchValue" })}
                    name="searchValue"
                    type="text"
                    disabled={props.localLoading || loading}
                    validationRequired={false}
                    blnModal={true}
                    field={searchValueObj}
                    setFieldValue={setSearchValueObj}
                  />
                </Col>
                <Col xl="4">
                  <MyButton
                    type="button"
                    class="btn btn-primary margin-top-12"
                    content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                    loading={props.localLoading || loading}
                    disable={props.localLoading || loading}
                    onClick={() => {
                      props.onSelectFunction({searchType: checked, searchValue: searchValueObj.searchValue})
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
});