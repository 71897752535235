import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, newSpaceBeforeCapitalLetter, customerProductReturnStatusColor, returnPriceWithCurrency, getPurchaseTypeIcon } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import CancelModal from "../../app/components/modal/CancelModal";

const CustomerProductReturnViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { customerProductReturnStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, setShowCancelPrompt} = commonStore;
  const { customerProductReturnDetail, setCustomerProductReturnDetail, getCustomerProductReturnWithId, updateCustomerProductReturn } = customerProductReturnStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerProductReturn" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: RoutesList.customerProductReturn }, { title: intl.formatMessage({ id: "CustomerProductReturn" }), urlPath: RoutesList.customerProductReturn }];
  const [blnReturnAndRefund, setBlnReturnAndRefund] = useState(false);
  const customerProductReturnFullDetail = customerProductReturnDetail || {
    customerSalesOrderId: "",
    customerSalesOrderNo: "",
    customerId: "",
    customerName: "",
    productReturnDetails: [],
    productReturnNo: "",
    requestedDate: "",
    approvedDate: "",
    approverId: "",
    approverName: "",
    businessEntityId: "",
    businessEntityName: "",
    returnBranchId: "",
    returnBranchName: "",
    customerDeliverOrderId: "",
    customerDeliverOrderNo: "",
    note: "",
    comment: "",
    advisedCreditAmount: 0,
    approvedCreditAmount: 0,
    status: "",
    id: "",      
    workflowAction: "Save",
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerProductReturnDetail || {
      comment: "",
      id: "",      
      workflowAction: "Save",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (customerProductReturnFullDetail.status === Constants.processing) {
          await updateCustomerProductReturn(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnProcessing = customerProductReturnFullDetail.status === Constants.processing;
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || !blnProcessing;
  const blnPermissionUpdateCustomerProductReturn = checkPermission([PermissionConstants.UpdateCustomerProductReturn])
  const blnPermissionReviewCustomerProductReturn = checkPermission([PermissionConstants.ReviewCustomerProductReturn])
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants. ManageCustomerSalesOrder]);
  const blnPermissionManageDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  async function fetchCustomerProductReturnDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let resultAPI = await getCustomerProductReturnWithId(id);
      let blnReturnAndRefundTemp = false;
      if (resultAPI) {
        if (resultAPI.productReturnDetails) {
          for (let a=0; a<resultAPI.productReturnDetails.length; a++) {
            if (resultAPI.productReturnDetails[a].returnType === Constants.returnAndRefund) {
              blnReturnAndRefundTemp = true;
              break;
            }
          }
        }
      }      
      setBlnReturnAndRefund(blnReturnAndRefundTemp);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerProductReturn], true)) {
      return;
    }
    
    fetchCustomerProductReturnDetailAPI();

    return (() => {
      if (id && !addAction) {
        setCustomerProductReturnDetail(undefined);
      }
    })
  }, []);
  
  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  const displayCustomerProductReturnRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0 text-muted">
          {label}
        </span>
        <span className="margin-left-8 mb-0 text-muted">
          {value}
        </span>
      </div>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: "16px" }}>
                            <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "CustomerProductReturn" })}
                            </span>
                          </div>
                        </Col>
                        <Row>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ProductReturnNo" })}:`, customerProductReturnFullDetail.productReturnNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ReturnBranchName" })}:`, `${customerProductReturnFullDetail.returnBranchName}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestDate" })}:`, moment(customerProductReturnFullDetail.requestedDate).format(Constants.displayDateFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "RequestTime" })}:`, moment(customerProductReturnFullDetail.requestedDate).format(Constants.displayTimeFormat))}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "Customer" })}:`, blnPermissionViewCustomer ?
                                <Link to={customerProductReturnFullDetail.customerId ? `/${RoutesList.customer}/view/${customerProductReturnFullDetail.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                  <Label className="margin-bottom-0 pointer-clickable">{customerProductReturnFullDetail.customerName || Constants.emptyData}</Label>
                                </Link>
                              :
                                `${customerProductReturnFullDetail.customerName}`)}
                          </Col>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({id: "CustomerSalesOrderNo"})}:`,  
                              blnPermissionManageCustomerSalesOrder ?
                              <Link to={customerProductReturnFullDetail.customerSalesOrderId ? `/${RoutesList.customerSalesOrder}/view/${customerProductReturnFullDetail.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{customerProductReturnFullDetail.customerSalesOrderNo || Constants.emptyData}</Label>
                              </Link>
                              :
                              `${customerProductReturnFullDetail.customerSalesOrderNo}`)}
                            {displayHeaderRowData(`${intl.formatMessage({id: "CustomerDeliverOrderNo"})}:`, 
                              blnPermissionManageDeliverOrder ?
                              <Link to={customerProductReturnFullDetail.customerDeliverOrderId ? `/${RoutesList.customerRedemption}/view/${customerProductReturnFullDetail.customerDeliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{customerProductReturnFullDetail.customerDeliverOrderNo || Constants.emptyData}</Label>
                              </Link>
                              :
                              `${customerProductReturnFullDetail.customerDeliverOrderNo}`)}
                            {
                              customerProductReturnFullDetail.approverName
                              &&
                              <>
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApproverName" })}:`, `${customerProductReturnFullDetail.approverName}`)}
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedDate" })}:`, customerProductReturnFullDetail.approvedDate !== Constants.invalidDate ? moment(customerProductReturnFullDetail.approvedDate).format(Constants.displayDateFormat) : Constants.emptyData)}
                                {displayHeaderRowData(`${intl.formatMessage({ id: "ApprovedDate" })}:`, customerProductReturnFullDetail.approvedDate !== Constants.invalidDate ? moment(customerProductReturnFullDetail.approvedDate).format(Constants.displayTimeFormat) : Constants.emptyData)}
                              </>
                            }
                          </Col>
                        </Row>
                      </div>
                      <hr/>
                      <address style={{ lineHeight: '20px', marginTop: '20px' }}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={customerProductReturnFullDetail.status}
                            statusColorFunction={customerProductReturnStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th className="">{intl.formatMessage({ id: "ProductName" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "RedeemType" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ReturnType" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "IsDefect" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ExchangeAmount" })}</th>
                              <th className="">{intl.formatMessage({ id: "Remarks" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              customerProductReturnFullDetail.productReturnDetails.map((item, key) => (
                                <tr key={key} className={`${item.isExceedMinStockLevel ? "table-row-exceed-min-stock-level": ""}`}>
                                  <td className="">{getPurchaseTypeIcon(Constants.product)}{item.productName}</td>
                                  <td className="text-center">{newSpaceBeforeCapitalLetter(item.redeemType)}</td>
                                  <td className="text-center">{item.quantity}</td>
                                  <td className="text-center">{newSpaceBeforeCapitalLetter(item.returnType)}</td>
                                  <td className="text-center">{item.isDefect ? Constants.yes : Constants.no}</td>
                                  <td className="text-center">{returnPriceWithCurrency(item.advisedCreditAmount)}</td>
                                  <td className="">{item.remark || Constants.emptyData}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        validation={validation}
                      />
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Comment" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="comment"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput || !blnPermissionUpdateCustomerProductReturn}
                        validation={validation}
                      />
                      <div className="mt-4" />
                      <div className="flex-direction-row d-print-none">
                        {
                          !Boolean(successMessage)
                          &&
                          blnProcessing
                          &&
                          <>
                            {
                              blnPermissionReviewCustomerProductReturn
                              &&
                              <>
                                <MyButton
                                  type="submit"
                                  class={`btn btn-success margin-right-8`}
                                  content={intl.formatMessage({ id: "Approve" })}
                                  disable={
                                    validation.isSubmitting
                                  }
                                  loading={
                                    validation.isSubmitting
                                  }
                                  onClick={() => {
                                    validation.setFieldValue("workflowAction", Constants.approve)
                                  }}
                                />
                                <MyButton
                                  type="submit"
                                  class={`btn btn-danger margin-right-8`}
                                  content={intl.formatMessage({ id: "Reject" })}
                                  disable={
                                    validation.isSubmitting
                                  }
                                  loading={
                                    validation.isSubmitting
                                  }
                                  onClick={() => {
                                    validation.setFieldValue("workflowAction", Constants.reject)
                                  }}
                                />
                              </>
                            }
                            {
                              blnPermissionUpdateCustomerProductReturn
                              &&
                              <MyButton
                                type="button"
                                class={`btn btn-warning margin-right-8`}
                                content={Constants.cancel}
                                disable={
                                  validation.isSubmitting
                                }
                                loading={
                                  validation.isSubmitting
                                }
                                onClick={() => {
                                  setShowCancelPrompt(true);
                                }}
                              />
                            }
                          </>
                        }
                        <GeneralSubmitAndCancelBtn
                          successMessage={successMessage}
                          className="width-100-percentage"
                          viewAction={false}
                          validation={validation}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                          cancelButtonBesidePrint={false}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
        <CancelModal
          onCancelClick={async () => {
            validation.setFieldValue("workflowAction", Constants.cancel);
            validation.submitForm();
          }} />
      </DetailViewLayout>
    </div>
  );
};

export default observer(CustomerProductReturnViewDetail);
