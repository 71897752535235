import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';
import { useIntl } from "react-intl";
import NoteIcon from 'mdi-react/NoteIcon';
import PencilIcon from 'mdi-react/PencilIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ContentSaveEditIcon from 'mdi-react/ContentSaveEditIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ClipboardEditIcon from 'mdi-react/ClipboardEditIcon';
import BookArrowRightOutlineIcon from 'mdi-react/BookArrowRightOutlineIcon';
import WhatsappIcon from 'mdi-react/WhatsappIcon';
import CreditCardSyncOutlineIcon from 'mdi-react/CreditCardSyncOutlineIcon';
import PlusBoxOutlineIcon from 'mdi-react/PlusBoxOutlineIcon';
import FileDocumentRemoveOutlineIcon from 'mdi-react/FileDocumentRemoveOutlineIcon';
import CalendarMonthOutlineIcon from 'mdi-react/CalendarMonthOutlineIcon';
import BookOpenIcon from "mdi-react/BookOpenIcon";

interface Props {
  to?: string;
  onClick?: Function;
  type: "add" | "view" | "edit" | "delete" | "save" | "cancel" | "redeem" | "outTreatment" | "whatsapp" | "exchangetocredit" | "close" | "calendar" | "viewModal";
  iconReplaceUI?: any;
  iconId?: string;
  iconToolTipText?: string;
  iconColorText?: string;
  disabled?: boolean;
  target?: string;
  className?: string;
}

export default function TableActionButton(props: Props) {
  const intl = useIntl();
  const addToolTipId = "addtooltip";
  const viewToolTipId = "viewtooltip";
  const editToolTipId = "edittooltip";
  const deleteToolTipId= "deletetooltip";
  const saveToolTipId = "savetooltip";
  const cancelToolTipId = "canceltooltip";
  const redeemToolTipId = "redeemtooltip";
  const outTreatmentToolTipId = "outtreatmenttooltip";
  const whatsappToolTipId = "whatsapptooltip";
  const exchangeToCreditToolTipId = "exchangetocredittooltip";
  const calendarToolTipId = "calendartooltip";
  const closeToolTipId = "closetooltip";
  const viewModalToolTipId = "viewmodaltooltip";
  const blnAddType = props.type === 'add';
  const blnViewType = props.type === 'view';
  const blnEditType = props.type === 'edit';
  const blnSaveType = props.type === 'save';
  const blnCancelType = props.type === 'cancel';
  const blnRedeemType = props.type === 'redeem';
  const blnOutTreatmentType = props.type === 'outTreatment';
  const blnWhatsappType = props.type === 'whatsapp';
  const blnExchangeToCreditType = props.type === 'exchangetocredit';
  const blnCloseType = props.type === 'close';
  const blnCalendarType = props.type === 'calendar';
  const blnViewModalType = props.type === 'viewModal';
  const blnDeleteType = props.type === 'delete';
  const tableMenuIconSize = 18;

  const onClickFunction = () => {
    if (props.onClick) {
      props.onClick();
    }
  }

  const displayChildUI = () => {
    return (
      <>
        {
          props.iconReplaceUI
          ?
          props.iconReplaceUI
          :
          blnAddType
          ?
          <PlusBoxOutlineIcon size={tableMenuIconSize} id={addToolTipId}/>
          :
          blnViewType
          ?
          <NoteIcon size={tableMenuIconSize} id={viewToolTipId}/>
          :
          blnEditType
          ? 
          <PencilIcon size={tableMenuIconSize} id={editToolTipId}/>
          :
          blnSaveType
          ?
          <ContentSaveEditIcon size={tableMenuIconSize} id={saveToolTipId}/>
          :
          blnCancelType
          ?
          <CloseIcon size={tableMenuIconSize} id={cancelToolTipId}/>
          :
          blnOutTreatmentType
          ?
          <ClipboardEditIcon size={tableMenuIconSize} id={outTreatmentToolTipId}/>
          :
          blnRedeemType
          ?
          <BookArrowRightOutlineIcon size={tableMenuIconSize} id={redeemToolTipId}/>
          :
          blnWhatsappType
          ?
          <WhatsappIcon size={tableMenuIconSize} id={whatsappToolTipId}/>
          :
          blnExchangeToCreditType
          ?
          <CreditCardSyncOutlineIcon size={tableMenuIconSize} id={exchangeToCreditToolTipId}/>
          :
          blnCloseType
          ?
          <FileDocumentRemoveOutlineIcon size={tableMenuIconSize} id={closeToolTipId}/>
          :
          blnCalendarType
          ?
          <CalendarMonthOutlineIcon size={tableMenuIconSize} id={calendarToolTipId}/>
          :
          blnViewModalType
          ?
          <BookOpenIcon size={tableMenuIconSize} id={viewModalToolTipId} />
          :
          <DeleteIcon size={tableMenuIconSize} id={deleteToolTipId}/>
        }
        <UncontrolledTooltip placement="top" target={props.iconId ? props.iconId : blnAddType ? addToolTipId : blnViewType ? viewToolTipId : blnEditType ? editToolTipId : blnSaveType ? saveToolTipId : blnCancelType ? cancelToolTipId : blnRedeemType ? redeemToolTipId : blnOutTreatmentType ? outTreatmentToolTipId : blnWhatsappType ? whatsappToolTipId : blnExchangeToCreditType ? exchangeToCreditToolTipId : blnCloseType ? closeToolTipId : blnCalendarType ? calendarToolTipId : blnViewModalType ? viewModalToolTipId : deleteToolTipId}>
          {props.iconToolTipText ? props.iconToolTipText : blnAddType ? intl.formatMessage({ id: "Add" }) : blnViewType ? intl.formatMessage({ id: "View" }) : blnEditType ? intl.formatMessage({ id: "Edit" }) : blnSaveType ? intl.formatMessage({ id: "Save" }) : blnCancelType ? intl.formatMessage({ id: "Cancel" }) : blnRedeemType ? intl.formatMessage({ id: "Redeem" }) : blnOutTreatmentType ? intl.formatMessage({ id: "OutTreatment" }) : blnWhatsappType ? intl.formatMessage({ id: "Whatsapp" }) : blnExchangeToCreditType ? intl.formatMessage({ id: "ExchangeToCredit" }) : blnCloseType ? intl.formatMessage({ id: "Close" }) : blnCalendarType ? intl.formatMessage({ id: "Calendar" }) : blnViewModalType ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Delete" })}
        </UncontrolledTooltip>
      </>
    )
  }
  return (
    props.to
    ?
    <Link
      to={props.to}
      className={`pointer-clickable ${props.disabled ? "text-secondary" : props.iconColorText ? props.iconColorText : blnViewType || blnCalendarType || blnViewModalType ? "text-primary" : blnAddType || blnEditType || blnSaveType || blnRedeemType || blnOutTreatmentType || blnWhatsappType || blnExchangeToCreditType ? "text-success" : "text-danger"} ${props.className ? props.className : ""}` }
      {...props.onClick ? {onClick: () => { if(!props.disabled) { onClickFunction() }}} : undefined}
      target={props.target ? props.target : ""}
      >
      {displayChildUI()}
    </Link>
    :
    <div
      className={`pointer-clickable ${props.disabled ? "text-secondary" : props.iconColorText ? props.iconColorText : blnViewType || blnCalendarType || blnViewModalType ? "text-primary" : blnAddType || blnEditType || blnSaveType || blnRedeemType || blnOutTreatmentType || blnWhatsappType || blnExchangeToCreditType ? "text-success" : "text-danger"} ${props.className ? props.className : ""}` }
      {...props.onClick ? {onClick: () => { if(!props.disabled) { onClickFunction() }}} : undefined}>
      {displayChildUI()}
    </div>
  )
}
