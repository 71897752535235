import React, { Fragment, useState } from "react";
import { FormFeedback, Modal } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import MyButton from "../form/MyButton";
import GeneralTextAreaForModal from "../form/GeneralTextAreaForModal";
import { useIntl } from "react-intl";
import { isTablet } from "react-device-detect";
import { StaticReferenceDataObject } from "../../models/staticReference";
import { newSpaceBeforeCapitalLetter } from "../../common/function/function";

interface Props {
    title: string;
    remarkTitle?: string;
    remarkRequired?: boolean;
    showPrompt: boolean;
    setShowPrompt: Function;
    onActionClick: Function;
    localLoading?: boolean;
    dismissModalAfterOnClick?: boolean;
    defaultRemarkList?: StaticReferenceDataObject[];
}

export default observer(function ActionPromptModalWithRemarkModal(props: Props) {
    const intl = useIntl();

    //Use Store
    const { commonStore } = useStore();
    const { loading, successMessage, setErrorMessage } = commonStore;

    const [errorState, setErrorState] = useState({remark: false});
    const [remarkDetail, setRemarkDetail] = useState({remark: ""});
    const disabledFieldInput = props.localLoading || loading || Boolean(successMessage);
    const blnDefaultRemarkExist = props.defaultRemarkList ? props.defaultRemarkList.length > 0 ? true : false : false;

    const hideModal = () => {
        if (!props.localLoading && !loading && !Boolean(successMessage)) {
            props.setShowPrompt(false);
        }
    }

    const displayDefaultRemark = () => {
        let aryViews : any = [];
        if (props.defaultRemarkList) {
            props.defaultRemarkList.map((valueDefaultRemark, indexDefaultRemark)=> {
                let displayContent = newSpaceBeforeCapitalLetter(valueDefaultRemark.displayValue || "");
                aryViews.push(
                    <Fragment key={`default_remark_key_${indexDefaultRemark}`}>
                        <MyButton
                            type="button"
                            class="btn btn-info button-padding-overwrite margin-left-8 margin-top-4"
                            content={displayContent}
                            disable={disabledFieldInput}
                            onClick={()=> {
                                setRemarkDetail((valueRemarkTemp)=> ({
                                    remark: valueRemarkTemp.remark ? valueRemarkTemp.remark + ` ${displayContent}` : `${displayContent}`
                                }))
                            }}
                        />
                    </Fragment>
                )
            })
        }
        return <div className="margin-top--8 margin-left--8px">{aryViews}</div>
    }

    return (
        <div>
            <Modal
                isOpen={props.showPrompt}
                toggle={() => {
                    if (!disabledFieldInput) {
                        hideModal();
                    }
                }}
                size={"xl"}
                style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
                centered={true}>
                <div className="modal-header">
                    <h5 className="modal-title margin-top-0" style={{ whiteSpace: "pre-wrap" }}> {props.title}</h5>
                    {
                        !disabledFieldInput
                        &&
                        <button
                            type="button"
                            onClick={() => {
                                hideModal();
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    }
                </div>
                <div className="modal-body">
                    <GeneralTextAreaForModal
                        title={props.remarkTitle || intl.formatMessage({ id: "Remark" })}
                        name="remark"
                        className={errorState.remark ? "mb-0" : "mb-3"}
                        row={4}
                        validationRequired={props.remarkRequired || false}
                        field={remarkDetail}
                        setFieldValue={setRemarkDetail}
                        disabled={disabledFieldInput}
                        onChangeFunction={() => {
                            if (props.remarkRequired && errorState.remark) {
                                setErrorState((value) => ({ ...value, remark: false }))
                            }
                        }} />
                    {props.remarkRequired && !remarkDetail.remark ? (
                        <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: props.remarkTitle || intl.formatMessage({ id: "Remark" }) })}`}</FormFeedback>
                    ) : null}
                    {
                        blnDefaultRemarkExist
                        ?
                        displayDefaultRemark()
                        :
                        <div/>
                    }
                    <div className={`float-end d-print-none ${blnDefaultRemarkExist ? "mt-2" : ""}`}>
                        <MyButton
                            type="button"
                            class="btn btn-danger margin-left-8"
                            style={{minWidth: "60px"}}
                            content={intl.formatMessage({ id: "No" })}
                            disable={disabledFieldInput}
                            loading={props.localLoading || loading}
                            onClick={() => {
                                hideModal();
                            }}
                        />
                        <MyButton
                            type="button"
                            class="btn btn-success margin-left-8"
                            style={{minWidth: "60px"}}
                            content={`Yes`}
                            disable={disabledFieldInput}
                            loading={props.localLoading || loading}
                            onClick={() => {
                                if (props.remarkRequired && !remarkDetail.remark) {
                                    setErrorMessage(`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: props.remarkTitle || intl.formatMessage({ id: "Remark" }) })}`)
                                    setErrorState({ remark: false })
                                } else {
                                    props.onActionClick(remarkDetail.remark);
                                    if (props.dismissModalAfterOnClick) {
                                        props.setShowPrompt(false);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
})