import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { SupplierListObject } from '../../app/models/supplier';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";

const Supplier = () => {
  const intl = useIntl();
  //Use Store
  const { supplierStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt } = commonStore;
  const { supplierList, getSupplier, deleteSupplier } = supplierStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<SupplierListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateSupplier = checkPermission([PermissionConstants.CreateSupplier]);
  const blnPermissionUpdateSupplier = checkPermission([PermissionConstants.UpdateSupplier]);
  const blnPermissionDeleteSupplier = checkPermission([PermissionConstants.DeleteSupplier]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSupplierAPI() {
      setLoading(true);
      await getSupplier({ pageNumber: currentPage, pageSize: pageSize })
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSupplier], true)) {
      return;
    }

    fetchSupplierAPI();
  }, [])

  const onClickDelete = async (row: SupplierListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const SupplierColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "contactPerson",
      text: intl.formatMessage({ id: "ContactPerson" }),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageSupplier, PermissionConstants.UpdateSupplier, PermissionConstants.DeleteSupplier]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.supplier}/view/${row.id}`}
              type={`view`}/>
            {
              blnPermissionUpdateSupplier
              &&
              <TableActionButton 
                to={`/${RoutesList.supplier}/edit/${row.id}`}
                type={`edit`}/>
            }
            {
              blnPermissionDeleteSupplier
              &&
              <TableActionButton 
                type={`delete`}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Supplier" }) })}
        title={intl.formatMessage({ id: "Supplier" })}
        addButton={
          blnPermissionCreateSupplier
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Supplier" }) })}
            onClick={() => { history.push(`/${RoutesList.supplier}/add`) }}
            linkTo={`/${RoutesList.supplier}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={SupplierColumns}
          data={supplierList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Supplier" }) })}
          objSorted={defaultSorted}
          requestAPI={getSupplier} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteSupplier(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (supplierList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getSupplier({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Supplier);
