import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { ProductRedemptionNoteReturnListObject } from "../../app/models/productRedemptionNoteReturn";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { checkPermission, newSpaceBeforeCapitalLetter, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, productRedemptionNoteReturnStatusColor, returnPriceWithCurrency, returnAdvancedSearchText } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";

const ProductRedemptionNoteReturn = () => {
  const intl = useIntl();
  //Use Store
  const { productRedemptionNoteReturnStore, staticReferenceStore, branchStore, commonStore, customerStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { productRedemptionNoteReturnList, productRedemptionNoteReturnPaginationParams, getProductRedemptionNoteReturn, setProductRedemptionNoteReturnPaginationParams } = productRedemptionNoteReturnStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { productRedemptionNoteReturnStatus, getStaticReferenceWithType } = staticReferenceStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<ProductRedemptionNoteReturnListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: "" }];
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedProductRedemptionNoteReturnStatus, setSelectedProductRedemptionNoteReturnStatus] = useState<string>(AllStatus);
  const [selectedProductRedemptionNoteReturnStatusId, setSelectedProductRedemptionNoteReturnStatusId] = useState<number | string | undefined>(" ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [productRedemptionNoteReturnStatusList, setProductRedemptionNoteReturnStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const blnPermissionCreateProductRedemptionNoteReturn = checkPermission([PermissionConstants.RequestProductRedemptionNoteReturn]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageProductRedemptionNote = checkPermission([PermissionConstants.ManageProductRedemptionNote]);
  const [triggerSearch, setTriggerSearch] = useState(0);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      productRedemptionNoteReturnNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let productRedemptionNoteReturnRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedCustomerId ? "" : getBranchId(),
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    productRedemptionNoteReturnStatus: selectedProductRedemptionNoteReturnStatusId,
    productRedemptionNoteReturnStatusName: selectedProductRedemptionNoteReturnStatus,
    productRedemptionNoteReturnNo: validation.values.productRedemptionNoteReturnNo
  }
  
  useEffect(() => {
    async function fetchProductRedemptionNoteReturnListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.productRedemptionNoteReturn)
      if (blnBackNavigation) {
        if (productRedemptionNoteReturnPaginationParams) {
          setCurrentPage(productRedemptionNoteReturnPaginationParams.pageNumber)
          setSelectedProductRedemptionNoteReturnStatus(productRedemptionNoteReturnPaginationParams.productRedemptionNoteReturnStatusName || "");
          setSelectedProductRedemptionNoteReturnStatusId(productRedemptionNoteReturnPaginationParams.productRedemptionNoteReturnStatus);
          setSelectedCustomerId(productRedemptionNoteReturnPaginationParams.customerId || "");
          setSelectedCustomer(productRedemptionNoteReturnPaginationParams.customerName || "");

          validation.setValues((values)=> ({
            ...values,
            productRedemptionNoteReturnNo: productRedemptionNoteReturnPaginationParams.productRedemptionNoteReturnNo || "",
          }))
          productRedemptionNoteReturnRequestBody = productRedemptionNoteReturnPaginationParams;
        }
      }
      else {
        setProductRedemptionNoteReturnPaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchProductRedemptionNoteReturnAPI(false),
        getStaticReferenceWithType(Constants.productRedemptionNoteReturnStatus, AllStatus),
        getBranchDropDown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setProductRedemptionNoteReturnStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageProductRedemptionNoteReturn], true)) {
      return;
    }

    fetchProductRedemptionNoteReturnListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.productRedemptionNoteReturn)
        if (!blnSetParams) {
          setProductRedemptionNoteReturnPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedProductRedemptionNoteReturnStatusId || selectedProductRedemptionNoteReturnStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchProductRedemptionNoteReturnAPI(true);
    }
  }, [selectedProductRedemptionNoteReturnStatusId, selectedCustomerId, triggerSearch]);

  async function fetchProductRedemptionNoteReturnAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getProductRedemptionNoteReturn({
      ...productRedemptionNoteReturnRequestBody
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setCurrentPage(1);
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);
    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const ProductRedemptionNoteReturnColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productRedemptionNoteReturnNo",
      text: `${intl.formatMessage({ id: "ProductRedemptionNoteReturnNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerName}</div>
          }
        </>
      )
    },
    {
      dataField: "returnBranchName",
      text: `${intl.formatMessage({ id: "ReturnBranch" }).toUpperCase()}`,
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "TotalExchangeAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalExchangeAmount)}
        </>
      ),
    },
    {
      dataField: "productRedemptionNoteNo",
      text: intl.formatMessage({ id: "ProductRedemptionNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageProductRedemptionNote
              ?
              <Link to={row.productRedemptionNoteId ? `/${RoutesList.productRedemptionNote}/view/${row.productRedemptionNoteId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.productRedemptionNoteNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.productRedemptionNoteNo}</div>
          }
        </>
      )
    },
    {
      dataField: "requestedOn",
      text: intl.formatMessage({ id: "RequestedOn" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.requestedOn
        ?
        <>
          <div className="">
            {`${moment(row.requestedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyData
      )
    },
    {
      dataField: "reviewedOn",
      text: intl.formatMessage({ id: "ReviewedOn" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.reviewedOn
        ?
        <>
          <div className="">
            {`${moment(row.reviewedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.reviewedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyData
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={productRedemptionNoteReturnStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.productRedemptionNoteReturn}/view/${row.id}`}
              type="view" />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }) })}
        title={intl.formatMessage({ id: "ProductRedemptionNoteReturn" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateProductRedemptionNoteReturn
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }) })}
            onClick={() => { history.push(`/${RoutesList.productRedemptionNoteReturn}/add`) }}
            linkTo={`/${RoutesList.productRedemptionNoteReturn}/add`}
            disable={localLoading || loading} />
        }>
        {
          !initialCallAPI
          &&
          <Col xl="8">
            <Row>
              {
                !initialCallAPI
                &&
                <DoubleColumnRowParent
                  blnDoubleTab>
                  {
                    displayCustomerDropdown &&
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "Customer" })}
                      name="customer"
                      placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                      options={customerDropdownList}
                      labelField="preferredName"
                      valueField="id"
                      initialLabel={selectedCustomer}
                      initialValue={selectedCustomerId}
                      setFieldLabel={setSelectedCustomer}
                      setFieldValue={setSelectedCustomerId}
                      disabled={localLoading || loading}
                      onChange={() => {
                        clearCustomerDropdownList();
                        setCurrentPage(1);
                        setErrorMessage("");
                        setSuccessMessage("");
                      }}
                      blnSupportCustomSearch={true}
                      onChangeCustomSearch={(value)=> {
                        fetchCustomerDropdown(value);
                      }}
                      onMenuOpenFunction={()=> {
                        clearCustomerDropdownList();
                        setLoadingCustomer(false);
                      }}
                      loadingCustomSearch={loadingCustomer}
                      setLoadingCustomSearch={setLoadingCustomer}
                    />
                  }
                </DoubleColumnRowParent>
              }
              {
                !initialCallAPI
                &&
                <DoubleColumnRowParent>
                  <MyButton
                    type="button"
                    class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-12"}`}
                    content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                    loading={localLoading || loading}
                    disable={localLoading || loading}
                    onClick={() => { setCustomerSearchModal(true) }}
                  />
                </DoubleColumnRowParent>
              }
            </Row>
          </Col>
        }
        <Col xl="8">
          <Row>
            <DoubleColumnRowParent
              blnDoubleTab>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }) })}
                name="productRedemptionNoteReturnStatus"
                options={productRedemptionNoteReturnStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedProductRedemptionNoteReturnStatus}
                initialValue={selectedProductRedemptionNoteReturnStatusId}
                setFieldLabel={setSelectedProductRedemptionNoteReturnStatus}
                setFieldValue={setSelectedProductRedemptionNoteReturnStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent
              blnDoubleTab>
              <GeneralInput
                title={intl.formatMessage({ id: "ProductRedemptionNoteReturnNo" })}
                name="productRedemptionNoteReturnNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterProductRedemptionNoteReturnNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        </Col>
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ProductRedemptionNoteReturnColumns}
              data={productRedemptionNoteReturnList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNoteReturn" }) })}
              objSorted={defaultSorted}
              productRedemptionNoteReturnStatus={selectedProductRedemptionNoteReturnStatusId}
              productRedemptionNoteReturnStatusName={selectedProductRedemptionNoteReturnStatus}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedCustomerId ? "" : getBranchId()}
              productRedemptionNoteReturnNo={validation.values.productRedemptionNoteReturnNo}
              requestAPI={getProductRedemptionNoteReturn}
            />
          </>
        )}
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
};

export default observer(ProductRedemptionNoteReturn);
