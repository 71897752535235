import { useState } from "react";
import {
  Modal
} from "reactstrap";
import _ from 'lodash';
import MyAddButton from "../form/MyAddButton";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import DropZoneUploadWidget from "../form/DropZoneUploadWidget";
import { useIntl } from "react-intl";
import MyButton from "../form/MyButton";

interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  buttonTitle?: string;
  btnFunction?: Function;
  ExtraTopComponent?: any;
}

export default observer(function ImportFileModal(props: Props) {
  const { commonStore } = useStore();
  const { loadingModal, successMessage, setErrorMessage } = commonStore;
  const [excelFile, setExcelFile] = useState("");
  const intl = useIntl();
  const disabledFieldInput = loadingModal || Boolean(successMessage);
  const hideModal = () => {
    props.setModal(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        {
          props.ExtraTopComponent
          ?
          <props.ExtraTopComponent/>
          :
          <div/>
        }
        <DropZoneUploadWidget 
          name="excelFile"
          disabled={disabledFieldInput}
          blnExcelFormat={true}
          setNewImageUrl={setExcelFile}
          setErrorMessage={setErrorMessage}/>
        {
          !Boolean(successMessage) 
          &&
          <MyButton
            content={props.buttonTitle || "Submit"}
            class={"btn btn-success"}
            onClick={()=> {
              if (!excelFile) {
                setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
              }
              else if (props.btnFunction){
                props.btnFunction(excelFile);
              }
              else {
                setErrorMessage(intl.formatMessage({id: "TechnicalIssue"}))
              }
            }}
            disable={disabledFieldInput}
            loading={loadingModal}
          />
        }
      </div>
    </Modal>
  )
})