import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { CountryListObject } from '../../app/models/country';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const Country = () => {
  const intl = useIntl();
  //Use Store
  const { countryStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { countryList, countryPaginationParams, getCountry, deleteCountry, setCountryPaginationParams } = countryStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CountryListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateCountry = checkPermission([PermissionConstants.CreateCountry]);
  const blnPermissionUpdateCountry = checkPermission([PermissionConstants.UpdateCountry]);
  const blnPermissionDeleteCountry = checkPermission([PermissionConstants.DeleteCountry]);

  let countryRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCountryAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.country)
      if (blnBackNavigation) {
        if (countryPaginationParams) {
          setCurrentPage(countryPaginationParams.pageNumber)
          countryRequestBody = countryPaginationParams;
        }
      }
      else {
        setCountryPaginationParams(undefined);
      }
      await getCountry(countryRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCountry], true)) {
      return;
    }

    fetchCountryAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.country)
        if (!blnSetParams) {
          setCountryPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: CountryListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const CountryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageCountry, PermissionConstants.UpdateCountry, PermissionConstants.DeleteCountry]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.country}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateCountry
              &&
              <TableActionButton 
                to={`/${RoutesList.country}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteCountry
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Country" }) })}
        title={intl.formatMessage({ id: "Country" })}
        addButton={
          blnPermissionCreateCountry
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Country" }) })}
            onClick={() => { history.push(`/${RoutesList.country}/add`) }}
            linkTo={`/${RoutesList.country}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CountryColumns}
          data={countryList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Country" }) })}
          objSorted={defaultSorted}
          requestAPI={getCountry} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCountry(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (countryList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCountry({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Country);
