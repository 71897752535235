import React, { useState, useEffect } from "react";
import { Col, Label, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { NotificationDetailObject } from "../../../app/models/notification";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedNotificationDetail: NotificationDetailObject | undefined;
}

export default observer(function UserRolePermissionsListingModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),);
  //Data List
  const defaultNotificationDetails = {
    notificationType: "",
    to: "",
    subject: "",
    body: "",
    url: "",
    isSent: false,
    isNew: false,
    isRead: false
  }
  const [notificationDetails, setNotificationDetails] = useState(props.selectedNotificationDetail || defaultNotificationDetails)
  const blnEmail = props.selectedNotificationDetail?.notificationType == "Email"

  useEffect(() => {
    if (props.selectedNotificationDetail) {
      if (blnEmail) {
        //set email body
        let contentState = stateFromHTML(props.selectedNotificationDetail.body);
        setEditorState(EditorState.createWithContent(contentState));
      }
      setNotificationDetails(props.selectedNotificationDetail);
    }
  }, [props.selectedNotificationDetail])

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        size="lg"
        centered>
        {
          !props.selectedNotificationDetail
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0"><b>{props.selectedNotificationDetail.notificationType}</b></h5>
                </div>
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "To" })}
                    name="to"
                    className={"mb-3"}
                    type="text"
                    maxLength={4}
                    validationRequired={true}
                    field={notificationDetails}
                    setFieldValue={setNotificationDetails}
                    disabled={true}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "Subject" })}
                    name="subject"
                    className={"mb-3"}
                    type="text"
                    maxLength={4}
                    validationRequired={true}
                    field={notificationDetails}
                    setFieldValue={setNotificationDetails}
                    disabled={true}
                  />
                </SingleColumnRowParent>
                {
                  blnEmail ?
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <Label>{intl.formatMessage({ id: "Body" })}</Label>
                      <Editor editorStyle={{ backgroundColor: "#eff2f7", opacity: '1' }}
                        editorState={editorState}
                        toolbarHidden
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        name="body"
                        disabled={true}
                      />
                    </SingleColumnRowParent>
                    :
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <GeneralTextAreaForModal
                        title={intl.formatMessage({ id: "Body" })}
                        name="body"
                        className={"mb-3"}
                        row={6}
                        field={notificationDetails}
                        setFieldValue={setNotificationDetails}
                        disabled={false} />
                    </SingleColumnRowParent>
                }

                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "Url" })}
                    name="url"
                    className={blnEmail ? "mt-3 mb-3" : "mb-3"}
                    type="text"
                    maxLength={4}
                    validationRequired={true}
                    field={notificationDetails}
                    setFieldValue={setNotificationDetails}
                    disabled={true}
                  />
                </SingleColumnRowParent>
                <Row>
                  <Col sm="12" md="12" xl="12">
                    <Row>
                      <Col>
                        <GeneralInputForModal
                          title={intl.formatMessage({ id: "New" })}
                          name="isNew"
                          type="checkbox"
                          disabled={true}
                          field={notificationDetails}
                          setFieldValue={setNotificationDetails}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInputForModal
                          title={intl.formatMessage({ id: "Sent" })}
                          name="isSent"
                          type="checkbox"
                          disabled={true}
                          field={notificationDetails}
                          setFieldValue={setNotificationDetails}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              Yes
                            </Label>
                          }
                        />
                      </Col>
                      <Col>
                        <GeneralInputForModal
                          title={intl.formatMessage({ id: "Read" })}
                          name="isRead"
                          type="checkbox"
                          disabled={true}
                          field={notificationDetails}
                          setFieldValue={setNotificationDetails}
                          childrenUI={
                            <Label className="margin-bottom-0 margin-left-4">
                              {intl.formatMessage({ id: "Yes" })}
                            </Label>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <div className="mb-3 mt-4 flex-right">
                  <MyButton
                    type="button"
                    class="btn btn-back"
                    style={{ width: '20%' }}
                    content={intl.formatMessage({ id: "Back" })}
                    onClick={() => {
                      hideModal();
                    }}
                  />
                </div> */}
              </div>
            </div>
        }
      </Modal>
  )
})