import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { CategoryListObject } from '../../app/models/category';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const Category = () => {
  const intl = useIntl();
  //Use Store
  const { categoryStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt } = commonStore;
  const { categoryList, categoryPaginationParams, getCategory, deleteCategory, setCategoryPaginationParams } = categoryStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CategoryListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionUpdateCategory = checkPermission([PermissionConstants.UpdateCategory]);
  const blnPermissionDeleteCategory = checkPermission([PermissionConstants.DeleteCategory]);
  const blnPermissionCreateCategory = checkPermission([PermissionConstants.CreateCategory]);

  let cateogryRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize 
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCategoryAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.category)
      if (blnBackNavigation) {
        if (categoryPaginationParams) {
          setCurrentPage(categoryPaginationParams.pageNumber)
          cateogryRequestBody = categoryPaginationParams;
        }
      }
      else {
        setCategoryPaginationParams(undefined);
      }
      await getCategory(cateogryRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCategory], true)) {
      return;
    }

    fetchCategoryAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.category)
        if (!blnSetParams) {
          setCategoryPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: CategoryListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const CategoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.category}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateCategory
              &&
              <TableActionButton 
                to={`/${RoutesList.category}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteCategory
              &&
              <TableActionButton 
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Category" }) })}
        title={intl.formatMessage({ id: "Category" })}
        addButton={
          blnPermissionCreateCategory
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Category" }) })}
            onClick={() => { history.push(`/${RoutesList.category}/add`) }}
            linkTo={`/${RoutesList.category}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={CategoryColumns}
          data={categoryList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Category" }) })}
          objSorted={defaultSorted}
          requestAPI={getCategory} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCategory(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (categoryList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCategory({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(Category);
