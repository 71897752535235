import { PaginationRequestBody } from "../models/pagination";
import { ServiceTreatmentListObject, ServiceTreatmentAddObject, ServiceTreatmentUpdateObject, ServiceTreatmentDetailObject, BranchForServiceTreatmentObject, ServiceTreatmentDropdownRequestBody, ServiceTreatmentExportObject, ServiceTreatmentImportObject, ServiceMachineActiveObject, ServiceMachineActiveRequestObject } from "../models/serviceTreatment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel, newSpaceBeforeCapitalLetter } from "../common/function/function";
import { Constants } from "../constants/Constants";
import _ from "lodash";

export default class serviceTreatmentStore {
  serviceTreatmentList: ServiceTreatmentListObject[] = [];
  serviceTreatmentDropdownList: ServiceTreatmentListObject[] = [];
  serviceTreatmentDetail: ServiceTreatmentDetailObject | undefined = undefined;
  serviceTreatmentBranchAccess: BranchForServiceTreatmentObject[] = [];
  serviceTreatmentPaginationParams: PaginationRequestBody | undefined = undefined;
  serviceTreatmentTableColumnFromStore: any[] = [];
  serviceTreatmentBranchMachineActive: ServiceMachineActiveObject[] = []; 

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.serviceTreatmentList = [];
    this.serviceTreatmentDropdownList = [];
    this.serviceTreatmentDetail = undefined;
    this.serviceTreatmentBranchAccess = [];
    this.serviceTreatmentPaginationParams = undefined;
    this.serviceTreatmentTableColumnFromStore = [];
  }

  setServiceTreatmentDetail = (serviceTreatmentDetail: ServiceTreatmentDetailObject | undefined) => {
    this.serviceTreatmentDetail = serviceTreatmentDetail;
  }

  setServiceTreatmentPaginationParams = (serviceTreatmentPaginationParams: PaginationRequestBody | undefined) => {
    this.serviceTreatmentPaginationParams = _.cloneDeep(serviceTreatmentPaginationParams);
  }

  setServiceTreatmentTableColumnFromStore = (serviceTreatmentTableColumnFromStore: any[]) => {
    this.serviceTreatmentTableColumnFromStore = serviceTreatmentTableColumnFromStore;
  }
  
  getServiceTreatment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      this.setServiceTreatmentPaginationParams(PaginationRequestBody);
      PaginationRequestBody.name = encodeURIComponent(PaginationRequestBody.name || "");
      const resultServiceTreatment = await agent.ServiceTreatment.serviceTreatmentList(PaginationRequestBody);
      runInAction(() => {
        this.serviceTreatmentList = resultServiceTreatment.data;
        store.commonStore.setTotalItem(resultServiceTreatment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.serviceTreatmentList = [];
    }
  }

  getServiceTreatmentDropdown = async (serviceTreatmentDropdownRequestBody: ServiceTreatmentDropdownRequestBody = {}, blnSorting: boolean = false) => {
    try{
      let resultServiceTreatmentDropdown = await agent.ServiceTreatment.serviceTreatmentDropdownList(serviceTreatmentDropdownRequestBody);
      runInAction(() => {
        if (blnSorting) {
          resultServiceTreatmentDropdown = _.orderBy(resultServiceTreatmentDropdown, ["displayOrder", "salesCategoryName", "name"], ['asc', 'desc', 'asc']);
        }
        this.serviceTreatmentDropdownList = resultServiceTreatmentDropdown;
      });
      return Promise.resolve(resultServiceTreatmentDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.serviceTreatmentDropdownList = [];
    }
  }

  getServiceTreatmentWithId = async (id: string) => {
    try{
      let resultServiceTreatmentDetail = await agent.ServiceTreatment.serviceTreatmentDetail(id);
      if (resultServiceTreatmentDetail.serviceProducts) {
        resultServiceTreatmentDetail.serviceProducts = resultServiceTreatmentDetail.serviceProducts.map((valueServiceProductsTemp)=> ({
          ...valueServiceProductsTemp,
          productName: `${valueServiceProductsTemp.productName} (${valueServiceProductsTemp.measurementQuantity > 1 ? `${valueServiceProductsTemp.measurementQuantity} ` : ""}${valueServiceProductsTemp.measurementName})`
        }))

        resultServiceTreatmentDetail.serviceMachines = resultServiceTreatmentDetail.serviceMachines.map((valueServiceMachineTemp)=> ({
          ...valueServiceMachineTemp,
          consumptionType: newSpaceBeforeCapitalLetter(valueServiceMachineTemp.consumptionType),
          isByDuration: valueServiceMachineTemp.consumptionType === Constants.byDuration
        }))
      }
      runInAction(() => {
        this.serviceTreatmentDetail = resultServiceTreatmentDetail;
      });
      return Promise.resolve(resultServiceTreatmentDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.serviceTreatmentDetail = undefined;
    }
  }

  getServiceTreatmentBranchMachineActive = async (serviceMachineActiveRequestObject: ServiceMachineActiveRequestObject) => {
    try{
      const resultServiceTreatmentBranchMachineActive = await agent.ServiceTreatment.serviceTreatmentBranchMachineActive(serviceMachineActiveRequestObject);
      return Promise.resolve(resultServiceTreatmentBranchMachineActive);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  addServiceTreatment = async (serviceTreatmentRequestBody: ServiceTreatmentAddObject) => {
    try{
      await agent.ServiceTreatment.addServiceTreatment(serviceTreatmentRequestBody);
      store.commonStore.setSuccessMessage(`ServiceTreatmentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateServiceTreatment = async (serviceTreatmentRequestBody: ServiceTreatmentUpdateObject) => {
    try{
      await agent.ServiceTreatment.updateServiceTreatment(serviceTreatmentRequestBody);
      store.commonStore.setSuccessMessage(`ServiceTreatmentUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteServiceTreatment  = async (id: string, name: string) => {
    try {
      await agent.ServiceTreatment.deleteServiceTreatment(id);
      store.commonStore.setSuccessMessage(`ServiceTreatmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getServiceTreatmentBranchAccess = async () => {
    try{
      const resultServiceTreatmentBranchAccess = await agent.ServiceTreatment.serviceTreatmentBrandAccess();
      runInAction(() => {
        this.serviceTreatmentBranchAccess = resultServiceTreatmentBranchAccess;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.serviceTreatmentBranchAccess = [];
    }
  }

  importServiceTreatment = async (serviceTreatmentImportRequestBody: ServiceTreatmentImportObject) => {
    try{
      let resultImportServiceTreatment = await agent.ServiceTreatment.importServiceTreatment(serviceTreatmentImportRequestBody);
      return Promise.resolve(resultImportServiceTreatment)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportServiceTreatment = async (serviceTreatmentExportRequestBody: ServiceTreatmentExportObject) => {
    try{
      await downloadExcel(`/serviceTreatment/export`, serviceTreatmentExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}