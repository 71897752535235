import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Row } from "reactstrap";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, returnPriceWithCurrency, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfCurrentMonthToLastDayOfCurrentMonth, getYears, getMonths } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const SalesVariance = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, windowSize, setErrorMessage } = commonStore;
  const { salesVarianceList, getSalesVarianceList, setSalesVarianceList, exportSalesVariance } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const paramsSearch = useLocation().search;
  const [localLoading, setLocalLoading] = useState(false);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : " ");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : AllBranch);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfCurrentMonthToLastDayOfCurrentMonth());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());
    
    async function fetchDynamicSalesListAPI() {     
      setLoading(true); 
      
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      let resultAPI = await Promise.all(aryAPI);  
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

      if (fromDateParams && toDateParams && branchIdParams) {
        let yearParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("YYYY"));
        let monthParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("M"));
        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedYear(yearParams);
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, { value: monthParams });
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }

        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.salesVariance}` });
        await getSalesVarianceList({ branchId: branchIdParams || selectedBranchId, fromDate: fromDateTemp, toDate: toDateTemp });
      }

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setSalesVarianceList([]);
    })
  }, []);

  useEffect(()=> {
    setSelectedDateRange(dateRangeLimit)
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
  }, [selectedYear, selectedMonthValue])

  async function fetchSalesVarianceListApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getSalesVarianceList({
      branchId: selectedBranchId,
      fromDate: selectedDateRange.fromDate,
      toDate: selectedDateRange.toDate
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportSalesVarianceApi() {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}));
      return;
    }

    setLocalLoading(true);
    await exportSalesVariance({
      branchId: selectedBranchId,
      fromDate: selectedDateRange.fromDate,
      toDate: selectedDateRange.toDate
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const tableFixedWidth = 200;
  const returnHeaderStyle = { width: `${tableFixedWidth}px`, minWidth: `${tableFixedWidth}px`, maxWidth: `${tableFixedWidth}px` };
  const returnCurrencyLabelColor = (value) => {
    return { 
      color: value !== 0 ? Constants.red : Constants.black,
      fontWeight: value !== 0 ? Constants.boldFontWeight : Constants.normalFontWeight
    }
  }

  //Table Content UI
  const SalesVarianceColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
    },
    {
      dataField: "totalBranchSales",
      text: intl.formatMessage({ id: "TotalBranchSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalBranchSales)}</div>
      )
    },
    {
      dataField: "totalPaymentReceived",
      text: intl.formatMessage({ id: "TotalPaymentReceived" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPaymentReceived)}</div>
      )
    },
    {
      dataField: "totalSalesVariance",
      text: intl.formatMessage({ id: "TotalSalesVariance" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalSalesVariance)}}>
          {returnPriceWithCurrency(row.totalSalesVariance)}
        </div>
      )
    },
    {
      dataField: "totalEarnedSales",
      text: intl.formatMessage({ id: "TotalEarnedSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalEarnedSales)}}>
          {returnPriceWithCurrency(row.totalEarnedSales)}
        </div>
      )
    },
    {
      dataField: "totalSharedSales",
      text: intl.formatMessage({ id: "TotalSharedSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalSharedSales)}}>
          {returnPriceWithCurrency(row.totalSharedSales)}
        </div>
      )
    },
    {
      dataField: "totalManualSalesAdjustment",
      text: intl.formatMessage({ id: "TotalManualSalesAdjustment" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalManualSalesAdjustment)}}>
          {returnPriceWithCurrency(row.totalManualSalesAdjustment)}
        </div>
      )
    },
    {
      dataField: "totalRefundSales",
      text: intl.formatMessage({ id: "TotalRefundSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalRefundSales)}}>
          {returnPriceWithCurrency(row.totalRefundSales)}
        </div>
      )
    },
    {
      dataField: "totalBackDateSales",
      text: intl.formatMessage({ id: "BackdateSales" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalBackDateSales)}}>
          {returnPriceWithCurrency(row.totalBackDateSales)}
        </div>
      )
    },
    {
      dataField: "totalBackDatePayment",
      text: intl.formatMessage({ id: "BackdatePayment" }).toUpperCase(),
      headerStyle: returnHeaderStyle,
      formatter: (cellContent, row) => (
        <div style={{...returnCurrencyLabelColor(row.totalBackDatePayment)}}>
          {returnPriceWithCurrency(row.totalBackDatePayment)}
        </div>
      )
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesVariance" }) })}
        title={intl.formatMessage({ id: "SalesVariance" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportSalesVarianceApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-Variance.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  className={""}
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  setFieldValue={setSelectedBranchId}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
            </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  options={monthList}
                  initialLabel={selectedMonth}
                  initialValue={selectedMonthValue}
                  setFieldLabel={setSelectedMonth}
                  setFieldValue={setSelectedMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                {
                  localLoadingDateRange
                  ?
                  <Loading/>
                  :
                  <GeneralDateRange
                    title={intl.formatMessage({ id: "DateRange" })}
                    name={"DateRange"}
                    disabled={localLoading || loading}
                    initialValue={selectedDateRange}
                    minMaxDateObj={dateRangeLimit}
                    validationRequired={true}
                    onChangeFunction={onSelectDateRange}/>
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => {
                    fetchSalesVarianceListApi(true)
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading || loading
          ?
          <Loading />
          :
          <div className="standard-layout">
            <PaginationTableWithoutApi 
              title={intl.formatMessage({ id: "SalesVariance" })}
              headerClassName={"table-light"}
              options={salesVarianceList}
              columns={SalesVarianceColumns}
              pageSize={Constants.maxPageSize}
              keyField={"branchId"}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesVariance);
