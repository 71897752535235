import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { VirtualWarehouseStockMovementListObject } from '../../app/models/virtualWarehouseStockMovement';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnRouteFromModule } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { Link } from "react-router-dom"
import { RoutesList } from "../../app/constants/RoutesList";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { PaginationRequestBody } from "../../app/models/pagination";

const VirtualWarehouseStockMovement = () => {
  const intl = useIntl();
  //Use Store
  const { virtualWarehouseStockMovementStore, branchStore, productStore, staticReferenceStore, commonStore } = useStore();
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { loading, setLoading, totalItem, setErrorMessage, setSuccessMessage } = commonStore;
  const { virtualWarehouseStockMovementList, getVirtualWarehouseStockMovement  } = virtualWarehouseStockMovementStore;
  const { virtualWarehouseMovementType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [productListFinal, setProductListFinal] = useState<ObjectForDropdown[]>([]);
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [selectedProductName, setSelectedProductName] = useState(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedVirtualWarehouseMovementType, setSelectedVirtualWarehouseMovementType] = useState<number | string>(AllType);
  const [selectedVirtualWarehouseMovementTypeId, setSelectedVirtualWarehouseMovementTypeId] = useState<number | string | undefined>(" ");
  const [virtualWarehouseMovementTypeList, setVirtualWarehouseMovementTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const blnHQStaff = !getBranchUser();
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      virtualWarehouseNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let virtualWarehouseStockMovementRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId == AllBranch ? "" : selectedBranchId,
    productId: selectedProductId == AllProduct ? "" : selectedProductId,
    movementType: selectedVirtualWarehouseMovementTypeId,
    virtualWarehouseNo: validation.values.virtualWarehouseNo,
    dateRange: selectedDateRange
  }

  useEffect(() => {
    async function fetchAppointmentAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getProductDropdown(),
        getVirtualWarehouseStockMovement(virtualWarehouseStockMovementRequestBody),
        getStaticReferenceWithType(Constants.virtualWarehouseMovementType, AllType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setProductListFinal(arraySpliceInAllValue(resultAPI[1], AllProduct, false, "name", "id"));
      setVirtualWarehouseMovementTypeList(resultAPI[3]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse], true)) {
      return;
    }

    fetchAppointmentAPI();
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchVirtualWarehouseStockMovementAPI(true);
    }
  }, [selectedBranchId, selectedProductId, selectedDateRange, selectedVirtualWarehouseMovementTypeId, triggerSearch])

  async function fetchVirtualWarehouseStockMovementAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getVirtualWarehouseStockMovement(virtualWarehouseStockMovementRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const VirtualWarehouseStockMovementColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"150px"}}>{row.productName || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.actionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.actionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "moveFrom",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
    },
    {
      dataField: "moveTo",
      text: intl.formatMessage({ id: "To" }).toUpperCase(),
      formatter: (cellContent, row) => (
          <div className="">
            {row.moveTo || Constants.emptyData}
          </div>
      ),
    },
    {
      dataField: "movementType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{newSpaceBeforeCapitalLetter(row.movementType)}</div>
      ),
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.quantity)}
            statusBackgroundColor={Math.sign(row.quantity) == -1 ? Constants.red : Constants.lightgreen} />
        </>
      ),
    },
    {
      dataField: "reservedQuantity",
      text: intl.formatMessage({ id: "ReservedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.reservedQuantity)}
            statusBackgroundColor={Math.sign(row.reservedQuantity) == -1 ? Constants.peach : Constants.tabBlue} />
        </>
      ),
    },
    {
      dataField: "virtualWarehouseNo",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomerVirtualWarehouse
        ?
          <div>
            <Link className="d-block text-primary mb-2 h6"
              to={`/${RoutesList.customerVirtualWarehouse}/view/${row.virtualWarehouseId}`}
              target={Constants.blank}
              rel="noopener noreferrer">
              {row.virtualWarehouseNo}
            </Link>
          </div>
        :
          <div> {row.virtualWarehouseNo || Constants.emptyData} </div>
      ),
    },
    {
      dataField: "actionUserName",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
    },   
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{width:"400px"}}>{row.action || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {row.remark || Constants.emptyData}
        </>,
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ReservedProductMovement" }) })}
        title={intl.formatMessage({ id: "ReservedProductMovement" })}>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || !blnHQStaff}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Product" })}
              name="productName"
              labelField={"name"}
              valueField={"id"}
              options={productListFinal}
              initialLabel={selectedProductName}
              initialValue={selectedProductId}
              setFieldLabel={setSelectedProductName}
              setFieldValue={setSelectedProductId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "MovementType" })}
              name="virtualWarehouseMovementType"
              options={virtualWarehouseMovementTypeList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedVirtualWarehouseMovementType}
              initialValue={selectedVirtualWarehouseMovementTypeId}
              setFieldLabel={setSelectedVirtualWarehouseMovementType}
              setFieldValue={setSelectedVirtualWarehouseMovementTypeId}
              disabled={localLoading || loading}
              validationRequired={true}
              onChange={() => {
                setCurrentPage(1);
                setErrorMessage("");
                setSuccessMessage("");
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={`${intl.formatMessage({ id: "ReferenceNo" })} (${intl.formatMessage({ id: "VirtualWarehouseNo" })})`}
              name="virtualWarehouseNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterReferenceNo" })}
              onBlurFunction={() => {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange} />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={VirtualWarehouseStockMovementColumns}
              data={virtualWarehouseStockMovementList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ReservedProductMovement" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              productId={selectedProductId}
              movementType={selectedVirtualWarehouseMovementTypeId}
              dateRange={selectedDateRange}
              virtualWarehouseNo={validation.values.virtualWarehouseNo}
              requestAPI={getVirtualWarehouseStockMovement} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(VirtualWarehouseStockMovement);
