import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, newSpaceBeforeCapitalLetter, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue, returnSubBrandList, getCurrentMonthRange, returnPriceWithCurrency, returnMonthRangeByYear, getCurrentMonthRangeForSingleYear, returnArrayTypeOfMonthRangeByYear } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeMonthDetailInterface, DateRangeMonthInterface, DateRangeMonthMinMaxInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { SubBrandObject } from "../../../app/models/brand";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import GeneralMonthRangeWithinSingleYear from "../../../app/components/form/GeneralMonthRangeWithinSingleYear";
import ReportExpandRow from "../subView/ReportExpandRow";

const BranchMonthlySalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, windowSize } = commonStore;
  const { branchMonthlySalesSummaryList, getBranchMonthlySalesSummary, setBranchMonthlySalesSummaryList, exportBranchMonthlySalesSummary } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const monthRangeLimit : DateRangeMonthMinMaxInterface | any = returnMonthRangeByYear(selectedYear, true);
  const [selectedMonthRange, setSelectedMonthRange] = useState<DateRangeMonthDetailInterface[]>(getCurrentMonthRangeForSingleYear());
  const selectedDateRange =  { 
    fromDate: moment(`${selectedMonthRange[0].year}-${selectedMonthRange[0].month}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat), 
    toDate: moment(`${selectedMonthRange[selectedMonthRange.length-1].year}-${selectedMonthRange[selectedMonthRange.length-1].month}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  useEffect(() => {
    setYearList(getYears());

    async function fetchBranchMonthlySales() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchBranchMonthlySales();

    return (() => {
      setBranchMonthlySalesSummaryList([]);
    })
  }, [])

  useEffect(()=> {
    if (!initialCallAPI) {
      setSelectedMonthRange(returnArrayTypeOfMonthRangeByYear(selectedYear))
      setLocalLoadingDateRange(true);
      setTimeout(()=> {
        setLocalLoadingDateRange(false);
      }, 100)
    }
  }, [selectedYear])

  const onSelectMonthRange = (objDateRange: any) => {
    setSelectedMonthRange(objDateRange);
  }

  const fetchBranchMonthlySalesWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportBranchMonthlySalesSummary({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    } else {
      await getBranchMonthlySalesSummary({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
  };

  // //Table Content UI
  const BranchMonthlySalesColumns = [
    { 
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    }
  ];

  const aryBranchMonthlySalesSummaryDetail : ObjectForReportRow[] = [
    {label: "Month", value: "monthName", type: "string"}, 
    {label: "SubBrand", value: "subBrandName", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: false }},
    {label: "BranchSales", value: "totalSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }}, 
    {label: "PaymentReceived", value: "totalPaymentReceived", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "paymentRouteUrl" }}, 
    {label: "SalesVariance", value: "totalSalesVariance", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "salesVarianceRouteUrl" }},
    {label: "GroupSales", value: "groupSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }}, 
    {label: "ProductSales", value: "productSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }},
  ];

  const aryBranchMonthlySalesSummaryDetailWithoutSubBrand : ObjectForReportRow[] = [
    {label: "Month", value: "monthName", type: "string"}, 
    {label: "BranchSales", value: "totalSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }}, 
    {label: "PaymentReceived", value: "totalPaymentReceived", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "paymentRouteUrl" }}, 
    {label: "SalesVariance", value: "totalSalesVariance", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true, hyperLinkPathFieldName: "salesVarianceRouteUrl" }},
    {label: "GroupSales", value: "groupSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }}, 
    {label: "ProductSales", value: "productSales", type: "dynamic-table-cell-ui", tableCellObject: { rowFieldName: "details", blnPercentage: false, blnCurrency: true }}
  ];
  
  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "MonthDetails" })}
        rowFieldName={"monthDetails"}
        rowColumns={returnSubBrandList().length > 0 ?  aryBranchMonthlySalesSummaryDetail : aryBranchMonthlySalesSummaryDetailWithoutSubBrand}
        keyFieldName={"monthName"}
        pageSize={Constants.maxPageSize}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "BranchMonthlySalesSummary" }) })}
        title={intl.formatMessage({ id: "BranchMonthlySalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchBranchMonthlySalesWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Branch-Monthly-Sales-Summary.aspx`}>
        <Row>
          {
              subBrandListFinal.length > 0
              &&
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={"3"}>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl={"3"}>
            {
              localLoadingDateRange
              ?
              <Loading/>
              :
              <GeneralMonthRangeWithinSingleYear
                title={intl.formatMessage({ id: "MonthRange" })}
                name={"MonthRange"}
                disabled={localLoading || loading}
                initialValue={selectedMonthRange}
                minMaxMonthObj={monthRangeLimit}
                onChangeFunction={onSelectMonthRange}/>
            }
          </Col>
          <Col xl={"3"}>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchBranchMonthlySalesWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading />
          :
          <div className="standard-layout">
            <PaginationTableWithoutApi 
              title={intl.formatMessage({ id: "BranchMonthlySalesSummary" })}
              headerClassName={"table-light"}
              options={branchMonthlySalesSummaryList}
              columns={BranchMonthlySalesColumns}
              pageSize={Constants.maxPageSize}
              expandRow={expandRow}
              keyField={"branchName"}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(BranchMonthlySalesSummary);
