import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, purchasedItemStatusColor, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForReportRow } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import ReportExpandRow from "../subView/ReportExpandRow";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";

const ReservedProductSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportStockStore, branchStore, categoryStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { reservedProductSummaryList, getReservedProductSummaryList, setReservedProductSummaryList, exportReservedProductSummary } = reportStockStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { categoryDropdownList, getCategoryDropdown } = categoryStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportInventoryReport = checkPermission([PermissionConstants.ExportInventoryReport]);

  const aryReservedProductSummaryDetail: ObjectForReportRow[] = [
    {label: "Branch", value: "branchName", type: "string"}, 
    {label: "PhysicalQuantity", value: "actualQuantity", type: "string"}, 
    { label: "ReservedQuantity", value: "reservedQuantity", type: "string" },
    { label: "NoCostQuantity", value: "noCostQuantity", type: "string" },
  ];

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      sKU: "",
      categoryIds: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchReservedProductSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getCategoryDropdown(),
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageInventoryReport], true)) {
      return;
    }

    fetchReservedProductSummary();

    return (() => {
      setReservedProductSummaryList([]);
    })
  }, [])

  const fetchReservedProductSummaryWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportReservedProductSummary({ branchId: selectedBranchId, name: validation.values.name, SKU: validation.values.sKU, categoryIds: validation.values.categoryIds });
    }
    else{
      await getReservedProductSummaryList({ branchId: selectedBranchId, name: encodeURIComponent(validation.values.name), SKU: encodeURIComponent(validation.values.sKU), categoryIds: validation.values.categoryIds });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const ReservedProductSummaryColumns = [
    {
      dataField: "productId", //field name from array to display
      text: "productId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.productName}</div>
      ),
    },
    {
      dataField: "sku",
      text: intl.formatMessage({ id: "SKU" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.sku || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "totalActualQuantity",
      text: intl.formatMessage({ id: "TotalPhysicalQuantity" }).toUpperCase(),
    },
    {
      dataField: "totalReservedQuantity",
      text: intl.formatMessage({ id: "TotalReservedQuantity" }).toUpperCase(),
    },
    {
      dataField: "totalNoCostQuantity",
      text: intl.formatMessage({ id: "TotalNoCostQuantity" }).toUpperCase(),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ReservedProductSummaryDetails" })}
        rowFieldName={"reservedProductSummaryDetails"}
        rowColumns={aryReservedProductSummaryDetail}
        keyFieldName={"branchName"}
        statusColorFunction={purchasedItemStatusColor}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ReservedProductSummary" }) })}
        title={intl.formatMessage({ id: "ReservedProductSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportInventoryReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchReservedProductSummaryWithLoading(true)
            }}
          />
        }>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "Name" })}
                name="name"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterProductName" })}
              />
            </Col>
            <Col xl="3">
              <GeneralInput
                title={intl.formatMessage({ id: "SKU" })}
                name="sKU"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSKU" })}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="3">
              <DropDownWithTitleMultiSelect
                name="categoryIds"
                title={intl.formatMessage({ id: "Category" })}
                specifyReturnFieldName={[{ "field": "categoryIds", "value": "id" }]}
                returnFieldWithLabel={false}
                labelField={"name"}
                valueField={"id"}
                options={categoryDropdownList}
                disabled={localLoading || loading}
                validationRequired={false}
                validation={validation}
              />
            </Col>
            <Col xl="2">
              <MyButton
                type="button"
                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchReservedProductSummaryWithLoading(false)
                }}
              />
            </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={ReservedProductSummaryColumns}
                data={reservedProductSummaryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ReservedProductSummary" }) })}
                objSorted={defaultSorted}
                expandRow={expandRow}
                keyField={"productId"}
                requestAPI={getReservedProductSummaryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ReservedProductSummary);
