import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { BranchCommissionListObject } from '../../app/models/branchCommission';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { arraySpliceInAllValue, branchCommissionStatusColor, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, getMonths, getYears, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import GenerateBranchCommissionModal from "./Modal/GenerateBranchCommissionModal";

const BranchCommission = () => {
  const intl = useIntl();
  //Use Store
  const { branchCommissionStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { branchCommissionList, branchCommissionPaginationParams, getBranchCommission, deleteBranchCommission, setBranchCommissionPaginationParams, addBranchCommission } = branchCommissionStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  //Data List
  const [selectedRow, setSelectedRow] = useState<BranchCommissionListObject | undefined>(undefined);
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>();
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionManageBranchCommission = checkPermission([PermissionConstants.ManageBranchCommission]);
  const blnPermissionCreateBranchCommission = checkPermission([PermissionConstants.CreateBranchCommission]);
  const blnPermissionUpdateBranchCommission = checkPermission([PermissionConstants.UpdateBranchCommission]);
  const blnPermissionReviewBranchCommissionFirstApproval = checkPermission([PermissionConstants.ReviewBranchCommissionFirstApproval]);
  const blnPermissionReviewBranchCommissionSecondApproval = checkPermission([PermissionConstants.ReviewBranchCommissionSecondApproval]);
  const blnPermissionDeleteBranchCommission = checkPermission([PermissionConstants.DeleteBranchCommission]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [branchCommissionModal, setBranchCommissionModal] = useState(false);

  let branchCommissionRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    year: selectedYear,
    selectedMonthDisplay: selectedMonth,
    month: selectedMonthValue,
    branchId: selectedBranchId,
    branchName: selectedBranchName
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchBranchCommissionListAPI() {
      setLoading(true)

      let blnSetDate = false;
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.branchCommission)
      if (blnBackNavigation) {
        if (branchCommissionPaginationParams) {
          setCurrentPage(branchCommissionPaginationParams.pageNumber)
          setSelectedMonth(branchCommissionPaginationParams.selectedMonthDisplay || moment().format("MMM"))
          setSelectedMonthValue(branchCommissionPaginationParams.month || Number(moment().format("M")))
          setSelectedYear(branchCommissionPaginationParams.year || Number(moment().format('yyyy')))
          setSelectedBranchId(branchCommissionPaginationParams.branchId || " ")
          setSelectedBranchName(branchCommissionPaginationParams.branchName || AllBranch)
          branchCommissionRequestBody = branchCommissionPaginationParams;
          blnSetDate = true;
        }
      }
      else {
        setBranchCommissionPaginationParams(undefined);
      }

      if (!blnSetDate) {
        setSelectedMonth(moment().format("MMM"))
        setSelectedMonthValue(Number(moment().format("M")))
        setSelectedYear(Number(moment().format('yyyy')))
      }

      setShowDropdown(true);

      let aryAPI: any = [
        getBranchDropDown()
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], AllBranch, true))
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageBranchCommission], true)) {
      return;
    }

    fetchBranchCommissionListAPI();
    setMonthList(getMonths());
    setYearList(getYears());

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.branchCommission)
        if (!blnSetParams) {
          setBranchCommissionPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedYear && selectedMonthValue) {
      fetchBranchCommissionAPI(true);
    }
  }, [selectedYear, selectedMonthValue])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchBranchCommissionAPI(true);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setBranchCommissionModal(false);
        fetchBranchCommissionAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchBranchCommissionAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getBranchCommission(branchCommissionRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: BranchCommissionListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const BranchCommissionColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.documentNo || Constants.emptyData}
        </>
      )
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "year",
      text: intl.formatMessage({ id: "Year" }).toUpperCase(),
    },
    {
      dataField: "month",
      text: intl.formatMessage({ id: "Month" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {moment(row.month, "MM").format("MMM")}
        </>
      )
    },
    {
      dataField: "totalBranchPackageSales",
      text: intl.formatMessage({ id: "TotalBrPackageSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalBranchPackageSales)}</div>
      ),
    },
    {
      dataField: "totalBranchProductSales",
      text: intl.formatMessage({ id: "TotalBrProductSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalBranchProductSales)}</div>
      ),
    },
    {
      dataField: "totalBranchSales",
      text: intl.formatMessage({ id: "TotalBrSales" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalBranchSales)}</div>
      ),
    },
    // {
    //   dataField: "totalBranchSalesAdjustment",
    //   text: intl.formatMessage({ id: "TotalBrSalesAdjustment" }).toUpperCase(),
    //   formatter: (cellContent, row) => (
    //     <div>{returnPriceWithCurrency(row.totalBranchSalesAdjustment)}</div>
    //   ),
    // },
    {
      dataField: "totalAngPaoBonus",
      text: intl.formatMessage({ id: "TotalAngPaoBonus" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalAngPaoBonus)}</div>
      ),
    },
    {
      dataField: "totalPackageCommission",
      text: intl.formatMessage({ id: "TotalPackageComm" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPackageCommission)}</div>
      ),
    },
    {
      dataField: "totalProductCommission",
      text: intl.formatMessage({ id: "TotalProductComm" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalProductCommission)}</div>
      ),
    },
    {
      dataField: "totalCommission",
      text: intl.formatMessage({ id: "TotalComm" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalCommission)}</div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={branchCommissionStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.branchCommission}/view/${row.id}`}
              type={'view'} />
            {
              (blnPermissionUpdateBranchCommission || blnPermissionReviewBranchCommissionFirstApproval || blnPermissionReviewBranchCommissionSecondApproval)
              &&
              row.status !== Constants.final
              &&
              <TableActionButton
                to={`/${RoutesList.branchCommission}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteBranchCommission
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "BranchCommission" }) })}
        title={intl.formatMessage({ id: "BranchCommission" })}
        addButton={
          blnPermissionCreateBranchCommission
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "GenerateWithModuleName" }, { moduleName: intl.formatMessage({ id: "BranchCommission" }) })}
            onClick={() => setBranchCommissionModal(true)}
            disable={localLoading || loading || Boolean(successMessage)}
            loading={loading} />
        }
        addButtonXL={"4"}>
        {
          showDropdown
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading || Boolean(successMessage)}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading || Boolean(successMessage)}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || Boolean(successMessage)}
                validationRequired={true}
                onChange={(value) => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={BranchCommissionColumns}
              data={branchCommissionList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "BranchCommission" }) })}
              objSorted={defaultSorted}
              selectedYear={selectedYear}
              selectedMonth={selectedMonthValue}
              selectedMonthDisplay={selectedMonth}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              requestAPI={getBranchCommission} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteBranchCommission(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (branchCommissionList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getBranchCommission({ pageNumber: currentPageTemp, pageSize: pageSize, year: selectedYear, month: selectedMonthValue })
            setLoading(false);
          }} />
        {
          branchCommissionModal &&
          <GenerateBranchCommissionModal
            blnShow={branchCommissionModal}
            setModal={setBranchCommissionModal}
            optionYearList={yearList}
            optionMonthList={monthList}
            addBranchCommission={addBranchCommission}
            setErrorMessage={setErrorMessage} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(BranchCommission);
