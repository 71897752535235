import { useState } from "react";
import { Input, Modal } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import { VerifyRefundNoteListObject } from "../../../app/models/refundNote";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  showPrompt: boolean;
  setShowPrompt: Function;
  refundNoteId: string;
  verifyRefundNoteList: VerifyRefundNoteListObject[];
  addRefundNoteWebFormNotification: Function;
}

export default observer(function VerifyRefundNoteModal(props: Props) {
  const { commonStore } = useStore();
  const { successMessageWithoutNavigation, setErrorMessage } = commonStore;
  const [localLoading, setLocalLoading] = useState(false);
  const disabledFieldInput = localLoading || Boolean(successMessageWithoutNavigation);
  const [optionType, setOptionType] = useState<number | undefined>(undefined);
  const [optionValue, setOptionValue] = useState<string | undefined>(undefined);
  const intl = useIntl();

  const hideModal = () => {
    props.setShowPrompt(false);
  }

	return (
		<Modal
      isOpen={props.showPrompt}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size="md"
      centered>
      {
        <div style={{marginLeft: "40px", marginRight: "40px", marginTop: "22.5px", marginBottom: "22.5px"}}>
          <div>
            <h4 style={{whiteSpace:"pre-wrap"}} className="text-center">{intl.formatMessage({ id: "RefundNoteApprovedMessage" })}</h4>
          </div>
          {
            props.verifyRefundNoteList.map((valueVerifyRefundNote, indexVerifyRefundNote)=> (
              <div 
                key={`radio_${indexVerifyRefundNote}`} 
                className={indexVerifyRefundNote === 0 ? "mt-4" : props.verifyRefundNoteList.length-1 === indexVerifyRefundNote ? "mb-3" : ""}>
                <Input
                  type="radio"
                  name="optionsServicesTreatment"
                  checked={valueVerifyRefundNote.type === optionType}
                  onChange={() => { 
                    setOptionType(valueVerifyRefundNote.type);
                    setOptionValue(valueVerifyRefundNote.additionalReference);
                  }}
                  disabled={disabledFieldInput}
                />
                &nbsp;
                <label className="font-size-15 margin-left-4">{`${valueVerifyRefundNote.name} (${valueVerifyRefundNote.additionalReference})`}</label>
              </div>
            ))
          }
          {
            !Boolean(successMessageWithoutNavigation)
            &&
            <div className="text-center">
              <MyButton
                type="button"
                class={`btn btn-success`}
                style={{ width: '20%' }}
                content={intl.formatMessage({ id: "Send" })}
                onClick={async () => {
                  if (optionType === undefined) {
                    setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Type" })}))
                    return;
                  }
                  setLocalLoading(true);
                  let resultAddRefundNoteWebFormNotification = await props.addRefundNoteWebFormNotification({id: props.refundNoteId, type: optionType, valueToContact: optionValue});
                  if (resultAddRefundNoteWebFormNotification.status === Constants.success) {
                    props.setShowPrompt(false);
                  }
                  setLocalLoading(false);
                }}
                disable={disabledFieldInput}
                loading={localLoading}
              />
            </div>
          }
        </div>
      }
    </Modal>
	);
})
