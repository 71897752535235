import { PaginationRequestBody } from "../models/pagination";
import { PurchaseOrderAddObject, PurchaseOrderDetailObject, PurchaseOrderListObject, PurchaseOrderUpdateObject, PurchaseOrderDropdownRequestBody, PurchaseOrderWarehouseDetailsObject, PurchaseOrderWarehouseRequestBody } from "../models/purchaseOrder";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class PurchaseOrderStore {
  purchaseOrderList: PurchaseOrderListObject[] = [];
  purchaseOrderDropDownList: PurchaseOrderListObject[] = [];
  purchaseOrderDetail: PurchaseOrderDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.purchaseOrderList = [];
    this.purchaseOrderDropDownList = [];
    this.purchaseOrderDetail = undefined;
  }
  
  setPurchaseOrderDetail = (purchaseOrderDetail: PurchaseOrderDetailObject | undefined) => {
    this.purchaseOrderDetail = purchaseOrderDetail;
  }

  getPurchaseOrder = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultPurchaseOrders = await agent.PurchaseOrder.purchaseOrderList(PaginationRequestBody);
      runInAction(() => {
        this.purchaseOrderList = resultPurchaseOrders.data;
        store.commonStore.setTotalItem(resultPurchaseOrders.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchaseOrderList = [];
    }
  };

  getPurchaseOrderDropdown = async (PurchaseOrderDropdownRequestBody: PurchaseOrderDropdownRequestBody) => {
    try {
      const resultPurchaseOrdersDropdown = await agent.PurchaseOrder.purchaseOrderDropdown(PurchaseOrderDropdownRequestBody);
      runInAction(() => {
        this.purchaseOrderDropDownList = resultPurchaseOrdersDropdown;
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchaseOrderDropDownList = [];
    }
  };


  getPurchaseOrderWithId = async (id: string) => {
    try{
      const resultPurchaseOrderDetail = await agent.PurchaseOrder.purchaseOrderDetail(id);
      runInAction(() => {
        this.purchaseOrderDetail = resultPurchaseOrderDetail;
      });

      return Promise.resolve(resultPurchaseOrderDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchaseOrderDetail = undefined;
    }
  }

  addPurchaseOrder = async (purchaseOrderRequestBody: PurchaseOrderAddObject) => {
    try{
      await agent.PurchaseOrder.addPurchaseOrder(purchaseOrderRequestBody);
      store.commonStore.setSuccessMessage(`PurchaseOrderAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePurchaseOrder = async (purchaseOrderRequestBody: PurchaseOrderUpdateObject) => {
    try{
      await agent.PurchaseOrder.updatePurchaseOrder(purchaseOrderRequestBody);
      store.commonStore.setSuccessMessage(`PurchaseOrderUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePurchaseOrder  = async (id: string, name: string) => {
    try {
      await agent.PurchaseOrder.deletePurchaseOrder(id);
      store.commonStore.setSuccessMessage(`PurchaseOrderDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPurchaseOrderWarehouseList = async (purchaseOrderWarehouseRequestBody: PurchaseOrderWarehouseRequestBody) => {
    try{
      const resultPurchaseOrderWarehouseList  = await agent.PurchaseOrder.purchaseOrderWarehouseList(purchaseOrderWarehouseRequestBody);

      return Promise.resolve(resultPurchaseOrderWarehouseList);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
