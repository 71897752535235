import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { IncludesLocationPathName, stockAdjustmentStatusColor, checkPermission } from "../../app/common/function/function";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, Form } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import MyButton from "../../app/components/form/MyButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import Borderline from "../../app/components/form/BorderLine";
import { history } from "../..";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";

const StockAdjustmentInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockAdjustmentStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;
  const { stockAdjustmentDetail, setStockAdjustmentDetail, getStockAdjustmentWithId, updateStockAdjustment } = stockAdjustmentStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle =  `${addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
} ${intl.formatMessage({id: "StockAdjustment"})}`;
  const breadCrumbList = [{ title: intl.formatMessage({id: "StockAdjustment"}), urlPath: RoutesList.stockAdjustment }];
  const [recreateStockAdjustmentModal, setRecreateStockAdjustmentModal] = useState(false);
  const invoiceFullDetail = stockAdjustmentDetail || {
    id: "",
    stockAdjustmentNo: "",
    stockAdjustDate: "",
    branchId: "",
    branchName: "",
    note: "",
    status: "",
    approverId: "",
    approverName: "",
    approvedDate: "",
    reviewRemark: "",
    stockAdjustmentDetails: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockAdjustmentDetail || {
      id: "",
      stockAdjustmentNo: "",
      stockAdjustDate: "",
      branchId: "",
      branchName: "",
      note: "",
      status: "",
      approverId: "",
      approverName: "",
      approvedDate: "",
      reviewRemark: "",
      stockAdjustmentDetails: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (invoiceFullDetail.status == Constants.processing) {
          await updateStockAdjustment(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const blnPermissionCreateStockAdjustment = checkPermission([PermissionConstants.CreateStockAdjustment]);
  const blnPermissionReviewStockAdjustment = checkPermission([PermissionConstants.ReviewStockAdjustment]);
  const blnProcessingStatus = invoiceFullDetail.status == Constants.processing;
  const blnRejectedStatus = invoiceFullDetail.status == Constants.rejected;
  const blnCompletedStatus = invoiceFullDetail.status == Constants.completed;
  const blnPendingStatus = invoiceFullDetail.status == Constants.pending;

  async function fetchStockAdjustmentDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getStockAdjustmentWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateStockAdjustment, PermissionConstants.ReviewStockAdjustment], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateStockAdjustment], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageStockAdjustment], true)) {
        return;
      }
    }
    
    fetchStockAdjustmentDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockAdjustmentDetail(undefined);
      }
    })
  }, []);

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
       <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnShowPrint={true}>
          {loading ? (
            <Loading />
          ) : (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: '16px' }}>
                              <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "StockAdjustment" })}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({id: "StockAdjustmentNo"})}:`, invoiceFullDetail.stockAdjustmentNo)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "BranchName"})}:`, invoiceFullDetail.branchName)}
                          {
                            !blnProcessingStatus && !blnRejectedStatus
                            &&
                            <>
                              {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedName"})}:`, invoiceFullDetail.approverName)}
                              {displayHeaderRowData(`${intl.formatMessage({id: "ApprovedDate"})}:`, moment(invoiceFullDetail.approvedDate).format(Constants.displayDateFormat))}
                            </>
                          }
                        </Col>
                      </div>
                      <Borderline />
                      <address>
                        <strong>{intl.formatMessage({id: "Status"})}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.status}
                            statusColorFunction={stockAdjustmentStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "Product(s)"})}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({id: "Name"})}</th>
                              {/* <th className="text-center">{intl.formatMessage({id: "SupplierReferenceNo"})}</th> */}
                              {/* <th className="text-center">{intl.formatMessage({id: "CostPrice"})}</th> */}
                              <th className="text-center">{intl.formatMessage({id: "AdjustQuantity"})}</th>
                              {/* <th className="text-center">{intl.formatMessage({id: "ExpiryDate"})}</th> */}
                              <th className="text">{intl.formatMessage({id: "Remarks"})}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.stockAdjustmentDetails.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{`${item.productName}`}</td>
                                    {/* <td className="text-center">{item.supplierReferenceNo}</td> */}
                                    {/* <td className="text-center">{returnPriceWithCurrency(item.costPrice)}</td> */}
                                    <td className="text-center">{item.adjustQuantity}</td>
                                    {/* <td className="text-center">{moment(item.expiryDate).format(Constants.displayDateFormat)}</td> */}
                                    <td className="">{item.remark || Constants.emptyData}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={`py-2 mt-3`}>
                        <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({id: "Note"})} :
                        </h3>
                      </div>
                      <GeneralTextAreaForModal
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={true}
                        field={invoiceFullDetail}
                        setFieldValue={() => { }} />
                      {
                        !blnPendingStatus
                        &&
                        <>
                          <div className={`py-2 mt-3`}>
                            <h3 className="font-size-15 font-weight-bold">
                            {intl.formatMessage({id: "ReviewRemark"})} :
                            </h3>
                          </div>
                          <GeneralTextArea
                            title=""
                            name="reviewRemark"
                            className="margin-top-0"
                            row={5}
                            disabled={validation.isSubmitting || Boolean(successMessage) || !blnProcessingStatus}
                            validation={validation}/>
                        </>
                      }
                      <div className="margin-top-24" />
                      <div className="d-print-none flex-direction-row">
                        <>
                          {
                            blnPermissionCreateStockAdjustment &&
                            blnRejectedStatus &&
                              <MyButton
                                type="button"
                                class="btn btn-warning margin-right-8"
                                style={{ width: '300px' }}
                                content={intl.formatMessage({ id: "RecreateStockAdjustment" }).toUpperCase()}
                                onClick={async () => { setRecreateStockAdjustmentModal(true) }}
                              />
                          }
                          <GeneralSubmitAndCancelBtn
                            className="width-100-percentage"
                            successMessage={successMessage}
                            viewAction={false}
                            validation={validation}
                            dirtyDisabled={true}
                            showPrint={true}
                            hidePrimaryButton={blnRejectedStatus || blnCompletedStatus || blnPendingStatus || !blnPermissionReviewStockAdjustment}
                            submitButtonTitle={Constants.approve}
                            onClickFunction={() => {
                              validation.setFieldValue("workflowAction", Constants.approve)
                            }}
                            showExtraRightButton={blnPermissionReviewStockAdjustment}
                            extraRightButtonTitle={intl.formatMessage({id: "Reject"})}
                            extraRightButtonColor="btn-danger"
                            extraRightDirtyDisabled={true}
                            extraRightButtonFunction={() => {
                              validation.setFieldValue("workflowAction", Constants.reject)
                            }}
                            cancelButtonBesidePrint={blnRejectedStatus}
                          />
                        </>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        {
          recreateStockAdjustmentModal
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "RecreateStockAdjustmentAlert" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={recreateStockAdjustmentModal}
            setShowPrompt={setRecreateStockAdjustmentModal}
            onPrimaryClick={() => { 
                history.push({
                pathname: `/${RoutesList.stockAdjustment}/add`,
                state: { detail: { recreateStockAdjustmentId: invoiceFullDetail.id } }
              }); 
            }}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockAdjustmentInvoiceDetail);
