import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, refundNoteStatusColor, returnPriceWithCurrency, validRefundNoteStatusForEdit, validRefundNoteStatusForView } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { history } from "../..";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import MyAddButton from "../../app/components/form/MyAddButton";
import { RefundNoteListObject, VerifyRefundNoteListObject } from "../../app/models/refundNote";
import DeleteModal from "../../app/components/modal/DeleteModal";
import VerifyRefundNoteModal from "./Modal/VerifyRefundNoteModal";

const RefundNote = () => {
  const intl = useIntl();

  //Use Store
  const { refundNoteStore, customerStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setErrorMessage, setSuccessMessage, setShowDeletePrompt } = commonStore;
  const { refundNoteList, refundNotePaginationParams, getRefundNote, getRefundNoteWebFormRequest, deleteRefundNote, addRefundNoteWebFormNotification, setRefundNoteList, setRefundNotePaginationParams } = refundNoteStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedRefundNoteStatus, setSelectedRefundNoteStatus] = useState<string>(AllStatus);
  const [selectedRefundNoteStatusId, setSelectedRefundNoteStatusId] = useState<number | string | undefined>(" ");
  const [refundNoteStatusList, setRefundNoteStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [selectedRow, setSelectedRow] = useState<RefundNoteListObject | undefined>(undefined);
  const [verifyRefundNoteModal, setVerifyRefundNoteModal] = useState(false);
  const [verifyRefundNoteList, setVerifyRefundNoteList] = useState<VerifyRefundNoteListObject[]>([]);

  const blnPermissionManageCreditNote =  checkPermission([PermissionConstants.ManageCreditNote]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionCreateRefundNote = checkPermission([PermissionConstants.CreateRefundNote]);
  const blnPermissionUpdateRefundNote = checkPermission([PermissionConstants.UpdateRefundNote]);
  const blnPermissionDeleteRefundNote = checkPermission([PermissionConstants.DeleteRefundNote]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
      referenceNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  //Default Sorting Type
  const defaultSorted = [];

  //Table Content UI
  const RefundNoteColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
    },
    {
      dataField: "requestDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.requestDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.requestDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "requestBranchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.requestBranchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "references",
      text: intl.formatMessage({ id: "ReferenceNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          row.references.length > 0
          ? 
          <>
            {
              row.references.map((valueReferenceTemp, indexReferenceTemp)=> (
                <div key={`${row.documentNo}_${valueReferenceTemp.creditNoteDocumentNo}_${indexReferenceTemp}`}>
                  {
                    blnPermissionManageCreditNote 
                    ?
                    <Link to={`/${RoutesList.creditNote}/view/${valueReferenceTemp.creditNoteId}`} target={Constants.blank} rel="noopener noreferrer">
                      <Label className="margin-bottom-0">{valueReferenceTemp.creditNoteDocumentNo}</Label>
                    </Link>
                    :
                    valueReferenceTemp.creditNoteDocumentNo
                  }
                </div>
              ))
            }
          </>
          :
          Constants.emptyData
        }
      </>
    },
    {
      dataField: "subTotalRefundAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.subTotalRefundAmount)}</>, //Custom UI to display
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={refundNoteStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              validRefundNoteStatusForView(row.status)
              &&
              <TableActionButton
                to={`/${RoutesList.refundNote}/view/${row.id}`}
                type={'view'} />
            }
            {
              blnPermissionUpdateRefundNote && validRefundNoteStatusForEdit(row.status)
              &&
              <TableActionButton
                to={`/${RoutesList.refundNote}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteRefundNote && row.status === Constants.draft
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
            {
              row.status === Constants.pendingSign
              &&
              <TableActionButton
                type="whatsapp"
                onClick={async () => {
                  setLocalLoading(true);
                  let resultRefundNoteWebFormRequest = await getRefundNoteWebFormRequest(row.id);
                  if (resultRefundNoteWebFormRequest.status === Constants.success) {
                    setSelectedRow(row);
                    setVerifyRefundNoteList(resultRefundNoteWebFormRequest.data);
                    setVerifyRefundNoteModal(true);
                  }
                  setLocalLoading(false);
                }}/>
            }
          </div>
        </>
      ),
    },
  ];

  let refundNoteRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    status: selectedRefundNoteStatusId,
    statusName: selectedRefundNoteStatus,
    documentNo: validation.values.documentNo,
    referenceNo: validation.values.referenceNo
  }

  useEffect(() => {
    async function fetchRefundNoteListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.refundNote)
      if (blnBackNavigation) {
        if (refundNotePaginationParams) {
          setCurrentPage(refundNotePaginationParams.pageNumber);
          setSelectedBranchId(refundNotePaginationParams.branchId!);
          setSelectedBranchName(refundNotePaginationParams.branchName!);
          setSelectedRefundNoteStatusId(refundNotePaginationParams.status)
          setSelectedRefundNoteStatus(refundNotePaginationParams.statusName || AllStatus)
          setSelectedCustomerId(refundNotePaginationParams.customerId || "")
          setSelectedCustomer(refundNotePaginationParams.customerName || "")
          validation.setValues((values) => ({
            ...values,
            documentNo: refundNotePaginationParams.documentNo || "",
            referenceNo: refundNotePaginationParams.referenceNo || "",
          }))
          refundNoteRequestBody = refundNotePaginationParams;
        }
      }
      else {
        setRefundNotePaginationParams(undefined);
      }
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.refundNoteStatus, AllStatus),
        getBranchDropDown(),
        getRefundNote(refundNoteRequestBody)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setRefundNoteStatusList(resultAPI[0]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageRefundNote], true)) {
      return;
    }

    fetchRefundNoteListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.refundNote)
        if (!blnSetParams) {
          setRefundNotePaginationParams(undefined);
        }
      }
      setRefundNoteList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchRefundNoteAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (
      (selectedRefundNoteStatusId || selectedRefundNoteStatusId === 0) && !initialCallAPI
    ) {
      fetchRefundNoteAPI(true);
    }
  }, [selectedRefundNoteStatusId, selectedCustomerId, triggerSearch]);

  async function fetchRefundNoteAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getRefundNote({
      ...refundNoteRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickDelete = async (row: RefundNoteListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "RefundNote" }) })}
        title={intl.formatMessage({ id: "RefundNote" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateRefundNote
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "RefundNote" }) })}
              onClick={() => { history.push(`/${RoutesList.refundNote}/add`) }}
              linkTo={`/${RoutesList.refundNote}/add`}
              disable={loading} />
          </>
        }
        addButtonXL={"4"}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "RefundNote" }) })}
                name="refundNoteStatus"
                options={refundNoteStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedRefundNoteStatus}
                initialValue={selectedRefundNoteStatusId}
                setFieldLabel={setSelectedRefundNoteStatus}
                setFieldValue={setSelectedRefundNoteStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <DoubleColumnRowParent>
              {
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "DocumentNo" })}
                name="documentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "ReferenceNo" })}
                name="referenceNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterReferenceNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
          ? 
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={RefundNoteColumns}
            data={refundNoteList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "RefundNote" }) })}
            objSorted={defaultSorted}
            requestAPI={getRefundNote}
            customerId={selectedCustomerId}
            customerName={selectedCustomer}
            branchId={selectedBranchId}
            branchName={selectedBranchName}
            status={selectedRefundNoteStatusId}
            statusName={selectedRefundNoteStatus}
            documentNo={validation.values.documentNo}
            referenceNo={validation.values.referenceNo} />
        }
      </ListViewLayout>
      <DeleteModal
        displayName={selectedRow?.documentNo}
        onDeleteClick={async () => {
          setLoading(true);
          await deleteRefundNote(selectedRow?.id || "");
          let currentPageTemp = currentPage;
          if (refundNoteList.length === 1 && currentPage !== 1) {
            setCurrentPage((value) => value - 1)
            currentPageTemp = currentPageTemp - 1;
          }
          await getRefundNote({ ...refundNoteRequestBody, pageNumber: currentPageTemp })
          setLoading(false);
        }} />
      {
        verifyRefundNoteModal
        &&
        <VerifyRefundNoteModal
          showPrompt={verifyRefundNoteModal}
          setShowPrompt={setVerifyRefundNoteModal}
          refundNoteId={selectedRow?.id || ""}
          verifyRefundNoteList={verifyRefundNoteList}
          addRefundNoteWebFormNotification={addRefundNoteWebFormNotification}/>
      }
    </div>
  );
}

export default observer(RefundNote);
