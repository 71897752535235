import { useField } from 'formik';
import React, { Fragment } from 'react'
import { Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type? : InputType;
    disabled?: boolean;
}

export default function TextInput(props: Props) {

    const [field, meta] = useField(props.name);

    return (
    
        <div>
            <Label className="form-label">{props.label}</Label>
            <Input {...field} {...props}/>
            {meta.touched && meta.error ? (
                <Label className="text-danger">{meta.error}</Label>
            ) : null}
        </div>
    )
}
