import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import BreadCrumb from '../../app/components/breadCrumb/BreadCrumb';
import AlertMessage from '../../app/components/alert/AlertMessage';
import { SalesCategoryListObject } from '../../app/models/salesCategory';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const SalesCategory = () => {
  const intl = useIntl();
  //Use Store
  const { salesCategoryStore, commonStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { salesCategoryList, salesCategoryPaginationParams, getSalesCategory, deleteSalesCategory, setSalesCategoryPaginationParams } = salesCategoryStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<SalesCategoryListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateSalesCategory = checkPermission([PermissionConstants.CreateSalesCategory]);
  const blnPermissionUpdateSalesCategory = checkPermission([PermissionConstants.UpdateSalesCategory]);
  const blnPermissionDeleteSalesCategory = checkPermission([PermissionConstants.DeleteSalesCategory]);

  let salesCategoryRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesCategoryAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.salesCategory)
      if (blnBackNavigation) {
        if (salesCategoryPaginationParams) {
          setCurrentPage(salesCategoryPaginationParams.pageNumber)
          salesCategoryRequestBody = salesCategoryPaginationParams;
        }
      }
      else {
        setSalesCategoryPaginationParams(undefined);
      }
      await getSalesCategory(salesCategoryRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesCategory], true)) {
      return;
    }

    fetchSalesCategoryAPI();
    
    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.salesCategory)
        if (!blnSetParams) {
          setSalesCategoryPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: SalesCategoryListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const SalesCategoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      style: {
        flex: 1,
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageSalesCategory, PermissionConstants.UpdateSalesCategory, PermissionConstants.DeleteSalesCategory]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.salesCategory}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateSalesCategory
              &&
              <TableActionButton 
                to={`/${RoutesList.salesCategory}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteSalesCategory
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesCategory" }) })}
        title={intl.formatMessage({ id: "SalesCategory" })}
        addButton={
          blnPermissionCreateSalesCategory
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "SalesCategory" }) })}
            onClick={() => { history.push(`/${RoutesList.salesCategory}/add`) }}
            linkTo={`/${RoutesList.salesCategory}/add`}
            disable={loading} />
        }>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={SalesCategoryColumns}
          data={salesCategoryList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesCategory" }) })}
          objSorted={defaultSorted}
          requestAPI={getSalesCategory} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteSalesCategory(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (salesCategoryList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getSalesCategory({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesCategory);
