import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl";
import { useStore } from "../../app/stores/store";
import { PaginationRequestBody } from "../../app/models/pagination";
import { useEffect, useState } from "react";
import { Constants } from "../../app/constants/Constants";
import { checkPermission, comparePathForPagination, comparePathForSetParams, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import { RoutesList } from "../../app/constants/RoutesList";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import TableActionButton from "../../app/components/table/TableActionButton";
import { TncListObject } from "../../app/models/tnc";
import { history } from "../..";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import PaginationTable from "../../app/components/table/PaginationTable";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { Row } from "reactstrap";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import DeleteModal from "../../app/components/modal/DeleteModal";
import Loading from "../../app/components/loading/Loading";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { DateRangeInterface } from "../../app/models/common";

const Tnc = () => {
    const intl = useIntl();

    //Use Store
    const { tncStore, commonStore, staticReferenceStore } = useStore();
    const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setSuccessMessage, setErrorMessage } = commonStore;
    const { tncList, tncPaginationParams, getTnc, updateTnc, deleteTnc, setTncPaginationParams } = tncStore;
    const { getStaticReferenceWithType } = staticReferenceStore;

    const AllType = `${intl.formatMessage({ id: "AllType" })}`;
    const [selectedTncCategoryOptionType, setSelectedTncCategoryOptionType] = useState<string>(AllType);
    const [selectedTncCategoryOptionTypeId, setSelectedTncCategoryOptionTypeId] = useState<number | string | undefined>(" ");
    const [initialCallAPI, setInitialCallAPI] = useState(true);
    const [localLoading, setLocalLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState<TncListObject | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = Constants.defaultPageSize;
    const blnPermissionCreateTnc = checkPermission([PermissionConstants.CreateTnc]);
    const blnPermissionUpdateTnc = checkPermission([PermissionConstants.UpdateTnc]);
    const blnPermissionDeleteTnc = checkPermission([PermissionConstants.DeleteTnc]);
    const [tncCategoryOptionTypeList, setTncCategoryOptionTypeList] = useState<StaticReferenceDataObject[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });

    let tncRequestBody: PaginationRequestBody = {
        pageNumber: currentPage,
        pageSize: pageSize,
        categoryType: selectedTncCategoryOptionTypeId,
        categoryTypeName: selectedTncCategoryOptionType,
        dateRange: selectedDateRange
    }

    //Default Sorting Type
    const defaultSorted = [
    ];

    useEffect(() => {
        async function fetchTncAPI() {
            setLoading(true);
            let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.tnc)
            if (blnBackNavigation) {
                if (tncPaginationParams) {
                    setCurrentPage(tncPaginationParams.pageNumber)
                    setSelectedTncCategoryOptionType(tncPaginationParams.categoryTypeName || "");
                    setSelectedTncCategoryOptionTypeId(tncPaginationParams.categoryType)
                    if (tncPaginationParams.dateRange) {
                        setSelectedDateRange(tncPaginationParams.dateRange)
                    }
                    tncRequestBody = tncPaginationParams;
                }
            }
            else {
                setTncPaginationParams(undefined);
            }
            setLoading(false);

            let aryAPI: any = [
                getTnc(tncRequestBody),
                getStaticReferenceWithType(Constants.tncCategoryType, AllType)
            ];
            let resultAPI = await Promise.all(aryAPI);
            setTncCategoryOptionTypeList(resultAPI[1]);
            setLoading(false);
            setInitialCallAPI(false);
        }

        if (!checkPermission([PermissionConstants.ManageTnc], true)) {
            return;
        }

        fetchTncAPI();

        return (() => {
            if (history) {
                const blnSetParams = comparePathForSetParams(history, RoutesList.tnc)
                if (!blnSetParams) {
                    setTncPaginationParams(undefined);
                }
            }
        })
    }, [])

    useEffect(() => {
        if (
            (selectedTncCategoryOptionTypeId || selectedTncCategoryOptionTypeId === 0) &&
            !initialCallAPI
        ) {
            fetchLatestTncAPI(true);
        }
    }, [selectedTncCategoryOptionTypeId, selectedDateRange]);

    async function fetchLatestTncAPI(blnDropDown?: boolean) {
        blnDropDown ? setLocalLoading(true) : setLoading(true);
        await getTnc(tncRequestBody);
        blnDropDown ? setLocalLoading(false) : setLoading(false);
    }

    const onClickDelete = async (row: TncListObject) => {
        setSelectedRow(row);
        setShowDeletePrompt(true);
    }

    const onSelectDateRange = (objDateRange: any) => {
        setCurrentPage(1);
        setErrorMessage("");
        setSuccessMessage("");
        setSelectedDateRange(objDateRange);
    }

    const extractTextFromHtml = (html: string): string => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        let text = tempDiv.textContent || tempDiv.innerText || '';

        text = text.trim();

        while (text.endsWith('\n')) {
            text = text.slice(0, -1);
        }
        return text;
    };

    //Table Content UI
    const TncColumns = [
        {
            dataField: "id", //field name from array to display
            text: "id", //display name
            sort: true, //sort function enable
            hidden: true, //hide UI
        },
        {
            dataField: "name",
            text: intl.formatMessage({ id: "Name" }).toUpperCase(),
        },
        {
            dataField: "description",
            text: intl.formatMessage({ id: "Description" }).toUpperCase(),
            formatter: (cellContent, row) => {
                const textFormatter = extractTextFromHtml(row.description);
                return textFormatter;
            }
        },
        {
            dataField: "category",
            text: intl.formatMessage({ id: "Category" }).toUpperCase(),
            formatter: (cellcontent, row) => (
                <div>{newSpaceBeforeCapitalLetter(row.category)}</div>
            )
        },
        {
            dataField: "startDate",
            text: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
            formatter: (cellContent, row) => <>{`${moment(row.startDate).format(Constants.displayDateFormat)}`}</>,
        },
        {
            dataField: "endDate",
            text: intl.formatMessage({ id: "EndDate" }).toUpperCase(),
            formatter: (cellContent, row) => {
                if (row.endDate === null) {
                    return <>{Constants.emptyData}</>
                } else {
                    return <>{moment(row.endDate).format(Constants.displayDateFormat)}</>;
                }
            }
        },
        {
            dataField: "action",
            isDummyField: true,
            text: intl.formatMessage({ id: "Action" }).toUpperCase(),
            headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
            hidden: !checkPermission([PermissionConstants.ManageTnc, PermissionConstants.UpdateTnc, PermissionConstants.DeleteTnc]),
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3 flex-justfity-content-center">
                        <TableActionButton
                            to={`/${RoutesList.tnc}/view/${row.id}`}
                            type="view" />
                        {
                            blnPermissionUpdateTnc
                            &&
                            row.isModify
                            &&
                            row.isLatest
                            &&
                            <TableActionButton
                                to={`/${RoutesList.tnc}/edit/${row.id}`}
                                type="edit" />
                        }
                        {
                            blnPermissionDeleteTnc
                            &&
                            row.isModify
                            &&
                            row.isLatest
                            &&
                            <TableActionButton
                                type="delete"
                                onClick={() => onClickDelete(row)} />
                        }
                    </div>
                </>
            ),
        },
    ];


    return (
        <div>
            <ListViewLayout
                metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Tnc" }) })}
                title={intl.formatMessage({ id: "Tnc" })}
                addButton={
                    blnPermissionCreateTnc
                    &&
                    <MyAddButton
                        content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Tnc" }) })}
                        onClick={() => { history.push(`/${RoutesList.tnc}/add`) }}
                        linkTo={`/${RoutesList.tnc}/add`}
                        disable={loading} />
                }>
                {
                    !initialCallAPI
                    &&
                    <Row>
                        <DoubleColumnRowParent>
                            <GeneralSelectDropdown
                                title={intl.formatMessage({ id: "Category" })}
                                name="tncCategoryType"
                                options={tncCategoryOptionTypeList}
                                blnValueWithNewSpace={true}
                                labelField="displayValue"
                                valueField="key"
                                initialLabel={selectedTncCategoryOptionType}
                                initialValue={selectedTncCategoryOptionTypeId}
                                setFieldLabel={setSelectedTncCategoryOptionType}
                                setFieldValue={setSelectedTncCategoryOptionTypeId}
                                disabled={localLoading || loading}
                                onChange={() => {
                                    setCurrentPage(1);
                                    setErrorMessage("");
                                    setSuccessMessage("");
                                }}
                                validationRequired={true}
                            />
                        </DoubleColumnRowParent>
                        <DoubleColumnRowParent>
                            <GeneralDateRange
                                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "Date" }) })}
                                name={"DateRange"}
                                disabled={localLoading || loading}
                                onChangeFunction={onSelectDateRange}
                            />
                        </DoubleColumnRowParent>
                    </Row>
                }
                {localLoading ? (
                    <Loading />
                ) : (
                    <PaginationTable
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={pageSize}
                        totalItem={totalItem}
                        tableColumns={TncColumns}
                        data={tncList}
                        emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Tnc" }) })}
                        objSorted={defaultSorted}
                        categoryType={selectedTncCategoryOptionTypeId}
                        categoryTypeName={selectedTncCategoryOptionType}
                        dateRange={selectedDateRange}
                        requestAPI={getTnc} />
                )}
                <DeleteModal
                    displayName={selectedRow?.name}
                    onDeleteClick={async () => {
                        setLoading(true);
                        await deleteTnc(selectedRow?.id || "", selectedRow?.name || "");
                        let currentPageTemp = currentPage;
                        if (tncList.length === 1 && currentPage !== 1) {
                            setCurrentPage((value) => value - 1)
                            currentPageTemp = currentPageTemp - 1;
                        }
                        await getTnc({ ...tncRequestBody, pageNumber: currentPageTemp, pageSize: pageSize })
                        setLoading(false);
                    }} />
            </ListViewLayout>
        </div>
    );
};

export default observer(Tnc);