import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import {
  checkPermission,
  contructValidationErrorMessage,
  IncludesLocationPathName,
  newSpaceBeforeCapitalLetter,
  purchaseOrderStatusColor,
  returnCurrency,
  returnCurrentOnlyDate,
  returnCurrentOnlyTimeWith30Minutes,
  returnExpiredDate,
  returnOperatingHours,
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { InboundNoticeContentObject, AdditionalCostObject } from "../../app/models/inboundNotice";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useIntl } from "react-intl";
import moment from "moment";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const InboundNoticeDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { inboundNoticeStore, purchaseOrderStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { addInboundNotice, updateInboundNotice, inboundNoticeDetail, setInboundNoticeDetail, getInboundNoticeWithId } = inboundNoticeStore;
  const { purchaseOrderDropDownList, getPurchaseOrderDropdown, getPurchaseOrderWithId } = purchaseOrderStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "InboundNotice" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "InboundNotice" }), urlPath: RoutesList.inboundNotice }];
  const [activeTab, setActiveTab] = useState(1);
  const timeList = returnOperatingHours(true);
  const [loadingPurchaseOrder, setLoadingPurchaseOrder] = useState(false);
  const [purchaseOrderModal, setPurchaseOrderModal] = useState(false);
  const [inboundNoticeDetailsModal, setInboundNoticeDetailsModal] = useState(false);
  const [inboundNoticeDetailsOption, setInboundNoticeDetailsOption] = useState<InboundNoticeContentObject[]>([]);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: inboundNoticeDetail || {
      purchaseOrderId: "",
      purchaseOrderNo: "",
      supplierId: "",
      supplierContactPerson: "",
      supplierContactNo: "",
      warehouseId: "",
      warehouseContactPerson: "",
      warehouseContactNo: "",
      note: "",
      deliveryDate: "",
      deliveryFullDate: returnCurrentOnlyDate(),
      deliveryFullTime: returnCurrentOnlyTimeWith30Minutes(),
      inboundNoticeDetails: [],
      additionalCosts: [],
      workflowAction: "",
      inboundNoticeStatus: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.deliveryDate = moment(`${valuesTemp.deliveryFullDate} ${valuesTemp.deliveryFullTime}`, "YYYY-MM-DD hh:mm A").format(Constants.defaultDateFormat)
      try {
        if (addAction) {
          await addInboundNotice(valuesTemp);
        } else {
          await updateInboundNotice(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || (validation.values.inboundNoticeStatus !== Constants.pending && !addAction);
  const blnTab1 = activeTab === 1;
  const blnTab2 = activeTab === 2;
  const blnTab3 = activeTab === 3;
  const blnProcessingStatus = validation.values.inboundNoticeStatus == Constants.processing;
  const blnReceivedStatus = validation.values.inboundNoticeStatus == Constants.received || validation.values.inboundNoticeStatus == Constants.receiving;
  const blnCancelledStatus = validation.values.inboundNoticeStatus == Constants.cancelled;
  const blnPendingStatus = validation.values.inboundNoticeStatus == Constants.pending;

  const addAdditionalDetails = (index) => {
    let inboundNoticeDetailsTemp = _.cloneDeep(validation.values.inboundNoticeDetails);
    if (inboundNoticeDetailsTemp) {
      if (inboundNoticeDetailsTemp.length > index) {
        let totalAdditionalQuantity = 0;
        if (inboundNoticeDetailsTemp[index].additionalDetails) {
          inboundNoticeDetailsTemp[index].additionalDetails.map((valueAdditionalDetails) => {
            totalAdditionalQuantity += valueAdditionalDetails.quantity;
          })
          let addQuantity = inboundNoticeDetailsTemp[index].quantity - totalAdditionalQuantity
          addQuantity = addQuantity > 0 ? addQuantity : 0;
          inboundNoticeDetailsTemp[index].additionalDetails.push({
            supplierReferenceNo: "",
            quantity: addQuantity,
            expiryDate: returnExpiredDate(Constants.textInputDateFormat),
            isFOC: false
          })
        }
        validation.setFieldValue("inboundNoticeDetails", inboundNoticeDetailsTemp)
      }
    }
  }

  //Table Content UI
  const PurchaseOrderColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "purchaseOrderNo",
      text: `${intl.formatMessage({ id: "PurchaseOrderNo" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "requestedDate",
      text: intl.formatMessage({ id: "RequestedDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.requestedDate).format(
            Constants.displayDateAndTimeFormat
          )}
        </div>
      ),
    },
    {
      dataField: "purchaseFromSupplierName",
      text: intl.formatMessage({ id: "PurchaseFrom" }).toUpperCase(),
    },
    {
      dataField: "receiverName",
      text: intl.formatMessage({ id: "Receiver" }).toUpperCase(),
    },
    {
      dataField: "purchasePaymentMethod",
      text: intl.formatMessage({ id: "PurchasePaymentMethod" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.purchasePaymentMethod)}</div>,
    },
    {
      dataField: "purchaseOrderStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.purchaseOrderStatus}
          statusColorFunction={purchaseOrderStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({id: "Select"})}/>
          </div>
        </>
      ),
    },
  ];

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "ProductName" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: "productName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "PurchaseOrderQuantity" }).toUpperCase(),
      type: "label",
      xl: "2",
      inputName: addAction || blnPendingStatus ? "remainingQuantity" : "allowedQuantity",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "quantity",
      inputType: "number",
      validationRequired: true,
    },
    {
      label: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      type: "input",
      xl: "2",
      inputName: "remark",
      inputType: "text",
    },
    {
      label: `${intl.formatMessage({ id: "AdditionalDetails" }).toUpperCase()}`,
      type: "dynamicButton",
      xl: "2",
      inputName: "additionalDetails",
      inputType: "row",
      rowButtonTitle: "Add Additional Details",
      validationRequired: true,
      // rowInputField: { supplierReferenceNo: "", quantity: 0, isFOC: false}
      dynamicButtonFunction: addAdditionalDetails
    },
    {
      label: `${intl.formatMessage({ id: "AdditionalDetails" }).toUpperCase()}`,
      type: "dynamicRowContent",
      xl: "10",
      contentXL: "12",
      inputName: "additionalDetails",
      inputType: "row",
      rowValue: [
        {
          label: intl.formatMessage({ id: "SupplierReferenceNo" }).toUpperCase(),
          type: "input",
          xl: "3",
          inputName: "supplierReferenceNo",
          inputType: "text",
          validationRequired: true,
        },
        {
          label: `${intl.formatMessage({ id: "Quantity" }).toUpperCase()}`,
          type: "input",
          xl: "2",
          inputName: "quantity",
          inputType: "number",
          validationRequired: true
        },
        {
          label: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
          type: "input",
          xl: "3",
          inputName: "expiryDate",
          inputType: "date",
          // validationRequired: true,
        },
        {
          label: intl.formatMessage({ id: "IsFreeOfCharge" }).toUpperCase(),
          type: "input",
          xl: "2",
          inputName: "isFOC",
          inputType: "checkbox",
          checkBoxText: ""
        },
      ],
      rowButtonTitle: "",
    },
  ];
  const fieldsOfAdditionalCosts: AdditionalCostObject = { name: "", amount: 0, isItemized: true };
  const aryDynamicInputAndCheckBoxAdditionalCostTab: ObjectForTableWithDynamicInputAndCheckBox[] =
  [
    {
      label: intl.formatMessage({ id: "Name" }).toUpperCase(),
      type: "input",
      xl: "4",
      inputName: "name",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      type: "input",
      xl: "4",
      inputName: "amount",
      inputType: "number",
      inputGroup: true,
      inputGroupText: `${returnCurrency()} `
    },
    {
      label: intl.formatMessage({ id: "Itemized" }).toUpperCase(),
      type: "input",
      xl: "4",
      inputName: "isItemized",
      inputType: "checkbox",
      checkBoxText: intl.formatMessage({ id: "Yes" })
    }
  ];

  useEffect(() => {
    async function fetchInboundNoticeDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getPurchaseOrderDropdown({ orderTypes: "3", dropDownModule: "0", statuses: "1,3" }), //PartialComplete and Processing
      ];
      if (id && !addAction) {
        aryAPI.push(getInboundNoticeWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setInboundNoticeDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateInboundNotice], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateInboundNotice], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageInboundNotice], true)) {
        return;
      }
    }

    if(viewAction && checkPermission([PermissionConstants.UpdateInboundNotice])){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.inboundNotice}/edit/${id}`)} })
    }

    fetchInboundNoticeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setInboundNoticeDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    async function initInboundNoticeDetail(inboundNoticeDetail) {
      let aryInboundNoticeDetailsTemp = inboundNoticeDetail.inboundNoticeDetails.map((value) => {
        let additionalDetailsTemp = value.additionalDetails.map((valueAdditionalDetails)=> ({
          ...valueAdditionalDetails,
          expiryDate: moment(valueAdditionalDetails.expiryDate).format(Constants.displayDateFormat),
        }))
        return ({
          ...value,
          remainingQuantity: value.allowedQuantity,
          additionalDetails:  additionalDetailsTemp
        })
      })
      validation.setValues({
        ...inboundNoticeDetail,
        deliveryFullDate: moment(inboundNoticeDetail.deliveryDate).format(Constants.displayDateFormat),
        deliveryFullTime: moment(inboundNoticeDetail.deliveryDate).format(Constants.displayTimeFormat),
        inboundNoticeDetails: aryInboundNoticeDetailsTemp
      })
      let purchaseOrderDetailObject = await getPurchaseOrderWithId(inboundNoticeDetail.purchaseOrderId);
      if (purchaseOrderDetailObject) {
        let inboundNoticeDetailsTemp: any = [];
        purchaseOrderDetailObject.purchaseOrderDetails.map((value) => {
          if ((value.remainingQuantity || 0) > 0) {
            let indexSelectedProductTemp = _.findIndex(inboundNoticeDetail.inboundNoticeDetails, { productId: value.productId })
            let purchaseOrderDetailContent: any = {
              productId: value.productId,
              productName: value.productName,
              remainingQuantity: value.remainingQuantity,
              quantity: value.remainingQuantity,
              taxRate: 0,
              remark: value.remark,
              disabled: indexSelectedProductTemp >= 0 ? true : false,
              additionalDetails: []
            }
            if (!addAction) {
              purchaseOrderDetailContent = {
                ...purchaseOrderDetailContent,
                id: value.id
              }
            }
            inboundNoticeDetailsTemp.push(purchaseOrderDetailContent);
          }
        })
        setInboundNoticeDetailsOption(inboundNoticeDetailsTemp);
      }
    }

    if (id && !addAction) {
      if (inboundNoticeDetail) {
        initInboundNoticeDetail(inboundNoticeDetail)
      }
    }
  }, [inboundNoticeDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab)
      }
    }
  }

  const nextButtonDisableTab = () => {
    if (blnTab1) {
      if (!validation.values.purchaseOrderId || !validation.values.supplierId ||
        !validation.values.supplierContactPerson || !validation.values.supplierContactNo ||
        !validation.values.warehouseId || !validation.values.warehouseContactPerson ||
        !validation.values.warehouseContactNo || !validation.values.deliveryFullDate ||
        !validation.values.deliveryFullTime) {
        return true;
      }
    }

    if (blnTab2) {
      let blnDisabledTab2 = false;
      let totalQuantity = 0;
      if (validation.values.inboundNoticeDetails) {
        if (validation.values.inboundNoticeDetails.length > 0) {
          validation.values.inboundNoticeDetails.map((valueTemp) => {
            totalQuantity += valueTemp.quantity;
          })
          if (totalQuantity < 1) {
            blnDisabledTab2 = true;
          }
        }
        else {
          blnDisabledTab2 = true;
        }
      }
      else {
        blnDisabledTab2 = true;
      }
      return blnDisabledTab2;
    }
    return false;
  }

  const onChangePurchaseOrderModal = (row) => {
    validation.setFieldValue("purchaseOrderId", row.id);
    validation.setFieldValue("purchaseOrderNo", row.purchaseOrderNo);
    setPurchaseOrderModal(false);
    onChangePurchaseOrder(row.id);
  }

  const onChangePurchaseOrder = async (id: string) => {
    setLoadingPurchaseOrder(true);
    try {
      let purchaseOrderDetailObject = await getPurchaseOrderWithId(id);
      if (purchaseOrderDetailObject) {
        let inboundNoticeDetailsTemp: any = [];
        purchaseOrderDetailObject.purchaseOrderDetails.map((value) => {
          if ((value.remainingQuantity || 0) > 0) {
            let purchaseOrderDetailContent: any = {
              productId: value.productId,
              productName: value.productName,
              remainingQuantity: value.remainingQuantity,
              quantity: value.remainingQuantity,
              taxRate: 0,
              remark: value.remark,
              additionalDetails: [{ supplierReferenceNo: "", quantity: value.remainingQuantity, expiryDate: returnExpiredDate(Constants.textInputDateFormat), isFOC: false }]
            }
            if (!addAction) {
              purchaseOrderDetailContent = {
                ...purchaseOrderDetailContent,
                id: value.id
              }
            }
            inboundNoticeDetailsTemp.push(purchaseOrderDetailContent);
          }
        })
        validation.setFieldValue("supplierId", purchaseOrderDetailObject.purchaseFromSupplierId)
        validation.setFieldValue("supplierContactPerson", purchaseOrderDetailObject.purchaseFromContactPerson)
        validation.setFieldValue("supplierContactNo", purchaseOrderDetailObject.purchaseFromContactNo)
        validation.setFieldValue("warehouseId", purchaseOrderDetailObject.receiverId)
        validation.setFieldValue("warehouseContactPerson", purchaseOrderDetailObject.shippingContactPerson)
        validation.setFieldValue("warehouseContactNo", purchaseOrderDetailObject.shippingContactNo)
        validation.setFieldValue("inboundNoticeDetails", inboundNoticeDetailsTemp)
        inboundNoticeDetailsTemp = inboundNoticeDetailsTemp.map((value) => ({
          ...value,
          disabled: true,
        }))
        setInboundNoticeDetailsOption(inboundNoticeDetailsTemp);
      }
    }
    catch (e) {
      setErrorMessage(String(e));
    }
    setLoadingPurchaseOrder(false);
  }

  const inboundNoticeDetailsValidation = () => {
    let blnEmptyQuantity = false;
    let blnEmptyAdditionalQuantity = false;
    let blnEmptyAdditionalRow = false;
    let blnEmptySupplierName = false;
    let blnMismatchRowNumber = false;
    let blnQuantityMoreThanPurchaseOrderQuantity = false;
    let blnQuantityMismatchPurchaseOrderQuantityWhenFocExist = false;
    let blnMoreThanOneCheckboxForFOC = false;

    validation.values.inboundNoticeDetails.map((inboundNoticeDetailsTemp) => {
      let totalAdditionalQuantity = 0;
      let totalAdditionalWithoutFOCQuantity = 0;
      let blnFOC = false;
      let totalFOC = 0;
      let purchaseOrderQuantity = addAction || blnPendingStatus ? inboundNoticeDetailsTemp.remainingQuantity : inboundNoticeDetailsTemp.allowedQuantity;
      if (inboundNoticeDetailsTemp.quantity < 1) {
        blnEmptyQuantity = true;
      }

      if (inboundNoticeDetailsTemp.additionalDetails) {
        if (inboundNoticeDetailsTemp.additionalDetails.length < 1) {
          blnEmptyAdditionalRow = true;
        }
        inboundNoticeDetailsTemp.additionalDetails.map((additionalDetailsTemp) => {
          if (additionalDetailsTemp.isFOC) {
            blnFOC = true;
            totalFOC += 1;
          }
          else {
            totalAdditionalWithoutFOCQuantity += additionalDetailsTemp.quantity;
          }

          totalAdditionalQuantity += additionalDetailsTemp.quantity;
          if (additionalDetailsTemp.quantity < 1) {
            blnEmptyAdditionalQuantity = true;
          }

          if (!additionalDetailsTemp.supplierReferenceNo) {
            blnEmptySupplierName = true;
          }
        })
      }
      else {
        blnEmptyAdditionalRow = true;
      }

      if (inboundNoticeDetailsTemp.quantity !== totalAdditionalQuantity) {
        blnMismatchRowNumber = true;
      }

      if (!blnFOC && inboundNoticeDetailsTemp.quantity > purchaseOrderQuantity) {
        blnQuantityMoreThanPurchaseOrderQuantity = true;
      }
      else if (blnFOC && totalAdditionalWithoutFOCQuantity !== purchaseOrderQuantity) {
        blnQuantityMismatchPurchaseOrderQuantityWhenFocExist = true;
      }

      if (totalFOC > 1) {
        blnMoreThanOneCheckboxForFOC = true;
      }
    })

    if (blnEmptyQuantity) {
      setErrorMessage("Amount of Quantity is require full up at least one")
    }
    else if (blnEmptyAdditionalQuantity) {
      setErrorMessage("Amount of Additional Quantity is require full up at least one")
    }
    else if (blnEmptyAdditionalRow) {
      setErrorMessage("Require at least one additonal row")
    }
    else if (blnEmptySupplierName) {
      setErrorMessage("Supplier Reference No could not be empty")
    }
    else if (blnMismatchRowNumber) {
      setErrorMessage("Quantity must match with addtional row")
    }
    else if (blnQuantityMoreThanPurchaseOrderQuantity) {
      setErrorMessage("Quantity should not more than purchase order quantity when there's no FOC selected")
    }
    else if (blnQuantityMismatchPurchaseOrderQuantityWhenFocExist) {
      setErrorMessage("Total quantity must match with purchase order quantity when FOC is ticked")
    }
    else if (blnMoreThanOneCheckboxForFOC) {
      setErrorMessage("Each row is only allow one row of FOC")
    }

    return blnEmptyQuantity || blnEmptyAdditionalQuantity || blnEmptyAdditionalRow || blnMismatchRowNumber || blnQuantityMoreThanPurchaseOrderQuantity || blnQuantityMismatchPurchaseOrderQuantityWhenFocExist || blnEmptySupplierName || blnMoreThanOneCheckboxForFOC;
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem
                    className={classNames({ current: blnTab1 })}
                  >
                    <NavLink
                      className={classNames({ current: blnTab1 })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                      disabled={true}
                    >
                      <span className="number">1.</span> {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "InboundNotice" }) })}`}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab2 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab2 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">2.</span> {`${intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "InboundNotice" }) })}`}
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classNames({ current: blnTab3 })}
                  >
                    <NavLink
                      className={classNames({ active: blnTab3 })}
                      onClick={() => {
                        setActiveTab(2)
                      }}
                      disabled={true}
                    >
                      <span className="number">3.</span> {`${intl.formatMessage({ id: "AdditionalCosts" })}`}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent
                  activeTab={activeTab}
                  className="body"
                >
                  <TabPane tabId={1}>
                    <Row form={+true}>
                      {" "}
                      <Col xl={12}>
                        <LineBreakWithTittle
                          paddingBottom="0px"
                          title={`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "InboundNotice" }) })}`}
                          h4Title
                        />
                        <SingleColumnRowParent >
                          <GeneralInput
                            title={intl.formatMessage({ id: "PurchaseOrder" })}
                            name="purchaseOrderNo"
                            className="mb-3 mt-4"
                            classNameInput="width-100-percentage"
                            type="text"
                            disabled={
                              true
                            }
                            validation={validation}
                            childrenUI={
                              addAction
                              &&
                              <MyButton
                                type="button"
                                content={"Select"}
                                class="btn btn-primary margin-left-8"
                                onClick={() => { setPurchaseOrderModal(true) }}
                                loading={loadingPurchaseOrder}
                                disable={disabledFieldInput || viewAction || loadingPurchaseOrder}
                              />
                            }/>
                        </SingleColumnRowParent>
                        {
                          loadingPurchaseOrder
                          ?
                          <Loading 
                            fullHeightDisabled={true}/>
                          :
                          <>
                            <Row>
                              <DoubleColumnRowParent>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "SupplierContactPerson" })}
                                  name="supplierContactPerson"
                                  type="text"
                                  disabled={
                                    disabledFieldInput ||
                                    viewAction
                                  }
                                  validationRequired={true}
                                  validation={validation}
                                />
                              </DoubleColumnRowParent>
                              <DoubleColumnRowParent>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "SupplierContactNo" })}
                                  name="supplierContactNo"
                                  type="number"
                                  blnNumberOnly={true}
                                  validationRequired={
                                    true
                                  }
                                  disabled={
                                    disabledFieldInput ||
                                    viewAction
                                  }
                                  validation={validation}
                                />
                              </DoubleColumnRowParent>
                            </Row>
                            <Row>
                              <DoubleColumnRowParent>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "WarehouseContactPerson" })}
                                  name="warehouseContactPerson"
                                  type="text"
                                  disabled={
                                    disabledFieldInput ||
                                    viewAction
                                  }
                                  validationRequired={true}
                                  validation={validation}
                                />
                              </DoubleColumnRowParent>
                              <DoubleColumnRowParent>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "WarehouseContactNo" })}
                                  name="warehouseContactNo"
                                  type="number"
                                  blnNumberOnly={true}
                                  validationRequired={
                                    true
                                  }
                                  disabled={
                                    disabledFieldInput ||
                                    viewAction
                                  }
                                  validation={validation}
                                />
                              </DoubleColumnRowParent>
                            </Row>
                            <Row>
                              <DoubleColumnRowParent>
                                <GeneralInput
                                  title={intl.formatMessage({ id: "DeliveryDate" })}
                                  name="deliveryFullDate"
                                  type="date"
                                  validationRequired={
                                    true
                                  }
                                  disabled={
                                    disabledFieldInput ||
                                    viewAction
                                  }
                                  validation={validation}
                                />
                              </DoubleColumnRowParent>
                              <DoubleColumnRowParent>
                                <DropDownWithTitle
                                  name={"deliveryFullTime"}
                                  title={intl.formatMessage({ id: "Time" })}
                                  specifyReturnFieldName={[{ "field": "deliveryFullTime", "value": "value" }]}
                                  labelField={"label"}
                                  valueField={"value"}
                                  options={timeList}
                                  disabled={disabledFieldInput || viewAction}
                                  validationRequired={true}
                                  initialLabel={validation.values.deliveryFullTime}
                                  initialValue={validation.values.deliveryFullTime}
                                  validation={validation} />
                              </DoubleColumnRowParent>
                            </Row>
                          </>
                        }
                        <SingleColumnRowParent>
                          <GeneralTextArea
                            title="Note"
                            name="note"
                            row={5}
                            disabled={disabledFieldInput || viewAction}
                            validation={validation}
                          />
                        </SingleColumnRowParent>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Product" }) })}`}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="inboundNoticeDetails"
                        title=""
                        buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
                        blnEditable={!viewAction}
                        blnSupportWithModal={true}
                        blnBtnModalBottom={true}
                        options={inboundNoticeDetailsOption}
                        optionsId={"productId"}
                        setOptionsFunction={setInboundNoticeDetailsOption}
                        addButtonFunction={() => setInboundNoticeDetailsModal(true)}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        blnDynamicRowContent={true}
                        data={validation.values.inboundNoticeDetails}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                  <TabPane tabId={3}>
                    <Col>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "AdditionalCosts" })}
                        h4Title
                      />
                      <TableWithEditableInputFields
                        name="additionalCosts"
                        title={""}
                        buttonTitle={intl.formatMessage({ id: "AddAdditionalCosts" })}
                        blnEditable={!viewAction}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxAdditionalCostTab}
                        data={validation.values.additionalCosts}
                        inputField={fieldsOfAdditionalCosts}
                        validation={validation}
                        disabled={disabledFieldInput} />
                    </Col>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    {(blnTab2 || blnTab3) && !Boolean(successMessage) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Previous" })}
                        loading={
                          validation.isSubmitting
                        }
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      />
                    }
                  </li>
                  <li>
                    {(blnTab1 || blnTab2) &&
                      <MyButton
                        type="button"
                        class="btn btn-primary"
                        style={{ width: '100%' }}
                        content={intl.formatMessage({ id: "Next" })}
                        disable={
                          nextButtonDisableTab()
                        }
                        loading={
                          validation.isSubmitting || loadingPurchaseOrder
                        }
                        onClick={() => {
                          if (blnTab2) {
                            if (inboundNoticeDetailsValidation()) {
                              return;
                            }
                          }
                          toggleTab(activeTab + 1)
                        }}
                      />
                    }
                  </li>
                </ul>
              </div>

            </div>
            <div className="mt-3" />
            <div className="flex-direction-row">
              {!Boolean(successMessage) && blnTab3 && !blnReceivedStatus && !blnCancelledStatus && !addAction &&
                <MyButton
                  type="submit"
                  class={`btn btn-warning margin-right-8`}
                  content={Constants.cancel}
                  disable={
                    validation.isSubmitting
                  }
                  loading={
                    validation.isSubmitting
                  }
                  onClick={() => {
                    validation.setFieldValue("workflowAction", Constants.cancel)
                  }}
                />
              }
              <GeneralSubmitAndCancelBtn
                successMessage={successMessage}
                viewAction={viewAction}
                validation={validation}
                dirtyDisabled={true}
                showExtraLeftButton={blnProcessingStatus ? false : true}
                extraLeftButtonTitle={intl.formatMessage({ id: "Save" })}
                extraLeftButtonColor="btn-primary"
                extraLeftButtonFunction={() => {
                  validation.setFieldValue("workflowAction", Constants.save)
                }}
                hidePrimaryButton={blnTab1 || blnTab2}
                submitButtonTitle={blnProcessingStatus ? Constants.acknowledge : Constants.submit}
                onClickFunction={() => {
                  validation.setFieldValue("workflowAction", blnProcessingStatus ? Constants.acknowledge : Constants.submit)
                }}
              />
            </div>
          </Form>
        )}
        <SelectWithTableListModal
          blnShow={purchaseOrderModal}
          setModal={setPurchaseOrderModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "PurchaseOrder" }) })}
          options={purchaseOrderDropDownList}
          keyField={"id"}
          fieldName={"purchaseOrderId"}
          onSelectFunction={onChangePurchaseOrderModal}
          columns={PurchaseOrderColumns}
          validation={validation}/>
        <SelectDropDownModal
          blnShow={inboundNoticeDetailsModal}
          setModal={setInboundNoticeDetailsModal}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Product" }) })}
          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Product" }) })}
          placeholder={"- Please Select -"}
          labelField={"productName"}
          valueField={"productId"}
          options={inboundNoticeDetailsOption}
          setOptionsFunction={setInboundNoticeDetailsOption}
          fieldInput={validation.values.inboundNoticeDetails}
          fieldName={"inboundNoticeDetails"}
          validation={validation} />
      </DetailViewLayout>
    </div >
  );
};

export default observer(InboundNoticeDetail);
