import { PaginationRequestBody } from "../models/pagination";
import { InboundNoticeAddObject, InboundNoticeDetailObject, InboundNoticeListObject, InboundNoticeUpdateObject } from "../models/inboundNotice";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class InboundNoticeStore {
  inboundNoticeList: InboundNoticeListObject[] = [];
  inboundNoticeDetail: InboundNoticeDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.inboundNoticeList = [];
    this.inboundNoticeDetail = undefined;
  }

  setInboundNoticeDetail = (inboundNoticeDetail: InboundNoticeDetailObject | undefined) => {
    this.inboundNoticeDetail = inboundNoticeDetail;
  }

  getInboundNotice = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultInboundNotices = await agent.InboundNotice.inboundNoticeList(PaginationRequestBody);
      runInAction(() => {
        this.inboundNoticeList = resultInboundNotices.data;
        store.commonStore.setTotalItem(resultInboundNotices.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.inboundNoticeList = [];
    }
  };

  getInboundNoticeWithId = async (id: string) => {
    try{
      const resultInboundNoticeDetail = await agent.InboundNotice.inboundNoticeDetail(id);
      runInAction(() => {
        this.inboundNoticeDetail = resultInboundNoticeDetail;
      });

      return Promise.resolve(resultInboundNoticeDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.inboundNoticeDetail = undefined;
    }
  }

  addInboundNotice = async (inboundNoticeRequestBody: InboundNoticeAddObject) => {
    try{
      await agent.InboundNotice.addInboundNotice(inboundNoticeRequestBody);
      store.commonStore.setSuccessMessage(`InboundNoticeAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateInboundNotice = async (inboundNoticeRequestBody: InboundNoticeUpdateObject) => {
    try{
      await agent.InboundNotice.updateInboundNotice(inboundNoticeRequestBody);
      store.commonStore.setSuccessMessage(`InboundNoticeUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteInboundNotice  = async (id: string, name: string) => {
    try {
      await agent.InboundNotice.deleteInboundNotice(id);
      store.commonStore.setSuccessMessage(`InboundNoticeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
