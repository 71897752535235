import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { observer } from "mobx-react-lite";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import FileSettingsOutlineIcon from 'mdi-react/FileSettingsOutlineIcon';
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon';

// users
import user9 from '../../../../assets/images/users/avatar-9.png';

//store
import { useStore } from '../../../../app/stores/store';
import { RoutesList } from "../../../constants/RoutesList";
import { isMobile } from "react-device-detect";
import { isBranchManager } from "../../../common/function/function";

interface Props {
  isMobileApplicable: boolean;
}

const ProfileMenu = (props: Props) => {
  // Declare a new state variable, which we'll call "menu"
  const { userStore, profileStore, commonStore } = useStore();
  const { logout, setSwitchBrandModal } = userStore;
  const { blnDevAndUatEnv, showHelpMenu, setShowHelpMenu } = commonStore;
  const [menu, setMenu] = useState(false)
  const { profileDetail } = profileStore;
  
  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileDetail?.photoUrl || user9}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{profileDetail?.preferredName}</span>
          <ChevronDownIcon className="d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {
            isMobile
            &&
            <style type="text/css">
              {`
                .dropdown-menu.show {
                  top: 70px !important;
                }
              `}
            </style>
          }
          <DropdownItem tag="a" href={`/${RoutesList.profile}`}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {("Profile")}
          </DropdownItem>
          <DropdownItem tag="a" href={`/${RoutesList.changePassword}`}>
            {" "}
            <i className="bx bx-lock-alt font-size-16 align-middle me-1"/>
            {("Change Password")}
          </DropdownItem>
          {
            isBranchManager()
            ?
            <DropdownItem tag="a" href={`/${RoutesList.changePasscode}`}>
              {" "}
              {/* <i className="bx bx-lock-alt font-size-16 align-middle me-1"/> */}
              <FileSettingsOutlineIcon size="16" className="font-size-16 align-middle me-1"/>
              {("Change Passcode")}
            </DropdownItem>
            :
            null
          }
          {
            blnDevAndUatEnv
            &&
            <DropdownItem
              onClick={()=> {
                const blnEnvWaterMark = window.localStorage.getItem('env_watermark');
                if (blnEnvWaterMark === "false") {
                  window.localStorage.setItem("env_watermark", "true");
                }
                else {
                  window.localStorage.setItem("env_watermark", "false");
                }
              }}>
              <i className="bx bx-pen font-size-16 align-middle me-1"/>
              {("Show/Hide Env Watermark")}
            </DropdownItem>
          }
          {
            props.isMobileApplicable
            &&
            <DropdownItem 
              tag="a" 
              href="#"
              onClick={()=> {
                setShowHelpMenu(!showHelpMenu);
              }}>
              <i className="bx bx-help-circle font-size-16 align-middle me-1"/>
              {("Help")}
            </DropdownItem>
          }
          {
            profileDetail?.otherAvailableBrands
            ?
            profileDetail.otherAvailableBrands.length > 0
            ?
            <DropdownItem
              onClick={()=> {
                setSwitchBrandModal(true);
              }}>
              <SwapHorizontalIcon size="16" className="font-size-16 align-middle me-1"/>
              {("Switch Brand")}
            </DropdownItem>
            :
            <div/>
            :
            <div/>
          }
          <div className="dropdown-divider"/>
          <DropdownItem onClick={()=> logout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default observer(ProfileMenu);
