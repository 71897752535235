import { PaginationRequestBody } from "../models/pagination";
import { PaymentTerminalListObject, PaymentTerminalAddObject, PaymentTerminalUpdateObject, PaymentTerminalDetailObject } from "../models/paymentTerminal";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class PaymentTerminalStore {
  paymentTerminalList: PaymentTerminalListObject[] = [];
  paymentTerminalDropdownList: PaymentTerminalListObject[] = [];
  paymentTerminalDetail: PaymentTerminalDetailObject | undefined = undefined;
  paymentTerminalPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.paymentTerminalList = [];
    this.paymentTerminalDropdownList = [];
    this.paymentTerminalDetail = undefined;
    this.paymentTerminalPaginationParams= undefined;
  }

  setPaymentTerminalDetail = (paymentTerminalDetail: PaymentTerminalDetailObject | undefined) => {
    this.paymentTerminalDetail = paymentTerminalDetail;
  }

  setPaymentTerminalPaginationParams = (paymentTerminalPaginationParams: PaginationRequestBody | undefined) => {
    this.paymentTerminalPaginationParams = paymentTerminalPaginationParams;
  }
  
  getPaymentTerminal = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setPaymentTerminalPaginationParams(PaginationRequestBody);
    PaginationRequestBody.name = encodeURIComponent(PaginationRequestBody.name || "");
    try{
      const resultPaymentTerminal = await agent.PaymentTerminal.paymentTerminalList(PaginationRequestBody);
      runInAction(() => {
        this.paymentTerminalList = resultPaymentTerminal.data;
        store.commonStore.setTotalItem(resultPaymentTerminal.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentTerminalList = [];
    }
  }

  getPaymentTerminalDropdown = async () => {
    try{
      const resultPaymentTerminalDropdown = await agent.PaymentTerminal.paymentTerminalDropdownList();
      runInAction(() => {
        this.paymentTerminalDropdownList = resultPaymentTerminalDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentTerminalDropdownList = [];
    }
  }

  getPaymentTerminalWithId = async (id: string) => {
    try{
      const resultPaymentTerminalDetail = await agent.PaymentTerminal.paymentTerminalDetail(id);
      runInAction(() => {
        this.paymentTerminalDetail = resultPaymentTerminalDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.paymentTerminalDetail = undefined;
    }
  }

  addPaymentTerminal = async (paymentTerminalRequestBody: PaymentTerminalAddObject) => {
    try{
      await agent.PaymentTerminal.addPaymentTerminal(paymentTerminalRequestBody);
      store.commonStore.setSuccessMessage(`PaymentTerminalAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePaymentTerminal = async (paymentTerminalRequestBody: PaymentTerminalUpdateObject) => {
    try{
      await agent.PaymentTerminal.updatePaymentTerminal(paymentTerminalRequestBody);
      store.commonStore.setSuccessMessage(`PaymentTerminalUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePaymentTerminal  = async (id: string, name: string) => {
    try {
      await agent.PaymentTerminal.deletePaymentTerminal(id);
      store.commonStore.setSuccessMessage(`PaymentTerminalDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}