import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import classNames from "classnames";
import {
	AddOptionsValueField,
	checkPermission,
	contructValidationErrorMessage,
	getBrandId,
	IncludesLocationPathName,
	returnBlobImage,
} from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
	Row,
	Col,
	Form,
	Nav,
	NavItem,
	NavLink,
	Label,
	TabContent,
	TabPane,
	Input,
} from "reactstrap";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DropZoneUploadWidget from "../../app/components/form/DropZoneUploadWidget";
import TreeView from "../../app/components/table/TreeView";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
import { EmployeeBranchTransferHistory, EmployeeLeaveEntitlementObject, EmployeePayrollItemObject, EmployeePositionMovementHistory } from "../../app/models/employee";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import CardAccountDetailsIcon from 'mdi-react/CardAccountDetailsIcon';
import OfficeBuildingMarkerIcon from 'mdi-react/OfficeBuildingMarkerIcon';
import AccountArrowRightIcon from 'mdi-react/AccountArrowRightIcon';
import HistoryIcon from 'mdi-react/HistoryIcon';
import moment from "moment";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";
import ReassignCustomerModal from "./Modal/ReassignCustomerModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import MyButton from "../../app/components/form/MyButton";
import UpdatePositionMovementHistoryModal from "./Modal/UpdatePositionMovementHistoryModal";
import UpdateBranchTransferHistoryModal from "./Modal/UpdateBranchTransferHistoryModal";
import PaginationTableWithoutApi from "../../app/components/table/PaginationTableWithoutApi";
import TableActionButton from "../../app/components/table/TableActionButton";
import AddPositionMovementHistoryModal from "./Modal/AddPositionMovementHistoryModal";
import AddBranchTransferHistoryModal from "./Modal/AddBranchTransferHistoryModal";
import { BranchListObject } from "../../app/models/branch";
import LoadingModal from "../../app/components/modal/LoadingModal";

const EmployeeDetail = () => {
	//Use Params
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const {
		employeeStore,
		staticReferenceStore,
		raceStore,
		userRoleStore,
		businessEntityStore,
		branchStore,
		epfContributionStore,
		countryStore,
		positionStore,
		commonStore,
		payrollItemStore,
		leaveEntitlementStore,
		customerStore,
		departmentStore,
		settingsStore
	} = useStore();
	const {
		errorMessage,
		successMessage,
		loading,
		windowSize,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const {
		addEmployee,
		updateEmployee,
		employeeDetail,
		setEmployeeDetail,
		getEmployeeWithId,
		accessTree,
		getAccessTree,
		exportEmployee,
		getEmployeeDropdown,
		reassignCustomer,
		addEmployeePositionMovementHistory,
		updateEmployeePositionMovementHistory,
		deleteEmployeePositionMovementHistory,
		addEmployeeBranchTransferHistory,
		updateEmployeeBranchTransferHistory,
		deleteEmployeeBranchTransferHistory,
		resetEmployeePasscode
	} = employeeStore;
	const {
		titleType,
		identityType,
		genderType,
		employeeType,
		getStaticReference,
	} = staticReferenceStore;
	const { raceDropdownList, getRaceDropdown } = raceStore;
	const { userRoleDropdownList, getUserRoleDropdown, setUserRoleDropdownList } = userRoleStore;
	const { businessEntityDropdownList, getBusinessEntityDropDown } = businessEntityStore;
	const { branchDropDownList, getBranchDropDown, getBranchDropDownWithoutValidation, setBranchList } = branchStore;
	const { epfContributionDropdownList, getEPFContributionDropdown } = epfContributionStore;
	const { stateList, countryDropdownList, getStateWithId, getCountryDropdown, setStateList } = countryStore;
	const { positionDropdownList, getPositionDropdown, setPositionDropdownList } = positionStore;
	const { payrollItemDropdownList, getPayrollItemDropdown } = payrollItemStore;
	const { leaveEntitlementDropdownList, getLeaveEntitlementDropdown } = leaveEntitlementStore;
	const { getCustomer } = customerStore;
	const { departmentDropdownList, getDepartmentDropdown } = departmentStore;
	const { generalSettings, getGeneralSettings } = settingsStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "Employee" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "Employee" }), urlPath: RoutesList.employee }];
	const [activeTab, setActiveTab] = useState("1");
	const [newImageUrl, setNewImageUrl] = useState<string>("");
	const [loadingState, setLoadingState] = useState(false);
	const [loadingBranch, setLoadingBranch] = useState(false);
	const [loadingRolePosition, setLoadingRolePosition] = useState(false);
	const [loadingResetPasscode, setLoadingResetPasscode] = useState(false);
	// const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "Details" }).toUpperCase(), intl.formatMessage({ id: "Branches" }).toUpperCase(),
	// intl.formatMessage({ id: "Payroll" }).toUpperCase(), intl.formatMessage({ id: "LeaveEntitlement" }).toUpperCase(), intl.formatMessage({ id: "PositionMovementHistories" }).toUpperCase(), 
	// intl.formatMessage({ id: "BranchTransferHistories" }).toUpperCase()]);
	const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "Details" }).toUpperCase(), intl.formatMessage({ id: "Branches" }).toUpperCase()]);
	const tabBarForEditViewEmployee = [intl.formatMessage({ id: "Details" }).toUpperCase(), intl.formatMessage({ id: "Branches" }).toUpperCase(), intl.formatMessage({ id: "PositionMovementHistories" }).toUpperCase(),
	intl.formatMessage({ id: "BranchTransferHistories" }).toUpperCase()];
	const pageSize = Constants.maxPageSize;
	const [initialData, setInitialData] = useState(false);
	const [branchDropdownForBranchHistories, setBranchDropdownForBranchHistories] = useState<BranchListObject[]>([])

	const [employeePayrollItemList, setEmployeePayrollItemList] = useState<EmployeePayrollItemObject[]>([]);
	const [payrollItemModal, setPayrollItemModal] = useState(false);
	const [employeeLeaveEntitlementList, setEmployeeLeaveEntitlementList] = useState<EmployeeLeaveEntitlementObject[]>([]);
	const [leaveEntitlementModal, setLeaveEntitlementModal] = useState(false);
	const [blnSubmitting, setBlnSubmitting] = useState(false);
	const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
	const [reassignCustomerModal, setReassignCustomerModal] = useState(false);
	const [reassignEmployeeCustomerAlertModal, setReassignEmployeeCustomerAlertModal] = useState(false);
	const [addPositionMovementHistoryModal, setAddPositionMovementHistoryModal] = useState(false);
	const [updatePositionMovementHistoryModal, setUpdatePositionMovementHistoryModal] = useState(false);
	const [refreshPostionMovementHistoryData, setRefreshPostionMovementHistoryData] = useState(false);
	const [addBranchTransferHistoryModal, setAddBranchTransferHistoryModal] = useState(false);
	const [updateBranchTransferHistoryModal, setUpdateBranchTransferHistoryModal] = useState(false);
	const [refreshBranchTransferHistoryData, setRefreshBranchTransferHistoryData] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
  const [selectedPositionMovementHistoryRow, setSelectedPositionMovementHistoryRow] = useState<EmployeePositionMovementHistory | undefined>(undefined);
	const [showDeletePositionMovementHistoryPrompt, setShowDeletePositionMovementHistoryPrompt] = useState(false);
	const [selectedBranchTransferHistoryRow, setSelectedBranchTransferHistoryRow] = useState<EmployeeBranchTransferHistory | undefined>(undefined);
	const [showDeleteBranchTransferHistoryPrompt, setShowDeleteBranchTransferHistoryPrompt] = useState(false);
	const [resetPasscodePrompt, setResetPasscodePrompt] = useState(false);
  const [resetPasscodeSuccessPrompt, setResetPasscodeSuccessPrompt] = useState(false);
  const [passcodeFromResetPasscodeResponse, setPasscodeFromResetPasscodeResponse] = useState("");

	const aryDynamicInputAndCheckBoxTabPayrollTypeTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
		{
			label: intl.formatMessage({ id: "PayrollItemName" }),
			type: "label",
			xl: "6",
			inputName: "payrollItemName",
			inputType: "text",
		},
		{
			label: intl.formatMessage({ id: "Amount" }),
			type: "input",
			xl: "6",
			inputName: "amount",
			inputType: "number",
		},
	];

	const aryDynamicInputAndCheckBoxLeaveEntitlementTab: ObjectForTableWithDynamicInputAndCheckBox[] =
	[
		{
			label: intl.formatMessage({ id: "LeaveEntitlementName" }),
			type: "label",
			xl: "6",
			inputName: "leaveEntitlementName",
			inputType: "text",
		},
		{
			label: intl.formatMessage({ id: "EntitleQuantity" }),
			type: "input",
			xl: "6",
			inputName: "entitleQuantity",
			inputType: "number",
		},
	];

	const PositionMovementHistoriesColumns = [
		{
      dataField: "positionDisplayName",
      text: intl.formatMessage({ id: "PositionName" }).toUpperCase()
    },
		{
      dataField: "roleName",
      text: intl.formatMessage({ id: "RoleName" }).toUpperCase(),
			formatter: (cellContent, row) => (
				<div>{row.roleName || Constants.emptyData}</div>
			)
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
			formatter: (cellContent, row) => (
				<Input
					type="text"
					disabled={true}
					value={moment(row.startDate, Constants.displayDateFormat).format(Constants.displayInputDateFormat)}
				/>
			)
    },
    {
      dataField: "displayEndDate",
      text: intl.formatMessage({ id: "EndDate" }).toUpperCase(),
			formatter: (cellContent, row) => (
				<Input
					type="text"
					disabled={true}
					className={`${row.displayEndDate === Constants.present ? "inputWithSuccessColor" : ""}`}
					value={row.displayEndDate}
				/>
			)
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row, index) => (
        <>
					{
						blnPermissionUpdateEmployee
						&&
						index === 0
						&&
						<div className="d-flex gap-3 flex-justfity-content-center">
							<TableActionButton 
								type="edit"
								disabled={disabledFieldInput}
								onClick={()=> {
									setUpdatePositionMovementHistoryModal(true);
								}}/>
							{
								validation.values.positionMovementHistories.length > 1
								&&
								<TableActionButton 
									type="delete"
									disabled={disabledFieldInput}
									onClick={()=> {
										onClickDeletePositionMovementHistoryPrompt(row)
									}}/>
							}
						</div>
					}
        </>
      ),
    }
	]

	const BranchTransferHistoriesColumns = [
		{
      dataField: "branchDisplayName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase()
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
			formatter: (cellContent, row) => (
				<Input
					type="text"
					disabled={true}
					value={moment(row.startDate, Constants.displayDateFormat).format(Constants.displayInputDateFormat)}
				/>
			)
    },
    {
      dataField: "displayEndDate",
      text: intl.formatMessage({ id: "EndDate" }).toUpperCase(),
			formatter: (cellContent, row) => (
				<Input
					type="text"
					disabled={true}
					className={`${row.displayEndDate === Constants.present ? "inputWithSuccessColor" : ""}`}
					value={row.displayEndDate}
				/>
			)
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row, index) => (
        <>
					{
						blnPermissionUpdateEmployee
						&&
						index === 0
						&&
						<div className="d-flex gap-3 flex-justfity-content-center">
							<TableActionButton 
								type="edit"
								disabled={disabledFieldInput}
								onClick={()=> {
									setUpdateBranchTransferHistoryModal(true);
								}}/>
							{
								validation.values.branchTransferHistories.length > 1
								&&
								<TableActionButton 
									type="delete"
									disabled={disabledFieldInput}
									onClick={()=> {
										onClickDeleteBranchTransferHistoryPrompt(row);
									}}/>
							}
						</div>
					}
        </>
      ),
    }
	]

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: employeeDetail || {
			// firstName: "",
			// middleName: "",
			// lastName: "",
			title: "",
			preferredName: "",
			fullName: "",
			identityType: "",
			identityNo: "",
			dob: "1990-01-01",
			gender: "",
			raceName: "",
			raceId: "",
			mobileNo: "",
			email: "",
			username: "",
			roleName: "",
			roleId: "",
			positionName: "",
			positionId: "",
			businessEntityName: "",
			businessEntityId: "",
			branchName: "",
			branchId: "",
			employeeType: "",
			isEnableLogin: true,
			isReceiveNotification: true,
			employmentStartDate: "",
			employmentEndDate: "",
			confirmationDate: "",
			salary: "",
			personalSalesTarget: "",
			epfContributionId: "",
			epfContributionName: "",
			epfNo: "",
			epfName: "",
			incomeTaxNo: "",
			socsoNo: "",
			bankNo: "",
			bankName: "",
			fingerPrintId: "",
			// password: "",
			warehouseName: "",
			warehouseId: "",
			addressLine1: "",
			addressLine2: "",
			city: "",
			postcode: "",
			countryName: "",
			countryId: "",
			stateId: "",
			stateName: "",
			hrmsReferenceCode: "",
			accessTree: accessTree,
			file: undefined,
			photoUrl: "",
			defaultPayrollItems: [],
			leaveEntitlements: [],
			positionMovementHistories: [],
			branchTransferHistories: [],
			appolousId: null,
			isBypassReassignCustomerValidation: false,
			departmentId: "",
			departmentName: ""
		},
		validationSchema: Yup.object({
			email: Yup.string().email().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Email" }) })),
			roleId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Role" }) })),
			// lastName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LastName" }) })),
			// firstName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FirstName" }) })),
			preferredName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PreferredName" }) })),
			fullName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FullName" }) })),
			employeeType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EmployeeType" }) })),
			businessEntityId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "BusinessEntity" }) })),
			employmentStartDate: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EmploymentStartDate" }) })),
			employmentEndDate: Yup.string().test("employmentEndDate", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EmploymentEndDate" }) }), (value: any, context) => {
				if (!blnIsEnableLogin) {
					return value;
				}

				return true
			}).nullable(),
			mobileNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MobileNo" }) })),
			username: Yup.string().test("userName", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Username" }) }), (value: any, context) => {
				if (!employeeBranchType && blnIsEnableLogin) {
					return value;
				}

				return true;
			}),
			branchId: Yup.string().test("branchId", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }), (value: any, context) => {
				if (employeeBranchType) {
					return value;
				}

				return true
			}).nullable(),
			// password: Yup.string()
			// 	.nullable()
			// 	.test("isValidPass", intl.formatMessage({ id: "PasswordValidation" }), (value: any, context) => {
			// 		const hasUpperCase = /[A-Z]/.test(value);
			// 		const hasLowerCase = /[a-z]/.test(value);
			// 		const hasNumber = /[0-9]/.test(value);
			// 		const hasFulfillLength = value ? value.length > 7 && value.length < 13 : false;
			// 		let validConditions = 0;
			// 		const numberOfMustBeValidConditions = 4;
			// 		const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasFulfillLength];
			// 		conditions.forEach((condition) =>
			// 			condition ? validConditions++ : null
			// 		);
			// 		if ((!value && !addAction) || !blnIsEnableLogin) {
			// 			return true;
			// 		}

			// 		if (validConditions >= numberOfMustBeValidConditions) {
			// 			return true;
			// 		}
			// 		return false;
			// 	}),
			identityType: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityType" }) })),
			identityNo: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) })),
			positionId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Position" }) })),
			// epfContributionId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EPFContribution" }) })),
			// epfNo: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "EPFNo" }) })),
			countryId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) })),
			stateId: Yup.string()
				.test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
					if (stateList.length > 0 && !value) {
						return false;
					}
					return true;
				}).nullable(),
			stateName: Yup.string()
				.test("isValidPass", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
					if (stateList.length === 0 && !value) {
						return false;
					}
					return true;
				}),
			departmentId: Yup.string().test("departmentId", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Department" }) }), (value: any, context) => {
				if (employeeHQType) {
					return value;
				}

				return true
			}).nullable(),
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			// valuesTemp.accessTree = accessTree;
			try {
				if (newImageUrl) {
					let fileTemp = await returnBlobImage(newImageUrl);
					valuesTemp.file = fileTemp;
				}
				if (addAction) {
					await addEmployee(valuesTemp);
				} else {
					if(!blnPermissionReassignEmployeeCustomer){
						valuesTemp.isBypassReassignCustomerValidation = true;
					}
					let resultUpdateEmployee = await updateEmployee(valuesTemp);
					if (resultUpdateEmployee) {
						if (resultUpdateEmployee.status === Constants.failed) {
							validation.setFieldError("employmentEndDate", intl.formatMessage({id: resultUpdateEmployee.data}));
						}
					}
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const employeeHQType = validation.values.employeeType.toLocaleLowerCase().includes("hq");
	const employeeBranchType = validation.values.employeeType && !validation.values.employeeType.toLocaleLowerCase().includes("hq")
	const blnIsEnableLogin = validation.values.isEnableLogin
	const blnPermissionUpdateEmployee = checkPermission([PermissionConstants.UpdateEmployee]);
	const blnPermissionCreateEmployee = checkPermission([PermissionConstants.CreateEmployee]);
	const blnPermissionReassignEmployeeCustomer = checkPermission([PermissionConstants.ReassignEmployeeCustomer]);
	const blnPermissionResetEmployeePasscode = checkPermission([PermissionConstants.ResetEmployeePasscode]);
	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
	const blnShowAppolous = generalSettings?.isShowAppolous;

	useEffect(() => {
		if (addAction) {
			setEmployeeDetail(undefined);
			setBranchList([]);
			setStateList([]);
		}

		if (!addAction && !viewAction) {
			if (!checkPermission([PermissionConstants.UpdateEmployee], true)) {
				return;
			}
		}
		else if (addAction) {
			if (!checkPermission([PermissionConstants.CreateEmployee], true)) {
				return;
			}
		}
		else if (viewAction) {
			if (!checkPermission([PermissionConstants.ManageEmployee], true)) {
				return;
			}
		}

		if(viewAction && blnPermissionUpdateEmployee){
			burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.employee}/edit/${id}`)} })
		}
		if (viewAction && blnPermissionCreateEmployee) {
			burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ExportAsTemplate" })}`, onFunction: () => { exportEmployeeReport(true) } })
		}

		fetchEmployeeDetailAPI();
		
		if (id) {
			setTabBar(tabBarForEditViewEmployee);
		}

		return (() => {
			if (id && !addAction) {
				setEmployeeDetail(undefined);
			}
			setPositionDropdownList([]);
			setUserRoleDropdownList([]);
		})
	}, []);

	useEffect(() => {
		if (id && !addAction) {
			if (employeeDetail) {
				if (employeeDetail.countryId) {
					getStateWithLoading(employeeDetail.countryId, false)
				}
				if (employeeDetail.businessEntityId) {
					getBranchWithLoading(employeeDetail.businessEntityId, false)
				}
				if(employeeDetail.employeeType){
					getRolePositionWithLoading(employeeDetail.employeeType, false)
				}

				if (employeeDetail.employeeType === Constants.branchStaff && blnPermissionResetEmployeePasscode) {
					let indexResetPasscode = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "ResetPasscode" })}`})
					if (indexResetPasscode < 0) {
						burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "ResetPasscode" })}`, 
							onFunction: () => {
								setResetPasscodePrompt(true);
							}
						})
					}
				}
			}
		}
	}, [employeeDetail]);

	useEffect(() => {
		if (id && !addAction) {
			validation.setFieldValue("username", employeeDetail?.username || "");
		}
		else if (employeeBranchType || !validation.values.isEnableLogin) {
			validation.setFieldValue("username", "");
		}
		else {
			validation.setFieldValue("branchName", "");
			validation.setFieldValue("branchId", "");
		}
	}, [validation.values.employeeType, validation.values.isEnableLogin])

	useEffect(() => {
		async function refreshEmployeeDetailAPI() {
			setLoading(true);
			if (id && !addAction) {
				await getEmployeeWithId(id);
			}
			setLoading(false);
		}

		if (refreshPostionMovementHistoryData || refreshBranchTransferHistoryData) {
			refreshEmployeeDetailAPI();

			if(refreshPostionMovementHistoryData){
				setActiveTab('3');
				setRefreshPostionMovementHistoryData(false);
			}
	
			if(refreshBranchTransferHistoryData){
				setActiveTab('4')
				setRefreshBranchTransferHistoryData(false);
			}
		}
	}, [refreshPostionMovementHistoryData, refreshBranchTransferHistoryData]);

	useEffect(() => {
		if (id && !addAction) {
			if (employeeDetail) {
				if(blnPermissionReassignEmployeeCustomer && errorMessage == 'Employee-020'){
					setAddBranchTransferHistoryModal(false);
					setUpdateBranchTransferHistoryModal(false);
					setReassignEmployeeCustomerAlertModal(true);
				}
			}
		}
	}, [errorMessage]);

	// useEffect(() => {
	// 	let employeePayrollItemListTemp: EmployeePayrollItemObject[] = payrollItemDropdownList.map((value: PayrollItemDetailObject) => {
	// 		let payrollItemIdFound = false;
	// 		employeeDetail?.defaultPayrollItems.map((valueEmployeeDetail) => {
	// 			if (valueEmployeeDetail.payrollItemId === value.id) {
	// 				payrollItemIdFound = true;
	// 			}
	// 		})
	// 		return ({
	// 			payrollItemId: value.id,
	// 			payrollItemName: value.name,
	// 			amount: 0,
	// 			disabled: payrollItemIdFound
	// 		})
	// 	});
	// 	setEmployeePayrollItemList(employeePayrollItemListTemp);
	// }, [payrollItemDropdownList, employeeDetail])

	// useEffect(() => {
	// 	let employeeLeaveEntitlementListTemp: EmployeeLeaveEntitlementObject[] = leaveEntitlementDropdownList.map((value: LeaveEntitlementDetailObject) => {
	// 		let leaveEntitlementIdFound = false;
	// 		employeeDetail?.leaveEntitlements.map((valueEmployeeDetail) => {
	// 			if (valueEmployeeDetail.leaveEntitlementId === value.id) {
	// 				leaveEntitlementIdFound = true;
	// 			}
	// 		})
	// 		return ({
	// 			leaveEntitlementId: value.id,
	// 			leaveEntitlementName: value.name,
	// 			entitleQuantity: value.defaultQuantity,
	// 			disabled: leaveEntitlementIdFound
	// 		})
	// 	});
	// 	setEmployeeLeaveEntitlementList(employeeLeaveEntitlementListTemp);
	// }, [leaveEntitlementDropdownList, employeeDetail])

	useEffect(() => {
		if (validation.isSubmitting) {
			setBlnSubmitting(true);
		}

		if (!validation.isSubmitting && blnSubmitting) {
			setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	const blnEndDate = validation.values.employmentStartDate && validation.values.employmentStartDate !== "";

	const fetchEmployeeDetailAPI = async () =>{
		setLoading(true);
		let aryAPI: any = [
			getStaticReference(),
			getAccessTree(),
			getRaceDropdown(),
			getBusinessEntityDropDown(getBrandId()),
			getEPFContributionDropdown(),
			getCountryDropdown(),
			getGeneralSettings(),
			getDepartmentDropdown()
			// getPositionDropdown(),
			// getPayrollItemDropdown(),
			// getLeaveEntitlementDropdown(),
		];
		if (id && !addAction) {
			aryAPI.push(getEmployeeWithId(id));
			aryAPI.push(getBranchDropDownWithoutValidation());
		}
		let resultAPI = await Promise.all(aryAPI);

		let blnAddRessignCustomerButton = true;
		if (resultAPI.length > 8) {
			if (resultAPI[8].employeeType === Constants.hqStaff) {
				blnAddRessignCustomerButton = false;
			}
			if (resultAPI[9]) {
				setBranchDropdownForBranchHistories(resultAPI[9]);
			}
		}

		if ((id && !addAction) && blnPermissionReassignEmployeeCustomer && blnAddRessignCustomerButton) {
			burgerMenuListContent.push({ label: `${intl.formatMessage({id: "ReassignCustomer"})}`, onFunction: () => {setReassignCustomerModal(true)} })
		}
		setLoading(false);
	}

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const getRolePositionWithLoading = async (id: string, removeData: boolean = true) => {
		let valueIdTemp = id.toLocaleLowerCase().includes("hq");
		setLoadingRolePosition(true);
		let aryRolePositionAPI = [
			getPositionDropdown(valueIdTemp ? 0 : 1), // 0 = hq staff, 1 = branch staff
			getUserRoleDropdown(valueIdTemp ? 0 : 1)
		]
		await Promise.all(aryRolePositionAPI);
		if (removeData) {
			validation.setValues((values) => ({
				...values,
				positionId: "",
				positionName: "",
				roleId: "",
				roleName: "",
			}))
		}
		setLoadingRolePosition(false);
		if (valueIdTemp && addAction) {
			validation.setFieldValue("isEnableLogin", true)
		}
		else if (!valueIdTemp && addAction) {
			validation.setFieldValue("departmentId", "");
			validation.setFieldValue("departmentName", "");
		}
	}

	const getStateWithLoading = async (id: string, removeData: boolean = true) => {
		setLoadingState(true);
		await getStateWithId(id);
		if (removeData) {
			validation.setValues((values) => ({
				...values,
				stateId: "",
				stateName: "",
			}))
		}
		setLoadingState(false);
	};

	const getBranchWithLoading = async (id: string, removeData: boolean = true) => {
		setLoadingBranch(true);
		await getBranchDropDown({businessEntityId: id});
		if (removeData) {
			validation.setValues((values) => ({
				...values,
				branchId: "",
				branchName: "",
			}))
		}
		setLoadingBranch(false);
	};

	const returnTabBarIcon = (valueTab: string) => {
		let iconTabBar: any = null;
		if (valueTab === intl.formatMessage({ id: "Details" }).toUpperCase()) {
			iconTabBar = <CardAccountDetailsIcon size={Constants.menuIconSize} />;
		}
		else if (valueTab === intl.formatMessage({ id: "Branches" }).toUpperCase()) {
			iconTabBar = <OfficeBuildingMarkerIcon size={Constants.menuIconSize} />
		}
		else if (valueTab === intl.formatMessage({ id: "PositionMovementHistories" }).toUpperCase()) {
			iconTabBar = <AccountArrowRightIcon size={Constants.menuIconSize} />
		}
		else if (valueTab === intl.formatMessage({ id: "BranchTransferHistories" }).toUpperCase()) {
			iconTabBar = <HistoryIcon size={Constants.menuIconSize} />
		}
		return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
	}

	const exportEmployeeReport = async (blnExport: boolean) => {
    setLoading(true);
    if (blnExport) {
      await (exportEmployee({ exportType: 2, selectedIds: [id] }))
    }
    setLoading(false);
  }

	const onClickDeletePositionMovementHistoryPrompt = async (row: EmployeePositionMovementHistory) => {
    setSelectedPositionMovementHistoryRow(row);
    setShowDeletePositionMovementHistoryPrompt(true);
  }

	const onClickDeletePositionMovementHistory = async () => {
		setLoadingModal(true);
		let resultAPIDeleteEmployeePositionMovementHistory = await deleteEmployeePositionMovementHistory(selectedPositionMovementHistoryRow?.id || "", selectedPositionMovementHistoryRow?.employeeId || "");
		setLoadingModal(false);
		if (resultAPIDeleteEmployeePositionMovementHistory.status === Constants.success) {
			setRefreshPostionMovementHistoryData(true);
		}
	}

	const onClickDeleteBranchTransferHistoryPrompt = async (row: EmployeeBranchTransferHistory) => {
    setSelectedBranchTransferHistoryRow(row);
    setShowDeleteBranchTransferHistoryPrompt(true);
  }

	const onClickDeleteBranchTransferHistory = async () => {
		setLoadingModal(true);
		let resultAPIDeleteBranchTransferHistory = await deleteEmployeeBranchTransferHistory(selectedBranchTransferHistoryRow?.id || "", selectedBranchTransferHistoryRow?.employeeId || "");
		setLoadingModal(false);
		if (resultAPIDeleteBranchTransferHistory.status === Constants.success) {
			setRefreshBranchTransferHistoryData(true);
		}
	}
	
	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}
				burgerMenuList={burgerMenuListContent}
				auditTrailId={viewAction ? id : null}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="standard-layout"
						onSubmit={(e) => {
							e.preventDefault();
							if (Object.keys(validation.errors).length > 0) {
								setActiveTab("1");
								setTimeout(() => {
									window.scrollTo({
										top: 0,
										behavior: 'smooth',
									});
								}, 100)
							}
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Nav className="nav nav-tabs nav-tabs-custom">
							{tabBar.map((value, indexTab) => (
								<NavItem key={`tabkey_${indexTab}`}>
									<NavLink
										disabled={(indexTab == 1 && (employeeBranchType || false)) || (indexTab == 3 && (employeeHQType || false))}
										style={{ cursor: "pointer" }}
										className={classNames({
											active:
												activeTab ===
												String(indexTab + 1),
										})}
										onClick={() => {
											toggle(String(indexTab + 1));
										}}
									>
										{/* {value} */}
										{returnTabBarIcon(value)}
									</NavLink>
								</NavItem>
							))}
						</Nav>
						<TabContent
							activeTab={activeTab}
							className="margin-top-24"
						>
							<TabPane tabId="1">
								<Row form={+true}>
									{" "}
									{/* +true = Convert boolean to numbers*/}
									<Col xl={6}>
										<LineBreakWithTittle
											paddingBottom="0px"
											title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Personal" }) })}
											className="mt-0"
											blnSkipMarginTop
											h4Title
										/>
										{
											!addAction 
											&&
											validation.values.appolousId !== null
											&&
											blnShowAppolous
											&&
											<GeneralInputWithClickableUI
												title={intl.formatMessage({ id: "ViewAppolous" })}
												name="appolousId"
												className="mt-4"
												type="number"
												disabled={true}
												validation={validation}
												onClickFunction={()=> {
													window.open(`https://app.appolous.com/employees/${validation.values.appolousId}/edit`)
												}}/>
										}
										{/* <Row className={validation.values.appolousId !== null ? "mt-3" : "mt-4"}>
											<Col xl="4">
												<GeneralInput
													title={intl.formatMessage({ id: "FirstName" })}
													name="firstName"
													type="text"
													validationRequired={
														true
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</Col>
											<Col xl="4">
												<GeneralInput
													title={intl.formatMessage({ id: "MiddleName" })}
													name="middleName"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</Col>
											<Col xl="4">
												<GeneralInput
													title={intl.formatMessage({ id: "LastName" })}
													name="lastName"
													type="text"
													validationRequired={
														true
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</Col>
										</Row> */}
										<Row className={validation.values.appolousId !== null ? "mt-3" : "mt-4"}>
											<SingleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "FullName" })}
													name="fullName"
													type="text"
													validationRequired={true}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</SingleColumnRowParent>
										</Row>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<GeneralInput
												title={intl.formatMessage({ id: "PreferredName" })}
												name="preferredName"
												type="text"
												validationRequired={true}
												disabled={
													disabledFieldInput ||
													viewAction
												}
												validation={validation}
											/>
										</SingleColumnRowParent>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"raceId"}
													title={intl.formatMessage({ id: "Race" })}
													specifyReturnFieldName={[
														{
															field: "raceId",
															value: "id",
														},
													]}
													labelField={"name"}
													valueField={"id"}
													options={raceDropdownList}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													initialLabel={
														!addAction
															? validation
																.values
																.raceName
															: undefined
													}
													initialValue={
														!addAction
															? validation
																.values
																.raceId
															: undefined
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"title"}
													title={intl.formatMessage({ id: "Title" })}
													specifyReturnFieldName={[
														{
															field: "title",
															value: "displayValue",
														},
													]}
													labelField={
														"displayValue"
													}
													valueField={
														"displayValue"
													}
													blnValueWithNewSpace={true}
													options={titleType}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													initialValue={
														!addAction
															? validation
																.values
																.title
															: undefined
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"identityType"}
													title={intl.formatMessage({ id: "IdentityType" })}
													specifyReturnFieldName={[
														{
															field: "identityType",
															value: "displayValue",
														},
													]}
													labelField={
														"displayValue"
													}
													valueField={
														"displayValue"
													}
													blnValueWithNewSpace={true}
													options={identityType}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													initialValue={
														!addAction
															? validation
																.values
																.identityType
															: undefined
													}
													onChangeFunction={()=> {
														validation.setFieldValue("identityNo", "");
													}}
													validationRequired={true}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "IdentityNo" })}
													name="identityNo"
													type="text"
													blnAlphaNumberic={validation.values.identityType === Constants.passport ? false : true}
													validationRequired={
														true
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "DateOfBirth" })}
													name="dob"
													type="date"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"gender"}
													title={intl.formatMessage({ id: "Gender" })}
													specifyReturnFieldName={[
														{
															field: "gender",
															value: "displayValue",
														},
													]}
													labelField={
														"displayValue"
													}
													valueField={
														"displayValue"
													}
													blnValueWithNewSpace={true}
													options={genderType}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													initialValue={
														!addAction
															? validation
																.values
																.gender
															: undefined
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "MobileNo" })}
													name="mobileNo"
													type="number"
													blnNumberOnly={true}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validationRequired={true}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "Email" })}
													name="email"
													type="text"
													validationRequired={
														true
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<LineBreakWithTittle
											title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Address" }) })}
											className="mt-4"
											h4Title
										/>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<GeneralInput
												title={intl.formatMessage({ id: "AddressLine1" })}
												name="addressLine1"
												type="text"
												disabled={
													disabledFieldInput ||
													viewAction
												}
												validation={validation}
											/>
										</SingleColumnRowParent>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<GeneralInput
												title={intl.formatMessage({ id: "AddressLine2" })}
												name="addressLine2"
												type="text"
												disabled={
													disabledFieldInput ||
													viewAction
												}
												validation={validation}
											/>
										</SingleColumnRowParent>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "City" })}
													name="city"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "PostCode" })}
													name="postcode"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"countryId"}
													title={intl.formatMessage({ id: "Country" })}
													specifyReturnFieldName={[
														{
															field: "countryId",
															value: "id",
														},
													]}
													labelField={"name"}
													valueField={"id"}
													options={countryDropdownList}
													disabled={
														disabledFieldInput ||
														viewAction ||
														loadingState
													}
													onChangeFunction={
														getStateWithLoading
													}
													initialLabel={
														!addAction
															? validation
																.values
																.countryName
															: undefined
													}
													initialValue={
														!addAction
															? validation
																.values
																.countryId
															: undefined
													}
													validationRequired={true}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												{loadingState ? (
													<Loading />
												) : (
													stateList.length > 0
														?
														<DropDownWithTitle
															name={"stateId"}
															title={intl.formatMessage({ id: "State" })}
															specifyReturnFieldName={[
																{
																	field: "stateId",
																	value: "id",
																},
																{
																	field: "stateName",
																	value: "name",
																},
															]}
															labelField={"name"}
															valueField={"id"}
															options={stateList}
															disabled={
																disabledFieldInput ||
																viewAction
															}
															initialLabel={
																!addAction
																	? validation
																		.values
																		.stateName
																	: undefined
															}
															initialValue={
																!addAction
																	? validation
																		.values
																		.stateId
																	: undefined
															}
															validationRequired={stateList.length > 0 ? true : false}
															validation={
																validation
															}
														/>
														:
														<GeneralInput
															title={intl.formatMessage({ id: "StateName" })}
															name="stateName"
															type="text"
															disabled={
																disabledFieldInput ||
																viewAction
															}
															validationRequired={true}
															validation={validation}
														/>
												)}
											</DoubleColumnRowParent>
										</Row>
										{/*
                        accessTree: accessTree,
                        file: undefined */}
									</Col>
									<Col xl={6} className={`${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-8" : ""}`}>
										<LineBreakWithTittle
											paddingBottom="0px"
											title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
											className="mt-0"
											blnSkipMarginTop
											h4Title
										/>
										{
											!addAction
											&&
											<SingleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "EmployeeNo" })}
													name="employeeNo"
													className={"mb-3 mt-4"}
													type="text"
													disabled={true}
													validation={validation}
												/>
											</SingleColumnRowParent>
										}
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<GeneralInput
												title={intl.formatMessage({ id: "HR2000ReferenceNo" })}
												name="hrmsReferenceCode"
												className={addAction ? "mb-3 mt-4" : ""}
												type="text"
												disabled={
													disabledFieldInput ||
													viewAction
												}
												validation={validation}
											/>
										</SingleColumnRowParent>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<DropDownWithTitle
												name={"employeeType"}
												title={intl.formatMessage({ id: "EmployeeType" })}
												specifyReturnFieldName={[
													{
														field: "employeeType",
														value: "displayValue",
													},
												]}
												labelField={"displayValue"}
												valueField={"displayValue"}
												blnValueWithNewSpace={true}
												options={employeeType}
												disabled={
													disabledFieldInput ||
													viewAction ||
													loadingRolePosition ||
													!addAction
												}
												initialValue={
													!addAction
														? validation.values
															.employeeType
														: undefined
												}
												onChangeFunction={getRolePositionWithLoading}
												validationRequired={true}
												validation={validation}
											/>
										</SingleColumnRowParent>
										{
											employeeHQType
											&&
											<SingleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={"departmentId"}
													title={intl.formatMessage({ id: "Department" })}
													specifyReturnFieldName={[
														{
															field: "departmentId",
															value: "id",
														},
														{
															field: "departmentName",
															value: "name",
														}
													]}
													labelField={"name"}
													valueField={"id"}
													options={departmentDropdownList}
													disabled={
														disabledFieldInput ||
														viewAction ||
														loadingRolePosition
													}
													initialLabel={!addAction ? validation.values.departmentName: undefined}
													initialValue={!addAction ? validation.values.departmentId: undefined}
													validationRequired={true}
													validation={validation}
												/>
											</SingleColumnRowParent>
										}
										<Row>
											{
												loadingRolePosition ?
													<Loading />
													:
													<>
														<DoubleColumnRowParent
															blnDoubleTab={true}>
															<DropDownWithTitle
																name={"roleId"}
																title={intl.formatMessage({ id: "Role" })}
																specifyReturnFieldName={[
																	{
																		field: "roleId",
																		value: "id",
																	},
																]}
																labelField={"name"}
																valueField={"id"}
																blnValueWithNewSpace={true}
																options={userRoleDropdownList}
																disabled={disabledFieldInput || !addAction}
																initialLabel={!addAction ? validation.values.roleName : undefined}
																initialValue={!addAction ? validation.values.roleId : undefined}
																validationRequired={true}
																validation={validation}
															/>
														</DoubleColumnRowParent>
														<DoubleColumnRowParent
															blnDoubleTab={true}>
															<DropDownWithTitle
																name={"positionId"}
																title={intl.formatMessage({ id: "Position" })}
																specifyReturnFieldName={[
																	{
																		field: "positionId",
																		value: "id",
																	},
																]}
																labelField={"name"}
																valueField={"id"}
																options={positionDropdownList}
																disabled={disabledFieldInput || !addAction}
																initialLabel={!addAction ? validation.values.positionName : undefined}
																initialValue={!addAction ? validation.values.positionId : undefined}
																validationRequired={true}
																validation={validation}
															/>
														</DoubleColumnRowParent>
													</>
											}
										</Row>
										<Row>
											<Col
												xl={
													validation.values
														.employeeType
														? validation.values.employeeType
															.toLowerCase()
															.includes(
																"hq"
															)
															? "12"
															: "6"
														: "6"
												}
											>
												<DropDownWithTitle
													name={
														"businessEntityId"
													}
													title={
														"Business Entity"
													}
													specifyReturnFieldName={[
														{
															field: "businessEntityId",
															value: "id",
														},
													]}
													labelField={"name"}
													valueField={"id"}
													options={
														businessEntityDropdownList
													}
													disabled={
														disabledFieldInput ||
														!addAction || 
                            loadingBranch
													}
													onChangeFunction={
														getBranchWithLoading
													}
													initialLabel={
														!addAction
															? validation
																.values
																.businessEntityName
															: undefined
													}
													initialValue={
														!addAction
															? validation
																.values
																.businessEntityId
															: undefined
													}
													validationRequired={true}
													validation={validation}
												/>
											</Col>
											{!validation.values
												.employeeType || employeeBranchType ? (
												<Col xl="6">
													{loadingBranch ? (
														<Loading />
													) : (
														<DropDownWithTitle
															name={
																"branchId"
															}
															title={"Branch"}
															specifyReturnFieldName={[
																{
																	field: "branchId",
																	value: "id",
																},
															]}
															labelField={
																"name"
															}
															valueField={
																"id"
															}
															options={
																branchDropDownList
															}
															disabled={
																disabledFieldInput ||
																!addAction
															}
															initialLabel={
																!addAction
																	? validation
																		.values
																		.branchName
																	: undefined
															}
															initialValue={
																!addAction
																	? validation
																		.values
																		.branchId
																	: undefined
															}
															validationRequired={true}
															validation={
																validation
															}
														/>
													)}
												</Col>
											) : null}
										</Row>
										{/* <DropDownWithTitle
												name={"warehouseId"}
												title={"Warehouse"}
												specifyReturnFieldName={[
													{
														field: "warehouseId",
														value: "id",
													},
												]}
												labelField={"name"}
												valueField={"id"}
												options={[]}
												disabled={
													disabledFieldInput ||
													viewAction
												}
												initialLabel={
													!addAction
														? validation.values
																.warehouseName
														: undefined
												}
												initialValue={
													!addAction
														? validation.values
																.warehouseId
														: undefined
												}
												validation={validation}
											/> */}
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "EmploymentStartDate" })}
													name="employmentStartDate"
													type="date"
													validationRequired={
														true
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
                        <GeneralInput
													title={intl.formatMessage({ id: "EmployeeConfirmationDate" })}
													name="confirmationDate"
													type="date"
													disabled={
														disabledFieldInput ||
														viewAction || 
														!blnEndDate
													}
													minCurrentDate={true}
                          minDateValue={validation.values.employmentStartDate ? moment(validation.values.employmentStartDate).format(Constants.displayDateFormat) : ""}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<Col xs={6} md={6} xl={6}>
												<GeneralInput
													title={intl.formatMessage({ id: "EnableLogin" })}
													name="isEnableLogin"
													type="checkbox"
													disabled={
														disabledFieldInput ||
														viewAction || 
                            (addAction && employeeHQType)
													}
													validation={validation}
													childrenUI={
														<Label className="margin-bottom-0 margin-left-4">
															Yes
														</Label>
													}
												/>
											</Col>
											<Col xs={6} md={6} xl={6}>
                        <GeneralInput
													title={intl.formatMessage({ id: "EmploymentEndDate" })}
													name="employmentEndDate"
													type="date"
													disabled={
														disabledFieldInput ||
														viewAction ||
														!blnEndDate
													}
													minCurrentDate={true}
                          minDateValue={validation.values.employmentStartDate ? moment(validation.values.employmentStartDate).format(Constants.displayDateFormat) : ""}
													validation={validation}
												/>
												{/* <GeneralInput
													title={intl.formatMessage({ id: "ReceiveNotification" })}
													name="isReceiveNotification"
													type="checkbox"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
													childrenUI={
														<Label className="margin-bottom-0 margin-left-4">
															{intl.formatMessage({ id: "Yes" })}
														</Label>
													}
												/> */}
											</Col>
										</Row>
										{
											validation.values.isEnableLogin
											&&
											<div>
												<LineBreakWithTittle
													title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "UserLogin" }) })}
													className="mt-4"
													h4Title
												/>
												{/* <Row> */}
													<SingleColumnRowParent
														blnDoubleTab>
														<GeneralInput
															title={intl.formatMessage({ id: "Username" })}
															name="username"
															type="text"
															disabled={
																disabledFieldInput ||
																viewAction ||
																employeeBranchType ||
																false
															}
															validationRequired={true}
															validation={validation}
														/>
													</SingleColumnRowParent>
													{/* <DoubleColumnRowParent
														blnDoubleTab={true}>
														<GeneralInput
															title={intl.formatMessage({ id: "Password" })}
															name="password"
															type="password"
															validationRequired={
																addAction && true
															}
															disabled={
																disabledFieldInput ||
																viewAction
															}
															validation={validation}
														/>
													</DoubleColumnRowParent> */}
												{/* </Row> */}
											</div>
										}
										{/* <LineBreakWithTittle
											title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Salary" }) })}
											className="mt-4"
											h4Title
										/>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "Salary" })}
													name="salary"
													type="number"
													inputGroup={true}
													inputGroupText={returnCurrency()}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "PersonalSalesTarget" })}
													name="personalSalesTarget"
													type="number"
													inputGroup={true}
													inputGroupText={returnCurrency()}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<DropDownWithTitle
													name={
														"epfContributionId"
													}
													title={
														intl.formatMessage({ id: "EPFContribution" })
													}
													specifyReturnFieldName={[
														{
															field: "epfContributionId",
															value: "id",
														},
													]}
													labelField={"name"}
													valueField={"id"}
													options={
														epfContributionDropdownList
													}
													disabled={
														disabledFieldInput ||
														viewAction
													}
													initialLabel={
														!addAction
															? validation
																.values
																.epfContributionName
															: undefined
													}
													initialValue={
														!addAction
															? validation
																.values
																.epfContributionId
															: undefined
													}
													validationRequired={true}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "EPFNo" })}
													name="epfNo"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validationRequired={true}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "IncomeTaxNo" })}
													name="incomeTaxNo"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "SocsoNo" })}
													name="socsoNo"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row>
										<Row>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "BankName" })}
													name="bankName"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
											<DoubleColumnRowParent
												blnDoubleTab={true}>
												<GeneralInput
													title={intl.formatMessage({ id: "BankNo" })}
													name="bankNo"
													type="text"
													disabled={
														disabledFieldInput ||
														viewAction
													}
													validation={validation}
												/>
											</DoubleColumnRowParent>
										</Row> */}
										{/* <LineBreakWithTittle
											title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Attendance" }) })}
											className="mt-4"
											h4Title
										/>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<GeneralInput
												title={intl.formatMessage({ id: "FingerPrint" })}
												name="fingerPrintId"
												type="text"
												disabled={
													disabledFieldInput ||
													viewAction
												}
												validation={validation}
											/>
										</SingleColumnRowParent> */}
										<LineBreakWithTittle
											title={intl.formatMessage({ id: "EmployeePhoto" })}
											className="mt-4"
											h4Title
										/>
										<SingleColumnRowParent
											blnDoubleTab={true}>
											<DropZoneUploadWidget
												name="file"
												validation={validation}
												setNewImageUrl={setNewImageUrl}
												disabled={
													disabledFieldInput ||
													viewAction
												}
												imageUrl={
													validation.values.photoUrl
												}
											/>
										</SingleColumnRowParent>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<Col xl="12">
									<TreeView
										name="accessTree"
										title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Branch" }) })}
										blnEditable={!viewAction}
										data={validation.values.accessTree}
										aryField={[
											"brands",
											"businessEntities",
											"branchAccess",
										]}
										validation={validation}
										disabled={disabledFieldInput}
									/>
								</Col>
							</TabPane>
							{/* <TabPane tabId="3">
								<Col xl="12" style={{ marginBottom: "20px" }}>
									<LineBreakWithTittle
										paddingBottom="0px"
										title={intl.formatMessage({ id: "PayrollItems" })}
										h4Title />
									<SingleColumnRowParent
										blnDoubleTab>
										<TableWithEditableInputFields
											name="defaultPayrollItems"
											title=""
											buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "PayrollItems" }) })}
											blnEditable={!viewAction}
											blnSupportWithModal={true}
											blnBtnModalBottom={true}
											options={employeePayrollItemList}
											optionsId={"payrollItemId"}
											setOptionsFunction={setEmployeePayrollItemList}
											addButtonFunction={() => setPayrollItemModal(true)}
											aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabPayrollTypeTab}
											data={validation.values.defaultPayrollItems}
											validation={validation}
											disabled={disabledFieldInput} />
									</SingleColumnRowParent>
								</Col>
							</TabPane>
							<TabPane tabId="4">
								<Col xl="12" style={{ marginBottom: "20px" }}>
									<LineBreakWithTittle
										paddingBottom="0px"
										title={intl.formatMessage({ id: "LeaveEntitlement" })}
										h4Title />
									<SingleColumnRowParent
										blnDoubleTab>
										<TableWithEditableInputFields
											name="leaveEntitlements"
											title=""
											buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "LeaveEntitlement" }) })}
											blnEditable={!viewAction}
											blnSupportWithModal={true}
											blnBtnModalBottom={true}
											options={employeeLeaveEntitlementList}
											optionsId={"leaveEntitlementId"}
											setOptionsFunction={setEmployeeLeaveEntitlementList}
											addButtonFunction={() => setLeaveEntitlementModal(true)}
											aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxLeaveEntitlementTab}
											data={validation.values.leaveEntitlements}
											validation={validation}
											disabled={disabledFieldInput} />
									</SingleColumnRowParent>
								</Col>
							</TabPane> */}
              <TabPane tabId="3">
								<Col xl="12" style={{ marginBottom: "20px" }}>
									{
										validation.values.positionMovementHistories 
										&& 
										validation.values.positionMovementHistories.length > 0
										&&	
										blnPermissionUpdateEmployee
										&&
										<div className="flex-right">
                      <MyButton
                        type="button"
                        class={`btn btn-success margin-right-8`}
                        content={intl.formatMessage({ id: "AddPositionMovementHistory" })}
                        disable={disabledFieldInput}
                        loading={disabledFieldInput}
                        onClick={() => {
													setAddPositionMovementHistoryModal(true);
                        }}
                      />
                    </div>
									}
									<LineBreakWithTittle
										className="mt-2"
										paddingBottom="0px"
										title={intl.formatMessage({ id: "PositionMovementHistories" })}
										h4Title />
									<SingleColumnRowParent blnDoubleTab>
										{
											!refreshPostionMovementHistoryData 
											&&
											<div className="tab_bar_border">
												<div className="mt-2">
													<PaginationTableWithoutApi 
														title={intl.formatMessage({ id: "PositionMovementHistories" })}
														options={validation.values.positionMovementHistories}
														columns={PositionMovementHistoriesColumns}
														pageSize={Constants.maxPageSize}
														keyField={"id"}/>
												</div>
											</div>
										}
									</SingleColumnRowParent>
								</Col>
							</TabPane>
							<TabPane tabId="4">
								<Col xl="12" style={{ marginBottom: "20px" }}>
									{								
										validation.values.branchTransferHistories 
										&& 
										validation.values.branchTransferHistories.length > 0
										&&	
										blnPermissionUpdateEmployee
										&&
										<div className="flex-right">
											<MyButton
												type="button"
												class={`btn btn-success margin-right-8`}
												content={intl.formatMessage({ id: "AddBranchTransferHistory" })}
												disable={
													validation.isSubmitting
												}
												loading={
													validation.isSubmitting
												}
												onClick={() => {
													setAddBranchTransferHistoryModal(true);
												}}
											/>
										</div>
									}
									<LineBreakWithTittle
										paddingBottom="0px"
										title={intl.formatMessage({ id: "BranchTransferHistories" })}
										h4Title />
									<SingleColumnRowParent blnDoubleTab>
										{
											!refreshBranchTransferHistoryData 
											&&
											<div className="tab_bar_border">
												<div className="mt-2">
													<PaginationTableWithoutApi 
														title={intl.formatMessage({ id: "BranchTransferHistories" })}
														options={validation.values.branchTransferHistories}
														columns={BranchTransferHistoriesColumns}
														pageSize={Constants.maxPageSize}
														keyField={"id"}/>
												</div>
											</div>
										}
									</SingleColumnRowParent>
								</Col>
							</TabPane>
						</TabContent>
						<GeneralSubmitAndCancelBtn
							successMessage={successMessage}
							viewAction={viewAction}
							validation={validation}
						/>
					</Form>
				)}
				<SelectDropDownModal
					blnShow={payrollItemModal}
					setModal={setPayrollItemModal}
					title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "PayrollItem" }) })}
					buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "PayrollItem" }) })}
					placeholder={"- Please Select -"}
					labelField={"payrollItemName"}
					valueField={"payrollItemId"}
					options={AddOptionsValueField(employeePayrollItemList, "name", "id")}
					setOptionsFunction={setEmployeePayrollItemList}
					fieldInput={validation.values.defaultPayrollItems}
					fieldName={"defaultPayrollItems"}
					validation={validation} />
				<SelectDropDownModal
					blnShow={leaveEntitlementModal}
					setModal={setLeaveEntitlementModal}
					title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "LeaveEntitlement" }) })}
					buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "LeaveEntitlement" }) })}
					placeholder={"- Please Select -"}
					labelField={"leaveEntitlementName"}
					valueField={"leaveEntitlementId"}
					options={AddOptionsValueField(employeeLeaveEntitlementList, "name", "id")}
					setOptionsFunction={setEmployeeLeaveEntitlementList}
					fieldInput={validation.values.leaveEntitlements}
					fieldName={"leaveEntitlements"}
					validation={validation} />
				{
					reassignCustomerModal
					&&
					<ReassignCustomerModal
						blnShow={reassignCustomerModal}
						setModal={setReassignCustomerModal}
						selectedEmployee={employeeDetail ? employeeDetail : undefined}
						getCustomer={getCustomer}
						getEmployeeDropdown={getEmployeeDropdown}
						reassignCustomer={reassignCustomer}
					/>
				}
				{
					reassignEmployeeCustomerAlertModal
					&&
					<ActionPromptModal
						title={`${intl.formatMessage({ id: "ReassignEmployeeCustomerAlert" }, {br: <br/>})}`}
						primaryActionTitle={`Confirm`}
						showPrompt={reassignEmployeeCustomerAlertModal}
						setShowPrompt={setReassignEmployeeCustomerAlertModal}
						onPrimaryClick={async () => {
							setReassignCustomerModal(true);
						}}
					/>
				}
				{
					addPositionMovementHistoryModal
					&&
					<AddPositionMovementHistoryModal
						blnShow={addPositionMovementHistoryModal}
						setModal={setAddPositionMovementHistoryModal}
						addEmployeePositionMovementHistory={addEmployeePositionMovementHistory}
						validation={validation}
						setRefreshPostionMovementHistoryData={setRefreshPostionMovementHistoryData}
						positionDropdownList={positionDropdownList}
						userRoleDropdownList={userRoleDropdownList}
					/>
				}
				{
					updatePositionMovementHistoryModal
					&&
					<UpdatePositionMovementHistoryModal
						blnShow={updatePositionMovementHistoryModal}
						setModal={setUpdatePositionMovementHistoryModal}
						updateEmployeePositionMovementHistory={updateEmployeePositionMovementHistory}
						validation={validation}
						setRefreshPostionMovementHistoryData={setRefreshPostionMovementHistoryData}
						positionDropdownList={positionDropdownList}
						userRoleDropdownList={userRoleDropdownList}
					/>
				}
				{
          showDeletePositionMovementHistoryPrompt
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "DeletePositionMovementHistory" }, { employeeName: selectedPositionMovementHistoryRow?.employeeName, positionName: selectedPositionMovementHistoryRow?.positionName})}`}
            primaryActionTitle={`Yes`}
            showPrompt={showDeletePositionMovementHistoryPrompt}
            setShowPrompt={setShowDeletePositionMovementHistoryPrompt}
            onPrimaryClick={() => { onClickDeletePositionMovementHistory() }}
          />
        }
				{
					addBranchTransferHistoryModal
					&&
					<AddBranchTransferHistoryModal
						blnShow={addBranchTransferHistoryModal}
						setModal={setAddBranchTransferHistoryModal}
						addEmployeeBranchTransferHistory={addEmployeeBranchTransferHistory}
						validation={validation}
						setRefreshBranchTransferHistoryData={setRefreshBranchTransferHistoryData}
						branchDropdownList={branchDropdownForBranchHistories}
						blnPermissionReassignEmployeeCustomer={blnPermissionReassignEmployeeCustomer}
					/>
				}
				{
					updateBranchTransferHistoryModal
					&&
					<UpdateBranchTransferHistoryModal
						blnShow={updateBranchTransferHistoryModal}
						setModal={setUpdateBranchTransferHistoryModal}
						updateEmployeeBranchTransferHistory={updateEmployeeBranchTransferHistory}
						validation={validation}
						setRefreshBranchTransferHistoryData={setRefreshBranchTransferHistoryData}
						branchDropdownList={branchDropdownForBranchHistories}
						blnPermissionReassignEmployeeCustomer={blnPermissionReassignEmployeeCustomer}
					/>
				}
				{
          showDeleteBranchTransferHistoryPrompt
          &&
          <ActionPromptModal
            title={`${intl.formatMessage({ id: "DeleteBranchTransferHistory" }, { employeeName: selectedBranchTransferHistoryRow?.employeeName, branchName: selectedBranchTransferHistoryRow?.branchName})}`}
            primaryActionTitle={`Yes`}
            showPrompt={showDeleteBranchTransferHistoryPrompt}
            setShowPrompt={setShowDeleteBranchTransferHistoryPrompt}
            onPrimaryClick={() => { onClickDeleteBranchTransferHistory() }}
          />
        }
				{
					loadingModal
					&&
					<LoadingModal/>
				}
				{
          resetPasscodePrompt
          &&
          <ActionPromptModal 
            title={intl.formatMessage({id: "EmployeePasscodeResetMessage"}, {name: `${employeeDetail?.preferredName} (${employeeDetail?.employeeNo})`})}
            primaryActionTitle={`Yes`}
            showPrompt={resetPasscodePrompt}
            setShowPrompt={setResetPasscodePrompt}
            localLoading={loadingResetPasscode}
            onPrimaryClick={async ()=> {
              setLoadingResetPasscode(true);
              let resultEmployeePasswordReset = await resetEmployeePasscode(employeeDetail?.id || "");
              if (resultEmployeePasswordReset.status === Constants.success) {
                setPasscodeFromResetPasscodeResponse(resultEmployeePasswordReset.data.passcode)
                setResetPasscodeSuccessPrompt(true);
                setResetPasscodePrompt(false);
              }
              setLoadingResetPasscode(false);
            }}/>
        }
        {
          resetPasscodeSuccessPrompt
          &&
          <ActionPromptModal 
            title={intl.formatMessage({id: "EmployeePasscodeResetMessageSuccess"}, {name: `${employeeDetail?.preferredName} (${employeeDetail?.employeeNo})`, passcode: passcodeFromResetPasscodeResponse})}
            primaryActionTitle={`Yes`}
            showPrompt={resetPasscodeSuccessPrompt}
            setShowPrompt={setResetPasscodeSuccessPrompt}
            blnHideCancelButton={true}
            blnShowCloseButton={true}
            onPrimaryClick={()=> {}}/>
        }
			</DetailViewLayout>
		</div>
	);
};

export default observer(EmployeeDetail);
