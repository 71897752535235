import React, { useState } from 'react'
import GeneralInput from './GeneralInput';
import { Label, Tooltip } from 'reactstrap';
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';
import { Constants } from '../../constants/Constants';

interface Props {
  title: string;
  name: string;
  toolTipMessage?: string;
  className?: string;
  type: string;
  disabled: boolean;
  validation: any;
  validationRequired?: boolean;
  onClickFunction: any;
}

export default function GeneralInputWithClickableUI(props: Props) {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <div
      className={props.className}
      onClick={()=> {
        props.onClickFunction();
      }}>
      <Label className={"pointer-clickable"}>
        {props.validationRequired && !props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
        <Label className="margin-bottom-0">{props.title}</Label>
        <ArrowTopRightBoldBoxOutlineIcon className="margin-left-4" size={18} color={Constants.tabBlue}/>
        {
          props.toolTipMessage
          &&
          <>
            <Tooltip
              placement="right"
              isOpen={showToolTip}
              target={`${props.name}_tooltip`}
              toggle={() => {
                setShowToolTip(!showToolTip);
              }}>
              {props.toolTipMessage}
            </Tooltip>
          </>
        }
      </Label>
      <GeneralInput
        title={""}
        name={props.name}
        className="mb-0"
        type={props.type}
        disabled={props.disabled}
        validationRequired={props.validationRequired}
        validation={props.validation}
      />
    </div>
  )
}