import { useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { AdditionBonusSalesTargetDuplicatedRequestObject } from "../../../app/models/additionBonusSalesTarget";
import moment from "moment";

interface Props {
  blnShow: boolean;
  setModal: Function;
}

export default observer(function AdditionBonusSalesTargetDuplicateModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { additionBonusSalesTargetStore, commonStore, customerStore } = useStore();
  const { successMessage, errorMessage, loading, totalItem, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { duplicateAdditionBonusSalesTarget } = additionBonusSalesTargetStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [searchValueObj, setSearchValueObj] = useState<AdditionBonusSalesTargetDuplicatedRequestObject>({duplicateFromYear: moment().format("YYYY"), startYear: "", endYear: ""})

  //Default Sorting Type
  const defaultSorted = [];

  const disabledFieldInput = loading || localLoading || Boolean(successMessage) || Boolean(errorMessage);

  const clearSearchValueObj = () => {
    setSearchValueObj({duplicateFromYear: "", startYear: "", endYear: ""})
  }

  const hideModal = () => {
    clearSearchValueObj();
    props.setModal(false);
  }

  const fetchValidationWithLoading = async () => {
    if (!searchValueObj.duplicateFromYear) {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DuplicateFromYear" }) }));
      return;
    }
    if (!searchValueObj.startYear) {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "StartYear" }) }));
      return;
    }
    if (!searchValueObj.endYear) {
      setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "EndYear" }) }));
      return;
    }
    setLocalLoading(true);

    let resultAdditionBonusSalesTargetDuplicate = await duplicateAdditionBonusSalesTarget(searchValueObj);
    if (resultAdditionBonusSalesTargetDuplicate?.status) {
      if (resultAdditionBonusSalesTargetDuplicate.status === Constants.success) {
        setTimeout(()=> {
          props.setModal(false);
        }, Constants.timeoutNavigation)
      }
    }
    setLocalLoading(false);
  };

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size='xl'
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "DuplicateAdditionBonusSalesTarget"})}</h5>
        {
          !disabledFieldInput
          &&
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className="standard-layout">
          {
            loading
            ?
            <Loading />
            :
            <Row className={`align-items-md-center ${localLoading && "margin-right-32"}`}>
              <Col xl="3">
                <GeneralInput
                  title={intl.formatMessage({ id: "DuplicateFromYear" })}
                  name="duplicateFromYear"
                  type="text"
                  disabled={disabledFieldInput}
                  validationRequired={true}
                  blnModal={true}
                  field={searchValueObj}
                  setFieldValue={setSearchValueObj}
                />
              </Col>
              <Col xl="3">
                <GeneralInput
                  title={intl.formatMessage({ id: "StartYear" })}
                  name="startYear"
                  type="text"
                  disabled={disabledFieldInput}
                  validationRequired={true}
                  blnModal={true}
                  field={searchValueObj}
                  setFieldValue={setSearchValueObj}
                />
              </Col>
              <Col xl="3">
                <GeneralInput
                  title={intl.formatMessage({ id: "EndYear" })}
                  name="endYear"
                  type="text"
                  disabled={disabledFieldInput}
                  validationRequired={true}
                  blnModal={true}
                  field={searchValueObj}
                  setFieldValue={setSearchValueObj}
                />
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class="btn btn-primary margin-top-12"
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={disabledFieldInput}
                  onClick={() => {
                    fetchValidationWithLoading()
                  }}
                />
              </Col>
            </Row>
          }
        </div>
      </div>
    </Modal>
  )
});