import React, { useState, useEffect, useRef } from "react";
import { Col, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithSelectAllCheckBoxAndInput from "../../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import { Constants } from "../../../app/constants/Constants";
import moment from "moment";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import Borderline from "../../../app/components/form/BorderLine";
import { LeadListObject } from "../../../app/models/lead";
import { EmployeeDetailObject, EmployeeListObject } from "../../../app/models/employee";
import { getBrandId, leadStatusColor, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import { CustomerListObject } from "../../../app/models/customer";
import GeneralInput from "../../../app/components/form/GeneralInput";
import ScrollButton from "../../../app/components/scrollButton/ScrollButton";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedEmployee: EmployeeDetailObject | undefined;
  getCustomer: Function;
  getEmployeeDropdown: Function;
  reassignCustomer: Function;
}

export default observer(function ReassignCustomerModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { setErrorMessage } = commonStore;
  const [loadingReassign, setLoadingReassign] = useState(false);
  const [loadingModalAPI, setLoadingModalAPI] = useState(false);
  const pageSize = Constants.maxPageSize;
  const [reassignCustomerList, setReassignCustomerList] = useState<CustomerListObject[]>([]);
  const [toConsultantList, setToConsultantList] = useState<EmployeeListObject[]>([]);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false);
  const refTopDiv : any = useRef(null);
  const refBottomDiv : any = useRef(null);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] =
    [
      {
        label: intl.formatMessage({ id: "Name" }).toUpperCase(),
        type: "label",
        xl: "2.6",
        inputName: "preferredName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "Branch" }).toUpperCase(),
        type: "label",
        xl: "2.6",
        inputName: "branchName",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
        type: "label",
        xl: "2.6",
        inputName: "mobileNo",
        inputType: "text",
      },
      // {
      //   label: intl.formatMessage({ id: "Email" }).toUpperCase(),
      //   type: "label",
      //   xl: "2.4",
      //   inputName: "email",
      //   inputType: "text",
      // },
      {
        label: intl.formatMessage({ id: "JoinedDate" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "joinedDate",
        inputType: "text",
      },
      {
        label: intl.formatMessage({ id: "LastVisitDate" }).toUpperCase(),
        type: "label",
        xl: "2",
        inputName: "lastVisitDate",
        inputType: "text",
      },
    ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerListDetails: [],
      fromConsultantId: "",
      fromConsultantName: "",
      toConsultantId: "",
      toConsultantName: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    async function fetchEmployeeCustomerAPI() {
      if(props.selectedEmployee){
        setLoadingModalAPI(true);
        let aryAPI: any = [
          props.getEmployeeDropdown({ 
            brandId: getBrandId(), 
            branchId: props.selectedEmployee.branchId, 
            isBranchStaff: true,
            userRoleIds: [`${Constants.branchManagerUserRoleId},${Constants.consultantUserRoleId}`], 
            isActive: true }),
          props.getCustomer({ 
            pageNumber: 1, 
            pageSize: pageSize, 
            branchId: props.selectedEmployee.branchId, 
            consultantId: props.selectedEmployee.id}, true)
        ];
  
        let resultAPI = await Promise.all(aryAPI);
        if(resultAPI[0]){
          let toConsultantListTemp = _.cloneDeep(resultAPI[0]);
          _.remove(toConsultantListTemp, {
              id: props.selectedEmployee.id
          });
          validation.setFieldValue("fromConsultantId", props.selectedEmployee.id)
          validation.setFieldValue("fromConsultantName", props.selectedEmployee.preferredName)
          setToConsultantList(toConsultantListTemp);
        }
        if(resultAPI[1]){
          validation.setFieldValue("customerListDetails", initCustomerList(resultAPI[1]));
          setReassignCustomerList(resultAPI[1]);
        }
        setLoadingModalAPI(false);
      }
		}

    fetchEmployeeCustomerAPI();

  }, [props.selectedEmployee])

  const hideModal = () => {
    validation.setFieldValue("customerListDetails", []);
    props.setModal(false);
  }

  const initCustomerList = (customerListTemp?: any) => {
    let valueTemp: any = customerListTemp;
    let customerListDetailsTemp: any = [];
    if (valueTemp.length > 0) {
      customerListDetailsTemp = valueTemp.map((value) => {
        return {
          ...value,
          lastVisitDate: value.lastVisitDate !== Constants.invalidDate ? moment(value.lastVisitDate).format(Constants.displayDateFormat) : Constants.emptyData,
          joinedDate: value.joinedDate !== Constants.invalidDate ? moment(value.joinedDate).format(Constants.displayDateFormat) : Constants.emptyData,
          isActive: true
        }
      })
    }
    return customerListDetailsTemp;
  }

  const fetchReassignCustomerAPI = async (selectedCustomerIdsTemp) => {
    setLoadingReassign(true);
    let resultAPI = await props.reassignCustomer({ consultantId: validation.values.toConsultantId, customerIds: selectedCustomerIdsTemp });
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
      }, Constants.timeoutNavigation)
    }
    setLoadingReassign(false);
  }

  const onChangeConsultant = async (id: string, blnCallAPI: boolean) => {
      let indexConsultant = _.findIndex(toConsultantList, { id: id });
      if (indexConsultant > -1) {
        validation.setValues((values) => ({
          ...values,
          toConsultantId: toConsultantList[indexConsultant].id,
          toConsultantName: toConsultantList[indexConsultant].preferredName
        }))
      }
  };

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingReassign && !localSuccessMessage) {
            hideModal();
          }
        }}
        size={"xl"}
        centered>
        {
          (!reassignCustomerList || loadingModalAPI)
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "ReassignCustomer" })}</h5>
                </div>
                {
                  !loadingReassign && !localSuccessMessage
                  &&
                  <button
                    type="button"
                    onClick={() => { hideModal(); }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                <div ref={refTopDiv}/>
                {
                  !loadingModalAPI && !loadingReassign
                  &&
                  <ScrollButton
                    blnScrollToTop={true}
                    refDiv={refTopDiv}
                    scrollToDiv={true}
                    blnModal={true}/>
                }
                <div className="">
                  <Row>
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "FromConsultant" })}
                        name={"fromConsultantName"}
                        type="text"
                        disabled={true}
                        validationRequired={true}
                        validation={validation}
                        />
                    </SingleColumnRowParent>
                  </Row>
                  <Row>
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "ToConsultant" })}
                        name="consultantId"
                        className={"mb-3"}
                        options={toConsultantList}
                        labelField={"preferredName"}
                        valueField={"id"}
                        initialLabel={validation.values.toConsultantName}
                        initialValue={validation.values.toConsultantId}
                        disabled={ !(validation.values.customerListDetails.length > 0) || !validation.values.fromConsultantId || loadingModalAPI || loadingReassign || Boolean(localSuccessMessage)}
                        onChange={(idTemp) => { onChangeConsultant(idTemp, false) }}
                        validationRequired={true}
                      />
                    </SingleColumnRowParent>
                  </Row>
                </div>
                {
                  loadingModalAPI 
                  ?
                  <Loading/>
                  :
                  validation.values.fromConsultantId !== "" &&
                  <>
                    <Borderline />
                    <div className="py-2 margin-bottom--24">
                      <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({ id: "CustomerLists" })}
                      </h3>
                    </div>
                    <TableWithSelectAllCheckBoxAndInput
                      name="customerListDetails"
                      title=""
                      className="mb-3"
                      blnEditable={true}
                      aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                      data={validation.values.customerListDetails}
                      validation={validation}
                      selectAllTextFieldDisplay={"customerNo"}
                      selectAllTextFieldDisplayXLSize={2}
                      blnTableViewCss={true}
                      disabled={loadingReassign || Boolean(localSuccessMessage)} />
                    <div className="mb-3 mt-4 flex-right">
                      {
                        !Boolean(localSuccessMessage)
                        &&
                        <MyButton
                          type="button"
                          class="btn btn-primary"
                          style={{ width: '20%' }}
                          content={intl.formatMessage({ id: "Confirm" })}
                          onClick={() => {
                            let blnErrorFound = false;
                            if (validation.values.toConsultantId === "") {
                              blnErrorFound = true;
                            }
                            if (!blnErrorFound) {
                              let valueTemp: any = validation.values.customerListDetails;
                              let selectedLeadIds: any = [];
                              for (var a = 0; a < valueTemp.length; a++) {
                                if (valueTemp[a].isActive && valueTemp[a].isActive == true) {
                                  selectedLeadIds.push(valueTemp[a].id)
                                }
                              }
                              if (selectedLeadIds && selectedLeadIds.length > 0) {
                                fetchReassignCustomerAPI(selectedLeadIds)
                              } else {
                                setErrorMessage(`${intl.formatMessage({ id: "NoItemSelected" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}`)
                                return;
                              }
                            }
                            else {
                              setErrorMessage(`${(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "ToConsultant" }) }))}`)
                              return;
                            }
                          }}
                          loading={loadingReassign}
                          disable={!(validation.values.customerListDetails.length>0) || loadingModalAPI || loadingReassign || Boolean(localSuccessMessage)}
                        />
                      }
                    </div>
                  </>
                }
              </div>
              {
                !loadingModalAPI && !loadingReassign
                &&
                <ScrollButton
                  blnScrollToTop={false}
                  refDiv={refBottomDiv}
                  blnModal={true}/>
              }
              <div ref={refBottomDiv}/>
            </div>
        }
      </Modal>
  )
})