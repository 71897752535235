import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, IncludesLocationPathName } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import classnames from "classnames"
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { BranchMachineAddObject, BranchMachineListObject, BranchMachineObject } from "../../app/models/machine";
import BranchMachineEditModal from "./Modal/BranchMachineEditModal";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import LoadingModal from "../../app/components/modal/LoadingModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const MachineDetail = () => {
	//Use Params
	const { id } = useParams();

	//Use INTL
	const intl = useIntl();

	//Use Store
	const { machineStore, staticReferenceStore, branchStore, commonStore } = useStore();
	const {
		windowSize,
		errorMessage,
		successMessage,
		loading,
		setErrorMessage,
		setSuccessMessage,
		setLoading,
	} = commonStore;
	const { addMachine, addBranchMachine, updateMachine, updateBranchMachine, resetBranchMachine, machineDetail, setMachineDetail, getMachineWithId, getBranchMachine } = machineStore;
	const { machineConsumptionType, getStaticReferenceWithType } = staticReferenceStore;
	const { branchDropDownList, getBranchDropDown } = branchStore;
	const addAction = IncludesLocationPathName("add");
	const viewAction = IncludesLocationPathName("view");
	const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
		} ${intl.formatMessage({ id: "Machine" })}`;
	const breadCrumbList = [{ title: intl.formatMessage({ id: "Machine" }), urlPath: RoutesList.machine }];
	const [activeTab, setActiveTab] = useState("1");
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateMachine = checkPermission([PermissionConstants.UpdateMachine]);
  const tabBar = addAction ? [intl.formatMessage({ id: "Basic" })] : [intl.formatMessage({ id: "Basic" }), intl.formatMessage({ id: "Branches" })];
	const [blnBranchMachineEditModal, setBlnBranchMachineEditModal] = useState(false);
	const [blnBranchMachineListModal, setBlnBranchMachineListModal] = useState(false);
	const [blnResetBranchMachineModal, setBlnResetBranchMachineModal] = useState(false);
	const [localLoading, setLocalLoading] = useState(false);
	const [loadingBranchMachineList, setLoadingBranchMachineList] = useState(false);
	const [refreshBranchMachinesList, setRefreshBranchMachinesList] = useState(false);
	const [branchMachineDetail, setBranchMachineDetail] = useState<BranchMachineListObject>({ 
		branchId: "",
    branchName: "",
    machineId: "",
    machineName: "",
    serialNumber: "",
		reloadQuantity: 0,
    balanceQuantity: 0,
    isActive: true
	});
	const [branchMachineList, setBranchMachineList] = useState<BranchMachineListObject[]>([]);
	const [selectedBranchForBranchMachineObject, setSelectedBranchForBranchMachineObject] = useState({branchId: "", branchName: ""});

	const onClickViewBranchMachine = async (index, branchId: string = "") => {
		if (!localLoading) {
			setBranchMachineList([]);
			setLoadingBranchMachineList(true);
			if (!branchId && index > -1) {
				setSelectedBranchForBranchMachineObject({branchId: validation.values.branchMachines[index].branchId, branchName: validation.values.branchMachines[index].branchName});
			}
			let resultBranchMachine = await getBranchMachine({branchId: branchId || validation.values.branchMachines[index].branchId, machineId: id});
			if (resultBranchMachine) {
				setBranchMachineList(resultBranchMachine);
			}
			setLoadingBranchMachineList(false);
			setBlnBranchMachineListModal(true);
		}
	}
	
  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
		{ 
			label: intl.formatMessage({ id: "Name" }).toUpperCase(), 
			type: "input", 
			xl: "4.85", 
			inputName: "branchName", 
			inputType: "text",
			disabled: true
		}, 
		{ 
			label: intl.formatMessage({ id: "TotalCountOfMachines" }).toUpperCase(), 
			type: "input", 
			xl: "4.85", 
			inputName: "machineCount", 
			inputType: "text",
			disabled: true
		}, 
		{
			label: `${intl.formatMessage({ id: "MoreInfo" })}`,
			type: "dynamicButton",
			xl: "2.3",
			textAlign: true,
			textValueAlign: true,
			inputName: "",
			inputType: "row",
			rowButtonTitle: localLoading ? 'Loading' : "View",
			dynamicButtonClassName: localLoading ? 'btn-secondary' : "btn-primary",
			dynamicButtonFunction: onClickViewBranchMachine
		},
	];

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: machineDetail || {
			name: "",
			description: "",
			modelNumber: "",
			consumptionType: "",
			displayOrder: 1,
			lifespanQuantity: 1,
			reloadQuantity: 1,
			branchQuantities: [],
			branchMachines: [],
			isActive: true
		},
		validationSchema: Yup.object({
			name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MachineName" }) })),
			displayOrder: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "DisplayOrder" }) })),
			modelNumber: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "ModelNumber" }) })),
			consumptionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "ConsumptionType" }) }))
		}),
		onSubmit: async (values) => {
			const valuesTemp: any = _.cloneDeep(values);
			if (valuesTemp.reloadQuantity > valuesTemp.lifespanQuantity) {
				setErrorMessage(intl.formatMessage({ id:  valuesTemp.consumptionType === Constants.byDuration ? "ReloadDurationGreaterThanLifeSpanDuration" : "ReloadQuantityGreaterThanLifeSpanQuantity" }))
				return;
			}
			
			try {
				if (addAction) {
					await addMachine(valuesTemp);
				} else {
					await updateMachine(valuesTemp);
				}
			} finally {
				validation.setSubmitting(false);
			}
		},
	});

	const blnDurationType = validation.values.consumptionType === Constants.byDuration;

	const BranchMachinesColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "serialNumber",
      text: intl.formatMessage({ id: "SerialNumber" }).toUpperCase(),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: blnDurationType ? "BalanceMinutes" : "BalanceQuantity" }).toUpperCase(),
    },
		{
      dataField: "isActive",
      text: intl.formatMessage({ id: "Active" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isActive ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
		{
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: '190px' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center gap-3">
          {
						blnPermissionUpdateMachine
            &&
            <MyButton
              id={Constants.selectWithTableListSelectButton}
              type="button"
              class="btn btn-success"
              content={intl.formatMessage({ id: "Edit" })} />
          }
					{
						blnPermissionUpdateMachine
            &&
						<MyButton
							id={Constants.selectWithTableListDeleteButton}
							type="button"
							class="btn btn-warning"
							content={intl.formatMessage({ id: "Reset" })} />
					}
        </div>
      ),
		}
  ];

	const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

	useEffect(() => {
		async function fetchMachineDetailAPI() {
			setLoading(true);
			let aryAPI: any = [
        getStaticReferenceWithType(Constants.machineConsumptionType),
				getBranchDropDown(),
      ];
      if (id && !addAction) {
        aryAPI.push(getMachineWithId(id))
      }
			await Promise.all(aryAPI);
			setLoading(false);
		}

		if (addAction) {
			setMachineDetail(undefined);
		}

		if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateMachine], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateMachine], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageMachine], true)) {
        return;
      }
    }

		if(viewAction && blnPermissionUpdateMachine){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.machine}/edit/${id}`)} })
    }

		fetchMachineDetailAPI();

		return (() => {
			if (id && !addAction) {
				setMachineDetail(undefined);
			}
		})
	}, []);

	useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
			if (Object.keys(validation.errors).length > 0) {
				if (Constants.validationError) {
					console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
				}
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
			}
		}
	}, [validation.isSubmitting, validation.errors])

	const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

	const setAddNewBranchMachineInfo = (branchObject?: any) => {
		setBranchMachineDetail({
			branchId: branchObject?.branchId || getBranchId(),
			branchName: branchObject?.branchName || getBranchName(),
			machineId: id,
			machineName: validation.values.name,
			serialNumber: "",
			reloadQuantity: machineDetail?.reloadQuantity || validation.values.reloadQuantity,
			balanceQuantity: machineDetail?.reloadQuantity || validation.values.reloadQuantity,
			isActive: true
		})
		setBlnBranchMachineEditModal(true);
	}

	const onClickAddNewOrUpdateBranchMachine = async (branchMachineRequestBody: BranchMachineListObject) => {
		setLocalLoading(true);
		let resultAddBranchMachine = branchMachineRequestBody.id ? await updateBranchMachine({id: branchMachineRequestBody.id || "", balanceQuantity: branchMachineRequestBody.balanceQuantity, isActive: branchMachineRequestBody.isActive}) : await addBranchMachine(branchMachineRequestBody);
		if (resultAddBranchMachine.status === Constants.success) {
			refreshBranchMachineList();	
			if (!branchMachineRequestBody.id) {
				let branchMachinesTemp = _.cloneDeep(validation.values.branchMachines)
				let indexBranchMachine = _.findIndex(branchMachinesTemp, { branchId: branchMachineRequestBody.branchId});
				if (indexBranchMachine > -1) {
					setRefreshBranchMachinesList(true);
					branchMachinesTemp[indexBranchMachine].machineCount +=1;
					validation.setFieldValue("branchMachines", branchMachinesTemp);
					setTimeout(()=> {
						setRefreshBranchMachinesList(false);
					}, 100)
				}
			}
		}
		setLocalLoading(false);
	}

	const onEditBranchMachineModal = (objBranchMachine: BranchMachineListObject) => {
		setBranchMachineDetail(objBranchMachine)
		setBlnBranchMachineEditModal(true);
	}

	const onResetBranchMachineModal = (objBranchMachine: BranchMachineListObject) => {
		setBranchMachineDetail(objBranchMachine);
		setBlnResetBranchMachineModal(true);
	}

	const onClickResetBranchMachineBalanceQuantity = async () => {
		setLoadingBranchMachineList(true);
		let resultResetBranchMachine = await resetBranchMachine(branchMachineDetail.id || "");
		if (resultResetBranchMachine.status === Constants.success) {
			refreshBranchMachineList();	
		}
		setLoadingBranchMachineList(false);
	}

	const refreshBranchMachineList = () => {
		setBlnBranchMachineEditModal(false);
		setBlnBranchMachineListModal(false);
		onClickViewBranchMachine(-1, selectedBranchForBranchMachineObject.branchId);		
	}

	const displayFooterButtons = () => {
    return (
      <GeneralSubmitAndCancelBtn
        successMessage={successMessage}
        viewAction={viewAction}
        validation={validation}
      />
    )
  }

	return (
		<div>
			<DetailViewLayout
				title={displayTitle}
				breadCrumbList={breadCrumbList}
				burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
				{loading ? (
					<Loading />
				) : (
					<Form
						className="checkout-tabs"
						onSubmit={(e) => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}
						onBlur={() => {
							if (errorMessage || successMessage) {
								setErrorMessage("");
								setSuccessMessage("");
							}
						}}
					>
						<Row form={+true}>
							{" "}
							{/* +true = Convert boolean to numbers*/}
							<Col xl="2" sm="12">
								{/* <Nav className="flex-column vertical-icon"> */}
								{/* 1200 - 400 */}
								{
									windowSize.innerWidth < 1200 && windowSize.innerWidth > 400
										?
										<Row>
											{
												tabBar.map((value, indexTab) => (
													<Col xs={"4"} md={"4"} lg={"4"} xl={"4"}>
														<Nav className="flex-column" pills>
															<NavItem
																key={`tabkey_${indexTab}`}>
																<NavLink
																	className={classnames({ active: activeTab === String(indexTab + 1) })}
																	// style={{ backgroundColor: !blnPurchasableProduct && indexTab === 2 ? 'lightgrey' : undefined }}
																	onClick={() => {
																		toggle(String(indexTab + 1));
																	}}
																>
																	{/* <i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bx-dollar-circle"} check-nav-icon mt-4 mb-2`} /> */}
																	<i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bxl-dropbox"} check-nav-icon mt-4 mb-2`} />
																	<p className="font-weight-bold mb-4">{value}</p>
																</NavLink>
															</NavItem>
														</Nav>
													</Col>
												))
											}
										</Row>
										:
										<Nav className="flex-column" pills>
											{
												tabBar.map((value, indexTab) => (
													<NavItem
														key={`tabkey_${indexTab}`}>
														<NavLink
															className={classnames({ active: activeTab === String(indexTab + 1) })}
															// style={{ backgroundColor: !blnPurchasableProduct && indexTab === 2 ? 'lightgrey' : undefined }}
															onClick={() => {
																toggle(String(indexTab + 1));
															}}
														>
															{/* <i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bx-dollar-circle"} check-nav-icon mt-4 mb-2`} /> */}
															<i className={`${indexTab === 0 ? "bx bx-cube d-block" : indexTab === 1 ? "bx bx-store" : "bx bxl-dropbox"} check-nav-icon mt-4 mb-2`} />
															<p className="font-weight-bold mb-4">{value}</p>
														</NavLink>
													</NavItem>
												))
											}
										</Nav>
								}
							</Col>
							<Col xl="10">
								<TabContent
									activeTab={activeTab}
									className="mt-4 mt-md-0 content-layout-white">
									<TabPane tabId="1">
										<Col xl={12}>
											<LineBreakWithTittle
												paddingBottom="0px"
												title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Machine" }) })}
												h4Title />
											<SingleColumnRowParent>
												<GeneralInput
													title={intl.formatMessage({ id: "MachineName" })}
													name="name"
													className="mb-3 mt-4"
													type="text"
													disabled={disabledFieldInput || viewAction}
													validationRequired={true}
													validation={validation}
												/>
											</SingleColumnRowParent>
											<SingleColumnRowParent>
												<GeneralInput
													title={intl.formatMessage({ id: "ModelNumber" })}
													name="modelNumber"
													type="text"
													disabled={disabledFieldInput || viewAction}
													validationRequired={true}
													validation={validation}
												/>
											</SingleColumnRowParent>
											<SingleColumnRowParent>
												<DropDownWithTitle
													name={"consumptionType"}
													title={intl.formatMessage({ id: "ConsumptionType" })}
													specifyReturnFieldName={[
														{
															field: "consumptionType",
															value: "displayValue",
														},
													]}
													labelField={"displayValue"}
													valueField={"displayValue"}
													options={machineConsumptionType}
													blnValueWithNewSpace={true}
													disabled={disabledFieldInput || viewAction}
													initialValue={!addAction ? validation.values.consumptionType : undefined}
													validationRequired={true}
													validation={validation} />
											</SingleColumnRowParent>
											<Row>
												<DoubleColumnRowParent>
													<GeneralInput
														title={intl.formatMessage({ id: blnDurationType ? "LifespanDuration" : "LifespanQuantity" })}
														name="lifespanQuantity"
														type="text"
														disabled={disabledFieldInput || viewAction}
														validation={validation}
													/>
												</DoubleColumnRowParent>
												<DoubleColumnRowParent>
													<GeneralInput
														title={intl.formatMessage({ id: blnDurationType ? "ReloadDuration" : 'ReloadQuantity' })}
														name="reloadQuantity"
														type="text"
														disabled={disabledFieldInput || viewAction}
														validation={validation}
													/>
												</DoubleColumnRowParent>
											</Row>
											<SingleColumnRowParent>
												<GeneralTextArea
													title="Description"
													name="description"
													row={5}
													disabled={disabledFieldInput || viewAction}
													validation={validation}/>
											</SingleColumnRowParent>
											<SingleColumnRowParent>
												<GeneralInput
													title={intl.formatMessage({ id: "DisplayOrder" })}
													name="displayOrder"
													type="number"
													disabled={disabledFieldInput || viewAction}
													validationRequired={true}
													validation={validation}
												/>
											</SingleColumnRowParent>
											<SingleColumnRowParent>
												<GeneralInput
													title={intl.formatMessage({ id: "Active" })}
													name="isActive"
													type="checkbox"
													disabled={disabledFieldInput || viewAction}
													validation={validation}
													childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
											</SingleColumnRowParent>
										</Col>
										{displayFooterButtons()}
									</TabPane>
									<TabPane tabId="2">
										<>
											<LineBreakWithTittle
												paddingBottom="0px"
												title={intl.formatMessage({ id: "Branches" })}
												h4Title />
											{
												refreshBranchMachinesList
												?
												<Loading/>
												:
												<TableWithEditableInputFields
													name="branchMachines"
													title=""
													buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "BranchMachine" }) })}
													blnSupportWithModal={true}
													blnBtnModalBottom={true}
													blnEditable={!viewAction}
													hideDeleteButton={true}
													hideAddButton={true}
													blnDisabledCustomizedTableScrolling={true}
													addButtonFunction={()=> {setAddNewBranchMachineInfo()}}
													aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
													data={validation.values.branchMachines}
													validation={validation}
													disabled={disabledFieldInput || loading} />
											}
										</>
										<div className="mt-3" />
                    {displayFooterButtons()}
									</TabPane>
								</TabContent>
							</Col>
						</Row>
					</Form>
				)}
			</DetailViewLayout>
			{
				blnBranchMachineListModal &&
				<SelectWithTableListModal
					blnShow={blnBranchMachineListModal}
					setModal={setBlnBranchMachineListModal}
					title={`${intl.formatMessage({ id: "BranchMachineList" })} (${selectedBranchForBranchMachineObject.branchName || Constants.emptyData})`}					
					options={branchMachineList}
					keyField={"id"}
					fieldName={"id"}
					onSelectFunction={onEditBranchMachineModal}
					onDeleteFunction={onResetBranchMachineModal}
					topRightButton={()=> (
						<MyButton
							type="button"
							content={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "BranchMachine" }) })}
							class="btn btn-success"
							onClick={()=> {
								setAddNewBranchMachineInfo(selectedBranchForBranchMachineObject)
							}}
							loading={loading}
						/>
					)}
					columns={BranchMachinesColumns}
					validation={validation}/>
			}
			{
				blnBranchMachineEditModal
				&&
				<BranchMachineEditModal 
					blnShow={blnBranchMachineEditModal}
					setModal={setBlnBranchMachineEditModal}
					branchMachineDetail={branchMachineDetail}
					setBranchMachineDetail={setBranchMachineDetail}
					addNewOrUpdateBranchMachine={onClickAddNewOrUpdateBranchMachine}
					branchDropDownList={branchDropDownList}
					viewModalAction={false}
					loadingBranchMachine={localLoading}
					setLoadingBranchMachine={setLocalLoading}
					blnDurationType={blnDurationType}
				/>
			}
			{
				blnResetBranchMachineModal
				&&
				<ActionPromptModal 
					title={`${intl.formatMessage({ id: "ResetBranchMachineBalanceCount" }, {field: `${branchMachineDetail.machineName} (${branchMachineDetail.serialNumber})`})}`}
					primaryActionTitle={`Yes`}
					showPrompt={blnResetBranchMachineModal}
					setShowPrompt={setBlnResetBranchMachineModal}
					onPrimaryClick={()=> {
						onClickResetBranchMachineBalanceQuantity()
					}}
				/>
			}
			{
				loadingBranchMachineList
				&&
				<LoadingModal/>
			}
		</div>
	);
};

export default observer(MachineDetail);
