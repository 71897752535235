import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, returnPriceWithCurrency, checkPermission, getBranchId, getBranchName, getBranchUser, returnSubBrandList } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import ReportExpandRow from "../subView/ReportExpandRow";
import _ from "lodash";

const ConsultantDailySales = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, successMessage, setErrorMessage } = commonStore;
  const { consultantDailySalesList, setConsultantDailySalesList, getConsultantDailySales, exportConsultantDailySales } = reportSalesStore; 
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedSubBrandId, setSelectedSubBrandId] = useState("");
  const [selectedSubBrandName, setSelectedSubBrandName] = useState("");
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnHasSubBrand = returnSubBrandList().length > 0;

  const aryConsultantDailySalesDetail : ObjectForReportRow[] = [
    {label: "Employee", value: "employeeName", type: "string"}, 
    {label: "LeaveType", value: "leaveType", type: "string"}, 
    {label: "PackageSales/Adjustment/Total", value: "finalPackageSalesAmountDisplay", type: "string"},
    {label: "ProductSales/Adjustment/Total", value: "finalProductSalesAmountDisplay", type: "string"},  
    {label: "ProductCommission/Adjustment/Total", value: "finalProductCommisionAmountDisplay", type: "string"}
  ];

  const paramsSearch = useLocation().search;
  const yearParams = new URLSearchParams(paramsSearch).get('year');
  const monthParams = new URLSearchParams(paramsSearch).get('month');
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
 
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchConsultantDailySales() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];
      
      let resultAPI = await Promise.all(aryAPI);
      // if (yearParams && monthParams && branchParams) {
      //   setSelectedYear(Number(yearParams));
      //   let monthListTemp = getMonths();
      //   let indexMonth = _.findIndex(monthListTemp, {value: Number(monthParams)});
      //   if (indexMonth > -1) {
      //     setSelectedMonth(monthListTemp[indexMonth].label);
      //     setSelectedMonthValue(monthListTemp[indexMonth].value);
      //   }
      //   let indexBranch = _.findIndex(resultAPI[0], {id: branchParams});
      //   if (indexBranch > -1) {
      //     setSelectedBranchId(resultAPI[0][indexBranch].id);
      //     setSelectedBranchName(resultAPI[0][indexBranch].name);
      //   }
      //   history.replace({ pathname: `/${RoutesList.report}/${RoutesList.consultantDailySales}` });
      //   await getConsultantDailySales({pageSize: pageSize, pageNumber: 1, branchId: branchParams, year: Number(yearParams), month: Number(monthParams)});
      // }
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }
    
    fetchConsultantDailySales();

    return (()=> {
      setConsultantDailySalesList([]);
    })
  }, [])

  const fetchConsultantDailySalesWithLoading = async (action: string) => {
    if (selectedBranchId) {
      setLocalLoading(true);
      setCurrentPage(1);
      if (action === Constants.export) {
        await exportConsultantDailySales({pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear || 0, month: selectedMonthValue, subBrandId: selectedSubBrandId})
      }
      else {
        await getConsultantDailySales({pageSize: pageSize, pageNumber: 1, branchId: selectedBranchId, year: selectedYear, month: selectedMonthValue, subBrandId: selectedSubBrandId});
      }
      setLocalLoading(false);
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
    }
	};

  //Table Content UI
  const ConsultantDailySalesColumns = [
    {
      dataField: "date",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.date).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.date).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "packageSalesAmount",
      text: intl.formatMessage({ id: "PackageSales/Adjustment/Total" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.packageSalesAmount)} / {returnPriceWithCurrency(row.packageSalesAdjustment)} / {returnPriceWithCurrency(row.totalPackageSalesAmount)}</div>
      ),
    },
    {
      dataField: "productSalesAmount",
      text: intl.formatMessage({ id: "ProductSales/Adjustment/Total" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productSalesAmount)} / {returnPriceWithCurrency(row.productSalesAdjustment)} / {returnPriceWithCurrency(row.totalProductSalesAmount)}</div>
      ),
    },
    {
      dataField: "productCommissionAmount",
      text: intl.formatMessage({ id: "ProductCommission/Adjustment/Total" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productCommissionAmount)} / {returnPriceWithCurrency(row.productCommissionAdjustment)} / {returnPriceWithCurrency(row.totalProductCommissionAmount)}</div>
      ),
    }
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "ConsultantDailySales" })}
        rowFieldName={"consultantDailySalesDetail"}
        rowColumns={aryConsultantDailySalesDetail}
        keyFieldName={"employeeId"}
      />
    ),
    showExpandColumn: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "ConsultantDailySales"})})}
        title={intl.formatMessage({ id: "ConsultantDailySales" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchConsultantDailySalesWithLoading(Constants.export)
            }}/>
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Consultant-Daily-Sales.aspx`}>
        {
          !loading
          &&
          <>
          <Row>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            {
              !blnHasSubBrand
              &&
              <Col xl="3">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchConsultantDailySalesWithLoading("")
                  }}
                />
              </Col>
            }
          </Row>
          {
            blnHasSubBrand
            &&
            <Row>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                  options={returnSubBrandList()}
                  labelField={"name"}
                  valueField={"id"}
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                />
              </Col>
              <Col xl="3">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : ""} margin-bottom-14`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={()=> {
                    fetchConsultantDailySalesWithLoading("")
                  }}
                />
              </Col>
            </Row>
          }
          </>
        }
        {
          localLoading
          ?
          <Loading/>
          :
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ConsultantDailySalesColumns}
              data={consultantDailySalesList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "ConsultantDailySales"})})}
              objSorted={defaultSorted}
              keyField={"date"}
              requestAPI={getConsultantDailySales}
              expandRow={expandRow}
              hideSearchBar={true}/>
          </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ConsultantDailySales);
