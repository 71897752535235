import React from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import ActionPromptModal from "../../../app/components/modal/ActionPromptModal";

interface Props {
  blnShow: boolean;
  setModal: Function;
	onRequestClick: () => void;
  onCancelClick: () => void;
}

export default observer(function StockAdjustmentRequestModal(props: Props) {

	return (
    <ActionPromptModal 
      title={`Some products is having discrepancies, do you would like to create stock adjustment?`}
      primaryActionTitle={`Yes`}
      showPrompt={props.blnShow}
      setShowPrompt={props.setModal}
      onPrimaryClick={()=> {props.onRequestClick()}}
      onCancelClick={()=> {props.onCancelClick()}}
      />
	);
})