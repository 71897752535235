import { useEffect, useState } from "react";
import { FormFeedback, Modal } from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { observer } from "mobx-react-lite";
import { Constants } from "../../../app/constants/Constants";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  customerId: any;
  onClickAddRelationship: Function;
  loadingRelationship: boolean;
}

export default observer(function AddRelationshipModal(props: Props) {
  const intl = useIntl();
  const { customerStore, staticReferenceStore, commonStore } = useStore();
  const { successMessage } = commonStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { customerRelationshipType, getStaticReferenceWithType } = staticReferenceStore;
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [loadingCustomerRelationshipType, setLoadingCustomerRelationshipType] = useState(false);
  const disabledFieldInput = props.loadingRelationship || Boolean(successMessage);

  useEffect(()=> {
    async function fetchCustomerRelationshipType() {
      setLoadingCustomerRelationshipType(true);
      await getStaticReferenceWithType(Constants.customerRelationshipType);
      setLoadingCustomerRelationshipType(false);
    }

    fetchCustomerRelationshipType();
  },[])

  const [relationshipDetails, setRelationshipDetails] = useState({
    linkFromCustomerId: props.customerId, 
    linkToCustomerId: "",
    relationshipType: ""
  });

  const [errorState, setErrorState] = useState({
    linkToCustomerId: false,
    relationshipType: false
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      linkToCustomerId: false,
      relationshipType: false
    });
  }

  const hideModal = () => {
    clearErrorState();
    props.setModal(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown("", {customerNoOrName: value}, true);    
    
    if (resultCustomerAPI) { 
      resultCustomerAPI.map((valueCustomerAPI: any)=> {
        if (valueCustomerAPI.options) {
          _.remove(valueCustomerAPI.options, {id: props.customerId});
        }
      })
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const setLinkToCustomerId = (id: string) => {
    setRelationshipDetails((valueRelationshipDetail)=> ({
      ...valueRelationshipDetail,
      linkToCustomerId: id
    }))
    clearErrorState("linkToCustomerId");
    clearCustomerDropdownList();
  }

  const setRelationShip = (id: string) => {
    setRelationshipDetails((valueRelationshipDetail)=> ({
      ...valueRelationshipDetail,
      relationshipType: id
    }))
    clearErrorState("relationshipType");
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!disabledFieldInput) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "AddRelationship" })}</h5>
          {
            !disabledFieldInput
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          {
            <div>
              {/* <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Relationship" })}
                  name="relationship"
                  type="text"
                  field={relationshipDetails}
                  setFieldValue={setRelationshipDetails}
                  disabled={disabledFieldInput}/>
              </SingleColumnRowParent>              */}
              <SingleColumnRowParent blnDoubleTab={true}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="linkToCustomerId"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  className={errorState.linkToCustomerId ? "mb-0" : "mb-3"}
                  options={customerDropdownList}
                  labelField={"preferredName"}
                  valueField={"id"}
                  disabled={disabledFieldInput}
                  onChange={setLinkToCustomerId}
                  validationRequired={true}
                  blnSupportCustomSearch={true}
                  blnSupportGroupDropdown={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
                {errorState.linkToCustomerId ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) })}</FormFeedback>
                ) : null}
              </SingleColumnRowParent>
              {
                loadingCustomerRelationshipType
                ?
                <Loading/>
                :
                <>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Relationship" })}
                  name="relationshipType"
                  className={errorState.relationshipType ? "mb-0" : "mb-3"}
                  options={customerRelationshipType}
                  labelField={"displayValue"}
                  valueField={"displayValue"}
                  disabled={disabledFieldInput}
                  onChange={setRelationShip}
                  validationRequired={true}
                />
                  {errorState.relationshipType ? (
                    <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Relationship" }) })}</FormFeedback>
                  ) : null}
                </>
              }
              <div className="">
                {
                  !Boolean(successMessage)
                  &&
                  <>
                    <MyButton
                      type="button"
                      class="btn btn-success"
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        let errorStateTemp = {
                          linkToCustomerId: false,
                          relationshipType: false
                        };
                        let blnErrorFound = false;
                        if (!relationshipDetails.linkToCustomerId) {
                          errorStateTemp.linkToCustomerId = true;
                          blnErrorFound = true;
                        }
                        if (!relationshipDetails.relationshipType) {
                          errorStateTemp.relationshipType = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          props.onClickAddRelationship(relationshipDetails);
                        }
                      }}
                      disable={disabledFieldInput}
                      loading={props.loadingRelationship}
                    />
                  </>
                }
              </div>
            </div>
          }
        </div>
      </Modal>
  )
})