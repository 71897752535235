import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, getYears, getMonths, returnSubBrandList, displaySummaryDetailsTableCellUI, firstDayOfMonthToCurrentDay } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import MyButton from "../../../app/components/form/MyButton";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { useLocation } from 'react-router-dom';
import { history } from "../../../";

const BranchSalesSummary = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize } = commonStore;
  const { branchSalesSummaryList, getBranchSalesSummaryList, setBranchSalesSummaryList, exportBranchSalesSummary } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const paramsSearch = useLocation().search;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [initialData, setInitialData] = useState(true);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths());

    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }

    async function fetchBranchSalesSummaryListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      
      const branchIdParams = new URLSearchParams(paramsSearch).get('branchId');
      const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
      const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
      const subBrandIdParams = new URLSearchParams(paramsSearch).get('subBrandId');

      if (fromDateParams && toDateParams) {
        let yearParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("YYYY"));
        let monthParams = Number(moment(toDateParams, Constants.defaultDateFormat).format("M"));
        let fromDateTemp = moment(fromDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);
        let toDateTemp = moment(toDateParams, Constants.defaultDateFormat).format(Constants.displayDateFormat);

        setSelectedYear(yearParams);
        let monthListTemp = getMonths();
        let indexMonth = _.findIndex(monthListTemp, { value: monthParams });
        if (indexMonth > -1) {
          setSelectedMonth(monthListTemp[indexMonth].label);
          setSelectedMonthValue(monthListTemp[indexMonth].value);
        }

        let indexBranch = _.findIndex(resultAPI[0], { id: branchIdParams });
        if (indexBranch > -1) {
          setSelectedBranchId(resultAPI[0][indexBranch].id);
          setSelectedBranchName(resultAPI[0][indexBranch].name);
        }

        if(subBrandIdParams && subBrandIdParams !== " "){
          let indexSubBrandId = _.findIndex(returnSubBrandList(), { id: subBrandIdParams})
          if (indexSubBrandId > -1) {
            let subBrandListTemp: any = _.cloneDeep(returnSubBrandList());
            setSelectedSubBrandId(subBrandListTemp[indexSubBrandId].id);
            setSelectedSubBrandName(subBrandListTemp[indexSubBrandId].name);
          }
        }

        setSelectedDateRange({fromDate: fromDateTemp, toDate: toDateTemp});

        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.branchSalesSummary}` });
        await getBranchSalesSummaryList({ branchId: branchIdParams || selectedBranchId, fromDate: fromDateTemp, toDate: toDateTemp, subBrandId: subBrandIdParams || selectedSubBrandId });
      }

      setLoading(false);
      setInitialData(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchBranchSalesSummaryListAPI();

    return (() => {
      setBranchSalesSummaryList([]);
    })
  }, []);

  useEffect(()=> {
    if (!initialData) {
      setSelectedDateRange(dateRangeLimit)
      setLocalLoadingDateRange(true);
      setTimeout(()=> {
        setLocalLoadingDateRange(false);
      }, 100)
    }
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const fetchBranchSalesSummaryListWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if(blnExport){
      await exportBranchSalesSummary({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    }
    else{
      await getBranchSalesSummaryList({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, subBrandId: selectedSubBrandId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const BranchSalesSummaryColumns = [
    {
      dataField: "branchId", //field name from array to display
      text: "branchId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.branchId}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      style: { width: "350px" },
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "subBrandName",
      text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
      hidden: returnSubBrandList().length < 1,
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "subBrandName")
      },
    },
    {
      dataField: "totalSales",
      text: intl.formatMessage({ id: "BranchSales" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "totalSales", false, true)
      },
    },
    {
      dataField: "totalPaymentReceived",
      text: intl.formatMessage({ id: "PaymentReceived" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "totalPaymentReceived", false, true, "", "", "paymentRouteUrl")
      },
    },
    {
      dataField: "totalSalesVariance",
      text: intl.formatMessage({ id: "SalesVariance" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "totalSalesVariance", false, true, "", "", "salesVarianceRouteUrl")
      },
    },
    {
      dataField: "groupSales",
      text: intl.formatMessage({ id: "GroupSales" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "groupSales", false, true)
      },
    },
    {
      dataField: "productSales",
      text: intl.formatMessage({ id: "ProductSales" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "productSales", false, true)
      }
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      formatter: (cellContent, row) => {
        return displaySummaryDetailsTableCellUI(row.details, "routeUrl", false, false, intl.formatMessage({ id: "ViewDailySales" }), RoutesList.branchDailySalesSummary)
      }
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "BranchSalesSummary" }) })}
        title={intl.formatMessage({ id: "BranchSalesSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchBranchSalesSummaryListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Branch-Sales-Summary.aspx`}>
        {
          !loading
          &&
          <>
            <Row>
              {
                returnSubBrandList().length > 0
                &&
                <Col xl="3">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    labelField="name"
                    valueField="id"
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Years" })}
                  name="years"
                  options={yearList}
                  initialLabel={selectedYear}
                  initialValue={selectedYear}
                  setFieldValue={setSelectedYear}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl="3">
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Month" })}
                  name="months"
                  options={monthList}
                  initialLabel={selectedMonth}
                  initialValue={selectedMonthValue}
                  setFieldLabel={setSelectedMonth}
                  setFieldValue={setSelectedMonthValue}
                  disabled={localLoading || loading}
                  validationRequired={true}
                />
              </Col>
              <Col xl={"3"}>
                {
                  localLoadingDateRange
                  ?
                  <Loading/>
                  :
                  <GeneralDateRange
                    title={intl.formatMessage({ id: "DateRange" })}
                    name={"DateRange"}
                    disabled={localLoading || loading}
                    initialValue={selectedDateRange}
                    minMaxDateObj={dateRangeLimit}
                    validationRequired={true}
                    onChangeFunction={onSelectDateRange}/>
                }
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading }
                  disable={localLoading || loading }
                  onClick={() => {
                    fetchBranchSalesSummaryListWithLoading(false)
                  }}
                />
              </Col>
            </Row>
          </>
        } 
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={BranchSalesSummaryColumns}
              bordered={true}
              keyField={"branchId"}
              data={branchSalesSummaryList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "BranchSalesSummary" }) })}
              objSorted={defaultSorted}
              requestAPI={getBranchSalesSummaryList}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(BranchSalesSummary);
