import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Row, Col, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import * as Yup from "yup";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import Loading from "../../app/components/loading/Loading";
import { useIntl } from "react-intl";
import AppointmentLeadProfileDetail from "./SubView/AppointmentLeadProfileDetail";
import moment from "moment";
import classNames from "classnames";
import { appointmentStatusColor, checkPermission, IncludesLocationPathName } from "../../app/common/function/function";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import PaginationTable from "../../app/components/table/PaginationTable";
import MyAddButton from "../../app/components/form/MyAddButton";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { AppointmentLeadFollowUpListObject } from "../../app/models/appointmentLead";
import AppointmentLeadFollowUpHistoryModal from "./Modal/AppointmentLeadFollowUpHistoryModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import HistoryIcon from 'mdi-react/HistoryIcon';
import CalendarAccountIcon from 'mdi-react/CalendarAccountIcon';
import GeneralStatus from "../../app/components/form/GeneralStatus";

const AppointmentLeadProfile = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  
  //Use Store
  const { appointmentLeadStore, appointmentStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading, setShowDeletePrompt } = commonStore;
  const { appointmentLeadDetail, getAppointmentLeadWithId, appointmentLeadFollowUpList, getAppointmentLeadFollowUp, getAppointmentLeadFollowUpWithId, appointmentLeadFollowUpDetail, setAppointmentLeadFollowUpDetail, addAppointmentLeadFollowUp, updateAppointmentLeadFollowUp, deleteAppointmentLeadFollowUp, appointmentLeadFollowUpTotalItems, setAppointmentLeadDetail } = appointmentLeadStore;
  const { appointmentList, getAppointmentList, appointmentTotalItems } = appointmentStore;

  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${intl.formatMessage({ id: "FollowUpProfile" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "FollowUp" }), urlPath: RoutesList.appointmentLead }];
  const [isMenu, setIsMenu] = useState(false);
  const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "FollowUpHistory" }).toUpperCase(), intl.formatMessage({ id: "Appointment" }).toUpperCase()]);

  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAppointment, setCurrentPageAppointment] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [appointmentLeadFollowUpHistoryModal, setAppointmentLeadFollowUpHistoryModal] = useState(false);
  const [blnAction, setBlnAction] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<AppointmentLeadFollowUpListObject | undefined>(undefined);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingAppointmentLeadFollowUpHistory, setLoadingAppointmentLeadFollowUpHistory] = useState(false);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const blnPermissionManageAppointmentLeadFollowUp = checkPermission([PermissionConstants.ManageAppointmentLeadFollowUp])
  const blnPermissionCreateAppointmentLeadFollowUp = checkPermission([PermissionConstants.CreateAppointmentLeadFollowUp])
  const blnPermissionUpdateAppointmentLeadFollowUp = checkPermission([PermissionConstants.UpdateAppointmentLeadFollowUp])
  const blnPermissionDeleteAppointmentLeadFollowUp = checkPermission([PermissionConstants.DeleteAppointmentLeadFollowUp])

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "AppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{moment(row.appointStartDateTime).format(Constants.displayDateAndTimeFormat)}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
    },
    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.madeBy || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.remark || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ApptContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="" >
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) =>
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor}/>
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManageAppointment])
              &&
              <TableActionButton 
                to={`/${RoutesList.appointment}/view/${row.id}`}
                type={'view'}/>
            }
          </div>
        </>
      ),
    },
  ];

  const AppointmentLeadFollowUpHistoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      style: {
        width: '30%'
      },
    },
    {
      dataField: "followedUpUserName",
      text: intl.formatMessage({ id: "FollowedUpUser" }).toUpperCase(),
    },
    {
      dataField: "followedUpOn",
      text: intl.formatMessage({ id: "FollowedUpOn" }).toUpperCase(),
      formatter: (cellContent, row) => <>{moment(row.followedUpOn).format(Constants.displayDateAndTimeFormat)}</>, //Custom UI to display
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageAppointmentLeadFollowUp, PermissionConstants.UpdateAppointmentLeadFollowUp, PermissionConstants.DeleteAppointmentLeadFollowUp]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageAppointmentLeadFollowUp
              &&
              <TableActionButton 
                type={'view'}
                onClick={() => onClickAppointmentLeadFollowUpAction(Constants.view, row)}/>
            }
            {
              blnPermissionUpdateAppointmentLeadFollowUp
              &&
              <TableActionButton 
                type={'edit'}
                onClick={() => onClickAppointmentLeadFollowUpAction(Constants.update, row)}/>
            }
            {
              blnPermissionDeleteAppointmentLeadFollowUp
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: appointmentLeadDetail || {    
      brandId: "",
      brandName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      consultantId: "",
      consultantName: "",
      serviceTreatmentId: "",
      serviceTreatmentName: "",
      promoRemark: "",
      voucherReferenceNo: "",
      leadNo: "",
      name: "",
      mobileNo: "",
      email: "",
      sourceId: "",
      mediaSource: "",
      branchId: "",
      branchName: "",
      submissionDate: "",
      status: "",
      remark: "",
      lastFollowUpDate: "",
      lastAppointmentDate: "",
      raceId: "",
      raceName: "",
      subBrandId: "",
      subBrandName: "",
      subBrandIcon: "",
      lastFollowUpRemark: "",
      type: "",
      id: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
    }
  });

  const blnAddAppointment = checkPermission([PermissionConstants.CreateAppointment]);

  async function fetchCustomerDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getAppointmentLeadWithId(id),
        getAppointmentLeadFollowUp({ pageNumber: currentPage, pageSize: pageSize, leadId: id }),
        getAppointmentList({ pageNumber: 1, pageSize: pageSize, branchId: "", leadId: id })
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageAppointmentLead], true)) {
        return;
      }
    }

    fetchCustomerDetailAPI();
    return (() => {
      if (id && !addAction) {
        setAppointmentLeadFollowUpDetail(undefined);
        setAppointmentLeadDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(async () => {
        setSuccessMessage("");
        await fetchCustomerDetailAPI();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const onClickDelete = async (row: AppointmentLeadFollowUpListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const onClickAppointmentLeadFollowUpAction = async (blnActionTemp: string, row?: AppointmentLeadFollowUpListObject) => {
    if (blnActionTemp === Constants.add) {
      setBlnAction(blnActionTemp);
      setAppointmentLeadFollowUpHistoryModal(true);
    }
    else if (row) {
      setLoadingAppointmentLeadFollowUpHistory(true);
      let resultAppointmentLeadFollowUpWithId = await getAppointmentLeadFollowUpWithId(row.id);
      setLoadingAppointmentLeadFollowUpHistory(false);
      if (resultAppointmentLeadFollowUpWithId?.status === Constants.success) {
        setBlnAction(blnActionTemp);
        setAppointmentLeadFollowUpHistoryModal(true);
      }
    }
  }

	const returnTabBarIcon = (valueTab: string) => {
    let iconTabBar: any = null;
    if (valueTab === intl.formatMessage({ id: "Appointment" }).toUpperCase()) {
      iconTabBar = <CalendarAccountIcon size={Constants.menuIconSize} />;
    }
    else if (valueTab === intl.formatMessage({ id: "FollowUpHistory" }).toUpperCase()) {
      iconTabBar = <HistoryIcon size={Constants.menuIconSize} />
    }
		return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
	}

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={[]}
        auditTrailId={viewAction ? id : null}>
        {loading && !successMessage ? (
          <Loading />
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row>
              <Col xl="3" lg="4" sm="4">
                <AppointmentLeadProfileDetail
                  validation={validation}
                  isMenu={isMenu}
                  toggleMenu={toggleMenu}
                  windowSize={windowSize}
                />
              </Col>
              <Col xl="9" lg="8" sm="8">
                <div className="standard-layout">
                  <Nav className="nav nav-tabs nav-tabs-custom">
                    {tabBar.map((value, indexTab) => (
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          disabled={indexTab == 1 && (false)}
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active:
                              activeTab ===
                              String(indexTab + 1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab + 1));
                          }}
                        >
                          {/* {value} */}
                          {returnTabBarIcon(value)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="margin-top-24">
                    {
                      PermissionConstants.ManageAppointmentLeadFollowUp &&
                      <TabPane tabId="1">
                        <Col lg="12">
                          <div className="table-responsive">
                            <div className="flex-right margin-bottom--12">
                              {
                                blnPermissionCreateAppointmentLeadFollowUp &&
                                <MyAddButton
                                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
                                  onClick={() => onClickAppointmentLeadFollowUpAction(Constants.add)}
                                  disable={localLoading || loading || loadingAppointmentLeadFollowUpHistory} />
                              }
                            </div>
                            <PaginationTable
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              pageSize={pageSize}
                              totalItem={appointmentLeadFollowUpTotalItems}
                              tableColumns={AppointmentLeadFollowUpHistoryColumns}
                              data={appointmentLeadFollowUpList}
                              localLoading={loadingAppointmentLeadFollowUpHistory}
                              setLocalLoading={setLoadingAppointmentLeadFollowUpHistory}
                              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
                              objSorted={defaultSorted}
                              hideSearchBar={true}
                              leadId={id}
                              requestAPI={getAppointmentLeadFollowUp} />
                          </div>
                        </Col>
                      </TabPane>
                    }
                    {
                      PermissionConstants.ManageAppointment &&
                      <TabPane tabId="2">
                        <Col lg="12">
                          <div className="table-responsive">
                            <div className={`flex-right ${blnAddAppointment ? "margin-bottom--12" : "margin-bottom--24"}`}>
                              {
                                blnAddAppointment
                                &&
                                <MyAddButton
                                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/${RoutesList.appointment}`,
                                      state: {
                                        detail: {
                                          branchId: validation.values.branchId,
                                          branchName: validation.values.branchName,
                                          consultantId: validation.values.consultantId,
                                          consultantName: validation.values.consultantName,
                                          leadId: validation.values.id,
                                          customerName: validation.values.name,
                                          leadName: validation.values.name,
                                          appointmentSourceType: validation.values.type,
                                          selectedServiceTreatmentIdFromLead: validation.values.serviceTreatmentId,
                                          subBrandName: validation.values.subBrandName,
                                          subBrandId: validation.values.subBrandId,
                                          sourceId: validation.values.sourceId,
                                          mediaSourceName: validation.values.mediaSource,
                                          email: validation.values.email,
                                          contactNo: validation.values.mobileNo,
                                          addAppointmentAction: true
                                        }
                                      }
                                    });
                                  }}
                                  disable={localLoading || loading} />
                              }
                            </div>
                            <PaginationTable
                              currentPage={currentPageAppointment}
                              setCurrentPage={setCurrentPageAppointment}
                              pageSize={pageSize}
                              totalItem={appointmentTotalItems}
                              tableColumns={AppointmentColumns}
                              data={appointmentList}
                              localLoading={loadingAppointment}
                              setLocalLoading={setLoadingAppointment}
                              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                              objSorted={defaultSorted}
                              hideSearchBar={true}
                              leadId={id}
                              requestAPI={getAppointmentList} />
                          </div>
                        </Col>
                      </TabPane>
                    }
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
        {
          appointmentLeadFollowUpHistoryModal &&
          <AppointmentLeadFollowUpHistoryModal
            blnShow={appointmentLeadFollowUpHistoryModal}
            blnAction={blnAction}
            setModal={setAppointmentLeadFollowUpHistoryModal}
            leadId={id}
            addFollowUp={addAppointmentLeadFollowUp}
            updateFollowUp={updateAppointmentLeadFollowUp}
            selectedFollowUpDetail={appointmentLeadFollowUpDetail}
            setFollowUpDetail={setAppointmentLeadFollowUpDetail}
            setErrorMessage={setErrorMessage} />
        }
        <DeleteModal
          displayName={selectedRow?.remark}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteAppointmentLeadFollowUp(selectedRow?.id || "");
            let currentPageTemp = currentPage;
            if (appointmentLeadFollowUpList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getAppointmentLeadFollowUp({ pageNumber: currentPageTemp, pageSize: pageSize, leadId: id })
            setLoading(false);
          }} />
      </DetailViewLayout>
    </div>
  );
}

export default observer(AppointmentLeadProfile);
