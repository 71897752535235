export const PermissionConstants = {
  ManageAppointment: "ManageAppointment",
  CreateAppointment: "CreateAppointment",
  UpdateAppointment: "UpdateAppointment",
  DeleteAppointment: "DeleteAppointment",
  ManageCustomerOrder: "ManageCustomerOrder",
  CreateCustomerOrder: "CreateCustomerOrder",
  UpdateCustomerOrder: "UpdateCustomerOrder",
  DeleteCustomerOrder: "DeleteCustomerOrder",
  ManageCustomerInvoice: "ManageCustomerInvoice",
  UpdateCustomerInvoice: "UpdateCustomerInvoice",
  ManageSalesTarget: "ManageSalesTarget",
  CreateSalesTarget: "CreateSalesTarget",
  UpdateSalesTarget: "UpdateSalesTarget",
  DeleteSalesTarget: "DeleteSalesTarget",
  ManageCustomer: "ManageCustomer",
  CreateCustomer: "CreateCustomer",
  ManualCreateCustomer: "ManualCreateCustomer",
  UpdateCustomer: "UpdateCustomer",
  DeleteCustomer: "DeleteCustomer",
  ManageCustomerDeliverOrder: "ManageCustomerDeliverOrder",
  CreateCustomerDeliverOrder: "CreateCustomerDeliverOrder",
  UpdateCustomerDeliverOrder: "UpdateCustomerDeliverOrder",
  DeleteCustomerDeliverOrder: "DeleteCustomerDeliverOrder",
  RequestVoidCustomerDeliverOrder: "RequestVoidCustomerDeliverOrder",
  ReviewVoidCustomerDeliverOrder: "ReviewVoidCustomerDeliverOrder",
  ManageProduct: "ManageProduct",
  CreateProduct: "CreateProduct",
  UpdateProduct: "UpdateProduct",
  DeleteProduct: "DeleteProduct",
  ManageCategory: "ManageCategory",
  CreateCategory: "CreateCategory",
  UpdateCategory: "UpdateCategory",
  DeleteCategory: "DeleteCategory",
  ManageSalesCategory: "ManageSalesCategory",
  CreateSalesCategory: "CreateSalesCategory",
  UpdateSalesCategory: "UpdateSalesCategory",
  DeleteSalesCategory: "DeleteSalesCategory",
  ManageWarehouse: "ManageWarehouse",
  CreateWarehouse: "CreateWarehouse",
  UpdateWarehouse: "UpdateWarehouse",
  DeleteWarehouse: "DeleteWarehouse",
  ManageMeasurement: "ManageMeasurement",
  CreateMeasurement: "CreateMeasurement",
  UpdateMeasurement: "UpdateMeasurement",
  DeleteMeasurement: "DeleteMeasurement",
  ManageStockMovement: "ManageStockMovement",
  CreateStockMovement: "CreateStockMovement",
  UpdateStockMovement: "UpdateStockMovement",
  DeleteStockMovement: "DeleteStockMovement",
  ManageStockRequest: "ManageStockRequest",
  CreateStockRequest: "CreateStockRequest",
  UpdateStockRequest: "UpdateStockRequest",
  DeleteStockRequest: "DeleteStockRequest",
  ReviewStockRequest: "ReviewStockRequest",
  ManageStockReceive: "ManageStockReceive",
  CreateStockReceive: "CreateStockReceive",
  UpdateStockReceive: "UpdateStockReceive",
  DeleteStockReceive: "DeleteStockReceive",
  CompleteStockReceive: "CompleteStockReceive",
  ManagePurchaseOrder: "ManagePurchaseOrder",
  CreatePurchaseOrder: "CreatePurchaseOrder",
  UpdatePurchaseOrder: "UpdatePurchaseOrder",
  DeletePurchaseOrder: "DeletePurchaseOrder",
  ReviewPurchaseOrder: "ReviewPurchaseOrder",
  ManageGRN: "ManageGRN",
  CreateGRN: "CreateGRN",
  UpdateGRN: "UpdateGRN",
  DeleteGRN: "DeleteGRN",
  ManageInboundNotice: "ManageInboundNotice",
  CreateInboundNotice: "CreateInboundNotice",
  UpdateInboundNotice: "UpdateInboundNotice",
  DeleteInboundNotice: "DeleteInboundNotice",
  ManagePurchaseDeliverOrder: "ManagePurchaseDeliverOrder",
  CreatePurchaseDeliverOrder: "CreatePurchaseDeliverOrder",
  UpdatePurchaseDeliverOrder: "UpdatePurchaseDeliverOrder",
  DeletePurchaseDeliverOrder: "DeletePurchaseDeliverOrder",
  ReviewPurchaseDeliverOrder: "ReviewPurchaseDeliverOrder",
  ManageEmployee: "ManageEmployee",
  CreateEmployee: "CreateEmployee",
  UpdateEmployee: "UpdateEmployee",
  DeleteEmployee: "DeleteEmployee",
  ReassignEmployeeCustomer: "ReassignEmployeeCustomer",
  ManagePermission: "ManagePermission",
  UpdatePermission: "UpdatePermission",
  ManagePosition: "ManagePosition",
  CreatePosition: "CreatePosition",
  UpdatePosition: "UpdatePosition",
  DeletePosition: "DeletePosition",
  ManageLeaveEntitlement: "ManageLeaveEntitlement",
  CreateLeaveEntitlement: "CreateLeaveEntitlement",
  UpdateLeaveEntitlement: "UpdateLeaveEntitlement",
  DeleteLeaveEntitlement: "DeleteLeaveEntitlement",
  ManageEpfContribution: "ManageEpfContribution",
  CreateEpfContribution: "CreateEpfContribution",
  UpdateEpfContribution: "UpdateEpfContribution",
  DeleteEpfContribution: "DeleteEpfContribution",
  ManageEis: "ManageEis",
  CreateEis: "CreateEis",
  UpdateEis: "UpdateEis",
  DeleteEis: "DeleteEis",
  ManagePcb: "ManagePcb",
  CreatePcb: "CreatePcb",
  UpdatePcb: "UpdatePcb",
  DeletePcb: "DeletePcb",
  ManageSocso: "ManageSocso",
  CreateSocso: "CreateSocso",
  UpdateSocso: "UpdateSocso",
  DeleteSocso: "DeleteSocso",
  ManagePayrollItem: "ManagePayrollItem",
  CreatePayrollItem: "CreatePayrollItem",
  UpdatePayrollItem: "UpdatePayrollItem",
  DeletePayrollItem: "DeletePayrollItem",
  ManageBrand: "ManageBrand",
  CreateBrand: "CreateBrand",
  UpdateBrand: "UpdateBrand",
  DeleteBrand: "DeleteBrand",
  ManageBusinessEntity: "ManageBusinessEntity",
  CreateBusinessEntity: "CreateBusinessEntity",
  UpdateBusinessEntity: "UpdateBusinessEntity",
  DeleteBusinessEntity: "DeleteBusinessEntity",
  ManageBranch: "ManageBranch",
  CreateBranch: "CreateBranch",
  UpdateBranch: "UpdateBranch",
  DeleteBranch: "DeleteBranch",
  ManageCountry: "ManageCountry",
  CreateCountry: "CreateCountry",
  UpdateCountry: "UpdateCountry",
  DeleteCountry: "DeleteCountry",
  ManageSupplier: "ManageSupplier",
  CreateSupplier: "CreateSupplier",
  UpdateSupplier: "UpdateSupplier",
  DeleteSupplier: "DeleteSupplier",
  ManageServiceTreatment: "ManageServiceTreatment",
  CreateServiceTreatment: "CreateServiceTreatment",
  UpdateServiceTreatment: "UpdateServiceTreatment",
  DeleteServiceTreatment: "DeleteServiceTreatment",
  ExportServiceTreatment: "ExportServiceTreatment",
  ManagePackage: "ManagePackage",
  CreatePackage: "CreatePackage",
  UpdatePackage: "UpdatePackage",
  DeletePackage: "DeletePackage",
  ExportPackage: "ExportPackage",
  ManageTax: "ManageTax",
  CreateTax: "CreateTax",
  UpdateTax: "UpdateTax",
  DeleteTax: "DeleteTax",
  ManageCurrency: "ManageCurrency",
  CreateCurrency: "CreateCurrency",
  UpdateCurrency: "UpdateCurrency",
  DeleteCurrency: "DeleteCurrency",
  ManageRace: "ManageRace",
  CreateRace: "CreateRace",
  UpdateRace: "UpdateRace",
  DeleteRace: "DeleteRace",
  ManageMediaSource: "ManageMediaSource",
  CreateMediaSource: "CreateMediaSource",
  UpdateMediaSource: "UpdateMediaSource",
  DeleteMediaSource: "DeleteMediaSource",
  ManageUserRole: "ManageUserRole",
  ManageNotificationTemplate: "ManageNotificationTemplate",
  CreateNotificationTemplate: "CreateNotificationTemplate",
  UpdateNotificationTemplate: "UpdateNotificationTemplate",
  DeleteNotificationTemplate: "DeleteNotificationTemplate",
  ManageStockAdjustment: "ManageStockAdjustment",
  CreateStockAdjustment: "CreateStockAdjustment",
  UpdateStockAdjustment: "UpdateStockAdjustment",
  DeleteStockAdjustment: "DeleteStockAdjustment",
  ReviewStockAdjustment: "ReviewStockAdjustment",
  ManageStockIn: "ManageStockIn",
  CreateStockIn: "CreateStockIn",
  UpdateStockIn: "UpdateStockIn",
  DeleteStockIn: "DeleteStockIn",
  ReviewStockIn: "ReviewStockIn",
  ManageStockOut: "ManageStockOut",
  CreateStockOut: "CreateStockOut",
  UpdateStockOut: "UpdateStockOut",
  DeleteStockOut: "DeleteStockOut",
  ReviewStockOut: "ReviewStockOut",
  ManageStockReturnToHQ: "ManageHQStockReturn",
  CreateStockReturnToHQ: "CreateHQStockReturn",
  UpdateStockReturnToHQ: "UpdateHQStockReturn",
  ReviewStockReturnToHQ: "ReviewHQStockReturn",
  ManagePaymentMethod: "ManagePaymentMethod",
  CreatePaymentMethod: "CreatePaymentMethod",
  UpdatePaymentMethod: "UpdatePaymentMethod",
  DeletePaymentMethod: "DeletePaymentMethod",
  ManagePayslip: "ManagePayslip",
  CreatePayslip: "CreatePayslip",
  UpdatePayslip: "UpdatePayslip",
  DeletePayslip: "DeletePayslip",
  ManageAdditionalFormField: "ManageAdditionalFormField",
  CreateAdditionalFormField: "CreateAdditionalFormField",
  UpdateAdditionalFormField: "UpdateAdditionalFormField",
  DeleteAdditionalFormField: "DeleteAdditionalFormField",
  ManageNotification: "ManageNotification",
  CreateNotification: "CreateNotification",
  UpdateNotification: "UpdateNotification",
  DeleteNotification: "DeleteNotification",
  ManageReport: "ManageReport",
  ManageStockTake: "ManageStockTake",
  CreateStockTake: "CreateStockTake",
  UpdateStockTake: "UpdateStockTake",
  DeleteStockTake: "DeleteStockTake",
  ManageAuditLog: "ManageAuditLog",
  ManageCustomerNote: "ManageCustomerNote",
  CreateCustomerNote: "CreateCustomerNote",
  UpdateCustomerNote: "UpdateCustomerNote",
  DeleteCustomerNote: "DeleteCustomerNote",
  ManageLead: "ManageLead",
  CreateLead: "CreateLead",
  UpdateLead: "UpdateLead",
  UpdateWalkInLead: "UpdateWalkInLead",
  DeleteLead: "DeleteLead",
  ManageFollowUpHistory: "ManageFollowUpHistory",
  CreateFollowUpHistory: "CreateFollowUpHistory",
  UpdateFollowUpHistory: "UpdateFollowUpHistory",
  DeleteFollowUpHistory: "DeleteFollowUpHistory",
  ManageAppointmentSetting: "ManageAppointmentSetting",
  UpdateAppointmentSetting: "UpdateAppointmentSetting",
  ManageGeneralSetting: "ManageGeneralSetting",
  UpdateGeneralSetting: "UpdateGeneralSetting",
  ManageSalesSetting: "ManageSalesSetting",
  UpdateSalesSetting: "UpdateSalesSetting",
  ManageCustomerSalesOrder: "ManageCustomerSalesOrder",
  CreateCustomerSalesOrder: "CreateCustomerSalesOrder",
  UpdateCustomerSalesOrder: "UpdateCustomerSalesOrder",
  RequestVoidCustomerSalesOrder: "RequestVoidCustomerSalesOrder",
  ReviewVoidCustomerSalesOrder: "ReviewVoidCustomerSalesOrder",
  ManagePromotion: "ManagePromotion",
  CreatePromotion: "CreatePromotion",
  UpdatePromotion: "UpdatePromotion",
  DeletePromotion: "DeletePromotion",
  ManageCreditAdjustment: "ManageCreditAdjustment",
  DeleteCreditAdjustment: "DeleteCreditAdjustment",
  RequestCreditAdjustment: "RequestCreditAdjustment",
  ReviewCreditAdjustment: "ReviewCreditAdjustment",
  CreateTransferCredit: "CreateTransferCredit",
  CreateConsultantSales: "CreateConsultantSales",
  ManageEmployeeLeave: "ManageEmployeeLeave",
  CreateEmployeeLeave: "CreateEmployeeLeave",
  UpdateEmployeeLeave: "UpdateEmployeeLeave",
  DeleteEmployeeLeave: "DeleteEmployeeLeave",
  ManagePaymentAdjustment: "ManagePaymentAdjustment",
  RequestPaymentAdjustment: "RequestPaymentAdjustment",
  ReviewPaymentAdjustment: "ReviewPaymentAdjustment",
  DeletePaymentAdjustment: "DeletePaymentAdjustment",
  ManageCustomerProductReturn: "ManageCustomerProductReturn",
  CreateCustomerProductReturn: "CreateCustomerProductReturn",
  UpdateCustomerProductReturn: "UpdateCustomerProductReturn",
  ReviewCustomerProductReturn: "ReviewCustomerProductReturn",
  ManageExtendPackage: "ManageExtendPackage",
  RequestExtendPackage: "RequestExtendPackage",
  ReviewExtendPackage: "ReviewExtendPackage",
  DeleteExtendPackage: "DeleteExtendPackage",
  ManageLeadDuplicate: "ManageDuplicateLead",
  DeleteLeadDuplicate: "DeleteDuplicateLead",
  ManagePaymentMethodOption: "ManagePaymentMethodOption",
  CreatePaymentMethodOption: "CreatePaymentMethodOption",
  UpdatePaymentMethodOption: "UpdatePaymentMethodOption",
  DeletePaymentMethodOption: "DeletePaymentMethodOption",
  ManageSalesAdjustment: "ManageSalesAdjustment",
  CreateSalesAdjustment: "CreateSalesAdjustment",
  UpdateSalesAdjustment: "UpdateSalesAdjustment",
  DeleteSalesAdjustment: "DeleteSalesAdjustment",
  ManageCommissionScheme: "ManageCommissionScheme",
  CreateCommissionScheme: "CreateCommissionScheme",
  UpdateCommissionScheme: "UpdateCommissionScheme",
  DeleteCommissionScheme: "DeleteCommissionScheme",
  ManageAppointmentReport: "ManageAppointmentReport",
  ExportAppointmentReport: "ExportAppointmentReport",
  ManageCustomerReport: "ManageCustomerReport",
  ExportCustomerReport: "ExportCustomerReport",
  ManageInventoryReport: "ManageInventoryReport",
  ExportInventoryReport: "ExportInventoryReport",
  ManageManagementReport: "ManageManagementReport",
  ExportManagementReport: "ExportManagementReport",
  ManageMarcomReport: "ManageMarcomReport",
  ExportMarcomReport: "ExportMarcomReport",
  ManagePaymentReport: "ManagePaymentReport",
  ExportPaymentReport: "ExportPaymentReport",
  ManageSalesReport: "ManageSalesReport",
  ExportSalesReport: "ExportSalesReport",
  ManageServiceReport: "ManageServiceReport",
  ExportServiceReport: "ExportServiceReport",
  ManageMasterDataReport: "ManageMasterDataReport",
  ExportMasterDataReport: "ExportMasterDataReport",
  ManageHumanResourceReport: "ManageHumanResourceReport",
  ExportHumanResourceReport: "ExportHumanResourceReport",
  ManageAppolousReport: "ManageAppolousReport",
  ExportAppolousReport: "ExportAppolousReport",
  ManageCustomerAccessRequest: "ManageCustomerAccessRequest",
  CreateCustomerAccessRequest: "CreateCustomerAccessRequest",
  UpdateCustomerAccessRequest: "UpdateCustomerAccessRequest",
  ReviewCustomerAccessRequest: "ReviewCustomerAccessRequest",
  DeleteCustomerAccessRequest: "DeleteCustomerAccessRequest",
  ManageCache: "ManageCache",
  CreateStockOutProduct: "CreateStockOutProduct",
  ManageCustomerTransferRequest: "ManageCustomerTransferRequest",
  CreateCustomerTransferRequest: "CreateCustomerTransferRequest",
  UpdateCustomerTransferRequest: "UpdateCustomerTransferRequest",
  ReviewCustomerTransferRequest: "ReviewCustomerTransferRequest",
  DeleteCustomerTransferRequest: "DeleteCustomerTransferRequest",
  ManageAdditionBonusSalesTarget: "ManageAdditionBonusSalesTarget", 
  CreateAdditionBonusSalesTarget: "CreateAdditionBonusSalesTarget",
  UpdateAdditionBonusSalesTarget: "UpdateAdditionBonusSalesTarget",
  DeleteAdditionBonusSalesTarget: "DeleteAdditionBonusSalesTarget",
  ManageBranchCommission: "ManageBranchCommission",
  CreateBranchCommission: "CreateBranchCommission",
  UpdateBranchCommission: "UpdateBranchCommission",
  DeleteBranchCommission: "DeleteBranchCommission",
  ReviewBranchCommissionFirstApproval: "ReviewBranchCommissionFirstApproval",
  ReviewBranchCommissionSecondApproval: "ReviewBranchCommissionSecondApproval",
  ReviewBranchCommissionFinalApproval: "ReviewBranchCommissionFinalApproval",
  ManageCustomerVirtualWarehouse: "ManageCustomerVirtualWarehouse",
  ManageVirtualWarehouseCreditExchange: "ManageVirtualWarehouseCreditExchange",
  RequestVirtualWarehouseCreditExchange: "RequestVirtualWarehouseCreditExchange",
  ReviewVirtualWarehouseCreditExchange: "ReviewVirtualWarehouseCreditExchange", 
  UpdateVirtualWarehouseCreditExchange: "UpdateVirtualWarehouseCreditExchange",
  ManageProductRedemptionNote: "ManageProductRedemptionNote",
  CreateProductRedemptionNote: "CreateProductRedemptionNote",
  UpdateProductRedemptionNote: "UpdateProductRedemptionNote",
  RequestVoidProductRedemptionNote: "RequestVoidProductRedemptionNote",
  ReviewVoidProductRedemptionNote: "ReviewVoidProductRedemptionNote",
  ManageProductRedemptionNoteReturn: "ManageProductRedemptionNoteReturn",
  RequestProductRedemptionNoteReturn: "RequestProductRedemptionNoteReturn",
  ReviewProductRedemptionNoteReturn: "ReviewProductRedemptionNoteReturn",
  ManageContractLiability: "ManageContractLiability",
  BackdateCustomerSalesOrder: "BackdateCustomerSalesOrder",
  BackdateCustomerDeliverOrder: "BackdateCustomerDeliverOrder",
  BackdateProductRedemptionNote: "BackdateProductRedemptionNote",
  ManageDSR: "ManageDSR",
  CreateDSR: "CreateDSR",
  DeleteDSR: "DeleteDSR",
  ManageBatchJob: "ManageBatchJob",
  CreateBatchJob: "CreateBatchJob",
  UpdateBatchJob: "UpdateBatchJob",
  DeleteBatchJob: "DeleteBatchJob",
  ManageCustomerSubBranchRequest: "ManageCustomerSubBranchRequest",
  CreateCustomerSubBranchRequest: "CreateCustomerSubBranchRequest",
  UpdateCustomerSubBranchRequest: "UpdateCustomerSubBranchRequest",
  DeleteCustomerSubBranchRequest: "DeleteCustomerSubBranchRequest",
  ReviewCustomerSubBranchRequest: "ReviewCustomerSubBranchRequest",
  ManageAppolousRedemption: "ManageAppolousRedemption",
  CreateAppolousRedemption: "CreateAppolousRedemption",
  UpdateAppolousRedemption: "UpdateAppolousRedemption",
  DeleteAppolousRedemption: "DeleteAppolousRedemption",
  ExtendAppolousRedemption: "ExtendAppolousRedemption",
  StatusUpdateAppolousRedemption: "StatusUpdateAppolousRedemption",
  ManageAppolousSales: "ManageAppolousSales",
  CreateAppolousSales: "CreateAppolousSales",
  UpdateAppolousSales: "UpdateAppolousSales",
  DeleteAppolousSales: "DeleteAppolousSales",
  SearchAllBranchCustomer: "SearchAllBranchCustomer",
  ReviseClosedAppointment: "ReviseClosedAppointment",
  BackdateAppolousSales: "BackdateAppolousSales",
  BackdateAppolousRedemption: "BackdateAppolousRedemption",
  EditCustomerJoinedDate: "EditCustomerJoinedDate",
  ManageAppointmentTarget: "ManageAppointmentTarget",
  CreateAppointmentTarget: "CreateAppointmentTarget",
  UpdateAppointmentTarget: "UpdateAppointmentTarget",
  DeleteAppointmentTarget: "DeleteAppointmentTarget",
  ManageMarcomDailyRecords: "ManageMarcomDailyRecords",
  CreateMarcomDailyRecords: "CreateMarcomDailyRecords",
  DeleteMarcomDailyRecords: "DeleteMarcomDailyRecords",
  CreateAppolousPackage: "CreateAppolousPackage",
  CreateAppolousProduct: "CreateAppolousProduct",
  EditCustomerMediaSource: "EditCustomerMediaSource",
  DeleteAppolousPayment: "DeleteAppolousPayment",
  UpdateStockReceiveStatus: "UpdateStockReceiveStatus",
  UpdateCustomerSalesOrderStatus: "UpdateCustomerSalesOrderStatus",
  UpdateAppolousRedemptionDetail: "UpdateAppolousRedemptionDetail",
  UpdateCustomerDeliverOrderDetail: "UpdateCustomerDeliverOrderDetail",
  ResetEmployeePassword: "ResetEmployeePassword",
  ExtendCustomerAccessRequest: "ExtendCustomerAccessRequest",
  ManageDepartment: "ManageDepartment",
  CreateDepartment: "CreateDepartment",
  UpdateDepartment: "UpdateDepartment",
  DeleteDepartment: "DeleteDepartment",
  ManageChangePaymentMethod: "ManageChangePaymentMethod",
  RequestChangePaymentMethod: "RequestChangePaymentMethod",
  ReviewChangePaymentMethod: "ReviewChangePaymentMethod",
  DeleteChangePaymentMethod: "DeleteChangePaymentMethod",
  ManageAppointmentLead: "ManageAppointmentLead",
  ManageAppointmentLeadFollowUp: "ManageAppointmentLeadFollowUp",
  CreateAppointmentLeadFollowUp: "CreateAppointmentLeadFollowUp",
  UpdateAppointmentLeadFollowUp: "UpdateAppointmentLeadFollowUp",
  DeleteAppointmentLeadFollowUp: "DeleteAppointmentLeadFollowUp",
  ManageMachine: "ManageMachine",
  CreateMachine: "CreateMachine",
  UpdateMachine: "UpdateMachine",
  DeleteMachine: "DeleteMachine",
  ManageMachineUsage: "ManageMachineUsage",
  ResetEmployeePasscode: "ResetEmployeePasscode",
  BackdateCustomerSalesOrderPayment: "BackdateCustomerSalesOrderPayment",
  ManageContractLiabilityBenchmark: "ManageContractLiabilityBenchmark",
  CreateContractLiabilityBenchmark: "CreateContractLiabilityBenchmark",
  UpdateContractLiabilityBenchmark: "UpdateContractLiabilityBenchmark",
  DeleteContractLiabilityBenchmark: "DeleteContractLiabilityBenchmark",
  ManageGroupCode: "ManageGroupCode",
  CreateGroupCode: "CreateGroupCode",
  UpdateGroupCode: "UpdateGroupCode",
  DeleteGroupCode: "DeleteGroupCode",
  ManageAccountReport: "ManageAccountReport",
  ExportAccountReport: "ExportAccountReport",
  CreateCustomerSalesOrderPayment: "CreateCustomerSalesOrderPayment",
  UpdateCustomerSalesOrderSubBrand: "UpdateCustomerSalesOrderSubBrand",
  ManageTnc: "ManageTnc",
  CreateTnc: "CreateTnc",
  UpdateTnc: "UpdateTnc",
  DeleteTnc: "DeleteTnc",
  ManageUnknownCustomerPayment: "ManageUnknownCustomerPayment",
  CreateUnknownCustomerPayment: "CreateUnknownCustomerPayment",
  UpdateUnknownCustomerPayment: "UpdateUnknownCustomerPayment",
  DeleteUnknownCustomerPayment: "DeleteUnknownCustomerPayment",
  UseUnknownCustomerPayment: "UseUnknownCustomerPayment",
  ManageCashOut: "ManageCashOut",
  CreateCashOut: "CreateCashOut",
  UpdateCashOut: "UpdateCashOut",
  DeleteCashOut: "DeleteCashOut",
  ManagePaymentTerminal: "ManagePaymentTerminal",
  CreatePaymentTerminal: "CreatePaymentTerminal",
  UpdatePaymentTerminal: "UpdatePaymentTerminal",
  DeletePaymentTerminal: "DeletePaymentTerminal",
  ManageChangePaymentMode: "ManageChangePaymentMode",
  CreateChangePaymentMode: "CreateChangePaymentMode",
  UpdateChangePaymentMode: "UpdateChangePaymentMode",
  DeleteChangePaymentMode: "DeleteChangePaymentMode",
  ReviewChangePaymentModeFirstApproval: "ReviewChangePaymentModeFirstApproval",
  ReviewChangePaymentModeFinalApproval: "ReviewChangePaymentModeFinalApproval",
  ManageCloseCounter: "ManageCloseCounter",
  CreateCloseCounter: "CreateCloseCounter",
  UpdateCloseCounter: "UpdateCloseCounter",
  DeleteCloseCounter: "DeleteCloseCounter",
  OverrideCloseCounter: "OverrideCloseCounter",
  DeleteBlocker: "DeleteBlocker",
  PrintAllStockAdjustment: "PrintAllStockAdjustment",
  ManageProductCreditCommissionScheme: "ManageProductCreditCommissionScheme",
  CreateProductCreditCommissionScheme: "CreateProductCreditCommissionScheme",
  UpdateProductCreditCommissionScheme: "UpdateProductCreditCommissionScheme",
  DeleteProductCreditCommissionScheme: "DeleteProductCreditCommissionScheme",
  ManageExchangeToCredit: "ManageExchangedCredit",
  ConvertExchangeToCredit: "ConvertExchangedCredit",
  ReviewExchangeToCredit: "ReviewExchangedCredit",
  CreateCreditRedemptionNoteRequest: "CreateCreditRedemptionNoteRequest",
  ManageTaxEntity: "ManageTaxEntity",
  CreateTaxEntity: "CreateTaxEntity",
  UpdateTaxEntity: "UpdateTaxEntity",
  DeleteTaxEntity: "DeleteTaxEntity",
  ManageCreditRedemptionNoteRequest: "ManageCreditRedemptionNoteRequest",
  ReviewCreditRedemptionNoteRequest: "ReviewCreditRedemptionNoteRequest",
  UpdateCreditRedemptionNoteRequest: "UpdateCreditRedemptionNoteRequest",
  ManageCreditRedemptionNote: "ManageCreditRedemptionNote",
  UpdateCreditRedemptionNote: "UpdateCreditRedemptionNote",
  ManageCreditNote: "ManageCreditNote",
  CreateCreditNote: "CreateCreditNote",
  ReviewCreditNote: "ReviewCreditNote",
  ManageRefundNote: "ManageRefundNote",
  CreateRefundNote: "CreateRefundNote",
  UpdateRefundNote: "UpdateRefundNote",
  DeleteRefundNote: "DeleteRefundNote",
  ReviewRefundNoteApproval: "ReviewRefundNoteApproval",
  ReviewRefundNoteComplete: "ReviewRefundNoteComplete",
  ReviewRefundNoteVerify: "ReviewRefundNoteVerify",
  ReviewCreditRedemptionNoteRequestWithRefund: "ReviewCreditRedemptionNoteRequestWithRefund"
}