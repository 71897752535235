import { PaginationRequestBody } from "../models/pagination";
import { AdditionBonusSalesTargetAddObject, AdditionBonusSalesTargetDetailObject, AdditionBonusSalesTargetDuplicatedRequestObject, AdditionBonusSalesTargetListObject, AdditionBonusSalesTargetUpdateObject } from "../models/additionBonusSalesTarget";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class AdditionBonusSalesTargetStore {
  additionBonusSalesTargetList: AdditionBonusSalesTargetListObject[] = [];
  additionBonusSalesTargetDetail: AdditionBonusSalesTargetDetailObject | undefined = undefined;
  additionBonusSalesTargetPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.additionBonusSalesTargetList = [];
    this.additionBonusSalesTargetDetail = undefined;
    this.additionBonusSalesTargetPaginationParams= undefined;
  }

  setAdditionBonusSalesTargetDetail = (additionBonusSalesTargetDetail: AdditionBonusSalesTargetDetailObject | undefined) => {
    this.additionBonusSalesTargetDetail = additionBonusSalesTargetDetail;
  }

  setAdditionBonusSalesTargetPaginationParams = (additionBonusSalesTargetPaginationParams: PaginationRequestBody | undefined) => {
    this.additionBonusSalesTargetPaginationParams = additionBonusSalesTargetPaginationParams;
  }

  getAdditionBonusSalesTarget = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setAdditionBonusSalesTargetPaginationParams(PaginationRequestBody);
    try{
      const resultAdditionBonusSalesTarget = await agent.AdditionBonusSalesTarget.additionBonusSalesTargetList(PaginationRequestBody);
      runInAction(() => {
        this.additionBonusSalesTargetList = resultAdditionBonusSalesTarget.data;
        store.commonStore.setTotalItem(resultAdditionBonusSalesTarget.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.additionBonusSalesTargetList = [];
    }
  }

  getAdditionBonusSalesTargetWithId = async (id: string) => {
    try{
      const resultAdditionBonusSalesTargetDetail = await agent.AdditionBonusSalesTarget.additionBonusSalesTargetDetail(id);
      runInAction(() => {
        this.additionBonusSalesTargetDetail = resultAdditionBonusSalesTargetDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.additionBonusSalesTargetDetail = undefined;
    }
  }

  addAdditionBonusSalesTarget = async (additionBonusSalesTargetRequestBody: AdditionBonusSalesTargetAddObject) => {
    try{
      await agent.AdditionBonusSalesTarget.addAdditionBonusSalesTarget(additionBonusSalesTargetRequestBody);
      store.commonStore.setSuccessMessage(`AdditionBonusSalesTargetAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateAdditionBonusSalesTarget = async (additionBonusSalesTargetRequestBody: AdditionBonusSalesTargetUpdateObject) => {
    try{
      await agent.AdditionBonusSalesTarget.updateAdditionBonusSalesTarget(additionBonusSalesTargetRequestBody);
      store.commonStore.setSuccessMessage(`AdditionBonusSalesTargetUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteAdditionBonusSalesTarget  = async (id: string, name: string) => {
    try {
      await agent.AdditionBonusSalesTarget.deleteAdditionBonusSalesTarget(id);
      store.commonStore.setSuccessMessage(`AdditionBonusSalesTargetDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  duplicateAdditionBonusSalesTarget = async (additionBonusSalesTargetDuplicatedRequestObject: AdditionBonusSalesTargetDuplicatedRequestObject) => {
    try {
      await agent.AdditionBonusSalesTarget.additionBonusSalesTargetDuplicate(additionBonusSalesTargetDuplicatedRequestObject);
      store.commonStore.setSuccessMessage(`Addition Bonus Sales Target successfully duplicated for year ${additionBonusSalesTargetDuplicatedRequestObject.startYear} till year ${additionBonusSalesTargetDuplicatedRequestObject.endYear}.`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}