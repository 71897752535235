import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { useIntl } from "react-intl";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import TableActionButton from "../../app/components/table/TableActionButton";
import { returnPriceWithCurrency, newSpaceBeforeCapitalLetter, returnSubBrandList, checkPermission, getBranchId, returnAdvancedSearchText, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, AddOptionsValueField, salesOrderStatusColor } from "../../app/common/function/function";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { RoutesList } from "../../app/constants/RoutesList";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PaginationRequestBody } from '../../app/models/pagination';
import { DateRangeInterface } from '../../app/models/common';
import { Link } from "react-router-dom";
import { SubBrandObject } from '../../app/models/brand';
import { PermissionConstants } from '../../app/constants/PermissionConstants';
import Loading from '../../app/components/loading/Loading';
import { Col, Row, Label } from 'reactstrap';
import ListViewLayout from '../../app/components/layout/ListViewLayout';
import CustomerSearchModal from '../CustomerPurchase/Modal/CustomerSearchModal';
import MyButton from '../../app/components/form/MyButton';
import GeneralSelectDropdown from '../../app/components/form/GeneralSelectDropdown';
import DoubleColumnRowParent from '../../app/components/form/DoubleColumnRowParent';
import GeneralDateRange from '../../app/components/form/GeneralDateRange';
import GeneralInput from '../../app/components/form/GeneralInput';

const CreditRedemptionNote = () => {

    const intl = useIntl();

    //Use Store
    const { commonStore, crnStore, customerStore, staticReferenceStore } = useStore();
    const { totalItem, loading, windowSize, prevPath, setSuccessMessage, setErrorMessage, setLoading } = commonStore;
    const { crnList, crnPaginationParams, getCRN, setCRNPaginationParams } = crnStore;
    const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
    const { getStaticReferenceWithType } = staticReferenceStore;

    const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
    const [selectedCreditRedemptionNoteStatus, setSelectedCreditRedemptionNoteStatus] = useState<string>(AllStatus);
    const [selectedCreditRedemptionNoteStatusId, setSelectedCreditRedemptionNoteStatusId] = useState<number | string | undefined>(" ");
    const [creditRNList, setCreditRNList] = useState<any>([]);
    const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
    const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
    const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
    const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
    const allCustomer = `${intl.formatMessage({ id: "AllCustomer" })}`;
    const [selectedCustomer, setSelectedCustomer] = useState<string>(allCustomer);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
    const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
    const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
    const [customerSearchModal, setCustomerSearchModal] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [loadingCustomer, setLoadingCustomer] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [initialCallAPI, setInitialCallAPI] = useState(true);
    const pageSize = Constants.defaultPageSize;
    const [triggerSearch, setTriggerSearch] = useState(0);
    const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);


    //Default Sorting Type
    const defaultSorted = [];

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            documentNo: "",
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
        },
    });

    let creditRedemptionNoteRequestBody: PaginationRequestBody = {
        pageNumber: currentPage,
        pageSize: pageSize,
        branchId: blnAdvancedSearch ? "" : getBranchId(),
        customerId: selectedCustomerId,
        customerName: selectedCustomer,
        documentNo: validation.values.documentNo,
        status: selectedCreditRedemptionNoteStatusId,
        statusName: selectedCreditRedemptionNoteStatus,
        subBrandName: selectedSubBrandName,
        subBrandId: selectedSubBrandId,
        ...selectedDateRange && { dateRange: selectedDateRange }
    }

    useEffect(() => {
        async function fetchCreditRedemptionNoteListAPI() {
            setLocalLoading(true)
            if (returnSubBrandList().length > 0) {
                setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
            }
            let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.creditRedemptionNote)
            if (blnBackNavigation) {
                if (crnPaginationParams) {
                    setCurrentPage(crnPaginationParams.pageNumber);
                    setSelectedDateRange(crnPaginationParams.dateRange || { fromDate: "", toDate: "" });
                    setSelectedCreditRedemptionNoteStatus(crnPaginationParams.statusName || "");
                    setSelectedCreditRedemptionNoteStatusId(crnPaginationParams.status);
                    setSelectedCustomer(crnPaginationParams.customerName || "");
                    setSelectedCustomerId(crnPaginationParams.customerId || "");
                    setSelectedSubBrandName(crnPaginationParams.subBrandName || AllSubBrand);
                    setSelectedSubBrandId(crnPaginationParams.subBrandId || " ");
                    validation.setValues((values) => ({
                        ...values,
                        documentNo: crnPaginationParams.documentNo || "",
                    }))
                    creditRedemptionNoteRequestBody = crnPaginationParams;
                }
            }
            else {
                setCRNPaginationParams(undefined);
            }
            let aryAPI: any = [
                fetchCreditRedemptionNoteAPI(false),
                getStaticReferenceWithType(Constants.salesOrderStatus, AllStatus),
            ];
            let resultAPI = await Promise.all(aryAPI);
            let CRNTypeTemp = AddOptionsValueField(resultAPI[1], "displayValue", "displayValue");
            setCreditRNList(CRNTypeTemp.filter(valueCRNTypeTemp => valueCRNTypeTemp.displayValue !== Constants.pending && 
                valueCRNTypeTemp.displayValue !== Constants.cancelled && 
                valueCRNTypeTemp.displayValue !== Constants.creditExchanged && 
                valueCRNTypeTemp.displayValue !== Constants.creditExchangeSubmitted && 
                valueCRNTypeTemp.displayValue !== Constants.voidRequestSubmitted && 
                valueCRNTypeTemp.displayValue !== Constants.voided));
            setLocalLoading(false);
            setInitialCallAPI(false);
        }

        if (!checkPermission([PermissionConstants.ManageCreditRedemptionNote], true)) {
            return;
        }

        fetchCreditRedemptionNoteListAPI();

        return (() => {
            if (history) {
                const blnSetParams = comparePathForSetParams(history, RoutesList.creditRedemptionNote)
                if (!blnSetParams) {
                    setCRNPaginationParams(undefined);
                }
            }
        })
    }, [])

    useEffect(() => {
        if ((selectedCreditRedemptionNoteStatusId || selectedCreditRedemptionNoteStatusId === 0) && !initialCallAPI) {
            fetchCreditRedemptionNoteAPI(true);
        }
    }, [selectedCreditRedemptionNoteStatusId, triggerSearch, selectedSubBrandId, selectedDateRange, selectedCustomerId])

    async function fetchCreditRedemptionNoteAPI(blnDropDown?: boolean) {
        blnDropDown ? setLocalLoading(true) : setLoading(true);
        await getCRN(creditRedemptionNoteRequestBody)
        blnDropDown ? setLocalLoading(false) : setLoading(false);
    }


    const onClickSelect = (row) => {
        setSelectedCustomer(row.preferredName);
        setSelectedCustomerId(row.id);
        setDisplayCustomerDropdown(false);
        setBlnAdvancedSearch(true);

        setTimeout(() => {
            setDisplayCustomerDropdown(true);
            setCustomerSearchModal(false);
        }, 100)
    }

    const onClickSearch = () => {
        setCurrentPage(1);
        setErrorMessage("");
        setSuccessMessage("");
        setTriggerSearch((value) => value + 1)
    }

    const onSelectDateRange = (objDateRange: any) => {
        setCurrentPage(1);
        setErrorMessage("");
        setSuccessMessage("");
        setSelectedDateRange(objDateRange);
    }

    const clearCustomerDropdownList = () => {
        setCustomerDropdownList([]);
    }

    async function fetchCustomerDropdown(value: string) {
        clearCustomerDropdownList();
        let resultCustomerAPI = await getCustomerDropdown(getBranchId(), { customerNoOrName: value });
        if (resultCustomerAPI) {
            setCustomerDropdownList(resultCustomerAPI);
            setLoadingCustomer(false);
        }
        else if (!value) {
            setLoadingCustomer(false);
        }
    }

    //Table Content UI
    const CreditRedemptionNoteColumns = [
        {
            dataField: "id", //field name from array to display
            text: "id", //display name
            sort: true, //sort function enable
            hidden: true, //hide UI
            formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
        },
        {
            dataField: "documentNo",
            text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
            style: {
                width: "15%"
            },
        },
        {
            dataField: "issueDate",
            text: intl.formatMessage({ id: "Date" }).toUpperCase(),
            formatter: (cellContent, row) => (
                <>
                    <div className="">
                        {`${moment(row.issueDate).format(Constants.displayDateFormat)}`}
                    </div>
                    <div className="small-text">
                        {moment(row.issueDate).format(Constants.displayTimeFormat)}
                    </div>
                </>
            ),
        },
        {
            dataField: "branchName",
            text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            formatter: (cellContent, row) => (
                <div className="text-center">{`${row.branchName}`}</div>
            ),
        },
        {
            dataField: "customerName",
            text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            formatter: (cellContent, row) => (
                blnPermissionManageCustomer
                    ?
                    <div className="text-center">
                        <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
                        </Link>
                    </div>
                    :
                    <div className="text-center">{row.customerName}</div>
            ),
        },
        {
            dataField: "totalAmount",
            text: intl.formatMessage({ id: "Total" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            formatter: (cellContent, row) => (
                <div className="text-center">{returnPriceWithCurrency(row.totalAmount)}</div>
            ),
        },
        {
            dataField: "subBrandName",
            text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            hidden: returnSubBrandList().length === 0,
            formatter: (cellContent, row) => (
                <div className="text-center">
                    {newSpaceBeforeCapitalLetter(row.subBrandName)}
                </div>
            )
        },
        {
            dataField: "status",
            text: intl.formatMessage({ id: "Status" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            formatter: (cellContent, row) => (
                <GeneralStatus
                    status={row.status}
                    statusColorFunction={salesOrderStatusColor} />
            )
        },
        {
            dataField: "action",
            isDummyField: true,
            text: intl.formatMessage({ id: "Action" }).toUpperCase(),
            headerStyle: { textAlign: 'center' },
            formatter: (cellContent, row) => (
                <>
                    <div className="d-flex gap-3 flex-justfity-content-center">
                        <TableActionButton
                            to={`/${RoutesList.creditRedemptionNote}/view/${row.id}`}
                            type="view" />
                    </div>
                </>
            ),
        },
    ];

    return (
        <div>
            <ListViewLayout
                metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CreditRedemptionNote" }) })}
                title={intl.formatMessage({ id: "CreditRedemptionNote" })}>
                {
                    !initialCallAPI
                    &&
                    <>
                        <Row>
                            {
                                subBrandListFinal.length > 0
                                &&
                                <DoubleColumnRowParent>
                                    <GeneralSelectDropdown
                                        title={intl.formatMessage({ id: "SubBrand" })}
                                        name="subBrandName"
                                        options={subBrandListFinal}
                                        initialLabel={selectedSubBrandName}
                                        initialValue={selectedSubBrandId}
                                        labelField="name"
                                        valueField="id"
                                        disabled={localLoading || loading}
                                        setFieldLabel={setSelectedSubBrandName}
                                        setFieldValue={setSelectedSubBrandId}
                                        onChange={(value) => {
                                            setCurrentPage(1);
                                            setErrorMessage("");
                                            setSuccessMessage("");
                                        }}
                                        validationRequired={true}
                                    />
                                </DoubleColumnRowParent>
                            }
                            <DoubleColumnRowParent>
                                <GeneralSelectDropdown
                                    title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "CreditRedemptionNote" }) })}
                                    name="status"
                                    options={creditRNList}
                                    blnValueWithNewSpace={true}
                                    labelField="displayValue"
                                    valueField="key"
                                    initialLabel={selectedCreditRedemptionNoteStatus}
                                    initialValue={selectedCreditRedemptionNoteStatusId}
                                    setFieldLabel={setSelectedCreditRedemptionNoteStatus}
                                    setFieldValue={setSelectedCreditRedemptionNoteStatusId}
                                    disabled={localLoading || loading}
                                    validationRequired={true}
                                    onChange={() => {
                                        setCurrentPage(1);
                                        setErrorMessage("");
                                        setSuccessMessage("");
                                    }}
                                />
                            </DoubleColumnRowParent>
                            <DoubleColumnRowParent>
                                <GeneralInput
                                    title={intl.formatMessage({ id: "DocumentNo" })}
                                    name="documentNo"
                                    type="text"
                                    disabled={localLoading || loading}
                                    validation={validation}
                                    placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                                    onBlurFunction={() => {
                                        onClickSearch()
                                    }}
                                />
                            </DoubleColumnRowParent>
                        </Row>
                        <Row>
                            <DoubleColumnRowParent>
                                <GeneralDateRange
                                    title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "SubmissionDate" }) })}
                                    name={"DateRange"}
                                    disabled={localLoading || loading}
                                    validationRequired={false}
                                    onChangeFunction={onSelectDateRange}
                                    initialValue={selectedDateRange} />
                            </DoubleColumnRowParent>
                            <Col xl={'5'} lg={"8"} md={"8"} xs={'12'}>
                                <div className={`flex-direction-row ${Constants.innerWidthCompare > windowSize.innerWidth ? "mb-3" : ""}`}>
                                    {
                                        displayCustomerDropdown &&
                                        <GeneralSelectDropdown
                                            title={intl.formatMessage({ id: "Customer" })}
                                            name="customer"
                                            className="flex-1 margin-right-12"
                                            placeholder={intl.formatMessage({ id: "CustomerPrefillMessage" })}
                                            options={customerDropdownList}
                                            labelField="preferredName"
                                            valueField="id"
                                            initialLabel={selectedCustomer}
                                            initialValue={selectedCustomerId}
                                            setFieldLabel={setSelectedCustomer}
                                            setFieldValue={setSelectedCustomerId}
                                            disabled={localLoading || loading}
                                            onChange={() => {
                                                clearCustomerDropdownList();
                                                setCurrentPage(1);
                                                setErrorMessage("");
                                                setSuccessMessage("");
                                                setBlnAdvancedSearch(false);
                                            }}
                                            blnSupportCustomSearch={true}
                                            onChangeCustomSearch={(value) => {
                                                fetchCustomerDropdown(value);
                                            }}
                                            onMenuOpenFunction={() => {
                                                clearCustomerDropdownList();
                                                setLoadingCustomer(false);
                                            }}
                                            loadingCustomSearch={loadingCustomer}
                                            setLoadingCustomSearch={setLoadingCustomer}
                                        />
                                    }
                                    <>
                                        <MyButton
                                            type="button"
                                            class={`btn btn-primary margin-top-28`}
                                            content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                                            loading={localLoading || loading}
                                            disable={localLoading || loading}
                                            onClick={() => { setCustomerSearchModal(true) }}
                                        />
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
                {
                    localLoading
                        ?
                        <Loading />
                        :
                        <PaginationTable
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            totalItem={totalItem}
                            tableColumns={CreditRedemptionNoteColumns}
                            data={crnList}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CreditRedemptionNote" }) })}
                            objSorted={defaultSorted}
                            status={selectedCreditRedemptionNoteStatusId}
                            statusName={selectedCreditRedemptionNoteStatus}
                            branchId={blnAdvancedSearch ? "" : getBranchId()}
                            customerId={selectedCustomerId}
                            customerName={selectedCustomer}
                            documentNo={validation.values.documentNo}
                            subBrandId={selectedSubBrandId}
                            subBrandName={selectedSubBrandName}
                            dateRange={selectedDateRange}
                            requestAPI={getCRN} />
                }
            </ListViewLayout>
            {
                customerSearchModal
                &&
                <CustomerSearchModal
                    blnShow={customerSearchModal}
                    setModal={setCustomerSearchModal}
                    blnHideOtherBranch={false}
                    blnHideAccessGranted={true}
                    title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
                    onSelectFunction={onClickSelect} />
            }
        </div>
    );
}

export default observer(CreditRedemptionNote)