import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Container, Row, Col, Button, UncontrolledTooltip, Label } from "reactstrap";
import PaginationTable from "../../app/components/table/PaginationTable";
import SortCaret from "../../app/components/table/SortCaret";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { ProductRedemptionNoteListObject } from "../../app/models/productRedemptionNote";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import moment from "moment";
import { checkPermission, newSpaceBeforeCapitalLetter, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, comparePathForPagination, comparePathForSetParams, productRedemptionStatusColor, returnAdvancedSearchText } from "../../app/common/function/function";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import Loading from "../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";

const ProductRedemptionNote = () => {
  const intl = useIntl();
  //Use Store
  const { productRedemptionNoteStore, staticReferenceStore, branchStore, commonStore, customerStore } = useStore();
  const { loading, setLoading, errorMessage, totalItem, successMessage, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { productRedemptionNoteList, productRedemptionNotePaginationParams, getProductRedemptionNote, setProductRedemptionNotePaginationParams } = productRedemptionNoteStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { productRedemptionStatus, getStaticReferenceWithType } = staticReferenceStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<ProductRedemptionNoteListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: "" }];
  const pageSize = Constants.defaultPageSize;
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedProductRedemptionStatus, setSelectedProductRedemptionStatus] = useState<string>(AllStatus);
  const [selectedProductRedemptionStatusId, setSelectedProductRedemptionStatusId] = useState<number | string | undefined>(" ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [productRedemptionStatusList, setProductRedemptionStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const blnPermissionUpdateProductRedemptionNote = checkPermission([PermissionConstants.UpdateProductRedemptionNote]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [triggerSearch, setTriggerSearch] = useState(0);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      productRedemptionNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let productRedemptionNoteRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedCustomerId ? "" : getBranchId(),
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    productRedemptionStatus: selectedProductRedemptionStatusId,
    productRedemptionStatusName: selectedProductRedemptionStatus,
    productRedemptionNo: validation.values.productRedemptionNo
  }
  
  useEffect(() => {
    async function fetchProductRedemptionNoteListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.productRedemptionNote)
      if (blnBackNavigation) {
        if (productRedemptionNotePaginationParams) {
          setCurrentPage(productRedemptionNotePaginationParams.pageNumber)
          setSelectedProductRedemptionStatus(productRedemptionNotePaginationParams.productRedemptionStatusName || "");
          setSelectedProductRedemptionStatusId(productRedemptionNotePaginationParams.productRedemptionStatus);
          setSelectedCustomerId(productRedemptionNotePaginationParams.customerId || "");
          setSelectedCustomer(productRedemptionNotePaginationParams.customerName || "");

          validation.setValues((values)=> ({
            ...values,
            productRedemptionNo: productRedemptionNotePaginationParams.productRedemptionNo || "",
          }))
          productRedemptionNoteRequestBody = productRedemptionNotePaginationParams;
        }
      }
      else {
        setProductRedemptionNotePaginationParams(undefined);
      }
      let aryAPI: any = [
        fetchProductRedemptionNoteAPI(false),
        getStaticReferenceWithType(Constants.productRedemptionStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setProductRedemptionStatusList(resultAPI[1]);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageProductRedemptionNote], true)) {
      return;
    }

    fetchProductRedemptionNoteListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.productRedemptionNote)
        if (!blnSetParams) {
          setProductRedemptionNotePaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (
      (selectedProductRedemptionStatusId || selectedProductRedemptionStatusId === 0) &&
      !initialCallAPI
    ) {
      fetchProductRedemptionNoteAPI(true);
    }
  }, [selectedProductRedemptionStatusId, selectedCustomerId, triggerSearch]);

  async function fetchProductRedemptionNoteAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getProductRedemptionNote({
      ...productRedemptionNoteRequestBody,
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setCurrentPage(1);
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);
    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const ProductRedemptionNoteColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "productRedemptionNo",
      text: `${intl.formatMessage({ id: "ProductRedemptionNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
    },
    {
      dataField: "businessEntityName",
      text: `${intl.formatMessage({ id: "BusinessEntity" }).toUpperCase()}`,
    },
    {
      dataField: "branchName",
      text: `${intl.formatMessage({ id: "Branch" }).toUpperCase()}`,
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerNo}</div>
          }
        </>
      )
    },
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "PerformedDateTime" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performedDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performedDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "note",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.note || Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={productRedemptionStatusColor}/>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.productRedemptionNote}/view/${row.id}`}
              type="view" />
            {
              row.status == Constants.pending &&
              blnPermissionUpdateProductRedemptionNote
              &&
              <TableActionButton
                to={`/${RoutesList.productRedemptionNote}/edit/${row.id}`}
                type="edit" />
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNote" }) })}
        title={intl.formatMessage({ id: "ProductRedemptionNote" })}
        breadCrumbList={breadCrumbList}
        addButton={
          <div/>
        }>
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ProductRedemption" }) })}
                name="productRedemptionStatus"
                options={productRedemptionStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedProductRedemptionStatus}
                initialValue={selectedProductRedemptionStatusId}
                setFieldLabel={setSelectedProductRedemptionStatus}
                setFieldValue={setSelectedProductRedemptionStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "ProductRedemptionNo" })}
                name="productRedemptionNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterProductRedemptionNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
            {
              !initialCallAPI
              &&
              <DoubleColumnRowParent>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                      setBlnAdvancedSearch(false);
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </DoubleColumnRowParent>
            }
            {
              !initialCallAPI
              &&
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-12"}`}
                  content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </DoubleColumnRowParent>
            }
          </Row>
        }
        {localLoading ? (
          <Loading />
        ) : (
          <>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={ProductRedemptionNoteColumns}
              data={productRedemptionNoteList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNote" }) })}
              objSorted={defaultSorted}
              productRedemptionStatus={selectedProductRedemptionStatusId}
              productRedemptionStatusName={selectedProductRedemptionStatus}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              branchId={selectedCustomerId ? "" : getBranchId()}
              productRedemptionNo={validation.values.productRedemptionNo}
              requestAPI={getProductRedemptionNote}
            />
          </>
        )}
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
};

export default observer(ProductRedemptionNote);
