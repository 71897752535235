import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../../";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, newSpaceBeforeCapitalLetter, getBranchId, customerSubBranchRequestStatusColor, comparePathForPagination, comparePathForSetParams, returnSwitchTabButtonUI } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { Link } from 'react-router-dom';
import GeneralInput from "../../app/components/form/GeneralInput";
import { PaginationRequestBody } from "../../app/models/pagination";
import TableActionButton from "../../app/components/table/TableActionButton";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { CustomerSubBranchRequestListObject } from "../../app/models/customerSubBranchRequest";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const CustomerSubBranchRequest = () => {
  const intl = useIntl();
  //Use Store
  const { customerSubBranchRequestStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { customerSubBranchRequestList, customerSubBranchRequestPaginationParams, deleteCustomerSubBranchRequest, getCustomerSubBranchRequest, setCustomerSubBranchRequestPaginationParams } = customerSubBranchRequestStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<CustomerSubBranchRequestListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const [selectedRequestBranchId, setSelectedRequestBranchId] = useState("");
  const [selectedSubBranchId, setSelectedSubBranchId] = useState(getBranchId());
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedSubBranchRequestType, setSelectedSubBranchRequestType] = useState<string>(AllType);
  const [selectedSubBranchRequestTypeId, setSelectedSubBranchRequestTypeId] = useState<number | string | undefined>(" ");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedCustomerSubBranchRequestStatus, setSelectedCustomerSubBranchRequestStatus] = useState<string>(AllStatus);
  const [selectedCustomerSubBranchRequestStatusId, setSelectedCustomerSubBranchRequestStatusId] = useState<number | string | undefined>(" ");
  const [subBranchRequestTypeList, setSubBranchRequestTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [customerSubBranchRequestStatusList, setCustomerSubBranchRequestStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [blnOwnBranch, setBlnOwnBranch] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [triggerSearch, setTriggerSearch] = useState(0);

  const blnPermissionUpdateCustomerSubBranchRequest = checkPermission([PermissionConstants.UpdateCustomerSubBranchRequest]);
  const blnPermissionDeleteCustomerSubBranchRequest = checkPermission([PermissionConstants.DeleteCustomerSubBranchRequest]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Requests" }), urlPath: "" }];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      customerName: "",
      customerNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerSubBranchRequestRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    requestType: selectedSubBranchRequestTypeId,
    requestTypeName: selectedSubBranchRequestType,
    status: selectedCustomerSubBranchRequestStatusId,
    statusName: selectedCustomerSubBranchRequestStatus,
    requestBranchId: selectedRequestBranchId,
    subBranchId: selectedSubBranchId,
    customerName: validation.values.customerName,
    customerNo: validation.values.customerNo
  }
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCustomerSubBranchRequestListAPI() {
      setLoading(true);
      setLocalLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerSubBranchRequest)
      if (blnBackNavigation) {
        if (customerSubBranchRequestPaginationParams) {
          setCurrentPage(customerSubBranchRequestPaginationParams.pageNumber);
          setSelectedSubBranchRequestTypeId(customerSubBranchRequestPaginationParams.requestType);
          setSelectedSubBranchRequestType(customerSubBranchRequestPaginationParams.requestTypeName || AllType);
          setSelectedCustomerSubBranchRequestStatusId(customerSubBranchRequestPaginationParams.status);
          setSelectedCustomerSubBranchRequestStatus(customerSubBranchRequestPaginationParams.statusName || AllStatus);
          setSelectedRequestBranchId(customerSubBranchRequestPaginationParams.requestBranchId || "")
          setSelectedSubBranchId(customerSubBranchRequestPaginationParams.subBranchId || "")
          validation.setValues((values)=> ({
            ...values,
            customerName: customerSubBranchRequestPaginationParams.customerName || "",
            customerNo: customerSubBranchRequestPaginationParams.customerNo || "",
          }))
          customerSubBranchRequestRequestBody = customerSubBranchRequestPaginationParams;
          if(customerSubBranchRequestPaginationParams.requestBranchId === getBranchId()){
            setBlnOwnBranch(true)
          }
        }
      }
      else {
        setCustomerSubBranchRequestPaginationParams(undefined);
      }
      let aryAPI: any = [
        getCustomerSubBranchRequest(customerSubBranchRequestRequestBody),
        getStaticReferenceWithType(Constants.subBranchRequestType, AllType),
        getStaticReferenceWithType(Constants.customerSubBranchRequestStatus, AllStatus)
      ];
      let resultAPI = await Promise.all(aryAPI);

      setSubBranchRequestTypeList(resultAPI[1]);
      setCustomerSubBranchRequestStatusList(resultAPI[2]);

      setLoading(false);
      setLocalLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerSubBranchRequest], true)) {
      return;
    }

    fetchCustomerSubBranchRequestListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerSubBranchRequest)
        if (!blnSetParams) {
          setCustomerSubBranchRequestPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(()=> {
    if ((selectedSubBranchRequestTypeId || selectedSubBranchRequestTypeId === 0 ) && (selectedCustomerSubBranchRequestStatusId || selectedCustomerSubBranchRequestStatusId === 0 ) && !initialCallAPI) {
      fetchCustomerSubBranchRequest(true);
    }
  }, [selectedSubBranchRequestTypeId, selectedCustomerSubBranchRequestStatusId, selectedRequestBranchId, selectedSubBranchId, triggerSearch])

  async function fetchCustomerSubBranchRequest(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerSubBranchRequest(customerSubBranchRequestRequestBody)
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const CustomerSubBranchRequestColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerSubBranchRequestNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase()
    },
    {
      dataField: "requestBranchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            row.customerName || Constants.emptyData
          }
        </div>
      ),
    },
    {
      dataField: "fromSubBranchName",
      text: intl.formatMessage({ id: "OriginalSubBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.fromSubBranchName ? row.fromSubBranchName : Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "toSubBranchName",
      text: intl.formatMessage({ id: "NewSubBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.toSubBranchName ? row.toSubBranchName : Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "subBranchConsultantName",
      text: intl.formatMessage({ id: "SubBranchConsultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.subBranchConsultantName ? row.subBranchConsultantName : Constants.emptyData}
        </>
      ),
    },
    {
      dataField: "requestType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.requestType)}
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={customerSubBranchRequestStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.customerSubBranchRequest}/view/${row.id}`}
              type={`view`} />
            {
              (blnPermissionUpdateCustomerSubBranchRequest)
              &&
              row.status == Constants.pending
              &&
              row.requestBranchId == getBranchId()
              &&
              <TableActionButton
                to={`/${RoutesList.customerSubBranchRequest}/edit/${row.id}`}
                type={`edit`} />
            }
            {
              blnPermissionDeleteCustomerSubBranchRequest
              &&
              row.status === Constants.pending
              &&
              row.requestBranchId === getBranchId()
              &&
              <TableActionButton
                type="delete"
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    }
  ];

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickDelete = async (row: CustomerSubBranchRequestListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickOtherBranch = () => {
    setCurrentPage(1);
    setSelectedSubBranchId(getBranchId())
    setSelectedRequestBranchId("")
    setBlnOwnBranch(false);
  }

  const onClickOwnBranch = () => {
    setCurrentPage(1);
    setSelectedSubBranchId("")
    setSelectedRequestBranchId(getBranchId())
    setBlnOwnBranch(true);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerSubBranchRequest" }) })}
        title={intl.formatMessage({ id: "CustomerSubBranchRequest" })}
        breadCrumbList={breadCrumbList}>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Type" })}
                  name="requestType"
                  options={subBranchRequestTypeList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedSubBranchRequestType}
                  initialValue={selectedSubBranchRequestTypeId}
                  setFieldLabel={setSelectedSubBranchRequestType}
                  setFieldValue={setSelectedSubBranchRequestTypeId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Status" })}
                  name="status"
                  options={customerSubBranchRequestStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedCustomerSubBranchRequestStatus}
                  initialValue={selectedCustomerSubBranchRequestStatusId}
                  setFieldLabel={setSelectedCustomerSubBranchRequestStatus}
                  setFieldValue={setSelectedCustomerSubBranchRequestStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <Col xl={'4'} md={'6'} xs={'12'} className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-26" : "margin-bottom-12"}`}>
                <div className="btn-group" role="group">
                  {returnSwitchTabButtonUI("blnRequestFromOwn", "blnRequestFromOwnFalse", !blnOwnBranch, localLoading || loading, intl.formatMessage({ id: "RequestByOtherBranch" }), onClickOtherBranch, Constants.searchBarWidth)}
                  {returnSwitchTabButtonUI("blnRequestFromOwn", "blnRequestFromOwnTrue", blnOwnBranch, localLoading || loading, intl.formatMessage({ id: "RequestByOwnBranch" }), onClickOwnBranch, Constants.searchBarWidth)}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerName" })}
                  name="customerName"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerName" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </Col>
              <Col xl={'4'} md={'6'} xs={'12'}>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerNo" })}
                  name="customerNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerSubBranchRequestColumns}
              keyField={"id"}
              data={customerSubBranchRequestList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerSubBranchRequest" }) })}
              objSorted={defaultSorted}
              requestAPI={getCustomerSubBranchRequest}
              requestType={selectedSubBranchRequestTypeId}
              requestTypeName={selectedSubBranchRequestType}
              status={selectedCustomerSubBranchRequestStatusId}
              statusName={selectedCustomerSubBranchRequestStatus}
              requestBranchId={selectedRequestBranchId}
              subBranchId={selectedSubBranchId}
              customerName={validation.values.customerName}
              customerNo={validation.values.customerNo}
              hideSearchBar={true} />
        }
        <DeleteModal
          displayName={`${selectedRow?.customerName}`}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCustomerSubBranchRequest(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (customerSubBranchRequestList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCustomerSubBranchRequest({  ...customerSubBranchRequestRequestBody, pageNumber: currentPageTemp })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(CustomerSubBranchRequest);
