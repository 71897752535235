import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import classNames from "classnames";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Nav, NavItem, NavLink } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../app/models/common";
// import { StateObject } from "../../app/models/lead";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { EmployeeListObject } from "../../app/models/employee";

const LeadDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { leadStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { leadDuplicateDetail, setLeadDuplicateDetail, getLeadDuplicateWithId } = leadStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "LeadDuplicate" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "LeadDuplicate" }), urlPath: RoutesList.leadDuplicate }];
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: leadDuplicateDetail || {
      name: "",
      mobileNo: "",
      email: "",
      mediaSource: "",
      stateName: "",
      branchName: "",
      serviceTreatmentName: "",
      promoRemark: "",
      voucherReferenceNo: "",
      marcomConsultantName: "",
      raceName: "",
      gender: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {},
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchLeadDetailAPI() {
      setLoading(true);
      await getLeadDuplicateWithId(id);
      setLoading(false);
    }

    if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageLeadDuplicate], true)) {
        return;
      }
    }

    fetchLeadDetailAPI();

    return (() => {
      if (id && !addAction) {
        setLeadDuplicateDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "LeadDuplicate" }) })}
                  h4Title />
                <Row className="margin-top-20">
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Name" })}
                      name="name"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}/>
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"raceId"}
                      title={intl.formatMessage({ id: "Race" })}
                      specifyReturnFieldName={[
                        {
                          field: "raceId",
                          value: "id",
                        },
                        {
                          field: "raceName",
                          value: "name",
                        }
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput ||viewAction}
                      initialLabel={validation.values.raceName}
                      initialValue={validation.values.raceName}
                      validationRequired={false}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"sourceId"}
                      title={intl.formatMessage({ id: "MediaSource" })}
                      specifyReturnFieldName={[{ "field": "mediaSource", "value": "name" }, { "field": "sourceId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialLabel={validation.values.mediaSource}
                      initialValue={validation.values.mediaSource}
                      validation={validation} />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"marcomConsultantId"}
                      title={intl.formatMessage({ id: "AppointedBy" })}
                      specifyReturnFieldName={[{ "field": "marcomConsultantName", "value": "preferredName" }, { "field": "marcomConsultantId", "value": "id" }]}
                      labelField={"preferredName"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      initialLabel={validation.values.marcomConsultantName}
                      initialValue={validation.values.marcomConsultantName}
                      validation={validation} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "MobileNo" })}
                      name="mobileNo"
                      type="number"
                      blnNumberOnly={true}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Email" })}
                      name="email"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={false}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"gender"}
                      title={intl.formatMessage({ id: "Gender" })}
                      specifyReturnFieldName={[{ "field": "gender", "value": "displayValue" }]}
                      labelField={"displayValue"}
                      valueField={"key"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={validation.values.gender}
                      initialValue={validation.values.gender}
                      validation={validation}/>
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DuplicateCount" })}
                      name="count"
                      type="number"
                      blnNumberOnly={true}
                      disabled={disabledFieldInput || viewAction}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Other" }) })}
                  className="mt-4"
                  h4Title />
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"branchId"}
                      title={intl.formatMessage({ id: "PreferredBranch" })}
                      specifyReturnFieldName={[{ "field": "branchName", "value": "name" }, { "field": "branchId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={validation.values.branchName}
                      initialValue={validation.values.branchName}
                      validation={validation}/>
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"consultantId"}
                      title={intl.formatMessage({ id: "PreferredConsultant" })}
                      specifyReturnFieldName={[{ "field": "consultantName", "value": "preferredName" }, { "field": "consultantId", "value": "id" }]}
                      labelField={"preferredName"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={validation.values.marcomConsultantName}
                      initialValue={validation.values.marcomConsultantName}
                      validation={validation} />
                  </Col>
                </Row>
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"serviceTreatmentId"}
                      title={intl.formatMessage({ id: "ServiceTreatment" })}
                      specifyReturnFieldName={[{ "field": "serviceTreatmentName", "value": "name" }, { "field": "serviceTreatmentId", "value": "id" }]}
                      labelField={"name"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={validation.values.serviceTreatmentName}
                      initialValue={validation.values.serviceTreatmentName}
                      validation={validation} />
                  </Col>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "VoucherReferenceNo" })}
                      name="voucherReferenceNo"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </Col>
                </Row>
                <GeneralTextArea
                  title={intl.formatMessage({ id: "PromotionRemark" })}
                  name="promoRemark"
                  row={4}
                  disabled={disabledFieldInput || viewAction}
                  validation={validation} />
              </Col>
              <Col xl={6}>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Address" }) })}
                  h4Title />
                <Row>
                  <Col xs={"12"} md={"6"} xl={"6"}>
                    <DropDownWithTitle
                      name={"stateId"}
                      title={intl.formatMessage({ id: "State" })}
                      specifyReturnFieldName={[
                        {
                          field: "stateId",
                          value: "id",
                        },
                        {
                          field: "stateName",
                          value: "name",
                        },
                      ]}
                      labelField={"name"}
                      valueField={"id"}
                      options={[]}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={!addAction ? validation.values.stateName : undefined}
                      initialValue={!addAction ? validation.values.stateName : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(LeadDetail);
