import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, arraySpliceInAllValue, firstDayOfMonthToCurrentDay, appointmentStatusColor, returnSubBrandList } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { Link } from 'react-router-dom';
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { SubBrandObject } from "../../../app/models/brand";

const LeadAppointments = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, branchStore, mediaSourceStore, staticReferenceStore, userStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { leadAppointmentsList, getLeadAppointmentsList, setLeadAppointmentsList, exportLeadAppointments } = reportMarcomStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { getUserDropdown } = userStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const [userListFinal, setUserListFinal] = useState<ObjectForDropdown[]>([]);
  const AllUser = `${intl.formatMessage({ id: "AllUser" })}`;
  const [selectedMadeBy, setSelectedMadeBy] = useState<string>(AllUser);
  const [selectedMadeById, setSelectedMadeById] = useState<string>(" ");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedAppointmentStatus, setSelectedAppointmentStatus] = useState<number | string>(AllStatus);
  const [selectedAppointmentStatusId, setSelectedAppointmentStatusId] = useState<number | string | undefined>(" ");
  const [appointmentStatusList, setAppointmentStatusList] = useState<StaticReferenceDataObject[]>([])

  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");

  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];

  const blnPermissionManageAppointment = checkPermission([PermissionConstants.ManageAppointment]);
  const blnPermissionManageLead = checkPermission([PermissionConstants.ManageLead]);
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    if (returnSubBrandList().length > 0) {
      setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
    }
    
    async function fetchCollectionListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
        getStaticReferenceWithType(Constants.appointmentStatusType, AllStatus),
        getUserDropdown()
      ];
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"));
      setAppointmentStatusList(resultAPI[2]);
      setUserListFinal(arraySpliceInAllValue(resultAPI[3], AllUser, false, 'preferredName', 'id'));

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchCollectionListAPI();

    return (() => {
      setLeadAppointmentsList([]);
    })
  }, []);

  const fetchLeadAppointmentsListWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportLeadAppointments({
        pageNumber: 1,
        pageSize: pageSize,
        branchId: selectedBranchId,
        sourceId: selectedMediaSourceId,
        status: selectedAppointmentStatusId,
        dateRange: selectedDateRange,
        madeById: selectedMadeById,
        subBrandId: selectedSubBrandId
      });
    }
    else {
      setCurrentPage(1);
      await getLeadAppointmentsList({
        pageNumber: 1,
        pageSize: pageSize,
        branchId: selectedBranchId,
        sourceId: selectedMediaSourceId,
        appointmentStatus: selectedAppointmentStatusId,
        dateRange: selectedDateRange,
        madeById: selectedMadeById,
        subBrandId: selectedSubBrandId
      });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const LeadAppointmentsColumns = [
    {
      dataField: "appointmentId", //field name from array to display
      text: "appointmentId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI,
      formatter: (cellContent, row) => <>{row.appointmentId}</>, //Custom UI to display
    },
    {
      dataField: "leadNo",
      text: intl.formatMessage({ id: "LeadNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead
              ?
              <Link to={row.leadId ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.leadNo || Constants.emptyData}</Label>
              </Link>
              :
              row.leadNo
          }
        </>
      ),
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageLead
              ?
              <Link to={row.leadId ? `/${RoutesList.lead}/view/${row.leadId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.name || Constants.emptyData}</Label>
              </Link>
              :
              row.name
          }
        </>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSource",
      text: intl.formatMessage({ id: "Source" }).toUpperCase(),
    },
    {
      dataField: "raceName",
      text: intl.formatMessage({ id: "Race" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.raceName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "marcomConsultant",
      text: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.marcomConsultant || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "AppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageAppointment
              ?
              <Link to={row.appointmentId ? `/${RoutesList.appointment}/view/${row.appointmentId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">
                  <>
                    <div className="">
                      {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
                    </div>
                    <div className="small-text">
                      {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)}
                    </div>
                  </>
                </Label>
              </Link>
              :
              <>
                <div className="">
                  {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
                </div>
                <div className="small-text">
                  {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)}
                </div>
              </>
          }
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "serviceTreatments",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.serviceTreatments || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor} />
      ),
    },

    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.madeBy || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.remark && row.remark !== " " ? row.remark : Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "isLeadTurnedUp",
      text: intl.formatMessage({ id: "TurnedUp" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isLeadTurnedUp ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeadAppointments" }) })}
        title={intl.formatMessage({ id: "LeadAppointments" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchLeadAppointmentsListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Lead-Appointments.aspx`}>
        <Row>
          {
            subBrandListFinal.length > 0
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "MediaSource" })}
              name="mediaSource"
              options={mediaSourceListFinal}
              labelField="name"
              valueField="id"
              initialLabel={selectedMediaSourceName}
              initialValue={selectedMediaSourceId}
              setFieldLabel={setSelectedMediaSourceName}
              setFieldValue={setSelectedMediaSourceId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "CreatedDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              initialValue={selectedDateRange}
              validationRequired={true} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "MadeBy" })}
              name="madeBy"
              options={userListFinal}
              labelField="preferredName"
              valueField="id"
              initialLabel={selectedMadeBy}
              initialValue={selectedMadeById}
              setFieldLabel={setSelectedMadeBy}
              setFieldValue={setSelectedMadeById}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Appointment" }) })}
              name="appointmentStatus"
              options={appointmentStatusList}
              blnValueWithNewSpace={true}
              labelField="displayValue"
              valueField="key"
              initialLabel={selectedAppointmentStatus}
              initialValue={selectedAppointmentStatusId}
              setFieldLabel={setSelectedAppointmentStatus}
              setFieldValue={setSelectedAppointmentStatusId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading }
              disable={localLoading || loading }
              onClick={() => {
                fetchLeadAppointmentsListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={LeadAppointmentsColumns}
              keyField={"appointmentId"}
              data={leadAppointmentsList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "LeadAppointments" }) })}
              objSorted={defaultSorted}
              requestAPI={getLeadAppointmentsList}
              branchId={selectedBranchId}
              sourceId={selectedMediaSourceId}
              appointmentStatus={selectedAppointmentStatusId}
              madeById={selectedMadeById}
              dateRange={selectedDateRange}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} 
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadAppointments);
