import { useEffect, useState } from "react";
import { Col, Input, Label, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { arraySpliceInAllValue, getBranchId, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../../app/common/function/function";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import moment from "moment";
import { observer } from 'mobx-react-lite';
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import { isTablet } from "react-device-detect";
interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  onSelectFunction: Function;
  blnHideOtherBranch?: boolean;
  blnHideAccessGranted?: boolean;
  branchId?: string;
  branchName?: string;
  actionTitle?: string;
}

interface SearchValueObject {
  searchValue: string;
}

export default observer(function CustomerSearchModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { customerStore, staticReferenceStore, commonStore } = useStore();
  const { loading, totalItem, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerAdvanceSearchList, customerRequestAccessList, subBranchCustomersList, customerAdvanceSearchTotalItems, getCustomerAdvanceSearch, getCustomerRequestAccessList, setCustomerAdvanceSearchList, setCustomerAdvanceSearchTotalItems, setCustomerRequestAccessList, setSubBranchCustomersList } = customerStore;
  const { customerSearchType, getStaticReferenceWithType } = staticReferenceStore;

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);

  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedCustomerSearchType, setSelectedCustomerSearchType] = useState<number | string>(All);
  const [selectedCustomerSearchTypeValue, setSelectedCustomerSearchTypeValue] = useState<number | string | undefined>(" ");
  const [refreshCustomerSearchType, setRefreshCustomerSearchType] = useState(false);
  const [customerSearchTypeFinal, setCustomerSearchTypeFinal] = useState<StaticReferenceDataObject[]>([]);
  const [customerSearchTypeDropdownList, setCustomerSearchTypeDropdownList] = useState<StaticReferenceDataObject[]>([]);
  const [checked, setCheckBoxChecked] = useState<any>(Constants.ownBranchValue);
  const [searchValueObj, setSearchValueObj] = useState<SearchValueObject>({searchValue: ""})
  const [loadingSearchType, setLoadingSearchType] = useState(false);
  const [tabAccessGranted, setTabAccessGranted] = useState(true);
  const blnOtherBranch = checked === Constants.otherBranchValue;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const CustomerSearchColumns = [
    {
      dataField: "customerNo",
      text: `${intl.formatMessage({ id: "CustomerNo" }).toUpperCase()}.`,
      sort: true,
    },
    {
      dataField: "preferredName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
    },
    {
      dataField: "identityType",
      text: intl.formatMessage({ id: "IdentityType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.identityType)}
        </div>
      ),
    },
    {
      dataField: "identityNo",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: blnOtherBranch && tabAccessGranted ? "to" : "",
      text: blnOtherBranch && tabAccessGranted ? intl.formatMessage({ id: "ValidTill" }).toUpperCase() : "",
      formatter: (cellContent, row) => (
        <div>
          {blnOtherBranch && tabAccessGranted ? moment(row.to).format(Constants.displayDateAndTimeFormat) : ""}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={props.actionTitle || intl.formatMessage({ id: "Select" })} 
              onClick={() => props.onSelectFunction({...row, blnAccessGranted: blnOtherBranch && tabAccessGranted})}/>
          </div>
        </>
      ),
    },
  ];

  useEffect(()=> {
    async function fetchCustomerSearchType (){
      let customerSearchTypeTemp : StaticReferenceDataObject[] = _.cloneDeep(customerSearchType);
      if (customerSearchType.length === 0) {
        setLoadingSearchType(true);
        let resultCustomerSearchTypeTemp = await getStaticReferenceWithType(Constants.customerSearchType)
        if (resultCustomerSearchTypeTemp) {
          customerSearchTypeTemp = _.cloneDeep(resultCustomerSearchTypeTemp);
        }
        setLoadingSearchType(false);
      }
      arraySpliceInAllValue(customerSearchTypeTemp, All);
      setCustomerSearchTypeFinal(customerSearchTypeTemp);
      setCustomerSearchTypeDropdownList(customerSearchTypeTemp)
    }

    fetchCustomerSearchType();

    return (() => {
      setCustomerAdvanceSearchList([]);
      setCustomerRequestAccessList([]);
      setSubBranchCustomersList([]);
      setCustomerAdvanceSearchTotalItems(0);
    })
  },[])

  const clearSearchValueObj = (blnMobileNo: boolean = false) => {
    setSearchValueObj({searchValue: blnMobileNo ? "6" :""})
  }

  const hideModal = () => {
    clearSearchValueObj();
    props.setModal(false);
  }
  const options = [
    { label: props.branchName || Constants.ownBranchDisplayName, value: Constants.ownBranchValue },
    { label: Constants.otherBranchDisplayName, value: Constants.otherBranchValue },
  ];

  const fetchCustomerSearchWithLoading = async () => {
    if (!blnOtherBranch) {
      if (removeAllSpace(String(selectedCustomerSearchTypeValue)) !== "" && !searchValueObj.searchValue) {
        setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: newSpaceBeforeCapitalLetter(selectedCustomerSearchType) }));
        return;
      }
    }
    setLocalLoading(true);
    if (checked === Constants.ownBranchValue) {
      setCurrentPage(1);
      await getCustomerAdvanceSearch({ pageSize: pageSize, pageNumber: 1, branchId: props.branchId || getBranchId(), searchType: selectedCustomerSearchTypeValue, searchValue: searchValueObj.searchValue || " " })
    }
    // else {
    //   await getCustomerRequestAccessList(getBranchId())
    // }
    setLocalLoading(false);
  };

  async function handleChangeRadioBox(value) {
    setRefreshCustomerSearchType(true);
    setCheckBoxChecked(value);
    if (value === Constants.ownBranchValue) {
      // setSelectedCustomerSearchType(customerSearchTypeFinal[0].displayValue);
      // setSelectedCustomerSearchTypeValue(customerSearchTypeFinal[0].key);
      // setCustomerSearchTypeDropdownList(customerSearchTypeFinal);
      setTabAccessGranted(true);
    }
    else {
      // let customerSearchTypeTemp = _.filter(_.cloneDeep(customerSearchTypeFinal), function(value) {
      //   return value.displayValue !== Constants.preferredName && value.displayValue !== All;
      // });
      // setSelectedCustomerSearchType(customerSearchTypeTemp[0].displayValue);
      // setSelectedCustomerSearchTypeValue(customerSearchTypeTemp[0].key);
      // setCustomerSearchTypeDropdownList(customerSearchTypeTemp);

      setLocalLoading(true);
      await getCustomerRequestAccessList((props.branchId && props.branchId !== " ") ? props.branchId : getBranchId())
      if (props.blnHideAccessGranted) {
        setTabAccessGranted(false);
      }
      setLocalLoading(false);
    }
    setTimeout(()=> {
      setRefreshCustomerSearchType(false);
    }, 100)
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      size='xl'
      style={{...isTablet && {width: '100%', maxWidth: '80%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="standard-layout">
          <Row className="align-items-md-center margin-top--8 margin-bottom--40">
            {
              !props.blnHideOtherBranch
              &&
              <Col xl="6">
                <Row className="mb-4">
                  <Label>{intl.formatMessage({ id: "Branch" })}</Label>
                  <div className="flex-direction-row">
                    {options.map((option, index) => (
                      <div key={`radio_${index}`}>
                        <Input
                          type="radio"
                          name="branch"
                          checked={option.value === checked}
                          className={`${index > 0 && "margin-left-16"}`}
                          onChange={() => handleChangeRadioBox(option.value)}
                          disabled={localLoading || loading}
                        />
                        &nbsp;
                        <label>{option.label}</label>
                      </div>
                    ))}
                  </div>
                </Row>
              </Col>
            }
            {
              !refreshCustomerSearchType
              &&
              <Col xl="6">
                {
                  loadingSearchType
                  ?
                  <Loading className={`${!props.blnHideOtherBranch && "flex-right"}`}/>
                  :
                  <Row className={`align-items-md-center ${!props.blnHideOtherBranch && "flex-right"} ${localLoading && "margin-right-32"}`}>
                    {
                      !blnOtherBranch
                      &&
                      <Col xl="5">
                        <GeneralSelectDropdown
                          title={intl.formatMessage({ id: "SearchType" })}
                          name="customerSearchType"
                          options={customerSearchTypeDropdownList}
                          labelField="displayValue"
                          valueField="key"
                          initialLabel={selectedCustomerSearchType}
                          initialValue={selectedCustomerSearchTypeValue}
                          setFieldLabel={setSelectedCustomerSearchType}
                          setFieldValue={setSelectedCustomerSearchTypeValue}
                          blnValueWithNewSpace={true}
                          blnReturnFullValue={true}
                          disabled={localLoading || loading}
                          validationRequired={true}
                          onChange={(value) => {
                            setErrorMessage("");
                            setSuccessMessage("");
                            clearSearchValueObj(value.label === Constants.mobileNoWithSpace ? true : false);
                          }}
                        />
                      </Col>
                    }
                    {
                      selectedCustomerSearchTypeValue !== " " 
                      &&
                      !blnOtherBranch
                      &&
                      <Col xl="5">
                        <GeneralInput
                          title={newSpaceBeforeCapitalLetter(selectedCustomerSearchType)}
                          name="searchValue"
                          type="text"
                          disabled={localLoading || loading}
                          validationRequired={false}
                          blnModal={true}
                          field={searchValueObj}
                          setFieldValue={setSearchValueObj}
                        />
                      </Col>
                    }
                    {
                      !blnOtherBranch
                      &&
                      <Col xl="2">
                        <MyButton
                          type="button"
                          class="btn btn-primary margin-top-12"
                          content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                          loading={localLoading || loading}
                          disable={localLoading || loading}
                          onClick={() => {
                            fetchCustomerSearchWithLoading()
                          }}
                        />
                      </Col>
                    }
                  </Row>
                }
              </Col>
            }
          </Row>
        </div>
        {
          blnOtherBranch
          &&
          <Col xl={'4'} md={'6'} xs={'12'} className={`mt-2 margin-left-16 margin-bottom--24`}>
            <div className="btn-group" role="group">
              {
                !props.blnHideAccessGranted
                &&
                <>
                  <input type="radio" className="btn-check" name="blnRequestAccessGranted" id="blnRequestAccessGrantedTrue" autoComplete="off" defaultChecked disabled={localLoading || loading}
                    onClick={()=> { 
                      setTabAccessGranted(true);
                    }}/>
                  <label className="btn btn-outline-primary" htmlFor="blnRequestAccessGrantedTrue">{intl.formatMessage({ id: "AccessGranted" })}</label>
                </>
              }
              <input type="radio" className="btn-check" name="blnRequestAccessGranted" id="blnRequestAccessGrantedFalse" autoComplete="off" disabled={localLoading || loading} defaultChecked={props.blnHideAccessGranted}
                onClick={()=> { 
                  setTabAccessGranted(false);
                }}/>
              <label className="btn btn-outline-primary" htmlFor="blnRequestAccessGrantedFalse">{intl.formatMessage({ id: "SharedProfile" })}</label>
            </div>
          </Col>
        }
        {
          localLoading
            ?
            <Loading className={`${(blnOtherBranch || props.blnHideOtherBranch) && "mt-4"} margin-left-16`}/>
            :
            !refreshCustomerSearchType
            &&
            <>
              {
                blnOtherBranch
                ?
                <div className="standard-layout mt-1">
                  <PaginationTableWithoutApi 
                    title={intl.formatMessage({ id: "Customer" })}
                    headerClassName={"table-light"}
                    blnShowSearchBar={true}
                    blnDisplayForMainListing={true}
                    options={tabAccessGranted ? customerRequestAccessList : subBranchCustomersList}
                    columns={CustomerSearchColumns}
                    pageSize={Constants.defaultPageSize}
                    keyField={"customerId"}/>
                </div>
                :
                <PaginationTable
                  currentPage={!blnOtherBranch ? currentPage : 1}
                  setCurrentPage={setCurrentPage}
                  pageSize={!blnOtherBranch ? pageSize : Constants.maxPageSize}
                  totalItem={!blnOtherBranch ? customerAdvanceSearchTotalItems : tabAccessGranted ? customerRequestAccessList.length : subBranchCustomersList.length}
                  tableColumns={CustomerSearchColumns}
                  data={!blnOtherBranch ? customerAdvanceSearchList : tabAccessGranted ? customerRequestAccessList : subBranchCustomersList}
                  emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Customer" }) })}
                  objSorted={defaultSorted}
                  requestAPI={getCustomerAdvanceSearch}
                  branchId={checked === Constants.ownBranchValue ? props.branchId || getBranchId() : getBranchId()}
                  searchType={selectedCustomerSearchTypeValue}
                  searchValue={searchValueObj.searchValue}
                  hideSearchBar={true} />
              }
            </>
        }
      </div>
    </Modal>
  )
});