import { PaginationRequestBody } from "../models/pagination";
import { SocsoListObject, SocsoAddObject, SocsoUpdateObject, SocsoDetailObject } from "../models/socso";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class SocsoStore {
  socsoList: SocsoListObject[] = [];
  socsoDetail: SocsoDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.socsoList = [];
    this.socsoDetail = undefined;
  }

  setSocsoDetail = (socsoDetail: SocsoDetailObject | undefined) => {
    this.socsoDetail = socsoDetail;
  }
  
  getSocso = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSocso = await agent.Socso.socsoList(PaginationRequestBody);
      runInAction(() => {
        this.socsoList = resultSocso.data;
        store.commonStore.setTotalItem(resultSocso.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.socsoList = [];
    }
  }

  getSocsoWithId = async (id: string) => {
    try{
      const resultSocsoDetail = await agent.Socso.socsoDetail(id);
      runInAction(() => {
        this.socsoDetail = resultSocsoDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.socsoDetail = undefined;
    }
  }

  addSocso = async (socsoRequestBody: SocsoAddObject) => {
    try{
      await agent.Socso.addSocso(socsoRequestBody);
      store.commonStore.setSuccessMessage(`SocsoAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateSocso = async (socsoRequestBody: SocsoUpdateObject) => {
    try{
      await agent.Socso.updateSocso(socsoRequestBody);
      store.commonStore.setSuccessMessage(`SocsoUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteSocso  = async (id: string, name: string) => {
    try {
      await agent.Socso.deleteSocso(id);
      store.commonStore.setSuccessMessage(`SocsoDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}