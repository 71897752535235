import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, checkPermission, returnTableCellUI, returnPriceWithCurrency, getMonths, getBranchUser, newSpaceBeforeCapitalLetter, arraySpliceInAllValue, returnSwitchTabButtonUI } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import _ from "lodash";
import { BusinessEntityListObject } from "../../../app/models/businessEntity";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { useFormik } from "formik";
import * as Yup from "yup";

const MonthlyFinancialReport = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, businessEntityStore, branchStore, staticReferenceStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, windowSize } = commonStore;
  const { getBusinessEntityDropDown } = businessEntityStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { monthlyFinancialReportList, getMonthlyFinancialReportList, setMonthlyFinancialReportList, exportMonthlyFinancial, exportMonthlyFinancialBranchDetail, exportMonthlyFinancialAccountDetail } = reportAccountStore; 
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { contractLiabilityTransactionType, getStaticReferenceWithType } = staticReferenceStore;
  
  //Data List
  const monthlyFinancialReportColumns = [
    {
      dataField: "businessEntityName",
      text: intl.formatMessage({ id: "BusinessEntity" }).toUpperCase(),
      style: { alignContent: 'baseline' }
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        let aryViews : any = [];
        row.branchList.forEach((valueBranchTemp, indexBranchTemp) => {
          let heightTemp = `${50*valueBranchTemp.financialAccountList.length}px`;
          aryViews.push(
            <div style={{ minWidth: "200px" }} key={`div_branch_${index}_${indexBranchTemp}`}>
              <div
                className= { blnPermissionExportAccountReport ? "pointer-clickable primary-color" : "" }
                style={ blnPermissionExportAccountReport ? {position: "absolute", marginTop: "12px", fontWeight:"500"} : {}} 
                onClick={() => blnPermissionExportAccountReport ? onClickExportMonthlyFinancialBranchDetail(valueBranchTemp.branchId) : {}}>
                {`${valueBranchTemp.branchName}`}
              </div>
              {returnTableCellUI(`branch_${index}_${indexBranchTemp}`, indexBranchTemp, row.branchList.length, "", false, heightTemp, heightTemp, heightTemp)}
            </div>
          )
        })
        return aryViews;
      }
    },
    {
      dataField: "financialAccount",
      text: intl.formatMessage({ id: "FinancialAccount" }).toUpperCase(),
      formatter: (cellContent, row, index) => {
        return returnFinancialAccountSubView(row, index, "transactionType", false);
      },
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalAmount" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <div
          className= { blnPermissionExportAccountReport ? "pointer-clickable primary-color" : "" }
          style={ blnPermissionExportAccountReport ? {fontWeight: "500"} : {}}>
            {returnFinancialAccountSubView(row, index, "totalAmount", true)}
      </div>
      ),
    }
  ]
  const AllBusinessEntity = `${intl.formatMessage({ id: "AllBusinessEntity" })}`;
  const [selectedBusinessEntityName, setSelectedBusinessEntityName] = useState(AllBusinessEntity);
  const [selectedBusinessEntityId, setSelectedBusinessEntityId] = useState(" ");
  const [businessEntityListFinal, setBusinessEntityListFinal] = useState<BusinessEntityListObject[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(" ");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [blnAll, setBlnAll] = useState(true);
  const [blnMonthlyPosting, setBlnMonthlyPosting] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      financialAccount: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  useEffect(() => {    
    setYearList(getYears());
    setMonthList(getMonths());
    
    async function fetchMonthlyFinancialReport() {
			setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getBusinessEntityDropDown(),
        getStaticReferenceWithType(Constants.contractLiabilityTransactionType)
      ];      
      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setBusinessEntityListFinal(arraySpliceInAllValue(resultAPI[1], AllBusinessEntity, true))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }
    
    fetchMonthlyFinancialReport();

    return(()=> {
      setMonthlyFinancialReportList([]);
    })
  }, [])

  useEffect(() => {
    async function clearCustomer () {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    clearCustomer();
  }, [selectedBranchId])

  const fetchMonthlyFinancialReportWithLoading = async (blnExport: boolean) => {
    setCurrentPage(1);
    setLocalLoading(true);
    if (blnExport) {
      await exportMonthlyFinancial({ businessEntityId: selectedBusinessEntityId, branchId: selectedBranchId, financialAccount: validation.values.financialAccount, year: selectedYear, month: selectedMonthValue, postCycle: blnAll ? 0 : !blnMonthlyPosting ? 1 : 2, customerId: selectedCustomerId });
    } 
    else {
      await getMonthlyFinancialReportList({ businessEntityId: selectedBusinessEntityId, branchId: selectedBranchId, financialAccount: validation.values.financialAccount, year: selectedYear, month: selectedMonthValue, postCycle: blnAll ? 0 : !blnMonthlyPosting ? 1 : 2, customerId: selectedCustomerId });      
    }
    setTimeout(()=> {
      setLocalLoading(false);
    }, 100)
	};

  const onClickExportMonthlyFinancialBranchDetail = async (id: string) => {
    setLocalLoading(true);
    await exportMonthlyFinancialBranchDetail({ branchId: id, financialAccountTypes: validation.values.financialAccount, year: selectedYear, month: selectedMonthValue, postCycle: blnAll ? 0 : !blnMonthlyPosting ? 1 : 2, customerId: selectedCustomerId});
    setLocalLoading(false);
  }

  const onClickExportMonthlyFinancialAccountDetail = async (id: string, type: number ) => {
    setLocalLoading(true);
    await exportMonthlyFinancialAccountDetail({ branchId: id, financialAccountType: type , year: selectedYear, month: selectedMonthValue, postCycle: blnAll ? 0 : !blnMonthlyPosting ? 1 : 2, customerId: selectedCustomerId});
    setLocalLoading(false);
  }

  const returnFinancialAccountSubView = (row, index, fieldName, blnReturnCurrency) => {
    let aryViews : any = [];
    row.branchList.forEach((valueBranchTemp, indexBranchTemp) => {
      let arySubViews : any = [];
      valueBranchTemp.financialAccountList.forEach((valueFinancialAccountTemp, indexFinancialAccountTemp) => {
        let indexFinancialAmountTemp = indexFinancialAccountTemp === 0 && indexBranchTemp === 0 ? 0 : indexFinancialAccountTemp || 1;
        let aryLengthTemp = valueBranchTemp.financialAccountList.length + (((row.branchList.length -1) === indexBranchTemp) ? 0 : 1);         
        arySubViews.push(
          <div style={{ minWidth: "200px" }} key={`${fieldName}_${index}_${indexBranchTemp}_${indexFinancialAccountTemp}`}
          onClick={() => blnPermissionExportAccountReport && fieldName === "totalAmount" ?  onClickExportMonthlyFinancialAccountDetail(valueBranchTemp.branchId, indexFinancialAccountTemp) : {}}>
            {returnTableCellUI(`${fieldName}_${index}_${indexBranchTemp}_${indexFinancialAccountTemp}`, indexFinancialAmountTemp, aryLengthTemp, blnReturnCurrency ? returnPriceWithCurrency(valueFinancialAccountTemp[fieldName]) : newSpaceBeforeCapitalLetter(valueFinancialAccountTemp[fieldName]))}
          </div>
        )
      })
      aryViews.push(
        <div key={`${fieldName}_${index}_${indexBranchTemp}_parent`}>
          {arySubViews}
        </div>
      )
    })
    return aryViews;
  }

  const blnViewAllFunction = () => {
    setBlnAll(true);
  }

  const blnDailyPostingFunction = () => {
    setBlnAll(false);
    setBlnMonthlyPosting(false);
  }

  const blnMonthlyPostingFunction = () => {
    setBlnAll(false);
    setBlnMonthlyPosting(true);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "CLAndCCPosition"})})}
        title={intl.formatMessage({ id: "CLAndCCPosition" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchMonthlyFinancialReportWithLoading(true)
            }}
          />
        }
        >
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "BusinessEntity" })}
              name="businessEntityName"
              options={businessEntityListFinal}
              initialLabel={selectedBusinessEntityName}
              initialValue={selectedBusinessEntityId}
              setFieldLabel={setSelectedBusinessEntityName}
              setFieldValue={setSelectedBusinessEntityId}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl={"3"}>
            {
              displayCustomerDropdown &&
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Customer" })}
                name="customer"
                placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                options={customerDropdownList}
                labelField="preferredName"
                valueField="id"
                initialLabel={selectedCustomer}
                initialValue={selectedCustomerId}
                setFieldLabel={setSelectedCustomer}
                setFieldValue={setSelectedCustomerId}
                disabled={localLoading || loading}
                onChange={() => {
                  clearCustomerDropdownList();
                }}
                blnSupportCustomSearch={true}
                onChangeCustomSearch={(value)=> {
                  fetchCustomerDropdown(value);
                }}
                onMenuOpenFunction={()=> {
                  clearCustomerDropdownList();
                  setLoadingCustomer(false);
                }}
                loadingCustomSearch={loadingCustomer}
                setLoadingCustomSearch={setLoadingCustomer}
              />
            }
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name="financialAccount"
              title={intl.formatMessage({ id: "FinancialAccount" })}
              specifyReturnFieldName={[{ "field": "financialAccount", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"key"}
              options={contractLiabilityTransactionType}
              disabled={localLoading || loading}
              blnValueWithNewSpace={true}
              validationRequired={false}
              validation={validation}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Years" })}
              name="years"
              options={yearList}
              initialLabel={selectedYear}
              initialValue={selectedYear}
              setFieldValue={setSelectedYear}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col>
            <div className={`btn-group ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`} role="group">
              {returnSwitchTabButtonUI("blnViewAll", "blnViewAll", blnAll, loading || localLoading, intl.formatMessage({ id: "All" }), blnViewAllFunction, "100px")}
              {returnSwitchTabButtonUI("blnNotMonthlyPosting", "blnNotMonthlyPostingTrue", !blnMonthlyPosting && !blnAll, loading || localLoading, intl.formatMessage({ id: "DailyPosting" }), blnDailyPostingFunction, "150px")}
              {returnSwitchTabButtonUI("blnMonthlyPosting", "blnMonthlyPostingTrue", blnMonthlyPosting && !blnAll, loading || localLoading, intl.formatMessage({ id: "MonthlyPosting" }), blnMonthlyPostingFunction, "150px")}
            </div>
          </Col>
          <Col>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading }
              disable={localLoading || loading }
              onClick={() => {
                fetchMonthlyFinancialReportWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
          ?
          <Loading/>
          :
          <div>
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              bordered={true}
              pageSize={pageSize}
              totalItem={monthlyFinancialReportList.length}
              tableColumns={monthlyFinancialReportColumns}
              data={monthlyFinancialReportList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "CLAndCCPosition"})})}
              objSorted={defaultSorted}
              keyField={"businessEntityName"}
              requestAPI={getMonthlyFinancialReportList}
              hideSearchBar={true}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(MonthlyFinancialReport);
