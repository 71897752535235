import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import * as Yup from "yup";
import { useFormik } from "formik";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { BranchListObject } from "../../app/models/branch";
import agent from "../../app/api/agent";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import MyButton from "../../app/components/form/MyButton";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import { downloadExcel, returnBlobImage } from "../../app/common/function/function";
import { ProductImportObject } from "../../app/models/product";
import { Constants } from "../../app/constants/Constants";
import { useStore } from "../../app/stores/store";
import LoadingModal from "../../app/components/modal/LoadingModal";

const ProductTemporally = () => {
  const { commonStore } = useStore();
  const { setErrorMessage, setSuccessMessage } = commonStore;
  const [branchDropdown, setBranchDrodpown] = useState<BranchListObject[]>([]);
  const [importFileModal, setImportFileModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  useEffect(()=> {
    async function fetchBranchDropdown() {
      const resultBranchesDropdownFinal = await agent.Branch.branchDropdown({}, false);
      setBranchDrodpown(resultBranchesDropdownFinal.branches);
    }

    fetchBranchDropdown();
  }, []);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchId: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  const importProductFunction = async (excelFile) => {
    if (excelFile) {
      setLoadingModal(true);
      try {
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: ProductImportObject = { file: fileTemp };
        await agent.Product.importProductTemporally(valuesTemp);
        setSuccessMessage('Import Stock Balance Succesfully')
      }
      catch (e) {
        setErrorMessage('Import Stock Balance Error')
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage('No Excel File Found')
    }
  }

  return (
    <DetailViewLayout
				title={"Export / Import Stock Balance"}
				breadCrumbList={[]}
        auditTrailId={""}>
      <SingleColumnRowParent>
        <DropDownWithTitle
          key={`branch-dropdown`}
          name={"branchDropdown"}
          title={"Branch"}
          specifyReturnFieldName={[
            {
              field: "branchId",
              value: "value",
            },
          ]}
          labelField={"name"}
          valueField={"id"}
          options={branchDropdown}
          disabled={false}
          initialLabel={""}
          initialValue={""}
          validationRequired={false}
          validation={validation}/>
      </SingleColumnRowParent>
      <div className="display-flex">
        <MyButton
          content={"Export Stock Balance"}
          onClick={async () => { 
            setLoadingModal(true);
            await downloadExcel(`/stockbalance/export/${validation.values.branchId}`, {});
            setLoadingModal(false);
          }}
          class={'btn btn-success'}
          disable={false} />
        <MyButton
          content={"Import Stock Balance"}
          onClick={() => { 
            setImportFileModal(true);
          }}
          class={'btn btn-info margin-left-16'}
          disable={false} />
      </div>
      <ImportFileModal
        blnShow={importFileModal}
        setModal={setImportFileModal}
        title={"Import Stock Balance"}
        btnFunction={importProductFunction}
      />
      {
        loadingModal
        &&
        <LoadingModal/>
      }
    </DetailViewLayout>
  );
}

export default observer(ProductTemporally);
