import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import PaginationTable from '../../app/components/table/PaginationTable';
import { CommissionSchemeProductCreditListObject } from '../../app/models/commissionScheme';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { history } from "../..";
import { checkPermission, comparePathForPagination, comparePathForSetParams, returnThousandSeperator } from '../../app/common/function/function';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";

const ProductCreditCommissionSettings = () => {
  const intl = useIntl();
  //Use Store
  const { commissionSchemeStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, prevPath, setShowDeletePrompt } = commonStore;
  const { commissionSchemeProductCreditList, commissionSchemeProductCreditPaginationParams, getCommisionSchemeProductCredit, deleteCommissionSchemeProductCredit, setCommissionSchemeProductCreditPaginationParams } = commissionSchemeStore;
  
  //Data List
  const [selectedRow, setSelectedRow] = useState<CommissionSchemeProductCreditListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: "" }];
  const blnPermissionCreateProductCreditCommissionSettings = checkPermission([PermissionConstants.CreateProductCreditCommissionScheme]);
  const blnPermissionUpdateProductCreditCommissionSettings = checkPermission([PermissionConstants.UpdateProductCreditCommissionScheme]);
  const blnPermissionDeleteProductCreditCommissionSettings = checkPermission([PermissionConstants.DeleteProductCreditCommissionScheme]);

  let productCreditCommissionSettingsRequestBody : PaginationRequestBody = { 
    pageNumber: currentPage, 
    pageSize: pageSize
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  useEffect(() => {
    async function fetchProductCreditCommissionSettingsListAPI() {
      setLoading(true)

      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.productCreditCommissionSettings)
      if (blnBackNavigation) {
        if (commissionSchemeProductCreditPaginationParams) {
          setCurrentPage(commissionSchemeProductCreditPaginationParams.pageNumber)
          productCreditCommissionSettingsRequestBody = commissionSchemeProductCreditPaginationParams;
        }
      }
      else {
        setCommissionSchemeProductCreditPaginationParams(undefined);
      }
    
      await getCommisionSchemeProductCredit(productCreditCommissionSettingsRequestBody)
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageProductCreditCommissionScheme], true)) {
      return;
    }

    fetchProductCreditCommissionSettingsListAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.productCreditCommissionSettings)
        if (!blnSetParams) {
          setCommissionSchemeProductCreditPaginationParams(undefined);
        }
      }
    })
  }, [])

  const onClickDelete = async (row: CommissionSchemeProductCreditListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  //Table Content UI
  const ProductCreditCommissionSettingsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "creditAmountFrom",
      text: intl.formatMessage({ id: "CreditAmountFrom" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <div>
          {returnThousandSeperator(row.creditAmountFrom)}
        </div>,
    },
    {
      dataField: "creditAmountTo",
      text: intl.formatMessage({ id: "CreditAmountTo" }).toUpperCase(),
      formatter: (cellContent, row) => 
        <div>
          {returnThousandSeperator(row.creditAmountTo)}
        </div>,
    },
    {
      dataField: "percentage",
      text: intl.formatMessage({ id: "Percentage" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{`${row.percentage}%`}</div>, //Custom UI to display
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.productCreditCommissionSettings}/view/${row.id}`}
              type={'view'}/>
            {
              blnPermissionUpdateProductCreditCommissionSettings
              &&
              <TableActionButton 
                to={`/${RoutesList.productCreditCommissionSettings}/edit/${row.id}`}
                type={'edit'}/>
            }
            {
              blnPermissionDeleteProductCreditCommissionSettings
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ProductCreditCommissionSettings" }) })}
        title={intl.formatMessage({ id: "ProductCreditCommissionSettings" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionCreateProductCreditCommissionSettings
          &&
          <>
            <MyAddButton
              content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "ProductCreditCommissionSettings" }) })}
              onClick={() => { history.push(`/${RoutesList.productCreditCommissionSettings}/add`) }}
              linkTo={`/${RoutesList.productCreditCommissionSettings}/add`}
              disable={loading} />
          </>
        }
        addButtonXL={"4"}>
        <PaginationTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          totalItem={totalItem}
          tableColumns={ProductCreditCommissionSettingsColumns}
          data={commissionSchemeProductCreditList}
          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ProductCreditCommissionSettings" }) })}
          objSorted={defaultSorted}
          requestAPI={getCommisionSchemeProductCredit} />
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteCommissionSchemeProductCredit(selectedRow?.id || "");
            let currentPageTemp = currentPage;
            if (commissionSchemeProductCreditList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCommisionSchemeProductCredit({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(ProductCreditCommissionSettings);
