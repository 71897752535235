import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { LeadImportObject, LeadImportSuccessDetailObject, LeadListObject } from '../../app/models/lead';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, getBrandId, leadStatusColor, newSpaceBeforeCapitalLetter, returnBlobImage, returnSubBrandList } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import ImportFileModal from "../../app/components/modal/ImportFileModal";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import MyButton from "../../app/components/form/MyButton";
import { EmployeeListObject } from "../../app/models/employee";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import { useLocation } from "react-router-dom";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import ImportLeadModal from "./Modal/ImportLeadModal";
import ReassignLeadModal from "./Modal/ReassignLeadModal";
import { SubBrandObject } from "../../app/models/brand";
import { PaginationRequestBody } from "../../app/models/pagination";
import FollowUpHistoryModal from "./Modal/FollowUpHistoryModal";
import SelectDropDownModal from "../../app/components/modal/SelectDropDownModal";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import { MediaSourceListObject } from "../../app/models/mediaSource";

const Lead = () => {
  const intl = useIntl();
  //Use Store
  const { leadStore, mediaSourceStore, branchStore, staticReferenceStore, employeeStore, profileStore, commonStore } = useStore();
  const { loading, setLoading, loadingModal, setLoadingModal, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt, prevPath } = commonStore;
  const { leadList, getLead, leadPaginationParams, deleteLead, importLead, exportLead, reassignLead, setLeadPaginationParams, addFollowUp } = leadStore;
  const { mediaSourceDropdownList, getMediaSourceDropdown } = mediaSourceStore;
  const { branchDropDownList, isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { profileDetail } = profileStore;
  const { getEmployeeDropdown } = employeeStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<LeadListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedLeadStatus, setSelectedLeadStatus] = useState<string>(AllStatus);
  const [selectedLeadStatusId, setSelectedLeadStatusId] = useState<number | string | undefined>(" ");
  const [leadStatusList, setLeadStatusList] = useState<StaticReferenceDataObject[]>([]);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [subBrandListForModal, setSubBrandListForModal] = useState<SubBrandObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const [filteredMediaSourceList, setFilteredMediaSourceList] = useState<MediaSourceListObject[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedAppointmentDateRange, setSelectedAppointmentDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [selectedSubmissionDateRange, setSelectedSubmissionDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [importGeneralFileModal, setImportGeneralFileModal] = useState(false);
  const [importLeadGenFileModal, setImportLeadGenFileModal] = useState(false);
  const [importGoogleFileModal, setImportGoogleFileModal] = useState(false);
  const [marcomConsultantFinalList, setMarcomConsultantFinalList] = useState<EmployeeListObject[]>([]);
  const All = `${intl.formatMessage({ id: "All" })}`;
  const [selectedMarcomConsultantName, setSelectedMarcomConsultantName] = useState(All);
  const [selectedMarcomConsultantId, setSelectedMarcomConsultantId] = useState(" ");
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [importedLeadDetails, setImportedLeadDetails] = useState<LeadImportSuccessDetailObject[]>([]);
  const [modalImportedLeadDetails, setModalImportedLeadDetails] = useState<boolean>(false);
  const [reassignLeadModal, setReassignLeadModal] = useState(false);
  const [marcomConsultantList, setMarcomConsultantList] = useState<EmployeeListObject[]>([]);
  const [marcomConsultantWithoutFilterList, setMarcomConsultantWithoutFilterList] = useState<EmployeeListObject[]>([]);
  const [followUpHistoryModal, setFollowUpHistoryModal] = useState(false);
  const [selectedLeadDetails, setSelectedLeadDetails] = useState<LeadListObject | undefined>(undefined);
  const [selectedLeadPlatformTypeValue, setSelectedLeadPlatformTypeValue] = useState<string | number>("");
  const [blnHasDuplicatedLeadOrHasExistingCustomer, setBlnHasDuplicatedLeadOrHasExistingCustomer] = useState(false);
  const [importMenu, setImportMenu] = useState(false);
  const [exportMenu, setExportMenu] = useState(false);
  const paramsSearch = useLocation().search;
  const statusParams = new URLSearchParams(paramsSearch).get('status');
  const marcomConsultantIdParams = new URLSearchParams(paramsSearch).get('marcomConsultantId');
  const blnPermissionCreateLead = checkPermission([PermissionConstants.CreateLead]);
  const blnPermissionUpdateLead = checkPermission([PermissionConstants.UpdateLead]);
  const blnPermissionDeleteLead = checkPermission([PermissionConstants.DeleteLead]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      leadName: "",
      leadMobileNo: "",
      leadEmail: "",
      leadNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let leadRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    sourceId: selectedMediaSourceId,
    sourceName: selectedMediaSourceName,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    appointmentDateRange: selectedAppointmentDateRange,
    submissionDateRange: selectedSubmissionDateRange,
    leadStatus: selectedLeadStatusId,
    leadStatusName: selectedLeadStatus,
    marcomConsultantId: selectedMarcomConsultantId,
    marcomConsultantName: selectedMarcomConsultantName,
    leadName: validation.values.leadName,
    leadMobileNo: validation.values.leadMobileNo,
    leadEmail: validation.values.leadEmail,
    leadNo: validation.values.leadNo,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId,
  }

  useEffect(() => {
    async function fetchLeadListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.lead)
      if (blnBackNavigation) {
        if (leadPaginationParams) {
          setInitialCallAPI(false);
          setCurrentPage(leadPaginationParams.pageNumber)
          setSelectedMediaSourceId(leadPaginationParams.sourceId || "");
          setSelectedMediaSourceName(leadPaginationParams.sourceName || AllSource);
          setSelectedBranchId(leadPaginationParams.branchId || "");
          setSelectedBranchName(leadPaginationParams.branchName || AllBranch);
          setSelectedAppointmentDateRange(leadPaginationParams.appointmentDateRange)
          setSelectedSubmissionDateRange(leadPaginationParams.submissionDateRange)
          setSelectedLeadStatusId(leadPaginationParams.leadStatus);
          setSelectedLeadStatus(leadPaginationParams.leadStatusName || AllStatus);
          setSelectedMarcomConsultantId(leadPaginationParams.marcomConsultantId || "");
          setSelectedMarcomConsultantName(leadPaginationParams.marcomConsultantName || All);
          setSelectedSubBrandName(leadPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(leadPaginationParams.subBrandId || " ");
          validation.setValues((values) => ({
            ...values,
            leadName: leadPaginationParams.leadName || "",
            leadMobileNo: leadPaginationParams.leadMobileNo || "",
            leadEmail: leadPaginationParams.leadEmail || "",
            leadNo: leadPaginationParams.leadNo || ""
          }))
          leadRequestBody = leadPaginationParams;
        }
        else {
          blnBackNavigation = false;
        }
      }
      else {
        setLeadPaginationParams(undefined);
      }

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
        setSubBrandListForModal(returnSubBrandList());
      }
      let blnStatusAndConsultantParams = statusParams && marcomConsultantIdParams;
      let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
        getStaticReferenceWithType(Constants.leadStatus, AllStatus),
        //this one filter resigned marcom consultant
        getEmployeeDropdown({ 
          brandId: getBrandId(), 
          branchId: "", 
          userRoleIds: [`${Constants.marComUserRoleId},${Constants.marComCustomerServiceId}`], 
          isActive: true }),
        //this one no filter resigned marcom consultant
        getEmployeeDropdown({ brandId: getBrandId(), branchId: "",  userRoleIds: [`${Constants.marComUserRoleId},${Constants.marComCustomerServiceId}`]}),
      ];
      let resultAPI = await Promise.all(aryAPI);
      if (!blnStatusAndConsultantParams) {
        setInitialCallAPI(false);
      }
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setFilteredMediaSourceList(_.cloneDeep(resultAPI[1].filter(mediaSource => mediaSource.isReferral !== true)))
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"));
      setLeadStatusList(resultAPI[2]);

      if (resultAPI[3] && resultAPI[3].length > 0) {
        setMarcomConsultantList(resultAPI[3])
        let marcomConsultantFinalListTemp: any = _.cloneDeep(resultAPI[3]);
        marcomConsultantFinalListTemp = marcomConsultantFinalListTemp.map((value) => ({ label: value.preferredName, value: value.id }))
        marcomConsultantFinalListTemp.splice(0, 0, { label: All, value: " " });
        setMarcomConsultantFinalList(marcomConsultantFinalListTemp);

        if(blnBackNavigation){
          setLoading(false);
          setLoadingDropdownRefresh(false);
          return;
        }

        let blnIndexFound = false;
        if (profileDetail) {
          let indexFoundMarcomConsultant = _.findIndex(marcomConsultantFinalListTemp, { value: profileDetail.id });
          if (indexFoundMarcomConsultant > -1) {
            blnIndexFound = true;
            setSelectedMarcomConsultantName(marcomConsultantFinalListTemp[indexFoundMarcomConsultant].label)
            setSelectedMarcomConsultantId(marcomConsultantFinalListTemp[indexFoundMarcomConsultant].value);
          }
        }
        if (!blnIndexFound && !blnStatusAndConsultantParams) {
          await fetchLeadApi(false);
        }
      }

      if (resultAPI[4] && resultAPI[4].length > 0) {
        setMarcomConsultantWithoutFilterList(resultAPI[4]);
      }

      if(blnBackNavigation){
        setLoading(false);
        setLoadingDropdownRefresh(false);
        return;
      }
      
      if (blnStatusAndConsultantParams) {
        let leadStatusListTemp: any = _.cloneDeep(resultAPI[2]);
        let leadStatusIdTemp: number | string = " ";
        let marcomConsultantIdTemp = "";
        leadStatusListTemp.map((value) => {
          if (statusParams == value.key) {
            setSelectedLeadStatus(value.displayValue)
            setSelectedLeadStatusId(value.key);
            leadStatusIdTemp = value.key;
          }
        })

        let marcomConsultantFinalListTemp: any = _.cloneDeep(resultAPI[3]);
        marcomConsultantFinalListTemp.map((value) => {
          if (marcomConsultantIdParams == value.id) {
            setSelectedMarcomConsultantName(value.preferredName)
            setSelectedMarcomConsultantId(value.id);
            marcomConsultantIdTemp = value.id;
          }
        })
        await fetchLeadApi(false, 1, leadStatusIdTemp, marcomConsultantIdTemp);
        setInitialCallAPI(false);
        history.replace({ pathname: `/${RoutesList.lead}` });
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageLead], true)) {
      return;
    }

    fetchLeadListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.lead)
        if (!blnSetParams) {
          setLeadPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage && reassignLeadModal) {
      setTimeout(() => {
        setSuccessMessage("");
        setReassignLeadModal(false);
        fetchLeadApi(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    if ((selectedLeadStatusId || selectedLeadStatusId === 0) && !initialCallAPI) {
      fetchLeadApi(true);
    }
  }, [selectedLeadStatusId, selectedMarcomConsultantId, selectedBranchId, selectedMediaSourceId, selectedAppointmentDateRange, selectedSubmissionDateRange, triggerSearch, selectedSubBrandId])

  async function fetchLeadApi(blnDropDown: boolean = false, pageSizeInsert?: number, leadStatusTemp?: number | string, marcomConsultantId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getLead({
      ...leadRequestBody,
      ...pageSizeInsert && { pageNumber: pageSizeInsert },
      ...(leadStatusTemp || leadStatusTemp === 0) && { leadStatus: leadStatusTemp },
      ...marcomConsultantId && { marcomConsultantId: marcomConsultantId }
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickDelete = async (row: LeadListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onSelectDateRange = (objDateRange: any, type: string) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    if (type == Constants.appointmentDate) {
      setSelectedAppointmentDateRange(objDateRange);
    }
    else if (type == Constants.submissionDate) {
      setSelectedSubmissionDateRange(objDateRange);
    }
  }

  const refreshDataAfterLeadImport = () => {
    setCurrentPage(1);
    fetchLeadApi(true, 1);
    setTimeout(() => {
      setModalImportedLeadDetails(false);
      setImportedLeadDetails([]);
    }, 2000)
  }

  const importFunctionWithGeneralType = (excelFile) => {
    importLeadFunction(excelFile, "");
  } 

  const importFunctionWithLeadGenType = (excelFile) => {
    importLeadFunction(excelFile, 0);
  } 

  const importFunctionWithGoogleType = (excelFile) => {
    importLeadFunction(excelFile, 1);
  } 

  const importLeadFunction = async (excelFile, platformType) => {
    if (excelFile) {
      try {
        setLoadingModal(true);
        let fileTemp = await returnBlobImage(excelFile);
        let valuesTemp: LeadImportObject = { file: fileTemp };
        let resultImportLead = await importLead(platformType, valuesTemp);
        if (resultImportLead?.leadDetails) {
          let leadDetailsTemp = _.cloneDeep(resultImportLead.leadDetails);
          leadDetailsTemp = leadDetailsTemp.map((valueLeadDetailsTemp) => ({
            ...valueLeadDetailsTemp,
            backgroundColor: valueLeadDetailsTemp.isExistingCustomer ? Constants.leadPink : valueLeadDetailsTemp.isDuplicateLead ? Constants.leadYellow : "",
            isRequiredToAddLead: !valueLeadDetailsTemp.isExistingCustomer && !valueLeadDetailsTemp.isDuplicateLead ? true : false
          }))

          setSelectedLeadPlatformTypeValue(platformType)
          if (platformType === "") {
            setImportGeneralFileModal(false);
          }
          else if (platformType === 0) {
            setImportLeadGenFileModal(false);
          }
          else if (platformType === 1) {
            setImportGoogleFileModal(false);
          }
          setImportedLeadDetails(leadDetailsTemp);
          setBlnHasDuplicatedLeadOrHasExistingCustomer(resultImportLead.hasDuplicateLead || resultImportLead.hasExistingCustomer ? true : false)
          setModalImportedLeadDetails(true);
        }
      }
      finally {
        setLoadingModal(false);
      }
    }
    else {
      setErrorMessage(intl.formatMessage({ id: "ValidationUpload" }, { field: intl.formatMessage({ id: "File" }) }))
    }
  }

  const exportLeadReport = async (platformType: string | number) => {
    setLocalLoading(true);
    await (exportLead({ exportType: 2, platformType: platformType }))
    setLocalLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const LeadColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "leadNo",
      text: intl.formatMessage({ id: "LeadNo" }).toUpperCase(),
      hidden: true,
      style: {
        flex: 1,
      },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "mobileNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
    },
    {
      dataField: "raceName",
      text: intl.formatMessage({ id: "Race" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.raceName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "marcomConsultantName",
      text: intl.formatMessage({ id: "AppointedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.marcomConsultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSource",
      text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "PreferredBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "lastAppointmentDate",
      text: intl.formatMessage({ id: "LastAppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "lastFollowUpDate",
      text: intl.formatMessage({ id: "LastFollowUpDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.submissionDate !== Constants.invalidDateTime ? moment(row.submissionDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.submissionDate !== Constants.invalidDateTime ? moment(row.submissionDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
          </div>
        )
      }
    },
    {
      dataField: "lastFollowUpRemark",
      text: intl.formatMessage({ id: "LastFollowUpRemark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.lastFollowUpRemark || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "type",
      text: intl.formatMessage({ id: "LeadType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.type) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Lead" }) }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={leadStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageLead, PermissionConstants.UpdateLead, PermissionConstants.DeleteLead]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              iconToolTipText={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
              onClick={()=> {
                setSelectedLeadDetails(row);
                setFollowUpHistoryModal(true);
              }}
              type={'add'} />
            <TableActionButton
              to={`/${RoutesList.lead}/view/${row.id}`}
              type={'view'} />
            {
              row.status !== Constants.closed
              &&
              blnPermissionUpdateLead
              &&
              row.type !== Constants.walkIn
              &&
              <TableActionButton
                to={`/${RoutesList.lead}/edit/${row.id}`}
                type={'edit'} />
            }
            {
              blnPermissionDeleteLead
              &&
              row.type !== Constants.walkIn
              &&
              <TableActionButton
                type={'delete'}
                onClick={() => onClickDelete(row)} />
            }
          </div>
        </>
      ),
    },
  ];

  const returnImportExportDropdownButton = (blnImport: boolean) => {
    return (
      <ButtonDropdown
        isOpen={blnImport ? importMenu : exportMenu}
        toggle={() => blnImport ? setImportMenu(!importMenu) : setExportMenu(!exportMenu)}
        className={"d-inline-block"}
        disable={loading ? true : undefined}
        style={{ alignSelf: 'center' }}>
        <DropdownToggle
          className={`btn ${blnImport ? 'btn-primary' : 'btn-info'} margin-left-8`}
          id={`${blnImport ? `page-header-import-dropdown` : `page-header-export-dropdown`}`}
          tag="button"
          style={{ minWidth: '120px', height: "36px" , borderRadius:'0.25rem' }}>
          <span className="ms-2 me-1">{blnImport ? intl.formatMessage({ id: "Import" }) : intl.formatMessage({ id: "Export" })}</span>
          <ChevronDownIcon size={Constants.menuIconSize}/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => blnImport ? setImportGeneralFileModal(true) : exportLeadReport("")}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Default" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "DefaultTemplate" }) })
            }
          </DropdownItem>
          <DropdownItem tag="a" onClick={() => blnImport ? setImportLeadGenFileModal(true) : exportLeadReport(0)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "LeadGen" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "LeadGenTemplate" }) })
            }
          </DropdownItem>
          <DropdownItem tag="a" onClick={() => blnImport ? setImportGoogleFileModal(true) : exportLeadReport(1)}>
            {" "}
            {
              blnImport
                ?
                intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Google" }) })
                :
                intl.formatMessage({ id: "ExportWithModuleName" }, { moduleName: intl.formatMessage({ id: "GoogleTemplate" }) })
            }
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
  
  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}
        title={intl.formatMessage({ id: "Lead" })}
        addButton={
          <>
            {blnPermissionCreateLead
              &&
              <>
                <MyAddButton
                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}
                  onClick={() => { history.push(`/${RoutesList.lead}/add`) }}
                  linkTo={`/${RoutesList.lead}/add`}
                  disable={loading} />
                {returnImportExportDropdownButton(true)}
                {returnImportExportDropdownButton(false)}
              </>
            }
            {
              blnPermissionUpdateLead &&
              <MyButton
                content={intl.formatMessage({ id: "ReassignWithModuleName" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}
                onClick={() => { setReassignLeadModal(true) }}
                class={'btn btn-warning margin-left-8'}
                disable={loading} />
            }
          </>
        }
        addButtonXL={"6"}>
        {
          !initialCallAPI &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    onChange={(value) => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PreferredBranch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "MediaSource" })}
                  name="mediaSource"
                  styles={{}}
                  options={mediaSourceListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedMediaSourceName}
                  initialValue={selectedMediaSourceId}
                  setFieldLabel={setSelectedMediaSourceName}
                  setFieldValue={setSelectedMediaSourceId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                  name={"SubmissionDateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={(value) => {
                    onSelectDateRange(value, Constants.submissionDate)
                  }}
                  initialValue={selectedSubmissionDateRange} />
              </DoubleColumnRowParent>
            </Row>
            <Row>

              {
                !loadingDropdownRefresh
                &&
                <>
                  <DoubleColumnRowParent>
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Lead" }) })}
                      name="leadStatus"
                      options={leadStatusList}
                      blnValueWithNewSpace={true}
                      labelField="displayValue"
                      valueField="key"
                      initialLabel={selectedLeadStatus}
                      initialValue={selectedLeadStatusId}
                      setFieldLabel={setSelectedLeadStatus}
                      setFieldValue={setSelectedLeadStatusId}
                      disabled={localLoading || loading}
                      validationRequired={true}
                      onChange={() => {
                        setCurrentPage(1);
                        setErrorMessage("");
                        setSuccessMessage("");
                      }}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <GeneralSelectDropdown
                      title={intl.formatMessage({ id: "AppointedBy" })}
                      name={"marcomConsultantId"}
                      options={marcomConsultantFinalList}
                      initialLabel={selectedMarcomConsultantName}
                      initialValue={selectedMarcomConsultantId}
                      setFieldLabel={setSelectedMarcomConsultantName}
                      setFieldValue={setSelectedMarcomConsultantId}
                      disabled={localLoading || loading}
                      validationRequired={true}
                      onChange={() => {
                        setCurrentPage(1);
                        setErrorMessage("");
                        setSuccessMessage("");
                      }} />
                  </DoubleColumnRowParent>
                </>
              }
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "LastAppointmentDate"})})}
                  name={"LastAppointmentDateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={(value) => {
                    onSelectDateRange(value, Constants.appointmentDate)
                  }}
                  initialValue={selectedAppointmentDateRange} />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "LeadNo" })}
              name="leadNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "LeadNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="leadName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Name" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "MobileNo" })}
              name="leadMobileNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "MobileNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Email" })}
              name="leadEmail"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Email" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={LeadColumns}
              data={leadList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Lead" }) })}
              objSorted={defaultSorted}
              requestAPI={getLead}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              sourceId={selectedMediaSourceId}
              sourceName={selectedMediaSourceName}
              marcomConsultantId={selectedMarcomConsultantId}
              marcomConsultantName={selectedMarcomConsultantName}
              leadStatus={selectedLeadStatusId}
              leadStatusName={selectedLeadStatus}
              appointmentDateRange={selectedAppointmentDateRange}
              submissionDateRange={selectedSubmissionDateRange}
              leadName={validation.values.leadName}
              leadMobileNo={validation.values.leadMobileNo}
              leadEmail={validation.values.leadEmail}
              leadNo={validation.values.leadNo}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName} />
        }
        {
          importGeneralFileModal
          &&
          <ImportFileModal
            blnShow={importGeneralFileModal}
            setModal={setImportGeneralFileModal}
            title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Default" }) })}
            btnFunction={importFunctionWithGeneralType}
          />
        }
        {
          importLeadGenFileModal
          &&
          <ImportFileModal
            blnShow={importLeadGenFileModal}
            setModal={setImportLeadGenFileModal}
            title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "LeadGen" }) })}
            btnFunction={importFunctionWithLeadGenType}
          />
        }
        {
          importGoogleFileModal
          &&
          <ImportFileModal
            blnShow={importGoogleFileModal}
            setModal={setImportGoogleFileModal}
            title={intl.formatMessage({ id: "ImportWithModuleName" }, { moduleName: intl.formatMessage({ id: "Google" }) })}
            btnFunction={importFunctionWithGoogleType}
          />
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteLead(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (leadList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getLead(leadRequestBody)
            setLoading(false);
          }} />
        {
          modalImportedLeadDetails
          &&
          <ImportLeadModal
            blnShow={modalImportedLeadDetails}
            setModal={setModalImportedLeadDetails}
            importedLeadDetails={importedLeadDetails}
            setImportedLeadDetails={setImportedLeadDetails}
            refreshData={refreshDataAfterLeadImport}
            selectedLeadPlatformTypeValue={selectedLeadPlatformTypeValue}
            marcomConsultantFinalList={marcomConsultantList}
            filteredMediaSourceList={filteredMediaSourceList}
            subBrandListForModal={subBrandListForModal}
            blnHasDuplicatedLeadOrHasExistingCustomer={blnHasDuplicatedLeadOrHasExistingCustomer} />
        }
        {
          reassignLeadModal
          &&
          <ReassignLeadModal
            blnShow={reassignLeadModal}
            setModal={setReassignLeadModal}
            getLead={getLead}
            marcomConsultantList={marcomConsultantList}
            marcomConsultantWithoutFilterList={marcomConsultantWithoutFilterList}
            reassignLead={reassignLead}
          />
        }
        {
          followUpHistoryModal &&
          <FollowUpHistoryModal
            blnShow={followUpHistoryModal}
            blnAction={Constants.add}
            setModal={setFollowUpHistoryModal}
            leadId={selectedLeadDetails?.id}
            leadName={selectedLeadDetails?.name}
            addFollowUp={addFollowUp}
            selectedFollowUpDetail={undefined}
            setFollowUpDetail={()=>{}}
            setErrorMessage={setErrorMessage}
            onSuccessFunction={()=> {
              fetchLeadApi(true);
            }} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(Lead);
