import React, { useEffect, useState } from 'react'
import { Input, Label, Row, Col, Button } from 'reactstrap';
import Select from "react-select";
import { AddOptionsValueField, filterDropDownValue } from '../../common/function/function';
import _ from 'lodash';
import MyButton from '../form/MyButton';

interface Props {
  title: string;
  name: string;
  className?: string;
  styles?: any;
  placeholder?: string;
  options: any;
  labelField?: string;
  valueField?: string;
  menuIsOpen: boolean;
  setMenuIsOpen: any;
  disabled: boolean;
  initialValue: any;
  setFieldFunction: any;
  prevoiousValue: any;
  setPreviousFieldFunction: any;
  onChange?: Function;
}

export default function GeneralMultiSelectDropdownWithCheckBox(props: Props) {
  let optionsTemp : any = AddOptionsValueField(props.options, (props.labelField || "label"), (props.valueField || "value"));
  const selectAllValue = 'all'
  // const buttonValue = 'button'
  optionsTemp.splice(0,0, {label: "Select All", value: selectAllValue});
  // optionsTemp.splice(optionsTemp.length,0, {label: "Button", value: buttonValue});
  const [blnChangesMade, setBlnChangesMade] = useState(false);
  
  useEffect(()=> {
    if (props.menuIsOpen) {
      props.setMenuIsOpen(false);
    }
  }, [props.menuIsOpen])

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const nbValues = getValue().length == optionsTemp.length ? getValue().length -1 : getValue().length;
  
    return (
      <div style={{marginLeft: '8px'}}>{`${nbValues} ${nbValues === 1 ? 'room' : 'rooms'} selected`}</div>
    );
  };

  const formatOptionLabel = ({ value, label }) => {
    let indexTemp = _.findIndex(props.initialValue, {value : value});
    const indexSelectAllTemp = _.findIndex(props.initialValue, {value : selectAllValue});
    
    // if (value == buttonValue) {
    //   return (
    //     <Row>
    //       <Col xl={6} style={{ display: 'flex', justifyContent: 'center'}}>
    //           <MyButton 
    //             style={{width: '100%'}}
    //             content={"Ok"}
    //             class="btn btn-success"
    //             onClick={()=> {
    //               refreshAppointmentHandling();
    //             }}/>
    //       </Col>
    //       <Col xl={6} style={{ display: 'flex', justifyContent: 'center'}}>
    //         <MyButton 
    //           style={{width: '100%'}}
    //           content={"Cancel"}
    //           class="btn btn-danger"
    //           onClick={()=> {
    //             props.setFieldFunction(props.prevoiousValue)
    //             props.setMenuIsOpen(true)
    //           }}/>
    //       </Col>
    //     </Row>
    //   )
    // }
    // else {
      return (
        <div style={{ display: "flex" }}>
          <Input
            checked={(indexTemp > -1) || (indexSelectAllTemp > -1) ? true : false}
            type="checkbox"
            disabled={props.disabled}
            onChange={()=> {
            }}
          />
          <div style={{ marginLeft: "10px", color: 'black' }}>
            <span>{label}</span>
          </div>
        </div>
      )
    // }
  }

  const removeSelectAllAndButtonValue = (aryTemp : any) => {
    let selectedAryValueTemp = _.cloneDeep(aryTemp);
    selectedAryValueTemp = selectedAryValueTemp.filter((valueTemp) => valueTemp.value !== selectAllValue)
    return selectedAryValueTemp;
  }

  const refreshAppointmentHandling = () => {
    let aryRoomTemp = filterDropDownValue(props.initialValue, selectAllValue);
    if (props.onChange) {
      props.setPreviousFieldFunction(props.initialValue);
      props.onChange(aryRoomTemp);
      props.setMenuIsOpen(true)
    }
  }

  return (
    props.menuIsOpen
    ?
    <div/>
    :
    <div className={props.className || "mb-3"} style={props.styles}>
      {
        props.title 
        && 
        <Label>
         {!props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
          <Label className="margin-bottom-0">{props.title}</Label>
        </Label>
      }
      <Select
        name={props.name}
        placeholder={props.placeholder || "- Please Select -"}
        isDisabled={props.disabled}
        onChange={(selectedAryValue: any, valueSelected)=> {
          // if (valueSelected.option?.value == buttonValue)
          //   return;
          setBlnChangesMade(true);
          if (valueSelected.option?.value == selectAllValue) {
            const indexSelectAllTemp = _.findIndex(selectedAryValue, {value : selectAllValue});
            if (indexSelectAllTemp < 0) {
              props.setFieldFunction([]);
            }
            else {
              let selectedAryValueTemp = removeSelectAllAndButtonValue(optionsTemp);
              selectedAryValueTemp.splice(0,0, {label: "Select All", value: selectAllValue});
              props.setFieldFunction(selectedAryValueTemp);
            }
          }
          else {
            let selectedAryValueTemp = removeSelectAllAndButtonValue(selectedAryValue);
            if (selectedAryValueTemp.length == props.options.length) {
              selectedAryValueTemp.splice(0,0, {label: "Select All", value: selectAllValue});
            }
            props.setFieldFunction(selectedAryValueTemp);
          }
        }}
        defaultValue={props.initialValue || null}
        value={props.initialValue || null}
        options={optionsTemp || []}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'white',
            primary: 'white',
            borderWidth: '1px', borderStyle: "solid"
          },
        })}
        isMulti
        isClearable={false}
        hideSelectedOptions={false}
        components={{ValueContainer}}
        closeMenuOnSelect={false}
        isSearchable={false}
        menuPosition="fixed"
        onMenuClose={()=> {
          if (blnChangesMade) {
            refreshAppointmentHandling()
          }
          setBlnChangesMade(false);
        }}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  )
}
