import React, { useEffect, useState } from 'react'
import {
  Label
} from "reactstrap";
import Select from 'react-select';
import _ from 'lodash';
import { AddOptionsValueField, convertValueWithSpaceInOptionsArray, customFilter, newSpaceBeforeCapitalLetter } from '../../common/function/function';
import { Constants } from '../../constants/Constants';
import GeneralImageWithBase64 from '../form/GeneralImageWithBase64';

interface Props {
  name: string;
  className?: any;
  validationRequired?: boolean;
  title?: string;
  options: any;
  valueField: string,
  labelField: string,
  specifyReturnFieldName: any; //[{"field": "", "value": ""}]
  returnFieldWithLabel: boolean;
  returnAllField?: boolean;
  placeholder?: string;
  disabled: boolean;
  initialLabel?: string | number;
  initialValue?: string | number | string[] | any;
  validation: any;
  field?: any;
  setFieldValue?: any;
  blnValueWithNewSpace?: boolean;
  onBlurFunction?: Function;
  blnDisplayImage?: boolean;
  displayToolTip?: any;
  onChangeFunction?: Function;
  blnSupportCustomSearch?: boolean;
  onChangeCustomSearch?: Function;
  onMenuOpenFunction?: Function;
  loadingCustomSearch?: boolean;
  setLoadingCustomSearch?: Function;
  keyDiv?: string;
}

let localSearchValue = "";
export default function DropDownWithTitleMultiSelect(props: Props) {  
  let optionsTemp = AddOptionsValueField(props.options, props.labelField, props.valueField);
  let initialValueDisplay = props.initialValue || undefined;
  const [blnOnChange, setBlnOnChange] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  let timeOut : any =  0;

  if (props.blnValueWithNewSpace) {
    optionsTemp = convertValueWithSpaceInOptionsArray(optionsTemp, true);
  }

  const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: props.validation.touched[props.name] && props.validation.errors[props.name] ? 'red' : base.borderColor,
    })
  }

  const triggerOnBlurFunction = () => {
    if (!blnOnChange) return;
    setBlnOnChange(false);
    clearTimeoutFunction();
    if (props.onBlurFunction) {
      props.onBlurFunction();
    }
  }

  // useEffect(() => {
  //   const timeOut = setTimeout(() => {
  //     if (triggerValue) {
  //       if (props.setLoadingCustomSearch) {
  //         props.setLoadingCustomSearch(true);
  //       }
  //       if (props.onChangeCustomSearch) {
  //         props.onChangeCustomSearch(triggerValue);
  //       }
  //       setTriggerValue('');
  //     }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timeOut)
  //   }
  // }, [triggerValue])
  
  const localOnChangeCustomSearch = (value) => {
    clearTimeoutFunction();
    localSearchValue = value;

    if (value.length < Constants.dropdownSearchValueLength) {
      if (props.onMenuOpenFunction) {
        props.onMenuOpenFunction();
      }
      return;
    }

    timeOut = setTimeout(() => {
      if (props.setLoadingCustomSearch) {
        props.setLoadingCustomSearch(true);
      }
      if (props.onChangeCustomSearch) {
        props.onChangeCustomSearch(value);
      }
    }, Constants.dropdownSearchAPITimeout);
  }

  const clearTimeoutFunction = () => {
    localSearchValue = "";
    if(timeOut) {
      clearTimeout(timeOut)
    }
  }

  return (
    <div className={`${props.className || "" } mb-3`} {...props.keyDiv && {key: props.keyDiv}}>
      {
        props.title && 
        <Label>
          {props.validationRequired && !props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
          <Label className="margin-bottom-0 margin-top-0">{props.title}</Label>
          {
            props.displayToolTip && props.displayToolTip()
          }
        </Label>
      }
      <Select
        name={props.name}
        {...props.keyDiv && {key: props.keyDiv}}
        className={props.validation.touched[props.name] && props.validation.errors[props.name] ? "" : "margin-bottom-0"}
        options={optionsTemp}
        placeholder={props.placeholder || "- Please Select -"}
        defaultValue={initialValueDisplay ? initialValueDisplay : null}
        isDisabled={props.disabled}
        onChange={(selectedValue: any)=> {
          let aryTemp : any = [];
          props.specifyReturnFieldName.map((valueTemp)=> {
            selectedValue.map((valueSelected)=> {
              let indexField = _.findIndex(aryTemp, valueTemp.field);
              let contentTemp = props.returnFieldWithLabel 
                                ? 
                                {
                                  label: valueSelected[props.labelField],
                                  value: valueSelected[valueTemp.value],
                                  ...props.returnAllField && valueSelected
                                }
                                :
                                valueSelected[valueTemp.value]
              if (indexField < 0) {
                aryTemp.push({
                  [valueTemp.field]: [contentTemp]
                })
              }
              else {
                aryTemp[indexField][valueTemp.field].push(contentTemp)
              }
            })
          })
          props.specifyReturnFieldName.map((valueTemp)=> {
            let indexField = _.findIndex(aryTemp, valueTemp.field);
            let aryFinal = indexField < 0 ? [] : aryTemp[indexField][valueTemp.field];

            if (props.setFieldValue) {
              let fieldTemp = _.cloneDeep(props.field);
              fieldTemp[props.name] = aryFinal;
              props.setFieldValue(fieldTemp);
            }
            else {
              props.validation.setFieldValue(valueTemp.field, aryFinal);
            }
          })
          if (props.onBlurFunction) {
            setBlnOnChange(true);
          }
          if (props.onChangeFunction) {
            props.onChangeFunction();
          }
        }}
        isMulti
        menuPosition="fixed"
        // onBlur={props.onBlurFunction && blnOnChange ? triggerOnBlurFunction : props.validation.handleBlur}   
        onBlur={()=> {
          setInputSearch("");
          props.onBlurFunction && blnOnChange ? triggerOnBlurFunction() : props.validation.handleBlur("");
        }}   
        styles={selectStyle}
        onKeyDown={(event) => {
          if (props.onBlurFunction && event.key === Constants.enter) {
            triggerOnBlurFunction();        
          }
        }}
        {...!props.blnSupportCustomSearch && !props.onChangeCustomSearch && !props.setLoadingCustomSearch && {inputValue: inputSearch}}
        onInputChange={(value: any, action)=> {
          if (props.blnSupportCustomSearch && props.onChangeCustomSearch && props.setLoadingCustomSearch) {
            localOnChangeCustomSearch(value);
          }
          else if (action.action === "input-change") {
            setInputSearch(value);
          }
        }}
        formatOptionLabel={valueTemp => {
          return (
            props.blnDisplayImage
            ?
            <GeneralImageWithBase64
              base64Image={valueTemp[props.labelField || ""]}/>
            :
            <div>
              {valueTemp["label"]}
            </div>
          )
        }}
        {...(props.blnSupportCustomSearch && props.loadingCustomSearch) && {noOptionsMessage : ()=> ('Loading...')}}
        {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && (!localSearchValue || localSearchValue.length < Constants.dropdownSearchValueLength)) && {noOptionsMessage : ()=> (`Please enter ${localSearchValue.length < Constants.dropdownSearchValueLength ? Math.abs(localSearchValue.length-Constants.dropdownSearchValueLength) : ""} or more characters to search`)}}
        {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && localSearchValue.length > (Constants.dropdownSearchValueLength-1)) && {noOptionsMessage : ()=> (`No options`)}}
        {...props.blnSupportCustomSearch && {filterOption: customFilter}}
        onMenuOpen={()=> {
          if (props.blnSupportCustomSearch && props.onMenuOpenFunction) {
            props.onMenuOpenFunction();
          }
        }}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
      />
      {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
        <div className="error_require_text margin-bottom-4">{props.validation.errors[props.name]}</div>
      ) : null}
    </div>
  )
}