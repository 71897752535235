import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import { useStore } from "../../../app/stores/store";
import Loading from "../../../app/components/loading/Loading";
import moment from "moment";
import { observer } from 'mobx-react-lite';
import { ReferenceDocumentTransactionListObject } from "../../../app/models/referenceDocument";
import PaginationTable from "../../../app/components/table/PaginationTable";
import { PaginationRequestBody } from "../../../app/models/pagination";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  documentId: string;
  documentType: string;
}

export default observer(function ReferenceDocumentTransactionModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { referenceDocumentStore } = useStore();
  const { getReferenceDocumentTransaction } = referenceDocumentStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [localReferenceDocumentTransactionList, setLocalReferenceDocumentTransactionList] = useState<ReferenceDocumentTransactionListObject[]>([]);
  const [localTotalItems, setLocalTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const disabledFieldInput = localLoading;

  useEffect(()=> {
    async function fetchReferenceDocumentTransaction (){
      setLocalLoading(true);
      await fetchReferenceDocumentTransactionAPI({pageSize: pageSize, pageNumber: currentPage, documentId: props.documentId, documentType: props.documentType})
      setLocalLoading(false);
    }

    fetchReferenceDocumentTransaction();
  },[])

  const ReferenceDocumentTransactionColumns = [
    {
      dataField: "companyName",
      text: intl.formatMessage({ id: "Company" }).toUpperCase()
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "transactionType",
      text: intl.formatMessage({ id: "TransactionType" }).toUpperCase()
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      headerStyle: { textAlign: 'end' },
      formatter: (cellContent, row) => (
        <div className="text-end">{row.amount}</div>
      )
    },
    {
      dataField: "actionDate",
      text: intl.formatMessage({ id: "TransactionDate" }).toUpperCase(),
      headerStyle: { textAlign: 'end' },
      formatter: (cellContent, row) => (
        <div className="text-end">{`${moment(row.actionDate).format(Constants.displayDateTextInputFormat)}`}</div>
      )
    },
    {
      dataField: "commissionRate",
      text: intl.formatMessage({ id: "CommRate" }).toUpperCase(),
      headerStyle: { textAlign: 'end' },
      formatter: (cellContent, row) => (
        <div className="text-end">{row.commissionRate ? `${row.commissionRate}%` : ""}</div>
      )
    },
  ]

  const fetchReferenceDocumentTransactionAPI = async (objTemp: PaginationRequestBody) => {
    let resultReferenceDocumentTransactionAPI = await getReferenceDocumentTransaction(objTemp);
    setLocalReferenceDocumentTransactionList(resultReferenceDocumentTransactionAPI.data);
    setLocalTotalItems(resultReferenceDocumentTransactionAPI.totalItems)
  }

  const hideModal = () => {
    props.setModal(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!disabledFieldInput) {
          hideModal();
        }
      }}
      size='xl'
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Transactions"})}</h5>
        {
          !disabledFieldInput 
          &&
          <button
            type="button"
            onClick={() => {
              if (!localLoading) {
                hideModal();
              }
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        }

      </div>
      <div className="modal-body">
        <div className="margin-left-16 margin-right-16">
          {
            localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={localTotalItems}
                tableColumns={ReferenceDocumentTransactionColumns}
                data={localReferenceDocumentTransactionList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Transactions" }) })}
                objSorted={[]}
                blnRemoveBackground={true}
                documentId={props.documentId}
                documentType={props.documentType}
                requestAPI={fetchReferenceDocumentTransactionAPI} />
            </>
          }
        </div>
      </div>
    </Modal>
  )
});