import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, compareParamsArrayWithDefaultArray, convertParamsUrlToArray, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, returnRouteFromModule } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import ExchangeToCreditDetailModal from "../../ExchangeToCredit/Modal/ExchangeToCreditDetailModal";
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import { history } from "../../..";
import CreditAdjustmentModal from "../../Customer/Modal/CreditAdjustmentModal";
import PaymentAdjustmentDetailModal from "../../CustomerPaymentAdjustment/Modal/PaymentAdjustmentDetailModal";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import SalesAdjustmentDetailModal from "../../SalesAdjustment/Modal/SalesAdjustmentDetailModal";

const SalesAdjustmentHistory = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, exchangeToCreditStore, creditAdjustmentStore, customerPaymentAdjustmentStore, salesAdjustmentStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { salesAdjustmentHistoryList, getSalesAdjustmentHistoryList, setSalesAdjustmentHistoryList, exportSalesAdjustmentHistory } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getExchangeToCreditWithId } = exchangeToCreditStore;
  const { creditAdjustmentDetail, getCreditAdjustmentWithId } = creditAdjustmentStore;
  const { getCustomerPaymentAdjustmentWithId } = customerPaymentAdjustmentStore;
  const { getSalesAdjustmentWithId } = salesAdjustmentStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [salesAdjustmentTypeList, setSalesAdjustmentTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [selectedSalesAdjustmentTypeOptions, setSelectedSalesAdjustmentTypeOptions] = useState<string[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [creditAdjustmentModal, setCreditAdjustmentModal] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);

  const [exchangeToCreditDetailModal, setExchangeToCreditDetailModal] = useState(false);
  const [loadingExchangeToCreditDetail, setLoadingExchangeToCreditDetail] = useState(false);
  const [selectedExchangeToCreditDetail, setSelectedExchangeToCreditDetail] = useState<any>([]);
  const [paymentAdjustmentDetailModal, setPaymentAdjustmentDetailModal] = useState(false);
  const [selectedCustomerPaymentAdjustmentDetail, setSelectedCustomerPaymentAdjustmentDetail] = useState<any>([]);
  const [salesAdjustmentDetailModal, setSalesAdjustmentDetailModal] = useState(false);
  const [selectedSalesAdjustmentDetail, setSelectedSalesAdjustmentDetail] = useState<any>([]);

  const blnPermissionManageExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);
  const blnPermissionManageCustomerProductReturn = checkPermission([PermissionConstants.ManageCustomerProductReturn]);
  const blnPermissionManageCreditAdjustment = checkPermission([PermissionConstants.ManageCreditAdjustment]);
  const blnPermissionManagePaymentAdjustment = checkPermission([PermissionConstants.ManagePaymentAdjustment]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const blnPermissionManageAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);
  const blnPermissionManageSalesAdjustment = checkPermission([PermissionConstants.ManageSalesAdjustment]);
  
  const paramsSearch = useLocation().search;
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');
  const salesAdjustmentTypesIdsParams = convertParamsUrlToArray("salesAdjustmentTypes", paramsSearch);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      salesOrderNo: "",
      salesAdjustmentTypeIds: [],
      salesAdjustmentTypeNames: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchCollectionsSummary() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let blnParams = branchParams && fromDateParams && toDateParams;

      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.salesAdjustmentType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setSalesAdjustmentTypeList(resultAPI[1]);

      if (blnParams) {
        setSelectedDateRange({ fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" })

        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        if (salesAdjustmentTypesIdsParams) {
          let selectedSalesAdjustmentTypeOptionsTemp = compareParamsArrayWithDefaultArray(salesAdjustmentTypesIdsParams, resultAPI[1], "displayValue", "displayValue", "key");
          setSelectedSalesAdjustmentTypeOptions(selectedSalesAdjustmentTypeOptionsTemp);
          let salesAdjustmentTypeIdsTemp = selectedSalesAdjustmentTypeOptionsTemp.map((valueSelectedSalesAdjustmentTypeOptionTemp)=> (valueSelectedSalesAdjustmentTypeOptionTemp.key));
          validation.setFieldValue("salesAdjustmentTypeIds", salesAdjustmentTypeIdsTemp);
        }
        
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.salesAdjustmentHistory}` });
        await fetchSalesAdjustmentHistoryWithLoading(false, branchIdTemp, { fromDate: moment(fromDateParams).format(Constants.displayDateFormat) || "", toDate: moment(toDateParams).format(Constants.displayDateFormat) || "" }, salesAdjustmentTypesIdsParams);
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setSalesAdjustmentHistoryList([]);
    })
  }, [])


  async function fetchSalesAdjustmentHistoryWithLoading(blnDropDown: boolean = false, branchIdTemp?: string, dateRange?: DateRangeInterface, salesAdjustmentTypesTemp?: string[]) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate && !dateRange) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getSalesAdjustmentHistoryList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: branchIdTemp || selectedBranchId,
      dateRange: dateRange || selectedDateRange,
      salesOrderNo: validation.values.salesOrderNo,
      salesAdjustmentTypes: salesAdjustmentTypesTemp || validation.values.salesAdjustmentTypeIds
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportSalesAdjustmentHistoryApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportSalesAdjustmentHistory({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      salesOrderNo: validation.values.salesOrderNo,
      salesAdjustmentTypes: validation.values.salesAdjustmentTypeIds
    });
    setLocalLoading(false)
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const CollectionsSummaryDetailsColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "performedDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.performedDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.performedDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "salesAdjustmentNo",
      text: intl.formatMessage({ id: "SalesAdjustmentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageSalesAdjustment
        ?
        <Link to="#" onClick={() => onClickViewSalesAdjustment(row.salesAdjustmentId)}>
          <Label className="margin-bottom-0 pointer-clickable">{row.salesAdjustmentNo}</Label>
        </Link>
        :
        <div>{row.salesAdjustmentNo}</div>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {
            row.salesOrderNo ?
              <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 pointer-clickable">{row.salesOrderNo}</Label>
              </Link>
              :
              <div>{Constants.emptyData}</div>
          }
        </>
    },
    {
      dataField: "salesAdjustmentType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.salesAdjustmentType) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.amount)}</div>
      ),
    },
    {
      dataField: "productAmount",
      text: intl.formatMessage({ id: "ProductAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productAmount)}</div>
      ),
    },
    {
      dataField: "productCommission",
      text: intl.formatMessage({ id: "ProductComm" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.productCommission)}</div>
      ),
    },
    {
      dataField: "referenceNo",
      text: intl.formatMessage({ id: "Reference" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div style={{minWidth: "120px"}}>
          {
            row.referenceNo && row.referenceId ?
              viewReferenceNo(row)
              :
              <div>{Constants.emptyData}</div>
          }
        </div>
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.remark || Constants.emptyData}
        </div>
      ),
    },
  ];

  const onClickViewExchangeToCreditDetail = async (exchangeToCreditId: any) => {
    setLoadingExchangeToCreditDetail(true);
    let resultExchangeToCredit = await getExchangeToCreditWithId(exchangeToCreditId);
    setSelectedExchangeToCreditDetail(resultExchangeToCredit)
    setExchangeToCreditDetailModal(true);
    setLoadingExchangeToCreditDetail(false);
  }

  const onClickViewCreditAdjustment = async (creditAdjustmentId: any) => {
    setLoading(true);
    await getCreditAdjustmentWithId(creditAdjustmentId)
    setCreditAdjustmentModal(true);
    setLoading(false);
  }

  const onClickViewPaymentAdjustment = async (paymentAdjustmentId: any) => {
    setLoading(true);
    let resultPaymentAdjustment = await getCustomerPaymentAdjustmentWithId(paymentAdjustmentId)
    setSelectedCustomerPaymentAdjustmentDetail(resultPaymentAdjustment);
    setPaymentAdjustmentDetailModal(true);
    setLoading(false);
  }

  const onClickViewSalesAdjustment = async (salesAdjustmentId: any) => {
    setLoading(true);
    let resultSalesAdjustment = await getSalesAdjustmentWithId(salesAdjustmentId);
    setSelectedSalesAdjustmentDetail(resultSalesAdjustment)
    setSalesAdjustmentDetailModal(true);
    setLoading(false);
  }

  const viewReferenceNo = (row) => {
    if (row.salesAdjustmentType === Constants.refundSalesOrderToCredit) {
      return (
        blnPermissionManageExchangeToCredit
          ?
          <Link to="#" onClick={() => onClickViewExchangeToCreditDetail(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType === Constants.encashFromCredit) {
      return (
        blnPermissionManageCreditAdjustment
          ?
          <Link to="#" onClick={() => onClickViewCreditAdjustment(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType === Constants.productReturnCredit) {
      return (
        blnPermissionManageCustomerProductReturn
          ?
          <Link to={row.referenceId ? `/${returnRouteFromModule(row.salesAdjustmentType)}/view/${row.referenceId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType === Constants.customerCreditPayment) {
      return (
        <MyButton
          type={"button"}
          content={`${intl.formatMessage({ id: "ViewDetails" })}`}
          subContent={<CashCheckIcon size={20} />}
          onClick={() => {
            history.push({
              pathname: `/${RoutesList.receipt}/view/${row.referenceId}`,
              state: {
                blnSalesAdjustment: true
              }
            });
          }}
          style={{ border: 'none', backgroundColor: 'transparent', fontWeight: '500' }}
          class="text-primary margin-bottom-0 padding-0"
        />
      );
    }
    else if (row.salesAdjustmentType === Constants.paymentAdjustment) {
      return (
        blnPermissionManagePaymentAdjustment
          ?
          <Link to="#" onClick={() => onClickViewPaymentAdjustment(row.referenceId)}>
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else if (row.salesAdjustmentType === Constants.appolousSale) {
      return (
        blnPermissionManageAppolousSales
          ?
          <Link to={row.referenceId ? `/${returnRouteFromModule(row.salesAdjustmentType)}/view/${row.referenceId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.referenceNo}</Label>
          </Link>
          :
          <div>{row.referenceNo}</div>
      );
    }
    else {
      return (
        <div>{row.referenceNo}</div>
      );
    }
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesAdjustmentHistory" }) })}
        title={intl.formatMessage({ id: "SalesAdjustmentHistory" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              exportSalesAdjustmentHistoryApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-Adjustment-History.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              {
                // !getBranchUser()
                // &&
                <Col xl="3">
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Branch" })}
                    name="branchName"
                    options={branchListFinal}
                    initialLabel={selectedBranchName}
                    initialValue={selectedBranchId}
                    setFieldLabel={setSelectedBranchName}
                    setFieldValue={setSelectedBranchId}
                    disabled={localLoading || loading || getBranchUser() || loadingExchangeToCreditDetail}
                    validationRequired={true}
                  />
                </Col>
              }
              <Col xl="3">
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({ id: "Date" }) })}
                  name={"DateRange"}
                  disabled={localLoading || loading || loadingExchangeToCreditDetail}
                  onChangeFunction={onSelectDateRange}
                  validationRequired={true}
                  initialValue={selectedDateRange} />
              </Col>
            </Row>
            <Row>
              <Col xl='3'>
                <DropDownWithTitleMultiSelect
                    name="salesAdjustmentTypes"
                    title={intl.formatMessage({ id: "SalesAdjustmentType" })}
                    specifyReturnFieldName={[{ "field": "salesAdjustmentTypeIds", "value": "key" }]}
                    returnFieldWithLabel={false}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={salesAdjustmentTypeList}
                    disabled={localLoading || loading || loadingExchangeToCreditDetail}
                    blnValueWithNewSpace={true}
                    initialValue={selectedSalesAdjustmentTypeOptions}
                    validationRequired={false}
                    validation={validation}
                  />
              </Col>
              <Col xl='3'>
                <GeneralInput
                  title={intl.formatMessage({ id: "CustomerSalesOrderNo" })}
                  name="salesOrderNo"
                  type="text"
                  disabled={localLoading || loading || loadingExchangeToCreditDetail}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterCustomerSalesOrderNo" })}
                />
              </Col>
              <Col xl="2">
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                  content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                  loading={localLoading || loading || loadingExchangeToCreditDetail}
                  disable={localLoading || loading || loadingExchangeToCreditDetail}
                  onClick={() => {
                    fetchSalesAdjustmentHistoryWithLoading(true)
                  }}
                />
              </Col>
            </Row>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={CollectionsSummaryDetailsColumns}
                data={salesAdjustmentHistoryList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesAdjustmentHistory" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                salesOrderNo={validation.values.salesOrderNo}
                salesAdjustmentTypes={validation.values.salesAdjustmentTypeIds}
                keyField={"id"}
                requestAPI={getSalesAdjustmentHistoryList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
      {
        exchangeToCreditDetailModal &&
        <ExchangeToCreditDetailModal
          blnShow={exchangeToCreditDetailModal}
          setModal={setExchangeToCreditDetailModal}
          selectedExchangeToCreditDetail={selectedExchangeToCreditDetail}
          setSelectedExchangeToCreditDetail={setSelectedExchangeToCreditDetail}
        />
      }
      {
        creditAdjustmentModal &&
        <CreditAdjustmentModal
          blnShow={creditAdjustmentModal}
          setModal={setCreditAdjustmentModal}
          selectedCreditAdjustmentDetail={creditAdjustmentDetail}
          blnView={true}
          setErrorMessage={setErrorMessage} />
      }
      {
        paymentAdjustmentDetailModal &&
        <PaymentAdjustmentDetailModal
          blnShow={paymentAdjustmentDetailModal}
          setModal={setPaymentAdjustmentDetailModal}
          selectedCustomerPaymentAdjustmentDetail={selectedCustomerPaymentAdjustmentDetail}
          setSelectedCustomerPaymentAdjustmentDetail={setSelectedCustomerPaymentAdjustmentDetail}
        />
      }
      {
        salesAdjustmentDetailModal &&
        <SalesAdjustmentDetailModal
          blnShow={salesAdjustmentDetailModal}
          setModal={setSalesAdjustmentDetailModal}
          blnAction={Constants.view}
          selectedSalesAdjustmentDetail={selectedSalesAdjustmentDetail}
          setSelectedSalesAdjustmentDetail={setSelectedSalesAdjustmentDetail}
          blnAllowCrossMonthSales={false} />
      }
    </div>
  );
}

export default observer(SalesAdjustmentHistory);
