import { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { getFileExtension, getUniqueFileName } from "../../common/function/function";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { Constants } from "../../constants/Constants";
import FileDocumentIcon from 'mdi-react/FileDocumentIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { FormFeedback, Label, UncontrolledTooltip } from "reactstrap";
import _ from "lodash";
import { GeneralAttachmentListInterface } from "../../models/common";
import { isWindows } from "react-device-detect";

interface Props {
  title: string;
	name: string;
  className?: string;
	validation: any;
  validationRequired?: boolean;
	disabled: boolean;
  setErrorMessage?: Function;
  blnExcelFormat?: boolean;
  blnMultipleFile?: boolean;
}

export default observer(function GeneralAttachment(props: Props) {
	const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [finalFiles, setFinalFiles] = useState<GeneralAttachmentListInterface[]>([]);
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const disabledSelectForSingleFile = !props.blnMultipleFile && finalFiles.length > 0;
  const displayErrorMessage = props.validation.touched[props.name] && props.validation.errors[props.name];

  useEffect(()=> {
    setFinalFiles(_.cloneDeep(props.validation.values[props.name]));
    
    return () => {
      finalFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }
  }, [])

	useEffect(() => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((valueFile: any) => {
        finalFiles.push({
          preview: valueFile.preview, 
          path: getUniqueFileName(valueFile.path, finalFiles)
        });
      });
      onUpdateSelectedFiles(finalFiles);
      setSelectedFiles([]);
    }
	}, [selectedFiles]);

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        setFinalFiles([]);
      }, Constants.timeoutNavigation+100)
    }
  }, [successMessage])

	const onDrop = useCallback(
		(acceptedFiles) => {
      if (props.blnExcelFormat) {
        if (acceptedFiles.length > 0) {
          if (getFileExtension(acceptedFiles[0].path).toLowerCase() !== 'xlsx' && getFileExtension(acceptedFiles[0].path).toLowerCase() !== 'xlsm') {
            if (props.setErrorMessage) {
              props.setErrorMessage(intl.formatMessage({ id: "FileTypeNotAccepted" }))
            }
            return;
          }
        }
      }
			setSelectedFiles(
				acceptedFiles.map((file: any) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
		[setSelectedFiles]
	);

	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop,
	  });

  const deleteSelectedFile = (indexSelectedFile) => {
    let finalFilesTemp = _.cloneDeep(finalFiles);
    finalFilesTemp.splice(indexSelectedFile, 1);
    setFinalFiles(finalFilesTemp);
    onUpdateSelectedFiles(finalFilesTemp);
  }

  const onUpdateSelectedFiles = (finalFilesTemp) => {
    props.validation.setFieldValue(props.name, finalFilesTemp);
  }

  const onClickWebUrl = (webUrl: string) => {
    if (webUrl) {
      window.open(`${webUrl}`)
    }
  }

	return (
    <div className={`${props.className ? props.className : ""}`}>
      <div className="flex-align-items-center-without-height mb-2">
        {props.validationRequired && !props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
        {props.title && <Label className="margin-bottom-0">{props.title}</Label>}
      </div>
      <div 
        className={!displayErrorMessage ? "mb-3" : "is-invalid form-control"}
        style={{
          border: "1px solid", 
          borderColor: !displayErrorMessage ? "#CED4DA" : "",
          borderRadius: "4px",
          height: isWindows ? "150px" : "140px",
          overflowX: "scroll",
          display: "flex",
          backgroundColor: props.disabled ? Constants.placeholderColor : ""
        }}>
        {
          !props.disabled && !disabledSelectForSingleFile
          &&
          <div
            key={`value_file_index_add_${props.name}`}
            className="pointer-clickable"
            {...!props.disabled && !disabledSelectForSingleFile ? getRootProps() : {}}
            style={{
              width: "100px",
              minWidth: "100px",
              maxWidth: "100px",
              marginTop: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              padding: "8px",
              paddingTop: isWindows ? "10px" : "8px",
              backgroundColor: "#EDF0F5",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <input {...getInputProps()} />
            <div 
              style={{display: "flex"}}>
              <PlusIcon 
                size={50}/>
            </div>
          </div>
        }
        {
          finalFiles.map((valueFile, indexFile)=> (
            <div
              key={`value_file_index_${indexFile}`}
              id={`${props.name}_file_${indexFile}`} 
              style={{
                width: "100px",
                minWidth: "100px",
                maxWidth: "100px",
                marginTop: "10px",
                marginLeft: "10px",
                marginBottom: "10px",
                padding: "8px", 
                paddingTop: isWindows ? "10px" : "8px",
                border: "1px solid", 
                borderColor: "#CED4DA",
                borderRadius: "8px",
                overflow: "hidden"
              }}>
              <div 
                style={{display: "flex"}}>
                <FileDocumentIcon
                  size={50} 
                  className={`text-primary ${!props.disabled ? "margin-left-16" : "margin-left-16"} ${valueFile.webUrl ? "pointer-clickable" : ""}`}
                  style={{marginTop: "16px"}}
                  onClick={()=> {
                    onClickWebUrl(valueFile.webUrl || "")
                  }}/>
                {
                  !props.disabled
                  &&
                  <button
                    type="button"
                    key={`button_key_delete_image`}
                    className={``}
                    style={{
                      width: "20px",
                      height: "20px",
                      padding: "0px",
                      border: "none",
                      backgroundColor: Constants.white
                    }}
                    onClick={() => {
                      deleteSelectedFile(indexFile)
                    }}>
                    <CloseCircleIcon 
                      style={{color: Constants.red}}/>
                  </button>
                }
              </div>
              <div 
                className={`text-truncate text-center ${valueFile.webUrl ? "pointer-clickable" : ""}`} 
                style={{fontSize: "12px", marginTop: "2px"}}
                onClick={()=> {
                  onClickWebUrl(valueFile.webUrl || "")
                }}>
                {valueFile.path}
              </div>
              <UncontrolledTooltip placement="top" target={`${props.name}_file_${indexFile}`} style={{whiteSpace:"pre-wrap"}}>
                {valueFile.path}
              </UncontrolledTooltip>
            </div>
          ))
        }
      </div>
      {displayErrorMessage ? (
        <FormFeedback type="invalid" className="mb-3">{props.validation.errors[props.name]}</FormFeedback>
      ) : null}
    </div>
	);
})