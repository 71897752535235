import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, getBranchId, getBranchName, firstDayOfMonthToCurrentDay, arraySpliceInAllValue, returnNumberWithDecimal, returnSubBrandList } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";

const LeadTurnUp = () => {
  const intl = useIntl();
  //Use Store
  const { reportMarcomStore, branchStore, reportCustomerStore, commonStore } = useStore();
  const { loading, setLoading, windowSize, setErrorMessage } = commonStore;
  const { leadTurnUpList, getLeadTurnUpList, setLeadTurnUpList, exportLeadTurnUp, exportLeadsDetails } = reportMarcomStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { exportCustomerDetailsReportList } = reportCustomerStore; 

  //Data List
  const [localLoading, setLocalLoading] = useState(false);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());

  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportMarcomReport = checkPermission([PermissionConstants.ExportMarcomReport]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      isIncludeRejoinAndCrossBrand: false,
      isIncludeWalkInLead: false
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });
  
  useEffect(() => {
    async function fetchLeadTurnUpListAPI() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()      
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], AllBranch, false, "name", "id"))
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageMarcomReport], true)) {
      return;
    }

    fetchLeadTurnUpListAPI();

    return (() => {
      setLeadTurnUpList([]);
    })
  }, []);

  const fetchLeadTurnUpListWithLoading = async (blnExport: boolean) => {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportLeadTurnUp({ branchId: selectedBranchId, dateRange: selectedDateRange, subBrandId: selectedSubBrandId, isIncludeRejoinAndCrossBrand: validation.values.isIncludeRejoinAndCrossBrand, isIncludeWalkInLead: validation.values.isIncludeWalkInLead });
    }
    else {
      await getLeadTurnUpList({ branchId: selectedBranchId, dateRange: selectedDateRange, subBrandId: selectedSubBrandId, isIncludeRejoinAndCrossBrand: validation.values.isIncludeRejoinAndCrossBrand, isIncludeWalkInLead: validation.values.isIncludeWalkInLead });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const LeadTurnUpColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase()
    },
    {
      dataField: "newLeadCount",
      text: intl.formatMessage({ id: "NewLeads" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        blnPermissionExportMarcomReport && (leadTurnUpList.length === 1 || ((leadTurnUpList.length -1) !== index))
        ?
        <div
          onClick={async ()=> {
            setLocalLoading(true);
            await exportLeadsDetails({
              branchName: row.branchName,
              branchId: row.branchId,
              firstAppointmentFromDate: selectedDateRange.fromDate,
              firstAppointmentToDate: selectedDateRange.toDate,
              isFromLeadTurnUpSummary: true,
              isFirstAppointmentOnly: true,
              templateType: 2,
              subBrandId: selectedSubBrandId,
              isIncludeRejoinAndCrossBrand: validation.values.isIncludeRejoinAndCrossBrand,
              isIncludeWalkInLead: validation.values.isIncludeWalkInLead
            });
            setLocalLoading(false);
          }}>
          <Label id={"newleadexport"} className="margin-bottom-0 pointer-clickable text-primary">{row.newLeadCount}</Label>
          <UncontrolledTooltip placement="top" target="newleadexport">
            {intl.formatMessage({ id: "ExportNewLeads" })}
          </UncontrolledTooltip>
        </div>
        :
        row.newLeadCount
      ),
    },
    {
      dataField: "turnUpCount",
      text: intl.formatMessage({ id: "TurnUp" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        blnPermissionExportCustomerReport && (leadTurnUpList.length === 1 || ((leadTurnUpList.length -1) !== index))
        ?
        <div
          onClick={async ()=> {
            setLocalLoading(true);
            await exportCustomerDetailsReportList({
              branchName: row.branchName,
              branchId: row.branchId,
              joinedFromDate: selectedDateRange.fromDate,
              joinedToDate: selectedDateRange.toDate,
              isFromLeadTurnUpSummary: true,
              subBrandId: selectedSubBrandId,
              isIncludeRejoinAndCrossBrand: validation.values.isIncludeRejoinAndCrossBrand,
              isIncludeWalkInLead: validation.values.isIncludeWalkInLead
            });
            setLocalLoading(false);
          }}>
          <Label id={"turnupexport"} className="margin-bottom-0 pointer-clickable text-primary">{row.turnUpCount}</Label>
          <UncontrolledTooltip placement="top" target="turnupexport">
            {intl.formatMessage({ id: "ExportTurnUp" })}
          </UncontrolledTooltip>
        </div>
        :
        row.turnUpCount
      ),
    },
    {
      dataField: "turnUpPercentage",
      text: intl.formatMessage({ id: "TurnUpPercentageWithoutSymbol" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {`${returnNumberWithDecimal(row.turnUpPercentage)}%`}
        </div>
      ),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "LeadsTurnUpRateSummary" }) })}
        title={intl.formatMessage({ id: "LeadsTurnUpRateSummary" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportMarcomReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchLeadTurnUpListWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Leads-Turn-Up-Rate-Summary.aspx`}>
        <Row>
          {
            subBrandListFinal.length > 0
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "SubBrand" })}
                name="subBrandName"
                options={subBrandListFinal}
                initialLabel={selectedSubBrandName}
                initialValue={selectedSubBrandId}
                labelField="name"
                valueField="id"
                disabled={localLoading || loading}
                setFieldLabel={setSelectedSubBrandName}
                setFieldValue={setSelectedSubBrandId}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
          }
          <DoubleColumnRowParent>
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              validationRequired={true}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRange" })}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              initialValue={selectedDateRange}
              validationRequired={true} />
          </DoubleColumnRowParent>
        </Row>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "IncludeRejoinAndCrossBrand" })}
              name="isIncludeRejoinAndCrossBrand"
              type="checkbox"
              disabled={localLoading || loading}
              validation={validation}
              childrenUI={
                <Label className="margin-bottom-0 margin-left-4">
                  Yes
                </Label>
              }
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "IncludeWalkInLead" })}
              name="isIncludeWalkInLead"
              type="checkbox"
              disabled={localLoading || loading}
              validation={validation}
              childrenUI={
                <Label className="margin-bottom-0 margin-left-4">
                  Yes
                </Label>
              }
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-12" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchLeadTurnUpListWithLoading(false)
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
          ?
          <Loading />
          :
          <div className="standard-layout">
            <PaginationTableWithoutApi
              title={intl.formatMessage({ id: "LeadsTurnUpRateSummary" })}
              headerClassName={"table-light"}
              options={leadTurnUpList}
              columns={LeadTurnUpColumns}
              pageSize={Constants.maxPageSize}
              blnHideTotal={true}
              blnDisabledScroll={true}
              keyField={"branchId"}/>
          </div>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(LeadTurnUp);
