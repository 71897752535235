import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { IncludesLocationPathName, inboundNoticeStatusColor, returnCurrentOnlyDate, returnCurrentOnlyTimeWith30Minutes, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, checkPermission } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../app/components/form/GeneralTextAreaForModal";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import MyButton from "../../app/components/form/MyButton";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";

const InboundNoticeInvoiceDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { inboundNoticeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
    setErrorMessage,
    setSuccessMessage,
  } = commonStore;
  const { inboundNoticeDetail, setInboundNoticeDetail, getInboundNoticeWithId, updateInboundNotice } = inboundNoticeStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle =  `${addAction ? intl.formatMessage({id: "Add"}) : viewAction ? intl.formatMessage({id: "View"}) : intl.formatMessage({id: "Edit"})
} ${intl.formatMessage({id: "InboundNotice"})}`;
  const breadCrumbList = [{ title: intl.formatMessage({id: "InboundNotice"}), urlPath: RoutesList.inboundNotice }];
  const invoiceFullDetail = inboundNoticeDetail || {
    inboundNoticeNo: "",
    createdAt: "",
    deliveryDate: "",
    purchaseOrderId: "",
    purchaseOrderNo: "",
    businessEntityId: "",
    businessEntityName: "",
    supplierId: "",
    supplierName: "",
    warehouseId: "",
    warehouseName: "",
    inboundNoticeStatus: "",
    note: "",
    supplierContactPerson: "",
    supplierContactNo: "",
    warehouseContactPerson: "",
    warehouseContactNo: "",
    deliveryFullDate: returnCurrentOnlyDate(),
    deliveryFullTime: returnCurrentOnlyTimeWith30Minutes(),
    inboundNoticeDetails: [{
      productId: "",
      productName: "",
      quantity: 0,
      allowedQuantity: 0,
      costPrice: 0,
      taxRate: 0,
      remark: "",
    }],
    additionalCosts: [{
      name: "",
      amount: 0,
      isItemized: false
    }],
    workflowAction: "",
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: inboundNoticeDetail || {
      purchaseOrderId: "",
      purchaseOrderNo: "",
      supplierId: "",
      supplierContactPerson: "",
      supplierContactNo: "",
      warehouseId: "",
      warehouseContactPerson: "",
      warehouseContactNo: "",
      note: "",
      deliveryDate: "",
      deliveryFullDate: returnCurrentOnlyDate(),
      deliveryFullTime: returnCurrentOnlyTimeWith30Minutes(),
      inboundNoticeDetails: [],
      additionalCosts: [],
      workflowAction: "",
      inboundNoticeStatus: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (invoiceFullDetail.inboundNoticeStatus == Constants.processing || invoiceFullDetail.inboundNoticeStatus == Constants.acknowledged) {
          await updateInboundNotice(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const paidStatus = invoiceFullDetail.inboundNoticeStatus == Constants.paid;
  const blnProcessingStatus = invoiceFullDetail.inboundNoticeStatus == Constants.processing;
  const blnReceivedStatus = invoiceFullDetail.inboundNoticeStatus == Constants.received || validation.values.inboundNoticeStatus == Constants.receiving;
  const blnCancelledStatus = invoiceFullDetail.inboundNoticeStatus == Constants.cancelled;
  const blnAcknowledgedStatus = invoiceFullDetail.inboundNoticeStatus == Constants.acknowledged;
  const blnPendingStatus = invoiceFullDetail.inboundNoticeStatus == Constants.pending;
  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || blnReceivedStatus || blnCancelledStatus || blnAcknowledgedStatus;
  const [totalAdditionalCost, setTotalAdditionalCost] = useState(0)
  const blnPermissionUpdateInboundNotice = checkPermission([PermissionConstants.UpdateInboundNotice]);

  async function fetchInboundNoticeDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getInboundNoticeWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateInboundNotice], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateInboundNotice], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageInboundNotice], true)) {
        return;
      }
    }
    
    fetchInboundNoticeDetailAPI();

    return (() => {
      if (id && !addAction) {
        setInboundNoticeDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    setTotalAdditionalCost(0);
    if (inboundNoticeDetail?.additionalCosts) {
      let totalAdditionalCostTemp = 0;
      inboundNoticeDetail.additionalCosts.map((valueAdditionalCostTemp)=> {
        totalAdditionalCostTemp += valueAdditionalCostTemp.amount;
      })
      setTotalAdditionalCost(totalAdditionalCostTemp);
    }
  }, [inboundNoticeDetail])

  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 ">
          {label}
        </h6>
        <h6 className="">
          {value}
        </h6>
      </Row>
    )
  }

  const displayBillingShippingRowData = (label, value) => {
    return (
      <div className="flex-direction-row">
        <span className="mb-0">
          {label}
        </span>
        <span className="margin-left-8 mb-0">
          {value}
        </span>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <MetaTags>
          <title>{displayTitle}</title>
        </MetaTags>
        <Container fluid>
          <BreadCrumb
            breadCrumbList={breadCrumbList}
            activeBreadCrumbTitle={displayTitle}
          />
          {/* {
            ((errorMessage || successMessage) && !loading)
            &&
            <AlertMessage 
              errorMessage={errorMessage}
              successMessage={successMessage}/>
          } */}
          {loading ? (
            <Loading />
          ) : (
            <Form
              className=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Row>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {intl.formatMessage({ id: "InboundNotice" })}
                              </span>
                              <br />
                              <br />
                            </div>
                          </Col>
                        </Row>
                        <Col>
                          {displayHeaderRowData(`${intl.formatMessage({id: "InboundNoticeNo"})}:`, invoiceFullDetail.inboundNoticeNo)}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Date"})}:`, moment(invoiceFullDetail.createdAt).format(Constants.displayDateFormat))}
                          {displayHeaderRowData(`${intl.formatMessage({id: "Time"})}:`, moment(invoiceFullDetail.createdAt).format(Constants.displayTimeFormat))}
                        </Col>
                        <hr />
                        <Row style={{ alignItems: 'center', marginTop: '20px' }}>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Billing" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Company"})}:`, invoiceFullDetail.supplierName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Name"})}:`, invoiceFullDetail.supplierContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ContactNo"})}.:`, invoiceFullDetail.supplierContactNo)}
                              {/* {displayBillingShippingRowData("Address:", invoiceFullDetail.purchaseFromAddress)}
                              {displayBillingShippingRowData("Payment method:", invoiceFullDetail.purchasePaymentMethod)} */}
                            </div>
                          </Col>
                          <Col>
                            <div className="logo-lg" style={{ lineHeight: '20px' }}>
                              <span style={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '20px' }}>
                                {`${intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Shipping" }) })}`} :
                              </span>
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "Receiver"})}:`, invoiceFullDetail.warehouseName)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ShippingContactPerson"})}:`, invoiceFullDetail.warehouseContactPerson)}
                              {displayBillingShippingRowData(`${intl.formatMessage({id: "ContactNo"})}.:`, invoiceFullDetail.warehouseContactNo)}
                              {/* {displayBillingShippingRowData("Shipping Address:", invoiceFullDetail.shippingAddress)} */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <br /><br />
                      <address>
                        <strong>{intl.formatMessage({id: "Status"})}:</strong>
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={invoiceFullDetail.inboundNoticeStatus}
                            statusColorFunction={inboundNoticeStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({id: "Product(s)"})}
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light">
                            <tr>
                              <th>{intl.formatMessage({id: "Name"})}</th>
                              <th></th>
                              <th></th>
                              <th className="text-center">{intl.formatMessage({id: "Price"})}</th>
                              <th className="text-center">{intl.formatMessage({id: "Quantity"})}</th>
                              <th className="text-end">{intl.formatMessage({id: "Total"})}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              invoiceFullDetail.inboundNoticeDetails.map((item, key) => {
                                return (
                                  <Fragment key={`${item.productName}_${key}`}>
                                    <tr key={key}>
                                      <td>{`${item.productName}`}</td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-center">{returnPriceWithCurrency(item.costPrice)}</td>
                                      <td className="text-center">{item.quantity}</td>
                                      <td className="text-end">{returnPriceWithCurrency(item.costPrice * item.quantity)}</td>
                                      <td style={{ width: "40px" }}><Input type='checkbox' className="margin-top-0" style={{width:"18px", height:"18px"}} disabled={true}></Input></td>
                                    </tr>
                                    {
                                      item.additionalDetails
                                      ?
                                      item.additionalDetails.map((additionalDetailsTemp, index) => (
                                        <tr key={`${additionalDetailsTemp.supplierReferenceNo}_${index}`}>
                                          <td style={{borderRightWidth: '1px'}}>
                                            <i className="bx bx-subdirectory-right"></i> {`${intl.formatMessage({id: "SupplierReferenceNo"})} : ${additionalDetailsTemp.supplierReferenceNo}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            {`${intl.formatMessage({id: "Quantity"})} : ${additionalDetailsTemp.quantity}`}
                                          </td>
                                          <td style={{borderRightWidth: '1px'}}>
                                            {`${intl.formatMessage({id: "IsFreeOfCharge"})} : ${additionalDetailsTemp.isFOC ? intl.formatMessage({id: "Yes"}) : intl.formatMessage({id: "No"})}`}
                                          </td>
                                          <td colSpan={4}/>
                                        </tr>
                                      ))
                                      :
                                      null
                                    }
                                  </Fragment>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                      {
                        (invoiceFullDetail.additionalCosts && invoiceFullDetail.additionalCosts.length !== 0) &&
                        <>
                          <div className="py-2 mt-3">
                            <h3 className="font-size-15 font-weight-bold">
                              {intl.formatMessage({id: "AdditionalCosts"})}
                            </h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="table-fixed" style={{ width: '100%' }}>
                              <thead className="table-light">
                                <tr>
                                  <th>{intl.formatMessage({id: "Name"})}</th>
                                  <th></th>
                                  <th style={{ width: "15%" }} className="text-center">{intl.formatMessage({id: "Itemized"})}</th>
                                  <th style={{ width: "15%" }} className="text-end">{intl.formatMessage({id: "Amount"})}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  invoiceFullDetail.additionalCosts.map((item, key) => (
                                    <tr key={key}>
                                      <td >{item.name}</td>
                                      <td></td>
                                      <td style={{textAlign: "center"}}>{item.isItemized ? `${intl.formatMessage({id: "Yes"})}` : `${intl.formatMessage({id: "No"})}`}</td>
                                      <td className="text-end">{returnPriceWithCurrency(item.amount)}</td>
                                    </tr>
                                  ))
                                }
                                <tr>
                                  <td colSpan={2} />
                                  <td className="text-center" style={{ fontWeight: 'bold' }}>
                                    {intl.formatMessage({id: "Total"})}
                                  </td>
                                  <td className="text-end">
                                    {returnPriceWithCurrency(totalAdditionalCost)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      }
                      <div className={`py-2 mt-3`}>
                        <h3 className="font-size-15 font-weight-bold">
                        {intl.formatMessage({id: "Note"})} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                      <div className="margin-top-24" />
                      <div className="flex-direction-row d-print-none">
                        {!Boolean(successMessage) && !blnReceivedStatus && !blnCancelledStatus && !blnPendingStatus && !addAction && blnPermissionUpdateInboundNotice &&
                          <MyButton
                            type="submit"
                            class={`btn btn-warning margin-right-8`}
                            content={Constants.cancel}
                            disable={
                              validation.isSubmitting
                            }
                            loading={
                              validation.isSubmitting
                            }
                            onClick={()=> {
                              validation.setFieldValue("workflowAction", Constants.cancel)
                            }}
                          />
                        }
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          showExtraLeftButton={blnProcessingStatus ? false : true}
                          extraLeftButtonTitle={intl.formatMessage({id: "Save"})}
                          extraLeftButtonColor="btn-primary"
                          extraLeftButtonFunction={() => {
                            validation.setFieldValue("workflowAction", Constants.save)
                          }}
                          showPrint={true}
                          hidePrimaryButton={blnReceivedStatus || blnCancelledStatus || blnAcknowledgedStatus || blnPendingStatus || !blnPermissionUpdateInboundNotice}
                          submitButtonTitle={blnProcessingStatus ? Constants.acknowledge : Constants.submit}
                          onClickFunction={() => {
                            validation.setFieldValue("workflowAction", blnProcessingStatus ? Constants.acknowledge : Constants.submit)
                          }}
                        />
                      </div>
                      {/* <div className="d-print-none">
                        <GeneralSubmitAndCancelBtn
                          className="flex-right"
                          successMessage={successMessage}
                          viewAction={false}
                          hidePrimaryButton={true}
                          showPrint={true}
                          cancelButtonTitle={intl.formatMessage({id: "Back"})}
                        />
                      </div> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
};

export default observer(InboundNoticeInvoiceDetail);
