import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, contructValidationErrorMessage, getMonths, getYears, IncludesLocationPathName, replaceAllStringWithArray, returnThousandSeperatorInString } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Label } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { ObjectForDropdown } from "../../app/models/common";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const SalesTargetDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { salesTargetStore, staticReferenceStore, positionStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { addSalesTarget, updateSalesTarget, salesTargetDetail, setSalesTargetDetail, getSalesTargetWithId } = salesTargetStore;
  const { commissionTypeList, commissionEarnType, getStaticReferenceWithType } = staticReferenceStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [showApplicableToFutureMonths, setShowApplicableToFutureMonths] = useState(false);
  const [showConfirmSalesTargetSubmission, setShowConfirmSalesTargetSubmission] = useState(false);
  const [confirmSalesTargetSubmissionMessage, setConfirmSalesTargetSubmissionMessage] = useState("");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "SalesTarget" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CommissionSettings" }), urlPath: RoutesList.salesTarget }, { title: intl.formatMessage({ id: "SalesTarget" }), urlPath: RoutesList.salesTarget }];
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateSalesTarget = checkPermission([PermissionConstants.UpdateSalesTarget]);
  const [refreshCommissionValue, setRefreshCommissionValue] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: salesTargetDetail || {
      name: "",
      year: undefined,
      month: undefined,
      from: undefined,
      to: undefined,
      commissionType: "",
      commissionValue: undefined,
      commissionEarnType: "",
      isRequiredSplit: true,
      splitPortion: undefined,
      positionName: "",
      positionId: "",
      isActive: true,
      extraCommissionValue: 0,
      isApplicableToFutureMonths: false,
      isConfirmSalesTargetSubmission: false
    },
    validationSchema: Yup.object({
      positionId: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Position" }) })),
      from:Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "From" }) })),
      to: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "To" }) })),
      year: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })),
      month: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })),
      commissionType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionType" }) })),
      commissionValue: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CommissionValue" }) })),
      commissionEarnType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CommissionEarnType" }) })),
      splitPortion: Yup.string()
        .test("isRequiredSplit", intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SplitPortion" }) }), (value: any, context) => {
          if (validation.values.isRequiredSplit && !value) {
            return false;
          }
          return true;
        }),
        
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (valuesTemp.commissionType && valuesTemp.commissionValue === 0) {
        valuesTemp.commissionValue = 0;
      }

      if (valuesTemp.positionId !== Constants.supervisorId) {
        valuesTemp.extraCommissionValue = 0;
      }

      validateNumberFieldWithSeparator(valuesTemp);

      try {
        if (addAction) {
          let resultAddSalesTarget = await addSalesTarget(valuesTemp);
          if (resultAddSalesTarget) {
            if (resultAddSalesTarget.message) {
              setConfirmSalesTargetSubmissionMessage(resultAddSalesTarget.message);
              setShowConfirmSalesTargetSubmission(true);
            }
          }
        } else {
          setShowApplicableToFutureMonths(true);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnSalesTargetName = !addAction && viewAction;

  useEffect(() => {
    async function fetchSalesTargetDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.commissionType),
        getStaticReferenceWithType(Constants.commissionEarnType),
        getPositionDropdown("1")
      ];
      if (id && !addAction) {
        aryAPI.push(getSalesTargetWithId(id));
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setSalesTargetDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateSalesTarget], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateSalesTarget], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageSalesTarget], true)) {
        return;
      }
    }

    if (viewAction && blnPermissionUpdateSalesTarget) {
      burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.salesTarget}/edit/${id}`) } })
    }

    setMonthList(getMonths());
    setYearList(getYears());
    fetchSalesTargetDetailAPI();

    return (() => {
      if (id && !addAction) {
        setSalesTargetDetail(undefined);
      }
    })
  }, []);
  
  useEffect(() => {
    if (salesTargetDetail) {
      validation.setFieldValue("from", returnThousandSeperatorInString(salesTargetDetail.from.toString()));
      validation.setFieldValue("to", returnThousandSeperatorInString(salesTargetDetail.to.toString()));
      if(salesTargetDetail.commissionType === Constants.byAmount){
        validation.setFieldValue("commissionValue", returnThousandSeperatorInString(salesTargetDetail.commissionValue.toString()));
      }
    }
  }, [salesTargetDetail])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onClickAddSalesTarget = async (isConfirmSalesTargetSubmission: boolean) => {
    validation.setSubmitting(true);
    try {
      let valuesTemp : any = _.cloneDeep(validation.values);
      validateNumberFieldWithSeparator(valuesTemp);
      valuesTemp = { ...valuesTemp, isConfirmSalesTargetSubmission: isConfirmSalesTargetSubmission}
      await addSalesTarget(valuesTemp);
      setShowConfirmSalesTargetSubmission(false);
    }
    finally {
      validation.setSubmitting(false);
    }
  }

  const onClickUpdateSalesTarget = async (isApplicableToFutureMonths: boolean) => {
    validation.setSubmitting(true);
    try {
      let valuesTemp : any = _.cloneDeep(validation.values);
      validateNumberFieldWithSeparator(valuesTemp);
      valuesTemp = { ...valuesTemp, isApplicableToFutureMonths: isApplicableToFutureMonths}
      await updateSalesTarget(valuesTemp);
      setShowApplicableToFutureMonths(false);
    }
    finally {
      validation.setSubmitting(false);
    }
  }

  const validateNumberFieldWithSeparator = (salesTargetDetailTemp: any) => {
    if(salesTargetDetailTemp.from.includes(",")){
      let fromTemp = removeThousandSeperator(salesTargetDetailTemp.from);
      salesTargetDetailTemp.from = fromTemp;
    }
    if(salesTargetDetailTemp.to.includes(",")){
      let toTemp = removeThousandSeperator(salesTargetDetailTemp.to);
      salesTargetDetailTemp.to = toTemp;
    }
    if(salesTargetDetailTemp.commissionType === Constants.byAmount){
      if(salesTargetDetailTemp.commissionValue.includes(",")){
        let commissionValueTemp = removeThousandSeperator(salesTargetDetailTemp.commissionValue);
        salesTargetDetailTemp.commissionValue = commissionValueTemp;
      }
    }
    return salesTargetDetailTemp;
  }

  const removeThousandSeperator = (value) =>{
    return Number(replaceAllStringWithArray(value, [','], ""));
  }
  
  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                {
                  blnSalesTargetName &&
                  <>
                    <LineBreakWithTittle
                      paddingBottom="0px"
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "SalesTarget" }) })}
                      h4Title />
                    <SingleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "SalesTargetName" })}
                        name="name"
                        className="mb-3 mt-4"
                        type="text"
                        disabled={disabledFieldInput || blnSalesTargetName}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                  </>
                }
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"year"}
                      title={intl.formatMessage({ id: "Year" })}
                      specifyReturnFieldName={[{ "field": "year", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      options={yearList}
                      disabled={disabledFieldInput || !addAction}
                      initialLabel={!addAction ? `${validation.values.year}` : undefined}
                      initialValue={!addAction ? validation.values.year : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"month"}
                      title={intl.formatMessage({ id: "Month" })}
                      specifyReturnFieldName={[{ "field": "month", "value": "value" }]}
                      labelField={"label"}
                      valueField={"value"}
                      placeholder={""}
                      options={monthList}
                      disabled={disabledFieldInput || !addAction}
                      initialLabel={!addAction ? `${moment(validation.values.month, "MM").format("MMM")}` : undefined}
                      initialValue={!addAction ? validation.values.month : undefined}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "From" })}
                      name="from"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.from){
                          let fromTemp:any = replaceAllStringWithArray(validation.values.from, [','], "");
                          const formattedValue = returnThousandSeperatorInString(fromTemp);
                          validation.setFieldValue("from", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "To" })}
                      name="to"
                      type="text"
                      blnNumberOnlyWithDecimal={true}
                      disabled={disabledFieldInput || viewAction}
                      onBlurFunction={() => {
                        if(validation.values.to){
                          let toTemp:any = replaceAllStringWithArray(validation.values.to, [','], "");
                          const formattedValue = returnThousandSeperatorInString(toTemp);
                          validation.setFieldValue("to", formattedValue.toString())
                        }
                      }}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent>
                    <DropDownWithTitle
                      name={"commissionType"}
                      title={intl.formatMessage({ id: "CommissionType" })}
                      specifyReturnFieldName={[{ "field": "commissionType", "value": "displayValue" }]}
                      labelField={"displayValue"}
                      valueField={"displayValue"}
                      options={commissionTypeList}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={!addAction ? validation.values.commissionType : undefined}
                      onChangeFunction={()=> {
                        setRefreshCommissionValue(true);
                        validation.setFieldValue("commissionValue", "");
                        setTimeout(() => {
                          setRefreshCommissionValue(false);
                        }, 100);
                      }}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent>
                    {
                      refreshCommissionValue
                      ?
                      <Loading/>
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "CommissionValue" })}
                        name="commissionValue"
                        type="text"
                        blnNumberOnlyWithDecimal={validation.values.commissionType === Constants.byAmount ? true : false}
                        disabled={disabledFieldInput || viewAction}
                        onBlurFunction={() => {
                          if(validation.values.commissionValue && validation.values.commissionType === Constants.byAmount){
                            let commissionValueTemp:any = replaceAllStringWithArray(validation.values.commissionValue, [','], "");
                            const formattedValue = returnThousandSeperatorInString(commissionValueTemp);
                            validation.setFieldValue("commissionValue", formattedValue.toString())
                          }
                        }}
                        validationRequired={true}
                        validation={validation}
                      />
                    }
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"commissionEarnType"}
                    title={intl.formatMessage({ id: "CommissionEarnType" })}
                    specifyReturnFieldName={[{ "field": "commissionEarnType", "value": "displayValue" }]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    options={commissionEarnType}
                    blnValueWithNewSpace={true}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={!addAction ? validation.values.commissionEarnType : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"positionId"}
                    title={intl.formatMessage({ id: "Position" })}
                    specifyReturnFieldName={[{ "field": "positionName", "value": "name" }, { "field": "positionId", "value": "id" }]}
                    labelField={"name"}
                    valueField={"id"}
                    options={positionDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialLabel={!addAction ? validation.values.positionName : undefined}
                    initialValue={!addAction ? validation.values.positionId : undefined}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                {
                  validation.values.positionId === Constants.supervisorId
                  &&
                  <SingleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ExtraCommission" })}
                      name="extraCommissionValue"
                      type="number"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                }
                <Row>
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "RequiredSplit" })}
                      name="isRequiredSplit"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                  </DoubleColumnRowParent>
                  {
                    validation.values.isRequiredSplit
                    &&
                    <DoubleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "SplitPortion" })}
                        name="splitPortion"
                        type="number"
                        disabled={disabledFieldInput || viewAction}
                        validationRequired={true}
                        validation={validation}
                      />
                    </DoubleColumnRowParent>
                  }
                </Row>
                <Row>
                  {
                    addAction
                    &&
                    <DoubleColumnRowParent>
                      <GeneralInput
                        title={intl.formatMessage({ id: "ApplicableToFutureMonths" })}
                        name="isApplicableToFutureMonths"
                        type="checkbox"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                        childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                    </DoubleColumnRowParent>
                  } 
                  <DoubleColumnRowParent>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Active" })}
                      name="isActive"
                      type="checkbox"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                      childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                  </DoubleColumnRowParent>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
      {
        showApplicableToFutureMonths
        &&
        <ActionPromptModal 
          title={intl.formatMessage({ id: "UpdateSalesTargetApplicableToFutureMonths"})}
          cancelActionTitle={intl.formatMessage({ id: "No"})}
          showPrompt={showApplicableToFutureMonths}
          setShowPrompt={setShowApplicableToFutureMonths}
          blnShowCloseButton={true}
          onPrimaryClick={()=> {onClickUpdateSalesTarget(true)}}
          onCancelClick={()=> {onClickUpdateSalesTarget(false)}}/>
      }
      {
        showConfirmSalesTargetSubmission
        &&
        <ActionPromptModal 
          title={confirmSalesTargetSubmissionMessage}
          cancelActionTitle={intl.formatMessage({ id: "No"})}
          showPrompt={showConfirmSalesTargetSubmission}
          setShowPrompt={setShowConfirmSalesTargetSubmission}
          blnShowCloseButton={true}
          onPrimaryClick={()=> {onClickAddSalesTarget(true)}}/>
      }
    </div>
  );
};

export default observer(SalesTargetDetail);
