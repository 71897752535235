import { PaginationRequestBody } from "../models/pagination";
import { PurchaseDeliverOrderAddObject, PurchaseDeliverOrderDetailObject, PurchaseDeliverOrderListObject, PurchaseDeliverOrderUpdateObject } from "../models/purchaseDeliverOrder";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class PurchaseDeliverOrderStore {
  purchaseDeliverOrderList: PurchaseDeliverOrderListObject[] = [];
  purchaseDeliverOrderDetail: PurchaseDeliverOrderDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.purchaseDeliverOrderList = [];
    this.purchaseDeliverOrderDetail = undefined;
  }

  setPurchaseDeliverOrderDetail = (countryDetail: PurchaseDeliverOrderDetailObject | undefined) => {
    this.purchaseDeliverOrderDetail = countryDetail;
  }

  getPurchaseDeliverOrder = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultPurchaseDeliverOrders = await agent.PurchaseDeliverOrder.purchaseDeliverOrderList(PaginationRequestBody);
      runInAction(() => {
        this.purchaseDeliverOrderList = resultPurchaseDeliverOrders.data;
        store.commonStore.setTotalItem(resultPurchaseDeliverOrders.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchaseDeliverOrderList = [];
    }
  };

  getPurchaseDeliverOrderWithId = async (id: string) => {
    try{
      const resultPurchaseDeliverOrderDetail = await agent.PurchaseDeliverOrder.purchaseDeliverOrderDetail(id);
      runInAction(() => {
        this.purchaseDeliverOrderDetail = resultPurchaseDeliverOrderDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.purchaseDeliverOrderDetail = undefined;
    }
  }

  addPurchaseDeliverOrder = async (purchaseDeliverOrderRequestBody: PurchaseDeliverOrderAddObject) => {
    try{
      await agent.PurchaseDeliverOrder.addPurchaseDeliverOrder(purchaseDeliverOrderRequestBody);
      store.commonStore.setSuccessMessage(`PurchaseDeliverOrderAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePurchaseDeliverOrder = async (purchaseDeliverOrderRequestBody: PurchaseDeliverOrderUpdateObject) => {
    try{
      await agent.PurchaseDeliverOrder.updatePurchaseDeliverOrder(purchaseDeliverOrderRequestBody);
      store.commonStore.setSuccessMessage(`PurchaseDeliverOrderUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePurchaseDeliverOrder  = async (id: string, name: string) => {
    try {
      await agent.PurchaseDeliverOrder.deletePurchaseDeliverOrder(id);
      store.commonStore.setSuccessMessage(`PurchaseDeliverOrderDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
