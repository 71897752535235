import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import { checkPermission, getBrandName, IncludesLocationPathName, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Form,
  Label,
  Table,
} from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import moment from "moment";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

const PaySlipDetail = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { payslipStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    windowSize,
    setErrorMessage,
    setSuccessMessage,
    setLoading
  } = commonStore;
  const { payslipDetail, setPayslipDetail, getPayslipWithId } = payslipStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "PaySlip" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "PaySlip" }), urlPath: RoutesList.payslip }];
  const displaySmallView = Constants.innerWidthCompare > windowSize.innerWidth ? true : false;
  const payslipFullDetail = payslipDetail || {
    brandId: "",
    brandName: "",
    businessEntityId: "",
    businessEntityName: "",
    branchId: "",
    branchName: "",
    employeeId: "",
    employeeFullName: "",
    preferredName: "",
    month: 0,
    year: 0,
    basic: 0,
    grossPay: 0,
    additionAmount: 0,
    addition: "",
    deductionAmount: 0,
    deduction: "",
    pcB_Amount: 0,
    epF_Employee_Percentage: 0,
    epF_Employee_Amount: 0,
    epF_Employer_Percentage: 0,
    epF_Employer_Amount: 0,
    eiS_Employee_Amount: 0,
    eiS_Employer_Amount: 0,
    socsO_Employee_Amount: 0,
    socsO_Employer_Amount: 0,
    netPay: 0,
    status: "",
  }
  const [additionHeader, setAdditionHeader] = useState<string[]>([]);
  const [additionContent, setAdditionContent] = useState<number[]>([]);
  const [deductionHeader, setDeductionHeader] = useState<string[]>([]);
  const [deductionContent, setDeductionContent] = useState<number[]>([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: async (values) => { },
  });

  const disabledFieldInput =
    validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchPayslipDetailAPI() {
    	setLoading(true);
      if (id && !addAction) {
        let resultPaySlipTemp = await getPayslipWithId(id);
        if (resultPaySlipTemp) {
          convertAdditionAndDeduction(resultPaySlipTemp, Constants.addition, setAdditionHeader, setAdditionContent)
          convertAdditionAndDeduction(resultPaySlipTemp, Constants.deduction, setDeductionHeader, setDeductionContent)
        }
      }
    	setLoading(false);
    }
    if (addAction) {
    	setPayslipDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdatePayslip], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreatePayslip], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManagePayslip], true)) {
        return;
      }
    }

    fetchPayslipDetailAPI();
  }, []);

  const convertAdditionAndDeduction = (resultPaySlipTemp, fieldName, setHeader, setContent) => {
    let fieldTemp = JSON.parse(resultPaySlipTemp[fieldName])
    let headerTemp : string[]= [];
    let contentTemp : number[] = [];
    fieldTemp.map((valueTemp: any)=> {
      headerTemp.push(valueTemp.Name)
      contentTemp.push(valueTemp.Amount)
    })
    setHeader(headerTemp)
    setContent(contentTemp)
  }

  const displayCustomRowData = (label, value) => {
    //770 handling margin
    return (
      <Row className={`${Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : ""}`}>
        <Col sm={12} md={6} xl={6}>
          <Label className={`margin-bottom-0`} >
            {label} :
          </Label>
        </Col>
        <Col sm={12} md={6} xl={6}>
          <Label className="margin-bottom-0">
            {value}
          </Label>
        </Col>
      </Row>
    )
  }

  const displayTableHeader = (mainTitle: string, subTitle: string,) => {
    return (
      <Col xl={displaySmallView ? 12 : 6}>
        <Row style={{ height: '100%' }}>
          <Col xs={6} md={6} xl={8} className="payslip-table-border padding-12px">
            <Label className="margin-0">{mainTitle}</Label>
          </Col>
          <Col xs={6} md={6} xl={4} className="payslip-table-border padding-12px flex-right">
            <Label className="margin-0">{subTitle}</Label>
          </Col>
        </Row>
      </Col>
    )
  }

  const displayTableContent = (aryMainContent: any[], arySubContent: any[]) => {
    return (
      <Col xl={displaySmallView ? 12 : 6}>
        <Row style={{ height: '100%' }}>
          <Col xs={6} md={6} xl={8} className="payslip-table-border padding-12px">
            {
              aryMainContent.map((valueMainContent) => (
                <div>
                  <Label className="margin-0">{valueMainContent}</Label>
                  <br />
                </div>
              ))
            }
          </Col>
          <Col xs={6} md={6} xl={4} className="payslip-table-border padding-12px">
            {
              arySubContent.map((valueSubContent) => (
                <div className="flex-right">
                  <Label className="margin-0">{valueSubContent}</Label>
                  <br />
                </div>
              ))
            }
          </Col>
        </Row>
      </Col>
    )
  }

  const displayEmptyContent = () => {
    return (
      <Col xl={6} className="payslip-table-border padding-12px">
        <Row style={{ height: '100%' }}>
        </Row>
      </Col >
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "PaySlip" }) })}
                  h4Title
                />
                <div className="margin-top-24">
                  <Row>
                    <Col sm={6} xl={6}>
                      <strong>{getBrandName()}</strong>
                      <br />
                      One Doc
                      <div className="pl-0 col-sm-8 col-12 mb-0">
                        No. A-1-31, IOI Boulevard, Jalan Kenari 7, Bandar Puchong
                        Jaya, 47170 Puchong, Selangor
                      </div>
                    </Col>
                    <Col sm={6} xl={6} className={Constants.innerWidthCompare770 > windowSize.innerWidth ? "margin-top-16" : "flex-right"}>
                      <div className="">
                        <h5 className="text-primary">{payslipFullDetail.status}</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row className="payslip-table-border margin-top-16 padding-16px margin-0">
                    <Col xs={12} sm={6} xl={6}>
                      {displayCustomRowData(`${intl.formatMessage({ id: "Name" }).toUpperCase()}`, payslipFullDetail.employeeFullName)}
                      {displayCustomRowData(`${intl.formatMessage({ id: "NRICNo" })}`, "888888-88-8888")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "EMPLOYEENo" })}`, "A88888")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "Position" }).toUpperCase()}`, "BOSSKU")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "Bank" }).toUpperCase()}`, "SUPER RICH BANK")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "BANKACCNo" })}`, "888888-88-8888")}
                    </Col>
                    <Col xs={12} sm={6} xl={6}>
                      {displayCustomRowData(`${intl.formatMessage({ id: "Date" }).toUpperCase()}`, moment(new Date).format(Constants.displayDateAndTimeFormat))}
                      {displayCustomRowData(`${intl.formatMessage({ id: "Period" }).toUpperCase()}`, "-")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "Department" }).toUpperCase()}`, "SALES DEPARTMENT")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "EPFNo" })}`, "T888888")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "SocsoNo" })}`, "A888888")}
                      {displayCustomRowData(`${intl.formatMessage({ id: "IncomeTaxNo" })}`, "888888-88-8888")}
                    </Col>
                  </Row>
                  {
                    Constants.innerWidthCompare > windowSize.innerWidth
                      ?
                      <>
                        <Row className="margin-0 margin-top-8">
                          <Col xl={4}>
                            {displayTableHeader(`${intl.formatMessage({ id: "Income" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                            {displayTableContent(_.concat(["BASIC SALARY", "OT"], additionHeader), _.concat([payslipFullDetail.basic, "-"], additionContent))}
                            {displayTableHeader(`${intl.formatMessage({ id: "Total" }).toUpperCase()}`, returnPriceWithCurrency(payslipFullDetail.grossPay))}
                          </Col>
                          <Col xl={4} className="margin-top-8">
                            {displayTableHeader(`${intl.formatMessage({ id: "Contribution" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                            {displayTableContent([`${intl.formatMessage({ id: "PCB" }).toUpperCase()}`, `${intl.formatMessage({ id: "EPF" }).toUpperCase()}`, `${intl.formatMessage({ id: "SOCSO" }).toUpperCase()}`, `${intl.formatMessage({ id: "EIS" }).toUpperCase()}`], [payslipFullDetail.pcB_Amount, payslipFullDetail.epF_Employer_Amount, payslipFullDetail.socsO_Employer_Amount, payslipFullDetail.eiS_Employer_Amount])}
                            {displayTableHeader(`${intl.formatMessage({ id: "TotalContribution" })}`, returnPriceWithCurrency(payslipFullDetail.additionAmount))}
                          </Col>
                          <Col xl={4} className="margin-top-8">
                            {displayTableHeader(`${intl.formatMessage({ id: "Deduction" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                            {displayTableContent(deductionHeader, deductionContent)}
                            {displayTableHeader(`${intl.formatMessage({ id: "TotalDeduction" })}`, returnPriceWithCurrency(payslipFullDetail.deductionAmount))}
                          </Col>
                        </Row>
                      </>
                      :
                      <>
                        <Row className="margin-0" style={{ backgroundColor: "#eff2f7" }}>
                          {displayTableHeader(`${intl.formatMessage({ id: "Income" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                          {displayTableHeader(`${intl.formatMessage({ id: "Deduction" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                        </Row>
                        <Row className="margin-0">
                          {displayTableContent(_.concat(["BASIC SALARY", "OT"], additionHeader), _.concat([payslipFullDetail.basic, "-"], additionContent))}
                          {displayTableContent(deductionHeader, deductionContent)}
                        </Row>
                        <Row className="margin-0" style={{ backgroundColor: "#eff2f7" }}>
                          {displayTableHeader(`${intl.formatMessage({ id: "Contribution" }).toUpperCase()}`, `${intl.formatMessage({ id: "Amount" }).toUpperCase()} (${returnCurrency()})`)}
                          {displayEmptyContent()}
                        </Row>
                        <Row className="margin-0">
                          {displayTableContent([`${intl.formatMessage({ id: "PCB" }).toUpperCase()}`, `${intl.formatMessage({ id: "EPF" }).toUpperCase()}`, `${intl.formatMessage({ id: "SOCSO" }).toUpperCase()}`, `${intl.formatMessage({ id: "EIS" }).toUpperCase()}`], [payslipFullDetail.pcB_Amount, payslipFullDetail.epF_Employer_Amount, payslipFullDetail.socsO_Employer_Amount, payslipFullDetail.eiS_Employer_Amount])}
                          {displayEmptyContent()}
                        </Row>
                        <Row className="margin-0">
                          {displayTableHeader(`${intl.formatMessage({ id: "Total" }).toUpperCase()}`, returnPriceWithCurrency(payslipFullDetail.grossPay))}
                          {displayTableHeader(`${intl.formatMessage({ id: "TotalDeduction" }).toUpperCase()}`, returnPriceWithCurrency(payslipFullDetail.deductionAmount))}
                        </Row>
                      </>
                  }
                  <Row className="margin-0">
                    <Col xs={6} md={6} xl={10} className="payslip-table-border padding-12px flex-right">
                      <Label className="margin-0">{intl.formatMessage({ id: "NettPay" }).toUpperCase()}</Label>
                    </Col>
                    <Col xs={6} md={6} xl={2} className="payslip-table-border padding-12px flex-right">
                      <Label className="margin-0">{returnPriceWithCurrency(payslipFullDetail.netPay)}</Label>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(PaySlipDetail);
