import React from 'react'
interface Props {
  title: string;
  paddingBottom?: string;
  color?: string;
  className?: string;
  h4Title?: boolean;
  blnSkipMarginTop?: boolean;
  titleSize?: string;
}

export default function LineBreakWithTittle(props: Props) {
  return (
    <div className={`flex-direction-row ${!props.h4Title && !props.blnSkipMarginTop && "mt-4"} ${props.className}`} style={{paddingBottom: props.paddingBottom || '20px', alignItems: 'center'}}>
      {
        props.h4Title
        ?
        <div className="h4 margin-bottom-0" style={{color: '#556ee6'}}>{`${props.title}`}</div>
        :
        <span style={{color: props.color || '#556ee6', fontSize: props.titleSize ? props.titleSize : '14px'}}>{props.title}</span>
      }
      <div style={{flex: 1, height: '1px', backgroundColor: '#ccc', marginLeft: '10px'}}/>
    </div>
  )
}
