import { PaginationRequestBody } from "../models/pagination";
import { SalesAdjustmentListObject, SalesAdjustmentAddObject, SalesAdjustmentDetailObject, SalesAdjustmentUpdateObject } from "../models/salesAdjustment";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class SalesAdjustmentStore {
  salesAdjustmentList: SalesAdjustmentListObject[] = [];
  salesAdjustmentDetail: SalesAdjustmentDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.salesAdjustmentList = [];
    this.salesAdjustmentDetail = undefined;
  }

  setSalesAdjustmentDetail = (salesAdjustmentDetail: SalesAdjustmentDetailObject | undefined) => {
    this.salesAdjustmentDetail = salesAdjustmentDetail;
  }
  
  getSalesAdjustment = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSalesAdjustment = await agent.SalesAdjustment.salesAdjustmentList(PaginationRequestBody);
      runInAction(() => {
        this.salesAdjustmentList = resultSalesAdjustment.data;
        store.commonStore.setTotalItem(resultSalesAdjustment.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesAdjustmentList = [];
    }
  }

  getSalesAdjustmentWithId = async (id: string) => {
    try{
      const resultSalesAdjustmentDetail = await agent.SalesAdjustment.salesAdjustmentDetail(id);
      runInAction(() => {
        this.salesAdjustmentDetail = resultSalesAdjustmentDetail;
      });
      return Promise.resolve(resultSalesAdjustmentDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesAdjustmentDetail = undefined;
    }
  }

  addSalesAdjustment = async (salesAdjustmentRequestBody: SalesAdjustmentAddObject) => {
    try{
      await agent.SalesAdjustment.addSalesAdjustment(salesAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`SalesAdjustmentAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateSalesAdjustment = async (salesAdjustmentRequestBody: SalesAdjustmentUpdateObject) => {
    try{
      await agent.SalesAdjustment.updateSalesAdjustment(salesAdjustmentRequestBody);
      store.commonStore.setSuccessMessage(`SalesAdjustmentUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteSalesAdjustment = async (id: string, name: string) => {
    try {
      await agent.SalesAdjustment.deleteSalesAdjustment(id);
      store.commonStore.setSuccessMessage(`SalesAdjustmentDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}