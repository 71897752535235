import React from "react"
import ReactApexChart from "react-apexcharts"
import { returnCurrency, returnPriceWithCurrency } from "../../../common/function/function";
import { DatumObject, RowDataObject } from "../../../models/dashboard";

interface Props {
	data?: DatumObject[];
  rowData?: RowDataObject;
  displayCurrency?: boolean;
}

const DashedLine = (props: Props) => {
  const options : any = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#f46a6a", "#34c38f"],
    dataLabels: { enabled: !1 },
    stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
    // title: { text: "Page Statistics", align: "left" },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: props.rowData?.categories || [],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return `${props.displayCurrency ? returnCurrency() : e}`
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return `${props.displayCurrency ? returnCurrency() : e}`
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return `${props.displayCurrency ? returnCurrency() : e}`
            },
          },
        },
      ],
    },
    grid: { borderColor: "#f1f1f1" },
  }

  return (
    <ReactApexChart
      options={options}
      series={props.data || []}
      type="line"
      height="220"
    />
  )
}

export default DashedLine