import React, { useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import MyButton from "../../../app/components/form/MyButton";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import _ from "lodash";
import { Constants } from "../../../app/constants/Constants";
import { UpdateCustomerSalesOrderObject } from "../../../app/models/customerOrder";
import { history } from "../../..";
import { RoutesList } from "../../../app/constants/RoutesList";

interface Props {
  showPrompt: boolean;
  setShowPrompt: Function;
  blnEdit: boolean;
  intl: any;
  validation: any;
}

export default observer(function CustomerEditOrCancelModal(props: Props) {
  //Use Store
  const { commonStore, customerSalesOrderStore } = useStore();
  const { successMessage, loading, setSuccessMessage } = commonStore;
  const { updateCustomerSalesOrder } = customerSalesOrderStore;
  const [loadingEditOrCancelCustomerSalesOrder, setLoadingEditOrCancelCustomerSalesOrder] = useState(false);
  const [editOrCancelCustomerSalesOrderNote, setEditOrCancelCustomerSalesOrderNote] = useState({note: ""});
  
  const hideModal = () => {
    props.setShowPrompt(false);
  }

	return (
		<Modal
      isOpen={props.showPrompt}
      toggle={() => {
        if (!Boolean(successMessage) && !loadingEditOrCancelCustomerSalesOrder) {
          hideModal();
        }
      }}
      size="lg"
      centered>
      {
        <div className="padding-horizontal-20px">
          <div className="modal-header">
            <div>
              <h5 className="modal-title margin-top-0">{props.intl.formatMessage({ id: props.blnEdit ? "CancelAndRecreateCustomerSalesOrder" : "CancelCustomerSalesOrder" })} ({`${props.validation.values.customerSalesOrderNo}`})</h5>
            </div>
            {
              !Boolean(successMessage) && !loadingEditOrCancelCustomerSalesOrder && 
              <button
                type="button"
                onClick={() => { hideModal(); }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            }
          </div>
          <div className="modal-body">
            <GeneralTextAreaForModal
              title={props.intl.formatMessage({ id: "Note" })}
              name="note"
              row={5}
              validationRequired={false}
              field={editOrCancelCustomerSalesOrderNote}
              setFieldValue={setEditOrCancelCustomerSalesOrderNote}
              disabled={loadingEditOrCancelCustomerSalesOrder || Boolean(successMessage)} />
            <p className="font-size-14 error_require_text">
              {props.intl.formatMessage({ id: props.blnEdit ? "CancelAndRecreateCustomerSalesOrderAlert" : "CancelCustomerSalesOrderAlert" })}
              <br/>
              <br/>
              {props.blnEdit && props.intl.formatMessage({id: "SystemNavigatePOS"})}
            </p>
          </div>
          {
            !Boolean(successMessage)
            &&
            <div className="mb-3 flex-right flex-direction-row">
              {/* <MyButton
                type="button"
                class="btn btn-back margin-right-8"
                style={{ width: '20%' }}
                content={props.intl.formatMessage({ id: "Back" })}
                onClick={() => props.setShowPrompt(false)}
                disable={loadingEditOrCancelCustomerSalesOrder}
                loading={loadingEditOrCancelCustomerSalesOrder}
              /> */}
              <MyButton
                type="button"
                class="btn btn-success"
                style={{ width: '20%' }}
                content={props.intl.formatMessage({ id: "Confirm" })}
                onClick={async () => {
                  setLoadingEditOrCancelCustomerSalesOrder(true);
                  let updateCustomerSalesOrderRequestBody = _.cloneDeep({
                    id: props.validation.values.id,
                    note: editOrCancelCustomerSalesOrderNote.note,
                    workflowAction: props.blnEdit ? Constants.submit : Constants.cancel
                  })
                  let resultAPI = await updateCustomerSalesOrder(updateCustomerSalesOrderRequestBody)
                  if (resultAPI && props.blnEdit) {
                    setTimeout(() => {
                      if (resultAPI) {
                        if (resultAPI.cartId) {
                          setSuccessMessage("");
                          history.push({
                            pathname: `/${RoutesList.pos}`,
                            state: {
                              detail: {
                                customerCartId: resultAPI.cartId
                              }
                            }
                          });
                        }
                      }
                      props.setShowPrompt(false);
                    }, Constants.dismissSuccessMessage)
                  }
                  setLoadingEditOrCancelCustomerSalesOrder(false);
                }}
                disable={loadingEditOrCancelCustomerSalesOrder || Boolean(successMessage)}
                loading={loadingEditOrCancelCustomerSalesOrder}
              />
            </div>
          }
        </div>
      }
    </Modal>
	);
})
