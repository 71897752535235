import { PaginationRequestBody } from "../models/pagination";
import { ContractLiabilityBenchmarkListObject, ContractLiabilityBenchmarkAddObject, ContractLiabilityBenchmarkUpdateObject, ContractLiabilityBenchmarkDetailObject, ContractLiabilityBenchmarkDeleteObject } from "../models/contractLiabilityBenchmark";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class ContractLiabilityBenchmarkStore {
  contractLiabilityBenchmarkList: ContractLiabilityBenchmarkListObject[] = [];
  contractLiabilityBenchmarkDetail: ContractLiabilityBenchmarkDetailObject | undefined = undefined;
  contractLiabilityBenchmarkPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.contractLiabilityBenchmarkList = [];
    this.contractLiabilityBenchmarkDetail = undefined;
    this.contractLiabilityBenchmarkPaginationParams= undefined;
  }

  setContractLiabilityBenchmarkDetail = (contractLiabilityBenchmarkDetail: ContractLiabilityBenchmarkDetailObject | undefined) => {
    this.contractLiabilityBenchmarkDetail = contractLiabilityBenchmarkDetail;
  }

  setContractLiabilityBenchmarkPaginationParams = (contractLiabilityBenchmarkPaginationParams: PaginationRequestBody | undefined) => {
    this.contractLiabilityBenchmarkPaginationParams = contractLiabilityBenchmarkPaginationParams;
  }
  
  getContractLiabilityBenchmark = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setContractLiabilityBenchmarkPaginationParams(PaginationRequestBody);
    try{
      const resultContractLiabilityBenchmark = await agent.ContractLiabilityBenchmark.contractLiabilityBenchmarkList(PaginationRequestBody);
      runInAction(() => {
        this.contractLiabilityBenchmarkList = resultContractLiabilityBenchmark.data;
        store.commonStore.setTotalItem(resultContractLiabilityBenchmark.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityBenchmarkList = [];
    }
  }

  getContractLiabilityBenchmarkWithId = async (id: string) => {
    try{
      const resultContractLiabilityBenchmarkDetail = await agent.ContractLiabilityBenchmark.contractLiabilityBenchmarkDetail(id);
      runInAction(() => {
        this.contractLiabilityBenchmarkDetail = resultContractLiabilityBenchmarkDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.contractLiabilityBenchmarkDetail = undefined;
    }
  }

  addContractLiabilityBenchmark = async (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkAddObject) => {
    try{
      await agent.ContractLiabilityBenchmark.addContractLiabilityBenchmark(contractLiabilityBenchmarkRequestBody);
      store.commonStore.setSuccessMessage(`ContractLiabilityBenchmarkAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateContractLiabilityBenchmark = async (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkUpdateObject) => {
    try{
      await agent.ContractLiabilityBenchmark.updateContractLiabilityBenchmark(contractLiabilityBenchmarkRequestBody);
      store.commonStore.setSuccessMessage(`ContractLiabilityBenchmarkUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteContractLiabilityBenchmark  = async (contractLiabilityBenchmarkRequestBody: ContractLiabilityBenchmarkDeleteObject) => {
    try {
      await agent.ContractLiabilityBenchmark.deleteContractLiabilityBenchmark(contractLiabilityBenchmarkRequestBody);
      store.commonStore.setSuccessMessage(`ContractLiabilityBenchmarkDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}