import { useEffect, useState } from "react";
import {
  Modal,
  Label,
  FormFeedback,
  Row
} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import SingleColumnRowParent from "../../../app/components/form/SingleColumnRowParent";
import { useStore } from "../../../app/stores/store";
import DropDownWithTitle from "../../../app/components/dropdown/DropDownWithTitle";
import { EmployeeListObject } from "../../../app/models/employee";
import { AddOptionsValueField } from "../../../app/common/function/function";
import { PayslipAddObject } from "../../../app/models/payslip";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";

interface Props {
  blnShow: boolean;
  setModal: Function;
  // selectedMonth: any;
  // selectedMonthValue: any;
  // selectedYear: any;
  optionYearList: any;
  optionMonthList: any;
  employeeList: EmployeeListObject[];
  addPayslip: Function;
  setErrorMessage: Function;
}

export default function GeneratePayslipModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { loading, setLoading } = commonStore;
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const optionsEmployeeList = AddOptionsValueField(props.employeeList, "preferredName", "id");
  const defaultStockOutDetails = {
    year: selectedYear,
    month: selectedMonthValue,
    employeeId: undefined
  }
  const [payslipDetails, setPayslipDetails] = useState(defaultStockOutDetails)
  const [errorState, setErrorState] = useState({
    year: false,
    month: false,
  })

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      year: false,
      month: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    setPayslipDetails(defaultStockOutDetails);
    props.setModal(false);
  }

  const onChangeYear = async (value) => {
    setPayslipDetails(valueTemp => ({
      ...valueTemp,
      year: Number(value)
    }));
    clearErrorState("year");
  }

  const onChangeMonth = (value) => {
    setPayslipDetails(valueTemp => ({
      ...valueTemp,
      month: Number(value),
    }));
    clearErrorState("month");
  }
  const onChangeEmployee = (e: any) => {
    setPayslipDetails(valueTemp => ({
      ...valueTemp,
      employeeId: e.value,
    }));
  }

  const onClickGeneratePayslip= async (payslipDetailTemp: PayslipAddObject) => {
    setLoading(true);
    let result = await props.addPayslip(payslipDetailTemp);
    setLoading(false);
    if (result.status === Constants.success) {
      //temporary solution
      window.location.reload();
    }
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          hideModal();
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "Payslip" })}</h5>
          <button
            type="button"
            onClick={() => {
              hideModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Label>
            <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Year" })}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={errorState.year ? "mb-0" : "mb-3"}
            options={props.optionYearList}
            onChange={onChangeYear}
            initialLabel={selectedYear}
            initialValue={selectedYear}
            setFieldValue={setSelectedYear}
            disabled={false}
            validationRequired={true}
          />
          {errorState.year ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Year" }) })}</FormFeedback>
          ) : null}
          <Label>
            <Label style={{ color: 'red' }} className="margin-bottom-0">{`*`}</Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Month" })}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={errorState.month ? "mb-0" : "mb-3"}
            options={props.optionMonthList}
            onChange={onChangeMonth}
            initialLabel={selectedMonth}
            initialValue={selectedMonthValue}
            setFieldLabel={setSelectedMonth}
            setFieldValue={setSelectedMonthValue}
            disabled={false}
            validationRequired={true}
          />
          {errorState.month ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Month" }) })}</FormFeedback>
          ) : null}
          <Label>
            <Label className="margin-bottom-0">{intl.formatMessage({ id: "Employee" })}</Label>
          </Label>
          <GeneralSelectDropdown
            title=""
            name=""
            className={"mb-3"}
            options={optionsEmployeeList}
            onChange={onChangeEmployee}
            initialLabel={""}
            initialValue={payslipDetails.employeeId}
            blnReturnFullValue={true}
            disabled={false}
          />

          <div className="margin-top-14 ">
            <MyButton
              type="button"
              class="btn btn-primary"
              content={intl.formatMessage({ id: "Generate" })}
              onClick={() => {
                let errorStateTemp = {
                  year: false,
                  month: false,
                };

                let blnErrorFound = false;
                if (!payslipDetails.year) {
                  errorStateTemp.year = true;
                  blnErrorFound = true;
                }
                else {
                  if (!payslipDetails.month) {
                    errorStateTemp.month = true;
                    blnErrorFound = true;
                  }
                }

                if (blnErrorFound) {
                  setErrorState(errorStateTemp)
                }
                else {
                  onClickGeneratePayslip(payslipDetails)
                  hideModal();
                }
              }}
            />
          </div>
        </div>
      </Modal>
  )
}