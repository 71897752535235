import { useState, useEffect } from "react";
import { Col, Modal, Row, Table } from "reactstrap";
import _ from 'lodash';
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { calculatePOSTotalPrice, displayOptionalProducts, displayPromotionCode, getPurchaseTypeIcon, returnCurrency, returnPriceWithCurrency } from "../../../app/common/function/function";
import { Constants } from "../../../app/constants/Constants";
import Borderline from "../../../app/components/form/BorderLine";
import { CustomerOrderDetailObject, CustomerPaymentDetailObject } from "../../../app/models/customerOrder";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { EmployeeListObject } from "../../../app/models/employee";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import moment from "moment";

interface Props {
  blnShow: boolean;
  setModal: Function;
  validation: any;
  employeeDropdownList: EmployeeListObject[];
  blnBlockPaymentSubmit: boolean;
  blnPermissionBackdateProductRedemptionNote: boolean;
  blnHideRounding?: boolean;
}

export default observer(function CustomerOrderConfimSalesModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;

  const [totalDraftPayment, setTotalDraftPayment] = useState(0);

  const blnIsPaymentBackdate = props.validation.values.isPaymentBackdate;

  const hideModal = () => {
    props.setModal(false);
  }

  useEffect(() => {
    let totalDraftPaymentTemp = 0;
    if (props.validation.values.paymentDetails && props.validation.values.paymentDetails.length > 0) {
      props.validation.values.paymentDetails.map((value) => {
        totalDraftPaymentTemp += Number(value.paidAmount)
      })
    }
    setTotalDraftPayment(totalDraftPaymentTemp);
  }, [props.validation.values.paymentDetails])

  useEffect(() => {
    if (props.validation.values.salesOrderDetails && props.validation.values.salesOrderDetails.length > 0) {
      props.validation.values.salesOrderDetails = props.validation.values.salesOrderDetails.sort((a, b) => (a.purchaseType > b.purchaseType) ? 1 : -1)
    }
  }, [props.validation.values.salesOrderDetails])
  

  const getPaymentStatus = () => {
    if (totalDraftPayment >= props.validation.values.totalPrice) {
      return <b className="font-size-13 text-success">{`(${intl.formatMessage({ id: "FullyPaid" })})`}</b>;
    }
    else if (totalDraftPayment < props.validation.values.totalPrice) {
      if (totalDraftPayment === 0) {
        return <b className="font-size-13 text-danger">{`(${intl.formatMessage({ id: "Unpaid" })})`}</b>;
      } else {
        return <b className="font-size-13 text-warning">{`(${intl.formatMessage({ id: "PartiallyPaid" })})`}</b>;
      }
    }
  }

  const displayHeaderRowData = (label, value) => {
    return (
      <tr>
        <td colSpan={4} className="text-end m-0" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end">
          {value}
        </td>
      </tr>
    )
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!props.validation.isSubmitting && !Boolean(successMessage)) {
            hideModal();
          }
        }}
        size="lg"
        centered>
        {
          <div className="padding-horizontal-20px">
            <div className="modal-header">
              <div>
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "SalesSummaryConfirmation" })} {getPaymentStatus()}</h5>
              </div>
              {
                !props.validation.isSubmitting
                &&
                !Boolean(successMessage) 
                &&
                <button
                  type="button"
                  onClick={() => { hideModal(); }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              }
            </div>
            <div className="modal-body">
              <Row style={{ fontSize: '15px' }}>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customerName"
                    type="text"
                    disabled={true}
                    validationRequired={true}
                    validation={props.validation}
                  />
                </DoubleColumnRowParent>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <DropDownWithTitleMultiSelect
                    name={"consultantIds"}
                    title={"Consultants"}
                    specifyReturnFieldName={[{ "field": "consultantIds", "value": "id" }]}
                    returnFieldWithLabel={true}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={props.employeeDropdownList}
                    disabled={true}
                    initialValue={props.validation.values.consultantIds}
                    validationRequired={true}
                    validation={props.validation} />
                </DoubleColumnRowParent>
              </Row>
              <Row>
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <p className="margin-left-8 mb-0" style={{ color: 'grey' }}>
                    {props.validation.values.customerNo} | {props.validation.values.customerMobileNo} {`${props.validation.values.tinDisplayName ? ` | ${props.validation.values.tinDisplayName}` : ""}`}
                  </p>
                </DoubleColumnRowParent>
                {
                  props.validation.values.subBrandName
                  &&
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "SubBrand" })}
                      name="subBrandName"
                      type="text"
                      disabled={true}
                      validationRequired={true}
                      validation={props.validation}
                    />
                  </DoubleColumnRowParent>
                }
              </Row>
              <Borderline />
              {
                props.blnPermissionBackdateProductRedemptionNote
                &&
                props.validation.values.isBackdate
                &&
                <>
                  <GeneralInput
                    title={intl.formatMessage({ id: "SalesOrderSubmissionDate" })}
                    name="submissionDate"
                    type="datetime-local"
                    disabled={true}
                    validation={props.validation}
                  />
                  <Borderline />
                </>
              }
              {
                props.validation.values.salesOrderDetails && props.validation.values.salesOrderDetails.length > 0 &&
                <>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({ id: "OrderedItem" })}
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-fixed">
                      <thead className="table-light">
                        <tr>
                          <th style={{ width: '30%' }}>{intl.formatMessage({ id: "Name" })}</th>
                          <th className="">{intl.formatMessage({ id: "Type" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "Quantity" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "Remark" })}</th>
                          <th className="text-end">{intl.formatMessage({ id: "Price" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props.validation.values.salesOrderDetails.map((value: CustomerOrderDetailObject, index) => (
                            <tr key={index}>
                              <td className="">
                                {getPurchaseTypeIcon(value.purchaseType)}{value.itemName} {displayPromotionCode(value.promotionCode)}
                                {displayOptionalProducts(value.serviceProductUsedTemp, intl)}
                                {value.expiredDate && <p className="margin-bottom-0" style={{fontSize: '10px'}}>{`${intl.formatMessage({id: "ExpiredOn"})} ${value.expiredDate}`}</p>}
                                {value.discountValue ? <p className="margin-bottom-0" style={{fontSize: '10px'}}>{`${intl.formatMessage({id: "With"})} ${value.discountType == Constants.amount ? returnCurrency() : ""} ${value.discountValue} ${value.discountType == Constants.percentage ? "%" : ""} ${intl.formatMessage({id: "Discount"}).toLowerCase()}`} </p> : null}
                              </td>
                              <td className="">{value.purchaseType}</td>
                              <td className="text-center">{value.count}</td>
                              <td className="text-center">{value.remark || Constants.emptyData}</td>
                              <td className="text-end">{returnPriceWithCurrency(calculatePOSTotalPrice(value.count, value.price, value.discountType, value.discountValue))}</td>
                            </tr>
                          ))
                        }
                        {displayHeaderRowData(`${intl.formatMessage({ id: "Total" })}`, returnPriceWithCurrency(props.validation.values.totalSellingPrice))}
                        {displayHeaderRowData(`${intl.formatMessage({ id: "Tax" })}`, returnPriceWithCurrency(props.validation.values.totalTax))}
                        {!props.blnHideRounding && Constants.supportMalaysiaRounding ? displayHeaderRowData(`${intl.formatMessage({ id: "Rounding" })}`, returnPriceWithCurrency(props.validation.values.totalRoundingPrice || 0)) : undefined}
                        {displayHeaderRowData(`${intl.formatMessage({ id: "SubTotal" })}`, returnPriceWithCurrency(props.validation.values.totalPrice))}
                      </tbody>
                    </Table>
                  </div>
                </>
              }
              {
                props.validation.values.paymentDetails && props.validation.values.paymentDetails.length > 0 &&
                <>
                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 font-weight-bold">
                      {intl.formatMessage({ id: "Payment" })}
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-fixed">
                      <thead className="table-light">
                        <tr>
                          {blnIsPaymentBackdate && <th className="text-danger">{intl.formatMessage({ id: "Date" })}*</th>}
                          <th >{intl.formatMessage({ id: "PaymentMethod" })}</th>
                          <th className="text-center">{intl.formatMessage({ id: "TransactionNo" })}/{intl.formatMessage({ id: "ApprovalCode" })}</th>
                          <th className="text-end">{intl.formatMessage({ id: "Amount" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          props.validation.values.paymentDetails.map((value: CustomerPaymentDetailObject, index) => (
                            <tr key={index}>
                              {blnIsPaymentBackdate && 
                                <td>
                                  <div className="">
                                    {`${moment(props.validation.values.paymentDate, Constants.textInputDateAndTimeFormat).format(Constants.displayDateFormat)}`}
                                  </div>
                                  <span className="small-text">
                                    {moment(props.validation.values.paymentDate, Constants.textInputDateAndTimeFormat).format(Constants.displayTimeFormat)}
                                  </span>
                                </td>
                              }
                              <td style={{ width: '40%' }}>
                                <p className={`margin-bottom-0`}>
                                  {`${value.paymentMethodName}`} {value.cardType ? `(${value.cardType} | ${value.last4CardDigit})` : ""}
                                </p>
                                <p className="payment-method-sub-text">{`${value?.paymentMethodOptionName ? `${value?.paymentMethodOptionName ? `${value.paymentMethodOptionName}` : ""}${value.paymentIssuerBankName ? ` - ${value.paymentIssuerBankName}` : ""}` : ""}`}</p>
                              </td>
                              <td className="text-center">{value.transactionNo || Constants.emptyData}</td>
                              <td className="text-end">{returnPriceWithCurrency(value.paidAmount)}</td>
                            </tr>
                          ))
                        }
                        <tr>
                          <td className="text-end m-0" colSpan={blnIsPaymentBackdate ? 3 : 2} style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: "Total" })}
                          </td>
                          <td className="text-end">
                            {returnPriceWithCurrency(totalDraftPayment)}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-end m-0" colSpan={blnIsPaymentBackdate ? 3 : 2} style={{ fontWeight: 'bold' }}>
                            {intl.formatMessage({ id: "OutstandingBalanceAfterPayment" })}
                          </td>
                          <td className="text-end">
                            {returnPriceWithCurrency(props.validation.values.totalPrice - totalDraftPayment)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </>
              }
              <p className="font-size-14 error_require_text mt-3">
                {intl.formatMessage({ id: "SalesSummaryConfirmationMade" })}
              </p>
            </div>
            {
              !Boolean(successMessage)
              &&
              <div className="mb-3 flex-right flex-direction-row">
                <MyButton
                  type="button"
                  class="btn btn-back margin-right-8"
                  style={{ width: '20%' }}
                  content={intl.formatMessage({ id: "Back" })}
                  onClick={() => props.setModal(false)}
                  disable={props.validation.isSubmitting || props.blnBlockPaymentSubmit}
                  loading={props.validation.isSubmitting}
                />
                <MyButton
                  type="submit"
                  class="btn btn-success"
                  style={{ width: '20%' }}
                  content={intl.formatMessage({ id: "Submit" })}
                  form={Constants.paymentOrderForm}
                  disable={props.validation.isSubmitting || props.blnBlockPaymentSubmit}
                  loading={props.validation.isSubmitting}
                />
              </div>
            }
          </div>
        }
      </Modal>
  )
})