import { useState, useEffect } from "react";
import { FormFeedback, Modal, Row } from "reactstrap";
import _ from 'lodash';
import Loading from "../../../app/components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { checkPermission, extendPackageStatusColor, getBranchId, getBranchName, getBranchUser, returnNewExpiredDate } from "../../../app/common/function/function";
import { useIntl } from "react-intl";
import MyButton from "../../../app/components/form/MyButton";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import Borderline from "../../../app/components/form/BorderLine";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import moment from "moment";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import SelectDropDownModal from "../../../app/components/modal/SelectDropDownModal";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralInputWithClickableUI from "../../../app/components/form/GeneralInputWithClickableUI";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedExtendPackageDetail: any;
  setSelectedExtendPackageDetail: Function;
  getCustomerExpiredPackage?: Function;
  requestExtendPackage?: Function;
  reviewExtendPackage?: Function;
  blnAction: any;
}

export default observer(function ExtendPackageDetailModal(props: Props) {
  //Use Store
  const intl = useIntl();
  const { customerStore, branchStore, commonStore } = useStore();
  const { customerDropdownList, getCustomerDropdown, getCustomerExpiredPackage, setCustomerDropdownList } = customerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { successMessage, setErrorMessage } = commonStore;
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const [loadingPackage, setLoadingPackage] = useState(false);
  const [customerPackageList, setCustomerPackageList] = useState<any[]>([]);
  const [expiredPackageModal, setExpiredPackageModal] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);

  const defaultRequestExtendPackageDetails = {
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: "",
    reason: "",
    requestExtendPackageDetails: []
  }
  const defaultReviewExtendPackageDetails = {
    id: "",
    remark: "",
    workflowAction: "",
  }
  const [requestExtendPackageDetails, setRequestExtendPackageDetails] = useState(defaultRequestExtendPackageDetails)
  const [reviewExtendPackageDetails, setReviewExtendPackageDetails] = useState(defaultReviewExtendPackageDetails)
  const [errorState, setErrorState] = useState({
    remark: false,
    reason: false,
  })

  const blnReviewExtendPackage = checkPermission([PermissionConstants.ReviewExtendPackage]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);

  const aryDynamicInputAndCheckBoxTabServiceTreatment: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "Package" }).toUpperCase(),
      type: "label",
      xl: "3",
      inputName: "packageName",
      inputType: "text",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "SalesOrderNo" }),
      type: "label",
      xl: "3",
      inputName: "customerSalesOrderNo",
      inputType: "text",
      linkPath: blnPermissionManageCustomerSalesOrder ? `${RoutesList.customerSalesOrder}/view/` : "",
      linkPathFieldName: "customerSalesOrderId"
    },
    {
      label: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "packageExpiredDate",
      inputType: "date",
      disabled: true
    },
    {
      label: intl.formatMessage({ id: "NewExpiredDate" }).toUpperCase(),
      type: "input",
      xl: "3",
      inputName: "newExpiredDate",
      inputType: "date",
    },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchId: "",
      branchName: "",
      customerId: "",
      customerName: "",
      reason: "",
      remark: "",
      requestExtendPackageDetails: [],
      extendPackageNo: "",
      requestedDate: "",
    },
    onSubmit: async (values) => {
    },
  });

  const hideModal = () => {
    if (blnRequest) {
      setRequestExtendPackageDetails(defaultRequestExtendPackageDetails)
      setCustomerPackageList([]);
      validation.setFieldValue("customerId", "");
      validation.setFieldValue("reason", "");
      validation.setFieldValue("requestExtendPackageDetails", []);
    }
    if (blnReview) {
      setReviewExtendPackageDetails(defaultReviewExtendPackageDetails);
      props.setSelectedExtendPackageDetail([]);
    }
    props.setModal(false);
  }

  useEffect(() => {
    async function fetchExtendPackageAPI() {
      setLoadingAPI(true)
      let aryAPI: any = [
        getBranchDropDown(),
      ];
      await Promise.all(aryAPI);
      setLoadingAPI(false);
    }

    fetchExtendPackageAPI();
  }, [])

  useEffect(() => {
    if (props.selectedExtendPackageDetail) {
      validation.setFieldValue("branchName", props.selectedExtendPackageDetail.branchName);
      validation.setFieldValue("branchId", props.selectedExtendPackageDetail.branchId);
      validation.setFieldValue("customerName", props.selectedExtendPackageDetail.customerName);
      validation.setFieldValue("customerId", props.selectedExtendPackageDetail.customerId);
      validation.setFieldValue("extendPackageNo", props.selectedExtendPackageDetail.extendPackageNo);
      validation.setFieldValue("requestedDate", moment(props.selectedExtendPackageDetail.requestedDate).format(Constants.displayDateAndTimeFormat));
      setSelectedBranchId(props.selectedExtendPackageDetail.branchId)
      if (props.selectedExtendPackageDetail.extendPackageDetails) {
        let aryTemp: any[] = [];
        props.selectedExtendPackageDetail.extendPackageDetails.map((value) => {
          aryTemp.push({
            ...value,
            packageName: `${value.packageName} (${value.customerSalesOrderNo})`,
            packageExpiredDate: moment(value.previousExpiredDate).format(Constants.displayDateFormat),
            newExpiredDate: moment(value.newExpiredDate).format(Constants.displayDateFormat),
          })
        })
        validation.setFieldValue("requestExtendPackageDetails", aryTemp);
      }
    }
  }, [props.selectedExtendPackageDetail])

  const blnProcessingStatus = props.selectedExtendPackageDetail.status == Constants.processing;
  const blnRequest = props.blnAction == Constants.request;
  const blnReview = props.blnAction == Constants.review;

  const fetchExpiredPackageWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingPackage(true);
    let indexCustomer = _.findIndex(customerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownList[indexCustomer].id,
        customerName: customerDropdownList[indexCustomer].preferredName
      }))
    }
    let resultCustomerExpiredPackageList = await getCustomerExpiredPackage({ pageNumber: 1, pageSize: pageSize, customerId: id });

    let aryPackageTemp: any[] = [];
    if (resultCustomerExpiredPackageList) {
      resultCustomerExpiredPackageList.map((valueCustomerExpiredPackageListTemp) => {
        aryPackageTemp.push({
          ...valueCustomerExpiredPackageListTemp,
          packageName: `${valueCustomerExpiredPackageListTemp.packageName} (${valueCustomerExpiredPackageListTemp.customerSalesOrderNo})`,
          packageExpiredDate: moment(valueCustomerExpiredPackageListTemp.packageExpiredDate).format(Constants.displayDateFormat),
          newExpiredDate: returnNewExpiredDate(Constants.textInputDateFormat)
        })
      })
    }
    setCustomerPackageList(aryPackageTemp);

    if (removeData) {
      validation.setFieldValue("remark", "");
      validation.setFieldValue("requestExtendPackageDetails", []);
    }
    setLoadingPackage(false);
    clearCustomerDropdownList();
  };

  const fetchCustomerWithLoading = async (id: string, removeData: boolean = true) => {
    setDisplayCustomerDropdown(false);
    let indexBranch = _.findIndex(branchDropDownList, { id: id });
    if (indexBranch > -1) {
      validation.setValues((values) => ({
        ...values,
        branchId: branchDropDownList[indexBranch].id,
        branchName: branchDropDownList[indexBranch].name
      }))
      setSelectedBranchId(branchDropDownList[indexBranch].id)
      setSelectedBranchName(branchDropDownList[indexBranch].name)
    }

    if (removeData) {
      validation.setFieldValue("customerId", "")
      validation.setFieldValue("customerName", "")
    }

    setTimeout(()=> {
      setDisplayCustomerDropdown(true);
    }, 100)
  };

  const onClickExtendExpiredPackage = async (valueAction: string) => {
    if (blnRequest) {
      let requestExtendPackageTemp = _.cloneDeep({ ...requestExtendPackageDetails, customerId: validation.values.customerId, branchId: selectedBranchId, requestExtendPackageDetails: validation.values.requestExtendPackageDetails })
      setRequestExtendPackageDetails(requestExtendPackageTemp);
      if (props.requestExtendPackage) {
        setLoadingRequest(true);
        await props.requestExtendPackage(requestExtendPackageTemp)
        setLoadingRequest(false);
      }
    }
    if (blnReview) {
      let reviewExtendPackageTemp = _.cloneDeep({ ...reviewExtendPackageDetails, id: props.selectedExtendPackageDetail.id, workflowAction: valueAction })
      setReviewExtendPackageDetails(reviewExtendPackageTemp);
      if (props.reviewExtendPackage) {
        setLoadingReview(true);
        await props.reviewExtendPackage(reviewExtendPackageTemp);
        setLoadingReview(false);
      }
    }
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingRequest && !loadingReview && !successMessage) {
            hideModal();
          }
        }}
        size="xl"
        centered>
        {
          !props.selectedExtendPackageDetail || loadingAPI
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <div className="flex-align-items-center-without-height" style={{flex:1 }}>
                  <h5 className="modal-title margin-top-0" style={{flex:1 }}>{intl.formatMessage({ id: "ModuleWithDetails" }, { moduleName: intl.formatMessage({ id: "ExtendPackage" }) })}</h5>
                  {
                    blnReview &&
                    <GeneralStatus
                    className="margin-right-20"
                    status={props.selectedExtendPackageDetail.status}
                    statusColorFunction={extendPackageStatusColor}/>
                  }
                </div>
                {
                  !loadingRequest && !loadingReview && !successMessage
                  &&
                  <button
                    type="button"
                    onClick={() => {
                      hideModal();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                }
              </div>
              <div className="modal-body">
                {
                  blnRequest
                  &&
                  <Row>
                    <DoubleColumnRowParent blnDoubleTab={true}>
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "Branch" })}
                        name="branchId"
                        className={''}
                        options={branchDropDownList}
                        labelField={"name"}
                        valueField={"id"}
                        initialLabel={selectedBranchName}
                        initialValue={selectedBranchId}
                        disabled={loadingAPI || Boolean(successMessage) || loadingCustomer || getBranchUser() || loadingRequest}
                        onChange={fetchCustomerWithLoading}
                        validationRequired={true}
                      />
                    </DoubleColumnRowParent>
                    <DoubleColumnRowParent blnDoubleTab={true}>
                      {
                        displayCustomerDropdown 
                        &&
                        <GeneralSelectDropdown
                          title={intl.formatMessage({ id: "Customer" })}
                          name="customerId"
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          className={""}
                          options={customerDropdownList}
                          labelField={"preferredName"}
                          valueField={"id"}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          disabled={loadingAPI || Boolean(successMessage) || loadingRequest || !selectedBranchId}
                          onChange={fetchExpiredPackageWithLoading}
                          validationRequired={true}
                          blnSupportCustomSearch={true}
                          onChangeCustomSearch={(value)=> {
                            fetchCustomerDropdown(value);
                          }}
                          onMenuOpenFunction={()=> {
                            clearCustomerDropdownList();
                            setLoadingCustomer(false);
                          }}
                          loadingCustomSearch={loadingCustomer}
                          setLoadingCustomSearch={setLoadingCustomer}
                        />
                      }
                    </DoubleColumnRowParent>
                  </Row>
                }
                {
                  blnReview &&
                  <>
                    <Row style={{ fontSize: '14px' }}>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "Branch" })}
                          name="branchName"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInputWithClickableUI
                          title={intl.formatMessage({ id: "Customer" })}
                          name="customerName"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                          onClickFunction={()=> {
                            if (blnPermissionManageCustomer) {
                              window.open(`/${RoutesList.customer}/view/${validation.values.customerId}`)
                            }
                          }}/>
                      </DoubleColumnRowParent>
                    </Row>
                    <Row style={{ fontSize: '14px' }}>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "ExtendPackageNo" })}
                          name="extendPackageNo"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "RequestDate" })}
                          name="requestedDate"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                    </Row>
                  </>
                }
                {
                  selectedBranchId !== ""
                  &&
                  validation.values.customerId
                  &&
                  <>{
                    loadingPackage ?
                      <Loading />
                      :
                      <>
                        <Borderline />
                        <TableWithEditableInputFields
                          name="requestExtendPackageDetails"
                          className={`tab_bar_border_top_width width-100-percentage`}
                          title={intl.formatMessage({ id: "Package" })}
                          buttonTitle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
                          blnEditable={blnRequest}
                          blnSupportWithModal={true}
                          blnBtnModalBottom={true}
                          options={customerPackageList}
                          optionsId={"packageId"}
                          setOptionsFunction={setCustomerPackageList}
                          addButtonFunction={() => setExpiredPackageModal(true)}
                          aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTabServiceTreatment}
                          data={validation.values.requestExtendPackageDetails}
                          validation={validation}
                          disabled={blnProcessingStatus || blnReview || Boolean(successMessage) || loadingRequest}
                          loading={loadingPackage} />
                        <SelectDropDownModal
                          blnShow={expiredPackageModal}
                          setModal={setExpiredPackageModal}
                          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "Package" }) })}
                          buttonTittle={intl.formatMessage({ id: "ButtonAddField" }, { field: intl.formatMessage({ id: "Package" }) })}
                          placeholder={"- Please Select -"}
                          labelField={"packageName"}
                          valueField={"packageId"}
                          options={customerPackageList}
                          setOptionsFunction={setCustomerPackageList}
                          fieldInput={validation.values.requestExtendPackageDetails}
                          fieldName={"requestExtendPackageDetails"}
                          validation={validation}
                          loading={loadingPackage} />
                        <GeneralTextAreaForModal
                          title={intl.formatMessage({ id: "Reason" })}
                          name="reason"
                          className="mt-3"
                          row={5}
                          validationRequired={true}
                          field={blnRequest ? requestExtendPackageDetails : props.selectedExtendPackageDetail}
                          setFieldValue={setRequestExtendPackageDetails}
                          disabled={blnProcessingStatus || blnReview || Boolean(successMessage) || loadingRequest}
                        />
                        {errorState.reason ? (
                          <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) })}</FormFeedback>
                        ) : null}
                      </>
                  }
                  </>
                }
                {
                  blnReview
                  &&
                  <>
                    <Borderline />
                    <GeneralTextAreaForModal
                      title={intl.formatMessage({ id: "Review" })}
                      name="remark"
                      className={errorState.remark ? "mb-0" : "mb-3"}
                      row={4}
                      validationRequired={errorState.remark}
                      field={blnProcessingStatus ? reviewExtendPackageDetails : props.selectedExtendPackageDetail}
                      setFieldValue={setReviewExtendPackageDetails}
                      disabled={loadingReview || !blnProcessingStatus || Boolean(successMessage) || !blnReviewExtendPackage} />
                    {errorState.remark ? (
                      <FormFeedback className="mb-3 font-size-12" type="invalid">{intl.formatMessage({ id: "EnterReviewWhenReject" })}</FormFeedback>
                    ) : null}
                  </>
                }
                <div className="mb-2 mt-4 flex-right">
                  {
                    blnRequest
                    &&
                    selectedBranchId !== ""
                    &&
                    validation.values.customerId
                    &&
                    !Boolean(successMessage)
                    &&
                    !loadingPackage
                    &&
                    <MyButton
                      type="submit"
                      class="btn btn-success"
                      style={{ width: '20%' }}
                      content={Constants.submit}
                      onClick={() => {
                        const valuesTemp: any = _.cloneDeep(validation.values);
                        if (valuesTemp.requestExtendPackageDetails) {
                          if (valuesTemp.requestExtendPackageDetails.length < 1) {
                            setErrorMessage("Please select at least one Expired Package to extend")
                            return;
                          }
                          let errorStateTemp = {
                            remark: false,
                            reason: false,
                          };
                          let blnErrorFound = false;
                          if (!requestExtendPackageDetails.reason) {
                            errorStateTemp.reason = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickExtendExpiredPackage("");
                          }
                        }
                      }}
                      disable={loadingRequest || blnProcessingStatus || Boolean(successMessage)}
                      loading={loadingRequest}
                    />
                  }
                  {
                    blnProcessingStatus
                    &&
                    blnReviewExtendPackage
                    &&
                    !Boolean(successMessage)
                    &&
                    <>
                      <MyButton
                        type="button"
                        class="btn btn-danger margin-right-8"
                        style={{ width: '15%' }}
                        content={Constants.reject}
                        onClick={() => {
                          let errorStateTemp = {
                            remark: false,
                            reason: false,
                          };
                          let blnErrorFound = false;
                          if (!reviewExtendPackageDetails.remark) {
                            errorStateTemp.remark = true;
                            blnErrorFound = true;
                          }
                          if (blnErrorFound) {
                            setErrorState(errorStateTemp)
                          }
                          else {
                            onClickExtendExpiredPackage(Constants.reject);
                          }
                        }}
                        disable={loadingReview || !blnProcessingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                      <MyButton
                        type="submit"
                        class="btn btn-success"
                        style={{ width: '15%' }}
                        content={Constants.approve}
                        onClick={() => {
                          onClickExtendExpiredPackage(Constants.approve);
                        }}
                        disable={loadingReview || !blnProcessingStatus || Boolean(successMessage)}
                        loading={loadingReview}
                      />
                    </>
                  }
                </div>
              </div>
            </div>
        }
      </Modal>
  )
})
