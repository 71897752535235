import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, getBranchId, getBranchName, getBranchUser, getMonths } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import moment from "moment";
import { ObjectForDropdown } from "../../../app/models/common";
import { Link } from "react-router-dom"

const CustomerBirthday = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerBirthdayList, getCustomerBirthdayList, setCustomerBirthdayList, exportCustomerBirthday } = reportCustomerStore;
  const { isEligibleAllBranch, branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setMonthList(getMonths());

    async function fetchCollectionsSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setCustomerBirthdayList([]);
    })
  }, [])

  const fetchCustomerBirthdayWithLoading = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportCustomerBirthday({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, month: selectedMonthValue })
    }
    else { 
      setCurrentPage(1);
      await getCustomerBirthdayList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, month: selectedMonthValue });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  const CollectionsSummaryDetailsColumns = [
    {
      dataField: "customerId", //field name from array to display
      text: "customerId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "birthday",
      text: intl.formatMessage({ id: "Birthday" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {moment(row.birthday).format(Constants.displayDateFormat)}
        </div>
      ),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerBirthday" }) })}
        title={intl.formatMessage({ id: "CustomerBirthday" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchCustomerBirthdayWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Customer-Birthday.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
          <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Month" })}
              name="months"
              // styles={{marginLeft: '25px'}}
              options={monthList}
              initialLabel={selectedMonth}
              initialValue={selectedMonthValue}
              setFieldLabel={setSelectedMonth}
              setFieldValue={setSelectedMonthValue}
              disabled={localLoading || loading}
              validationRequired={true}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchCustomerBirthdayWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={CollectionsSummaryDetailsColumns}
                data={customerBirthdayList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerBirthday" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                selectedMonth={selectedMonthValue}
                keyField={"customerId"}
                requestAPI={getCustomerBirthdayList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(CustomerBirthday);
