import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { history } from '../../../../'
import { RoutesList } from "../../../constants/RoutesList"
import { observer } from 'mobx-react-lite';
import { useStore } from "../../../stores/store"
import Loading from "../../loading/Loading"
import { getNotificationIcon } from "../../../common/function/function"
import moment from "moment"
import MyButton from "../../form/MyButton"
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import ArrowRightCircleIcon from 'mdi-react/ArrowRightCircleIcon';
import { Constants } from "../../../constants/Constants"

const NotificationDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  //Use Store
  const { notificationStore } = useStore();
  const { notificationDropdownList, getNotificationDropdown} = notificationStore;
  const [localLoading, setLocalLoading] = useState(false);
  const [menu, setMenu] = useState(false)
  const intl = useIntl();

  useEffect(()=> {
    if (menu) {
      if (notificationDropdownList.length < 1) {
        fetchNotificationAPI();
      }
    }
  }, [menu])

  async function fetchNotificationAPI() {
    setLocalLoading(true)
    await getNotificationDropdown({
      pageNumber: 1,
      pageSize: 3,
      notificationType: " ",
    });
    setLocalLoading(false);
  }

  const displayNotificationContent = () => {
    const aryViews : any = [];
    notificationDropdownList.length > 0
    ?
    notificationDropdownList.map((valueNotificationDropdownTemp, index)=> {
      aryViews.push(
        <Link 
          key={`notication_${index}`}
          to={{
            pathname: `/${RoutesList.notification}`, 
            state: { detail: valueNotificationDropdownTemp.id },
          }}
          onClick={()=>{
            setMenu(false);
          }}>
          <div className="media margin-top-4">
            <div className="avatar-xs me-3">
              <span className="avatar-title bg-primary rounded-circle font-size-16">
                {getNotificationIcon(valueNotificationDropdownTemp.notificationType)}
              </span>
            </div>
            <div className="media-body">
              <h6 className="margin-top-0 mb-1">
                {valueNotificationDropdownTemp.notificationType}
              </h6>
              <div className="font-size-12">
                <p className="mb-1" style={{color: '#495057'}}>
                  {valueNotificationDropdownTemp.subject}
                </p>
                <p className="margin-bottom-4" style={{color: '#495057'}}>
                  <ClockOutlineIcon size={Constants.menuIconSize}/>{" "}
                  {moment(valueNotificationDropdownTemp.createdAt).fromNow()}
                </p>
              </div>
            </div>
          </div>
        </Link>
      )
    })
    :
    aryViews.push(
      <div key={'no_notification'}>
        {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Notification" }) })}
      </div>
    )

    return aryViews;
  }

  return (
    <div>
      <Dropdown
        isOpen={menu}
        toggle={()=> {setMenu(!menu)}}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
          {/* <span className="badge bg-danger rounded-pill">3</span> */}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link
                  to={`/${RoutesList.notification}`}
                  className="text-primary"
                  onClick={() => {
                    history.push(`/${RoutesList.notification}`);
                    setMenu(false);
                  }}
                >
                  {intl.formatMessage({ id: "ViewAll" })}
                </Link>                  
              </div>
            </Row>
          </div>
          {
            localLoading
            ?
            <Loading 
              className="margin-bottom-1rem margin-left-8"/>
            :
            <SimpleBar style={{padding: '18px', paddingTop: '0px' }}>
              {displayNotificationContent()}
            </SimpleBar>
          }
          <div className="p-2 border-top d-grid">
            <Link
              to={`/${RoutesList.notification}`}
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              onClick={() => {
                history.push(`/${RoutesList.notification}`);
                setMenu(false);
              }}>
              <ArrowRightCircleIcon size={Constants.menuIconSize} className="me-1"/>
              {" "}
              {("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default observer(NotificationDropdown)