import { PaginationRequestBody } from "../models/pagination";
import { BusinessEntityListObject, BusinessEntityAddObject, BusinessEntityUpdateObject, BusinessEntityDetailObject } from "../models/businessEntity";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class BusinessEntityStore {
  businessEntityList: BusinessEntityListObject[] = [];
  businessEntityDropdownList: BusinessEntityListObject[] = [];
  businessEntityDetail: BusinessEntityDetailObject | undefined = undefined;
  businessEntityPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.businessEntityList = [];
    this.businessEntityDropdownList = [];
    this.businessEntityDetail = undefined;
    this.businessEntityPaginationParams= undefined;
  }

  setBusinessEntityDetail = (businessEntityDetail: BusinessEntityDetailObject | undefined) => {
    this.businessEntityDetail = businessEntityDetail;
  }

  setBusinessEntityPaginationParams = (businessEntityPaginationParams: PaginationRequestBody | undefined) => {
    this.businessEntityPaginationParams = businessEntityPaginationParams;
  }

  getBusinessEntity = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setBusinessEntityPaginationParams(PaginationRequestBody);
    try{
      const resultBusinessEntities = await agent.BusinessEntity.businessEntityList(PaginationRequestBody);
      runInAction(() => {
        this.businessEntityList = resultBusinessEntities.data;
        store.commonStore.setTotalItem(resultBusinessEntities.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.businessEntityList = [];
    }
  }

  getBusinessEntityDropDown = async (brandId: string = "") => {
    try{
      const resultBusinessEntitiesDropdown = await agent.BusinessEntity.businessEntityDropdown(brandId);
      runInAction(() => {
        this.businessEntityDropdownList = resultBusinessEntitiesDropdown;
      });
      return Promise.resolve(resultBusinessEntitiesDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.businessEntityDropdownList = [];
    }
  }

  getBusinessEntityWithId = async (id: string) => {
    try{
      const resultBusinessEntitysDetail = await agent.BusinessEntity.businessEntityDetail(id);
      runInAction(() => {
        this.businessEntityDetail = resultBusinessEntitysDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.businessEntityDetail = undefined;
    }
  }

  addBusinessEntity = async (businessEntityRequestBody: BusinessEntityAddObject) => {
    try{
      await agent.BusinessEntity.addBusinessEntity(businessEntityRequestBody);
      store.commonStore.setSuccessMessage(`BusinessEntityAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateBusinessEntity = async (businessEntityRequestBody: BusinessEntityUpdateObject) => {
    try{
      await agent.BusinessEntity.updateBusinessEntity(businessEntityRequestBody);
      store.commonStore.setSuccessMessage(`BusinessEntityUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteBusinessEntity  = async (id: string, name: string) => {
    try {
      await agent.BusinessEntity.deleteBusinessEntity(id);
      store.commonStore.setSuccessMessage(`BusinessEntityDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}