import React from 'react'
import maintanence from "../../../assets/images/coming-soon.svg"
import onedoc_log_v2 from "../../../assets/images/logo/onedoc_log_v2.png"

import {
  Col,
  Container,
  Row,
  Spinner
} from "reactstrap";

interface Props {
  content ?: string;
  initialLoading?: boolean;
  className?: string;
  fullHeightDisabled?: boolean;
}

export default function Loading(props: Props) {
  const displayLoading = (strLoading, blnJustifyCenter?: boolean) => {
    return (
      <div className={`fade-in-animation flex-direction-row ${props.fullHeightDisabled ? "flex-align-items-center-without-height mb-3" : "flex-align-items-center"} ${props.className ? props.className : ""}`} style={blnJustifyCenter ? {justifyContent: 'center'} : undefined}>
        <Spinner className="ms-2" color="primary"/>
        <p style={{marginTop: "1em", marginLeft: "1em"}}> {strLoading} </p>
      </div>
    )
  }

  return (
    props.initialLoading
    ?
    <div className={`my-5 pt-sm-5 ${props.className ? props.className : props.className}`}>
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center">
              <Row className="justify-content-center mt-5">
                <Col sm="4">
                  <div className="maintenance-img margin-top-48" style={{height: '150px'}}>
                    {/* <img
                      src={onedoc_log_v2}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    /> */}
                  </div>
                </Col>
              </Row>
              {displayLoading("System is setting up ...", true)}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    :
    displayLoading(props.content || "Loading")
  )
}