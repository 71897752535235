
import React from "react";
import MyButton from "./MyButton";
import { history } from '../../../';
import { Link, useLocation } from "react-router-dom";
import { Constants } from "../../constants/Constants";
import { RoutesList } from "../../constants/RoutesList";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import PrinterIcon from "mdi-react/PrinterIcon";
interface Props {
  successMessage: string;
  viewAction: boolean;
  validation?: any;
  showPrint?: boolean;
  showExtraLeftButton?: boolean;
  extraLeftButtonTitle?: string;
  extraLeftButtonColor?: string;
  extraLeftButtonFunction?: Function;
  extraLeftButtonWithoutForm?: boolean;
  extraLeftDirtyDisabled?: boolean;
  showExtraRightButton?: boolean;
  extraRightButtonTitle?: string;
  extraRightButtonColor?: string;
  extraRightButtonFunction?: Function;
  extraRightButtonWithoutForm?: boolean;
  extraRightDirtyDisabled?: boolean;
  hidePrimaryButton?: boolean;
  submitButtonTitle?: string;
  submitButtonColor?: string;
  onClickFunction?: Function
  cancelButtonTitle?: string;
  cancelButtonColor?: string;
  cancelButtonFunction?: Function;
  cancelButtonBesidePrint?: boolean;
  className?: string;
  blnMarginTopByInnerWidth?: boolean;
  dirtyDisabled?: boolean;
}

export default observer(function GeneralSubmitAndCancelBtn(props: Props) {
  const location = useLocation();
  const { commonStore } = useStore();
	const { windowSize, setHideComponentForPrint } = commonStore;

  const returnCancelButton = () => {
    return (
      !Boolean(props.successMessage) && !props.viewAction && (
        <MyButton
          type="button"
          class={`btn ${props.cancelButtonColor ? props.cancelButtonColor : "btn-back"} ${props.hidePrimaryButton ? "" : "margin-left-8"}`}
          content={props.cancelButtonTitle || "Back"}
          onClick={() => {
            if (props.cancelButtonFunction) {
              props.cancelButtonFunction();
            }
            else {
              let aryPath = location.pathname.split('/');
              if (aryPath.length > 2) {
                const firstPath = location.pathname.split('/')[1];
                if (firstPath == RoutesList.appointment || firstPath == RoutesList.appointmentList) {
                  history.push({
                    pathname: `/${firstPath}`,
                    state: { action: "refreshAppointmentData" }
                  });
                }
                else if (firstPath === RoutesList.receipt) {
                  history.push(`/${RoutesList.customerSalesOrder}`)
                }
                else {
                  history.push(`/${firstPath}`)
                }
              }
              else {
                history.push(RoutesList.dashboard)
              }
            }
          }}
          disable={
            props.validation?.isSubmitting
          }
          loading={
            props.validation?.isSubmitting
          }
        />
      )
    )
  }

  const returnExtraGeneralButton = (blnLeftButton: boolean = true) => {
    return (
      !Boolean(props.successMessage) && !props.viewAction && !props.hidePrimaryButton && (
        <MyButton
          type={((blnLeftButton && props.extraLeftButtonWithoutForm) || (!blnLeftButton && props.extraRightButtonWithoutForm)) ? "button" :"submit"}
          class={`btn ${blnLeftButton ? (props.extraLeftButtonColor || "btn-success") : (props.extraRightButtonColor || "btn-success")} ${blnLeftButton ? 'margin-right-8' : 'margin-left-8'}`}
          content={(blnLeftButton ? props.extraLeftButtonTitle : props.extraRightButtonTitle) || ""}
          disable={
            props.validation?.isSubmitting || (((blnLeftButton && !props.extraLeftDirtyDisabled) || (!blnLeftButton && !props.extraRightDirtyDisabled)) ? !props.validation?.dirty : false)
          }
          loading={
            props.validation?.isSubmitting
          }
          onClick={()=> {
            if (blnLeftButton && props.extraLeftButtonFunction) {
              props.extraLeftButtonFunction();
            }

            if (!blnLeftButton && props.extraRightButtonFunction) {
              props.extraRightButtonFunction();
            }
          }}
        />
      )
    )
  }

  return (
    <div className={`flex-direction-row ${props.className} ${(props.blnMarginTopByInnerWidth && Constants.innerWidthCompare>windowSize.innerWidth) ? "margin-top-16" : ""}`}>
      {props.showExtraLeftButton && returnExtraGeneralButton(true)}
      {!Boolean(props.successMessage) && !props.viewAction && !props.hidePrimaryButton && (
        <MyButton
          type="submit"
          class={`btn ${props.submitButtonColor ? props.submitButtonColor : "btn-success"}`}
          content={props.submitButtonTitle || "Save"}
          disable={
            props.validation?.isSubmitting || (!props.dirtyDisabled ? !props.validation?.dirty : false)
          }
          loading={
            props.validation?.isSubmitting
          }
          onClick={()=> {
            if (props.onClickFunction) {
              props.onClickFunction();
            }
          }}
        />
      )}
      {props.showExtraRightButton && returnExtraGeneralButton(false)}
      {!props.cancelButtonBesidePrint && returnCancelButton()}
      {
        props.showPrint &&
        <div className="flex-right" style={{ width: "100%", maxHeight: "38px" }}>
          <div style={{ width: '100%' }} />
          {/* <Link
            to="#"
            onClick={(event) => { 
              setHideComponentForPrint(true);
              setTimeout(()=> {
                window.print(); 
              }, 100)
              event.preventDefault();
            }}
            className="btn btn-success me-1"
          >
            <PrinterIcon size={Constants.menuIconSize}/>
          </Link>{" "} */}
        </div>
      }
      {props.cancelButtonBesidePrint && returnCancelButton()}
    </div>
  )
})