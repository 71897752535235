import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Label
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, exchangeToCreditStatusColor } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import _ from "lodash";
import ExchangeToCreditDetailModal from "./Modal/ExchangeToCreditDetailModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { history } from "../..";
import { PaginationRequestBody } from "../../app/models/pagination";
import GeneralInput from "../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ExchangeToCreditDetailObject } from "../../app/models/exchangeToCredit";

const ExchangeToCredit = () => {
  const intl = useIntl();

  //Use Params
  const { id } = useParams();

  //Use Store
  const { exchangeToCreditStore, customerStore, staticReferenceStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { exchangeToCreditList, exchangeToCreditPaginationParams, getExchangeToCredit, getExchangeToCreditWithId, setExchangeToCreditList, setExchangeToCreditPaginationParams, reviewExchangeToCredit } = exchangeToCreditStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Adjustment" }), urlPath: "" }];
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedExchangeToCreditStatus, setSelectedExchangeToCreditStatus] = useState<string>(AllStatus);
  const [selectedExchangeToCreditStatusId, setSelectedExchangeToCreditStatusId] = useState<number | string | undefined>(" ");
  const [exchangeToCreditStatusList, setExchangeToCreditStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [exchangeToCreditDetailModal, setExchangeToCreditDetailModal] = useState(false);
  const [selectedExchangeToCreditDetail, setSelectedExchangeToCreditDetail] = useState<ExchangeToCreditDetailObject | undefined>(undefined);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const blnPermissionManageCustomerSalesOrder =  checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewExchangeToCredit = checkPermission([PermissionConstants.ReviewExchangeToCredit])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      documentNo: "",
      referenceDocumentNo: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const ExchangeToCreditColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
    },
    {
      dataField: "issueDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.issueDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.issueDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      )
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "SalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionManageCustomerSalesOrder 
          ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.salesOrderNo}</Label>
            </Link>
          :
            row.salesOrderNo
        }
      </>
    },
    {
      dataField: "totalExchangeAmount",
      text: intl.formatMessage({ id: "Amount" }).toUpperCase(),
      formatter: (cellContent, row) => <>{returnPriceWithCurrency(row.totalExchangeAmount)}</>, //Custom UI to display
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={exchangeToCreditStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.exchangeToCredit}/view/${row.id}`}
              type={'view'} />
            {
              blnPermissionReviewExchangeToCredit && row.status === Constants.pending
              ?
              <TableActionButton
                to="#"
                type={'edit'}
                onClick={() => onClickViewDetail(row.id)} />
              :
              <TableActionButton 
                type="viewModal"
                onClick={()=> onClickViewDetail(row.id)}/>
            }
          </div>
        </>
      ),
    },
  ];

  let exchangeToCreditRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    status: selectedExchangeToCreditStatusId,
    statusName: selectedExchangeToCreditStatus,
    documentNo: validation.values.documentNo,
    referenceDocumentNo: validation.values.referenceDocumentNo
  }

  useEffect(() => {
    async function fetchExchangeToCreditListAPI() {
      setLocalLoading(true)
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.exchangeToCredit)
      if (blnBackNavigation) {
        if (exchangeToCreditPaginationParams) {
          setCurrentPage(exchangeToCreditPaginationParams.pageNumber);
          setSelectedBranchId(exchangeToCreditPaginationParams.branchId!);
          setSelectedBranchName(exchangeToCreditPaginationParams.branchName!);
          setSelectedExchangeToCreditStatusId(exchangeToCreditPaginationParams.status)
          setSelectedExchangeToCreditStatus(exchangeToCreditPaginationParams.statusName || AllStatus)
          setSelectedCustomerId(exchangeToCreditPaginationParams.customerId || "")
          setSelectedCustomer(exchangeToCreditPaginationParams.customerName || "")
          validation.setValues((values) => ({
            ...values,
            documentNo: exchangeToCreditPaginationParams.documentNo || "",
            referenceDocumentNo: exchangeToCreditPaginationParams.referenceDocumentNo || "",
          }))
          exchangeToCreditRequestBody = exchangeToCreditPaginationParams;
        }
      }
      else {
        setExchangeToCreditPaginationParams(undefined);
      }
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.exchangeToCreditStatus, AllStatus),
        getBranchDropDown(),
        getExchangeToCredit(exchangeToCreditRequestBody)
      ];
      let resultAPI = await Promise.all(aryAPI);
      setExchangeToCreditStatusList(resultAPI[0]);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? AllBranch : "", true))
      setLocalLoading(false);
      setInitialCallAPI(false);

      if (id) {
        await onClickViewDetail(id);
        window.history.replaceState(null, "", `/${RoutesList.exchangeToCredit}`)
      }
    }

    if (!checkPermission([PermissionConstants.ManageExchangeToCredit], true)) {
      return;
    }

    fetchExchangeToCreditListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.exchangeToCredit)
        if (!blnSetParams) {
          setExchangeToCreditPaginationParams(undefined);
        }
      }
      setExchangeToCreditList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerDropdownFunction() {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerDropdownFunction();
      fetchExchangeToCreditAPI(true, Constants.emptyData);
    }
  }, [selectedBranchId])

  useEffect(() => {
    if (
      (selectedExchangeToCreditStatusId || selectedExchangeToCreditStatusId === 0) && !initialCallAPI
    ) {
      fetchExchangeToCreditAPI(true);
    }
  }, [selectedExchangeToCreditStatusId, selectedCustomerId, triggerSearch]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setExchangeToCreditDetailModal(false);
        fetchExchangeToCreditAPI(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  async function fetchExchangeToCreditAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getExchangeToCredit({
      ...exchangeToCreditRequestBody,
      ...customerId === Constants.emptyData && { customerId: "", customerName: "" },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickViewDetail = async (exchangeToCreditId: any) => {
    setLoading(true);
    let resultPreviewExchangeToCredit = await getExchangeToCreditWithId(exchangeToCreditId);
    setSelectedExchangeToCreditDetail(resultPreviewExchangeToCredit)
    setExchangeToCreditDetailModal(true);
    setLoading(false);
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ExchangeToCredit" }) })}
        title={intl.formatMessage({ id: "ExchangeToCredit" })}
        breadCrumbList={breadCrumbList}
      >
        {
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ExchangeToCredit" }) })}
                name="exchangeToCreditStatus"
                options={exchangeToCreditStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedExchangeToCreditStatus}
                initialValue={selectedExchangeToCreditStatusId}
                setFieldLabel={setSelectedExchangeToCreditStatus}
                setFieldValue={setSelectedExchangeToCreditStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          !initialCallAPI
          &&
          <Row className={`${loadingCustomer ? "margin-bottom-16" : ""}`}>
            <DoubleColumnRowParent>
              {
                displayCustomerDropdown &&
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Customer" })}
                  name="customer"
                  placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                  options={customerDropdownList}
                  labelField="preferredName"
                  valueField="id"
                  initialLabel={selectedCustomer}
                  initialValue={selectedCustomerId}
                  setFieldLabel={setSelectedCustomer}
                  setFieldValue={setSelectedCustomerId}
                  disabled={localLoading || loading}
                  onChange={() => {
                    clearCustomerDropdownList();
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  blnSupportCustomSearch={true}
                  onChangeCustomSearch={(value)=> {
                    fetchCustomerDropdown(value);
                  }}
                  onMenuOpenFunction={()=> {
                    clearCustomerDropdownList();
                    setLoadingCustomer(false);
                  }}
                  loadingCustomSearch={loadingCustomer}
                  setLoadingCustomSearch={setLoadingCustomer}
                />
              }
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "DocumentNo" })}
                name="documentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterDocumentNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralInput
                title={intl.formatMessage({ id: "SalesOrderNo" })}
                name="referenceDocumentNo"
                type="text"
                disabled={localLoading || loading}
                validation={validation}
                placeholder={intl.formatMessage({ id: "EnterSalesOrderNo" })}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
        }
        {
          localLoading
          ? 
          <Loading/>
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ExchangeToCreditColumns}
            data={exchangeToCreditList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ExchangeToCredit" }) })}
            objSorted={defaultSorted}
            requestAPI={getExchangeToCredit}
            customerId={selectedCustomerId}
            customerName={selectedCustomer}
            branchId={selectedBranchId}
            branchName={selectedBranchName}
            status={selectedExchangeToCreditStatusId}
            statusName={selectedExchangeToCreditStatus}
            documentNo={validation.values.documentNo}
            referenceDocumentNo={validation.values.referenceDocumentNo} />
        }
      </ListViewLayout>
      {
        exchangeToCreditDetailModal &&
        <ExchangeToCreditDetailModal
          blnShow={exchangeToCreditDetailModal}
          setModal={setExchangeToCreditDetailModal}
          selectedExchangeToCreditDetail={selectedExchangeToCreditDetail!}
          setSelectedExchangeToCreditDetail={setSelectedExchangeToCreditDetail}
          reviewExchangeToCredit={reviewExchangeToCredit}
        />
      }
    </div>
  );
}

export default observer(ExchangeToCredit);
