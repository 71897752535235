import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import MetaTags from "react-meta-tags";
import { useStore } from '../../app/stores/store';
import {
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import { history } from "../..";
import PaginationTable from '../../app/components/table/PaginationTable';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { ObjectForDropdown } from "../../app/models/common";
import Loading from "../../app/components/loading/Loading";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import _ from "lodash";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnAdvancedSearchText, virtualWarehouseStatusColor } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { RoutesList } from "../../app/constants/RoutesList";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import GeneralInput from "../../app/components/form/GeneralInput";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import MyAddButton from "../../app/components/form/MyAddButton";
import MyButton from "../../app/components/form/MyButton";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";

const CustomerVirtualWarehouse = () => {
  const intl = useIntl();
  //Use Store
  const { customerVirtualWarehouseStore, branchStore, productStore, customerStore, staticReferenceStore, commonStore } = useStore();
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getProductDropdown } = productStore;
  const { loading, setLoading, totalItem, prevPath, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { customerVirtualWarehouseList, customerVirtualWarehousePaginationParams, getCustomerVirtualWarehouse, setCustomerVirtualWarehousePaginationParams } = customerVirtualWarehouseStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [productListFinal, setProductListFinal] = useState<ObjectForDropdown[]>([]);
  const AllProduct = `${intl.formatMessage({ id: "AllProduct" })}`;
  const [selectedProductName, setSelectedProductName] = useState(AllProduct);
  const [selectedProductId, setSelectedProductId] = useState(" ");
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedVirtualWarehouseStatus, setSelectedVirtualWarehouseStatus] = useState<string>(AllStatus);
  const [selectedVirtualWarehouseStatusId, setSelectedVirtualWarehouseStatusId] = useState<number | string | undefined>(" ");
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [blnAdvancedSearch, setBlnAdvancedSearch] = useState(false);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [virtualWarehouseStatusList, setVirtualWarehouseStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const pageSize = Constants.defaultPageSize;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: "" }];
  const blnHQStaff = !getBranchUser();
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionCreateProductRedemptionNote = checkPermission([PermissionConstants.CreateProductRedemptionNote]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

   // validation
   const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      virtualWarehouseNo: "",
      deliverOrderNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let customerVirtualWarehouseRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedCustomerId ? "" : getBranchId(),
    productId: selectedProductId == AllProduct ? "" : selectedProductId,
    productName: selectedProductName,
    customerId: selectedCustomerId,
    customerName: selectedCustomer,
    virtualWarehouseStatus: selectedVirtualWarehouseStatusId,
    virtualWarehouseStatusName: selectedVirtualWarehouseStatus,
    virtualWarehouseNo: validation.values.virtualWarehouseNo,
    deliverOrderNo: validation.values.deliverOrderNo,
  }
  
  useEffect(() => {
    async function fetchCustomerVirtualWarehouse() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.customerVirtualWarehouse) || comparePathForPagination(prevPath, RoutesList.productRedemptionNote)
      if (blnBackNavigation) {
        if (customerVirtualWarehousePaginationParams) {
          setCurrentPage(customerVirtualWarehousePaginationParams.pageNumber);
          setSelectedProductId(customerVirtualWarehousePaginationParams.productId || "");
          setSelectedProductName(customerVirtualWarehousePaginationParams.productName || AllProduct);
          setSelectedVirtualWarehouseStatusId(customerVirtualWarehousePaginationParams.virtualWarehouseStatus);
          setSelectedVirtualWarehouseStatus(customerVirtualWarehousePaginationParams.virtualWarehouseStatusName || AllStatus);
          setSelectedCustomerId(customerVirtualWarehousePaginationParams.customerId || "");
          setSelectedCustomer(customerVirtualWarehousePaginationParams.customerName || "");

          validation.setValues((values)=> ({
            ...values,
            virtualWarehouseNo: customerVirtualWarehousePaginationParams.virtualWarehouseNo || "",
            deliverOrderNo: customerVirtualWarehousePaginationParams.deliverOrderNo || ""
          }))
          customerVirtualWarehouseRequestBody = customerVirtualWarehousePaginationParams;
        }
      }
      else {
        setCustomerVirtualWarehousePaginationParams(undefined);
      }

      let aryAPI: any = [
        getProductDropdown(),
        getCustomerVirtualWarehouse(customerVirtualWarehouseRequestBody),
        getStaticReferenceWithType(Constants.virtualWarehouseStatus, AllStatus),
      ];

      let resultAPI = await Promise.all(aryAPI);
      setProductListFinal(arraySpliceInAllValue(resultAPI[0], AllProduct, false, "name", "id"));
      setVirtualWarehouseStatusList(resultAPI[2]);
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse], true)) {
      return;
    }

    fetchCustomerVirtualWarehouse();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.customerVirtualWarehouse) || comparePathForSetParams(history, RoutesList.productRedemptionNote)
        if (!blnSetParams) {
          setCustomerVirtualWarehousePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchCustomerVirtualWarehouseAPI(true);
    }
  }, [selectedProductId, selectedVirtualWarehouseStatusId, selectedCustomerId, triggerSearch])

  async function fetchCustomerVirtualWarehouseAPI(blnDropDown?: boolean, customerId?: string) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getCustomerVirtualWarehouse({
      ...customerVirtualWarehouseRequestBody,
      customerId: customerId === Constants.emptyData ? "" : selectedCustomerId,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSelect = (row) => {
    setCurrentPage(1);
    setSelectedCustomer(row.preferredName);
    setSelectedCustomerId(row.id);
    setDisplayCustomerDropdown(false);
    setBlnAdvancedSearch(true);
    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  //Table Content UI
  const CustomerVirtualWarehouseColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "virtualWarehouseNo",
      text: intl.formatMessage({ id: "VirtualWarehouseNo" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
              ?
              <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
              </Link>
              :
              <div>{row.customerName}</div>
          }
        </>
      )
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    
    {
      dataField: "deliverOrderNo",
      text: intl.formatMessage({ id: "CustomerRedemptionNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            checkPermission([PermissionConstants.ManageCustomerDeliverOrder])
            ?
            <Link to={row.deliverOrderId ? `/${RoutesList.customerRedemption}/view/${row.deliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.deliverOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.deliverOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "totalQuantity",
      text: intl.formatMessage({ id: "TotalQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.totalQuantity}
        </div>
      ),
    },
    // {
    //   dataField: "reservedQuantity",
    //   text: intl.formatMessage({ id: "ReservedQty" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.reservedQuantity}
    //     </div>
    //   ),
    // },
    {
      dataField: "redeemedQuantity",
      text: intl.formatMessage({ id: "RedeemedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.redeemedQuantity}
        </div>
      ),
    },
    {
      dataField: "creditExchangedQuantity",
      text: intl.formatMessage({ id: "CreditExchangedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.creditExchangedQuantity}
        </div>
      ),
    },
    {
      dataField: "returnedQuantity",
      text: intl.formatMessage({ id: "ReturnedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.returnedQuantity}
        </div>
      ),
    },
    {
      dataField: "expiredQuantity",
      text: intl.formatMessage({ id: "ExpiredQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.expiredQuantity}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={virtualWarehouseStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "reversedQuantity",
      text: intl.formatMessage({ id: "ReversedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.reversedQuantity}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              to={`/${RoutesList.customerVirtualWarehouse}/view/${row.id}`}
              type="view" />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "CustomerVirtualWarehouse" }) })}
        title={intl.formatMessage({ id: "CustomerVirtualWarehouse" })}
        breadCrumbList={breadCrumbList}>
        { 
          !initialCallAPI
          &&
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Product" })}
                name="productName"
                labelField={"name"}
                valueField={"id"}
                options={productListFinal}
                initialLabel={selectedProductName}
                initialValue={selectedProductId}
                setFieldLabel={setSelectedProductName}
                setFieldValue={setSelectedProductId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "VirtualWarehouseStatus" })}
                name="customerVirtualWarehouseType"
                options={virtualWarehouseStatusList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedVirtualWarehouseStatus}
                initialValue={selectedVirtualWarehouseStatusId}
                setFieldLabel={setSelectedVirtualWarehouseStatus}
                setFieldValue={setSelectedVirtualWarehouseStatusId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            {
              !initialCallAPI
              &&
              <DoubleColumnRowParent>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                      setBlnAdvancedSearch(false);
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </DoubleColumnRowParent>
            }
            {
              !initialCallAPI
              &&
              <DoubleColumnRowParent>
                <MyButton
                  type="button"
                  class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare770 ? "margin-top-28" : "margin-bottom-12"}`}
                  content={intl.formatMessage({ id: returnAdvancedSearchText() }).toUpperCase()}
                  loading={localLoading || loading}
                  disable={localLoading || loading}
                  onClick={() => { setCustomerSearchModal(true) }}
                />
              </DoubleColumnRowParent>
            }
          </Row>
        }
        <Row className={`${displayCustomerDropdown ? "margin-bottom-16" : ""}`}>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "VirtualWarehouseNo" })}
              name="virtualWarehouseNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "VirtualWarehouseNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "CustomerRedemptionNo" })}
              name="deliverOrderNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "CustomerRedemptionNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          {
            blnPermissionCreateProductRedemptionNote
            &&
            <DoubleColumnRowParent>
              <MyButton
                class={`btn btn-success ${Constants.innerWidthCompare<windowSize.innerWidth ? "margin-top-28" : ""}`}
                content={intl.formatMessage({ id: "Redeem" })}
                onClick={() => {
                  history.push({
                    pathname: `/${RoutesList.productRedemptionNote}/add`,
                    state: { 
                      detail: { 
                        customerId: selectedCustomerId,
                        customerName: selectedCustomer
                      }}
                  }); 
                }}
                disable={localLoading || loading} />
            </DoubleColumnRowParent>
          }
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={CustomerVirtualWarehouseColumns}
              data={customerVirtualWarehouseList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerVirtualWarehouse" }) })}
              objSorted={defaultSorted}
              branchId={selectedCustomerId ? "" : getBranchId()}
              productId={selectedProductId}
              productName={selectedProductName}
              customerId={selectedCustomerId}
              customerName={selectedCustomer}
              virtualWarehouseStatus={selectedVirtualWarehouseStatusId}
              virtualWarehouseStatusName={selectedVirtualWarehouseStatus}
              virtualWarehouseNo={validation.values.virtualWarehouseNo}
              deliverOrderNo={validation.values.deliverOrderNo}
              requestAPI={getCustomerVirtualWarehouse} />
        }
      </ListViewLayout>
      {
        customerSearchModal
        &&
        <CustomerSearchModal
          blnShow={customerSearchModal}
          setModal={setCustomerSearchModal}
          blnHideOtherBranch={false}
          title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
          onSelectFunction={onClickSelect} />
      }
    </div>
  );
}

export default observer(CustomerVirtualWarehouse);
