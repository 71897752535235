import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import MyButton from "../../app/components/form/MyButton";
import {
  contructValidationErrorMessage,
  getDisplayName,
  IncludesLocationPathName,
  returnBlobImage,
} from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import { history } from "../..";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import { Constants } from "../../app/constants/Constants";
import { RoutesList } from "../../app/constants/RoutesList";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropZoneUploadWidget from "../../app/components/form/DropZoneUploadWidget";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";

const ProfileDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { staticReferenceStore, raceStore, countryStore, profileStore, commonStore, } = useStore();
  const { errorMessage, successMessage, loading, windowSize, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { titleType, identityType, genderType, getStaticReference } = staticReferenceStore;
  const { profileDetail, updateProfile, getProfileDetail, setProfileDetail } = profileStore;
  const { raceDropdownList, getRaceDropdown } = raceStore;
  const { stateList, countryDropdownList, getStateWithId, getCountryDropdown, setStateList } = countryStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Profile" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Profile" }), urlPath: RoutesList.profile }];
  const [loadingState, setLoadingState] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(false);
  const [newImageUrl, setNewImageUrl] = useState<string>("");
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: profileDetail || {
      id: "",
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      preferredName: "",
      employeeNo: "",
      identityNo: "",
      identityType: "",
      dob: "1990-01-01",
      gender: "",
      mobileNo: "",
      email: "",
      raceId: "",
      raceName: "",
      branchId: "",
      branchName: "",
      emergencyContact: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      photoUrl: "",
      file: undefined,
      isSystemUser: false,
      lastLoginDateTime: ""
    },
    validationSchema: Yup.object({
      dob: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "DateOfBirth" }) }))
      }).nullable(),
      raceId: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Race" }) }))
      }).nullable(),
      title: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Title" }) }))
      }).nullable(),
      countryId: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Country" }) }))
      }).nullable(),
      stateId: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        })
      }).nullable(),
      stateName: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().test("isValidPass", intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "State" }) }), (value: any, context) => {
          if (stateList.length === 0 && !value) {
            return false;
          }
          return true;
        })
      }).nullable(),
      gender: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Gender" }) }))
      }).nullable(),
      firstName: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "FirstName" }) }))
      }).nullable(),

      lastName: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "LastName" }) }))
      }).nullable(),
      preferredName: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PreferredName" }) }))
      }).nullable(),
      identityType: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }))
      }).nullable(),
      identityNo: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) }))
      }).nullable(),
      mobileNo: Yup.string().when('isSystemUser', {
        is: false,
        then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "MobileNo" }) }))
      }).nullable(),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!valuesTemp.stateId) {
        delete valuesTemp["stateId"]
      }
      if (newImageUrl) {
        let fileTemp = await returnBlobImage(newImageUrl);
        valuesTemp.file = fileTemp;
      }
      try {
        let resultProfile = await updateProfile(valuesTemp);
        if (resultProfile?.status === Constants.success) {
          validation.setValues(resultProfile.data);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnSystemUser = validation.values.isSystemUser == true;

  useEffect(() => {
    async function fetchProfileDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getProfileDetail(),
        getStaticReference(),
        getRaceDropdown(),
        getCountryDropdown(),
      ];
      if (!addAction) {
        aryAPI.push(getProfileDetail);
      }
      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (addAction) {
      setStateList([]);
    }

    fetchProfileDetailAPI();
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  useEffect(() => {
    if (!addAction) {
      if (profileDetail && !initialCallAPI) {
        setInitialCallAPI(true);
        if (profileDetail.countryId) {
          getStateWithLoading(profileDetail.countryId, false)
        }
      }
    }
  }, [profileDetail]);

  const getStateWithLoading = async (id: string, removeData: boolean = true) => {
    setLoadingState(true);
    await getStateWithId(id);
    if (removeData) {
      validation.setFieldValue("stateId", "");
      validation.setFieldValue("stateName", "");
    }
    setLoadingState(false);
  };

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "MyProfile" })}
                  h4Title
                />
                {
                  validation.values.employeeNo
                  &&
                  <GeneralInput
                    title={intl.formatMessage({ id: "EmployeeNo" })}
                    name="employeeNo"
                    className="margin-top-20"
                    type="text"
                    disabled={true}
                    validation={validation}
                  />
                }
                {
                  !blnSystemUser &&
                  <Row className="mt-3">
                    <Col xs={"12"} md={"4"} xl={"4"}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "FirstName" })}
                        name="firstName"
                        type="text"
                        validationRequired={true}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                    </Col>
                    <Col xs={"12"} md={"4"} xl={"4"}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "MiddleName" })}
                        name="middleName"
                        type="text"
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                    </Col>
                    <Col xs={"12"} md={"4"} xl={"4"}>
                      <GeneralInput
                        title={intl.formatMessage({ id: "LastName" })}
                        name="lastName"
                        type="text"
                        validationRequired={true}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                    </Col>
                  </Row>
                }
                <GeneralInput
                  title={intl.formatMessage({ id: "PreferredName" })}
                  className={blnSystemUser ? "margin-top-20" : ""}
                  name="preferredName"
                  type="text"
                  validationRequired={true}
                  disabled={disabledFieldInput || viewAction}
                  validation={validation}
                />
                {
                  !blnSystemUser &&
                  <>
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <DropDownWithTitle
                          name={"raceId"}
                          title={intl.formatMessage({ id: "Race" })}
                          specifyReturnFieldName={[
                            {
                              field: "raceId",
                              value: "id",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={raceDropdownList}
                          disabled={disabledFieldInput || viewAction}
                          initialLabel={!addAction ? validation.values.raceName : undefined}
                          initialValue={!addAction ? validation.values.raceId : undefined}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <DropDownWithTitle
                          name={"title"}
                          title={intl.formatMessage({ id: "Title" })}
                          specifyReturnFieldName={[
                            {
                              field: "title",
                              value: "displayValue",
                            },
                          ]}
                          labelField={"displayValue"}
                          valueField={"displayValue"}
                          blnValueWithNewSpace={true}
                          options={titleType}
                          disabled={disabledFieldInput || viewAction}
                          initialValue={!addAction ? validation.values.title : undefined}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <DropDownWithTitle
                          name={"identityType"}
                          title={intl.formatMessage({ id: "IdentityType" })}
                          specifyReturnFieldName={[
                            {
                              field: "identityType",
                              value: "displayValue",
                            },
                          ]}
                          labelField={"displayValue"}
                          valueField={"displayValue"}
                          blnValueWithNewSpace={true}
                          options={identityType}
                          disabled={disabledFieldInput || viewAction}
                          initialValue={!addAction ? validation.values.identityType : undefined}
                          onChangeFunction={()=> {
														validation.setFieldValue("identityNo", "");
													}}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "IdentityNo" })}
                          name="identityNo"
                          type="text"
                          blnAlphaNumberic={validation.values.identityType === Constants.passport ? false : true}
                          validationRequired={true}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "DateOfBirth" })}
                          name="dob"
                          type="date"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <DropDownWithTitle
                          name={"gender"}
                          title={intl.formatMessage({ id: "Gender" })}
                          specifyReturnFieldName={[
                            {
                              field: "gender",
                              value: "displayValue",
                            },
                          ]}
                          labelField={"displayValue"}
                          valueField={"displayValue"}
                          blnValueWithNewSpace={true}
                          options={genderType}
                          disabled={disabledFieldInput || viewAction}
                          initialValue={!addAction ? validation.values.gender : undefined}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "MobileNo" })}
                          name="mobileNo"
                          type="number"
                          blnNumberOnly={true}
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "EmergencyContact" })}
                          name="emergencyContact"
                          type="text"
                          disabled={
                            disabledFieldInput ||
                            viewAction
                          }
                          validation={validation}
                        />
                      </Col>
                      <GeneralInput
                        title={intl.formatMessage({ id: "Email" })}
                        name="email"
                        type="text"
                        validationRequired={true}
                        disabled={disabledFieldInput || viewAction}
                        validation={validation}
                      />
                    </Row>
                    <LineBreakWithTittle
                      title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "Address" }) })}
                      className="mt-4"
                      h4Title />
                    <GeneralInput
                      title={intl.formatMessage({ id: "AddressLine1" })}
                      name="addressLine1"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                    <GeneralInput
                      title={intl.formatMessage({ id: "AddressLine2" })}
                      name="addressLine2"
                      type="text"
                      disabled={disabledFieldInput || viewAction}
                      validation={validation}
                    />
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "City" })}
                          name="city"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "PostCode" })}
                          name="postcode"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        <DropDownWithTitle
                          name={"countryId"}
                          title={intl.formatMessage({ id: "Country" })}
                          specifyReturnFieldName={[
                            {
                              field: "countryId",
                              value: "id",
                            },
                          ]}
                          labelField={"name"}
                          valueField={"id"}
                          options={countryDropdownList}
                          disabled={disabledFieldInput || viewAction || loadingState}
                          onChangeFunction={
                            getStateWithLoading
                          }
                          initialLabel={!addAction ? validation.values.countryName : undefined}
                          initialValue={!addAction ? validation.values.countryId : undefined}
                          validationRequired={true}
                          validation={validation}
                        />
                      </Col>
                      <Col xs={"12"} md={"6"} xl={"6"}>
                        {loadingState ? (
                          <Loading />
                        ) : (
                          stateList.length > 0
                            ?
                            <DropDownWithTitle
                              name={"stateId"}
                              title={intl.formatMessage({ id: "State" })}
                              specifyReturnFieldName={[
                                {
                                  field: "stateId",
                                  value: "id",
                                },
                                {
                                  field: "stateName",
                                  value: "name",
                                },
                              ]}
                              labelField={"name"}
                              valueField={"id"}
                              options={stateList}
                              disabled={disabledFieldInput || viewAction}
                              initialLabel={!addAction ? validation.values.stateName : undefined}
                              initialValue={!addAction ? validation.values.stateId : undefined}
                              validationRequired={stateList.length > 0 ? true : false}
                              validation={validation}
                            />
                            :
                            <GeneralInput
                              title={intl.formatMessage({ id: "StateName" })}
                              name="stateName"
                              type="text"
                              disabled={disabledFieldInput || viewAction}
                              validationRequired={true}
                              validation={validation}
                            />
                        )}
                      </Col>
                    </Row>
                  </>
                }
              </Col>
              <Col xl={6} className={`${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-8" : ""}`}>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "System" }) })}
                  h4Title
                />
                <Col >
                  <SingleColumnRowParent
                    blnDoubleTab={true}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "LastLoginDateAndTime" })}
                      name="lastLoginDateTime"
                      type="text"
                      disabled={true}
                      validation={validation}
                    />
                  </SingleColumnRowParent>
                </Col>
                <LineBreakWithTittle
                  title={intl.formatMessage({ id: "Photo" })}
                  className="mt-4"
                  h4Title
                />
                <Col >
                  <SingleColumnRowParent
                    blnDoubleTab={true}>
                    <DropZoneUploadWidget
                      name="file"
                      validation={validation}
                      setNewImageUrl={setNewImageUrl}
                      disabled={disabledFieldInput || viewAction}
                      imageUrl={validation.values.photoUrl}
                    />
                  </SingleColumnRowParent>
                </Col>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              successMessage={""}
              viewAction={viewAction}
              validation={validation}
              cancelButtonFunction={() => {
                history.push(`/${RoutesList.dashboard}`)
              }}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ProfileDetail);
