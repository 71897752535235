import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import { checkPermission, getBrandName, IncludesLocationPathName, returnBrandSlogan, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import AlertMessage from "../../app/components/alert/AlertMessage";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Table } from "reactstrap";
import BreadCrumb from "../../app/components/breadCrumb/BreadCrumb";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";

const ReceiptViewUI = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { invoiceStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    setLoading,
  } = commonStore;
  const { invoiceDetail, setInvoiceDetail, getInvoiceWithId } = invoiceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "Receipt" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "CustomerSalesOrder" }), urlPath: RoutesList.customerSalesOrder }];
  const invoiceFullDetail = invoiceDetail || {
    id: "",
    issueDate: "",
    branchName: "",
    balance: 0,
    subTotalAmount: 0,
    taxAmount: 0,
    discountAmount: 0,
    totalAmount: 0,
    brandName: "",
    businessEntityName: "",
    branchAddress: "",
    branchContactNo: "",
    totalPayment: "",
    invoiceDetails: [
      {
        id: "",
        itemName: "",
        unitPrice: 0,
      }
    ],
    paymentHistories: [
      {
        id: "",
        paymentMethodName: "",
        paidAmount: 0,
        branchName: "",
      }
    ]
  }

  async function fetchInvoiceDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getInvoiceWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageCustomerSalesOrder], true)) {
      return;
    }
    
    fetchInvoiceDetailAPI();

    return (() => {
      if (id && !addAction) {
        setInvoiceDetail(undefined);
      }
    })
  }, []);

  const displayCustomRowData = (colSpan, label, value) => {
    return (
      <tr key={label}>
        {/* <td colSpan={colSpan} /> */}
        <td colSpan={colSpan} className="text-end" style={{ fontWeight: 'bold' }}>
          {label}
        </td>
        <td className="text-end" style={{ paddingRight: '0px' }}>
          {`${returnPriceWithCurrency(value)}`}
        </td>
      </tr>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <Row className="email-template">
              <Col md="12">
                <Table
                  className="receipt-table-border">
                  <tbody>
                    <tr>
                      <td>
                        <div className="receipt-table-content">
                          <Table className="receipt-table-main" width="100%" cellPadding="0" cellSpacing="0">
                            <tbody>
                              <tr>
                                <td className="receipt-table-body-content">
                                  <Table>
                                    <tbody>
                                      <tr>
                                        <td className="receipt-table-body-content-block aligncenter">
                                          <Table className="receipt-table-invoice">
                                            <tbody>
                                              <tr>
                                                <td className="receipt-table-invoice-content">
                                                  <h3 className="margin-top-16 margin-bottom-2 font-size-15 font-weight-bold">
                                                    {invoiceFullDetail.brandName}
                                                  </h3>
                                                  <h3 className="margin-bottom-0 font-weight-bold" style={{ fontSize: '12px', marginTop: '5px' }}>
                                                    {invoiceFullDetail.businessEntityName}
                                                  </h3>
                                                  <h6 className="margin-bottom-0" style={{ fontSize: '11px' }}>
                                                    {invoiceFullDetail.branchName}
                                                  </h6>
                                                  <h6 className="margin-bottom-0" style={{ fontSize: '11px' }}>
                                                    {invoiceFullDetail.branchAddress}
                                                  </h6>
                                                  {/* <br/> */}
                                                  <h6 className="margin-bottom-0" style={{ fontSize: '11px' }}>
                                                    TEL: {invoiceFullDetail.branchContactNo}
                                                  </h6>
                                                  <hr />
                                                  <Row>
                                                    <Col sm="12">
                                                      <div className="margin-bottom-0">
                                                        <strong>{intl.formatMessage({ id: "Date" })}:</strong> &nbsp;
                                                        <span>{moment(invoiceFullDetail.issueDate).format(Constants.displayDateFormat)}</span> &nbsp;
                                                        <span>{moment(invoiceFullDetail.issueDate).format(Constants.displayTimeFormat)}</span>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ borderBottom: "none", padding: "5px 0", }}>
                                                  <Table>
                                                    <thead>
                                                      <tr style={{ width: '100%' }}>
                                                        <th className="padding-left-0">{intl.formatMessage({ id: "Quantity" })}</th>
                                                        <th className="padding-left-0">{intl.formatMessage({ id: "Name" })}</th>
                                                        <th className="text-end padding-right-0">{intl.formatMessage({ id: "Price" })}</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        invoiceFullDetail.invoiceDetails.map((item, key) => (
                                                          <tr key={key}>
                                                            <td className="recept-table-billing-list" valign="top">
                                                              {item.count} </td>
                                                            <td className="recept-table-billing-list" valign="top">
                                                              {item.itemName} </td>
                                                            <td className="recept-table-billing-list alignright" align="right" valign="top">
                                                               {returnPriceWithCurrency(item.unitPrice)} </td>
                                                          </tr>
                                                        ))
                                                      }
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "SubTotal" })}`, invoiceFullDetail.subTotalAmount)}
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "SubTotalDiscount" })}`, invoiceFullDetail.discountAmount)}
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "SubTotalTax" })}`, invoiceFullDetail.taxAmount)}
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "Total" })}`, invoiceFullDetail.totalAmount)}
                                                      {
                                                        invoiceFullDetail.paymentHistories.map((item, key) => (
                                                            displayCustomRowData(2, item.paymentMethodName, item.paidAmount)
                                                        ))
                                                      }
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "Paid" })}`, invoiceFullDetail.totalPayment)}
                                                      {displayCustomRowData(2, `${intl.formatMessage({ id: "Balance" })}`, invoiceFullDetail.balance)}
                                                    </tbody>
                                                  </Table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="receipt-table-body-content-block" align="center" valign="top">
                                          {intl.formatMessage({ id: "ThankYouForChoosingWithField" }, {brandName: getBrandName()})}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          className="receipt-table-body-content-block" valign="top">
                                          © {new Date().getFullYear()} {getBrandName()}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(ReceiptViewUI);
