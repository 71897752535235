import { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Label,
  FormFeedback} from "reactstrap";
import _ from 'lodash';
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { AddOptionsValueField, newSpaceBeforeCapitalLetter, returnCurrency } from "../../../app/common/function/function";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { Constants } from "../../../app/constants/Constants";
import Loading from "../../../app/components/loading/Loading";
import { IssuerBanksListObject } from "../../../app/models/paymentMethodOption";
import { ChangePaymentModePaymentMethodListObject } from "../../../app/models/changePaymentMode";

interface Props {
  blnShow: boolean;
  setModal: Function;
  cardType: StaticReferenceDataObject[];
  branchPaymentMethod: ChangePaymentModePaymentMethodListObject[];
  selectedNewPaymentIndex: number | undefined;
  setSelectedNewPaymentIndex: Function;
  validation: any;
}

export default function NewPaymentModal(props: Props) {
  const intl = useIntl();
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
  const [loadingIssuerBank, setLoadingIssuerBank] = useState(false);
  const [localIssuerBankList, setLocalIssuerBankList] = useState<IssuerBanksListObject[]>([]);

  let selectedIndex = props.selectedNewPaymentIndex ? props.selectedNewPaymentIndex-1 : -1
  const [state, setState] = useState(
      selectedIndex > -1 
      ?
      props.validation.values.newPaymentDetails[selectedIndex]
      :
      {
        paymentMethodId: "",
        paymentMethodName: "",
        amount: 0,
        cardType: "",
        transactionNo: "",
        paymentMethodOptionId: undefined,
        paymentMethodOptionName: "",
        optionTypeTitle: "",
        optionTypeList: [],
        isCardInput: false,
        isQRPay: false,
        isCustomerCredit: false,
        isTransferCredit: false,
        paymentIssuerBankId: null,
        paymentIssuerBankName: "",
      }
  );

  const errorStateDefault = {
    cardType: false,
    amount: false,
    paymentMethodName: false,
    paymentMethodOptionId: false,
    transactionNo: false,
    paymentIssuerBankId: false,
    cardOrAccountNo: false
  }

  const [errorState, setErrorState] = useState(_.cloneDeep(errorStateDefault))

  const [optionsPaymentMethod, setOptionsPaymentMethod] = useState(AddOptionsValueField(props.branchPaymentMethod, "name", "id"));
  const optionsCardType = AddOptionsValueField(props.cardType, "displayValue", "displayValue");

  const cardValid = state.isCardInput
  const qrValid = state.isQRPay
  const customerCreditValid = state.isCustomerCredit
  const transferCreditValid = state.isTransferCredit
  const onlineTransferOrChequeValid = !cardValid && !qrValid && !customerCreditValid && !transferCreditValid && state.optionTypeList.length > 0;

  useEffect(()=> {
    setOptionsPaymentMethod(AddOptionsValueField(props.branchPaymentMethod, "name", "id"))
    if (selectedIndex > -1) {
      let newPaymentsTemp : any = _.cloneDeep(props.validation.values.newPaymentDetails[selectedIndex]);
      if (newPaymentsTemp.issuerBanks) {
        setLocalIssuerBankList(AddOptionsValueField(newPaymentsTemp.issuerBanks, "name", "id") || []);
      }
    }
  }, [])

  const hideModal = () => {
    props.setModal(false);
    props.setSelectedNewPaymentIndex(undefined);
  }

  const onChangePaymentMethod = async (e: any) => {
    setLoadingPaymentMethod(true);
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodId = e.id;
    stateTemp.paymentMethodName = e.name;
    stateTemp.cardType = "";
    stateTemp.paymentMethodOptionName = "";
    stateTemp.paymentMethodOptionId = undefined;
    stateTemp.optionTypeTitle = "";
    stateTemp.optionTypeList = [];
    stateTemp.isCardInput = e.isCardInput;
    stateTemp.isQRPay = e.isQRPay;
    stateTemp.paymentIssuerBankId = null;
    stateTemp.paymentIssuerBankName = "";

    setLocalIssuerBankList([]);
    
    setTimeout(()=> {
      if (e.isOptionAvailable && e.paymentMethodOptionType !== Constants.none) {
        stateTemp.optionTypeTitle = newSpaceBeforeCapitalLetter(e.paymentMethodOptionType)
        stateTemp.optionTypeList = AddOptionsValueField(e.paymentMethodOptions, "name", "id")
      }
      setState(stateTemp);
      setLoadingPaymentMethod(false);
    }, 100)
  }

  const onChangeCardType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.cardType = e.displayValue;
    setState(stateTemp);
  }

  const onChangeOptionType = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentMethodOptionName = e.label;
    stateTemp.paymentMethodOptionId = e.value;
    stateTemp.paymentIssuerBankId = null;
    stateTemp.paymentIssuerBankName = "";
    stateTemp.issuerBanks = e.issuerBanks;

    setState(stateTemp);
    setLoadingIssuerBank(true);
    setLocalIssuerBankList(AddOptionsValueField(e.issuerBanks, "name", "id") || []);
    setTimeout(()=> {
      setLoadingIssuerBank(false);
    }, 100)
  }

  const onChangeIssuerBank = (e: any) => {
    let stateTemp = _.cloneDeep(state);
    stateTemp.paymentIssuerBankId = e.value;
    stateTemp.paymentIssuerBankName = e.label;

    setState(stateTemp);
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "NewPayment" })}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          loadingPaymentMethod
          ?
          <Loading className="margin-bottom-16" />
          :
          <>
            { 
              <div>
                <Col>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "BankName" })}
                    name="bankName"
                    type="text"
                    field={state}
                    setFieldValue={setState}
                  />
                </Col>
                <Col>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "CardOrAccountNo" })}
                    name="cardOrAccountNo"
                    className={errorState.cardOrAccountNo ? "mb-0" : "mb-3"}
                    type="text"
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.cardOrAccountNo) {
                        setErrorState((value) => ({...value, cardOrAccountNo: false}))
                      }
                    }}
                  />
                  {errorState.cardOrAccountNo ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "CardOrAccountNo"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "PaymentMethod" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodName ? "mb-0" : "mb-3"}
                  options={optionsPaymentMethod}
                  onChange={onChangePaymentMethod}
                  blnReturnFullValue={true}
                  onBlur={()=> {
                    if (errorState.paymentMethodName) {
                      setErrorState((value) => ({...value, paymentMethodName: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodName}
                  initialValue={state.paymentMethodId}
                  validationRequired={true}
                />
                {errorState.paymentMethodName ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "PaymentMethod"})})}`}</FormFeedback>
                ) : null}
              </div>
            }
            {
              cardValid
              &&
              <Row>
                <Col>
                  <Label>
                    <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                    <Label className="margin-bottom-0">{intl.formatMessage({ id: "Credit/DebitCardType" })}</Label>
                  </Label>
                  <GeneralSelectDropdown
                    title=""
                    name=""
                    className={errorState.cardType ? "mb-0" : "mb-3"}
                    options={optionsCardType}
                    blnValueWithNewSpace={true}
                    blnReturnFullValue={true}
                    onChange={onChangeCardType}
                    onBlur={()=> {
                      if (errorState.cardType) {
                        setErrorState((value) => ({...value, cardType: false}))
                      }
                    }}
                    initialLabel={state.cardType}
                    initialValue={state.cardType}
                    validationRequired={true}
                  />
                  {errorState.cardType ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "CardType"})})}`}</FormFeedback>
                  ) : null}
                </Col>
              </Row>
            }
            {
              state.optionTypeList.length > 0
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{state.optionTypeTitle}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodOptionId ? "mb-0" : "mb-3"}
                  options={state.optionTypeList}
                  blnReturnFullValue={true}
                  onChange={onChangeOptionType}
                  onBlur={()=> {
                    if (errorState.paymentMethodOptionId) {
                      setErrorState((value) => ({...value, paymentMethodOptionId: false}))
                    }
                  }}
                  initialLabel={state.paymentMethodOptionName}
                  initialValue={state.paymentMethodOptionId}
                  validationRequired={true}
                />
                {errorState.paymentMethodOptionId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: state.optionTypeTitle})}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              loadingIssuerBank
              ?
              <Loading/>
              :
              localIssuerBankList.length > 0
              &&
              <Col>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "IssuerBanks" })}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentIssuerBankId ? "mb-0" : "mb-3"}
                  options={localIssuerBankList}
                  blnReturnFullValue={true}
                  onChange={onChangeIssuerBank}
                  initialLabel={state.paymentIssuerBankName || ""}
                  initialValue={state.paymentIssuerBankId || ""}
                  onBlur={()=> {
                    if (errorState.paymentIssuerBankId) {
                      setErrorState((value) => ({...value, paymentIssuerBankId: false}))
                    }
                  }}
                  validationRequired={true}
                />
                {errorState.paymentIssuerBankId ? (
                  <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IssuerBanks" }) })}`}</FormFeedback>
                ) : null}
              </Col>
            }
            {
              <Row>
                <Col xl={(cardValid || qrValid) ? 6 : 12}>
                  <GeneralInputForModal
                    title={intl.formatMessage({ id: "Amount" })}
                    name="amount"
                    className={errorState.amount ? "mb-0" : "mb-3"}
                    type="number"
                    inputGroup={true}
                    inputGroupText={returnCurrency()}
                    validationRequired={true}
                    field={state}
                    setFieldValue={setState}
                    onBlur={()=> {
                      if (errorState.amount) {
                        setErrorState((value) => ({...value, amount: false}))
                      }
                    }}
                    blnDisableNegativeValue={true}
                  />
                  {errorState.amount ? (
                    <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "Amount"})})}`}</FormFeedback>
                  ) : null}
                </Col>
                {
                  (cardValid || qrValid || onlineTransferOrChequeValid)
                  &&
                  <Col>
                    <GeneralInputForModal
                      title={intl.formatMessage({ id: qrValid || onlineTransferOrChequeValid ? "TransactionNo" : "ApprovalCode"})}
                      name="transactionNo"
                      className={errorState.transactionNo ? "mb-0" : "mb-3"}
                      type={"text"}
                      typeExcludeNumberType={true}
                      field={state}
                      setFieldValue={setState}
                      validationRequired={!qrValid && !onlineTransferOrChequeValid}
                      {...!qrValid && !onlineTransferOrChequeValid && {maxLength: 6}}
                    />
                    {errorState.transactionNo ? (
                      <FormFeedback className="mb-3" type="invalid">{`${intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({id: "ApprovalCode"})})}`}</FormFeedback>
                    ) : null}
                  </Col>
                }
              </Row>
            }
          </>
        }
        <div className="">
          <MyButton
            type="button"
            class="btn btn-primary"
            content={intl.formatMessage({ id: "Save" })}
            disable={loadingPaymentMethod}
            onClick={()=> {
              let errorStateTemp = _.cloneDeep(errorStateDefault);

              let blnErrorFound = false;
              if (!state.paymentMethodName) {
                errorStateTemp.paymentMethodName = true;
                blnErrorFound = true;
              }

              if (cardValid) {
                if (!state.cardType) {
                  errorStateTemp.cardType = true;
                  blnErrorFound = true;
                }
                if (String(state.transactionNo).length !== 6) {
                  errorStateTemp.transactionNo = true;
                  blnErrorFound = true;
                }
              }

              if (!state.amount) {
                errorStateTemp.amount = true;
                blnErrorFound = true;
              }

              if (state.optionTypeList.length > 0 && !state.paymentMethodOptionId) {
                errorStateTemp.paymentMethodOptionId = true;
                blnErrorFound = true;
              }

              if (localIssuerBankList.length > 0 && !state.paymentIssuerBankId) {
                errorStateTemp.paymentIssuerBankId = true;
                blnErrorFound = true;
              }

              if (state.bankName && !state.cardOrAccountNo) {
                errorStateTemp.cardOrAccountNo = true;
                blnErrorFound = true;
              }

              state.amount = Number(state.amount);

              if (blnErrorFound) {
                setErrorState(errorStateTemp)
              }
              else {
                let newPaymentsTemp = _.cloneDeep(props.validation.values.newPaymentDetails);
                if (selectedIndex > -1) {
                  newPaymentsTemp[selectedIndex] = state;
                }
                else {
                  newPaymentsTemp.push(state);
                }
                newPaymentsTemp = newPaymentsTemp.map((valueNewPaymentTemp, indexNewPaymentTemp)=> ({
                  ...valueNewPaymentTemp,
                  randomId: `new_payment_index_${indexNewPaymentTemp}`
                }))
                props.validation.setFieldValue("newPaymentDetails", newPaymentsTemp)
                hideModal();
              }
            }}/>
          </div>
        </div>
      </Modal>
  )
}