import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Label,
} from "reactstrap";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, GeneralSnipButtonObject } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import { Link } from "react-router-dom";
import moment from "moment";
import GeneralSnipButton from "../../../app/components/form/GeneralSnipButton";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";

const ProductSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, windowSize, setErrorMessage } = commonStore;
  const { productSalesDetails, getProductSalesDetails, setProductSalesDetails, exportProductSalesDetails } = reportSalesStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedProductAppolous, setSelectedProductAppolous] = useState(0);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionViewCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionViewAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  const aryProductSnipButton : GeneralSnipButtonObject[]= [
    {
      buttonName: intl.formatMessage({ id: "ERP" }),
      onClick: ()=> {
        setSelectedProductAppolous(0);
      }
    },
    {
      buttonName: intl.formatMessage({ id: "Appolous" }),
      onClick: ()=> {
        setSelectedProductAppolous(1)
      },
      color: "btn-info",
      outlineColor: "btn-outline-info-color"
    }
  ]
  
  useEffect(() => {
    async function fetchProductSalesDetails() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchProductSalesDetails();

    return (() => {
      setProductSalesDetails(undefined);
    })
  }, [])

  const fetchProductSalesDetailsWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }
    else if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportProductSalesDetails({       
        pageNumber: 1,
        pageSize: pageSize,
        branchId: selectedBranchId,
        dateRange: selectedDateRange
      });
    }
    else{
      await getProductSalesDetails({
        branchId: selectedBranchId,
        dateRange: selectedDateRange
      });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const PurchasedProductSalesDetailsColumns = [
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.submissionDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.submissionDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "salesOrderNo",
      text: intl.formatMessage({ id: "CustomerSalesOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomerSalesOrder
            ?
            <Link to={row.salesOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.salesOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.salesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.salesOrderNo}</div>
          }          
        </>
      )
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "ProductName" }).toUpperCase()
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Count" }).toUpperCase()
    },
    {
      dataField: "originalPrice",
      text: intl.formatMessage({ id: "OriginalPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.originalPrice) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "discountType",
      text: intl.formatMessage({ id: "DiscountType" }).toUpperCase()
    },
    {
      dataField: "discountValue",
      text: intl.formatMessage({ id: "DiscountValue" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.discountValue) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "totalPrice",
      text: intl.formatMessage({ id: "TotalPrice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.totalPrice) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "hasCreditPayment",
      text: intl.formatMessage({ id: "CreditPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.hasCreditPayment ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "creditAmount",
      text: intl.formatMessage({ id: "CreditAmount" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.creditAmount) || Constants.emptyData}
        </div>
      ),
    },
  ];

  const AppolousProductSalesDetailsColumns = [
    {
      dataField: "paymentDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.paymentDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.paymentDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "performaInvoiceNo",
      text: `${intl.formatMessage({ id: "InvoiceNo" }).toUpperCase()}.`,
      formatter: (cellContent, row) => 
      <>
        {
          blnPermissionViewAppolousSales
          ?
          <Link to={row.appolousSaleId ? `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.appolousSaleId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.performaInvoiceNo || Constants.emptyData}</Label>
          </Link>
          :
          row.performaInvoiceNo || Constants.emptyData
        }
      </>
    },
    {
      dataField: "productSale",
      text: intl.formatMessage({ id: "ProductSale" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.productSale) || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "productCommission",
      text: intl.formatMessage({ id: "ProductCommission" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {returnPriceWithCurrency(row.productCommission) || Constants.emptyData}
        </div>
      ),
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ProductSalesDetails" }) })}
        title={intl.formatMessage({ id: "ProductSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchProductSalesDetailsWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Product-Sales-Details.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownList}
              labelField="name"
              valueField="id"
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchProductSalesDetailsWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <div>
                <GeneralSnipButton
                  name="productSnipButton"
                  className="z-index-0"
                  aryButton={aryProductSnipButton}
                  selectedIndex={selectedProductAppolous}
                  setSelectedIndex={setSelectedProductAppolous}/>
              </div>
              <div className="standard-layout mt-3" style={{paddingTop: "40px"}}>
                {
                  selectedProductAppolous == 0
                  ?
                  <div key={"ERPProductSalesDetails"}>
                    <PaginationTableWithoutApi 
                      title={intl.formatMessage({ id: "ProductSalesDetails" })}
                      blnDisplayForMainListing={true}
                      options={productSalesDetails?.erpProductSalesDetails || []}
                      columns={PurchasedProductSalesDetailsColumns}
                      pageSize={Constants.defaultPageSize}
                      keyField={"randomId"}/>
                  </div>
                  :
                  <div key={"ApplousProductSalesDetails"}>
                    <PaginationTableWithoutApi 
                      title={intl.formatMessage({ id: "ProductSalesDetails" })}
                      blnDisplayForMainListing={true}
                      options={productSalesDetails?.appolousProductSalesDetails || []}
                      columns={AppolousProductSalesDetailsColumns}
                      pageSize={Constants.defaultPageSize}
                      keyField={"randomId"}/>
                  </div>
                }
              </div>
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ProductSalesDetails);
