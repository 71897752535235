import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { AppointmentOverviewSummaryExportObject, AppointmentOverviewSummaryGetObject, AppointmentOverviewSummaryListObject, CSCommissionExportObject, CSDailyAppointmentExportObject, CustomerTurnedUpListObject, LeadAppointmentsListObject, LeadDetailsListObject, LeadsSummaryByEmployeeExportObject, LeadsSummaryByMediaExportObject, LeadSummaryByEmployeeListGetObject, LeadSummaryByEmployeeListObject, LeadSummaryByMediaListGetObject, LeadSummaryByMediaListObject, LeadTurnUpGetAndExportObject, LeadTurnUpListObject, PromoCodeHistoryListObject, SundayMarcomReportExportObject, TodayAppointmentMadeSummaryExportObject, NewVisitVsTurnUpGetAndExportObject, NewVisitVsTurnUpDetailObject } from "../models/reportMarcom";
import { calculateDivHeight, convertArrayToStringDisplay, downloadExcel } from "../common/function/function";

export default class ReportMarcomStore {
  leadsSummaryByMediaList: LeadSummaryByMediaListObject[] = [];
  leadsSummaryByEmployeeList: LeadSummaryByEmployeeListObject[] = [];
  leadsDetailsList: LeadDetailsListObject[] = [];
  promoCodeHistoryList: PromoCodeHistoryListObject[] = [];
  customerTurnedUpList: CustomerTurnedUpListObject[] = [];
  leadAppointmentsList: LeadAppointmentsListObject[] = [];
  appointmentOverviewSummaryList: AppointmentOverviewSummaryListObject[] = [];
  leadTurnUpList: LeadTurnUpListObject[] = [];
  newVisitVsTurnUpDetail: NewVisitVsTurnUpDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.leadsSummaryByMediaList = [];
    this.leadsSummaryByEmployeeList = [];
    this.leadsDetailsList = [];
    this.promoCodeHistoryList = [];
    this.customerTurnedUpList = [];
    this.leadAppointmentsList = [];
    this.appointmentOverviewSummaryList  = [];
    this.leadTurnUpList = [];
    this.newVisitVsTurnUpDetail = undefined;
  }

  setLeadsSummaryByMediaList = (leadSummaryByMediaList: LeadSummaryByMediaListObject[]) => {
    this.leadsSummaryByMediaList = leadSummaryByMediaList;
  }

  setLeadsSummaryByEmployeeList = (leadSummaryByEmployeeList: LeadSummaryByEmployeeListObject[]) => {
    this.leadsSummaryByEmployeeList = leadSummaryByEmployeeList;
  }

  setLeadsDetailsList = (leadDetailsListObject: LeadDetailsListObject[]) => {
    this.leadsDetailsList = leadDetailsListObject;
  }

  setPromoCodeHistoryList = (promoCodeHistoryList: PromoCodeHistoryListObject[]) => {
    this.promoCodeHistoryList = promoCodeHistoryList;
  }

  setCustomerTurnedUpList = (customerTurnedUpList: CustomerTurnedUpListObject[]) => {
    this.customerTurnedUpList = customerTurnedUpList;
  }

  setLeadAppointmentsList = (leadAppointmentsList: LeadAppointmentsListObject[]) => {
    this.leadAppointmentsList = leadAppointmentsList;
  }

  setAppointmentOverviewSummaryList = (appointmentOverviewSummaryList: AppointmentOverviewSummaryListObject[]) => {
    this.appointmentOverviewSummaryList = appointmentOverviewSummaryList;
  }

  setLeadTurnUpList = (leadTurnUpList: LeadTurnUpListObject[]) => {
    this.leadTurnUpList = leadTurnUpList;
  }
  
  setNewVisitVsTurnUpDetail = (newVisitVsTurnUpDetail: NewVisitVsTurnUpDetailObject | undefined) => {
    this.newVisitVsTurnUpDetail = newVisitVsTurnUpDetail;
  }

  getLeadsSummaryByMedia = async (leadSummaryByMediaListGetObject: LeadSummaryByMediaListGetObject) => {
    try{
      let resultLeadsByMediaSummary : LeadSummaryByMediaListObject[] = await agent.ReportMarcom.leadsSummaryByMediaList(leadSummaryByMediaListGetObject);
      resultLeadsByMediaSummary = resultLeadsByMediaSummary.map((valueResultLeadsByMediaSummary)=> {
        valueResultLeadsByMediaSummary.leadsSummaryDetails = valueResultLeadsByMediaSummary.leadsSummaryDetails.map((valueLeadsSummaryByMediaDetailsTemp, index)=> ({
          ...valueLeadsSummaryByMediaDetailsTemp,
          randomId: `${valueResultLeadsByMediaSummary.sourceName}_${index}`
        }))
        return({
          ...valueResultLeadsByMediaSummary
        })
      })

      runInAction(() => {
        this.leadsSummaryByMediaList = resultLeadsByMediaSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadsSummaryByMediaList = [];
    }
  }

  getLeadsByEmployeeSummary = async (leadSummaryByEmployeeListGetObject: LeadSummaryByEmployeeListGetObject) => {
    try{
      let resultLeadsByEmployeeSummary : LeadSummaryByEmployeeListObject[] = await agent.ReportMarcom.leadsSummaryByEmployeeList(leadSummaryByEmployeeListGetObject);
      resultLeadsByEmployeeSummary = resultLeadsByEmployeeSummary.map((valueResultLeadsByEmployeeSummary)=> {
        valueResultLeadsByEmployeeSummary.leadsSummaryDetails = valueResultLeadsByEmployeeSummary.leadsSummaryDetails.map((valueLeadsSummaryByEmployeeDetailsTemp, index)=> ({
          ...valueLeadsSummaryByEmployeeDetailsTemp,
          randomId: `${valueResultLeadsByEmployeeSummary.sourceName}_${index}`
        }))
        return({
          ...valueResultLeadsByEmployeeSummary
        })
      })

      runInAction(() => {
        this.leadsSummaryByEmployeeList = resultLeadsByEmployeeSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadsSummaryByEmployeeList = [];
    }
  }

  getLeadsDetailList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultLeadDetails = await agent.ReportMarcom.leadsDetailList(PaginationRequestBody);
      runInAction(() => {
        this.leadsDetailsList = resultLeadDetails.data;
        store.commonStore.setTotalItem(resultLeadDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadsDetailsList = [];
    }
  }

  getPromoCodeHistoryList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPromoCodeHistory = await agent.ReportMarcom.promoCodeHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.promoCodeHistoryList = resultPromoCodeHistory.data;
        store.commonStore.setTotalItem(resultPromoCodeHistory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.promoCodeHistoryList = [];
    }
  }

  getCustomerTurnedUpList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerTurnedUp = await agent.ReportMarcom.customerTurnedUpList(PaginationRequestBody);
      resultCustomerTurnedUp.data = resultCustomerTurnedUp.data.map((valueResultCustomerTurnedUp, index)=> {
        return({
          ...valueResultCustomerTurnedUp,
          randomId: `${valueResultCustomerTurnedUp.customerNo}_${index}`, 
        })
      })
      runInAction(() => {
        this.customerTurnedUpList = resultCustomerTurnedUp.data;
        store.commonStore.setTotalItem(resultCustomerTurnedUp.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerTurnedUpList = [];
    }
  }

  getLeadAppointmentsList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultLeadAppointments = await agent.ReportMarcom.leadAppointments(PaginationRequestBody);
      runInAction(() => {
        this.leadAppointmentsList = resultLeadAppointments.data;
        store.commonStore.setTotalItem(resultLeadAppointments.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadAppointmentsList = [];
    }
  }
  
  getAppointmentOverviewSummary = async (appointmentOverviewSummaryGetObject: AppointmentOverviewSummaryGetObject) => {
    try{
      let resultAppointmentOverviewSummary: any = await agent.ReportMarcom.appointmentOverviewSummary(appointmentOverviewSummaryGetObject);
      resultAppointmentOverviewSummary = resultAppointmentOverviewSummary.map((valueAppointmentOverviewSummary)=> {
        valueAppointmentOverviewSummary.appointmentOverviewSummaryDetails = valueAppointmentOverviewSummary.appointmentOverviewSummaryDetails.map((valueAppointmentOverviewSummaryDetail)=> {
          let divHeightTemp : any = undefined;
          if (valueAppointmentOverviewSummaryDetail.marcomCsNames) {
            divHeightTemp = Math.max(calculateDivHeight(convertArrayToStringDisplay(valueAppointmentOverviewSummaryDetail.marcomCsNames), 100, 16, 1.5), 50);
          }
          return ({
            ...valueAppointmentOverviewSummaryDetail,
            ...divHeightTemp && {divHeight: divHeightTemp}
          })
        });

        if (valueAppointmentOverviewSummary.appointmentOverviewSummaryDetails.length > 1) {
          valueAppointmentOverviewSummary.appointmentOverviewSummaryDetails.push({
            target: valueAppointmentOverviewSummary.target,
            totalTurnedUp: valueAppointmentOverviewSummary.totalTurnedUp,
            turnedUpPercentage: valueAppointmentOverviewSummary.turnedUpPercentage,
            leadAppt: valueAppointmentOverviewSummary.leadAppt,
            telerate: valueAppointmentOverviewSummary.telerate,
            isTotalField: true
          })
        }
        return valueAppointmentOverviewSummary;
      })
      runInAction(() => {
        this.appointmentOverviewSummaryList = resultAppointmentOverviewSummary;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentOverviewSummaryList = [];
    }
  }

  getLeadTurnUpList = async (leadTurnUpGetAndExportObject: LeadTurnUpGetAndExportObject) => {
    try{
      const resultLeadTurnUp = await agent.ReportMarcom.leadTurnUpList(leadTurnUpGetAndExportObject);
      runInAction(() => {
        this.leadTurnUpList = resultLeadTurnUp;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.leadTurnUpList = [];
    }
  }

  getNewVisitVsTurnUpDetail = async (newVisitVsTurnUpGetAndExportObject : NewVisitVsTurnUpGetAndExportObject) => {
    try {
      const resultNewVisitVsTurnUpDetail = await agent.ReportMarcom.newVisitVsTurnUpDetail(newVisitVsTurnUpGetAndExportObject);
      runInAction(() => {
        this.newVisitVsTurnUpDetail = resultNewVisitVsTurnUpDetail;
      })
    } catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.newVisitVsTurnUpDetail = undefined;
    }
  }

  exportLeadsSummaryByEmployee = async (leadsSummaryByEmployeeExportRequestBody: LeadsSummaryByEmployeeExportObject) => {
    try{
      await downloadExcel(`/report/marcom/leadssummarybyemployee/export`, leadsSummaryByEmployeeExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportLeadsSummaryByMedia = async (leadsSummaryByMediaExportRequestBody: LeadsSummaryByMediaExportObject) => {
    try{
      await downloadExcel(`/report/marcom/leadssummarybymedia/export`, leadsSummaryByMediaExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportLeadsDetails = async (PaginationRequestBody: PaginationRequestBody | any) => {
    try{
      await downloadExcel(`/report/marcom/leadsdetails/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportPromoCodeHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/marcom/promocodehistory/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportCustomerTurnedUp = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/marcom/customerturnedup/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportLeadAppointments = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/marcom/leadappointments/export`, PaginationRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppointmentOverviewSummary = async (appointmentOverviewSummaryExportRequestBody: AppointmentOverviewSummaryExportObject) => {
    try{
      await downloadExcel(`/report/marcom/overview/export`, appointmentOverviewSummaryExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportLeadTurnUp = async (leadTurnUpGetAndExportObject: LeadTurnUpGetAndExportObject) => {
    try{
      await downloadExcel(`/report/marcom/leadturnup/export`, leadTurnUpGetAndExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportSundayMarcom = async (sundayMarcomReportExportObject: SundayMarcomReportExportObject) => {
    try{
      await downloadExcel(`/report/marcom/sunday/export`, sundayMarcomReportExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportTodayAppointmentMadeSummary = async (todayAppointmentMadeSummaryExportObject: TodayAppointmentMadeSummaryExportObject) => {
    try{
      await downloadExcel(`/report/marcom/todayappointmentmadesummary/export`, todayAppointmentMadeSummaryExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportDailyMarcomAppointment = async (csDailyAppointmentExportObject: CSDailyAppointmentExportObject) => {
    try {
      await downloadExcel(`/report/marcom/csdailyappointment/export` , csDailyAppointmentExportObject);
    } catch (error: any) {
      console.log(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
  
  exportCSCommission = async (CSCommissionExportObject: CSCommissionExportObject) => {
    try{
      await downloadExcel(`/report/marcom/cscommission/export`, CSCommissionExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportNewVisitVsTurnUpList = async (newVisitVsTurnUpGetAndExportObject : NewVisitVsTurnUpGetAndExportObject) => {
    try {
      await downloadExcel(`/report/marcom/newvisitvsturnup/export`, newVisitVsTurnUpGetAndExportObject)
    } catch (error: any) {
      console.log(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}