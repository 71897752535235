import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import { useIntl } from "react-intl";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { AppointmentSubmitObject } from "../../app/models/setting";

const AppointmentSettingsDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { settingsStore, commonStore, staticReferenceStore } = useStore();
  const { errorMessage, successMessage, loading, setLoading, setErrorMessage, setSuccessMessage } = commonStore;
  const { appointmentSettings, getAppointmentSettings, updateAppointmentSettings, setAppointmentSettingsDetail } = settingsStore;
  const { calendarViewType, bookingSlotIntervalType, getStaticReferenceWithType } = staticReferenceStore;
  const displayTitle = `${intl.formatMessage({ id: "AppointmentSettings" })}`;
  const [blnSubmitting, setBlnSubmitting] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: appointmentSettings || {
      id: "",
      calendarDisplayInterval: 0,
      restrictBranchAccess: false,
      draggable: false,
      defaultViewType: 0,
      defaultViewTypeName: "",
      allDaySlot: false,
      displayDescription: false,
      bookingSlotInterval: 0,
      bookingSlotIntervalName: "",
      isMediaSourceMandatory: false,
      isEmailMandatory: false,
      bookingPageExtraInfo: "",
      dateTimePageExtraInfo: "",
      reminderEmailExtraInfo: "",
      confirmationTimePageExtraInfo: ""
    },
    validationSchema: Yup.object({
      defaultViewType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "CalendarViewType" }) })),
      bookingSlotInterval: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "BookingSlotInterval" }) })),
      calendarDisplayInterval: Yup.number().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CalendarDisplayInterval" }) })),
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      if (!blnEditAction) {
        try {
          let valuesFinal: AppointmentSubmitObject = {
            id: valuesTemp.id,
            values: {...valuesTemp}
          }
          await updateAppointmentSettings(valuesFinal);
        } finally {
          validation.setSubmitting(false);
        }
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnEditAction = !checkPermission([PermissionConstants.UpdateAppointmentSetting])

  useEffect(() => {
    async function fetchAppointmentSettingsDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getAppointmentSettings(),
        getStaticReferenceWithType(Constants.calendarViewType),
        getStaticReferenceWithType(Constants.bookingSlotIntervalType)
      ];

      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointmentSetting], true)) {
      return;
    }

    fetchAppointmentSettingsDetailAPI();

    return (() => {
      if (id) {
        setAppointmentSettingsDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={[]}
        auditTrailId={undefined}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {/* +true = Convert boolean to numbers*/}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "AppointmentSettings" }) })}
                  h4Title />
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"defaultViewType"}
                    className="mb-3 mt-4"
                    title={intl.formatMessage({ id: "CalendarViewType" })}
                    specifyReturnFieldName={[
                      {
                        field: "defaultViewType",
                        value: "key",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"displayValue"}
                    options={calendarViewType}
                    validationRequired={true}
                    disabled={disabledFieldInput || blnEditAction}
                    initialValue={validation.values.defaultViewTypeName}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <DropDownWithTitle
                    name={"bookingSlotInterval"}
                    title={intl.formatMessage({ id: "BookingSlotInterval" })}
                    specifyReturnFieldName={[
                      {
                        field: "bookingSlotInterval",
                        value: "key",
                      },
                    ]}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={bookingSlotIntervalType}
                    validationRequired={true}
                    disabled={disabledFieldInput || blnEditAction}
                    initialValue={validation.values.bookingSlotIntervalName}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "CalendarDisplayInterval" })}
                    name="calendarDisplayInterval"
                    type="text"
                    disabled={disabledFieldInput || blnEditAction}
                    validationRequired={true}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "LabelWithExtraInfo" }, { field: intl.formatMessage({ id: "BookingPage" }) })}
                    name="bookingPageExtraInfo"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "LabelWithExtraInfo" }, { field: intl.formatMessage({ id: "DateTimePage" }) })}
                    name="dateTimePageExtraInfo"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "LabelWithExtraInfo" }, { field: intl.formatMessage({ id: "ReminderEmail" }) })}
                    name="reminderEmailExtraInfo"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "LabelWithExtraInfo" }, { field: intl.formatMessage({ id: "ConfirmationTimePage" }) })}
                    name="confirmationTimePageExtraInfo"
                    row={5}
                    disabled={disabledFieldInput || blnEditAction}
                    validation={validation} />
                </SingleColumnRowParent>
                <Row>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "AllDaySlot" })}
                      name="allDaySlot"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "ReceiveNotification" })}
                      name="receiveNotification"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "DisplayDescription" })}
                      name="displayDescription"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "MediaSourceMandatory" })}
                      name="isMediaSourceMandatory"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "EmailMandatory" })}
                      name="isEmailMandatory"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          {intl.formatMessage({ id: "Yes" })}
                        </Label>
                      }
                    />
                  </Col>
                  <Col xs={3} md={3} xl={3}>
                    <GeneralInput
                      title={intl.formatMessage({ id: "Draggable" })}
                      name="draggable"
                      type="checkbox"
                      disabled={disabledFieldInput || blnEditAction}
                      validation={validation}
                      childrenUI={
                        <Label className="margin-bottom-0 margin-left-4">
                          Yes
                        </Label>
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <GeneralSubmitAndCancelBtn
              className="mt-2"
              successMessage={successMessage}
              viewAction={blnEditAction}
              validation={validation}
              blnMarginTopByInnerWidth={true}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(AppointmentSettingsDetail);
