import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, getBrandName, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnAdvancedSearchIcon, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import TableWithEditableInputFields from "../../app/components/table/TableWithEditableInputFields";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import { history } from "../..";
import Borderline from "../../app/components/form/BorderLine";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import MiniCard from "../../app/components/form/MiniCard";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import TableWithSelectAllCheckBoxAndInput from "../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import ProductRedemptionNoteConfimationModal from "./Modal/ProductRedemptionNoteConfimationModal";
import { ProductRedemptionEligibleListObject } from "../../app/models/productRedemptionNote";
import { CustomerListObject } from "../../app/models/customer";
import GeneralInput from "../../app/components/form/GeneralInput";

const ProductRedemptionNoteDetail = () => {
  //Use Params
  const { id } = useParams();
  const location = useLocation();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { productRedemptionNoteStore, customerStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { addProductRedemptionNote, getProductRedemptionEligibleWithId,  } = productRedemptionNoteStore;
  const displayTitle = `${intl.formatMessage({ id: "Add" })} ${intl.formatMessage({ id: "CustomerVirtualWarehouse" })}`;
  const [breadCrumbList, setBreadCrumbList] = useState([{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: RoutesList.customerVirtualWarehouse }, { title: intl.formatMessage({ id: "CustomerVirtualWarehouse" }), urlPath: RoutesList.customerVirtualWarehouse }]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [localCustomerDropdownList, setLocalCustomerDropdownList] = useState<CustomerListObject[]>([]);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [loadingProductRedemptionNote, setLoadingProductRedemptionNote] = useState(false); 
  const [productRedemptionNoteConfirmationModal, setProductRedemptionNoteConfirmationModal] = useState(false);
  const [blnBlockRedemptionSubmit, setBlnBlockRedemptionSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionManageCustomerVirtualWarehouse= checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);
  const blnPermissionViewCustomerDeliverOrder= checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionBackdateProductRedemptionNote= checkPermission([PermissionConstants.BackdateProductRedemptionNote]);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  const [blnAddFromCustomerProfile, setBlnAddFromCustomerProfile] = useState(false);
  const [blnCustomerDisabled, setBlnCustomerDisabled] = useState(false);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "VirtualWarehouseNo" }),
      type: "label",
      xl: "2.4",
      inputName: "virtualWarehouseNo",
      inputType: "text",
      linkPath: blnPermissionManageCustomerVirtualWarehouse ? `${RoutesList.customerVirtualWarehouse}/view/` : "",
      linkPathFieldName: "virtualWarehouseId"
    },
    {
      label: intl.formatMessage({ id: "BranchName" }),
      type: "label",
      xl: "2.4",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "DeliverOrderNo" }),
      type: "label",
      xl: "2.4",
      inputName: "deliverOrderNo",
      inputType: "text",
      linkPath: blnPermissionViewCustomerDeliverOrder ? `${RoutesList.customerRedemption}/view/` : "",
      linkPathFieldName: "deliverOrderId"
    },
    {
      label: intl.formatMessage({ id: "AvailableQuantity" }),
      type: "input",
      xl: "2.4",
      inputName: "availableQuantity",
      inputType: "number",
      disabled: true,
    },
    {
      label: intl.formatMessage({ id: "Quantity" }),
      type: "input",
      xl: "2.4",
      inputName: "quantity",
      inputType: "number",
      numberMaxFieldName: "availableQuantity"
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchid: getBranchId(),
      branchName: getBranchName(),
      customerId: "",
      customerName: "",
      isBackdate: false,
      performedDateTime: "",
      note: "",
      redeemDetails: [],
      redeemDetailsDisplay: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (!valuesTemp.customerId) {
          setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) }))
          return;
        }
        let blnProceed = false;
        let blnProceedEmptyQuantity = false
        let redeemDetailsDisplayTemp : ProductRedemptionEligibleListObject[] = [];
        if (valuesTemp) {
          if (valuesTemp.redeemDetailsDisplay) {
            for (let a=0; a<valuesTemp.redeemDetailsDisplay.length; a++) {
              let valueRedeemDetailsTemp = _.cloneDeep(valuesTemp.redeemDetailsDisplay[a]);
              if (valueRedeemDetailsTemp.isActive && valueRedeemDetailsTemp.quantity > 0) {
                redeemDetailsDisplayTemp.push(valueRedeemDetailsTemp);
                blnProceed = true;
              }
              else if (valueRedeemDetailsTemp.isActive && valueRedeemDetailsTemp.quantity < 1) {
                blnProceedEmptyQuantity = true;
                break;
              }
            }
          }
          
          valuesTemp.performedDateTime = valuesTemp.isBackdate ? moment(valuesTemp.performedDateTime).format(Constants.defaultDateFormat) : "";
        }

        if (blnProceedEmptyQuantity) {
          setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" }) }))
          return;
        }
        else if (!blnProceed) {
          setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Product" }) }))
          return;
        }
        else {
          if (!productRedemptionNoteConfirmationModal){
            validation.setFieldValue("redeemDetails", redeemDetailsDisplayTemp)
            prefillCustomerInfo(valuesTemp.customerId);
            setProductRedemptionNoteConfirmationModal(true);
          }
          else {
            setBlnBlockRedemptionSubmit(true);
            let result = await addProductRedemptionNote(valuesTemp);
            if (result?.status == Constants.success) {
              let valuesFinal : any = _.cloneDeep(values);
              if (result?.data) {
                valuesFinal = {
                  ...values,
                  productRedemptionNoteId: result?.data?.productRedemptionNoteId, 
                  productRedemptionNoteNo: result?.data?.productRedemptionNoteNo,
                  blnAddFromCustomerProfile: blnAddFromCustomerProfile           
                }
              } 
              setTimeout(() => {
                setSuccessMessage("")
                // history.push({
                //   pathname: `/${RoutesList.productRedemptionNoteAcknowledgement}`,
                //   state: { detail: valuesFinal }
                // });
                history.push({ pathname: `/${RoutesList.productRedemptionNote}/edit/${result?.data?.productRedemptionNoteId}`});
              }, Constants.dismissSuccessMessage)
            }
          }
        }
      } finally {
        setTimeout(()=> {
          setBlnBlockRedemptionSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchProductRedemptionNoteDetailAPI() {
      setLoading(true);
      if (location.pathname === `/${RoutesList.productRedemptionNote}/addFromCustomer` && Constants.supportSingleRedeem) {
        if (location) {
          if (location.state) {
              if (location.state.detail) {
              let resultAPI : any = await getCustomerDropdown("", {customerId: location.state.detail.customerId});
              setLocalCustomerDropdownList(resultAPI || []);
              validation.setValues((values)=> ({
                ...values,
                customerId: location.state.detail.customerId,
                customerName: location.state.detail.customerName,
                redeemDetailsDisplay: location.state.detail.redeemDetailsDisplay
              }))
              addProductFromCustomerProfileHandling();
            }
          }
        }
      }
      else{
        if (location) {
          if (location.state) {
            if (location.state.detail) {    
              if (location.state.detail.customerId && location.state.detail.deliverOrderId) {
                let aryAPI: any = [
                  getCustomerDropdown("", {customerId: location.state.detail.customerId}),
                ];
                let resultAPI = await Promise.all(aryAPI);
                fetchProductRedemptionNoteWithLoading(location.state.detail.customerId, location.state.detail.deliverOrderId, resultAPI[0])
                setBlnCustomerDisabled(true);
              }
              else if (location.state.detail.customerId && location.state.detail.customerName) {
                let aryAPI: any = [
                  getCustomerDropdown("", {customerId: location.state.detail.customerId}),
                ];
                let resultAPI = await Promise.all(aryAPI);
                fetchProductRedemptionNoteWithLoading(location.state.detail.customerId, "", resultAPI[0], location.state.detail.customerName, location.state.detail.redeemDetailsDisplay)

                if (location.pathname === `/${RoutesList.productRedemptionNote}/addFromCustomer` && !Constants.supportSingleRedeem) {
                  addProductFromCustomerProfileHandling();
                }
              }
            }
          }
        }
      }
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.CreateProductRedemptionNote], true)) {
      return;
    }

    fetchProductRedemptionNoteDetailAPI();
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const addProductFromCustomerProfileHandling = () => {
    setBlnAddFromCustomerProfile(true);
    setBreadCrumbList([{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${location.state.detail.customerId}` }, ])
  }

  const onClickSelect = (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      customerNo: row.customerNo,
      customerMobileNo: row.mobileNo,
      customerBranchName: row.branchName,
      redeemDetails: [],
      redeemDetailsDisplay: []
    }))

    let localCustomerDropdownTemp : any = [{
      id: row.customerId,
      preferredName: row.preferredName,
      customerNo: row.customerNo,
      mobileNo: row.mobileNo,
    }];

    fetchProductRedemptionNoteWithLoading(row.id, "", localCustomerDropdownTemp, row.preferredName);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const prefillCustomerInfo = (id: string) => {
    let indexCustomer = _.findIndex(localCustomerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: localCustomerDropdownList[indexCustomer].id,
        customerName: localCustomerDropdownList[indexCustomer].preferredName,
        customerNo: localCustomerDropdownList[indexCustomer].customerNo,
        customerMobileNo: localCustomerDropdownList[indexCustomer].mobileNo,
      }))
    }
  }

  const fetchProductRedemptionNoteWithLoading = async (id: string, deliverOrderId?: string, customerDropdownListTemp?: CustomerListObject[] | undefined, customerName?: string, redeemDetailsParams?: ProductRedemptionEligibleListObject[]) => {
    setLoadingProductRedemptionNote(true);
    let customerDropdownListFinal = _.cloneDeep(customerDropdownListTemp || customerDropdownList)
    setLocalCustomerDropdownList(customerDropdownListFinal);
    let indexCustomer = _.findIndex(customerDropdownListFinal, { id: id });
    let customerIdFinal = id;
    let customerNameFinal = indexCustomer > -1 ? customerDropdownListFinal[indexCustomer].preferredName : customerName || ""
    validation.setValues((values) => ({
      ...values,
      customerId: customerIdFinal,
      customerName: customerNameFinal,
      redeemDetails: [],
      redeemDetailsDisplay: []
    }))
    let resultAPI : ProductRedemptionEligibleListObject[] | undefined = await getProductRedemptionEligibleWithId(id, deliverOrderId || "");
    if (resultAPI) {
      if (redeemDetailsParams) {
        if (redeemDetailsParams.length > 0) {
          let indexReservedProduct = _.findIndex(resultAPI, { virtualWarehouseId: redeemDetailsParams[0].virtualWarehouseId })
          if (indexReservedProduct > -1) {
            resultAPI[indexReservedProduct].isActive = true;
          }
        }
      }
      validation.setFieldValue("redeemDetailsDisplay", resultAPI);
    }
    else {
      validation.setFieldValue("redeemDetailsDisplay", []);
    }
    setLoadingProductRedemptionNote(false);
    clearCustomerDropdownList();
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            id={Constants.productRedemptionNoteForm}
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "ProductRedemptionNote" }) })}
                  h4Title /> 
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <Row className="mt-3">
                      <Col xl='8' md='8' xs='8'>
                        {
                          displayCustomerDropdown &&
                          <DropDownWithTitle
                            name={"customerId"}
                            title={intl.formatMessage({ id: "Customer" })}
                            placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                            specifyReturnFieldName={[
                              {
                                field: "customerId",
                                value: "id",
                              }
                            ]}
                            labelField={"preferredName"}
                            valueField={"id"}
                            options={customerDropdownList}
                            disabled={disabledFieldInput || blnAddFromCustomerProfile || blnCustomerDisabled}
                            initialLabel={validation.values.customerName}
                            initialValue={validation.values.customerId}
                            validationRequired={true}
                            validation={validation}
                            onChangeField={"id"}
                            onChangeFunction={fetchProductRedemptionNoteWithLoading}
                            blnSupportCustomSearch={true}
                            onChangeCustomSearch={(value)=> {
                              fetchCustomerDropdown(value);
                            }}
                            onMenuOpenFunction={()=> {
                              clearCustomerDropdownList();
                              setLoadingCustomer(false);
                            }}
                            loadingCustomSearch={loadingCustomer}
                            setLoadingCustomSearch={setLoadingCustomer}
                          />
                        }
                      </Col>
                      <Col xl='4' md='4' xs='4'>
                        <MyButton
                          type="button"
                          class={`btn btn-primary margin-top-28`}
                          content={""}
                          subContent={returnAdvancedSearchIcon()}
                          loading={loading}
                          disable={loading || blnAddFromCustomerProfile || blnCustomerDisabled}
                          onClick={() => { setCustomerSearchModal(true) }}
                        />
                      </Col>
                    </Row>
                    {
                      blnPermissionBackdateProductRedemptionNote
                      &&
                      <GeneralInput
                        title={""}
                        name="isBackdate"
                        type="checkbox"
                        disabled={disabledFieldInput}
                        validationRequired={false}
                        validation={validation}
                        childrenUI={
                          <Label className="margin-bottom-0 margin-left-4">
                            {intl.formatMessage({ id: "IsApplyPerformedDateTime" })}
                          </Label>
                        }
                        onChangeFunctionWithSetValidationField={(valueChecked)=> {
                          validation.setFieldValue("performedDateTime", valueChecked ? moment().format(Constants.textInputDateAndTimeFormat) : "");
                        }}
                      />
                    }
                    {
                      blnPermissionBackdateProductRedemptionNote
                      &&
                      validation.values.isBackdate
                      &&
                      <GeneralInput
                        title={""}
                        name="performedDateTime"
                        type="datetime-local"
                        disabled={disabledFieldInput}
                        validationRequired={false}
                        validation={validation}
                      />
                    }
                    <SingleColumnRowParent blnDoubleTab={true}>
                      <GeneralTextArea
                        title={intl.formatMessage({ id: "Note" })}
                        name="note"
                        row={5}
                        disabled={disabledFieldInput}
                        validation={validation}
                      />
                    </SingleColumnRowParent>
                  </DoubleColumnRowParent>
                  {
                    validation.values.customerId
                    ?
                    loadingProductRedemptionNote
                    ?
                    <div>
                      <Loading/>
                    </div>
                    :
                    <>
                      {/* <hr/> */}
                      <LineBreakWithTittle
                        className="py-2 mt-3"
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "RedeemDetails" })}
                        h4Title
                      />
                      <TableWithSelectAllCheckBoxAndInput
                        name="redeemDetailsDisplay"
                        className="mt-0"
                        blnTableViewCss={true}
                        title=""
                        blnEditable={true}
                        aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                        data={validation.values.redeemDetailsDisplay}
                        validation={validation}
                        selectAllTextFieldDisplay={"productName"}
                        selectAllTextFieldDisplayXLSize={2}
                        disabled={disabledFieldInput}
                        warningField="quantity"
                        warningFieldBackgroundColor={Constants.lightorange} />
                      <div className="mt-3" />
                    </>
                    :
                    <div/>
                  }
              </Col>
              <GeneralSubmitAndCancelBtn
                className="mt-4"
                successMessage={successMessage}
                submitButtonTitle={intl.formatMessage({ id: "Redeem" })}
                cancelButtonFunction={blnAddFromCustomerProfile ? ()=> {history.push(`/${RoutesList.customer}/view/${location.state.detail.customerId}`)} : undefined}
                viewAction={false}
                validation={validation}
              />
            </Row>
          </Form>
        )}
        {
          customerSearchModal
          &&
          <CustomerSearchModal
            blnShow={customerSearchModal}
            blnHideOtherBranch={false}
            setModal={setCustomerSearchModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
            onSelectFunction={onClickSelect} />
        }
        {
          productRedemptionNoteConfirmationModal &&
          <ProductRedemptionNoteConfimationModal
            blnShow={productRedemptionNoteConfirmationModal}
            setModal={setProductRedemptionNoteConfirmationModal}
            validation={validation}
            blnBlockRedemptionSubmit={blnBlockRedemptionSubmit}
            blnPermissionBackdateProductRedemptionNote={blnPermissionBackdateProductRedemptionNote}/>
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(ProductRedemptionNoteDetail);
