import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { AppointmentDetailsListObject, AppointmentSummaryBySourceExportObject, AppointmentSummaryByStatusExportObject, AppointmentSummaryListBySourceObject, AppointmentSummaryListByStatusObject, AppointmentSummaryListGetObject, RoomOccupancyExportObject, RoomOccupancyListGetObject, RoomOccupancyListObject, RoomUtilizationExportObject, RoomUtilizationListGetObject, RoomUtilizationListObject } from "../models/reportAppointment";
import { downloadExcel } from "../common/function/function";

export default class ReportAppointmentStore {
  appointmentSummaryListBySource: AppointmentSummaryListBySourceObject[] = [];
  appointmentSummaryListByStatus: AppointmentSummaryListByStatusObject[] = [];
  appointmentDetailsList: AppointmentDetailsListObject[] = [];
  roomUtilizationList: RoomUtilizationListObject[] = [];
  roomOccupancyList: RoomOccupancyListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.appointmentSummaryListBySource = [];
    this.appointmentSummaryListByStatus = [];
    this.appointmentDetailsList = [];
    this.roomUtilizationList = [];
    this.roomOccupancyList = [];
  }

  setAppointmentSummaryListBySource = (appointmentSummaryListBySourceObject: AppointmentSummaryListBySourceObject[]) => {
    this.appointmentSummaryListBySource = appointmentSummaryListBySourceObject;
  }

  setAppointmentSummaryListByStatus = (appointmentSummaryListByStatusObject: AppointmentSummaryListByStatusObject[]) => {
    this.appointmentSummaryListByStatus = appointmentSummaryListByStatusObject;
  }

  setAppointmentDetailsList = (appointmentDetailsListObject: AppointmentDetailsListObject[]) => {
    this.appointmentDetailsList = appointmentDetailsListObject;
  }

  setRoomUtilizationList = (roomUtilizationListObject: RoomUtilizationListObject[]) => {
    this.roomUtilizationList = roomUtilizationListObject;
  }

  setRoomOccupancyList = (roomOccupancyListObject: RoomOccupancyListObject[]) => {
    this.roomOccupancyList = roomOccupancyListObject;
  }

  getAppointmentSummaryBySource = async (appointmentSummaryListGetObject: AppointmentSummaryListGetObject) => {
    try{
      const resultAppointmentSummaryBySource = await agent.ReportAppointment.appointmentSummaryBySourceList(appointmentSummaryListGetObject);
      runInAction(() => {
        this.appointmentSummaryListBySource = resultAppointmentSummaryBySource;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentSummaryListBySource = [];
    }
  }

  getAppointmentSummaryByStatus = async (appointmentSummaryListGetObject: AppointmentSummaryListGetObject) => {
    try{
      const resultAppointmentSummaryByStatus = await agent.ReportAppointment.appointmentSummaryByStatusList(appointmentSummaryListGetObject);
      runInAction(() => {
        this.appointmentSummaryListByStatus = resultAppointmentSummaryByStatus;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentSummaryListByStatus = [];
    }
  }

  getAppointmentDetailList = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultAppointmentDetails = await agent.ReportAppointment.appointmentDetailList(PaginationRequestBody);
      runInAction(() => {
        resultAppointmentDetails.data = resultAppointmentDetails.data.map((valueResultAppointmentDetailsTemp, index)=> ({
          ...valueResultAppointmentDetailsTemp,
          randomId: `${valueResultAppointmentDetailsTemp.appointStartDateTime}_${index}`
        }))
        this.appointmentDetailsList = resultAppointmentDetails.data;
        store.commonStore.setTotalItem(resultAppointmentDetails.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.appointmentDetailsList = [];
    }
  }
  
  getRoomUtilizationList = async (roomUtilizationListGetObject: RoomUtilizationListGetObject) => {
    try{
      let resultRoomUtilization = await agent.ReportAppointment.roomUtilizationList(roomUtilizationListGetObject);
      resultRoomUtilization = resultRoomUtilization.map((valueResultRoomUtilization)=> {
        valueResultRoomUtilization.appointments = valueResultRoomUtilization.appointments.map((valueRoomAppointmentDetailsTemp, index)=> ({
          ...valueRoomAppointmentDetailsTemp,
          randomId: `${valueResultRoomUtilization.roomName}_${index}`
        }))
        return({
          ...valueResultRoomUtilization
        })
      })
      runInAction(() => {
        this.roomUtilizationList = resultRoomUtilization;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.roomUtilizationList = [];
    }
  }

  getRoomOccupancyList = async (roomOccupancyListGetObject: RoomOccupancyListGetObject) => {
    try{
      const resultRoomOccupancy = await agent.ReportAppointment.roomOccupancyList(roomOccupancyListGetObject);
      runInAction(() => {
        this.roomOccupancyList = resultRoomOccupancy;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.roomOccupancyList = [];
    }
  }
  exportAppointmentSummaryBySource = async (appointmentSummaryBySourceExportObject: AppointmentSummaryBySourceExportObject) => {
    try{
      await downloadExcel(`/report/appointment/summarybysource/export`, appointmentSummaryBySourceExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppointmentSummaryByStatus = async (appointmentSummaryByStatusExportObject: AppointmentSummaryByStatusExportObject) => {
    try{
      await downloadExcel(`/report/appointment/summarybystatus/export`, appointmentSummaryByStatusExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportAppointmentDetail = async (appointmentDetailExportObject: PaginationRequestBody) => {
    try{
      await downloadExcel(`/report/appointment/detail/export`, appointmentDetailExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportRoomUtilization = async (roomUtilizationExportObject: RoomUtilizationExportObject) => {
    try{
      await downloadExcel(`/report/appointment/roomutilization/export`, roomUtilizationExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportRoomOccupancy = async (roomOccupancyExportObject: RoomOccupancyExportObject) => {
    try{
      await downloadExcel(`/report/appointment/roomoccupancy/export`, roomOccupancyExportObject);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}