import _ from "lodash";
import React, { Fragment, useRef, useState } from "react";
import { FormFeedback, Label } from "reactstrap";
import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Constants } from "../../constants/Constants";
import moment from "moment";
import { DateRangeInterface } from "../../models/common";
interface Props {
	title: string;
	name: string;
	className?: string;
	disabled: boolean;
	validationRequired?: boolean;
	onChangeFunction?: Function;
  initialValue?: DateRangeInterface;
  minMaxDateObj?: DateRangeInterface;
  blnMinMaxDateObjWithBothViews?: boolean;
}

export default function GeneralDateRange(props: Props) {
  const [localDateDisplay, setLocalDateDisplay] = useState(props.initialValue?.fromDate && props.initialValue.toDate ? `${props.initialValue.fromDate} - ${props.initialValue.toDate}` : "")
  const [blnTrigger, setBlnTrigger] = useState(false);
  const [blnClearDateFromInput, setBlnClearDateFromInput] = useState(false);

  const handleApply = (event, picker) => {
    if (picker?.startDate && picker?.endDate) {
      let startDate = moment(picker.startDate).format(Constants.displayDateFormat);
      let endDate = moment(picker.endDate).format(Constants.displayDateFormat);
      dateHandling(startDate, endDate);
    }
  };

  const handleCallback = (start, end) => {
    if (blnClearDateFromInput && !props.validationRequired)
    {
      return;
    }

    if (start && end) {
      let startDate = moment(start).format(Constants.displayDateFormat);
      let endDate = moment(end).format(Constants.displayDateFormat);
      dateHandling(startDate, endDate);
    }
  };

  const dateHandling = (startDate, endDate) => {
    if (!blnTrigger) {
      setBlnTrigger(true);
      setLocalDateDisplay(`${startDate} - ${endDate}`)
      if (props.onChangeFunction) {
        props.onChangeFunction({fromDate: startDate, toDate: endDate})
      }
      setTimeout(()=> {
        setBlnTrigger(false);
      }, 1000)
    }
  }

  const onChangeFromInput = (value) => {
    if (!props.validationRequired) {
      if (!value && !blnClearDateFromInput) {
        setBlnClearDateFromInput(true);
      }
      else if (value && blnClearDateFromInput) {
        setBlnClearDateFromInput(false);
      }
    }
  }

  const handleClear = () => {
    if (props.validationRequired) {
      return;
    }

    setLocalDateDisplay("");
    if (props.onChangeFunction) {
      props.onChangeFunction({fromDate: "", toDate: ""})
    }
  }

  const handleHide = () => {
    if (blnClearDateFromInput) {
      handleClear();
      setBlnClearDateFromInput(false);
    }
  }

	return (
		<div>
			<div className={props.className || "mb-3"}>
				{props.title && (
					<Label>
						{props.validationRequired && (
							<Label className="margin-bottom-0" style={{ color: "red" }}>{`*`}</Label>
						)}
						<Label className="margin-bottom-0">{props.title}</Label>
					</Label>
				)}
				<br />
        <div className="flex-direction-row">
          {
            props.minMaxDateObj && !props.blnMinMaxDateObjWithBothViews
            ?
            <style type="text/css">
              {`
                .daterangepicker .drp-calendar.left .calendar-table {
                  display: none !important;
                }
                .daterangepicker .drp-calendar.right {
                  width: 100%;
                }
              `}
            </style>
            :
            null
          }
          <DateRangePicker 
            initialSettings={{
              startDate: props.initialValue?.fromDate ? moment(props.initialValue.fromDate) : moment(),
              endDate: props.initialValue?.toDate ? moment(props.initialValue.toDate) : moment(),
              alwaysShowCalendars: true,
              autoUpdateInput: props.initialValue?.fromDate && props.initialValue?.toDate ? true : false,
              locale: {
                ...!props.validationRequired && {cancelLabel: 'Clear'},
                format: Constants.displayDateFormat
              },
              ranges: !props.minMaxDateObj ? {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, 'days').toDate(),
                  moment().subtract(1, 'days').toDate(),
                ],
                'Last 7 Days': [
                  moment().subtract(6, 'days').toDate(),
                  moment().toDate(),
                ],
                'Last 14 Days': [
                  moment().subtract(13, 'days').toDate(),
                  moment().toDate(),
                ],
                'Last 30 Days': [
                  moment().subtract(29, 'days').toDate(),
                  moment().toDate(),
                ],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month').toDate(),
                  moment().subtract(1, 'month').endOf('month').toDate(),
                ],
                'This Month': [
                  moment().startOf('month').toDate(),
                  moment().endOf('month').toDate(),
                ],
                'This Year': [
                  moment().startOf('year').toDate(),
                  moment().endOf('year').toDate(),
                ],
                'Last 5 Years': [
                  moment().subtract(5, 'year').startOf('year').toDate(),
                  moment().endOf('year').toDate(),
                ],
              } : undefined,
              ...props.minMaxDateObj ? {
                "minDate": props.minMaxDateObj.fromDate,
                "maxDate": props.minMaxDateObj.toDate
              } : undefined
            }}
            onCallback={handleCallback}
            onApply={handleApply}
            onHide={handleHide}
            onCancel={handleClear}>
            <input type="text" 
              className="form-control" 
              value={localDateDisplay} 
              placeholder="dd M, yyyy" 
              disabled={props.disabled} 
              onChange={(event)=> {
                onChangeFromInput(event.target.value) 
                setLocalDateDisplay(event.target.value)
              }}/>
          </DateRangePicker>
        </div>
			</div>
		</div>
	);
}
