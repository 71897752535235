import { PaginationRequestBody } from "../models/pagination";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { CustomerOrderObject, CustomerOrderPayment, CustomerOrderPaymentSuccess, CustomerPurchaseObject, CustomerPurchaseRedeem, CustomerUpgrade } from "../models/customerOrder";
import { Constants } from "../constants/Constants";

export default class customerOrderStore {
  customerPurchaseDetail: CustomerPurchaseObject | undefined = undefined ;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerPurchaseDetail = undefined;
  }

  submitPOS = async (posRequestBody: CustomerOrderObject) => {
    try{
      let result : CustomerOrderPaymentSuccess = await agent.CustomerOrder.submitPOS(posRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`CustomerOrderCreateSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: result});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}});
    }
  }

  submitAdditionalPayment = async (posRequestBody: CustomerOrderPayment) => {
    try{
      await agent.CustomerOrder.submitAdditionalPayment(posRequestBody);
      store.commonStore.setSuccessMessage(`PaymentCreateSuccess`)
      return Promise.resolve(Constants.success);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  setCustomerPurchaseDetail = (customerPurchaseDetail: CustomerPurchaseObject | undefined) => {
    this.customerPurchaseDetail = customerPurchaseDetail;
  }

  getCustomerPurchase = async (id: string, status?: number) => {
    try{
      const resultCustomerPurchase = await agent.CustomerOrder.customerPurchaseList(id, status);
      runInAction(() => {
        this.customerPurchaseDetail = resultCustomerPurchase;
      });

      return Promise.resolve(resultCustomerPurchase);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPurchaseDetail = undefined;
    }
  }

  submitCustomerRedeem = async (customerRedeemRequestBody: CustomerPurchaseRedeem) => {
    try{
      let result = await agent.CustomerOrder.submitCustomerRedeem(customerRedeemRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`ItemRedeemSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: result});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}});
    }
  }

  submitPackageUpgrade = async (packageUpgradeRequestBody: CustomerUpgrade) => {
    try{
      let result = await agent.CustomerOrder.submitPackageUpgrade(packageUpgradeRequestBody);
      setTimeout(()=> (
        store.commonStore.setSuccessMessage(`PackageUpgradeSuccess`)
      ), 100)
      return Promise.resolve({status: Constants.success, data: result});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {}});
    }
  }
}