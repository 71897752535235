import { makeAutoObservable, runInAction } from "mobx";
import { CRNDetailObject, CRNListObject, CRNUpdateSubBrandObject } from "../models/crn";
import { store } from "./store";
import _ from "lodash";
import agent from "../api/agent";
import { PaginationRequestBody } from "../models/pagination";
import { Constants } from "../constants/Constants";

export default class CRNStore {
  crnList: CRNListObject[] = [];
  crnDetail: CRNDetailObject | undefined = undefined;
  crnPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.crnList = [];
    this.crnDetail = undefined;
    this.crnPaginationParams = undefined;
  };

  setCRNDetail = (crnDetail: CRNDetailObject | undefined) => {
    this.crnDetail = crnDetail
  }; 

  setCRNPaginationParams = (
    crnPaginationParams: PaginationRequestBody | undefined) => {
      this.crnPaginationParams = _.cloneDeep(crnPaginationParams);
  };

  getCRN = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      this.setCRNPaginationParams(PaginationRequestBody);
      const resultCRNRequest = await agent.CRN.crnList(PaginationRequestBody);
      runInAction(() => {
        this.crnList = resultCRNRequest.data;
        store.commonStore.setTotalItem(resultCRNRequest.pagination.totalItems);
      });
    } catch (error: any) {
      console.error(error);
      store.commonStore.setErrorMessage(
        Array.isArray(error) ? error[0] : error.message
      );
      this.crnList = [];
    }
  };

  getCRNWithId = async (id: string) => {
    try {
      const resultCRNDetail = await agent.CRN.crnDetail(id);
      runInAction(() => {
        this.crnDetail = resultCRNDetail;
      });
    } catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.crnDetail = undefined;
    }
  };

  updateCRNSubBrand = async (updateCRNSubBrandRequestBody : CRNUpdateSubBrandObject) => {
    try {
      await agent.CRN.updateCRNSubBrand(updateCRNSubBrandRequestBody);
      return Promise.resolve({status: Constants.success})
    } catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed})
    }
  };
}
