import { PaginationRequestBody } from "../models/pagination";
import { RefundNoteListObject, RefundNoteAddObject, RefundNoteUpdateObject, RefundNoteDetailObject, CustomerCreditNoteListObject, RefundNoteReviewRequestBody, AddRefundNoteNotificationRequestBody, SignRefundNoteWebFormRequestBody, CancelRefundNoteWebFormRequestBody, AccountProcessRefundNoteRequestBody, RefundNoteAccountRevisedUpdateObject } from "../models/refundNote";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { onClickWhatsappWithDelay } from "../common/function/function";

export default class refundNoteStore {
  refundNoteList: RefundNoteListObject[] = [];
  refundNoteDetail: RefundNoteDetailObject | undefined = undefined;
  refundNotePaginationParams: PaginationRequestBody | undefined = undefined;
  customerCreditNoteList: CustomerCreditNoteListObject[] = [];
  customerCreditNoteTotalItems: number = 0;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.refundNoteList = [];
    this.refundNoteDetail = undefined;
    this.refundNotePaginationParams= undefined;
    this.customerCreditNoteList = [];
    this.customerCreditNoteTotalItems = 0;
  }

  setRefundNoteList = (refundNoteList: RefundNoteListObject[]) => {
    this.refundNoteList = refundNoteList;
  }

  setRefundNoteDetail = (refundNoteDetail: RefundNoteDetailObject | undefined) => {
    this.refundNoteDetail = refundNoteDetail;
  }

  setRefundNotePaginationParams = (refundNotePaginationParams: PaginationRequestBody | undefined) => {
    this.refundNotePaginationParams = refundNotePaginationParams;
  }
  
  getRefundNote = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setRefundNotePaginationParams(PaginationRequestBody);
    try{
      const resultRefundNote = await agent.RefundNote.refundNoteList(PaginationRequestBody);
      runInAction(() => {
        this.refundNoteList = resultRefundNote.data;
        store.commonStore.setTotalItem(resultRefundNote.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.refundNoteList = [];
    }
  }

  getRefundNoteWithId = async (id: string) => {
    try{
      const resultRefundNoteDetail = await agent.RefundNote.refundNoteDetail(id);
      runInAction(() => {
        this.refundNoteDetail = resultRefundNoteDetail;
      });
      return Promise.resolve(resultRefundNoteDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.refundNoteDetail = undefined;
    }
  }

  addRefundNote = async (refundNoteRequestBody: RefundNoteAddObject) => {
    try{
      await agent.RefundNote.addRefundNote(refundNoteRequestBody);
      store.commonStore.setSuccessMessage(`RefundNoteAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateRefundNote = async (refundNoteRequestBody: RefundNoteUpdateObject) => {
    try{
      await agent.RefundNote.updateRefundNote(refundNoteRequestBody);
      store.commonStore.setSuccessMessage(`RefundNoteUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteRefundNote  = async (id: string) => {
    try {
      await agent.RefundNote.deleteRefundNote(id);
      store.commonStore.setSuccessMessage(`RefundNoteDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  accountProcessRefundNote = async (accountProcessRefundNoteRequestBody: AccountProcessRefundNoteRequestBody) => {
    try{
      await agent.RefundNote.accountProcessRefundNote(accountProcessRefundNoteRequestBody);
      if (accountProcessRefundNoteRequestBody.workflowAction === Constants.complete) {
        store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteCompleteSuccess`)
      }
      else {
        store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteRejectAndReviseSuccess`)
      }
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getCustomerCreditNote = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerCreditNote = await agent.RefundNote.customerCreditNoteList(PaginationRequestBody);
      runInAction(() => {
        this.customerCreditNoteList = resultCustomerCreditNote.data;
        this.customerCreditNoteTotalItems = resultCustomerCreditNote.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerCreditNoteList = [];
      this.customerCreditNoteTotalItems = 0;
    }
  }

  reviewRefundNote = async (reviewRefundNoteRequestBody: RefundNoteReviewRequestBody) => {
    try{
      await agent.RefundNote.reviewRefundNote(reviewRefundNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteReviewSuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  verifyRefundNote = async (refundNoteRequestBody: RefundNoteReviewRequestBody) => {
    try{
      await agent.RefundNote.verifyRefundNote(refundNoteRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`RefundNoteVerifySuccess`);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getRefundNoteWebFormRequest = async (id: string) => {
    try{
      const resultRefundNoteWebFormRequest = await agent.RefundNote.refundNoteWebFormRequest(id);
      return Promise.resolve({status: Constants.success, data: resultRefundNoteWebFormRequest});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  addRefundNoteWebFormNotification = async (refundNoteRequestBody: AddRefundNoteNotificationRequestBody) => {
    try{
      let resultVerifyRefundNote = await agent.RefundNote.addRefundNoteWebFormNotification(refundNoteRequestBody);
      //0 - Whatsapp
      //1 - Email
      if (refundNoteRequestBody.type === 1) {
        store.commonStore.setSuccessMessageWithoutNavigation(`EmailSendSuccess`);
      }
      else {
        const encodedId = encodeURIComponent(resultVerifyRefundNote.url);
        onClickWhatsappWithDelay(`https://web.whatsapp.com/send?phone=${refundNoteRequestBody.valueToContact}&text=${encodedId}`)
      }
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getRefundNoteWebFormDetail = async (id: string) => {
    try{
      const resultRefundNoteWebFormDetail = await agent.RefundNote.refundNoteWebFormDetail(id);
      return Promise.resolve({status: Constants.success, data: resultRefundNoteWebFormDetail, message: ""});
    }
    catch (error: any) {
      console.error(error)
      return Promise.resolve({status: Constants.failed, data: undefined, message: Array.isArray(error) ? error[0] : error.message});
    }
  }

  signRefundNoteWebForm = async (signRefundNoteWebFormRequestBody: SignRefundNoteWebFormRequestBody) => {
    try{
      await agent.RefundNote.signRefundNoteWebForm(signRefundNoteWebFormRequestBody);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error);
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message);
      return Promise.resolve({status: Constants.failed});
    }
  }
  
  cancelRefundNoteWebForm = async (cancelRefundNoteWebFormRequestBody: CancelRefundNoteWebFormRequestBody) => {
    try{
      await agent.RefundNote.cancelRefundNoteWebForm(cancelRefundNoteWebFormRequestBody);
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error);
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message);
      return Promise.resolve({status: Constants.failed});
    }
  }

  updateRefundNoteAccountRevised = async (refundNoteRequestBody: RefundNoteAccountRevisedUpdateObject) => {
    try{
      await agent.RefundNote.updateRefundNoteAccountRevised(refundNoteRequestBody);
      store.commonStore.setSuccessMessage(`RefundNoteUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}