import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { getYears, getMonths, newSpaceBeforeCapitalLetter, returnPriceWithCurrency, checkPermission, getBranchId, getBranchName, getBranchUser, arraySpliceInAllValue, dailySalesRecordTypeListHandling, returnSubBrandList, firstDayOfCurrentMonthToLastDayOfCurrentMonth, firstDayOfMonthToCurrentDay, returnDateRangeByYearAndMonthChecking } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import moment from "moment";
import Loading from "../../../app/components/loading/Loading";
import MyButton from "../../../app/components/form/MyButton";
import { BranchListObject } from "../../../app/models/branch";
import _ from "lodash";
import { Link, useLocation } from 'react-router-dom';

import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import ReportExpandRow from "../subView/ReportExpandRow";
import PaginationTableWithoutApi from "../../../app/components/table/PaginationTableWithoutApi";
import Borderline from "../../../app/components/form/BorderLine";
import { SubBrandObject } from "../../../app/models/brand";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import { toDate } from "date-fns";

const SalesOverviewDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, staticReferenceStore, customerStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage, setSuccessMessage } = commonStore;
  const { salesOverviewDetailsList, getSalesOverviewDetailsList, setSalesOverviewDetailsList, exportSalesOverviewDetails } = reportSalesStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;

  //Data List
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const AllType = `${intl.formatMessage({ id: "AllType" })}`;
  const [selectedDailySalesRecordType, setSelectedDailySalesRecordType] = useState<number | string>(AllType);
  const [selectedDailySalesRecordTypeId, setSelectedDailySalesRecordTypeId] = useState<number | string | undefined>(" ");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])
  const [yearList, setYearList] = useState<ObjectForDropdown[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(Number(moment().format('yyyy')));
  const [monthList, setMonthList] = useState<ObjectForDropdown[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MMM"));
  const [selectedMonthValue, setSelectedMonthValue] = useState<number>(Number(moment().format("M")));
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const dateRangeLimit = {
    fromDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).startOf('month').format(Constants.displayDateFormat)),
    toDate: String(moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat).endOf('month').format(Constants.displayDateFormat)),
  }
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [dailySalesRecordTypeList, setDailySalesRecordTypeList] = useState<StaticReferenceDataObject[]>([]);
  const [tableExpandRowKeyList, setTableExpandRowKeyList] = useState<string[]>([])
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);
  const [refreshTable, setRefreshTable] = useState(false);
  const blnSubBrandExist = returnSubBrandList().length > 0;
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [localLoadingDateRange, setLocalLoadingDateRange] = useState(false);

  const paramsSearch = useLocation().search;
  const branchParams = new URLSearchParams(paramsSearch).get('branchId');
  const typeParams = new URLSearchParams(paramsSearch).get('type');
  const subBrandParams = new URLSearchParams(paramsSearch).get('subBrandId');
  const yearParams = new URLSearchParams(paramsSearch).get('year');
  const monthParams = new URLSearchParams(paramsSearch).get('month');
  const fromDateParams = new URLSearchParams(paramsSearch).get('fromDate');
  const toDateParams = new URLSearchParams(paramsSearch).get('toDate');

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    setYearList(getYears());
    setMonthList(getMonths())

    async function fetchSalesOverviewDetails() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let aryAPI: any = [
        getBranchDropDown({isHQ: false}, false, false, false, true, false),
        getStaticReferenceWithType(Constants.dailySalesRecordTypeDropdownFilter, AllType)
      ];

      let resultAPI = await Promise.all(aryAPI);
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchDropDownListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true));
      setDailySalesRecordTypeList(dailySalesRecordTypeListHandling(resultAPI[1]));
      let dailySalesRecordTypeListTemp = _.cloneDeep(dailySalesRecordTypeListHandling(resultAPI[1]));
      let tableExpandRowKeyListTemp: string[] = dailySalesRecordTypeListTemp.map(valueDailySalesRecordTypeListTemp => valueDailySalesRecordTypeListTemp.displayValue);
      setTableExpandRowKeyList(tableExpandRowKeyListTemp);
      
      let blnParams = (branchParams && typeParams);
      if (blnParams) {
        let branchIdTemp = "";
        if (branchParams) {
          let branchListTemp: any = _.cloneDeep(resultAPI[0]);
          branchListTemp.map((value) => {
            if (branchParams == value.id) {
              setSelectedBranchName(value.name)
              setSelectedBranchId(value.id);
              branchIdTemp = value.id;
            }
          })
        }

        let typeIdTemp : string | number = "";
        if (typeParams) {
          let typeListTemp: StaticReferenceDataObject[] = _.cloneDeep(dailySalesRecordTypeListHandling(resultAPI[1]));
          typeListTemp.map((value) => {
            if (typeParams == value.displayValue) {
              setSelectedDailySalesRecordType(value.displayValue)
              setSelectedDailySalesRecordTypeId(value.key);
              typeIdTemp = value.key;
            }
          })
        }

        let subBrandIdTemp= "";
        if(blnSubBrandExist && subBrandParams) {
          let subBrandListTemp: any = returnSubBrandList();
          subBrandListTemp.map((value) => {
            if (subBrandParams == value.id) {
              setSelectedSubBrandName(value.name)
              setSelectedSubBrandId(value.id);
              subBrandIdTemp = value.id;
            }
          })
        }

        if (yearParams && monthParams && fromDateParams && toDateParams) {
          setSelectedYear(Number(yearParams));
          let monthListTemp = getMonths();
          let indexMonth = _.findIndex(monthListTemp, { value: Number(monthParams) });
          if (indexMonth > -1) {
            setSelectedMonth(monthListTemp[indexMonth].label);
            setSelectedMonthValue(monthListTemp[indexMonth].value);
          }
          const selectedYearAndMonthDateFormat = moment(`${yearParams}-${monthParams}`, Constants.displayYearAndMonthFormat);
          setSelectedDateRange(returnDateRangeByYearAndMonthChecking(selectedYearAndMonthDateFormat))
        }
        history.replace({ pathname: `/${RoutesList.report}/${RoutesList.salesOverviewDetails}` });
        await fetchSalesOverviewDetailsWithLoading(false, branchIdTemp, typeIdTemp, subBrandIdTemp, fromDateParams || "", toDateParams || "");
      }

      setLoading(false);
      setLoadingDropdownRefresh(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSalesOverviewDetails();

    return (() => {
      setSalesOverviewDetailsList([]);
    })
  }, [])

  useEffect(() => {
    async function getCustomerAndConsultanttDropdownFunction () {
      setDisplayCustomerDropdown(false);
      setSelectedCustomer("");
      setSelectedCustomerId("");
      setTimeout(()=> {
        setDisplayCustomerDropdown(true);
      }, 100)
    }

    if (selectedBranchId && !initialCallAPI) {
      getCustomerAndConsultanttDropdownFunction();
    }
  }, [selectedBranchId])

  useEffect(()=> {
    const selectedYearAndMonthDateFormat = moment(`${selectedYear}-${selectedMonthValue}`, Constants.displayYearAndMonthFormat);
    setSelectedDateRange(returnDateRangeByYearAndMonthChecking(selectedYearAndMonthDateFormat));
    setLocalLoadingDateRange(true);
    setTimeout(()=> {
      setLocalLoadingDateRange(false);
    }, 100)
  }, [selectedYear, selectedMonthValue])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(selectedBranchId, {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  const fetchSalesOverviewDetailsWithLoading = async (blnExport: boolean, branchIdTemp?: string, typeIdTemp?: string | number, subBrandIdTemp?: string, fromDateTemp?: string, toDateTemp?: string) => {
    setLocalLoading(true);
    if (blnExport) {
      await exportSalesOverviewDetails({ branchId: selectedBranchId, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, type: typeIdTemp === 0 ? typeIdTemp : typeIdTemp || selectedDailySalesRecordTypeId, subBrandId: selectedSubBrandId, customerId: selectedCustomerId });
    } else {
      setActiveTab("1");
      await getSalesOverviewDetailsList({ branchId: branchIdTemp || selectedBranchId, fromDate: fromDateTemp || selectedDateRange.fromDate, toDate: toDateTemp || selectedDateRange.toDate, type: typeIdTemp === 0 ? typeIdTemp : typeIdTemp || selectedDailySalesRecordTypeId, subBrandId: subBrandIdTemp || selectedSubBrandId, customerId: selectedCustomerId });
    }
    setLocalLoading(false);
  };

  //Table Content UI
  // const SalesOverviewDetailsColumns = [
  //   {
  //     dataField: "branchName",
  //     text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
  //   }
  // ];

  const RecordTypeColumns = [
    {
      dataField: "type",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {newSpaceBeforeCapitalLetter(row.type)}
        </>
      ),
    },
    {
      dataField: "amount",
      text: intl.formatMessage({ id: "Value" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {row.type === Constants.resignUpgrade ? returnPriceWithCurrency(row.amount) : row.count}
        </>
      ),
    }
  ];

  const aryCustomerDetails: ObjectForReportRow[] = [
    { label: "CustomerName", value: "customerName", type: "string-without-space" },
    { label: "CustomerNo", value: "customerNo", type: "string-without-space" },
    // { label: "IdentityType", value: "identityType", type: "string" },
    // { label: "IdentityNo", value: "identityNo", type: "string" },
    { label: "JoinedDate", value: "joinedDate", type: "dateWithoutTime" },
    { label: "MediaSource", value: "mediaSource", type: "string", hideFieldCondition: "type", hideFieldValueCompare: Constants.resignUpgrade },
    { label: "PaymentAmount", value: "paymentAmount", type: "currency", displayFieldCondition: "type", displayFieldValueCompare: Constants.resignUpgrade },
    { label: "Remark", value: "remark", type: "string" }
  ];

  const aryCustomerDetailsWithPermission : ObjectForReportRow[] = [
    ...aryCustomerDetails,
    {label: "Action", value: "customerId", type: "action"}
  ]

  // const expandRowRecordTypes = {
  //   renderer: row => (
  //     <div key={`expandrowRecordTypes::`} style={{marginTop: "8px", marginBottom: "8px", marginLeft: "42px", padding: "18px", border: "solid", borderWidth: "0.2px", borderColor: "lightblue", borderRadius: "8px"}}>
  //       <Label>{intl.formatMessage({ id: "RecordTypes" })}</Label>
  //       <PaginationTableWithoutApi
  //         title={intl.formatMessage({ id: "RecordTypes" })}
  //         keyField={"type"}
  //         columns={RecordTypeColumns}
  //         options={row.recordTypes}
  //         expandRow={expandRowCustomer} />
  //     </div>
  //   ),
  //   showExpandColumn: true,
  //   // onlyOneExpanding: true,3
  //   className: "padding-2-5px",
  //   expandHeaderColumnRenderer: ({ isAnyExpands }) => (
  //     <div>
  //       {
  //         !isAnyExpands
  //         ?
  //         <i className="bx bx bx-plus"></i>
  //         :
  //         <i className="bx bx bx-minus"></i>        
  //       }
  //     </div>
  //   ),
  //   expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
  //     <div>
  //       {
  //         !expanded
  //         ?
  //         <i className="bx bx bx-plus"></i>
  //         :
  //         <i className="bx bx bx-minus"></i>        
  //       }
  //     </div>
  //   )
  // };

  const expandRowCustomer = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "Customer" })}
        rowFieldName={"customers"}
        rowColumns={blnPermissionManageCustomer ? aryCustomerDetailsWithPermission : aryCustomerDetails}
        keyFieldName={"randomId"}
        actionTitle={intl.formatMessage({ id: "ViewCustomer" })}
        actionPathName={`/${RoutesList.customer}/view/`}
      />
    ),
    showExpandColumn: true,
    expanded: tableExpandRowKeyList,
    // onlyOneExpanding: true,
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
          ?
          <i className="bx bx bx-plus"></i>
          :
          <i className="bx bx bx-minus"></i>        
        }
      </div>
    )
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "SalesOverviewDetails" }) })}
        title={intl.formatMessage({ id: "SalesOverviewDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={() => {
              fetchSalesOverviewDetailsWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-Overview-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <Row>
            {
              subBrandListFinal.length > 0
              &&
              <Col xl={"3"}>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  validationRequired={true}
                />
              </Col>
            }
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
                options={branchDropDownListFinal}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                validationRequired={true}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
              />
            </Col>
            {
              <Col xl={'3'}>
                {
                  displayCustomerDropdown &&
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Customer" })}
                    name="customer"
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    options={customerDropdownList}
                    labelField="preferredName"
                    valueField="id"
                    initialLabel={selectedCustomer}
                    initialValue={selectedCustomerId}
                    setFieldLabel={setSelectedCustomer}
                    setFieldValue={setSelectedCustomerId}
                    disabled={localLoading || loading}
                    onChange={() => {
                      clearCustomerDropdownList();
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    blnSupportCustomSearch={true}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomer(false);
                    }}
                    loadingCustomSearch={loadingCustomer}
                    setLoadingCustomSearch={setLoadingCustomer}
                  />
                }
              </Col>
            }
          </Row>
        }
        {
          !loadingDropdownRefresh
          &&
          <Row>
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Years" })}
                name="years"
                options={yearList}
                initialLabel={selectedYear}
                initialValue={selectedYear}
                setFieldValue={setSelectedYear}
                disabled={localLoading || loading}
                validationRequired={true}
              />
          </Col>
            <Col xl={"3"}>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Month" })}
                name="months"
                // styles={{marginLeft: '25px'}}
                options={monthList}
                initialLabel={selectedMonth}
                initialValue={selectedMonthValue}
                setFieldLabel={setSelectedMonth}
                setFieldValue={setSelectedMonthValue}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              {
                localLoadingDateRange
                ?
                <Loading/>
                :
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRange" })}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  initialValue={selectedDateRange}
                  minMaxDateObj={dateRangeLimit}
                  validationRequired={true}
                  onChangeFunction={onSelectDateRange}/>
              }
            </Col>
          </Row>
        }
        {
          !loadingDropdownRefresh
          &&
          <Row>
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Type" })}
                name="dailySalesRecordType"
                options={dailySalesRecordTypeList}
                blnValueWithNewSpace={true}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedDailySalesRecordType}
                initialValue={selectedDailySalesRecordTypeId}
                setFieldLabel={setSelectedDailySalesRecordType}
                setFieldValue={setSelectedDailySalesRecordTypeId}
                disabled={localLoading || loading}
                validationRequired={true}
              />
            </Col>
            <Col xl={"3"}>
              <MyButton
                type="button"
                class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchSalesOverviewDetailsWithLoading(false)
                }}
              />
            </Col>
          </Row>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <div className="standard-layout">
              <Nav className="nav nav-tabs nav-tabs-custom" style={{borderBottom: '0px'}}>
                {salesOverviewDetailsList.map((valueSalesOverviewDetailsList, indexTab) => (
                  <NavItem key={`tabkey_${indexTab}`}>
                    <NavLink
                      disabled={indexTab == 1 && (false)}
                      style={{ 
                        cursor: "pointer", 
                        border: 'solid', 
                        borderWidth: '1px', 
                        color: activeTab === String(indexTab + 1) ? Constants.white : Constants.black,
                        borderColor: activeTab === String(indexTab + 1) ? Constants.lightbluefaded : Constants.lightgrey, 
                        backgroundColor: activeTab === String(indexTab + 1) ? Constants.tabBlue : Constants.white,
                        borderRadius: '18px', 
                        margin: '4px' 
                      }}
                      onClick={() => {
                        setRefreshTable(true);
                        toggle(String(indexTab + 1));
                        setTimeout(()=> {
                          setRefreshTable(false);
                        }, 100)
                      }}
                    >
                      {valueSalesOverviewDetailsList.branchName}
                    </NavLink>
                  </NavItem>
                )
              )}
              </Nav>
              {
                !refreshTable
                &&
                salesOverviewDetailsList.length > 0
                ?
                <div>
                  {salesOverviewDetailsList.length > 1 && <Borderline/>}
                  <div key={`expandrowRecordTypes::`} style={{marginTop: salesOverviewDetailsList.length === 1 ? '16px' : "0px"}}>
                    {/* <Label>{intl.formatMessage({ id: "RecordTypes" })}</Label> */}
                    <PaginationTableWithoutApi
                      title={intl.formatMessage({ id: "RecordTypes" })}
                      keyField={"type"}
                      columns={RecordTypeColumns}
                      options={salesOverviewDetailsList[Number(activeTab)-1].recordTypes}
                      blnDisplayForMainListing={true}
                      expandRow={expandRowCustomer} />
                  </div>
                </div>
                :
                <div>- {intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "Item"})})}</div>
              }
            </div>
            // <PaginationTable
            //   currentPage={currentPage}
            //   setCurrentPage={setCurrentPage}
            //   pageSize={pageSize}
            //   totalItem={totalItem}
            //   tableColumns={SalesOverviewDetailsColumns}
            //   data={salesOverviewDetailsList}
            //   emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SalesOverviewDetails" }) })}
            //   objSorted={defaultSorted}
            //   keyField={"branchId"}
            //   requestAPI={getSalesOverviewDetailsList}
            //   branchId={selectedBranchId}
            //   selectedYear={selectedYear}
            //   selectedMonth={selectedMonthValue}
            //   type={selectedDailySalesRecordTypeId}
            //   expandRow={expandRowRecordTypes}
            //   hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesOverviewDetails);
