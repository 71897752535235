import React from "react";
import {
  Col,
  Label, Row
} from "reactstrap";
import Select from 'react-select';
import _ from 'lodash';
import { AddOptionsValueField, convertValueWithSpaceInOptionsArray, customFilter, newSpaceBeforeCapitalLetter } from '../../common/function/function';
import Loading from "../loading/Loading";
import { Constants } from "../../constants/Constants";
import ArrowTopRightBoldBoxOutlineIcon from 'mdi-react/ArrowTopRightBoldBoxOutlineIcon';

interface Props {
  name: string;
  className?: any;
  validationRequired?: boolean;
  title: string;
  options: any;
  valueField: string,
  labelField: string,
  specifyReturnFieldName: any; //[{"field": "", "value": ""}]
  placeholder?: string;
  disabled: boolean;
  initialLabel?: string | number;
  initialValue?: string | number;
  validation: any;
  onChangeField?: string;
  onChangeAllField?: boolean;
  onChangeFunction?: Function;
  blnValueWithNewSpace?: boolean;
  loading?: boolean;
  field?: any;
  setFieldValue?: Function;
  blnModal?: boolean;
  disableMenuPortalTarget?: boolean;
  blnSupportCustomSearch?: boolean;
  onChangeCustomSearch?: Function;
  onMenuOpenFunction?: Function;
  loadingCustomSearch?: boolean;
  setLoadingCustomSearch?: Function;
  blnSupportClickableUI?: boolean;
  onClickUIFunction?: Function;
  blnSupportGroupDropdown?: boolean;
  blnHideTitle?: boolean;
  titleClassName?: string;
  subTitle?: string;
}

let localSearchValue = "";
export default function DropDownWithTitle(props: Props) {  
  let optionsTemp = props.blnSupportGroupDropdown ? props.options : AddOptionsValueField(props.options, props.labelField, props.valueField);
  let initialLabelDisplay = props.initialLabel || undefined;
  let initialValueDisplay = props.initialValue || undefined;
  let timeOut : any =  0;

  if (props.blnValueWithNewSpace) {
    optionsTemp = convertValueWithSpaceInOptionsArray(optionsTemp, true);
    initialLabelDisplay = props.initialLabel ? newSpaceBeforeCapitalLetter(props.initialLabel) : undefined;
    initialValueDisplay = props.initialValue ? newSpaceBeforeCapitalLetter(props.initialValue) : undefined;
  }

  const selectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      borderColor: props.validation.touched[props.name] && props.validation.errors[props.name] ? 'red' : base.borderColor,
    })
  }

  const localOnChangeCustomSearch = (value) => {
    clearTimeoutFunction();
    localSearchValue = value;

    if (value.length < Constants.dropdownSearchValueLength) {
      if (props.onMenuOpenFunction) {
        props.onMenuOpenFunction();
      }
      return;
    }

    timeOut = setTimeout(() => {
      if (props.setLoadingCustomSearch) {
        props.setLoadingCustomSearch(true);
      }
      if (props.onChangeCustomSearch) {
        props.onChangeCustomSearch(value);
      }
    }, Constants.dropdownSearchAPITimeout);
  }

  const clearTimeoutFunction = () => {
    localSearchValue = "";
    if(timeOut) {
      clearTimeout(timeOut)
    }
  }

  const formatGroupLabel = (data: any) => {
    return (
      <div className="groupStyles">
      	<span style={{fontSize: '13px'}}>{data.label}</span>
    	</div>
    )
  }
  
  return (
    <div className={`${props.className || "" } mb-3`}  
        onClick={() => {
          if(props.blnSupportClickableUI && props.onClickUIFunction){
            props.onClickUIFunction()
          }
        }}
    >
      {
        !props.blnHideTitle
        &&
        <Label>
          {props.validationRequired && !props.disabled && <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>}
          <Label className={`margin-bottom-0 margin-top-0 ${props.titleClassName || ""}`}>{props.title}</Label>
          {props.subTitle && <span className="small-text" style={{fontSize: "11px"}}>{`${props.subTitle}`}</span>}
          {props.blnSupportClickableUI && <ArrowTopRightBoldBoxOutlineIcon className="margin-left-4 pointer-clickable" size={18} color={Constants.tabBlue}/>}
        </Label>
      }
      <Row>
        <Col xl={props.loading ? 10 : 12}>
          <Select
            name={props.name}
            classNamePrefix={props.name}
            options={optionsTemp}
            isOptionDisabled={(option: any) => option.disabled}
            placeholder={props.placeholder || "- Please Select -"}
            defaultValue={initialValueDisplay ? { label: initialLabelDisplay || initialValueDisplay, value: initialValueDisplay }: null}
            isDisabled={props.disabled || props.loading}
            isClearable={props.validationRequired ? false : true}
            onChange={(selectedValue: any)=> {
              props.specifyReturnFieldName.map((valueTemp)=> {
                if (props.blnModal) {
                  let fieldTemp = _.cloneDeep(props.field);
                  fieldTemp[valueTemp.field] = selectedValue ? selectedValue[valueTemp.value] : "";
                  if (props.setFieldValue) {
                    props.setFieldValue(fieldTemp);
                  }
                }
                else {
                  props.validation.setFieldValue(valueTemp.field, selectedValue ? selectedValue[valueTemp.value] : "");
                }
              })
              if (props.onChangeFunction) {
                let fieldTemp = props.onChangeField || "value"
                props.onChangeFunction(selectedValue ? props.onChangeAllField ? selectedValue : selectedValue[fieldTemp] : "");
              }
            }}
            onInputChange={(value: any)=> {
              if (props.blnSupportCustomSearch && props.onChangeCustomSearch && props.setLoadingCustomSearch) {
                localOnChangeCustomSearch(value);
              }
            }}
            menuPosition="fixed"
            {...!props.blnModal && {className : props.validation.touched[props.name] && props.validation.errors[props.name] ? "" : "margin-bottom-0"}}
            {...!props.blnModal && {styles : selectStyle}}
            {...!props.blnModal && {onBlur : props.validation.handleBlur}}
            {...!props.blnModal && !props.disableMenuPortalTarget && {menuPortalTarget : document.body}}
            {...(props.blnSupportCustomSearch && props.loadingCustomSearch) && {noOptionsMessage : ()=> ('Loading...')}}
            {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && (!localSearchValue || localSearchValue.length < Constants.dropdownSearchValueLength)) && {noOptionsMessage : ()=> (`Please ${initialLabelDisplay ? "re-enter" : "enter"} ${localSearchValue.length < Constants.dropdownSearchValueLength ? Math.abs(localSearchValue.length-Constants.dropdownSearchValueLength) : ""} or more characters to search`)}}
            {...(props.blnSupportCustomSearch && !props.loadingCustomSearch && localSearchValue.length > (Constants.dropdownSearchValueLength-1)) && {noOptionsMessage : ()=> (`No options`)}}
            {...props.blnSupportCustomSearch && {filterOption: customFilter}}
            onMenuOpen={()=> {
              if (props.blnSupportCustomSearch && props.onMenuOpenFunction) {
                props.onMenuOpenFunction();
              }

  			      setTimeout(()=>{
                const selectedEl = document.getElementsByClassName(`${props.name}__option--is-selected`)[0];
                if(selectedEl){
                  selectedEl.scrollIntoView({block:'nearest', inline: 'start'});
                }
              },15)
            }}
            {...props.blnSupportGroupDropdown && {formatGroupLabel: formatGroupLabel}}
          />
        </Col>
        {
          props.loading
          &&
          <Col xl={2}>
            <Loading className="margin-top--5"/>
          </Col>
        }
      </Row>
      {!props.blnModal && props.validation.touched[props.name] && props.validation.errors[props.name] ? (
        <div className="error_require_text margin-bottom-4">{props.validation.errors[props.name]}</div>
      ) : null}
    </div>
  )
}