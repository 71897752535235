import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { AppointmentLeadListObject } from '../../app/models/appointmentLead';
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, leadStatusColor, newSpaceBeforeCapitalLetter, returnSubBrandList } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import _ from "lodash";
import Loading from "../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import TableActionButton from "../../app/components/table/TableActionButton";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { SubBrandObject } from "../../app/models/brand";
import { PaginationRequestBody } from "../../app/models/pagination";
import AppointmentLeadFollowUpHistoryModal from "./Modal/AppointmentLeadFollowUpHistoryModal";
import { StaticReferenceDataObject } from "../../app/models/staticReference";

const AppointmentLead = () => {
  const intl = useIntl();
  //Use Store
  const { appointmentLeadStore, mediaSourceStore, branchStore, raceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, successMessage, setSuccessMessage, setErrorMessage, prevPath } = commonStore;
  const { appointmentLeadList, getAppointmentLead, appointmentLeadPaginationParams, setAppointmentLeadPaginationParams, addAppointmentLeadFollowUp } = appointmentLeadStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getRaceDropdown } = raceStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedAppointmentDateRange, setSelectedAppointmentDateRange] = useState<DateRangeInterface | undefined>({ fromDate: "", toDate: "" });
  const [raceListFinal, setRaceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllRace = `${intl.formatMessage({ id: "AllRace" })}`;
  const [selectedRaceName, setSelectedRaceName] = useState(AllRace);
  const [selectedRaceId, setSelectedRaceId] = useState(" ");
  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [appointmentLeadFollowUpHistoryModal, setAppointmentLeadFollowUpHistoryModal] = useState(false);
  const [selectedAppointmentLeadDetails, setSelectedAppointmentLeadDetails] = useState<AppointmentLeadListObject | undefined>(undefined);
  const AllStatus = `${intl.formatMessage({ id: "AllStatus" })}`;
  const [selectedLeadStatus, setSelectedLeadStatus] = useState<string>(AllStatus);
  const [selectedLeadStatusId, setSelectedLeadStatusId] = useState<number | string | undefined>(" ");
  const [leadStatusList, setLeadStatusList] = useState<StaticReferenceDataObject[]>([ 
    {
      "key": " ",
      "displayValue": AllStatus
    },
    {
        "key": 1,
        "displayValue": "FollowUp"
    },
    {
        "key": 6,
        "displayValue": "NotShow"
    }
  ]);
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      leadName: "",
      leadMobileNo: "",
      leadEmail: "",
      leadNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async () => {
    },
  });

  let appointmentLeadRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    sourceId: selectedMediaSourceId,
    sourceName: selectedMediaSourceName,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    leadName: validation.values.leadName,
    leadMobileNo: validation.values.leadMobileNo,
    leadEmail: validation.values.leadEmail,
    leadNo: validation.values.leadNo,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId,
    appointmentDateRange: selectedAppointmentDateRange,
    raceName: selectedRaceName,
    raceId: selectedRaceId,
    leadStatus: selectedLeadStatusId,
    leadStatusName: selectedLeadStatus
  }

  useEffect(() => {
    async function fetchAppointmentLeadListAPI() {
      setLoading(true);
      setLoadingDropdownRefresh(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.appointmentLead)
      if (blnBackNavigation) {
        if (appointmentLeadPaginationParams) {
          setCurrentPage(appointmentLeadPaginationParams.pageNumber)
          setSelectedMediaSourceId(appointmentLeadPaginationParams.sourceId || "");
          setSelectedMediaSourceName(appointmentLeadPaginationParams.sourceName || AllSource);
          setSelectedBranchId(appointmentLeadPaginationParams.branchId || "");
          setSelectedBranchName(appointmentLeadPaginationParams.branchName || AllBranch);
          setSelectedAppointmentDateRange(appointmentLeadPaginationParams.appointmentDateRange)
          setSelectedSubBrandName(appointmentLeadPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(appointmentLeadPaginationParams.subBrandId || " ");
          setSelectedRaceName(appointmentLeadPaginationParams.raceName || "");
          setSelectedRaceId(appointmentLeadPaginationParams.raceId || "");
          setSelectedLeadStatusId(appointmentLeadPaginationParams.leadStatus);
          setSelectedLeadStatus(appointmentLeadPaginationParams.leadStatusName || AllStatus);
          validation.setValues((values) => ({
            ...values,
            leadName: appointmentLeadPaginationParams.leadName || "",
            leadMobileNo: appointmentLeadPaginationParams.leadMobileNo || "",
            leadEmail: appointmentLeadPaginationParams.leadEmail || "",
            leadNo: appointmentLeadPaginationParams.leadNo || ""
          }))
          appointmentLeadRequestBody = appointmentLeadPaginationParams;
        }
        else {
          blnBackNavigation = false;
        }
      }
      else {
        setAppointmentLeadPaginationParams(undefined);
      }

      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      let aryAPI: any = [
        getBranchDropDown(),
        getMediaSourceDropdown(),
        getRaceDropdown(),
        getAppointmentLead(appointmentLeadRequestBody),
      ];
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[1], AllSource, false, "name", "id"));
      setRaceListFinal(arraySpliceInAllValue(resultAPI[2], AllRace, false, "name", "id"));

      setLoading(false);
      setLoadingDropdownRefresh(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointmentLead], true)) {
      return;
    }

    fetchAppointmentLeadListAPI();

    return (() => {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.appointmentLead)
        if (!blnSetParams) {
          setAppointmentLeadPaginationParams(undefined);
        }
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        fetchAppointmentLeadApi(true);
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchAppointmentLeadApi(true);
    }
  }, [selectedBranchId, selectedMediaSourceId, selectedRaceId, selectedAppointmentDateRange, triggerSearch, selectedSubBrandId, selectedLeadStatusId])

  async function fetchAppointmentLeadApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getAppointmentLead({
      ...appointmentLeadRequestBody
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedAppointmentDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  //Table Content UI
  const AppointmentLeadColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
    },
    {
      dataField: "mobileNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase(),
    },
    {
      dataField: "raceName",
      text: intl.formatMessage({ id: "Race" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.raceName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSource",
      text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "PreferredBranch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.branchName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "lastAppointmentDate",
      text: intl.formatMessage({ id: "LastAppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastAppointmentDate !== Constants.invalidDateTime ? moment(row.lastAppointmentDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "lastFollowUpDate",
      text: intl.formatMessage({ id: "LastFollowUpDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.lastFollowUpDate !== Constants.invalidDateTime ? moment(row.lastFollowUpDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "SubmissionDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${row.submissionDate !== Constants.invalidDateTime ? moment(row.submissionDate).format(Constants.displayDateFormat) : Constants.emptyData}`}
          </div>
          <div className="small-text">
            {row.submissionDate !== Constants.invalidDateTime ? moment(row.submissionDate).format(Constants.displayTimeFormat) : Constants.emptyData}
          </div>
        </>
      ),
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName) || Constants.emptyData}
          </div>
        )
      }
    },
    {
      dataField: "lastFollowUpRemark",
      text: intl.formatMessage({ id: "LastFollowUpRemark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.lastFollowUpRemark || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={leadStatusColor} />
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageAppointmentLead]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton
              iconToolTipText={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
              onClick={()=> {
                setSelectedAppointmentLeadDetails(row);
                setAppointmentLeadFollowUpHistoryModal(true);
              }}
              type={'add'} />
            <TableActionButton
              to={`/${RoutesList.appointmentLead}/view/${row.id}`}
              type={'view'} />
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
        title={intl.formatMessage({ id: "FollowUp" })}>
        {
          !initialCallAPI &&
          <>
            <Row>
              {
                subBrandListFinal.length > 0
                &&
                <DoubleColumnRowParent>
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "SubBrand" })}
                    name="subBrandName"
                    options={subBrandListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedSubBrandName}
                    initialValue={selectedSubBrandId}
                    disabled={localLoading || loading}
                    setFieldLabel={setSelectedSubBrandName}
                    setFieldValue={setSelectedSubBrandId}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                    validationRequired={true}
                  />
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "PreferredBranch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "MediaSource" })}
                  name="mediaSource"
                  styles={{}}
                  options={mediaSourceListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedMediaSourceName}
                  initialValue={selectedMediaSourceId}
                  setFieldLabel={setSelectedMediaSourceName}
                  setFieldValue={setSelectedMediaSourceId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                {
                  loadingDropdownRefresh
                  ?
                  <Loading/>
                  :
                  <GeneralSelectDropdown
                    title={intl.formatMessage({ id: "Race" })}
                    name="race"
                    styles={{}}
                    options={raceListFinal}
                    labelField="name"
                    valueField="id"
                    initialLabel={selectedRaceName}
                    initialValue={selectedRaceId}
                    setFieldLabel={setSelectedRaceName}
                    setFieldValue={setSelectedRaceId}
                    disabled={localLoading || loading}
                    validationRequired={true}
                    onChange={() => {
                      setCurrentPage(1);
                      setErrorMessage("");
                      setSuccessMessage("");
                    }}
                  />
                }
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "LastAppointmentDate"})})}
                  name={"LastAppointmentDateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={(value) => {
                    onSelectDateRange(value)
                  }}
                  initialValue={selectedAppointmentDateRange} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "LeadNo" })}
                  name="leadNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "LeadNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Lead" }) })}
                  name="leadStatus"
                  options={leadStatusList}
                  blnValueWithNewSpace={true}
                  labelField="displayValue"
                  valueField="key"
                  initialLabel={selectedLeadStatus}
                  initialValue={selectedLeadStatusId}
                  setFieldLabel={setSelectedLeadStatus}
                  setFieldValue={setSelectedLeadStatusId}
                  disabled={localLoading || loading}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="leadName"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Name" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "MobileNo" })}
              name="leadMobileNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "MobileNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Email" })}
              name="leadEmail"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Email" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={AppointmentLeadColumns}
              data={appointmentLeadList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
              objSorted={defaultSorted}
              requestAPI={getAppointmentLead}
              branchId={selectedBranchId}
              branchName={selectedBranchName}
              sourceId={selectedMediaSourceId}
              sourceName={selectedMediaSourceName}
              leadName={validation.values.leadName}
              leadMobileNo={validation.values.leadMobileNo}
              leadEmail={validation.values.leadEmail}
              leadNo={validation.values.leadNo}
              subBrandId={selectedSubBrandId}
              subBrandName={selectedSubBrandName}
              raceId={selectedRaceId}
              raceName={selectedRaceName}
              appointmentDateRange={selectedAppointmentDateRange}
              leadStatus={selectedLeadStatusId}
              leadStatusName={selectedLeadStatus}
            />
        }
        {
          appointmentLeadFollowUpHistoryModal &&
          <AppointmentLeadFollowUpHistoryModal
            blnShow={appointmentLeadFollowUpHistoryModal}
            blnAction={Constants.add}
            setModal={setAppointmentLeadFollowUpHistoryModal}
            leadId={selectedAppointmentLeadDetails?.id}
            leadName={selectedAppointmentLeadDetails?.name}
            addFollowUp={addAppointmentLeadFollowUp}
            selectedFollowUpDetail={undefined}
            setFollowUpDetail={()=>{}}
            setErrorMessage={setErrorMessage}
            onSuccessFunction={()=> {
              fetchAppointmentLeadApi(true);
            }} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppointmentLead);
