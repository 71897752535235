import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Label, Row } from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { history } from "../../..";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import { checkPermission, getBranchUser, salesOrderStatusColor, newSpaceBeforeCapitalLetter, getBrandId, returnPriceWithCurrency, purchasedItemStatusColor, getBranchId, convertParamsUrlToArray, compareParamsArrayWithDefaultArray, convertValueWithSpaceInOptionsArray, firstDayOfMonthToCurrentDay, returnSubBrandList, appolousSalesOrderStatusColor } from "../../../app/common/function/function";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import moment from "moment";
import { DateRangeInterface, ObjectForDropdown, ObjectForReportRow } from "../../../app/models/common";
import _ from "lodash";
import Loading from "../../../app/components/loading/Loading";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link, useLocation } from 'react-router-dom';
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import ReportExpandRow from "../subView/ReportExpandRow";
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const DynamicSalesDetails = () => {
  const intl = useIntl();
  //Use Store
  const { reportAppolousStore, branchStore, customerStore, staticReferenceStore, productStore, serviceTreatmentStore, packageStore, commonStore } = useStore();
  const { loading, setLoading, setLoadingModal, errorMessage, totalItem, successMessage, windowSize, setSuccessMessage, setErrorMessage, setShowDeletePrompt } = commonStore;
  const { appolousDynamicSalesList, getAppolousDynamicSalesList, setAppolousDynamicSalesList, exportAppolousDynamicSalesList } = reportAppolousStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { appolousSaleStatus, getStaticReferenceWithType } = staticReferenceStore;
  const { productDropdownList, getProductDropdown } = productStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { packageDropdownList, getPackageDropdown } = packageStore;

  //Data List
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingBranchDropdown, setLoadingBranchDropdown] = useState(false);
  const [loadingAppolousSalesStatus, setLoadingAppolousSalesStatus] = useState(false);
  const [loadingCustomerDropdown, setLoadingCustomerDropdown] = useState(false);
  const [loadingProductDropdown, setLoadingProductDropdown] = useState(false);
  const [loadingServiceTreatmentDropdown, setLoadingServiceTreatmentDropdown] = useState(false);
  const [loadingPackageDropdown, setLoadingPackageDropdown] = useState(false);

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [selectedBranchOptions, setSelectedBranchOptions] = useState<any>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState<any>([]);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<any>([]);
  const [selectedProductOptions, setSelectedProductOptions] = useState<any>([]);
  const [selectedPackageOptions, setSelectedPackageOptions] = useState<any>([]);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState<any>([]);

  const [loadingDropdownRefresh, setLoadingDropdownRefresh] = useState(false);
  const [blnAdvanceFilter, setBlnAdvanceFilter] = useState(false);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const blnPermissionExportAppolousReport = checkPermission([PermissionConstants.ExportAppolousReport]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  const aryAppolousSalesOrderDetails: ObjectForReportRow[] = [
    { label: "Name", value: "itemName", type: "string" },
    { label: "Type", value: "itemType", type: "string" },
    { label: "Quantity", value: "quantity", type: "string" },
    { label: "Price", value: "price", type: "currency" },
    { label: "Discount", value: "discount", type: "string" },
    { label: "TotalPrice", value: "totalPrice", type: "currency" },
    { label: "Description", value: "description", type: "string" }];

  const paramsSearch = useLocation().search;

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchIds: [],
      customerIds: [],
      productIds: [],
      serviceIds: [],
      packageIds: [],
      statuses: [],
      includeOtherSalesDetails: false,
      salesCategoryName: "",
      invoiceNo: "",
      detailItemName: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  async function fetchBranchDropdown () {
    setLoadingBranchDropdown(true);
    let resultAPI = await getBranchDropDown();
    setLoadingBranchDropdown(false);
    return resultAPI;
  }

  async function fetchAppolousSaleStatus () {
    setLoadingAppolousSalesStatus(true);
    let resultAPI = await getStaticReferenceWithType(Constants.appolousSaleStatus);
    setLoadingAppolousSalesStatus(false);
    return resultAPI;
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown("", {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomerDropdown(false);
    }
    else if (!value) {
      setLoadingCustomerDropdown(false);
    }
  }

  async function fetchProductDropdown () {
    setLoadingProductDropdown(true);
    let resultAPI = await getProductDropdown();
    setLoadingProductDropdown(false);
    return resultAPI;
  }

  async function fetchServiceTreatmentDropdown () {
    setLoadingServiceTreatmentDropdown(true);
    let resultAPI = await getServiceTreatmentDropdown();
    setLoadingServiceTreatmentDropdown(false);
    return resultAPI;
  }

  async function fetchPackageDropdown () {
    setLoadingPackageDropdown(true);
    let resultAPI = await getPackageDropdown({ branchId: "" });
    setLoadingPackageDropdown(false);
    return resultAPI;
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  useEffect(() => {
    async function fetchDynamicSalesListAPI() {
      // setLoading(true);
      // setLoadingDropdownRefresh(true);

      let aryAPI: any = [
        fetchBranchDropdown(),
        fetchAppolousSaleStatus(),
        fetchProductDropdown(),
        fetchServiceTreatmentDropdown(),
        fetchPackageDropdown(),
      ];
      await Promise.all(aryAPI);

      // setLoading(false);
      // setLoadingDropdownRefresh(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppolousReport], true)) {
      return;
    }

    fetchDynamicSalesListAPI();

    return (() => {
      setAppolousDynamicSalesList([]);
    })
  }, []);

  async function fetchDynamicSalesDetailApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getAppolousDynamicSalesList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      branchIds: validation.values.branchIds,
      customerIds: validation.values.customerIds,
      productIds: validation.values.productIds,
      packageIds: validation.values.packageIds,
      serviceIds: validation.values.serviceIds,
      statuses: validation.values.statuses,
      includeOtherSalesDetails: validation.values.includeOtherSalesDetails,
      salesCategoryName: validation.values.salesCategoryName,
      invoiceNo: validation.values.invoiceNo,
      detailItemName: validation.values.detailItemName
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportDynamicSalesDetailsApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    await exportAppolousDynamicSalesList({
      pageNumber: 1,
      pageSize: pageSize,
      dateRange: selectedDateRange,
      branchIds: validation.values.branchIds,
      customerIds: validation.values.customerIds,
      productIds: validation.values.productIds,
      packageIds: validation.values.packageIds,
      serviceIds: validation.values.serviceIds,
      statuses: validation.values.statuses,
      includeOtherSalesDetails: validation.values.includeOtherSalesDetails,
      salesCategoryName: validation.values.salesCategoryName,
      invoiceNo: validation.values.invoiceNo,
      detailItemName: validation.values.detailItemName
    });
    setLocalLoading(false);
  }

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const DynamicSalesColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "performaInvoiceNo",
      text: intl.formatMessage({ id: "InvoiceNo" }).toUpperCase(),
      formatter: (cellContent, row) => <>
        <Link to={row.appolousSaleId ? `/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.appolousSaleId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
          <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.performaInvoiceNo || Constants.emptyData}</Label>
        </Link>
      </>
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.createdAt).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div>
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div>{row.customerName}</div>
      ),
    },
    {
      dataField: "salesCategoryName",
      text: intl.formatMessage({ id: "SalesCategory" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.salesCategoryName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "total",
      text: `${intl.formatMessage({ id: "TotalAmount" }).toUpperCase()}`,
      formatter: (cellContent, row) => (
        <div>{`${returnPriceWithCurrency(row.total)}`}</div>
      ),
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.totalPayment)}</div>
      ),
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{returnPriceWithCurrency(row.balance)}</div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={appolousSalesOrderStatusColor} />
      ),
    },
  ];

  const expandRow = {
    renderer: row => (
      <ReportExpandRow
        row={row}
        rowTitle={intl.formatMessage({ id: "AppolousSalesOrderDetails" })}
        rowFieldName={"details"}
        rowColumns={aryAppolousSalesOrderDetails}
        keyFieldName={"appolousSaleDetailId"}
      />
    ),
    showExpandColumn: true,
    // onlyOneExpanding: true,3
    className: "padding-2-5px",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <div>
        {
          !isAnyExpands
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    ),
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
      <div>
        {
          !expanded
            ?
            <i className="bx bx bx-plus"></i>
            :
            <i className="bx bx bx-minus"></i>
        }
      </div>
    )
  };

  const displayOptionalRow = (mainComponent: any) => {
    return (
      <div className="flex-align-items-center">
        {mainComponent()}
        <div style={{marginTop: '10px', backgroundColor: 'lightgrey', padding: '4px', borderRadius: '20px', marginLeft: "18px"}}>
          <p className="text-muted margin-top-0 margin-bottom-0">OR</p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "AppolousDynamicSalesDetails" }) })}
        title={intl.formatMessage({ id: "AppolousDynamicSalesDetails" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAppolousReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportDynamicSalesDetailsApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Appolous-Dynamic-Sales-Details.aspx`}>
        {
          !loadingDropdownRefresh
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "Date"})})}
                  name={"DateRange"}
                  disabled={localLoading || loading}
                  onChangeFunction={onSelectDateRange}
                  initialValue={selectedDateRange}
                  validationRequired={true} />
              </DoubleColumnRowParent>
              {
                !getBranchUser() &&
                  <DoubleColumnRowParent>
                    {
                      loadingBranchDropdown
                      ?
                      <Loading/>
                      :
                      <DropDownWithTitleMultiSelect
                        name={"branchIds"}
                        title={intl.formatMessage({ id: "Branch" })}
                        specifyReturnFieldName={[{ "field": "branchIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={branchDropDownList}
                        disabled={localLoading || loading || getBranchUser()}
                        initialValue={selectedBranchOptions}
                        validationRequired={false}
                        validation={validation} />
                    }
                </DoubleColumnRowParent>
              }
              <DoubleColumnRowParent>
                {
                  loadingAppolousSalesStatus
                  ?
                  <Loading/>
                  :
                  <DropDownWithTitleMultiSelect
                    name={"statuses"}
                    title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "AppolousSalesOrder" }) })}
                    specifyReturnFieldName={[{ "field": "statuses", "value": "displayValue" }]}
                    returnFieldWithLabel={false}
                    labelField={"displayValue"}
                    valueField={"key"}
                    options={appolousSaleStatus}
                    disabled={localLoading || loading}
                    blnValueWithNewSpace={true}
                    initialValue={selectedStatusOptions}
                    validationRequired={false}
                    validation={validation} />
                }
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                {
                  <DropDownWithTitleMultiSelect
                    name={"customerIds"}
                    title={intl.formatMessage({ id: "Customer" })}
                    placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                    specifyReturnFieldName={[{ "field": "customerIds", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={customerDropdownList}
                    disabled={localLoading || loading}
                    initialValue={selectedCustomerOptions}
                    validationRequired={false}
                    validation={validation}
                    blnSupportCustomSearch={true}
                    onChangeFunction={()=> {
                      clearCustomerDropdownList();
                    }}
                    onChangeCustomSearch={(value)=> {
                      fetchCustomerDropdown(value);
                    }}
                    onMenuOpenFunction={()=> {
                      clearCustomerDropdownList();
                      setLoadingCustomerDropdown(false);
                    }}
                    loadingCustomSearch={loadingCustomerDropdown}
                    setLoadingCustomSearch={setLoadingCustomerDropdown} />
                }
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "InvoiceNo" })}
                  name="invoiceNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterInvoiceNo" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "SalesCategoryName" })}
                  name="salesCategoryName"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "EnterSalesCategoryName" })}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IncludeOtherSalesDetails" })}
                  name="includeOtherSalesDetails"
                  type="checkbox"
                  disabled={localLoading || loading}
                  validation={validation}
                  childrenUI={
                    <Label className="margin-bottom-0 margin-left-4">
                      Yes
                    </Label>
                  }
                />
              </DoubleColumnRowParent>
            </Row>
            <hr/>
            <div className={`flex-align-items-center margin-bottom-16`}>
              <div style={{backgroundColor: Constants.lightgreyfaded, padding: '4px', paddingLeft: '16px', paddingRight: '16px', borderRadius: '8px'}} 
                onClick={()=> {
                  setBlnAdvanceFilter((valueAdvanceFilter)=> !valueAdvanceFilter);
                }}>
                <Label className="margin-bottom-0">{intl.formatMessage({ id: "AdvanceFilter" })}</Label>
                {blnAdvanceFilter ? <ChevronUpIcon/> : <ChevronDownIcon/>}
              </div>
            </div>
            {
              <Row style={{display: !blnAdvanceFilter ? "none": ""}}>
                <DoubleColumnRowParent>
                  {
                    loadingProductDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"productIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "Product" })}
                        specifyReturnFieldName={[{ "field": "productIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={productDropdownList}
                        disabled={localLoading || loading}
                        blnValueWithNewSpace={true}
                        initialValue={selectedProductOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  {
                    loadingServiceTreatmentDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"serviceIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "ServiceTreatment" })}
                        specifyReturnFieldName={[{ "field": "serviceIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={serviceTreatmentDropdownList}
                        disabled={localLoading || loading}
                        blnValueWithNewSpace={true}
                        initialValue={selectedServiceOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  {
                    loadingPackageDropdown
                    ?
                    <Loading/>
                    :
                    displayOptionalRow(()=> (
                      <DropDownWithTitleMultiSelect
                        name={"packageIds"}
                        className={"flex-1"}
                        title={intl.formatMessage({ id: "Package" })}
                        specifyReturnFieldName={[{ "field": "packageIds", "value": "id" }]}
                        returnFieldWithLabel={false}
                        labelField={"name"}
                        valueField={"id"}
                        options={packageDropdownList}
                        disabled={localLoading || loading}
                        blnValueWithNewSpace={true}
                        initialValue={selectedPackageOptions}
                        validationRequired={false}
                        validation={validation} />
                    ))
                  }
                </DoubleColumnRowParent>
                <DoubleColumnRowParent>
                  <GeneralInput
                    title={intl.formatMessage({ id: "DetailItemName" })}
                    name="detailItemName"
                    className={"margin-right-20"}
                    type="text"
                    disabled={localLoading || loading}
                    validation={validation}
                    placeholder={intl.formatMessage({ id: "EnterDetailItemName" })}
                  />
                </DoubleColumnRowParent>
              </Row>
            }
            <DoubleColumnRowParent>
              <MyButton
                type="button"
                class={`btn btn-primary margin-bottom-18`}
                content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
                loading={localLoading || loading}
                disable={localLoading || loading}
                onClick={() => {
                  fetchDynamicSalesDetailApi(true)
                }}
              />
            </DoubleColumnRowParent>
          </>
        }
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={DynamicSalesColumns}
              keyField={"appolousSaleId"}
              data={appolousDynamicSalesList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "DynamicSalesDetails" }) })}
              objSorted={defaultSorted}
              requestAPI={getAppolousDynamicSalesList}
              expandRow={expandRow}
              branchIds={validation.values.branchIds}
              customerIds={validation.values.customerIds}
              productIds={validation.values.productIds}
              packageIds={validation.values.packageIds}
              serviceIds={validation.values.serviceIds}
              statuses={validation.values.statuses}
              includeOtherSalesDetails={validation.values.includeOtherSalesDetails}
              dateRange={selectedDateRange}
              salesCategoryName={validation.values.salesCategoryName}
              invoiceNo={validation.values.invoiceNo}
              detailItemName={validation.values.detailItemName} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(DynamicSalesDetails);
