import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import DetailViewLayout from '../../app/components/layout/DetailViewLayout';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName } from "../../app/common/function/function";
import { RoutesList } from "../../app/constants/RoutesList";
import { Col, Form, Row } from 'reactstrap';
import { PermissionConstants } from '../../app/constants/PermissionConstants';
import { burgerMenu } from '../../app/models/common';
import { history } from "../..";
import _ from "lodash";
import { useStore } from '../../app/stores/store';
import { Constants } from '../../app/constants/Constants';
import DoubleColumnRowParent from '../../app/components/form/DoubleColumnRowParent';
import GeneralInput from '../../app/components/form/GeneralInput';
import GeneralTextArea from '../../app/components/form/GeneralTextArea';
import SingleColumnRowParent from '../../app/components/form/SingleColumnRowParent';
import DropDownWithTitle from '../../app/components/dropdown/DropDownWithTitle';
import LineBreakWithTittle from '../../app/components/form/LineBreakWithTittle';
import GeneralSubmitAndCancelBtn from '../../app/components/form/GeneralSubmitAndCancelBtn';
import Loading from '../../app/components/loading/Loading';
import { useLocation } from "react-router-dom";

const TaxEntityDetail = () => {
    //Use Paramsf
    const { id } = useParams();

    //Use INTL
    const intl = useIntl();

    const location = useLocation();
    const addAction = IncludesLocationPathName("add");
    const viewAction = IncludesLocationPathName("view");
    const editAction = IncludesLocationPathName("edit");
    const { commonStore, taxEntityStore } = useStore();
    const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
    const { taxEntityBusinessType, taxEntityIdentityType, taxEntityDetail, addTaxEntity, updateTaxEntity, getTaxEntityWithId, setTaxEntityDetail, getEInvoiceCodeWithType, getTaxEntityStaticReferenceWithType } = taxEntityStore;
    const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
        } ${intl.formatMessage({ id: "Tax" })}`;
    const [blnSubmitting, setBlnSubmitting] = useState(false);
    const [viewAfterSubmit, setViewAfterSubmit] = useState(false);
    const [displayStateDropdown, setDisplayStateDropdown] = useState(true);
    const [displayFieldValue, setDisplayFieldValue] = useState(true);
    const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
    const blnPermissionUpdateTaxEntity = checkPermission([PermissionConstants.UpdateTaxEntity]);
    const [eInvMiscList, setEInvMiscList] = useState([]);
    const [eInvMiscFilteredList, setEInvMiscFilteredList] = useState([{code:"",values:""}]);
    const [eInvStateList, setEInvStateList] = useState([]);
    const [eInvStateFilteredList, setEInvStateFilteredList] = useState([]);
    const [eInvCountryList, setEInvCountryList] = useState([]);
    
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: taxEntityDetail || {
            id: "",
            tinNo: "",
            customerId: "",
            customerName: "",
            businessType: "",
            identityType: "",
            identityNo: "",
            businessRegistrationNo: "",
            msicCode: "",
            msicName: "",
            sstRegisterNo: "",
            businessActivityDesc: "",
            tourismTaxRegisterNo: "",
            prevGstRegisterNo: "",
            registeredName: "",
            tradeName: "",
            phone: "",
            email: "",
            address: "",
            postCode: "",
            city: "",
            countryCode: "",
            countryName: "",
            stateCode: "",
            stateName: "",
        },
        validationSchema: Yup.object({
            tinNo: Yup.string().max(20).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "TinNo" }) })),
            businessType: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "BusinessType" }) })),
            identityType: Yup.string()
                .max(10)
                .when("businessType", {
                    is: (value) => [Constants.individual].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IdentityType" }) }))
                        .nullable()
                }).nullable(),
            identityNo: Yup.string()
                .max(30)
                .when(["countryName", "businessType"], {
                    is: (countryName, businessType) =>
                        countryName === Constants.malaysia.toUpperCase() &&
                        businessType === Constants.individual,
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "IdentityNo" }) }))
                        .nullable()
                }).nullable(),
            businessRegistrationNo: Yup.string()
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BusinessRegistrationNo" }) }))
                        .nullable()
                }).nullable(),
            msicCode: Yup.string()
                .max(5)
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "MsicCode" }) }))
                        .nullable()
                }).nullable(),
            sstRegisterNo: Yup.string().max(20).nullable(),
            businessActivityDesc: Yup.string()
                .max(100)
                .when("businessType", {
                    is: (value) => [Constants.government, Constants.business].includes(value),
                    then: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BusinessActivityDesc" }) }))
                        .nullable()
                }).nullable(),
            tourismTaxRegisterNo: Yup.string().max(20).nullable(),
            prevGstRegisterNo: Yup.string().max(20).nullable(),
            registeredName: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "RegisterName" }) })),
            tradeName: Yup.string().max(100).nullable(),
            phone: Yup.string().max(25).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Phone" }) })),
            email: Yup.string().email().max(200).nullable(),
            address: Yup.string().max(200).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Address" }) })),
            postCode: Yup.string().max(10).nullable(),
            city: Yup.string().max(50).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "City" }) })),
            countryCode: Yup.string().max(3).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Country" }) })),
            stateCode: Yup.string().max(3).required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "State" }) })),
        }),
        onSubmit: async (values) => {
            const valuesTemp: any = _.cloneDeep(values);
            try {
                if (addAction) {
                    let resultAddTaxEntity = await addTaxEntity(valuesTemp);
                    if (resultAddTaxEntity?.status) {
                        if (resultAddTaxEntity.status === Constants.success) {
                            setViewAfterSubmit(true);
                            setTimeout(() => {
                                history.push(`/${RoutesList.customer}/view/${valuesTemp.customerId}`)
                            }, Constants.timeoutNavigation)
                        }
                    }
                } else {
                    let resultUpdateTaxEntity = await updateTaxEntity(valuesTemp);
                    if (resultUpdateTaxEntity?.status) {
                        if (resultUpdateTaxEntity.status === Constants.success) {
                            setViewAfterSubmit(true);
                            setTimeout(() => {
                                history.push(`/${RoutesList.customer}/view/${valuesTemp.customerId}`)
                            }, Constants.timeoutNavigation)
                        }
                    }
                }
            } finally {
                validation.setSubmitting(false);
            }
        },
    });

    const disabledFieldInput = validation.isSubmitting || Boolean(successMessage) || viewAfterSubmit;

    const removeState = async () => {
        setDisplayStateDropdown(false)
        validation.setFieldValue("stateCode", "")
        validation.setFieldValue("stateName", "")
        setTimeout(() => {
            setDisplayStateDropdown(true)
        }, 100)
    }

    const removeHiddenFieldValue = async (valueBusinessType) => {
        setDisplayFieldValue(false)
        if (valueBusinessType === Constants.individual) {
            validation.setFieldValue("businessRegistrationNo", "")
            validation.setFieldValue("msicCode", "")
            validation.setFieldValue("msicName", "")
            validation.setFieldValue("sstRegisterNo", "")
            validation.setFieldValue("businessActivityDesc", "")
            validation.setFieldValue("tourismTaxRegisterNo", "")
            validation.setFieldValue("prevGstRegisterNo", "")
        } else {
            validation.setFieldValue("identityType", "")
            validation.setFieldValue("identityNo", "")
            validation.setFieldValue("msicCode", eInvMiscFilteredList[0].code)
            validation.setFieldValue("msicName", eInvMiscFilteredList[0].values)
        }
        setTimeout(() => {
            setDisplayFieldValue(true)
        }, 100)
    }

    useEffect(() => {
        async function fetchTaxEntityDetailAPI() {
            setLoading(true);
            let aryAPI: any = [
                getTaxEntityStaticReferenceWithType(Constants.taxEntityBusinessType),
                getTaxEntityStaticReferenceWithType(Constants.taxEntityIdentityType),
                getEInvoiceCodeWithType(Constants.msic),
                getEInvoiceCodeWithType(Constants.state),
                getEInvoiceCodeWithType(Constants.country)
            ];
            if (id && !addAction) {
                aryAPI.push(getTaxEntityWithId(id));
            }
            let resultAPI = await Promise.all(aryAPI);
            if (resultAPI[2]) {
                setEInvMiscList(resultAPI[2])
                setEInvMiscFilteredList(resultAPI[2].filter(s => s.code === '00000'))
            }
            if (resultAPI[3]) {
                setEInvStateList(resultAPI[3])
                setEInvStateFilteredList(resultAPI[3].filter(s => s.code === '00' || s.code === '17'))
            }
            if (resultAPI[4]) {
                setEInvCountryList(resultAPI[4])
            }
            if (blnPermissionUpdateTaxEntity && viewAction) {
                burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.taxEntity}/edit/${id}`) } })
            }

            setLoading(false);
        }

        if (addAction) {
            setTaxEntityDetail(undefined);
        }

        if (!addAction && !viewAction) {
            if (!checkPermission([PermissionConstants.UpdateTaxEntity], true)) {
                return;
            }
        }
        else if (addAction) {
            if (!checkPermission([PermissionConstants.CreateTaxEntity], true)) {
                return;
            }
        }
        else if (viewAction) {
            if (!checkPermission([PermissionConstants.ManageTaxEntity], true)) {
                return;
            }
        }

        fetchTaxEntityDetailAPI();
        if (location.state) {
            if (location.state.detail) {
                if (location.state.detail) {
                    validation.setFieldValue("customerId", location.state.detail.customerId)
                    validation.setFieldValue("customerName", location.state.detail.customerName)
                }
            }
        }
        return (() => {
            if (id && !addAction) {
                setTaxEntityDetail(undefined);
            }
        })
    }, [])

    useEffect(() => {
        if (validation.isSubmitting) {
            setBlnSubmitting(true);
        }

        if (!validation.isSubmitting && blnSubmitting) {
            setBlnSubmitting(false);
            if (Object.keys(validation.errors).length > 0) {
                if (Constants.validationError) {
                    console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
                }
                setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
            }
        }
    }, [validation.isSubmitting, validation.errors])

    const goBackCustomerProfile = () => {
        history.push(`/${RoutesList.customer}/view/${validation.values.customerId}`)
    }

    return (
        <div>
            <DetailViewLayout
                title={displayTitle}
                breadCrumbList={[]}
                burgerMenuList={burgerMenuListContent}
                auditTrailId={viewAction ? id : null}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <Form
                        className="standard-layout"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        onBlur={() => {
                            if (errorMessage || successMessage) {
                                setErrorMessage("");
                                setSuccessMessage("");
                            }
                        }}>
                        <Row form={+true}>
                            {" "}
                            {/* +true = Convert boolean to numbers*/}
                            <Col xl={12}>
                                {
                                    <>
                                        <LineBreakWithTittle
                                            paddingBottom="0px"
                                            title="Tax Entity"
                                            h4Title />
                                        <Row>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "Tin" }).toUpperCase()}
                                                    name="tinNo"
                                                    className="mb-3 mt-4"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction || editAction}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "CustomerName" }).toUpperCase()}
                                                    name="customerName"
                                                    className="mb-3 mt-4"
                                                    type="text"
                                                    disabled={true}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                        </Row>
                                    </>
                                }
                                <Row>
                                    <DoubleColumnRowParent>
                                        <DropDownWithTitle
                                            name="businessType"
                                            title={intl.formatMessage({ id: "BusinessType" })}
                                            specifyReturnFieldName={[{ "field": "businessType", "value": "displayValue" }]}
                                            labelField={"displayValue"}
                                            valueField={"displayValue"}
                                            options={taxEntityBusinessType}
                                            disabled={disabledFieldInput || viewAction || editAction}
                                            initialValue={validation.values.businessType}
                                            onChangeFunction={removeHiddenFieldValue}
                                            validationRequired={true}
                                            validation={validation} />
                                    </DoubleColumnRowParent>
                                </Row>
                                {
                                    displayFieldValue
                                    &&
                                    validation.values.businessType === Constants.individual &&
                                    <>
                                        <Row>
                                            <DoubleColumnRowParent>
                                                <DropDownWithTitle
                                                    name="identityType"
                                                    title={intl.formatMessage({ id: "IdentityType" })}
                                                    specifyReturnFieldName={[{ "field": "identityType", "value": "displayValue" }]}
                                                    labelField={"displayValue"}
                                                    valueField={"displayValue"}
                                                    options={taxEntityIdentityType}
                                                    disabled={disabledFieldInput || viewAction}
                                                    initialValue={validation.values.identityType}
                                                    validationRequired={true}
                                                    validation={validation} />
                                            </DoubleColumnRowParent>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "IdentityNo" })}
                                                    name="identityNo"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                        </Row>
                                    </>
                                }
                                {
                                    displayFieldValue
                                    &&
                                    (validation.values.businessType === Constants.government ||
                                        validation.values.businessType === Constants.business) &&
                                    <>
                                        <Row>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "BusinessRegistrationNo" })}
                                                    name="businessRegistrationNo"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction || editAction}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                        </Row>
                                        <Row>
                                            <DoubleColumnRowParent>
                                                <DropDownWithTitle
                                                    name={"msicCode"}
                                                    title={intl.formatMessage({ id: "MsicCode" })}
                                                    specifyReturnFieldName={[
                                                        {
                                                            field: "msicCode",
                                                            value: "code",
                                                        },
                                                        {
                                                            field: "msicName",
                                                            value: "values",
                                                        },
                                                    ]}
                                                    labelField={"values"}
                                                    valueField={"code"}
                                                    options={eInvMiscList}
                                                    disabled={disabledFieldInput || viewAction}
                                                    initialLabel={validation.values.msicName}
                                                    initialValue={validation.values.msicCode}
                                                    validationRequired={true}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "SstRegisterNo" })}
                                                    name="sstRegisterNo"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                        </Row>
                                        <SingleColumnRowParent>
                                            <GeneralInput
                                                title={intl.formatMessage({ id: "BusinessActivityDesc" })}
                                                name="businessActivityDesc"
                                                type="text"
                                                disabled={disabledFieldInput || viewAction}
                                                validationRequired={true}
                                                validation={validation}
                                            />
                                        </SingleColumnRowParent>
                                        <Row>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "TourismTaxRegisterNo" })}
                                                    name="tourismTaxRegisterNo"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                            <DoubleColumnRowParent>
                                                <GeneralInput
                                                    title={intl.formatMessage({ id: "PrevGstRegisterNo" })}
                                                    name="prevGstRegisterNo"
                                                    type="text"
                                                    disabled={disabledFieldInput || viewAction}
                                                    validation={validation}
                                                />
                                            </DoubleColumnRowParent>
                                        </Row>
                                    </>
                                }
                                <SingleColumnRowParent>
                                    <GeneralInput
                                        title={intl.formatMessage({ id: "RegisterName" })}
                                        name="registeredName"
                                        type="text"
                                        disabled={disabledFieldInput || viewAction}
                                        validationRequired={true}
                                        validation={validation}
                                    />
                                </SingleColumnRowParent>
                                <SingleColumnRowParent>
                                    <GeneralInput
                                        title={intl.formatMessage({ id: "TradeName" })}
                                        name="tradeName"
                                        type="text"
                                        disabled={disabledFieldInput || viewAction}
                                        validation={validation}
                                    />
                                </SingleColumnRowParent>
                                <Row>
                                    <DoubleColumnRowParent>
                                        <GeneralInput
                                            title={intl.formatMessage({ id: "Phone" })}
                                            name="phone"
                                            type="text"
                                            blnNumberOnly={true}
                                            disabled={disabledFieldInput || viewAction}
                                            validationRequired={true}
                                            validation={validation}
                                        />
                                    </DoubleColumnRowParent>
                                    <DoubleColumnRowParent>
                                        <GeneralInput
                                            title={intl.formatMessage({ id: "EmailAddress" })}
                                            name="email"
                                            type="text"
                                            disabled={disabledFieldInput || viewAction}
                                            validation={validation}
                                        />
                                    </DoubleColumnRowParent>
                                </Row>
                                <SingleColumnRowParent>
                                    <GeneralTextArea
                                        title={intl.formatMessage({ id: "Address" })}
                                        name="address"
                                        row={5}
                                        disabled={disabledFieldInput || viewAction}
                                        validationRequired={true}
                                        validation={validation}
                                    />
                                </SingleColumnRowParent>
                                <Row>
                                    <DoubleColumnRowParent>
                                        <GeneralInput
                                            title={intl.formatMessage({ id: "PostCode" })}
                                            name="postCode"
                                            type="text"
                                            disabled={disabledFieldInput || viewAction}
                                            validation={validation}
                                        />
                                    </DoubleColumnRowParent>
                                    <DoubleColumnRowParent>
                                        <GeneralInput
                                            title={intl.formatMessage({ id: "City" })}
                                            name="city"
                                            type="text"
                                            disabled={disabledFieldInput || viewAction}
                                            validationRequired={true}
                                            validation={validation}
                                        />
                                    </DoubleColumnRowParent>
                                </Row>
                                <Row>
                                    <DoubleColumnRowParent>
                                        <DropDownWithTitle
                                            name={"countryCode"}
                                            title={intl.formatMessage({ id: "Country" })}
                                            specifyReturnFieldName={[
                                                {
                                                    field: "countryCode",
                                                    value: "code",
                                                },
                                                {
                                                    field: "countryName",
                                                    value: "values",
                                                },
                                            ]}
                                            labelField={"values"}
                                            valueField={"code"}
                                            options={eInvCountryList}
                                            disabled={disabledFieldInput || viewAction}
                                            onChangeFunction={removeState}
                                            initialLabel={validation.values.countryName}
                                            initialValue={validation.values.countryCode}
                                            validationRequired={true}
                                            validation={validation}
                                        />
                                    </DoubleColumnRowParent>
                                    {
                                        displayStateDropdown
                                        &&
                                        <DoubleColumnRowParent>
                                            <DropDownWithTitle
                                                name={"stateCode"}
                                                title={intl.formatMessage({ id: "State" })}
                                                specifyReturnFieldName={[
                                                    {
                                                        field: "stateCode",
                                                        value: "code",
                                                    },
                                                    {
                                                        field: "stateName",
                                                        value: "values",
                                                    },
                                                ]}
                                                labelField={"values"}
                                                valueField={"code"}
                                                options={validation.values.countryName === Constants.malaysia.toUpperCase() ? eInvStateList : eInvStateFilteredList}
                                                disabled={disabledFieldInput || viewAction}
                                                initialLabel={validation.values.stateName}
                                                initialValue={validation.values.stateCode}
                                                validationRequired={true}
                                                validation={validation}
                                            />
                                        </DoubleColumnRowParent>
                                    }
                                </Row>
                            </Col>
                            <GeneralSubmitAndCancelBtn
                                className={"mb-3 mt-3 text-align"}
                                successMessage={successMessage}
                                viewAction={false}
                                validation={validation}
                                cancelButtonFunction={() => goBackCustomerProfile()}
                            />
                        </Row>
                    </Form>
                )}
            </DetailViewLayout>
        </div>
    );
}

export default observer(TaxEntityDetail)