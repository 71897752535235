import { PaginationRequestBody } from "../models/pagination";
import { BranchForPackageObject, PackageAddObject, PackageDetailObject, PackageListObject, PackageUpdateObject, PackageDropdownRequestBody, PackageExportObject, PackageImportObject, PackageWithGroupCodeRequestObject, PackageWithGroupCodeListObject } from "../models/package";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { downloadExcel } from "../common/function/function";
import _ from "lodash";
export default class PackageStore {
  packageList: PackageListObject[] = [];
  packageDropdownList: PackageListObject[] = [];
  packageDetail: PackageDetailObject | undefined = undefined;
  packageBranchAccess: BranchForPackageObject[] = [];
  packagePaginationParams: PaginationRequestBody | undefined = undefined;
  packageWithGroupCode: PackageWithGroupCodeListObject[] = [];
  packageTableColumnFromStore: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.packageList = [];
    this.packageDropdownList = [];
    this.packageDetail = undefined;
    this.packageBranchAccess = [];
    this.packagePaginationParams = undefined;
    this.packageWithGroupCode = [];
    this.packageTableColumnFromStore = [];
  }

  setPackageDetail = (countryDetail: PackageDetailObject | undefined) => {
    this.packageDetail = countryDetail;
  }

  setPackagePaginationParams = (packagePaginationParams: PaginationRequestBody | undefined) => {
    this.packagePaginationParams = _.cloneDeep(packagePaginationParams);
  }

  setPackageTableColumnFromStore = (packageTableColumnFromStore: any[]) => {
    this.packageTableColumnFromStore = packageTableColumnFromStore;
  }

  getPackage = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      this.setPackagePaginationParams(PaginationRequestBody);
      PaginationRequestBody.name = encodeURIComponent(PaginationRequestBody.name || "");
      const resultPackages = await agent.Package.packageList(PaginationRequestBody);
      runInAction(() => {
        this.packageList = resultPackages.data;
        store.commonStore.setTotalItem(resultPackages.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.packageList = [];
    }
  };

  getPackageDropdown = async (PackageDropdownRequestBody: PackageDropdownRequestBody, blnSorting: boolean = false) => {
    try {
      let resultPackagesDropdown = await agent.Package.packageDropdownList(PackageDropdownRequestBody);
      runInAction(() => {
        if (blnSorting) {
          resultPackagesDropdown = _.orderBy(resultPackagesDropdown, ["displayOrder", "salesCategoryName", "name"], ['asc', 'desc', 'asc']);
        }
        this.packageDropdownList = resultPackagesDropdown;
      });
      return Promise.resolve(resultPackagesDropdown);
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.packageDropdownList = [];
    }
  };

  getPackageWithId = async (id: string) => {
    try{
      const resultPackageDetail = await agent.Package.packageDetail(id);
      runInAction(() => {
        this.packageDetail = resultPackageDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.packageDetail = undefined;
    }
  }

  addPackage = async (packageRequestBody: PackageAddObject) => {
    try{
      await agent.Package.addPackage(packageRequestBody);
      store.commonStore.setSuccessMessage(`PackageAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updatePackage = async (packageRequestBody: PackageUpdateObject) => {
    try{
      await agent.Package.updatePackage(packageRequestBody);
      store.commonStore.setSuccessMessage(`PackageUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deletePackage  = async (id: string, name: string) => {
    try {
      await agent.Package.deletePackage(id);
      store.commonStore.setSuccessMessage(`PackageDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPackageBranchAccess = async () => {
    try{
      const resultPackageBranchAccess = await agent.Package.packageBrandAccess();
      runInAction(() => {
        this.packageBranchAccess = resultPackageBranchAccess;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPackageWithGroupCode = async (packageWithGroupCodeRequestObject: PackageWithGroupCodeRequestObject) => {
    try{
      const resultPackageWithGroupCode = await agent.Package.packageWithGroupCode(packageWithGroupCodeRequestObject);
      runInAction(() => {
        this.packageWithGroupCode = resultPackageWithGroupCode;
      });
      return Promise.resolve(resultPackageWithGroupCode);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  exportPackage = async (PackageExportRequestBody: PackageExportObject) => {
    try{
      await downloadExcel(`/package/export`, PackageExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importPackage = async (packageImportRequestBody: PackageImportObject) => {
    try{
      const resutlImportPackage = await agent.Package.importPackage(packageImportRequestBody);
      return Promise.resolve(resutlImportPackage);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
