import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, contructValidationErrorMessage, getBranchId, getBranchName, newSpaceBeforeCapitalLetter, returnAdvancedSearchIcon, returnCurrency, returnPriceWithCurrency } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { history } from '../../';
import { Container, Row, Col, Card, CardBody, Table, UncontrolledTooltip, Input, Form, Label } from "reactstrap";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyButton from "../../app/components/form/MyButton";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { ObjectForTableWithDynamicInputAndCheckBox, burgerMenu } from "../../app/models/common";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import CustomerSearchModal from "../CustomerPurchase/Modal/CustomerSearchModal";
import TableWithSelectAllCheckBoxAndInput from "../../app/components/table/TableWithSelectAllCheckBoxAndInput";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { ProductRedemptionEligibleListObject } from "../../app/models/productRedemptionNote";
import VirtualWarehouseCreditExchangeConfirmationModal from "./Modal/VirtualWarehouseCreditExchangeConfirmationModal";
import { CustomerListObject } from "../../app/models/customer";

const VirtualWarehouseCreditExchangeDetail = () => {
  //Use Params
  const { id } = useParams();
  const location = useLocation();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { virtualWarehouseCreditExchangeStore, productRedemptionNoteStore, customerStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading } = commonStore;
  const { requestVirtualWarehouseCreditExchange, setCustomerIdFromVirtualWarehouseCreditExchange } = virtualWarehouseCreditExchangeStore;
  const { customerDropdownList, getCustomerDropdown, setCustomerDropdownList } = customerStore;
  const { getProductRedemptionEligibleWithId, } = productRedemptionNoteStore;
  const displayTitle = `${intl.formatMessage({ id: "Add" })} ${intl.formatMessage({ id: "VirtualWarehouseCreditExchange" })}`;
  const [breadCrumbList, setBreadCrumbList] = useState([{ title: intl.formatMessage({ id: "VirtualWarehouse" }), urlPath: RoutesList.virtualWarehouseCreditExchange }, { title: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }), urlPath: RoutesList.virtualWarehouseCreditExchange }]);
  const [displayCustomerDropdown, setDisplayCustomerDropdown] = useState(true);
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [loadingVirtualWarehouseCreditExchange, setLoadingVirtualWarehouseCreditExchange] = useState(false);
  const [virtualWarehouseCreditExchangeConfirmationModal, setVirtualWarehouseCreditExchangeConfirmationModal] = useState(false);
  const [blnBlockCreditExchangeSubmit, setBlnBlockCreditExchangeSubmit] = useState(false);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionViewCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const [blnAddFromCustomerProfile, setBlnAddFromCustomerProfile] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [localCustomerDropdownList, setLocalCustomerDropdownList] = useState<CustomerListObject[]>([]);

  const aryDynamicInputAndCheckBoxTab: ObjectForTableWithDynamicInputAndCheckBox[] = [
    {
      label: intl.formatMessage({ id: "VirtualWarehouseNo" }),
      type: "label",
      xl: "2.4",
      inputName: "virtualWarehouseNo",
      inputType: "text",
      linkPath: blnPermissionViewCustomerDeliverOrder ? `${RoutesList.customerVirtualWarehouse}/view/` : "",
      linkPathFieldName: "virtualWarehouseId"
    },
    {
      label: intl.formatMessage({ id: "BranchName" }),
      type: "label",
      xl: "2.4",
      inputName: "branchName",
      inputType: "text",
    },
    {
      label: intl.formatMessage({ id: "DeliverOrderNo" }),
      type: "label",
      xl: "2.4",
      inputName: "deliverOrderNo",
      inputType: "text",
      linkPath: blnPermissionViewCustomerDeliverOrder ? `${RoutesList.customerRedemption}/view/` : "",
      linkPathFieldName: "deliverOrderId"
    },
    {
      label: intl.formatMessage({ id: "AvailableQuantity" }),
      type: "input",
      xl: "2.4",
      inputName: "availableQuantity",
      inputType: "number",
      disabled: true,
    },
    {
      label: intl.formatMessage({ id: "Quantity" }),
      type: "input",
      xl: "2.4",
      inputName: "quantity",
      inputType: "number",
      numberMaxFieldName: "availableQuantity"
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchid: getBranchId(),
      branchName: getBranchName(),
      customerId: "",
      customerName: "",
      note: "",
      creditExchangeDetails: [],
      creditExchangeDetailsDisplay: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (!valuesTemp.customerId) {
          setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Customer" }) }))
          return;
        }
        let blnProceed = false;
        let blnProceedEmptyQuantity = false
        let creditExchangeDetailsDisplayTemp: ProductRedemptionEligibleListObject[] = [];
        if (valuesTemp) {
          if (valuesTemp.creditExchangeDetailsDisplay) {
            for (let a = 0; a < valuesTemp.creditExchangeDetailsDisplay.length; a++) {
              let valueRedeemDetailsTemp = _.cloneDeep(valuesTemp.creditExchangeDetailsDisplay[a]);
              if (valueRedeemDetailsTemp.isActive && valueRedeemDetailsTemp.quantity > 0) {
                creditExchangeDetailsDisplayTemp.push(valueRedeemDetailsTemp);
                blnProceed = true;
              }
              else if (valueRedeemDetailsTemp.isActive && valueRedeemDetailsTemp.quantity < 1) {
                blnProceedEmptyQuantity = true;
                break;
              }
            }
          }
        }

        if (blnProceedEmptyQuantity) {
          setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Quantity" }) }))
          return;
        }
        else if (!blnProceed) {
          setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Product" }) }))
          return;
        }
        else if (!valuesTemp.note) {
          setErrorMessage(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Reason" }) }))
          return;
        }
        else {
          if (!virtualWarehouseCreditExchangeConfirmationModal) {
            validation.setFieldValue("creditExchangeDetails", creditExchangeDetailsDisplayTemp)
            prefillCustomerInfo(valuesTemp.customerId);
            setVirtualWarehouseCreditExchangeConfirmationModal(true);
          }
          else {
            setBlnBlockCreditExchangeSubmit(true);
            await requestVirtualWarehouseCreditExchange(valuesTemp);
          }
        }
      } finally {
        setTimeout(() => {
          setBlnBlockCreditExchangeSubmit(false);
        }, 500);
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);

  useEffect(() => {
    async function fetchVirtualWarehouseCreditExchangeDetailAPI() {
      setLoading(true);
      if (location.pathname === `/${RoutesList.virtualWarehouseCreditExchange}/addFromCustomer`) {
        if (location) {
          if (location.state) {
            if (location.state.detail) {
              setLocalCustomerDropdownList(await getCustomerDropdown("", {customerId: location.state.detail.customerId}) || []);
              validation.setValues((values)=> ({
                ...values,
                customerId: location.state.detail.customerId,
                customerName: location.state.detail.customerName,
                creditExchangeDetailsDisplay: location.state.detail.creditExchangeDetailsDisplay
              }))
              setBlnAddFromCustomerProfile(true);
              setCustomerIdFromVirtualWarehouseCreditExchange(location.state.detail.customerId)
              setBreadCrumbList([{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }, { title: intl.formatMessage({ id: "CustomerProfile" }), urlPath: `${RoutesList.customer}/view/${location.state.detail.customerId}` }, ])
            }
          }
        }
      }

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.RequestVirtualWarehouseCreditExchange], true)) {
      return;
    }

    fetchVirtualWarehouseCreditExchangeDetailAPI();

    return (()=> {
      setCustomerIdFromVirtualWarehouseCreditExchange("");
    })
  }, []);

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }

    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const onClickSelect = (row) => {
    validation.setValues((values) => ({
      ...values,
      customerId: row.id,
      customerName: row.preferredName,
      creditExchangeDetails: [],
      creditExchangeDetailsDisplay: []
    }))
    fetchVirtualWarehouseCreditExchangeWithLoading(row.id);
    setDisplayCustomerDropdown(false);

    setTimeout(() => {
      setDisplayCustomerDropdown(true);
      setCustomerSearchModal(false);
    }, 100)
  }

  const prefillCustomerInfo = (id: string) => {
    let indexCustomer = _.findIndex(localCustomerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: localCustomerDropdownList[indexCustomer].id,
        customerName: localCustomerDropdownList[indexCustomer].preferredName,
        customerNo: localCustomerDropdownList[indexCustomer].customerNo,
        customerMobileNo: localCustomerDropdownList[indexCustomer].mobileNo,
      }))
    }
  }

  const fetchVirtualWarehouseCreditExchangeWithLoading = async (id: string) => {
    setLoadingVirtualWarehouseCreditExchange(true);
    setLocalCustomerDropdownList(customerDropdownList);
    let indexCustomer = _.findIndex(customerDropdownList, { id: id });
    if (indexCustomer > -1) {
      validation.setValues((values) => ({
        ...values,
        customerId: customerDropdownList[indexCustomer].id,
        customerName: customerDropdownList[indexCustomer].preferredName,
        creditExchangeDetails: [],
        creditExchangeDetailsDisplay: []
      }))
    }
    let resultAPI = await getProductRedemptionEligibleWithId(id);

    if (resultAPI) {
      let resultTemp = resultAPI.filter(eligibleProduct => {
        return eligibleProduct.unitPrice !== 0;
      });
      validation.setFieldValue("creditExchangeDetailsDisplay", resultTemp);
    }
    else {
      validation.setFieldValue("creditExchangeDetailsDisplay", []);
    }
    setLoadingVirtualWarehouseCreditExchange(false);
    clearCustomerDropdownList();
  }

  async function fetchCustomerDropdown (value: string) {
    clearCustomerDropdownList();
    let resultCustomerAPI = await getCustomerDropdown(getBranchId(), {customerNoOrName: value});    
    if (resultCustomerAPI) { 
      setCustomerDropdownList(resultCustomerAPI);
      setLoadingCustomer(false);
    }
    else if (!value) {
      setLoadingCustomer(false);
    }
  }

  const clearCustomerDropdownList = () => {
    setCustomerDropdownList([]);
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            id={Constants.virtualWarehouseCreditExchangeForm}
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              <Col xl={12}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "VirtualWarehouseCreditExchange" }) })}
                  h4Title />
                <DoubleColumnRowParent blnDoubleTab={true}>
                  <Row className="mt-3">
                    <Col xl='8' md='8' xs='8'>
                      {displayCustomerDropdown &&
                        <DropDownWithTitle
                          name={"customerId"}
                          title={intl.formatMessage({ id: "Customer" })}
                          placeholder={intl.formatMessage({ id: "CustomerPrefillMessage"})}
                          specifyReturnFieldName={[
                            {
                              field: "customerId",
                              value: "id",
                            }
                          ]}
                          labelField={"preferredName"}
                          valueField={"id"}
                          options={customerDropdownList}
                          disabled={disabledFieldInput || blnAddFromCustomerProfile}
                          initialLabel={validation.values.customerName}
                          initialValue={validation.values.customerId}
                          validationRequired={true}
                          validation={validation}
                          onChangeField={"id"}
                          onChangeFunction={fetchVirtualWarehouseCreditExchangeWithLoading}
                          blnSupportCustomSearch={true}
                          onChangeCustomSearch={(value)=> {
                            fetchCustomerDropdown(value);
                          }}
                          onMenuOpenFunction={()=> {
                            clearCustomerDropdownList();
                            setLoadingCustomer(false);
                          }}
                          loadingCustomSearch={loadingCustomer}
                          setLoadingCustomSearch={setLoadingCustomer}
                        />
                      }
                    </Col>
                    {/* <Col xl='4' md='4' xs='4'>
                      <MyButton
                        type="button"
                        class={`btn btn-primary margin-top-28`}
                        content={""}
                        subContent={returnAdvancedSearchIcon()}
                        loading={loading}
                        disable={loading || blnAddFromCustomerProfile}
                        onClick={() => { setCustomerSearchModal(true) }}
                      />
                    </Col> */}
                  </Row>
                  <SingleColumnRowParent blnDoubleTab={true}>
                    <GeneralTextArea
                      title={intl.formatMessage({ id: "Reason" })}
                      name="note"
                      row={5}
                      disabled={disabledFieldInput}
                      validation={validation}
                      validationRequired={true}
                    />
                  </SingleColumnRowParent>
                </DoubleColumnRowParent>
                {
                  validation.values.customerId
                    ?
                    loadingVirtualWarehouseCreditExchange
                      ?
                      <div>
                        <Loading />
                      </div>
                      :
                      <>
                        <LineBreakWithTittle
                          className="py-2 mt-3"
                          paddingBottom="0px"
                          title={intl.formatMessage({ id: "CreditExchangeDetails" })}
                          h4Title
                        />
                        <TableWithSelectAllCheckBoxAndInput
                          name="creditExchangeDetailsDisplay"
                          title=""
                          blnTableViewCss={true}
                          blnEditable={true}
                          aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxTab}
                          data={validation.values.creditExchangeDetailsDisplay}
                          validation={validation}
                          selectAllTextFieldDisplay={"productName"}
                          selectAllTextFieldDisplayXLSize={2}
                          disabled={disabledFieldInput} />
                        <div className="mt-3" />
                      </>
                    :
                    <div />
                }
              </Col>
              <GeneralSubmitAndCancelBtn
                className="mt-4"
                successMessage={successMessage}
                submitButtonTitle={intl.formatMessage({ id: "Submit" })}
                cancelButtonFunction={blnAddFromCustomerProfile ? ()=> {history.push(`/${RoutesList.customer}/view/${location.state.detail.customerId}`)} : undefined}
                viewAction={false}
                validation={validation}
              />
            </Row>
          </Form>
        )}
        {
          customerSearchModal
          &&
          <CustomerSearchModal
            blnShow={customerSearchModal}
            blnHideOtherBranch={false}
            setModal={setCustomerSearchModal}
            title={intl.formatMessage({ id: "InputSelectTitle" }, { field: intl.formatMessage({ id: "CustomerSearch" }) })}
            onSelectFunction={onClickSelect} />
        }
        {
          virtualWarehouseCreditExchangeConfirmationModal &&
          <VirtualWarehouseCreditExchangeConfirmationModal
            blnShow={virtualWarehouseCreditExchangeConfirmationModal}
            setModal={setVirtualWarehouseCreditExchangeConfirmationModal}
            validation={validation}
            blnBlockCreditExchangeSubmit={blnBlockCreditExchangeSubmit} />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(VirtualWarehouseCreditExchangeDetail);
