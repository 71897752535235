import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Label,
  Row} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { ChangePaymentModeListObject } from '../../app/models/changePaymentMode';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams, getBranchId, getBranchName, getBranchUser, changePaymentModeStatusColor, arraySpliceInAllValue, newSpaceBeforeCapitalLetter } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import moment from "moment";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";

const ChangePaymentMode = () => {
  const intl = useIntl();
  //Use Store
  const { changePaymentModeStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { changePaymentModeList, changePaymentModePaginationParams, getChangePaymentMode, deleteChangePaymentMode, setChangePaymentModePaginationParams } = changePaymentModeStore;
  const { changePaymentModeStatus, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<ChangePaymentModeListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [localLoading, setLocalLoading] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>({ fromDate: "", toDate: "" });
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const allBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : allBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedChangePaymentModeStatusesOptions, setSelectedChangePaymentModeStatusesOptions] = useState<string[]>([]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const blnPermissionCreateChangePaymentMode =  checkPermission([PermissionConstants.CreateChangePaymentMode]);
  const blnPermissionUpdateChangePaymentMode =  checkPermission([PermissionConstants.UpdateChangePaymentMode]);
  const blnPermissionDeleteChangePaymentMode =  checkPermission([PermissionConstants.DeleteChangePaymentMode]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const blnPermissionReviewChangePaymentModeFinalApproval = checkPermission([PermissionConstants.ReviewChangePaymentModeFinalApproval]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      changePaymentModeStatusIds: [],
      changePaymentModeNo: "",
      name: "",
      identityNo: "",
      contactNo: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });
  
  let changePaymentModeRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    statuses: validation.values.changePaymentModeStatusIds,
    changePaymentModeNo: validation.values.changePaymentModeNo,
    preparedFromDate: selectedDateRange.fromDate,
    preparedToDate: selectedDateRange.toDate,
    name: validation.values.name,
    identityNo: validation.values.identityNo,
    contactNo: validation.values.contactNo
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchChangePaymentModeAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.changePaymentMode)
      if (blnBackNavigation) {
        if (changePaymentModePaginationParams) {
          setCurrentPage(changePaymentModePaginationParams.pageNumber)
          setSelectedBranchId(changePaymentModePaginationParams.branchId || "");
          setSelectedBranchName(changePaymentModePaginationParams.branchName || "");
          setSelectedDateRange({fromDate: changePaymentModePaginationParams.requestFromDate || "", toDate: changePaymentModePaginationParams.requestToDate || ""})

          if (changePaymentModePaginationParams.statuses) {
            validation.setFieldValue("changePaymentModeStatusIds", changePaymentModePaginationParams.statuses);
          }

          validation.setValues((values) => ({
            ...values,
            changePaymentModeNo: changePaymentModePaginationParams.changePaymentModeNo || "",
            name: changePaymentModePaginationParams.name || "",
            identityNo: changePaymentModePaginationParams.identityNo || "",
            contactNo: changePaymentModePaginationParams.contactNo || ""
          }))
          changePaymentModeRequestBody = changePaymentModePaginationParams;
        }
      }
      else {
        setChangePaymentModePaginationParams(undefined);
      }
      
      let aryAPI: any = [
        getChangePaymentMode(changePaymentModeRequestBody),
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.changePaymentModeStatus),
      ]
      let resultAPI = await Promise.all(aryAPI);
      setBranchListFinal(arraySpliceInAllValue(resultAPI[1], isEligibleAllBranch ? allBranch : "", true))

      if (changePaymentModePaginationParams && changePaymentModePaginationParams.statuses) {
        let changePaymentModeStatusOptionListTemp: any = [];
        resultAPI[2].map((valueChangePaymentModeStatus) => {
        for (var a = 0; a < changePaymentModePaginationParams.statuses!.length; a++) {
          if(changePaymentModePaginationParams.statuses![a] ===  valueChangePaymentModeStatus.key){
            changePaymentModeStatusOptionListTemp.push({
              ...valueChangePaymentModeStatus,
              label: newSpaceBeforeCapitalLetter(valueChangePaymentModeStatus.displayValue),
              value: valueChangePaymentModeStatus.key
            })
          }}
        })
        setSelectedChangePaymentModeStatusesOptions(changePaymentModeStatusOptionListTemp);
      }

      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageChangePaymentMode], true)) {
      return;
    }

    fetchChangePaymentModeAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.changePaymentMode)
        if (!blnSetParams) {
          setChangePaymentModePaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) 
    {
      fetchChangePaymentModeAPI(true);
    }
  }, [selectedDateRange, selectedBranchId, triggerSearch]);

  const onClickDelete = async (row: ChangePaymentModeListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchChangePaymentModeAPI(blnDropDown?: boolean) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getChangePaymentMode({
      ...changePaymentModeRequestBody,
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  //Table Content UI
  const ChangePaymentModeColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "changePaymentModeNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase()
    },
    {
      dataField: "preparedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        row.preparedOn
        ?
        <>
          <div className="">
            {`${moment(row.preparedOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.preparedOn).format(Constants.displayTimeFormat)}
          </div>
        </>
        :
        Constants.emptyData
      ),
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{`${row.branchName || Constants.emptyData}`}</div>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer
        ?
        <div className="text-center">
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div className="text-center">{row.customerName}</div>
      ),
    },
    {
      dataField: "customerIdentityNo",
      text: intl.formatMessage({ id: "IdentityNo" }).toUpperCase()
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "MobileNo" }).toUpperCase()
    },
    {
      dataField: "preparedBy",
      text: intl.formatMessage({ id: "PreparedBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>{row.preparedBy || Constants.emptyData}</>
      )
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.status}
          statusColorFunction={changePaymentModeStatusColor}/>
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageChangePaymentMode, PermissionConstants.UpdateChangePaymentMode, PermissionConstants.DeleteChangePaymentMode]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.status !== Constants.draft
              &&
              <TableActionButton 
                to={`/${RoutesList.changePaymentMode}/view/${row.id}`}
                type="view"/>
            }
            {
              blnPermissionUpdateChangePaymentMode
              &&
              (row.status === Constants.draft || row.status === Constants.pendingSign || row.status === Constants.reviseRequired)
              &&
              <TableActionButton 
                to={`/${RoutesList.changePaymentMode}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionReviewChangePaymentModeFinalApproval
              &&
              row.status === Constants.completed
              &&
              !row.isCompletedRecordUpdated
              &&
              <TableActionButton 
                to={`/${RoutesList.changePaymentMode}/editcompleted/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteChangePaymentMode
              &&
              row.status === Constants.draft
              &&
              <TableActionButton 
                type="delete"
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];
  
  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }
  
  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
        title={intl.formatMessage({ id: "ChangePaymentMode" })}
        addButton={
          blnPermissionCreateChangePaymentMode
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
            onClick={() => { history.push(`/${RoutesList.changePaymentMode}/add`) }}
            linkTo={`/${RoutesList.changePaymentMode}/add`}
            disable={loading} />
        }>
        {
          !initialCallAPI
          &&
          <>
            <Row>
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "Branch" })}
                  name="branchName"
                  options={branchListFinal}
                  initialLabel={selectedBranchName}
                  initialValue={selectedBranchId}
                  setFieldLabel={setSelectedBranchName}
                  setFieldValue={setSelectedBranchId}
                  disabled={localLoading || loading || getBranchUser()}
                  validationRequired={true}
                  onChange={() => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralDateRange
                  title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PreparedDate"})})}
                  name={"dateRange"}
                  disabled={localLoading || loading}
                  initialValue={selectedDateRange}
                  onChangeFunction={onSelectDateRange} />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <DropDownWithTitleMultiSelect
                  name="changePaymentStatuses"
                  title={intl.formatMessage({ id: "Status" })}
                  specifyReturnFieldName={[{ "field": "changePaymentModeStatusIds", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={changePaymentModeStatus}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  initialValue={selectedChangePaymentModeStatusesOptions}
                  validationRequired={false}
                  validation={validation}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
            <Row>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "DocumentNo" })}
                  name="changePaymentModeNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "DocumentNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "Name" })}
                  name="name"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "Name" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "IdentityNo" })}
                  name="identityNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "IdentityNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
              <DoubleColumnRowParent>
                <GeneralInput
                  title={intl.formatMessage({ id: "MobileNo" })}
                  name="contactNo"
                  type="text"
                  disabled={localLoading || loading}
                  validation={validation}
                  placeholder={intl.formatMessage({ id: "MobileNo" })}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              </DoubleColumnRowParent>
            </Row>
          </>
        }
        {
          localLoading
          ?
          <Loading />
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={totalItem}
            tableColumns={ChangePaymentModeColumns}
            data={changePaymentModeList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
            objSorted={defaultSorted}
            branchId={selectedBranchId}
            branchName={selectedBranchName}
            statuses={validation.values.changePaymentModeStatusIds}
            changePaymentModeNo={validation.values.changePaymentModeNo}
            preparedFromDate={selectedDateRange.fromDate}
            preparedToDate={selectedDateRange.toDate}
            name={validation.values.name}
            identityNo={validation.values.identityNo}
            contactNo={validation.values.contactNo}
            requestAPI={getChangePaymentMode} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteChangePaymentMode(selectedRow?.id || "", "");
            let currentPageTemp = currentPage;
            if (changePaymentModeList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getChangePaymentMode({ pageNumber: currentPageTemp, pageSize: pageSize })
            setLoading(false);
          }} />
      </ListViewLayout>
    </div>
  );
}

export default observer(ChangePaymentMode);
