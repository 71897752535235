import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { checkPermission, IncludesLocationPathName, stockReceiveStatusColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Card, CardBody, Table, UncontrolledTooltip, Form, Label } from "reactstrap";
import moment from "moment";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import RefreshCircleIcon from 'mdi-react/RefreshIcon';
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const StockReceiveViewDetail = () => {
  //Use Params
  const { id } = useParams();
  //Use INTL
  const intl = useIntl();
  //Use Store
  const { stockReceiveStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, setErrorMessage, setSuccessMessage, setLoading, } = commonStore;
  const { stockReceiveDetail, setStockReceiveDetail, getStockReceiveWithId, completeStockReceive, updateStockReceiveStatus } = stockReceiveStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view") || IncludesLocationPathName("print");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "StockReceive" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "StockReceive" }), urlPath: RoutesList.stockReceive }];
  const [showRefreshStatusPrompt, setShowRefreshStatusPrompt] = useState(false);
  const stockReceiveFullDetail = stockReceiveDetail || {
    id: "",
    isDelete: false,
    stockReceiveNo: "",
    receivedDate: "",
    requestBranchId: "",
    requestBranchName: "",
    requestType: "",
    fromSupplierId: "",
    fromSupplierName: "",
    fromBranchId: "",
    fromBranchName: "",
    note: "",
    status: "",
    stockRequestId: "",
    stockRequestNo: "",
    isAllowUpdateStatus: false,
    stockReceiveDetails: []
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: stockReceiveDetail || {
      id: "",
      stockRequestId: "",
      requestType: "",
      note: ""
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      try {
        if (blnPartialCompleted) {
          await completeStockReceive(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnHQRequestType = (validation.values.requestType !== "" && validation.values.requestType === Constants.hq);
  const blnPartialCompleted = stockReceiveFullDetail.status === Constants.partialCompleted;
  const blnPermissionManageStockRequest = checkPermission([PermissionConstants.ManageStockRequest])
  const blnPermissionCompleteStockReceive = checkPermission([PermissionConstants.CompleteStockReceive])
  const blnUpdateStockReceiveStatus = checkPermission([PermissionConstants.UpdateStockReceiveStatus]);
  const blnIsAllowUpdateStatus = stockReceiveFullDetail.isAllowUpdateStatus;

  async function fetchStockReceiveDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      await getStockReceiveWithId(id);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!checkPermission([PermissionConstants.ManageStockReceive], true)) {
      return;
    }
    
    fetchStockReceiveDetailAPI();

    return (() => {
      if (id && !addAction) {
        setStockReceiveDetail(undefined);
      }
    })
  }, []);


  const displayHeaderRowData = (label, value) => {
    return (
      <Row>
        <h6 className="m-0 text-muted">
          {label}
        </h6>
        <h6 className="text-muted">
          {value}
        </h6>
      </Row>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        blnSupportStickyHeader={true}
        blnShowPrint={true}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <Col>
                          <div className="logo-lg" style={{ lineHeight: '20px', marginBottom: '16px' }}>
                            <span style={{ fontSize: Constants.documentTitleFontSize, fontWeight: 'bold', lineHeight: '20px' }}>
                              {intl.formatMessage({ id: "StockReceive" })}
                            </span>
                          </div>
                        </Col>
                        <Row>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "StockReceiveNo" })}:`, stockReceiveFullDetail.stockReceiveNo)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "From" })}:`, `${blnHQRequestType ? stockReceiveFullDetail.fromSupplierName : stockReceiveFullDetail.fromBranchName} ${blnHQRequestType ? "" : `(${intl.formatMessage({ id: "Branch" })})`}`)}
                          </Col>
                          <Col>
                            {displayHeaderRowData(`${intl.formatMessage({ id: "StockRequestNo" })}:`, 
                            blnPermissionManageStockRequest ?
                              <Link to={stockReceiveFullDetail.stockRequestId ? `/${RoutesList.stockRequest}/view/${validation.values.stockRequestId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                                <Label className="margin-bottom-0 pointer-clickable">{stockReceiveFullDetail.stockRequestNo || Constants.emptyData}</Label>
                              </Link>
                              :
                            `${stockReceiveFullDetail.stockRequestNo}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "To" })}:`, `${stockReceiveFullDetail.requestBranchName}`)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ReceivedDate" })}:`, stockReceiveFullDetail.receivedDate !== Constants.invalidDate ? moment(stockReceiveFullDetail.receivedDate).format(Constants.displayDateFormat) : Constants.emptyData)}
                            {displayHeaderRowData(`${intl.formatMessage({ id: "ReceivedTime" })}:`, stockReceiveFullDetail.receivedDate !== Constants.invalidDate ? moment(stockReceiveFullDetail.receivedDate).format(Constants.displayTimeFormat) : Constants.emptyData)}
                          </Col>
                        </Row>
                      </div>
                      <hr/>
                      <address style={{ lineHeight: '20px', marginTop: '20px' }}>
                        <strong>{intl.formatMessage({ id: "Status" })}:</strong>
                        {
                          blnUpdateStockReceiveStatus && blnIsAllowUpdateStatus
                          ?
                          <span className="margin-left-2" onClick={()=> {setShowRefreshStatusPrompt(true)}}>
                            <UncontrolledTooltip placement="top" target={"updateStockReceiveStatus"}>
                              {intl.formatMessage({ id: "RefreshStatus" })}
                            </UncontrolledTooltip>
                            <span><RefreshCircleIcon size={22} className={`${disabledFieldInput ? "text-secondary" : "text-danger"} pointer-clickable`} id="updateStockReceiveStatus" /></span>
                          </span>
                          :
                          null
                        }
                        <br />
                        <div style={{ marginTop: '8px' }}>
                          <GeneralStatus
                            status={stockReceiveFullDetail.status}
                            statusColorFunction={stockReceiveStatusColor}
                            blnDisableCenter/>
                        </div>
                      </address>
                      <div className="py-2 mt-3">
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Product(s)" })}
                        </h3>
                      </div>
                      <div className="">
                        <Table className="table-fixed" style={{ width: '100%' }}>
                          <thead className="table-light bootstrap-table-sticky-header" id="bootstrap-table-sticky-header">
                            <tr>
                              <th className="">{intl.formatMessage({ id: "No." })}</th>
                              <th className="">{intl.formatMessage({ id: "ProductName" })}</th>
                              <th className="">{intl.formatMessage({ id: "UOM" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "RequestedQuantity" })}</th>
                              <th className="text-center">{intl.formatMessage({ id: "ReceivedQuantity" })}</th>
                              <th className="">{intl.formatMessage({ id: "Remarks" })}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              stockReceiveFullDetail.stockReceiveDetails.map((item, key) => (
                                <tr key={key} className={item.requestedQuantity !== item.quantity ? "table-pink" : ""}>
                                  <td className="">{`${key+1}. `}</td>
                                  <td className="">{item.productName}</td>
                                  <td className="">{item.measurementName}</td>
                                  <td className="text-center">{item.requestedQuantity}</td>
                                  <td className="text-center">{item.quantity}</td>
                                  <td className="">{item.remark || Constants.emptyData}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </Table>
                      </div>
                      <div className={'py-2 mt-3'}>
                        <h3 className="font-size-15 font-weight-bold">
                          {intl.formatMessage({ id: "Note" })} :
                        </h3>
                      </div>
                      <GeneralTextArea
                        title=""
                        name="note"
                        className="margin-top-0"
                        row={5}
                        disabled={(blnPartialCompleted && blnPermissionCompleteStockReceive ? false : true) || disabledFieldInput}
                        validation={validation}
                      />
                      <div className="mt-4" />
                      <div className="flex-direction-row d-print-none">
                        <GeneralSubmitAndCancelBtn
                          className="width-100-percentage"
                          successMessage={successMessage}
                          viewAction={false}
                          validation={validation}
                          dirtyDisabled={true}
                          hidePrimaryButton={blnPartialCompleted && blnPermissionCompleteStockReceive ? false : true}
                          submitButtonTitle={ Constants.complete }    
                          showPrint={true}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Form>
        )}
        {
          showRefreshStatusPrompt
          &&
          <ActionPromptModal 
            title={`Are you sure want to refresh status?`}
            primaryActionTitle={`Yes`}
            showPrompt={showRefreshStatusPrompt}
            setShowPrompt={setShowRefreshStatusPrompt}
            localLoading={disabledFieldInput}
            onPrimaryClick={async ()=> {
              validation.setSubmitting(true);
              let resultUpdateStockReceiveStatus = await updateStockReceiveStatus(stockReceiveFullDetail.id);
              if (resultUpdateStockReceiveStatus.status === Constants.success) {
                fetchStockReceiveDetailAPI();
              }
              setShowRefreshStatusPrompt(false);
              validation.setSubmitting(false);
            }}
          />
        }
      </DetailViewLayout>
    </div>
  );
};

export default observer(StockReceiveViewDetail);
