import { PaginationRequestBody } from "../models/pagination";
import { GoodsReceiveNoteAddObject, GoodsReceiveNoteDetailObject, GoodsReceiveNoteListObject, GoodsReceiveNoteUpdateObject } from "../models/goodsReceiveNote";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
export default class GoodsReceiveNoteStore {
  goodsReceiveNoteList: GoodsReceiveNoteListObject[] = [];
  goodsReceiveNoteDetail: GoodsReceiveNoteDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  reset = () => {
    this.goodsReceiveNoteList = [];
    this.goodsReceiveNoteDetail = undefined;
  }

  setGoodsReceiveNoteDetail = (countryDetail: GoodsReceiveNoteDetailObject | undefined) => {
    this.goodsReceiveNoteDetail = countryDetail;
  }

  getGoodsReceiveNote = async (PaginationRequestBody: PaginationRequestBody) => {
    try {
      const resultGoodsReceiveNotes = await agent.GoodsReceiveNote.goodsReceiveNoteList(PaginationRequestBody);
      runInAction(() => {
        this.goodsReceiveNoteList = resultGoodsReceiveNotes.data;
        store.commonStore.setTotalItem(resultGoodsReceiveNotes.pagination.totalItems);
      });
    } 
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.goodsReceiveNoteList = [];
    }
  };

  getGoodsReceiveNoteWithId = async (id: string) => {
    try{
      const resultGoodsReceiveNoteDetail = await agent.GoodsReceiveNote.goodsReceiveNoteDetail(id);
      runInAction(() => {
        this.goodsReceiveNoteDetail = resultGoodsReceiveNoteDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.goodsReceiveNoteDetail = undefined;
    }
  }

  addGoodsReceiveNote = async (goodsReceiveNoteRequestBody: GoodsReceiveNoteAddObject) => {
    try{
      await agent.GoodsReceiveNote.addGoodsReceiveNote(goodsReceiveNoteRequestBody);
      store.commonStore.setSuccessMessage(`GoodsReceiveNoteAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateGoodsReceiveNote = async (goodsReceiveNoteRequestBody: GoodsReceiveNoteUpdateObject) => {
    try{
      await agent.GoodsReceiveNote.updateGoodsReceiveNote(goodsReceiveNoteRequestBody);
      store.commonStore.setSuccessMessage(`GoodsReceiveNoteUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteGoodsReceiveNote  = async (id: string, name: string) => {
    try {
      await agent.GoodsReceiveNote.deleteGoodsReceiveNote(id);
      store.commonStore.setSuccessMessage(`GoodsReceiveNoteDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
