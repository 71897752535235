import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  Col,
  Label,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import * as Yup from "yup";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import Loading from "../../app/components/loading/Loading";
import { Link } from 'react-router-dom';
import { useIntl } from "react-intl";
import CustomerProfileDetail from "./subView/CustomerProfileDetail";
import moment from "moment";
import classNames from "classnames";
import { appointmentStatusColor, appolousCustomerPackageStatusColor, appolousSalesOrderStatusColor, checkPermission, comparePathForPagination, displayPromotionCode, getBranchId, getBranchUser, getPurchaseTypeIcon, IncludesLocationPathName, newSpaceBeforeCapitalLetter, onClickWhatsappWithDelay, paymentStatusColor, purchasedItemStatusColor, removeAllSpace, returnPriceWithCurrency, returnSubBrandList, returnSwitchTabButtonUI, returnThousandSeperator, salesOrderStatusColor, virtualWarehouseStatusColor } from "../../app/common/function/function";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import PaginationTable from "../../app/components/table/PaginationTable";
import MyAddButton from "../../app/components/form/MyAddButton";
import CustomerNoteModal from "./Modal/CustomerNoteModal";
import { AddCustomerRelationshipObject, AddCustomerTagObject, CustomerNoteListObject, CustomerValidationResponseObject, RelationshipObject, TagDetailsObject, UpdateCustomerJoinedDateObject, UpdateCustomerMediaSourceObject } from "../../app/models/customer";
import DeleteModal from "../../app/components/modal/DeleteModal";
import SelectWithTableListModal from "../../app/components/modal/SelectWithTableListModal";
import TableActionButton from "../../app/components/table/TableActionButton";
import PackageVariantClosedIcon from 'mdi-react/PackageVariantClosedIcon';
import ArrangeBringForwardIcon from 'mdi-react/ArrangeBringForwardIcon';
import CreditCardMultipleIcon from 'mdi-react/CreditCardMultipleIcon';
import ClipboardEditIcon from 'mdi-react/ClipboardEditIcon';
import BookIcon from 'mdi-react/BookIcon';
import CashIcon from 'mdi-react/CashIcon';
import CashCheckIcon from 'mdi-react/CashCheckIcon';
import CashRemoveIcon from 'mdi-react/CashRemoveIcon';
import CashPlusIcon from 'mdi-react/CashPlusIcon';
import CreditAdjustmentModal from "./Modal/CreditAdjustmentModal";
import ExchangeToCreditDetailModal from "../ExchangeToCredit/Modal/ExchangeToCreditDetailModal";
import TransferCreditModal from "./Modal/TransferCreditModal";
import CardInfo from "../../app/components/form/CardInfo";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import ProgressCloseIcon from 'mdi-react/ProgressCloseIcon';
import AccountClockIcon from 'mdi-react/AccountClockIcon';
import WarehouseIcon from 'mdi-react/WarehouseIcon';
import FileRefreshOutlineIcon from 'mdi-react/FileRefreshOutlineIcon';
import CalendarArrowRightIcon from 'mdi-react/CalendarArrowRightIcon';
import SortCaret from "../../app/components/table/SortCaret";
import SubBranchRequestModal from "./Modal/SubBranchRequestModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";
import GeneralSnipButton from "../../app/components/form/GeneralSnipButton";
import { GeneralSnipButtonObject } from "../../app/models/common";
import AppolousCustomerPackageExtendExpiryModal from "../Appolous/CustomerPackage/Modal/AppolousCustomerPackageExtendExpiryModal";
import AppolousCustomerPackageStatusUpdateModal from "../Appolous/CustomerPackage/Modal/AppolousCustomerPackageStatusUpdateModal";
import AppolousCustomerPackageEditModal from "../Appolous/CustomerPackage/Modal/AppolousCustomerPackageEditModal";
import AppolousCustomerProductRedeemModal from "../Appolous/CustomerProduct/Modal/AppolousCustomerProductRedeemModal";
import AppolousCustomerProductStatusUpdateModal from "../Appolous/CustomerProduct/Modal/AppolousCustomerProductStatusUpdateModal";
import { ProductForBranchObject } from "../../app/models/branch";
import EditCustomerJoinedDateModal from "./Modal/EditCustomerJoinedDateModal";
import LoadingModal from "../../app/components/modal/LoadingModal";
import { AppolousCustomerPackageAddRequestBody } from "../../app/models/appolousCustomerPackage";
import { AppolousCustomerProductAddRequestBody } from "../../app/models/appolousCustomerProduct";
import AddAppolousCustomerPackageModal from "../Appolous/CustomerPackage/Modal/AddAppolousCustomerPackageModal";
import AddAppolousCustomerProductModal from "../Appolous/CustomerProduct/Modal/AddAppolousCustomerProductModal";
import EditCustomerMediaSourceModal from "./Modal/EditCustomerMediaSourceModal";
import AddTagModal from "./Modal/AddTagModal";
import AddRelationshipModal from "./Modal/AddRelationshipModal";
// users
import user9 from '../../assets/images/users/avatar-9.png';
import CustomerAccessRequestModal from "./Modal/CustomerAccessRequestModal";
import { CustomerAccessRequestListObject } from "../../app/models/customerAccessRequest";
import ExtendCustomerAccessRequestModal from "./Modal/ExtendCustomerAccessRequestModal";
import CreditHistoryModal from "./Modal/CreditHistoryModal";
import MyButton from "../../app/components/form/MyButton";
import { TaxEntityListObject } from "../../app/models/taxEntity";

const CustomerProfile = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { appointmentStore, customerStore, creditAdjustmentStore, exchangeToCreditStore, transferCreditStore, appolousCustomerPackageStore, appolousCustomerProductStore, appolousCustomerSalesStore, settingsStore, staticReferenceStore, branchStore, packageStore, productStore, customerAccessRequestStore, commonStore, taxEntityStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, prevPath, setErrorMessage, setSuccessMessage, setLoading, setShowDeletePrompt } = commonStore;
  const { appointmentList, getAppointmentList, appointmentTotalItems } = appointmentStore;
  const { customerDetail, getCustomerWithId, customerNoteList, getCustomerNote, addCustomerNote, updateCustomerNote, customerNoteDetail, setCustomerNoteDetail, getCustomerNoteWithId, deleteCustomerNote, customerRedeemHistoryList, getCustomerRedeemHistory, customerRedeemHistoryTotalItems, customerNoteTotalItems, getCustomerPackageRedeemHistory, customerPackageRedeemHistory, customerSubBranchHistory, getCustomerSubBranchHistory, customerSubBranchHistoryTotalItems, 
    customerPurchasedPackageList, 
    getCustomerPurchasedPackage, 
    customerPurchasedPackageTotalItems,
    customerPurchasedProductList, 
    getCustomerPurchasedProduct, 
    customerPurchasedProductTotalItems,
    customerReservedProductList, 
    getCustomerReservedProduct, 
    customerReservedProductTotalItems,
    customerPurchasedServiceList, 
    getCustomerPurchasedService, 
    customerPurchasedServiceTotalItems,
    customerClosePurchasedHistoryList, 
    getCustomerClosePurchasedHistory, 
    customerClosePurchasedHistoryTotalItems,
    customerSalesOrderList, 
    getCustomerSalesOrder, 
    customerSalesOrderTotalItems,
    customerTransferHistoryList, 
    getCustomerTransferHistory, 
    customerTransferHistoryTotalItems,
    resetWhenLeaveCustomerProfile,
    updateCustomerJoinedDate,
    updateCustomerMediaSource,
    addCustomerTag,
    deleteCustomerTag,
    addCustomerRelationship,
    deleteCustomerRelationship,
    customerServiceRedeemHistory,
    getCustomerServiceRedeemHistory
  } = customerStore;
  const { creditAdjustmentDetail, getCreditAdjustmentWithId, requestCreditAdjustment, previewCreditAdjustment } = creditAdjustmentStore;
  const { getExchangeToCreditWithId } = exchangeToCreditStore;
  const { appolousCustomerPackageList, getAppolousCustomerPackage, appolousCustomerPackageListTotalItems, extendExpiryAppolousCustomerPackage, statusUpdateAppolousCustomerPackage, editAppolousCustomerPackage, setAppolousCustomerPackageList, addAppolousCustomerPackage } = appolousCustomerPackageStore;
  const { appolousCustomerProductList, getAppolousCustomerProduct, appolousCustomerProductListTotalItems, redeemAppolousCustomerProduct, statusUpdateAppolousCustomerProduct, setAppolousCustomerProductList, addAppolousCustomerProduct } = appolousCustomerProductStore;
  const { appolousCustomerSalesList, getAppolousCustomerSales, appolousCustomerSalesListTotalItems, setAppolousCustomerSalesList } = appolousCustomerSalesStore;
  const { getBranchProductList } = branchStore;
  const { transferCustomerCredit, getCustomerTransferCreditHistory, customerTransferCreditHistoryList, customerTransferCreditHistoryTotalItems } = transferCreditStore;
  const { creditAdjustmentType, debitType, transferCreditAdjustmentType, creditPaymentType, getStaticReferenceWithType } = staticReferenceStore;
  const { packageDropdownList, getPackageDropdown} = packageStore;
  const { productDropdownList, getProductDropdown} = productStore;
  const { generalSettings, getGeneralSettings } = settingsStore;
  const { customerAccessRequestList, customerAccessRequestTotalItems, addCustomerAccessRequest, getCustomerAccessRequest, extendCustomerAccessRequest, deleteCustomerAccessRequest, setCustomerAccessRequestList } = customerAccessRequestStore;
  const { taxEntityList, customerTaxEntityTotalItems, getCustomerTaxEntityWithId, addTaxEntityFormHistory, setTaxEntityList, deleteTaxEntity, blnScrollTaxEntityOnCustomerProfile, setBlnScrollTaxEntityOnCustomerProfile } = taxEntityStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "CustomerProfile" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Customer" }), urlPath: RoutesList.customer }];
  const [isMenu, setIsMenu] = useState(false);
  const [tabBar, setTabBar] = useState([
    intl.formatMessage({ id: "Packages" }).toUpperCase(), 
    intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
    intl.formatMessage({ id: "Products" }).toUpperCase(), 
    intl.formatMessage({ id: "CustomerVirtualWarehouse" }).toUpperCase(),
    intl.formatMessage({ id: "Histories" }).toUpperCase(),
    intl.formatMessage({ id: "Sales" }).toUpperCase(), 
    intl.formatMessage({ id: "Notes" }).toUpperCase(), 
    intl.formatMessage({ id: "CustomerTransferHistories" }).toUpperCase(), 
    intl.formatMessage({ id: "SubBranchHistories" }).toUpperCase(), 
    intl.formatMessage({ id: "GrantedAccesses" }).toUpperCase(), 
    intl.formatMessage({ id: "TinInfo" }).toUpperCase(), 
  ]);
  const [activeTab, setActiveTab] = useState("1");
  const [currentPageNote, setCurrentPageNote] = useState(1);
  const [currentPageAppointment, setCurrentPageAppointment] = useState(1);
  const [currentPagePurchasedPackage, setCurrentPagePurchasedPackage] = useState(1);
  const [currentPagePurchasedProduct, setCurrentPagePurchasedProduct] = useState(1);
  const [currentPageReservedProduct, setCurrentPageReservedProduct] = useState(1);
  const [currentPagePurchasedService, setCurrentPagePurchasedService] = useState(1);
  const [currentPageClosePurchasedHistory, setCurrentPageClosePurchasedHistory] = useState(1);
  const [currentPageSalesOrder, setCurrentPageSalesOrder] = useState(1);
  const [currentPageTransferHistory, setCurrentPageTransferHistory] = useState(1);
  const [currentPageSubBranchHistory, setCurrentPageSubBranchHistory] = useState(1);
  const [currentPageAppolousCustomerPackage, setCurrentPageAppolousCustomerPackage] = useState(1);
  const [currentPageAppolousCustomerProduct, setCurrentPageAppolousCustomerProduct] = useState(1);
  const [currentPageAppolousCustomerSales, setCurrentPageAppolousCustomerSales] = useState(1);
  const [currentPageCustomerAccessRequest, setCurrentPageCustomerAccessRequest] = useState(1);
  const [currentPageCustomerTaxEntity, setCurrentPageCustomerTaxEntity] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const maxPageSize = Constants.maxPageSize;
  const appointmentPageSize = 5;
  const [customerNoteModal, setCustomerNoteModal] = useState(false);
  const [blnAction, setBlnAction] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<CustomerNoteListObject | undefined>(undefined);
  const [selectedTaxEntityRow, setSelectedTaxEntityRow] = useState<TaxEntityListObject | undefined>(undefined);
  const today = moment(new Date()).format(Constants.displayDateFormat);
  const [loadingRedeemHistory, setLoadingRedeemHistory] = useState(false);
  const [redeemHistoryModal, setRedeemHistoryModal] = useState(false);
  const [loadingCreditHistory, setLoadingCreditHistory] = useState(false);
  const [creditHistoryModal, setCreditHistoryModal] = useState(false);
  const [loadingTransferCreditHistory, setLoadingTransferCreditHistory] = useState(false);
  const [transferCreditHistoryModal, setTransferCreditHistoryModal] = useState(false);
  const [creditAdjustmentModal, setCreditAdjustmentModal] = useState(false);
  const [exchangeToCreditDetailModal, setExchangeToCreditDetailModal] = useState(false);
  const [selectedExchangeToCreditDetail, setSelectedExchangeToCreditDetail] = useState<any>([]);
  const [transferCreditModal, setTransferCreditModal] = useState(false);
  const [packageRedeemHistoryModal, setPackageRedeemHistoryModal] = useState(false);
  const [serviceRedeemHistoryModal, setServiceRedeemHistoryModal] = useState(false);
  const [subBranchRequestModal, setSubBranchRequestModal] = useState(false);
  const [subBranchRequestAcknowledgementModal, setSubBranchRequestAcknowledgementModal] = useState(false);
  const [subBranchRequestAction, setSubBranchRequestAction] = useState<string>(Constants.add);
  const [subBranchRemoveModal, setSubBranchRemoveModal] = useState(false);
  const [editCustomerMediaSourceModal, setEditCustomerMediaSourceModal] = useState(false);
  const [appolousCustomerPackageExtendExpiryModal, setAppolousCustomerPackageExtendExpiryModal] = useState(false);
  const [appolousCustomerPackageStatusUpdateModal, setAppolousCustomerPackageStatusUpdateModal] = useState(false);
  const [appolousCustomerPackageEditModal, setAppolousCustomerPackageEditModal] = useState(false);
  const [selectedAppolousCustomerPackageDetail, setSelectedAppolousCustomerPackageDetail] = useState<any>(undefined);
  const [appolousCustomerProductRedeemModal, setAppolousCustomerProductRedeemModal] = useState(false);
  const [appolousCustomerProductStatusUpdateModal, setAppolousCustomerProductStatusUpdateModal] = useState(false);
  const [alternativeProductMessageModal, setAlternativeProductMessageModal] = useState(false);
  const [editCustomerJoinedDateModal, setEditCustomerJoinedDateModal] = useState(false);
  const [addAppolousCustomerPackageModal, setAddAppolousCustomerPackageModal] = useState(false);
  const [addAppolousCustomerProductModal, setAddAppolousCustomerProductModal] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [addRelationshipModal, setAddRelationshipModal] = useState(false);
  const [deleteRelationshipModal, setDeleteRelationshipModal] = useState(false);
  const [customerAccessRequestModal, setCustomerAccessRequestModal] = useState(false);
  const [extendCustomerAccessRequestModal, setExtendCustomerAccessRequestModal] = useState(false);
  const [revokeCustomerAccessRequestModal, setRevokeCustomerAccessRequestModal] = useState(false);
  const [showDeleteCustomerTaxEntity, setShowDeleteCustomerTaxEntity] = useState(false);
  const [selectedAppolousCustomerProductDetail, setSelectedAppolousCustomerProductDetail] = useState<any>(undefined);
  const [selectedExtendCustomerForAccess, setSelectedExtendCustomerForAccess] = useState<CustomerAccessRequestListObject | undefined>(undefined);
  const [subBranchRequestId, setSubBranchRequestId] = useState("");
  const [localBranchProductList, setLocalBranchProductList] = useState<ProductForBranchObject[]>([]);
  const [showTiles, setShowTiles] = useState(getBranchUser() ? false : true);
  const [selectedPackageAppolous, setSelectedPackageAppolous] = useState(0);
  const [selectedProductAppolous, setSelectedProductAppolous] = useState(0);
  const [selectedSalesAppolous, setSelectedSalesAppolous] = useState(0);
  const [selectedTagDetails, setSelectedTagDetails] = useState<TagDetailsObject | undefined>(undefined);
  const [selectedRelationshipDetails, setSelectedRelationshipDetails]= useState<RelationshipObject | undefined>(undefined);
  const [showCompletedPurchasedPackage, setShowCompletedPurchasedPackage] = useState(false);
  const [showCompletedPurchasedService, setShowCompletedPurchasedService] = useState(false);
  const [showCompletedPurchasedProduct, setShowCompletedPurchasedProduct] = useState(false);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [loadingPurchasedPackage, setLoadingPurchasedPackage] = useState(false);
  const [loadingPurchasedProduct, setLoadingPurchasedProduct] = useState(false);
  const [loadingReservedProduct, setLoadingReservedProduct] = useState(false);
  const [loadingPurchasedService, setLoadingPurchasedService] = useState(false);
  const [loadingClosePurchasedHistory, setLoadingClosePurchasedHistory] = useState(false);
  const [loadingSalesOrder, setLoadingSalesOrder] = useState(false);
  const [loadingTransferHistory, setLoadingTransferHistory] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingSubBranchHistory, setLoadingSubBranchHistory] = useState(false);
  const [loadingAppolousCustomerPackage, setLoadingAppolousCustomerPackage] = useState(false);
  const [loadingAppolousCustomerProduct, setLoadingAppolousCustomerProduct] = useState(false);
  const [loadingAppolousCustomerSales, setLoadingAppolousCustomerSales] = useState(false);
  const [loadingBranchProductDropdown, setLoadingBranchProductDropdown] = useState(false);
  const [loadingEditCustomerJoinedDate, setLoadingEditCustomerJoinedDate] = useState(false);
  const [loadingAddAppolousCustomerPackage, setLoadingAddAppolousCustomerPackage] = useState(false);
  const [loadingAddAppolousCustomerProduct, setLoadingAddAppolousCustomerProduct] = useState(false);
  const [loadingEditCustomerMediaSource, setLoadingEditCustomerMediaSource] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingProductDropdown, setLoadingProductDropdown] = useState(false);
  const [loadingPackageDropdown, setLoadingPackageDropdown] = useState(false);
  const [loadingCustomerTag, setLoadingCustomerTag] = useState(false);
  const [loadingCustomerRelationship, setLoadingCustomerRelationship] = useState(false);
  const [loadingCustomerAccessRequest, setLoadingCustomerAccessRequest] = useState(false);
  const [loadingCustomerTaxEntity, setLoadingCustomerTaxEntity] = useState(false);
  const [loadingRevokeCustomerAccessRequest, setLoadingRevokeCustomerAccessRequest] = useState(false);
  const [initialCallAPI, setInitialCallAPI] = useState(true);
  const [initialRerouteFromTin, setInitialRerouteFromTin] = useState(true);
  const blnPermissionManageAppointment = checkPermission([PermissionConstants.ManageAppointment]);
  const blnPermissionCreateAppointment = checkPermission([PermissionConstants.CreateAppointment]);
  const blnPermissionManageCustomerNote = checkPermission([PermissionConstants.ManageCustomerNote]);
  const blnPermissionCreateCustomerNote = checkPermission([PermissionConstants.CreateCustomerNote]);
  const blnPermissionUpdateCustomerNote = checkPermission([PermissionConstants.UpdateCustomerNote]);
  const blnPermissionDeleteCustomerNote = checkPermission([PermissionConstants.DeleteCustomerNote]);
  const blnPermissionManageCustomerSalesOrder = checkPermission([PermissionConstants.ManageCustomerSalesOrder]);
  const blnPermissionManageExchangeToCredit = checkPermission([PermissionConstants.ManageExchangeToCredit]);
  const blnPermissionManageCreditAdjustment = checkPermission([PermissionConstants.ManageCreditAdjustment]);
  const blnPermissionManageCustomerProductReturn = checkPermission([PermissionConstants.ManageCustomerProductReturn]);
  const blnPermissionManageCustomerDeliverOrder = checkPermission([PermissionConstants.ManageCustomerDeliverOrder]);
  const blnPermissionManageContractLiability = checkPermission([PermissionConstants.ManageContractLiability]);
  const blnPermissionManageCustomerVirtualWarehouse = checkPermission([PermissionConstants.ManageCustomerVirtualWarehouse]);
  const blnPermissionCreateProductRedemptionNote = checkPermission([PermissionConstants.CreateProductRedemptionNote]);
  const blnPermissionManageVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.ManageVirtualWarehouseCreditExchange]);
  const blnPermissionRequestVirtualWarehouseCreditExchange = checkPermission([PermissionConstants.RequestVirtualWarehouseCreditExchange]);
  const blnPermissionManageProductRedemptionNoteReturn = checkPermission([PermissionConstants.ManageProductRedemptionNoteReturn]);
  const blnPermissionCreateCustomerDeliverOrder = checkPermission([PermissionConstants.CreateCustomerDeliverOrder]);
  const blnPermissionManageAppolousRedemption = checkPermission([PermissionConstants.ManageAppolousRedemption]);
  const blnPermissionExtendAppolousRedemption = checkPermission([PermissionConstants.ExtendAppolousRedemption]);
  const blnPermissionStatusUpdateAppolousRedemption = checkPermission([PermissionConstants.StatusUpdateAppolousRedemption]);
  const blnPermissionUpdateAppolousRedemption = checkPermission([PermissionConstants.UpdateAppolousRedemption]);
  const blnPermissionCreateAppolousRedemption = checkPermission([PermissionConstants.CreateAppolousRedemption]);
  const blnPermissionManageAppolousSales = checkPermission([PermissionConstants.ManageAppolousSales]);
  const blnPermissionCreateAppolousPackage = checkPermission([PermissionConstants.CreateAppolousPackage]);
  const blnPermissionCreateAppolousProduct = checkPermission([PermissionConstants.CreateAppolousProduct]);
  const blnPermissionUpdateCustomerSalesOrder = checkPermission([PermissionConstants.UpdateCustomerSalesOrder]);
  const blnPermissionManageCustomerAccessRequest = checkPermission([PermissionConstants.ManageCustomerAccessRequest]);
  const blnPermissionDeleteCustomerAccessRequest = checkPermission([PermissionConstants.DeleteCustomerAccessRequest]);
  const blnPermissionExtendCustomerAccessRequest = checkPermission([PermissionConstants.ExtendCustomerAccessRequest]);
  const blnPermissionManageTaxEntity = checkPermission([PermissionConstants.ManageTaxEntity]);
  const blnPermissionCreateTaxEntity = checkPermission([PermissionConstants.CreateTaxEntity]);
  const blnPermissionUpdateTaxEntity = checkPermission([PermissionConstants.UpdateTaxEntity]);
  const blnPermissionDeleteTaxEntity = checkPermission([PermissionConstants.DeleteTaxEntity]);
  const blnPermissionManageCreditRedemptionNote = checkPermission([PermissionConstants.ManageCreditRedemptionNote]);
  const blnPermissionManageCreditNote = checkPermission([PermissionConstants.ManageCreditNote]);
  const blnShowAppolous = generalSettings?.isShowAppolous;
  const showCompletedButtonWidth = "150px";
  const refTinTable : any = useRef(null);

  const aryCustomerPackageSnipButton : GeneralSnipButtonObject[]= [
    {
      buttonName: intl.formatMessage({ id: "ERP" }),
      onClick: ()=> {
        setSelectedPackageAppolous(0);
      }
    },
    {
      buttonName: intl.formatMessage({ id: "Appolous" }),
      onClick: ()=> {
        setSelectedPackageAppolous(1);
        if (appolousCustomerPackageList.length < 1 && !loadingAppolousCustomerPackage) {
          fetchAppolousCustomerPackageAPI();
        }
      },
      color: "btn-info",
      outlineColor: "btn-outline-info-color"
    }
  ]

  const aryCustomerProductSnipButton : GeneralSnipButtonObject[]= [
    {
      buttonName: intl.formatMessage({ id: "ERP" }),
      onClick: ()=> {
        setSelectedProductAppolous(0);
      }
    },
    {
      buttonName: intl.formatMessage({ id: "Appolous" }),
      onClick: ()=> {
        setSelectedProductAppolous(1);
        if (appolousCustomerProductList.length < 1 && !loadingAppolousCustomerProduct) {
          fetchAppolousCustomerProductAPI();
        }
      },
      color: "btn-info",
      outlineColor: "btn-outline-info-color"
    }
  ]

  const aryCustomerSalesSnipButton : GeneralSnipButtonObject[]= [
    {
      buttonName: intl.formatMessage({ id: "ERP" }),
      onClick: ()=> {
        setSelectedSalesAppolous(0);
      }
    },
    {
      buttonName: intl.formatMessage({ id: "Appolous" }),
      onClick: ()=> {
        setSelectedSalesAppolous(1);
        if (appolousCustomerSalesList.length < 1 && !loadingAppolousCustomerSales) {
          fetchAppolousCustomerSalesAPI();
        }
      },
      color: "btn-info",
      outlineColor: "btn-outline-info-color"
    }
  ]

  const customerProfileFullDetail = customerDetail || {
    id: "",
    preferredName: "",
    branchId: "",
    branchName: "",
    customerNo: "",
    mobileNo: "",
    identityNo: "",
    totalInvoiceSales: 0,
    totalEarnedSales: 0,
    totalSalesOrderSales: 0,
    totalPaid: 0,
    totalUnpaid: 0,
    totalUnearnRevenue: 0,
    totalAvailableCredits: 0,
    totalLockedCredits: 0,
    availableCredits: 0,
    lockedCredits: 0,
    transferCredits: 0,
    subBranchId: "",
    subBranchName: "",
    subBranchConsultantId: "",
    subBranchConsultantName: "",
    referredById: "",
    referredByName: "",
    joinedDate: "",
    purchasePackages: [{
      packageName: "",
      status: "",
      customerSalesOrderId: "",
      customerSalesOrderNo: "",
      customerSalesOrderBalance: 0,
      quantity: 0,
      packageSessions: 0,
      availableSessions: 0,
      expiredDate: "",
      totalPrice: 0,
      isUpgradePackage: false,
      productQuantity: 0,
      availableProductQuantity: 0,
      optionalProductCost: 0,
      availableOptionalProductCost: 0,
      redeemedSessions: 0,
      redeemedProductQuantity: 0,
    }],
    salesOrders: [{
      customerSalesOrderNo: "",
      createdAt: "",
      branchId: "",
      branchName: "",
      totalAmount: 0.00,
      balance: 0.00,
      paymentStatus: "",
      salesOrderStatus: "",
      submissionDate: "",
    }],
    purchaseProducts: [{
      productName: "",
      status: "",
      customerSalesOrderId: "",
      customerSalesOrderNo: "",
      customerSalesOrderBalance: 0,
      purchaseDate: "",
      quantity: 0,
      redeemedQuantity: 0,
      unitPrice: 0,
      totalPrice: 0,
    }],
    purchaseServices: [{
      id: "",
      serviceId: "",
      serviceName: "",
      status: "",
      customerSalesOrderId: "",
      customerSalesOrderNo: "",
      customerSalesOrderBalance: 0,
      purchaseDate: "",
      quantity: 0,
      redeemedQuantity: 0,
      unitPrice: 0,
      totalPrice: 0,
      paymentStatus: "",
      expiredDate: ""
    }],
    closedPurchaseHistories: [{
      id: "",
      itemId: "",
      itemName: "",
      type: "",
      status: "",
      paymentStatus: "",
      quantity: 0,
      customerSalesOrderId: "",
      customerSalesOrderNo: "",
    }],
    additionalFormData: [{
      name: "",
      controlType: "",
      rows: 0,
      options: [],
      placeHolder: "",
      isRequired: false,
      isActive: false,
      displayOrder: 0,
      value: "",
    }],
    transferHistories: [{
      branchName: "",
      endDate: "",
      startDate: "",
    }],
    virtualWarehouses: [{
      id: "",
      virtualWarehouseNo: "",
      businessEntityName: "",
      branchName: "",
      deliverOrderId: "",
      deliverOrderNo: "",
      productName: "",
      totalQuantity: 0,
      reservedQuantity: 0,
      redeemedQuantity: 0,
      creditExchangedQuantity: 0,
      returnedQuantity: 0,
      unitPrice: 0,
      status: "",
      isAllowPerformAction: false,
    }],
    appolousId: null,
    tags: [],
    relationships: []
  }

  const selectedCustomerForAccess : CustomerValidationResponseObject = {
    isExist: false,
    branchId: "",
    branchName: "",
    customerId: id,
    customerName: customerProfileFullDetail.preferredName,
    joinedDate: ""
  };

  const blnOrginalBranchMatchUserBranch = customerProfileFullDetail.branchId === getBranchId();
  const blnCustomerProfileFullAccess = customerProfileFullDetail.branchId === getBranchId() || customerProfileFullDetail.subBranchId === getBranchId();

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      headerStyle: {minWidth: '120px'},
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.appointStartDateTime).format(Constants.displayDateFormat)}
          </div>
          <div className="">
            {moment(row.appointStartDateTime).format(Constants.displaySmallDay)}, {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          {row.serviceTreatmentNames || <b>{intl.formatMessage({id: "FollowUpOnly"})}</b>}
        </>,
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      style: {
        width: '15%'
      },
      formatter: (cellContent, row) => <>{row.remark || Constants.emptyData}</>
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => <> {row.consultantName || Constants.emptyData}</>
    },
    {
      dataField: "isFollowUpOnly",
      text: intl.formatMessage({ id: "FollowUp" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isFollowUpOnly ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ApptContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="" >
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) =>
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor} />
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageAppointment &&
              <>
                {
                  (!getBranchUser() || (getBranchUser() && customerProfileFullDetail.branchId === getBranchId()))
                  &&
                  <TableActionButton 
                    to={(`/${RoutesList.appointment}?appointmentId=${row.id}&branchId=${row.branchId}&branchName=${row.branchName}&startDate=${moment(row.appointStartDateTime).format(Constants.displayDateFormat).toString()}&endDate=${moment(row.appointEndDateTime).add(1, 'days').format(Constants.displayDateFormat).toString()}`)}
                    type="calendar"/>
                }
                <TableActionButton
                  to={`/${RoutesList.appointment}/view/${row.id}`}
                  type="view" />
              </>
            }
          </div>
        </>
      ),
    },
  ];

  const CustomerNoteColumns = [
    {
      dataField: "note",
      text: intl.formatMessage({ id: "Note" }).toUpperCase(),
      headerAttrs: { hidden: customerNoteList.length > 0 ? true : false },
      formatter: (cellContent, row, indexNote) => (
        <div className={`${indexNote > 0 ? "margin-top-16" : ""} margin-bottom-16`}>
          <div className="flex-align-items-center-without-height">
            <img
              className="rounded-circle header-profile-user margin-right-8"
              src={user9}
              alt="Header Avatar"
            />
            <span style={{borderBottom: '1px solid', borderColor: Constants.lightpurple, fontWeight: 600}}>
              {`${row.commentedUserName} `}
            </span> 
            {intl.formatMessage({ id: "On" }).toLowerCase()} {moment(row.commentedOn).format(Constants.displayDateForNote)} {intl.formatMessage({ id: "At" }).toLowerCase()} {moment(row.commentedOn).format(Constants.displayTimeFormat)}
            <span style={{fontSize: '11px', marginLeft: '8px', color: row.isFollowUp ? Constants.green : Constants.grey}}>
              {`(${intl.formatMessage({ id: "FollowUp" })} : ${row.isFollowUp ? Constants.nikeSymbol : Constants.xSymbol})`}
            </span>
            <div className="margin-left-8"/>
            {
              blnPermissionManageCustomerNote
              &&
              <TableActionButton
                type="view"
                onClick={() => onClickNoteAction(Constants.view, row)} />
            }
            {
              blnPermissionUpdateCustomerNote
              &&
              checkIsToday(row.commentedOn)
              &&
              <TableActionButton
                type="edit"
                onClick={() => onClickNoteAction(Constants.update, row)} />
            }
            {
              blnPermissionDeleteCustomerNote
              &&
              <TableActionButton
                type="delete"
                onClick={() => onClickDelete(row)} />
            }
          </div>
          <div className="flex-direction-row ">
            <div style={{padding: '16px', backgroundColor: '#f1f1f3', marginTop: '8px', borderRadius: '8px', flex: 1}}>
              {`${row.note} `}
            </div>
            </div>
        </div>
      )
    }
  ];

  const RedeemHistoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "redeemName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      sort: true,
    },
    {
      dataField: "RedeemedDate",
      text: intl.formatMessage({ id: "RedeemedDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {moment(row.redeemDateTime).format(Constants.displayDateAndTimeFormat)}
        </div>,
    },
    {
      dataField: "customerDeliverOrderNo",
      text: intl.formatMessage({ id: "DeliverOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomerDeliverOrder
          ?
          <Link to={row.customerDeliverOrderId ? `/${RoutesList.customerRedemption}/view/${row.customerDeliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 pointer-clickable">{row.customerDeliverOrderNo || Constants.emptyData}</Label>
          </Link>
          :
          <div>
            {row.customerDeliverOrderNo}
          </div>
      ),
    },
    {
      dataField: "productBatchReferenceNo",
      text: intl.formatMessage({ id: "ProductBatchReferenceNo" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {row.productBatchReferenceNo ? row.productBatchReferenceNo : Constants.emptyData}
        </div>,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
    },
    {
      dataField: "totalPrice",
      text: intl.formatMessage({ id: "WorthValue" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {returnPriceWithCurrency(row.totalPrice)}
          </div>
        </>,
    },
  ];

  const TransferCreditHistoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "CreditDetail" }).toUpperCase(),
      sort: true,
      style: {
        width: '30%'
      },
    },
    {
      dataField: "entityType",
      text: intl.formatMessage({ id: "EntityType" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.entityType)}</div>,
    },

    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.createdAt).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "credits",
      text: intl.formatMessage({ id: "Credits" }).toUpperCase(),
      headerStyle: { textAlign: "center" },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={returnThousandSeperator(row.credits)}
          statusBackgroundColor={Math.sign(row.credits) == -1 ? Constants.red : Constants.lightgreen} />
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      style: {
        width: '5%'
      },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.entityId ?
              blnPermissionManageCustomerSalesOrder
                &&
                <TableActionButton
                  to={(`/${RoutesList.customerSalesOrder}/view/${row.entityId}`)}
                  type="view"
                />
                :
                Constants.emptyData
            }
          </div>
        </>
      ),
    },
  ];

  const PackageRedeemptionHistoryColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "redemptionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.redemptionDate).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.redemptionDate).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "redeemDetailName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.redeemDetailName)}</div>,
    },
    {
      dataField: "redeemType",
      text: intl.formatMessage({ id: "Type" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.redeemType)}</div>,
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{newSpaceBeforeCapitalLetter(row.count)}</div>,
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      style: {
        width: '5%'
      },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageCustomerDeliverOrder
              &&
              <TableActionButton
                to={(`/${RoutesList.customerRedemption}/view/${row.customerDeliverOrderId}`)}
                type="view"
              />
            }
          </div>
        </>
      ),
    },
  ];

  const PurchasedPackagesColumns = [
    {
      dataField: "packageName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.package)}{row.packageName} {displayPromotionCode(row.promotionCode)}
        </>
      )
    },
    {
      dataField: "purchasedDate",
      text: intl.formatMessage({ id: "PurchasedDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.purchasedDate).format(Constants.displayDateFormat)}
          </div>
        </>
    },
    {
      dataField: "redeemedSessions",
      text: intl.formatMessage({ id: "Sessions" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            row.packageSessions !== 0
              ?
              `${row.redeemedSessions}/${row.packageSessions}`
              :
              Constants.emptyData
          }
        </>
      )
    },
    {
      dataField: "availableSessions",
      text: intl.formatMessage({ id: "AvailableSessions" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            row.packageSessions !== 0
              ?
              `${row.availableSessions}`
              :
              Constants.emptyData
          }
        </>
      )
    },
    {
      dataField: "expiredDate",
      text: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.expiredDate).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
            ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerSalesOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={purchasedItemStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              (row.availableSessions > 0 || row.availableProductQuantity > 0 || row.availableOptionalProductCost > 0)
              &&
              blnPermissionCreateCustomerDeliverOrder
              &&
              <TableActionButton
                onClick={() => {
                  if (Constants.supportSingleRedeem) {
                    history.push({
                      pathname: `/${RoutesList.customer}/${RoutesList.customerProfilePurchase}`,
                      state: {
                        detail: {
                          itemType: Constants.package,
                          customerSalesOrderId: row.customerSalesOrderId,
                          customerSalesOrderDetailId: row.id,
                          customerId: customerProfileFullDetail.id,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerName: customerProfileFullDetail.preferredName,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    });
                  }
                  else {
                    history.push ({
                      pathname: `/${RoutesList.customerPurchase}`,
                      state: {
                        detail: {
                          customerSalesOrderNo: row.customerSalesOrderNo,
                          customerId: customerProfileFullDetail.id,
                          customerName: `${customerProfileFullDetail.preferredName} (${customerProfileFullDetail.customerNo})`,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    })
                  }
                }}
                type="redeem" />
            }
            {
              blnPermissionManageCustomerSalesOrder &&
              <TableActionButton
                onClick={() => onClickViewPackageRedeemHistory(row.customerSalesOrderDetailId)}
                type="view" />
            }
          </div>
        </>
      ),
    },
  ];

  const AppolousCustomerPackageColumns = [
    {
      dataField: "packageName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.package)}{row.packageName}
        </>
      )
    },
    {
      dataField: "redeemedSessions",
      text: intl.formatMessage({ id: "Sessions" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            row.availableSessions !== null
            ?
            `${row.redeemedSessions}/${row.totalSessions}`
            :
            Constants.emptyData
          }
        </>
      )
    },
    {
      dataField: "expiredDate",
      text: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.expiredDate).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={appolousCustomerPackageStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row, indexAction) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.availableActions.map((valueAvailableAction)=> {
                let buttonType : any = String(valueAvailableAction).toLowerCase()
                let blnRedeem = valueAvailableAction === Constants.redeem;
                let blnView = valueAvailableAction === Constants.view;
                let blnExtendExpiry = valueAvailableAction === Constants.extendExpiry;
                let blnClose = valueAvailableAction === Constants.close;
                let blnReactivate = valueAvailableAction === Constants.reactivate;
                let blnEdit = valueAvailableAction === Constants.edit;

                if ((blnView && !blnPermissionManageAppolousRedemption) || 
                  (blnExtendExpiry && !blnPermissionExtendAppolousRedemption) || 
                  ((blnClose || blnReactivate) && !blnPermissionStatusUpdateAppolousRedemption) || 
                  (blnEdit && !blnPermissionUpdateAppolousRedemption) || 
                  (blnRedeem && !blnPermissionCreateAppolousRedemption)) 
                {
                  return null
                }

                return (
                  <div key={`value_${valueAvailableAction}_${indexAction}`}>
                    <TableActionButton
                      onClick={() => {
                        if (blnView) {
                          history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerPackage}/view/${row.appolousCustomerPackageId}`)
                        }
                        else if (blnExtendExpiry) {
                          setSelectedAppolousCustomerPackageDetail({
                            appolousCustomerPackageId: row.appolousCustomerPackageId,
                            appolousCustomerPackageName: row.packageName,
                            expiredDate: moment().add(1, 'days').format(Constants.displayDateFormat)
                          })
                          setAppolousCustomerPackageExtendExpiryModal(true);
                        }
                        else if (blnClose || blnReactivate) {
                          setSelectedAppolousCustomerPackageDetail({
                            appolousCustomerPackageId: row.appolousCustomerPackageId,
                            appolousCustomerPackageName: row.packageName,
                            action: valueAvailableAction 
                          })
                          setAppolousCustomerPackageStatusUpdateModal(true);
                        }
                        else if (blnEdit) {
                          setSelectedAppolousCustomerPackageDetail({
                            appolousCustomerPackageId: row.appolousCustomerPackageId,
                            packageName: row.packageName,
                            totalSessions: row.totalSessions,
                            redeemedSessions: row.redeemedSessions
                          })
                          setAppolousCustomerPackageEditModal(true);
                        }
                        else if (blnRedeem) {
                          history.push({
                            pathname: `/${RoutesList.customer}/${RoutesList.appolousCustomerPackageRedemption}/redeem`,
                            state: {
                              detail: {
                                appolousCustomerPackageId: row.appolousCustomerPackageId
                              }
                            }
                          });
                        }
                      }}
                      type={buttonType}
                      {...(blnExtendExpiry || blnReactivate) && {iconReplaceUI: blnExtendExpiry ? <CalendarArrowRightIcon className="text-success" size={18} id="extendExpiryAppolousCustomerPackageTooltip" /> : <FileRefreshOutlineIcon className="text-success" size={18} id="reactiveAppolousCustomerPackageTooltip"/>}}
                      {...(blnExtendExpiry || blnReactivate) && {iconId: blnExtendExpiry ? "extendExpiryAppolousCustomerPackageTooltip": "reactiveAppolousCustomerPackageTooltip"}}
                      {...(blnExtendExpiry || blnReactivate) && {iconToolTipText: blnExtendExpiry ? `${intl.formatMessage({ id: "ExtendExpiry" })}` : `${intl.formatMessage({ id: "Reactivate" })}`}}
                      />
                  </div>
                )
              })
            }
          </div>
        </>
      ),
    }
  ];

  const PurchasedProductsColumns = [
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.product)}{row.productName} {displayPromotionCode(row.promotionCode)}
        </>
      )
    },
    {
      dataField: "purchaseDate",
      text: intl.formatMessage({ id: "PurchasedDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.purchaseDate).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
    },
    {
      dataField: "redeemedQuantity",
      text: `${intl.formatMessage({ id: "Redm" }).toUpperCase()}.`,
    },
    {
      dataField: "balanceQuantity",
      text: `${intl.formatMessage({ id: "Bal" }).toUpperCase()}.`,
      formatter: (cellContent, row) => (
        <>
          {row.quantity - row.redeemedQuantity}
        </>
      )
    },
    {
      dataField: "unitPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.unitPrice)}
        </>
      )
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
            ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerSalesOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              (((row.quantity - row.redeemedQuantity) > 0) && (row.paymentStatus === Constants.paid || (row.totalPrice === 0 && row.paymentStatus === Constants.partial)))      
              &&
              blnPermissionCreateCustomerDeliverOrder
              &&
              <TableActionButton
                onClick={() => {
                  if (Constants.supportSingleRedeem) {
                    history.push({
                      pathname: `/${RoutesList.customer}/${RoutesList.customerProfilePurchase}`,
                      state: {
                        detail: {
                          itemType: Constants.productOnly,
                          customerSalesOrderId: row.customerSalesOrderId,
                          customerSalesOrderDetailId: row.id,
                          customerId: customerProfileFullDetail.id,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerName: customerProfileFullDetail.preferredName,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    });
                  }
                  else {
                    history.push ({
                      pathname: `/${RoutesList.customerPurchase}`,
                      state: {
                        detail: {
                          customerSalesOrderNo: row.customerSalesOrderNo,
                          customerId: customerProfileFullDetail.id,
                          customerName: `${customerProfileFullDetail.preferredName} (${customerProfileFullDetail.customerNo})`,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    })
                  }
                }}
                type="redeem" />
            }
            {
              ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
              ?
              <TableActionButton
                to={`/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}`}
                type="view" />
              :
              Constants.emptyDataV2
            }
          </div>
        </>
      ),
    },
  ];

  const AppolousCustomerProductColumns = [
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.product)}{row.productName}
        </>
      )
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
    },
    {
      dataField: "redeemedQuantity",
      text: intl.formatMessage({ id: "Redm" }).toUpperCase(),
    },
    {
      dataField: "balanceQuantity",
      text: intl.formatMessage({ id: "Bal" }).toUpperCase(),
    },
    {
      dataField: "price",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.price)}
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row, indexAction) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.availableActions.map((valueAvailableAction) => {
                let buttonType : any = String(valueAvailableAction).toLowerCase()
                let blnRedeem = valueAvailableAction === Constants.redeem;
                let blnView = valueAvailableAction === Constants.view;
                let blnClose = valueAvailableAction === Constants.close;
                let blnReactivate = valueAvailableAction === Constants.reactivate;

                if ((blnView && !blnPermissionManageAppolousRedemption) ||
                  (blnRedeem && !blnPermissionCreateAppolousRedemption) || 
                  ((blnClose || blnReactivate) && !blnPermissionStatusUpdateAppolousRedemption)) {
                  return null
                }

                return (
                  <div key={`value_${valueAvailableAction}_${indexAction}`}>
                    <TableActionButton
                      onClick={() => {
                        if (blnView) {
                          history.push(`/${RoutesList.customer}/${RoutesList.appolousCustomerProduct}/view/${row.id}`)
                        }
                        else if (blnRedeem) {
                          setSelectedAppolousCustomerProductDetail(row);
                          setAppolousCustomerProductRedeemModal(true);
                        }
                        else if (blnClose || blnReactivate) {
                          setSelectedAppolousCustomerProductDetail({
                            id: row.id,
                            appolousCustomerProductName: row.productName,
                            action: valueAvailableAction,
                            isManual: row.isManual
                          })
                          setAppolousCustomerProductStatusUpdateModal(true);
                        }
                      }}
                      type={buttonType}
                      {...(blnReactivate) && {iconReplaceUI: <FileRefreshOutlineIcon className="text-success" size={18} id="reactiveAppolousCustomerProductTooltip"/>}}
                      {...(blnReactivate) && {iconId: "reactiveAppolousCustomerProductTooltip"}}
                      {...(blnReactivate) && {iconToolTipText: `${intl.formatMessage({ id: "Reactivate" })}`}}
                      />
                  </div>
                )
              })
            }
          </div>
        </>
      ),
    }
  ];

  const PurchasedServicesColumns = [
    {
      dataField: "serviceName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.service)}{row.serviceName} {displayPromotionCode(row.promotionCode)}
        </>
      )
    },
    {
      dataField: "purchaseDate",
      text: intl.formatMessage({ id: "PurchasedDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.purchaseDate).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
    },
    {
      dataField: "redeemedQuantity",
      text: `${intl.formatMessage({ id: "Redm" }).toUpperCase()}.`,
    },
    {
      dataField: "balanceQuantity",
      text: `${intl.formatMessage({ id: "Bal" }).toUpperCase()}.`,
      formatter: (cellContent, row) => (
        <>
          {row.quantity - row.redeemedQuantity}
        </>
      )
    },
    {
      dataField: "unitPrice",
      text: intl.formatMessage({ id: "Price" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.unitPrice)}
        </>
      )
    },

    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
            ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerSalesOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={purchasedItemStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.quantity - row.redeemedQuantity > 0
              &&
              blnPermissionCreateCustomerDeliverOrder
              &&
              <TableActionButton
                onClick={() => {
                  if (Constants.supportSingleRedeem) {
                    history.push({
                      pathname: `/${RoutesList.customer}/${RoutesList.customerProfilePurchase}`,
                      state: {
                        detail: {
                          itemType: Constants.serviceTreatmentOnly,
                          customerSalesOrderId: row.customerSalesOrderId,
                          customerSalesOrderDetailId: row.id,
                          customerId: customerProfileFullDetail.id,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerName: customerProfileFullDetail.preferredName,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    });
                  }
                  else {
                    history.push ({
                      pathname: `/${RoutesList.customerPurchase}`,
                      state: {
                        detail: {
                          customerSalesOrderNo: row.customerSalesOrderNo,
                          customerId: customerProfileFullDetail.id,
                          customerName: `${customerProfileFullDetail.preferredName} (${customerProfileFullDetail.customerNo})`,
                          customerNo: customerProfileFullDetail.customerNo,
                          customerMobileNo: customerProfileFullDetail.mobileNo
                        }
                      }
                    })
                  }
                }}
                type="redeem" />
            }
            {
              <TableActionButton
                onClick={() => onClickViewServiceTreatmentRedeemHistory(row.id)}
                type="view" />
            }
          </div>
        </>
      ),
    },
  ];

  const ServiceRedeemptionHistoryColumns = [
    {
      dataField: "randomId", //field name from array to display
      text: "randomId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "redemptionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.redemptionDate).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.redemptionDate).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "count",
      text: intl.formatMessage({ id: "Quantity" }).toUpperCase(),
    },
    {
      dataField: "serviceByName",
      text: intl.formatMessage({ id: "ServiceBy" }).toUpperCase(),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.remark || Constants.emptyData}</div>,
    },
    {
      dataField: "",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      style: {
        width: '5%'
      },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageCustomerDeliverOrder
              &&
              <TableActionButton
                to={(`/${RoutesList.customerRedemption}/view/${row.customerDeliverOrderId}`)}
                type="view"
              />
            }
          </div>
        </>
      ),
    },
  ];

  const InactiveClosedPurchasedHistoriesColumns = [
    {
      dataField: "serviceName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(row.type)}{row.itemName}
        </>
      )
    },

    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "Qty" }).toUpperCase(),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={purchasedItemStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
            ?
            <Link to={row.customerSalesOrderId ? `/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerSalesOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              ((blnPermissionManageCustomerSalesOrder && row.salesOrderType === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.salesOrderType === Constants.creditRedemptionNote))
              ?
              <TableActionButton
                to={`/${row.salesOrderType === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.customerSalesOrderId}`}
                type="view" />
              :
              Constants.emptyDataV2
            }
          </div>
        </>
      ),
    },
  ];

  const SalesOrderColumns = [
    {
      dataField: "customerSalesOrderNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            ((blnPermissionManageCustomerSalesOrder && row.type === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.type === Constants.creditRedemptionNote))
            ?
            <Link to={`/${row.type === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.id}`} target={Constants.blank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerSalesOrderNo || Constants.emptyData}</Label>
            </Link>
            :
            <div>
              {row.customerSalesOrderNo}
            </div>
          }
        </>
      )
    },
    {
      dataField: "submissionDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.submissionDate).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.submissionDate).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "totalAmount",
      text: intl.formatMessage({ id: "TotalInvoice" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalAmount)}
        </>
      )
    },
    {
      dataField: "totalPayment",
      text: intl.formatMessage({ id: "TotalPayment" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.totalPayment)}
        </>
      )
    },
    {
      dataField: "balance",
      text: intl.formatMessage({ id: "Balance" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {returnPriceWithCurrency(row.balance)}
        </>
      )
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        headerStyle: { textAlign: 'center' },
        formatter: (cellContent, row) => (
          <div className="text-center">
            {newSpaceBeforeCapitalLetter(row.subBrandName)}
          </div>
        )
      }
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "PaymentStatus" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.paymentStatus)}
            statusBackgroundColor={paymentStatusColor(row.paymentStatus)} />
        </>
      ),
    },
    {
      dataField: "salesOrderStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.salesOrderStatus)}
            statusBackgroundColor={salesOrderStatusColor(row.salesOrderStatus)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.actionButtonWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              ((blnPermissionManageCustomerSalesOrder && row.type === Constants.invoice) || (blnPermissionManageCreditRedemptionNote && row.type === Constants.creditRedemptionNote))
              ?
              <TableActionButton
                to={`/${row.type === Constants.invoice ? RoutesList.customerSalesOrder : RoutesList.creditRedemptionNote}/view/${row.id}`}
                type="view" />
              :
              Constants.emptyDataV2
            }
          </div>
        </>
      ),
    },
  ];

  const AppolousCustomerSalesColumns = [
    {
      dataField: "performaInvoiceNo",
      text: intl.formatMessage({ id: "Invoice" }).toUpperCase(),
    },
    {
      dataField: "createdAt",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.createdAt).format(Constants.displayDateFormat)}
          </div>
        </>,
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "total",
      text: intl.formatMessage({ id: "Total" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div>
            {returnPriceWithCurrency(row.total)}
          </div>
          {
            row.balance > 0
            &&
            <div className="small-text">
              {returnPriceWithCurrency(row.balance)}
            </div>
          }
        </>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={removeAllSpace(row.status) === Constants.partialPayment ? Constants.partial : row.status}
            statusBackgroundColor={appolousSalesOrderStatusColor(removeAllSpace(row.status))} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageAppolousSales &&
              <TableActionButton
                to={(`/${RoutesList.customer}/${RoutesList.appolousCustomerSales}/view/${row.appolousSaleId}`)}
                type="view" />
            }
          </div>
        </>
      ),
    }
  ];

  const VirtualWarehouseColumns = [
    {
      dataField: "virtualWarehouseNo",
      text: `${intl.formatMessage({ id: "VirtualWarehouseNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerVirtualWarehouse
              ?
              <Link to={row.id ? `/${RoutesList.customerVirtualWarehouse}/view/${row.id}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.virtualWarehouseNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>
                {row.virtualWarehouseNo}
              </div>
          }
        </>
      )
    },
    {
      dataField: "productName",
      text: intl.formatMessage({ id: "Product" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {getPurchaseTypeIcon(Constants.product)}{row.productName}
        </>
      )
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "deliverOrderNo",
      text: `${intl.formatMessage({ id: "RedemptionNo" }).toUpperCase()}.`,
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionManageCustomerDeliverOrder
              ?
              <Link to={row.deliverOrderId ? `/${RoutesList.customerRedemption}/view/${row.deliverOrderId}` : ""} target={Constants.blank} rel="noopener noreferrer">
                <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.deliverOrderNo || Constants.emptyData}</Label>
              </Link>
              :
              <div>
                {row.deliverOrderNo}
              </div>
          }
        </>
      )
    },
    {
      dataField: "totalQuantity",
      text: intl.formatMessage({ id: "TotalQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.totalQuantity}
        </div>
      ),
    },
    // {
    //   dataField: "reservedQuantity",
    //   text: intl.formatMessage({ id: "ReservedQty" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.reservedQuantity}
    //     </div>
    //   ),
    // },
    {
      dataField: "redeemedQuantity",
      text: intl.formatMessage({ id: "RedeemedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.redeemedQuantity}
        </div>
      ),
    },
    // {
    //   dataField: "creditExchangedQuantity",
    //   text: intl.formatMessage({ id: "CreditExchangedQty" }).toUpperCase(),
    //   headerStyle: { textAlign: 'center' },
    //   formatter: (cellContent, row) => (
    //     <div className="flex-justfity-content-center">
    //       {row.creditExchangedQuantity}
    //     </div>
    //   ),
    // },
    {
      dataField: "availableQuantity",
      text: intl.formatMessage({ id: "BalQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.availableQuantity}
        </div>
      ),
    },
    {
      dataField: "returnedQuantity",
      text: intl.formatMessage({ id: "ReturnedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.returnedQuantity}
        </div>
      ),
    },
    {
      dataField: "reversedQuantity",
      text: intl.formatMessage({ id: "ReversedQty" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.reversedQuantity}
        </div>
      ),
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <>
          <GeneralStatus
            status={(row.status)}
            statusBackgroundColor={virtualWarehouseStatusColor(row.status)} />
        </>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.isAllowPerformAction
              &&
              (row.status === Constants.active || row.status === Constants.expired)
              &&
              blnPermissionCreateProductRedemptionNote
              &&
              <TableActionButton
                onClick={() => {
                  history.push({
                    pathname: `/${RoutesList.productRedemptionNote}/addFromCustomer`,
                    state: {
                      detail: {
                        customerId: customerProfileFullDetail.id,
                        customerName: `${customerProfileFullDetail.preferredName} (${customerProfileFullDetail.identityNo})`,
                        redeemDetailsDisplay: [{...JSON.parse(JSON.stringify(row)), isActive: true, quantity: 0, availableQuantity: row.availableQuantity, virtualWarehouseId: row.id}]
                      }
                    }
                  });
                }}
                type="redeem" />
            }
            {
              row.isAllowPerformAction
              &&
              (row.status === Constants.active || row.status === Constants.expired)
              &&
              row.unitPrice > 0
              &&
              blnPermissionRequestVirtualWarehouseCreditExchange
              &&
              <TableActionButton
                onClick={() => {
                  history.push({
                    pathname: `/${RoutesList.virtualWarehouseCreditExchange}/addFromCustomer`,
                    state: {
                      detail: {
                        customerId: customerProfileFullDetail.id,
                        customerName: `${customerProfileFullDetail.preferredName} (${customerProfileFullDetail.identityNo})`,
                        creditExchangeDetailsDisplay: [{...JSON.parse(JSON.stringify(row)), isActive: true, quantity: 0, availableQuantity: row.availableQuantity, virtualWarehouseId: row.id}]
                      }
                    }
                  });
                }}
                type="exchangetocredit" />
            }
            {
              blnPermissionManageCustomerVirtualWarehouse &&
              <TableActionButton
                to={(`/${RoutesList.customerVirtualWarehouse}/view/${row.id}`)}
                type="view" />
            }
          </div>
        </>
      ),
    },
  ];

  const CustomerSubBranchHistoryColumn = [
    {
      dataField: "randomId", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.randomId}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
      <>
        <div>
          {moment(row.startDate).format(Constants.displayDateFormat)}
        </div>
        <div className="small-text">
          {moment(row.startDate).format(Constants.displayTimeFormat)}
        </div>
      </>
    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "EndDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
      <>
        {
          row.endDate !== Constants.infiniteDateTimeWithValue ?
            <>
              {moment(row.endDate).format(Constants.displayDateFormat)}
              <div className="small-text">
                {moment(row.endDate).format(Constants.displayTimeFormat)}
              </div>
            </>
            :
            <strong>{intl.formatMessage({ id: "Present" })}</strong>
        }
      </>,
    }
  ];

  const CustomerTransferHistoryColumns = [
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
      style: {
        width: '30%',
      },
    },
    {
      dataField: "startDate",
      text: intl.formatMessage({ id: "StartDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {row.startDate !== Constants.infiniteDateTime ? moment(row.startDate).format(Constants.displayDateFormat) : Constants.emptyData}
          </div>
          <div className="small-text">
            {row.startDate !== Constants.infiniteDateTime ? moment(row.startDate).format(Constants.displayTimeFormat) : ""}
          </div>
        </>,
    },
    {
      dataField: "endDate",
      text: intl.formatMessage({ id: "ToDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {
            row.endDate !== Constants.infiniteDateTime 
            ?
            <>
              {moment(row.endDate).format(Constants.displayDateFormat)}
              <div className="small-text">
                {moment(row.endDate).format(Constants.displayTimeFormat)}
              </div>
            </>
            :
            <strong>{intl.formatMessage({ id: "Present" })}</strong>
          }
        </div>,
    }
  ];

  const CustomerAccessRequestColumn = [
    {
      dataField: "requestBranchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "from",
      text: intl.formatMessage({ id: "From" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div>
            {moment(row.from).format(Constants.displayDateFormat)}
          </div>
          <div className="small-text">
            {moment(row.from).format(Constants.displayTimeFormat)}
          </div>
        </>,
    },
    {
      dataField: "to",
      text: intl.formatMessage({ id: "ValidTill" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <div>
          {moment(row.to).format(Constants.displayDateFormat)}
          <div className="small-text">
            {moment(row.to).format(Constants.displayTimeFormat)}
          </div>
        </div>,
    },
    {
      dataField: "approveBy",
      text: intl.formatMessage({ id: "ActionBy" }).toUpperCase(),
      formatter: (cellContent, row) => <div>{row.approveBy || Constants.emptyData}</div>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              row.customerId == id
              &&
              blnPermissionExtendCustomerAccessRequest
              &&
              <TableActionButton
                type="edit"
                onClick={()=> {
                  setSelectedExtendCustomerForAccess(row);
                  setExtendCustomerAccessRequestModal(true);
                }}
                iconReplaceUI={<CalendarArrowRightIcon size={18} id="extendAccessRequestTooltip" />}
                iconId="extendAccessRequestTooltip"
                iconToolTipText={`${intl.formatMessage({ id: "Extend" })}`} />
            }
            {
              row.customerId == id
              &&
              blnPermissionDeleteCustomerAccessRequest
              &&
              <TableActionButton
                type="cancel"
                onClick={() => {
                  setSelectedExtendCustomerForAccess(row);
                  setRevokeCustomerAccessRequestModal(true);
                }}
                iconToolTipText={intl.formatMessage({ id: "Revoke" })} />
            }
          </div>
        </>
      ),
    }
  ];

  const CustomerTaxEntityColumn = [
    {
      dataField: "tinNo",
      text: intl.formatMessage({ id: "Tin" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.tinNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "businessType",
      text: intl.formatMessage({ id: "BusinessType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.businessType || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "registeredName",
      text: intl.formatMessage({ id: "CompanyName" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.businessType !== Constants.individual ? row.registeredName : Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "businessRegistrationNo",
      text: intl.formatMessage({ id: "BusinessRegistrationNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.businessRegistrationNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center'},
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageTaxEntity 
              &&
              <TableActionButton
              to={(`/${RoutesList.taxEntity}/view/${row.id}`)}
              type="view" />
            }
            {
              blnPermissionUpdateTaxEntity
              &&
              <TableActionButton
              to={(`/${RoutesList.taxEntity}/edit/${row.id}`)}
              type="edit" />
            }
            {
              blnPermissionDeleteTaxEntity
              &&
              <TableActionButton
              type="delete"
              onClick={() => onClickDeleteCustomerTaxEntityPrompt(row)} />
            }
          </div>
        </>
      ),
    }
  ];

  const CustomerTransferHistoryRowStyle = (row, rowIndex) => {
    return {backgroundColor: row.endDate === Constants.infiniteDateTime ? Constants.lightbluefaded : ""}
  }

  const CustomerSubBranchHistoryRowStyle = (row, rowIndex) => {
    return {backgroundColor: row.endDate === Constants.infiniteDateTimeWithValue ? Constants.lightbluefaded : ""}
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: customerDetail || {
      id: "",
      customerNo: "",
      firstName: "",
      middleName: "",
      lastName: "",
      title: "",
      preferredName: "",
      identityNo: "",
      dob: "",
      gender: "",
      raceName: "",
      branchId: "",
      branchName: "",
      joinedDate: "",
      lastVisitDate: "",
      mobileNo: "",
      email: "",
      occupation: "",
      identityType: "",
      emergencyContact: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryName: "",
      stateName: "",
      sourceId: "",
      mediaSource: "",
      consultantName: "",
      subBranchId: "",
      subBranchName: "",
      subBranchConsultantId: "",
      subBranchConsultantName: "",
      tags: [],
      relationships: []
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
    }
  });

  async function fetchCustomerDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getCustomerWithId(id),
        getGeneralSettings()
      ];
      if (initialCallAPI) {
        aryAPI.push(
          getStaticReferenceWithType(Constants.creditAdjustmentType),
          getStaticReferenceWithType(Constants.debitType),
          getStaticReferenceWithType(Constants.transferCreditAdjustmentType),
          getStaticReferenceWithType(Constants.creditPaymentType))
      }
      await Promise.all(aryAPI);
      setInitialCallAPI(false);
    }
    setLoading(false);

    if (initialRerouteFromTin) {
      let blnBackNavigationFromTaxEntity = comparePathForPagination(prevPath, RoutesList.taxEntity)
      if (blnBackNavigationFromTaxEntity || blnScrollTaxEntityOnCustomerProfile) {
        if (refTinTable) {
          if (refTinTable.current) {
            toggle("11", intl.formatMessage({ id: "TinInfo" }).toUpperCase());
            setTimeout(()=> { 
              refTinTable.current.scrollIntoView({ behavior: "smooth" })
            }, 100)
          }
        }
        setBlnScrollTaxEntityOnCustomerProfile(false);
      }
      setInitialRerouteFromTin(false);
    }
  }

  async function fetchAppointmentAPI() {
    setLoadingAppointment(true);
    await getAppointmentList({ pageNumber: 1, pageSize: appointmentPageSize, branchId: "", customerId: id })
    setLoadingAppointment(false);
  }

  async function fetchPackageAPI() {
    setLoadingPurchasedPackage(true);
    await getCustomerPurchasedPackage({ pageNumber: 1, pageSize: maxPageSize, customerId: id, showCompleted: showCompletedPurchasedPackage })
    setLoadingPurchasedPackage(false);
  }

  async function fetchProductAPI() {
    setLoadingPurchasedProduct(true);
    await getCustomerPurchasedProduct({ pageNumber: 1, pageSize: maxPageSize, customerId: id, showCompleted: showCompletedPurchasedProduct })
    setLoadingPurchasedProduct(false);
  }

  async function fetchReservedProductAPI() {
    setLoadingReservedProduct(true);
    await getCustomerReservedProduct({ pageNumber: 1, pageSize: maxPageSize, customerId: id })
    setLoadingReservedProduct(false);
  }

  async function fetchServiceAPI() {
    setLoadingPurchasedService(true);
    await getCustomerPurchasedService({ pageNumber: 1, pageSize: maxPageSize, customerId: id, showCompleted: showCompletedPurchasedService })
    setLoadingPurchasedService(false);
  }

  async function fetchClosePurchasedHistoryAPI() {
    setLoadingClosePurchasedHistory(true);
    await getCustomerClosePurchasedHistory({ pageNumber: 1, pageSize: maxPageSize, customerId: id })
    setLoadingClosePurchasedHistory(false);
  }

  async function fetchSubBranchHistoryAPI() {
    setLoadingSubBranchHistory(true);
    await getCustomerSubBranchHistory({ pageNumber: 1, pageSize: maxPageSize, customerId: id })
    setLoadingSubBranchHistory(false);
  }

  async function fetchSalesOrderAPI() {
    setLoadingSalesOrder(true);
    await getCustomerSalesOrder({ pageNumber: 1, pageSize: maxPageSize, customerId: id })
    setLoadingSalesOrder(false);
  }

  async function fetchTransferHistoryAPI() {
    setLoadingTransferHistory(true);
    await getCustomerTransferHistory({ pageNumber: 1, pageSize: maxPageSize, customerId: id })
    setLoadingTransferHistory(false);
  }

  async function fetchNoteAPI() {
    setLoadingNotes(true);
    await getCustomerNote({ pageNumber: currentPageNote, pageSize: maxPageSize, customerId: id })
    setLoadingNotes(false);
  }

  async function fetchAppolousCustomerPackageAPI() {
    setLoadingAppolousCustomerPackage(true);
    await getAppolousCustomerPackage({ pageNumber: currentPageAppolousCustomerPackage, pageSize: maxPageSize, customerId: id })
    setLoadingAppolousCustomerPackage(false);
  }

  async function fetchAppolousCustomerProductAPI() {
    setLoadingAppolousCustomerProduct(true);
    await getAppolousCustomerProduct({ pageNumber: currentPageAppolousCustomerProduct, pageSize: maxPageSize, customerId: id })
    setLoadingAppolousCustomerProduct(false);
  }

  async function fetchAppolousCustomerSalesAPI() {
    setLoadingAppolousCustomerSales(true);
    await getAppolousCustomerSales({ pageNumber: currentPageNote, pageSize: maxPageSize, customerId: id })
    setLoadingAppolousCustomerSales(false);
  }

  async function fetchProductDropdown () {
    setLoadingProductDropdown(true);
    await getProductDropdown();
    setLoadingProductDropdown(false);
  }

  async function fetchPackageDropdown () {
    setLoadingPackageDropdown(true);
    await getPackageDropdown({ branchId: "" });
    setLoadingPackageDropdown(false);
  }

  async function fetchCustomerAccessRequestList() {
    setLoadingCustomerAccessRequest(true);
    await getCustomerAccessRequest({ pageNumber: 1, pageSize: maxPageSize, customerId: id, isFilterActiveRequestsOnly: true, customerAccessRequestStatus: Constants.approved, originalBranchId: customerProfileFullDetail.branchId }, true)
    setLoadingCustomerAccessRequest(false);
  }

  async function fetchCustomerTaxEntityList() {
    setLoadingCustomerTaxEntity(true);
    await getCustomerTaxEntityWithId(id)
    setLoadingCustomerTaxEntity(false);
  }
  
  async function addCustomerTaxEntityWebFormHistory() {
    const result = await addTaxEntityFormHistory({customerId: id})
    if (result?.data.webFormHistoryId) {
      return result?.data.webFormHistoryId; 
    }
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateCustomer], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateCustomer], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageCustomer], true)) {
        return;
      }
    }
    
    fetchCustomerDetailAPI();
    fetchAppointmentAPI();
    fetchPackageAPI();

    return (()=> {
      resetWhenLeaveCustomerProfile();
      setAppolousCustomerPackageList([]);
      setAppolousCustomerProductList([]);
      setAppolousCustomerSalesList([]);
      setCustomerAccessRequestList([]);
      setTaxEntityList([]);
    })
  }, []);

  useEffect(() => {
    if (successMessage && !customerNoteModal && !subBranchRequestModal && !subBranchRemoveModal && !subBranchRequestAcknowledgementModal && !appolousCustomerPackageExtendExpiryModal && !appolousCustomerPackageStatusUpdateModal && !appolousCustomerPackageEditModal && !appolousCustomerProductRedeemModal && !appolousCustomerProductStatusUpdateModal && !editCustomerJoinedDateModal && !editCustomerMediaSourceModal && !addTagModal && !deleteTagModal && !addRelationshipModal && !deleteRelationshipModal && !customerAccessRequestModal && !extendCustomerAccessRequestModal && !revokeCustomerAccessRequestModal) {
      setTimeout(async () => {
        setSuccessMessage("");
        fetchCustomerDetailAPI();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(()=> {
    if (!initialCallAPI) {
      fetchPackageAPI();
    }
  }, [showCompletedPurchasedPackage])

  useEffect(()=> {
    if (!initialCallAPI) {
      fetchServiceAPI();
    }
  }, [showCompletedPurchasedService])

  useEffect(()=> {
    if (!initialCallAPI) {
      fetchProductAPI();
    }
  }, [showCompletedPurchasedProduct])

  const onClickDelete = async (row: CustomerNoteListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const onClickDeleteCustomerTaxEntityPrompt = async (row: TaxEntityListObject) => {
    setSelectedTaxEntityRow(row);
    setShowDeleteCustomerTaxEntity(true);
  }

  const onClickDeleteCustomerTaxEntity = async () => {
		setLoadingModal(true);
		let resultAPIDeleteCustomerTaxEntity = await deleteTaxEntity(selectedTaxEntityRow?.id || "");
		setLoadingModal(false);
		if (resultAPIDeleteCustomerTaxEntity.status === Constants.success) {
			fetchCustomerTaxEntityList();
		}
	}

  const toggle = (tab: string, valueTab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      switch(valueTab) {
        case intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(): //SERVICE TREATMENTS
          return customerPurchasedServiceList.length < 1 && !loadingPurchasedService ? fetchServiceAPI() : undefined;
        case intl.formatMessage({ id: "Products" }).toUpperCase(): //PURCHASED PRODUCT
          return customerPurchasedProductList.length < 1 && !loadingPurchasedProduct ? fetchProductAPI() : undefined;
        case intl.formatMessage({ id: "CustomerVirtualWarehouse" }).toUpperCase(): //RESERVED PRODUCT
          return customerReservedProductList.length < 1 && !loadingReservedProduct ? fetchReservedProductAPI() : undefined;
        case intl.formatMessage({ id: "Histories" }).toUpperCase(): //HISTORIES 
          return customerClosePurchasedHistoryList.length < 1  && !loadingClosePurchasedHistory ? fetchClosePurchasedHistoryAPI() : undefined;
        case intl.formatMessage({ id: "Sales" }).toUpperCase(): //SALES
          return customerSalesOrderList.length < 1  && !loadingSalesOrder ? fetchSalesOrderAPI() : undefined;
        case intl.formatMessage({ id: "Notes" }).toUpperCase(): //NOTES
          return customerNoteList.length < 1 && !loadingNotes  ? fetchNoteAPI() : undefined;
        case intl.formatMessage({ id: "CustomerTransferHistories" }).toUpperCase(): //CUSTOMER TRANSFER HISTORIES
          return customerTransferHistoryList.length < 1  && !loadingTransferHistory ? fetchTransferHistoryAPI() : undefined;
        case intl.formatMessage({ id: "SubBranchHistories" }).toUpperCase(): //SUB BRANCH HISTORIES
          return customerSubBranchHistory.length < 1  && !loadingSubBranchHistory ? fetchSubBranchHistoryAPI() : undefined;
        case intl.formatMessage({ id: "GrantedAccesses" }).toUpperCase(): //GRANTED ACCESS
          return customerAccessRequestList.length < 1  && !loadingCustomerAccessRequest ? fetchCustomerAccessRequestList() : undefined;
        case intl.formatMessage({ id: "TinInfo" }).toUpperCase(): //TIN INFO
          return taxEntityList.length < 1  && !loadingCustomerTaxEntity ? fetchCustomerTaxEntityList() : undefined;
        default:
          return undefined;
      }
    }
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const emptyTableData = () => {
    return (
      <tr><td colSpan={7} style={{ color: "gray", textAlign: "center" }}>{intl.formatMessage({ id: "NoAvailableData" })}</td></tr>
    );
  };

  const checkIsToday = (createdDate: any) => {
    let blnIsWithinToday = moment(createdDate).format(Constants.displayDateFormat) === today
    return blnIsWithinToday;
  }

  const onClickNoteAction = async (blnActionTemp: string, row?: CustomerNoteListObject) => {
    if (blnActionTemp === Constants.add) {
      setBlnAction(blnActionTemp);
      setCustomerNoteModal(true);
    }
    else {
      if (row) {
        setLoadingNotes(true);
        let resultCustomerNoteWithId = await getCustomerNoteWithId(row.id);
        setLoadingNotes(false);
        if (resultCustomerNoteWithId?.status === Constants.success) {
          setBlnAction(blnActionTemp);
          setCustomerNoteModal(true);
        }
      }
    }
  }

  const onClickViewExchangeToCreditDetail = async (exchangeToCreditId: any) => {
    setLoading(true);
    let resultExchangeToCreditDetail = await getExchangeToCreditWithId(exchangeToCreditId);
    setSelectedExchangeToCreditDetail(resultExchangeToCreditDetail)
    setExchangeToCreditDetailModal(true);
    setLoading(false);
  }

  const onClickViewCreditAdjustment = async (creditAdjustmentId: any) => {
    setLoading(true);
    await getCreditAdjustmentWithId(creditAdjustmentId)
    setCreditAdjustmentModal(true);
    setLoading(false);
  }

  const onClickViewPackageRedeemHistory = async (customerSalesOrderId: any) => {
    setLoadingPurchasedPackage(true);
    await getCustomerPackageRedeemHistory(customerSalesOrderId)
    setPackageRedeemHistoryModal(true);
    setLoadingPurchasedPackage(false);
  }

  const onClickViewServiceTreatmentRedeemHistory = async (id: string) => {
    setLoadingPurchasedService(true);
    await getCustomerServiceRedeemHistory(id)
    setServiceRedeemHistoryModal(true);
    setLoadingPurchasedService(false);
  }

  const onClikUpdateCustomerJoinedDate = async (updateCustomerJoinedDateObject: UpdateCustomerJoinedDateObject) => {
    setLoadingEditCustomerJoinedDate(true);
    let result = await updateCustomerJoinedDate(updateCustomerJoinedDateObject);
    setLoadingEditCustomerJoinedDate(false);
    if(result.status === Constants.success){
      setEditCustomerJoinedDateModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickAddAppolousCustomerPackage = async (appolousCustomerPackageAddRequestBody: AppolousCustomerPackageAddRequestBody) => {
    setLoadingAddAppolousCustomerPackage(true);
    let result = await addAppolousCustomerPackage(appolousCustomerPackageAddRequestBody);
    setLoadingAddAppolousCustomerPackage(false);
    if(result && result.status === Constants.success){
      setAddAppolousCustomerPackageModal(false);
      setLoadingModal(true);
      fetchAppolousCustomerPackageAPI();
      setLoadingModal(false);
    }
  }

  const onClickAddAppolousCustomerProduct = async (appolousCustomerProductAddRequestBody: AppolousCustomerProductAddRequestBody) => {
    setLoadingAddAppolousCustomerProduct(true);
    let result = await addAppolousCustomerProduct(appolousCustomerProductAddRequestBody);
    setLoadingAddAppolousCustomerProduct(false);
    if(result && result.status === Constants.success){
      setAddAppolousCustomerProductModal(false);
      setLoadingModal(true);
      fetchAppolousCustomerProductAPI();
      setLoadingModal(false);
    }
  }

  const onClickUpdateCustomerMediaSource = async (updateCustomerMediaSourceObject: UpdateCustomerMediaSourceObject) => {
    setLoadingEditCustomerMediaSource(true);
    let result = await updateCustomerMediaSource(updateCustomerMediaSourceObject);
    setLoadingEditCustomerMediaSource(false);
    if(result.status === Constants.success){
      setEditCustomerMediaSourceModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickAddCustomerTag = async (addCustomerTagRequestBody: AddCustomerTagObject) => {
    setLoadingCustomerTag(true);
    let result = await addCustomerTag(addCustomerTagRequestBody);
    setLoadingCustomerTag(false);
    if(result.status === Constants.success){
      setAddTagModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickAddCustomerRelationship = async (addCustomerRelationshipRequestBody: AddCustomerRelationshipObject) => {
    setLoadingCustomerRelationship(true);
    let result = await addCustomerRelationship(addCustomerRelationshipRequestBody);
    setLoadingCustomerRelationship(false);
    if(result.status === Constants.success){
      setAddRelationshipModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickDeleteCustomerTag = async () => {
    setLoadingCustomerTag(true);
    let result = await deleteCustomerTag(selectedTagDetails?.id || "");
    setLoadingCustomerTag(false);
    if(result.status === Constants.success){
      setDeleteTagModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickDeleteCustomerRelationship = async () => {
    setLoadingCustomerRelationship(true);
    let result = await deleteCustomerRelationship(selectedRelationshipDetails?.id || "");
    setLoadingCustomerRelationship(false);
    if(result.status === Constants.success){
      setDeleteRelationshipModal(false);
      setLoadingModal(true);
      await getCustomerWithId(id);
      setLoadingModal(false);
    }
  }

  const onClickRevokeCustomerAccessRequest = async () => {
    setLoadingRevokeCustomerAccessRequest(true);
    let result = await deleteCustomerAccessRequest(selectedExtendCustomerForAccess?.id || "");
    setLoadingRevokeCustomerAccessRequest(false);
    if(result.status === Constants.success){
      setSelectedExtendCustomerForAccess(undefined);
      setRevokeCustomerAccessRequestModal(false);
      fetchCustomerAccessRequestList();
    }
  }

  const onClickShowCompletedPurchasedPackage = () => {
    setShowCompletedPurchasedPackage((valueShowCompletedPurchasedPackage)=> !valueShowCompletedPurchasedPackage);
  }

  const onClickShowCompletedPurchasedService = () => {
    setShowCompletedPurchasedService((valueShowCompletedPurchasedService)=> !valueShowCompletedPurchasedService);
  }

  const onClickShowCompletedPurchasedProduct = () => {
    setShowCompletedPurchasedProduct((valueShowCompletedPurchasedProduct)=> !valueShowCompletedPurchasedProduct);
  }

  const returnTabBarIcon = (valueTab: string) => {
    let iconTabBar: any = null;
    if (valueTab === intl.formatMessage({ id: "Packages" }).toUpperCase()) {
      iconTabBar = <PackageVariantClosedIcon size={Constants.menuIconSize} />;
    }
    else if (valueTab === intl.formatMessage({ id: "Products" }).toUpperCase()) {
      iconTabBar = <ArrangeBringForwardIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase()) {
      iconTabBar = <ClipboardEditIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "Histories" }).toUpperCase()) {
      iconTabBar = <ProgressCloseIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "Sales" }).toUpperCase()) {
      iconTabBar = <CreditCardMultipleIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "Notes" }).toUpperCase()) {
      iconTabBar = <BookIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "CustomerTransferHistories" }).toUpperCase()) {
      iconTabBar = <AccountClockIcon size={Constants.menuIconSize} />
    }
    else if (valueTab === intl.formatMessage({ id: "CustomerVirtualWarehouse" }).toUpperCase()) {
      iconTabBar = <WarehouseIcon size={Constants.menuIconSize} />
    }

    return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={
          blnCustomerProfileFullAccess
          ?
          // blnPermissionManageContractLiability
          // ?
          // [
          //   {
          //     label: `${intl.formatMessage({ id: "ViewContractLiability" })}`,
          //     onFunction: () => {
          //       history.push({ pathname: `/${RoutesList.contractLiabilitySiteMap}/${id}` });
          //     }
          //   },
          //   {
          //     label: `${intl.formatMessage({ id: showTiles ? "HideSalesInfo" : "ShowSalesInfo"})}`,
          //     onFunction: () => {
          //       setShowTiles((valueTiles)=> !valueTiles);
          //     }
          //   },
          // ]
          // : 
          [
            {
              label: `${intl.formatMessage({ id: showTiles ? "HideSalesInfo" : "ShowSalesInfo"})}`,
              onFunction: () => {
                setShowTiles((valueTiles)=> !valueTiles);
              }
            }
          ]
          : 
          []
        }
        auditTrailId={viewAction ? id : null}>
        {loading && !successMessage && !redeemHistoryModal && !creditHistoryModal && !transferCreditHistoryModal ? (
          <Loading />
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row>
              <Col xl="3" lg="4" sm="4">
                <CustomerProfileDetail
                  validation={validation}
                  customerCredit={customerProfileFullDetail.totalAvailableCredits}
                  customerLockedCredit={customerProfileFullDetail.totalLockedCredits}
                  transferCredits={customerProfileFullDetail.transferCredits}
                  isMenu={isMenu}
                  toggleMenu={toggleMenu}
                  setCreditHistoryModal={async () => {
                    setCreditHistoryModal(true);
                  }}
                  setCreditAdjustmentModal={setCreditAdjustmentModal}
                  setTransferCreditModal={setTransferCreditModal}
                  setSubBranchRemoveModal={setSubBranchRemoveModal}
                  setTransferCreditHistoryModal={async () => {
                    if (loadingTransferCreditHistory) {
                      return;
                    }

                    setLoadingTransferCreditHistory(true);
                    await getCustomerTransferCreditHistory({
                      pageNumber: 1,
                      pageSize: pageSize,
                      customerId: id
                    })
                    setLoadingTransferCreditHistory(false);
                    setTransferCreditHistoryModal(true);
                  }}
                  setSubBranchRequestModal={setSubBranchRequestModal}
                  setEditCustomerJoinedDateModal={setEditCustomerJoinedDateModal}
                  setEditCustomerMediaSourceModal={setEditCustomerMediaSourceModal}
                  setAddTagModal={setAddTagModal}
                  setDeleteTagModal={setDeleteTagModal}
                  setSelectedTagDetails={setSelectedTagDetails}
                  setAddRelationshipModal={setAddRelationshipModal}
                  setDeleteRelationshipModal={setDeleteRelationshipModal}
                  setSelectedRelationshipDetails={setSelectedRelationshipDetails}
                  setCustomerAccessRequestModal={setCustomerAccessRequestModal}
                  isTranseferCreditEnabled={generalSettings?.isTransferCreditEnabled || false}
                  loadingCreditHistory={loadingCreditHistory}
                  loadingTransferCreditHistory={loadingTransferCreditHistory}
                  isShowAppolous={generalSettings?.isShowAppolous || false}
                  windowSize={windowSize}
                  successMessage={successMessage}
                />
              </Col>
              <Col xl="9" lg="8" sm="8">
                {
                  showTiles
                  &&
                  <Row>
                    <Col xl="3" lg="6" sm="6" xs="12">
                      <div
                        onClick={async () => {
                          if (!getBranchUser()) {
                            if (loadingRedeemHistory) {
                              return;
                            }
                            setLoadingRedeemHistory(true)
                            await getCustomerRedeemHistory({
                              pageNumber: 1,
                              pageSize: pageSize,
                              customerId: id
                            })
                            setLoadingRedeemHistory(false)
                            setRedeemHistoryModal(true)
                          }
                        }}>
                        <CardInfo
                          icon={() => (<CashIcon className="h2 text-warning mb-0" />)}
                          title={intl.formatMessage({ id: !getBranchUser() ? "TotalEarnedSales" : "TotalSalesOrderSales" }).toUpperCase()}
                          value={returnPriceWithCurrency(!getBranchUser() ? customerProfileFullDetail.totalEarnedSales : customerProfileFullDetail.totalSalesOrderSales)}
                          valueColor={"text-green-color"}
                          loading={loadingRedeemHistory}
                          blnArrow={!getBranchUser()} />
                      </div>
                    </Col>
                    <Col xl="3" lg="6" sm="6" xs="12">
                      <CardInfo
                        icon={() => (<CashCheckIcon className="h2 text-primary mb-0" />)}
                        title={intl.formatMessage({ id: "TotalPaid" }).toUpperCase()}
                        value={returnPriceWithCurrency(customerProfileFullDetail.totalPaid)} />
                    </Col>
                    <Col xl="3" lg="6" sm="6" xs="12">
                      <CardInfo
                        icon={() => (<CashRemoveIcon className="h2 text-info mb-0" />)}
                        title={intl.formatMessage({ id: "TotalUnpaid" }).toUpperCase()}
                        value={returnPriceWithCurrency(customerProfileFullDetail.totalUnpaid)} />
                    </Col>
                    <Col xl="3" lg="6" sm="6" xs="12">
                      <CardInfo
                        icon={() => (<CashPlusIcon className="h2 text-info mb-0" />)}
                        title={intl.formatMessage({ id: "UnearnedRevenue" }).toUpperCase()}
                        value={returnPriceWithCurrency(customerProfileFullDetail.totalUnearnRevenue)} />
                    </Col>
                  </Row>
                }
                <div style={{ backgroundColor: "white", marginBottom: '25px' }}>
                  <div className={`flex-right`} style={{ paddingTop: '20px', paddingRight: '20px' }}>
                    {
                      blnPermissionCreateAppointment
                      &&
                      <MyAddButton
                        content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                        onClick={() => {
                          history.push({
                            pathname: `/${RoutesList.appointment}`,
                            state: {
                              detail: {
                                branchId: validation.values.branchId,
                                branchName: validation.values.branchName,
                                customerId: validation.values.id,
                                customerName: validation.values.preferredName,
                                email: validation.values.email,
                                contactNo: validation.values.mobileNo,
                                addAppointmentAction: true
                              }
                            }
                          });
                        }}
                        disable={loading} />
                    }
                  </div>
                  <PaginationTable
                    title={intl.formatMessage({ id: "Appointments" })}
                    currentPage={currentPageAppointment}
                    setCurrentPage={setCurrentPageAppointment}
                    pageSize={appointmentPageSize}
                    totalItem={appointmentTotalItems}
                    tableColumns={AppointmentColumns}
                    data={appointmentList}
                    localLoading={loadingAppointment}
                    setLocalLoading={setLoadingAppointment}
                    loadingClassName={"margin-left-8 padding-bottom-12"}
                    emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                    objSorted={defaultSorted}
                    hideSearchBar={true}
                    customerId={id}
                    requestAPI={getAppointmentList} />
                </div>
              </Col>
              <Col>
                <div className="standard-layout">
                  <Nav className="nav nav-tabs nav-tabs-custom">
                    {tabBar.map((valueTabBar, indexTab) => (
                      (valueTabBar === intl.formatMessage({ id: "Notes" }).toUpperCase() && (!blnPermissionManageCustomerNote || !blnCustomerProfileFullAccess))
                      || (valueTabBar === intl.formatMessage({ id: "Sales" }).toUpperCase() && !blnCustomerProfileFullAccess)
                      || (valueTabBar === intl.formatMessage({ id: "GrantedAccesses" }).toUpperCase() && (!blnPermissionManageCustomerAccessRequest || !blnOrginalBranchMatchUserBranch))
                      ?
                      <div key={`tabkey_${indexTab}`} style={{display: 'hidden'}}/>
                      :
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          disabled={indexTab == 1 && (false)}
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active:
                              activeTab ===
                              String(indexTab + 1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab + 1), valueTabBar);
                          }}
                        >
                          {/* {value} */}
                          {returnTabBarIcon(valueTabBar)}
                        </NavLink>
                      </NavItem>
                    )
                  )}
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="margin-top-8"
                    style={{paddingLeft: '12px', paddingRight: '12px'}}>
                    <TabPane tabId="1">
                      <Col lg="12">
                        <Row className="mt-4 mb-2">
                          <Col className="flex-direction-row">
                            {
                              blnPermissionManageAppolousRedemption
                              &&
                              blnShowAppolous
                              &&
                              <div>
                                <GeneralSnipButton
                                  name="customerPackage"
                                  aryButton={aryCustomerPackageSnipButton}
                                  selectedIndex={selectedPackageAppolous}
                                  setSelectedIndex={setSelectedPackageAppolous}/>
                              </div>
                            }
                            {
                              selectedPackageAppolous === 0
                              &&
                              <div className="btn-group margin-left-12" role="group">
                                {returnSwitchTabButtonUI("blnShowCompletedPurchasedPackage", "blnShowCompletedPurchasedPackageFalse", showCompletedPurchasedPackage, loadingPurchasedPackage || loading, intl.formatMessage({ id: "ShowCompleted" }), onClickShowCompletedPurchasedPackage, showCompletedButtonWidth, "btn-outline-info")}
                              </div>
                            }
                          </Col>
                          {
                            blnPermissionUpdateCustomerSalesOrder 
                            &&
                            blnCustomerProfileFullAccess
                            &&
                            selectedPackageAppolous === 0 
                            &&
                            <Col className="flex-right">
                              <MyAddButton
                                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "PackageUpgrade" }) })}
                                onClick={() => {
                                  history.push({
                                    pathname: `/${RoutesList.packageUpgrade}`,
                                    state: { detail: { customerId: id } }
                                  });
                                }}
                                disable={loading} />
                            </Col>
                          }
                          {
                            blnPermissionCreateAppolousPackage 
                            &&
                            selectedPackageAppolous !== 0
                            &&
                            blnShowAppolous
                            &&
                            <Col className="flex-right">
                              <MyAddButton
                                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "AppolousCustomerPackage" }) })}
                                onClick={async() => {
                                  fetchPackageDropdown();
                                  setAddAppolousCustomerPackageModal(true);
                                }}
                                disable={loadingAppolousCustomerPackage} />
                            </Col>
                          }
                        </Row>
                        {
                          selectedPackageAppolous == 0
                          ?
                          <PaginationTable
                            currentPage={currentPagePurchasedPackage}
                            setCurrentPage={setCurrentPagePurchasedPackage}
                            pageSize={maxPageSize}
                            totalItem={customerPurchasedPackageTotalItems}
                            tableColumns={PurchasedPackagesColumns}
                            data={customerPurchasedPackageList}
                            localLoading={loadingPurchasedPackage}
                            setLocalLoading={setLoadingPurchasedPackage}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PurchasePackages" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            showCompleted={showCompletedPurchasedPackage}
                            blnRemoveBackground={true}
                            requestAPI={getCustomerPurchasedPackage} />
                          :
                          <PaginationTable
                            currentPage={currentPageAppolousCustomerPackage}
                            setCurrentPage={setCurrentPageAppolousCustomerPackage}
                            pageSize={maxPageSize}
                            totalItem={appolousCustomerPackageListTotalItems}
                            tableColumns={AppolousCustomerPackageColumns}
                            data={appolousCustomerPackageList}
                            localLoading={loadingAppolousCustomerPackage}
                            setLocalLoading={setLoadingAppolousCustomerPackage}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousCustomerPackage" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            blnRemoveBackground={true}
                            keyField={"appolousCustomerPackageId"}
                            requestAPI={getAppolousCustomerPackage} />
                        }
                      </Col>
                    </TabPane>
                    <TabPane tabId="2">
                      <Col lg="12">
                        <div className="btn-group mt-3" role="group">
                          {returnSwitchTabButtonUI("blnShowCompletedPurchasedService", "blnShowCompletedServiceFalse", showCompletedPurchasedService, loadingPurchasedService || loading, intl.formatMessage({ id: "ShowCompleted" }), onClickShowCompletedPurchasedService, showCompletedButtonWidth,  "btn-outline-info")}
                        </div>
                        <PaginationTable
                          currentPage={currentPagePurchasedService}
                          setCurrentPage={setCurrentPagePurchasedService}
                          pageSize={maxPageSize}
                          totalItem={customerPurchasedServiceTotalItems}
                          tableColumns={PurchasedServicesColumns}
                          data={customerPurchasedServiceList}
                          localLoading={loadingPurchasedService}
                          setLocalLoading={setLoadingPurchasedService}
                          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PurchaseServices" }) })}
                          objSorted={defaultSorted}
                          hideSearchBar={true}
                          customerId={id}
                          showCompleted={showCompletedPurchasedService}
                          blnRemoveBackground={true}
                          requestAPI={getCustomerPurchasedService} />
                      </Col>
                    </TabPane>
                    <TabPane tabId="3">
                      <Col lg="12">
                        <Row className="mt-4 mb-2">
                          <Col>
                            {
                              blnPermissionManageAppolousRedemption
                              &&
                              blnShowAppolous
                              &&
                              <GeneralSnipButton
                                name="customerProduct"
                                aryButton={aryCustomerProductSnipButton}
                                selectedIndex={selectedProductAppolous}
                                setSelectedIndex={setSelectedProductAppolous}/>
                            }
                            {
                              selectedProductAppolous === 0
                              &&
                              <div className="btn-group margin-left-12" role="group">
                                {returnSwitchTabButtonUI("blnShowCompletedPurchasedProduct", "blnShowCompletedPurchasedProductFalse", showCompletedPurchasedProduct, loadingPurchasedProduct || loading, intl.formatMessage({ id: "ShowCompleted" }), onClickShowCompletedPurchasedProduct, showCompletedButtonWidth, "btn-outline-info")}
                              </div>
                            }
                          </Col>
                          {
                            blnPermissionCreateAppolousProduct 
                            &&
                            selectedProductAppolous !== 0
                            &&
                            blnShowAppolous
                            &&
                            <Col className="flex-right">
                              <MyAddButton
                                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "AppolousCustomerProduct" }) })}
                                onClick={async() => {
                                  fetchProductDropdown();
                                  setAddAppolousCustomerProductModal(true);
                                }}
                                disable={loadingAppolousCustomerProduct} />
                            </Col>
                          }
                        </Row>
                        {
                          selectedProductAppolous == 0
                          ?
                          <PaginationTable
                            currentPage={currentPagePurchasedProduct}
                            setCurrentPage={setCurrentPagePurchasedProduct}
                            pageSize={maxPageSize}
                            totalItem={customerPurchasedProductTotalItems}
                            tableColumns={PurchasedProductsColumns}
                            data={customerPurchasedProductList}
                            localLoading={loadingPurchasedProduct}
                            setLocalLoading={setLoadingPurchasedProduct}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "PurchaseProducts" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            showCompleted={showCompletedPurchasedProduct}
                            blnRemoveBackground={true}
                            requestAPI={getCustomerPurchasedProduct} />
                          :
                          <PaginationTable
                            currentPage={currentPageAppolousCustomerProduct}
                            setCurrentPage={setCurrentPageAppolousCustomerProduct}
                            pageSize={maxPageSize}
                            totalItem={appolousCustomerProductListTotalItems}
                            tableColumns={AppolousCustomerProductColumns}
                            data={appolousCustomerProductList}
                            localLoading={loadingAppolousCustomerProduct}
                            setLocalLoading={setLoadingAppolousCustomerProduct}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousCustomerProduct" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            blnRemoveBackground={true}
                            keyField={"appolousSaleDetailId"}
                            requestAPI={getAppolousCustomerProduct} />
                        }
                      </Col>
                    </TabPane>
                    <TabPane tabId="4">
                      <Col lg="12">
                        <PaginationTable
                          currentPage={currentPageReservedProduct}
                          setCurrentPage={setCurrentPageReservedProduct}
                          pageSize={maxPageSize}
                          totalItem={customerReservedProductTotalItems}
                          tableColumns={VirtualWarehouseColumns}
                          data={customerReservedProductList}
                          localLoading={loadingReservedProduct}
                          setLocalLoading={setLoadingReservedProduct}
                          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerVirtualWarehouse" }) })}
                          objSorted={defaultSorted}
                          hideSearchBar={true}
                          customerId={id}
                          blnRemoveBackground={true}
                          requestAPI={getCustomerReservedProduct} />
                      </Col>
                    </TabPane>
                    <TabPane tabId="5">
                      <Col lg="12">
                        <PaginationTable
                          currentPage={currentPageClosePurchasedHistory}
                          setCurrentPage={setCurrentPageClosePurchasedHistory}
                          pageSize={maxPageSize}
                          totalItem={customerClosePurchasedHistoryTotalItems}
                          tableColumns={InactiveClosedPurchasedHistoriesColumns}
                          data={customerClosePurchasedHistoryList}
                          localLoading={loadingClosePurchasedHistory}
                          setLocalLoading={setLoadingClosePurchasedHistory}
                          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Histories" }) })}
                          objSorted={defaultSorted}
                          hideSearchBar={true}
                          customerId={id}
                          blnRemoveBackground={true}
                          requestAPI={getCustomerClosePurchasedHistory} />
                      </Col>
                    </TabPane>
                    <TabPane tabId="6">
                      <Col lg="12">
                        <Row className="mt-4 mb-2">
                          {
                            blnPermissionManageAppolousSales
                            &&
                            blnShowAppolous
                            &&
                            <Col>
                              <GeneralSnipButton
                                name="customerSales"
                                aryButton={aryCustomerSalesSnipButton}
                                selectedIndex={selectedSalesAppolous}
                                setSelectedIndex={setSelectedSalesAppolous}/>
                            </Col>
                          }
                          {
                            blnPermissionManageCustomerSalesOrder &&
                            <Col className="flex-right">
                              <MyAddButton
                                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "P.O.S" }) })}
                                onClick={() => {
                                  history.push({
                                    pathname: `/${RoutesList.pos}`,
                                    state: { detail: { customerId: id, branchId: customerDetail?.branchId || "" } }
                                  });
                                }}
                                disable={loading} />
                            </Col>
                          }
                        </Row>
                        {
                          selectedSalesAppolous == 0
                          ?
                          <PaginationTable
                            currentPage={currentPageSalesOrder}
                            setCurrentPage={setCurrentPageSalesOrder}
                            pageSize={maxPageSize}
                            totalItem={customerSalesOrderTotalItems}
                            tableColumns={SalesOrderColumns}
                            data={customerSalesOrderList}
                            localLoading={loadingSalesOrder}
                            setLocalLoading={setLoadingSalesOrder}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Sales" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            blnRemoveBackground={true}
                            requestAPI={getCustomerSalesOrder} />
                          :
                          <PaginationTable
                            currentPage={currentPageAppolousCustomerSales}
                            setCurrentPage={setCurrentPageAppolousCustomerSales}
                            pageSize={maxPageSize}
                            totalItem={appolousCustomerSalesListTotalItems}
                            tableColumns={AppolousCustomerSalesColumns}
                            data={appolousCustomerSalesList}
                            localLoading={loadingAppolousCustomerSales}
                            setLocalLoading={setLoadingAppolousCustomerSales}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AppolousCustomerSales" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            blnRemoveBackground={true}
                            keyField={"appolousSaleId"}
                            requestAPI={getAppolousCustomerSales} />
                        }
                      </Col>
                    </TabPane>
                    {
                      blnPermissionManageCustomerNote &&
                      <TabPane tabId="7">
                        <Col lg="12">
                          <div className="flex-right mt-4 mb-2">
                            {
                              blnPermissionCreateCustomerNote &&
                              <MyAddButton
                                content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Note" }) })}
                                onClick={() => onClickNoteAction(Constants.add)}
                                disable={loading || loadingNotes || Boolean(successMessage)} />
                            }
                          </div>
                          <PaginationTable
                            currentPage={currentPageNote}
                            setCurrentPage={setCurrentPageNote}
                            pageSize={maxPageSize}
                            totalItem={customerNoteTotalItems}
                            tableColumns={CustomerNoteColumns}
                            data={customerNoteList}
                            localLoading={loadingNotes}
                            setLocalLoading={setLoadingNotes}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Note" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            blnRemoveBackground={true}
                            requestAPI={getCustomerNote} />
                        </Col>
                      </TabPane>
                    }
                    <TabPane tabId="8">
                      <Col lg="12">
                        <PaginationTable
                          currentPage={currentPageTransferHistory}
                          setCurrentPage={setCurrentPageTransferHistory}
                          pageSize={maxPageSize}
                          totalItem={customerTransferHistoryTotalItems}
                          tableColumns={CustomerTransferHistoryColumns}
                          rowStyle={CustomerTransferHistoryRowStyle}
                          data={customerTransferHistoryList}
                          localLoading={loadingTransferHistory}
                          setLocalLoading={setLoadingTransferHistory}
                          emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "CustomerTransferHistories" }) })}
                          objSorted={defaultSorted}
                          hideSearchBar={true}
                          customerId={id}
                          keyField="startDate"
                          blnRemoveBackground={true}
                          requestAPI={getCustomerTransferHistory} />
                      </Col>
                    </TabPane>
                    <TabPane tabId="9">
                      <Col lg="12">
                        {
                          loadingSubBranchHistory
                          ?
                          <Loading/>
                          :
                          <PaginationTable
                            currentPage={currentPageSubBranchHistory}
                            setCurrentPage={setCurrentPageSubBranchHistory}
                            pageSize={maxPageSize}
                            totalItem={customerSubBranchHistoryTotalItems}
                            tableColumns={CustomerSubBranchHistoryColumn}
                            rowStyle={CustomerSubBranchHistoryRowStyle}
                            data={customerSubBranchHistory}
                            localLoading={loadingSubBranchHistory}
                            setLocalLoading={setLoadingSubBranchHistory}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "SubBranchHistories" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            customerId={id}
                            keyField="startDate"
                            blnRemoveBackground={true}
                            requestAPI={getCustomerSubBranchHistory} />
                        }
                      </Col>
                    </TabPane>
                    <TabPane tabId="10">
                      <Col lg="12">
                        {
                          loadingCustomerAccessRequest
                          ?
                          <Loading/>
                          :
                          <PaginationTable
                            currentPage={currentPageCustomerAccessRequest}
                            setCurrentPage={setCurrentPageCustomerAccessRequest}
                            pageSize={maxPageSize}
                            totalItem={customerAccessRequestTotalItems}
                            tableColumns={CustomerAccessRequestColumn}
                            data={customerAccessRequestList}
                            localLoading={loadingCustomerAccessRequest}
                            setLocalLoading={setLoadingCustomerAccessRequest}
                            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "GrantedAccesses" }) })}
                            objSorted={defaultSorted}
                            hideSearchBar={true}
                            keyField="id"
                            blnRemoveBackground={true}
                            requestAPI={getCustomerAccessRequest} />
                        }
                      </Col>
                    </TabPane>
                    <TabPane tabId="11">
                      <Col lg="12">
                        <div ref={refTinTable}>
                          {
                            loadingCustomerTaxEntity
                            ?
                            <Loading/>
                            :
                            <>
                            <div className="flex-right mt-4 mb-2">
                              {
                                blnPermissionCreateTaxEntity
                                &&
                                <>
                                <MyButton
                                content={intl.formatMessage({ id: "ReqTIN"})}
                                type="button"
                                onClick={async () =>{
                                  setLoadingCustomerTaxEntity(true);
                                  let result = await addCustomerTaxEntityWebFormHistory()
                                  const message = `${window.location.origin}/${RoutesList.taxEntityWeb}/add/${result}` ;
                                  const encodedId = encodeURIComponent(message);
                                  onClickWhatsappWithDelay(`https://web.whatsapp.com/send?phone=${customerDetail?.mobileNo}&text=${encodedId}`)
                                  setLoadingCustomerTaxEntity(false);
                                }}
                                class={'btn btn-primary margin-right-8'}
                                disable={loading} />
                                <MyAddButton
                                  class={"margin-left-4"}
                                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Tin" }).toUpperCase()})}
                                  onClick={() => { 
                                    history.push({
                                      pathname: `/${RoutesList.taxEntity}/add`,
                                      state: {
                                        detail: {
                                          customerId: customerProfileFullDetail.id,
                                          customerName: customerProfileFullDetail.preferredName
                                        }
                                      }
                                    })
                                  }}
                                  disable={loading || loadingNotes || Boolean(successMessage)} />
                                </>
                              }
                              
                            </div>
                            <PaginationTable
                              currentPage={currentPageCustomerTaxEntity}
                              setCurrentPage={setCurrentPageCustomerTaxEntity}
                              pageSize={maxPageSize}
                              totalItem={customerTaxEntityTotalItems}
                              tableColumns={CustomerTaxEntityColumn}
                              data={taxEntityList}
                              localLoading={loadingCustomerTaxEntity}
                              setLocalLoading={setLoadingCustomerTaxEntity}
                              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "TinInfo" }) })}
                              objSorted={defaultSorted}
                              hideSearchBar={true}
                              keyField="id"
                              blnRemoveBackground={true}
                              requestAPI={getCustomerTaxEntityWithId} />
                              </>
                          }
                        </div>
                      </Col>
                    </TabPane>
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
        {/* CustomerNote */}
        {
          customerNoteModal 
          ?
          <CustomerNoteModal
            blnShow={customerNoteModal}
            blnAction={blnAction}
            setModal={setCustomerNoteModal}
            customerId={id}
            currentPage={currentPageNote}
            setCurrentPage={setCurrentPageNote}
            addCustomerNote={addCustomerNote}
            updateCustomerNote={updateCustomerNote}
            getCustomerNote={getCustomerNote}
            selectedCustomerNoteDetail={customerNoteDetail}
            setSelectedCustomerNoteDetail={setCustomerNoteDetail}
            setErrorMessage={setErrorMessage}
            setLoadingNotes={setLoadingNotes} />
          :
          <div/>
        }
        <DeleteModal
          displayName={selectedRow?.note}
          onDeleteClick={async () => {
            setLoadingNotes(true);
            await deleteCustomerNote(selectedRow?.id || "");
            let currentPageTemp = currentPageNote;
            if (customerNoteList.length === 1 && currentPageNote !== 1) {
              setCurrentPageNote((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getCustomerNote({ pageNumber: currentPageTemp, pageSize: maxPageSize, customerId: id })
            setLoadingNotes(false);
          }} />
        {
          showDeleteCustomerTaxEntity
          &&
          <ActionPromptModal
          title={`${intl.formatMessage({ id: "DeleteCustomerTaxEntity" }, {customerName: `${customerProfileFullDetail.preferredName} (${selectedTaxEntityRow?.tinNo})`})}`}
          primaryActionTitle={`Yes`}
          showPrompt={showDeleteCustomerTaxEntity}
          setShowPrompt={setShowDeleteCustomerTaxEntity}
          onPrimaryClick={() => { onClickDeleteCustomerTaxEntity() }}
        />
        }
        {/* CustomerRedeemHistory */}
        {
          redeemHistoryModal &&
          <SelectWithTableListModal
            blnShow={redeemHistoryModal}
            setModal={setRedeemHistoryModal}
            title={intl.formatMessage({ id: "RedeemHistory" })}
            options={customerRedeemHistoryList}
            keyField={"redeemId"}
            fieldName={"redeemId"}
            columns={RedeemHistoryColumns}
            validation={validation}
            blnPagination={true}
            totalItem={customerRedeemHistoryTotalItems}
            requestAPI={getCustomerRedeemHistory}
            paginationSubmitField={{ customerId: id }} />
        }
        {/* CreditHistory */}
        {
          creditHistoryModal &&
          <CreditHistoryModal
            blnShow={creditHistoryModal}
            setModal={setCreditHistoryModal}
            customerId={id}
            creditPaymentType={creditPaymentType}
            availableCredits={customerDetail?.availableCredits || 0}
            lockedCredits={customerDetail?.lockedCredits || 0}
            availableProductCredits={customerDetail?.availableProductCredits || 0}
            lockedProductCredits={customerDetail?.lockedProductCredits || 0}
            availableExchangedCredits={customerDetail?.availableExchangedCredits || 0}
            lockedExchangedCredits={customerDetail?.lockedExchangedCredits || 0}
            blnPermissionManageExchangeToCredit={blnPermissionManageExchangeToCredit}
            blnPermissionManageCreditAdjustment={blnPermissionManageCreditAdjustment}
            blnPermissionManageCustomerSalesOrder={blnPermissionManageCustomerSalesOrder}
            blnPermissionManageCustomerProductReturn={blnPermissionManageCustomerProductReturn}
            blnPermissionManageVirtualWarehouseCreditExchange={blnPermissionManageVirtualWarehouseCreditExchange}
            blnPermissionManageProductRedemptionNoteReturn={blnPermissionManageProductRedemptionNoteReturn}
            blnPermissionManageAppolousSales={blnPermissionManageAppolousSales}
            blnPermissionManageCreditRedemptionNote={blnPermissionManageCreditRedemptionNote}
            blnPermissionManageCreditNote={blnPermissionManageCreditNote}
            onClickViewExchangeToCreditDetail={onClickViewExchangeToCreditDetail}
            onClickViewCreditAdjustment={onClickViewCreditAdjustment} />
        }
        {/* TransferCreditHistory */}
        {
          transferCreditHistoryModal &&
          <SelectWithTableListModal
            blnShow={transferCreditHistoryModal}
            setModal={setTransferCreditHistoryModal}
            title={intl.formatMessage({ id: "TransferCreditHistory" })}
            options={customerTransferCreditHistoryList}
            keyField={"id"}
            fieldName={"entityId"}
            columns={TransferCreditHistoryColumns}
            validation={validation}
            blnPagination={true}
            totalItem={customerTransferCreditHistoryTotalItems}
            requestAPI={getCustomerTransferCreditHistory}
            paginationSubmitField={{ customerId: id }} />
        }
        {/* CreditAdjustment */}
        {
          creditAdjustmentModal &&
          <CreditAdjustmentModal
            blnShow={creditAdjustmentModal}
            setModal={setCreditAdjustmentModal}
            customerId={id}
            creditAdjustmentType={creditAdjustmentType}
            debitType={debitType}
            previewCreditAdjustment={previewCreditAdjustment}
            requestCreditAdjustment={requestCreditAdjustment}
            selectedCreditAdjustmentDetail={creditAdjustmentDetail}
            blnView={creditHistoryModal}
            setErrorMessage={setErrorMessage} />
        }
        {/* ExchangeToCredit */}
        {
          exchangeToCreditDetailModal &&
          <ExchangeToCreditDetailModal
            blnShow={exchangeToCreditDetailModal}
            setModal={setExchangeToCreditDetailModal}
            selectedExchangeToCreditDetail={selectedExchangeToCreditDetail}
            setSelectedExchangeToCreditDetail={setSelectedExchangeToCreditDetail}
          />
        }
        {/* TransferCredit */}
        {
          transferCreditModal &&
          <TransferCreditModal
            blnShow={transferCreditModal}
            setModal={setTransferCreditModal}
            customerId={id}
            transferCreditAdjustmentType={transferCreditAdjustmentType}
            transferCustomerCredit={transferCustomerCredit}
            setErrorMessage={setErrorMessage} />
        }
        {/* CustomerPackageRedeemHistory */}
        {
          packageRedeemHistoryModal &&
          <SelectWithTableListModal
            blnShow={packageRedeemHistoryModal}
            setModal={setPackageRedeemHistoryModal}
            title={(customerPackageRedeemHistory && `${customerPackageRedeemHistory.packageName} (${customerPackageRedeemHistory.sessions} ${intl.formatMessage({ id: "Sessions" })})`) || ""}
            options={customerPackageRedeemHistory?.packageRedemptionHistories}
            keyField={"randomId"}
            fieldName={"randomId"}
            columns={PackageRedeemptionHistoryColumns}
            validation={validation}
            modalSubtitleBadge={customerPackageRedeemHistory?.serviceNames || []}
          />
        }
        {/* CustomerServiceRedeemHistory */}
        {
          serviceRedeemHistoryModal &&
          <SelectWithTableListModal
            blnShow={serviceRedeemHistoryModal}
            setModal={setServiceRedeemHistoryModal}
            title={(customerServiceRedeemHistory && `${customerServiceRedeemHistory.serviceName} (${customerServiceRedeemHistory.totalSessions} ${intl.formatMessage({ id: "Sessions" })})`) || ""}
            options={customerServiceRedeemHistory?.serviceRedemptionHistories}
            keyField={"randomId"}
            fieldName={"randomId"}
            columns={ServiceRedeemptionHistoryColumns}
            validation={validation}
            modalSubtitleBadge={customerPackageRedeemHistory?.serviceNames || []}
          />
        }
        {
          subBranchRequestModal &&
          <SubBranchRequestModal
            blnShow={subBranchRequestModal}
            setModal={setSubBranchRequestModal}
            customerId={id}
            customerName={validation.values.preferredName}
            subBranchId={validation.values.subBranchId}
            subBranchName={validation.values.subBranchName}
            subBranchConsultantId={validation.values.subBranchConsultantId}
            subBranchConsultantName={validation.values.subBranchConsultantName}
            setSubBranchRequestAcknowledgementModal={setSubBranchRequestAcknowledgementModal}
            setSubBranchRequestId={setSubBranchRequestId}
            setSubBranchRequestAction={setSubBranchRequestAction}
            deleteSubBranch={false}
          />
        }
        {
          subBranchRequestAcknowledgementModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "SubBranchRequestAcknowledgementMessage" }, { action: subBranchRequestAction })}`}
            primaryActionTitle={`Yes`}
            showPrompt={subBranchRequestAcknowledgementModal}
            setShowPrompt={setSubBranchRequestAcknowledgementModal}
            onPrimaryClick={()=> {
              history.push({
                pathname: `/${RoutesList.customerSubBranchRequest}/edit/${subBranchRequestId}`
              }); 
            }}
          />
        }
        {
          subBranchRemoveModal
          &&
          <SubBranchRequestModal
            blnShow={subBranchRemoveModal}
            setModal={setSubBranchRemoveModal}
            customerId={id}
            customerName={validation.values.preferredName}
            subBranchId={validation.values.subBranchId}
            subBranchName={validation.values.subBranchName}
            subBranchConsultantId={validation.values.subBranchConsultantId}
            subBranchConsultantName={validation.values.subBranchConsultantName}
            setSubBranchRequestAcknowledgementModal={setSubBranchRequestAcknowledgementModal}
            setSubBranchRequestId={setSubBranchRequestId}
            setSubBranchRequestAction={setSubBranchRequestAction}
            deleteSubBranch={true}
          />
        }
        {
          appolousCustomerPackageExtendExpiryModal
          &&
          <AppolousCustomerPackageExtendExpiryModal
            blnShow={appolousCustomerPackageExtendExpiryModal}
            setModal={setAppolousCustomerPackageExtendExpiryModal}
            customerId={id}
            currentPage={currentPageAppolousCustomerPackage}
            setCurrentPage={setCurrentPageAppolousCustomerPackage}
            extendExpiryFunction={extendExpiryAppolousCustomerPackage}
            getAppolousCustomerPackages={getAppolousCustomerPackage}
            selectedAppolousCustomerPackageDetail={selectedAppolousCustomerPackageDetail}
            setSelectedAppolousCustomerPackageDetail={setSelectedAppolousCustomerPackageDetail}
            setErrorMessage={setErrorMessage}
            setLoadingAppolousCustomerPackage={setLoadingAppolousCustomerPackage}
          />
        }
        {
          appolousCustomerPackageStatusUpdateModal
          &&
          <AppolousCustomerPackageStatusUpdateModal
            blnShow={appolousCustomerPackageStatusUpdateModal}
            setModal={setAppolousCustomerPackageStatusUpdateModal}
            customerId={id}
            currentPage={currentPageAppolousCustomerPackage}
            setCurrentPage={setCurrentPageAppolousCustomerPackage}
            statusUpdateFunction={statusUpdateAppolousCustomerPackage}
            getAppolousCustomerPackages={getAppolousCustomerPackage}
            selectedAppolousCustomerPackageDetail={selectedAppolousCustomerPackageDetail}
            setSelectedAppolousCustomerPackageDetail={setSelectedAppolousCustomerPackageDetail}
            setErrorMessage={setErrorMessage}
            setLoadingAppolousCustomerPackage={setLoadingAppolousCustomerPackage}
          />
        }
        {
          appolousCustomerPackageEditModal
          &&
          <AppolousCustomerPackageEditModal
            blnShow={appolousCustomerPackageEditModal}
            setModal={setAppolousCustomerPackageEditModal}
            customerId={id}
            currentPage={currentPageAppolousCustomerPackage}
            setCurrentPage={setCurrentPageAppolousCustomerPackage}
            editFunction={editAppolousCustomerPackage}
            getAppolousCustomerPackages={getAppolousCustomerPackage}
            selectedAppolousCustomerPackageDetail={selectedAppolousCustomerPackageDetail}
            setSelectedAppolousCustomerPackageDetail={setSelectedAppolousCustomerPackageDetail}
            setErrorMessage={setErrorMessage}
            setLoadingAppolousCustomerPackage={setLoadingAppolousCustomerPackage}
          />
        }
        {
          appolousCustomerProductRedeemModal
          &&
          <AppolousCustomerProductRedeemModal
            blnShow={appolousCustomerProductRedeemModal}
            setModal={setAppolousCustomerProductRedeemModal}
            customerId={id}
            currentPage={currentPageAppolousCustomerProduct}
            setCurrentPage={setCurrentPageAppolousCustomerProduct}
            redeemFunction={redeemAppolousCustomerProduct}
            getAppolousCustomerProducts={getAppolousCustomerProduct}
            selectedAppolousCustomerProductRedeemDetail={selectedAppolousCustomerProductDetail}
            setSelectedAppolousCustomerProductRedeemDetail={setSelectedAppolousCustomerProductDetail}
            setErrorMessage={setErrorMessage}
            setLoadingAppolousCustomerProductRedeem={setLoadingAppolousCustomerProduct}
            setAlternativeProductMessageModal={setAlternativeProductMessageModal}
            localBranchProductList={localBranchProductList}/>
        }
        {
          appolousCustomerProductStatusUpdateModal
          &&
          <AppolousCustomerProductStatusUpdateModal
            blnShow={appolousCustomerProductStatusUpdateModal}
            setModal={setAppolousCustomerProductStatusUpdateModal}
            customerId={id}
            currentPage={currentPageAppolousCustomerProduct}
            setCurrentPage={setCurrentPageAppolousCustomerProduct}
            statusUpdateFunction={statusUpdateAppolousCustomerProduct}
            getAppolousCustomerProducts={getAppolousCustomerProduct}
            selectedAppolousCustomerProductDetail={selectedAppolousCustomerProductDetail}
            setSelectedAppolousCustomerProductDetail={setSelectedAppolousCustomerProductDetail}
            setErrorMessage={setErrorMessage}
            setLoadingAppolousCustomerProduct={setLoadingAppolousCustomerProduct}
          />
        }
        {
          alternativeProductMessageModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "AlternativeProductMessage" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={alternativeProductMessageModal}
            setShowPrompt={setAlternativeProductMessageModal}
            onPrimaryClick={async ()=> {
              setLoadingBranchProductDropdown(true);
              let filteredBranchProductList : ProductForBranchObject[] = [];
              let resutlBranchProductList : ProductForBranchObject[] | undefined = await getBranchProductList(getBranchId());
              if (resutlBranchProductList) {
                filteredBranchProductList = resutlBranchProductList.filter(branchProduct => {
                  return branchProduct.quantity > 0;
                });
              }
              setLocalBranchProductList(filteredBranchProductList);
              setSelectedAppolousCustomerProductDetail((valueSelectedAppolousCustomerProductDetail)=> ({
                ...valueSelectedAppolousCustomerProductDetail,
                isAlternative: true
              })); 
              setLoadingBranchProductDropdown(false);
              setAlternativeProductMessageModal(false);
            }}
            localLoading={loadingBranchProductDropdown}
          />
        }
        {
          editCustomerJoinedDateModal &&
          <EditCustomerJoinedDateModal
            blnShow={editCustomerJoinedDateModal}
            setModal={setEditCustomerJoinedDateModal}
            customerId={id}
            customerName={customerProfileFullDetail.preferredName}
            currentJoinedDate={moment(customerProfileFullDetail?.joinedDate).format(Constants.displayDateTextInputFormat)} 
            onClikUpdateCustomerJoinedDate={onClikUpdateCustomerJoinedDate}
            loadingEditCustomerJoinedDate={loadingEditCustomerJoinedDate} />
        }
        {
          addAppolousCustomerPackageModal &&
          <AddAppolousCustomerPackageModal
            blnShow={addAppolousCustomerPackageModal}
            setModal={setAddAppolousCustomerPackageModal}
            customerId={id}
            customerName={customerProfileFullDetail.preferredName}
            branchName={customerProfileFullDetail.branchName}
            packageDropdownList={packageDropdownList} 
            loadingPackageDropdown={loadingPackageDropdown}
            onClickAddAppolousCustomerPackage={onClickAddAppolousCustomerPackage}
            loadingAddAppolousCustomerPackage={loadingAddAppolousCustomerPackage} />
        }
        {
          addAppolousCustomerProductModal &&
          <AddAppolousCustomerProductModal
            blnShow={addAppolousCustomerProductModal}
            setModal={setAddAppolousCustomerProductModal}
            customerId={id}
            customerName={customerProfileFullDetail.preferredName}
            branchName={customerProfileFullDetail.branchName}
            productDropdownList={productDropdownList} 
            loadingProductDropdown={loadingProductDropdown}
            onClickAddAppolousCustomerProduct={onClickAddAppolousCustomerProduct}
            loadingAddAppolousCustomerProduct={loadingAddAppolousCustomerProduct} />
        }
        {
          editCustomerMediaSourceModal &&
          <EditCustomerMediaSourceModal
            blnShow={editCustomerMediaSourceModal}
            setModal={setEditCustomerMediaSourceModal}
            customerId={id}
            customerName={validation.values.preferredName}
            sourceId={validation.values.sourceId}
            sourceName={validation.values.mediaSource}
            referredById={customerProfileFullDetail.referredById}
            referredByName={customerProfileFullDetail.referredByName}
            onClickUpdateCustomerMediaSource={onClickUpdateCustomerMediaSource}
            loadingEditCustomerMediaSource={loadingEditCustomerMediaSource}
          />
        }
        {
          addTagModal && 
          <AddTagModal 
            blnShow={addTagModal}
            setModal={setAddTagModal}
            customerId={id}
            onClickAddTag={onClickAddCustomerTag}
            loadingTag={loadingCustomerTag}/>
        }
        {
          deleteTagModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "DeleteTagAlert" },  { tag: selectedTagDetails?.tagValue || "" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={deleteTagModal}
            setShowPrompt={setDeleteTagModal}
            onPrimaryClick={onClickDeleteCustomerTag}
            localLoading={loadingCustomerTag}
          />
        }
        {
          addRelationshipModal && 
          <AddRelationshipModal 
            blnShow={addRelationshipModal}
            setModal={setAddRelationshipModal}
            customerId={id}
            onClickAddRelationship={onClickAddCustomerRelationship}
            loadingRelationship={loadingCustomerRelationship}/>
        }
        {
          deleteRelationshipModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "DeleteRelationshipAlert" },  { name: selectedRelationshipDetails?.linkToCustomerName || "", type: selectedRelationshipDetails?.relationshipType || ""  })}`}
            primaryActionTitle={`Yes`}
            showPrompt={deleteRelationshipModal}
            setShowPrompt={setDeleteRelationshipModal}
            onPrimaryClick={onClickDeleteCustomerRelationship}
            localLoading={loadingCustomerRelationship}
          />
        }
        {
          customerAccessRequestModal
          &&
          <CustomerAccessRequestModal
            blnShow={customerAccessRequestModal}
            setModal={setCustomerAccessRequestModal}
            selectedCustomerForAccess={selectedCustomerForAccess}
            addCustomerAccessRequest={addCustomerAccessRequest}
            customerBranchId={customerProfileFullDetail.branchId}
            customerSubBranchId={customerProfileFullDetail.subBranchId}
            fetchCustomerAccessRequestList={fetchCustomerAccessRequestList}/>
        }
        {
          extendCustomerAccessRequestModal
          &&
          <ExtendCustomerAccessRequestModal
            blnShow={extendCustomerAccessRequestModal}
            setModal={setExtendCustomerAccessRequestModal}
            selectedExtendCustomerForAccess={selectedExtendCustomerForAccess}
            setSelectedExtendCustomerForAccess={setSelectedExtendCustomerForAccess}
            extendCustomerAccessRequest={extendCustomerAccessRequest}
            fetchCustomerAccessRequestList={fetchCustomerAccessRequestList}/>
        }
        {
          revokeCustomerAccessRequestModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "RevokeAccessRequestAlert" },  { branch: selectedExtendCustomerForAccess?.requestBranchName || "" })}`}
            primaryActionTitle={`Yes`}
            showPrompt={revokeCustomerAccessRequestModal}
            setShowPrompt={setRevokeCustomerAccessRequestModal}
            onPrimaryClick={onClickRevokeCustomerAccessRequest}
            onCancelClick={()=> {setSelectedExtendCustomerForAccess(undefined)}}
            localLoading={loadingRevokeCustomerAccessRequest}
          />
        }
        {
          loadingModal
          &&
          <LoadingModal/>
        }
      </DetailViewLayout>
    </div>
  );
}

export default observer(CustomerProfile);
