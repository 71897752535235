import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, changePaymentModeStatusColor, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { Link } from "react-router-dom";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import DropDownWithTitleMultiSelect from "../../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";

const ChangePaymentModeReport = () => {
  const intl = useIntl();
  //Use Store
  const { reportAccountStore, branchStore, staticReferenceStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { changePaymentModeList, getChangePaymentModeList, setChangePaymentModeList, exportChangePaymentMode } = reportAccountStore;
  const { isEligibleAllBranch, getBranchDropDown } = branchStore;
  const { getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const [filteredChangePaymentModeStatus, setFilteredChangePaymentModeStatus] = useState<StaticReferenceDataObject[]>([]);
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportAccountReport = checkPermission([PermissionConstants.ExportAccountReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const localEmptyData = " ";
  const tableDetailWidth = "180px";
  
  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      changePaymentModeNo: "",
      statuses: []
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    async function fetchChangePaymentModeListApi() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown(),
        getStaticReferenceWithType(Constants.changePaymentModeStatus)
      ];

      let resultAPI = await Promise.all(aryAPI);

      setBranchListFinal(arraySpliceInAllValue(resultAPI[0], isEligibleAllBranch ? AllBranch : "", true))
      setFilteredChangePaymentModeStatus(resultAPI[1].filter(changePaymentModeStatusTemp => changePaymentModeStatusTemp.key !== 0 && changePaymentModeStatusTemp.key !== 5))

      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAccountReport], true)) {
      return;
    }

    fetchChangePaymentModeListApi();

    return (() => {
      setChangePaymentModeList([]);
    })
  }, [])

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  async function fetchChangePaymentModeApi(blnDropDown: boolean = false) {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    blnDropDown ? setLocalLoading(true) : setLoading(true);
    setCurrentPage(1);
    await getChangePaymentModeList({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      changePaymentModeNo: validation.values.changePaymentModeNo,
      statuses: validation.values.statuses
    });
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  async function exportChangePaymentModeApi() {
    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true)
    await exportChangePaymentMode({
      pageNumber: 1,
      pageSize: pageSize,
      branchId: selectedBranchId,
      dateRange: selectedDateRange,
      changePaymentModeNo: validation.values.changePaymentModeNo,
      statuses: validation.values.statuses
    });
    setLocalLoading(false)
  }

  const localGreenColor = "#d2f1cb";
  const localHeaderStyle = { border: "0px solid" }
  const returnLocalDetailStyle = (index) => {
    return ({
      backgroundColor: index === 0 ? Constants.tableCellGrey : "",
      border: index === 0 ? `1px solid` : "",
      borderColor: index === 0 ?  Constants.tableCellBorderGrey : "",
      fontWeight: index === 0 ? 600 : ""
    })
  }

  //Table Content UI
  const ChangePaymentModeListColumns = [
    {
      dataField: "preparedOn",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(
          index === 0 
          ? 
          localEmptyData
          : 
          !row.preparedOn
          ?
          Constants.emptyData
          :
          <div>
            <div className="">
              {`${moment(row.preparedOn).format(Constants.displayDateFormat)}`}
            </div>
            <div className="small-text">
              {moment(row.preparedOn).format(Constants.displayTimeFormat)}
            </div>
          </div>
        )
      ),
    },
    {
      dataField: "documentNo",
      text: intl.formatMessage({ id: "DocumentNo" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(index === 0 ? localEmptyData : row.documentNo)
      ),
    },
    {
      dataField: "outlet",
      text: intl.formatMessage({ id: "Outlet" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(index === 0 ? localEmptyData : row.outlet)
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(index === 0 ? localEmptyData : row.customerName, blnPermissionViewCustomer && row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : "")
      ),
    },
    {
      dataField: "identityNo",
      text: intl.formatMessage({ id: "IcNoOrPassportNo" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(index === 0 ? localEmptyData : row.identityNo)
      ),
    },
    {
      dataField: "oldPaymentDetails",
      text: intl.formatMessage({ id: "1stPaymentDetails" }).toUpperCase(),
      headerStyle: { textAlign: 'center', ...localHeaderStyle },
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => {
        let aryDateViews : any = [];
        let aryPaymentModeViews : any = [];
        let aryAccountNoOrCreditCardNoViews : any = [];
        let aryAmountViews : any = [];

				row.oldPaymentDetails.map((value, indexOldPaymentDetail) => {
					aryDateViews.push(
						displayTableCellUI(`old_payment_details_date_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.transactionDate ? moment(value.transactionDate).format(Constants.displayDateFormat) : "")
					)
          aryPaymentModeViews.push(
						displayTableCellUI(`old_payment_details_payment_mode_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.paymentMode)
					)
          aryAccountNoOrCreditCardNoViews.push(
						displayTableCellUI(`old_payment_details_account_no__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.cardOrAccountNo)
					)
          aryAmountViews.push(
						displayTableCellUI(`old_payment_details_amount__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.oldPaymentDetails.length, value.amount ? returnPriceWithCurrency(value.amount) : "")
					)
				})
        
        return (
          <div className="flex-direction-row">
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "TransactionDate" }).toUpperCase() : aryDateViews, "", index, false, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "PaymentMode" }).toUpperCase()  : aryPaymentModeViews, "", index, true, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "AccountNoOrCreditCardNo" }).toUpperCase()  : aryAccountNoOrCreditCardNoViews, "", index, true, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Amount" }).toUpperCase()  : aryAmountViews, "", index, true, false, index === 0 ? false : true, true)}
          </div>
        )
      },
    },
    {
      dataField: "newPaymentDetails",
      text: intl.formatMessage({ id: "2ndPaymentDetails" }).toUpperCase(),
      headerStyle: { textAlign: 'center', ...localHeaderStyle },
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => {
        let aryDateViews : any = [];
        let aryPaymentModeViews : any = [];
        let aryAccountNoOrCreditCardNoViews : any = [];
        let aryAmountViews : any = [];

				row.newPaymentDetails.map((value, indexOldPaymentDetail) => {
					aryDateViews.push(
						displayTableCellUI(`new_payment_details_date_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.newPaymentDetails.length, value.transactionDate ? moment(value.transactionDate).format(Constants.displayDateFormat) : "")
					)
          aryPaymentModeViews.push(
						displayTableCellUI(`new_payment_details_payment_mode_${indexOldPaymentDetail}`, indexOldPaymentDetail, row.newPaymentDetails.length, value.paymentMode)
					)
          aryAccountNoOrCreditCardNoViews.push(
						displayTableCellUI(`new_payment_details_account_no__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.newPaymentDetails.length, value.cardOrAccountNo)
					)
          aryAmountViews.push(
						displayTableCellUI(`new_payment_details_amount__${indexOldPaymentDetail}`, indexOldPaymentDetail, row.newPaymentDetails.length, value.amount ? returnPriceWithCurrency(value.amount) : "")
					)
				})
        
        return (
          <div className="flex-direction-row">
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "TransactionDate" }).toUpperCase()  : aryDateViews, "", index, false, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "PaymentMode" }).toUpperCase()  : aryPaymentModeViews, "", index, true, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "AccountNoOrCreditCardNo" }).toUpperCase()  : aryAccountNoOrCreditCardNoViews, "", index, true, true, index === 0 ? false : true, true)}
            {displayTableDetailColumn(index === 0 ? intl.formatMessage({ id: "Amount" }).toUpperCase()  : aryAmountViews, "", index, true, false, index === 0 ? false : true, true)}
          </div>
        )
      },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center', ...localHeaderStyle },
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index)
      }),
      formatter: (cellContent, row, index) => (
        // displayTableDetailColumn(
          index === 0 
          ? 
          localEmptyData
          : 
          <div>
          <GeneralStatus
            status={row.status}
            statusColorFunction={changePaymentModeStatusColor}/>
          </div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      headerStyle: localHeaderStyle,
      style: (cellContent, row, index) => ({ 
        ...returnLocalDetailStyle(index),
        minWidth: "500px"
      }),
      formatter: (cellContent, row, index) => (
        displayTableDetailColumn(index === 0 ? localEmptyData : row.remark || Constants.emptyData)
      ),
    }
  ];

  const displayTableDetailColumn = (valueDisplay, hyperLink?: string, index?: number, blnPadding?: boolean, showBorderRight?: boolean, disableDisplayFlex?: boolean, disabledTextCenter?: boolean) => {
    return (
      <div
        style={{
          flex: 1,
          paddingLeft: blnPadding ? "0.75rem" : "",
          paddingRight: blnPadding ? "0.75rem" : "",
          borderRight: showBorderRight ? `solid ${index === 0 ? Constants.tableCellBorderGrey : Constants.placeholderColor}` : "",
          borderRightWidth: showBorderRight ? "1px" : "",
          alignItems: 'center', 
          justifyContent: !disabledTextCenter ? index || index === 0 ? index === 0 ? 'center' : 'right' : undefined : undefined,
          textAlign: !disabledTextCenter ? index || index === 0 ? index === 0 ? 'center' : 'right' : undefined : undefined,
          display: !disableDisplayFlex ? 'flex' : undefined,
          minHeight: '60px',
          minWidth: "120px"
        }}>
        {
          hyperLink
          ?
          <Link
            to={hyperLink}
            target={Constants.supportBlank}
            className="flex-direction-row">
            {valueDisplay}
          </Link>
          :
          valueDisplay
        }
      </div>
    )
  }

  const displayTableCellUI = (key, index, totalLength, value) => {
    return (
      <div 
        key={key}
        style={{
          marginBottom: index === totalLength-1 ? "-0.75rem" : "", 
          marginLeft: "-0.75rem",
          marginRight: "-0.75rem",
          paddingLeft: "16px",
          paddingRight: '16px',
          minHeight: '60px', 
          maxHeight: '60px', 
          height: '60px', 
          alignItems: 'center', 
          display: 'flex', 
          borderBottom: index === totalLength-1 ? "" : "dashed #eff2f7",
          borderBottomWidth: "1px",
          width: tableDetailWidth,
          minWidth: tableDetailWidth,
        }}>
        {value}
      </div>
    )
  }

  const returnTableDataCellCss = () => {
    return {
      height: 0,
      paddingTop: 0,
      paddingBottom: 0
    }
  }

  const ChangePaymentModeRowStyle = (row, index) => {
    return ({
      backgroundColor: index === 0 ? localGreenColor: "",
      fontWeight: index === 0 ? 600 : "",
    })
  }

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
        title={intl.formatMessage({ id: "ChangePaymentMode" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportAccountReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              exportChangePaymentModeApi()
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Change-Payment-Mode.aspx`}>
        <Row>
          <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              options={branchListFinal}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "PreparedDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="3">
            <DropDownWithTitleMultiSelect
              name={"statuses"}
              title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "ChangePaymentMode" }) })}
              specifyReturnFieldName={[{ "field": "statuses", "value": "key" }]}
              returnFieldWithLabel={false}
              labelField={"displayValue"}
              valueField={"key"}
              options={filteredChangePaymentModeStatus}
              disabled={localLoading || loading}
              blnValueWithNewSpace={true}
              validationRequired={false}
              validation={validation} />
          </Col>
        </Row>
        <Row>
          <Col xl='3'>
            <GeneralInput
              title={intl.formatMessage({ id: "DocumentNo" })}
              name="changePaymentModeNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "DocumentNo" })}
            />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchChangePaymentModeApi(true)
              }}
            />
          </Col>
        </Row>
 
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={ChangePaymentModeListColumns}
                rowStyle={ChangePaymentModeRowStyle}
                bordered={true}
                data={changePaymentModeList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ChangePaymentMode" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                changePaymentModeNo={validation.values.changePaymentModeNo}
                statuses={validation.values.statuses}
                keyField={"randomId"}
                requestAPI={getChangePaymentModeList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ChangePaymentModeReport);
