import React, { useEffect, useState } from "react";
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../models/common";
import { Label, Input, Row, Col, InputGroup } from "reactstrap";
import _ from "lodash";
import LineBreakWithTittle from "../form/LineBreakWithTittle";
import { convertArrayForExpandRow, getPurchaseTypeIcon, handleCustomizeTableScrolling, returnMobileOrTable, returnWidthPercentage } from "../../common/function/function";
import MyButton from "../form/MyButton";
import GeneralStatus from "../form/GeneralStatus";
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import { Constants } from "../../constants/Constants";
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
interface Props {
  name: string;
  title: string;
  className?: string;
  blnValidation?: boolean;
  blnEditable: boolean;
  aryDynamicInputAndCheckBoxTab?: ObjectForTableWithDynamicInputAndCheckBox[];
	data: any
  inputGroup?: boolean;
  inputGroupText?: string;
  inputField?: any;
  validation: any;
  customCheckedField?: string;
  hideSelectAllTitle?: boolean;
  selectAllTextFieldDisplay: string;
  selectAllTextFieldDisplayXLSize?: number;
  disabled: boolean;
  blnTableViewCss?: boolean;
  iconTypeName?: string;
  disabledCheckFieldName?: string;
  hideSelectAllButton?: boolean;
  expandRowTitle?: string;
  expandRowTitleField?: string;
  blnExpandRow?: true;
  expandRowBlockField?: string;
  warningField?: string;
  warningFieldBackgroundColor?: string;
  fixedHeader?: boolean;
  windowSizeHeight?: number;
}

export default function TableWithSelectAllCheckBoxAndInput(props: Props) {
	const aryData = _.cloneDeep(props.data);
  const [aryExpandData, setAryExpandData] = useState(_.cloneDeep(convertArrayForExpandRow(props.data, props.expandRowTitleField || "", true, props.disabledCheckFieldName || "")));
  const [blnSelectAllChecked, setSelectAllChecked] = useState(false);
  const checkedField = props.customCheckedField || "isActive";
  const blnScreenSupportScroll = handleCustomizeTableScrolling();
  
  useEffect(()=> {
    checkAllTicked();
  }, [])

  const onChangeTextInput = (value: any, rowIndex: number, textInputFieldName: string, textInputType: string, maxNumber?: number) => {
    let valueForNumber : number | undefined | string = undefined;
    if (maxNumber || maxNumber === 0) {
      valueForNumber = value === "" ? "" : Math.max(0, Math.min(maxNumber, Number(value)));
    }
    aryData[rowIndex][textInputFieldName || ""] = textInputType == "number" ? (valueForNumber === "" ? "" : valueForNumber === 0 ? 0 : valueForNumber || Number(value)) : value
    props.validation.setFieldValue(props.name, aryData);
  }

  const onChangeChecked = (e: React.ChangeEvent<any>, rowIndex: number, rowHeaderExpandIndex: number = -1, rowExpandIndex: number = -1) => {
    if (props.blnExpandRow && rowHeaderExpandIndex > -1 && rowExpandIndex > -1) {
      let aryExpandDataTemp = _.cloneDeep(aryExpandData);
      let blnProceedExpandRow = true;
      if (aryExpandDataTemp.length > rowHeaderExpandIndex) {
        if (aryExpandDataTemp[rowHeaderExpandIndex].data) {
          if (aryExpandDataTemp[rowHeaderExpandIndex].data.length > rowExpandIndex) {
            if (props.expandRowBlockField) {
              if (e.target.checked && aryExpandDataTemp[rowHeaderExpandIndex].data[rowExpandIndex][props.expandRowBlockField]) {
                if (aryExpandDataTemp[rowHeaderExpandIndex].data[rowExpandIndex][props.expandRowBlockField].length > 0) {
                  blnProceedExpandRow = false;
                }
              }
            }
            if (blnProceedExpandRow) {
              aryExpandDataTemp[rowHeaderExpandIndex].data[rowExpandIndex][checkedField] = e.target.checked;
              setAryExpandData(aryExpandDataTemp);
            }
          }
        }
      }
    }
		aryData[rowIndex][checkedField] = e.target.checked;
		props.validation.setFieldValue(props.name, aryData);

    checkAllTicked();
	};

  const markedAll = (checked: boolean) => {
    for (var a=0; a<aryData.length; a++) {
      let blnBlock = false;
      if (props.disabledCheckFieldName) {
        if (aryData[a][props.disabledCheckFieldName]) {
          blnBlock = true;
        }
      }
      
      if (!blnBlock) {
        aryData[a][checkedField] = checked;
      }
    }
    props.validation.setFieldValue(props.name, aryData);
  } 

  const checkAllTicked = () => {
    let blnAllChcked = true;
    for (var a=0; a<aryData.length; a++) {
      if (aryData[a][checkedField] == false) {
        blnAllChcked = false;
        break;
      }
      
      if (!blnAllChcked) 
        break;
    }
    setSelectAllChecked(blnAllChcked)
  }

  const displayEmptyRow = () => {
    return (
      <LineBreakWithTittle title={`There's no content added`} color='grey'/>
    )
  }

  const displayTableHeader = () => {
    return (
      props.aryDynamicInputAndCheckBoxTab &&
      <div className={`${props.blnTableViewCss ? "padding-0-55rem" : "padding-0-75rem"} flex-direction-row`} style={{backgroundColor: props.blnTableViewCss ? "#eff2f7" : "none"}}>
        <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize) || 3)}}>
          <div className={`flex-direction-row ${props.blnTableViewCss ? "margin-top-8 margin-left-4" : ""}`}>
            {
              !props.hideSelectAllButton
              &&
              <Input
                type="checkbox"
                style={{minWidth: '10px'}}
                onChange={(e)=>{
                  setSelectAllChecked(e.target.checked)
                  markedAll(e.target.checked)
                }}
                checked={blnSelectAllChecked}
                disabled={props.disabled || !props.blnEditable}
                onBlur={props.validation.handleBlur}
              />
            }
            {
              !props.hideSelectAllButton
              &&
              <Label className="margin-left-4 margin-bottom-4">{props.hideSelectAllTitle ? "" : `Select All`}</Label>
            }
          </div>
        </div>
        <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize ? 12-props.selectAllTextFieldDisplayXLSize : 9))}}>
          <div className={`flex-direction-row ${props.blnTableViewCss ? "margin-top-10" : ""}`}>
            {
              props.aryDynamicInputAndCheckBoxTab.map((valueTemp, index)=> (
                <div 
                  key={`tab_index ${index}`}
                  style={{width: returnWidthPercentage(Number(valueTemp.xl))}}>
                  <h6 style={valueTemp?.textAlign ? {textAlign: 'center'} : undefined} className={valueTemp.flexRight ? "flex-right padding-right-20" : ""}>{valueTemp.label}</h6>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  const displayTableContent = (value, index, indexHeaderExpandRow: number = -1, indexExpandRow: number = -1) => {
    return (
      <div key={`content_${index}_${indexExpandRow}`} style={{...props.warningFieldBackgroundColor && props.warningField && { backgroundColor: !value[props.warningField] && value[checkedField] ? props.warningFieldBackgroundColor : "" }}}>
        {!props.blnTableViewCss && <div className="borderLine margin-bottom-2" />}
        <div key={`tab_first_row_${index}`}>
          <div className="padding-0-75rem flex-align-items-center flex-direction-row">
            <div style={{width: returnWidthPercentage(Number(12))}}>
              <div className="flex-align-items-center flex-direction-row">
                <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize) || 3)}}>
                  <div className="flex-direction-row" style={{paddingRight: '20px', marginBottom: !props.selectAllTextFieldDisplay  ? "8px" : ""}}>
                    <div>
                      <Input
                        type="checkbox"
                        style={{minWidth: '10px'}}
                        onChange={(e) => onChangeChecked(e, index, indexHeaderExpandRow, indexExpandRow)}
                        checked={value[checkedField]}
                        disabled={props.disabled || !props.blnEditable || (props.disabledCheckFieldName ? value[props.disabledCheckFieldName] : false)}
                        onBlur={props.validation.handleBlur}
                      />
                    </div>
                    <div>
                      {
                        props.selectAllTextFieldDisplay 
                        && 
                        <Label className="margin-left-4 margin-bottom-4">
                          {props.iconTypeName && getPurchaseTypeIcon(value[props.iconTypeName])}{`${value[props.selectAllTextFieldDisplay]}`}
                        </Label>
                      }
                    </div>
                  </div>
                </div>
                <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize ? 12-props.selectAllTextFieldDisplayXLSize : 9))}}>
                  <div className="flex-direction-row">
                    {
                      props.aryDynamicInputAndCheckBoxTab &&
                      props.aryDynamicInputAndCheckBoxTab.map((valueTab, indexValueTab)=> (
                        <div style={{width: returnWidthPercentage(Number(valueTab.xl)), paddingRight: '20px'}} key={`${index}_${indexValueTab}`}>
                          {
                            valueTab.type == 'input'
                            ?
                              valueTab.inputGroup
                              ?
                              <Row className="g-0">
                                <InputGroup>
                                  <span className="input-group-text">
                                    {valueTab.inputGroupText}
                                  </span>
                                  <Input
                                    key={`tab_second_row_input_${index}_${indexValueTab}`}
                                    name={valueTab.inputName}
                                    type={valueTab.inputType}
                                    onChange={(e) => {
                                      onChangeTextInput(e?.target?.value, index, valueTab.inputName, valueTab.inputType)
                                    }}
                                    onBlur={props.validation.handleBlur}
                                    defaultValue={value[valueTab.inputName]}
                                    disabled={props.disabled || !props.blnEditable || !value[checkedField] || valueTab.disabled}
                                    onWheel={(event) => { event.currentTarget.blur(); }}
                                  />
                                </InputGroup>
                              </Row>
                              :
                              <Input
                                key={`tab_second_row_input_${index}_${indexValueTab}`}
                                name={valueTab.inputName}
                                type={valueTab.inputType}
                                {...valueTab.numberMaxFieldName ? {"value": value[valueTab.inputName]} : undefined}
                                {...!valueTab.numberMaxFieldName ? {"defaultValue": value[valueTab.inputName]} : undefined}
                                onChange={(e) => {
                                  onChangeTextInput(valueTab.inputType == 'checkbox' ? e?.target?.checked : e?.target?.value, index, valueTab.inputName, valueTab.inputType, valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : undefined)
                                }}
                                disabled={props.disabled || !props.blnEditable || !value[checkedField] || valueTab.disabled}
                                onBlur={props.validation.handleBlur}
                                onWheel={(event) => { event.currentTarget.blur(); }}
                                min={(valueTab.numberMaxFieldName) ? 0 : undefined}
                                max={valueTab.numberMaxFieldName ? value[valueTab.numberMaxFieldName] : undefined}
                              />
                            :
                          valueTab.type == 'dynamicButton'
                          ?
                          <div className={`${valueTab.textValueAlign && "flex-justfity-content-center margin-left-20"}`}>
                            <MyButton
                              type="button"
                              content={valueTab.rowButtonTitle || "Add"}
                              class="btn btn-success"
                              onClick={() => {
                                if (valueTab.dynamicButtonFunction) {
                                  valueTab.dynamicButtonFunction(index)
                                }
                                else {
                                  let aryRowTemp = _.cloneDeep(value[valueTab.inputName]) || [];
                                  aryRowTemp.push(valueTab.rowInputField);
                                  aryData[index][valueTab.inputName] = aryRowTemp;
                                  props.validation.setFieldValue(props.name, aryData);
                                }
                              }}
                              disable={props.disabled || valueTab.disabled}
                              onBlur={props.validation.handleBlur}
                            />
                          </div>
                            :
                            valueTab.type == 'status'
                            ?
                            <div className="flex-align-items-center">
                            <GeneralStatus
                              status={value[valueTab.inputName]}
                              statusColorFunction={valueTab.statusColorFunction}
                              blnDisableCenter={true} />
                            </div>
                            :
                            valueTab.linkPath && valueTab.linkPathFieldName
                            ?
                            <div className={`flex-align-items-center ${valueTab.flexRight ? "flex-right" : ""}`}>
                              <Link to={`/${valueTab.linkPath}${value[valueTab.linkPathFieldName]}`} target={Constants.supportBlank} rel="noopener noreferrer">
                                <Label className="margin-bottom-4 pointer-clickable">
                                  {valueTab?.inputLabelTextDisplay} 
                                  {
                                    value[valueTab.inputName]
                                  }
                                  </Label>
                              </Link>
                            </div>
                            :
                            <div className={`flex-align-items-center ${valueTab.flexRight ? "flex-right" : ""}`}>
                              <Label className="margin-bottom-4">
                                {valueTab?.inputLabelTextDisplay} 
                                {
                                  value[valueTab.inputName]
                                }
                                {
                                  valueTab.inputSubTextName
                                  ?
                                  value[valueTab.inputSubTextName]
                                  ?
                                  <p className="payment-method-sub-text">{`${value[valueTab.inputSubTextName]}`}</p>
                                  :
                                  null
                                  :
                                  null
                                }
                                </Label>
                            </div>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.blnTableViewCss && <div className="table-border-line margin-bottom-2" />}
        </div>
      </div>
    )
  }

	return (
		<div className={`${props.className} ${blnScreenSupportScroll ? "table-select-row-support-scroll" : ""}`}>
      <div style={{...blnScreenSupportScroll && {minWidth: Constants.innerWidthCompare}}}>
        <Label>
          {props.blnValidation && <Label style={{ color: "red" }}>{`*`}</Label>}
          {props.title && <div className="h4">{props.title}</div>}
        </Label>
        {
          props.data.length >0
          ?
          <>
            {
              props.blnExpandRow
              ?
              <div>
                <div className="flex-align-items-center flex-direction-row" style={{padding: "14px", backgroundColor: Constants.placeholderColor}}>
                  <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize) || 3)}}>
                  </div>
                  <h6 className="mb-0">{props.expandRowTitle}</h6>
                </div>
                {
                  aryExpandData.map((valueExpandRow, indexExpandRow) => (
                    <div key={`tab_expand_row_${indexExpandRow}`}>
                      <div 
                        className="flex-align-items-center flex-direction-row" 
                        style={{padding: "14px"}}
                        onClick={()=> {
                          let aryExpandDataTemp = _.cloneDeep(aryExpandData);
                          aryExpandDataTemp.map((valueAryExpandDataTemp, indexAryExpandDataTemp)=> {
                            valueAryExpandDataTemp.isExpandable = indexAryExpandDataTemp === indexExpandRow ? !valueAryExpandDataTemp.isExpandable : false;
                            if (valueAryExpandDataTemp.data) {
                              valueAryExpandDataTemp.data = valueAryExpandDataTemp.data.map((valueAryExpandDataDetailTemp)=> ({
                                ...valueAryExpandDataDetailTemp,
                                [checkedField]: false
                              }))
                            }
                            return valueAryExpandDataTemp;
                          })
                          markedAll(false);
                          setAryExpandData(aryExpandDataTemp);
                        }}>
                        <div style={{width: returnWidthPercentage(Number(props.selectAllTextFieldDisplayXLSize) || 3)}}>
                          {valueExpandRow.isExpandable ? <MinusIcon className='me-1' size={14}/> : <PlusIcon className='me-1' size={14}/>}
                        </div>
                        <p className="mb-0" style={{fontSize: '14px'}}>{valueExpandRow[props.expandRowTitleField || ""]}</p>
                      </div>
                      {props.blnTableViewCss && <div className="table-border-line margin-bottom-2" />}
                      {
                        valueExpandRow.data && valueExpandRow.isExpandable
                        ?
                        <>
                          <div key={`expandrow::`} style={{marginTop: "8px", marginBottom: "8px", marginLeft: "42px", padding: "18px", border: "solid", borderWidth: "0.2px", borderColor: "lightblue", borderRadius: "8px"}}>
                            {
                              <div>
                                {displayTableHeader()}
                                {
                                  valueExpandRow.data.map((value: any, index: number) => (
                                    displayTableContent(value, value.originalIndex ? value.originalIndex : index, indexExpandRow, index)
                                  ))
                                }
                              </div>
                            }
                          </div>
                          {props.blnTableViewCss && <div className="table-border-line margin-bottom-2" />}
                        </>
                        :
                        <div/>
                      }
                    </div>
                  ))
                }
              </div>
              :
              <div>
                {displayTableHeader()}
                <div {...props.fixedHeader && !returnMobileOrTable() && {style: {overflowY: 'scroll', maxHeight: props.windowSizeHeight || 300}}}>
                  {aryData.map((value: any, index: number) => (
                    displayTableContent(value, index)
                  ))}
                </div>
              </div>
            }
          </>
          :
          displayEmptyRow()
        }
      </div>
		</div>
	);
};
