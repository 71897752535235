import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import MetaTags from "react-meta-tags";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Container, Row, Col, Form, Modal, Label } from "reactstrap";
import { Constants } from '../../app/constants/Constants';
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import { useIntl } from "react-intl";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import moment from "moment";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { AuditLogCompareValue } from "../../app/models/auditLog";
import BootstrapTable from "react-bootstrap-table-next";
import { newSpaceBeforeCapitalLetter, removeAllSpace, returnRouteFromModule } from "../../app/common/function/function";
import ExchangeToCreditDetailModal from "../ExchangeToCredit/Modal/ExchangeToCreditDetailModal";
import CustomerAccessRequestDetailModal from "../CustomerAccessRequest/Modal/CustomerAccessRequestDetailModal";
import CreditAdjustmentModal from "../Customer/Modal/CreditAdjustmentModal";
import CustomerNoteModal from "../Customer/Modal/CustomerNoteModal";
import PaymentAdjustmentDetailModal from "../CustomerPaymentAdjustment/Modal/PaymentAdjustmentDetailModal";
import ExtendPackageDetailModal from "../ExtendPackage/Modal/ExtendPackageDetailModal";
import FollowUpHistoryModal from "../Lead/Modal/FollowUpHistoryModal";
import SalesAdjustmentDetailModal from "../SalesAdjustment/Modal/SalesAdjustmentDetailModal";
import GeneralInputWithClickableUI from "../../app/components/form/GeneralInputWithClickableUI";
import { isTablet } from "react-device-detect";

interface Props {
  id: string;
  blnShow: boolean;
  setModal: Function;
}

const AuditLogDetail = (props: Props) => {
  //Use Store
  const { auditLogStore, customerAccessRequestStore, exchangeToCreditStore, creditAdjustmentStore, customerStore, customerPaymentAdjustmentStore, extendPackageStore, leadStore, salesAdjustmentStore, commonStore } = useStore();

  //Use INTL
  const intl = useIntl();

  const {
    errorMessage,
    successMessage,
    loading,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { auditLogDetail, setAuditLogDetail, getAuditLogWithId } = auditLogStore;
  const { getCustomerAccessRequestWithId } = customerAccessRequestStore;
  const { getExchangeToCreditWithId } = exchangeToCreditStore;
  const { customerNoteDetail, setCustomerNoteDetail, getCustomerNoteWithId } = customerStore;
  const { creditAdjustmentDetail, getCreditAdjustmentWithId } = creditAdjustmentStore;
  const { getCustomerPaymentAdjustmentWithId } = customerPaymentAdjustmentStore;
  const { getExtendPackageWithId } = extendPackageStore;
  const { getFollowUpWithId, followUpDetail, setFollowUpDetail } = leadStore;
  const { getSalesAdjustmentWithId } = salesAdjustmentStore;
  const [auditLogCompareValues, setAuditLogCompareValues] = useState<AuditLogCompareValue[]>([]);
  const displayTitle = `${intl.formatMessage({ id: "View" })} ${intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "AuditLog" }) })}`;
  const disabledFieldInput = true;
  const [modalLoading, setModalLoading] = useState(false);

  const [exchangeToCreditDetailModal, setExchangeToCreditDetailModal] = useState(false);
  const [selectedExchangeToCreditDetail, setSelectedExchangeToCreditDetail] = useState<any>([]);
  const [customerAccessRequestDetailModal, setCustomerAccessRequestDetailModal] = useState(false);
  const [selectedCustomerAccessRequestDetail, setSelectedCustomerAccessRequestDetail] = useState<any>([]);
  const [creditAdjustmentModal, setCreditAdjustmentModal] = useState(false);
  const [customerNoteModal, setCustomerNoteModal] = useState(false);
  const [paymentAdjustmentDetailModal, setPaymentAdjustmentDetailModal] = useState(false);
  const [selectedCustomerPaymentAdjustmentDetail, setSelectedCustomerPaymentAdjustmentDetail] = useState<any>([]);
  const [extendPackageDetailModal, setExtendPackageDetailModal] = useState(false);
  const [selectedExtendPackageDetail, setSelectedExtendPackageDetail] = useState<any>([]);
  const [followUpHistoryModal, setFollowUpHistoryModal] = useState(false);
  const [salesAdjustmentDetailModal, setSalesAdjustmentDetailModal] = useState(false);
  const [selectedSalesAdjustmentDetail, setSelectedSalesAdjustmentDetail] = useState<any>([]);
  
  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: auditLogDetail || {
      entityPropertiesChanged: [],
      oldValues: "",
      newValues: "",
      id: "",
      auditType: "",
      action: "",
      entityChanged: "",
      entityId: "",
      createdAt: "",
      createdBy: "",
      updatedAt: ""
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {}
  });

  //Table Content UI
  const AuditLogColumns = [
    {
      dataField: "fieldName",
      text: intl.formatMessage({ id: "Field" }).toUpperCase()
    },
    {
      dataField: "oldValue",
      text: intl.formatMessage({ id: "OldValues" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{overflowWrap: "anywhere"}}>
          {row.oldValue}
        </div>
      ),
    },
    {
      dataField: "newValue",
      text: intl.formatMessage({ id: "NewValues" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div style={{overflowWrap: "anywhere"}}>
          {row.newValue}
        </div>
      ),
    }
  ];

  useEffect(() => {
    async function fetchAuditLogDetailAPI() {
      setLoading(true);
      if (props.id) {
        await getAuditLogWithId(props.id);
      }
      setLoading(false);
    }

    if (!props.id) {
      setAuditLogDetail(undefined);
    }

    fetchAuditLogDetailAPI();

    return (() => {
      if (props.id) {
        setAuditLogDetail(undefined);
      }
    })
  }, []);

  useEffect(()=> {
    if (auditLogDetail) {
      validation.setFieldValue("entityChanged", newSpaceBeforeCapitalLetter(auditLogDetail.entityChanged))
      validation.setFieldValue("createdAt", moment(auditLogDetail.createdAt).format(Constants.displayDateAndTimeFormat))
      
      let oldValuesTemp = JSON.parse(auditLogDetail.oldValues);
      let newValuesTemp = JSON.parse(auditLogDetail.newValues);
      let entityPropertiesChangedTemp = auditLogDetail.entityPropertiesChanged;
      let auditLogCompareValuesTemp : AuditLogCompareValue[] = []; 
      for (var a=0; a<entityPropertiesChangedTemp.length; a++) {
        let oldValueDisplay = typeof(oldValuesTemp[entityPropertiesChangedTemp[a]]) === 'object' ? JSON.stringify(oldValuesTemp[entityPropertiesChangedTemp[a]]) : oldValuesTemp[entityPropertiesChangedTemp[a]]; 
        let newValueDisplay = typeof(newValuesTemp[entityPropertiesChangedTemp[a]]) === 'object' ? JSON.stringify(newValuesTemp[entityPropertiesChangedTemp[a]]) : newValuesTemp[entityPropertiesChangedTemp[a]]; 
        auditLogCompareValuesTemp.push({
          fieldName: entityPropertiesChangedTemp[a],
          oldValue: oldValueDisplay || Constants.emptyData,
          newValue: newValueDisplay || Constants.emptyData
        })
      }

      setAuditLogCompareValues(auditLogCompareValuesTemp);
    }
  }, [auditLogDetail])

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        dismissModal()
      }, Constants.timeoutNavigation)
    }
  }, [successMessage])

  const dismissModal = () => {
    props.setModal(false);
    setErrorMessage("");
    setSuccessMessage("");
  }

  const moduleHyperlinkValidation = (entityChanged, entityId) => {
    // current comission scheme included Package & Product, ignore first
    return (
      entityChanged !== 'Commission Scheme' && 
      entityChanged !== newSpaceBeforeCapitalLetter(Constants.dailySalesReportHistory) && 
      entityId &&
      entityId !== Constants.emptyId &&
      (entityChanged === Constants.customerAccessRequest || entityChanged === Constants.exchangeToCredit || entityChanged === Constants.customerCreditAdjustment 
        || entityChanged === Constants.customerNote || entityChanged === Constants.customerPaymentAdjustment || entityChanged === Constants.extendPackage
        || entityChanged === Constants.followUpHistory || entityChanged === Constants.salesAdjustmentHistory || returnRouteFromModule(entityChanged) !== undefined) 
    );
  }

  const viewModuleDetail = (entityChanged, entityId) => {
    if (entityChanged === Constants.customerAccessRequest) {
      onClickViewAccessRequestDetail(entityId);
    }
    else if (entityChanged === Constants.exchangeToCredit) {
      onClickViewExchangeToCreditDetail(entityId);
    }
    else if (entityChanged === Constants.customerCreditAdjustment) {
      onClickViewCreditAdjustment(entityId);
    }
    else if (entityChanged === Constants.customerNote) {
      onClickNoteAction(entityId);
    }
    else if (entityChanged === Constants.customerPaymentAdjustment) {
      onClickViewPaymentAdjustmentDetail(entityId);
    }
    else if (entityChanged === Constants.extendPackage) {
      onClickViewExtendPackageDetail(entityId);
    }
    else if (entityChanged === Constants.followUpHistory) {
      onClickFollowUpAction(entityId);
    }
    else if (entityChanged === Constants.salesAdjustmentHistory) {
      onClickViewSalesAdjustmentDetail(entityId);
    }
    // else if (entityChanged === Constants.dailySalesReportHistory) {
    //   window.open(`/${returnRouteFromModule(entityChanged)}`)
    // }
    else {
      if(returnRouteFromModule(entityChanged) !== undefined){
        window.open(`/${returnRouteFromModule(entityChanged)}/view/${validation.values.entityId}`)
      }
    }
  }

  const onClickViewAccessRequestDetail = async (customerAccessRequestId: any) => {
    setModalLoading(true);
    let resultAccessRequest = await getCustomerAccessRequestWithId(customerAccessRequestId);
    setSelectedCustomerAccessRequestDetail(resultAccessRequest)
    setCustomerAccessRequestDetailModal(true);
    setModalLoading(false);
  }

  const onClickViewExchangeToCreditDetail = async (exchangeToCreditId: any) => {
    setModalLoading(true);
    let resultExchangeToCredit = await getExchangeToCreditWithId(exchangeToCreditId);
    setSelectedExchangeToCreditDetail(resultExchangeToCredit)
    setExchangeToCreditDetailModal(true);
    setModalLoading(false);
  }

  const onClickViewCreditAdjustment = async (creditAdjustmentId: any) => {
    setModalLoading(true);
    await getCreditAdjustmentWithId(creditAdjustmentId)
    setCreditAdjustmentModal(true);
    setModalLoading(false);
  }

  const onClickNoteAction = async (noteId: any) => {
    setModalLoading(true);
    let resultCustomerNoteWithId = await getCustomerNoteWithId(noteId);
    if (resultCustomerNoteWithId?.status === Constants.success) {
      setCustomerNoteModal(true);
    }
    setModalLoading(false);
  }

  const onClickViewPaymentAdjustmentDetail = async (paymentAdjustmentId: any) => {
    setModalLoading(true);
    let resultPaymentAdjustment = await getCustomerPaymentAdjustmentWithId(paymentAdjustmentId);
    setSelectedCustomerPaymentAdjustmentDetail(resultPaymentAdjustment)
    setPaymentAdjustmentDetailModal(true);
    setModalLoading(false);
  }

  const onClickViewExtendPackageDetail = async (extendPackageId: any) => {
    setModalLoading(true);
    let resultExtendPackage = await getExtendPackageWithId(extendPackageId);
    setSelectedExtendPackageDetail(resultExtendPackage);
    setExtendPackageDetailModal(true);
    setModalLoading(false);
  }

  const onClickFollowUpAction = async (followUpId: any) => {
    setModalLoading(true);
    let resultFollowUpWithId = await getFollowUpWithId(followUpId);
    if (resultFollowUpWithId?.status === Constants.success) {
      setFollowUpHistoryModal(true);
    }
    setModalLoading(false);
  }

  const onClickViewSalesAdjustmentDetail = async (salesAdjustmentId: any) => {
    setModalLoading(true);
    let resultSalesAdjustmentDetail = await getSalesAdjustmentWithId(salesAdjustmentId);
    setSelectedSalesAdjustmentDetail(resultSalesAdjustmentDetail)
    setSalesAdjustmentDetailModal(true);
    setModalLoading(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={()=> {
        dismissModal();
      }}
      size="xl"
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="">
        <MetaTags>
          <title>{displayTitle}</title>
        </MetaTags>
        <Container>
          {(loading || modalLoading) && !successMessage ? (
            <div style={{ marginTop: '24px', marginBottom: '24px' }}>
              <Loading />
            </div>
          ) : (
            <Form
              className="standard-layout"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onBlur={() => {
                if (errorMessage || successMessage) {
                  setErrorMessage("");
                  setSuccessMessage("");
                }
              }}>
              <Row form={+true}>
                {" "}
                {/* +true = Convert boolean to numbers*/}
                <Col xl={12}>
                  <LineBreakWithTittle
                    paddingBottom="0px"
                    title={displayTitle}
                    h4Title />
                  <div className="mt-4"/>
                  <Row>
                    <Col xl="6">
                      {  
                        moduleHyperlinkValidation(auditLogDetail?.entityChanged, auditLogDetail?.entityId) && auditLogDetail?.auditType !== Constants.delete
                        ?
                        <GeneralInputWithClickableUI
                          title={intl.formatMessage({ id: "Module" })}
                          name="entityChanged"
                          type="text"
                          disabled={disabledFieldInput}
                          validationRequired={true}
                          validation={validation}
                          onClickFunction={()=> {
                            viewModuleDetail(removeAllSpace(validation.values.entityChanged), validation.values.entityId)
                          }}/>
                        :
                        <GeneralInput
                          title={intl.formatMessage({ id: "Module" })}
                          name="entityChanged"
                          type="text"
                          disabled={disabledFieldInput}
                          validationRequired={true}
                          validation={validation}/>
                      }
                    </Col>
                    <Col xl="6"> 
                      <GeneralInput
                        title={intl.formatMessage({ id: "Action" })}
                        name="auditType"
                        // className={"mt-4"}
                        type="text"
                        disabled={disabledFieldInput}
                        validationRequired={true}
                        validation={validation}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6">
                      <GeneralInput
                        title={intl.formatMessage({ id: "Date" })}
                        name="createdAt"
                        type="text"
                        validationRequired={true}
                        disabled={disabledFieldInput}
                        validation={validation}
                      />
                    </Col>
                    <Col xl="6">
                      <GeneralInput
                        title={intl.formatMessage({ id: "CreatedBy" })}
                        name="createdBy"
                        type="text"
                        validationRequired={true}
                        disabled={disabledFieldInput}
                        validation={validation}
                      />
                    </Col>
                  </Row>
                  <GeneralTextArea
                    title={intl.formatMessage({ id: "Remark"})}
                    name="action"
                    row={5}
                    disabled={disabledFieldInput}
                    validation={validation}/>
                  {
                    auditLogCompareValues.length > 0 
                    &&
                    validation.values.auditType !== Constants.delete
                    &&
                    <>
                      <Label>{intl.formatMessage({ id: "ChangesDetail"})}</Label>
                      <BootstrapTable
                        responsive
                        bordered={true}
                        striped={false}
                        classes={"table align-middle"}
                        headerClasses="table-light"
                        keyField="fieldName"
                        noDataIndication={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "AuditLog" }) })}
                        data={auditLogCompareValues || []}
                        columns={AuditLogColumns}
                      />
                    </>
                  }
                </Col>
              </Row>
              <GeneralSubmitAndCancelBtn
                className="flex-right"
                successMessage={successMessage}
                hidePrimaryButton={true}
                viewAction={false}
                validation={validation}
                cancelButtonFunction={() => dismissModal()}
              />
            </Form>
          )}
          {
            customerAccessRequestDetailModal &&
            <CustomerAccessRequestDetailModal
              blnShow={customerAccessRequestDetailModal}
              setModal={setCustomerAccessRequestDetailModal}
              selectedCustomerAccessRequestDetail={selectedCustomerAccessRequestDetail}
              setSelectedCustomerAccessRequestDetail={setSelectedCustomerAccessRequestDetail}
            />
          }
          {
            exchangeToCreditDetailModal &&
            <ExchangeToCreditDetailModal
              blnShow={exchangeToCreditDetailModal}
              setModal={setExchangeToCreditDetailModal}
              selectedExchangeToCreditDetail={selectedExchangeToCreditDetail}
              setSelectedExchangeToCreditDetail={setSelectedExchangeToCreditDetail}
            />
          }
          {
            creditAdjustmentModal &&
            <CreditAdjustmentModal
              blnShow={creditAdjustmentModal}
              setModal={setCreditAdjustmentModal}
              selectedCreditAdjustmentDetail={creditAdjustmentDetail}
              blnView={true}
              setErrorMessage={setErrorMessage} />
          }
          {
            customerNoteModal
            &&
            <CustomerNoteModal
              blnShow={customerNoteModal}
              blnAction={Constants.view}
              setModal={setCustomerNoteModal}
              selectedCustomerNoteDetail={customerNoteDetail}
              setSelectedCustomerNoteDetail={setCustomerNoteDetail} />
          }
          {
            paymentAdjustmentDetailModal &&
            <PaymentAdjustmentDetailModal
              blnShow={paymentAdjustmentDetailModal}
              setModal={setPaymentAdjustmentDetailModal}
              selectedCustomerPaymentAdjustmentDetail={selectedCustomerPaymentAdjustmentDetail}
              setSelectedCustomerPaymentAdjustmentDetail={setSelectedCustomerPaymentAdjustmentDetail}
            />
          }
          {
            extendPackageDetailModal &&
            <ExtendPackageDetailModal
              blnShow={extendPackageDetailModal}
              setModal={setExtendPackageDetailModal}
              selectedExtendPackageDetail={selectedExtendPackageDetail}
              setSelectedExtendPackageDetail={setSelectedExtendPackageDetail}
              blnAction={Constants.review}
            />
          }
          {
            followUpHistoryModal &&
            <FollowUpHistoryModal
              blnShow={followUpHistoryModal}
              blnAction={Constants.view}
              setModal={setFollowUpHistoryModal}
              selectedFollowUpDetail={followUpDetail}
              setFollowUpDetail={setFollowUpDetail}
              setErrorMessage={setErrorMessage} />
          }
          {
            salesAdjustmentDetailModal &&
            <SalesAdjustmentDetailModal
              blnShow={salesAdjustmentDetailModal}
              setModal={setSalesAdjustmentDetailModal}
              blnAction={Constants.view}
              selectedSalesAdjustmentDetail={selectedSalesAdjustmentDetail}
              setSelectedSalesAdjustmentDetail={setSelectedSalesAdjustmentDetail}
              blnAllowCrossMonthSales={false} />
          }
        </Container>
      </div>
    </Modal>
  );
};

export default observer(AuditLogDetail);
