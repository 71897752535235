import { PaginationRequestBody } from "../models/pagination";
import { SalesCategoryListObject, SalesCategoryAddObject, SalesCategoryUpdateObject, SalesCategoryDetailObject } from "../models/salesCategory";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class salesCategoryStore {
  salesCategoryList: SalesCategoryListObject[] = [];
  salesCategoryDropdownList: SalesCategoryListObject[] = [];
  salesCategoryDetail: SalesCategoryDetailObject | undefined = undefined;
  salesCategoryPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.salesCategoryList = [];
    this.salesCategoryDropdownList = [];
    this.salesCategoryDetail = undefined;
    this.salesCategoryPaginationParams= undefined;
  }

  setSalesCategoryDetail = (salesCategoryDetail: SalesCategoryDetailObject | undefined) => {
    this.salesCategoryDetail = salesCategoryDetail;
  }

  setSalesCategoryPaginationParams = (salesCategoryPaginationParams: PaginationRequestBody | undefined) => {
    this.salesCategoryPaginationParams = salesCategoryPaginationParams;
  }
  
  getSalesCategory = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setSalesCategoryPaginationParams(PaginationRequestBody);
    try{
      const resultSalesCategory = await agent.SalesCategory.salesCategoryList(PaginationRequestBody);
      runInAction(() => {
        this.salesCategoryList = resultSalesCategory.data;
        store.commonStore.setTotalItem(resultSalesCategory.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesCategoryList = [];
    }
  }

  getSalesCategoryDropdown = async () => {
    try{
      const resultSalesCategoryDropdown = await agent.SalesCategory.salesCategoryDropdownList();
      runInAction(() => {
        this.salesCategoryDropdownList = resultSalesCategoryDropdown;
      });
      return Promise.resolve(resultSalesCategoryDropdown)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesCategoryDropdownList = [];
    }
  }

  getSalesCategoryWithId = async (id: string) => {
    try{
      const resultSalesCategoryDetail = await agent.SalesCategory.salesCategoryDetail(id);
      runInAction(() => {
        this.salesCategoryDetail = resultSalesCategoryDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.salesCategoryDetail = undefined;
    }
  }

  addSalesCategory = async (salesCategoryRequestBody: SalesCategoryAddObject) => {
    try{
      await agent.SalesCategory.addSalesCategory(salesCategoryRequestBody);
      store.commonStore.setSuccessMessage(`SalesCategoryAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateSalesCategory = async (salesCategoryRequestBody: SalesCategoryUpdateObject) => {
    try{
      await agent.SalesCategory.updateSalesCategory(salesCategoryRequestBody);
      store.commonStore.setSuccessMessage(`SalesCategoryUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteSalesCategory  = async (id: string, name: string) => {
    try {
      await agent.SalesCategory.deleteSalesCategory(id);
      store.commonStore.setSuccessMessage(`SalesCategoryDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}