import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";
import { getFileExtension } from "../../common/function/function";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { Constants } from "../../constants/Constants";
import CloseIcon from 'mdi-react/CloseIcon';
interface Props {
	name: string;
	validation?: any;
	setNewImageUrl: Function;
	disabled: boolean;
	imageUrl?: string;
  blnExcelFormat?: boolean;
  setErrorMessage?: Function;
}

export default observer(function DropZoneUploadWidget(props: Props) {
	const [files, setFiles] = useState<any>([]);
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;

	useEffect(() => {
    if (files.length > 0) {
      if (props.validation) {
        props.validation.setFieldValue(props.name, files[0].preview);
      }
      props.setNewImageUrl(files[0].preview);
    }

    return () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    };
	}, [files]);

  useEffect(()=> {
    if (successMessage) {
      setTimeout(()=> {
        setFiles([]);
        props.setNewImageUrl("");
      }, Constants.timeoutNavigation+100)
    }
  }, [successMessage])

	const dzStyles = {
		border: "dashed 3px #eee",
		borderColor: "#eee",
		borderRadius: "5px",
		paddingTop: "30px",
		marginBottom: "20px",
		textAlign: "center" as "center",
		height: props.blnExcelFormat ? 150 : 300,
	};

	const dzActive = {
		borderColor: "green",
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
      if (props.blnExcelFormat) {
        if (acceptedFiles.length > 0) {
          if (getFileExtension(acceptedFiles[0].path).toLowerCase() !== 'xlsx' && getFileExtension(acceptedFiles[0].path).toLowerCase() !== 'xlsm') {
            if (props.setErrorMessage) {
              props.setErrorMessage(intl.formatMessage({ id: "FileTypeNotAccepted" }))
            }
            return;
          }
        }
      }
			setFiles(
				acceptedFiles.map((file: any) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
		[setFiles]
	);

	const { getRootProps, getInputProps, isDragActive, isDragReject } =
		useDropzone({
			onDrop,
		});

	return (
    <>
      <div
        {...!props.disabled ? getRootProps() : {}}
        style={{
          ...(isDragActive ? { ...dzStyles, ...dzActive } : dzStyles),
          position: "relative",
        }}
      >
        <input {...getInputProps()} />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100%",
            width: "100%",
            display: "flex",
            position: "absolute",
            top: "0px",
          }}
        >
          {
            files.length < 1 && !props.imageUrl 
            ?
            <div>
              {!isDragActive && intl.formatMessage({ id: "ClickHereOrDropFile" })}
              {isDragActive && !isDragReject && intl.formatMessage({ id: "DropItLikeItHot" })}
              {isDragReject && intl.formatMessage({ id: "FileTypeNotAccepted" })}
            </div>
            : 
            props.blnExcelFormat
            ?
            files.length > 0 && props.blnExcelFormat
            &&
            <h5 className="primary-color margin-top-16" style={{wordBreak: "break-all"}}>{`${intl.formatMessage({ id: "FileUploaded" })} ${files[0].path}`}</h5>
            :
            <div>
              <img
                style={{ height: "300px", width: "100%"}}
                src={
                  files.length > 0
                    ? files[0].preview
                    : props.imageUrl
                }
              />
            </div>
          }
        </div>
        {
          files.length > 0 && !props.disabled
          &&
          <button
            type="button"
            key={`button_key_delete_image`}
            className={`btn btn-danger flex-justfity-content-center flex-align-items-center-without-height`}
            style={{
              width: '40px',
              height: '40px',
              position: 'absolute',
              right: '10px',
              top: '10px',
              borderRadius:'20px'
            }}
            onClick={() => {
              props.setNewImageUrl("");
              if (props.validation) {
                props.validation.setFieldValue(props.name, undefined);
              }
              setFiles([]);
            }}>
            {/* <i className="mdi mdi-close font-size-20"></i> */}
            <CloseIcon />
          </button>
        }
      </div>
    </>
	);
})