import { useEffect, useState } from "react";
import { Col, Input, Label, Modal, Row } from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../../app/constants/Constants";
import { useStore } from "../../../app/stores/store";
import MyButton from "../../../app/components/form/MyButton";
import { observer } from 'mobx-react-lite';
import { ObjectForTableWithDynamicInputAndCheckBox } from "../../../app/models/common";
import { useFormik } from "formik";
import * as Yup from "yup";
import TableWithEditableInputFields from "../../../app/components/table/TableWithEditableInputFields";
import LineBreakWithTittle from "../../../app/components/form/LineBreakWithTittle";
import moment from "moment";
import { EmployeeBranchTransferHistory, UpdateEmployeeBranchTransferHistoryObject } from "../../../app/models/employee";
import { BranchListObject } from "../../../app/models/branch";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  updateEmployeeBranchTransferHistory: Function;
  validation: any;
  setRefreshBranchTransferHistoryData: Function;
  branchDropdownList: BranchListObject[];
  blnPermissionReassignEmployeeCustomer: boolean;
}

export default observer(function UpdateBranchTransferHistoryModal(props: Props) {
  const intl = useIntl();
  //Use Store
  const { commonStore } = useStore();
  const { setErrorMessage } = commonStore;
  const [localSuccessMessage, setLocalSuccessMessage] = useState(false);
  const [loadingUpdateBranchTransfer, setLoadingUpdateBranchTransfer] = useState(false);
  const isMinStartDateAllowed = props.validation.values.branchTransferHistories.length > 1;

	const branchTransferDateOnChange = () => {
		let branchTransferHistoriesTemp:EmployeeBranchTransferHistory[] = _.cloneDeep(validationLocal.values.branchTransferHistories);

		branchTransferHistoriesTemp[0].isStartDateUpdated = true;
    if(branchTransferHistoriesTemp.length > 1){
      branchTransferHistoriesTemp[1].displayEndDate = `${moment(props.validation.values.branchTransferHistories[1].endDate).format(Constants.displayInputDateFormat)}  →  ${moment(branchTransferHistoriesTemp[0].startDate).subtract(1, 'days').format(Constants.displayInputDateFormat)}`;
      branchTransferHistoriesTemp[1].endDate = moment(branchTransferHistoriesTemp[0].startDate).subtract(1, 'days').format(Constants.displayDateFormat);
      branchTransferHistoriesTemp[1].backgroundColor = Constants.lightorange;
    }
    validationLocal.setFieldValue("branchTransferHistories", branchTransferHistoriesTemp)
	}

	const aryDynamicInputAndCheckBoxBranchTransferHistoriesTab: ObjectForTableWithDynamicInputAndCheckBox[] =
		[
      {
        label: intl.formatMessage({ id: "BranchName" }).toUpperCase(),
        type: "select",
        xl: "4",
        inputName: "branchName",
        inputType: "select",
        optionsLocalField: { fieldName: "branchDropdownList", label: "name", value: "id"},
        displayLabel: "branchName",
        displayValue: "branchId",
        specifyReturnFieldName: [{ field: "branchName", value: "name" }, { field: "branchId", value: "id" }],
        validationRequired: true,
        disabledField: "branchFieldDisable"
      },
			{
				label: intl.formatMessage({ id: "StartDate" }),
				type: "input",
				xl: "4",
				inputName: "startDate",
				inputType: "date",
        validationRequired: true,
        disabled: props.validation.values.branchTransferHistories.length > 1 ? false : true,
				onChangeFunction: branchTransferDateOnChange,
			},
			{
				label: intl.formatMessage({ id: "EndDate" }),
				type: "input",
				xl: "4",
				inputName: "displayEndDate",
				inputType: "text",
				inputValueForSuccessColor: Constants.present,
        supportInputBackgroundColor: true,
        valueOnTime: true,
				disabled: true
			},
		];

  const validationLocal = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchTransferHistories: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(()=> {
    if(props.validation.values){
      let branchTransferHistoriesTemp = props.validation.values.branchTransferHistories.map((valueBranchTransferHistories, indexBranchTransferHistories)=> ({
        ...valueBranchTransferHistories,
        branchDropdownList: props.branchDropdownList,
        branchFieldDisable: indexBranchTransferHistories === 0 ? false : true,
      }))
      validationLocal.setFieldValue("branchTransferHistories", branchTransferHistoriesTemp)
    }
  }, [])

  const hideModal = () => {
    props.setModal(false);
  }

  const fetchUpdateEmployeeBranchTransferHistoryAPI = async () => {
    let branchTransferHistoriesTemp : EmployeeBranchTransferHistory[] = _.cloneDeep(validationLocal.values.branchTransferHistories);
    let updateEmployeeBranchTransferHistoryObject : UpdateEmployeeBranchTransferHistoryObject = {
      id: branchTransferHistoriesTemp[0].id,
      employeeId: branchTransferHistoriesTemp[0].employeeId,
      branchId: branchTransferHistoriesTemp[0].branchId,
      startDate: branchTransferHistoriesTemp[0].startDate,
      isBypassReassignCustomerValidation: !props.blnPermissionReassignEmployeeCustomer,
    }

    setLoadingUpdateBranchTransfer(true);
    let resultAPI = await props.updateEmployeeBranchTransferHistory(updateEmployeeBranchTransferHistoryObject);
    if (resultAPI.status === Constants.success) {
      setLocalSuccessMessage(true)
      setTimeout(() => {
        props.setModal(false);
        props.setRefreshBranchTransferHistoryData(true);
      }, Constants.timeoutNavigation)
    }
    setLoadingUpdateBranchTransfer(false);
  }

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        if (!loadingUpdateBranchTransfer && !localSuccessMessage) {
          hideModal();
        }
      }}
      size='xl'
      style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: "UpdateBranchTransferHistory" })}</h5>
        {
          !loadingUpdateBranchTransfer && !localSuccessMessage
          &&
          <button
            type="button"
            onClick={() => { hideModal(); }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        }
      </div>
      <div className="modal-body">
        <div className="standard-layout">
          <LineBreakWithTittle
            paddingBottom="0px"
            title={intl.formatMessage({ id: "BranchTransferHistories" })}
            h4Title />
          <TableWithEditableInputFields
            name="branchTransferHistories"
            title=""
            buttonTitle={""}
            blnEditable={true}
            aryDynamicInputAndCheckBoxTab={aryDynamicInputAndCheckBoxBranchTransferHistoriesTab}
            data={validationLocal.values.branchTransferHistories.slice(0,2)}
            validation={validationLocal}
            disabled={loadingUpdateBranchTransfer || Boolean(localSuccessMessage)}
            hideAddButton={true}
            hideDeleteButton={true}
            blnDisabledCustomizedTableScrolling={true}
            minCurrentDate={isMinStartDateAllowed ? true : false}
            minDateValue={isMinStartDateAllowed ? moment(props.validation.values.branchTransferHistories[1].startDate).add(1, 'days').format(Constants.displayDateFormat) : ""} 
            maxDateValue={isMinStartDateAllowed ? moment(props.validation.values.branchTransferHistories[0].endDate).format(Constants.displayDateFormat) : ""} 
          />
        </div>
        <div className="mb-3 mt-4 flex-right">
          {
            !Boolean(localSuccessMessage)
            &&
            <MyButton
              type="button"
              class="btn btn-success"
              style={{ width: '20%' }}
              content={intl.formatMessage({ id: "Confirm" })}
              onClick={() => {
                // let blnErrorFound = false;
                // let branchTransferHistoriesTemp : EmployeeBranchTransferHistory[] = _.cloneDeep(validationLocal.values.branchTransferHistories);
                // if(branchTransferHistoriesTemp && branchTransferHistoriesTemp.length > 0){
                //   if (!branchTransferHistoriesTemp[0].isStartDateUpdated) {
                //     blnErrorFound = true;
                //   }
                // }

                // if (!blnErrorFound) {
                  fetchUpdateEmployeeBranchTransferHistoryAPI()
                // }
                // else {
                //   setErrorMessage(`${(intl.formatMessage({ id: "NoNewValueDetectedForFieldName" }, { field: intl.formatMessage({ id: "StartDate" }) }))}`)
                //   return;
                // }
              }}
              loading={loadingUpdateBranchTransfer}
              disable={loadingUpdateBranchTransfer || Boolean(localSuccessMessage)}
            />
          }
        </div>
      </div>
    </Modal>
  )
});