import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, paymentStatusColor, returnPriceWithCurrency, totalCountStatusColor } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import MyButton from "../../../app/components/form/MyButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom"
import moment from "moment";
import GeneralInput from "../../../app/components/form/GeneralInput";
import GeneralStatus from "../../../app/components/form/GeneralStatus";
import { DateRangeInterface } from "../../../app/models/common";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";

const ExpiringPackage = () => {
  const intl = useIntl();
  //Use Store
  const { reportCustomerStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { expiringPackageList, getExpiringPackageList, setExpiringPackageList, exportExpiringPackage } = reportCustomerStore;
  const { branchDropDownList, getBranchDropDown } = branchStore;

  //Data List
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionExportCustomerReport = checkPermission([PermissionConstants.ExportCustomerReport]);
  const blnPermissionViewCustomer = checkPermission([PermissionConstants.ManageCustomer]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchCollectionsSummary() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      await Promise.all(aryAPI);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageCustomerReport], true)) {
      return;
    }

    fetchCollectionsSummary();

    return (() => {
      setExpiringPackageList([]);
    })
  }, [])

  const fetchExpiringPackageWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) }))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Date"})}))
      return;
    }

    setLocalLoading(true);
    if (blnExport) {
      await exportExpiringPackage({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange })
    }
    else { 
      setCurrentPage(1);
      await getExpiringPackageList({ pageNumber: 1, pageSize: pageSize, branchId: selectedBranchId, dateRange: selectedDateRange });
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  //Table Content UI
  const ExpiringPackageDetailsColumns = [
    {
      dataField: "customerOrderId", //field name from array to display
      text: "customerOrderId", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
    },
    {
      dataField: "customerOrderNo",
      text: intl.formatMessage({ id: "CustomerOrderNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <Link to={row.customerOrderId ? `/${RoutesList.customerSalesOrder}/view/${row.customerOrderId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
            <Label className="margin-bottom-0 margin-left-4 pointer-clickable">{row.customerOrderNo || Constants.emptyData}</Label>
          </Link>
        </>
      ),
    },
    {
      dataField: "purchaseDate",
      text: intl.formatMessage({ id: "Date" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.purchaseDate).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.purchaseDate).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Customer" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          {
            blnPermissionViewCustomer
            ?
            <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.supportBlank} rel="noopener noreferrer">
              <Label className="margin-bottom-0 pointer-clickable">{row.customerName || Constants.emptyData}</Label>
            </Link>
            :
            <div>{row.customerName}</div>
          }          
        </>
      ),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "packageName",
      text: intl.formatMessage({ id: "Package" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {row.packageName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "remainingSessions",
      text: intl.formatMessage({ id: "BalSessions" }).toUpperCase(),
      // headerStyle: { textAlign: "center" },
      // formatter: (cellContent, row) => (
      //   <GeneralStatus
      //     status={row.remainingSessions}
      //     statusColorFunction={totalCountStatusColor}/>
      // ),
    },
    {
      dataField: "remainingProductCost",
      text: intl.formatMessage({ id: "BalProductCost" }).toUpperCase(),
      // headerStyle: { textAlign: "center" },
      // formatter: (cellContent, row) => (
      //   <GeneralStatus
      //     status={row.remainingProductCost}
      //     statusColorFunction={totalCountStatusColor}/>
      // ),
    },
    {
      dataField: "remainingFreeGift",
      text: intl.formatMessage({ id: "BalFreeGift" }).toUpperCase(),
      // headerStyle: { textAlign: "center" },
      // formatter: (cellContent, row) => (
      //   <GeneralStatus
      //     status={row.remainingFreeGift}
      //     statusColorFunction={totalCountStatusColor}/>
      // ),
    },
    {
      dataField: "remainingBalance",
      text: intl.formatMessage({ id: "RemainingBalance" }).toUpperCase(),
      // headerStyle: { textAlign: "center" },
      // formatter: (cellContent, row) => (
      //   <GeneralStatus
      //     status={row.remainingBalance}
      //     statusColorFunction={totalCountStatusColor}/>
      // ),
    },
    {
      dataField: "expiredOn",
      text: intl.formatMessage({ id: "ExpiredDate" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <>
          <div className="">
            {`${moment(row.expiredOn).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.expiredOn).format(Constants.displayTimeFormat)}
          </div>
        </>
      ),
    },
    {
      dataField: "paymentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.paymentStatus}
          statusColorFunction={paymentStatusColor}/>
      )
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "ExpiringPackage" }) })}
        title={intl.formatMessage({ id: "ExpiringPackage" })}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportCustomerReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchExpiringPackageWithLoading(true)
            }}
          />
        }>
        <Row>
          {
            // !getBranchUser()
            // &&
            <Col xl="3">
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchDropDownList}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading || getBranchUser()}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                validationRequired={true}
              />
            </Col>
          }
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "ExpiredDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchExpiringPackageWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <>
              <PaginationTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                totalItem={totalItem}
                tableColumns={ExpiringPackageDetailsColumns}
                data={expiringPackageList}
                emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "ExpiringPackage" }) })}
                objSorted={defaultSorted}
                branchId={selectedBranchId}
                dateRange={selectedDateRange}
                keyField={"customerOrderId"}
                requestAPI={getExpiringPackageList}
                hideSearchBar={true} />
            </>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(ExpiringPackage);
