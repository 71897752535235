import { useEffect, useState } from "react";
import {
  Modal,
  Label,
  FormFeedback,
  Row,
  Col
} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useStore } from "../../../app/stores/store";
import { AddOptionsValueField, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, removeAllSpace, returnCurrency, returnSubBrandList, returnThousandSeperator } from "../../../app/common/function/function";
import { BranchListObject } from "../../../app/models/branch";
import { SalesAdjustmentAddObject } from "../../../app/models/salesAdjustment";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";
import DoubleColumnRowParent from "../../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../../app/components/form/GeneralInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { Constants } from "../../../app/constants/Constants";
import Borderline from "../../../app/components/form/BorderLine";
import Loading from "../../../app/components/loading/Loading";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import { SubBrandObject } from "../../../app/models/brand";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  branchDropDownList?: BranchListObject[];
  addSalesAdjustment?: Function;
  updateSalesAdjustment?: Function;
  blnAction: string;
  selectedSalesAdjustmentDetail: any;
  setSelectedSalesAdjustmentDetail: Function;
  createSalesAdjustmentType?: StaticReferenceDataObject[];
  blnAllowCrossMonthSales: boolean;
}

export default function SalesAdjustmentDetailModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [selectedSubBrandName, setSelectedSubBrandName] = useState("");
  const [selectedSubBrandId, setSelectedSubBrandId] = useState("");
  const [loadingSubmitSalesAdjustment, setLoadingSubmitSalesAdjustment] = useState(false);
  const defaultSalesAdjustmentDetails = {
    branchId: selectedBranchId,
    branchName: selectedBranchName,
    salesAdjustmentType: "",
    amount: 0,
    productAmount: 0,
    productCommission: 0,
    remark: "",
    performedDateTime: "",
    subBrandId: selectedSubBrandId,
    subBrandName: selectedSubBrandName
  }
  const [salesAdjustmentDetails, setSalesAdjustmentDetails] = useState(defaultSalesAdjustmentDetails)
  const [errorState, setErrorState] = useState({
    branchId: false,
    salesAdjustmentType: false,
    amount: false,
    performedDateTime: false,
    subBrandId: false
  })

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      branchName: "",
      salesAdjustmentType: "",
      salesAdjustmentNo: "",
      performedDateTime: "",
      subBrandName: Constants.emptyData,
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  useEffect(() => {
    if (props.selectedSalesAdjustmentDetail ) {
      if(props.blnAction === Constants.view || props.blnAction === Constants.update){
        validation.setFieldValue("branchName", props.selectedSalesAdjustmentDetail.branchName);
        validation.setFieldValue("salesAdjustmentType", newSpaceBeforeCapitalLetter(props.selectedSalesAdjustmentDetail.salesAdjustmentType));
        validation.setFieldValue("salesAdjustmentNo", props.selectedSalesAdjustmentDetail.salesAdjustmentNo);
        validation.setFieldValue("performedDateTime", moment(props.selectedSalesAdjustmentDetail.performedDateTime).format(Constants.displayDateFormat));
      }
      onChangeBranch(props.selectedSalesAdjustmentDetail.branchId)

      if(props.selectedSalesAdjustmentDetail.subBrandId){
        onChangeSubBrand(props.selectedSalesAdjustmentDetail.subBrandId)
      }
      
      setSalesAdjustmentDetails({
        ...props.selectedSalesAdjustmentDetail, 
        amount: props.blnAction === Constants.view ? returnThousandSeperator(props.selectedSalesAdjustmentDetail.amount) : props.blnAction === Constants.add ? "" : Math.abs(props.selectedSalesAdjustmentDetail.amount).toFixed(2), 
        productAmount: props.blnAction === Constants.view ? returnThousandSeperator(props.selectedSalesAdjustmentDetail.productAmount) : props.blnAction === Constants.add ? "" : Math.abs(props.selectedSalesAdjustmentDetail.productAmount).toFixed(2), 
        productCommission: props.blnAction === Constants.view ? returnThousandSeperator(props.selectedSalesAdjustmentDetail.productCommission) : props.blnAction === Constants.add ? "" : Math.abs(props.selectedSalesAdjustmentDetail.productCommission).toFixed(2)
        })
      }
  }, [props.selectedSalesAdjustmentDetail])

  useEffect(() => {
    if (validation.values.performedDateTime) {
      setSalesAdjustmentDetails((values) => ({
        ...values,
        performedDateTime: validation.values.performedDateTime
      }))
      clearErrorState("performedDateTime");
    }
  }, [validation.values.performedDateTime])

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      branchId: false,
      salesAdjustmentType: false,
      amount: false,
      performedDateTime: false,
      subBrandId: false
    });
  }
  const hideModal = () => {
    clearErrorState();
    validation.resetForm();
    setSalesAdjustmentDetails(defaultSalesAdjustmentDetails);
    props.setSelectedSalesAdjustmentDetail([]); 
    props.setModal(false);
  }

  const blnViewAction = props.blnAction == Constants.view;
  const blnUpdateAction = props.blnAction == Constants.update;
  const blnAddAction = props.blnAction == Constants.add;
  const blnIsShowProductCommission = props.selectedSalesAdjustmentDetail.isShowProductCommission; 
  const blnHasSubBrand = returnSubBrandList().length > 0;

  const onChangeBranch = (id: string) => {
    if(props.branchDropDownList){
      let indexBranch = _.findIndex(props.branchDropDownList, { id: id });
      if (indexBranch > -1) {
        setSalesAdjustmentDetails((values) => ({
          ...values,
          branchId: props.branchDropDownList![indexBranch].id,
          branchName: props.branchDropDownList![indexBranch].name
        }))
        setSelectedBranchId(props.branchDropDownList[indexBranch].id)
        setSelectedBranchName(props.branchDropDownList[indexBranch].name)
        clearErrorState("branchId")
      }
    }
  }

  const onChangeSubBrand = (id: string) => {
    if(blnHasSubBrand){
      let subBrandListTemp = returnSubBrandList();
      let indexSubBrand = _.findIndex(subBrandListTemp, { id: id });
      if (indexSubBrand > -1) {
        setSalesAdjustmentDetails((values) => ({
          ...values,
          subBrandId: subBrandListTemp[indexSubBrand].id || "",
          subBrandName: subBrandListTemp[indexSubBrand].name || ""
        }))
        setSelectedSubBrandId(subBrandListTemp[indexSubBrand].id || "")
        setSelectedSubBrandName(subBrandListTemp[indexSubBrand].name)
        if(props.blnAction === Constants.view){
          validation.setFieldValue("subBrandName", subBrandListTemp[indexSubBrand].name )
        }
        clearErrorState("subBrandId")
      }
    }
  }

  const onChangeSalesAdjustmentType = (displayValue: string) => {
    setSalesAdjustmentDetails((values) => ({
      ...values,
      salesAdjustmentType: removeAllSpace(displayValue)
    }))
    clearErrorState("salesAdjustmentType")
  }

  const onClickAddSalesAdjustment = async (salesAdjustmentDetailTemp: SalesAdjustmentAddObject) => {
    setLoadingSubmitSalesAdjustment(true);
    if(blnAddAction && props.addSalesAdjustment){
      await props.addSalesAdjustment(salesAdjustmentDetailTemp);
    }
    else if(blnUpdateAction && props.updateSalesAdjustment){
      await props.updateSalesAdjustment(salesAdjustmentDetailTemp);
    }
    setLoadingSubmitSalesAdjustment(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingSubmitSalesAdjustment && !successMessage) {
            hideModal();
          }
        }}
        size={blnViewAction ? "lg" : "md"}
        style={{...isTablet && {width: '100%', maxWidth: '75%'}}}
        centered
      >
        {
          !props.selectedSalesAdjustmentDetail 
            ?
            <div style={{ padding: "20px" }}>
              <Loading />
            </div>
            :
            <div className="padding-horizontal-20px">
              <div className="modal-header">
                <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: blnAddAction ? "AddSalesAdjustment" :  blnUpdateAction ? "EditSalesAdjustment" : "SalesAdjustmentDetail" })}</h5>
                {
                  !loadingSubmitSalesAdjustment && !successMessage &&
                  <button
                  type="button"
                  onClick={() => {
                    hideModal();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                }
              </div>
              <div className="modal-body">
                {
                  blnViewAction &&
                  <>
                    <Row style={{ fontSize: '14px' }}>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "SalesAdjustmentNo" })}
                          name="salesAdjustmentNo"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "Branch" })}
                          name="branchName"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                    </Row>
                    <Row style={{ fontSize: '14px' }}>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "Date" })}
                          name="performedDateTime"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                      <DoubleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "Type" })}
                          name="salesAdjustmentType"
                          type="text"
                          disabled={true}
                          validationRequired={true}
                          validation={validation}
                        />
                      </DoubleColumnRowParent>
                    </Row>
                    {
                      blnHasSubBrand
                      &&
                      <Row style={{ fontSize: '14px' }}>
                        <DoubleColumnRowParent blnDoubleTab={true}>
                          <GeneralInput
                            title={intl.formatMessage({ id: "SubBrand" })}
                            name="subBrandName"
                            type="text"
                            disabled={true}
                            validationRequired={true}
                            validation={validation}
                          />
                        </DoubleColumnRowParent>
                      </Row>
                    }
                    <Borderline />
                  </>
                }
                {
                  !blnViewAction &&
                  <>
                    <Row>
                      <Col xl={`${blnHasSubBrand ? "6" : "12"}`} md={`${blnHasSubBrand ? "6" : "12"}`} xs="12">
                        <GeneralSelectDropdown
                          title={intl.formatMessage({ id: "Branch" })}
                          name="branchId"
                          className={errorState.branchId ? "mb-0" : "mb-3"}
                          options={props.branchDropDownList}
                          labelField={"name"}
                          valueField={"id"}
                          validationRequired={true}
                          initialLabel={selectedBranchName}
                          initialValue={selectedBranchId}
                          onChange={onChangeBranch}
                          disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || getBranchUser() || !blnAddAction}
                        />
                        {errorState.branchId ? (
                          <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Branch" }) })}</FormFeedback>
                        ) : null}
                      </Col>
                      {
                        blnHasSubBrand
                        &&
                        <Col xl="6" md="6" xs="12">
                          <GeneralSelectDropdown
                            title={intl.formatMessage({ id: "SubBrand" })}
                            name="subBrandName"
                            className={errorState.subBrandId ? "mb-0" : "mb-3"}
                            options={returnSubBrandList()}
                            labelField="name"
                            valueField="id"
                            validationRequired={true}
                            initialLabel={selectedSubBrandName}
                            initialValue={selectedSubBrandId}
                            onChange={onChangeSubBrand}
                            disabled={loadingSubmitSalesAdjustment || Boolean(successMessage)}
                          />
                          {errorState.subBrandId ? (
                          <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SubBrand" }) })}</FormFeedback>
                        ) : null}
                        </Col>
                      }
                    </Row>
                  </>
                }
                {
                  !blnViewAction &&
                  <>
                    {
                      blnAddAction
                      ?
                      <GeneralSelectDropdown
                        title={intl.formatMessage({ id: "SalesAdjustmentType" })}
                        name="salesAdjustmentType"
                        className={errorState.salesAdjustmentType ? "mb-0" : "mb-3"}
                        options={props.createSalesAdjustmentType}
                        labelField="displayValue"
                        valueField="displayValue"
                        validationRequired={true}
                        onChange={onChangeSalesAdjustmentType}
                        blnValueWithNewSpace={true}
                        disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || !blnAddAction}
                        />
                      :
                      <GeneralInput
                        title={intl.formatMessage({ id: "SalesAdjustmentType" })}
                        name="salesAdjustmentType"
                        className={"mb-3"}
                        type="text"
                        disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || !blnAddAction}
                        validationRequired={true}
                        validation={validation} />
                    }
                    {errorState.salesAdjustmentType ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesAdjustmentType" }) })}</FormFeedback>
                    ) : null}
                    <GeneralInput
                      title={intl.formatMessage({ id: "SalesAdjustmentDate" })}
                      name="performedDateTime"
                      className={errorState.performedDateTime ? "mb-0" : "mb-3"}
                      type="date"
                      minCurrentDate={!props.blnAllowCrossMonthSales}
                      minDateValue={props.blnAllowCrossMonthSales ? undefined : moment().startOf('month').format(Constants.displayDateFormat)}
                      maxDateOfTheMonth={true}
                      disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || !blnAddAction}
                      validationRequired={true}
                      validation={validation} />
                    {errorState.performedDateTime ? (
                      <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "SalesAdjustmentDate" }) })}</FormFeedback>
                    ) : null}
                  </>
                }
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "PackageSaleAmount" })}
                  name={"amount"}
                  className={errorState.amount ? "mb-0" : "mb-3"}
                  type={blnViewAction ? "text" : "number"}
                  inputGroup={true}
                  inputGroupText={returnCurrency()}
                  // validationRequired={true}
                  blnDisableNegativeValue={true}
                  field={salesAdjustmentDetails}
                  setFieldValue={setSalesAdjustmentDetails}
                  onChangeFunction={() => { clearErrorState("amount") }}
                  disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || blnViewAction}
                />
                {errorState.amount ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PackageSales" }) })}</FormFeedback>
                ) : null}
                <Row>
                  <Col xl={`${(blnAddAction || blnIsShowProductCommission) ? "6" : "12"}`} md={`${(blnAddAction || blnIsShowProductCommission) ? "6" : "12"}`} xs="12">
                    <GeneralInputForModal
                    title={intl.formatMessage({ id: "ProductSaleAmount" })}
                      name={"productAmount"}
                      className={"mb-3"}
                      type={blnViewAction ? "text" : "number"}
                      inputGroup={true}
                      inputGroupText={returnCurrency()}
                      validationRequired={false}
                      blnDisableNegativeValue={true}
                      field={salesAdjustmentDetails}
                      setFieldValue={setSalesAdjustmentDetails}
                      disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || blnViewAction}
                    />
                  </Col>
                  {
                    (blnAddAction || blnIsShowProductCommission)
                    &&
                    <Col xl="6" md="6" xs="12">
                      <GeneralInputForModal
                        title={intl.formatMessage({ id: "ProductCommission" })}
                        name={"productCommission"}
                        className={"mb-3"}
                        type={blnViewAction ? "text" : "number"}
                        inputGroup={true}
                        inputGroupText={returnCurrency()}
                        validationRequired={false}
                        blnDisableNegativeValue={true}
                        field={salesAdjustmentDetails}
                        setFieldValue={setSalesAdjustmentDetails}
                        disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || blnViewAction}
                      />
                    </Col>
                  }
                </Row>
                <GeneralTextAreaForModal
                  title={intl.formatMessage({ id: "Remark" })}
                  name="remark"
                  className={"mb-3"}
                  row={4}
                  validationRequired={false}
                  field={salesAdjustmentDetails}
                  setFieldValue={setSalesAdjustmentDetails}
                  disabled={loadingSubmitSalesAdjustment || Boolean(successMessage) || blnViewAction} />
                {
                  !blnViewAction
                  && 
                  !Boolean(successMessage)
                  &&
                  <div className="margin-top-14 flex-right">
                    <MyButton
                      type="button"
                      class="btn btn-primary"
                      content={intl.formatMessage({ id: "Submit" })}
                      onClick={() => {
                        let errorStateTemp = {
                          branchId: false,
                          amount: false,
                          salesAdjustmentType: false,
                          performedDateTime: false,
                          subBrandId: false
                        };
                        let blnErrorFound = false;
                        if (!salesAdjustmentDetails.branchId && blnAddAction) {
                          errorStateTemp.branchId = true;
                          blnErrorFound = true;
                        }
                        if ((!salesAdjustmentDetails.amount || isNaN(salesAdjustmentDetails.amount)) && (!salesAdjustmentDetails.productAmount || isNaN(salesAdjustmentDetails.productAmount)) && (!salesAdjustmentDetails.productCommission || isNaN(salesAdjustmentDetails.productCommission))) {
                            errorStateTemp.amount = true;
                            blnErrorFound = true;
                        }
                        if (!salesAdjustmentDetails.salesAdjustmentType) {
                          errorStateTemp.salesAdjustmentType = true;
                          blnErrorFound = true;
                        }
                        if (!salesAdjustmentDetails.performedDateTime) {
                          errorStateTemp.performedDateTime = true;
                          blnErrorFound = true;
                        }
                        if(blnHasSubBrand && !salesAdjustmentDetails.subBrandId){
                          errorStateTemp.subBrandId = true;
                          blnErrorFound = true;
                        }
                        if (blnErrorFound) {
                          setErrorState(errorStateTemp)
                        }
                        else {
                          if(isNaN(salesAdjustmentDetails.productAmount)){
                            salesAdjustmentDetails.productAmount = 0;
                          }
                          if(isNaN(salesAdjustmentDetails.productCommission)){
                            salesAdjustmentDetails.productCommission = 0;
                          }
                          onClickAddSalesAdjustment(salesAdjustmentDetails)
                        }
                      }}
                      disable={loadingSubmitSalesAdjustment || Boolean(successMessage) || blnViewAction}
                      loading={loadingSubmitSalesAdjustment}
                    />
                  </div>
                }
              </div>
            </div>
        }

      </Modal>
  )
}