import { useEffect, useState } from "react";
import {Modal, FormFeedback, Label} from "reactstrap";
import _ from 'lodash';
import MyButton from "../../../app/components/form/MyButton";
import { useIntl } from "react-intl";
import { useStore } from "../../../app/stores/store";
import { Constants } from "../../../app/constants/Constants";
import { RoutesList } from "../../../app/constants/RoutesList";
import GeneralTextAreaForModal from "../../../app/components/form/GeneralTextAreaForModal";
import { history } from '../../..';
import GeneralInputForModal from "../../../app/components/form/GeneralInputForModal";

interface Props {
  blnShow: boolean;
  blnAction: any;
  setModal: Function;
  customerId?: any;
  currentPage?: number;
  setCurrentPage?: Function;
  addCustomerNote?: Function;
  updateCustomerNote?: Function;
  getCustomerNote?: Function;
  selectedCustomerNoteDetail: any;
  setSelectedCustomerNoteDetail: Function;
  setErrorMessage?: Function;
  setLoadingNotes?: Function;
}

export default function CustomerNoteModal(props: Props) {
  const intl = useIntl();
  const { commonStore } = useStore();
  const { successMessage } = commonStore;
  const [loadingCustomerNote, setLoadingCustomerNote] = useState(false);

  const defaultCustomerNoteDetails = {
    id: "",
    customerId: props.customerId,
    note: "",
    isFollowUp: false
  }
  const [customerNoteDetails, setCustomerNoteDetails] = useState(defaultCustomerNoteDetails)
  const [errorState, setErrorState] = useState({
    note: false,
  })

  useEffect(() => {
    if (props.selectedCustomerNoteDetail) {
      setCustomerNoteDetails(props.selectedCustomerNoteDetail);
    }
  }, [props.selectedCustomerNoteDetail])

  useEffect(() => {
    async function getNoteList() {
      if(props.setLoadingNotes){
        props.setLoadingNotes(true);
        if(props.getCustomerNote){
          await props.getCustomerNote({ pageNumber: props.currentPage, pageSize: Constants.maxPageSize, customerId: props.customerId });
        }
        props.setLoadingNotes(false);
      }
    }

    if (successMessage) {
      setTimeout(() => {
        hideModal();
        getNoteList();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  const viewNoteAction = props.blnAction === Constants.view || Boolean(successMessage);
  const addNoteAction = props.blnAction === Constants.add;

  const clearErrorState = (fieldName?: string) => {
    if (fieldName) {
      setErrorState((value) => ({
        ...value,
        [fieldName]: false
      }));
      return;
    }

    setErrorState({
      note: false
    });
  }
  
  const hideModal = () => {
    clearErrorState();
    setCustomerNoteDetails(defaultCustomerNoteDetails);
    props.setSelectedCustomerNoteDetail(undefined);
    props.setModal(false);
  }

  const onClickCustomerNote = async (customerNoteDetailTemp: any) => {
    setLoadingCustomerNote(true);
    if(addNoteAction){
      if(props.addCustomerNote){
        await props.addCustomerNote(customerNoteDetailTemp);
      }
    }
    else{
      if(props.updateCustomerNote){
        await props.updateCustomerNote(customerNoteDetailTemp);
      }
    }   
    setLoadingCustomerNote(false);
  }

  return (
    !props.blnShow
      ?
      <div />
      :
      <Modal
        isOpen={props.blnShow}
        toggle={() => {
          if (!loadingCustomerNote && !successMessage) {
            hideModal();
          }
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title margin-top-0">{intl.formatMessage({ id: `${props.blnAction}` })} {intl.formatMessage({ id: "Note" })}</h5>
          {
            !loadingCustomerNote && !successMessage
            &&
            <button
              type="button"
              onClick={() => {
                hideModal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
        <div className="modal-body">
          <GeneralTextAreaForModal
            title={intl.formatMessage({ id: "Note" })}
            name="note"
            className={errorState.note ? "mb-0" : "mb-3"}
            row={4}
            validationRequired={true}
            field={customerNoteDetails}
            setFieldValue={setCustomerNoteDetails}
            onChangeFunction={() => { clearErrorState("note") }}
            disabled={viewNoteAction || loadingCustomerNote || Boolean(successMessage)} />
          {errorState.note ? (
            <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Note" }) })}</FormFeedback>
          ) : null}
          <GeneralInputForModal
            title={intl.formatMessage({ id: "FollowUp" })}
            name="isFollowUp"
            type="checkbox"
            disabled={viewNoteAction || loadingCustomerNote || Boolean(successMessage) || !addNoteAction}
            field={customerNoteDetails}
            setFieldValue={setCustomerNoteDetails}
            childrenUI={
              <Label className="margin-bottom-0 margin-left-4">
                Yes
              </Label>
            }
          />
          <div className="float-end">
            {
              !viewNoteAction 
              &&
              !Boolean(successMessage)
              &&
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: `${props.blnAction}` })}
                onClick={() => {
                  let errorStateTemp = {
                    note: false,
                  };
                  let blnErrorFound = false;
                  if (!customerNoteDetails.note) {
                    errorStateTemp.note = true;
                    blnErrorFound = true;
                  }
                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    onClickCustomerNote(customerNoteDetails)
                  }
                }}
                loading={loadingCustomerNote}
                disable={loadingCustomerNote || Boolean(successMessage)}
              />
            }
          </div>
        </div>
      </Modal>
  )
}