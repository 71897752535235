import { PaginationRequestBody } from "../models/pagination";
import { CommissionSchemeListObject, CommissionSchemeAddObject, CommissionSchemeUpdateObject, CommissionSchemeDetailObject, CommissionSchemeProductListObject, CommissionSchemePackageListObject, CommissionSchemeProductCreditListObject, CommissionSchemeProductCreditDetailObject, CommissionSchemeProductCreditAddObject, CommissionSchemeProductCreditUpdateObject } from "../models/commissionScheme";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class CommissionSchemeStore {
  commissionSchemeList: CommissionSchemeListObject[] = [];
  commissionSchemeDetail: CommissionSchemeDetailObject | undefined = undefined;
  commissionSchemeProductsList:  CommissionSchemeProductListObject[] = [];
  commissionSchemePackagesList:  CommissionSchemePackageListObject[] = [];
  commissionSchemeProductCreditList: CommissionSchemeProductCreditListObject[] = [];
  commissionSchemeProductCreditDetail: CommissionSchemeProductCreditDetailObject | undefined = undefined;
  commissionSchemePaginationParams: PaginationRequestBody | undefined = undefined;
  commissionSchemeProductCreditPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.commissionSchemeList = [];
    this.commissionSchemeProductsList = [];
    this.commissionSchemePackagesList = [];
    this.commissionSchemeProductCreditList = [];
    this.commissionSchemeDetail = undefined;
    this.commissionSchemeProductCreditDetail = undefined;
    this.commissionSchemePaginationParams= undefined;
    this.commissionSchemeProductCreditPaginationParams = undefined;
  }

  setCommissionSchemeDetail = (commissionSchemeDetail: CommissionSchemeDetailObject | undefined) => {
    this.commissionSchemeDetail = commissionSchemeDetail;
  }
  
  setCommissionSchemeProductsList = (commissionSchemeProductsList: CommissionSchemeProductListObject[]) => {
    this.commissionSchemeProductsList = commissionSchemeProductsList;
  }

  setCommissionSchemePackagesList = (commissionSchemePackagesList: CommissionSchemePackageListObject[]) => {
    this.commissionSchemePackagesList = commissionSchemePackagesList;
  }

  setCommissionSchemeProductCreditList = (commissionSchemeProductCreditList: CommissionSchemeProductCreditListObject[]) => {
    this.commissionSchemeProductCreditList = commissionSchemeProductCreditList;
  }

  setCommissionSchemeProductCreditDetail = (commissionSchemeProductCreditDetail: CommissionSchemeProductCreditDetailObject | undefined) => {
    this.commissionSchemeProductCreditDetail = commissionSchemeProductCreditDetail;
  }

  setCommissionSchemePaginationParams = (commissionSchemePaginationParams: PaginationRequestBody | undefined) => {
    this.commissionSchemePaginationParams = commissionSchemePaginationParams;
  }

  setCommissionSchemeProductCreditPaginationParams = (commissionSchemeProductCreditPaginationParams: PaginationRequestBody | undefined) => {
    this.commissionSchemeProductCreditPaginationParams = commissionSchemeProductCreditPaginationParams;
  }

  getCommissionScheme = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCommissionSchemePaginationParams(PaginationRequestBody);
    try{
      const resultCommissionScheme = await agent.CommissionScheme.commissionSchemeList(PaginationRequestBody);
      runInAction(() => {
        this.commissionSchemeList = resultCommissionScheme.data;
        store.commonStore.setTotalItem(resultCommissionScheme.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemeList = [];
    }
  }

  getCommissionSchemeProducts = async () => {
    try{
      const resultCommissionSchemeProducts = await agent.CommissionScheme.commissionSchemeProductList();
      runInAction(() => {
        this.commissionSchemeProductsList = resultCommissionSchemeProducts;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemeProductsList = [];
    }
  }

  getCommissionSchemePackages = async () => {
    try{
      const resultCommissionSchemePackages = await agent.CommissionScheme.commissionSchemePackageList();
      runInAction(() => {
        this.commissionSchemePackagesList = resultCommissionSchemePackages;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemePackagesList = [];
    }
  }

  getCommissionSchemeWithId = async (id: string) => {
    try{
      const resultCommissionSchemeDetail = await agent.CommissionScheme.commissionSchemeDetail(id);
      runInAction(() => {
        this.commissionSchemeDetail = resultCommissionSchemeDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemeDetail = undefined;
    }
  }

  addCommissionScheme = async (commissionSchemeRequestBody: CommissionSchemeAddObject) => {
    try{
      await agent.CommissionScheme.addCommissionScheme(commissionSchemeRequestBody);
      store.commonStore.setSuccessMessage(`CommissionSchemeAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCommissionScheme = async (commissionSchemeRequestBody: CommissionSchemeUpdateObject) => {
    try{
      await agent.CommissionScheme.updateCommissionScheme(commissionSchemeRequestBody);
      store.commonStore.setSuccessMessage(`CommissionSchemeUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCommissionScheme  = async (id: string, name: string) => {
    try {
      await agent.CommissionScheme.deleteCommissionScheme(id);
      store.commonStore.setSuccessMessage(`CommissionSchemeDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCommisionSchemeProductCredit = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCommissionSchemeProductCreditPaginationParams(PaginationRequestBody);
    try{
      const resultCommisionSchemeProductCredit = await agent.CommissionScheme.commissionSchemeProductCreditList(PaginationRequestBody);
      runInAction(() => {
        this.commissionSchemeProductCreditList = resultCommisionSchemeProductCredit.data;
        store.commonStore.setTotalItem(resultCommisionSchemeProductCredit.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemeProductCreditList = [];
    }
  }

  getCommisionSchemeProductCreditWithId = async (id: string) => {
    try{
      const resultCommisionSchemeProductCreditDetail = await agent.CommissionScheme.commissionSchemeProductCreditDetail(id);
      runInAction(() => {
        this.commissionSchemeProductCreditDetail = resultCommisionSchemeProductCreditDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.commissionSchemeProductCreditDetail = undefined;
    }
  }

  addCommissionSchemeProductCredit = async (commissionSchemeProductCreditRequestBody: CommissionSchemeProductCreditAddObject) => {
    try{
      await agent.CommissionScheme.addCommissionSchemeProductCredit(commissionSchemeProductCreditRequestBody);
      store.commonStore.setSuccessMessage(`CommissionSchemeProductCreditAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCommissionSchemeProductCredit = async (commissionSchemeProductCreditRequestBody: CommissionSchemeProductCreditUpdateObject) => {
    try{
      await agent.CommissionScheme.updateCommissionSchemeProductCredit(commissionSchemeProductCreditRequestBody);
      store.commonStore.setSuccessMessage(`CommissionSchemeProductCreditUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCommissionSchemeProductCredit  = async (id: string) => {
    try {
      await agent.CommissionScheme.deleteCommissionSchemeProductCredit(id);
      store.commonStore.setSuccessMessage(`CommissionSchemeProductCreditDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}
