import { PaginationRequestBody } from "../models/pagination";
import { VirtualWarehouseCreditExchangeRequestObject, VirtualWarehouseCreditExchangeDetailObject, VirtualWarehouseCreditExchangeListObject, VirtualWarehouseCreditExchangeReviewObject } from "../models/virtualWarehouseCreditExchange";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import { CustomerSalesOrderSignObject } from "../models/customerSalesOrder";

export default class virtualWarehouseCreditExchangeStore {
  virtualWarehouseCreditExchangeList: VirtualWarehouseCreditExchangeListObject[] = [];
  virtualWarehouseCreditExchangeDetail: VirtualWarehouseCreditExchangeDetailObject | undefined = undefined;
  virtualWarehouseCreditExchangePaginationParams: PaginationRequestBody | undefined = undefined;
  customerIdFromVirtualWarehouseCreditExchange: string = "";

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.virtualWarehouseCreditExchangeList = [];
    this.virtualWarehouseCreditExchangeDetail = undefined;
    this.virtualWarehouseCreditExchangePaginationParams = undefined;
    this.customerIdFromVirtualWarehouseCreditExchange = "";
  }

  setVirtualWarehouseCreditExchangeDetail = (virtualWarehouseCreditExchangeDetail: VirtualWarehouseCreditExchangeDetailObject | undefined) => {
    this.virtualWarehouseCreditExchangeDetail = virtualWarehouseCreditExchangeDetail;
  }

  setVirtualWarehouseCreditExchangePaginationParams = (virtualWarehouseCreditExchangePaginationParams: PaginationRequestBody | undefined) => {
    this.virtualWarehouseCreditExchangePaginationParams = virtualWarehouseCreditExchangePaginationParams;
  }

  setCustomerIdFromVirtualWarehouseCreditExchange = (customerIdFromVirtualWarehouseCreditExchange: string) => {
    this.customerIdFromVirtualWarehouseCreditExchange = customerIdFromVirtualWarehouseCreditExchange;
  }
  
  getVirtualWarehouseCreditExchange = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setVirtualWarehouseCreditExchangePaginationParams(PaginationRequestBody);
    try{
      const resultVirtualWarehouseCreditExchange = await agent.VirtualWarehouseCreditExchange.virtualWarehouseCreditExchangeList(PaginationRequestBody);
      runInAction(() => {
        this.virtualWarehouseCreditExchangeList = resultVirtualWarehouseCreditExchange.data;
        store.commonStore.setTotalItem(resultVirtualWarehouseCreditExchange.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.virtualWarehouseCreditExchangeList = [];
    }
  }
  
  getVirtualWarehouseCreditExchangeWithId = async (id: string) => {
    try{
      const resultVirtualWarehouseCreditExchange = await agent.VirtualWarehouseCreditExchange.virtualWarehouseCreditExchangeDetail(id);
      runInAction(() => {
        this.virtualWarehouseCreditExchangeDetail = resultVirtualWarehouseCreditExchange;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.virtualWarehouseCreditExchangeDetail = undefined;
    }
  }

  requestVirtualWarehouseCreditExchange = async (virtualWarehouseCreditExchangeRequestBody: VirtualWarehouseCreditExchangeRequestObject) => {
    try{
      await agent.VirtualWarehouseCreditExchange.requestVirtualWarehouseCreditExchange(virtualWarehouseCreditExchangeRequestBody);
      store.commonStore.setSuccessMessage(`VirtualWarehouseCreditExchangeRequestSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  
  cancelVirtualWarehouseCreditExchange = async (id: string) => {
    try{
      await agent.VirtualWarehouseCreditExchange.cancelVirtualWarehouseCreditExchange(id);
      store.commonStore.setSuccessMessage(`VirtualWarehouseCreditExchangeCancelSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  reviewVirtualWarehouseCreditExchange = async (reviewVirtualWarehouseCreditExchangeRequestBody: VirtualWarehouseCreditExchangeReviewObject) => {
    try{
      await agent.VirtualWarehouseCreditExchange.reviewVirtualWarehouseCreditExchange(reviewVirtualWarehouseCreditExchangeRequestBody);
      store.commonStore.setSuccessMessage(`VirtualWarehouseCreditExchangeReviewSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}