import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import {
  Row,
  UncontrolledTooltip
} from "reactstrap";
import PaginationTable from '../../app/components/table/PaginationTable';
import { history } from "../..";
import { BrandListObject } from '../../app/models/brand';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import { checkPermission, comparePathForPagination, comparePathForSetParams } from "../../app/common/function/function";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import TableActionButton from "../../app/components/table/TableActionButton";
import { PaginationRequestBody } from "../../app/models/pagination";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import * as Yup from "yup";
import { useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import Loading from "../../app/components/loading/Loading";

const Brand = () => {
  const intl = useIntl();
  //Use Store
  const { brandStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, setShowDeletePrompt, prevPath, setErrorMessage, setSuccessMessage } = commonStore;
  const { brandList, brandPaginationParams, getBrand, deleteBrand, setBrandPaginationParams } = brandStore; 

  //Data List
  const [selectedRow, setSelectedRow] = useState<BrandListObject | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateBrand = checkPermission([PermissionConstants.CreateBrand]);
  const blnPermissionUpdateBrand = checkPermission([PermissionConstants.UpdateBrand]);
  const blnPermissionDeleteBrand = checkPermission([PermissionConstants.DeleteBrand]);
  const [localLoading, setLocalLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [initialCallAPI, setInitialCallAPI] = useState(true);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let brandRequestBody : PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    brandName: validation.values.name
  }

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];
  
  useEffect(() => {
    async function fetchBrandAPI() {
      setLoading(true);
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.brand)
      if (blnBackNavigation) {
        if (brandPaginationParams) {
          setCurrentPage(brandPaginationParams.pageNumber)
          validation.setValues((values)=> ({
            ...values,
            name: brandPaginationParams.brandName || "",
          }))
          brandRequestBody = brandPaginationParams;
        }
      }
      else {
        setBrandPaginationParams(undefined);
      }

      await getBrand(brandRequestBody)
      setLoading(false);
      setInitialCallAPI(false);
    }

    if (!checkPermission([PermissionConstants.ManageBrand], true)) {
      return;
    }

    fetchBrandAPI();

    return (()=> {
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.brand)
        if (!blnSetParams) {
          setBrandPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialCallAPI) {
      fetchBrandApi(true);
    }
  }, [triggerSearch])

  const onClickDelete = async (row : BrandListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  async function fetchBrandApi(blnDropDown: boolean = false) {
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getBrand(brandRequestBody);
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value)=> value+1)
  }

  //Table Content UI
  const BrandColumns = [
    { 
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "name",
      text: intl.formatMessage({id: "Name"}).toUpperCase(),
      style: {
        width: '70%'
      },
    },
    {
      dataField: "displayOrder",
      text: intl.formatMessage({ id: "DisplayOrder" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="text-center">{`${row.displayOrder}`}</div>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({id: "Action"}).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageBrand, PermissionConstants.UpdateBrand, PermissionConstants.DeleteBrand]), 
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            <TableActionButton 
              to={`/${RoutesList.brand}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateBrand
              &&
              <TableActionButton 
                to={`/${RoutesList.brand}/edit/${row.id}`}
                type="edit"/>
            }
            {
              blnPermissionDeleteBrand
              &&
              <TableActionButton 
                to="#"
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({id: "Brand"})})}
        title={intl.formatMessage({ id: "Brand" })}
        addButton={
          blnPermissionCreateBrand
          &&
          <MyAddButton
          content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({id: "Brand"})})}
          onClick={()=> {history.push(`/${RoutesList.brand}/add`)}}
          linkTo={`/${RoutesList.brand}/add`}
          disable={loading}/>
        }>
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "EnterBrandName" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
        </Row>
        {
          localLoading
            ?
            <Loading/>
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={BrandColumns}
              data={brandList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({id: "Brand"})})}
              objSorted={defaultSorted}
              brandName={validation.values.name}
              requestAPI={getBrand}/>
        }
        <DeleteModal
          displayName={selectedRow?.name}
          onDeleteClick={async ()=> {
            setLoading(true);
            await deleteBrand(selectedRow?.id || "", selectedRow?.name || "");
            let currentPageTemp = currentPage;
            if (brandList.length === 1 && currentPage !== 1) {
              setCurrentPage((value)=> value-1)
              currentPageTemp = currentPageTemp-1;
            }
            await getBrand({pageNumber: currentPageTemp, pageSize: pageSize})
            setLoading(false);
          }}/>
      </ListViewLayout>
    </div>
  );
}

export default observer(Brand);
