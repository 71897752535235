import { useState, useEffect } from "react";
import {
  Modal,
  Label,
  FormFeedback
} from "reactstrap";
import _ from 'lodash';
import { AddOptionsValueField, calculateBalanceAmount, newSpaceBeforeCapitalLetter } from "../../../app/common/function/function";
import GeneralInputForModal from '../../../app/components/form/GeneralInputForModal';
import { CustomerPaymentDetailObject } from "../../../app/models/customerOrder";
import { StaticReferenceDataObject } from "../../../app/models/staticReference";
import MyButton from "../../../app/components/form/MyButton";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { useIntl } from "react-intl";
import { IssuerBanksListObject } from "../../../app/models/paymentMethodOption";
import Loading from "../../../app/components/loading/Loading";

interface Props {
  blnShow: boolean;
  setModal: Function;
  selectedCustomerOrderDetail: CustomerPaymentDetailObject | undefined;
  setSelectedCustomerPaymentDetail: Function;
  cardType: StaticReferenceDataObject[];
  validation: any;
  paymentFieldName?: string;
  getPaymentMethodOptionDropdown: Function;
}

export default function CustomerOrderPaymentModal(props: Props) {
  const intl = useIntl();
  const [errorState, setErrorState] = useState({
    last4CardDigit: false,
    cardType: false,
    paymentMethodOptionId: false,
    issuerBankId: false,
    transactionNo: false,
  })
  const optionsCardType = AddOptionsValueField(props.cardType, "displayValue", "displayValue");
  const [optionsPaymentType, setOptionsPaymentType] = useState<any>([]);
  const [issuerBankList, setIssuerBankList] = useState<IssuerBanksListObject[]>([]);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingIssuerBank, setLoadingIssuerBank] = useState(false);
  const paymentDetailsFieldName = props.paymentFieldName || "paymentDetails";

  useEffect(() => {
    if(props.selectedCustomerOrderDetail?.isOptionAvailable){
      checkPaymentMethodOptions()
    }
  }, [])

  const hideModal = () => {
    setErrorState({
      last4CardDigit: false,
      cardType: false,
      paymentMethodOptionId: false,
      issuerBankId: false,
      transactionNo: false,
    });
    setIssuerBankList([]);
    props.setModal(false);
    props.setSelectedCustomerPaymentDetail(undefined);
  }

  const blnQR = props.selectedCustomerOrderDetail?.isQRPay
  const blnCard = props.selectedCustomerOrderDetail?.isCardInput
  const blnCustomerCredit = props.selectedCustomerOrderDetail?.isCustomerCredit
  const blnTransferCredit = props.selectedCustomerOrderDetail?.isTransferCredit
  const blnOnlineTransferOrCheque = !blnCard && !blnQR && !blnCustomerCredit && !blnTransferCredit && optionsPaymentType.length > 0;

  const onChangeCardType = (e) => {
    let selectedCustomerOrderDetail = _.cloneDeep(props.selectedCustomerOrderDetail)
    if (selectedCustomerOrderDetail) {
      selectedCustomerOrderDetail.cardType = e.value;
      props.setSelectedCustomerPaymentDetail(selectedCustomerOrderDetail);
    } 
  }

  const checkPaymentMethodOptions = async () => {
    setLocalLoading(true);
    let resultPaymentMethodOptionTemp = await props.getPaymentMethodOptionDropdown(props.selectedCustomerOrderDetail?.paymentMethodId || "");
    setOptionsPaymentType(AddOptionsValueField(resultPaymentMethodOptionTemp, "name", "id"));
    setLocalLoading(false);
  }

  const onChangeOptionType = (e) => {
    let selectedCustomerOrderDetail = _.cloneDeep(props.selectedCustomerOrderDetail)
    if (selectedCustomerOrderDetail) {
      selectedCustomerOrderDetail.paymentMethodOptionName = e.label;
      selectedCustomerOrderDetail.paymentMethodOptionId = e.value;
      delete selectedCustomerOrderDetail["paymentIssuerBankId"];
      delete selectedCustomerOrderDetail["paymentIssuerBankName"];

      let issuerBankTemp = e.issuerBanks || []
      setLoadingIssuerBank(true);
      setIssuerBankList(issuerBankTemp)
      props.setSelectedCustomerPaymentDetail(selectedCustomerOrderDetail);
      setTimeout(()=> {
        setLoadingIssuerBank(false);
      }, 100)
    } 
  }

  const onChangeIssuerBank = (e) => {
    let selectedCustomerOrderDetail = _.cloneDeep(props.selectedCustomerOrderDetail)
    if (selectedCustomerOrderDetail) {
      selectedCustomerOrderDetail.paymentIssuerBankId = e.value;
      selectedCustomerOrderDetail.paymentIssuerBankName = e.label;
      props.setSelectedCustomerPaymentDetail(selectedCustomerOrderDetail);
    } 
  }

  return (
    !props.blnShow
    ?
    <div/>
    : 
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.selectedCustomerOrderDetail?.paymentMethodName}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {
          localLoading
          ?
          <Loading/>
          :
          <>
            {
              blnCard
              &&
              <div>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: "Credit/DebitCardLastFourDigit"})}
                  name="last4CardDigit"
                  className={errorState.last4CardDigit ? "mb-0" : "mb-3"}
                  type="number"
                  maxLength={4}
                  typeExcludeNumberType={true}
                  validationRequired={true}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerPaymentDetail}
                />
                {errorState.last4CardDigit ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "Last4Digit" }) })}</FormFeedback>
                ) : null}
              </div>
            }
            {
              blnCard
              &&
              <div>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "Credit/DebitCardType"})}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.cardType ? "mb-0" : "mb-3"}
                  options={optionsCardType}
                  blnValueWithNewSpace={true}
                  onChange={onChangeCardType}
                  initialLabel={props.selectedCustomerOrderDetail?.cardType}
                  initialValue={props.selectedCustomerOrderDetail?.cardType}
                  blnReturnFullValue={true}
                  validationRequired={true}
                />
                {errorState.cardType ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "CardType" }) })}</FormFeedback>
                ) : null}
              </div>
            }
            {
              props.selectedCustomerOrderDetail?.isOptionAvailable && optionsPaymentType.length > 0
              &&
              <div>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{newSpaceBeforeCapitalLetter(props.selectedCustomerOrderDetail?.paymentMethodOptionType)}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.paymentMethodOptionId ? "mb-0" : "mb-3"}
                  options={optionsPaymentType}
                  onChange={onChangeOptionType}
                  initialLabel={props.selectedCustomerOrderDetail?.paymentMethodOptionName}
                  initialValue={props.selectedCustomerOrderDetail?.paymentMethodOptionId}
                  blnReturnFullValue={true}
                  validationRequired={true}
                />
                {errorState.paymentMethodOptionId ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field :newSpaceBeforeCapitalLetter(props.selectedCustomerOrderDetail?.paymentMethodOptionType)})}</FormFeedback>
                ) : null}
              </div>
            }
            {
              loadingIssuerBank
              ?
              <Loading/>
              :
              props.selectedCustomerOrderDetail?.isOptionAvailable && issuerBankList.length > 0
              &&
              <div>
                <Label>
                  <Label style={{color: 'red'}} className="margin-bottom-0">{`*`}</Label>
                  <Label className="margin-bottom-0">{intl.formatMessage({ id: "IssuerBanks"})}</Label>
                </Label>
                <GeneralSelectDropdown
                  title=""
                  name=""
                  className={errorState.issuerBankId ? "mb-0" : "mb-3"}
                  options={issuerBankList}
                  onChange={onChangeIssuerBank}
                  labelField="name"
                  valueField="id"
                  blnReturnFullValue={true}
                  validationRequired={true}
                />
                {errorState.issuerBankId ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "IssuerBank"}) })}</FormFeedback>
                ) : null}
              </div>
            }
            {
              (blnCard || blnQR || blnOnlineTransferOrCheque)
              &&
              <div>
                <GeneralInputForModal
                  title={intl.formatMessage({ id: blnQR || blnOnlineTransferOrCheque ? "TransactionNo" : "ApprovalCode"})}
                  name="transactionNo"
                  className={errorState.transactionNo ? "mb-0" : "mb-3"}
                  type={"text"}
                  typeExcludeNumberType={true}
                  field={props.selectedCustomerOrderDetail}
                  setFieldValue={props.setSelectedCustomerPaymentDetail}
                  validationRequired={!blnQR && !blnOnlineTransferOrCheque}
                  {...!blnQR && !blnOnlineTransferOrCheque && {maxLength: 6}}
                />
                {errorState.transactionNo ? (
                  <FormFeedback className="mb-3" type="invalid">{intl.formatMessage({ id: "ValidationEnterOne" }, { field : intl.formatMessage({ id: "ApprovalCode" })})}</FormFeedback>
                ) : null}
              </div>
            }
            <div className="">
              <MyButton
                type="button"
                class="btn btn-primary"
                content={intl.formatMessage({ id: "Save" })}
                onClick={()=> {
                  let errorStateTemp = {
                    last4CardDigit: false,
                    cardType: false,
                    paymentMethodOptionId: false,
                    issuerBankId: false,
                    transactionNo: false,
                  };

                  let blnErrorFound = false;
                  if (!props.selectedCustomerOrderDetail?.paymentMethodOptionId && props.selectedCustomerOrderDetail?.isOptionAvailable && optionsPaymentType.length > 0) {
                    errorStateTemp.paymentMethodOptionId = true;
                    blnErrorFound = true;
                  }
                  if (!props.selectedCustomerOrderDetail?.paymentIssuerBankId && props.selectedCustomerOrderDetail?.isOptionAvailable && optionsPaymentType.length > 0 && issuerBankList.length > 0) {
                    errorStateTemp.issuerBankId = true;
                    blnErrorFound = true;
                  }
                  if (blnCard) {
                    if (String(props.selectedCustomerOrderDetail?.last4CardDigit).length !== 4) {
                      errorStateTemp.last4CardDigit = true;
                      blnErrorFound = true;
                    }
                    if (!props.selectedCustomerOrderDetail?.cardType) {
                      errorStateTemp.cardType = true;
                      blnErrorFound = true;
                    }
                    if (String(props.selectedCustomerOrderDetail?.transactionNo).length !== 6) {
                      errorStateTemp.transactionNo = true;
                      blnErrorFound = true;
                    }
                  }

                  if (blnErrorFound) {
                    setErrorState(errorStateTemp)
                  }
                  else {
                    let paymentDetailsTemp : any = _.cloneDeep(props.validation.values[paymentDetailsFieldName]);
                    paymentDetailsTemp.push(props.selectedCustomerOrderDetail);
                    let prefillPaidNumber : any = calculateBalanceAmount(props.validation, props.paymentFieldName ? "customerPaymentDetails" : "paymentDetails", props.paymentFieldName ?  "totalPrice" : "totalSellingPrice", paymentDetailsTemp)

                    props.validation.setValues((values)=> ({
                      ...values,
                      [paymentDetailsFieldName]: paymentDetailsTemp,
                      selectedCustomerOrderDetail: undefined,
                      paidNumber: prefillPaidNumber,
                      paymentMethod: ""
                    }))
                    hideModal();
                  }
                }}
              />
            </div>
          </>
        }
      </div>
    </Modal>
  )
}