import React, { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import {
  Row,
  Col,
  Table,
  Label,
} from "reactstrap";
import PaginationTable from '../../../app/components/table/PaginationTable';
import { Constants } from "../../../app/constants/Constants";
import { PermissionConstants } from "../../../app/constants/PermissionConstants";
import { RoutesList } from "../../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import ListViewLayout from "../../../app/components/layout/ListViewLayout";
import { arraySpliceInAllValue, capitalizeFirstLetter, checkPermission, firstDayOfMonthToCurrentDay, getBranchId, getBranchName, getBranchUser, IncludesLocationPathName, newSpaceBeforeCapitalLetter, returnPriceWithCurrency } from "../../../app/common/function/function";
import GeneralSelectDropdown from "../../../app/components/form/GeneralSelectDropdown";
import { DateRangeInterface, ObjectForDropdown } from "../../../app/models/common";
import Loading from "../../../app/components/loading/Loading";
import _ from "lodash";
import GeneralDateRange from "../../../app/components/form/GeneralDateRange";
import MyButton from "../../../app/components/form/MyButton";
import { useLocation } from 'react-router-dom';
import { BranchListObject } from "../../../app/models/branch";

const SalesByEmployee = () => {
  const intl = useIntl();
  //Use Store
  const { reportSalesStore, branchStore, commonStore } = useStore();
  const { loading, setLoading, totalItem, windowSize, setErrorMessage } = commonStore;
  const { salesByEmployeeList, getSalesByEmployeeList, setSalesByEmployeeList, exportSalesByEmployee } = reportSalesStore;
  const { getBranchDropDown } = branchStore;

  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() ? getBranchId() : "");
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() ? getBranchName() : "");
  const [branchDropDownListFinal, setBranchDropDownListFinal] = useState<BranchListObject[]>([])

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(firstDayOfMonthToCurrentDay());
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Report" }), urlPath: RoutesList.report }];
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.maxPageSize;
  const blnPermissionExportSalesReport = checkPermission([PermissionConstants.ExportSalesReport]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  useEffect(() => {
    async function fetchSalesByEmployee() {
      setLoading(true);
      let aryAPI: any = [
        getBranchDropDown()
      ];

      let resultAPI = await Promise.all(aryAPI);

      setBranchDropDownListFinal(resultAPI[0])
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageSalesReport], true)) {
      return;
    }

    fetchSalesByEmployee();
    return (() => {
      setSalesByEmployeeList([]);
    })
  }, [])

  const fetchSalesByEmployeeWithLoading = async (blnExport: boolean) => {
    if (!selectedBranchId) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({id: "Branch"})}))
      return;
    }

    if (!selectedDateRange.fromDate && !selectedDateRange.toDate) {
      setErrorMessage(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "Date" }) }))
      return;
    }

    setLocalLoading(true);
    if(blnExport){
      await exportSalesByEmployee({ pageNumber: 1, pageSize: pageSize,branchId: selectedBranchId, dateRange: selectedDateRange })
    }else{
      await getSalesByEmployeeList({ branchId: selectedBranchId, dateRange: selectedDateRange});
    }
    setLocalLoading(false);
  };

  const onSelectDateRange = (objDateRange: any) => {
    setSelectedDateRange(objDateRange);
  }

  const returnSalesByEmployeeSummaryFontWeight = (index) => {
    return index == salesByEmployeeList.length -1 ? Constants.boldFontWeight : Constants.normalFontWeight;
  }

  //Table Content UI
  const SalesByEmployeeColumns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {row.name}
        </span>
      )
    },
    {
      dataField: "dailyReceived",
      text: intl.formatMessage({ id: "DailyReceived" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.dailyReceived)}
        </span>
      )
    },
    {
      dataField: "mtdReceived",
      text: intl.formatMessage({ id: "mtdReceived" }).toUpperCase(),
      formatter: (cellContent, row, index) => (
        <span style={{fontWeight: returnSalesByEmployeeSummaryFontWeight(index)}}>
          {returnPriceWithCurrency(row.mtdReceived)}
        </span>
      )
    }
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={`${intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: `SalesByEmployee`  })  })} `}
        title={`${intl.formatMessage({ id: "SalesByEmployee" })}`}
        breadCrumbList={breadCrumbList}
        addButton={
          blnPermissionExportSalesReport
          &&
          <MyButton
            type="button"
            class="btn btn-success"
            content={intl.formatMessage({ id: "Export" }).toUpperCase()}
            disable={localLoading || loading}
            onClick={()=> {
              fetchSalesByEmployeeWithLoading(true)
            }}
          />
        }
        hyperlink={`https://onedocgroup.sharepoint.com/sites/ONEERPUserGuides/SitePages/Sales-By-Employee.aspx`}>
        <Row>
            <Col xl="3">
            <GeneralSelectDropdown
              title={intl.formatMessage({ id: "Branch" })}
              name="branchName"
              className={`${windowSize.innerWidth > Constants.innerWidthCompare ? "mb-0" : ""}`}
              options={branchDropDownListFinal}
              labelField={"name"}
              valueField={"id"}
              initialLabel={selectedBranchName}
              initialValue={selectedBranchId}
              disabled={localLoading || loading || getBranchUser()}
              validationRequired={true}
              setFieldLabel={setSelectedBranchName}
              setFieldValue={setSelectedBranchId}
            />
          </Col>
          <Col xl="3">
            <GeneralDateRange
              title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
              name={"DateRange"}
              disabled={localLoading || loading}
              onChangeFunction={onSelectDateRange}
              validationRequired={true}
              initialValue={selectedDateRange} />
          </Col>
          <Col xl="2">
            <MyButton
              type="button"
              class={`btn btn-primary ${windowSize.innerWidth > Constants.innerWidthCompare ? "margin-top-28" : "margin-bottom-14"}`}
              content={intl.formatMessage({ id: "Apply" }).toUpperCase()}
              loading={localLoading || loading}
              disable={localLoading || loading}
              onClick={() => {
                fetchSalesByEmployeeWithLoading(false)
              }}
            />
          </Col>
        </Row>
        {
          localLoading
            ?
            <Loading />
            :
            <PaginationTable
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={totalItem}
              tableColumns={SalesByEmployeeColumns}
              data={salesByEmployeeList}
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Employee" }) })}
              objSorted={defaultSorted}
              branchId={selectedBranchId}
              dateRange={selectedDateRange}
              keyField={"name"}
              requestAPI={getSalesByEmployeeList}
              hideSearchBar={true} />
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(SalesByEmployee);
