import { PaginationRequestBody } from "../models/pagination";
import { CloseCounterListObject, CloseCounterAddObject, CloseCounterUpdateObject, CloseCounterDetailObject, OverrideCloseCounterObject } from "../models/closeCounter";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import moment from "moment";

export default class CloseCounterStore {
  closeCounterList: CloseCounterListObject[] = [];
  closeCounterDetail: CloseCounterDetailObject | undefined = undefined;
  closeCounterPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.closeCounterList = [];
    this.closeCounterDetail = undefined;
    this.closeCounterPaginationParams= undefined;
  }

  setCloseCounterDetail = (closeCounterDetail: CloseCounterDetailObject | undefined) => {
    this.closeCounterDetail = closeCounterDetail;
  }

  setCloseCounterPaginationParams = (closeCounterPaginationParams: PaginationRequestBody | undefined) => {
    this.closeCounterPaginationParams = closeCounterPaginationParams;
  }
  
  getCloseCounter = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCloseCounterPaginationParams(PaginationRequestBody);
    try{
      const resultCloseCounter = await agent.CloseCounter.closeCounterList(PaginationRequestBody);
      runInAction(() => {
        this.closeCounterList = resultCloseCounter.data;
        store.commonStore.setTotalItem(resultCloseCounter.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.closeCounterList = [];
    }
  }

  getCloseCounterWithId = async (id: string, blnOverride: boolean) => {
    try{
      const resultCloseCounterDetail: any = await agent.CloseCounter.closeCounterDetail(id);

      if (resultCloseCounterDetail && !blnOverride) {
        resultCloseCounterDetail.closeCounterSettlements = resultCloseCounterDetail.closeCounterSettlements.map((valueCloseCounterSettlementTemp, indexCloseCounterSettlementTemp)=> ({
          ...valueCloseCounterSettlementTemp,
          randomId: `settlements_index_${indexCloseCounterSettlementTemp}`
        }))

        if (resultCloseCounterDetail.closeCounterDetails) {
          if (resultCloseCounterDetail.closeCounterDetails.length > 0) {
            resultCloseCounterDetail.closeCounterDetails.push({
              id: "total_close_counter_details",
              paymentName: "Total",
              amount: resultCloseCounterDetail.totalAmount,
              overriddenAmount: resultCloseCounterDetail.totalOverriddenAmount,
              isOverridden: false,
              closeCounterTerminalDetails: []
            })
          } 
        }
        
        if (resultCloseCounterDetail.changePaymentModeSummaries) {
          if (resultCloseCounterDetail.changePaymentModeSummaries.length > 0) {
            resultCloseCounterDetail.changePaymentModeSummaries.splice(0, 0, {
              documentNo: "",
              ducomentId: "",
              status: "",
              oldPaymentDetails: [],
              newPaymentDetails: [],
              randomId: "change_payment_first_row"
            });

            resultCloseCounterDetail.changePaymentModeSummaries = resultCloseCounterDetail.changePaymentModeSummaries.map((valueChangePaymentModeTemp, index)=> {
              let oldPaymentLength = valueChangePaymentModeTemp.oldPaymentDetails.length;
              let newPaymentLength = valueChangePaymentModeTemp.newPaymentDetails.length;
              if (oldPaymentLength !== newPaymentLength) {
                let paymentVariable = oldPaymentLength > newPaymentLength ? "newPaymentDetails" : "oldPaymentDetails";
                let totalDifferent = oldPaymentLength > newPaymentLength ? oldPaymentLength - newPaymentLength : newPaymentLength - oldPaymentLength;
                for (let a=0; a<totalDifferent; a++) {
                  valueChangePaymentModeTemp[paymentVariable].push({
                    transactionDate: "",
                    paymentMode: "",
                    cardOrAccountNo: "",
                    amount: 0
                  }) 
                } 
              }
              return ({
                ...valueChangePaymentModeTemp,
                randomId: `${valueChangePaymentModeTemp.documentNo}_${index}`
              })
            });
          }
        }

        if (resultCloseCounterDetail.cashOutSummaries) {
          if (resultCloseCounterDetail.cashOutSummaries.length > 0) {
            resultCloseCounterDetail.cashOutSummaries = resultCloseCounterDetail.cashOutSummaries.map((valueCashOutSummaryTemp, indexCashOutSummary)=> ({
              ...valueCashOutSummaryTemp,
              randomId: `cash_out_index_${indexCashOutSummary}`
            }))

            resultCloseCounterDetail.cashOutSummaries.push({
              type: "Total",
              amount: resultCloseCounterDetail.totalCashOutAmount,
              randomId: `cash_out_index_total`
            })
          }
        }

        if (resultCloseCounterDetail.unknownCollections) {
          resultCloseCounterDetail.unknownCollections = resultCloseCounterDetail.unknownCollections.map((valueUnknownCollection)=> {
            if (valueUnknownCollection.paymentDetails) {
              valueUnknownCollection.paymentDetails = valueUnknownCollection.paymentDetails.map((valuePaymentDetail)=> {
                return ({
                  ...valuePaymentDetail,
                  paymentDate: moment(valuePaymentDetail.paymentDate).format(Constants.displayDateFormatV2)
                })
              })
            }
            return valueUnknownCollection;
          })
        }
      }
      runInAction(() => {
        this.closeCounterDetail = resultCloseCounterDetail;
      });
      return Promise.resolve(resultCloseCounterDetail);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.closeCounterDetail = undefined;
    }
  }

  addCloseCounter = async (closeCounterRequestBody: CloseCounterAddObject) => {
    try{
      await agent.CloseCounter.addCloseCounter(closeCounterRequestBody);
      store.commonStore.setSuccessMessage(`CloseCounterAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCloseCounter = async (closeCounterRequestBody: CloseCounterUpdateObject) => {
    try{
      await agent.CloseCounter.updateCloseCounter(closeCounterRequestBody);
      store.commonStore.setSuccessMessage(`CloseCounterUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  previewCloseCounter = async (closeCounterRequestBody: CloseCounterUpdateObject) => {
    try{
      let resultPreviewCloseCounter = await agent.CloseCounter.previewCloseCounter(closeCounterRequestBody);
      return Promise.resolve({status: Constants.success, data: resultPreviewCloseCounter});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: undefined})
    }
  }

  submitCloseCounter = async (id: string) => {
    try{
      await agent.CloseCounter.submitCloseCounter(id);
      store.commonStore.setSuccessMessage(`CloseCounterSubmitSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  overrideCloseCounter = async (closeCounterRequestBody: OverrideCloseCounterObject) => {
    try{
      await agent.CloseCounter.overrideCloseCounter(closeCounterRequestBody);
      store.commonStore.setSuccessMessage(`CloseCounterOverrideSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCloseCounter = async (id: string, name: string) => {
    try {
      await agent.CloseCounter.deleteCloseCounter(id);
      store.commonStore.setSuccessMessage(`CloseCounterDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getPendingCloseCounter = async (branchId: string) => {
    try{
      const resultCloseCounter = await agent.CloseCounter.pendingCloseCounter(branchId);
      return Promise.resolve(resultCloseCounter);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}