import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Label, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import PaginationTable from '../../app/components/table/PaginationTable';
import { AppointmentListObject } from '../../app/models/appointment';
import DeleteModal from "../../app/components/modal/DeleteModal";
import { appointmentStatusColor, arraySpliceInAllValue, checkPermission, comparePathForPagination, comparePathForSetParams, firstDayOfFiveYearAgoToLastDayOfCurrentYear, getBranchId, getBranchName, getBranchUser, newSpaceBeforeCapitalLetter, returnSubBrandList } from '../../app/common/function/function';
import GeneralSelectDropdown from "../../app/components/form/GeneralSelectDropdown";
import moment from "moment";
import { history } from "../../";
import Loading from "../../app/components/loading/Loading";
import { Constants } from '../../app/constants/Constants';
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import MyAddButton from "../../app/components/form/MyAddButton";
import { useIntl } from "react-intl";
import ListViewLayout from "../../app/components/layout/ListViewLayout";
import AppointmentDetailModal from "./Modal/AppointmentDetailModal";
import { BranchListObject } from "../../app/models/branch";
import _ from "lodash";
import TableActionButton from "../../app/components/table/TableActionButton";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import GeneralInput from "../../app/components/form/GeneralInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PaginationRequestBody } from "../../app/models/pagination";
import { DateRangeInterface, ObjectForDropdown } from "../../app/models/common";
import GeneralDateRange from "../../app/components/form/GeneralDateRange";
import { StaticReferenceDataObject } from "../../app/models/staticReference";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import GeneralStatus from "../../app/components/form/GeneralStatus";
import { SubBrandObject } from "../../app/models/brand";

const AppointmentList = () => {
  const intl = useIntl();
  //Use Store
  const { appointmentStore, branchStore, mediaSourceStore, serviceTreatmentStore, staticReferenceStore, commonStore } = useStore();
  const { getBranchDropDown, isEligibleAllBranch } = branchStore;
  const { loading, setLoading, windowSize, prevPath, setShowDeletePrompt, setErrorMessage, setSuccessMessage } = commonStore;
  const { appointmentList, appointmentDetail, setAppointmentDetail, appointmentListPaginationParams, setAppointmentList, getAppointmentList, getAppointmentWithId, deleteAppointment, setAppointmentListPaginationParams, appointmentTotalItems } = appointmentStore;
  const { getMediaSourceDropdown } = mediaSourceStore;
  const { serviceTreatmentDropdownList, getServiceTreatmentDropdown } = serviceTreatmentStore;
  const { appointmentSourceType, getStaticReferenceWithType } = staticReferenceStore;

  //Data List
  const [selectedRow, setSelectedRow] = useState<AppointmentListObject | undefined>(undefined);
  const [appointmentDetailModal, setAppointmentDetailModal] = useState(false);
  const [branchListFinal, setBranchListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSubBrand = `${intl.formatMessage({ id: "AllSubBrand" })}`;
  const [selectedSubBrandName, setSelectedSubBrandName] = useState(AllSubBrand);
  const [selectedSubBrandId, setSelectedSubBrandId] = useState(" ");
  const [subBrandListFinal, setSubBrandListFinal] = useState<SubBrandObject[]>([]);
  const AllBranch = `${intl.formatMessage({ id: "AllBranch" })}`;
  const [selectedBranchName, setSelectedBranchName] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchName() : AllBranch);
  const [selectedBranchId, setSelectedBranchId] = useState(getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ");
  const [appointmentStatusList, setAppointmentStatusList] = useState<StaticReferenceDataObject[]>([]);
  const [mediaSourceListFinal, setMediaSourceListFinal] = useState<ObjectForDropdown[]>([]);
  const AllSource = `${intl.formatMessage({ id: "AllSource" })}`;
  const [selectedMediaSourceName, setSelectedMediaSourceName] = useState(AllSource);
  const [selectedMediaSourceId, setSelectedMediaSourceId] = useState(" ");
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface | undefined>(firstDayOfFiveYearAgoToLastDayOfCurrentYear());
  const [selectedSubmissionDateRange, setSelectedSubmissionDateRange] = useState<DateRangeInterface | undefined>(firstDayOfFiveYearAgoToLastDayOfCurrentYear());
  const [genderListFinal, setGenderListFinal] = useState<ObjectForDropdown[]>([]);
  const AllGender = `${intl.formatMessage({ id: "AllGender" })}`;
  const [selectedGenderName, setSelectedGenderName] = useState(AllGender);
  const [selectedGenderId, setSelectedGenderId] = useState<any>(" ");
  const [initialLoading, setInitialLoading] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const blnPermissionCreateAppointment = checkPermission([PermissionConstants.CreateAppointment]);
  const blnPermissionUpdateAppointment = checkPermission([PermissionConstants.UpdateAppointment]);
  const blnPermissionDeleteAppointment = checkPermission([PermissionConstants.DeleteAppointment]);
  const blnPermissionDeleteBlocker = checkPermission([PermissionConstants.DeleteBlocker]);
  const blnPermissionManageCustomer = checkPermission([PermissionConstants.ManageCustomer]);
  const [triggerSearch, setTriggerSearch] = useState(0);
  const [selectedServiceOptions, setSelectedServiceOptions] = useState<any>([]);
  const [selectedSourceTypeOptions, setSelectedSourceTypeOptions] = useState<any>([]);
  const [selectedAppointmentStatusesOptions, setSelectedAppointmentStatusesOptions] = useState<string[]>([]);

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "asc",
    // },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      contactNo: "",
      serviceTreatmentIds: [],
      serviceTreatmentNames: [],
      sourceTypes: [],
      sourceTypeNames: [],
      appointmentStatusIds: [],
      appointmentStatusNames: [],
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
    },
  });

  let appointmentRequestBody: PaginationRequestBody = {
    pageNumber: currentPage,
    pageSize: pageSize,
    branchId: selectedBranchId,
    name: validation.values.name,
    contactNo: validation.values.contactNo,
    mediaSourceId: selectedMediaSourceId,
    mediaSourceName: selectedMediaSourceName,
    dateRange: selectedDateRange,
    fromSubmissionDate: selectedSubmissionDateRange?.fromDate,
    toSubmissionDate: selectedSubmissionDateRange?.toDate,
    statuses: validation.values.appointmentStatusIds,
    appointmentStatusNames: validation.values.appointmentStatusNames,
    serviceTreatmentIds: validation.values.serviceTreatmentIds,
    serviceTreatmentNames: validation.values.serviceTreatmentNames,
    sourceTypes: validation.values.sourceTypes,
    sourceTypeNames: validation.values.sourceTypeNames,
    subBrandName: selectedSubBrandName,
    subBrandId: selectedSubBrandId,
    genderName: selectedGenderName,
    gender: selectedGenderId,
  }

  useEffect(() => {
    async function fetchAppointmentList() {
      setLoading(true);
      setInitialLoading(true);
      let branchListTemp : any = await getBranchDropDown() || [];
      if (isEligibleAllBranch) {
        branchListTemp.splice(0, 0, { id: " ", name: AllBranch })
      } 
      if (returnSubBrandList().length > 0) {
        setSubBrandListFinal(arraySpliceInAllValue(returnSubBrandList(), AllSubBrand, false, "name", "id"));
      }
      setBranchListFinal(branchListTemp);
      let aryBranchTemp: BranchListObject[] = branchListTemp;
      let branchIdTemp = getBranchUser() || !isEligibleAllBranch ? getBranchId() : " ";
      let blnBackNavigation = comparePathForPagination(prevPath, RoutesList.appointmentList)
      
      if (blnBackNavigation) {
        if (appointmentListPaginationParams) {
          if (appointmentListPaginationParams.branchId) {
            branchIdTemp = appointmentListPaginationParams.branchId
          }

          setSelectedMediaSourceId(appointmentListPaginationParams.mediaSourceId || "");
          setSelectedMediaSourceName(appointmentListPaginationParams.mediaSourceName || AllSource);
          setSelectedDateRange(appointmentListPaginationParams.dateRange);
          setSelectedSubmissionDateRange({fromDate: appointmentListPaginationParams.fromSubmissionDate || "", toDate: appointmentListPaginationParams.toSubmissionDate || ""});
          setSelectedSubBrandName(appointmentListPaginationParams.subBrandName || AllSubBrand);
          setSelectedSubBrandId(appointmentListPaginationParams.subBrandId || " ");
          setSelectedGenderName(appointmentListPaginationParams.genderName || AllGender);
          setSelectedGenderId(appointmentListPaginationParams.gender || " ");
          setCurrentPage(appointmentListPaginationParams.pageNumber);

          if (appointmentListPaginationParams.serviceTreatmentIds) {
            validation.setFieldValue("sourceTypes", appointmentListPaginationParams.sourceTypes);
            validation.setFieldValue("sourceTypeNames", appointmentListPaginationParams.sourceTypeNames);
          }

          if (appointmentListPaginationParams.serviceTreatmentIds) {
            validation.setFieldValue("serviceTreatmentIds", appointmentListPaginationParams.serviceTreatmentIds);
            validation.setFieldValue("serviceTreatmentNames", appointmentListPaginationParams.serviceTreatmentNames);
          }

          if (appointmentListPaginationParams.statuses) {
            validation.setFieldValue("appointmentStatusIds", appointmentListPaginationParams.statuses);
            validation.setFieldValue("appointmentStatusNames", appointmentListPaginationParams.appointmentStatusNames);
          }
          
          validation.setValues((values) => ({
            ...values,
            name: appointmentListPaginationParams.name || "",
            contactNo: appointmentListPaginationParams.contactNo || ""
          }))
          appointmentRequestBody = appointmentListPaginationParams;
        }
      }
      else {
        setAppointmentListPaginationParams(undefined);
      }
      let aryAPI: any = [
        getMediaSourceDropdown(),
        getStaticReferenceWithType(Constants.appointmentSourceType),
        getServiceTreatmentDropdown(),
        getStaticReferenceWithType(Constants.appointmentStatusType),
        getStaticReferenceWithType(Constants.genderType)
      ];
      if (aryBranchTemp.length > 0) {
        let indexCurrentBranch = _.findIndex(aryBranchTemp, { id: branchIdTemp });
        indexCurrentBranch = indexCurrentBranch > -1 ? indexCurrentBranch : 0;
        branchIdTemp = aryBranchTemp[indexCurrentBranch].id;
        setSelectedBranchId(aryBranchTemp[indexCurrentBranch].id)
        setSelectedBranchName(aryBranchTemp[indexCurrentBranch].name)
        aryAPI.push(fetchAppointmentAPI(branchIdTemp));
      }
      let resultAPI = await Promise.all(aryAPI);
      setMediaSourceListFinal(arraySpliceInAllValue(resultAPI[0], AllSource, false, "name", "id"));
      setAppointmentStatusList(resultAPI[3]);
      setGenderListFinal(arraySpliceInAllValue(resultAPI[4], AllGender, false, "displayValue", "key"));
      let sourceTypeOptionListTemp: any = [];
      let serviceOptionListTemp: any = [];
      let appointmentStatusOptionListTemp: any = [];
      if (appointmentListPaginationParams && appointmentListPaginationParams.sourceTypes) {
        resultAPI[1].map((valueSourceTypeTemp) => {
        for (var a = 0; a < appointmentListPaginationParams.sourceTypes!.length; a++) {
          if(appointmentListPaginationParams.sourceTypes![a] ===  valueSourceTypeTemp.key){
            sourceTypeOptionListTemp.push({
              ...valueSourceTypeTemp,
              label: newSpaceBeforeCapitalLetter(valueSourceTypeTemp.displayValue),
              value: valueSourceTypeTemp.key
            })
          }}
        })
        setSelectedSourceTypeOptions(sourceTypeOptionListTemp);
      }
      if (appointmentListPaginationParams && appointmentListPaginationParams.serviceTreatmentIds) {
        resultAPI[2].map((valueServiceTemp) => {
        for (var a = 0; a < appointmentListPaginationParams.serviceTreatmentIds!.length; a++) {
          if(appointmentListPaginationParams.serviceTreatmentIds![a] ===  valueServiceTemp.id){
            serviceOptionListTemp.push({
              ...valueServiceTemp,
              label: valueServiceTemp.name,
              value: valueServiceTemp.id
            })
          }}
        })
        setSelectedServiceOptions(serviceOptionListTemp);
      }
      if (appointmentListPaginationParams && appointmentListPaginationParams.statuses) {
        resultAPI[3].map((valueAppointmentStatusTemp) => {
        for (var a = 0; a < appointmentListPaginationParams.statuses!.length; a++) {
          if(appointmentListPaginationParams.statuses![a] ===  valueAppointmentStatusTemp.key){
            appointmentStatusOptionListTemp.push({
              ...valueAppointmentStatusTemp,
              label: newSpaceBeforeCapitalLetter(valueAppointmentStatusTemp.displayValue),
              value: valueAppointmentStatusTemp.key
            })
          }}
        })
        setSelectedAppointmentStatusesOptions(appointmentStatusOptionListTemp);
      }

      setInitialLoading(false);
      setLoading(false);
    }

    if (!checkPermission([PermissionConstants.ManageAppointment], true)) {
      return;
    }

    fetchAppointmentList();

    return (() => {
      setAppointmentList([]);
      if (history) {
        const blnSetParams = comparePathForSetParams(history, RoutesList.appointmentList)
        if (!blnSetParams) {
          setAppointmentListPaginationParams(undefined);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (!initialLoading && triggerSearch !== 0) {
      fetchAppointmentAPI(selectedBranchId);
    }
  }, [triggerSearch])

  useEffect(() => {
    if (!initialLoading) {
      fetchAppointmentAPI(selectedBranchId);
    }
  }, [selectedMediaSourceId, selectedDateRange, selectedSubmissionDateRange, selectedSubBrandId, selectedGenderId]);

  async function fetchAppointmentAPI(branchId: string, pageNumber: number | undefined = undefined, blnDropDown: boolean = true) {
    setCurrentPage(pageNumber || appointmentRequestBody.pageNumber);
    blnDropDown ? setLocalLoading(true) : setLoading(true);
    await getAppointmentList({
      ...appointmentRequestBody,
      ...pageNumber && { pageNumber: pageNumber },
      ...branchId && { branchId: branchId },
    })
    blnDropDown ? setLocalLoading(false) : setLoading(false);
  }

  const onClickSearch = () => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setTriggerSearch((value) => value + 1)
  }

  const onClickDelete = async (row: AppointmentListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const refreshAppointmentData = async (blnDelete: boolean = false) => {
    fetchAppointmentAPI(selectedBranchId, blnDelete ? checkCurrentPageAfterDelete() : currentPage);
  }

  const checkCurrentPageAfterDelete = () => {
    let currentPageTemp = currentPage;
    if (appointmentList.length === 1 && currentPage !== 1) {
      setCurrentPage((value) => value - 1)
      currentPageTemp = currentPageTemp - 1;
    }

    return currentPageTemp;
  }

  const onSelectDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedDateRange(objDateRange);
  }

  const onSelectSubmissonDateRange = (objDateRange: any) => {
    setCurrentPage(1);
    setErrorMessage("");
    setSuccessMessage("");
    setSelectedSubmissionDateRange(objDateRange);
  }

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "customerName",
      text: intl.formatMessage({ id: "Name" }).toUpperCase(),
      formatter: (cellContent, row) => (
        blnPermissionManageCustomer && row.customerId
        ?
        <div>
          <Link to={row.customerId ? `/${RoutesList.customer}/view/${row.customerId}` : ""} target={Constants.blank} rel="noopener noreferrer">
            <Label className="pointer-clickable">{row.customerName || Constants.emptyData}</Label>
          </Link>
        </div>
        :
        <div>{row.isBlocker ? intl.formatMessage({ id: "Blocker" }) : row.customerName}</div>
      ),
    },
    {
      dataField: "email",
      text: intl.formatMessage({ id: "Email" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.email || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ApptContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="" style={{width: "120px"}}>
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "customerContactNo",
      text: intl.formatMessage({ id: "CustomerContactNo" }).toUpperCase(),
      hidden: true,
      formatter: (cellContent, row) => (
        <div className="" style={{width: "120px"}}>
          {row.customerContactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "leadContactNo",
      text: intl.formatMessage({ id: "LeadContactNo" }).toUpperCase(),
      hidden: true,
      formatter: (cellContent, row) => (
        <div className="" style={{width: "120px"}}>
          {row.leadContactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "gender",
      text: intl.formatMessage({ id: "Gender" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.gender || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "AppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) =>
        <>
          <div className="">
            {`${moment(row.appointStartDateTime).format(Constants.displayDateFormat)}`}
          </div>
          <div className="small-text">
            {moment(row.appointStartDateTime).format(Constants.displayTimeFormat)}
          </div>
        </>
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.serviceTreatmentNames || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "consultantName",
      text: intl.formatMessage({ id: "Consultant" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="">
          {row.consultantName || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "mediaSourceName",
      text: intl.formatMessage({ id: "MediaSource" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.mediaSourceName) || Constants.emptyData}
        </div>
      )
    },
    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
    },
    {
      dataField: "appointmentSourceType",
      text: intl.formatMessage({ id: "SourceType" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>
          {newSpaceBeforeCapitalLetter(row.appointmentSourceType)}
        </div>
      )
    },
    {
      ...returnSubBrandList().length > 0
      &&
      {
        dataField: "subBrandName",
        text: intl.formatMessage({ id: "SubBrand" }).toUpperCase(),
        formatter: (cellContent, row) => (
          <div>
            {newSpaceBeforeCapitalLetter(row.subBrandName)}
          </div>
        )
      }
    },
    {
      dataField: "isFollowUpOnly",
      text: intl.formatMessage({ id: "FollowUp" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <div className="flex-justfity-content-center">
          {row.isFollowUpOnly ? <div className="rounded-circle green-circle" /> : <div className="rounded-circle red-circle" />}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) => (
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor} />
      )
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              !row.isBlocker
              &&
              <TableActionButton 
                to={`/${RoutesList.appointment}?appointmentId=${row.id}&branchId=${row.branchId}&branchName=${row.branchName}&startDate=${moment(row.appointStartDateTime).format(Constants.displayDateFormat).toString()}&endDate=${moment(row.appointEndDateTime).add(1, 'days').format(Constants.displayDateFormat).toString()}`}
                type="calendar"/>
            }
            <TableActionButton 
              to={`/${RoutesList.appointmentList}${row.isBlocker ? "/blocker" : ""}/view/${row.id}`}
              type="view"/>
            {
              blnPermissionUpdateAppointment
              &&
              <TableActionButton 
                to={`/${RoutesList.appointmentList}${row.isBlocker ? "/blocker" : ""}/edit/${row.id}`}
                type="edit"/>
            }
            {
              ((blnPermissionDeleteAppointment && !row.isBlocker) || (blnPermissionDeleteBlocker && row.isBlocker))
              &&
              row.isAbleRescheduleOrDelete
              &&
              <TableActionButton 
                to="#"
                onClick={() => onClickDelete(row)}
                type="delete"/>
            }
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <ListViewLayout
        metaTitle={intl.formatMessage({ id: "ModuleNameWithOneDoc" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
        title={intl.formatMessage({ id: "Appointment" })}
        addButton={
          blnPermissionCreateAppointment
          &&
          <MyAddButton
            content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({id: "Appointment"})})}
            onClick={()=> {history.push(`/${RoutesList.appointmentList}/add`)}}
            linkTo={`/${RoutesList.appointmentList}/add`}
            disable={localLoading || loading}
          />
        }>
        {
          !initialLoading
          &&
          <>
          <Row>
            {
              subBrandListFinal.length > 0
              &&
              <DoubleColumnRowParent>
                <GeneralSelectDropdown
                  title={intl.formatMessage({ id: "SubBrand" })}
                  name="subBrandName"
                  options={subBrandListFinal}
                  labelField="name"
                  valueField="id"
                  initialLabel={selectedSubBrandName}
                  initialValue={selectedSubBrandId}
                  disabled={localLoading || loading}
                  setFieldLabel={setSelectedSubBrandName}
                  setFieldValue={setSelectedSubBrandId}
                  onChange={(value) => {
                    setCurrentPage(1);
                    setErrorMessage("");
                    setSuccessMessage("");
                  }}
                  validationRequired={true}
                />
              </DoubleColumnRowParent>
            }
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Branch" })}
                name="branchName"
                options={branchListFinal}
                labelField={"name"}
                valueField={"id"}
                initialLabel={selectedBranchName}
                initialValue={selectedBranchId}
                disabled={localLoading || loading}
                setFieldLabel={setSelectedBranchName}
                setFieldValue={setSelectedBranchId}
                onChange={(value) => {
                  fetchAppointmentAPI(value, 1, true);
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
                validationRequired={true}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "AppointmentDate"})})}
                name={"DateRange"}
                disabled={localLoading || loading}
                validationRequired={true}
                onChangeFunction={onSelectDateRange}
                initialValue={selectedDateRange} />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              <GeneralDateRange
                title={intl.formatMessage({ id: "DateRangeWithField" }, { field: intl.formatMessage({id: "SubmissionDate"})})}
                name={"SubmissionDateRange"}
                disabled={localLoading || loading}
                validationRequired={true}
                onChangeFunction={onSelectSubmissonDateRange}
                initialValue={selectedSubmissionDateRange} />
            </DoubleColumnRowParent>
          </Row>
          <Row>
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "MediaSource" })}
                name="mediaSource"
                styles={{}}
                options={mediaSourceListFinal}
                labelField="name"
                valueField="id"
                initialLabel={selectedMediaSourceName}
                initialValue={selectedMediaSourceId}
                setFieldLabel={setSelectedMediaSourceName}
                setFieldValue={setSelectedMediaSourceId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
            <DoubleColumnRowParent>
              {
                !initialLoading
                &&
                <DropDownWithTitleMultiSelect
                  name="appointmentStatuses"
                  title={intl.formatMessage({ id: "LabelWithStatus" }, { field: intl.formatMessage({ id: "Appointment" }) })}
                  specifyReturnFieldName={[{ "field": "appointmentStatusIds", "value": "key" }]}
                  returnFieldWithLabel={false}
                  labelField={"displayValue"}
                  valueField={"key"}
                  options={appointmentStatusList}
                  disabled={localLoading || loading}
                  blnValueWithNewSpace={true}
                  initialValue={selectedAppointmentStatusesOptions}
                  validationRequired={false}
                  validation={validation}
                  onBlurFunction={()=> {
                    onClickSearch()
                  }}
                />
              }
            </DoubleColumnRowParent>
            <DoubleColumnRowParent >
              <DropDownWithTitleMultiSelect
                name="appointmentSourceType"
                title={intl.formatMessage({ id: "AppointmentSourceType" })}
                specifyReturnFieldName={[{ "field": "sourceTypes", "value": "key" }]}
                returnFieldWithLabel={false}
                labelField={"displayValue"}
                valueField={"key"}
                options={appointmentSourceType}
                disabled={localLoading || loading}
                blnValueWithNewSpace={true}
                initialValue={selectedSourceTypeOptions}
                validationRequired={false}
                validation={validation}
                onBlurFunction={()=> {
                  onClickSearch()
                }}
              />
            </DoubleColumnRowParent>
          </Row>
          </>
        }
        <Row>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "Name" })}
              name="name"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "Name" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          <DoubleColumnRowParent>
            <GeneralInput
              title={intl.formatMessage({ id: "ContactNo" })}
              name="contactNo"
              type="text"
              disabled={localLoading || loading}
              validation={validation}
              placeholder={intl.formatMessage({ id: "ContactNo" })}
              onBlurFunction={()=> {
                onClickSearch()
              }}
            />
          </DoubleColumnRowParent>
          {
            !initialLoading
            &&
            <DoubleColumnRowParent>
              <DropDownWithTitleMultiSelect
                name={"serviceTreatmentIds"}
                title={intl.formatMessage({ id: "ServiceTreatments" })}
                specifyReturnFieldName={[{ "field": "serviceTreatmentIds", "value": "id" }, { "field": "serviceTreatmentNames", "value": "name" }]}
                returnFieldWithLabel={false}
                labelField={"name"}
                valueField={"id"}
                options={serviceTreatmentDropdownList}
                disabled={localLoading || loading}
                initialValue={selectedServiceOptions}
                validationRequired={false}
                validation={validation}
                onBlurFunction={()=> {
                  onClickSearch()
                }} />
            </DoubleColumnRowParent>
          }
          {
            !initialLoading
            &&
            <DoubleColumnRowParent>
              <GeneralSelectDropdown
                title={intl.formatMessage({ id: "Gender" })}
                name="gender"
                options={genderListFinal}
                labelField="displayValue"
                valueField="key"
                initialLabel={selectedGenderName}
                initialValue={selectedGenderId}
                setFieldLabel={setSelectedGenderName}
                setFieldValue={setSelectedGenderId}
                disabled={localLoading || loading}
                validationRequired={true}
                onChange={() => {
                  setCurrentPage(1);
                  setErrorMessage("");
                  setSuccessMessage("");
                }}
              />
            </DoubleColumnRowParent>
          }
        </Row>
        {
          localLoading && !appointmentDetailModal
          ?
          <Loading />
          :
          <PaginationTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            totalItem={appointmentTotalItems}
            tableColumns={AppointmentColumns}
            data={appointmentList}
            emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
            objSorted={defaultSorted}
            isModal={appointmentDetailModal}
            blnSupportColumnFilter={true}
            branchId={selectedBranchId}
            name={validation.values.name}
            contactNo={validation.values.contactNo}
            mediaSourceId={selectedMediaSourceId}
            mediaSourceName={selectedMediaSourceName}
            sourceTypes={validation.values.sourceTypes}
            sourceTypeNames={validation.values.sourceTypeNames}
            dateRange={selectedDateRange}
            fromSubmissionDate={selectedSubmissionDateRange?.fromDate}
            toSubmissionDate={selectedSubmissionDateRange?.toDate}
            serviceTreatmentIds={validation.values.serviceTreatmentIds}
            serviceTreatmentNames={validation.values.serviceTreatmentNames}
            statuses={validation.values.appointmentStatusIds}
            appointmentStatusNames={validation.values.appointmentStatusNames}
            subBrandId={selectedSubBrandId}
            subBrandName={selectedSubBrandName}
            gender={selectedGenderId}
            genderName={selectedGenderName}
            requestAPI={getAppointmentList} />
        }
        <DeleteModal
          displayName={""}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteAppointment(selectedRow?.id || "", "");
            await refreshAppointmentData(true);
            setLoading(false);
          }} />
        {
          appointmentDetailModal
          &&
          <AppointmentDetailModal
            blnShow={appointmentDetailModal}
            blnListView={true}
            setModal={setAppointmentDetailModal}
            selectedAppointmentDetail={appointmentDetail}
            setSelectedAppointmentDetail={setAppointmentDetail}
            refreshAppointmentData={refreshAppointmentData}
            selectedBranchId={selectedBranchId}
            windowSize={windowSize}/>
        }
      </ListViewLayout>
    </div>
  );
}

export default observer(AppointmentList);
