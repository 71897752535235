import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Row, Col, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import * as Yup from "yup";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import _ from "lodash";
import { useFormik } from "formik";
import { history } from "../..";
import Loading from "../../app/components/loading/Loading";
import { useIntl } from "react-intl";
import LeadProfileDetail from "./subView/LeadProfileDetail";
import moment from "moment";
import classNames from "classnames";
import { appointmentStatusColor, checkPermission, IncludesLocationPathName } from "../../app/common/function/function";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import PaginationTable from "../../app/components/table/PaginationTable";
import MyAddButton from "../../app/components/form/MyAddButton";
import DeleteModal from "../../app/components/modal/DeleteModal";
import { FollowUpListObject } from "../../app/models/lead";
import FollowUpHistoryModal from "./Modal/FollowUpHistoryModal";
import CloseRecordConfirmationModal from "./Modal/CloseRecordConfirmationModal";
import { burgerMenu } from "../../app/models/common";
import TableActionButton from "../../app/components/table/TableActionButton";
import HistoryIcon from 'mdi-react/HistoryIcon';
import CalendarAccountIcon from 'mdi-react/CalendarAccountIcon';
import GeneralStatus from "../../app/components/form/GeneralStatus";
import NotInterestedConfirmationModal from "./Modal/NotInterestedConfirmationModal";
import ReopenConfirmationModal from "./Modal/ReopenConfirmationModal";
import ActionPromptModal from "../../app/components/modal/ActionPromptModal";

const LeadFollowUp = () => {
  //Use Params
  const { id } = useParams();
  const intl = useIntl();
  //Use Store
  const { leadStore, appointmentStore, commonStore } = useStore();
  const { errorMessage, successMessage, loading, windowSize, totalItem, setErrorMessage, setSuccessMessage, setLoading, setShowDeletePrompt } = commonStore;
  const { leadDetail, getLeadWithId, followUpList, changeLeadStatus, exportLead, getFollowUp, getFollowUpWithId, followUpDetail, setFollowUpDetail, addFollowUp, updateFollowUp, deleteFollowUp, followUpTotalItems, setLeadDetail } = leadStore;
  const { appointmentList, getAppointmentList, appointmentTotalItems } = appointmentStore;

  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${intl.formatMessage({ id: "FollowUp" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "Lead" }), urlPath: RoutesList.lead }];
  const [isMenu, setIsMenu] = useState(false);
  const [tabBar, setTabBar] = useState([intl.formatMessage({ id: "FollowUpHistory" }).toUpperCase(), intl.formatMessage({ id: "Appointment" }).toUpperCase()]);

  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageAppointment, setCurrentPageAppointment] = useState(1);
  const pageSize = Constants.defaultPageSize;
  const [followUpHistoryModal, setFollowUpHistoryModal] = useState(false);
  const [reopenConfirmationModal, setReopenConfirmationModal] = useState(false);
  const [notInterestedConfirmationModal, setNotInterestedConfirmationModal] = useState(false);
  const [closeRecordConfirmationModal, setCloseRecordConfirmationModal] = useState(false);
  const [blnAction, setBlnAction] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState<FollowUpListObject | undefined>(undefined);
  const [localLoading, setLocalLoading] = useState(false);
  const [loadingFollowUpHistory, setLoadingFollowUpHistory] = useState(false);
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [lapsedAppointmentModal, setLapsedAppointmentModal] = useState(false);
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([{ label: `${intl.formatMessage({ id: "ExportAsTemplate" })}`, onFunction: () => { exportLeadReport(true) } }]);
  const blnPermissionManageFollowUpHistory = checkPermission([PermissionConstants.ManageFollowUpHistory])
  const blnPermissionCreateFollowUpHistory = checkPermission([PermissionConstants.CreateFollowUpHistory])
  const blnPermissionUpdateFollowUpHistory = checkPermission([PermissionConstants.UpdateFollowUpHistory])
  const blnPermissionDeleteFollowUpHistory = checkPermission([PermissionConstants.DeleteFollowUpHistory])

  //Default Sorting Type
  const defaultSorted = [
    // {
    //   dataField: "id",
    //   order: "desc",
    // },
  ];

  //Table Content UI
  const AppointmentColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "appointStartDateTime",
      text: intl.formatMessage({ id: "AppointmentDate" }).toUpperCase(),
      formatter: (cellContent, row) => <>{moment(row.appointStartDateTime).format(Constants.displayDateAndTimeFormat)}</>, //Custom UI to display
    },
    {
      dataField: "branchName",
      text: intl.formatMessage({ id: "Branch" }).toUpperCase(),
    },
    {
      dataField: "serviceTreatmentNames",
      text: intl.formatMessage({ id: "ServiceTreatments" }).toUpperCase(),
    },
    {
      dataField: "madeBy",
      text: intl.formatMessage({ id: "MadeBy" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.madeBy || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div>{row.remark || Constants.emptyData}</div>
      ),
    },
    {
      dataField: "contactNo",
      text: intl.formatMessage({ id: "ApptContactNo" }).toUpperCase(),
      formatter: (cellContent, row) => (
        <div className="" >
          {row.contactNo || Constants.emptyData}
        </div>
      ),
    },
    {
      dataField: "appointmentStatus",
      text: intl.formatMessage({ id: "Status" }).toUpperCase(),
      headerStyle: { textAlign: 'center' },
      formatter: (cellContent, row) =>
        <GeneralStatus
          status={row.appointmentStatus}
          statusColorFunction={appointmentStatusColor}/>
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              checkPermission([PermissionConstants.ManageAppointment])
              &&
              <TableActionButton 
                to={`/${RoutesList.appointment}/view/${row.id}`}
                type={'view'}/>
            }
            {
                <TableActionButton 
                  to={`/${RoutesList.appointment}?appointmentId=${row.id}&branchId=${row.branchId}&branchName=${row.branchName}&startDate=${moment(row.appointStartDateTime).format(Constants.displayDateFormat).toString()}&endDate=${moment(row.appointEndDateTime).add(1, 'days').format(Constants.displayDateFormat).toString()}`}
                  type="calendar"/>
            }
          </div>
        </>
      ),
    },
  ];

  const FollowUpHistoryColumns = [
    {
      dataField: "id", //field name from array to display
      text: "id", //display name
      sort: true, //sort function enable
      hidden: true, //hide UI
      formatter: (cellContent, row) => <>{row.id}</>, //Custom UI to display
    },
    {
      dataField: "remark",
      text: intl.formatMessage({ id: "Remark" }).toUpperCase(),
      style: {
        width: '30%'
      },
    },
    {
      dataField: "followedUpUserName",
      text: intl.formatMessage({ id: "FollowedUpUser" }).toUpperCase(),
    },
    {
      dataField: "followedUpOn",
      text: intl.formatMessage({ id: "FollowedUpOn" }).toUpperCase(),
      formatter: (cellContent, row) => <>{moment(row.followedUpOn).format(Constants.displayDateAndTimeFormat)}</>, //Custom UI to display
    },
    {
      dataField: "action",
      isDummyField: true,
      text: intl.formatMessage({ id: "Action" }).toUpperCase(),
      headerStyle: { textAlign: 'center', width: Constants.searchBarWidth },
      hidden: !checkPermission([PermissionConstants.ManageFollowUpHistory, PermissionConstants.UpdateFollowUpHistory, PermissionConstants.DeleteFollowUpHistory]),
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3 flex-justfity-content-center">
            {
              blnPermissionManageFollowUpHistory
              &&
              <TableActionButton 
                type={'view'}
                onClick={() => onClickFollowUpAction(Constants.view, row)}/>
            }
            {
              blnPermissionUpdateFollowUpHistory
              &&
              <TableActionButton 
                type={'edit'}
                onClick={() => onClickFollowUpAction(Constants.update, row)}/>
            }
            {
              blnPermissionDeleteFollowUpHistory
              &&
              <TableActionButton 
                type={'delete'}
                onClick={() => onClickDelete(row)}/>
            }
          </div>
        </>
      ),
    },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: leadDetail || {
      id: "",
      leadNo: "",
      name: "",
      mobileNo: "",
      email: "",
      sourceId: "",
      mediaSource: "",
      branchId: "",
      branchName: "",
      submissionDate: "",
      status: "",
      brandId: "",
      brandName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postcode: "",
      countryId: "",
      countryName: "",
      stateId: "",
      stateName: "",
      consultantId: "",
      consultantName: "",
      customerId: "",
      customerName: "",
      customerNo: "",
      serviceTreatmentId: "",
      serviceTreatmentName: "",
      promoRemark: "",
      voucherReferenceNo: "",
      remark: "",
      lastFollowUpDate: "",
      lastAppointmentDate: "",
      turnUpDate: "",
      marcomConsultantId: "",
      marcomConsultantName: "",
      subBrandName: "",
      subBrandId: "",
      isRejoin: false,
      isCrossBrand: false,
      gender: "",
      type: "",
      isLapsed: false
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
    }
  });

  const blnAddAppointment = checkPermission([PermissionConstants.CreateAppointment]) && validation.values.status !== Constants.turnedUp;
  const blnDisplayUpdateButton =  ((checkPermission([PermissionConstants.UpdateLead]) && leadDetail?.type == Constants.lead) || (checkPermission([PermissionConstants.UpdateWalkInLead]) && leadDetail?.type == Constants.walkIn));

  async function fetchCustomerDetailAPI() {
    setLoading(true);
    if (id && !addAction) {
      let aryAPI: any = [
        getLeadWithId(id),
        getFollowUp({ pageNumber: currentPage, pageSize: pageSize, leadId: id }),
        getAppointmentList({ pageNumber: 1, pageSize: pageSize, branchId: "", leadId: id })
      ];
      await Promise.all(aryAPI);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateLead], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageLead], true)) {
        return;
      }
    }

    fetchCustomerDetailAPI();
    return (() => {
      if (id && !addAction) {
        setFollowUpDetail(undefined);
        setLeadDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(async () => {
        setSuccessMessage("");
        await fetchCustomerDetailAPI();
      }, Constants.dismissSuccessMessage)
    }
  }, [successMessage])

  useEffect(() => {
    function addActionSubTitle(indexAction) {
      if (indexAction < 0) {
        burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "Action" })}`, 
          onFunction: () => {},
          blnSubTitle: true
        })
        return 1;
      }
      return indexAction;
    }

    if (leadDetail) {
      //Remove close button everytime before other action button
      let indexCloseRecordTemp = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "CloseRecord" })}`})
      if (indexCloseRecordTemp > -1) {
        burgerMenuListContent.splice(indexCloseRecordTemp, 1);
      }

      if (viewAction && blnDisplayUpdateButton) {
        let indexAction = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "Action" })}`})
        let indexEdit = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "EditAction" })}`})
        if (indexEdit < 0 && leadDetail.status !== Constants.closed && leadDetail.type !== Constants.walkIn) {
          indexAction = addActionSubTitle(indexAction);
          burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "EditAction" })}`, onFunction: () => { history.push(`/${RoutesList.lead}/edit/${id}`) }, blnSubView: true })
        }
        else if (leadDetail.status === Constants.closed && indexEdit > -1) {
          burgerMenuListContent.splice(indexEdit, 1);
          let indexActionTemp = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "Action" })}`})
          if (indexActionTemp > -1) {
            burgerMenuListContent.splice(indexActionTemp, 1);
          }
        }

        let indexNotInterested = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "NotInterested" })}`})
        if (leadDetail.status !== Constants.others && leadDetail.status !== Constants.closed && indexNotInterested < 0) {
          indexAction = addActionSubTitle(indexAction);
          burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "NotInterested" })}`, onFunction: () => { setNotInterestedConfirmationModal(true) }, blnSubView: true })
        }
        else if ((leadDetail.status === Constants.others || leadDetail.status === Constants.closed) && indexNotInterested > -1) {
          burgerMenuListContent.splice(indexNotInterested, 1);
        }

        let indexReopen = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "Reopen" })}`})
        if (leadDetail.status === Constants.others && indexReopen < 0) {
          indexAction = addActionSubTitle(indexAction);
          burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "Reopen" })}`, onFunction: () => { setReopenConfirmationModal(true) }, blnSubView: true })
        }
        else if (leadDetail.status !== Constants.others && indexReopen > -1) {
          burgerMenuListContent.splice(indexReopen, 1);
        }
        
        let indexCloseRecord = _.findIndex(burgerMenuListContent, {label: `${intl.formatMessage({ id: "CloseRecord" })}`})
        if (leadDetail.status !== Constants.closed && indexCloseRecord < 0) {
          indexAction = addActionSubTitle(indexAction);
          burgerMenuListContent.push({ label: `${intl.formatMessage({ id: "CloseRecord" })}`, onFunction: () => { setCloseRecordConfirmationModal(true) }, blnSubView: true })
        }
        else if (leadDetail.status === Constants.closed && indexCloseRecord > -1) {
          burgerMenuListContent.splice(indexCloseRecord, 1);
        }
      }
    }
  }, [leadDetail])

  const isClosedRecord = validation.values.status == Constants.closed;

  const onClickDelete = async (row: FollowUpListObject) => {
    setSelectedRow(row);
    setShowDeletePrompt(true);
  }

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  const onClickFollowUpAction = async (blnActionTemp: string, row?: FollowUpListObject) => {
    if (blnActionTemp === Constants.add) {
      setBlnAction(blnActionTemp);
      setFollowUpHistoryModal(true);
    }
    else {
      if (row) {
        setLoadingFollowUpHistory(true);
        let resultFollowUpWithId = await getFollowUpWithId(row.id);
        setLoadingFollowUpHistory(false);
        if (resultFollowUpWithId?.status === Constants.success) {
          setBlnAction(blnActionTemp);
          setFollowUpHistoryModal(true);
        }
      }
    }
  }

  const exportLeadReport = async (blnExport: boolean) => {
    setLocalLoading(true);
    if (blnExport) {
      await (exportLead({ exportType: 2, selectedIds: [id] }))
    }
    setLocalLoading(false);
  }

	const returnTabBarIcon = (valueTab: string) => {
    let iconTabBar: any = null;
    if (valueTab === intl.formatMessage({ id: "Appointment" }).toUpperCase()) {
      iconTabBar = <CalendarAccountIcon size={Constants.menuIconSize} />;
    }
    else if (valueTab === intl.formatMessage({ id: "FollowUpHistory" }).toUpperCase()) {
      iconTabBar = <HistoryIcon size={Constants.menuIconSize} />
    }
		return (
      <span
        key={`${valueTab}_tab`}
        className="d-none tabbar-display-block">
        {iconTabBar ? iconTabBar : null} {valueTab}
      </span>
    )
	}

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading && !successMessage ? (
          <Loading />
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}>
            <Row>
              <Col xl="3" lg="4" sm="4">
                <LeadProfileDetail
                  validation={validation}
                  isMenu={isMenu}
                  toggleMenu={toggleMenu}
                  setNotInterestedConfirmationModal={setNotInterestedConfirmationModal}
                  setCloseRecordConfirmationModal={setCloseRecordConfirmationModal}
                  setReopenConfirmationModal={setReopenConfirmationModal}
                  windowSize={windowSize}
                  blnDisplayUpdateButton={blnDisplayUpdateButton}
                />
              </Col>
              <Col xl="9" lg="8" sm="8">
                <div className="standard-layout">
                  <Nav className="nav nav-tabs nav-tabs-custom">
                    {tabBar.map((value, indexTab) => (
                      <NavItem key={`tabkey_${indexTab}`}>
                        <NavLink
                          disabled={indexTab == 1 && (false)}
                          style={{ cursor: "pointer" }}
                          className={classNames({
                            active:
                              activeTab ===
                              String(indexTab + 1),
                          })}
                          onClick={() => {
                            toggle(String(indexTab + 1));
                          }}
                        >
                          {/* {value} */}
                          {returnTabBarIcon(value)}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    className="margin-top-24">
                    {
                      PermissionConstants.ManageFollowUpHistory &&
                      <TabPane tabId="1">
                        <Col lg="12">
                          <div className="table-responsive">
                            <div className="flex-right margin-bottom--12">
                              {
                                !isClosedRecord &&
                                blnPermissionCreateFollowUpHistory &&
                                <MyAddButton
                                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
                                  onClick={() => onClickFollowUpAction(Constants.add)}
                                  disable={localLoading || loading || loadingFollowUpHistory} />
                              }
                            </div>
                            <PaginationTable
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              pageSize={pageSize}
                              totalItem={followUpTotalItems}
                              tableColumns={FollowUpHistoryColumns}
                              data={followUpList}
                              localLoading={loadingFollowUpHistory}
                              setLocalLoading={setLoadingFollowUpHistory}
                              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "FollowUp" }) })}
                              objSorted={defaultSorted}
                              hideSearchBar={true}
                              leadId={id}
                              requestAPI={getFollowUp} />
                          </div>
                        </Col>
                      </TabPane>
                    }
                    {
                      PermissionConstants.ManageAppointment &&
                      <TabPane tabId="2">
                        <Col lg="12">
                          <div className="table-responsive">
                            <div className={`flex-right ${blnAddAppointment ? "margin-bottom--12" : "margin-bottom--24"}`}>
                              {
                                blnAddAppointment
                                &&
                                !isClosedRecord
                                &&
                                <MyAddButton
                                  content={intl.formatMessage({ id: "NewWithModuleName" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                                  onClick={() => {
                                    if (leadDetail?.isLapsed) {
                                      setLapsedAppointmentModal(true);
                                      return;
                                    }

                                    history.push({
                                      pathname: `/${RoutesList.appointment}`,
                                      state: {
                                        detail: {
                                          branchId: validation.values.branchId,
                                          branchName: validation.values.branchName,
                                          consultantId: validation.values.consultantId,
                                          consultantName: validation.values.consultantName,
                                          leadId: validation.values.id,
                                          leadName: validation.values.name,
                                          appointmentSourceType: Constants.lead,
                                          selectedServiceTreatmentIdFromLead: validation.values.serviceTreatmentId,
                                          subBrandName: validation.values.subBrandName,
                                          subBrandId: validation.values.subBrandId,
                                          addAppointmentAction: true
                                        }
                                      }
                                    });
                                  }}
                                  disable={localLoading || loading} />
                              }
                            </div>
                            <PaginationTable
                              currentPage={currentPageAppointment}
                              setCurrentPage={setCurrentPageAppointment}
                              pageSize={pageSize}
                              totalItem={appointmentTotalItems}
                              tableColumns={AppointmentColumns}
                              data={appointmentList}
                              localLoading={loadingAppointment}
                              setLocalLoading={setLoadingAppointment}
                              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: intl.formatMessage({ id: "Appointment" }) })}
                              objSorted={defaultSorted}
                              hideSearchBar={true}
                              leadId={id}
                              requestAPI={getAppointmentList} />
                          </div>
                        </Col>
                      </TabPane>
                    }
                  </TabContent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
        {
          followUpHistoryModal &&
          <FollowUpHistoryModal
            blnShow={followUpHistoryModal}
            blnAction={blnAction}
            setModal={setFollowUpHistoryModal}
            leadId={id}
            addFollowUp={addFollowUp}
            updateFollowUp={updateFollowUp}
            selectedFollowUpDetail={followUpDetail}
            setFollowUpDetail={setFollowUpDetail}
            setErrorMessage={setErrorMessage} />
        }
        <DeleteModal
          displayName={selectedRow?.remark}
          onDeleteClick={async () => {
            setLoading(true);
            await deleteFollowUp(selectedRow?.id || "");
            let currentPageTemp = currentPage;
            if (followUpList.length === 1 && currentPage !== 1) {
              setCurrentPage((value) => value - 1)
              currentPageTemp = currentPageTemp - 1;
            }
            await getFollowUp({ pageNumber: currentPageTemp, pageSize: pageSize, leadId: id })
            setLoading(false);
          }} />
        {
          closeRecordConfirmationModal &&
          <CloseRecordConfirmationModal
            blnShow={closeRecordConfirmationModal}
            setModal={setCloseRecordConfirmationModal}
            changeLeadStatus={changeLeadStatus}
            leadId={id}
            leadDetail={leadDetail}
          />
        }
        {
          notInterestedConfirmationModal &&
          <NotInterestedConfirmationModal
            blnShow={notInterestedConfirmationModal}
            setModal={setNotInterestedConfirmationModal}
            changeLeadStatus={changeLeadStatus}
            leadId={id}
            leadDetail={leadDetail}
          />
        }
        {
          reopenConfirmationModal &&
          <ReopenConfirmationModal
            blnShow={reopenConfirmationModal}
            setModal={setReopenConfirmationModal}
            changeLeadStatus={changeLeadStatus}
            leadId={id}
            leadDetail={leadDetail}
          />
        }
        {
          lapsedAppointmentModal
          &&
          <ActionPromptModal 
            title={`${intl.formatMessage({ id: "LapsedAppointment" })}`}
            primaryActionTitle={`Close`}
            showPrompt={lapsedAppointmentModal}
            setShowPrompt={setLapsedAppointmentModal}
            blnHideCancelButton={true}
            blnShowCloseButton={true}
            onPrimaryClick={()=> {}}
          />
        }
      </DetailViewLayout>
    </div>
  );
}

export default observer(LeadFollowUp);
