import { useEffect, useState } from "react";
import {
  Modal,
} from "reactstrap";
import { useIntl } from "react-intl";
import _ from 'lodash';
import { Constants } from "../../constants/Constants";
import PaginationTable from "../table/PaginationTable";
import PaginationTableWithoutApi from "../table/PaginationTableWithoutApi";
import { isTablet } from "react-device-detect";

interface Props {
  blnShow: boolean;
  setModal: Function;
  title: string;
  options: any; //Dropdown data to display
  columns: any;
  keyField: string;
  fieldName: string;
  onSelectFunction?: Function;
  onDeleteFunction?: Function;
  validation: any;
  blnPagination?: boolean;
  totalItem?: number;
  requestAPI?: Function;
  paginationSubmitField?: any;
  alertMessage?: string;
  modalSize?: string;
  modalSubtitleBadge?: string[];
  topRightButton?: any;
  fullScreen?: boolean;
}

export default function SelectWithTableListModal(props: Props) {
  const intl = useIntl();

  const [selectedRow, setSelectedRow] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = Constants.defaultPageSize;

  const clearData = () => {
    setSelectedRow("");
    setCurrentPage(1);
  }

  const hideModal = () => {
    props.setModal(false);
    clearData();
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (String(e.target).includes(Constants.htmlButton)){
        if (e.target.id) {
          if (e.target.id === Constants.selectWithTableListDeleteButton) {
            if (props.onDeleteFunction) {
              props.onDeleteFunction(row);
            }
            return;
          }
        }
        if(props.onSelectFunction){
          props.onSelectFunction(row);
          clearData();
        }
      }
    }
  };

  const selectRowFunction = (row, isSelect, rowIndex) => {
    setSelectedRow(row);
  }; 

  useEffect(()=> {
    let indexTemp = _.findIndex(props.options, {[props.keyField]: props.validation.values[props.fieldName]});
    if (indexTemp > -1) {
      setSelectedRow(props.options[indexTemp])
    }
  }, [props.validation.values[props.fieldName]])

  return (
    <Modal
      isOpen={props.blnShow}
      toggle={() => {
        hideModal();
      }}
      style={{...isTablet && !props.fullScreen && {width: '100%', maxWidth: '75%'}}}
      size={props.modalSize ? props.modalSize : "xl"}
      fullscreen = {props.fullScreen ? props.fullScreen : false}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title margin-top-0">{props.title}</h5>
        <button
          type="button"
          onClick={() => {
            hideModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        { 
          props.alertMessage && 
          <div>
            <p className="modal-title font-size-14 error_require_text mb-3">{props.alertMessage}</p>
          </div>
        }
        {
          props.modalSubtitleBadge &&
          <div>
            {
              props.modalSubtitleBadge.map((valueModalSubtitleBadge, key)=> (
                <span key={`badge_${key}`} className="badge bg-info ms-2 align-bottom mb-3 margin-left-16" style={{ fontSize: "15px" }}>
                  {valueModalSubtitleBadge}
                </span>
              ))
            }
          </div>
        }        
        {
          props.topRightButton
          &&
          <div className={`flex-right margin-right-20 ${!props.blnPagination ? "mb-3" : ""}`}>
            {props.topRightButton()}
          </div>
        }
        <div className="table-responsive" style={{paddingLeft: "16px", paddingRight: "16px"}}>
          {
            !props.blnPagination
            ?
            <div className={props.topRightButton ? "" : "mt-3"}>
              <PaginationTableWithoutApi 
                title={props.title}
                options={props.options}
                columns={props.columns}
                keyField={props.keyField}
                rowEvents={rowEvents}
                selectRow={{
                  mode: "radio",
                  hideSelectColumn: true,
                  clickToSelect: true,
                  // bgColor: Constants.placeholderColor,
                  selected: !!selectedRow && [selectedRow[props.keyField]],
                  onSelect: selectRowFunction
                }}/>
            </div>
            :
            <PaginationTable
              bordered={true}
              keyField={props.keyField}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              totalItem={props.totalItem || 0}
              tableColumns={props.columns}
              data={props.options}
              rowEvents={ rowEvents }
              emptyTextDisplay={intl.formatMessage({ id: "EmptyItemDisplay" }, { moduleName: props.title})}
              requestAPI={props.requestAPI!}
              selectRow={{
                mode: "radio",
                hideSelectColumn: true,
                clickToSelect: true,
                bgColor: Constants.placeholderColor,
                selected: !!selectedRow && [selectedRow[props.keyField]],
                onSelect: selectRowFunction
              }}
              {...props.paginationSubmitField}
              blnRemoveBackground={true} />
          }
        </div>
      </div>
    </Modal>
  )
}