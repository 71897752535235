import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { checkPermission, displayTypeWithColor } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import { history } from "../..";
import '../../assets/css/verticalFamilyTree.css';
import { Constants } from "../../app/constants/Constants";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";

const BrandSiteMap = () => {
	//Use Params
	const { id } = useParams();

  //Use INTL
  const intl = useIntl();
  
	//Use Store
	const { brandStore, commonStore } = useStore();
	const {
    windowSize,
		loading,
		setLoading,
	} = commonStore;
	const { brandDetail, setBrandDetail, getBrandWithId } = brandStore;
	const displayTitle = `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "Brand"})} ${intl.formatMessage({id: "SiteMap"})}`;
	const breadCrumbList = [{ title: intl.formatMessage({id: "Brand"}), urlPath: RoutesList.brand }, { title:   `${intl.formatMessage({id: "View"})} ${intl.formatMessage({id: "Brand"})}`, urlPath: `${RoutesList.brand}/view/${id}` }];

  const brandColor = "lightblue";
  const businessEntityColor = Constants.lightorange;
  const branchColor = Constants.lightpink; 

  const blnPermissionManageBrand = checkPermission([PermissionConstants.ManageBrand]);
  const blnPermissionManageBusinessEntity = checkPermission([PermissionConstants.ManageBusinessEntity]);
  const blnPermissionManageBranch = checkPermission([PermissionConstants.ManageBranch]);

	useEffect(() => {
		async function fetchBrandSiteMapAPI() {
			setLoading(true);
      if (id) {
			  await getBrandWithId(id);
      }
      else {
        history.push(`/${RoutesList.notFound}`)
        return;
      }
			setLoading(false);
		} 
    
    if (!checkPermission([PermissionConstants.ManageBrand], true)) {
      return;
    }

    fetchBrandSiteMapAPI();
    
    return (()=> {
      setBrandDetail(undefined);
    })
	}, []);

	return (
		<div>
			<DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        // burgerMenuList={[]}
        blnShowPrint={true}
        auditTrailId={""}>
        {loading ? (
          <Loading />
        ) : (
          <div className="tree-vertical">
            <style type="text/css" media="print">
              {" @page { size: 20in 17in; } "}
            </style>
            <div className={windowSize.innerWidth > Constants.innerWidthCompare770 ? "flex-right" : ""} style={{marginRight: "42px"}}>
              {displayTypeWithColor(brandColor, intl.formatMessage({id: "Brand"}))}
              {displayTypeWithColor(businessEntityColor, intl.formatMessage({id: "BusinessEntity"}))}
              {displayTypeWithColor(branchColor, intl.formatMessage({id: "Branch"}))}
            </div>
            <ul>
              <li>
                <a href={blnPermissionManageBrand ? `/${RoutesList.brand}/view/${id}` : "#"} target={Constants.blank} rel="noopener noreferrer" style={{fontSize: "16px", padding: "18px"}}>{brandDetail?.name}</a>      
                {
                  brandDetail?.businessEntities
                  &&
                  brandDetail?.businessEntities.length > 0
                  &&
                  <ul>
                    {
                      brandDetail?.businessEntities.map((valueBusinessEntity, indexBusinessEntity)=> (
                        <li key={`business-entity-${indexBusinessEntity}`}> 
                          <a href={blnPermissionManageBusinessEntity ? `/${RoutesList.businessEntity}/view/${valueBusinessEntity.id}` : "#"}  target={Constants.blank} rel="noopener noreferrer" style={{backgroundColor: businessEntityColor, borderColor: businessEntityColor}}>{valueBusinessEntity.name}</a>
                          {
                            valueBusinessEntity.branches
                            &&
                            valueBusinessEntity.branches.length > 0
                            &&
                            <div>
                            {
                              valueBusinessEntity.branches.map((valueBranches, indexBranches)=> (
                                <div>
                                  <div style={{border: "solid", borderWidth: '1px', borderColor: "lightgrey", width: "0px", height: '40px', marginLeft: "50%"}}/>
                                  <div key={`branches-${indexBusinessEntity}-${indexBranches}`}>
                                    <a href={blnPermissionManageBranch ? `/${RoutesList.branch}/view/${valueBranches.id}` : "#"}  target={Constants.blank} rel="noopener noreferrer" style={{backgroundColor: branchColor, borderColor: branchColor}}>{valueBranches.name}</a>
                                  </div>
                                </div>
                              ))
                            }
                            </div>
                          }
                        </li>
                      ))
                    }        
                  </ul>
                }
              </li>
            </ul>
            <div className="d-print-none" style={{ marginTop: "3rem" }}>
              <GeneralSubmitAndCancelBtn
                className="width-100-percentage"
                successMessage={""}
                viewAction={false}
                hidePrimaryButton={true}
                cancelButtonTitle={intl.formatMessage({ id: "Back" })}
                cancelButtonFunction={()=> {
                  history.goBack();
                }}
                showPrint={true}
                cancelButtonBesidePrint={true}
              />
            </div>
          </div>
        )}
			</DetailViewLayout>
		</div>
	);
};

export default observer(BrandSiteMap);
