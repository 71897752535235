import { PaginationRequestBody } from "../models/pagination";
import { MediaSourceListObject, MediaSourceAddObject, MediaSourceUpdateObject, MediaSourceDetailObject } from "../models/mediaSource";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class mediaSourceStore {
  mediaSourceList: MediaSourceListObject[] = [];
  mediaSourceDropdownList: MediaSourceListObject[] = [];
  mediaSourceDetail: MediaSourceDetailObject | undefined = undefined;
  mediaSourcePaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.mediaSourceList = [];
    this.mediaSourceDropdownList = [];
    this.mediaSourceDetail = undefined;
    this.mediaSourcePaginationParams= undefined;
  }

  setMediaSourceDetail = (mediaSourceDetail: MediaSourceDetailObject | undefined) => {
    this.mediaSourceDetail = mediaSourceDetail;
  }

  setMediaSourcePaginationParams = (mediaSourcePaginationParams: PaginationRequestBody | undefined) => {
    this.mediaSourcePaginationParams = mediaSourcePaginationParams;
  }
  
  getMediaSource = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setMediaSourcePaginationParams(PaginationRequestBody);
    try{
      const resultMediaSource = await agent.MediaSource.mediaSourceList(PaginationRequestBody);
      runInAction(() => {
        this.mediaSourceList = resultMediaSource.data;
        store.commonStore.setTotalItem(resultMediaSource.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.mediaSourceList = [];
    }
  }

  getMediaSourceDropdown = async () => {
    try{
      const resultMediaSourceDropdown = await agent.MediaSource.mediaSourceDropdownList();
      runInAction(() => {
        this.mediaSourceDropdownList = resultMediaSourceDropdown;
      });
      return Promise.resolve(resultMediaSourceDropdown);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.mediaSourceDropdownList = [];
    }
  }

  getMediaSourceWithId = async (id: string) => {
    try{
      const resultMediaSourceDetail = await agent.MediaSource.mediaSourceDetail(id);
      runInAction(() => {
        this.mediaSourceDetail = resultMediaSourceDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.mediaSourceDetail = undefined;
    }
  }

  addMediaSource = async (mediaSourceRequestBody: MediaSourceAddObject) => {
    try{
      await agent.MediaSource.addMediaSource(mediaSourceRequestBody);
      store.commonStore.setSuccessMessage(`MediaSourceAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateMediaSource = async (mediaSourceRequestBody: MediaSourceUpdateObject) => {
    try{
      await agent.MediaSource.updateMediaSource(mediaSourceRequestBody);
      store.commonStore.setSuccessMessage(`MediaSourceUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteMediaSource  = async (id: string, name: string) => {
    try {
      await agent.MediaSource.deleteMediaSource(id);
      store.commonStore.setSuccessMessage(`MediaSourceDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}