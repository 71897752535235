import { PaginationRequestBody } from "../models/pagination";
import { ProductRedemptionNoteReturnAddObject, ProductRedemptionNoteReturnDetailObject, ProductRedemptionNoteReturnEligibleListObject, ProductRedemptionNoteReturnListObject, ProductRedemptionNoteReturnReviewObject } from "../models/productRedemptionNoteReturn";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class productRedemptionNoteReturnStore {
  productRedemptionNoteReturnList: ProductRedemptionNoteReturnListObject[] = [];
  productRedemptionNoteReturnDetail: ProductRedemptionNoteReturnDetailObject | undefined = undefined;
  productRedemptionNoteReturnMovementsTotalItem: number = 0;
  productRedemptionNoteReturnPaginationParams: PaginationRequestBody | undefined = undefined;
  productRedemptionNoteReturnEligibleList: ProductRedemptionNoteReturnEligibleListObject[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.productRedemptionNoteReturnList = [];
    this.productRedemptionNoteReturnDetail = undefined;
    this.productRedemptionNoteReturnEligibleList = [];
    this.productRedemptionNoteReturnMovementsTotalItem = 0;
    this.productRedemptionNoteReturnPaginationParams = undefined;
  }

  setProductRedemptionNoteReturnDetail = (productRedemptionNoteReturnDetail: ProductRedemptionNoteReturnDetailObject | undefined) => {
    this.productRedemptionNoteReturnDetail = productRedemptionNoteReturnDetail;
  }

  setProductRedemptionNoteReturnPaginationParams = (productRedemptionNoteReturnPaginationParams: PaginationRequestBody | undefined) => {
    this.productRedemptionNoteReturnPaginationParams = productRedemptionNoteReturnPaginationParams;
  }

  setProductRedemptionNoteReturnEligibleList = (productRedemptionNoteReturnEligibleList: ProductRedemptionNoteReturnEligibleListObject[]) => {
    this.productRedemptionNoteReturnEligibleList = productRedemptionNoteReturnEligibleList;
  }
  
  getProductRedemptionNoteReturn = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setProductRedemptionNoteReturnPaginationParams(PaginationRequestBody);
    try{
      const resultProductRedemptionNoteReturn = await agent.ProductRedemptionNoteReturn.productRedemptionNoteReturnList(PaginationRequestBody);
      runInAction(() => {
        this.productRedemptionNoteReturnList = resultProductRedemptionNoteReturn.data;
        store.commonStore.setTotalItem(resultProductRedemptionNoteReturn.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionNoteReturnList = [];
    }
  }
  
  getProductRedemptionNoteReturnWithId = async (id: string) => {
    try{
      const resultProductRedemptionNoteReturn = await agent.ProductRedemptionNoteReturn.productRedemptionNoteReturnDetail(id);
      runInAction(() => {
        this.productRedemptionNoteReturnDetail = resultProductRedemptionNoteReturn;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionNoteReturnDetail = undefined;
    }
  }

  getProductRedemptionNoteReturnEligibleWithId = async (customerId: string) => {
    try{
      let resultProductRedemptionNoteReturnEligible = await agent.ProductRedemptionNoteReturn.productRedemptionNoteReturnEligibleList(customerId);
      if (resultProductRedemptionNoteReturnEligible) {
        resultProductRedemptionNoteReturnEligible = resultProductRedemptionNoteReturnEligible.map((valueProductRedemptionEligible) => {
          if (valueProductRedemptionEligible.details) {
            valueProductRedemptionEligible.details = valueProductRedemptionEligible.details.map((valueProductRedemptionEligibleDetails)=> ({
              ...valueProductRedemptionEligibleDetails,
              maxQuantity: valueProductRedemptionEligibleDetails.quantity - valueProductRedemptionEligibleDetails.creditExchangedQuantity,
              quantity: 0
            }))
          }
          return valueProductRedemptionEligible;
        })
      }
      runInAction(() => {
        this.productRedemptionNoteReturnEligibleList = resultProductRedemptionNoteReturnEligible;
      });
      return Promise.resolve(resultProductRedemptionNoteReturnEligible);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.productRedemptionNoteReturnEligibleList = [];
    }
  }

  addProductRedemptionNoteReturn = async (productRedemptionNoteReturnRequestBody: ProductRedemptionNoteReturnAddObject) => {
    try{
      const resultProductRedemptionNoteReturn=  await agent.ProductRedemptionNoteReturn.addProductRedemptionNoteReturn(productRedemptionNoteReturnRequestBody);
      store.commonStore.setSuccessMessage(`ProductRedemptionNoteReturnAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultProductRedemptionNoteReturn});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: {productRedemptionNoteReturnId: "", productRedemptionNoteReturnNo: ""}});
    }
  }
  
  reviewProductRedemptionNoteReturn = async (reviewProductRedemptionNoteReturnRequestbody: ProductRedemptionNoteReturnReviewObject) => {
    try{
      await agent.ProductRedemptionNoteReturn.reviewProductRedemptionNoteReturn(reviewProductRedemptionNoteReturnRequestbody);
      store.commonStore.setSuccessMessage(`ProductRedemptionNoteReturnUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  cancelProductRedemptionNoteReturn = async (id: string) => {
    try{
      await agent.ProductRedemptionNoteReturn.cancelProductRedemptionNoteReturn(id);
      store.commonStore.setSuccessMessage(`CancelProductRedemptionNoteReturnSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}