import { PaginationRequestBody } from "../models/pagination";
import { NotificationDetailObject, NotificationListObject } from "../models/notification";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";

export default class notificationStore {
  notificationList: NotificationListObject[] = [];
  notificationDropdownList: NotificationListObject[] = [];
  notificationDetail: NotificationDetailObject | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.notificationList = [];
    this.notificationDetail = undefined;
    this.notificationDropdownList = [];
  }

  getNotification = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultNotification = await agent.Notification.notificationList(PaginationRequestBody);
      runInAction(() => {
        this.notificationList = resultNotification.data;
        store.commonStore.setTotalItem(resultNotification.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationList = [];
    }
  }

  getNotificationDropdown = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultNotificationDropdown = await agent.Notification.notificationList(PaginationRequestBody);
      runInAction(() => {
        this.notificationDropdownList = resultNotificationDropdown.data;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationDropdownList = [];
    }
  }

  getNotificationWithId = async (id: string) => {
    try{
      const resultNotificationDetail = await agent.Notification.notificationDetail(id);
      runInAction(() => {
        this.notificationDetail = resultNotificationDetail;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.notificationDetail = undefined;
      return Promise.resolve({status: Constants.failed});
    }
  }
}