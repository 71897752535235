import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import GeneralInput from "../../app/components/form/GeneralInput";
import { checkPermission, contructValidationErrorMessage, IncludesLocationPathName, newSpaceBeforeCapitalLetter, removeAllSpace } from "../../app/common/function/function";
import Loading from "../../app/components/loading/Loading";
import _ from "lodash";
import { Row, Col, Form, Label } from "reactstrap";
import LineBreakWithTittle from "../../app/components/form/LineBreakWithTittle";
import GeneralSubmitAndCancelBtn from "../../app/components/form/GeneralSubmitAndCancelBtn";
import SingleColumnRowParent from "../../app/components/form/SingleColumnRowParent";
import DropDownWithTitleMultiSelect from "../../app/components/dropdown/DropDownWithTitleMultiSelect";
import { Constants } from "../../app/constants/Constants";
import { PermissionConstants } from "../../app/constants/PermissionConstants";
import { RoutesList } from "../../app/constants/RoutesList";
import { useIntl } from "react-intl";
import DetailViewLayout from "../../app/components/layout/DetailViewLayout";
import DropDownWithTitle from "../../app/components/dropdown/DropDownWithTitle";
import GeneralTextArea from "../../app/components/form/GeneralTextArea";
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import { burgerMenu } from "../../app/models/common";
import { history } from "../..";

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DoubleColumnRowParent from "../../app/components/form/DoubleColumnRowParent";
import { EmployeeListObject } from "../../app/models/employee";

const NotificationTemplateDetail = () => {
  //Use Params
  const { id } = useParams();

  //Use INTL
  const intl = useIntl();

  //Use Store
  const { notificationTemplateStore, userRoleStore, positionStore, staticReferenceStore, employeeStore, commonStore } = useStore();
  const {
    errorMessage,
    successMessage,
    loading,
    windowSize,
    setErrorMessage,
    setSuccessMessage,
    setLoading,
  } = commonStore;
  const { notificationTemplateList, addNotificationTemplate, updateNotificationTemplate, notificationTemplateDetail, setNotificationTemplateDetail, getNotificationTemplateWithId, notificationTemplateDropdownList, getNotificationTemplateDropdown } = notificationTemplateStore;
  const { userRoleDropdownList, getUserRoleDropdown } = userRoleStore;
  const { positionDropdownList, getPositionDropdown } = positionStore;
  const { getEmployeeDropdownOnlyHQ } = employeeStore;
  const { notificationType, getStaticReferenceWithType } = staticReferenceStore;
  const addAction = IncludesLocationPathName("add");
  const viewAction = IncludesLocationPathName("view");
  const displayTitle = `${addAction ? intl.formatMessage({ id: "Add" }) : viewAction ? intl.formatMessage({ id: "View" }) : intl.formatMessage({ id: "Edit" })
    } ${intl.formatMessage({ id: "NotificationTemplate" })}`;
  const breadCrumbList = [{ title: intl.formatMessage({ id: "NotificationTemplate" }), urlPath: RoutesList.notificationTemplate }];
  const pageSize = Constants.maxPageSize;
  const [localEmployeeDropdownList, setLocalEmployeeDropdownList] = useState<EmployeeListObject[]>([]);
  const [templateTypeList, setTemplateTypeList] = useState<any>([]);
  const [selectedNotificationTemplateType, setSelectedNotificationTemplateType] = useState<any>([]);
  const [selectedNotificationTypeIds, setSelectedNotificationTypeIds] = useState<any>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<any>([]);
  const [selectedPosition, setSelectedPosition] = useState<any>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<any>([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [burgerMenuListContent, setBurgerMeanuListContent] = useState<burgerMenu[]>([]);
  const [blnSubmitting, setBlnSubmitting] = useState(false);
  const blnPermissionUpdateNotificationTemplate = checkPermission([PermissionConstants.UpdateNotificationTemplate]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: notificationTemplateDetail || {
      name: "",
      templateKey: "",
      notificationTypes: [],
      bccEmailAddresses: "",
      emailSubject: "",
      emailBody: "",
      smsSubject: "",
      smsBody: "",
      pushNotificationSubject: "",
      pushNotificationBody: "",
      notificationSubject: "",
      notificationBody: "",
      targetUserRoles: [],
      targetPositions: [],
      targetEmployees: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "NotificationTemplateName" }) })),
      templateKey: Yup.string().required(intl.formatMessage({ id: "ValidationSelect" }, { field: intl.formatMessage({ id: "TemplateKey" }) })),
      notificationTypes: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "NotificationType" }) })),
      //targetUserRoles: Yup.array().min(1, intl.formatMessage({ id: "ValidationSelectOne" }, { field: intl.formatMessage({ id: "UserRole" }) })),
      // bccEmailAddresses: Yup.string().email().test(
      //   "bccEmailAddresses",
      //   intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "BccEmailAddresses" }) }),
      //   (value: any, context) => {
      //     if (blnIsEmail) {
      //       return value;
      //     }
      //     return true;
      //   }
      // ).nullable(),
      emailSubject: Yup.string().test(
        "emailSubject",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "EmailSubject" }) }),
        (value: any, context) => {
          if (blnIsEmail) {
            return value;
          }
          return true;
        }
      ).nullable(),
      smsSubject: Yup.string().test(
        "smsSubject",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "SmsSubject" }) }),
        (value: any, context) => {
          if (blnIsSms) {
            return value;
          }
          return true;
        }
      ).nullable(),
      pushNotificationSubject: Yup.string().test(
        "pushNotificationSubject",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "PushNotificationSubject" }) }),
        (value: any, context) => {
          if (blnIsPushNotification) {
            return value;
          }
          return true;
        }
      ).nullable(),
      notificationSubject: Yup.string().test(
        "notificationSubject",
        intl.formatMessage({ id: "ValidationEnterOne" }, { field: intl.formatMessage({ id: "NotificationSubject" }) }),
        (value: any, context) => {
          if (blnIsSecureMessage) {
            return value;
          }
          return true;
        }
      ).nullable(),
    }),

    onSubmit: async (values) => {
      const valuesTemp: any = _.cloneDeep(values);
      valuesTemp.templateKey = removeAllSpace(validation.values.templateKey);
      if (valuesTemp.notificationTypes) {
        if (!(blnIsEmail)) {
          valuesTemp.bccEmailAddresses = "";
          valuesTemp.emailSubject = "";
          valuesTemp.emailBody = "";
        }
        if (!(blnIsSms)) {
          valuesTemp.smsSubject = "";
          valuesTemp.smsBody = "";
        }
        if (!(blnIsPushNotification)) {
          valuesTemp.pushNotificationSubject = "";
          valuesTemp.pushNotificationBody = "";
        }
        if (!(blnIsSecureMessage)) {
          valuesTemp.notificationSubject = "";
          valuesTemp.notificationBody = "";
        }
      }
      try {
        if (addAction) {
          await addNotificationTemplate(valuesTemp);
        } else {
          await updateNotificationTemplate(valuesTemp);
        }
      } finally {
        validation.setSubmitting(false);
      }
    },
  });

  const disabledFieldInput = validation.isSubmitting || Boolean(successMessage);
  const blnNotificationTypeArray = validation.values.notificationTypes.map(value => removeAllSpace(value.toLowerCase()));
  const blnIsEmail = blnNotificationTypeArray.includes('email');
  const blnIsSms = blnNotificationTypeArray.includes('sms');
  const blnIsPushNotification = blnNotificationTypeArray.includes('pushnotification');
  const blnIsSecureMessage = blnNotificationTypeArray.includes('securemessage');

  useEffect(() => {
    async function fetchNotificationTemplateDetailAPI() {
      setLoading(true);
      let aryAPI: any = [
        getStaticReferenceWithType(Constants.notificationType),
        getNotificationTemplateDropdown(),
        getUserRoleDropdown(""),
        getPositionDropdown(""),
        getEmployeeDropdownOnlyHQ({isActive: true})
      ];
      if (id && !addAction) {
        aryAPI.push(getNotificationTemplateWithId(id));
      }
      let resultAPI = await Promise.all(aryAPI);
      if (resultAPI[4]) {
        setLocalEmployeeDropdownList(resultAPI[4]);
      }
      setLoading(false);
    }

    if (addAction) {
      setNotificationTemplateDetail(undefined);
    }

    if (!addAction && !viewAction) {
      if (!checkPermission([PermissionConstants.UpdateNotificationTemplate], true)) {
        return;
      }
    }
    else if (addAction) {
      if (!checkPermission([PermissionConstants.CreateNotificationTemplate], true)) {
        return;
      }
    }
    else if (viewAction) {
      if (!checkPermission([PermissionConstants.ManageNotificationTemplate], true)) {
        return;
      }
    }

    if(viewAction && blnPermissionUpdateNotificationTemplate){
      burgerMenuListContent.push({ label: `${intl.formatMessage({id: "EditAction"})}`, onFunction: () => { history.push(`/${RoutesList.notificationTemplate}/edit/${id}`)} })
    }

    fetchNotificationTemplateDetailAPI();

    return (() => {
      if (id && !addAction) {
        setNotificationTemplateDetail(undefined);
      }
    })
  }, []);

  useEffect(() => {
    let aryNotificationTemplateDropdownTemp: any = [];
    if (notificationTemplateDropdownList) {
      for (var a = 0; a < notificationTemplateDropdownList.length; a++) {
        aryNotificationTemplateDropdownTemp.push({
          label: notificationTemplateDropdownList[a],
          value: notificationTemplateDropdownList[a]
        })
      }
      setTemplateTypeList(aryNotificationTemplateDropdownTemp)
    }
  }, [notificationTemplateDropdownList])

  useEffect(() => {
    let arySelectedNotificationTypeIdTemp: any = [];
    let arySelectedUserRoleIdTemp: any = [];
    let arySelectedPositionIdTemp: any = [];
    let arySelectedEmployeeIdTemp: any = [];

    if (id && !addAction) {
      if (notificationTemplateDetail) {
        notificationTemplateDetail.notificationTypes.map((value) => {
          for (var a = 0; a < notificationType.length; a++) {
            if (value === notificationType[a].displayValue) {
              arySelectedNotificationTypeIdTemp.push({
                ...notificationType[a],
                label: newSpaceBeforeCapitalLetter(notificationType[a].displayValue),
                value: notificationType[a].key
              })
            }
          }
        })
        setSelectedNotificationTypeIds(arySelectedNotificationTypeIdTemp);

        if (notificationTemplateDetail.targetUserRoles) {
          notificationTemplateDetail.targetUserRoles.map((value) => {
            for (var a = 0; a < userRoleDropdownList.length; a++) {
              if (value === userRoleDropdownList[a].id) {
                arySelectedUserRoleIdTemp.push({
                  ...userRoleDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(userRoleDropdownList[a].name),
                  value: userRoleDropdownList[a].id
                })
              }
            }
          })
        }
        setSelectedUserRole(arySelectedUserRoleIdTemp);

        if (notificationTemplateDetail.targetPositions) {
          notificationTemplateDetail.targetPositions.map((value) => {
            for (var a = 0; a < positionDropdownList.length; a++) {
              if (value === positionDropdownList[a].id) {
                arySelectedPositionIdTemp.push({
                  ...positionDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(positionDropdownList[a].name),
                  value: positionDropdownList[a].id
                })
              }
            }
          })
        }
        setSelectedPosition(arySelectedPositionIdTemp);

        if (notificationTemplateDetail.targetEmployees) {
          notificationTemplateDetail.targetEmployees.map((value) => {
            for (var a = 0; a < localEmployeeDropdownList.length; a++) {
              if (value === localEmployeeDropdownList[a].id) {
                arySelectedEmployeeIdTemp.push({
                  ...localEmployeeDropdownList[a],
                  label: newSpaceBeforeCapitalLetter(localEmployeeDropdownList[a].preferredName),
                  value: localEmployeeDropdownList[a].id
                })
              }
            }
          })
        }
        setSelectedEmployee(arySelectedEmployeeIdTemp);

        //set email body
        let contentState = stateFromHTML(notificationTemplateDetail.emailBody);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
    let arySelectedLeaveTypeTemp: any = [];
    templateTypeList.map((valueTemplateTypeList) => {
      if (notificationTemplateList && notificationTemplateList.length > 0) {
        let templateTypeFound = false;
        for (var a = 0; a < notificationTemplateList.length; a++) {
          if (notificationTemplateList[a].templateKey === valueTemplateTypeList.value) {
            templateTypeFound = true;
          }
        }
        arySelectedLeaveTypeTemp.push({
          label: valueTemplateTypeList.label,
          value: valueTemplateTypeList.value,
        })
        setSelectedNotificationTemplateType(arySelectedLeaveTypeTemp);
      } else {
        arySelectedLeaveTypeTemp.push({
          label: valueTemplateTypeList.label,
          value: valueTemplateTypeList.value,
          disabled: false
        })
        setSelectedNotificationTemplateType(arySelectedLeaveTypeTemp);
      }
    });
  }, [notificationTemplateDetail, notificationType, userRoleDropdownList, templateTypeList, positionDropdownList, localEmployeeDropdownList])

  useEffect(() => {
    if (validation.isSubmitting) {
      setBlnSubmitting(true);
    }
  
    if (!validation.isSubmitting && blnSubmitting) {
      setBlnSubmitting(false);
      if (Object.keys(validation.errors).length > 0) {
        if (Constants.validationError) {
          console.log(`Validation Errors :: ${JSON.stringify(validation.errors)}`)
        }
				setErrorMessage(Constants.validationErrorActualMessage ? contructValidationErrorMessage(validation.errors) : intl.formatMessage({ id: "ValidationError" }))
      }
    }
  }, [validation.isSubmitting, validation.errors])

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    validation.setFieldValue("emailBody", currentContentAsHTML)
  }

  return (
    <div>
      <DetailViewLayout
        title={displayTitle}
        breadCrumbList={breadCrumbList}
        burgerMenuList={burgerMenuListContent}
        auditTrailId={viewAction ? id : null}>
        {loading ? (
          <Loading />
        ) : (
          <Form
            className="standard-layout"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            onBlur={() => {
              if (errorMessage || successMessage) {
                setErrorMessage("");
                setSuccessMessage("");
              }
            }}
          >
            <Row form={+true}>
              {" "}
              {/* +true = Convert boolean to numbers*/}
              <Col xl={6}>
                <LineBreakWithTittle
                  paddingBottom="0px"
                  title={intl.formatMessage({ id: "ModuleWithInformation" }, { moduleName: intl.formatMessage({ id: "NotificationTemplate" }) })}
                  h4Title />
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "NotificationTemplateName" })}
                    name="name"
                    className="mb-3 mt-4"
                    type="text"
                    disabled={disabledFieldInput || viewAction}
                    validationRequired={true}
                    validation={validation}
                  />
                </SingleColumnRowParent>
                <Row>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitle
                      name={"templateKey"}
                      title={intl.formatMessage({ id: "TemplateType" })}
                      specifyReturnFieldName={[
                        {
                          field: "templateKey",
                          value: "value",
                        },
                      ]}
                      labelField={"label"}
                      valueField={"value"}
                      blnValueWithNewSpace={true}
                      options={selectedNotificationTemplateType}
                      disabled={disabledFieldInput || viewAction}
                      initialLabel={!addAction ? validation.values.templateKey : undefined}
                      initialValue={!addAction ? validation.values.templateKey : undefined}
                      validationRequired={true}
                      validation={validation}
                    />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent
                    blnDoubleTab={true}>
                    <DropDownWithTitleMultiSelect
                      name={"notificationTypes"}
                      title={intl.formatMessage({ id: "NotificationType" })}
                      specifyReturnFieldName={[{ "field": "notificationTypes", "value": "displayValue" }]}
                      returnFieldWithLabel={false}
                      blnValueWithNewSpace={true}
                      labelField={"displayValue"}
                      valueField={"key"}
                      options={notificationType}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={selectedNotificationTypeIds}
                      validationRequired={true}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <Row>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitleMultiSelect
                      name={"targetUserRoles"}
                      title={intl.formatMessage({ id: "TargetUserRoles" })}
                      specifyReturnFieldName={[{ "field": "targetUserRoles", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={userRoleDropdownList}
                      blnValueWithNewSpace={true}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={selectedUserRole}
                      validationRequired={false}
                      validation={validation} />
                  </DoubleColumnRowParent>
                  <DoubleColumnRowParent blnDoubleTab={true}>
                    <DropDownWithTitleMultiSelect
                      name={"targetPositions"}
                      title={intl.formatMessage({ id: "TargetPositions" })}
                      specifyReturnFieldName={[{ "field": "targetPositions", "value": "id" }]}
                      returnFieldWithLabel={false}
                      labelField={"name"}
                      valueField={"id"}
                      options={positionDropdownList}
                      disabled={disabledFieldInput || viewAction}
                      initialValue={selectedPosition}
                      validationRequired={false}
                      validation={validation} />
                  </DoubleColumnRowParent>
                </Row>
                <SingleColumnRowParent>
                  <DropDownWithTitleMultiSelect
                    name={"targetEmployees"}
                    title={intl.formatMessage({ id: "TargetEmployees" })}
                    specifyReturnFieldName={[{ "field": "targetEmployees", "value": "id" }]}
                    returnFieldWithLabel={false}
                    labelField={"preferredName"}
                    valueField={"id"}
                    options={localEmployeeDropdownList}
                    disabled={disabledFieldInput || viewAction}
                    initialValue={selectedEmployee}
                    validationRequired={false}
                    validation={validation} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  <GeneralInput
                    title={intl.formatMessage({ id: "Active" })}
                    name="isActive"
                    type="checkbox"
                    disabled={disabledFieldInput || viewAction}
                    validation={validation}
                    childrenUI={<Label className="margin-bottom-0 margin-left-4">{intl.formatMessage({ id: "Yes" })}</Label>} />
                </SingleColumnRowParent>
                <SingleColumnRowParent blnDoubleTab={true}>
                  {
                    blnIsEmail &&
                    <>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Email" }) })}
                        className="mt-4"
                        blnSkipMarginTop
                        h4Title
                      />
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "BccEmailAddresses" })}
                          name="bccEmailAddresses"
                          className="mb-3 mt-4"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          // validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "EmailSubject" })}
                          name="emailSubject"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <Label>{intl.formatMessage({ id: "EmailBody" })}</Label>
                        <Editor
                          editorState={editorState}
                          onEditorStateChange={handleEditorChange}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          name="emailBody"                        
                          readOnly={disabledFieldInput || viewAction}
                        />
                      </SingleColumnRowParent>
                    </>
                  }
                </SingleColumnRowParent>
              </Col>
              {
                <Col xl={6} className={`${Constants.innerWidthCompare > windowSize.innerWidth ? "margin-top-32" : ""}`}>
                  {
                    blnIsSms &&
                    <>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "Sms" }).toUpperCase() })}
                        className={"mt-0"}
                        blnSkipMarginTop
                        h4Title
                      />
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "SmsSubject" })}
                          name="smsSubject"
                          className="mb-3 mt-4"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "SmsBody" })}
                          name="smsBody"
                          row={5}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                    </>
                  }
                  {
                    blnIsPushNotification &&
                    <>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "PushNotification" }) })}
                        className={blnIsSms ? "mt-4" : "mt-0"}
                        blnSkipMarginTop
                        h4Title
                      />
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "PushNotificationSubject" })}
                          name="pushNotificationSubject"
                          className="mb-3 mt-4"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "PushNotificationBody" })}
                          name="pushNotificationBody"
                          row={5}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                    </>
                  }
                  {
                    blnIsSecureMessage &&
                    <>
                      <LineBreakWithTittle
                        paddingBottom="0px"
                        title={intl.formatMessage({ id: "ModuleWithDetail" }, { moduleName: intl.formatMessage({ id: "SecureMessage" }) })}
                        className={blnIsPushNotification ? "mt-4" : "mt-0"}
                        blnSkipMarginTop
                        h4Title
                      />
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralInput
                          title={intl.formatMessage({ id: "NotificationSubject" })}
                          name="notificationSubject"
                          className="mb-3 mt-4"
                          type="text"
                          disabled={disabledFieldInput || viewAction}
                          validationRequired={true}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                      <SingleColumnRowParent blnDoubleTab={true}>
                        <GeneralTextArea
                          title={intl.formatMessage({ id: "NotificationBody" })}
                          name="notificationBody"
                          row={5}
                          disabled={disabledFieldInput || viewAction}
                          validation={validation}
                        />
                      </SingleColumnRowParent>
                    </>
                  }
                </Col>
              }
            </Row>
            <GeneralSubmitAndCancelBtn
              className={blnIsEmail ? "mb-3 mt-3" : ""}
              successMessage={successMessage}
              viewAction={viewAction}
              validation={validation}
            />
          </Form>
        )}
      </DetailViewLayout>
    </div>
  );
};

export default observer(NotificationTemplateDetail);
