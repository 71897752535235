import { PaginationRequestBody } from "../models/pagination";
import { CustomerListObject, CustomerAddObject, CustomerUpdateObject, CustomerDetailObject, CustomerNoteListObject, CustomerNoteDetailObject, CustomerNoteAddObject, CustomerNoteUpdateObject, CustomerRedeemHistoryListObject, CustomerCreditHistoryListObject, CustomerValidationRequestBody, CustomerExpiredPackageObject, CustomerRequestAccessListObject, CustomerPackageRedeemHistoryObject, CustomerSubBranchHistoryObject, ApprovedCustomerAccessRequestListObject, SubBranchCustomerListObject, PurchasePackageObject, PurchaseProductObject, ReservedProductObject, PurchaseServiceObject, ClosedPurchaseHistoryObject, SalesOrderObject, TransferHistoryObject, CustomerCriteriaSearchGetObject, CustomerCriteriaSearchResponseObject, CustomerExportObject, CustomerImportObject, CustomerDropdownRequestObject, UpdateCustomerJoinedDateObject, UpdateCustomerMediaSourceObject, AddCustomerTagObject, AddCustomerRelationshipObject, CustomerServiceRedeemHistoryObject } from "../models/customer";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Constants } from "../constants/Constants";
import _ from "lodash";
import { downloadExcel, getBranchId, replaceAllStringWithArray } from "../common/function/function";

export default class customerStore {
  customerList: CustomerListObject[] = [];
  customerDropdownList: CustomerListObject[] = [];
  customerDetail: CustomerDetailObject | undefined = undefined;
  customerNoteList: CustomerNoteListObject[] = [];
  customerNoteDetail: CustomerNoteDetailObject | undefined = undefined;
  customerRedeemHistoryList: CustomerRedeemHistoryListObject[] = [];
  customerCreditHistoryList: CustomerCreditHistoryListObject[] = [];
  customerExpiredPackageList: CustomerExpiredPackageObject[] = [];
  customerRequestAccessList: ApprovedCustomerAccessRequestListObject[] = [];
  customerPurchasedPackageList: PurchasePackageObject[] = [];
  customerPurchasedProductList: PurchaseProductObject[] = [];
  customerReservedProductList: ReservedProductObject[] = [];
  customerPurchasedServiceList: PurchaseServiceObject[] = [];
  customerClosePurchasedHistoryList: ClosedPurchaseHistoryObject[] = [];
  customerSalesOrderList: SalesOrderObject[] = [];
  customerTransferHistoryList: TransferHistoryObject[] = [];
  subBranchCustomersList: SubBranchCustomerListObject[] = [];
  customerSubBranchHistory: CustomerSubBranchHistoryObject[] = [];
  customerPaginationParams: PaginationRequestBody | undefined = undefined;
  customerRedeemHistoryTotalItems: number = 0;
  customerCreditHistoryTotalItems: number = 0;
  customerNoteTotalItems: number = 0;
  customerAdvanceSearchList: CustomerListObject[] = [];
  customerAdvanceSearchTotalItems: number = 0;
  customerPackageRedeemHistory: CustomerPackageRedeemHistoryObject | undefined = undefined;
  customerPackageRedeemHistoryTotalItems: number = 0;
  customerServiceRedeemHistory: CustomerServiceRedeemHistoryObject | undefined = undefined;
  customerSubBranchHistoryTotalItems: number = 0;
  customerPurchasedPackageTotalItems: number = 0;
  customerPurchasedProductTotalItems: number = 0;
  customerReservedProductTotalItems: number = 0;
  customerPurchasedServiceTotalItems: number = 0;
  customerClosePurchasedHistoryTotalItems: number = 0;
  customerSalesOrderTotalItems: number = 0;
  customerTransferHistoryTotalItems: number = 0;
  customerCriteriaSearchDetail: CustomerCriteriaSearchResponseObject | undefined = undefined;
  customerDropdownController: any = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.customerList = [];
    this.customerDropdownList = [];
    this.customerRequestAccessList = [];
    this.subBranchCustomersList = [];
    this.customerSubBranchHistory = [];
    this.customerPurchasedPackageList = [];
    this.customerPurchasedProductList = [];
    this.customerReservedProductList = [];
    this.customerPurchasedServiceList = [];
    this.customerClosePurchasedHistoryList = [];
    this.customerSalesOrderList = [];
    this.customerTransferHistoryList = [];
    this.customerDetail = undefined;
    this.customerPaginationParams = undefined;
    this.customerRedeemHistoryTotalItems = 0;
    this.customerCreditHistoryTotalItems = 0;
    this.customerNoteTotalItems = 0;
    this.customerAdvanceSearchList = [];
    this.customerAdvanceSearchTotalItems = 0;
    this.customerPackageRedeemHistory = undefined;
    this.customerPackageRedeemHistoryTotalItems = 0;
    this.customerSubBranchHistoryTotalItems = 0;
    this.customerPurchasedPackageTotalItems = 0;
    this.customerPurchasedProductTotalItems = 0;
    this.customerReservedProductTotalItems = 0;
    this.customerPurchasedServiceTotalItems = 0;
    this.customerClosePurchasedHistoryTotalItems = 0;
    this.customerSalesOrderTotalItems = 0;
    this.customerTransferHistoryTotalItems = 0;
    this.customerCriteriaSearchDetail = undefined;
    this.customerServiceRedeemHistory = undefined;
    this.customerCreditHistoryList = [];
  }

  resetWhenLeaveCustomerProfile = () => {
    this.customerPurchasedPackageList = [];
    this.customerPurchasedProductList = [];
    this.customerReservedProductList = [];
    this.customerPurchasedServiceList = [];
    this.customerClosePurchasedHistoryList = [];
    this.customerSalesOrderList = [];
    this.customerNoteList = [];
    this.customerTransferHistoryList = [];
    this.customerSubBranchHistory = [];
    this.customerCreditHistoryList = [];
  }

  setCustomerDetail = (customerDetail: CustomerDetailObject | undefined) => {
    this.customerDetail = customerDetail;
  }

  setCustomerList = (customerList: CustomerListObject[]) => {
    this.customerList = customerList;
  }

  setCustomerRequestAccessList = (customerRequestAccessList: ApprovedCustomerAccessRequestListObject[]) => {
    this.customerRequestAccessList = customerRequestAccessList;
  }

  setSubBranchCustomersList = (subBranchCustomersList: SubBranchCustomerListObject[]) => {
    this.subBranchCustomersList = subBranchCustomersList;
  }
  
  setCustomerPaginationParams = (customerPaginationParams: PaginationRequestBody | undefined) => {
    this.customerPaginationParams = customerPaginationParams;
  }

  setCustomerAdvanceSearchList = (customerAdvanceSearchList: CustomerListObject[]) => {
    this.customerAdvanceSearchList = customerAdvanceSearchList;
  } 

  setCustomerAdvanceSearchTotalItems = (customerAdvanceSearchTotalItems: number) => {
    this.customerAdvanceSearchTotalItems = customerAdvanceSearchTotalItems;
  }

  setCustomerNoteDetail = (customerNoteDetail: CustomerNoteDetailObject | undefined) => {
    this.customerNoteDetail = customerNoteDetail;
  }

  setCustomerCriteriaSearchDetail = (customerCriteriaSearchDetail: CustomerCriteriaSearchResponseObject | undefined) => {
    this.customerCriteriaSearchDetail = customerCriteriaSearchDetail;
  } 

  setCustomerDropdownList = (customerDropdownList: CustomerListObject[]) => {
    this.customerDropdownList = customerDropdownList;
  }

  setCustomerDropdownController = (customerDropdownController: any) => {
    this.customerDropdownController = customerDropdownController;
  }

  setCustomerCreditHistoryList = (customerCreditHistoryList: CustomerCreditHistoryListObject[]) => {
    this.customerCreditHistoryList = customerCreditHistoryList;
  }

  getCustomer = async (PaginationRequestBody: PaginationRequestBody, blnReturnResultOnly : boolean = false) => {
    this.setCustomerPaginationParams(PaginationRequestBody);
    try{
      const resultCustomer = await agent.Customer.customerList(PaginationRequestBody);
      if (!blnReturnResultOnly) {
        runInAction(() => {
          this.customerList = resultCustomer.data;
          store.commonStore.setTotalItem(resultCustomer.pagination.totalItems);
        });
      }

      return Promise.resolve(resultCustomer.data);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerList = [];
    }
  }

  getCustomerSearchAll = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCustomerPaginationParams(PaginationRequestBody);
    try{
      const resultCustomer = await agent.Customer.customerSearchAllList(PaginationRequestBody);
      runInAction(() => {
        this.customerList = resultCustomer.data;
        store.commonStore.setTotalItem(resultCustomer.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerList = [];
    }
  }

  getCustomerAdvanceSearch = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerAdvanceSearch = await agent.Customer.customerList(PaginationRequestBody);
      runInAction(() => {
        this.customerAdvanceSearchList = resultCustomerAdvanceSearch.data;
        this.customerAdvanceSearchTotalItems = resultCustomerAdvanceSearch.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerAdvanceSearchList = [];
      this.customerAdvanceSearchTotalItems = 0;
    }
  }

  getCustomerDropdown = async (branchId: string = "", customerDropdownRequestObject: CustomerDropdownRequestObject | undefined = undefined, blnGroupData: boolean = false) => {
    try{
      store.commonStore.setDropdownCustomSearchValue(customerDropdownRequestObject?.customerNoOrName || "");
      if (this.customerDropdownController) {
        this.customerDropdownController.abort()
        this.setCustomerDropdownController(undefined);
      }
      if (!customerDropdownRequestObject?.customerId && !customerDropdownRequestObject?.customerNoOrName) {
        return null;
      }

      const customerDropdownControllerTemp = new AbortController();
      this.setCustomerDropdownController(customerDropdownControllerTemp);
      const resultCustomerDropdown = await agent.Customer.customerDropdownList(branchId, customerDropdownRequestObject, this.customerDropdownController.signal);
      if (!customerDropdownRequestObject.customerId && (customerDropdownRequestObject?.customerNoOrName !== store.commonStore.dropdownCustomSearchValue)) {
        return null;
      }

      let resultCustomerDropdownFinal = resultCustomerDropdown.map((valueTemp: CustomerListObject) => ({...valueTemp, preferredName: `${valueTemp.preferredName} (${valueTemp.customerNo})`}));
      if (blnGroupData) {
        let finalGroupData : any = [];
        resultCustomerDropdownFinal.map((valueResultCustomerDropdownFinal)=> {
          let indexGroup = _.findIndex(finalGroupData, { label: valueResultCustomerDropdownFinal.branchName })
          if (indexGroup > -1) {
            finalGroupData[indexGroup].options.push({
              ...valueResultCustomerDropdownFinal,
              label: valueResultCustomerDropdownFinal.preferredName,
              value: valueResultCustomerDropdownFinal.id
            })
          }
          else {
            finalGroupData.push({
              label: valueResultCustomerDropdownFinal.branchName,
              options: [{
                ...valueResultCustomerDropdownFinal, 
                label: valueResultCustomerDropdownFinal.preferredName,
                value: valueResultCustomerDropdownFinal.id
              }]
            })
          }
        })
        resultCustomerDropdownFinal = finalGroupData;
      }
      runInAction(() => {
        this.customerDropdownList = resultCustomerDropdownFinal;
        this.setCustomerDropdownController(undefined);
      });
      return Promise.resolve(resultCustomerDropdownFinal)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerDropdownList = [];
    }
  }

  getCustomerWithId = async (id: string) => {
    try{
      const resultCustomerDetail = await agent.Customer.customerDetail(id);
      runInAction(() => {
        this.customerDetail = resultCustomerDetail;
      });
      return Promise.resolve(resultCustomerDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerDetail = undefined;
    }
  }

  getCustomerWithAppointmentId = async (id: string) => {
    try{
      const resultCustomerDetail = await agent.Customer.customerDetailWithAppointmentId(id);
      if(resultCustomerDetail.dob == Constants.invalidDateTime) {
        resultCustomerDetail.dob = "1990-01-01";
      }
      if(resultCustomerDetail.branchId == Constants.emptyId){
        resultCustomerDetail.branchId = "";
      }
      if(resultCustomerDetail.consultantId == Constants.emptyId){
        resultCustomerDetail.consultantId = "";
      }
      return Promise.resolve(resultCustomerDetail)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerDetail = undefined;
    }
  }

  addCustomer = async (customerRequestBody: CustomerAddObject) => {
    try{
      const resultAddCustomer = await agent.Customer.addCustomer(customerRequestBody);
      store.commonStore.setSuccessMessageWithoutNavigation(`CustomerAddSuccess`)
      return Promise.resolve({status: Constants.success, data: resultAddCustomer.customerId});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: ""});
    }
  }

  updateCustomer = async (customerRequestBody: CustomerUpdateObject) => {
    try{
      await agent.Customer.updateCustomer(customerRequestBody);
      store.commonStore.setSuccessMessage(`CustomerUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomer  = async (id: string, name: string) => {
    try {
      await agent.Customer.deleteCustomer(id);
      store.commonStore.setSuccessMessage(`CustomerDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCustomerNote = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerNote = await agent.Customer.customerNoteList(PaginationRequestBody);
      runInAction(() => {
        this.customerNoteList = resultCustomerNote.data;
        this.customerNoteTotalItems = resultCustomerNote.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerNoteList = [];
      this.customerNoteTotalItems = 0;
    }
  }

  getCustomerNoteWithId = async (id: string) => {
    try{
      const resultCustomerNoteDetail = await agent.Customer.customerNoteDetail(id);
      runInAction(() => {
        this.customerNoteDetail = resultCustomerNoteDetail;
      });
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerNoteDetail = undefined;
      return Promise.resolve({status: Constants.failed});
    }
  }

  addCustomerNote = async (customerNoteRequestBody: CustomerNoteAddObject) => {
    try{
      await agent.Customer.addCustomerNote(customerNoteRequestBody);
      store.commonStore.setSuccessMessage(`CustomerNoteAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCustomerNote = async (customerNoteRequestBody: CustomerNoteUpdateObject) => {
    try{
      await agent.Customer.updateCustomerNote(customerNoteRequestBody);
      store.commonStore.setSuccessMessage(`CustomerNoteUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCustomerNote  = async (id: string) => {
    try {
      await agent.Customer.deleteCustomerNote(id);
      store.commonStore.setSuccessMessageWithoutNavigation(`CustomerNoteDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCustomerRedeemHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerRedeemHistory = await agent.Customer.customerRedeemHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.customerRedeemHistoryList = resultCustomerRedeemHistory.data;
        this.customerRedeemHistoryTotalItems = resultCustomerRedeemHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerRedeemHistoryList = [];
      this.customerRedeemHistoryTotalItems = 0;
    }
  }

  getCustomerCreditHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerCreditHistory = await agent.Customer.customerCreditHistoryList(PaginationRequestBody);
      runInAction(() => {
        this.customerCreditHistoryList = resultCustomerCreditHistory.data;
        this.customerCreditHistoryTotalItems = resultCustomerCreditHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerCreditHistoryList = [];
      this.customerCreditHistoryTotalItems = 0;
    }
  }

  customerValidate = async (customerValidationRequestBody: CustomerValidationRequestBody) => {
    try{
      const resultCustomerValidation = await agent.Customer.customerValidation(customerValidationRequestBody);
      return Promise.resolve({status: Constants.success, data: resultCustomerValidation});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed, data: []});
    }
  }

  getCustomerExpiredPackage = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerExpiredPackage = await agent.Customer.customerExpiredPackage(PaginationRequestBody);
      runInAction(() => {
        this.customerExpiredPackageList = resultCustomerExpiredPackage.data;
        store.commonStore.setTotalItem(resultCustomerExpiredPackage.pagination.totalItems);
      });
      return Promise.resolve(resultCustomerExpiredPackage.data);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerExpiredPackageList = [];
    }
  }

  getCustomerRequestAccessList = async (requestBranchId: string) => {
    try{
      // const resultCustomerRequestAccessList = await agent.Customer.customerRequestAcessList(requestBranchId);
      // runInAction(() => {
        // let resultCustomerRequestAccessListTemp : any = _.cloneDeep(resultCustomerRequestAccessList);
        // resultCustomerRequestAccessListTemp = resultCustomerRequestAccessListTemp.map((valueResultCustomerRequestAccessList: CustomerRequestAccessListObject)=> ({
        //   ...valueResultCustomerRequestAccessList,
        //   id: valueResultCustomerRequestAccessList.customerId,
        //   preferredName: valueResultCustomerRequestAccessList.customerName,
        //   branchName: valueResultCustomerRequestAccessList.originalBranchName,
        // }))
      //   this.customerRequestAccessList = resultCustomerRequestAccessListTemp;
      // });
      const resultCustomerRequestOtherBranch = await agent.Customer.customerRequestOtherBranch(requestBranchId);
      runInAction(() => {
        let resultCustomerRequestOtherBranchTemp : any = _.cloneDeep(resultCustomerRequestOtherBranch);
        if (resultCustomerRequestOtherBranchTemp) {
          if (resultCustomerRequestOtherBranchTemp.approvedCustomerAccessRequests) {
            let approvedCustomerAccessRequestsTemp : any = _.cloneDeep(resultCustomerRequestOtherBranchTemp.approvedCustomerAccessRequests);
            approvedCustomerAccessRequestsTemp = approvedCustomerAccessRequestsTemp.map((valueApprovedCustomerAccessRequestsTemp: ApprovedCustomerAccessRequestListObject)=> ({
              ...valueApprovedCustomerAccessRequestsTemp,
              id: valueApprovedCustomerAccessRequestsTemp.customerId,
              preferredName: valueApprovedCustomerAccessRequestsTemp.customerName,
              branchName: valueApprovedCustomerAccessRequestsTemp.originalBranchName,
            }))
            this.customerRequestAccessList = approvedCustomerAccessRequestsTemp;
          }
          if (resultCustomerRequestOtherBranchTemp.subBranchCustomers) {
            let subBranchCustomersTemp : any = _.cloneDeep(resultCustomerRequestOtherBranchTemp.subBranchCustomers);
            subBranchCustomersTemp = subBranchCustomersTemp.map((valueSubBranchCustomersTemp: SubBranchCustomerListObject)=> ({
              ...valueSubBranchCustomersTemp,
              id: valueSubBranchCustomersTemp.customerId,
              preferredName: valueSubBranchCustomersTemp.customerName,
              branchName: valueSubBranchCustomersTemp.originalBranchName,
            }))
            this.subBranchCustomersList = subBranchCustomersTemp;
          }
        }
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerRequestAccessList = [];
      this.subBranchCustomersList = [];
    }
  }

  getCustomerPackageRedeemHistory = async (id: string) => {
    try{
      const resultCustomerPackageRedeemHistory = await agent.Customer.customerPackageRedeemHistory(id);
      runInAction(() => {
        resultCustomerPackageRedeemHistory.packageRedemptionHistories = resultCustomerPackageRedeemHistory.packageRedemptionHistories.map((valuePackageRedemptionHistories, index) =>({
          ...valuePackageRedemptionHistories,
          randomId: `${valuePackageRedemptionHistories.redeemDetailName}_${index}`,
          redeemType: replaceAllStringWithArray(valuePackageRedemptionHistories.redeemType, [Constants.package], "")
        }))
        this.customerPackageRedeemHistory = resultCustomerPackageRedeemHistory;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPackageRedeemHistory = undefined;
    }
  }

  getCustomerServiceRedeemHistory = async (id: string) => {
    try{
      const resultCustomerServiceRedeemHistory = await agent.Customer.customerServiceRedeemHistory(id);
      runInAction(() => {
        resultCustomerServiceRedeemHistory.serviceRedemptionHistories = resultCustomerServiceRedeemHistory.serviceRedemptionHistories.map((valueServiceRedemptionHistories, index) =>({
          ...valueServiceRedemptionHistories,
          randomId: `${valueServiceRedemptionHistories.redemptionDate}_${index}`,
        }))
        this.customerServiceRedeemHistory = resultCustomerServiceRedeemHistory;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerServiceRedeemHistory = undefined;
    }
  }

  getCustomerSubBranchHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultCustomerSubBranchHistory = await agent.Customer.customerSubBranchHistory(PaginationRequestBody);
      runInAction(() => {
        this.customerSubBranchHistory = resultCustomerSubBranchHistory.data;
        this.customerSubBranchHistoryTotalItems = resultCustomerSubBranchHistory.pagination.totalItems;
      });
      return Promise.resolve(resultCustomerSubBranchHistory.data);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSubBranchHistory = [];
      this.customerSubBranchHistoryTotalItems = 0;
    }
  }

  getCustomerPurchasedPackage = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPurchasedPackage = await agent.Customer.customerPurchasedPackage(PaginationRequestBody);
      runInAction(() => {
        this.customerPurchasedPackageList = resultPurchasedPackage.data;
        this.customerPurchasedPackageTotalItems = resultPurchasedPackage.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPurchasedPackageList = [];
      this.customerPurchasedPackageTotalItems = 0;
    }
  }

  getCustomerPurchasedProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPurchasedProduct = await agent.Customer.customerPurchasedProduct(PaginationRequestBody);
      runInAction(() => {
        this.customerPurchasedProductList = resultPurchasedProduct.data;
        this.customerPurchasedProductTotalItems = resultPurchasedProduct.pagination.totalItems;
      });
      return Promise.resolve(resultPurchasedProduct.data)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPurchasedProductList = [];
      this.customerPurchasedProductTotalItems = 0;
    }
  }

  getCustomerReservedProduct = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultReservedProduct = await agent.Customer.customerReservedProduct(PaginationRequestBody);
      runInAction(() => {
        this.customerReservedProductList = resultReservedProduct.data;
        this.customerReservedProductTotalItems = resultReservedProduct.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerReservedProductList = [];
      this.customerReservedProductTotalItems = 0;
    }
  }

  getCustomerPurchasedService = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultPurchasedService = await agent.Customer.customerPurchasedService(PaginationRequestBody);
      runInAction(() => {
        this.customerPurchasedServiceList = resultPurchasedService.data;
        this.customerPurchasedServiceTotalItems = resultPurchasedService.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerPurchasedServiceList = [];
      this.customerPurchasedServiceTotalItems = 0;
    }
  }

  getCustomerClosePurchasedHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultClosePurchasedHistory = await agent.Customer.customerClosePurchasedHistory(PaginationRequestBody);
      runInAction(() => {
        this.customerClosePurchasedHistoryList = resultClosePurchasedHistory.data;
        this.customerClosePurchasedHistoryTotalItems = resultClosePurchasedHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerClosePurchasedHistoryList = [];
      this.customerClosePurchasedHistoryTotalItems = 0;
    }
  }

  getCustomerSalesOrder = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultSalesOrder = await agent.Customer.customerSalesOrder(PaginationRequestBody);
      runInAction(() => {
        this.customerSalesOrderList = resultSalesOrder.data;
        this.customerSalesOrderTotalItems = resultSalesOrder.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerSalesOrderList = [];
      this.customerSalesOrderTotalItems = 0;
    }
  }

  getCustomerTransferHistory = async (PaginationRequestBody: PaginationRequestBody) => {
    try{
      const resultTransferHistory = await agent.Customer.customerTransferHistory(PaginationRequestBody);
      runInAction(() => {
        this.customerTransferHistoryList = resultTransferHistory.data;
        this.customerTransferHistoryTotalItems = resultTransferHistory.pagination.totalItems;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerTransferHistoryList = [];
      this.customerTransferHistoryTotalItems = 0;
    }
  }

  exportCustomer = async (CustomerExportRequestBody: CustomerExportObject) => {
    try{
      await downloadExcel(`/customer/export`, CustomerExportRequestBody);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  importCustomer = async (customerImportRequestBody: CustomerImportObject) => {
    try{
      const resutlImportCustomer = await agent.Customer.importCustomer(customerImportRequestBody);
      return Promise.resolve(resutlImportCustomer);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
  
  getCustomerCriteriaSearch = async (customerCriteriaSearchObject: CustomerCriteriaSearchGetObject) => {
    try{
      const resultCustomerCriteriaSearch = await agent.Customer.customerCriteriaSearch(customerCriteriaSearchObject);
      return Promise.resolve(resultCustomerCriteriaSearch);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.customerCriteriaSearchDetail = undefined;
    }
  }

  updateCustomerJoinedDate = async (customerJoinedDateRequestBody: UpdateCustomerJoinedDateObject) => {
    try{
      await agent.Customer.updateCustomerJoinedDate(customerJoinedDateRequestBody);
      store.commonStore.setSuccessMessage(`CustomerJoinedDateUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  updateCustomerMediaSource = async (customerMediaSourceRequestBody: UpdateCustomerMediaSourceObject) => {
    try{
      await agent.Customer.updateCustomerMediaSource(customerMediaSourceRequestBody);
      store.commonStore.setSuccessMessage(`CustomerMediaSourceUpdateSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  addCustomerTag = async (addCustomerTagRequestBody: AddCustomerTagObject) => {
    try{
      await agent.Customer.addCustomerTag(addCustomerTagRequestBody);
      store.commonStore.setSuccessMessage(`CustomerTagAddSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  deleteCustomerTag = async (id: string) => {
    try{
      await agent.Customer.deleteCustomerTag(id);
      store.commonStore.setSuccessMessage(`CustomerTagDeleteSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  addCustomerRelationship = async (addCustomerRelationshipRequestBody: AddCustomerRelationshipObject) => {
    try{
      await agent.Customer.addCustomerRelationship(addCustomerRelationshipRequestBody);
      store.commonStore.setSuccessMessage(`CustomerRelationshipAddSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  deleteCustomerRelationship = async (id: string) => {
    try{
      await agent.Customer.deleteCustomerRelationship(id);
      store.commonStore.setSuccessMessage(`CustomerRelationshipDeleteSuccess`)
      return Promise.resolve({status: Constants.success});
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      return Promise.resolve({status: Constants.failed});
    }
  }

  getCustomerAvailableUnknownPayment = async (customerId: string) => {
    try{
      const resultCustomerAvailableUnknownPayment = await agent.Customer.customerAvailableUnknownPayment(customerId);
      return Promise.resolve(resultCustomerAvailableUnknownPayment);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getCustomerAvailableCreditPayment = async (customerId: string) => {
    try{
      const resultCustomerAvailableCreditPayment = await agent.Customer.customerAvailableCreditPayment(customerId, getBranchId());
      return Promise.resolve(resultCustomerAvailableCreditPayment);
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }
}