import { PaginationRequestBody } from "../models/pagination";
import { CountryListObject, CountryAddObject, CountryUpdateObject, CountryDetailObject, StateDetailObject } from "../models/country";
import { store } from "./store";
import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class countryStore {
  countryList: CountryListObject[] = [];
  countryDropdownList: CountryListObject[] = [];
  countryDetail: CountryDetailObject | undefined = undefined;
  stateList: StateDetailObject[] = [];
  countryPaginationParams: PaginationRequestBody | undefined = undefined;

  constructor() {
    makeAutoObservable(this)
  }

  reset = () => {
    this.countryList = [];
    this.countryDropdownList = [];
    this.countryDetail = undefined;
    this.stateList = [];
    this.countryPaginationParams= undefined;
  }

  setCountryDetail = (countryDetail: CountryDetailObject | undefined) => {
    this.countryDetail = countryDetail;
  }

  setStateList = (stateList: StateDetailObject[]) => {
    this.stateList = stateList;
  }

  setCountryPaginationParams = (countryPaginationParams: PaginationRequestBody | undefined) => {
    this.countryPaginationParams = countryPaginationParams;
  }
  
  getCountry = async (PaginationRequestBody: PaginationRequestBody) => {
    this.setCountryPaginationParams(PaginationRequestBody);
    try{
      const resultCountry = await agent.Country.countryList(PaginationRequestBody);
      runInAction(() => {
        this.countryList = resultCountry.data;
        store.commonStore.setTotalItem(resultCountry.pagination.totalItems);
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.countryList = [];
    }
  }

  getCountryDropdown = async () => {
    try{
      const resultCountryDropdown = await agent.Country.countryDropdownList();
      runInAction(() => {
        this.countryDropdownList = resultCountryDropdown;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.countryDropdownList = [];
    }
  }

  getCountryWithId = async (id: string) => {
    try{
      const resultCountryDetail = await agent.Country.countryDetail(id);
      runInAction(() => {
        this.countryDetail = resultCountryDetail;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.countryDetail = undefined;
    }
  }

  addCountry = async (countryRequestBody: CountryAddObject) => {
    try{
      await agent.Country.addCountry(countryRequestBody);
      store.commonStore.setSuccessMessage(`CountryAddSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  updateCountry = async (countryRequestBody: CountryUpdateObject) => {
    try{
      await agent.Country.updateCountry(countryRequestBody);
      store.commonStore.setSuccessMessage(`CountryUpdateSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  deleteCountry  = async (id: string, name: string) => {
    try {
      await agent.Country.deleteCountry(id);
      store.commonStore.setSuccessMessage(`CountryDeleteSuccess`)
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
    }
  }

  getStateWithId = async (id: string) => {
    try{
      const resultStateList = await agent.Country.stateList(id);
      runInAction(() => {
        this.stateList = resultStateList;
      });
    }
    catch (error: any) {
      console.error(error)
      store.commonStore.setErrorMessage(Array.isArray(error) ? error[0] : error.message)
      this.stateList = [];
    }
  } 
}